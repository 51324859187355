import { Collection, Model } from '@b2cmessenger/backbone';
import settings from 'settings';

import ReservationModel from 'models/ReservationModel';

const ReservationSummaryCollection = Collection.extend({
    model: Model,
    options: {
        placeId: undefined,
        userId: undefined,
    },

    url: settings.host + settings.serv_reservation.calendar_summary,

    parse(data) {
        return data.map(({ date, values }) => this._parseDayValues(date, values));
    },

    fetch(options) {
        _.defaults(options || (options = {}), {
            data: {},
        });

        _.defaults(options.data, {
            'place_id': options.placeId || this.options.placeId || null,
            'user_id': options.userId || this.options.userId || options.userId,
        });

        if (!_.isUndefined(options.dateAfter)) {
            _.defaults(options.data, {
                'date_after': options.dateAfter
            });
        }

        if (!_.isUndefined(options.dateBefore)) {
            _.defaults(options.data, {
                'date_before': options.dateBefore
            });
        }

        if (!_.isUndefined(options.status)) {
            const status = [];
            const statusValues = _.values(_.omit(ReservationModel.Status, 'Unknown'));

            if (_.isArray(options.status)) {
                status.push(..._.intersection(statusValues, options.status));
            } else if (statusValues.includes(options.status)) {
                status.push(options.status);
            }

            if (status.length) {
                _.extend(options.data, {
                    'status': status
                });
            }
        }

        return Collection.prototype.fetch.call(this, options);
    },

    _parseDayValues(day, values) {
        const date = B2Cjs.datetimeServerLocalToJS(day);
        const unix = date.getTime();

        return _(values).chain()
            .keys()
            .reduce((memo, key) => {
                const val = values[key];

                switch (Number(key)) {
                    case ReservationModel.Status.New:
                    case ReservationModel.Status.ClientSuggestedChanges:
                    case ReservationModel.Status.BusinessSuggestedChanges:
                        memo.pending += val;
                        break;
                    case ReservationModel.Status.CancelledByClient:
                    case ReservationModel.Status.RejectedByBusiness:
                        memo.canceled += val;
                        break;
                    case ReservationModel.Status.Confirmed:
                        memo.accepted += val;
                        break;
                }

                return memo;
            }, { accepted: 0, pending: 0, canceled: 0 })
            .assign({ id: unix, date })
            .value();
    }
});

export default ReservationSummaryCollection;
