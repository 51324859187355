import { Required, ItemView, ViewModel } from '@b2cmessenger/backbone';

import messagePhotoItemTemplate from './MessagePhotoItem.jade';
import './MessagePhotoItem.scss';

/** @type {(properties: import('./MessagePhotoItem').Properties) => (target: any) => typeof MessagePhotoItemView} */
const properties = ItemView.properties;

/** @type {(options: import('./MessagePhotoItem').Options) => (target: any) => typeof MessagePhotoItemView} */
const options = ItemView.options;

@options({
    model: Required
})
@properties({
    className: 'widget message-photo-item-widget',
    template: messagePhotoItemTemplate,

    attributes() {
        return {
            src: this.model.get('url') || ''
        }
    },

    ui: {
        img: '[data-js-img]',
        more: '[data-js-more]'
    },

    computeds: {
        src: {
            deps: ['url'],
            get: url => url || ''
        },
        cssWidth: {
            deps: ['widthPercent'],
            get: (widthPercent) => widthPercent > 0 && `${(widthPercent - 0.0001).toFixed(4)}%` || 'auto'
        },
        cssHeightPadding: {
            deps: ['widthPercent', 'ar'],
            get: (widthPercent, ar) => {
                widthPercent = Number(widthPercent) || 0;
                ar = Number(ar) || 1;
                return `${(Math.floor(widthPercent / ar * 10000) / 10000).toFixed(3)}%`;
            }
        },
        cssMarginLeft: {
            deps: ['leftMargin'],
            get: leftMargin => leftMargin > 0 && `${(leftMargin - 0.01).toFixed(2)}px` || '0'
        },
        cssMarginTop: {
            deps: ['topMargin'],
            get: topMargin => topMargin > 0 && `${(topMargin - 0.01).toFixed(2)}px` || '0'
        },
        moreText: {
            deps: ['isLastAndHasMore'],
            get: isLastAndHasMore => isLastAndHasMore && _.isNumber(isLastAndHasMore)
                && `${isLastAndHasMore} photo${isLastAndHasMore > 1 ? 's' : ''} more ` || ''
        }
    },

    bindings: {
        ':el': 'css:{width:cssWidth,"padding-top":cssHeightPadding,"margin-left":cssMarginLeft,"margin-top":cssMarginTop}',
        '@ui.img': 'attr:{src:src}',
        '@ui.more': 'classes:{hidden:not(isLastAndHasMore)}',
        '@ui.more > .label': 'text:moreText'
    },

    triggers: {
        'click': 'click'
    }
})
class MessagePhotoItemView extends ItemView {
    initialize() {
        this.viewModel = new ViewModel({
            widthPercent: null,
            leftMargin: null,
            topMargin: null,
            isLastAndHasMore: false
        });
    }

    onRender() {
        this._img = this.ui.img[0];
    }

    onAttach(view) {
        if (!this.model.get('w') || !this.model.get('h')) {
            if (!this._img.complete) {
                if (!this._onImageSizeUpdatedListener) {
                    this._onImageSizeUpdatedListener = this._onImageSizeUpdated.bind(this);
                    this._img.addEventListener('load', this._onImageSizeUpdatedListener);
                }

            } else {
                this._onImageSizeUpdated();
            }
        }
    }

    onDestroy(view) {
        if (this._onImageSizeUpdatedListener) {
            this._img.removeEventListener('load', this._onImageSizeUpdatedListener);
            this._onImageSizeUpdatedListener = null;
        }
    }

    _onImageSizeUpdated() {
        if (!this.isDestroyed) {
            this.model.set({
                w: this._img.naturalWidth,
                h: this._img.naturalHeight
            });
        }
    }
};

export default MessagePhotoItemView;
