import {
    LOAD_CATEGORY,
    LOAD_CATEGORY_SUCCEEDED,
    LOAD_CATEGORY_FAILED,

    SET_LOADING,

    INIT_MENU,
    INIT_MENU_SUCCEEDED,
    INIT_MENU_FAILED, SAVE_MENU_INFO, SAVE_MENU_INFO_FAILED, DISPLAY_CATEGORY
} from "../actions";

const initialState = {
    loading: false,
    error: null,
    selectedCategoryId: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, loading: action.payload.loading };

        case INIT_MENU:
            return {
                ...state,
                selectedCategoryId: action.payload.categoryId || null,
                error: null
            };

        case INIT_MENU_SUCCEEDED:
            return {
                ...state,
                selectedCategoryId: action.payload.categoryId || state.selectedCategoryId,
                error: action.payload.error || null
            };

        case DISPLAY_CATEGORY:
            return { ...state, selectedCategoryId: action.payload.id };

        case INIT_MENU_FAILED:
        case LOAD_CATEGORY_FAILED:
        case SAVE_MENU_INFO_FAILED:
            return { ...state, error: action.payload.error };

        case SAVE_MENU_INFO:
        case LOAD_CATEGORY:
            return { ...state, error: null };

        case LOAD_CATEGORY_SUCCEEDED:
            return { ...state, selectedCategoryId: action.payload.id };

        default:
            return state
    }
}
