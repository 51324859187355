import { Model, ItemView, ViewModel } from '@b2cmessenger/backbone';
import NotificationSwitchModel from "widgets/NotificationControl/NotificationSwitchModel";

import ModalWindow from 'windows/Modal/Modal';

import template from "./NotificationSwitch.jade"
import './NotificationSwitch.scss';

const NotificationSwitch = ItemView.extend({
    options: {
        notificationMuteCollection: null,
        notificationMuteAttributes: null,
        dataModel: null,
        caption: 'Block notifications?',
        title: 'Block notifications?',
        text: null,
        invert: false,
    },

    template,
    className: "widget notification-switch-widget mute_type input-group horizontal multiline-label",

    ui: {
        caption: '[data-js-caption]',
        muteSwitch: '[data-js-mute]',
        btnHelp: '[data-js-help]',
    },

    computeds: {
        c_isMuted: {
            deps: ['muted', 'invert'],
            get: (muted, invert) => invert ? !muted: !!muted,
            set: _.throttle(function(val) {
                const invert = this.getBinding('invert');
                this.setBinding('muted', invert ? !val : !!val);
            }, 500)
        }
    },

    bindingHandlers: {
        sliderValue: {
            init($element, value) {
                $element.slider();
            },

            get($element) {
                return $element.val();
            },

            set($element, value) {
                try {
                    if ($element.val() + '' != value + '') {
                        $element.val(value);
                        $element.slider && $element.slider('instance') && $element.slider('refresh');
                    }

                } catch (error) { }
            }
        },
    },

    bindingFilters: {
        bool: {
            get: bool => String(!!bool),
            set: str => str == "true",
        },
    },

    bindings: {
        '@ui.caption': 'html:caption',
        '@ui.muteSwitch': 'sliderValue:bool(c_isMuted)',
        '@ui.btnHelp': 'classes:{hidden:not(length(text))}',
    },

    events: {
        'click @ui.btnHelp'(e) {
            this.showMessage({
                title: this.getBinding('title'),
                text: this.getBinding('text'),
                isHtml: true
            }, {
                border: 'no-border'
            });
        },
    },

    initialize() {
        if (this.options.model) {
            this.model = this.options.model;
        } else {
            this.model = new NotificationSwitchModel({}, {
                notificationMuteCollection: this.options.notificationMuteCollection,
                notificationMuteAttributes: this.options.notificationMuteAttributes,
            });
        }

        if (!this.options.caption) {
            this.options.caption = this.options.invert ? 'Receive notifications?' : 'Block notifications?';
        }

        this.viewModel = new ViewModel({
            caption: this.options.caption,
            title: this.options.title,
            text: this.options.text,
            invert: !!this.options.invert
        });
    },

    onAttach() {
        this.ui.muteSwitch.slider('refresh');
    }
});

export default NotificationSwitch;
