import React, { useState, useEffect, useMemo, useCallback } from 'react';
import InlineUser from 'components/UI/User/InlineUser';
import './MenuWaiterCallForm.scss';
import InputGroup, {
    RadioInput,
    SliderGroup,
    SwitchInput,
    TextareaInput,
    TextInputGroup,
} from 'components/UI/InputGroup/InputGroup';
import { actionMap, actionLabelMap, optionBitsMap } from 'components/menu/MenuWaiterCall/MenuWaiterCallContainer';
import { callWaiter, orderCallWaiter } from 'utils/api/menu';
import { showError, showMessage } from 'windows/Modal/Info';
import Spinner from 'components/UI/Spinner/Spinner';
import PropTypes from 'prop-types';
import Select from 'components/UI/Select';
import { Button, SendButton } from 'components/UI/Button/Button';
import qrCodeScanner from 'utils/qrCodeScanner';

const MenuWaiterCallForm = (props) => {
    const {
        order = null,
        placeId = null,
        changeOrder = _.noop,
        addModel = _.noop,
        forceOpenForm = false,
        tables = [],
        selectedTableId,
    } = props;

    // UI
    const [showHeader, setShowHeader] = useState(false);
    const [loading, setLoading] = useState(false);

    const formRef = React.useCallback(
        (ref) => {
            if (forceOpenForm && ref !== null) {
                ref.scrollIntoCenter();
            }
        },
        [forceOpenForm]
    );

    useEffect(() => {
        setShowHeader(order !== null);
    }, [order]);

    // Form
    const [comment, setComment] = useState('');
    const [tableId, setTableId] = useState(null);
    const [action, setAction] = useState(actionMap.ActionCall);
    const [usePlasticCard, setUsePlasticCard] = useState(false);

    useEffect(() => {
        setTableId(selectedTableId);
    }, [selectedTableId]);

    const actionList = useMemo(() => {
        const list = [
            {
                id: `${actionMap.ActionCall}-${actionLabelMap[actionMap.ActionCall]}`,
                label: actionLabelMap[actionMap.ActionCall],
                value: actionMap.ActionCall,
                name: 'action',
                checked: action === actionMap.ActionCall,
            },
        ];

        if (order !== null) {
            list.push({
                id: `${actionMap.ActionBill}-${actionLabelMap[actionMap.ActionBill]}`,
                label: actionLabelMap[actionMap.ActionBill],
                value: actionMap.ActionBill,
                name: 'action',
                checked: action === actionMap.ActionBill,
            });
        }
        return list;
    }, [action, order, actionMap, actionLabelMap]);

    const changeAction = (value) => {
        value = Number(value);
        setAction(value);

        if (value !== actionMap.ActionBill) {
            setUsePlasticCard(false);
        }
    };

    const clearForm = () => {
        changeAction(actionMap.ActionCall);
        setComment('');
    };

    const submitOrderCallWaiter = useCallback(
        async (comment, action, options) => {
            try {
                setLoading(true);
                const data = await orderCallWaiter(order, {
                    comment,
                    action,
                    options,
                });

                changeOrder({ id: order.id, waiterRequests: [...order.waiterRequests, { ...data }] });
                clearForm();
                showMessage('Request to the waiter was sent.');
            } catch (e) {
                showError(e instanceof Error ? e.message : e);
            } finally {
                setLoading(false);
            }
        },
        [order]
    );

    const submitCallWaiter = useCallback(
        async (comment, tableId) => {
            try {
                setLoading(true);
                const data = await callWaiter(placeId, {
                    comment,
                    tableId,
                });

                addModel(data);
                clearForm();
                showMessage('Request to the waiter was sent.');
            } catch (e) {
                showError(e instanceof Error ? e.message : e);
            } finally {
                setLoading(false);
            }
        },
        [placeId]
    );

    const submitForm = () => {
        if (order !== null) {
            submitOrderCallWaiter(comment, action, usePlasticCard ? optionBitsMap.BillPlasticCard : 0);
        } else if (tableId) {
            submitCallWaiter(comment, tableId);
        } else {
            showError('Please point the table number!', undefined, undefined, { dontSendToGaAndSentry: true });
        }
    };

    const isTableIdRequired = _.isNull(order);
    const selectTableUsingQrScanner = useCallback(async () => {
        try {
            const qrCodeModel = await qrCodeScanner();

            if (qrCodeModel) {
                const tableFromQr = qrCodeModel.get('deepLinkMenuTable') || {};

                if (placeId === tableFromQr.placeId) {
                    const table = tables.find((t) => Number(t.id) === Number(tableFromQr.tableId));
                    const tableId = table && table.id;

                    if (tableId) {
                        setTableId(tableId);
                    }
                } else {
                    showError('Table from another place');
                }
            }
        } catch (e) {
            if (e) {
/////////////////////////////
/////////////////////////////
/////////////////////////
                showError(_.isString(e) ? e : e.message);
//////////////////////////
            }
        }
    }, [placeId, tables, setTableId]);

    const tableSelectorDisabled =
////////////////////////////////
        false;
/////////////
/////////
//////////////

    const tableIdSelectOptions = tables.map((t) => ({ value: t.id, name: t.number }));
    const tableIdSelectValue = _.isNull(tableId) ? undefined : tableId;
    const tableIdSelectDefaultValue = _.isNull(tableId)
        ? tableSelectorDisabled
            ? 'Scan table'
            : 'Select table'
        : undefined;

    return (
        <div className="menu-waiter-call-form" ref={formRef}>
            {loading && <Spinner />}
            {showHeader && (
                <div className="menu-waiter-call-form__header">
                    <div className="order-waiter">
                        <span className="waiter">Call the waiter</span>
                        <span>&nbsp;</span>
                        <InlineUser {...order.waiterUser} nameFirst />
                    </div>
                </div>
            )}

            <div className="menu-waiter-call-form__form">
                {isTableIdRequired && (
                    <InputGroup label="Table number" className="input-group table-number">
                        <Select
                            disabled={tableSelectorDisabled}
                            defaultValue={tableIdSelectDefaultValue}
                            onChange={setTableId}
                            value={tableIdSelectValue}
                            options={tableIdSelectOptions}
                        />
                        <Button className="icon-qrcode" onClick={selectTableUsingQrScanner} />
                    </InputGroup>
                )}

                <TextInputGroup className="input-group description">
                    <TextareaInput
                        value={comment}
                        placeholder="Comment"
                        maxLength={255}
                        onChange={(e) => setComment(e.currentTarget.value)}
                    />
                </TextInputGroup>

                <div className="menu-waiter-call-form__form-controls">
                    <div className="menu-waiter-call-form__form_action_controls">
                        <InputGroup className="input-group">
                            <div className="radio-input">
                                <RadioInput options={actionList} onClick={(e) => changeAction(e.currentTarget.value)} />
                            </div>
                        </InputGroup>

                        {action === actionMap.ActionBill && (
                            <SliderGroup caption="Plastic card" captionFirst={false}>
                                <SwitchInput value={usePlasticCard} onChange={setUsePlasticCard} />
                            </SliderGroup>
                        )}
                    </div>

                    <SendButton onClick={submitForm}>Send</SendButton>
                </div>
            </div>
        </div>
    );
};

MenuWaiterCallForm.propTypes = {
    order: PropTypes.object,
    placeId: PropTypes.number,
    changeOrder: PropTypes.func,
    addModel: PropTypes.func,
    forceOpenForm: PropTypes.bool,
    tables: PropTypes.array,
    selectedTableId: PropTypes.number,
};

export default MenuWaiterCallForm;
