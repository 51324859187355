import React from 'react';

import { WindowContent, WindowFooter, WindowHeader } from "components/window/Window";

import Footer from "components/UI/Footer/Footer";
import KeyboardPadding from "components/KeyboardPadding";
import TextButton from "components/UI/TextButton";
import ReactWindow from 'windows/ReactWindow';
import { BackButton, Button } from "components/UI/Button/Button";
import Header from "components/UI/Header/Header";
import { OrderStatus, PlaceOrderStatusText } from 'components/menu/MenuPlaceOrder/MenuPlaceOrder';
import './MenuOrderStatusChange.scss';
import ConfirmModalWindow from "windows/Modal/Confirm";

const NextPossibleStatusValuesByStatusId = {
    [OrderStatus.Created]: [
        OrderStatus.Created,
        OrderStatus.Confirmed,
        OrderStatus.Canceled
    ],
    [OrderStatus.Confirmed]: [
        OrderStatus.Confirmed,
        OrderStatus.Done,
        OrderStatus.Canceled
    ]
};

export const OrderStatusIcon = {
    [OrderStatus.Created]: 'ion-md-time',
    [OrderStatus.Confirmed]: 'ion-ios-checkmark-circle-outline',
    [OrderStatus.Done]: 'ion-ios-checkmark-circle',
    [OrderStatus.Canceled]: 'ion-ios-close-circle-outline',
};

const MenuOrderStatusButtons = ({ status, onClick }) => {
    const buttons = NextPossibleStatusValuesByStatusId.hasOwnProperty(status) ?
        NextPossibleStatusValuesByStatusId[status] :
        [OrderStatus.Created, OrderStatus.Confirmed, OrderStatus.Done, OrderStatus.Canceled];

    const buttonsList = buttons.map(value => {
        const isCurrentStatus = Number(value) === Number(status);
        const className = ['btn-big', 'status-button', OrderStatusIcon[value], isCurrentStatus ? 'selected' : null]
            .filter(Boolean).join(' ');

        return (
            <Button className={className}
                    disabled={isCurrentStatus}
                    key={value}
                    onClick={() => onClick(value)}>
                <span>{PlaceOrderStatusText[value]}</span>
            </Button>
        );
    });

    return (
        <div className='status-buttons'>
            {buttonsList}
        </div>
    )
};

const MenuOrderStatusChange = (props) => {
    const { order } = props;
    const { order_number, status } = order;
    const onClick = React.useCallback(async (status) => {
        if (status === OrderStatus.Done || status === OrderStatus.Canceled) {
            const confirm = await new ConfirmModalWindow({
                title: OrderStatus.Done === status ? 'Finish this order?' : 'Cancel this order?' 
            }).show();

            if (confirm) {
                props.closeWindow({ status, order });
            }
        } else {
            props.closeWindow({ status, order });
        }
    }, [order]);

    return (
        <>
            <WindowHeader>
                <Header>
                    <BackButton onClick={() => props.closeWindow()}/>
                    <h1 className="title">{`Change status | Order ${order_number}`}</h1>
                </Header>
            </WindowHeader>
            <WindowContent>
                <MenuOrderStatusButtons status={status} onClick={onClick}/>
            </WindowContent>
            <WindowFooter>
                <Footer>
                    <TextButton onClick={() => props.closeWindow()}>CLOSE</TextButton>
                </Footer>
            </WindowFooter>
            <KeyboardPadding/>
        </>
    );
};

const MenuOrderStatusChangeWindow = ReactWindow.extend({
    constructor: function MenuOrderStatusChangeWindow(props) {
        const options = {
            props,
            component: MenuOrderStatusChange,
            name: 'MenuOrderStatusChange'
        };

        return ReactWindow.apply(this, [options]);
    }
});

export default MenuOrderStatusChangeWindow;
