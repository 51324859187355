var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (B2CPlace, Number, logo, logoThumb, logoThumb_local, logo_local, name, ratings) {pug_html = pug_html + "\u003Cdiv" + (" class=\"logo\""+pug.attr("style", pug.style("background-image:url("+(logoThumb_local || logo_local || logoThumb || logo || B2CPlace.const.default_thumb)+")"), true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"content\"\u003E\u003Cp class=\"name\"\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003Cp class=\"address\" data-js-address\u003E\u003C\u002Fp\u003E\u003Cp" + (" class=\"rating b2crating\""+pug.attr("data-rating", ratings.t.r, true, true)) + "\u003E";
// iterate [1, 2, 3, 4, 5]
;(function(){
  var $$obj = [1, 2, 3, 4, 5];
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var val = $$obj[pug_index0];
if (Number(ratings.t.r) >= val) {
pug_html = pug_html + "\u003Cspan class=\"star set\"\u003E\u003C\u002Fspan\u003E";
}
else
if (Number(ratings.t.r) >= val - 0.5) {
pug_html = pug_html + "\u003Cspan class=\"star semi\"\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"star\"\u003E\u003C\u002Fspan\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var val = $$obj[pug_index0];
if (Number(ratings.t.r) >= val) {
pug_html = pug_html + "\u003Cspan class=\"star set\"\u003E\u003C\u002Fspan\u003E";
}
else
if (Number(ratings.t.r) >= val - 0.5) {
pug_html = pug_html + "\u003Cspan class=\"star semi\"\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"star\"\u003E\u003C\u002Fspan\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"direction\" data-js-direction\u003E\u003Cp class=\"ion-ios-flag-outline\"\u003E \u003C\u002Fp\u003E\u003Cp class=\"distance\" data-js-distance\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";}.call(this,"B2CPlace" in locals_for_with?locals_for_with.B2CPlace:typeof B2CPlace!=="undefined"?B2CPlace:undefined,"Number" in locals_for_with?locals_for_with.Number:typeof Number!=="undefined"?Number:undefined,"logo" in locals_for_with?locals_for_with.logo:typeof logo!=="undefined"?logo:undefined,"logoThumb" in locals_for_with?locals_for_with.logoThumb:typeof logoThumb!=="undefined"?logoThumb:undefined,"logoThumb_local" in locals_for_with?locals_for_with.logoThumb_local:typeof logoThumb_local!=="undefined"?logoThumb_local:undefined,"logo_local" in locals_for_with?locals_for_with.logo_local:typeof logo_local!=="undefined"?logo_local:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined,"ratings" in locals_for_with?locals_for_with.ratings:typeof ratings!=="undefined"?ratings:undefined));;return pug_html;};
module.exports = template;