import _ from 'underscore';
import { Marionette } from '@b2cmessenger/backbone';

import PlaceItemWidget from 'widgets/PlaceItem/PlaceItem';

import './Map.scss';
import markerIcon from 'img/markericon.png';
import markerIconActive from 'img/markeractiveicon.png';
import userMarkerIcon from 'img/usermarkericon.png';

import getNextPrettyColor from 'utils/randomPrettyColor';

const MIN_DELTA = 0.0001,
    DEFAULT_ZOOM = 14;

const MapView = Marionette.CompositeView.extend({
    options: {
        maxMarkers: 50
    },

    tagName: 'div',
    className: "map-widget",
    childView: PlaceItemWidget,
    reorderOnSort: true,

    getViewComparator() {
        const bounds = this.map && this.map.getBounds();

        if (bounds) {
            return m => {
                if (bounds.contains({ lat: Number(m.get('adr_latitude')) || 0, lng: Number(m.get('adr_longitude')) || 0 })) {
                    return 0;
                } else {
                    return 1;
                }
            };
        }
    },

    filter(child, index, collection) {
        return index < this.options.maxMarkers;
    },

    initialize() {
        this.markers = {};
        this.model.set({
            overload: false
        });

        this.listenTo(this.model, 'change:lat change:lng', _.debounce(this.onModelLatOrLngChange.bind(this)));
        this.listenTo(this.model, 'change:userLat change:userLng', this.renderUserMarker);
        this.listenTo(this.model, 'change:overload', (m, overload) => {
            this.resortView();

            if (overload) {
                this.el && this.el.classList.add('overloaded');
            } else {
                this.el && this.el.classList.remove('overloaded')
            }
        });
        this.listenTo(this.collection, 'reset', _.debounce(c => {
            c.length && this.refresh(true)
        }));
    },

    activate(model) {
        const marker = this.markers[model.cid];
        if(marker && !marker.isOpen) {
            google.maps.event.trigger(marker, 'click');
        }
    },

    onModelLatOrLngChange(model, v, o, no_delta) {
        if(this.map) {
            const lat = model.get('lat') || 0,
                lng = model.get('lng') || 0;

            this.map.setCenter(new google.maps.LatLng(lat, lng));
            this.refreshZoom();
        }
    },

    renderUserMarker() {
        if(!this.map) return;

        if(this.model.has('userLat') && this.model.has('userLng')) {
            if(!this.userMarker) {
                this.userMarker = new google.maps.Marker({
                    position: {
                        lat: Number(this.model.get('userLat')), 
                        lng: Number(this.model.get('userLng'))
                    },
                    map: this.map,
                    icon: {
                        url: userMarkerIcon,
                        size: new google.maps.Size(123, 123),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(10.25, 10.25),
                        scaledSize: new google.maps.Size(20.5, 20.5)
                    }
                });
            } else {
                this.userMarker.setPosition({
                    lat: Number(this.model.get('userLat')), 
                    lng: Number(this.model.get('userLng'))
                });
            }
        } else if(this.userMarker) {
            this.userMarker.setMap(null);
            delete this.userMarker;
        }
    },

    render() {
        this._ensureViewIsIntact();
        this._isRendering = true;
        this.resetChildViewContainer();
  
        this.triggerMethod('before:render', this);

        if (initGoogleMap.inited) {
            if (!this.map) {
                this.map = new google.maps.Map(this.el, {
                    zoom: this.model.has('lat') && this.model.has('lng') ? DEFAULT_ZOOM : 2,
                    center: new google.maps.LatLng(this.model.get('lat') || 0, this.model.get('lng') || 0),
                    clickableIcons: false,
                    fullscreenControl: false,
                    keyboardShortcuts: false,
                    rotateControl: false,
                    streetViewControl: false,
                    zoomControl: true,
                    mapTypeControl: true
                });

                this.model.set({ notOpened: true });

                google.maps.event.addListener(this.map, 'bounds_changed', () => this.trigger('map:bounds_changed'));
                google.maps.event.addListener(this.map, 'resize', () => {
                    this.map.setZoom(this.map.getZoom());
                    const lat = this.model.get('lat'),
                        lng = this.model.get('lng');

                    if (!_.isNull(lat) && !_.isUndefined(lat) && !_.isNull(lng) && !_.isUndefined(lng)) {
                        this.map.setCenter(new google.maps.LatLng(lat, lng));
                    }
                });

                _.defer(() => this.map && google.maps.event.trigger(this.map, 'resize'));
            } else {
                //this.map.setCenter(new google.maps.LatLng(this.model.get('lat') || 0, this.model.get('lng') || 0));
            }

            this.renderUserMarker();
            this._renderChildren();

        } else {
            $(document).on(initGoogleMap.event_map_inited, this.render.bind(this));
        }

        this._isRendering = false;
        this.isRendered = true;
        this.triggerMethod('render', this);

        return this;
    },

    resetMapCenterToModel() {
        const lat = this.model.get('lat'),
            lng = this.model.get('lng');

        if (!_.isNull(lat) && !_.isUndefined(lat) && !_.isNull(lng) && !_.isUndefined(lng)) {
            this.map.setCenter(new google.maps.LatLng(lat, lng));
        }
    },

    refresh(reset) {
        if (this.isRendered && this.map) {
            const lat = this.model.get('lat'),
                lng = this.model.get('lng');

            if (!_.isNull(lat) && !_.isUndefined(lat) && !_.isNull(lng) && !_.isUndefined(lng)) {
                this.map.setCenter(new google.maps.LatLng(lat, lng));

                _.defer(() => {
                    if (this.map) {
                        google.maps.event.trigger(this.map, 'resize');

                        this.resetMapCenterToModel();

                        this.refreshZoom(!reset);
                    }
                });
            } else if (reset) {
                if (this.collection.length > 1) {
                    const first = this.collection.first(),
                        last = this.collection.last();

                    if (first.has('adr_latitude') && first.has('adr_longitude')
                        && last.has('adr_latitude') && last.has('adr_longitude')
                    ) {
                        const bounds = new google.maps.LatLngBounds;
                        bounds.extend({ lat: Number(first.get('adr_latitude')), lng: Number(first.get('adr_longitude')) });
                        bounds.extend({ lat: Number(last.get('adr_latitude')), lng: Number(last.get('adr_longitude')) });

                        if (bounds.toSpan().lat()) {
                            this.map.fitBounds(bounds, 20);

                            _.defer(() => {
                                if (this.map) {
                                    google.maps.event.trigger(this.map, 'resize');
                                }
                            });

                            return;
                        }
                    }
                }

                if (this.collection.length > 1) {
                    const first = this.collection.find(m => m.has('adr_latitude') && m.has('adr_longitude'));

                    if (first) {
                        this.map.setZoom(DEFAULT_ZOOM);
                        this.map.panTo({ lat: Number(first.get('adr_latitude')), lng: Number(first.get('adr_longitude')) });

                        _.defer(() => {
                            if (this.map) {
                                google.maps.event.trigger(this.map, 'resize');

                                this.map.panTo({ lat: Number(first.get('adr_latitude')), lng: Number(first.get('adr_longitude')) });
                            }
                        });

                        return;
                    }
                }

                _.defer(() => {
                    if (this.map) {
                        google.maps.event.trigger(this.map, 'resize');
                    }
                });
            } else {
                _.defer(() => {
                    if (this.map) {
                        google.maps.event.trigger(this.map, 'resize');
                    }
                });
            }
        }
    },

    refreshZoom(onlyIfNecessary) {
        if (this.isRendered) {
            let zoom = DEFAULT_ZOOM;

            if (this.children && this.children.length && this.el.offsetWidth && this.el.offsetHeight) {
                const m = this.collection.first();
                if (m.get('adr_latitude') && m.get('adr_longitude')) {
                    zoom = this._calculateZoomForCenterAndPoint(
                        { lat: Number(m.get('adr_latitude')), lng: Number(m.get('adr_longitude')) },
                        { lat: this.map.getCenter().lat(), lng: this.map.getCenter().lng() },
                        this.el.offsetWidth,
                        this.el.offsetHeight
                    );
                }
            }

            if (!onlyIfNecessary || zoom < this.map.getZoom()) {
                this.map.setZoom(zoom);
            }
        }
    },

    renderChildView(view, index) {
        this.attachHtml(this, view, index);
        return view;
    },

    attachHtml(collectionView, itemView, index) {
        if (window.google && google.maps) {
            const markerImage = {
                url: markerIcon,
                anchor: new google.maps.Point(9.65, 26.54),
                scaledSize: new google.maps.Size(19.3, 27.34)
            };

            const markerImageActive = {
                url: markerIconActive,
                anchor: new google.maps.Point(13.75, 32.25),
                scaledSize: new google.maps.Size(27.5, 43)
            }

            if (this.markers[itemView.model.cid]) {
                const marker = this.markers[itemView.model.cid];
                marker.setPosition({
                    lat: Number(itemView.model.get('adr_latitude')),
                    lng: Number(itemView.model.get('adr_longitude'))
                });
            } else if (this.map) {
                var marker = new google.maps.Marker({
                    position: {
                        lat: Number(itemView.model.get('adr_latitude')),
                        lng: Number(itemView.model.get('adr_longitude'))
                    },
                    map: this.map,
                    icon: markerImage
                });

                marker.addListener('click', () => {
                    if (marker.isOpen) {
                        marker.isOpen = false;
                        marker.setIcon(markerImage);
                        marker.infoWindow && marker.infoWindow.close();
                        delete marker.infoWindow;
                    } else {
                        marker.isOpen = true;
                        marker.setIcon(markerImageActive);

                        const content = new PlaceItemWidget({ model: itemView.model });
                        this.listenTo(content, 'select', () => {
                            this.trigger('childview:select', content);
                        });
                        content.render();

                        const infoWindow = new google.maps.InfoWindow({
                            content: content.el,
                            pixelOffset: new google.maps.Size(0, 8)
                        });

                        infoWindow.setContent(content.el);

                        infoWindow.addListener('domready', function () {
                            let $iw = $(this.content).parent().parent();
                            let $iwBack = $iw.prev().addClass('gm-style-back');

                            $iwBack.children(':nth-child(1)').addClass('gm-style-arrow-shadow');
                            $iwBack.children(':nth-child(2)').addClass('gm-style-back-shadow');
                            $iwBack.children(':nth-child(3)').addClass('gm-style-arrow');
                            $iwBack.children(':nth-child(4)').addClass('gm-style-back-white');

                            let $iwClose = $iw.next().addClass('gm-style-close');
                        });

                        infoWindow.addListener('closeclick', () => {
                            marker.isOpen = false;
                            marker.setIcon(markerImage);
                        });

                        infoWindow.setPosition(marker.getPosition());
                        infoWindow.open(this.map);

                        marker.infoWindow = infoWindow;

                        _.values(this.markers)
                            .filter(other => other !== marker && other.isOpen)
                            .forEach(other => {
                                other.isOpen = false;
                                other.setIcon(markerImage);
                                other.infoWindow.close();
                            });
                    }
                });

                this.markers[itemView.model.cid] = marker;
            }
        }
    },

    removeChildView: function(view) {
        if(view && view.model && view.model.cid) {
            let marker = this.markers[view.model.cid];
            if (marker) {
                if (marker.infoWindow) {
                    marker.infoWindow.close();
                    delete marker.infoWindow;
                }
                marker.setMap(null);
                delete this.markers[view.model.cid];
            }
        }

        return Marionette.CompositeView.prototype.removeChildView.apply(this, arguments);
    },

    _calculateZoomForCenterAndPoint(center, point, mapWidth, mapHeight) {
        function rad(l) {
            const sin = Math.sin(l * Math.PI / 180),
                rad = Math.log((1 + sin) / (1 - sin)) / 2;

            return Math.max(Math.min(rad, Math.PI), -Math.PI) / 2;
        }

        function calculateZoom(map, world, fraction) {
            return Math.floor(Math.log(map / world / fraction) / Math.LN2);
        }

        const bounds = new google.maps.LatLngBounds;
        bounds.extend(center);
        bounds.extend(point);

        const ne = bounds.getNorthEast(),
            sw = bounds.getSouthWest(),
            latFraction = (rad(ne.lat()) - rad(sw.lat())) / Math.PI,
            deltaLng = ne.lng() - sw.lng(),
            lngFraction = ((deltaLng < 0) ? (deltaLng + 360) : deltaLng) / 360;

        return Math.min(
            Math.max(0, calculateZoom(mapHeight, 256, latFraction) - 1),
            Math.max(0, calculateZoom(mapWidth, 256, lngFraction) - 1),
            DEFAULT_ZOOM
        );
    }
 });

export default MapView;
