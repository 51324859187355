import _ from 'underscore';

import Window from 'windows/Window';
import HeaderView from 'widgets/Header/Header';
import FooterView from 'widgets/Footer/Footer';
import InfoModal from 'windows/Modal/Info';
import ConfirmModal from 'windows/Modal/Confirm';

import EvidenceModel from 'models/EvidenceModel';
import EvidenceEditorWidget from 'widgets/EvidenceEditor/EvidenceEditor';

import './EvidenceEditor.scss';

let EvidenceEditor = Window.extend({
    windowName: "evidence-editor-window",
    className: "evidence-editor-window",

    initialize() {
        if(!this.model) {
            if(!this.options.place_id) throw new Error('options have no place_id');
            this.model = new EvidenceModel({ 
                place_id: this.options.place_id,
                type: _(EvidenceModel.Type).find(t => t == this.options.type)
            });
        }
    },

    onRender() {
        let headerView = new HeaderView({ 
            leftButtons: ['back'],
            title: this.model.get('type') == EvidenceModel.Type.Selfie ? "Add your selfie" : 
                this.model.get('type') == EvidenceModel.Type.Receipt ? "Add your receipt" :
                "Add your photo evidence",
            rightButtons: ['images', 'save']
        });

        headerView.listenTo(this.model, 'change:photos', (m, photos) => {
            headerView.ui.btnsave.prop('disabled', !photos.length);
        });

        this.listenTo(headerView, 'back:click', view => this.cancel());
        this.listenTo(headerView, 'images:click', view => {
            const type = this.model.get('type'),
                place = { id: this.model.get('place_id') };

            this.cancel()
                .then(isClosed => isClosed && app.controller.goToEvidencesPage({
                    place, type
                }));
        });

        this.listenTo(headerView, 'save:click', view => this.save());

        this.header.show(headerView);
        headerView.ui.btnsave.prop('disabled', !this.model.get('hasPhotos'));

        let footerView = new FooterView({
            buttons: [{
                id: "cancel",
                text: "CANCEL",
                icn: "empty"
            },{
                id: "save",
                text: "SAVE",
                icn: "empty"
            }]
        });

        footerView.listenTo(this.model, 'change:photos', (m, photos) => {
            footerView.ui.btnsave.prop('disabled', !photos.length);
        });

        this.listenTo(footerView, 'cancel:click', view => this.cancel());
        this.listenTo(footerView, 'save:click', view => this.save());

        this.footer.show(footerView);
        footerView.ui.btnsave.prop('disabled', !this.model.get('hasPhotos'));
        this.content.show(new EvidenceEditorWidget({ model: this.model }));
    },

    cancel() {
        if (this.model.get('isDraft') && this.model.get('hasPhotos')) {
            return new ConfirmModal({ message: "Cancel editing? All changes will be lost!" })
                .show()
                .then(confirm => {
                    if (confirm) {
                        this.model.destroy();
                        this.close();

                        return true;
                    } else {
                        return false;
                    }
                });
        } else {
            this.model.destroy();
            this.close();

            return Promise.resolve(true);
        }
    },

    save() {
        this.showLoading();
        this.model.set('status', EvidenceModel.Status.Active);
        this.model.save({
            users: {
                [LoginedUserHandler.getLoginedUser().id]: LoginedUserHandler.getLoginedUser()
            }
        }, {
            error: (model, responce, options) => {
                this.hideLoading();
                this.showError(responce, options.textStatus, options.errorThrown);
            },
            success: () => {
                this.hideLoading();
                new InfoModal({
                    additionalClassName: 'green-border',
                    title: "You are verified in this place!\nRemember: business will validate your evidence"
                }).show()
                    .then(() => {
                        this.close(this.model)
                    });

            }
        });
    }
});

export default EvidenceEditor;