import React from 'react';
import './MenuTable.scss';

export const getTableListItemClassName = (item, ...classNames) => {
    return ['menu-table', ...classNames].filter(Boolean).join(' ');
};

export const MenuTableContent = (props) => {
    return (
        <>
            <div className="content">
                <div className="name">
                    Table 
                    {' '}
                    <span>{props.number}</span>
                </div>
            </div>
        </>
    );
};

export const MenuTableBase = (props) => {
    return (
        <div className={getTableListItemClassName(props, props.className)} onClick={props.onClick}>
            <MenuTableContent id={props.id} number={props.number} qrString={props.qr_string} />
            {props.children}
        </div>
    );
};

const MenuTable = (props) => {
    const { className, onClick, ...restProps } = props;

    return (
        <MenuTableBase {...restProps} className={className} onClick={onClick}>
            {props.children}
        </MenuTableBase>
    );
};

export default MenuTable;
