import React from 'react';
import TextButton from 'components/UI/TextButton';
import './MenuPlaceOrderActions.scss';

const MenuPlaceOrderActions = (props) => {
    return <div className={['order-actions', props.className].filter(Boolean).join(' ')}>{props.children}</div>;
};

export const OrderActionButton = (props) => {
    return (
        <TextButton
            className={['border-filled', props.className].filter(Boolean).join(' ')}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            {props.children}
        </TextButton>
    );
};

export default MenuPlaceOrderActions;
