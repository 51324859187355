/**
 * Created by Alex on 29.03.2016.
 */
CategoryBrowserStorage = {
    cat_base : null,
    parent_to_child : null,

    init: function () {
        this.init_base_in_memory();
    },

    init_base_in_memory: function () {
        if (window.PROFILE)
            console.time('CategoryBrowserStorage.init_base_in_memory');

        if (!window.categoryCollection)
            window.categoryCollection = new CategoryCollection();

        if (this.cat_base != null) {
            if (window.PROFILE)
                console.timeEnd('CategoryBrowserStorage.init_base_in_memory');
            return true;
        }

        if (localStorage.categories_cat_bas == null) {
            if (window.PROFILE)
                console.timeEnd('CategoryBrowserStorage.init_base_in_memory');
            return false;
        }

        this.cat_base = JSON.parse(localStorage.categories_cat_bas);
        this.parent_to_child = JSON.parse(localStorage.categories_parent_to_child);

        window.categoryCollection.reset(_(this.cat_base).values());
        window.categoryCollection.each(function (c) {
            var parent_id = c.get('parent_id');
            if (parent_id && parent_id != 1) {
                var parent = window.categoryCollection.get(parent_id);
                if (parent) {
                    c.set({ parent: parent });
                    var children = parent.get('children');
                    if (!children) {
                        children = new CategoryCollection();
                        parent.set({ children: children });
                    }
                    children.add(c);
                }
            }
        });

        if (window.PROFILE)
            console.timeEnd('CategoryBrowserStorage.init_base_in_memory');

        return true;
    },

    init_local_storage : function(server_cats) {
        if (localStorage.categories_cat_bas == null)
            this.init_firsttime_local_storage(server_cats);
        else
            this.init_delta_local_storage(server_cats);

        var event = new Event("b2c_cats_base_inited");
        document.dispatchEvent(event);
    },

    init_firsttime_local_storage: function (server_cats) {
        if (window.PROFILE)
            console.time('CategoryBrowserStorage.init_firsttime_local_storage');

        this.cat_base = {};
        this.parent_to_child = {};
        var cat;
        for(var i=0; i<server_cats.length; i++) {
            cat = server_cats[i];
            this.cat_base[cat.id] = cat;
            if (this.parent_to_child[cat.parent_id] == null)
                this.parent_to_child[cat.parent_id] = [cat.id];
            else
                this.parent_to_child[cat.parent_id].push(cat.id);
        }
        this.save_memory_base_to_local_storage();

        window.categoryCollection.reset(_(this.cat_base).values());
        window.categoryCollection.each(function (c) {
            var parent_id = c.get('parent_id');
            if (parent_id && parent_id != 1) {
                var parent = window.categoryCollection.get(parent_id);
                if (parent) {
                    c.set({ parent: parent });
                    var children = parent.get('children');
                    if (!children) {
                        children = new CategoryCollection();
                        parent.set({ children: children });
                    }
                    children.add(c);
                }
            }
        });

        if (window.PROFILE)
            console.timeEnd('CategoryBrowserStorage.init_firsttime_local_storage');
    },

    init_delta_local_storage: function (server_cats) {
        if (window.PROFILE)
            console.time('CategoryBrowserStorage.init_delta_local_storage');

        this.init_base_in_memory();

        if (server_cats == null || server_cats.length == 0) {
            if (window.PROFILE)
                console.timeEnd('CategoryBrowserStorage.init_delta_local_storage');
            return;
        }

        var old_cat, new_cat, parent_to_child;
        for(var i=0; i<server_cats.length; i++) {
            new_cat = server_cats[i];
            old_cat = this.cat_base[new_cat.id];
            if(old_cat != null) {
                if (old_cat.parent_id != new_cat.parent_id) {
                    parent_to_child = this.parent_to_child[old_cat.parent_id];
                    parent_to_child.splice(parent_to_child.indexOf(old_cat.id), 1);

                    this.cat_base[new_cat.id] = new_cat;
                    if (this.parent_to_child[new_cat.parent_id] == null)
                        this.parent_to_child[new_cat.parent_id] = [new_cat.id];
                    else
                        this.parent_to_child[new_cat.parent_id].push(new_cat.id);
                }
            } else {
                this.cat_base[new_cat.id] = new_cat;
                if (this.parent_to_child[new_cat.parent_id] == null)
                    this.parent_to_child[new_cat.parent_id] = [new_cat.id];
                else
                    this.parent_to_child[new_cat.parent_id].push(new_cat.id);
            }

            this.cat_base[new_cat.id] = new_cat;
        }
        this.save_memory_base_to_local_storage();

        window.categoryCollection.reset(_(this.cat_base).values());
        window.categoryCollection.each(function (c) {
            var parent_id = c.get('parent_id');
            if (parent_id && parent_id != 1) {
                var parent = window.categoryCollection.get(parent_id);
                if (parent) {
                    c.set({ parent: parent });
                    var children = parent.get('children');
                    if (!children) {
                        children = new CategoryCollection();
                        parent.set({ children: children });
                    }
                    children.add(c);
                }
            }
        });

        if (window.PROFILE)
            console.timeEnd('CategoryBrowserStorage.init_delta_local_storage');
    },
    save_memory_base_to_local_storage : function() {
        localStorage.categories_cat_bas = JSON.stringify(this.cat_base);
        localStorage.categories_parent_to_child = JSON.stringify(this.parent_to_child);
    },

    /**
     * Возвращает всю базу категорий в виде ассоциативного массива
     */
    get_all_cars_as_assoc : function() {
        this.init_base_in_memory();
        return this.cat_base;
    },
    getCats: function (parentid) {
        var ids = this.getCatsIds(parentid);
        return this.getCatsByIds(ids);
    },
    getCatsByParentIds: function (parentids) {
        var ids = this.getCatsIdsByParentIds(parentids);
        return this.getCatsByIds(ids);
    },
    getCatsIds: function (parentid) {
        if (parentid == null)
            return this.parent_to_child[1];
        else {
            var ids = this.parent_to_child[parentid];
            if (ids == null)
                ids = [];
            return ids;
        }
    },
    getCatsIdsByParentIds: function (parentids) {
        if (parentids == null)
            return this.parent_to_child[1];
        else {
            var result = [];
            for(var i=0; i<parentids.length; i++) {
                var catsids = this.parent_to_child[parentids[i]];
                for(var j=0; j<catsids.length; j++) {
                    var catid = catsids[j];
                    if(result.indexOf(catid)<0)
                        result.push(catid);
                }
            }
            return result;
        }
    },
    getCat: function (id) {
        return this.cat_base[id];
    },
    getCatsByIds: function (ids) {
        var result = [];
        for (var i = 0; i < ids.length; i++) {
            var cat = this.cat_base[ids[i]];
            if (cat != null)
                result.push(cat);
        }
        return result;
    },
    getParentIds: function (id) {
        var cat = this.cat_base[id];
        if (cat.parent_id != 1 && cat.parent_id != null)
            return [cat.parent_id];
        else
            return [];
    },
    getParentIdsForIds: function (ids) {
        var result = [];
        for (var i=0; i<ids.length; i++) {
            var parentids = this.getParentIds(ids[i]);
            result = B2Cjs.array_merge(result, parentids);
        }
        return result;
    },
    getAncestorIds : function (ids) {
        return this._collcect_AncestorIds(ids, []);
    },
    getAncestorCats : function (ids) {
        return this.getCatsByIds(this._collcect_AncestorIds(ids, []));
    },
    getHierarchyIds : function(ids) {
        return B2Cjs.array_merge(ids, this.getAncestorIds(ids));
    },
    getHierarchyCats : function(ids) {
        return this.getCatsByIds(B2Cjs.array_merge(ids, this.getAncestorIds(ids)));
    },
    _collcect_AncestorIds : function (ids, result) {
        var parentids = this.getParentIdsForIds(ids);
        if(parentids.length == 0) {
            return result;
        } else {
            result = B2Cjs.array_merge(result, parentids);
            return this._collcect_AncestorIds(parentids, result);
        }
    },

    generate_css_row : function(cat) {
        var html = '';
        for(var field in cat) {
            html += cat[field] + ';';
        }
        var last_slash_index = cat.path.lastIndexOf("/");
        var my_alias;
        if (last_slash_index < 0)
            my_alias = cat.path;
        else
            my_alias = cat.path.substr(last_slash_index+1);

        my_alias += '\n';
        return html + my_alias;
    },
    generate_css_rows : function() {
        var html = '';
        for(var cat_id in this.cat_base) {
            html += this.generate_css_row(this.cat_base[cat_id]);
        }
        return html;
    },
    generate_css_header_by_cat : function(cat) {
        var html = '';
        for(var field in cat) {
            html += field + ';';
        }
        return html + 'my_alias'+'\n';
    },
    generate_css_header : function() {
        for(var cat_id in this.cat_base) {
            return this.generate_css_header_by_cat(this.cat_base[cat_id]);
        }
    },
    generate_css : function() {
        return this.generate_css_header() + this.generate_css_rows();
    }
}

window.CategoryBrowserStorage = CategoryBrowserStorage; // TODO: remove globals, use module exports
// export default CategoryBrowserStorage;
