const GoogleAnalyticsFirebaseProxy = {
    init() {
        return new Promise((resolve, reject) => {
            if (window.FirebasePlugin) {
                resolve(this);
            } else {
                reject(`FirebasePlugin not installed`);
            }
        });
    },

    send(eventName, options) {
        console.log('send GoogleAnalyticsFirebaseProxy: ', arguments);

        window.FirebasePlugin.logEvent(eventName, options);
    },

    set(key, value) {
        console.log('set GoogleAnalyticsFirebaseProxy: ', arguments);

        window.FirebasePlugin.setUserProperty(key, value);
    },

    setUserId(userId) {
        window.FirebasePlugin.setUserId(userId);
    },

    trackView(screen) {
        window.FirebasePlugin.setScreenName(screen);
    },
};

export default GoogleAnalyticsFirebaseProxy;
