/**
 * Created by Alex on 19.05.2016.
 */
var googlePlace = {
    CONST_MIN_BETWEEN_OPEN : 10, // Разрешенное количество минут между фильтром по времени, в которое заведение открыто и текущим временм
    goo_cat_to_b2c_cat_id : null, // ассоциативный массив вида {google_place_cat_alias : b2c_cat_id }
    b2c_cat_id_to_goo_cat : null, // ассоциативный массив вида {b2c_cat_id : google_place_cat_alias }

    /**
     * Возвращает ассоциативный массив {b2c_cat_id : alias_google_place}, состоящий только из категорий из @cats_ids
     * @param cats_ids - array of cat_ids - массив идентификаторов b2c категорий
     * @returns {*}
     */
    b2c_cats_ids_to_goo_cats : function(cats_ids) {
        this.mapping_init_from_storage();

        if (cats_ids == null)
            return null;
        if (this.b2c_cat_id_to_goo_cat == null) {
            console.log('b2c_cat_id_to_goo_cat is null');
            return null;
        }

        var result = {};
        for(var i= 0, cat_id; i<cats_ids.length; i++) {
            cat_id = cats_ids[i];
            if(this.b2c_cat_id_to_goo_cat[cat_id])
                result[cat_id] = this.b2c_cat_id_to_goo_cat[cat_id];
        }

        return result;
    },

    /**
     * Возвращает ассоциативный массив {goo_cats : b2c_cat_id}, состоящий только из категорий из @goo_cats
     * @param goo_cats - array of goo_cat - массив алиасов google place category
     * @returns {*}
     */
    goo_cats_to_b2c_cats_ids : function(goo_cats) {
        this.mapping_init_from_storage();

        if (goo_cats == null)
            return null;
        if (this.goo_cat_to_b2c_cat_id == null) {
            console.log('goo_cat_to_b2c_cat_id is null');
            return null;
        }

        var result = {};
        for(var i= 0, goo_cat; i<goo_cats.length; i++) {
            goo_cat = goo_cats[i];
            result[goo_cat] = this.goo_cat_to_b2c_cat_id[goo_cat];
        }

        return result;
    },

    /**
     * Строит фильтр для запроса поиска мест через сервис Google на основании фильтра, который используется для поиска мест в B2C Messenger
     * Если возвращает null, то filter невозможно создать. Такое, например, может произойти в случае, если b2c_filter.favorites == true
     * @param b2c_filter
     */
    create_goo_place_search_filter : function(b2c_filter) {
        var result = {};

        if (google == null || google.maps == null)
            return null; // google map js не проинициализирован
        if (b2c_filter.favorites)
            return null; // Google не знает любимых мест нашего пользователя
        if (b2c_filter.minrating > 0)
            return null; // Google не знает нашего рейтинга мест
        if (b2c_filter.open) {
            var d = new Date();
            if(b2c_filter.open.d == d.getDay()) {
                var filter_day = new Date();
                filter_day.setHours(b2c_filter.open.h);
                filter_day.setMinutes(b2c_filter.open.m);
                if (this.CONST_MIN_BETWEEN_OPEN > (Math.abs(B2Cjs.date_diff_in_minutes(filter_day, d)))) {
                    result.openNow = true; // Ищем открытые сейчас заведения
                } else {
                    return null; // Google не может искать по открытости в заданное время, только по откртости в текущий момент
                }
            } else {
                return null; // Google не может искать по открытости в заданное время, только по откртости в текущий момент
            }
        }

        result.name = b2c_filter.name;

        if (b2c_filter.categories != null && b2c_filter.categories.length > 0) {
            var goo_cats_assoc = this.b2c_cats_ids_to_goo_cats(b2c_filter.categories);
            var goo_cats = [];
            for(var b2c_cat_id in goo_cats_assoc)
                goo_cats.push(goo_cats_assoc[b2c_cat_id]);
            if (goo_cats.length == 0)
                return null; // Не смогли промаппить ниодну категорию. Поиск невозможен

            result.types = goo_cats;
        }


        if (b2c_filter.point_lat != null) {
            result.location = new google.maps.LatLng(b2c_filter.point_lat, b2c_filter.point_long);
            var maxdistance = b2c_filter.maxdistance ? b2c_filter.maxdistance : 50;
            result.radius = Math.round(1609.34*maxdistance); // у нас максимальная дистанция в милях. Google принимает в метрах
        } else if (b2c_filter.corners != null && b2c_filter.corners.nw != null && b2c_filter.corners.nw.lt != null) {
            var sw = new google.maps.LatLng(b2c_filter.corners.se.lt, b2c_filter.corners.nw.lg);
            var ne = new google.maps.LatLng(b2c_filter.corners.nw.lt, b2c_filter.corners.se.lg);
            result.bounds = new google.maps.LatLngBounds(sw, ne);
        } else {
            return null; // Google без локейшена не ищет
        }


        if ( result.bounds == null &&
            (
                result.types != null ||
                (result.name != null && result.name != '')
            )
        ) {
            result.rankBy = google.maps.places.RankBy.DISTANCE;
            result.radius = null;
        }


        return result;
    },

    /**
     * Инициализирует поиск мест через Google Map Place Api
     * @param map - любая google карта. Непонятно зачем она нужна, но api google её требует, хотя на карте маркеры с плейсами не появляются
     * @param b2c_filter - фильтр поиска плейсов в формате B2C Messenger
     * @param callback_ok - function(b2c_places_array, google_pagination_object) функция обратного вызова для получения резльтата
     * @param callback_error - function(error) функция обратного вызова для сообщения об ошибке
     * @returns {boolean}
     */
    search_places : function(map, b2c_filter, callback_ok, callback_error) {
        var goo_filter = this.create_goo_place_search_filter(b2c_filter);
        //console.log('googlePlace->search_places->goo_filter');
        //console.log(goo_filter);

        if (goo_filter == null)
            return false;

        service = new google.maps.places.PlacesService(map);
        var that = this;
        var callback = function(results, status, pagination) { that.on_search_places_callback(results, status, pagination, callback_ok, callback_error) };
        service.nearbySearch(goo_filter, callback);

        return true;
    },

    /**
     * Запрашивает информациюю по плейсу через Google Map Place Api
     * @param map - любая google карта. Непонятно зачем она нужна, но api google её требует, хотя на карте маркеры с плейсами не появляются
     * @param place_id - идентификатор плейса в БД Google
     * @param callback_ok - function(b2c_place) функция обратного вызова для получения резльтата
     * @param callback_error - function(error) функция обратного вызова для сообщения об ошибке
     * @param max_iterations - количество попыток запроса в случае, если гугл выдает ошибку google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT
     */
    request_place_info : function(map, place_id, callback_ok, callback_error, max_iterations) {
        try {
            if (max_iterations == null)
                max_iterations = 5;
            var service = new google.maps.places.PlacesService(map);

            var that = this;

            var options = {
                map : map,
                place_id : place_id,
                max_iterations : max_iterations
            };

            service.getDetails({
                placeId: place_id
            }, function(place, status) { that.on_request_place_info_callback(place, status, callback_ok, callback_error, options) });
        } catch(error) {
            callback_error(error, place_id);
        }
    },

    /**
     * Строит Object плейс в формате B2C Messenger
     * При этом к каждому объекту добавляется атрибут __goo_place, который указывает на оригинальный Google Place Object
     * Если на вход была дана полная версия плейса, то атрибут __goo_full == true
     * @param goo_place - плейс в формате Google
     */
    build_b2c_place : function(goo_place) {
        var place = {
            ext : {
                id : goo_place.place_id,
                src : 'goo'
            },
            __goo_place : goo_place
        };
        place.name = goo_place.name;
        place.www = goo_place.website;
        this.build_b2c_place_address(goo_place, place);
        this.build_b2c_place_phones(goo_place, place);
        this.build_b2c_place_days(goo_place, place);
        this.build_b2c_place_photos(goo_place, place);
        place.categories = this.build_b2c_place_categories_assoc(goo_place.types);

        console.log(place);

        return place;
    },
    /**
     * Строит адресные поля плейса
     * Если на вход была дана полная версия плейса goo_place, то атрибут .b2c_place__goo_full == true
     * @param goo_place - исходный плейс от гугла
     * @param b2c_place - плейс для заполнения в формате b2c
     */
    build_b2c_place_address : function(goo_place, b2c_place) {
        if (goo_place.address_components == null || goo_place.address_components.length == 0)
            b2c_place.adr_street_name = goo_place.vicinity;
        else {
            var ac = goo_place.address_components;
            for(var i=0; i<ac.length; i++) {
                var sub_ac = ac[i];
                if (sub_ac.types.indexOf('street_number') >= 0)
                    b2c_place.adr_street_number = sub_ac.long_name;
                else if (sub_ac.types.indexOf('route') >= 0)
                    b2c_place.adr_street_name = sub_ac.long_name;
                else if (sub_ac.types.indexOf('locality') >= 0)
                    b2c_place.adr_city = sub_ac.long_name;
                else if (sub_ac.types.indexOf('administrative_area_level_1') >= 0)
                    b2c_place.adr_state = sub_ac.long_name;
                else if (sub_ac.types.indexOf('country') >= 0)
                    b2c_place.adr_country_code = sub_ac.short_name;
                else if (sub_ac.types.indexOf('postal_code') >= 0)
                    b2c_place.adr_postal_code = sub_ac.long_name;
            }
            if(b2c_place.adr_street_number == null
                || b2c_place.adr_street_name == null
                || b2c_place.adr_city == null
            ||  b2c_place.adr_country_code == null) {
                console.log('bad goo_place = ');
                console.log(goo_place);
            }


            b2c_place.__goo_full = true; // Отметка, что это полная версия места
        }
        b2c_place.adr_latitude = goo_place.geometry.location.lat();
        b2c_place.adr_longitude = goo_place.geometry.location.lng();
    },
    build_b2c_place_phones : function(goo_place, b2c_place) {
        b2c_place.phone = [];
        if (goo_place.international_phone_number != null)
            b2c_place.phone.push(B2Cjs.phone_getInternalFormat(goo_place.international_phone_number));
    },
    build_b2c_place_photos : function(goo_place, b2c_place) {
        b2c_place.photos = {
            business : []
        };
        var bphs = b2c_place.photos.business; // Бизнес фото B2C Place

        if (goo_place.photos != null) {
            if (b2c_place.ext == null) // Сруктура показывающая серверу B2C Messenger, что здесь внешняя информация, которая требует особой обработки
                b2c_place.ext = {
                    src : 'goo'
                };
            b2c_place.ext.photos = [];
            bphs_ext_ph = b2c_place.ext.photos;

            var gphs = goo_place.photos; // Бизнес фото Google Place
            for(var i=0; i<gphs.length; i++) {
                var gph = gphs[i];
                var b2c_ph = {
                    id : Math.floor(Math.random() * (100000 - 1000 + 1)) + 1000,
                    thumb : gph.getUrl({'maxWidth': 640, 'maxHeight': 640}),
                    photo : gph.getUrl({'maxWidth': 1280, 'maxHeight': 1280}),
                };
                bphs.push(b2c_ph);
                bphs_ext_ph.push(b2c_ph.photo);

                if (i==0) {
                    b2c_place.logo = gph.getUrl({'maxWidth': 640, 'maxHeight': 640});
                    b2c_place.logoThumb = gph.getUrl({'maxWidth': 320, 'maxHeight': 320});
                    b2c_place.ext.logo = b2c_place.logo;
                }
            }
        }
    },
    /**
     * Конвертирует часы работы заведения из объекта места Google в B2C Messenger
     *
     * @param goo_place {Object} Объект места в формате Google
     * @param b2c_place {Object} Объект места в формате B2C Messenger
     */
    build_b2c_place_days : function(goo_place, b2c_place) {
        if (goo_place.opening_hours == null
            || goo_place.opening_hours.periods == null
            || goo_place.opening_hours.periods.length == 0
        )
            return;

        var goo_periods = goo_place.opening_hours.periods;

        if (goo_periods.length == 1
            && goo_periods[0].close == null) {
            b2c_place.days = [
                {d : 0, all : 1},
                {d : 1, all : 1},
                {d : 2, all : 1},
                {d : 3, all : 1},
                {d : 4, all : 1},
                {d : 5, all : 1},
                {d : 6, all : 1}
            ];
            return;
        }

        var days_assoc = {}; // {<day_num> : { d : <day_num>, all : -1|1|null, int :[{o_h : 10, o_m : 30, c_h : 22, c_h : 45}] }}


        for (var i = 0, b2c_place_days_ints; i < goo_periods.length; i++) {
            b2c_place_days_ints = this.build_b2c_place_days_int(goo_periods[i]);
            for(var day_num in b2c_place_days_ints) {
                var interval =  b2c_place_days_ints[day_num].int;
                if (days_assoc[day_num] == null) {
                    days_assoc[day_num] = {
                        d : Number(day_num),
                        int : []
                    }
                }
                days_assoc[day_num].int.push(interval);
            }
        }

        var days = [];
        for (var i = 0; i < 7; i++) {
            if(days_assoc[i] == null) {
                days.push({d:i, all : -1});
            } else {
                days.push(days_assoc[i]);
            }
        }
        b2c_place.days = days;
        return;
    },
    /**
     * Создает массив объектов интервалов работы заведения в формате B2C Messenger.
     * В виду того, что google имеет переходящие интервалы с одного дня на другой:
     * например: открытие в 16:00 одного дня, а закрытие в 03:00 следующего дня.
     * Один Google интеревал может разложиться на два интервала B2C Messenger
     *
     * @param goo_open_int {Object} Объект интервала работы заведения в формате Google
     * @return {Object} {<day_num> : { all : -1|1|null, int : {o_h : 10, o_m : 30, c_h : 22, c_h : 45} } }
     */
    build_b2c_place_days_int : function(goo_open_int) {
        var result = {};

        var first_int_c_h, // {Integer} часы закрытия первого интервала
            first_int_c_m; // {Integer} минуты закрытия первого интервала
        if (goo_open_int.open.day ==  goo_open_int.close.day) {
            first_int_c_h = goo_open_int.close.hours;
            first_int_c_m = goo_open_int.close.minutes;
        } else {
            first_int_c_h = 23;
            first_int_c_m = 59;
        }

        // Первый интервал
        var first_int = {
            o_h : goo_open_int.open.hours,
            o_m : goo_open_int.open.minutes,
            c_h : first_int_c_h,
            c_m : first_int_c_m
        };
        result[goo_open_int.open.day] = {int : first_int};

        if (goo_open_int.open.day !=  goo_open_int.close.day &&
            !(goo_open_int.close.hours == 0 && goo_open_int.close.minutes == 0)
        ) {
            var second_int = {
                o_h : 0,
                o_m : 0,
                c_h : goo_open_int.close.hours,
                c_m : goo_open_int.close.minutes
            };
            result[goo_open_int.close.day] = {int : second_int};
        }

        return result;
    },


    /**
     * Строит массив вида [{id : b2c_category_id}, {id : b2c_category_id}]
     * @param goo_place_types - array of google places categories ids
     */
    build_b2c_place_categories_assoc : function(goo_place_types) {
        var result = [];
        if (goo_place_types == null)
            return result;
        var goo_cats_to_b2c_cats_ids = this.goo_cats_to_b2c_cats_ids(goo_place_types);
        var b2c_cat_id;
        for(var goo_cat in goo_cats_to_b2c_cats_ids) {
            b2c_cat_id = goo_cats_to_b2c_cats_ids[goo_cat];
            if (b2c_cat_id != null) {
                result.push({id : b2c_cat_id});
            }
        }
        return result;
    },

    /**
     * Сохраняет меппинг идентификаторов категорий плейсов Google и B2C Messenger в локальное хранилище
     */
    mapping_save_to_storage : function() {
        localStorage.goo_cat_to_b2c_cat_id = JSON.stringify(this.goo_cat_to_b2c_cat_id);
        localStorage.b2c_cat_id_to_goo_cat = JSON.stringify(this.b2c_cat_id_to_goo_cat);
    },
    /**
     * Иницаилизирует ассоциативные массивы меппинга категорий плейсов Google и B2C Messenger из локального хранилища в переменные
     */
    mapping_init_from_storage : function() {
        if (this.goo_cat_to_b2c_cat_id != null)
            return;
        if (localStorage.goo_cat_to_b2c_cat_id == null)
            return;
        this.goo_cat_to_b2c_cat_id = JSON.parse(localStorage.goo_cat_to_b2c_cat_id);
        this.b2c_cat_id_to_goo_cat = JSON.parse(localStorage.b2c_cat_id_to_goo_cat);
    },
    /**
     * Инициализирует web worker, который на основании локальной базы категрий плейсов B2C Messenger строит двусторонний меппинг идентификаторов категорий плейсов Google и B2C Messenger.
     */
    init_building_cats_mapping : function() {
        this.mapping_init_from_storage();
        if (this.b2c_cat_id_to_goo_cat != null)
            return;

        var that = this;

        var categoryBase = new CategoryBase();
        var all_cats_as_assoc = categoryBase.get_all_cars_as_assoc();

        var worker_mapping = new Worker('js/w_goo_pl_cat.js');
        worker_mapping.onmessage = function(event) { that.on_worker_mapping_done(event) };
        worker_mapping.onerror = function(error) { console.log('w_goo_pl_cat.js error'); console.log(error) };
        worker_mapping.postMessage(all_cats_as_assoc);
    },
    /**
     * Обработчик результата работы воркера построение меппинга идентификаторов категорий плейсов Google и B2C Messenger.
     * Сохраняет меппинг в локальное хранилище
     * @param event
     */
    on_worker_mapping_done : function(event) {
        var message = event.data;

        if (message.messageType == "done") {
            // успешно отработали
            var data = message.data;
            this.goo_cat_to_b2c_cat_id = data.goo_cat_to_b2c_cat_id;
            this.b2c_cat_id_to_goo_cat = data.b2c_cat_id_to_goo_cat;

            console.log('googlePlace.on_worker_mapping_done() done');
            this.mapping_save_to_storage();
        } else {
            console.log('googlePlace.on_worker_mapping_done() not done:');
            console.log(event);
        }
    },
    /**
     * Обработчик результата поиска мест сервисом Google Map Place Api
     * @param results
     * @param status
     * @param pagination
     * @param callback_ok - function(b2c_places_array, google_pagination_object) функция обратного вызова для получения резльтата
     * @param callback_error - function(error) функция обратного вызова для сообщения об ошибке
     */
    on_search_places_callback : function(results, status, pagination, callback_ok, callback_error) {
        /*console.log("results:");
        console.log(results);
        console.log("status:");
        console.log(status);
        console.log("pagination:");
        console.log(pagination);*/

        if (status == google.maps.places.PlacesServiceStatus.OK) {
            var b2c_places_array = [];

            for (var i = 0; i < results.length; i++) {
                var place = results[i];
                //console.log(place);
                var b2c_place = this.build_b2c_place(place);
                b2c_places_array.push(b2c_place);
            }

            if (callback_ok != null) {
                callback_ok(b2c_places_array, pagination);
            }
        } else {
            if (callback_error != null) {
                var error = {
                    results : results,
                    status : status
                };
                callback_error(error);
            }
        }
    },

    on_request_place_info_callback : function(place, status, callback_ok, callback_error, options) {
        try {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                var b2c_place = this.build_b2c_place(place);
                if (callback_ok != null)
                    callback_ok(b2c_place);
            } else {
                if (status === google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT && options != null && options.max_iterations > 0) {
                    var that = this;

                    setTimeout(function(){ that.request_place_info(options.map, options.place_id, callback_ok, callback_error, --options.max_iterations) }, 2000);
                } else if (callback_error != null) {
                    var error = {
                        results : place,
                        status : status
                    };
                    callback_error(error, options.place_id);
                }
            }
        } catch(error) {
            callback_error(error, options.place_id);
        }
    }
};

// На событие завершенеи инициализации локальной базы категорий плейсов запустить иницаилизацию меппинга категрий плейсов B2C и Google
document.addEventListener('b2c_cats_base_inited', function(){ googlePlace.init_building_cats_mapping() });

window.googlePlace = googlePlace; // TODO: remove globals, use module exports
// export default googlePlace;
