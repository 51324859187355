import {Model} from '@b2cmessenger/backbone';

const NotificationSwitchModel = Model.extend({
    options: {
        notificationMuteCollection: null,
        notificationMuteAttributes: null,
    },

    defaults: {
        _mute_id: null,
    },

    computeds: {
        muted: {
            deps: ["_mute_id"],
            get: _mute_id => _mute_id,
            set(value) {
                if (value) {
                    if (!this.get('_mute_id')) {
                        this._notificationMuteCollection.create(this._notificationMuteAttributes, {
                            error: model => {
                                this._notificationMuteCollection.remove(model);
                                this._notificationMuteCollection.fetch();
                            }
                        });
                    }
                } else {
                    _.each(
                        this._notificationMuteCollection.where(this._notificationMuteAttributes),
                        model => model.destroy({
                            error: model => {
                                this._notificationMuteCollection.add(model);
                                this._notificationMuteCollection.fetch();
                            }
                        })
                    );
                }
            }
        }
    },

    initialize() {
        if (!this.options.notificationMuteCollection) {
            throw new Error('No notification mute collection provided');
        }

        this._notificationMuteCollection = this.options.notificationMuteCollection;

        if (!_.isObject(this.options.notificationMuteAttributes) || !_.size(this.options.notificationMuteAttributes)) {
            throw new Error('No notification mute attributes provided');
        }

        this._notificationMuteAttributes = this.options.notificationMuteAttributes;

        this.listenTo(this._notificationMuteCollection, 'add', model => {
            if (_.every(this._notificationMuteAttributes, (val, index) => model.get(index) == val)) {
                this.set({
                    _mute_id: model.cid
                });
            }
        });

        this.listenTo(this._notificationMuteCollection, 'remove', (model, collection) => {
            if (_.every(this._notificationMuteAttributes, (val, index) => model.get(index) == val)) {
                const nextModel = collection.findWhere(this._notificationMuteAttributes);
                this.set({
                    _mute_id: nextModel && nextModel.cid || null
                });
            }
        });

        const onReset = collection => {
            const model = collection.findWhere(this._notificationMuteAttributes);
            this.set({
                _mute_id: model && model.cid || null
            });
        };

        this.listenTo(this._notificationMuteCollection, 'reset', onReset);

        onReset(this._notificationMuteCollection);
    },
});


export default NotificationSwitchModel;
