import React, { useCallback } from 'react';
import './MenuVisibility.scss';

export const MenuVisibilityToggle = (props) => {
    const { active, onClick } = props;
    const className = ['menu-visibility', active && 'active', props.disabled && 'disabled'].filter(Boolean).join(' ');

    const clickHandler = useCallback(
        (e) => {
            e.stopPropagation();

            if (!props.disabled && onClick) {
                onClick();
            }
        },
        [onClick, props.disabled]
    );

    return (
        <button className={className} onClick={clickHandler} data-role="none">
            <i className="ion-ios-eye" />
        </button>
    );
};

export const MenuHiddenVisibility = () => {
    return (
        <button className="menu-visibility disabled" disabled data-role="none">
            <i className="ion-ios-eye-off" />
        </button>
    );
};
