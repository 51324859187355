import initPrecacheUrl from 'precache/api-init-index.precache';

const settings = {
    host: document.body.getAttribute('API_ENDPOINT') || HOST,
    //host : 'http://b2cm.2mx.org',
    //host: 'https://say2b.com',
    //host: 'http://rc.b2cmessenger.com',
    //host : 'http://rdeanar.b2cmessenger.com',
    init: '/api/init/index',
    init_precache: initPrecacheUrl,
    serv_user: {
        authorize: '/api/user/authorize',
        facebook_login: '/api/user/facebook/login',
        google_login: '/api/user/google/login',
        apple_login: '/api/user/apple/login',
        create: '/api/user/create',
        change: '/api/user/settingssave',
        passwordset: '/api/user/passwordset',
        passwordreset: '/api/user/password/initreset',
        passwordresetcode: '/api/user/password/resetcode',
        avatarset: '/api/user/avatarset',
        settingssave: '/api/user/settingssave',
        init_user: '/api/init/user',
        search: '/api/user/search',
        orders: '/api/v2/user/menu/orders',
        verification: {
            request_code: '/api/v2/user/verifications',
            verify: '/api/v2/user/verifications/{id}'
        },
        phones: {
            base: '/api/v2/user/phones',
            request_code: '/api/v2/user/phones/{id}/request_code',
            verify: '/api/v2/user/phones/{id}/verify',
            make_default: '/api/v2/user/phones/{id}/make_default'
        }
    },
    serv_place: {
        search: '/api/place/search',
        get: '/api/place/get',
        report: '/api/place/report',
        create: '/api/place/create',
        change: '/api/place/change',
        checkin: '/api/place/checkin',
        checkin_hist: '/api/place/checkin-hist',
        business_checkin: '/api/place/checkin/business/create',
        business_checkin_hist: '/api/place/checkin/business/hist',
        mybusiness: '/api/place/mybusiness',
        isverified: '/api/place/isverified',
        isverified_list: '/api/place/isverified/list',
        setlogo: '/api/place/setlogo',
        favorite: '/api/place/favorite',
        photo: {
            add: '/api/place/photo-add',
            delete: '/api/place/photo-delete'
        },
        employee: {
            search: '/api/place/employee/search',
            add: '/api/place/employee/add',
            delete: '/api/place/employee/delete',
            changeroles: '/api/place/employee/changeroles',
            accept: '/api/place/employee/accept',
        },
        menu: {
            info: '/api/v2/place/${place_id}/menu',
            categories: '/api/v2/place/${place_id}/menu/categories',
            category: '/api/v2/place/${place_id}/menu/categories/${category_id}',
            unlisted: '/api/v2/place/${place_id}/menu/categories/unlisted',
            hide_category: '/api/v2/place/${place_id}/menu/categories/${category_id}/hide',
            item: '/api/v2/place/${place_id}/menu/items/${item_id}',
            item_category: '/api/v2/place/${place_id}/menu/categories/${category_id}/items/${item_id}',
            hide_item: '/api/v2/place/${place_id}/menu/items/${item_id}/hide',
            detail: '/api/v2/place/${place_id}/menu/items/${item_id}',
            detailed_items: '/api/v2/place/${place_id}/menu/items',
            add_item: '/api/v2/place/${place_id}/menu/items',
            change_sort: '/api/v2/place/${place_id}/menu/sort',
            search: '/api/v2/place/${place_id}/menu/search',
            order: '/api/v2/place/${place_id}/menu/order',
            orders: '/api/v2/place/${place_id}/menu/orders',
            waiters: '/api/v2/place/${place_id}/waiters',
            employee_status: '/api/v2/place/${place_id}/employee/status',
            employee_status_by_id: '/api/v2/place/${place_id}/employee/${user_id}/status',
            section: {
                base: '/api/v2/place/${place_id}/section',
                list: '/api/v2/place/${place_id}/sections',
                tables: '/api/v2/place/${place_id}/section/${section_id}/tables'
            },
            table: {
                base: '/api/v2/place/${place_id}/table',
                list: '/api/v2/place/${place_id}/tables',
                qr: '/api/v2/place/${place_id}/tables/qr'
            },
            call_waiter: {
                order_call_waiter: '/api/v2/place/${place_id}/menu/order/${order_id}/call_waiter',
                call_waiter: '/api/v2/place/${place_id}/menu/call_waiter',
                waiter_requests: '/api/v2/place/${place_id}/menu/waiter_requests', // History for waiter
                call_waiter_history: '/api/v2/place/${place_id}/menu/call_waiter/history', // History for client
            },
        }
    },
    serv_message: {
        create: '/api/message/create',
        change: '/api/message/change',
        delete: '/api/message/delete',
        report: '/api/message/report',
        solutionrate: '/api/message/solutionrate',
        like: '/api/message/like',
        addphoto: '/api/message/addphoto',
        search: '/api/message/search',
    },
    serv_reservation: {
        base: '/api/v2/reservations',
        withmap: '/api/v2/reservations/withmap',
        get_tasks_reservations: '/api/v2/user/tasks/reservations',
        get_place_reservations: '/api/v2/place/{place_id}/tasks/reservations',
        calendar_summary: '/api/v2/reservations/calendar_summary',
    },
    serv_task: {
        create: '/api/task/create',
        change: '/api/task/change',
        delete: '/api/task/delete',
        report: '/api/task/report',
        like: '/api/task/like',
        addphoto: '/api/task/photo/add',
        deletephoto: '/api/task/photo/delete',
        search: '/api/task/search',
        comment: {
            create: '/api/task/comment/create',
            change: '/api/task/comment/change',
            delete: '/api/task/comment/delete',
            report: '/api/task/comment/report',
            like: '/api/task/comment/like',
            addphoto: '/api/task/photo/add',
            deletephoto: '/api/task/photo/delete',
            search: '/api/task/comment/search',
            solutionrate: '/api/task/comment/solutionrate',
        },
        target: '/api/task/targets'
    },
    serv_evidence: {
        save: '/api/evidence/save',
        photo: {
            add: '/api/evidence/photo/add',
            delete: '/api/evidence/photo/delete',
        },
        search: '/api/evidence/search',
        delete: '/api/evidence/delete',
        businessvalidate: '/api/evidence/businessvalidate',
    },
    serv_loyaltycard: {
        save: '/api/loyalty/save',
        search: '/api/loyalty/search',
        delete: '/api/loyalty/delete',
        businessvalidate: '/api/loyalty/businessvalidate',
        business: {
            base: '/api/v2/business/loyalty'
        }
    },
    serv_gift: {
        template: {
            save: '/api/gift/template/save',
            delete: '/api/gift/template/delete',
            search: '/api/gift/template/search',
        },
        create: '/api/gift/create',
        search: '/api/gift/search',
        brands: '/api/gift/brands',
        redeem: '/api/v2/gift/redeem',
    },
    serv_notification: {
        registration: '/api/notification/phone/registration',
        list: '/api/notification/list',
        readstatus: '/api/notification/readstatus',
        v2: '/api/v2/notifications',
        mute: '/api/v2/notifications/mute'
    },
    serv_invite: {
        invite: '/api/place/client/invite'
    },
    qrSecrets: ['00447E6711B24B74BC89C5D80D00323F', 'D8B160291433131287B121A2DE878524'],
    notificationFetcher: {
///////////////////////
        period: 10000,
/////////////////
//////////////////////
//////////////////
    },
    backbutton: {
        eventName: 'b2cBackButton'
    },
    ga: {
        gtag_id: 'G-E21192NQ02',
///////////////////////
        id: 'UA-86353249-3'
/////////////////
///////////////////////////
//////////////////
    },
    appsFlyer: {
        devKey: 'FZp7Z5PZ5Puwa9dhDuEFQh',
        appId: '1089962824'
    },
    sentryDsn: 'https://4db3ca4e81f447078f8ff77fbb4d13a8@o405056.ingest.sentry.io/5278959',
};


export default settings;
