import {Model} from "@b2cmessenger/backbone";
import settings from "settings";

const MenuCategoryModel = Model.extend({
    defaults: {
        name: '',
        parent_id: null,
        is_addition: 0,
        photo: null
    },

    computeds: {
        c_isValid: {
            deps: ['name', 'parent_id', 'is_addition', 'photo'],
            get(name, parent_id, is_addition, photo) {
                return name && name.length &&
                    Number(parent_id) >= 0 &&
                    ([0,1].indexOf(Number(is_addition)) > -1) &&
                    (photo == null || (Number(photo) > 0));
            }
        }
    },

    sync(method, model, options) {
        const error = options.error, self = this;
        const { placeId } = options;

        options.error = function (jqXHR, textStatus, errorThrown) {
            if (jqXHR.status == 422) {
                self._onServerResponse422(jqXHR, textStatus, errorThrown)
            }

            if (error) error.call(this, jqXHR, textStatus, errorThrown);
        };

        if (method === 'create') {
            options.url = settings.host + settings.serv_place.menu.categories.replace('${place_id}', placeId);
            options.attrs = this.pick('name', 'parent_id', 'is_addition', 'photo');

            return Model.prototype.sync.call(this, method, model, options);
        } else if (method === 'update' || method === 'patch') {
            method = 'patch';

            options.url = settings.host + settings.serv_place.menu.category.replace('${place_id}', placeId)
                .replace('${category_id}', this.id);
            options.type = 'PATCH';
            options.attrs = _.pick(options.attrs || this.toJSON(), ['name', 'parent_id', 'is_addition', 'photo']);

            return Model.prototype.sync.call(this, method, model, options);
        } else if (method === 'delete') {
            options.url = settings.host + settings.serv_place.menu.category.replace('${place_id}', placeId)
                .replace('${category_id}', this.id);
            if (options.moveToParent) {
                options.url = `${options.url}?move_to_parent=1`
            }
            options.type = 'DELETE';

            return Model.prototype.sync.call(this, method, model, options);
        } else if (method === 'read') {
            options.url = settings.host + settings.serv_place.menu.category.replace('${place_id}', placeId)
                .replace('${category_id}', this.id);

            return Model.prototype.sync.call(this, method, model, options);
        } else {
            const err = new Error(`Sync method "${method}" is not supported`);
            if (options && options.error) {
                options.error.call(options.context, err);
            }
            //@ts-ignore
            return $.Deferred().reject(err);
        }

    },

    _onServerResponse422(jqXHR, textStatus, errorThrown) {
        const fields = _.isArray(jqXHR.responseJSON) && jqXHR.responseJSON
            || (jqXHR.responseJSON.error && jqXHR.responseJSON.error.field) || [];

        this.validationError = _(fields).reduce((fields, o) => {
            let field = o.field && String(o.field),
                msg = o.message && String(o.message);

            if (field && msg) {
                fields[field] = msg;
            }

            return fields;
        }, _.create(null));

        this.trigger('invalid', this, this.validationError);
    }
});

export default MenuCategoryModel;
