import { Required, Optional, Model, ViewModel, LayoutView } from '@b2cmessenger/backbone';
import InfoModal from 'windows/Modal/Info';
import ConfirmModalWindow from 'windows/Modal/Confirm';

import GiftTemplatesWindow from 'windows/GiftTemplates/GiftTemplates';
import GiftModal from 'windows/Modal/Gift';
import GiftWidget from 'widgets/Gift/Gift';
import PhotoGridView from './PhotoGrid/PhotoGrid';
import FileInput from 'widgets/Inputs/FileInput';
import CommentEditorPhotoModel from './PhotoGrid/PhotoModel';

//@ts-ignore
import autosize from 'autosize';
import backgroundImageHandler from 'bindings/handlers/backgroundImage';

import template from './CommentEditor.jade';
import './CommentEditor.scss';
import ReservationEditorWindow from 'windows/ReservationEditor/ReservationEditor';
import LoginedUserModel from 'models/LoginedUserModel';
import ReservationModel from 'models/ReservationModel';
import ReservationChangeCommentAdditionalData
    from 'widgets/Reservation/ReservationChangeCommentAdditionalData/ReservationChangeCommentAdditionalData';
import MessageModel from 'models/MessageModel';
import ReservationDeclineModalWindow from 'widgets/Reservation/ReservationDeclineModal/ReservationDeclineModal';
import ImageInputHelpers from 'widgets/Inputs/ImageInputHelpers';

/**@type {typeof import('./CommentEditor').properties} */
const properties = LayoutView.properties;

/**@type {typeof import('./CommentEditor').options} */
const options = LayoutView.options;

/**@type {typeof import('./CommentEditor').events} */
const events = LayoutView.events;

const EditorMode = {
    Basic: 0,
    ReservationChanges: 1,
};

const ReservationActions = {
    None: null,
    Accept: 'accept',
    Suggest: 'suggest',
    Decline: 'decline'
};

@options({
    model: Required,
    reservationModel: Optional,
    placeModel: Optional,
    parentViewModel: Optional
})
@events({
    /** Editor opened with comment
     * @param {MessageModel} commentModel - model of existing comment of `null` if editing new comment */
    'comment:edit': (comment) => {
    },

    /** Editor opened with comment
     * @param {MessageModel} commentModel - model of existing comment of `null` if editing new comment */
    'comment:cancel': (comment) => {
    },

    /** Editor saved comment
     * @param {MessageModel} commentModel - model of saved comment */
    'comment:save': (comment) => {
    },

    /** Editor created new comment
     * @param {MessageModel} commentModel - model of newly created comment */
    'comment:create': (comment) => {
    },

    /** Editor saved changes to existing comment
     * @param {MessageModel} commentModel - model of updated comment */
    'comment:update': (comment) => {
    },
})
// @ts-ignore FIXME: REMOVE THIS! ! !
@properties({
    className: 'widget message-comment-editor-widget',
    template,

    ui: {
        authorAvatar: '[data-js-author-avatar]',
        authorName: '[data-js-author-name]',
        btnAnonym: '[data-js-btn-anonym]',
        text: '[data-js-text]',
        gift: '[data-js-gift]',
        btnAddGift: '[data-js-btn-add-gift]',
        btnRemoveGift: '[data-js-btn-remove-gift]',
        btnPhotos: '[data-js-btn-add-photos]',
        btnUndo: '[data-js-btn-undo]',
        btnSend: '[data-js-btn-send]',
        btnSolution: '[data-js-btn-solution]',
        btnSolutionLabel: '[data-js-btn-solution-label]',

        btnReservationAction: '.control-button[data-action]',
        btnAccept: '[data-js-btn-reservation-accept]',
        btnSuggest: '[data-js-btn-reservation-suggest]',
        btnDecline: '[data-js-btn-reservation-decline]',
        btnReservationMode: '[data-js-btn-reservation-mode]',
        btnReservationModeLabel: '[data-js-btn-reservation-mode-label]'
    },

    regions: {
        gift: '[data-js-gift]',
        photos: '[data-js-photos]',
        addPhoto: '[data-js-add-photo]',
        reservationChange: '[data-js-reservation-change]'
    },

    computeds: {
        authorAvatarUrl: {
            deps: ['isAuthorAnonym', 'userModel'],
            get: (isAuthorAnonym, userModel) => !isAuthorAnonym && userModel && userModel.get('avatarUrl')
        },
        authorName: {
            deps: ['isAuthorAnonym', 'isFromBussines', 'userModel'],
            get: (isAuthorAnonym, isFromBussines, userModel) => isAuthorAnonym ?
                isFromBussines ? 'Somebody' : 'Anonym' :
                userModel && userModel.get('name') || 'Unknown person'
        },
        isAuthorCanBeAnonym: {
            deps: ['isFromBussines', 'parentMessageModel', 'parentMessageModelIsReservation'],
            get: (isFromBussines, parentMessageModel, parentMessageModelIsReservation) => !isFromBussines &&
                !parentMessageModel.get('parentTaskModel').get('to_user_id') && !parentMessageModelIsReservation
        },
        markAsSolutionLabelText: {
            deps: ['isSolution'],
            get: isSolution => isSolution ? 'Marked as ' : 'Mark as '
        },

        /* Reservation related */
        isReservationChangesMode: {
            deps: ['editorMode'],
            get: editorMode => editorMode == EditorMode.ReservationChanges
        },
        reservationModeTexts: {
            deps: ['isReservationChangesMode'],
            get: isReservationChangesMode => ({
                'span': isReservationChangesMode ? 'Disable' : 'Enable',
                'label': ' reservation edit mode'
            })
        },
        displayReservationButtons: {
            deps: ['isReservationChangesMode', 'canSuggestReservationChanges'],
            get: (isReservationChangesMode, canSuggestReservationChanges) =>
                isReservationChangesMode && canSuggestReservationChanges
        },
        displayAcceptButton: {
            deps: ['displayReservationButtons', 'canAcceptReservationChanges'],
            get: (displayReservationButtons, canAcceptReservationChanges) =>
                displayReservationButtons && canAcceptReservationChanges
        },
        acceptTexts: {
            deps: ['isReservationChangesMode', 'isReservationChangeAcceptAction'],
            get: (isReservationChangesMode, isReservationChangeAcceptAction) => ({
                'span': isReservationChangesMode ? 'Accept' :
                    (isReservationChangeAcceptAction ? 'Accepted' : ''),
                'label': isReservationChangeAcceptAction ? '' : ' reservation'
            })
        },
        suggestTexts: {
            deps: ['isReservationChangesMode', 'isReservationChangeSuggestAction'],
            get: (isReservationChangesMode, isReservationChangeSuggestAction) => ({
                'span': isReservationChangesMode ? 'Suggest changes' :
                    (isReservationChangeSuggestAction ? 'Changes suggested' : ''),
                'label': isReservationChangeSuggestAction ? '' : ' to reservation'
            })
        },
        declineTexts: {
            deps: ['isReservationChangesMode', 'isReservationChangeDeclineAction'],
            get: (isReservationChangesMode, isReservationChangeDeclineAction) => ({
                'span': isReservationChangesMode ? 'Decline' :
                    (isReservationChangeDeclineAction ? 'Declined' : ''),
                'label': isReservationChangeDeclineAction ? '' : ' reservation'
            })
        },
        reservationChangeAction: {
            deps: ['messageModelReservationChange'],
            get: messageModelReservationChange => {
                if (messageModelReservationChange && messageModelReservationChange.data) {
                    const status = messageModelReservationChange.data.status;
                    switch (status) {
                        case ReservationModel.Status.Confirmed:
                            return ReservationActions.Accept;
                        case ReservationModel.Status.CancelledByClient:
                        case ReservationModel.Status.RejectedByBusiness:
                            return ReservationActions.Decline;
                        default:
                            return ReservationActions.Suggest;
                    }
                }

                return ReservationActions.None;
            }
        },
        isReservationChangeAcceptAction: {
            deps: ['reservationChangeAction'],
            get: reservationChangeAction => reservationChangeAction == ReservationActions.Accept
        },
        isReservationChangeSuggestAction: {
            deps: ['reservationChangeAction'],
            get: reservationChangeAction => reservationChangeAction == ReservationActions.Suggest
        },
        isReservationChangeDeclineAction: {
            deps: ['reservationChangeAction'],
            get: reservationChangeAction => reservationChangeAction == ReservationActions.Decline
        },
        isAcceptReservationChanges: {
            deps: ['messageModelReservationChange'],
            get: messageModelReservationChange => messageModelReservationChange && messageModelReservationChange.data
                && messageModelReservationChange.data.status == ReservationModel.Status.Confirmed
        },
        isAcceptActionDisabled: {
            deps: ['reservationAction', 'isReservationChangeAcceptAction'],
            get: (reservationAction, isReservationChangeAcceptAction) => isReservationChangeAcceptAction
                || [ReservationActions.Accept, ReservationActions.None].indexOf(reservationAction) == -1
        },
        isSuggestActionDisabled: {
            deps: ['reservationAction', 'isReservationChangeSuggestAction'],
            get: (reservationAction, isReservationChangeSuggestAction) => isReservationChangeSuggestAction
                || [ReservationActions.Suggest, ReservationActions.None].indexOf(reservationAction) == -1
        },
        isDeclineActionDisabled: {
            deps: ['reservationAction', 'isReservationChangeDeclineAction'],
            get: (reservationAction, isReservationChangeDeclineAction) => isReservationChangeDeclineAction
                || [ReservationActions.Decline, ReservationActions.None].indexOf(reservationAction) == -1
        }
    },

    bindings: {
        ':el': 'classes:{hidden:isHidden,loading:isLoading,"can-be-marked-as-solution":canBeMarkedAsSolution,' +
        '"can-suggest-reservation-changes":all(canSuggestReservationChanges,not(messageModelIsReservationChangeComment))}',
        '@ui.authorAvatar': 'backgroundImage:authorAvatarUrl,classes:{anonym:isAuthorAnonym}',
        '@ui.authorName': 'text:authorName',
        '@ui.btnAnonym': 'classes:{set:isAuthorAnonym,hidden:not(isAuthorCanBeAnonym)},disabled:disabled',
        '@ui.text': 'value:text,disabled:disabled',
        '@ui.btnAddGift': 'classes:{hidden:any(not(canAddGift),gift)},' +
        'disabled:any(not(canAddGift),not(hasPlaceModel),disabled)',
        '@ui.btnRemoveGift': 'classes:{hidden:any(not(canAddGift),not(gift))},' +
        'disabled:any(not(canAddGift),disabled)',
        '@ui.btnPhotos': 'disabled:disabled',
        '@ui.btnUndo': 'classes:{hidden:isDraft},disabled:disabled',
        '@ui.btnSend': 'disabled:disabled',
        '@ui.btnSolution': 'disabled:any(disabled,not(canBeMarkedAsSolution)),classes:{set:isSolution}',
        '@ui.btnSolutionLabel': 'text:markAsSolutionLabelText',

        '@ui.btnAccept': 'disabled:any(disabled,not(canSuggestReservationChanges),isAcceptActionDisabled),' +
        'toggle:any(displayAcceptButton,isReservationChangeAcceptAction),complexText:acceptTexts',
        '@ui.btnSuggest': 'disabled:any(disabled,not(canSuggestReservationChanges),isSuggestActionDisabled),' +
        'toggle:any(displayReservationButtons,isReservationChangeSuggestAction),complexText:suggestTexts',
        '@ui.btnDecline': 'disabled:any(disabled,not(canSuggestReservationChanges),isDeclineActionDisabled),' +
        'toggle:any(displayReservationButtons,isReservationChangeDeclineAction),complexText:declineTexts',
        '@ui.btnReservationMode': 'complexText:reservationModeTexts,' +
        'toggle:all(canSuggestReservationChanges,not(messageModelIsReservationChangeComment))'
    },

    events: {
        'click @ui.btnAnonym'() {
            this.model.set({
                isAuthorAnonym: this.getBinding('isAuthorCanBeAnonym') && !this.model.get('isAuthorAnonym')
            })
        },
        'click @ui.btnAddGift'() {
            this.selectGift();
        },
        'click @ui.btnRemoveGift'() {
            if (this.model.get('isDraft')) {
                this.model.set({
                    gift: null
                });
            }
        },
        'click @ui.btnPhotos'() {
            this.showError(new Error('Currently not supported'), 'Under construction');
        },
        'click @ui.btnUndo'() {
            this.trigger('comment:cancel', this.model.get('messageModel'));
        },
        'click @ui.btnSend'() {
            const isDraft = this.model.get('isDraft');
            this.viewModel.set({
                disabled: true,
                isLoading: true
            });

            if (this.reservationModel
                && this.viewModel.get('editorMode') == EditorMode.ReservationChanges
                && this.model.get('canSuggestReservationChanges')
            ) {
                const reservationActionResult = this.viewModel.get('reservationActionResult');

                this.reservationModel.save(reservationActionResult, {
                    message: this.model.get('text'),
                    includeLastChangeComment: true,
                    wait: true,
                    success: () => {
                        this.viewModel.set({
                            disabled: false,
                            isLoading: false
                        });
                        const commentModel = new MessageModel(this.reservationModel.get('last_change_comment'));
                        this.trigger('comment:save', commentModel);
                        this.trigger('comment:create', commentModel);

                        app.controller.trigger('reservation:change', this.reservationModel.id, {
                            status: this.reservationModel.get('status')
                        });
                    },
                    error: (m, jqXHR) => {
                        if (jqXHR.status == 422) {
                            const fieldsArray =
                                _.isArray(jqXHR.responseJSON) ?
                                    jqXHR.responseJSON
                                    : (jqXHR.responseJSON.error && jqXHR.responseJSON.error.field) || [];

                            const outdatedChangesError = fieldsArray.find((field) => {
                                return field.code === 1103001;
                            });

                            if (outdatedChangesError) {
                                this.showError(outdatedChangesError.message);
                                this.viewModel.set({
                                    reservationActionResult: null,
                                    reservationAction: ReservationActions.None,
                                    disabled: false,
                                    isLoading: false
                                });
                                return;
                            }

                            if (_.size(fieldsArray)) {
                                jqXHR.responseJSON = fieldsArray;
                                this.showError(jqXHR);
                            }
                        } else {
                            this.showError(jqXHR);
                        }

                        this.viewModel.set({
                            disabled: false,
                            isLoading: false
                        });
                    }
                });
                return;
            }

            this.model.save(null, {
                wait: true,
                success: () => {
                    this.viewModel.set({
                        disabled: false,
                        isLoading: false
                    });
                    this.trigger('comment:save', this.model.get('messageModel'));
                    if (isDraft) {
                        this.trigger('comment:create', this.model.get('messageModel'));
                    } else {
                        this.trigger('comment:update', this.model.get('messageModel'));
                    }
                },
                error: (m, resp) => {
                    this.showError(resp);
                    this.viewModel.set({
                        disabled: false,
                        isLoading: false
                    });
                }
            })
        },
        'click @ui.btnSolution'() {
            if (this.model.get('canBeMarkedAsSolution')) {
                this.model.set({ isSolution: !this.model.get('isSolution') });
            }
        },
        'click @ui.btnReservationAction'(e) {
            const action = e.currentTarget.getAttribute('data-action');

            if (action) {
                this._selectReservationChangeAction(action);
            }
        },
        'click @ui.btnReservationMode'() {
            if (this.viewModel.get('reservationAction') == ReservationActions.Suggest
                && this.viewModel.get('editorMode') == EditorMode.ReservationChanges) {
                this._discardReservationChanges()
                    .then(confirm => {
                        if (confirm) {
                            this._toggleReservationChangesMode();
                        }
                    })
            } else {
                this._toggleReservationChangesMode();
            }
        }
    },

    modelEvents: {
        'change:gift change:isDraft': _.debounce(function () {
            this._onGiftChange();
        }, 1)
    },

    templateHelpers() {
        return {
            ReservationActions: ReservationActions,
            placeModel: this.placeModel
        }
    },

    bindingHandlers: {
        backgroundImage: backgroundImageHandler,
        complexText: {
            set($el, selectorMap) {
                $el.each((i, el) => {
                    _.keys(selectorMap).forEach(key => {
                        const $child = $(el).find(key);

                        if ($child.length) {
                            $child.text(selectorMap[key]);
                        }
                    });
                });
            }
        }
    }
})
@InfoModal.showsMessages
class CommentEditor extends LayoutView {
    isEmpty() {
        return !this.model.get('isDirty');
    }

    setText(text) {
        this.model.set({ text });
        autosize.update(this.ui.text);
    }

    focusText(select = false) {
        this.ui.text.focus();

        if (select) {
            _.defer(() =>
                this.ui.text[0].setSelectionRange(0, this.ui.text[0].value && this.ui.text[0].value.length || 0));
        } else {
            _.defer(() => {
                const val = this.ui.text.val() || '';
                this.ui.text[0].setSelectionRange(val.length, val.length);
            });
        }
    }

    setReservationSuggestChanges() {
        this._toggleReservationChangesMode(true);
        this._selectReservationChangeAction(ReservationActions.Suggest);
    }

    show(show = true) {
        this.viewModel.set({ isHidden: !show });
    }

    hide() {
        this.show(false);
    }

    initialize() {
        /**@type {import('./CommentEditor')} */
            // @ts-ignore
        const self = this;
        this.viewModel = new ViewModel({
            editorMode: EditorMode.Basic,
            reservationAction: ReservationActions.None,
            reservationActionResult: null,
            isHidden: false,
            isLoading: false,
            hasPlaceModel: !!self.options.placeModel,
            parentViewModel: self.options.parentViewModel
        });

        this.placeModel = self.options.placeModel;
        this.reservationModel = self.options.reservationModel;
    }

    selectGift() {
        if (this.model.get('canAddGift') && this.placeModel) {
            return new GiftTemplatesWindow({
                brandId: this.placeModel.get('brand_id'),
                choosable: true
            }).show()
                .then(templateModel => {
                    if (templateModel) {
                        this.model.set({
                            gift: {
                                quantity: templateModel.get('quantity'),
                                templateModel,
                            }
                        });
                    }
                });
        } else {
            return Promise.reject('`canAddGift` is falsey or no `placeModel`');
        }
    }

    onRender() {
        /**@type {import('./CommentEditor')} */
            // @ts-ignore
        const self = this;

        _.defer(() => autosize(this.ui.text));

        this._onGiftChange();

        const photoGridView = new PhotoGridView({
            collection: self.model.get('photoCollection'),
            parentViewModel: this.viewModel
        });
        this.showChildView('photos', photoGridView);

        if (this.model.get('messageModelIsReservationChangeComment')) {
            this._displayReservationChange({
                model: new Model(this.model.get('messageModelReservationChange').data)
            });
        }

        if (!this.model.get('canSuggestReservationChanges')) {
            const addPhotoWidget = new FileInput({
                multiple: true,
                appearance: FileInput.Appearance.ButtonInline,
                parentViewModel: this.viewModel
            });
            this.showChildView('addPhoto', addPhotoWidget);

            this.listenTo(addPhotoWidget, 'change', collection => {
                if (collection.length) {
                    collection.reduce((promise, m) => {
                        const file = m.get('file');
                        const fileUrl = m.get('fileUrl');
                        const model = new CommentEditorPhotoModel({
                            status: CommentEditorPhotoModel.Status.Uploading,
                            full: ImageInputHelpers.getTransparentPixelImage(),
                            sizes: {full: {w: 1, h: 1}},
                        });

                        self.model.get('photoCollection').add(model);

                        return promise
                            .then(() => ImageInputHelpers.upload(file === null && fileUrl ? fileUrl : file, 'tp'))
                            .then(uploadedFile => {
                                model.set({
                                    full: null, ...uploadedFile,
                                    status: CommentEditorPhotoModel.Status.UploadedNotSaved
                                });
                            });
                    }, Promise.resolve());

                    addPhotoWidget.reset();
                }
            });
        }

        this.trigger('comment:edit', this.model.get('messageModel'));
    }

    _onGiftChange() {
        if (this.isRendered && !this.isDestroyed) {
            const oldGiftWidget = this.getChildView('gift');
            if (oldGiftWidget) {
                this.stopListening(oldGiftWidget);
                this.stopListening(oldGiftWidget.model);
                this.model.stopListening(oldGiftWidget.model);
            }

            const gift = this.model.get('gift');
            if (gift) {
                const isDraft = this.model.get('isDraft');
                const templateModel = gift.templateModel || new Model(gift.template);

                if (!gift.expireDate) {
                    gift.expireDate = gift.expire && B2Cjs.datetimeServerLocalToJS(gift.expire) || null;
                    if (gift.expireDate) {
                        gift.expireDate.setHours(23, 59, 59, 999);
                    }
                }

                if (!gift.expireDate) {
                    let expireDateLocal;
                    if (templateModel.get('period')) {
                        expireDateLocal = new Date();
                        expireDateLocal.setDate(expireDateLocal.getDate() + templateModel.get('period'));
                        expireDateLocal.setHours(23, 59, 59, 999);
                    }

                    if (templateModel.get('total_period')) {
                        let totalExpireDate = new Date(templateModel.get('total_period').replace(/ /g, 'T'));

                        let totalExpireDateLocal = new Date(totalExpireDate);
                        totalExpireDateLocal.setFullYear(totalExpireDate.getUTCFullYear(), totalExpireDate.getUTCMonth(),
                            totalExpireDate.getUTCDate());
                        totalExpireDateLocal.setHours(23, 59, 59, 999);

                        if (!expireDateLocal || expireDateLocal > totalExpireDateLocal) {
                            expireDateLocal = totalExpireDateLocal;
                        }
                    }

                    gift.expireDate = expireDateLocal;
                }

                const giftModel = new Model({
                    name: templateModel.get('name') || 'Coins',
                    thumb: templateModel.get('thumb'),
                    expireDate: gift.expireDate,
                    type: templateModel.get('type'),
                    quantity: gift.quantity || templateModel.get('quantity')
                });

                const giftWidget = new GiftWidget({
                    model: giftModel,
                    quantityEditable: isDraft && !!templateModel.get('custom_quantity_allowed'),
                    forceDefaultQuantity: templateModel.get('quantity'),
                    overImageMessage: isDraft ? 'Tap to change gift' : null,
                    additionalMessage: isDraft && templateModel.get('transformation_type') ?
                        'or go to receipt conversion' : '',
                    isAdditionalMessageInterative: isDraft && !!templateModel.get('transformation_type'),
                });

                if (isDraft) {
                    this.listenTo(giftModel, 'change:quantity', (m, quantity) => this.model.get('gift').quantity = quantity);

                    this.listenTo(giftWidget, 'image:click', () => this.selectGift());
                    this.listenTo(giftWidget, 'additionalMessage:click', () => {
                        new GiftModal({
                            startingReceiptAmount: this.model.get('gift').receipt_amount || 0,
                            model: templateModel,
                            showMessage: false
                        }).show()
                            .then(template => {
                                if (template && template.get('quantity')) {
                                    giftModel.set({ quantity: template.get('quantity') });

                                    if (template.get('receiptAmount') && template.get('transformation_type')) {
                                        this.model.set({
                                            gift: _.extend({}, this.model.get('gift'), {
                                                quantity: giftModel.get('quantity'),
                                                transformation_type: template.get('transformation_type') || null,
                                                receipt_amount: template.get('receiptAmount') || null,
                                                templateModel: template
                                            })
                                        });

                                        this.model.listenToOnce(giftModel, 'change:quantity',
                                            () => {
                                                this.model.get('gift').transformation_type = null;
                                                this.model.get('gift').receipt_amount = null;
                                            });
                                    }
                                }
                            })
                    });
                }

                this.showChildView('gift', giftWidget);
            } else {
                this.getRegion('gift').reset();
            }
        }
    }

    _displayReservationChange(options) {
        this.showChildView('reservationChange', new ReservationChangeCommentAdditionalData(options));
    }

    _discardReservationChanges() {
        if (_.isObject(this.viewModel.get('reservationActionResult'))) {
            return new ConfirmModalWindow({
                title: 'Discard changes?'
            })
                .show()
                .then(confirm => {
                    if (confirm && this.getRegion('reservationChange').hasView()) {
                        this.getRegion('reservationChange').empty();
                    }

                    return confirm;
                });
        }

        return Promise.resolve(true);
    }

    _toggleReservationChangesMode(value = null) {
        const oldValue = this.viewModel.get('editorMode');
        let newValue = oldValue !== EditorMode.ReservationChanges ? EditorMode.ReservationChanges : EditorMode.Basic;

        if (value !== null) {
            newValue = value ? EditorMode.ReservationChanges : EditorMode.Basic;
        }

        if (newValue == EditorMode.Basic) {
            this.viewModel.set('reservationAction', ReservationActions.None);
        }

        this.viewModel.set('editorMode', newValue);
    }

    _selectReservationChangeAction(action) {
        const reservationAction = this.viewModel.get('reservationAction');

        if (reservationAction != action) {
            this._setReservationChangeAction(action, reservationAction)
                .then(resultArray => {
                    const [result, resultOptions = null] = _.isArray(resultArray) ? resultArray : [resultArray];

                    if (resultOptions && resultOptions.action) {
                        if (resultOptions.action === ReservationActions.Decline) {
                            const commentModel = new MessageModel(result.comment);
                            this.trigger('comment:save', commentModel);
                            this.trigger('comment:create', commentModel);
                        } else if (resultOptions.action === ReservationActions.Suggest) {
                            this._selectReservationChangeAction(ReservationActions.Suggest);
                        }
                    } else if (result && _.isObject(result)) {
                        if (action == ReservationActions.Suggest) {
                            this._displayReservationChange({
                                model: new Model({
                                    ...result,
                                    status: this.model.get('isReservationAuthor') ?
                                        ReservationModel.Status.ClientSuggestedChanges :
                                        ReservationModel.Status.BusinessSuggestedChanges,
                                    previous_values: this.reservationModel.toJSON()
                                })
                            });
                        }

                        this.viewModel.set({
                            reservationAction: action,
                            reservationActionResult: result
                        });
                    } else {
                        this.viewModel.set({
                            reservationAction: ReservationActions.None,
                            reservationActionResult: null
                        });
                    }
                });
        } else {
            if (action == ReservationActions.Suggest) {
                this._discardReservationChanges()
                    .then(confirm => {
                        if (confirm) {
                            this.viewModel.set('reservationAction', ReservationActions.None);
                        }
                    });
            } else {
                this.viewModel.set('reservationAction', ReservationActions.None);
            }
        }
    }

    _setReservationChangeAction(action, prevAction = ReservationActions.None) {
        if (prevAction == ReservationActions.None) {
            switch (action) {
                case ReservationActions.Suggest:
                    return new ReservationEditorWindow({
                        model: this.reservationModel,
                        disableSavingButCloseWithData: true,
                        disableComment: true
                    }).show();
                case ReservationActions.Accept:
                    return Promise.resolve({ status: ReservationModel.Status.Confirmed });
                case ReservationActions.Decline:
                    return new ReservationDeclineModalWindow({
                        model: this.reservationModel,
                        message: this.model.get('text'),
                        isAuthor: this.model.get('isReservationAuthor'),
                        isReservationManager: this.model.get('isReservationManager')
                    })
                        .show()
                        .then(result => {
                            if (result === 'edit') {
                                return [
                                    null,
                                    { action: ReservationActions.Suggest }
                                ];
                            } else if (result instanceof ReservationModel) {
                                return [
                                    { comment: result.get('last_change_comment') },
                                    { action: ReservationActions.Decline }
                                ];
                            }
                        })
                        .catch(e => this.showError(e));
                    return Promise.resolve({
                        status: this.model.get('isReservationAuthor') ?
                            ReservationModel.Status.CancelledByClient :
                            ReservationModel.Status.RejectedByBusiness
                    });
            }
        } else if (prevAction == ReservationActions.Suggest) {
            return this._discardReservationChanges()
                .then(confirm => {
                    if (confirm) {
                        return this._setReservationChangeAction(action);
                    }

                    return null;
                })
        }

        return Promise.resolve(null);
    }
}

export default CommentEditor;
