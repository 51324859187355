import { Model } from '@b2cmessenger/backbone';
import B2CPlace from 'widgets/B2CPlace';

function isValidCoord(val) {
    if (!_.isNull(val)) {
        const n = Number(val);
        return !_.isNaN(n);
    } else {
        return false;
    }    
}

function parse(data) {
    if (data.categories) {
        const catsBase = new CategoryBase();
        data.categories = _.map(data.categories, c => (_.isObject(c) && c) || catsBase.getCat(c));
    }

    return data;
}

@Model.properties({
    defaults: {
        logoThumb: B2CPlace.const.default_thumb,
        ratings: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            t: {
                r: 0,
                v: 0
            }
        },
        categories: [],
        phone: []
    },

    computeds: {
        dist: {
            deps: ['point_latitude', 'point_longitude', 'adr_latitude', 'adr_longitude', '_dist'],
            get(point_latitude, point_longitude, adr_latitude, adr_longitude, _dist) {
                if (_.every([point_latitude, point_longitude, adr_latitude, adr_longitude], isValidCoord)) {
                    return geo.distance(point_latitude, point_longitude, adr_latitude, adr_longitude, 'M')
                } else {
                    return isValidCoord(_dist) ? _dist : null;
                }
            },
            set(val) {
                return { _dist: val };
            }
        },
        address: {
            deps: ['adr_postal_code', 'adr_street_name', 'adr_street_number', 'adr_city', 'adr_state', 'adr_country_code'],
            get: (adr_postal_code, adr_street_name, adr_street_number, adr_city, adr_state, adr_country_code) => 
                B2Cjs.getFormattedAddress(adr_postal_code, adr_street_name, adr_street_number, adr_city, adr_state, 
                    adr_country_code)
        },
        shortAddress: {
            deps: ['adr_street_name', 'adr_street_number', 'adr_city'],
            get: (adr_postal_code, adr_street_name, adr_street_number, adr_city, adr_state, adr_country_code) => 
                B2Cjs.getFormattedAddress(adr_postal_code, adr_street_name, adr_street_number, adr_city, adr_state, 
                    adr_country_code)
        }
    },
})
class PlaceModel extends Model {
    constructor(attrs, options) {
        super(parse(attrs), options);
    }

    parse(data) {
        return parse(data);
    }
}

export default PlaceModel;
