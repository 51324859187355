/**
 * Created by Alex on 05.03.2016.
 */
var PropertyBase;
(function(){
    var instance;
    PropertyBase = function Singleton() {
        if (instance) {
            return instance;
        }
        instance = this;
    },

    PropertyBase.prototype = {
        /**
         * Инициализирует базу свойств Плейсов
         */
        init : function() {
            if(app.localStorage)
                PropertyBrowserStorage.init();
        },
        /**
         * Обновляет локальную базу свойств мест/заведений на основе данных от сервера.
         * Новые свойства просто добавляются, старые перезатираются.
         *
         * @param server_properties {Array} Array of Property массив свойств от сервера
         */
        init_local_storage : function(server_properties) {
            if(app.localStorage)
                PropertyBrowserStorage.init_local_storage(server_properties);
        },
        /**
         * Возращается Property с указанным идентификатором
         *
         * @param id
         * @returns {Object} Property
         */
        get_property_by_id : function(id) {
            if(app.localStorage)
                return PropertyBrowserStorage.get_property_by_id(id);
        },
        /**
         * Возвращает массив Property с указанными идентификаторами
         *
         * @param ids {Array} Array of Integer - массив идентификаторов Property
         * @param sort_by_position {Boolean} true - результат будет отсортирован по атрибуту position по возврастанию
         * @param sort_by_title {Boolean} true - результат будет отсортирован по атрибуту title по возврастанию
         * @returns {Array} Array of Property - массив Property
         */
        get_properties_by_ids : function(ids, sort_by_position, sort_by_title) {
            if(app.localStorage)
                return PropertyBrowserStorage.get_properties_by_ids(ids, sort_by_position, sort_by_title);
        },
    }
}());

window.PropertyBase = PropertyBase; // TODO: remove globals, use module exports
// export default PropertyBase;
