import React, { Fragment, useEffect, useRef } from 'react';
import autosize from 'autosize';
import PropTypes from 'prop-types';

import './InputGroup.scss';

export const TextInput = ({ value, placeholder, onChange, onInput, disabled }) => {
    return (
        <input
            type="text"
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={onChange}
            onInput={onInput}
        />
    );
};

export const ControlledTextInput = (props) => {
    const { value, placeholder, onChange = _.noop, onInput, disabled } = props;
    const onChangeHandler = React.useCallback(
        (e) => {
            onChange(e.target.value);
        },
        [onChange]
    );

    return (
        <input
            type="text"
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={onChangeHandler}
            onInput={onInput}
        />
    );
};

export const CheckboxInput = ({ checked, onChange, disabled }) => {
    return <input type="checkbox" disabled={disabled} checked={checked} onChange={onChange} />;
};

export const TextareaInput = ({
    placeholder,
    className,
    value,
    onChange,
    onBlur,
    onInput,
    disabled,
    enableAutosize = true,
    maxLength,
}) => {
    const ref = useRef();

    useEffect(() => {
        if (enableAutosize) {
            autosize(ref.current);
        }
    }, [enableAutosize]);

    return (
        <textarea
            onBlur={onBlur}
            ref={ref}
            className={className || 'textarea-component'}
            value={value}
            placeholder={placeholder}
            maxLength={maxLength}
            disabled={disabled}
            onChange={onChange}
            onInput={onInput}
        />
    );
};

export const RadioInput = ({ options = [], onChange = _.noop, onClick = _.noop, disabled }) => {
    const inputEl = _(options).map((option, i) => {
        const { checked, label, value } = option;
        const id = _.isUndefined(option.id) ? i : `${option.id}-${i}`;

        return (
            <Fragment key={id}>
                <input
                    id={id}
                    type="radio"
                    value={value}
                    checked={checked}
                    disabled={disabled}
                    onChange={onChange}
                    onClick={onClick}
                    data-role="none"
                />
                <label htmlFor={id}>{label}</label>
            </Fragment>
        );
    });

    return inputEl;
};

export const SliderInput = ({ icon, children, checked, error, info, onClick, label, disabled }) => {
    return (
        <div className="slider-group">
            {icon && <i className={icon} />}
            {label && <label>{label}</label>}
            {children && <div className="children">{children}</div>}
            <div
                onClick={onClick}
                disabled={disabled}
                className="ui-slider ui-slider-switch ui-slider-track ui-shadow-inset ui-bar-inherit ui-corner-all"
            >
                <span
                    className="ui-slider-label ui-slider-label-a ui-btn-active"
                    role="img"
                    style={{ width: checked ? '100%' : '0%' }}
                >
                    Yes
                </span>
                <span
                    className="ui-slider-label ui-slider-label-b"
                    role="img"
                    style={{ width: checked ? '0%' : '100%' }}
                >
                    No
                </span>
                <div className="ui-slider-inneroffset">
                    <a
                        className="ui-slider-handle ui-btn ui-shadow ui-slider-handle-snapping"
                        role="slider"
                        aria-valuemin="0"
                        aria-valuemax="1"
                        aria-valuenow={checked ? 'true' : 'false'}
                        aria-valuetext={checked ? 'Yes' : 'No'}
                        title={checked ? 'Yes' : 'No'}
                        aria-labelledby="undefined-label"
                        style={{ left: checked ? '100%' : 0 }}
                    />
                </div>
            </div>
            {error && <p className="error">{error}</p>}
            {info && <p className="info">{info}</p>}
        </div>
    );
};

export const SearchInputWithInputGroup = (props) => {
    const {
        label,
        value,
        onChange,
        disabled,
        placeholder,
        onInput,
        className,
        icon: iconFromProp,
        error,
        info,
    } = props;
    const icon = _.isUndefined(iconFromProp) ? 'ion-ios-search-outline  ' : iconFromProp;

    return (
        <TextInputGroup
            label={label}
            className={['input-group search-input-group', className].filter(Boolean).join(' ')}
            icon={icon}
            error={error}
            info={info}
        >
            <TextInput
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                onChange={onChange}
                onInput={onInput}
            />
        </TextInputGroup>
    );
};
SearchInputWithInputGroup.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onInput: PropTypes.func,
    className: PropTypes.string,
    icon: PropTypes.string,
    error: PropTypes.string,
    info: PropTypes.string,
};

export const TextInputGroup = (props) => {
    const { icon, label, children, error, info, className = 'input-group' } = props;

    return (
        <div className={className}>
            {icon && <i className={icon} />}
            <label>
                {label && <p>{label}</p>}
                {children}
                {error && <p className="error">{error}</p>}
                {info && <p className="info">{info}</p>}
            </label>
        </div>
    );
};

export const SliderGroup = ({ info, caption, children, className, disabled, captionFirst = true }) => {
    const classNameValue = ['react-slider-group', 'slider-group', className, disabled && 'disabled']
        .filter(Boolean)
        .join(' ');

    return (
        <div className={classNameValue}>
            {captionFirst && caption && <span className="label multiline">{caption}</span>}
            {children}
            {!captionFirst && caption && <span className="label multiline">{caption}</span>}
            {info && <span className="info multiline">{info}</span>}
        </div>
    );
};

const InputGroup = (props) => {
    const { icon, label, children, error, info, className = 'input-group' } = props;

    return (
        <div className={className}>
            {icon && <i className={icon} />}
            {label && <label>{label}</label>}
            {children}
            {error && <p className="error">{error}</p>}
            {info && <p className="info">{info}</p>}
        </div>
    );
};

export const SwitchInput = ({ value, onChange, disabled, additionalClassNames = [] }) => {
    const checked = Boolean(value);
    const selectValue = checked ? 'on' : 'off';
    const className = ['ui-slider', 'ui-slider-switch', 'ui-slider-track', disabled && 'ui-disabled']
        .concat(additionalClassNames)
        .filter(Boolean)
        .join(' ');

    return (
        <>
            <select
                value={selectValue}
                onChange={(e) => onChange(e.target.value === 'on')}
                disabled={disabled}
                data-role="none"
            >
                <option value="off">No</option>
                <option value="on">Yes</option>
            </select>
            <div className={className} onClick={() => onChange(!checked)}>
                <span
                    className="ui-slider-label ui-slider-label-a ui-btn-active"
                    role="img"
                    style={{ width: checked ? '100%' : '0%' }}
                >
                    Yes
                </span>
                <span
                    className="ui-slider-label ui-slider-label-b"
                    role="img"
                    style={{ width: checked ? '0%' : '100%' }}
                >
                    No
                </span>
                <div className="ui-slider-inneroffset">
                    <a
                        className="ui-slider-handle ui-btn ui-shadow ui-slider-handle-snapping"
                        role="slider"
                        aria-valuemin="0"
                        aria-valuemax="1"
                        aria-valuenow={checked ? 'true' : 'false'}
                        aria-valuetext={checked ? 'Yes' : 'No'}
                        title={checked ? 'Yes' : 'No'}
                        aria-labelledby="undefined-label"
                        style={{ left: checked ? '100%' : 0 }}
                    />
                </div>
            </div>
        </>
    );
};

export const RadioSwitch = (props) => {
    const [uniqueId] = React.useState(_.uniqueId('radio-switch'));

    const className = React.useMemo(
        () => ['radio-switch', props.className, props.disabled && 'disabled'].filter(Boolean).join(' '),
        [props.className, props.disabled]
    );

    const onChange = React.useCallback(
        (e) => {
            props.onChange(e.target.value);
        },
        [props.onChange]
    );

    return (
        <div className={className}>
            <input
                id={uniqueId}
                type="checkbox"
                data-role="none"
                checked={props.value}
                disabled={props.disabled}
                onChange={onChange}
            />
            {React.Children.count(props.children) > 0 ? <label htmlFor={uniqueId}>{props.children}</label> : null}
        </div>
    );
};

export default InputGroup;
