import { Model } from '@b2cmessenger/backbone';

import Window from 'windows/Window';
import HeaderView from 'widgets/Header/Header';
import MapWidget from 'widgets/Map/Map';
import PlaceModel from 'models/PlaceModel';
import PlaceCollection from 'models/PlaceCollection';

import './PlaceMap.scss';

let PlaceMap = Window.extend({
    windowName: "place-map-window",
    className: "place-map-window",

    initialize() {
        this.mapModel = new Model({
            lat: this.model.get('adr_latitude'),
            lng: this.model.get('adr_longitude'),
            userLat: geo.getCurrentPosition().lt,
            userLng: geo.getCurrentPosition().lg,
        });

        this.listenTo(this.model, 'change:adr_latitude', () => this.mapModel.set('lat', this.model.get('adr_latitude')));
        this.listenTo(this.model, 'change:adr_longitude', () => this.mapModel.set('lng', this.model.get('adr_longitude')));

        geo.add_callback_curr_user_pos_change((lt, lg) => {
            this.mapModel.set({
                userLat: lt,
                userLng: lg,
            });
        });
    },

    onRender() {
        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: 'Place map'
        })
        this.listenTo(headerView, 'back:click', view => this.close());
        this.header.show(headerView);

        let mapView = new MapWidget({
            model: this.mapModel,
            collection: new PlaceCollection([this.model])
        });
        this.content.show(mapView);

        this.$el.enhanceWithin();

        mapView.activate(this.model);
    }
});


export default PlaceMap;
