var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (Status, id, lastSendAt, nextSendAt, paused, status, title) {let statusClass = 'unknown', last = null, next = null;

switch (status) {
    case Status.Scheduled:
        if (paused) {
            statusClass = 'paused';
        } else {
            statusClass = 'scheduled';
            if (nextSendAt) {
                next = nextSendAt.toLocaleString(undefined, {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                });
            }
        }
        break;
    case Status.InProgress:
        if (paused) {
            statusClass = 'paused';
        } else {
            statusClass = 'in-progress';
            if (nextSendAt) {
                next = nextSendAt.toLocaleString(undefined, {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                });
            }
            if (lastSendAt) {
              last = lastSendAt.toLocaleString(undefined, {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
              });
          }
        }
        break;
    case Status.Done:
        statusClass = 'done';
        if (lastSendAt) {
            last = lastSendAt.toLocaleString(undefined, {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        }
        break;
    case Status.Draft:
    case Status.Unknown:
    default:
        statusClass = 'unknown';
        break;
}

pug_html = pug_html + "\u003Ci" + (pug.attr("class", pug.classes(["ion-ios-radio-outline",('status-' + statusClass)], [false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003Cspan class=\"label\"\u003E Target: \u003C\u002Fspan\u003E\u003Cspan" + (pug.attr("class", pug.classes(["target",('status-' + statusClass)], [false,true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = title || '#' + id) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (last || next) {
pug_html = pug_html + "\u003Ci class=\"ion-ios-arrow-down\"\u003E\u003C\u002Fi\u003E\u003Ci class=\"ion-ios-arrow-up\"\u003E\u003C\u002Fi\u003E";
}
if (last) {
pug_html = pug_html + "\u003Cspan class=\"last-broadcast\" data-js-last\u003E" + (pug.escape(null == (pug_interp = last) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if (next) {
pug_html = pug_html + "\u003Cspan class=\"next-broadcast\" data-js-next\u003E" + (pug.escape(null == (pug_interp = next) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}}.call(this,"Status" in locals_for_with?locals_for_with.Status:typeof Status!=="undefined"?Status:undefined,"id" in locals_for_with?locals_for_with.id:typeof id!=="undefined"?id:undefined,"lastSendAt" in locals_for_with?locals_for_with.lastSendAt:typeof lastSendAt!=="undefined"?lastSendAt:undefined,"nextSendAt" in locals_for_with?locals_for_with.nextSendAt:typeof nextSendAt!=="undefined"?nextSendAt:undefined,"paused" in locals_for_with?locals_for_with.paused:typeof paused!=="undefined"?paused:undefined,"status" in locals_for_with?locals_for_with.status:typeof status!=="undefined"?status:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return pug_html;};
module.exports = template;