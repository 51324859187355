/**
 * Created by Alex on 13.03.2016.
 */
// B2C Reporter
(function( $ ){
    var methods = {
        init : function( options ) {

            return this.each(function(){

                var $this = $(this),
                    data = $this.data('b2creporter');

                if ( ! data ) {

                    data = {};
                    data.$this = $this;
                    data.options = $.extend(true, {}, $.fn.b2creporter.defaults, options);

                    $(this).data('b2creporter', data);

                    $this.addClass(data.options.cl_main);

                    $.fn.b2creporter.init(data);
                }
            });
        },
        close : function() {
            return this.each(function(){
                var $this = $(this),
                    data = $this.data('b2creporter');
                $.fn.b2creporter.close(data);
            });
        },
        show : function() {
            return this.each(function(){
                var $this = $(this),
                    data = $this.data('b2creporter');
                $.fn.b2creporter.show(data);
            });
        },
        getUserCont : function() {
            var $first = this.first();
            var data = $first.data('b2creporter');
            return data.jqUserCont;
        },
        send: function () {
            return this.each(function () {
                var $this = $(this),
                    data = $this.data('b2creporter');
                $.fn.b2creporter.onSendClick(data);
            });
        }
    };

    $.fn.b2creporter = function( method ) {

        if ( methods[method] ) {
            return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ));
        } else if ( typeof method === 'object' || ! method ) {
            return methods.init.apply( this, arguments );
        } else {
            $.error( 'Cannot find ' +  method + ' in jQuery.b2creporter' );
        }

    };

    $.fn.b2creporter.defaults = {
        onCancel : null, // onCancel()
        onClose : null, // onClose()
        onSend : null, // onSend(typeid, comment, callBackOnSuccess(), callBackOnError(message) )
        cl_main : 'b2creporter',
        cl_btn_cancel : 'b2c_btn_cancel',
        cl_btn_send : 'b2c_btn_send',
        cl_header : 'b2crepheader',
        cl_comment : 'b2crepcomment',
        cl_error : 'b2cerror',
        cl_types_cont : 'b2c_types_cont',
        cl_type : 'b2c_type',
        cl_type_set : 'set',
        cl_type_name : 'b2c_type_name',
        cl_type_input : 'b2c_type_input',
        cl_info_popup : 'b2c_info_popup',
        cl_info_popup_message : 'message',
        headerText : 'Report',
        commentPlaceholder : 'comment',
        types : [], // array of {id : 12, name, isdef : false, needc : true } where needc - is comment requared for this type id
        wrap_buttons: false,
        showMessage: null,
    };

    $.fn.b2creporter.init = function(data) {
        var html = $.fn.b2creporter.buildHTML(data);
        data.$this.b2cpopup({
            html_content : html,
            lightMode : true,
            onClose : function() { $.fn.b2creporter.onMainPopupClose(data, true); }
        });
        //data.$this.append($(html));
        data.jqComment = data.$this.find('.'+data.options.cl_comment);

        $.fn.b2creporter.addHandlers(data);
    };

    $.fn.b2creporter.buildHTML = function(data) {
        return $.fn.b2creporter.buildHTMLForHeader(data) + $.fn.b2creporter.buildHTMLForTypes(data) + $.fn.b2creporter.buildHTMLForComment(data) + (data.options.wrap_buttons ? '<div class="yes_no_cont">' : '') + '<span class="' + data.options.cl_btn_cancel + '">Cancel</span><span class="' + data.options.cl_btn_send + '">Send</span>' + (data.options.wrap_buttons ? '</div>' : '');
    };

    $.fn.b2creporter.buildHTMLForHeader = function(data) {
        if (data.options.headerText == null || data.options.headerText == '')
            return '';
        return '<p class="'+quoteattr(data.options.cl_header)+'">'+data.options.headerText+'</p>';
    };

    $.fn.b2creporter.buildHTMLForComment = function(data) {
        return '<textarea class="'+quoteattr(data.options.cl_comment)+'" placeholder="'+quoteattr(data.options.commentPlaceholder)+'" rows=1></textarea>';
    };

    $.fn.b2creporter.buildHTMLForTypes = function(data) {
        var html = '<div class="'+quoteattr(data.options.cl_types_cont)+'">';
        for(var i=0; i<data.options.types.length; i++) {
            html += $.fn.b2creporter.buildHTMLForType(data.options.types[i], data);
        }

        html += '</div>'
        return html;
    };

    $.fn.b2creporter.buildHTMLForType = function(type, data) {
        var checked = '', set = '';
        if (type.isdef) {
            checked = ' checked="checked"';
            data.currType = type;
            set = ' '+ data.options.cl_type_set;
        }
        return '<span class="'+quoteattr(data.options.cl_type)+set+'" data-id="'+quoteattr(type.id)+'"><input class="'+quoteattr(data.options.cl_type_input)+'" type="radio" '+checked+'><span class="'+quoteattr(data.options.cl_type_name)+'">'+escapeHtml(type.name)+'</span></span>';
    };

    $.fn.b2creporter.addHandlers = function(data) {
        data.$this.find('.'+data.options.cl_btn_cancel).click(function(event) { $.fn.b2creporter.onCancelClick(data) });
        data.$this.find('.'+data.options.cl_btn_send).click(function(event) { $.fn.b2creporter.onSendClick(data) });
        data.$this.find('.'+data.options.cl_type).click(function(event) { $.fn.b2creporter.onTypeClick($(this), data) });

        autosize(data.jqComment);
        data.jqComment.focusout(function(){ $.fn.b2creporter.checkComment(data); });
    };

    $.fn.b2creporter.onTypeClick = function(jqType, data) {
        if(jqType.hasClass(data.options.cl_type_set))
            return;
        var jqTypeSetPrev = $.fn.b2creporter.getCheckedType(data);
        if(jqTypeSetPrev.length == 1) {
            jqTypeSetPrev.removeClass(data.options.cl_type_set);
            jqTypeSetPrev.find('.'+data.options.cl_type_input).prop('checked', false);
        }
        jqType.addClass(data.options.cl_type_set);
        jqType.find('.'+data.options.cl_type_input).prop('checked', true);
        data.currType = $.fn.b2creporter.getTypeById(jqType.attr('data-id'), data);
        $.fn.b2creporter.checkComment(data);
    };

    $.fn.b2creporter.onCancelClick = function(data) {
        $.fn.b2creporter.close(data);
        if(data.options.onCancel != null) {
            data.options.onCancel();
        }
    };

    $.fn.b2creporter.onSendClick = function(data) {
        if(data.currType.needc && data.jqComment.val() == '') {
            $.fn.b2creporter.showMessage(data, 'Please, fill comment');
        } else if(data.options.onSend != null) {
            var callBackOnSuccess = function() { $.fn.b2creporter.callBackOnReportSuccess(data); };
            var callBackOnError = function(message) { $.fn.b2creporter.callBackOnReportError(data, message); };
            data.options.onSend(data.currType.id, data.jqComment.val(), callBackOnSuccess, callBackOnError);
        }
    };

    $.fn.b2creporter.onMainPopupClose = function(data) {
        $.fn.b2creporter.close(data, true);
    };

    $.fn.b2creporter.callBackOnReportSuccess = function(data) {
        $.fn.b2creporter.showMessage(data, 'Report sent', function(){ $.fn.b2creporter.close(data) });
    };

    $.fn.b2creporter.callBackOnReportError = function(data, message) {
        $.fn.b2creporter.showMessage(data, message, null, true);
    };

    $.fn.b2creporter.showMessage = function (data, message, onClose, isError) {
        if (data.options.showMessage) return data.options.showMessage(message, onClose, isError);

        if (data.jqInfoPopup == null) {
            data.jqInfoPopup = $('<div class="'+escapeattr(data.options.cl_info_popup)+'"></div>').appendTo(data.$this).b2cpopup({
                html_content : '<p class="'+escapeattr(data.options.cl_info_popup)+'"></p>',
                lightMode : true
            });
            data.jqInfoPopup.b2cpopup('getUserCont').click(function(event) { data.jqInfoPopup.b2cpopup('close'); });
        }
        data.jqInfoPopup.b2cpopup('option', 'onClose', onClose);
        data.jqInfoPopup.b2cpopup('getUserCont').text(message);
        data.jqInfoPopup.b2cpopup('show');
    };

    $.fn.b2creporter.show = function(data){
        data.$this.b2cpopup('show');
    };

    $.fn.b2creporter.close = function(data, isCallFrom_b2cpopup){
        if(!isCallFrom_b2cpopup)
            data.$this.b2cpopup('close');
        if(data.options.onClose != null)
            data.options.onClose(data);
    };

    $.fn.b2creporter.checkComment = function(data) {
        if(data.currType.needc && data.jqComment.val() == '') {
            data.jqComment.addClass(data.options.cl_error);
            return false;
        } else {
            data.jqComment.removeClass(data.options.cl_error);
            return true;
        }
    };

    $.fn.b2creporter.getTypeById = function(id, data) {
        var types = data.options.types;
        var notFound = true, i = 0, type;
        while(i<types.length && notFound) {
            type = types[i];
            if (id == type.id)
                notFound = false;
            else
                i++;
        }
        if (notFound)
            return null;
        else
            return type;
    };

    $.fn.b2creporter.getCheckedType = function(data) {
        return data.$this.find('.'+data.options.cl_type+'.'+data.options.cl_type_set);
    };

    $.fn.b2creporter.getCheckedTypeId = function(data) {
        return $.fn.b2creporter.getCheckedType(data).attr('data-id');
    };

})( jQuery );