import settings from 'settings';

import Window from 'windows/Window';
import FooterView from 'widgets/Footer/Footer';

import template from './Report.jade';
import './Report.scss';
import HeaderView from 'widgets/Header/Header';

const ReportWindow = Window.extend({
    windowName: "report-window",
    className: "report-window",
    template,

    ui: {
        content: '[data-js-content]'
    },

    onRender() {
        let headerView = new HeaderView({ 
            leftButtons: ['back'],
            title: 'Report this place' 
        });
        this.listenTo(headerView, 'back:click', view => this.$reporter.b2creporter('close'));
        this.header.show(headerView);

        let footerView = new FooterView({
            buttons: [{
                id: 'cancel',
                text: 'CANCEL',
                icn: 'empty'
            },{
                id: 'report',
                text: 'REPORT THIS PLACE',
                icn: 'empty'
            }]
        });
        this.listenTo(footerView, 'cancel:click', view => this.$reporter.b2creporter('close'));
        this.listenTo(footerView, 'report:click', view => this.$reporter.b2creporter('send'));
        this.footer.show(footerView);
    },

    onShow(place) {
        this.$reporter = $('<div class="report_cont"></div>').prependTo(this.ui.content).b2creporter({
            showMessage: (message, onClose, isError) => { 
                this.showMessage(message, { isError }).then(() => onClose && onClose());
            },
            onCancel : null,
            onClose : (data) => { this.close(data) },
            onSend : (typeid, comment, callBackOnSuccess, callBackOnError) => {
                Server.callServer({
                    url: settings.host + settings.serv_place.report,
                    type: "POST",
                    data: {
                        id : place.id,
                        type : typeid,
                        comment : comment
                    },
                    success : callBackOnSuccess,
                    error : (jqXHR, textStatus, errorThrown) => {
                        this.showError(jqXHR, textStatus, errorThrown);

                        callBackOnError(message);
                    },
                });
            },
            headerText : 'Report this place',
            commentPlaceholder : 'Your comment',
            types : [
                {id : 1, name : 'The place information is incorrect', isdef : true, needc : false },
                {id : 2, name : 'The place doesn’t exist', isdef : false, needc : false },
                {id : 3, name : 'Inappropriate', isdef : false, needc : false },
                {id : 4, name : 'Spam', isdef : false, needc : false },
                {id : 5, name : 'Other', isdef : false, needc : true },
            ], // array of {id : 12, name, isdef : false, needc : true } where needc - is comment requared for this type id
        }).b2creporter('show');
    },

    cancel() {
        this.$reporter.b2creporter('close');
    }
});

export default ReportWindow;
