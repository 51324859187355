import Scrollparent from 'scrollparent';

function scrollIntoCenter(reserved, options) {
    _.defaults(options || (options = {}), {
        windowHeightOffset: 0,
        windowWidthOffset: 0,
    });

    let window = this.ownerDocument.defaultView,
        parent = Scrollparent(this),
        rect = this.getBoundingClientRect();

    let offsetTop = (window.innerHeight + options.windowHeightOffset) / 2 - rect.top - rect.height / 2,
        offsetLeft = (window.innerWidth + options.windowWidthOffset) / 2 - rect.left - rect.width / 2;

    parent.scrollTop = parent.scrollTop - offsetTop;
    parent.scrollLeft = parent.scrollLeft - offsetLeft;
}


if(Element.prototype.scrollIntoCenter === undefined) {
    Element.prototype.scrollIntoCenter = scrollIntoCenter;
}

export default scrollIntoCenter;