import settings from "settings";
import {userDeviceInfo} from "utils/UserDeviceInfo";
import PlatformFeature from "./PlatformFeature";

const AppleSignInScope = {
    FullName: 0,
    Email: 1,
};
class AppleSignIn extends PlatformFeature {
    constructor(options) {
        super(options);

        if (!this.options.scopes) {
            _.extend(this.options, {
                scopes: [AppleSignInScope.FullName, AppleSignInScope.Email]
            });
        }
    }

    async initialize() {
///////////////////////
///////////////////////////////
/////////////////////////////////////////////////////////////////////////////
////////////////
////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////
/////////
/////////////////
        this.initializePromise = Promise.reject(AppleSignIn.UnSupported);
//////////////////

        return this.initializePromise.then((apiSource) => {
            this.ready = true;
            this.api = apiSource;
        });
    }

    async authorize() {
        await this.ensureInitialized();
        return this.signIn();
    }

    async signIn() {
        await this.ensureInitialized();
        const signIn$ = new Promise((resolve, reject) => {
            this.api.signin({requestedScopes: this.options.scopes}, resolve, reject);
        });

        try {
            const signInResult = await signIn$;
            const {fullName, identityToken = null} = signInResult;

            if (identityToken === null) {
                throw `AppleSignIn error: no \`identityToken\` found in sign-in result ${JSON.stringify(signInResult)}`;
            }

            const {givenName = '', familyName = ''} = fullName;
            return AppleSignIn.performServerLogin(identityToken, givenName, familyName);
        } catch (rejection) {
            if (_.isObject(rejection)) {
                const {code = null} = rejection;

                switch (Number(code)) {
                    case 1001:
                    case 1003:
                        throw 'The user canceled the sign-in flow.';

                    default:
                        throw `AppleSignIn error: ${JSON.stringify(rejection)}`;
                }
            }
        }
    }

    static performServerLogin(token, firstname = '', lastname = '') {
        const data = {
            firstname, lastname,
            jwt: token,
            info: userDeviceInfo.getInfo()
        };

        return new Promise((resolve, reject) => {
            Server.callServer({
                url: settings.host + settings.serv_user.apple_login,
                dataType: 'json',
                method: 'POST',
                data,
                success: resolve,
                error: reject
            });
        })
    }
}

export default new AppleSignIn();
