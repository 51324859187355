import React, { useState, useCallback, useEffect } from 'react';

import MenuItemsWithControl from 'components/menu/MenuItems/MenuItemsWithControl';
import MenuCategories from 'components/menu/MenuCategories/MenuCategories';
import { WindowContent, WindowFooter, WindowHeader } from 'components/window/Window';
import Header from 'components/UI/Header/Header';
import { BackButton } from 'components/UI/Button/Button';
import Footer from 'components/UI/Footer/Footer';
import KeyboardPadding from 'components/KeyboardPadding';

import './MenuAdditionsManagement.scss';
import ReactWindow from 'windows/ReactWindow';

const MenuAdditionsManagement = (props) => {
    const {
        title,
        additionCategories,
        additionItems,
        additions: additionCustomization = [],
        setAdditionItems,
        closeWindow,
    } = props;

    const isSingleAdditionCategory = additionCategories.length === 1;

    const [selectedCategoryId, setSelectedCategoryId] = useState();
    const [additions, setAdditions] = useState(additionCustomization);

    useEffect(() => {
        if (additionCategories.length === 1) {
            setSelectedCategoryId(additionCategories[0].id);
        }
    }, [additionCategories]);

    const onChangeCount = ({ item, count }) => {
        setAdditions((prev) => {
            const idx = _.findIndex(prev, (a) => a.id === item.id);
            const additions =
                idx !== -1
                    ? prev.map((i, itemIdx) => (idx === itemIdx ? (count ? { ...i, count } : null) : i)).filter(Boolean)
                    : [...prev, { id: item.id, count }];
            return additions;
        });
    };

    let content;

    if (selectedCategoryId) {
        const addOnItems = additionItems.filter((i) => i.categoryId === selectedCategoryId);
        const onSelectItem = (item_id) => {
            const { count } = additions.find((a) => a.id === item_id) || { count: 0 };

            if (Number(count) === 0) {
                onChangeCount({ item: { id: item_id }, count: 1 });
            }
        };

        content = (
            <>
                {!isSingleAdditionCategory && (
                    <MenuCategories
                        onSelectCategory={() => setSelectedCategoryId(null)}
                        categories={[
                            {
                                name: 'Back to add-on categories selection',
                                icon: 'ion-ios-arrow-round-back',
                            },
                        ]}
                    />
                )}
                <MenuItemsWithControl
                    additions
                    items={addOnItems}
                    itemsCountMap={additions}
                    onChangeCount={onChangeCount}
                    onSelectItem={onSelectItem}
                />
            </>
        );
    } else {
        content = (
            <>
                <div className="menu-browser-message">Navigate through categories by clicking them.</div>
                <MenuCategories
                    categories={additionCategories}
                    onSelectCategory={(id) => {
                        setSelectedCategoryId(id);
                    }}
                />
            </>
        );
    }
    const buttons = [
        { text: 'CLOSE', id: 'CLOSE', onClick: () => closeWindow() },
        {
            text: 'SAVE',
            id: 'SAVE',
            onClick: useCallback(() => {
                setAdditionItems(additions);
                closeWindow();
            }, [additions]),
        },
    ];
    return (
        <>
            <WindowHeader>
                <Header>
                    <BackButton onClick={() => closeWindow()} />
                    <h1 className="title">{title || 'Select addition items'}</h1>
                </Header>
            </WindowHeader>
            <WindowContent>{content}</WindowContent>
            <WindowFooter>
                <Footer buttons={buttons} />
            </WindowFooter>
            <KeyboardPadding />
        </>
    );
};

const MenuAdditionsManagementWindow = ReactWindow.extend({
    constructor: function MenuAdditionsManagementWindow(props) {
        const options = {
            props,
            component: MenuAdditionsManagement,
            name: 'MenuAdditionsManagement',
        };

        return ReactWindow.apply(this, [options]);
    },
});

export default MenuAdditionsManagementWindow;
