import { Marionette } from '@b2cmessenger/backbone';
import template from './EmployeesChild.jade';

import getNextPrettyColor from 'utils/randomPrettyColor';

export default Marionette.ItemView.extend({
    className: 'employee-child',
    template: template,
    events: {
        'click': 'onClick'
    },
    ui: {
        avatar: '[data-js-avatar]'
    },
    onRender() {
        let avatarUrl = this.model.get('user').avatarThumb;

        if(avatarUrl){
            this.ui.avatar.css('background-image', `url('${avatarUrl}')`);
        } else {
            this.ui.avatar.addClass('empty');
        }

        this.el.style['border-color'] = getNextPrettyColor(this.model.get('user').firstname + ' ' + this.model.get('user').lastname);
    },
    onClick() {
        if(this.options.clickable) this.trigger('click:user', this.model);
    }
});
