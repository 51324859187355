import _ from 'underscore';
import { Model, CollectionView, ItemView } from '@b2cmessenger/backbone';

import Page from 'pages/Page';
import HeaderView from 'widgets/Header/Header';
import EvidenceModel from 'models/EvidenceModel';
import EvidenceCollection from 'models/EvidenceCollection';
import EvidenceWidget from 'widgets/Evidence/Evidence';
import LeftMenuWindow from 'windows/LeftMenu/LeftMenu';

import template from './EvidenceList.jade';
import './EvidenceList.scss';

let FilterModel = Model.extend({
    defaults: () => _.create({
        type: EvidenceModel.Type.Other,
        only_valid: 0,
        need_users: 1,
    })
});

let EvidenceCollectionWidget = CollectionView.extend({
    childView: EvidenceWidget,
    className: "evidence-collection-widget widget",
    filter: m => !m.get('isDraft')
});

let EvidenceListPage = Page.extend({
    getPlaceId() {
        return this.viewModel.get('place_id');
    },

    getType() {
        return this.viewModel.get('type');
    },

    attributes: { id: 'page_evidences_list' },
    template: template,
    className: "evidence-list-page",

    regions: {
        header: '[data-js-header]',
        content: '[data-js-content]',
        footer: '[data-js-footer]',
    },

    initialize(options) {
        this.viewModel = new FilterModel();
        if(options.place_id) this.viewModel.set({ place_id: options.place_id });
        if(!this.collection)
            this.collection = new EvidenceCollection([], this.viewModel.attributes);
    },

    onRender() {
        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: "Evidences",
        });
        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.header.show(headerView);

        this.content.show(new EvidenceCollectionWidget({ collection: this.collection }));
    },

    show(options) {
        this.viewModel.set({
            place_id: options.place.id,
            type: options.type
        });

        return Page.prototype.show.apply(this, options);
    },

    onShow() {
        if(this.viewModel.has('place_id'))
            this.collection.fetch(this.viewModel.attributes);

        this.collection.listenTo(this.viewModel, 'change', () => this.collection.fetch(this.viewModel.attributes));

        return Page.prototype.onShow.call(this);
    },

    onHide() {
        this.collection.stopListening(this.viewModel, 'change');
        return Page.prototype.onHide.call(this);
    }
});

export default EvidenceListPage;
