var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (isReservation, isSolution, tags) {pug_html = pug_html + "\u003Cdiv class=\"tags\"\u003E";
if (tags) {
// iterate tags
;(function(){
  var $$obj = tags;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var tag = $$obj[pug_index0];
pug_html = pug_html + "\u003Cspan class=\"tag\"\u003E" + (pug.escape(null == (pug_interp = tag) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var tag = $$obj[pug_index0];
pug_html = pug_html + "\u003Cspan class=\"tag\"\u003E" + (pug.escape(null == (pug_interp = tag) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
    }
  }
}).call(this);

}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"panel\"\u003E";
if (isSolution) {
pug_html = pug_html + "\u003Cbutton class=\"solution-rating\" data-js-btn-solution-rating\u003E\u003Cspan class=\"rating\" data-js-solution-rating\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"votes\" data-js-solution-votes\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E";
}
else
if (!isReservation) {
pug_html = pug_html + "\u003Cbutton class=\"likes\" data-js-btn-like\u003E\u003C\u002Fbutton\u003E\u003Cbutton class=\"dislikes\" data-js-btn-dislike\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003Cbutton class=\"menu ion-ios-more\" data-js-btn-menu\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"menu\" data-js-menu\u003E\u003C\u002Fdiv\u003E\u003Cbutton class=\"comments\" data-js-btn-comments\u003E\u003C\u002Fbutton\u003E\u003Cbutton class=\"reply ion-ios-undo-outline\" data-js-btn-reply\u003EReply\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E";}.call(this,"isReservation" in locals_for_with?locals_for_with.isReservation:typeof isReservation!=="undefined"?isReservation:undefined,"isSolution" in locals_for_with?locals_for_with.isSolution:typeof isSolution!=="undefined"?isSolution:undefined,"tags" in locals_for_with?locals_for_with.tags:typeof tags!=="undefined"?tags:undefined));;return pug_html;};
module.exports = template;