var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (opt_children_count, opt_guests_count, opt_smoking_area, vm_address, vm_imageUrl, vm_name, vm_showDate) {pug_html = pug_html + "\u003Cdiv class=\"head\"\u003E\u003Cdiv" + (" class=\"logo\""+pug.attr("data-js-logo", true, true, true)+pug.attr("style", pug.style("background-image:url("+(vm_imageUrl)+")"), true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"content\"\u003E\u003Cp class=\"name\" data-js-name\u003E" + (pug.escape(null == (pug_interp = vm_name) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003Cp class=\"start\"\u003E";
if ((vm_showDate)) {
pug_html = pug_html + "\u003Ci class=\"ion-ios-calendar-outline\"\u003E\u003C\u002Fi\u003E";
}
else {
pug_html = pug_html + "\u003Ci class=\"ion-ios-time-outline\"\u003E\u003C\u002Fi\u003E";
}
pug_html = pug_html + "\u003Cspan data-js-start\u003E\u003C\u002Fspan\u003E\u003C\u002Fp\u003E";
if (vm_address) {
pug_html = pug_html + "\u003Cp class=\"address\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = vm_address) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cbutton class=\"btn_mapview btn-pin\" data-js-address-map data-role=\"none\"\u003E\u003Ci class=\"ion-ios-pin-outline\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"reservation\"\u003E\u003Cdiv class=\"reservation__item\"\u003E\u003Ci class=\"ion-ios-time-outline\"\u003E\u003C\u002Fi\u003E\u003Cspan data-js-duration\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"reservation__item\"\u003E\u003Ci class=\"ion-ios-people-outline\"\u003E\u003C\u002Fi\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = opt_guests_count) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
if (opt_children_count) {
pug_html = pug_html + "\u003Cdiv class=\"reservation__item\"\u003E\u003Ci class=\"icon-good_for_kids\"\u003E\u003C\u002Fi\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = opt_children_count) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
}
if (opt_smoking_area) {
pug_html = pug_html + "\u003Cdiv class=\"reservation__item\"\u003E\u003Ci" + (pug.attr("class", pug.classes(["icon-smoking",opt_smoking_area], [false,true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"status\" data-js-status-text\u003E\u003C\u002Fdiv\u003E";}.call(this,"opt_children_count" in locals_for_with?locals_for_with.opt_children_count:typeof opt_children_count!=="undefined"?opt_children_count:undefined,"opt_guests_count" in locals_for_with?locals_for_with.opt_guests_count:typeof opt_guests_count!=="undefined"?opt_guests_count:undefined,"opt_smoking_area" in locals_for_with?locals_for_with.opt_smoking_area:typeof opt_smoking_area!=="undefined"?opt_smoking_area:undefined,"vm_address" in locals_for_with?locals_for_with.vm_address:typeof vm_address!=="undefined"?vm_address:undefined,"vm_imageUrl" in locals_for_with?locals_for_with.vm_imageUrl:typeof vm_imageUrl!=="undefined"?vm_imageUrl:undefined,"vm_name" in locals_for_with?locals_for_with.vm_name:typeof vm_name!=="undefined"?vm_name:undefined,"vm_showDate" in locals_for_with?locals_for_with.vm_showDate:typeof vm_showDate!=="undefined"?vm_showDate:undefined));;return pug_html;};
module.exports = template;