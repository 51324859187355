import { ItemView, Optional, Required, ViewModel } from "@b2cmessenger/backbone";
import { getNextPrettyColor } from "utils/crutches";

import ReservationModel from 'models/ReservationModel';
import moment from 'moment';
import humanize from 'humanize-duration';

import template from './ReservationItem.jade';
import './ReservationItem.scss'

const Type = {
    Business: 'business',
    User: 'user'
};

@ItemView.options({
    model: Required,
    type: Required,
    userModel: Optional,
    placeModel: Optional,
    showDate: false,
    parentViewModel: Optional,
})
@ItemView.properties({
    template,
    tagName: 'div',
    className: 'widget reservation-item-widget',

    ui: {
        start: '[data-js-start]',
        logo: '[data-js-logo]',
        duration: '[data-js-duration]',
        address: '[data-js-address]',
        addressMapBtn: '[data-js-address-map]',
        name: '[data-js-name]',
        statusText: '[data-js-status-text]'
    },

    computeds: {
        c_startText: {
            deps: ['vm_showDate', 'date_start_local', 'date_end_local'],
            get(vm_showDate, date_start_local, date_end_local) {
                const dateStartLocal = B2Cjs.datetimeServerLocalToJS(date_start_local);
                if (dateStartLocal) {
                    if (vm_showDate) {
                        return dateStartLocal.toLocaleDateString(undefined,
                            { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' });
                    } else {
                        const dateEndLocal = B2Cjs.datetimeServerLocalToJS(date_end_local);
                        const timeFormat = { hour: 'numeric', minute: '2-digit' };
                        const getFormattedTime = date => date.toLocaleTimeString(undefined, timeFormat);

                        if (
                            moment(dateStartLocal).isSame(moment(dateEndLocal), 'day')
                            || dateEndLocal.valueOf() - dateStartLocal.valueOf() <= 12 * 3600 * 1000
                        ) {
                            return `${getFormattedTime(dateStartLocal)} - ${getFormattedTime(dateEndLocal)}`;
                        } else {
                            return getFormattedTime(dateStartLocal);
                        }
                    }
                } else {
                    return '';
                }
            }
        },
        c_durationText: {
            deps: ['date_start_local', 'date_end_local'],
            get: (date_start_local, date_end_local) => {
                const dateStartLocal = B2Cjs.datetimeServerLocalToJS(date_start_local);
                const dateEndLocal = B2Cjs.datetimeServerLocalToJS(date_end_local);

                if (dateStartLocal && dateEndLocal) {
                    return humanize(dateEndLocal.valueOf() - dateStartLocal.valueOf(),
                        { largest: 2, units: ['d', 'h', 'm'] });
                } else {
                    return ''
                }
            }
        },
        c_statusText: {
            deps: ['status'],
            get: status => ReservationModel.StatusText[status] || 'Unknown'
        },
        c_statusClasses: {
            deps: ['status'],
            get: status => {
                return {
                    'status__warning': [
                        ReservationModel.Status.New,
                        ReservationModel.Status.BusinessSuggestedChanges,
                        ReservationModel.Status.ClientSuggestedChanges
                    ].indexOf(status) != -1,
                    'status__negative': [
                        ReservationModel.Status.CancelledByClient,
                        ReservationModel.Status.RejectedByBusiness
                    ].indexOf(status) != -1,
                    'status__positive': status == ReservationModel.Status.Confirmed,
                    'status__active':   status == ReservationModel.Status.Completed
                };
            }
        }
    },

    bindings: {
        ':el': 'css:{"border-color":vm_color}',
        '@ui.start': 'text:c_startText',
        '@ui.duration': 'text:c_durationText',
        '@ui.statusText': 'text:c_statusText,classes:c_statusClasses'
    },

    events: {
        'click'() {
            this.trigger('click', this.model);
        },
        'click @ui.addressMapBtn'() {
            this.trigger('map:click', this.model, this.placeModel);
        },
        'click @ui.logo'(e) {
            e.stopImmediatePropagation();

            if (this.userModel) {
                this.trigger('user:click', this.model, this.userModel);
            } else if (this.placeModel) {
                this.trigger('place:click', this.model, this.placeModel);
            }
        }
    },

    viewModelEvents: {
        'change:vm_imageUrl change:vm_name change:vm_color change:vm_address change:vm_showDate'() {
            this._debouncedRender();
        }
    },

    modelEvents: {
        'change:opt_smoking_area change:opt_guests_count change:opt_children_count'() {
            this._debouncedRender();
        }
    }
})
class ReservationItem extends ItemView {
    initialize() {
        if (!_.contains(Type, this.options.type)) {
            this.options.type = this.options.placeModel ? Type.User : Type.Business;
        }

        const attrs = {};

        this.viewModel = new ViewModel({
            parentViewModel: this.options.parentViewModel || null,
            vm_name: null,
            vm_color: null,
            vm_imageUrl: null,
            vm_address: null,
            vm_showDate: !!this.options.showDate
        });

        if (this.options.type == Type.User && this.options.placeModel) {
            this.placeModel = this.options.placeModel;
            const { logoThumb_local, logo_local, logoThumb, logo, shortAddress } = this.placeModel.toJSON({ computed: true });

            _.extend(attrs, {
                'vm_imageUrl': logoThumb_local || logo_local || logoThumb || logo || B2CPlace.const.default_thumb,
                'vm_name': this.placeModel.get('name'),
                'vm_color': getNextPrettyColor(this.placeModel.get('name')),
                'vm_address': shortAddress
            });
        } else if (this.options.type == Type.Business && this.options.userModel) {
            this.userModel = this.options.userModel;

            _.extend(attrs, {
                'vm_imageUrl': this.userModel.get('avatarUrl') || 'images/anonymous.png',
                'vm_name': this.userModel.get('name'),
                'vm_color': getNextPrettyColor(this.userModel.get('name'))
            });
        }

        this.viewModel.set(attrs);

        this._debouncedRender = _.debounce(() => {
            if (!this.isDestroyed && this.isRendered) {
                this.render();
            }
        }, 1);
    }

    templateHelpers() {
        return {
            ...this.viewModel.pick('vm_name', 'vm_color', 'vm_imageUrl', 'vm_address', 'vm_showDate'),
            SmokingArea: ReservationModel.SmokingArea
        };
    }
}

Object.assign(ReservationItem, { Type });

export default ReservationItem;
