import { all } from 'redux-saga/effects'
import menuSagas from './menu/sagas';
import appSagas from './app/sagas';

export default function* rootSaga() {
    yield all([
        menuSagas(),
        appSagas()
    ])
}
