import Window from 'windows/Window';
import { ViewModel } from '@b2cmessenger/backbone';
import Header from "widgets/Header/Header";
import ReactView from 'widgets/ReactView';
import FooterView from "widgets/Footer/Footer";
import MenuCategoryEditorContent from 'components/menu/MenuCategory/MenuCategoryEditorContent';
import './MenuCategoryEditor.scss';
import ImageInputHelpers from "widgets/Inputs/ImageInputHelpers";
import MenuCategoryModel from 'models/MenuCategoryModel';

const template = _.template(`
<div class="header-region" data-js-header></div>
<div class="content-region" data-js-content></div>
<div class="footer-region" data-js-footer></div>
<div class="keyboard-padding" data-js-keyboard-padding></div>`);

const MenuEditorWindow = Window.extend({
    template,
    options: {
        placeId: null,
        parentCategory: null,
        category: null
    },

    windowName: 'menu-category-editor-window',

    regions: {
        header: '[data-js-header]',
        content: '[data-js-content]',
        footer: '[data-js-footer]'
    },

    initialize() {
        this.viewModel = new ViewModel;

        if (_.isObject(this.options.category)) {
            const payload = _.pick(this.options.category, ['id', 'name', 'is_addition', 'photo', 'photo_thumb', 'parent_id']);
            if (payload.id && payload.photo) {
                delete payload.photo;
            }

            if (this.options.parentCategory) {
                payload.is_addition = this.options.parentCategory.is_addition;
            }

            this.model = new MenuCategoryModel(payload);
        } else {
            this.model = new MenuCategoryModel
        }

        this._debouncedRender = _.debounce(() => {
            if (!this.isDestroyed && this.isRendered) {
                this.render()
            }
        }, 1);
    },

    onRender() {
        const headerView = new Header({
            title: `${this.model.isNew() ? 'Add' : 'Edit'} category`,
            leftButtons: ['back']
        });

        this.listenTo(headerView, 'back:click', () => this.close());
        this.header.show(headerView);

        const contentView = new ReactView({
            className: 'menu-category-editor',
            component: MenuCategoryEditorContent,
            model: this.model,
            modelToProps: model => ({ category: model.toJSON() }),
            props: {
                parentCategory: this.options.parentCategory || null,
                onCategoryChanged: (payload) => {
                    this.model.set(payload);
                },
                uploadPhoto: e => {
                    this.showLoading();
                    ImageInputHelpers.uploadFromDOMEvent('mc', e)
                        .then(data => {
                            this.hideLoading();

                            if (data === null) {
                                return;
                            }

                            this.model.set({
                                photo: data.id,
                                photo_thumb: data.full || data['1280'] || data['640'] || data['320']
                            });
                        })
                        .catch(e => {
                            this.hideLoading();
                            this.showError(e);
                        })
                }
            },
            mountEvent: 'onRender'
        });
        this.content.show(contentView);

        const footerView = new FooterView({
            buttons: [
                { id: "close", text: "CANCEL", icn: "empty" },
                { id: "continue", text: this.model.isNew() ? 'ADD' : 'SAVE', icn: "empty" }
            ]
        });

        footerView.listenTo(this.model, 'change:c_isValid', (m, canContinue) => {
            footerView.ui.btncontinue.prop('disabled', !canContinue);
        });

        this.listenTo(footerView, 'close:click', () => this.close(false));
        this.listenTo(footerView, 'continue:click', () => {
            this.showLoading();

            this.model
                .save(null, {
                    wait: true,
                    patch: this.model.isNew(),
                    attrs: this.model.isNew() ? null : this.model.changed,
                    success: data => {
                        this.close(data);
                        this.hideLoading();
                    },
                    error: error => {
                        this.showError(error);
                        this.hideLoading();
                    },
                    placeId: this.options.placeId
                })
        });

        this.footer.show(footerView);
        footerView.ui.btncontinue.prop('disabled', !this.model.get('c_isValid'));

        this.listenTo(this.model, 'sync', this._debouncedRender);
    }
});

export default MenuEditorWindow;
