import React, { useState } from 'react';
import MenuSortableCategories from 'components/menu/MenuSortable/MenuSortableCategories';
import MenuSortableItems from 'components/menu/MenuSortable/MenuSortableItems';
import arrayMove from 'utils/arrayMove';
import './MenuSortable.scss';
import PlatformFeature from 'utils/PlatformFeature';
import Footer from 'components/UI/Footer/Footer';

const MenuSortable = (props) => {
    const { categories: initialCategories, items: initialItems, onSelectCategory, onChangeOrderSave } = props;

    const [categories, setCategories] = useState(initialCategories);
    const [items, setItems] = useState(initialItems);
    const containerRef = React.useRef();

    const onCategoryChangeOrder = ({ oldIndex, newIndex }) => {
        setCategories(arrayMove(categories, oldIndex, newIndex));
    };
    const onItemChangeOrder = ({ oldIndex, newIndex }) => {
        setItems(arrayMove(items, oldIndex, newIndex));
    };

    const isCategoriesChanged = () => {
        return categories.some((el, index) => {
            return el.id !== initialCategories[index].id;
        });
    };
    const isItemsChanged = () => {
        return items.some((el, index) => {
            return el.id !== initialItems[index].id;
        });
    };
    const sortOrderChanged = isCategoriesChanged() || isItemsChanged();

    const onSortSave = () => {
        let changedCategoriesOrder = null;
        let changedItemsOrder = null;
        if (isCategoriesChanged()) {
            changedCategoriesOrder = [...categories].map((el) => el.id);
        }
        if (isItemsChanged()) {
            changedItemsOrder = [...items].map((el) => el.id);
        }

        onChangeOrderSave(changedCategoriesOrder, changedItemsOrder);
    };

    const onSortReset = () => {
        setCategories(initialCategories);
        setItems(initialItems);
    };

    let useDragHandle = true;
    let dragPressDelay = 0;

    if (new PlatformFeature().isTouchScreenDevice()) {
        useDragHandle = false;
        dragPressDelay = 200;
    }

    const buttons = [
        {
            text: 'CANCEL',
            id: 'cancel',
            onClick: onSortReset,
        },
        {
            text: 'SAVE',
            id: 'continue',
            disabled: false,
            onClick: onSortSave,
        },
    ];
    const footer = <Footer buttons={buttons} />;

    const sortableElements = (
        <>
            {categories.length > 0 ? (
                <MenuSortableCategories
                    categories={categories}
                    onSelectCategory={onSelectCategory}
                    onChangeOrder={onCategoryChangeOrder}
                    useDragHandle={useDragHandle}
                    dragPressDelay={dragPressDelay}
                    helperContainer={() => containerRef.current}
                />
            ) : null}
            {items.length > 0 ? (
                <MenuSortableItems
                    items={items}
                    additions={props.additions}
                    onChangeOrder={onItemChangeOrder}
                    useDragHandle={useDragHandle}
                    dragPressDelay={dragPressDelay}
                    helperContainer={() => containerRef.current}
                />
            ) : null}
        </>
    );
    const className = ['menu-sortable', sortOrderChanged && 'with-footer'].filter(Boolean).join(' ');

    return (
        <div className={className} ref={containerRef}>
            {sortableElements}
            {sortOrderChanged && footer}
        </div>
    );
};

export default MenuSortable;
