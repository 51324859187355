import {Collection} from '@b2cmessenger/backbone';
import settings from 'settings';

import NotificationMuteModel from 'models/NotificationMuteModel';

const NotificationMuteCollection = Collection.extend({
    model: NotificationMuteModel,

    initialize() {
    },

    url() {
        return settings.host + settings.serv_notification.mute;
    },

    set(models, options) {
        options = _.extend({}, { filter: null }, options);

        if (_.isFunction(options.filter)) {
            models = this.reject(options.filter).concat(models);
        }
        return Collection.prototype.set.call(this, models, options);
    },
});

export default NotificationMuteCollection;
