import elementResizeDetectorFactory from 'element-resize-detector';
import { Model, ItemView } from '@b2cmessenger/backbone';

import template from './Photo.jade';
import './Photo.scss';

let PhotoWidget = ItemView.extend({
    options: {
        minZoom: 1,
        maxZoom: 6,
        shortSwipeTresholdMs: 300,
        shortSwipeTresholdPx: 100,
        longSwipeTresholdPercent: 50,
        shortVerticalSwipeTresholdPx: 150,
        longVerticalSwipeTresholdPercent: 40,
        isCanvas: false
    },

    setRotation(deg) {
        this.viewModel.set('rotation', Number(deg) || 0);
    },

    getCanvas() {
        if (this.options.isCanvas) {
            return this.ui.image[0];
        }
    },

    template,
    className: 'widget photo-widget',

    ui: {
        image: '[data-js-image]'
    },

    bindings() {
        if (this.options.isCanvas) {
            return {
                '@ui.image': 'css:{transform:transformation(zoom,offsetX,offsetY)}',
                ':el': 'css:{transform:halfScaleAndRotate(rubberZoom,rotation)}'
            }
        } else {
            return {
                '@ui.image': 'attr:{src:photoUrl},css:{transform:transformation(zoom,offsetX,offsetY)}',
                ':el': 'css:{transform:halfScaleAndRotate(rubberZoom,rotation)}'
            }
        }
    },

    bindingFilters: {
        transformation: (scale, offX, offY) => `scale(${scale}) translate(${offX}px,${offY}px)`,
        halfScaleAndRotate: (scale, rotation) => `scale(${Math.sqrt(scale)}) rotate(${rotation.toFixed(2)}deg)`
    },

    events: {
        'touchstart': 'onTouchstart',
        'touchmove': 'onTouchmove',
        'touchend': 'onTouchend',
        'touchcancel': 'onTouchend',
    },

    initialize(options) {
        this.viewModel = new (Model.extend({
            defaults: {
                containerWidth: 0,
                containerHeight: 0,
                imageWidth: 0,
                imageHeight: 0,
                rawZoom: 1,
                minZoom: 1,
                maxZoom: 6,
                rawOffsetX: 0,
                rawOffsetY: 0,
                rubberZoomCoef: 0.4,
                rubberZoomPeriod: 200,
                expandZoomTreshold: 1.5,
                collapseZoomTreshold: 0.5,
                zooming: false,
                rotation: 0
            },

            computeds: {
                maxOffsetX: {
                    deps: ['zoom', 'imageWidth', 'containerWidth'],
                    get: (zoom, imageWidth, containerWidth) => Math.max(0, (imageWidth * zoom - containerWidth) / 2 / zoom) || 0
                },
                maxOffsetY: {
                    deps: ['zoom', 'imageHeight', 'containerHeight'],
                    get: (zoom, imageHeight, containerHeight) => Math.max(0, (imageHeight * zoom - containerHeight) / 2 / zoom) || 0
                },
                zoom: {
                    deps: ['rawZoom', 'minZoom', 'maxZoom'],
                    get: (rawZoom, minZoom, maxZoom) => Math.max(minZoom, Math.min(Number(rawZoom) || 0, maxZoom)) || 1,
                    set: rawZoom => _.create(null, { rawZoom })
                },
                offsetX: {
                    deps: ['rawOffsetX', 'maxOffsetX'],
                    get: (rawOffsetX, maxOffsetX) => Math.max(-maxOffsetX, Math.min(Number(rawOffsetX) || 0, maxOffsetX)) || 0,
                    set: rawOffsetX => _.create(null, { rawOffsetX })
                },
                offsetY: {
                    deps: ['rawOffsetY', 'maxOffsetY'],
                    get: (rawOffsetY, maxOffsetY) => Math.max(-maxOffsetY, Math.min(Number(rawOffsetY) || 0, maxOffsetY)) || 0,
                    set: rawOffsetY => _.create(null, { rawOffsetY })
                },
                rubberZoom: {
                    deps: ['rawZoom', 'zoom', 'rubberZoomCoef'],
                    get: (rawZoom, zoom, rubberZoomCoef) => Math.abs(rawZoom - zoom) < Number.EPSILON ? 1 : (
                        rawZoom > zoom ?
                            Math.max(1, Math.min(rawZoom / zoom, 1 + (Number(rubberZoomCoef) || 0))) :
                            Math.max(1 - (Number(rubberZoomCoef) || 0), Math.min(rawZoom / zoom, 1)))
                },
                realZoom: {
                    deps: ['rubberZoom', 'zoom'],
                    get: (rubberZoom, zoom) => rubberZoom * zoom
                },
                expanded: {
                    deps: ['rawZoom', 'zoom', 'expandZoomTreshold'],
                    get: (rawZoom, zoom, expandZoomTreshold) => rawZoom / zoom - expandZoomTreshold >= -Number.EPSILON
                },
                collapsed: {
                    deps: ['rawZoom', 'zoom', 'collapseZoomTreshold'],
                    get: (rawZoom, zoom, collapseZoomTreshold) => rawZoom / zoom - collapseZoomTreshold <= Number.EPSILON
                }
            },

            initialize() {
                this.listenTo(this, 'change:zooming', (m, zooming) => {
                    if(!zooming) {
                        let rubberZoom = m.get('rubberZoom'),
                            zoom = m.get('zoom');

                        if(rubberZoom != 1) {
                            let startTime = Date.now(),
                                targetValue = rubberZoom > 1 ? m.get('maxZoom') : m.get('minZoom'),
                                startValue = targetValue * rubberZoom,
                                diff = targetValue - startValue;

                            let frame = () => {
                                if(!m.get('zooming')) {
                                    let period = m.get('rubberZoomPeriod'),
                                        time = Date.now() - startTime;

                                    if(time >= period) {
                                        m.set('zoom', targetValue);
                                    } else {
                                        m.set('zoom', startValue + diff * Math.pow(2, 10 * (time / period - 1)));
                                        requestAnimationFrame(frame);
                                    }
                                }
                            };

                            requestAnimationFrame(frame);
                        }
                    }
                });

                this.listenTo(this, 'change:rawZoom change:minZoom change:maxZoom change:rubberZoomCoef change:expandZoomTreshold change:collapseZoomTreshold', m => {
                    let rawZoom = m.get('rawZoom'),
                        minZoom = m.get('minZoom'),
                        maxZoom = m.get('maxZoom'),
                        rubberZoomCoef = m.get('rubberZoomCoef'),
                        expandZoomTreshold = m.get('expandZoomTreshold'),
                        collapseZoomTreshold = m.get('collapseZoomTreshold');

                    this.set('rawZoom', Math.max(minZoom * Math.min(1 - rubberZoomCoef, collapseZoomTreshold), Math.min(rawZoom, maxZoom * Math.max(expandZoomTreshold, 1 + rubberZoomCoef))));
                })
            }
        }))({
            imageWidth: (this.options.isCanvas ? 0 : this.model.get('photoDimensions').w) || 0,
            imageHeight: (this.options.isCanvas ? 0 : this.model.get('photoDimensions').h) || 0,
            rawZoom: 1,
            zoomGestureStartZoom: 1,
            zoomGestureMaxZoom: 1,
            zoomGestureMinZoom: 1,
            minZoom: this.options.minZoom,
            maxZoom: this.options.maxZoom,
            rotate: this.options.rotate || false,
        });

        this.listenTo(this.viewModel, 'change:expanded', (m, expanded) => expanded &&
            this.trigger('gesture:expand', this, this.viewModel.get('zoomGestureStartZoom'), this.viewModel.get('zoomGestureMinZoom'), this.viewModel.get('zoomGestureMaxZoom')));
        this.listenTo(this.viewModel, 'change:collapsed', (m, collapsed) => collapsed &&
            this.trigger('gesture:collapse', this, this.viewModel.get('zoomGestureStartZoom'), this.viewModel.get('zoomGestureMinZoom'), this.viewModel.get('zoomGestureMaxZoom')));
        this.listenTo(this.viewModel, 'change:zoom', (m, zoom) => this.trigger('change:zoom', this, zoom));
    },

    onAttach() {
        if (this.options.isCanvas) {

        } else {
            let imageEl = this.ui.image[0];

            if (!imageEl.complete) {
                imageEl.addEventListener('load', this.onSizesUpdated.bind(this));
            } else {
                this.onSizesUpdated();
            }
        }

        if (!this.erd) {
            this.erd = elementResizeDetectorFactory({
                strategy: 'scroll'
            });
            this.erd.listenTo(this.el, _.debounce(this.onSizesUpdated.bind(this), 5));
        }
    },

    serializeData() {
        return _.extend(ItemView.prototype.serializeData.apply(this, arguments), { isCanvas: this.options.isCanvas })
    },

    onSizesUpdated() {
        if(this.isDestroyed) return;
        let imageEl = this.ui.image[0];

        if(!this.options.isCanvas && imageEl.naturalWidth && imageEl.naturalHeight) {
            this.model.set({
                photo_w: imageEl.naturalWidth,
                photo_h: imageEl.naturalHeight
            });
        }

        if(imageEl.offsetWidth && imageEl.offsetHeight) {
            this.viewModel.set({
                imageWidth: imageEl.offsetWidth,
                imageHeight: imageEl.offsetHeight,
            });
        } else {
            requestAnimationFrame(() => this.onSizesUpdated());
            return;
        }

        if(this.el.clientWidth && this.el.clientHeight) {
            this.viewModel.set({
                containerWidth: this.el.clientWidth,
                containerHeight: this.el.clientHeight,
            });
        } else {
            this.viewModel.set({
                containerWidth: imageEl.offsetWidth,
                containerHeight: imageEl.offsetHeight,
            });
            requestAnimationFrame(() => this.onSizesUpdated());
        }
    },

    onTouchstart(e) {
        if(!this.touches) this.touches = _.create(null, {});

        for(let i = 0; i < e.originalEvent.changedTouches.length; i++) {
            let eventTouch = e.originalEvent.changedTouches.item(i);
            this.touches[eventTouch.identifier] = _.create(null, {
                startX: eventTouch.pageX,
                startY: eventTouch.pageY,
                prevX: eventTouch.pageX,
                prevY: eventTouch.pageY,
                startTime: _.now(),

            });
        }

        if(e.originalEvent.touches.length == 2) {
            this.viewModel.set({
                zoomGestureStartZoom: this.viewModel.get('zoom'),
                zoomGestureMaxZoom: this.viewModel.get('zoom'),
                zoomGestureMinZoom: this.viewModel.get('zoom')
            });
        }

        if(e.originalEvent.touches.length >= 2) {
            this.viewModel.set('zooming', true);
        }
    },

    onTouchmove(e) {
        let moveX = 0, moveY = 0, scale = 1;
        if(e.originalEvent.touches.length == 2) {

            let eventTouch0 = e.originalEvent.touches.item(0),
                eventTouch1 = e.originalEvent.touches.item(1),
                touch0 = this.touches[eventTouch0.identifier],
                touch1 = this.touches[eventTouch1.identifier];

            if(eventTouch0 && eventTouch1 && touch0 && touch1) {
                let prevDistance = Math.sqrt((touch0.prevX - touch1.prevX) * (touch0.prevX - touch1.prevX) +
                                        (touch0.prevY - touch1.prevY) * (touch0.prevY - touch1.prevY)),
                    distance = Math.sqrt((eventTouch0.pageX - eventTouch1.pageX) * (eventTouch0.pageX - eventTouch1.pageX) +
                                        (eventTouch0.pageY - eventTouch1.pageY) * (eventTouch0.pageY - eventTouch1.pageY));

                scale = distance / prevDistance;
            }
        }

        for(let i = 0; i < e.originalEvent.changedTouches.length; i++) {
            let eventTouch = e.originalEvent.changedTouches.item(i);
            let touch = this.touches[eventTouch.identifier];
            if(touch) {
                moveX += eventTouch.pageX - touch.prevX;
                moveY += eventTouch.pageY - touch.prevY;
                touch.prevX = eventTouch.pageX;
                touch.prevY = eventTouch.pageY;
            }
        }

        let rawZoom = this.viewModel.get('rawZoom'),
            offsetX = this.viewModel.get('offsetX'),
            offsetY = this.viewModel.get('offsetY'),
            rotation = (Number(this.viewModel.get('rotation')) || 0) / 180 * Math.PI,
            zoomGestureMaxZoom = this.viewModel.get('zoomGestureMaxZoom'),
            zoomGestureMinZoom = this.viewModel.get('zoomGestureMinZoom');

        this.viewModel.set({
            zoom: rawZoom * scale
        });

        let zoom = this.viewModel.get('realZoom');

        this.viewModel.set({
            offsetX: offsetX + (moveX * Math.cos(rotation) + moveY * Math.sin(rotation)) / zoom,
            offsetY: offsetY + (-moveX * Math.sin(rotation) + moveY * Math.cos(rotation)) / zoom,
            zoomGestureMaxZoom: Math.max(zoom, zoomGestureMaxZoom),
            zoomGestureMinZoom: Math.min(zoom, zoomGestureMinZoom)
        });


        if(Math.abs(offsetX - this.viewModel.get('offsetX')) > Number.EPSILON ||
            Math.abs(offsetY - this.viewModel.get('offsetY')) > Number.EPSILON) {
                e.preventDefault();
        }
    },

    onTouchend(e) {
        if(e.originalEvent.touches.length < 2) {
            this.viewModel.set('zooming', false);
        }

        if(e.originalEvent.changedTouches.length == 1) {
            let eventTouch = e.originalEvent.changedTouches.item(0),
                touch = this.touches[eventTouch.identifier];

            if(touch) {
                let distanceX = eventTouch.pageX - touch.startX,
                    distanceY = eventTouch.pageY - touch.startY,
                    ms = _.now() - touch.startTime,
                    rotation = (Number(this.viewModel.get('rotation')) || 0) / 180 * Math.PI,
                    longSwipeTresholdPx = this.options.longSwipeTresholdPercent / 100 * (this.viewModel.get('containerWidth') * Math.abs(Math.cos(rotation)) + this.viewModel.get('containerHeight') * Math.abs(Math.sin(rotation)));

                if(ms < this.options.shortSwipeTresholdMs && Math.abs(distanceX) >= this.options.shortSwipeTresholdPx) {
                    this.trigger('gesture:swipe', this, distanceX, ms);
                } else if(longSwipeTresholdPx && Math.abs(distanceX) >= longSwipeTresholdPx) {
                    this.trigger('gesture:swipe:long', this, distanceX);
                }

                longSwipeTresholdPx = this.options.longVerticalSwipeTresholdPercent / 100 * (this.viewModel.get('containerWidth') * Math.abs(Math.sin(rotation)) + this.viewModel.get('containerHeight') * Math.abs(Math.cos(rotation)));

                if(ms < this.options.shortSwipeTresholdMs && Math.abs(distanceY) >= this.options.shortVerticalSwipeTresholdPx) {
                    this.trigger('gesture:vertical:swipe', this, distanceY, ms);
                } else if(longSwipeTresholdPx && Math.abs(distanceY) >= longSwipeTresholdPx) {
                    this.trigger('gesture:vertical:swipe:long', this, distanceY);
                }
            }
        }

        for(let i = 0; i < e.originalEvent.changedTouches.length; i++) {
            delete this.touches[e.originalEvent.changedTouches[i].identifier];
        }
    }
});

export default PhotoWidget;
