import { Optional, Required, ItemView, ViewModel, Model } from '@b2cmessenger/backbone';
import Modal from 'windows/Modal/Modal';
import FooterView from 'widgets/Footer/Footer';
import template from './UserEmailSearchVerifyModal.jade';
import './UserEmailSearchModal.scss';
import UserSearchHelpers from "windows/Modal/UserSearchModal/UserSearchHelpers";

const UserEmailSearchVerifyModal = Modal.extend({
    options: {
        userData: Required,
        parentViewModel: Optional
    },

    windowName: "user-email-search-verify-modal-window",
    className: "modal-window user-email-search-verify-modal-window green-border",

    initialize() {
        this.viewModel = new ViewModel({
            parentViewModel: this.options.parentViewModel || null,
            vm_email: this.options.userData.email,
            vm_userData: this.options.userData,
            vm_code: null,
            vm_codeError: null,
            vm_verifyId: null,
            vm_canContinue: null
        });

        this.kioskHelpers = new UserSearchHelpers({
            viewModel: this.viewModel
        });

        this.viewModel.addComputed('vm_canContinue', {
            deps: ['vm_code', 'disabled'],
            get: (vm_code, disabled) => !!(vm_code && !disabled)
        });

        return Modal.prototype.initialize.apply(this, arguments);
    },

    onShow() {
        this.kioskHelpers.requestCode({ email: this.viewModel.get('vm_email') })
            .then(data => {
                if (data && data.verification_id) {
                    this.viewModel.set('vm_verifyId', data.verification_id);
                }

                this.footerView.ui.btncontinue.prop('disabled', !this.viewModel.get('vm_canContinue'));
                this.content && this.content.currentView && this.content.currentView.ui &&
                this.content.currentView.ui.code && this.content.currentView.ui.code.focus();
            })
            .catch(error => {
                this.showError(...error);
                this.close();
            });

        //@ts-ignore
        return Modal.prototype.onShow.apply(this, arguments);
    },

    onRender() {
        //@ts-ignore
        const contentView = new UserEmailSearchVerifyContent({
            viewModel: this.viewModel
        });

        this.listenTo(contentView, 'continue', () => {
            if (this.viewModel.get('vm_canContinue')) {
                this._onContinue();
            }
        });
        this.content.show(contentView);

        const footerView = this.footerView = new FooterView({
            buttons: [{
                id: "close",
                text: "CANCEL",
                icn: "empty"
            }, {
                id: "continue",
                text: "CONTINUE",
                icn: "empty"
            }]
        });
        footerView.listenTo(this.viewModel, 'change:vm_canContinue', () => {
            console.log(!this.viewModel.get('vm_canContinue'));

            footerView.ui.btncontinue.prop('disabled', !this.viewModel.get('vm_canContinue'));
        });
        this.listenTo(footerView, 'close:click', view => this.close(false));
        this.listenTo(footerView, 'continue:click', view => this._onContinue());
        this.footer.show(footerView);
    },

    cancel() {
        this.close(false);
    },

    _onContinue() {
        if (this.viewModel.get('vm_canContinue')) {
            const options = {
                id: this.viewModel.get('vm_verifyId'),
                code: this.viewModel.get('vm_code')
            };

            this.kioskHelpers.verifyWithCode(options)
                .then((data) => {
                    if (data && data.valid) {
                        this.close(this.viewModel.get('vm_userData'));
                    }
                })
                .catch(error => {
                    if (error && error[0].status === 400) {
                        this.viewModel.set('vm_codeError', 'Invalid code');
                    } else {
                        this.showError(...error);
                        this.close(false);
                    }
                });
        }
    }
});

export default UserEmailSearchVerifyModal;

@ItemView.options({
    collection: Optional,
})
@ItemView.properties({
    template: template,
    tagName: 'form',
    className: "widget user-email-search-content-widget",

    ui: {
        email: '[data-js-email]',
        code: '[data-js-code]',
        codeError: '[data-js-code-error]',
    },

    computeds: {
        c_code: {
            deps: ['vm_code'],
            get: val => val || "",
            set(val) {
                this.viewModel.set('vm_code', val);
                this.viewModel.set('vm_codeError', '');
            }
        }
    },

    bindings: {
        '@ui.email': 'value:vm_email',
        '@ui.code': 'value:c_code, disabled:disabled, attr:{readonly:disabled}',
        '@ui.codeError': 'text:vm_codeError',
    },

    events: {
        'submit'(e) {
            this.trigger('continue');
            e.preventDefault();
        },
        'keyup @ui.code'(e) {
            this.viewModel.set('vm_code', this.ui.code.val());
        }
    }
})
class UserEmailSearchVerifyContent extends ItemView {
}
