import React from 'react';
import './MenuSection.scss';

export const getSectionListItemClassName = (item, ...classNames) => {
    return ['menu-section', ...classNames].filter(Boolean).join(' ');
};

function isColor(color) {
    const s = new Option().style;
    s.color = color;
    return s.color !== '';
}

export const MenuSectionDefaultColor = '#cccccc';
export const MenuSectionContent = (props) => {
    const tablesCount = React.useMemo(() => (props.tables || []).length, [props.tables]);
    const tablesSuffix = React.useMemo(() => `table${tablesCount !== 1 ? 's' : ''}`, [tablesCount]);
    const color = React.useMemo(() => (isColor(props.color) && props.color) || MenuSectionDefaultColor, [props.color]);

    return (
        <>
            <div className="photo">
                <i style={{ backgroundColor: color }} />
            </div>
            <div className="content">
                <div className="name">{props.name}</div>
                <div className="message">
                    <span>{tablesCount || 'No'}</span> 
                    {' '}
                    {tablesSuffix} 
                    {' '}
                    {props.message ? props.message : null}
                </div>
            </div>
        </>
    );
};

export const MenuSectionBase = (props) => {
    return (
        <div className={getSectionListItemClassName(props, props.className)} onClick={props.onClick}>
            <MenuSectionContent
                id={props.id}
                name={props.name}
                tables={props.tables}
                color={props.color}
                message={props.message}
            />
            {props.children}
        </div>
    );
};

const MenuSection = (props) => {
    const { className, onClick, ...restProps } = props;

    return (
        <MenuSectionBase {...restProps} className={className} onClick={onClick}>
            {props.children}
        </MenuSectionBase>
    );
};

export default MenuSection;
