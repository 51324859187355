import { ItemView } from '@b2cmessenger/backbone';

import taskEditorBroadcastKeyboardPanelTemplate from './TaskEditorBroadcastKeyboardPanel.jade';
import './TaskEditorBroadcastKeyboardPanel.scss';

import 'utils/HTMLInputElement.insertAtCaret';

const TaskEditorBroadcastKeyboardPanel = ItemView.extend({
    options: {
        inputEl: null
    },

    template: taskEditorBroadcastKeyboardPanelTemplate,
    className: "widget keyboard-additional-panel task-editor-broadcast-keyboard-panel",

    ui: {
        firstname: "[data-js-firstname]",
        lastname: "[data-js-lastname]",
        fullname: "[data-js-fullname]"
    },

    events: {
        'mousedown @ui.firstname'(e) {
            e.preventDefault();
        },

        'mousedown @ui.lastname'(e) {
            e.preventDefault();
        },

        'mousedown @ui.fullname'(e) {
            e.preventDefault();
        },

        'click @ui.firstname'() {
            this._inputEl.insertAtCaret("{firstname}", true);
            this._inputEl.focus();
        },

        'click @ui.lastname'() {
            this._inputEl.insertAtCaret("{lastname}", true);
            this._inputEl.focus();
        },

        'click @ui.fullname'() {
            this._inputEl.insertAtCaret("{fullname}", true);
            this._inputEl.focus();
        }
    },

    initialize() {
        this._inputEl = this.options.inputEl;
    }
});

export { TaskEditorBroadcastKeyboardPanel };
