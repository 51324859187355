import { Model, ViewModel, ItemView, CollectionView, CompositeView, LayoutView } from '@b2cmessenger/backbone';
import settings from 'settings';

import Window from 'windows/Window';
import HeaderView from 'widgets/Header/Header';
import FooterView from 'widgets/Footer/Footer';
import PropertiesWidget from 'widgets/Properties/Properties';
import PlaceModel from 'models/PlaceModel';
import AjaxError from 'utils/AjaxError';
import Behaviors from 'utils/Behaviors';
import getNextPrettyColor from 'utils/randomPrettyColor';
import ImageInput from 'widgets/Inputs/ImageInput';
import CategoryChooserWindow from 'windows/Category/CategoryChooser';
import ConfirmModal from 'windows/Modal/Confirm';

import contentTemplate from './Content.jade';
import phoneEditTemplate from './PhoneEdit.jade';
import phonesTemplate from './Phones.jade';
import workingDayTemplate from './WorkingDay.jade';
import workingIntervalTemplate from './WorkingInterval.jade';
import './PlaceEditor.scss';
import ImageInputHelpers from "widgets/Inputs/ImageInputHelpers";
import GoogleAnalytics from "utils/GoogleAnalytics";

const noname = "--__noname__--";

let WorkingIntervalWidget = ItemView.extend({
    className: 'widget working-interval-widget',
    template: workingIntervalTemplate,

    behaviors: [{
        behaviorClass : Behaviors.InputGroupWithError,
        name: 'from',
        selector: '[data-js-working-interval-from]',
        modelSave: false,
        onBlur: true
    }, {
        behaviorClass : Behaviors.InputGroupWithError,
        name: 'to',
        selector: '[data-js-working-interval-to]',
        modelSave: false,
        onBlur: true
    }],

    ui: {
        addBtn: '[data-js-btn-add]',
        deleteBtn: '[data-js-btn-delete]'
    },

    triggers: {
        'click [data-js-btn-add]': 'add',
        'click [data-js-btn-delete]': 'delete'
    },

    bindings: {
        '@ui.addBtn': 'disabled:disabled',
        '@ui.deleteBtn': 'disabled:disabled',
        '[data-js-working-interval-from]': 'classes:{disabled:disabled}',
        '[data-js-working-interval-from] input': 'disabled:disabled',
        '[data-js-working-interval-to]': 'classes:{disabled:disabled}',
        '[data-js-working-interval-to] input': 'disabled:disabled',
    },

    onFromChange(e, val) {
        let matches = String(val).match(/(\d+):(\d+)/);

        if(!matches || matches.length < 3 || Number(matches[1]) < 0 || Number(matches[1]) > 24 || Number(matches[2]) < 0 || Number(matches[2]) > 59) {
            this.trigger('from:error:add', 'Incorrect date specified');
        } else {
            this.model.set({
                o_h: Number(matches[1]),
                o_m: Number(matches[2])
            });
        }
    },

    onToChange(e, val) {
        let matches = String(val).match(/(\d+):(\d+)/);

        if(!matches || matches.length < 3 || Number(matches[1]) < 0 || Number(matches[1]) > 24 || Number(matches[2]) < 0 || Number(matches[2]) > 59) {
            this.trigger('to:error:add', 'Incorrect date specified');
        } else {
            this.model.set({
                c_h: Number(matches[1]),
                c_m: Number(matches[2])
            });
        }
    },

});

let WorkingDayWidget = CompositeView.extend({
    className: 'widget working-day-widget',
    template: workingDayTemplate,
    childView: WorkingIntervalWidget,
    childViewContainer: '[data-js-working-intervals]',
    childViewOptions() {
        let viewModel = new ViewModel;
        viewModel.set('parentDisabled', this.viewModel.get('disabled'));
        viewModel.listenTo(this.viewModel, 'change:disabled', (m, disabled) => viewModel.set('parentDisabled', disabled));

        return { viewModel };
    },

    behaviors: [{
        behaviorClass : Behaviors.InputGroupWithError,
        name: 'type',
        selector: '[data-js-working-day]',
        modelSave: false,
    }],

    bindings: {
        '[data-js-working-day]': 'classes:{disabled:disabled}',
        '[data-js-working-day] input': 'disabled:disabled'
    },

    initialize() {
        let IntervalCollection = Backbone.Collection.extend({ comparator: m => Number(m.o_h)*60 + Number(m.o_m) });
        this.collection = new IntervalCollection(this.normalizeIntervals(this.model.get('int')));
        this.listenTo(this.collection, 'change', () => {
            //this.collection.sort();
            this.model.set('int', this.collection.toJSON())
        });
        this.listenTo(this.collection, 'update', () => this.model.set('int', this.collection.toJSON()));
        this.listenTo(this.model, 'change:int', () => this.collection.reset(this.normalizeIntervals(this.model.get('int'))));
    },

    onTypeChange(e, val) {
        if(val == -1) {
            this.model.set({
                all: -1,
                int: undefined
            });
        } else if(val == 0){
            this.model.set({
                all: undefined,
                int: [{ o_h: 0, o_m: 0, c_h: 23, c_m: 59 }]
            });
        } else {
            this.model.set({
                all: 1,
                int: undefined
            });
        }
    },

    onChildviewAdd(child) {
        let index = this.collection.indexOf(child.model);

        let prevModel = child.model,
            nextModel = this.collection.at(index + 1);
        this.collection.add(new Model({
            o_h: prevModel ? prevModel.get('c_h') : 0,
            o_m: prevModel ? prevModel.get('c_m') : 0,
            c_h: nextModel ? nextModel.get('o_h') : 23,
            c_m: nextModel ? nextModel.get('o_m') : 59,
        }), { at: index + 1 });
    },

    onChildviewDelete(child) {
        this.collection.remove(child.model);
        if(!this.collection.length) {
            this.model.set('all', -1);
            this.model.unset('int');
        }
    },

    normalizeIntervals(ints) {
        ints = ints || [];
        return ints.map(i => Object.create({
            o_h: i.o_h || 0,
            o_m: i.o_m || 0,
            c_h: i.c_h || 0,
            c_m: i.c_m || 0,
        }));
    }
});

let WorkingDaysWidget = CollectionView.extend({
    className: 'widget working-days-widget',
    childView: WorkingDayWidget,
    childViewOptions() {
        let viewModel = new ViewModel;
        viewModel.set('parentDisabled', this.viewModel.get('disabled'));
        viewModel.listenTo(this.viewModel, 'change:disabled', (m, disabled) => viewModel.set('parentDisabled', disabled));

        return { viewModel };
    },

    initialize() {
        this.options.sort = false;
        this.collection = new Backbone.Collection(this.normalizeDays(this.model.get('days')));
        this.listenTo(this.collection, 'change', () => this.model.set('days', this.collection.map(d => (d.get('int') && d.get('int').length) ? { d: d.get('d'), int: d.get('int')} : { d: d.get('d'), all: d.get('all') || -1 })));
        this.listenTo(this.model, 'change:days', () => this.collection.reset(this.normalizeDays(this.model.get('days'))));

        this.listenTo(this, 'days:error:add', msg => {
            let matches = msg.match(/#(\d+)\s*(.*)/);

            if(matches) {
                let child = this.children.find(v => v.model.get('d') == matches[1]);
                if(child) child.trigger('type:error:add', matches[2]);
            } else {
                this.children[0].trigger('type:error:add', msg);
            }
        });

        this.listenTo(this, 'errors:clear', () => this.children.each(v => v.trigger('errors:clear')));

        this.viewModel = new ViewModel;
    },

    normalizeDays(days) {
        let out = [];
        for(let d of [0, 1, 2, 3, 4, 5, 6]) {
            let day = days.find(_d => _d.d == d) || { d: d };
            if(!day.all && !day.int) day.all = -1;
            out.push(day);
        }
        return out;
    }
});

let PhoneEditWidget = ItemView.extend({
    className: 'widget phone-edit-widget input-group phone',
    template: phoneEditTemplate,

    behaviors: [{
        behaviorClass : Behaviors.InputGroupWithError,
        name: 'value',
        selector: ' ',
        transformVal : val => val && B2Cjs.phone_getInternalFormat(val) || "",
        inputBindingToModel: true,
        transformValFromModel: phone => phone && B2Cjs.phone_getExternalFormat(phone) || "",
    }],

    bindings: {
        'input, button': 'disabled:disabled'
    },

    triggers: {
        'click [data-js-btn-delete]' : 'delete'
    },
});

let PhonesWidget = CompositeView.extend({
    className: 'widget phone-edit-widget',
    template: phonesTemplate,
    childView: PhoneEditWidget,
    childViewContainer: '[data-js-phones]',
    childViewOptions() {
        return {
            viewModel: this.viewModel
        };
    },

    behaviors: [{
        behaviorClass : Behaviors.InputGroupWithError,
        name: 'value',
        selector: '[data-js-last-phone]',
        transformVal : val => val && B2Cjs.phone_getInternalFormat(val) || "",
        inputBindingToModel: true,
        transformValFromModel: phone => phone && B2Cjs.phone_getExternalFormat(phone) || "",
        onChange: function() { this.collection.at(-1).set('value', this.model.get('value')); }
    }],

    ui: {
        lastPhoneGroup: '[data-js-last-phone]',
        lastPhone: '[data-js-last-phone] input'
    },

    events: {
        'click [data-js-btn-add]' : 'onAdd'
    },

    bindings: {
        '@ui.lastPhoneGroup input': 'disabled:disabled',
        '@ui.lastPhoneGroup button': 'disabled:disabled',
    },

    initialize() {
        this.options.sort = false;
        this.place = this.model;
        let phones = this.place.get('phone') || [];

        if(!phones.length) phones.push("");

        this.collection = new Backbone.Collection(_.map(phones, phone => Object.create({ value: phone })));
        this.model = this.collection.at(-1).clone();

        this.listenTo(this.collection, 'update', () => this.onCollectionUpdate());
        this.listenTo(this.collection, 'change:value', () => this.onCollectionUpdate());

        this.listenTo(this.place, 'change:phone', () => {
            let phones = this.place.get('phone') || [];
            if(!phones.length) phones.push("");
            this.collection.reset(_.map(phones, phone => Object.create({ value: phone })));
            const phone = this.collection.at(-1).get('value');
            this.ui.lastPhone.val(phone && B2Cjs.phone_getExternalFormat(phone) || "");
        });

        this.viewModel = new ViewModel;
    },

    onBeforeRender() {
        this.model = this.collection.at(-1).clone();
    },

    onAdd() {
        this.collection.add({ value: "" });
    },

    onChildviewDelete(child) {
        this.collection.remove(child.model);
    },

    onCollectionUpdate() {
        const phone = this.collection.at(-1).get('value');
        this.ui.lastPhone.val(phone && B2Cjs.phone_getExternalFormat(phone) || "");
        this.place.set('phone', this.collection.pluck('value').filter(p => p));
    }
});

let ContentWidget = LayoutView.extend({
    template: contentTemplate,

    className: 'widget place-editor-widget',

    ui: {
        form: '[data-js-form]',
        logoInput: '[data-js-input-logo]',
        shortdescTextarea: '[data-js-input-shortdesc]',
        mapBtn: '[data-js-btn-map]',
        map: '[data-js-map]',
        address: '[data-js-input-address]',
        adrLat: '[data-js-address-lat]',
        adrLng: '[data-js-address-lng]',
        mapCanvas: '[data-js-address-canvas]',
        mapCrosshair: '[data-js-address-crosshair]',
        adrSN: '[data-js-address-street-number]',
        adrRoute: '[data-js-address-route]',
        adrLocality: '[data-js-address-locality]',
        adrAAL1: '[data-js-address-administrative-area-level-1]',
        adrCode: '[data-js-address-postal-code]',
        adrCountry: '[data-js-address-country]',
        categories: '[data-js-categories]',
        categoriesBtn: '[data-js-btn-categories]',
        reservationsAllowed: '[data-js-reservations-allowed]',
        reservationMaxDaysGroup: '[data-js-group-max-days-before-reservation]',
        reservationMaxDays: '[data-js-max-days-before-reservation]',
        reservationDaysMinus: '[data-js-max-days-before-reservation-minus]',
        reservationDaysPlus: '[data-js-max-days-before-reservation-plus]',
        reservationDaysInfo: '[data-js-max-days-before-reservation-info]',
        menuAllowed: '[data-js-menu-allowed]'
    },

    regions: {
        logo: '[data-js-logo]',
        phones: '[data-js-phones]',
        workingHours: '[data-js-working-hours]',
        properties: '[data-js-properties]'
    },

    events: {
        'submit @ui.form': 'onSubmit',
        'change @ui.logoInput': 'onLogoChanged',
        'click @ui.mapBtn': 'onMapBtnClick',
        'click @ui.categoriesBtn' : 'onCategoriesBtnClick',
        'click @ui.reservationDaysMinus'() {
            const newValue = Number(this.model.get('max_days_before_reservation')) - 1;
            this.model.set('max_days_before_reservation', newValue > 0 ? newValue : 0);
        },
        'click @ui.reservationDaysPlus'() {
            const max = this.viewModel.get('reservationMaxDaysMax');
            const newValue = Number(this.model.get('max_days_before_reservation')) + 1;
            this.model.set('max_days_before_reservation', newValue < max ? newValue : max);
        }
    },

    bindings: {
        '@ui.form': 'disabled:disabled',
        '@ui.categoriesBtn': 'disabled:disabled',
        '@ui.reservationsAllowed': 'sliderValue:integer(reservations_allowed),sliderDisabled:disabled',
        '@ui.reservationMaxDaysGroup': 'classes:{disabled:c_reservationMaxDaysDisabled}',
        '@ui.reservationMaxDays': 'value:c_reservationMaxDays,disabled:c_reservationMaxDaysDisabled,attr:{"max":reservationMaxDaysMax}',
        '@ui.reservationDaysMinus': 'disabled:c_reservationDaysMinusDisabled',
        '@ui.reservationDaysPlus': 'disabled:c_reservationDaysPlusDisabled',
        '@ui.reservationDaysInfo': 'text:c_reservationMaxDaysInfoText',
        '@ui.menuAllowed': 'sliderValue:integer(menu_allowed),sliderDisabled:disabled',
    },

    computeds: {
        c_reservationMaxDays: {
            deps: ['max_days_before_reservation'],
            get: max_days_before_reservation => max_days_before_reservation,
            set(value) {
                let newValue = Math.max(Math.min(Math.floor(value), this.viewModel.get('reservationMaxDaysMax')), 0);

                this.model.set({ max_days_before_reservation: newValue });
                if (newValue != value) {
                    _.defer(() => this.ui.reservationMaxDays.val(newValue));
                }
            }
        },
        c_reservationMaxDaysDisabled: {
            deps: ['reservations_allowed', 'disabled'],
            get: (reservations_allowed, disabled) => !Number(reservations_allowed) || disabled
        },
        c_reservationMaxDaysInfoText: {
            deps: ['max_days_before_reservation'],
            get: max_days_before_reservation => max_days_before_reservation == 0 ? 'Zero days means no restrictions' : ''
        },
        c_reservationDaysMinusDisabled: {
            deps: ['max_days_before_reservation', 'c_reservationMaxDaysDisabled'],
            get: (max_days_before_reservation, c_reservationMaxDaysDisabled) =>
                max_days_before_reservation == 0 || c_reservationMaxDaysDisabled
        },
        c_reservationDaysPlusDisabled: {
            deps: ['max_days_before_reservation', 'reservationMaxDaysMax', 'c_reservationMaxDaysDisabled'],
            get: (max_days_before_reservation, reservationMaxDaysMax, c_reservationMaxDaysDisabled) =>
                max_days_before_reservation == reservationMaxDaysMax || c_reservationMaxDaysDisabled
        }
    },

    bindingHandlers: {
        sliderValue: {
            get($element) {
                return Number($element.val());
            },
            set($element, value) {
                try {
                    if ($element.val() + '' != value + '') {
                        $element.val(value);
                        $element.slider && $element.slider('instance') && $element.slider('refresh');
                    }

                } catch (error) { }
            }
        },
        sliderDisabled: {
            set($element, value) {
                $element.prop('disabled', !!value);
                $element.slider && $element.slider('instance') && (value ? $element.slider('disable') : $element.slider('enable'))
            },
        },
    },

    behaviors: [{
        behaviorClass : Behaviors.InputGroupWithError,
        name: 'name',
    }, {
        behaviorClass : Behaviors.InputGroupWithError,
        name: 'www',
    }, {
        behaviorClass : Behaviors.InputGroupWithError,
        name: 'shortdesc',
    }, {
        behaviorClass : Behaviors.InputGroupWithError,
        name: 'address',
        onChange: () => {},
        modelSave: false
    }],

    initialize() {
        this.listenTo(this.model, 'change:categories', () => this.onCategoriesChange());
        this.listenTo(this, 'submit', () => this.ui.form.submit());

        this.viewModel = new ViewModel({
            reservationMaxDaysMax: 365 * 3
        });

        this.listenTo(this.viewModel, 'change:disabled',
            (m, disabled) => this.regionManager.each(
                r => r.currentView && r.currentView.viewModel && r.currentView.viewModel.set('parentDisabled', disabled)));

        this.on('disable', () => this.viewModel.set('disable', true));
        this.on('enable', () => this.viewModel.set('disable', false));
    },

    onRender() {
        let logoInput = new ImageInput({
            model: this.model,
            modelKey: 'logo'
        });

        this.listenTo(logoInput, 'loading', () => this.trigger('disable'));
        this.listenTo(logoInput, 'loaded loadfailed', () => this.trigger('enable'));

        this.listenTo(logoInput, 'change', logo => {
            if(logo instanceof Blob || _.isString(logo)) {
                this.trigger('disable');

                ImageInputHelpers.upload(logo, 'bl')
                    .then(uploadedFile => {
                        var data = new FormData();
                        data.append('id', this.model.id);
                        data.append('logo', uploadedFile.id);

                        Server.callServer({
                            url: settings.host + settings.serv_place.setlogo,
                            type: "POST",
                            cache: false,
                            contentType: false,
                            processData: false,
                            data: data,
                            success : data => {
                                this.trigger('enable');
                                this.model.set('logo', data.logo);
                                this.model.set('logoThumb', data.logoThumb);
                            },
                            error : (jqXHR, textStatus, errorThrown) => {
                                this.trigger('enable');
                                this.showError();
                            }
                        });
                    })
                    .catch(e => {
                        this.trigger('enable');
                        this.showError(e);
                    });
            }
        });
        this.logo.show(logoInput);

        autosize(this.ui.shortdescTextarea);
        setTimeout(() => autosize.update(this.ui.shortdescTextarea), 0);

        $(document).on(initGoogleMap.event_map_inited, () => this._initMyGoogleMap());
        this._initMyGoogleMap();

        this.ui.categories.b2ccategoryviewer({
            showParents: true,
            indentChildren: true
        });
        this.onCategoriesChange();

        this.phones.show(new PhonesWidget({ model : this.model }));

        this.properties.show(new PropertiesWidget({ model: this.model, edit: true }));

        let workingDaysWidget = new WorkingDaysWidget({ model: this.model });
        workingDaysWidget.listenTo(this, 'days:error:add', msg => workingDaysWidget.trigger('days:error:add', msg));
        workingDaysWidget.listenTo(this, 'errors:clear', () => workingDaysWidget.trigger('errors:clear'));
        this.workingHours.show(workingDaysWidget);
    },

    _initMyGoogleMap() {
        if (this.myGoogleMap != null || !initGoogleMap.inited)
            return;

        this.myGoogleMap = new MyGoogleMap({
            //jqGoogleMapAutocompleteInput : $('#google_map_autocomplete'),
            default_center_latitude: null,
            default_center_longitude: null,
            jqGoogleMapAutocompleteInput: this.ui.address,
            jqGoogleMapCanvas: this.ui.mapCanvas,
            jqGoogleMapCrosshair: this.ui.mapCrosshair,
            jqLatitudeInput: this.ui.adrLat,
            jqLongitudeInput: this.ui.adrLng,
            htmlAdrInputFields: {
                street_number: this.ui.adrSN,
                route: this.ui.adrRoute,
                locality: this.ui.adrLocality,
                administrative_area_level_1: this.ui.adrAAL1,
                country: this.ui.adrCountry,
                postal_code: this.ui.adrCode
            },
            onCenterChanged: this.onCenterChanged.bind(this)
        });

        if (this.model.has('adr_latitude') && this.model.has('adr_longitude') ||
            this.model.has('adr_street_number') || this.model.has('adr_street_name') ||
            this.model.has('adr_city') || this.model.has('adr_state') ||
            this.model.has('adr_country_code') || this.model.has('adr_postal_code') ||
            this.model.has('adr_postal_code')) {
            this.myGoogleMap.setAddress({
                street_number: this.model.get('adr_street_number'),
                route: this.model.get('adr_street_name'),
                locality: this.model.get('adr_city'),
                administrative_area_level_1: this.model.get('adr_state'),
                country: this.model.get('adr_country_code'),
                postal_code: this.model.get('adr_postal_code'),
                latitude: this.model.get('adr_latitude'),
                longitude: this.model.get('adr_longitude'),
            });
        } else {
            if (this.model.has('adr_latitude') && this.model.has('adr_longitude'))
                this.myGoogleMap.setCenter(this.model.get('adr_latitude'), this.model.get('adr_longitude'));
            else
                this.myGoogleMap.setCenter(geo.getCurrentPosition().lt, geo.getCurrentPosition().lg);

            this.myGoogleMap.clear();
        }
    },

    validate() {
        this.trigger('errors:clear');
        if(!this.model.get('name') || this.model.get('name') == noname) {
            this.trigger('name:error:add', 'Name cannot be blank');
            return false;
        }

        return true;
    },

    onSubmit(e) {
        e.preventDefault();

        if(this.validate()) {
            let place = _.pick(this.model.attributes, ['id', 'brandid', 'name', 'www', 'shortdesc',
                'adr_street_number', 'adr_street_name', 'adr_city', 'adr_state', 'adr_country_code',
                'adr_postal_code', 'adr_latitude', 'adr_longitude', 'phone', 'days', 'properties',
                'reservations_allowed', 'max_days_before_reservation', 'menu_allowed']);

            place.categories = this.model.get('categories') ? this.model.get('categories').map(cat => cat.id) : [];
            place.mode = false; // сохранение
            place.status = 1; // сохранение публичной версии

            if(place.name == noname) delete place.name;

            if(GoogleAnalytics && this.model.get('isNew')) {
                console.log('ga.trackEvent', 'Place', 'submit', 'place.id', Number(place.id));
                GoogleAnalytics.trackEvent('Place', 'submit', 'place.id',  Number(place.id));
            }

            this.trigger('disable');

            place.properties = _.chain(place.properties)
                .keys()
                .reduce((memo, key) => {
                    if (_.isArray(place.properties[key]) && place.properties[key].length === 0) {
                        memo[key] = '';
                    } else {
                        memo[key] = place.properties[key];
                    }

                    return memo;
                }, {})
                .value();

            B2CPlace.server_change(place,
                data => {
                    this.trigger('enable');
                    if(data.name == noname) delete data.name;
                    this.model.set(this.model.parse(data));

                    if (this.model.get('status') == 0) { // Место сейчас в состоянии черновика
                        this.render();
                    } else if(this.model.get('status') == 1) {
                        if(GoogleAnalytics && this.model.get('isNew')) {
                            console.log('ga.trackEvent', 'Place', 'created', 'data.id', Number(data.id));
                            GoogleAnalytics.trackEvent('Place', 'created', 'data.id',  Number(data.id));
                        }
                        this.trigger('submitted');
                    }
                },
                (jqXHR, textStatus, errorThrown) => {
                    this.trigger('enable');
                    if(GoogleAnalytics && this.model.get('isNew')) {
                        console.log('ga.trackEvent', 'Place', 'submit failed', 'status', Number(jqXHR.status));
                        GoogleAnalytics.trackEvent('Place', 'submit failed', 'status', Number(jqXHR.status));
                    }
                    if (jqXHR.status == 422) { // Fields validation error
                        const fields = _.isArray(jqXHR.responseJSON) && jqXHR.responseJSON
                            || (jqXHR.responseJSON.error && jqXHR.responseJSON.error.field) || [];

                        for (let err of fields) {
                            this.trigger(`${err.field}:error:add`, err.message);
                        }
                    } else {
                        this.showError(jqXHR, textStatus, errorThrown);
                    }
                });
        }
        return false;
    },

    onCenterChanged() {
        if(this.myGoogleMap) {
            var adr = this.myGoogleMap.getAddress();
            this.model.set({
                adr_street_number: adr.street_number,
                adr_street_name: adr.route,
                adr_city: adr.locality,
                adr_state: adr.administrative_area_level_1,
                adr_country_code: adr.country,
                adr_postal_code: adr.postal_code,
                adr_latitude: adr.latitude,
                adr_longitude: adr.longitude,
            });
        }
    },

    onMapBtnClick() {
        if(this.ui.map.hasClass('hidden')) {
            this.ui.map.removeClass('hidden');
            this.ui.mapBtn.addClass('active');
            this.myGoogleMap && this.myGoogleMap.refresh();
        } else {
            this.ui.map.addClass('hidden');
            this.ui.mapBtn.removeClass('active');
        }
    },

    onCategoriesChange() {
        if (this.ui.categories) {
            this.ui.categories.b2ccategoryviewer('set', this.model.get('categories') || []);
            this.ui.categories.children('.b2c_cat').each((index, el) => el.style['border-color'] = getNextPrettyColor($(el).find('.b2c_cat_name').text()));
        }

        this.model.set('properties', _(
                new PropertyBase().get_properties_by_ids(
                    _(new CategoryBase().getCatsByIds(_(this.model.get('categories') || []).map(c => c.id)))
                        .reduce((ids, c) => _.union(ids, c.prop_ids), [])
                )
            ).reduce(
                (ret, p) => _.extend(ret, { [p.varname] : this.model.get('properties') && this.model.get('properties')[p.varname] }),
                Object.create(null)
            )
        );
    },

    onCategoriesBtnClick() {
        new CategoryChooserWindow()
            .show(this.model.get('categories') ? this.model.get('categories').map(cat => cat.id) : [])
            .then(selection => {
                const selected = selection.get('selected'),
                    hasSelectedChildren = selection.get('hasSelectedChildren'),
                    allChildrenSelected = selection.get('allChildrenSelected');

                const cats = selected.filter(c => !hasSelectedChildren.get(c.id) || allChildrenSelected.get(c.id));
                cats.sort((c1, c2) => {
                    const parents1 = c1.get('parents').slice().reverse(),
                          parents2 = c2.get('parents').slice().reverse();

                    parents1.push(c1);
                    parents2.push(c2);

                    for(let i = 0; i < Math.max(parents1.length, parents2.length); i++) {
                        let name1 = parents1[i] && parents1[i].get('name') || '',
                            name2 = parents2[i] && parents2[i].get('name') || '';

                        if(name1 < name2) return -1;
                        else if(name1 > name2) return 1;
                    }

                    let name1 = c1.get('name'), name2 = c2.get('name');

                    return name1 < name2 ? -1 : name1 > name2;
                });
                this.model.set('categories', _(cats).map(c => c.toJSON({ computeds: true })));
            });
    },
});

let PlaceEditor = Window.extend({
    windowName: "place-editor-window",
    className: "place-editor-window",

    onRender() {
        console.log(this.model);
        if(this.model) {
            let headerView = new HeaderView({
                leftButtons: ['back'],
                title: this.model.get('isNew') ? 'Add new place' : 'Edit place',
                rightButtons: [{ id: 'save' }]
            })
            this.listenTo(headerView, 'back:click', () => this.cancel());
            this.listenTo(headerView, 'save:click', () => contentWidget.trigger('submit'));
            this.header.show(headerView);

            let contentWidget = new ContentWidget({ model: this.model });
            this.listenTo(contentWidget, 'submitted', () => {
                this.viewModel.set('hasUnsavedChanges', false);
                this.close(this.model);
            });
            this.listenTo(contentWidget, 'enable', () => this.trigger('enable'));
            this.listenTo(contentWidget, 'disable', () => this.trigger('disable'));
            this.content.show(contentWidget);

            let footerView = new FooterView({
                buttons: [{
                    id: 'cancel',
                    text: 'CANCEL',
                    icn: 'empty'
                },{
                    id: 'save',
                    text: this.model.get('isNew') ? 'ADD PLACE' : 'SAVE',
                    icn: 'empty'
                }]
            });
            this.listenTo(footerView, 'cancel:click', () => this.cancel());
            this.listenTo(footerView, 'save:click', () => contentWidget.trigger('submit'));
            this.footer.show(footerView);

            this.$el.enhanceWithin();
        } else {
            this.header.show(new HeaderView({ title: 'Waiting for server...' }));
            this.content.reset();
            this.content._ensureElement();
            this.footer.reset();
        }
    },

    onShow() {
        this.viewModel.set('hasUnsavedChanges', false);

        if(!this.model) {
            if(GoogleAnalytics) {
                console.log('ga.trackEvent', 'Place', 'create start');
                GoogleAnalytics.trackEvent('Place', 'create start');
            }
            return new Promise((resolve, reject) => {
                let place = {
                    mode : false, // сохранение
                    status : 0, // сохранение черновика
                    name : noname,
                };

                B2CPlace.server_create(place,
                    data => {
                        if(GoogleAnalytics) {
                            console.log('ga.trackEvent', 'Place', 'draft created', 'data.id',  Number(data.id));
                            GoogleAnalytics.trackEvent('Place', 'draft created', 'data.id',  Number(data.id));
                        }
                        resolve(_.extend(place, data));
                    },
                    (jqXHR, textStatus, errorThrown) => {
                        if(GoogleAnalytics) {
                            console.log('ga.trackEvent', 'Place', 'draft rejected', textStatus);
                            GoogleAnalytics.trackEvent('Place', 'draft rejected', textStatus);
                        }
                        reject(new AjaxError(jqXHR, textStatus, errorThrown));
                    });
            })
            .then(place => {
                if(place.id) {
                    if(place.name == noname) delete place.name;
                    this.model = new PlaceModel(place);
                    this.model.set('isNew', true);
                    if(!place.logo && !place.logoThumb) {
                        this.model.unset('logo');
                        this.model.unset('logoThumb');
                    }
                    this.viewModel.listenToOnce(this.model, 'change', () => this.viewModel.set('hasUnsavedChanges', true));
                    this.render();
                } else throw new Error("place has no id!");
            });
        } else {
            this.viewModel.listenToOnce(this.model, 'change', () => this.viewModel.set('hasUnsavedChanges', true));
        }
    },

    cancel() {
        if(!this.viewModel.get('disabled'))  {
            if(this.viewModel.get('hasUnsavedChanges')) {
                new ConfirmModal({ message: "Cancel editing? All changes will be lost!" })
                    .show()
                    .then(confirm => confirm && this.close());
            } else {
                return Window.prototype.cancel.apply(this, arguments);
            }
        }
    }
});

export default PlaceEditor;
