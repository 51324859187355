import { Model } from '@b2cmessenger/backbone';

const CategoryModel = Model.extend({
    defaults: {
        _selected: false
    },
    computeds: {
        parents: {
            deps: ['parent'],
            get: parent => {
                const parents = [];
                while(parent) {
                    parents.push(parent);
                    parent = parent.get('parent');
                }
                return parents;
            }
        },
        children: {
            deps: ['_children'],
            get: children => children,
            set: val => _.create(null, { _children: val })
        },
        //hasSelectedChildren: {
        //    deps: ['children'],
        //    get: children => !!children && children.length && !!children.find(c => c.get('selected') || c.get('hasSelectedChildren'))
        //},
        //allChildrenSelected: {
        //    deps: ['children'],
        //    get: children => !!children && children.length && !children.find(c => !c.get('selected'))
        //},
        //selected: {
        //    deps: ['_selected', 'allChildrenSelected'],
        //    get: (_selected, allChildrenSelected) => _selected || allChildrenSelected,
        //    set(val) {
        //        if(!val) {
        //            this._clearSelection();
        //        } else {
        //            return { _selected: !!val };
        //        }
        //    }
        //},
        //selectedOrHasSelectedChildren: {
        //    deps: ['selected', 'hasSelectedChildren'],
        //    get: (selected, hasSelectedChildren) => selected || hasSelectedChildren
        //},
        searchName: {
            deps: ['name', 'path', 'url'],
            get: (name, path, url) =>
                (name || "").replace(/\W/g, '').toLowerCase() + ' ' +
                (path || "").replace(/.*\//g, '').replace(/\W/g, '').toLowerCase() + ' ' +
                (url || "").replace(/.*\//g, '').replace(/\W/g, '').toLowerCase()
        }

    },

    initialize() {
        const children = this.get('children');
        if(children) {
            this.listenTo(children, 'update reset change:selected change:hasSelectedChildren', () => this.trigger('change:children change', this, children, { simulated: true }));
        }

        this.on('change:_children', (m, children, options) => {
            if(!options.simulated && children) {
                this.listenTo(children, 'update reset change:selected change:hasSelectedChildren', () => this.trigger('change:children change', this, children, { simulated: true }));
            }
        });

        //this.on('change:_selected', (m, selected) => {
        //    if(!selected) {
        //        const children = this.get('children');
        //        if(children) {
        //            children.each(c => {
        //                c.set({ selected: true}, { silent: true });
        //                c.set('selected', false);
        //            });
        //        }
        //    }
        //});
    },

    //_clearSelection() {
    //    this.set({ _selected: false });
    //    const children = this.get('children');
    //    if(children) {
    //        children.each(c => c._clearSelection());
    //    }
    //}
});

export default CategoryModel;
