import React from 'react';

import './MenuItemProperty.scss';

const MenuItemProperty = ({ name, options }) => {
    let content = null;

    try {
        const optionsList =
            options &&
            _(options).map((option, i) => (
                <div key={i} className="property-option">
                    <span>{option}</span>
                </div>
            ));

        content = optionsList && (
            <div className="property">
                <div className="property-name">
                    <span>{name}</span>
                </div>
                <div className="property-options">{optionsList}</div>
            </div>
        );
    } catch (e) {
        showError(window.debug ? e : 'Error displaying properties.');
    }

    return content;
};

export default MenuItemProperty;
