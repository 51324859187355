import { ItemView } from '@b2cmessenger/backbone';
import template from './LoyaltyKioskCheckinGiftModal.jade';
import './LoyaltyKioskCheckinGiftModal.scss';
import Modal from 'windows/Modal/Modal';
import FooterView from "widgets/Footer/Footer";

const LoyaltyKioskCheckinGiftModal = Modal.extend({
    windowName: "kiosk-checkin-gift-modal-window",
    className: "modal-window kiosk-checkin-gift-modal-window green-border",

    onRender() {
        const contentView = new LoyaltyKioskCheckinGiftContent({
            model: this.model
        });
        this.listenTo(contentView, 'continue', () => this._onContinue());
        this.content.show(contentView);

        const footerView = new FooterView({
            buttons: [{
                id: "close",
                text: "LOGOUT",
                icn: "empty",
                class: "with-caption"
            }, {
                id: "continue",
                text: "CONTINUE",
                icn: "empty"
            }]
        });
        this.listenTo(footerView, 'close:click', view => this.close('logout'));
        this.listenTo(footerView, 'continue:click', view => this.close());
        this.footer.show(footerView);

        const updateCloseButton = () => {
            $(footerView.ui.btnclose)
                .find('.text')
                .html(`LOGOUT <span>in ${this.options.loyaltyKioskModel.get('timeLeft')}</span>`);
        }
        footerView.listenTo(this.options.loyaltyKioskModel, 'change:timeLeft', updateCloseButton);
        updateCloseButton();
    }
});

@ItemView.options({
})
@ItemView.properties({
    className: 'loyalty-kiosk-gift-item',
    template,

    ui: {
        image: '[data-js-image]',
    }
})
class LoyaltyKioskCheckinGiftContent extends ItemView {
    onRender() {
        if (!this.model.get('thumb')) {
            switch (this.model.get('type')){
                case 1:
                    this.ui.image.addClass('empty coins');
                    break;

                case 2:
                    this.ui.image.addClass('empty gifts');
                    break;

                case 3:
                    this.ui.image.addClass('empty discounts');
                    break;
            }
        } else {
            if (this.model.get('type') == 1) {
                this.ui.image.addClass('empty coins');
            } else {
                this.ui.image.removeClass('empty');
                this.ui.image.css('background-image', `url("${this.model.get('thumb')}")`);
            }
        }
    }
}

export default LoyaltyKioskCheckinGiftModal;