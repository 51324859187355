import 'utils/Element.scrollIntoCenter';
import { Model, ItemView, CollectionView } from '@b2cmessenger/backbone';
import './PhotoSlider.scss';

let PhotoSliderItem = ItemView.extend({
    className: 'widget photo-slider-item-widget',
    template: () => '',
    tagName: 'img',

    bindings: {
        ':el': 'attr:{src:thumbUrl},classes:{active:isActive}'
    },

    events: {
        'click': 'onClick'
    },

    initialize() {
        this.listenTo(this.viewModel, 'change:activePhotoIndex', (m, activePhotoIndex) => this.onActivePhotoIndexChanged());
    },

    onAttach() {
        this.onActivePhotoIndexChanged();
    },

    onActivePhotoIndexChanged() {
        if(this.el && this._c.isActive()) {
            this.el.scrollIntoCenter();
        }
    },

    onClick() {
        this.trigger('select', this);
    }
});

let PhotoSlider = CollectionView.extend({
    className: 'widget photo-slider-widget',

    childView: PhotoSliderItem,
    childViewOptions(model, index) {
        return {
            viewModel: this.viewModel,
            computeds: {
                isActive: {
                    deps: ['activePhotoIndex'],
                    get: activePhotoIndex => activePhotoIndex == index
                }
            }
        };
    },

    initialize() {
        if(!this.viewModel) {
            this.viewModel = new Model({
                activePhotoIndex: 0
            });
        }
    },

    onChildviewSelect(childview) {
        this.viewModel.set('activePhotoIndex', this.collection.indexOf(childview.model));
    }
});

export default PhotoSlider;
