import React, { useState, useMemo, useCallback, useEffect } from 'react';
import ReactPage from 'pages/ReactPage';
import { connect } from 'react-redux';

import Page, { PageContent, PageHeader } from 'components/page/Page';
import Header from 'components/UI/Header/Header';
import { AddButton, BackButton, Button, MenuButton } from 'components/UI/Button/Button';
import { getMenuError, getMenuIsReadyToInitialize } from 'store/menu/selectors';
import Spinner from 'components/UI/Spinner/Spinner';
import MenuItemError from 'components/menu/MenuItemDetail/MenuItemError';
import withStore from 'components/withStore';
import { showError } from 'windows/Modal/Info';
import qrCodeScanner from 'utils/qrCodeScanner';
import './MenuDashboard.scss';
import { SliderGroup, SwitchInput } from 'components/UI/InputGroup/InputGroup';
import { getIsLoginedUserNowWorkingByPlaceId } from 'store/app/selectors';
import { changeEmployeeStatus } from 'utils/api/menu';
import { getCountPlaceMenuActiveOrdersAssignedToMe } from 'store/menu/orders/selectors';
import NotificationsWindow from 'windows/Notifications/Notifications';
import NotificationModel from 'models/NotificationModel';
import useInitializeMenu from 'hooks/useInitializeMenu';
import { getMenuPermissionsInCurrentPlace } from 'store/menu/sections/selectors';

function isPlaceMenuRelatedNotification(appEvent, templateType) {
    switch (appEvent) {
        case NotificationModel.Type.NewMenuOrder:
            return templateType === 'no_working_waiters' || templateType === 'order_assigned_to_you';
        case NotificationModel.Type.MenuOrderUpdate:
            return templateType === 'order_updated_waiter_notify';
        default:
            return false;
    }
}

const MenuDashboardContent = (props) => {
    const [working, setWorking] = useState(false);
    const { placeId, error, ordersCount, isActive, menuPermissions } = props;

    const { shouldInitMenu, loading, readyToInitialize, initialized } = useInitializeMenu({ placeId, isActive });
    const { isWaiter, isOrderManager, isMenuManager } = menuPermissions;

    const nowWorkingLoading = useMemo(() => working !== props.nowWorking, [working, props.nowWorking]);

    const contentReady = useMemo(() => {
        return error === null && !loading && !shouldInitMenu && initialized;
    }, [shouldInitMenu, error, loading, initialized]);

    const pageTitle = useMemo(() => {
        switch (true) {
            case isOrderManager:
                return 'Order Manager Menu';
            case isWaiter:
                return 'Waiter Menu';
            default:
                return 'Menu';
        }
    }, [isOrderManager, isWaiter]);

    const setNowWorking = useCallback(
        (value) => {
            setWorking((prevWorking) => {
                changeEmployeeStatus({ placeId, status: Number(value) })
                    .then((data) => {
                        LoginedUserHandler.update_work_place_status(placeId, Number(data.status));
                    })
                    .catch((e) => {
                        setWorking(prevWorking);
                        showError(e instanceof Error ? e.message : e);
                    });

                return value;
            });
        },
        [placeId, setWorking]
    );

    const goToPlaceOrders = useCallback(() => app.controller.goToPlaceOrdersPage({ placeId }), [placeId]);
    const goToPlaceTables = useCallback(() => app.controller.goToMenuSections({ placeId }), [placeId]);
    const goToNotifications = useCallback(() => {
        new NotificationsWindow({
            collection: app.controller.notificationCollection,
            filter: (m) => isPlaceMenuRelatedNotification(m.get('app_event'), m.get('template_type')),
        }).show();
    }, []);

    const scanQr = useCallback(async () => {
        try {
            const qrCodeModel = await qrCodeScanner();

            if (qrCodeModel) {
                window.app.executeQrAction(qrCodeModel, placeId);
            }
        } catch (e) {
            if (e) {
/////////////////////////////
/////////////////////////////
/////////////////////////
                showError(_.isString(e) ? e : e.message);
//////////////////////////
            }
        }
    }, [placeId]);

    const goToUserSearch = useCallback(() => app.controller.goToUserSearchPage({ placeId }), [placeId]);
    const goToMenuCatalog = useCallback(() => app.controller.goToMenuPage({ placeId }), [placeId]);
    const goToMenuSettings = useCallback(() => app.controller.goToMenuSettings({ placeId }), [placeId]);
    const workingInfoMessage = useCallback(
        (working) => {
            switch (true) {
                case !working:
                    return 'You are not working';
                case isOrderManager:
                    return 'You will be notified about issues';
                case isWaiter:
                    return 'You are able to receive new orders';
                default:
                    return 'You are working';
            }
        },
        [isOrderManager, isWaiter]
    );

    const waiterContent = useMemo(() => {
        return (
            <>
                <SliderGroup
                    className="now-working outline"
                    caption="Working status"
                    info={workingInfoMessage(working)}
                >
                    <SwitchInput
                        disabled={nowWorkingLoading}
                        value={working}
                        onChange={setNowWorking}
                        additionalClassNames={['scaled-150-percent']}
                    />
                </SliderGroup>
            </>
        );
    }, [working, nowWorkingLoading, setNowWorking]);

    useEffect(() => {
        setWorking(props.nowWorking);
    }, [setWorking, props.nowWorking]);

    const onShow = useCallback(() => {
        if (readyToInitialize) {
            readyToInitialize(true);
        }
    }, [readyToInitialize]);

    return (
        <Page instance={props.__page__} onShow={onShow}>
            <PageHeader>
                <Header>
                    <BackButton onClick={props.goBack} />
                    <h1 className="title">{pageTitle}</h1>
                    <AddButton onClick={goToMenuCatalog} />
                    <MenuButton right />
                </Header>
            </PageHeader>
            <PageContent className="scrollable grow with-standard-padding">
                {contentReady ? (
                    <>
                        {isOrderManager || isWaiter ? waiterContent : null}
                        <div className="button-links">
                            {isWaiter ? (
                                <Button className="btn-big ion-md-cart" onClick={goToPlaceOrders} wrapChildren={false}>
                                    <p className="text">Orders</p>
                                    {Boolean(ordersCount) && (
                                        <span className="count">{ordersCount > 99 ? '99+' : ordersCount}</span>
                                    )}
                                </Button>
                            ) : null}
                            {isOrderManager || isWaiter ? (
                                <Button className="btn-big ion-md-apps" onClick={goToPlaceTables}>
                                    Tables
                                </Button>
                            ) : null}
                            <Button className="btn-big ion-ios-notifications" onClick={goToNotifications}>
                                Notifications
                            </Button>
                            <Button className="btn-big icon-qrcode" onClick={scanQr}>
                                Scan QR
                            </Button>
                            <Button className="btn-big ion-md-search" onClick={goToUserSearch}>
                                Find client
                            </Button>
                            {isMenuManager ? (
                                <Button className="btn-big ion-ios-settings" onClick={goToMenuSettings}>
                                    Menu Settings
                                </Button>
                            ) : null}
                        </div>
                    </>
                ) : null}
                {((!contentReady && !error) || loading) && <Spinner />}
                {error !== null && <MenuItemError errorText={error} displayRetryButton={false} />}
            </PageContent>
        </Page>
    );
};

const ConnectedMenuDashboardContent = withStore(
    connect((state, ownProps) => ({
        error: getMenuError(state),
        nowWorking: getIsLoginedUserNowWorkingByPlaceId(state, ownProps.placeId),
        ordersCount: getCountPlaceMenuActiveOrdersAssignedToMe(state),
        isReadyToInitialize: getMenuIsReadyToInitialize(state),
        menuPermissions: getMenuPermissionsInCurrentPlace(state),
    }))(MenuDashboardContent)
);

const MenuDashboardPage = ReactPage.extend({
    attributes: {
        id: 'page_menu_dashboard',
    },
    className: 'menu-dashboard-page',
    component: ConnectedMenuDashboardContent,
    immediateRenderOnShow: true,
});

export default MenuDashboardPage;
