import { Model } from '@b2cmessenger/backbone';

@Model.properties({
    defaults: {
        photo: null,
        thumb: null,
        photo_w: null,
        photo_h: null,
        thumb_w: null,
        thumb_h: null
    },

    computeds: {
        photoUrl: {
            deps: ['photo', 'thumb'],
            get: (photo, thumb) => photo || thumb,
        },
        thumbUrl: {
            deps: ['photo', 'thumb'],
            get: (photo, thumb) => (photo && /\.gif$/.test(photo) && photo) ||
                thumb || photo,
        },
        photoAR: {
            deps: ['photo', 'thumb', 'photo_w', 'photo_h', 'thumb_w', 'thumb_h'],
            get: (photo, thumb, photo_w, photo_h, thumb_w, thumb_h) => photo ? (photo_w / photo_h) || 0 : thumb ? (thumb_w / thumb_h) || 0 : 1
        },
        photoDimensions: {
            deps: ['photo', 'thumb', 'photo_w', 'photo_h', 'thumb_w', 'thumb_h'],
            get: (photo, thumb, photo_w, photo_h, thumb_w, thumb_h) => photo ? { w: photo_w, h: photo_h } : thumb ? { w: thumb_w, h: thumb_h } : { w: 0, h: 0 }
        }
    }
})
class PhotoModel extends Model {
    constructor(attributes, options) {
        super(attributes, options);
    }
};

export default PhotoModel;
