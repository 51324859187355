import React from 'react';

import './MenuItemParamInput.scss';
import NumericInput from 'components/UI/NumericInput/NumericInput';

const MenuItemParamInput = (props) => {
    const { id, icnClass, text, value, min, max, unit, units = null, onChange, onChangeUnit, error } = props;

    const onSelectChangeHandler = (e) => {
        onChangeUnit && onChangeUnit(e.currentTarget.value);
    };

    const unitEl = units ? (
        <label>
            <select value={unit} onChange={onSelectChangeHandler}>
                {units.map((u) => (
                    <option key={u} value={u}>
                        {u}
                    </option>
                ))}
            </select>
        </label>
    ) : unit ? (
        <span>{unit}</span>
    ) : null;

    const numericOptions = {
        value,
        onChange,
    };

    if (_.isNumber(min)) {
        numericOptions.min = min;
    }

    if (_.isNumber(max)) {
        numericOptions.max = max;
    }

    return (
        <div className={`param-input-group ${id}`}>
            <div className="param-label">
                <div className={`param-icon ${icnClass}`} />
                <span className="value">{`${text}:`}</span>
            </div>
            <span className="param-input">
                <NumericInput {...numericOptions} />
                {unitEl}
            </span>
            {error && <p className="error">{error}</p>}
        </div>
    );
};

export default MenuItemParamInput;
