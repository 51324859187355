import { CollectionView, ItemView, LayoutView } from '@b2cmessenger/backbone';
import LoyaltyKioskInfo from './LoyaltyKioskInfo';
import template from './LoyaltyKioskGifts.jade';
import giftItemTemplate from './LoyaltyKioskGiftItem.jade';
import './LoyaltyKioskGifts.scss';

const LoyaltyKioskGiftItem = ItemView.extend({
    className: 'loyalty-kiosk-gift-list-item',
    template: giftItemTemplate,

    ui: {
        image: '[data-js-image]',
        price: '[data-js-price]',
        redeem: '[data-js-redeem]'
    },

    bindings: {
        ':el': 'classes:{active:c_isActive}',
        '@ui.redeem': 'disabled:not(c_isActive)'
    },

    computeds: {
        c_isActive: {
            deps: ['transformation_ratio', '$loyaltyKioskModel'],
            get: (transformation_ratio, loyaltyKioskModel) => loyaltyKioskModel && loyaltyKioskModel.get('coins') >= transformation_ratio
        }
    },

    triggers: {
        'click @ui.redeem': 'redeem'
    },

    bindingSources() {
        return { loyaltyKioskModel: this.options.loyaltyKioskModel || null }
    },

    onRender() {
        if (!this.model.get('thumb')) {
            switch (this.model.get('type')){
                case 1:
                    this.ui.image.addClass('empty coins');
                    break;

                case 2:
                    this.ui.image.addClass('empty gifts');
                    break;

                case 3:
                    this.ui.image.addClass('empty discounts');
                    break;
            }
        } else {
            if (this.model.get('type') == 1) {
                this.ui.image.addClass('empty coins');
            } else {
                this.ui.image.removeClass('empty');
                this.ui.image.css('background-image', `url("${this.model.get('thumb')}")`);
            }
        }
    }
});

const LoyaltyKioskGiftList = CollectionView.extend({
    className: 'loyalty-kiosk-gifts',
    childView: LoyaltyKioskGiftItem,
    childViewOptions() {
        return {
            loyaltyKioskModel: this.options.loyaltyKioskModel
        }
    }
});

const LoyaltyKioskGifts = LayoutView.extend({
    template,
    className: 'widget loyalty-kiosk-gifts-widget',

    regions: {
        info: '[data-js-info]',
        gifts: '[data-js-gifts]'
    },

    onRender() {
        const infoView = new LoyaltyKioskInfo({ model: this.model });
        this.listenTo(infoView, 'logout', () => this.trigger('logout'));

        this.info.show(infoView);

        const giftListView = new LoyaltyKioskGiftList({
            collection: this.options.giftCollection,
            loyaltyKioskModel: this.model
        });

        this.listenTo(giftListView, 'childview:redeem', cv => this.trigger('redeem', cv.model.id));

        this.gifts.show(giftListView);
    }
});

export default LoyaltyKioskGifts;