import { Model, CollectionView, ItemView, LayoutView } from '@b2cmessenger/backbone';
import Page from 'pages/Page';

import BrandCollection from 'models/BrandCollection';

import BrandItemView from 'widgets/BrandItem/BrandItem';
import HeaderView from 'widgets/Header/Header';
import SearchPanelView from 'widgets/SearchPanel/SearchPanel';
import LoyaltyCardEditorWindow from 'windows/LoyaltyCardEditor/LoyaltyCardEditor';

import template from './LoyaltyCardsList.jade';
import './LoyaltyCardsList.scss';
import ViewWithWindows from 'traits/ViewWithWindows';

let LoyaltyCardsCollectionView = CollectionView.extend({
    tagName: 'ul',
    childView: BrandItemView,
    childViewEventPrefix: 'child',
    className: "loyalty-cards-collection-widget widget",
});

let LoyaltyCardsBrandCollection = BrandCollection.extend({
    initialize() {
        this.loyaltyCardBase = LoyaltyCardBase;

        this.listenTo(this.loyaltyCardBase, 'sync', () => this.fetch());
    },

    sync(method, collection, options) {
        return this[method](collection, options);
    },

    read(collection, options) {
        options = options || {};
        options.success(this.loyaltyCardBase.getBrands(options.query || ''));
    }
});

const withWindowsTrait = {
    Trait: ViewWithWindows,
    options: {
        windowMap: [
            {
                cls: LoyaltyCardEditorWindow,
                trigger() {
                    this.header.currentView.trigger('add:click');
                }
            }
        ],
    }
};

let LoyaltyCardsList = Page.extendWithTraits([withWindowsTrait], {
    attributes: {id: 'loyalty-cards-list'},
    template: template,
    className: "loyalty-cards-list-page",

    regions: {
        header: '[data-js-header]',
        searchPanel: '[data-js-search-panel]',
        content: '[data-js-content]'
    },

    initialize(options) {
        this.collection = new LoyaltyCardsBrandCollection();
    },

    onRender() {
        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: "Loyalty Card",
            rightButtons: [{id: 'add', text: 'Add'}]
        });
        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.listenTo(headerView, 'add:click', this.onAddCardClick);
        this.header.show(headerView);

        let searchPanelView = new SearchPanelView({placeholder: 'Name', withFilter: false, iconClass: 'ion-ios-contact'});
        this.listenTo(searchPanelView, 'text:change', this.onFilterChange);
        this.listenTo(searchPanelView, 'text:keyup', this.onFilterChange);
        this.searchPanel.show(searchPanelView);

        let loyaltyCardsCollectionView = new LoyaltyCardsCollectionView({ collection: this.collection });
        this.listenTo(loyaltyCardsCollectionView, 'child:brand:click', this.onBrandClick);
        this.content.show(loyaltyCardsCollectionView);
    },

    onShow() {
        this.collection.fetch();

        return Page.prototype.onShow.call(this);
    },

    onAddCardClick() {
        new LoyaltyCardEditorWindow().show().then(card => {
            if (card) {
                this.collection.fetch();
            }
        });
    },

    onFilterChange(text) {
        this.collection.fetch({query: text.toLowerCase()});
    },

    onBrandClick(childView, brandId) {
        app.controller.goToLoyaltyCardsPage({ brandId: childView.model.id });
    }
});

export default LoyaltyCardsList;
