import 'utils/Element.scrollIntoCenter';

import { ItemView } from '@b2cmessenger/backbone';
import Page from '../pages/Page';

import { TaskEditorBroadcastKeyboardPanel } from 'widgets/KeyboardPanels/KeyboardPanels';

const panelsMap = {
    'TaskEditorBroadcastKeyboardPanel': TaskEditorBroadcastKeyboardPanel
};

const scrollToFocusedElem = _.debounce(e => {
    const offset = Number(e && e.keyboardHeight) || 0,
        el = document.activeElement || $("input:focus,textarea:focus")[0];

    el && el.scrollIntoCenter(null, {
        windowHeightOffset: -offset
    });

    requestAnimationFrame(() => {
        el.classList.remove('fix-caret');
        requestAnimationFrame(() => el.classList.add('fix-caret'));
    });

}, 100);

let instance;

class KeyboardService {
    constructor(selector = '[data-js-keyboard-padding]') {
        if (instance) return instance;

        this.selector = selector;
        this.keyboardHeight = 0;
        this.isKeyboardShown = false;
        this.currentActiveElement = null;

        window.addEventListener('keyboardDidShow', this._onKeyboardShow.bind(this));
        window.addEventListener('keyboardDidHide', this._onKeyboardHide.bind(this));

        instance = this;
    }

    _onKeyboardShow(e) {
        const height = Number(e && e.keyboardHeight) || 0;

        this.keyboardHeight = height;
        this.isKeyboardShown = true;

        this._updateAdditionalPanel();

        const additionalPanelHeight = app.keyboardAdditionalPanel.$el && app.keyboardAdditionalPanel.$el.outerHeight() || 0;

        $(this.selector)
            .css('height', `${height + additionalPanelHeight}px`)
            .addClass('native-keyboard-shown');

        scrollToFocusedElem(e);
    }

    _onKeyboardHide(e) {
        this.keyboardHeight = 0;
        this.isKeyboardShown = false;

        $(this.selector)
            .css('height', '0')
            .removeClass('native-keyboard-shown');

        this._updateAdditionalPanel();
    }

    _updateAdditionalPanel() {
        if (this.isKeyboardShown) {
            const el = document.activeElement || $("input:focus,textarea:focus")[0];

            if (el) {
                if (el != this.currentActiveElement) {
                    this._hideAdditionalPanel();
                    this.currentActiveElement = el;
                    this._showAdditionalPanel(el.dataset.jsKeyboardPanel);
                }
            } else {
                this._hideAdditionalPanel();
            }
        } else {
            this._hideAdditionalPanel();
        }
    }

    _showAdditionalPanel(panelId) {
        this._bindedCurrentActiveElementHandler = () => _.defer(this._updateAdditionalPanel.bind(this));
        this.currentActiveElement.addEventListener("blur", this._bindedCurrentActiveElementHandler);

        const Panel = panelsMap[panelId];

        if (Panel) {
            const panel = new Panel({ inputEl: this.currentActiveElement });
            app.keyboardAdditionalPanel.show(panel);
            app.keyboardAdditionalPanel.el.style.bottom = `${this.keyboardHeight}px`;
        } else if (app.keyboardAdditionalPanel.hasView()) {
            app.keyboardAdditionalPanel.reset();
        }
    }

    _hideAdditionalPanel() {
        if (this.currentActiveElement) {
            this.currentActiveElement.removeEventListener("blur", this._bindedCurrentActiveElementHandler);
            delete this._bindedCurrentActiveElementHandler;
            this.currentActiveElement = null;
        }

        if (app.keyboardAdditionalPanel.hasView()) {
            app.keyboardAdditionalPanel.reset();
        }
    }
};

export default KeyboardService;
