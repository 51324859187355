import { Marionette } from '@b2cmessenger/backbone';
import template from './BrandsChild.jade';

import getNextPrettyColor from 'utils/randomPrettyColor';
import GiftsList from './GiftsList';

export default Marionette.ItemView.extend({
    className: 'brand-child',
    template: template,
    events: {
        'click': 'onClick'
    },
    ui: {
        avatar: '[data-js-avatar]'
    },
    onRender() {
        let avatarUrl = this.model.get('logoThumb');

        if(avatarUrl){
            this.ui.avatar.css('background-image', `url('${avatarUrl}')`);
        } else {
            this.ui.avatar.addClass('empty');
        }

        this.el.style['border-color'] = getNextPrettyColor(this.model.get('name'));
    },
    onClick() {
        new GiftsList({
            userId: LoginedUserHandler.loginedUser.id,
            brandId: this.model.get('id')
        }).show();
    }
});
