import { Required, Optional, ViewModel, Collection, CollectionView } from '@b2cmessenger/backbone';
import InfoModal from 'windows/Modal/Info';

import MessageModel from 'models/MessageModel';
import LoadMoreModel from './LoadMoreModel';
import CommentEditorModel from './CommentEditorModel';
import UserModel from 'models/UserModel';
import LoginedUserModel from 'models/LoginedUserModel';
import MessageView from 'widgets/Message/Message';
import MessageWithCommentsView from '../MessageWithComments'
import LoadMoreView from './LoadMore/LoadMore';
import CommentEditorView from './CommentEditor/CommentEditor';
import settings from 'settings';
import AjaxError from 'utils/AjaxError';

import './MessageComments.scss';

/** @typedef {import('./MessageComments')} MessageCommentsViewInterface */
/** @type {typeof import('./MessageComments').properties} */
// @ts-ignore
const properties = CollectionView.properties;

/** @type {typeof import('./MessageComments').options} */
// @ts-ignore
const options = CollectionView.options;

/** @type {typeof import('./MessageComments').events} */
// @ts-ignore
const events = CollectionView.events;

@events({
    /** Comments loading start */
    'load:start': () => { },

    /** Comments loading finished successfully */
    'load:finish': () => { },

    /** Comments loading failed
     * @param {Error | string} error */
    'load:fail': (error) => { },

    /** User clicked on user
     * @param {MessageView | MessageWithCommentsView} childView
     * @param {UserModel} userModel - model of user
     * @param {boolean} [isAnonym] - is user is anonym
     * @param {boolean} [isFromBussines] - is user from this place */
    'user:click': (childView, userModel, isAnonym, isFromBussines) => { },
})
@options({
    model: Required,
    loginedUserModel: Required,
    placeModel: Required,
    reservationModel: Optional,
    parentViewModel: Optional,
    initCommentsLimit: Optional || 5,
    initCommentsFirstCommentsLimit: Optional || 1,
    loadMoreCommentsLimit: Optional || 5
})
@properties({
    className: 'widget message-comments-widget',

    childViewOptions(child, index) {
        if (child instanceof LoadMoreModel) {
            return {
                parentViewModel: this.viewModel
            };
        } else if (child instanceof CommentEditorModel) {
            return {
                placeModel: this.placeModel,
                reservationModel: this.reservationModel,
                parentViewModel: this.viewModel
            };
        } else if (child instanceof MessageModel) {
            return {
                userModel: this.userCollection.get(child.get('user_id')),
                placeModel: this.placeModel,
                reservationModel: this.reservationModel,
                loginedUserModel: this.loginedUserModel,
                parentViewModel: this.viewModel
            }
        }
    },

    getChildView(child) {
        if (child instanceof LoadMoreModel) {
            return LoadMoreView;
        } else if (child instanceof CommentEditorModel) {
            return CommentEditorView;
        } else if (child instanceof MessageModel) {
            if (child.get('depth') == 1) {
                return MessageWithCommentsView;
            } else {
                return MessageView;
            }
        }
    },

    childEvents: {
        'click:newest'(loadMoreView) {
            this._onLoadMore(loadMoreView, false);
        },

        'click:oldest'(loadMoreView) {
            this._onLoadMore(loadMoreView, true);
        },

        'click:more'(loadMoreView) {
            this._onLoadMore(loadMoreView, false);
        },

        /**@param {MessageView|MessageWithCommentsView} cv */
        'menu:entry:finish'(cv, entryName, actionResult) {
            if (entryName == 'edit') {
                this._onCommentEdit(cv);
            }
        },

        /**@param {MessageView|MessageWithCommentsView} cv */
        'user:click'(cv, userModel, isAnonym, isFromBussines) {
            this.trigger('user:click', cv, userModel, isAnonym, isFromBussines);
        },

        'comment:edit'(cv, commentModel) {
            if (commentModel) {
                const commentView = this.children.findByModel(commentModel);
                if (commentView) {
                    commentView.hide();
                }
            }
        },

        'comment:cancel'(cv, commentModel) {
            delete this._commentEditorModelsByModelId[commentModel.id];
            this.collection.remove(cv.model);

            if (commentModel) {
                const commentView = this.children.findByModel(commentModel);
                if (commentView) {
                    commentView.show();
                }
            }
        },

        'comment:update'(cv, commentModel) {
            delete this._commentEditorModelsByModelId[commentModel.id];
            this.collection.remove(cv.model);

            const commentView = this.children.findByModel(commentModel);
            if (commentView) {
                commentView.show();
            }
        },

        'comment:create'(cv, commentModel) {
            delete this._commentEditorModelsByModelId[0];
            this.collection.remove(cv.model);

            if (!commentModel.get('_publishedAtValue')) {
                const publishedAt = B2Cjs.datetimeServerToJS(commentModel.get('published_at')) || new Date(0);
                publishedAt.setSeconds(publishedAt.getSeconds(), commentModel.id % 1000);
                commentModel.set({ _publishedAtValue: publishedAt.getTime() });
            }
            this.collection.add(commentModel);

            this.loadNew();
        },

        /**@param {MessageView} cv */
        'reply:click'(cv) {
            const messageModel = cv.model;

            const newCommentEditorModel = this._commentEditorModelsByModelId[0];
            if (newCommentEditorModel) {
                /**@type {CommentEditorView|null} */
                //@ts-ignore
                const newCommentEditor = this.children.findByModel(newCommentEditorModel);
                if (newCommentEditor) {
                    if (newCommentEditor.isEmpty() && messageModel) {
                        const authorName = messageModel.get('isAuthorAnonym') ? 'Anonym' :
                            cv.userModel.get('name');

                        const text = String(messageModel.get('text') || '').trim();
                        let excerpt = '';
                        if (text) {
                            const MAX_LENGTH = 256;
                            const MAX_LINES = 7;

                            let prevLineIsEmpty = true;
                            let isInQuote = false;
                            const lines = _.reduce(text.trim().split('\n'), (lines, l) => {
                                const line = l.trim();
                                if (!line) {
                                    if (!prevLineIsEmpty) {
                                        prevLineIsEmpty = true;
                                        lines.push(line);
                                    }
                                } else if (/^>\s*/i.test(line) || /^\s*(\*|_|-){3}\s*$/i.test(line)) {
                                    if (!isInQuote) {
                                        if (lines.length) {
                                            isInQuote = true;
                                            prevLineIsEmpty = false;
                                            lines.push('...');
                                        }
                                    }
                                } else {
                                    isInQuote = false;
                                    prevLineIsEmpty = false;
                                    lines.push(line);
                                }
                                return lines;
                            }, []);

                            if (lines.length) {
                                const firstLine = lines[0];

                                if (firstLine.length > MAX_LENGTH - 5) {
                                    excerpt = '> ' +
                                        firstLine.substring(0, MAX_LENGTH - 1)
                                            .replace(/[\s,.!?:\-;()]*[^\s,.!?:\-;()]+$/i, '...');
                                } else {
                                    excerpt += '> ' + firstLine;

                                    if (lines.length > 1) {
                                        let restLength = MAX_LENGTH - firstLine.length;
                                        const lastLine = lines[lines.length - 1];
                                        if (lastLine.length >= restLength - 5) {
                                            if (lines.length > 2) {
                                                for (let i = 1; i < lines.length - 1; ++i) {
                                                    if (lines[i].trim()) {
                                                        excerpt += '\n> ...\n';
                                                        break;
                                                    }
                                                }
                                            }
                                            excerpt += '\n> ' +
                                                lastLine.substring(0, restLength - 1)
                                                    .replace(/[\s,.!?:\-;()]*[^\s,.!?:\-;()]+$/i, '...');
                                        } else {
                                            restLength -= lastLine.length;

                                            for (let i = 1; i < lines.length - 1; i++) {
                                                const line = lines[i];

                                                if (line.length >= restLength - 5) {
                                                    excerpt += '\n> ' +
                                                        line.substring(0, restLength - 1)
                                                            .replace(/[\s,.!?:\-;()]*[^\s,.!?:\-;()]+$/i, '...');

                                                    if (lines.length > 2 + i) {
                                                        for (let j = i + 1; j < lines.length - 1; ++j) {
                                                            if (lines[j].trim()) {
                                                                excerpt += '\n> ...\n';
                                                                break;
                                                            }
                                                        }
                                                    }
                                                    break;
                                                } else {
                                                    excerpt += '\n> ' + line;
                                                    restLength -= line.length;

                                                    if (i >= MAX_LINES - 2) {
                                                        for (let j = i + 1; j < lines.length - 1; ++j) {
                                                            if (lines[j].trim()) {
                                                                excerpt += '\n> ...\n';
                                                                break;
                                                            }
                                                        }
                                                        break;
                                                    }
                                                }
                                            }

                                            excerpt += '\n> ' + lastLine;
                                            excerpt = excerpt.replace(/((>\s*\n)*(>\s*\.\.\.\n)+(>\s*\n)*)+/, '> \n> ...\n> \n');
                                        }
                                    }
                                }
                            }
                        }

                        if (excerpt) {
                            newCommentEditor.setText(`${excerpt}\n\n**${authorName}**, `);
                        } else {
                            newCommentEditor.setText(`**${authorName}**, `);
                        }

                        newCommentEditor.focusText();
                    }
                    newCommentEditor.el.scrollIntoCenter();
                }
            }
        }
    },

    reorderOnSort: true
})
@InfoModal.showsMessages
class MessageCommentsView extends CollectionView {
    constructor(options) {
        if (!Math.max(2, Number(options.initCommentsLimit))) {
            delete options.initCommentsLimit;
        }

        if (!Math.max(2, Number(options.loadMoreCommentsLimit))) {
            delete options.loadMoreCommentsLimit;
        }

        super(options)
    }

    initialize() {
        /**@type {import('./MessageComments')} */
        // @ts-ignore
        const self = this;

        this.model = self.options.model;

        this.collection = new Collection([], {
            comparator(l, r) {
                const diff = l.get('_publishedAtValue') - r.get('_publishedAtValue');
                if (!diff) {
                    if (l instanceof CommentEditorModel) {
                        return 1;
                    } else if (r instanceof CommentEditorModel) {
                        return -1;
                    }
                }
                return diff;
            }
        });

        this.viewModel = new ViewModel({
            parentViewModel: self.options.parentViewModel
        });

        this.userCollection = new Collection([], { model: UserModel });
        this.loginedUserModel = self.options.loginedUserModel;
        this.placeModel = self.options.placeModel;
        this.reservationModel = self.options.reservationModel;

        this.listenTo(this.loginedUserModel, 'change:id', (m, id) => {
            if (id) {
                this.userCollection.add(m.clone({ asUserModel: true }));
            }
            this._addOrRemoveNewCommentEditorIfNeeded();
        });

        this._isRefreshed = false;
        this._loadMoreModelsByCid = {};
        this._commentEditorModelsByModelId = {};
        this._newCommentToLoadPromisesById = {};
        this._loadNewCommentsByIdsDebounced = _.debounce(() => {
            Promise.resolve()
                .then(() => {
                    if (!this._isRefreshed) {
                        return this.refresh();
                    }
                })
                .then(() => _.keys(this._newCommentToLoadPromisesById))
                .then(ids => ids.length && this._loadMore(false, null, null, ids.length, ids))
                .then(() => {
                    _.each(_.keys(this._newCommentToLoadPromisesById), id => {
                        this._newCommentToLoadPromisesById[id].resolve(this.collection.get(id));
                        delete this._newCommentToLoadPromisesById[id];
                    });
                })
                .catch(e => {
                    _.each(_.keys(this._newCommentToLoadPromisesById), k => {
                        this._newCommentToLoadPromisesById[k].reject(e);
                        delete this._newCommentToLoadPromisesById[k];
                    });
                })
        }, 500);

        this._modelFetchPromise = null;

        this.listenTo(this.model, 'change:hasSolution', () => {
            this._addOrRemoveNewCommentEditorIfNeeded();
        });

        this.listenTo(this.collection, 'add', (model, c) => {
            if (model instanceof MessageModel && model.get('isSolution')) {
                if (!this.model.get('hasSolution')) {
                    this._enqueueModelFetch();
                } else {
                    const otherSolution = c.find(m => m instanceof MessageModel && m != model && m.get('isSolution'));
                    if (otherSolution) {
                        this.loadNewComment(otherSolution.id);
                    }
                }
            } else if (model instanceof MessageModel && model.get('isWithGift')) {
                this.model.set('hasgift', 1);
            }
        });

        this.listenTo(this.collection, 'reset', (c) => {
            if (!this.model.get('hasSolution')) {
                const solution = c.find(m => m instanceof MessageModel && m.get('isSolution'));
                if (solution) {
                    this._enqueueModelFetch();
                }
            }
        });

        this.listenTo(this.collection, 'remove', (model) => {
            if (model instanceof MessageModel && model.get('isSolution')) {
                this._enqueueModelFetch();
            }
        });

        this.listenTo(this.collection, 'destroy', (m) => {
            this._enqueueModelFetch();
        });

        this.listenTo(this.collection, 'change:isSolution', _.debounce((model, isSolution) => {
            if (model instanceof MessageModel) {
                if (isSolution) {
                    if (!this.model.get('hasSolution')) {
                        this._enqueueModelFetch();
                    } else {
                        const otherSolution = this.collection.find(m =>
                            m instanceof MessageModel && m != model && m.get('isSolution'));

                        if (otherSolution) {
                            this.loadNewComment(otherSolution.id);
                        }
                    }
                } else {
                    if (this.model.get('hasSolution')) {
                        const otherSolution = this.collection.find(m =>
                            m instanceof MessageModel && m != model && m.get('isSolution'));

                        if (!otherSolution) {
                            this._enqueueModelFetch();
                        }
                    }
                }
            }
        }, 100));
    }

    onDestroy(view) {
        this.stopListening(this.loginedUserModel);
    }

    refresh() {
        /**@type {import('./MessageComments')} */
        // @ts-ignore
        const self = this;

        return new Promise((resolve, reject) => {
            this.trigger('load:start');
            const isTask = this.model.get('isTask');
            const limit = this._getExtendedLimit(self.options.initCommentsLimit, this.model.get('commentscount'));

            Server.callServer({
                url: settings.host + settings.serv_task.comment.search,
                type: "POST",
                data: {
                    place_id: this.model.get('place_id'),
                    task_id: isTask ? this.model.id : this.model.get('task_id'),
                    parent_id: isTask ? 0 : this.model.id,
                    add_first: Math.max(0, Number(self.options.initCommentsFirstCommentsLimit) || 1),
                    all_gift_comm: isTask ? 1 : 0,
                    add_task_sol: isTask ? 1 : 0,
                    isuserverified: this.model.get('includeUnverified') ? 1 : 0,
                    limit: limit,
                    oldest: 0,
                    needuserinfo: 1
                },
                success: data => {
                    self.userCollection.set(data.users);
                    if (this.loginedUserModel.get('isLoggedIn')) {
                        this.userCollection.add(this.loginedUserModel.clone({ asUserModel: true }));
                    }

                    this._loadMoreModelsByCid = {};
                    this._commentEditorModelsByModelId = {};

                    this.model.set({ commentscount: Number(data.commentscount) || 0 });

                    if (data.comments.length) {
                        const result = [];
                        const sortedComments = _.sortBy(data.comments,
                            c => {
                                const publishedAt = B2Cjs.datetimeServerToJS(c.published_at) || new Date(0);
                                publishedAt.setSeconds(publishedAt.getSeconds(), c.id % 1000);
                                c._publishedAtValue = publishedAt.getTime();

                                return c._publishedAtValue;
                            });

                        let firstCommentIndex = _.indexOf(data.commentsMap, sortedComments[0].id);
                        if (firstCommentIndex > 0) {
                            const loadMoreModel = new LoadMoreModel({
                                _publishedAtValue: sortedComments[0]._publishedAtValue - 1,
                                count: firstCommentIndex,
                                double: this._doesNeedDoubleLoadMore(firstCommentIndex),
                                ids: data.commentsMap.slice(0, firstCommentIndex),
                                publishedAfter: null,
                                publishedBefore: sortedComments[0].published_at
                            });
                            this._loadMoreModelsByCid[loadMoreModel.cid] = loadMoreModel;
                            result.push(loadMoreModel);
                        } else if (firstCommentIndex < 0) {
                            const loadedMapIndex = _.findIndex(sortedComments,
                                c => {
                                    firstCommentIndex = _.indexOf(data.commentsMap, c.id);
                                    return firstCommentIndex > -1;
                                });

                            if (loadedMapIndex != -1) {
                                sortedComments.splice(0, loadedMapIndex);
                            }
                        }

                        if (firstCommentIndex > -1) {
                            let loadMoreIdsCount = 0;
                            let lastAddedComment = null;
                            for (let i = firstCommentIndex; i < data.commentsMap.length; ++i) {
                                const id = data.commentsMap[i];
                                const comment = sortedComments[0];

                                if (!comment) {
                                    const count = data.commentsMap.length - i + 1;
                                    const loadMoreModel = new LoadMoreModel({
                                        _publishedAtValue: _.last(sortedComments)._publishedAtValue + 1,
                                        count,
                                        double: this._doesNeedDoubleLoadMore(count),
                                        ids: data.commentsMap.slice(i),
                                        publishedAfter: _.last(sortedComments).published_at,
                                        publishedBefore: null
                                    });
                                    this._loadMoreModelsByCid[loadMoreModel.cid] = loadMoreModel;
                                    result.push(loadMoreModel);

                                    loadMoreIdsCount = 0;

                                    break;
                                }

                                if (id == comment.id) {
                                    if (loadMoreIdsCount > 0) {
                                        const loadMoreModel = new LoadMoreModel({
                                            _publishedAtValue: comment._publishedAtValue - 1,
                                            count: loadMoreIdsCount,
                                            double: this._doesNeedDoubleLoadMore(loadMoreIdsCount),
                                            ids: data.commentsMap.slice(i - loadMoreIdsCount, i),
                                            publishedAfter: lastAddedComment ? lastAddedComment.published_at : null,
                                            publishedBefore: comment.published_at
                                        });
                                        this._loadMoreModelsByCid[loadMoreModel.cid] = loadMoreModel;
                                        result.push(loadMoreModel);

                                        loadMoreIdsCount = 0;
                                    }
                                    result.push(new MessageModel(_.extend(comment, {
                                        parentMessageModel: this.model
                                    })));
                                    sortedComments.splice(0, 1);
                                    lastAddedComment = comment;
                                } else {
                                    loadMoreIdsCount++;
                                }
                            }

                            if (loadMoreIdsCount > 0) {
                                if (loadMoreIdsCount > 0) {
                                    const loadMoreModel = new LoadMoreModel({
                                        _publishedAtValue: lastAddedComment ? lastAddedComment._publishedAtValue + 1 : 0,
                                        count: loadMoreIdsCount,
                                        double: this._doesNeedDoubleLoadMore(loadMoreIdsCount),
                                        ids: data.commentsMap.slice(-loadMoreIdsCount),
                                        publishedAfter: lastAddedComment ? lastAddedComment.published_at : null,
                                        publishedBefore: null
                                    });
                                    this._loadMoreModelsByCid[loadMoreModel.cid] = loadMoreModel;
                                    result.push(loadMoreModel);
                                }
                            }
                        }

                        this.collection.reset(result);
                    }

                    this._addOrRemoveNewCommentEditorIfNeeded();

                    this._isRefreshed = true;
                    resolve();
                    this.trigger('load:finish');
                },
                error(jqXHR, textStatus, errorThrown) {
                    const error = new AjaxError(jqXHR, textStatus, errorThrown);
                    reject(error);
                    this.trigger('load:fail', error);
                }
            });
        });

    }

    loadNew() {
        /**@type {import('./MessageComments')} */
        // @ts-ignore
        const self = this;
        return Promise.resolve()
            .then(() => {
                if (!this._isRefreshed) {
                    return this.refresh();
                }
            })
            .then(() => this._loadMore(false, null, null, self.options.loadMoreCommentsLimit));
    }

    loadNewComment(commentId, isSolutionHint = false) {
        if (!this._newCommentToLoadPromisesById[commentId] && isSolutionHint) {
            const otherSolution = this.collection.find(m =>
                m instanceof MessageModel && m.id != commentId && m.get('isSolution'));

            if (otherSolution) {
                this._enqueueCommentToLoad(otherSolution.id);
            }
        }

        const existingCommentModel = this.collection.get(commentId);
        if (existingCommentModel) {
            if (existingCommentModel.get('isSolution') != isSolutionHint) {
                this._enqueueCommentToLoad(existingCommentModel.id);
            }

            return Promise.resolve(existingCommentModel);
        }

        return this._enqueueCommentToLoad(commentId);
    }

    loadNewSubComment(commentId, subCommentId) {
        /**@type {import('./MessageComments')} */
        // @ts-ignore
        const self = this;

        const existingCommentModel = this.collection.get(commentId);
        if (existingCommentModel) {
            const existingMessageView = self.children.findByModel(existingCommentModel);
            if (existingMessageView && existingMessageView instanceof MessageWithCommentsView) {
                return existingMessageView.loadNewComment(subCommentId);
            } else {
                return Promise.resolve(null);
            }
        } else {
            return Promise.resolve(null);
        }
    }

    updateComment(commentId) {
        const existingCommentModel = this.collection.get(commentId);
        if (existingCommentModel) {
            return this._enqueueCommentToLoad(commentId);
        }
    }

    openCommentEditorWithGiftSelect() {
        /**@type {import('./MessageComments')} */
        // @ts-ignore
        const self = this;
        return Promise.resolve()
            .then(() => {
                if (!this._isRefreshed) {
                    return this.refresh();
                }
            })
            .then(() => self._addOrRemoveNewCommentEditorIfNeeded(true))
            .then(editorView => {
                if (editorView) {
                    return editorView.selectGift()
                        .then(() => editorView.el.scrollIntoCenter());
                }
            });
    }

    openCommentEditor() {
        /**@type {import('./MessageComments')} */
        // @ts-ignore
        const self = this;
        return Promise.resolve()
            .then(() => {
                if (!this._isRefreshed) {
                    return this.refresh();
                }
            })
            .then(() => self._addOrRemoveNewCommentEditorIfNeeded())
            .then(editorView => {
                if (editorView) {
                    editorView.el.scrollIntoCenter();
                }

                return editorView;
            });
    }

    scrollToComment(commentId, subId) {
        if (commentId) {
            const existingCommentModel = this.collection.get(commentId);
            if (existingCommentModel) {
                const v = this.children.findByModel(existingCommentModel);
                if (v) {
                    if (subId && v instanceof MessageWithCommentsView) {
                        return v.scrollToComment(subId);
                    } else {
                        v.el.scrollIntoCenter();
                        return Promise.resolve();
                    }
                } else {
                    return Promise.reject(new Error('no child'));
                }
            } else {
                return Promise.resolve()
                    .then(() => {
                        if (!this._isRefreshed) {
                            return this.refresh();
                        }
                    })
                    .then(() => this._loadMore(false, null, null, 1, [commentId]))
                    .then(() => {
                        const existingCommentModel = this.collection.get(commentId);
                        if (existingCommentModel) {
                            const v = this.children.findByModel(existingCommentModel);
                            if (v) {
                                if (subId && v instanceof MessageWithCommentsView) {
                                    return v.scrollToComment(subId);
                                } else {
                                    v.el.scrollIntoCenter();
                                }
                            } else {
                                throw new Error('no child');
                            }
                        }
                    });
            }
        } else {
            return Promise.resolve();
        }
    }

    _loadMore(oldest, publishedafter, publishedbefore, limit, ids) {
        /**@type {import('./MessageComments')} */
        // @ts-ignore
        const self = this;
        return new Promise((resolve, reject) => {
            const isTask = this.model.get('isTask');

            Server.callServer({
                url: settings.host + settings.serv_task.comment.search,
                type: "POST",
                data: _.reduce({
                    place_id: this.model.get('place_id'),
                    task_id: isTask ? this.model.id : this.model.get('task_id'),
                    parent_id: isTask ? 0 : this.model.id,
                    all_gift_comm: 0,
                    add_task_sol: 0,
                    isuserverified: this.model.get('includeUnverified') ? 1 : 0,
                    limit: limit,
                    oldest: oldest ? 1 : 0,
                    needuserinfo: 1,
                    publishedafter: publishedafter || null,
                    publishedbefore: publishedbefore || null,
                    ids: ids && ids.length && ids || null
                }, (data, v, k) => {
                    if (!_.isNull(v)) {
                        data[k] = v;
                    }
                    return data;
                }, {}),
                success: data => {
                    self.userCollection.add(data.users, { merge: true });

                    const commentsCount = Number(data.commentscount) || 0;
                    this.model.set({ commentsCount });

                    this.collection.remove(_.keys(this._loadMoreModelsByCid));
                    let mapIndexCache = 0;
                    let idsToDelete = [];
                    this.collection.each(m => {
                        if (m.id) {
                            const i = data.commentsMap.indexOf(m.id, mapIndexCache);
                            if (i < 0) {
                                idsToDelete.push(m.id);
                            } else {
                                mapIndexCache = i + 1;
                            }
                        }
                    });
                    if (idsToDelete.length) {
                        _.each(idsToDelete, id => {
                            const commentEditorModel = this._commentEditorModelsByModelId[id];
                            if (commentEditorModel) {
                                this.collection.remove(commentEditorModel);
                                delete this._commentEditorModelsByModelId[id]
                            }
                        });

                        this.collection.remove(idsToDelete);
                    }

                    const result = [];
                    const sortedComments = _.sortBy(data.comments,
                        c => {
                            const publishedAt = B2Cjs.datetimeServerToJS(c.published_at) || new Date(0);
                            publishedAt.setSeconds(publishedAt.getSeconds(), c.id % 1000);
                            c._publishedAtValue = publishedAt.getTime();

                            return c._publishedAtValue;
                        });

                    this.collection.add(_.map(sortedComments, c => new MessageModel(_.extend(c, {
                        parentMessageModel: this.model
                    }))), { merge: true });

                    if (this.reservationModel) {
                        const reservationCommentPredicate = c =>
                            !!c.additional_data && c.additional_data.find(i => i.type === 'reservation_change');

                        _(sortedComments).chain()
                            .filter(reservationCommentPredicate)
                            .last()
                            .tap(c => {
                                if (c && this.reservationModel.get('last_change_comment_id') < c.id) {
                                    const reservationChange = reservationCommentPredicate(c).data;
                                    const previousValues = reservationChange.previous_values;
                                    const payload = _.extend(
                                        _.create(null),
                                        previousValues,
                                        _.pick(reservationChange, ..._.keys(previousValues)),
                                        {last_change_comment_id: c.id}
                                    );

                                    this.reservationModel.set(payload);
                                }
                            })
                            .value();
                    }

                    let colIndex = this.collection.findIndex(m => m.id);
                    let firstCommentIndex = this.collection.length ?
                        _.indexOf(data.commentsMap, this.collection.at(colIndex).id) : 0;

                    if (firstCommentIndex < 0) {
                        const loadedMapIndex = this.collection.findIndex(
                            m => {
                                if (m.id) {
                                    firstCommentIndex = _.indexOf(data.commentsMap, m.id);
                                    return firstCommentIndex > -1;
                                }
                            });

                        if (loadedMapIndex != -1) {
                            for (let i = 0; i < loadedMapIndex; ++i) {
                                this.collection.shift();
                            }
                        }
                    }
                    if (firstCommentIndex > 0) {
                        const firstExistingComment = this.collection.at(colIndex);
                        const firstLoadMoreModel = new LoadMoreModel({
                            _publishedAtValue: firstExistingComment ?
                                firstExistingComment.get('_publishedAtValue') - 1 : 0,
                            count: firstCommentIndex,
                            double: this._doesNeedDoubleLoadMore(firstCommentIndex),
                            ids: data.commentsMap.slice(0, firstCommentIndex),
                            publishedAfter: null,
                            publishedBefore: firstExistingComment ?
                                firstExistingComment.get('published_at') : null
                        });
                        this._loadMoreModelsByCid[firstLoadMoreModel.cid] = firstLoadMoreModel;
                        result.push(firstLoadMoreModel);
                    }

                    if (firstCommentIndex > -1) {
                        let loadMoreIdsCount = 0;
                        let lastAddedCommentModel = null;
                        for (let i = firstCommentIndex; i < data.commentsMap.length; ++i) {
                            const id = data.commentsMap[i];
                            let model = this.collection.at(colIndex);

                            while (colIndex < this.collection.length && !model.id) {
                                colIndex++;
                                model = this.collection.at(colIndex);
                            }

                            if (model && model.id == id) {
                                if (loadMoreIdsCount) {
                                    const loadMoreModel = new LoadMoreModel({
                                        _publishedAtValue: model.get('_publishedAtValue') - 1,
                                        count: loadMoreIdsCount,
                                        double: this._doesNeedDoubleLoadMore(loadMoreIdsCount),
                                        ids: data.commentsMap.slice(i - loadMoreIdsCount, i),
                                        publishedAfter: lastAddedCommentModel ?
                                            lastAddedCommentModel.get('published_at') : null,
                                        publishedBefore: model.get('published_at')
                                    });
                                    this._loadMoreModelsByCid[loadMoreModel.cid] = loadMoreModel;
                                    result.push(loadMoreModel);

                                    loadMoreIdsCount = 0;
                                }
                                colIndex++;
                                lastAddedCommentModel = model;
                            } else {
                                loadMoreIdsCount++;
                            }

                            if (colIndex >= this.collection.length &&
                                i + 1 - loadMoreIdsCount < data.commentsMap.length
                            ) {
                                const count = data.commentsMap.length - i - 1 + loadMoreIdsCount;
                                const loadMoreModel = new LoadMoreModel({
                                    _publishedAtValue: lastAddedCommentModel ?
                                        lastAddedCommentModel.get('_publishedAtValue') + 1 : 0,
                                    count,
                                    double: this._doesNeedDoubleLoadMore(count),
                                    ids: data.commentsMap.slice(i),
                                    publishedAfter: lastAddedCommentModel ?
                                        lastAddedCommentModel.get('published_at') : null,
                                    publishedBefore: null
                                });
                                this._loadMoreModelsByCid[loadMoreModel.cid] = loadMoreModel;
                                result.push(loadMoreModel);

                                loadMoreIdsCount = 0;
                                break;
                            }
                        }

                        if (loadMoreIdsCount > 0) {
                            if (loadMoreIdsCount > 0) {
                                const loadMoreModel = new LoadMoreModel({
                                    _publishedAtValue: lastAddedCommentModel ?
                                        lastAddedCommentModel.get('_publishedAtValue') + 1 : 0,
                                    count: loadMoreIdsCount,
                                    double: this._doesNeedDoubleLoadMore(loadMoreIdsCount),
                                    ids: data.commentsMap.slice(-loadMoreIdsCount),
                                    publishedAfter: lastAddedCommentModel ?
                                        lastAddedCommentModel.get('published_at') : null,
                                    publishedBefore: null
                                });
                                this._loadMoreModelsByCid[loadMoreModel.cid] = loadMoreModel;
                                result.push(loadMoreModel);
                            }
                        }
                    }

                    this.collection.add(result);

                    this._addOrRemoveNewCommentEditorIfNeeded();

                    resolve();
                },
                error(jqXHR, textStatus, errorThrown) {
                    reject(new AjaxError(jqXHR, textStatus, errorThrown));
                }
            });
        });
    }

    onBeforeDestroy(view) {
        this.viewModel.stopListening();
    }

    _onCommentEdit(childView) {
        if (this.loginedUserModel.get('isLoggedIn')) {
            const model = childView.model;
            if (!this._commentEditorModelsByModelId[model.id]) {
                if (model.get('user_id') == this.loginedUserModel.id
                    ||
                    model.get('isFromBussines') &&
                    this.loginedUserModel.hasRoleInPlace(
                        model.get('place_id'), LoginedUserModel.Roles.MESSAGE_MODERATOR)
                ) {
                    const commentEditorModel = new CommentEditorModel({
                        messageModel: model,
                        userModel: this.userCollection.get(model.get('user_id')),
                        reservationModel: this.reservationModel,
                        loginedUserModel: this.loginedUserModel
                    });

                    this._commentEditorModelsByModelId[model.id] = commentEditorModel
                    this.collection.add(commentEditorModel);
                }
            }
        }
    }

    _onLoadMore(loadMoreView, oldest) {
        /**@type {import('./MessageComments')} */
        // @ts-ignore
        const self = this;
        const loadMoreModel = loadMoreView.model;
        const limit = this._getExtendedLimit(self.options.loadMoreCommentsLimit, loadMoreModel.get('count'));

        return this._loadMore(
            oldest,
            loadMoreModel.get('publishedAfter') || null,
            loadMoreModel.get('publishedBefore') || null,
            limit
        );
    }

    _getExtendedLimit(limit, count) {
        if (limit >= count) {
            return limit;
        } else if (limit * 1.5 >= count) {
            return Math.round(limit * 1.5);
        } else {
            return limit;
        }
    }

    _doesNeedDoubleLoadMore(count) {
        /**@type {import('./MessageComments')} */
        // @ts-ignore
        const self = this;
        return this._getExtendedLimit(self.options.loadMoreCommentsLimit, count) < count;
    }

    _addOrRemoveNewCommentEditorIfNeeded(skipHasSlutionCheck = false) {
        /**@type {import('./MessageComments')} */
        // @ts-ignore
        const self = this;
        if (this.loginedUserModel.get('isLoggedIn') &&
            (
                this.model.get('isComment')
                || this.model.get('isTask') && !this.model.get('isBroadcast') &&
                (skipHasSlutionCheck || !this.model.get('hasSolution'))
            )
        ) {
            if (!this._commentEditorModelsByModelId[0]) {
                const commentEditorModel = new CommentEditorModel({
                    messageModel: null,
                    parentMessageModel: this.model,
                    userModel: this.loginedUserModel,
                    reservationModel: this.reservationModel,
                    loginedUserModel: this.loginedUserModel
                });

                this._commentEditorModelsByModelId[0] = commentEditorModel
                this.collection.add(commentEditorModel);

                return this.children.findByModel(commentEditorModel);
            } else {
                return this.children.findByModel(this._commentEditorModelsByModelId[0]);
            }
        } else {
            const existingCommentEditorModel = this._commentEditorModelsByModelId[0];
            if (existingCommentEditorModel) {
                /**@type {CommentEditorView} */
                //@ts-ignore
                const existingCommentEditor = self.children.findByModel(existingCommentEditorModel);
                if (existingCommentEditor && existingCommentEditor.isEmpty()) {
                    this.collection.remove(this._commentEditorModelsByModelId[0]);
                    delete this._commentEditorModelsByModelId[0];
                }
            }

            return null;
        }
    }

    _enqueueCommentToLoad(commentId) {
        if (!this._newCommentToLoadPromisesById[commentId]) {
            const deferred = {};
            const promise = new Promise((resolve, reject) => {
                deferred.resolve = resolve;
                deferred.reject = reject;
            });
            deferred.promise = promise;

            this._newCommentToLoadPromisesById[commentId] = deferred;
            this._loadNewCommentsByIdsDebounced();

            return promise;
        } else {
            return this._newCommentToLoadPromisesById[commentId].promise;
        }
    }

    _enqueueModelFetch() {
        if (!this._modelFetchPromise) {
            return this._modelFetchPromise = new Promise((resolve, reject) => {
                this.model.fetch({
                    success: () => {
                        resolve;
                        this._modelFetchPromise = null;
                    },
                    error: (m, resp) => {
                        if (resp instanceof Error) {
                            reject(resp);
                        } else {
                            reject(new AjaxError(resp));
                        }
                        this._modelFetchPromise = null;
                    }
                })
            });
        } else {
            return this._modelFetchPromise;
        }
    }
}

export default MessageCommentsView;
