var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (notificationsCount, onlyInternal, text) {if ((onlyInternal)) {
pug_html = pug_html + "\u003Ci class=\"ion-ios-notifications-outline\"\u003E\u003C\u002Fi\u003E\u003Cp class=\"text\"\u003E" + (pug.escape(null == (pug_interp = text) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
if ((notificationsCount)) {
pug_html = pug_html + "\u003Cspan" + (pug.attr("class", pug.classes([(notificationsCount > 9 ? "small" : ""),"counter"], [true,false]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = notificationsCount > 99 ? 99 : notificationsCount) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
}
else {
pug_html = pug_html + "\u003Cbutton" + (pug.attr("class", pug.classes([text ? 'text' : '',"btn-notifications"], [true,false]), false, true)+" data-role=\"none\"") + "\u003E\u003Ci class=\"ion-ios-notifications-outline\"\u003E\u003C\u002Fi\u003E\u003Cp class=\"text\"\u003E" + (pug.escape(null == (pug_interp = text) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
if ((notificationsCount)) {
pug_html = pug_html + "\u003Cspan" + (pug.attr("class", pug.classes([(notificationsCount > 9 ? "small" : ""),"counter"], [true,false]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = notificationsCount > 99 ? 99 : notificationsCount) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fbutton\u003E";
}}.call(this,"notificationsCount" in locals_for_with?locals_for_with.notificationsCount:typeof notificationsCount!=="undefined"?notificationsCount:undefined,"onlyInternal" in locals_for_with?locals_for_with.onlyInternal:typeof onlyInternal!=="undefined"?onlyInternal:undefined,"text" in locals_for_with?locals_for_with.text:typeof text!=="undefined"?text:undefined));;return pug_html;};
module.exports = template;