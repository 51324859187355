import { ItemView } from '@b2cmessenger/backbone';
import Modal from 'windows/Modal/Modal';
import FooterView from 'widgets/Footer/Footer';
import template from './ImageSource.jade';
import './ImageSource.scss';

const TYPES = ['camera', 'gallery'];

const ImageSourceModal = Modal.extend({
    windowName: "image-source-modal-window",
    className: "modal-window image-source-modal-window green-border",

    onRender() {
        const contentView = new ImageSourceContent();
        this.listenTo(contentView, 'selected:type', (type) => {
            if (type && _.contains(TYPES, type)) {
                this.close(type);
            } else {
                this.showError('Undefined select type');
                this.cancel();
            }
        });

        const footerView = new FooterView({
            buttons: [{
                id: "close",
                text: "CANCEL",
                icn: "empty"
            }]
        });

        this.listenTo(footerView, 'close:click', view => this.close(false));

        this.content.show(contentView);
        this.footer.show(footerView);
    },

    cancel() {
        this.close(false);
    }
});

export default ImageSourceModal;


@ItemView.properties({
    template: template,
    className: "widget image-source-modal-content-widget",

    ui: {
        'select': '[data-js-select]'
    },

    events: {
        'click @ui.select'(e) {
            this.trigger('selected:type', $(e.currentTarget).attr('data-type'));
        }
    }
})
class ImageSourceContent extends ItemView {
}
