import React from "react";

function useEventListener(source, eventName, handler) {
    const handlerRef = React.useRef();

    React.useEffect(() => {
        handlerRef.current = handler;
    }, [handler]);

    React.useEffect(() => {
        if (!source || !handler) {
            return;
        }

        const listener = e => handlerRef.current(e);

        if (_.isFunction(source.on)) {
            source.on(eventName, listener);
        } else if (_.isFunction(source.addEventListener)) {
            source.addEventListener(eventName, listener);
        }

        return () => {
            if (source && _.isFunction(source.on)) {
                source.off(eventName, listener);
            } else if (source && _.isFunction(source.addEventListener)) {
                source.removeEventListener(eventName, listener);
            }
        };
    }, [source, eventName]);
}

export default useEventListener;
