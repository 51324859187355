import {
    ADD_TO_CART,
    CHANGE_CART_COMMENT,
    CHANGE_CART_ITEM,
    CHANGE_CART_ITEM_QUANTITY,
    COLLAPSE_CART_ITEMS,
    DISCARD_CART_ORDER,
    REMOVE_FROM_CART,
    RESET_CART,
    SET_SELECTED_TABLE_ID,
    UPDATE_CART_ADDITION_ITEM_QUANTITY,
    UPDATE_CART_ITEM,
    UPDATE_CART_ITEM_QUANTITY
} from "store/menu/cart/actions";
import { getMenuCartItemUniqueId } from "store/menu/utils";

export const CartTypes = {
    Draft: 'draft',
    Current: 'current',
    Edit: 'edit'
};

export const initialCartState = {
    items: {},
    order: [],
    comment: '',
    selectedTableId: null,
    orderParams: null,
    placeId: null,
    type: CartTypes.Current
};

export default function (state = initialCartState, action) {
    switch (action.type) {
        case ADD_TO_CART: {
            const { cartItem, quantity, uniqueCartId } = action.payload;

            const item = state.items[uniqueCartId];
            const order = [...state.order];

            if (order.indexOf(uniqueCartId) < 0) {
                order.push(uniqueCartId);
            }

            return {
                ...state,
                items: {
                    ...state.items,
                    [uniqueCartId]: { ...cartItem, quantity: (item && item.quantity || 0) + quantity }
                },
                order
            };
        }

        case CHANGE_CART_ITEM: {
            const { uniqueCartId, changedCartItem, changedUniqueCartId, changes } = action.payload;

            const items = { ...state.items };
            const order = [...state.order];

            const item = {
                ...items[uniqueCartId],
                ...changedCartItem,
                quantity: !_.isUndefined(changes.quantity) ? changes.quantity : items[uniqueCartId].quantity,
                properties: {
                    ...items[uniqueCartId].properties,
                    ...changedCartItem.properties,
                },
                additions: {
                    ...items[uniqueCartId].additions,
                    ...changedCartItem.additions,
                }
            };

            if (uniqueCartId !== changedUniqueCartId) {
                delete items[uniqueCartId];
                order.splice(order.indexOf(uniqueCartId), 1, changedUniqueCartId);
            }

            items[changedUniqueCartId] = { ...item };

            return {
                ...state, items, order
            };
        }

        case REMOVE_FROM_CART: {
            const { uniqueCartId, isEditingOrderItem, itemId } = action.payload;

            const items = { ...state.items };
            let order = [...state.order];

            if (isEditingOrderItem && uniqueCartId) {
                items[uniqueCartId] = {
                    ...items[uniqueCartId],
                    quantity: 0
                };
            } else if (uniqueCartId) {
                delete items[uniqueCartId];
                order = order.filter(id => id !== uniqueCartId);
            } else if (itemId) {
                for (const uniqId in items) {
                    if (items[uniqId].id === itemId) {
                        order = order.filter(id => id !== uniqId);
                        delete items[uniqId];
                    }
                }
            }

            return {
                ...state, items, order
            };
        }

        case CHANGE_CART_ITEM_QUANTITY: {
            const { uniqueCartId, dt } = action.payload;

            const items = { ...state.items };
            const item = items[uniqueCartId];

            return {
                ...state,
                items: {
                    ...state.items,
                    [uniqueCartId]: {
                        ...item,
                        quantity: item.quantity + dt
                    }
                }
            };
        }

        case UPDATE_CART_ITEM: {
            const { item, uniqueId } = action.payload;

            return {
                ...state,
                items: {
                    ...state.items,
                    [uniqueId]: {
                        ...state.items[uniqueId],
                        ...item
                    }
                }
            }
        }

        case UPDATE_CART_ITEM_QUANTITY: {
            const { cartId, quantity } = action.payload;

            const items = { ...state.items };

            if (items[cartId]) {
                items[cartId].quantity = quantity;
            }

            return {
                ...state,
                items
            }
        }

        case UPDATE_CART_ADDITION_ITEM_QUANTITY: {
            const { cartId, itemId, quantity } = action.payload;
            const items = { ...state.items };
            const item = { ...items[cartId] };

            item.additions[itemId] = quantity;
            items[cartId] = item;

            return {
                ...state,
                items
            }
        }

        case DISCARD_CART_ORDER:
        case RESET_CART: {
            const { data } = action.payload;
            return data ? { ...data } : { ...initialCartState };
        }

        case CHANGE_CART_COMMENT: {
            const { comment } = action.payload;

            return {
                ...state,
                comment
            }
        }

        case COLLAPSE_CART_ITEMS: {
            const { items: oldItems, order: oldOrder } = state;

            const ordersMap = {};
            const items = _(oldItems).reduce((memo, item) => {
                // do not collapse elements with id
                if (item.orderItemId) {
                    const idx = _.findIndex(oldOrder, id => id === item.orderItemId);
                    ordersMap[item.orderItemId] = idx;
                    memo[item.orderItemId] = { ...item };

                    return memo;
                }

                if (item.quantity) {
                    const additions = _(item.additions).reduce((memo, value, key) => {
                        if (value > 0) {
                            memo[key] = value;
                        }

                        return memo;
                    }, {});

                    const newItem = {
                        ...item,
                        additions
                    };

                    const newId = getMenuCartItemUniqueId({ ...newItem });
                    const quantity = memo[newId] ? memo[newId].quantity + item.quantity : item.quantity;

                    memo[newId] = {
                        ...newItem,
                        quantity
                    };

                    const idx = _.findIndex(oldOrder, id => id === newId);
                    ordersMap[newId] = idx;
                }

                return memo;
            }, {});

            const order = _(ordersMap).reduce((memo = [], idx, id) => {
                memo = idx !== -1 ?
                    [
                        ...memo.slice(0, idx),
                        id,
                        ...memo.slice(idx)
                    ] : [...memo, id];
                return memo;
            }, []).filter(Boolean);

            console.log(items, order);

            return {
                ...state,
                items,
                order
            }
        }

        case SET_SELECTED_TABLE_ID: {
            const { id = null } = action.payload;

            return {
                ...state,
                selectedTableId: id
            }
        }

        default:
            return state
    }
}
