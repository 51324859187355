import { ViewModel, ItemView } from '@b2cmessenger/backbone';

import UserModel from 'models/UserModel';
import Window from 'windows/Window';
import HeaderView from 'widgets/Header/Header';

import template from './Content.jade';
import './MyID.scss';

import qrCodeGenerator from 'utils/qrCodeGenerator';
import getNextPrettyColor from 'utils/randomPrettyColor';

const MyID = Window.extend({
    windowName: "my-id-window",
    className: "my-id-window",

    initialize() {
        if(!this.model)
            this.model = new UserModel(LoginedUserHandler.getLoginedUser());
    },

    onRender() {
        const headerView = new HeaderView({ 
            leftButtons: ['back'],
            title: 'My ID & QR'
        })
        this.listenTo(headerView, 'back:click', view => this.close());
        this.header.show(headerView);

        this.content.show(new ContentView({
            model: this.model
        }));
    }
});

const ContentView = ItemView.extend({
    template: template,
    className: "widget my-id-content-widget",

    ui: {
        personBlock: '.person',
        avatar: '[data-js-avatar]',
        name: '[data-js-name]',
        qr: '[data-js-qr]'
    },

    bindingFilters: {
        backgroundUrl: src => src && `url(${src})` || 'url(common_res/icon/other/anonymous.png)',
        prettyColor: str => getNextPrettyColor(str)
    },

    bindings: {
        '@ui.personBlock': 'css:{"border-color":prettyColor(name)}',
        '@ui.avatar': 'css:{"background-image":backgroundUrl(avatarUrl)}',
        '@ui.name': 'text:name',
        '@ui.qr': 'attr:{src:qrDataUrl}'
    },

    initialize() {
        this.viewModel = this.viewModel || new ViewModel;

        this.viewModel.set({
            qrDataUrl: ''
        });

        const updateQrDataUrl = (m, id) => {
            if (_.isUndefined(m)) {
                id = this.model.get('id');
            }

            this.viewModel.set({ qrDataUrl: '' });

            id && qrCodeGenerator(id).then(url => this.viewModel.set({ qrDataUrl: url }));
        };

        this.viewModel.listenTo(this.model, 'change:id', updateQrDataUrl);
        updateQrDataUrl();
    },
});

export default MyID;
