import React from 'react';
import './FileInput.scss';

export const FileInput = (props) => {
    const multiple = props.multiple || null;
    const accept = props.accept || 'image/*';
    const onChange = props.onChange || _.noop;

    return (
        <input
            type="file"
            className={props.className}
            multiple={multiple}
            accept={accept}
            onChange={onChange}
            data-role="none"
        />
    );
};

export const ButtonFileInput = (props) => {
    const className = ['file-input', props.className].filter(Boolean).join(' ');

    return (
        <button className={className} data-role="none">
            {props.children}
            <FileInput multiple={props.multiple} accept={props.accept} onChange={props.onChange} />
        </button>
    );
};
