import React from 'react';

import MenuTextItems, { getTextItemsFromOrderFormattedItems } from 'components/menu/MenuItems/MenuTextItems';
import { RadioSwitch, SliderGroup, SwitchInput } from 'components/UI/InputGroup/InputGroup';
import MenuList, { byIdAndSortOrder, MenuListEmptyItem, MenuListItem } from 'components/menu/MenuList/MenuList';
import { MenuPlaceOrderBase } from 'components/menu/MenuPlaceOrder/MenuPlaceOrder';

import './MenuSelectableOrders.scss';

const MenuSelectableOrders = (props) => {
    const { items = [], selected = [], onSelectedChange = _.noop, multiple = false, displaySwitch = true } = props;
    const emptyContent = <MenuListEmptyItem message="No orders was found" />;

    const content = items.map((i) => {
        const textItems = getTextItemsFromOrderFormattedItems(i);
        const value = selected.indexOf(i.id) > -1;
        const onChange = onSelectedChange.bind(null, i);

        const onClick = (e) => {
            e.stopPropagation();
            e.preventDefault();

            onChange();
        };

        const switchInput = multiple ? (
            <SwitchInput value={value} onChange={onChange} />
        ) : (
            <RadioSwitch value={value} onChange={onChange} />
        );

        return (
            <MenuListItem className="menu-list-item-wrap order selectable" key={byIdAndSortOrder(i)} onClick={onClick}>
                <MenuPlaceOrderBase className="menu-list-item" {...i}>
                    <MenuTextItems items={textItems} limit={3} />
                </MenuPlaceOrderBase>

                {displaySwitch && (
                    <div className="menu-list-item-selection" onClick={(e) => e.stopPropagation()}>
                        <SliderGroup>{switchInput}</SliderGroup>
                    </div>
                )}
            </MenuListItem>
        );
    });

    return (
        <MenuList emptyContent={emptyContent} {...props}>
            {content}
        </MenuList>
    );
};

export default MenuSelectableOrders;
