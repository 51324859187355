import React, { useCallback, useMemo } from 'react';
import ReactPage from 'pages/ReactPage';

import Page, { PageContent, PageHeader } from 'components/page/Page';
import Header from 'components/UI/Header/Header';
import { BackButton, EditButton, MenuButton } from 'components/UI/Button/Button';

import withStore from 'components/withStore';
import { connect } from 'react-redux';
import Spinner from 'components/UI/Spinner/Spinner';
import { getMenuError, getMenuInfoLoading, getMenuIsEditPossible, getMenuWaitersAsArray } from 'store/menu/selectors';
import { saveMenuInfo } from 'store/menu/actions';
import MenuItemError from 'components/menu/MenuItemDetail/MenuItemError';
import MenuSectionEditorWindow from 'components/window/MenuSectionEditor/MenuSectionEditor';
import * as SectionAPI from 'utils/api/sections';
import {
    getMenuSectionById,
    getMenuSectionsAsArray,
    getMenuTablesAsArrayBySectionId,
} from 'store/menu/sections/selectors';
import MenuSections from 'components/menu/MenuSections/MenuSections';
import { removeSection, removeTable, upsertSection, upsertTable } from 'store/menu/sections/actions';
import InputGroup from 'components/UI/InputGroup/InputGroup';
import MenuTables from 'components/menu/MenuTables/MenuTables';
import TextButton from 'components/UI/TextButton';
import MenuTableEditorWindow from 'components/window/MenuTableEditor/MenuTableEditor';
import * as TableAPI from 'utils/api/tables';
import ConfirmModalWindow from 'windows/Modal/Confirm';
import { MenuListEmptyItem } from 'components/menu/MenuList/MenuList';
import useInitializeMenu from 'hooks/useInitializeMenu';

const MenuSectionContent = (props) => {
    const { placeId, isEditPossible, loading, isActive } = props;
    const { upsertSection, removeSection, upsertTable, removeTable, showError } = props;
    const { sections, waiters } = props;

    const section = useMemo(() => props.section && { ...props.section, tables: props.tables }, [
        props.section,
        props.tables,
    ]);

    const error = useMemo(() => {
        if (!section && !loading && !props.error) {
            return `Section does not exist`;
        }

        return props.error;
    }, [props.error, section, loading]);

    const pageTitle = useMemo(() => {
        if (section && section.name) return `Menu Section: ${section.name}`;
        return 'Menu Section';
    }, [section]);

    const { shouldInitMenu, readyToInitialize } = useInitializeMenu({ placeId, isActive });

    const contentReady = useMemo(() => {
        return error === null && !loading && !shouldInitMenu;
    }, [shouldInitMenu, error, loading]);

    const openSectionEditor = useCallback(
        async (options) => {
            const { section } = options;
            await new MenuSectionEditorWindow({
                section,
                saveSection: (editedSection) => {
                    return (editedSection.id
                        ? SectionAPI.updateSection({ ...editedSection, placeId })
                        : SectionAPI.createSection({ ...editedSection, placeId })
                    ).then(upsertSection);
                },
            }).show();
        },
        [placeId, showError, upsertSection]
    );
    const onRemoveSection = useCallback(
        async () => {
            const confirm = await new ConfirmModalWindow({
                title: `Do you really want to delete section '${section.name}' and all its tables?`,
            }).show();

            if (confirm) {
                try {
                    await SectionAPI.removeSection({ placeId, id: section.id });
                    removeSection(section.id);
                    app.controller.goToMenuSections({ placeId });
                } catch (e) {
                    showError(e instanceof Error ? e.message : e);
                }
            }
        },
        [placeId, section, showError, removeSection]
    );

    const openTableEditor = useCallback(
        async (options) => {
            const { sectionId, table } = options;

            await new MenuTableEditorWindow({
                table,
                sectionId,
                waiters,
                sections: sectionId ? sections.map((s) => ({ ...s, disabled: s.id !== sectionId })) : sections,
                saveTable: (editedTable) => {
                    return (editedTable.id
                        ? TableAPI.updateTable({ ...editedTable, placeId })
                        : TableAPI.createTable({ ...editedTable, sectionId, placeId })
                    ).then(upsertTable);
                },
            }).show();
        },
        [placeId, sections, showError, upsertTable]
    );

    const onRemoveTable = useCallback(
        async (options) => {
            const { table } = options;
            const confirm = await new ConfirmModalWindow({
                title: `Do you really want to delete table with number '${table.number}'?`,
            }).show();

            if (confirm) {
                try {
                    await TableAPI.removeTable({ placeId, id: table.id });
                    removeTable(table.id);
                } catch (e) {
                    showError(e instanceof Error ? e.message : e);
                }
            }
        },
        [placeId, showError, removeSection]
    );

    const tablesEmptyContent = useMemo(() => <MenuListEmptyItem message="Section has no tables" />, []);

    const onShow = useCallback(() => {
        if (readyToInitialize) {
            readyToInitialize(true);
        }
    }, [readyToInitialize]);

    return (
        <Page instance={props.__page__} onShow={onShow}>
            <PageHeader>
                <Header>
                    <BackButton onClick={props.goBack} />
                    <h1 className="title">{pageTitle}</h1>
                    {isEditPossible && Boolean(section) && (
                        <EditButton onClick={() => openSectionEditor({ section: _.omit(section, 'tables') })} />
                    )}
                    <MenuButton right />
                </Header>
            </PageHeader>
            <PageContent className="scrollable grow with-standard-padding">
                {contentReady && (
                    <>
                        <InputGroup
                            label="Section"
                            className="input-group menu-list-input-group no-horizontal-padding"
                        />
                        <MenuSections className="menu-list-compact" sections={[section]} />
                        {isEditPossible ? (
                            <TextButton
                                className="wide negative normalized border-filled with-top-margin"
                                onClick={onRemoveSection}
                            >
                                Remove section
                            </TextButton>
                        ) : null}
                        <InputGroup
                            label="Tables"
                            className="input-group menu-list-input-group no-horizontal-padding"
                        />
                        <MenuTables
                            className="menu-list-compact no-horizontal-margin"
                            tables={section.tables}
                            editable={isEditPossible}
                            onEditTable={openTableEditor}
                            onRemoveTable={onRemoveTable}
                            emptyContent={tablesEmptyContent}
                            waiters={waiters}
                        />
                        {isEditPossible ? (
                            <TextButton
                                className="medium normalized border-filled with-top-margin"
                                onClick={openTableEditor.bind(null, { sectionId: section.id })}
                            >
                                Create table in
                                {' '}
                                <span>{section.name}</span>
                            </TextButton>
                        ) : null}
                    </>
                )}
                {((!contentReady && !error) || loading) && <Spinner />}
                {error !== null && <MenuItemError errorText={error} displayRetryButton={false} />}
            </PageContent>
        </Page>
    );
};

const ConnectedMenuSectionContent = withStore(
    connect(
        (state, ownProps) => ({
            loading: getMenuInfoLoading(state),
            isEditPossible: getMenuIsEditPossible(state),

            error: getMenuError(state),
            section: getMenuSectionById(state, ownProps.sectionId),
            sections: getMenuSectionsAsArray(state),
            tables: getMenuTablesAsArrayBySectionId(state, ownProps.sectionId),
            waiters: getMenuWaitersAsArray(state),
        }),
        {
            saveMenuInfo,
            upsertTable,
            upsertSection,
            removeSection,
            removeTable,
        }
    )(MenuSectionContent)
);

const MenuSectionPage = ReactPage.extend({
    attributes: {
        id: 'page_menu_section',
    },
    className: 'menu-section-page',
    component: ConnectedMenuSectionContent,
    immediateRenderOnShow: true,
});

export default MenuSectionPage;
