import {byId, filterObject, getUpdatedAtTimeStamp} from "store/utils";
import {
    LOAD_CATEGORY_SUCCEEDED,
    UPSERT_CATEGORY,
    REMOVE_CATEGORY,
    INIT_MENU_SUCCEEDED,
    INIT_MENU, UPSERT_ITEM, REMOVE_ITEM, UPSERT_CATEGORIES,
} from "../actions";
import {UnlistedCategory} from "utils/api/menu";
import { RESET_CATEGORIES } from "store/menu/actions";

const initialState = {
    values: {},
    updatedAt: {},
    itemsMap: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case INIT_MENU:
            return {
                ...state, values: {}, updatedAt: {}, itemsMap: {}
            };

        case INIT_MENU_SUCCEEDED:
        case LOAD_CATEGORY_SUCCEEDED:
            const categoryId = action.type === INIT_MENU_SUCCEEDED ?
                action.payload.categoryId :
                action.payload.id;

            const values = { ...state.values };
            const itemsMap = { ...state.itemsMap };
            const updatedAt = { ...state.updatedAt };

            for (const category of action.payload.categories) {
                values[byId(category)] = { ...category };
            }

            itemsMap[categoryId] = (action.payload.items || []).map(byId);
            updatedAt[categoryId] = getUpdatedAtTimeStamp(updatedAt[categoryId]);

            return {
                ...state, values, updatedAt, itemsMap
            };

        case UPSERT_CATEGORY: {
            const { id, category } = action.payload;

            const values = { ...state.values };
            const itemsMap = { ...state.itemsMap };
            const updatedAt = { ...state.updatedAt };

            values[id] = { ...category };
            itemsMap[id] = action.payload.itemsMap || itemsMap[id] || [];

            return {
                ...state, values, updatedAt, itemsMap
            };
        }

        case UPSERT_CATEGORIES: {
            const { categories, categoriesItemsMap = {} } = action.payload;

            const values = { ...state.values };
            const itemsMap = { ...state.itemsMap };
            const updatedAt = { ...state.updatedAt };

            for (const category of categories) {
                const { id  } = category;

                if (_.isArray(categoriesItemsMap[id])) {
                    itemsMap[id] = [ ...categoriesItemsMap[id] ];
                    updatedAt[id] = getUpdatedAtTimeStamp(updatedAt[id]);
                }

                values[id] = { ...category};
            }

            return {
                ...state, values, itemsMap
            }
        }

        case UPSERT_ITEM: {
            const { categoryIds, id } = action.payload;

            const itemsMap = { ...state.itemsMap };
            const itemsMapWithinId = filterObject({ ...itemsMap }, (key, value) => value.indexOf(id) > -1);
            const itemCategoryIds = _.keys(itemsMapWithinId).map(Number);

            if (!categoryIds || _.isEqual(categoryIds.map(Number), itemCategoryIds.map(Number))) {
                return state;
            }

            const newCategoryIds = _.difference(categoryIds, itemCategoryIds);
            const outdatedCategoryIds = _.difference(itemCategoryIds, categoryIds);

            for (const categoryId of outdatedCategoryIds) {
                if (itemsMap[categoryId]) {
                    itemsMap[categoryId] = itemsMap[categoryId].filter(_id => _id !== id);
                }
            }

            for (const categoryId of newCategoryIds) {
                if (itemsMap[categoryId]) {
                    itemsMap[categoryId] = [...itemsMap[categoryId], id];
                } else {
                    itemsMap[categoryId] = [id]
                }
            }

            return { ...state, itemsMap };
        }

        case REMOVE_ITEM: {
            const { id } = action.payload;

            const itemsMap = { ...state.itemsMap };
            const itemCategoryIds = filterObject({ ...itemsMap }, (key, value) => value.indexOf(id) > -1);

            for (const categoryId in itemCategoryIds) {
                if (itemsMap.hasOwnProperty(categoryId) && itemsMap[categoryId]) {
                    itemsMap[categoryId] = itemsMap[categoryId].filter(_id => _id !== id);
                }
            }

            return { ...state, itemsMap };
        }

        case REMOVE_CATEGORY: {
            const values = { ...state.values };
            const itemsMap = { ...state.itemsMap };
            const updatedAt = { ...state.updatedAt };

            delete values[action.payload.id];
            delete itemsMap[action.payload.id];

            return {
                ...state, values, updatedAt, itemsMap
            };
        }

        case RESET_CATEGORIES: {
            const { categories = [], categoriesItemsMap = {} } = action.payload;

            const values = {};
            const itemsMap = {};
            const updatedAt = {};

            for (const category of categories) {
                const { id  } = category;

                if (_.isArray(categoriesItemsMap[id])) {
                    itemsMap[id] = [ ...categoriesItemsMap[id] ];
                    updatedAt[id] = getUpdatedAtTimeStamp(updatedAt[id]);
                }

                values[id] = { ...category};
            }

            return {
                ...initialState,
                values,
                itemsMap,
                updatedAt
            }
        }

        default:
            return state
    }
}
