import { Required, Optional, ViewModel, CollectionView } from '@b2cmessenger/backbone';

//@ts-ignore
import justifiedLayout from 'justified-layout';
// @ts-ignore
import elementResizeDetectorFactory from 'element-resize-detector';

import PhotoItemView from './PhotoItem';
import './PhotoGrid.scss';

/** @type {typeof import('./PhotoGrid').properties} */
// @ts-ignore
const properties = CollectionView.properties;

/** @type {typeof import('./PhotoGrid').options} */
// @ts-ignore
const options = CollectionView.options;

/** @type {typeof import('./PhotoGrid').events} */
// @ts-ignore
const events = CollectionView.events;

@options({
    collection: Required,
    parentViewModel: Optional
})
@properties({
    className: 'widget message-comment-editor-photo-grid-widget',
    childView: PhotoItemView,
    childViewOptions() {
        return { parentViewModel: this.viewModel }
    }
})
class PhotoGridView extends CollectionView {
    constructor(options) {
        super(options)
    }

    initialize() {
        /**@type {import('./PhotoGrid')} */
        // @ts-ignore
        const self = this;
        this.viewModel = new ViewModel({
            parentViewModel: self.options.parentViewModel,
            containerWidth: 0
        });

        this._debouncedRecalculateLayout = _.debounce(this._recalculateLayout, 100);
        this.listenTo(this.viewModel, 'change:containerWidth', this._debouncedRecalculateLayout);
        this.listenTo(this.collection, 'update reset change:ar', this._debouncedRecalculateLayout);
    }

    onAttach(view) {
        if (!this.erd) {
            this.erd = elementResizeDetectorFactory({
                strategy: 'scroll'
            });
            this.erd.listenTo(this.el, () => this.viewModel.set({ containerWidth: getContentWidth(this.el) }));
        }

        this._recalculateLayout();
    }

    _recalculateLayout() {
        /**@type {import('./PhotoGrid')} */
        // @ts-ignore
        const self = this;
        const containerWidth = this.viewModel.get('containerWidth');
        const allHaveAr = this.collection.length && this.collection.all(m => m.get('ar'));
        if (containerWidth && allHaveAr) {
            const margin = 7;
            const layout = justifiedLayout(this.collection.pluck('ar'), {
                containerWidth,
                containerPadding: 0,
                boxSpacing: {
                    horizontal: margin,
                    vertical: margin
                },
                targetRowHeight: 75,
                targetRowHeightTolerance: 0.33
            });

            let row = -1;
            _.each(layout.boxes, (b, index) => { 
                const isFirstInRow = b.row > row;
                row = b.row;

                const c = self.children.findByIndex(index);
                if (c) {
                    c.setDimensions(b.width, b.left, b.top);
                }
            });

            this.$el.css({ height: layout.containerHeight }).addClass('justified');
        } else {
            this.$el.css({ height: 0 }).removeClass('justified');
        }
    }
}

function getContentWidth(element) {
    var styles = getComputedStyle(element)

    return element.clientWidth - parseFloat(styles.paddingLeft) - parseFloat(styles.paddingRight);
}

export default PhotoGridView;
