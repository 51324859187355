import { Model, ViewModel } from '@b2cmessenger/backbone';
import settings from 'settings';

import Page from 'pages/Page';
import PlaceModel from 'models/PlaceModel';
import PropertiesWidget from 'widgets/Properties/Properties';
import PlaceMapWindow from 'windows/PlaceMap/PlaceMap';
import HeaderView from 'widgets/Header/Header';
import FooterView from 'widgets/Footer/Footer';
import ReportWindow from 'windows/Report/Report';
import PlaceGalleryWindow from 'windows/PlaceGallery/PlaceGallery';
import LeftMenuWindow from 'windows/LeftMenu/LeftMenu';
import PlaceEditorWindow from 'windows/PlaceEditor/PlaceEditor';
import InfoModal from 'windows/Modal/Info';
import quoteattr from 'utils/quoteattr';
import moment from 'moment-timezone';
import 'utils/Element.scrollIntoCenter';

import './Place.scss';
import template from './Place.jade';

import getNextPrettyColor from 'utils/randomPrettyColor';
import ConfirmModal from "windows/Modal/Confirm";
import NotificationMuteModel from "models/NotificationMuteModel";
import buildPlaceLiteWidget from 'widgets/lite/place/place';
import AjaxError from '../../utils/AjaxError';
import PhoneCollection from 'models/PhoneCollection';
import PhoneEditorModal from 'windows/Modal/Phone/PhoneEditor';
import PhoneVerifyModal from 'windows/Modal/Phone/PhoneVerify';
import ReservationEditorWindow from 'windows/ReservationEditor/ReservationEditor';
import ViewWithWindows from 'traits/ViewWithWindows';
import LoginedUserModel from "models/LoginedUserModel";

const withWindowsTrait = {
    Trait: ViewWithWindows,
    options: {
        windowMap: [
            {
                cls: ReservationEditorWindow,
                trigger() {
                    this.footer.currentView.trigger('bookreservation:click');
                }
            }
        ],
    }
};

window.moment = moment;

const Place = Page.extendWithTraits([withWindowsTrait],{
    template: template,

    attributes: {
        'id': "page_place",
    },

    className: "place-page",

    regions: {
        header: '[data-js-header]',
        footer: '[data-js-footer]',
        properties: '[data-js-properties]'
    },

    ui: {
        content: '[data-js-content]',
        opensSoon: '[data-js-opens-soon]',
        placeCard: '[data-js-content] .content .logo_base_info',
        name: '[data-js-name]',
        openUntil: '[data-js-open-until]',
        workHours: '[data-js-work-hours]',
        scrollingElem: '[data-js-content] > .content',
        backgorund: ' > .background',
        openDigitalMenu: '[data-js-open-digital-menu]',
        digitalMenu: '.digital-menu',
        myBusiness: '[data-js-mybusiness]'
    },

    bindings: {
        '@ui.opensSoon': 'classes:{closes:isOpen,opens:not(isOpen)},text:opensSoonText',
        '@ui.openUntil': 'classes:{hidden:not(openUntilInfo),open:isOpen,closed:not(isOpen),soon:opensSoonText},text:openUntilText',
        '@ui.digitalMenu': 'classes:{hidden:not(menuAllowed)}'
    },

    events: {
        'click @ui.myBusiness': 'myBusiness',
        'click @ui.openDigitalMenu': 'openDigitalMenu',
        'click @ui.opensSoon'() {
            this.ui.workHours && this.ui.workHours.length && this.ui.workHours[0].scrollIntoCenter();
        },
        'click @ui.openUntil'() {
            this.ui.workHours && this.ui.workHours.length && this.ui.workHours[0].scrollIntoCenter();
        },
    },

    computeds: {
        currentMomentTZ: {
            deps: ['currentMoment', 'placeTZ'],
            get(currentMoment, placeTZ) {
                if (currentMoment && placeTZ) {
                    return currentMoment.clone().tz(placeTZ)
                }
            }
        },
        openUntilInfo: {
            deps: ['currentMomentTZ', 'workDays'],
            get(currentMomentTZ, workDays) {
                if (currentMomentTZ && workDays) {
                    const currentDay = currentMomentTZ.day();
                    const workDay = workDays[currentDay];
                    if (workDay) {
                        if (workDay.all && workDay.all == 1) {
                            for (let i = 1; i <= 7; ++i) {
                                const day = (currentDay + i) % 7;
                                const nextWorkDay = workDays[day];
                                if (nextWorkDay.all && nextWorkDay.all == 1) {

                                } else if (nextWorkDay.all && nextWorkDay.all == -1) {
                                    return {
                                        open: true,
                                        all: true,
                                        untilMoment: currentMomentTZ.clone().add(i, 'd').startOf('day')
                                    };
                                } else if (nextWorkDay.int && nextWorkDay.int.length) {
                                    const int = nextWorkDay.int[0];
                                    if (int.o_h == 0 && int.o_m == 0) {
                                        if ((int.c_h == 0 && int.c_m == 0) || (int.c_h == 23 && int.c_m == 59)) {

                                        } else {
                                            return {
                                                open: true,
                                                all: true,
                                                untilMoment: currentMomentTZ.clone().add(i, 'd')
                                                    .hour(nextWorkDay.int[0].c_h).minute(nextWorkDay.int[0].c_m)
                                            };
                                        }
                                    } else {
                                        return {
                                            open: true,
                                            all: true,
                                            untilMoment: currentMomentTZ.clone().add(i, 'd').startOf('day')
                                        };
                                    }
                                }
                            }
                            return {
                                open: true,
                                all: true
                            }
                        } else if (workDay.all && workDay.all == -1) {
                            for (let i = 1; i <= 7; ++i) {
                                const day = (currentDay + i) % 7;
                                const nextWorkDay = workDays[day];
                                if (nextWorkDay.all && nextWorkDay.all == -1) {

                                } else if (nextWorkDay.all && nextWorkDay.all == 1) {
                                    return {
                                        open: false,
                                        all: true,
                                        untilMoment: currentMomentTZ.clone().add(i, 'd').startOf('day')
                                    };
                                } else if (nextWorkDay.int && nextWorkDay.int.length) {
                                    const int = nextWorkDay.int[0];
                                    return {
                                        open: false,
                                        all: true,
                                        untilMoment: currentMomentTZ.clone().add(i, 'd')
                                            .hour(nextWorkDay.int[0].o_h).minute(nextWorkDay.int[0].o_m)
                                    };
                                }
                            }
                            return {
                                open: false,
                                all: true
                            }
                        } else if (workDay.int) {
                            const int = _.find(workDay.int, int => {
                                return currentMomentTZ.isBetween(currentMomentTZ.clone().hour(int.o_h).minute(int.o_m),
                                    currentMomentTZ.clone().hour(int.c_h).minute(int.c_m));
                            });

                            if (int) {
                                if ((int.c_h == 0 && int.c_m == 0) || (int.c_h == 23 && int.c_m == 59)) {
                                    for (let i = 1; i <= 7; ++i) {
                                        const day = (currentDay + i) % 7;
                                        const nextWorkDay = workDays[day];
                                        if (nextWorkDay.all && nextWorkDay.all == 1) {

                                        } else if (nextWorkDay.all && nextWorkDay.all == -1) {
                                            return {
                                                open: true,
                                                all: false,
                                                untilMoment: currentMomentTZ.clone().add(i, 'd').startOf('day')
                                            };
                                        } else if (nextWorkDay.int && nextWorkDay.int.length) {
                                            const int = nextWorkDay.int[0];
                                            if (int.o_h == 0 && int.o_m == 0) {
                                                if (int.c_h == 0 && int.c_m == 0 || int.c_h == 23 && int.c_m == 59) {

                                                } else {
                                                    return {
                                                        open: true,
                                                        all: true,
                                                        untilMoment: currentMomentTZ.clone().add(i, 'd')
                                                            .hour(nextWorkDay.int[0].c_h).minute(nextWorkDay.int[0].c_m)
                                                    };
                                                }
                                            } else {
                                                return {
                                                    open: true,
                                                    all: true,
                                                    untilMoment: currentMomentTZ.clone().add(i, 'd').startOf('day')
                                                };
                                            }
                                        }
                                    }
                                    return {
                                        open: true,
                                        all: false
                                    }
                                } else {
                                    return {
                                        open: true,
                                        all: false,
                                        untilMoment: currentMomentTZ.clone().hour(int.c_h).minute(int.c_m)
                                    }
                                }
                            } else {
                                const nextTodayInt = _.find(workDay.int, int => {
                                    return currentMomentTZ.isBefore(currentMomentTZ.clone().hour(int.o_h).minute(int.o_m));
                                });

                                if (nextTodayInt) {
                                    return {
                                        open: false,
                                        all: false,
                                        untilMoment: currentMomentTZ.clone().hour(nextTodayInt.o_h).minute(nextTodayInt.o_m)
                                    }
                                } else {
                                    for (let i = 1; i <= 7; ++i) {
                                        const day = (currentDay + i) % 7;
                                        const nextWorkDay = workDays[day];
                                        if (nextWorkDay.all && nextWorkDay.all == -1) {

                                        } else if (nextWorkDay.all && nextWorkDay.all == 1) {
                                            return {
                                                open: false,
                                                all: true,
                                                untilMoment: currentMomentTZ.clone().add(i, 'd').startOf('day')
                                            };
                                        } else if (nextWorkDay.int && nextWorkDay.int.length) {
                                            const int = nextWorkDay.int[0];
                                            return {
                                                open: false,
                                                all: true,
                                                untilMoment: currentMomentTZ.clone().add(i, 'd')
                                                    .hour(nextWorkDay.int[0].o_h).minute(nextWorkDay.int[0].o_m)
                                            };
                                        }
                                    }
                                    return {
                                        open: false,
                                        all: false
                                    }
                                }
                            }
                        }
                    }
                }

                return undefined;
            }
        },
        isOpen: {
            deps: ['openUntilInfo'],
            get(openUntilInfo) {
                return openUntilInfo && openUntilInfo.open;
            }
        },
        openUntilText: {
            deps: ['currentMomentTZ', 'openUntilInfo'],
            get(currentMomentTZ, openUntilInfo) {
                if (openUntilInfo) {
                    let str = openUntilInfo.open ? 'Open' : 'Closed';
                    if (openUntilInfo.untilMoment) {
                        str += ' until';
                        if (openUntilInfo.untilMoment.day() == currentMomentTZ.day() ||
                            openUntilInfo.untilMoment.diff(currentMomentTZ, 'hours') < 11
                        ) {
                            str += ' ' + openUntilInfo.untilMoment.format('LT');
                        } else {
                            const currentDayMoment = currentMomentTZ.clone().startOf('day');
                            if (openUntilInfo.untilMoment.diff(currentDayMoment, 'days') == 1) {
                                if (openUntilInfo.untilMoment.hour() == 0 && openUntilInfo.untilMoment.minute() == 0) {
                                    str += ' midnight';
                                } else {
                                    str += ' tomorrow ' + openUntilInfo.untilMoment.format('LT');
                                }
                            } else {
                                if (openUntilInfo.untilMoment.hour() == 0 && openUntilInfo.untilMoment.minute() == 0) {
                                    str += ' ' + openUntilInfo.untilMoment.format('dddd');
                                } else {
                                    str += ' ' + openUntilInfo.untilMoment.format('dddd') + ' ' + openUntilInfo.untilMoment.format('LT');
                                }
                            }
                        }
                    }
                    return str;
                }
                return '';
            }
        },
        opensSoonText: {
            deps: ['currentMomentTZ', 'openUntilInfo'],
            get(currentMomentTZ, openUntilInfo) {
                if (openUntilInfo && openUntilInfo.untilMoment) {
                    if (openUntilInfo.untilMoment.diff(currentMomentTZ, 'minutes') <= 30) {
                        return `${openUntilInfo.open ? 'Closes' : 'Opens'} ${currentMomentTZ.to(openUntilInfo.untilMoment)}`
                    }
                }
                return '';
            }
        }
    },

    initialize() {
        this.viewModel = new ViewModel({
            placeTZ: '',
            workDays: [],
            currentMoment: moment(),
            menuAllowed: false
        });

        pagePlace.showError = this.showError.bind(this);

        pagePlace.onBtnMapViewClick = () => {
            new PlaceMapWindow({ model: new PlaceModel(pagePlace.place) }).show();
        };

        pagePlace.on_server_get_place_ok = place => {
            this._setPlace(place, true);

            notificationMuteCollection.set(place.mute, {
                filter: model =>
                    model.get('place_id') == place.id
                    && _.contains(
                    [
                        NotificationMuteModel.Type.Fave,
                        NotificationMuteModel.Type.BusinessDnd
                    ],
                    model.get('type')
                    )
            });

            this._renderFooter(pagePlace.place);
        };

        pagePlace.on_server_checkin_ok = data => {
            new InfoModal({
                additionalClassName: 'green-border',
                title: "You are checked in!"
            }).show();

            this.footer.currentView.ui.btncheckin.removeClass('pending');
            this.footer.currentView.ui.btncheckin.addClass('set');
        };

        pagePlace.on_server_checkin_error = (jqXHR, textStatus, errorThrown) => {
            this.showError(jqXHR, textStatus, errorThrown);

            this.footer.currentView.ui.btncheckin.removeClass('pending');
            this.footer.currentView.ui.btncheckin.removeClass('set');
        };

        pagePlace.on_server_set_place_favorite_ok = isadd => {
            this.footer.currentView.ui.btnfavorites.removeClass('pending');
            if (isadd)
                this.footer.currentView.ui.btnfavorites.addClass('set');
            else
                this.footer.currentView.ui.btnfavorites.removeClass('set');
        };

        pagePlace.on_server_set_place_favorite_error = (isadd, jqXHR, textStatus, errorThrown) => {
            if (jqXHR
                && jqXHR.status == 403
                && jqXHR.responseJSON
                && jqXHR.responseJSON.code == 403
                && jqXHR.responseJSON.message
            ) {
                this.showError(jqXHR.responseJSON.message);
            } else {
                this.showError(jqXHR, textStatus, errorThrown);
            }

            this.footer.currentView.ui.btnfavorites.removeClass('pending');
            if (isadd)
                this.footer.currentView.ui.btnfavorites.removeClass('set');
            else
                this.footer.currentView.ui.btnfavorites.addClass('set');
        };

        pagePlace.onPhotoClick = (jqPhotoCont, event) => {
            new PlaceGalleryWindow({
                model: new PlaceModel(pagePlace.place),
                openPhotoWithId: jqPhotoCont.attr('data-id')
            })
                .show()
                .then(place => {
                    this._setPlace(place.attributes, false);
                });
        };
    },

    onRender() {
        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: 'Place information',
            rightButtons: ['notifications', 'menu']
        });
        this.listenTo(headerView, 'back:click', event => this.cancel());
        this.listenTo(headerView, 'notifications:click', () => app.controller.showNotificationsWindow());
        this.listenTo(headerView, 'menu:click', () => new LeftMenuWindow({ right: true }).show());
        this.header.show(headerView);

        let footerView = new FooterView();

        this.listenTo(footerView, 'edit:click', () => {
            new PlaceEditorWindow({ model: new PlaceModel(pagePlace.place) })
                .show()
                .then(place => {
                    if (place) {
                        const renderFooter = pagePlace.place && pagePlace.place.menu_allowed !== place.get('menu_allowed');
                        this._setPlace(place.attributes, true)
                            .then(() => {
                                if (renderFooter) {
                                    this._renderFooter(pagePlace.place);
                                }
                            })
                    }
                });
        });

        this.listenTo(footerView, 'reservations:click', () => {
            app.controller.goToPlaceReservations({
                placeId: pagePlace.place.id
            });
        });

        this.listenTo(footerView, 'checkin:click', () => {
            this.footer.currentView.ui.btncheckin.addClass('pending');
            pagePlace.server_checkin();
        });

        this.listenTo(footerView, 'newtask:click', () => {
            app.controller.goToTaskEditorPage({
                new: true,
                place: pagePlace.place,
                onSave(task_for_send, to_user, isverified) {
                    app.controller.goToPlaceTasksPage({
                        replace: true, optionalLoad: true,
                        place: pagePlace.place,
                        scrollToTask: task_for_send.id,
                        addMessage: task_for_send,
                        addUser: to_user,
                        includeUnverified: !isverified
                    });
                }
            });
        });

        this.listenTo(footerView, 'bookreservation:click', async () => {
            try {
                const reservationModel = await (new ReservationEditorWindow({
                    placeId: pagePlace.place.id,
                    workingDays: pagePlace.place.days
                }).show());

                if (!!reservationModel && _.isObject(reservationModel)) {
                    if (reservationModel.get('task_id')) {
                        app.controller.goToTaskPage({
                            taskId: reservationModel.get('task_id'),
                            includeReservation: true,
                            reload: true
                        });
                    } else {
                        app.controller.goToMyReservations();
                    }
                }
            } catch (e) {
                this.showError(e);
            }
        });

        this.listenTo(footerView, 'digitalmenu:click', () => {
            app.controller.goToMenuPage({
                placeId: pagePlace.place.id
            });
        });

        this.listenTo(footerView, 'digitalmenudashboard:click', () => {
            app.controller.goToMenuDashboard({
                placeId: pagePlace.place.id
            });
        });

        this.listenTo(footerView, 'tasks:click', () => {
            app.controller.goToPlaceTasksPage({ place: pagePlace.place });
        });

        this.listenTo(footerView, 'gallery:click', () => {
            new PlaceGalleryWindow({ model: new PlaceModel(pagePlace.place) })
                .show()
                .then(place => {
                    if (place)
                        this._setPlace(place.attributes, place.attributes.full);
                });
        });

        this.listenTo(footerView, 'favorites:click', () => {
            this.footer.currentView.ui.btnfavorites.addClass('pending');
            var isadd = !this.footer.currentView.ui.btnfavorites.hasClass('set');
            pagePlace.server_set_place_favorite(isadd);
            pagePlace.place.favorite = isadd;
            this.ui.name.toggleClass('favorite', pagePlace.place.favorite);
            this._renderFooter(pagePlace.place)
        });

        this.listenTo(footerView, 'report:click', () => {
            new ReportWindow().show(pagePlace.place);
        });

        this.listenTo(footerView, 'blockNotifications:click', () => {
            if (pagePlace.place) {
                new ConfirmModal({
                    title: "Block notifications?",
                    text: `<p>Do you really want to block work related notifications from ${buildPlaceLiteWidget(pagePlace.place)}?\n\n` +
                    `You won't receive notifications about <em>new tasks</em> and <em>comments</em> from this place.\n\n` +
                    `This doesn't affect <em>personal tasks</em> from employees.</p>`,
                    isHtml: true
                })
                    .show()
                    .then(confirm => {
                        if (confirm) {
                            this.showLoading(500);

                            notificationMuteCollection.create({
                                type: NotificationMuteModel.Type.BusinessDnd,
                                place_id: pagePlace.place.id
                            }, {
                                wait: true,
                                success: () => this.hideLoading(),
                                error: (model, jqXHR) => {
                                    this.hideLoading();
                                    this.showError(jqXHR);
                                }
                            });
                        }
                    });
            }
        });

        this.listenTo(footerView, 'unblockNotifications:click', () => {
            if (pagePlace.place) {
                this.showLoading(500);

                Promise.all(_.map(
                    notificationMuteCollection.filter(m =>
                        m.get('type') == NotificationMuteModel.Type.BusinessDnd
                        && m.get('place_id') == pagePlace.place.id),
                    mute => new Promise((resolve, reject) => mute.destroy({
                        success: resolve,
                        error: (model, jqXHR) => reject(jqXHR),
                        wait: true,
                    }))
                ))
                    .catch(e => {
                        this.showError(e);
                    })
                    .then(() => this.hideLoading());
            }
        });

        this.listenTo(footerView, 'blockNews:click', () => {
            if (pagePlace.place) {
                new ConfirmModal({
                    message: `Do you really want to block news from ${buildPlaceLiteWidget(pagePlace.place)}?`,
                    isHtml: true
                })
                    .show()
                    .then(confirm => {
                        if (confirm) {
                            this.showLoading(500);

                            notificationMuteCollection.create({
                                type: NotificationMuteModel.Type.Fave,
                                place_id: pagePlace.place.id
                            }, {
                                wait: true,
                                success: () => this.hideLoading(),
                                error: (model, jqXHR) => {
                                    this.hideLoading();
                                    this.showError(jqXHR);
                                }
                            });
                        }
                    });
            }
        });

        this.listenTo(footerView, 'unblockNews:click', () => {
            if (pagePlace.place) {
                this.showLoading(500);

                Promise.all(_.map(
                    notificationMuteCollection.filter(m =>
                        m.get('type') == NotificationMuteModel.Type.Fave
                        && m.get('place_id') == pagePlace.place.id),
                    mute => new Promise((resolve, reject) => mute.destroy({
                        success: resolve,
                        error: (model, jqXHR) => reject(jqXHR),
                        wait: true,
                    }))
                ))
                    .catch(e => {
                        this.showError(e);
                    })
                    .then(() => this.hideLoading());
            }
        });

        this.listenTo(notificationMuteCollection, 'add remove', model => {
            if (pagePlace.place && model.get('place_id') == pagePlace.place.id) {
                this._renderFooter(pagePlace.place);
            }
        });

        this.listenTo(notificationMuteCollection, 'reset', model => {
            this._renderFooter(pagePlace.place);
        });

        this.footer.show(footerView);

        this.$el.enhanceWithin();
    },

    show(options) {
        if (options.place) {
            this._setPlace(options.place, options.place.full).then(() => {
                if (options.openNewTask) {
                    app.controller.goToTaskEditorPage(
                        _.extend(_.isObject(options.openNewTask) ? options.openNewTask : {}, {
                            new: true,
                            place: pagePlace.place,
                            onSave(task_for_send, to_user, isverified) {
                                app.controller.goToPlaceTasksPage({
                                    replace: true, optionalLoad: true,
                                    place: pagePlace.place,
                                    scrollToTask: task_for_send.id,
                                    addMessage: task_for_send,
                                    addUser: to_user,
                                    includeUnverified: !isverified
                                });
                            }
                        })
                    );
                }
            });
        }

        return Page.prototype.show.apply(this, arguments);
    },

    onAfterShow() {
        if (this._currentMomentUpdater) {
            clearInterval(this._currentMomentUpdater);
            this._currentMomentUpdater = null;
        }

        this.viewModel.set({ currentMoment: moment() });

        this._currentMomentUpdater = setInterval(() => {
            if (this.isActive) {
                this.viewModel.set({ currentMoment: moment() });
            }
        }, 45000);
    },

    onBeforeHide() {
        if (this._currentMomentUpdater) {
            clearInterval(this._currentMomentUpdater);
            this._currentMomentUpdater = null;
        }
    },

    _setPlace(place, full) {
        this._initAsPage();

        const promise = pagePlace.setPlace(place, full, this.ui.content);

        this.viewModel.set({
            placeTZ: place.timezone,
            workDays: place.days || [],
            currentMoment: moment(),
            menuAllowed: Number(place.menu_allowed) === 1
        });

        if (place.properties) {
            this._isControllerInited().then(() => {
                this.properties.show(new PropertiesWidget({ model: new Model({ properties: place.properties }), limit: 2 }));
            })
        }

        if (LoginedUserHandler.isUserEmployee(place.id)) {
            this.el.classList.remove('usermode');
            this.el.classList.add('businessmode');
        } else {
            this.el.classList.remove('businessmode');
            this.el.classList.add('usermode');
        }

        this._renderFooter(place);

        const prettyColor = getNextPrettyColor(place.name);
        this.ui.placeCard.css('border-color', prettyColor);

        return promise;
    },

    _isControllerInited() {
        return new Promise((resolve, reject) => {
            if (app.controller.inited) {
                resolve();
            } else {
                this.listenToOnce(app.controller, 'controller:inited', () => {
                    resolve();
                });
            }
        })
    },

    _renderFooter(place) {
        let muted = false;

        if (LoginedUserHandler.isUserLogined()) {
            if (LoginedUserHandler.isUserEmployee(place.id)) {

                const userHasAnyMenuRole =
                    LoginedUserHandler.isHasRole(place.id, LoginedUserModel.Roles.ORDER_MANAGER) ||
                    LoginedUserHandler.isHasRole(place.id, LoginedUserModel.Roles.WAITER);

                this.footer.currentView.options.buttons = [{
                    id: 'newtask',
                    text: 'NEW TASK'
                }, {
                    id: 'tasks',
                    text: 'TASKS'
                }, {
                    id: 'gallery',
                    text: 'GALLERY'
                }];

                this.footer.currentView.options.moreButtons = [
                    {
                        id: 'edit',
                        text: 'EDIT'
                    },
                    {
                        id: 'reservations',
                        text: 'PLACE RESERVATIONS'
                    },
                ];

                if (Number(place.menu_allowed) || userHasAnyMenuRole) {
                    this.footer.currentView.options.moreButtons.push(
                        {
                            id: 'digitalmenu',
                            text: 'DIGITAL MENU'
                        },
                    );
                }

                if (userHasAnyMenuRole) {
                    this.footer.currentView.options.moreButtons.push(
                        {
                            id: 'digitalmenudashboard',
                            text: 'DIGITAL MENU DASHBOARD'
                        }
                    );
                }

                if (!notificationMuteCollection.some(m =>
                    m.get('type') == NotificationMuteModel.Type.BusinessDnd &&
                    m.get('place_id') == place.id)) {
                    this.footer.currentView.options.moreButtons.push({
                        id: 'blockNotifications',
                        icn: 'muteNotifications',
                        text: 'BLOCK NOTIFICATIONS',
                        class: 'crossed'
                    });
                } else {
                    this.footer.currentView.options.moreButtons.push({
                        id: 'unblockNotifications',
                        icn: 'muteNotifications',
                        text: 'UNBLOCK NOTIFICATIONS'
                    });
                    muted = true;
                }
            } else {
                this.footer.currentView.options.buttons = [{
                    id: 'checkin',
                    text: 'CHECK-IN'
                }, {
                    id: 'tasks',
                    text: 'TASKS'
                }, {
                    id: 'gallery',
                    text: 'GALLERY'
                }, {
                    id: 'favorites',
                    text: 'FAVORITE'
                }];

                const moreButtons = [];

                if (place && place.reservations_allowed) {
                    moreButtons.push({
                        id: 'bookreservation',
                        text: 'MAKE RESERVATION'
                    });
                }

                moreButtons.push({
                    id: 'newtask',
                    text: 'NEW TASK'
                });

                if (place && Number(place.menu_allowed)) {
                    moreButtons.push({
                        id: 'digitalmenu',
                        text: 'PLACE MENU'
                    });
                }

                moreButtons.push({
                    id: 'report',
                    text: 'REPORT'
                });

                this.footer.currentView.options.moreButtons = moreButtons;
            }

            if (place.favorite) {
                if (!notificationMuteCollection.some(m =>
                    m.get('type') == NotificationMuteModel.Type.Fave &&
                    m.get('place_id') == place.id)) {
                    this.footer.currentView.options.moreButtons.push({
                        id: 'blockNews',
                        icn: 'muteNotifications',
                        text: 'BLOCK NEWS',
                        class: 'crossed'
                    });
                } else {
                    this.footer.currentView.options.moreButtons.push({
                        id: 'unblockNews',
                        icn: 'muteNotifications',
                        text: 'UNBLOCK NEWS'
                    });
                    muted = true;
                }
            }

        } else {
            this.footer.currentView.options.moreButtons = [];
            this.footer.currentView.options.buttons = [{
                id: 'checkin',
                text: 'CHECK-IN'
            }, {
                id: 'tasks',
                text: 'TASKS'
            }, {
                id: 'gallery',
                text: 'GALLERY'
            }, {
                id: 'favorites',
                text: 'FAVORITE'
            }];
        }

        this.footer.currentView.render();

        if (this.footer.currentView.ui.btncheckin) {
            this.footer.currentView.ui.btncheckin.removeClass('set');
        }

        if (this.footer.currentView.ui.btnfavorites) {
            this.footer.currentView.ui.btnfavorites.toggleClass('set', !!place.favorite);
        }

        this.ui.name.toggleClass('favorite', !!place.favorite);
        this.ui.name.toggleClass('muted', !!muted);
    },

    /**
     * Кнопка This is my business
     */
    myBusiness() {
        this.ui.myBusiness.prop('disabled', true);
        Server.callServer({
            url: settings.host + settings.serv_place.mybusiness,
            type: 'POST',
            data: {
                place_id: pagePlace.place.id
            },
            success: () => {
                new InfoModal({
                    additionalClassName: 'green-border',
                    title: 'Further instructions have been sent to your email'
                })
                    .show()
                    .then(() => this.ui.myBusiness.prop('disabled', false));
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.showError(jqXHR, textStatus, errorThrown);
                this.ui.myBusiness.prop('disabled', false);
            }
        });
    },

    openDigitalMenu() {
        app.controller.goToMenuPage({
            placeId: pagePlace.place.id
        });
    },

    onMutedNotificationsMessageClick() {
        //(new NotificationSettings()).show();
    },

    _getPlaceId() {
        return pagePlace.place && pagePlace.place.id;
    }
});

var pagePlace = {
    page_id: 'page_place',
    inited: false,
    place: null,
    jqPlacePage: null,
    jqLogoThumb: null,
    jqName: null,
    jqWWW: null,
    jqPhones: null,
    jqShortDesc: null,
    jq_adr_state: null,
    jq_adr_city: null,
    jq_adr_street_name: null,
    jq_adr_street_number: null,
    jqsRatingsStats: {
        common: {
            jqRating: null,
            jqVotesCount: null
        },
        commonDetails: {
            jqRating: null,
            jqVotesCount: null
        },
        star5: {
            jqRating: null,
            jqVotesCount: null
        },
        star4: {
            jqRating: null,
            jqVotesCount: null
        },
        star3: {
            jqRating: null,
            jqVotesCount: null
        },
        star2: {
            jqRating: null,
            jqVotesCount: null
        },
        star1: {
            jqRating: null,
            jqVotesCount: null
        }
    },
    jqsSolutionsStats: {
        common: {
            jqGraph: null,
            jqTaskTotal: null,
            jqTaskSolved: null
        },
        commonDetails: {
            jqGraph: null,
            jqSolRating: null,
            jqTaskTotal: null,
            jqTaskSolved: null,
            jqVotesCount: null
        },
        idea: {
            jqGraph: null,
            jqSolRating: null,
            jqTaskTotal: null,
            jqTaskSolved: null,
            jqVotesCount: null
        },
        issue: {
            jqGraph: null,
            jqSolRating: null,
            jqTaskTotal: null,
            jqTaskSolved: null,
            jqVotesCount: null
        },
        question: {
            jqGraph: null,
            jqSolRating: null,
            jqTaskTotal: null,
            jqTaskSolved: null,
            jqVotesCount: null
        }
    },
    jqsProperties: {
        maxPropInMain: 2,
        jqProperties: null,
        jqMain: null,
        jqBtnFold: null,
        jqMore: null,
        jqMoreContent: null
    },
    jq_work_hours_cont: null,
    jqGallery: null,
    jqBtnEdit: null,

    initialize: function ($el) {
        if (this.inited)
            return;

        var that = this;

        this.jqPlacePage = $el;

        var jqContent = this.jqPlacePage.children('.content');

        var jqLogoBaseInfo = jqContent.children('.logo_base_info');
        jqLogoBaseInfo.find('.address_cont').click(function () {
            that.onBtnMapViewClick();
        });

        this.jqLogoThumb = jqLogoBaseInfo.find('.logoThumb');
        this.jqName = jqLogoBaseInfo.find('.name');
        this.jqWWW = jqLogoBaseInfo.find('.www');
        this.jqPhones = jqLogoBaseInfo.find('.phones');

        var jqAddress = jqLogoBaseInfo.find('.address');
        this.jq_adr_state = jqAddress.find('.adr_state');
        this.jq_adr_city = jqAddress.find('.adr_city');
        this.jq_adr_street_name = jqAddress.find('.adr_street_name');
        this.jq_adr_street_number = jqAddress.find('.adr_street_number');

        this.jqShortDesc = jqContent.children('.shortdesc');

        var jqRatingsSolutionsCont = jqContent.find('.ratings_solutions_cont');
        this.initRatingsSolutions(jqRatingsSolutionsCont);

        this.jqCategories = jqContent.children('.categories').first();
        this.jqCategories.b2ccategoryviewer({
            showParents: true,
            indentChildren: true
        });

        this.jq_work_hours_cont = jqContent.children('.work_hours_cont');

        this.jqGallery = jqContent.children('.gallery');

        this.inited = true;
    },

    initRatingsSolutions: function (jqRatingsSolutionsCont) {
        var jqRatingsSolutionsCommonCont = jqRatingsSolutionsCont.children('.common_cont');
        var jqDetailsCont = jqRatingsSolutionsCont.children('.details_cont');

        jqRatingsSolutionsCommonCont.find('.btn-expand').click(function (event) {
            jqDetailsCont.removeClass('hidden');
            jqRatingsSolutionsCommonCont.addClass('hidden');
        });

        jqDetailsCont.find('.btn-collapse').click(function (event) {
            jqDetailsCont.addClass('hidden');
            jqRatingsSolutionsCommonCont.removeClass('hidden');
        });

        // Rating
        var jqRatingsCommonCont = jqRatingsSolutionsCommonCont.find('.rating_cont');
        var jqRatingDetailsCont = jqDetailsCont.children('.rating_cont');
        this.initRatingsStats(jqRatingsCommonCont, jqRatingDetailsCont);

        // Solution
        var jqSolutionsCommon = jqRatingsSolutionsCommonCont.find('.solution_cont');
        var jqDetailsSolutionsCont = jqDetailsCont.children('.solution_cont');
        this.initSolutionStats(jqSolutionsCommon, jqDetailsSolutionsCont);
    },

    initRatingsStats: function (jqRatingsCommonCont, jqRatingDetailsCont) {
        var that = this;

        this.jqsRatingsStats.common.jqRating = jqRatingsCommonCont.find('.rating');
        this.jqsRatingsStats.common.jqRating.b2crating({ readOnly: true });
        this.jqsRatingsStats.common.jqVotesCount = jqRatingsCommonCont.find('.number');

        var jqRatingsSolutionsTotalCont = jqRatingDetailsCont.find('.total');
        this.jqsRatingsStats.commonDetails.jqRating = jqRatingsSolutionsTotalCont.find('.rating');
        this.jqsRatingsStats.commonDetails.jqRating.b2crating({ readOnly: true, rating: 5 });
        this.jqsRatingsStats.commonDetails.jqVotesCount = jqRatingsSolutionsTotalCont.find('.number');

        var jqRatingsSolutionsStars5Cont = jqRatingDetailsCont.find('.static_star.s5');
        this.jqsRatingsStats.star5.jqRating = jqRatingsSolutionsStars5Cont.find('.rating');
        this.jqsRatingsStats.star5.jqRating.b2crating({ readOnly: true, rating: 5 });
        this.jqsRatingsStats.star5.jqVotesCount = jqRatingsSolutionsStars5Cont.find('.number');
        jqRatingsSolutionsStars5Cont.click(function () {
            that.onRattingStatsContClick(5)
        });

        var jqRatingsSolutionsStars4Cont = jqRatingDetailsCont.find('.static_star.s4');
        this.jqsRatingsStats.star4.jqRating = jqRatingsSolutionsStars4Cont.find('.rating');
        this.jqsRatingsStats.star4.jqRating.b2crating({ readOnly: true, rating: 4 });
        this.jqsRatingsStats.star4.jqVotesCount = jqRatingsSolutionsStars4Cont.find('.number');
        jqRatingsSolutionsStars4Cont.click(function () {
            that.onRattingStatsContClick(4)
        });

        var jqRatingsSolutionsStars3Cont = jqRatingDetailsCont.find('.static_star.s3');
        this.jqsRatingsStats.star3.jqRating = jqRatingsSolutionsStars3Cont.find('.rating');
        this.jqsRatingsStats.star3.jqRating.b2crating({ readOnly: true, rating: 3 });
        this.jqsRatingsStats.star3.jqVotesCount = jqRatingsSolutionsStars3Cont.find('.number');
        jqRatingsSolutionsStars3Cont.click(function () {
            that.onRattingStatsContClick(3)
        });

        var jqRatingsSolutionsStars2Cont = jqRatingDetailsCont.find('.static_star.s2');
        this.jqsRatingsStats.star2.jqRating = jqRatingsSolutionsStars2Cont.find('.rating');
        this.jqsRatingsStats.star2.jqRating.b2crating({ readOnly: true, rating: 2 });
        this.jqsRatingsStats.star2.jqVotesCount = jqRatingsSolutionsStars2Cont.find('.number');
        jqRatingsSolutionsStars2Cont.click(function () {
            that.onRattingStatsContClick(2)
        });

        var jqRatingsSolutionsStars1Cont = jqRatingDetailsCont.find('.static_star.s1');
        this.jqsRatingsStats.star1.jqRating = jqRatingsSolutionsStars1Cont.find('.rating');
        this.jqsRatingsStats.star1.jqRating.b2crating({ readOnly: true, rating: 1 });
        this.jqsRatingsStats.star1.jqVotesCount = jqRatingsSolutionsStars1Cont.find('.number');
        jqRatingsSolutionsStars1Cont.click(function () {
            that.onRattingStatsContClick(1)
        });
    },

    initSolutionStats: function (jqSolutionsCommon, jqDetailsSolutionsCont) {
        var that = this;

        this.jqsSolutionsStats.common.jqGraph = jqSolutionsCommon.find('.sol').slider();
        this.jqsSolutionsStats.common.jqTaskSolved = jqSolutionsCommon.find('.num_solved');
        this.jqsSolutionsStats.common.jqTaskTotal = jqSolutionsCommon.find('.num_total');

        var jqCommonCont = jqDetailsSolutionsCont.find('.total');
        this.jqsSolutionsStats.commonDetails.jqGraph = jqCommonCont.find('.sol').slider();
        this.jqsSolutionsStats.commonDetails.jqTaskSolved = jqCommonCont.find('.num_solved');
        this.jqsSolutionsStats.commonDetails.jqTaskTotal = jqCommonCont.find('.num_total');
        this.jqsSolutionsStats.commonDetails.jqSolRating = jqCommonCont.find('.rating').b2crating({ readOnly: true });
        this.jqsSolutionsStats.commonDetails.jqVotesCount = jqCommonCont.find('.number');
        jqCommonCont.click(function () {
            that.onSolRattingStatsContClick(1, 1, 1)
        });

        var jqIdeasCont = jqDetailsSolutionsCont.find('.idea');
        this.jqsSolutionsStats.idea.jqGraph = jqIdeasCont.find('.sol').slider();
        this.jqsSolutionsStats.idea.jqTaskSolved = jqIdeasCont.find('.num_solved');
        this.jqsSolutionsStats.idea.jqTaskTotal = jqIdeasCont.find('.num_total');
        this.jqsSolutionsStats.idea.jqSolRating = jqIdeasCont.find('.rating').b2crating({ readOnly: true });
        this.jqsSolutionsStats.idea.jqVotesCount = jqIdeasCont.find('.number');
        jqIdeasCont.click(function () {
            that.onSolRattingStatsContClick(1, 0, 0)
        });

        var jqIssueCont = jqDetailsSolutionsCont.find('.issue');
        this.jqsSolutionsStats.issue.jqGraph = jqIssueCont.find('.sol').slider();
        this.jqsSolutionsStats.issue.jqTaskSolved = jqIssueCont.find('.num_solved');
        this.jqsSolutionsStats.issue.jqTaskTotal = jqIssueCont.find('.num_total');
        this.jqsSolutionsStats.issue.jqSolRating = jqIssueCont.find('.rating').b2crating({ readOnly: true });
        this.jqsSolutionsStats.issue.jqVotesCount = jqIssueCont.find('.number');
        jqIssueCont.click(function () {
            that.onSolRattingStatsContClick(0, 1, 0)
        });

        var jqQuestionCont = jqDetailsSolutionsCont.find('.question');
        this.jqsSolutionsStats.question.jqGraph = jqQuestionCont.find('.sol').slider();
        this.jqsSolutionsStats.question.jqTaskSolved = jqQuestionCont.find('.num_solved');
        this.jqsSolutionsStats.question.jqTaskTotal = jqQuestionCont.find('.num_total');
        this.jqsSolutionsStats.question.jqSolRating = jqQuestionCont.find('.rating').b2crating({ readOnly: true });
        this.jqsSolutionsStats.question.jqVotesCount = jqQuestionCont.find('.number');
        jqQuestionCont.click(function () {
            that.onSolRattingStatsContClick(0, 0, 1)
        });
    },

    setPlace(place, fullMode, $el) {
        this.initialize($el);
        this.clearPage();

        if (fullMode) {
            _.extend(place, { full: true });
        }

        this.place = place;

        if (LoginedUserHandler.isUserEmployee(place.id)) {
            this.jqPlacePage.find('[data-js-mybusiness-container]').hide();
            this.jqPlacePage.addClass('businessmode');
            this.jqPlacePage.removeClass('usermode');
        } else {
            if (place.id == 1)
                this.jqPlacePage.find('[data-js-mybusiness-container]').hide();
            else
                this.jqPlacePage.find('[data-js-mybusiness-container]').show();
            this.jqPlacePage.removeClass('businessmode');
            this.jqPlacePage.addClass('usermode');
        }

        var logosrc = place.logoThumb || B2CPlace.const.default_thumb;
        this.jqLogoThumb.attr('src', logosrc);

        this.jqName.text(place.name);
        this.jqWWW.text((place.www || '').replace(/^http\:\/\//, '').replace(/\/$/, ''));
        this.jqShortDesc.text(place.shortdesc == null ? '' : place.shortdesc);
        if (place.adr_state != place.adr_city)
            this.jq_adr_state.text(place.adr_state);
        this.jq_adr_city.text(`${place.adr_city || ''}${(place.adr_city && (place.adr_state != place.adr_city && place.adr_state)) ? ', ' : ''}`);
        this.jq_adr_street_number.text(`${place.adr_street_number || ''}${(place.adr_street_number && (place.adr_state || place.adr_city)) ? ', ' : ''}`);
        this.jq_adr_street_name.text(`${place.adr_street_name || ''}${place.adr_street_name && (place.adr_street_number || place.adr_state || place.adr_city) ? ', ' : ''}`);

        this.setPhones(place.phone);

        this.jqsRatingsStats.common.jqRating.b2crating('set', place.rating);
        this.setRatingsStat(place.ratings);
        this.setSolutionsStat(place.solutions);
        this.setCategories(place.categories);
        this.setGallery(place.photos);
        if (place.days != null && place.days != '' && place.days.length > 0) {
            var b2c_work_hours = new B2CWorkHours({
                days: place.days,
                is_view_mode: true
            });
            b2c_work_hours.build_B2CWorkHours(this.jq_work_hours_cont);
        }

        if (!fullMode) {
            return this.server_get_place(place.id);
        } else {
            return Promise.resolve(this.place);
        }
    },

    setRatingsStat: function (ratings) {
        ratings = ratings || { t: { r: 0, v: 0 } };

        this.jqsRatingsStats.common.jqRating.b2crating('set', ratings.t.r);
        this.jqsRatingsStats.common.jqVotesCount.text(`${ratings.t.v} ${ratings.t.v == 1 ? 'Vote' : 'Votes'}`);
        this.jqsRatingsStats.commonDetails.jqRating.b2crating('set', ratings.t.r);
        this.jqsRatingsStats.commonDetails.jqVotesCount.text(ratings.t.v);
        this.jqsRatingsStats.star5.jqVotesCount.text(ratings[5]);
        this.jqsRatingsStats.star4.jqVotesCount.text(ratings[4]);
        this.jqsRatingsStats.star3.jqVotesCount.text(ratings[3]);
        this.jqsRatingsStats.star2.jqVotesCount.text(ratings[2]);
        this.jqsRatingsStats.star1.jqVotesCount.text(ratings[1]);
    },

    setPhones: function (phones) {
        if (phones == null)
            return;
        var html_phones = '';
        for (const phone of phones) {
            if (phone) {
                html_phones += `<a class="phone" href="tel:${B2Cjs.phone_getInternalFormat(phone)}">${B2Cjs.phone_getExternalFormat(phone)}</a>`;
            }
        }
        $(html_phones).appendTo(this.jqPhones);
    },

    setSolutionsStat: function (solutions) {
        if (solutions == null)
            return;

        if (solutions.t.tn > 0) {
            this.jqsSolutionsStats.common.jqGraph.attr('max', solutions.t.tn);
            this.jqsSolutionsStats.common.jqGraph.val(solutions.t.sn);
        } else {
            this.jqsSolutionsStats.common.jqGraph.attr('max', 1);
            this.jqsSolutionsStats.common.jqGraph.val(1);
        }
        this.jqsSolutionsStats.common.jqGraph.slider('refresh');
        this.jqsSolutionsStats.common.jqTaskSolved.text(solutions.t.sn);
        this.jqsSolutionsStats.common.jqTaskTotal.text(solutions.t.tn);

        if (solutions.t.tn > 0) {
            this.jqsSolutionsStats.commonDetails.jqGraph.attr('max', solutions.t.tn);
            this.jqsSolutionsStats.commonDetails.jqGraph.val(solutions.t.sn);
        } else {
            this.jqsSolutionsStats.commonDetails.jqGraph.attr('max', 1);
            this.jqsSolutionsStats.commonDetails.jqGraph.val(1);
        }
        this.jqsSolutionsStats.commonDetails.jqGraph.slider('refresh');
        this.jqsSolutionsStats.commonDetails.jqGraph.attr('max', solutions.t.tn);
        this.jqsSolutionsStats.commonDetails.jqGraph.val(solutions.t.sn);
        this.jqsSolutionsStats.commonDetails.jqGraph.slider('refresh');
        this.jqsSolutionsStats.commonDetails.jqTaskSolved.text(solutions.t.sn);
        this.jqsSolutionsStats.commonDetails.jqTaskTotal.text(solutions.t.tn);
        this.jqsSolutionsStats.commonDetails.jqSolRating.b2crating('set', solutions.t.r);
        this.jqsSolutionsStats.commonDetails.jqVotesCount.text(solutions.t.v);

        if (solutions.id.tn > 0) {
            this.jqsSolutionsStats.idea.jqGraph.attr('max', solutions.id.tn);
            this.jqsSolutionsStats.idea.jqGraph.val(solutions.id.sn);
        } else {
            this.jqsSolutionsStats.idea.jqGraph.attr('max', 1);
            this.jqsSolutionsStats.idea.jqGraph.val(1);
        }
        this.jqsSolutionsStats.idea.jqGraph.slider('refresh');
        this.jqsSolutionsStats.idea.jqTaskSolved.text(solutions.id.sn);
        this.jqsSolutionsStats.idea.jqTaskTotal.text(solutions.id.tn);
        this.jqsSolutionsStats.idea.jqSolRating.b2crating('set', solutions.id.r);
        this.jqsSolutionsStats.idea.jqVotesCount.text(solutions.id.v);

        if (solutions.is.tn > 0) {
            this.jqsSolutionsStats.issue.jqGraph.attr('max', solutions.is.tn);
            this.jqsSolutionsStats.issue.jqGraph.val(solutions.is.sn);
        } else {
            this.jqsSolutionsStats.issue.jqGraph.attr('max', 1);
            this.jqsSolutionsStats.issue.jqGraph.val(1);
        }
        this.jqsSolutionsStats.issue.jqGraph.slider('refresh');
        this.jqsSolutionsStats.issue.jqTaskSolved.text(solutions.is.sn);
        this.jqsSolutionsStats.issue.jqTaskTotal.text(solutions.is.tn);
        this.jqsSolutionsStats.issue.jqSolRating.b2crating('set', solutions.is.r);
        this.jqsSolutionsStats.issue.jqVotesCount.text(solutions.is.v);

        if (solutions.q.tn > 0) {
            this.jqsSolutionsStats.question.jqGraph.attr('max', solutions.q.tn);
            this.jqsSolutionsStats.question.jqGraph.val(solutions.q.sn);
        } else {
            this.jqsSolutionsStats.question.jqGraph.attr('max', 1);
            this.jqsSolutionsStats.question.jqGraph.val(1);
        }
        this.jqsSolutionsStats.question.jqGraph.slider('refresh');
        this.jqsSolutionsStats.question.jqTaskSolved.text(solutions.q.sn);
        this.jqsSolutionsStats.question.jqTaskTotal.text(solutions.q.tn);
        this.jqsSolutionsStats.question.jqSolRating.b2crating('set', solutions.q.r);
        this.jqsSolutionsStats.question.jqVotesCount.text(solutions.q.v);
    },

    setCategories: function (categories) {
        if (categories == null)
            return;
        var categoriesIds = [];
        for (var i = 0; i < categories.length; i++) {
            categoriesIds.push(categories[i].id);
        }

        var categoryBase = new CategoryBase();
        var cats = categoryBase.getCatsByIds(categoriesIds);

        this.jqCategories.b2ccategoryviewer('set', cats);
        this.jqCategories.children('.b2c_cat').each((index, el) => el.style['border-color'] = getNextPrettyColor($(el).find('.b2c_cat_name').text()));
    },

    setGallery: function (photos) {
        if (!photos || !photos.business || !photos.business.length)
            return;

        if (photos.business.length == 1) {
            this.jqGallery.addClass("single");
            let $photo = $(`<img src="${encodeURI(photos.business[0].thumb)}"/>`);
            $photo.click(event => this.onPhotoClick($photo, event));
            this.jqGallery.append($photo);
        } else {
            let photosWithThumbs = _(photos.business).filter(p => p.thumb && p.thumb_w && p.thumb_h);

            if (photosWithThumbs.length >= 2) {
                this.jqGallery.addClass("double-justified");

                let lp = photosWithThumbs[0],
                    rp = photosWithThumbs[1],
                    marginPercent = 4,
                    lAr = lp.thumb_w / lp.thumb_h,
                    rAr = rp.thumb_w / rp.thumb_h;

                let $photoLeft = $(`<img src="${encodeURI(lp.thumb)}" data-id="${quoteattr(lp.id)}" style="width:${quoteattr(lAr / (lAr + rAr) * (100 - marginPercent))}%"/>`);
                $photoLeft.click(event => this.onPhotoClick($photoLeft, event));
                this.jqGallery.append($photoLeft);

                let $photoRight = $(`<img src="${encodeURI(rp.thumb)}" data-id="${quoteattr(rp.id)}" style="width:${quoteattr(rAr / (lAr + rAr) * (100 - marginPercent))}%"/>`);
                $photoRight.click(event => this.onPhotoClick($photoRight, event));
                this.jqGallery.append($photoRight);
            } else {
                this.jqGallery.addClass("double");

                let $photoLeft = $(`<img src="${encodeURI(photos.business[0].thumb)}" data-id="${quoteattr(photos.business[0].id)}"/>`);
                $photoLeft.click(event => this.onPhotoClick($photoLeft, event));
                this.jqGallery.append($photoLeft);

                let $photoRight = $(`<img src="${encodeURI(photos.business[1].thumb)}" data-id="${quoteattr(photos.business[1].id)}"/>`);
                $photoRight.click(event => this.onPhotoClick($photoRight, event));
                this.jqGallery.append($photoRight);
            }
        }

        var jqPhotoContS = this.jqGallery.find('.photo_cont');
        for (var i = 0; i < jqPhotoContS.length && i < photos.business.length; i++) {
            var jqPhotoCont = $(jqPhotoContS[i]);
            var jqImg = jqPhotoCont.find('.photo');
            var photo = photos.business[i];
            jqPhotoCont.attr('data-id', photo.id);
            jqImg.attr('src', photo.thumb);
            jqPhotoCont.removeClass('hidden');
        }
    },

    server_get_place: function (id) {
        return new Promise((resolve, reject) => {
            try {
                const loginedUser = LoginedUserHandler.getLoginedUser();
                let anonym = true,
                    unverified = true;

                if (loginedUser) {
                    anonym = loginedUser.settings.viewanonym || loginedUser.settings.viewanonym == 1;
                    unverified = loginedUser.settings.viewunverified || loginedUser.settings.viewunverified == 1;
                }

                B2CPlace.server_get(
                    {
                        id: id,
                        anonym: anonym,
                        unverified: unverified,
                        limphotosb: 999,
                        limphotosu: 999,
                    },
                    place => {
                        this.on_server_get_place_ok(place);
                        resolve(this.place);
                    },
                    (jqXHR, textStatus, errorThrown) => reject(new AjaxError(jqXHR, textStatus, errorThrown))
                );
            } catch (e) {
                reject(e);
            }
        });
    },

    server_set_place_favorite: function (isadd) {
        Server.callServer({
            url: settings.host + settings.serv_place.favorite,
            type: "POST",
            data: {
                id: this.place.id,
                isadd: isadd
            },
            success: data => this.on_server_set_place_favorite_ok(isadd, data),
            error: (jqXHR, textStatus, errorThrown) => this.on_server_set_place_favorite_error(isadd, jqXHR, textStatus, errorThrown)
        });
    },

    server_checkin: function () {
        var currUserPos = geo.getCurrentPosition();

        Server.callServer({
            url: settings.host + settings.serv_place.checkin,
            type: "POST",
            data: {
                place_id: this.place.id,
                latitude: currUserPos.lt,
                longitude: currUserPos.lg,
            },
            success: this.on_server_checkin_ok.bind(this),
            error: this.on_server_checkin_error.bind(this)
        });
    },

    clearPage: function () {
        this.jqLogoThumb.attr('src', this.jqLogoThumb.attr('data-unsetsrc'));
        this.jqName.text('');
        this.jqWWW.text('');
        this.jqShortDesc.text('');
        this.jq_adr_state.text('');
        this.jq_adr_city.text('');
        this.jq_adr_street_name.text('');
        this.jq_adr_street_number.text('');
        this.jqPhones.find('.phone').remove();

        //this.jqCategories.children().remove();
        this.jqCategories.b2ccategoryviewer('set', []);

        this.jq_work_hours_cont.children('.' + B2CWorkHours.const._className).remove();

        this.clearRatings();
        this.clearSolutionsStats();
        this.clearGallery();
    },

    clearRatings: function () {
        this.jqsRatingsStats.common.jqRating.b2crating('set', 0);
        this.jqsRatingsStats.common.jqVotesCount.text('0');
        this.jqsRatingsStats.commonDetails.jqRating.b2crating('set', 0);
        this.jqsRatingsStats.commonDetails.jqVotesCount.text('0');
        this.jqsRatingsStats.star5.jqVotesCount.text('0');
        this.jqsRatingsStats.star4.jqVotesCount.text('0');
        this.jqsRatingsStats.star3.jqVotesCount.text('0');
        this.jqsRatingsStats.star2.jqVotesCount.text('0');
        this.jqsRatingsStats.star1.jqVotesCount.text('0');
    },

    clearSolutionsStats: function () {
        this.jqsSolutionsStats.common.jqGraph.attr('max', 0);
        this.jqsSolutionsStats.common.jqGraph.val(0);
        this.jqsSolutionsStats.common.jqGraph.slider('refresh');
        this.jqsSolutionsStats.common.jqTaskSolved.text(0);
        this.jqsSolutionsStats.common.jqTaskTotal.text(0);

        this.jqsSolutionsStats.commonDetails.jqGraph.attr('max', 0);
        this.jqsSolutionsStats.commonDetails.jqGraph.val(0);
        this.jqsSolutionsStats.commonDetails.jqGraph.slider('refresh');
        this.jqsSolutionsStats.commonDetails.jqTaskSolved.text(0);
        this.jqsSolutionsStats.commonDetails.jqTaskTotal.text(0);
        this.jqsSolutionsStats.commonDetails.jqSolRating.b2crating('set', 0);
        this.jqsSolutionsStats.commonDetails.jqVotesCount.text(0);

        this.jqsSolutionsStats.idea.jqGraph.attr('max', 0);
        this.jqsSolutionsStats.idea.jqGraph.val(0);
        this.jqsSolutionsStats.idea.jqGraph.slider('refresh');
        this.jqsSolutionsStats.idea.jqTaskSolved.text(0);
        this.jqsSolutionsStats.idea.jqTaskTotal.text(0);
        this.jqsSolutionsStats.idea.jqSolRating.b2crating('set', 0);
        this.jqsSolutionsStats.idea.jqVotesCount.text(0);

        this.jqsSolutionsStats.issue.jqGraph.attr('max', 0);
        this.jqsSolutionsStats.issue.jqGraph.val(0);
        this.jqsSolutionsStats.issue.jqGraph.slider('refresh');
        this.jqsSolutionsStats.issue.jqTaskSolved.text(0);
        this.jqsSolutionsStats.issue.jqTaskTotal.text(0);
        this.jqsSolutionsStats.issue.jqSolRating.b2crating('set', 0);
        this.jqsSolutionsStats.issue.jqVotesCount.text(0);

        this.jqsSolutionsStats.question.jqGraph.attr('max', 0);
        this.jqsSolutionsStats.question.jqGraph.val(0);
        this.jqsSolutionsStats.question.jqGraph.slider('refresh');
        this.jqsSolutionsStats.question.jqTaskSolved.text(0);
        this.jqsSolutionsStats.question.jqTaskTotal.text(0);
        this.jqsSolutionsStats.question.jqSolRating.b2crating('set', 0);
        this.jqsSolutionsStats.question.jqVotesCount.text(0);
    },

    clearGallery: function () {
        this.jqGallery.empty();

        this.jqGallery.removeClass("single");
        this.jqGallery.removeClass("double");
        this.jqGallery.removeClass("multiple");
    },

    onRattingStatsContClick: function (stars) {
        var filter = { rating: stars };

        app.controller.goToPlaceTasksPage({
            place: this.place,
            filter
        });
    },

    onSolRattingStatsContClick: function (idea, issue, question) {
        var filter = {
            idea: idea,
            issue: issue,
            question: question,
        };

        app.controller.goToPlaceTasksPage({
            place: this.place,
            filter
        });
    }
}

export default Place;
