import { Required, ItemView, ViewModel } from '@b2cmessenger/backbone';

//@ts-ignore
import autosize from 'autosize';

import template from './ReservationAccept.jade';
import './ReservationAccept.scss';
import ReservationModel from 'models/ReservationModel';
import AjaxError from 'utils/AjaxError';

// @ts-ignore
const options = ItemView.options;
// @ts-ignore
const properties = ItemView.properties;

@options({
    model: Required,
})
@properties({
    className: 'widget reservation-accept-widget',
    template,

    ui: {
        message: '[data-js-message]',
    },

    bindings: {
        '@ui.message': 'value:message,disabled:disabled',
    },
})
class ReservationAcceptView extends ItemView {
    initialize() {
        this.viewModel = new ViewModel({
            message: '',
        });
    }

    accept() {
        const message = this.viewModel.get('message');

        return new Promise((resolve, reject) => {
            this.viewModel.set({ disabled: true });
            this.model.save({
                status: ReservationModel.Status.Confirmed
            }, {
                message,
                wait: true,
                success: () => {
                    this.viewModel.set({ disabled: false });
                    resolve(this.model);
                },
                error: (model, jqXHR, options) => {
                    this.viewModel.set({ disabled: false });

                    if (jqXHR.status == 422 && _.isObject(this.model.validationError) &&
                        this.model.validationError['_general']) {
                        reject(new AjaxError(this.model.validationError['_general']));
                    } else {
                        reject(new AjaxError(jqXHR));
                    }
                }
            })
        });
    }

    onAttach(view) {
        _.defer(() => autosize(this.ui.message));
    }
}

export default ReservationAcceptView;
