import React from 'react';

import MenuCategories from 'components/menu/MenuCategories/MenuCategories';
import MenuEditableCategories from 'components/menu/MenuCategories/MenuEditableCategories';
import './MenuRoot.scss';
import MenuEditableItems from 'components/menu/MenuItems/MenuEditableItems';
import { UnlistedCategory } from 'utils/api/menu';
import { MenuListEmptyItem, MenuListType } from 'components/menu/MenuList/MenuList';
import MenuSortable from 'components/menu/MenuSortable/MenuSortable';
import Spinner from 'components/UI/Spinner/Spinner';
import MenuItemError from 'components/menu/MenuItemDetail/MenuItemError';
import Scrollparent from 'scrollparent';
import MenuItemsWithControl from '../MenuItems/MenuItemsWithControl';

const MenuRoot = (props) => {
    const rootRef = React.createRef();

    const {
        categories,
        items,
        selectedCategory,
        loading,
        editable,
        sortable,
        canBeEditable,
        error = null,
        isErrorCritical = false,
        onSelectCategory,
        onRemoveCategory,
        onAddCategory,
        onEditCategory,
        onSelectItem,
        onAddItem,
        onRemoveItem = _.noop,
        onEditItem,
        onToggleVisibility,
        onDisplayMenuPreferences = _.noop,
        onChangeOrderSave,
        itemsCountMap,
        onAddToCart,
        onRemoveFromCart,
    } = props;

    const selectedCategoryIsAddition = React.useMemo(
        () => (selectedCategory === null ? 0 : selectedCategory.is_addition),
        [selectedCategory]
    );

    React.useEffect(() => {
        const sp = rootRef.current && Scrollparent(rootRef.current);
        sp && sp.scrollTo(0, 0);
    }, [selectedCategory]);

    let rootContent = <Spinner />;

    if (!loading && error !== null) {
        rootContent = (
            <MenuItemError
                errorText={error}
                buttonText="Go to Menu index"
                displayRetryButton={!isErrorCritical}
                fetchData={() => onSelectCategory(null, null)}
            />
        );
    } else if (!loading) {
        const addButtons = canBeEditable ? (
            <div className="buttons">
                {(selectedCategory === null || selectedCategory.id !== UnlistedCategory.id) && (
                    <button
                        className="btn-medium ion-ios-add-circle-outline add-category"
                        data-role="none"
                        onClick={onAddCategory}
                    >
                        Add category
                    </button>
                )}
                <button className="btn-medium ion-ios-add-circle-outline add-item" data-role="none" onClick={onAddItem}>
                    Add item
                </button>
            </div>
        ) : null;

        const configurationButtons =
            editable && selectedCategory === null ? (
                <div className="buttons">
                    <button className="btn-medium ion-ios-settings" data-role="none" onClick={onDisplayMenuPreferences}>
                        Edit menu preferences
                    </button>
                </div>
            ) : null;

        const emptyItemsContent = selectedCategory &&
            selectedCategory.id !== UnlistedCategory.id &&
            items &&
            items.length === 0 &&
            categories &&
            categories.length === 0 && <MenuListEmptyItem type={MenuListType.Items} />;

        switch (true) {
            case sortable:
                rootContent = (
                    <MenuSortable
                        categories={categories}
                        onSelectCategory={_.noop}
                        onChangeOrderSave={onChangeOrderSave}
                        items={items}
                        additions={selectedCategoryIsAddition}
                    />
                );
                break;
            case editable:
                rootContent = (
                    <>
                        <MenuEditableCategories
                            categories={categories}
                            onSelectCategory={onSelectCategory}
                            onRemoveCategory={onRemoveCategory}
                            onEditCategory={onEditCategory}
                            onToggleVisibility={onToggleVisibility}
                        />
                        <MenuEditableItems
                            items={items}
                            additions={selectedCategoryIsAddition}
                            onSelectItem={onSelectItem}
                            onRemoveItem={(id, item) => onRemoveItem.call(null, id, item, selectedCategory)}
                            onEditItem={onEditItem}
                            emptyContent={emptyItemsContent}
                            onToggleVisibility={onToggleVisibility}
                        />
                        {addButtons}
                        {configurationButtons}
                    </>
                );
                break;
            default:
                rootContent = (
                    <>
                        <MenuCategories categories={categories} onSelectCategory={onSelectCategory} />
                        <MenuItemsWithControl
                            items={items}
                            additions={selectedCategoryIsAddition}
                            onSelectItem={onSelectItem}
                            emptyContent={emptyItemsContent}
                            itemsCountMap={itemsCountMap}
                            onIncrease={onAddToCart}
                            onDecrease={onRemoveFromCart}
                            displayMenuHiddenVisibility
                        />
                        {addButtons}
                    </>
                );
        }
    }

    return (
        <div className="menu-root" ref={rootRef}>
            {rootContent}
        </div>
    );
};

export default MenuRoot;
