import { byId, getUpdatedAtTimeStamp, isThereNeedToUpdateItem } from "store/utils";
import {
    LOAD_CATEGORY_SUCCEEDED,
    UPLOAD_MENU_ITEM_SUCCESS,

    LOAD_MENU_ITEM_DETAIL_SUCCEEDED,
    UPSERT_ITEM,
    REMOVE_ITEM,
    INIT_MENU_SUCCEEDED,
    INIT_MENU,
    UPSERT_ITEMS,

    LOAD_MENU_DETAILED_ITEMS,
    LOAD_MENU_DETAILED_ITEMS_SUCCEEDED,
    LOAD_MENU_DETAILED_ITEMS_FAILED, RESET_ITEMS, SAVE_MENU_INFO_SUCCEEDED,
} from '../actions';

const initialState = {
    values: {},
    updatedAt: {},
    loadingItems: false,
    loadingItemsError: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case INIT_MENU:
            return {
                ...state, values: {}, updatedAt: {}
            };

        case INIT_MENU_SUCCEEDED:
        case LOAD_CATEGORY_SUCCEEDED:
        case UPSERT_ITEMS: {
            const values = { ...state.values };
            const updatedAt = { ...state.updatedAt };

            for (const item of action.payload.items) {
                if (isThereNeedToUpdateItem(values[byId(item)], item)) {
                    values[byId(item)] = values[byId(item)] ? { ...values[byId(item)], ...item } : { ...item };
                    updatedAt[byId(item)] = getUpdatedAtTimeStamp(updatedAt[byId(item)]);
                }
            }

            return { ...state, values, updatedAt };
        }

        case LOAD_MENU_ITEM_DETAIL_SUCCEEDED:
        case UPLOAD_MENU_ITEM_SUCCESS:
        case UPSERT_ITEM: {
            const { item } = action.payload;
            const id = byId(item);

            return {
                ...state,
                values: { ...state.values, [id]: item },
                updatedAt: {
                    ...state.updatedAt,
                    [id]: getUpdatedAtTimeStamp(state.updatedAt[id])
                }
            };
        }

        case REMOVE_ITEM: {
            const values = { ...state.values };
            const updatedAt = { ...state.updatedAt };

            delete values[action.payload.id];
            delete updatedAt[action.payload.id];

            return { ...state, values, updatedAt };
        }

        case LOAD_MENU_DETAILED_ITEMS: {
            return {
                ...state,
                loadingItems: true,
                loadingItemsError: null
            };
        }

        case LOAD_MENU_DETAILED_ITEMS_SUCCEEDED: {
            const values = { ...state.values };
            const updatedAt = { ...state.updatedAt };

            for (const item of action.payload.items) {
                values[byId(item)] = { ...item };
                updatedAt[byId(item)] = getUpdatedAtTimeStamp(updatedAt[byId(item)]);
            }

            return { ...state, values, updatedAt,
                loadingItems: false, loadingItemsError: null  };
        }

        case LOAD_MENU_DETAILED_ITEMS_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                loadingItems: false,
                loadingItemsError: error
            };
        }

        case SAVE_MENU_INFO_SUCCEEDED: {
            const { currency } = action.payload;

            const values = { ...state.values };
            for (const key in values) {
                values[key].currency = currency;
            }

            return {
                ...state,
                values
            }
        }

        case RESET_ITEMS: {
            const values = { ...initialState.values };
            const updatedAt = { ...initialState.updatedAt };
            const { items = [] } = action.payload;

            for (const item of items) {
                values[byId(item)] = { ...item };
                updatedAt[byId(item)] = getUpdatedAtTimeStamp(updatedAt[byId(item)]);
            }

            return { ...initialState, values, updatedAt };
        }

        default:
            return state
    }
}
