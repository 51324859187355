import React from 'react';

import './PriceInput.scss';
import { getPriceIconAndPosition } from 'components/UI/Price/priceIconHelpers';
import NumericInput from 'components/UI/NumericInput/NumericInput';

const COEFFICIENT = 100;

const defaultPriceConversionToDisplay = (value) => {
    return _.isNaN(parseFloat(value)) ? '' : parseFloat(value) / COEFFICIENT;
};
const defaultPriceConversionToInput = (value) => {
    let val = _.isNaN(parseFloat(value)) ? null : parseFloat(value) * COEFFICIENT;

    // maximum 2 decimal places
    if (val && Number(val) === val && val % 1 !== 0) {
        val = val.toFixed();
    }

    // min 0 or null
    if (!_.isNull(val) && val < 0) {
        val = 0;
    }

    return val;
};

const PriceInput = ({
    value,
    currency = '',
    onChange,
    disabled = false,
    placeholder = 'Enter amount',
    label = 'Price:',
    priceConversionFunctionToDisplay = defaultPriceConversionToDisplay,
    priceConversionFunctionToInput = defaultPriceConversionToInput,
    error,
}) => {
    const { icon, isIconBefore } = getPriceIconAndPosition(value, currency);

    return (
        <div className="price-input-group">
            <span className="price-label">{label}</span>
            <span className="price-input">
                {isIconBefore && icon}
                <NumericInput
                    {...{
                        placeholder,
                        disabled,
                        value,
                        onChange,
                        parser: priceConversionFunctionToInput,
                        displayParser: priceConversionFunctionToDisplay,
                        className: isIconBefore ? 'right' : '',
                    }}
                />
                {!isIconBefore && icon}
            </span>
            <p className="error">{error}</p>
        </div>
    );
};

export default PriceInput;
