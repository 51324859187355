import { LayoutView, Model, Required, CollectionView } from "@b2cmessenger/backbone";
import template from './ReservationDayView.jade';
import './ReservationDayView.scss';
import ReservationItem from "../../widgets/ReservationItem/ReservationItem";
import PlaceModel from 'models/PlaceModel';
import UserModel from 'models/UserModel';
import PlaceMapWindow from 'windows/PlaceMap/PlaceMap';

@LayoutView.options({
    reservationCollection: Required,
    collection: Required,
    childViewType: Required
})
@LayoutView.properties({
    template,
    className: 'day-with-reservations',

    regions: {
        container: '[data-js-reservations]'
    },

    modelEvents: {
        'change:modelIds'() {
            const ids = this.model.get('modelIds');
            this.collection.set(this.options.reservationCollection.filter(m => ids.includes(m.id)));
        }
    }
})
class ReservationsDayView extends LayoutView {
    constructor(options) {
        super(options)
    }

    onRender() {
        const contentView = new ContentCView({
            collection: this.collection,
            childViewType: this.options.childViewType,
            placeId: this.options.placeId
        });

        this.container.show(contentView);
    }

    templateHelpers() {
        return {
            dateString: new Date(+this.model.get('id')).toLocaleDateString('en-US', {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric'
                }
            )
        }
    }
}

@Model.properties({})
class DayModel extends Model {
}

@CollectionView.options({
    collection: Required,
    childViewType: Required,
})
@CollectionView.properties({
    childView: ReservationItem,
    childEvents: {
        'click'(view, model) {
            if (model.has('task_id')) {
                app.controller.goToTaskPage({
                    taskId: model.get('task_id'),
                    includeReservation: true,
                    reload: true
                });
            }
        },
        'user:click'(view, model, userModel) {
            app.controller.goToOtherUserPage({
                userId: userModel.id,
                placeId: this.options.placeId,
            });
        },
        'place:click'(view, model, placeModel) {
            app.controller.goToPlacePage({
                place: placeModel.toJSON()
            });
        },
        'map:click'(view, model, placeModel) {
            new PlaceMapWindow({ model: placeModel }).show();
        }
    },
    childViewOptions(model, i) {
        return _.extend({}, {
            type: this.options.childViewType === 'user' ? ReservationItem.Type.User : ReservationItem.Type.Business,
            placeModel: model.get('place') && new PlaceModel(model.get('place')),
            userModel: model.get('user') && new UserModel(model.get('user'))
        });
    }
})
class ContentCView extends CollectionView {
    constructor(options) {
        super(options)
    }
}

export { DayModel };
export default ReservationsDayView;
