import React, { useState, useCallback, useMemo } from 'react';
import ReactPage from 'pages/ReactPage';
import { connect } from 'react-redux';

import Page, { PageContent, PageHeader } from 'components/page/Page';
import Header from 'components/UI/Header/Header';
import { AddButton, BackButton, MenuButton, QrButton } from 'components/UI/Button/Button';
import withStore from 'components/withStore';
import Spinner from 'components/UI/Spinner/Spinner';
import { getMenuError, getMenuInfoLoading, getMenuIsEditPossible } from 'store/menu/selectors';
import { saveMenuInfo } from 'store/menu/actions';
import MenuItemError from 'components/menu/MenuItemDetail/MenuItemError';
import MenuSectionEditorWindow from 'components/window/MenuSectionEditor/MenuSectionEditor';
import * as SectionAPI from 'utils/api/sections';
import { getMenuSectionsAsArray } from 'store/menu/sections/selectors';
import MenuSections from 'components/menu/MenuSections/MenuSections';
import { removeSection, removeTable, upsertSection, upsertTable } from 'store/menu/sections/actions';
import { MenuListEmptyItem } from 'components/menu/MenuList/MenuList';
import { showMessage } from 'windows/Modal/Info';
import FileOpener from 'utils/FileOpener';
import useInitializeMenu from 'hooks/useInitializeMenu';

const MenuSectionsContent = (props) => {
    const { placeId, isEditPossible, error, loading, isActive } = props;
    const { upsertSection, showError } = props;
    const { sections } = props;

    const [qrLoading, setQrLoading] = useState(false);

    const { shouldInitMenu, readyToInitialize, initialized } = useInitializeMenu({ placeId, isActive });

    const contentReady = useMemo(() => {
        return error === null && !loading && !shouldInitMenu && initialized;
    }, [shouldInitMenu, error, loading, initialized]);

    const openSectionEditor = useCallback(
        async (options) => {
            const { section } = options;
            await new MenuSectionEditorWindow({
                section,
                saveSection: (editedSection) => {
                    return (editedSection.id
                        ? SectionAPI.updateSection({ ...editedSection, placeId })
                        : SectionAPI.createSection({ ...editedSection, placeId })
                    ).then(upsertSection);
                },
            }).show();
        },
        [placeId, showError, upsertSection]
    );

    const onClickSection = useCallback(
        ({ section }) => {
            if (section) {
                app.controller.goToMenuSection({ placeId, sectionId: section.id });
            }
        },
        [placeId]
    );

    const sectionsEmptyContent = useMemo(() => {
        return (
            <MenuListEmptyItem
                message={(
                    <span>
                        Place has no sections created yet.
                        <br />
                        {isEditPossible && <span>You can add one by clicking plus button.</span>}
                    </span>
                )}
            />
        );
    }, [isEditPossible]);
    const cordovaSaveFile = (blob, fileName, onWriteEnd) => {
        let storageLocation = '';
        switch (window.device.platform) {
            case 'Android':
                storageLocation = 'file:///storage/emulated/0/';
                break;
            case 'iOS':
                storageLocation = cordova.file.documentsDirectory;
                break;
            default:
                break;
        }

        const errorCallback = function (e) {
            console.log(`Error: ${e}`);
        };

        window.resolveLocalFileSystemURL(
            storageLocation,
            function (fileSystem) {
                fileSystem.getDirectory(
                    'Download',
                    {
                        create: true,
                        exclusive: false,
                    },
                    function (directory) {
                        // You need to put the name you would like to use for the file here.
                        directory.getFile(
                            fileName,
                            {
                                create: true,
                                exclusive: false,
                            },
                            function (fileEntry) {
                                fileEntry.createWriter(function (writer) {
                                    // eslint-disable-next-line no-param-reassign
                                    writer.onwriteend = function () {
                                        if (onWriteEnd) {
                                            onWriteEnd(fileEntry);
                                        }
                                    };
                                    writer.seek(0);
                                    writer.write(blob); // You need to put the file, blob or base64 representation here.
                                }, errorCallback);
                            },
                            errorCallback
                        );
                    },
                    errorCallback
                );
            },
            errorCallback
        );
    };

    const downloadQrCodes = useCallback(async () => {
        setQrLoading(true);
        try {
            const data = await SectionAPI.downloadQrCodes({ placeId });
            if (!data) {
                showError('An error has occurred. Try again later.');
                return;
            }
            const fileName = 'say2b_qr_codes_for_tables.pdf';
            if (window.device) {
                cordovaSaveFile(data, fileName, (fileEntry) => {
                    FileOpener.open({ path: fileEntry.toURL(), mimeType: 'application/pdf' }).catch((e) => {
                        if (e && e.status === 9) {
                            console.warn(e);

                            if (window.device.platform === 'Android') {
                                showMessage('File saved to the Download directory on your device.');
                            } else {
                                showMessage('File saved to the Documents directory on your device.');
                            }
                        }
                    });
                });
            } else {
                const anchor = $(document.createElement('a'));
                const windowUrl = window.URL || window.webkitURL;
                const blob = new Blob([data]);
                const url = windowUrl.createObjectURL(blob);
                anchor
                    .prop({
                        href: url,
                        download: fileName,
                    })
                    .get(0)
                    .click();
                setTimeout(() => {
                    windowUrl.revokeObjectURL(url);
                    anchor.remove();
                });
            }
        } catch (e) {
            showError(e);
        } finally {
            setQrLoading(false);
        }
    }, [placeId]);

    const onShow = useCallback(() => {
        if (readyToInitialize) {
            readyToInitialize(true);
        }
    }, [readyToInitialize]);

    return (
        <Page instance={props.__page__} onShow={onShow}>
            <PageHeader>
                <Header>
                    <BackButton onClick={props.goBack} />
                    <h1 className="title">Menu Sections</h1>
                    <QrButton onClick={downloadQrCodes} disabled={qrLoading} />
                    {isEditPossible && <AddButton onClick={() => openSectionEditor({ section: null })} />}
                    <MenuButton right />
                </Header>
            </PageHeader>
            <PageContent className="scrollable grow with-standard-padding">
                {contentReady && (
                    <MenuSections
                        className="menu-list-compact"
                        sections={sections}
                        emptyContent={sectionsEmptyContent}
                        onClickSection={onClickSection}
                    />
                )}
                {((!contentReady && !error) || loading) && <Spinner />}
                {qrLoading && <Spinner />}
                {error !== null && <MenuItemError errorText={error} displayRetryButton={false} />}
            </PageContent>
        </Page>
    );
};

const ConnectedMenuSectionsContent = withStore(
    connect(
        (state) => ({
            loading: getMenuInfoLoading(state),
            isEditPossible: getMenuIsEditPossible(state),

            error: getMenuError(state),
            sections: getMenuSectionsAsArray(state),
        }),
        {
            saveMenuInfo,
            upsertTable,
            upsertSection,
            removeSection,
            removeTable,
        }
    )(MenuSectionsContent)
);

const MenuSectionsPage = ReactPage.extend({
    attributes: {
        id: 'page_menu_sections',
    },
    className: 'menu-sections-page',
    component: ConnectedMenuSectionsContent,
});

export default MenuSectionsPage;
