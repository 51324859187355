import { Model } from '@b2cmessenger/backbone';
import settings from 'settings';
import AjaxError from '../utils/AjaxError';

import NotificationMuteModel from 'models/NotificationMuteModel';

/**@type {(properties: Model.Properties<MessageModel>) => (target: any) => typeof MessageModel} */
const properties = Model.properties;

@properties({
    defaults: {
        task_id: null,
        status: null,
        place_id: null,
        user_id: null,
        published_at: null,
        isbusiness: null,
        issolution: null,
        to_user_id: null,
        subject: null,
        text: null,
        gift: null,
        commentscount: 0,

        authorvisibility: null,
        isuserverified: null,

        task_target_id: null,
        task_dummy_id: null,

        includeUnverified: false,
        parentMessageModel: null,
        notificationMuteCollection: null,
        notificationMuteModel: null
    },

    computeds: {
        task_id: {
            deps: ['_task_id'],
            get: _task_id => _task_id,
            set(val) {
                if (val != this.attributes._task_id) {
                    const oldVal = this.attributes._task_id;
                    this.attributes._task_id = val;
                    this.set({ notificationMuteCollection: this.attributes._notificationMuteCollection });
                    this.attributes._task_id = oldVal;
                }
                return { _task_id: val };
            }
        },
        publishedAt: {
            deps: ['published_at'],
            get: published_at => B2Cjs.datetimeServerToJS(published_at)
        },
        isWithGift: {
            deps: ['gift', 'isBroadcast', 'gift_template'],
            get: (gift, isBroadcast, gift_template) => !!gift || isBroadcast && !!gift_template
        },
        isFromBussines: {
            deps: ['isbusiness'],
            get: (isbusiness) => isbusiness == 1,
            set: (val) => ({ isbusiness: val ? 1 : 0 })
        },
        isAuthorAnonym: {
            deps: ['authorvisibility'],
            get: authorvisibility => !(authorvisibility == 1),
            set: val => ({ authorvisibility: val ? 0 : 1 })
        },
        isAuthorVerified: {
            deps: ['isuserverified'],
            get: isuserverified => isuserverified == 1
        },
        visibleUserId: {
            deps: ['user_id', 'isAuthorAnonym'],
            get: (user_id, isAuthorAnonym) => !isAuthorAnonym && user_id || 0
        },
        rating: {
            deps: ['_rating', 'isbusiness'],
            get: (_rating, isbusiness) => isbusiness != 1 && _rating || 0,
            set: val => ({ _rating: Math.max(0, Number(val) || 0) })
        },
        likes: {
            deps: ['likes_v', 'likes_u', 'includeUnverified'],
            get: (likes_v, likes_u, includeUnverified) =>
                Math.max(0, Number(includeUnverified ? likes_u : likes_v)) || 0,
            set(val) {
                if (this.get('includeUnverified')) {
                    return { likes_u: Math.max(0, Number(val) || 0) };
                } else {
                    return { likes_v: Math.max(0, Number(val) || 0) };
                }
            }
        },
        dislikes: {
            deps: ['dislikes_v', 'dislikes_u', 'includeUnverified'],
            get: (dislikes_v, dislikes_u, includeUnverified) =>
                Math.max(0, Number(includeUnverified ? dislikes_u : dislikes_v)) || 0,
            set(val) {
                if (this.get('includeUnverified')) {
                    return { dislikes_u: Math.max(0, Number(val) || 0) };
                } else {
                    return { dislikes_v: Math.max(0, Number(val) || 0) };
                }
            }
        },
        commentsCount: {
            deps: ['commentscount'],
            get: commentscount => Number(commentscount) || 0,
            set: val => ({ commentscount: Math.max(0, Number(val) || 0) })
        },
        parentTaskModel: {
            deps: ['isTask', 'parentMessageModel', 'task_id'],
            get(isTask, parentMessageModel, task_id) {
                if (isTask) {
                    return this;
                } else {
                    if (parentMessageModel) {
                        if (parentMessageModel.id == task_id) {
                            return parentMessageModel;
                        } else {
                            return parentMessageModel.get('parentTaskModel');
                        }
                    }
                    return null;
                }
            }
        },
        userLiked: {
            deps: ['curuserlike'],
            get: curuserlike => curuserlike > 0
        },
        userDisliked: {
            deps: ['curuserlike'],
            get: curuserlike => curuserlike < 0
        },
        isTask: {
            deps: ['task_id'],
            get: (task_id) => !task_id
        },
        isIdea: {
            deps: ['isTask', 'idea'],
            get: (isTask, idea) => isTask && idea == 1
        },
        isIssue: {
            deps: ['isTask', 'issue'],
            get: (isTask, issue) => isTask && issue == 1
        },
        isQuestion: {
            deps: ['isTask', 'question'],
            get: (isTask, question) => isTask && question == 1
        },
        hasSolution: {
            deps: ['isTask', 'hassolution'],
            get: (isTask, hassolution) => isTask && hassolution == 1,
            set: (val) => ({ hassolution: val ? 1 : 0 })
        },
        isBroadcast: {
            deps: ['isTask', 'task_target_id', 'task_dummy_id'],
            get: (isTask, task_target_id, task_dummy_id) => isTask && task_target_id && !task_dummy_id
        },
        isComment: {
            deps: ['isTask'],
            get: isTask => !isTask
        },
        reservationChange: {
            deps: ['additional_data'],
            get: additionalData => !!additionalData && additionalData.find(i => i.type === 'reservation_change')
        },
        isReservation: {
            deps: ['isTask', 'reservation'],
            get: (isTask, reservation) => isTask && !!reservation
        },
        isReservationChangeComment: {
            deps: ['isComment', 'reservationChange'],
            get: (isComment, reservationChange) => {
                return isComment && !!reservationChange;
            }
        },
        isCorruptedReservationTask: {
            deps: ['isTask', 'reservation_id', 'reservation'],
            get: (isTask, reservation_id, reservation) => isTask && Number(reservation_id) && !reservation
        },
        isSolution: {
            deps: ['isComment', 'issolution'],
            get: (isComment, issolution) => isComment && issolution == 1,
            set: (val) => ({ issolution: val ? 1 : 0 })
        },
        solutionRating: {
            deps: ['isSolution', 'solutionvotes', 'includeUnverified'],
            get(isSolution, solutionvotes, includeUnverified) {
                if (isSolution && solutionvotes) {
                    if (includeUnverified && _.has(solutionvotes, 'rating_u')) {
                        return Math.max(0, Number(solutionvotes.rating_u) || 0);
                    } else if (!includeUnverified && _.has(solutionvotes, 'rating_v')) {
                        return Math.max(0, Number(solutionvotes.rating_v) || 0);
                    }
                }

                return 0;
            }
        },
        solutionVotes: {
            deps: ['isSolution', 'solutionvotes', 'includeUnverified'],
            get(isSolution, solutionvotes, includeUnverified) {
                if (isSolution && solutionvotes) {
                    if (includeUnverified && _.has(solutionvotes, 'votes_u')) {
                        return Math.max(0, Number(solutionvotes.votes_u) || 0);
                    } else if (!includeUnverified && _.has(solutionvotes, 'votes_v')) {
                        return Math.max(0, Number(solutionvotes.votes_v) || 0);
                    }
                }

                return 0;
            }
        },
        taskAuthorSolutionRating: {
            deps: ['isSolution', 'solutionvotes'],
            get: (isSolution, solutionvotes) =>
                isSolution && solutionvotes && Math.max(0, Number(solutionvotes.author_rating)) || null
        },
        userSolutionRating: {
            deps: ['isSolution', 'solutionvotes'],
            get: (isSolution, solutionvotes) =>
                isSolution && solutionvotes && Math.max(0, Number(solutionvotes.cur_user_rating)) || null
        },
        notificationMuteCollection: {
            deps: ['isTask', '_notificationMuteCollection'],
            get: (isTask, _notificationMuteCollection) => isTask && _notificationMuteCollection || null,
            set(collection) {
                const oldCollection = this.attributes._notificationMuteCollection;
                if (oldCollection) {
                    this.stopListening(oldCollection);
                }

                if (collection && !this.attributes._task_id) {
                    this.listenTo(collection, 'add', model => {
                        if (model.get('type') == NotificationMuteModel.Type.Task && model.get('task_id') == this.id) {
                            this.set({ notificationMuteModel: model });
                        }
                    });

                    this.listenTo(collection, 'remove', model => {
                        if (model.get('type') == NotificationMuteModel.Type.Task && model.get('task_id') == this.id) {
                            this.set({ notificationMuteModel: null });
                        }
                    });

                    this.listenTo(collection, 'reset', (collection, options) => {
                        if (!options._messageModelOnResetCache) {
                            options._messageModelOnResetCache =
                                collection.reduce((cache, m) => {
                                    if (m.get('type') == NotificationMuteModel.Type.Task) {
                                        if (m.get('task_id') == this.id) {
                                            this.set({ notificationMuteModel: m });
                                        } else {
                                            cache[m.get('task_id')] = m;
                                        }
                                    }
                                    return cache;
                                }, {});
                        } else {
                            this.set({ notificationMuteModel: options._messageModelOnResetCache[this.id] || null });
                            delete options._messageModelOnResetCache[this.id];
                        }
                    });

                    this.set({
                        notificationMuteModel: collection.find(
                            m => m.get('type') == NotificationMuteModel.Type.Task && m.get('task_id') == this.id
                        ) || null
                    });
                } else {
                    this.set({ notificationMuteModel: null });
                }

                return { _notificationMuteCollection: collection };
            }
        },
        isMuted: {
            deps: ['notificationMuteModel'],
            get: notificationMuteModel => !!notificationMuteModel
        }
    }
})
class MessageModel extends Model {
    toJSON(options) {
        if (options.b2cmessage) {
            const data = _.omit(super.toJSON({ computed: false }), [
                '_notificationMuteCollection', '_publishedAtValueDesc', '_rating', '_task_id'
            ]);
            return _.extend(data, {
                rating: this.get('rating'),
                task_id: this.get('task_id')
            });
        } else {
            return super.toJSON(options);
        }
    }

    /** @returns {JQueryXHR} */
    sync(method, model, options) {
        if (method == 'read') {
            if (this.get('isTask')) {
                const success = options.success;
                _.extend(options, {
                    url: settings.host + settings.serv_task.search + (
                        this.get('reservation_id') ? '?include_reservation=1' : ''
                    ),
                    data: {
                        place_id: this.get('place_id'),
                        ids: [this.id],
                    },
                    emulateJSON: true,
                    success: function (data) {
                        success.call(this, data.tasks[0]);
                    }
                });
            } else {
                const success = options.success;
                _.extend(options, {
                    url: settings.host + settings.serv_task.comment.search,
                    data: {
                        place_id: this.get('place_id'),
                        task_id: this.get('task_id'),
                        ids: [this.id]
                    },
                    emulateJSON: true,
                    success: function (data) {
                        success.call(this, data.comments[0]);
                    }
                });
            }
            return super.sync('create', model, options);
        } else if (method == 'create' && this.get('isComment')) {
            _.extend(options, {
                url: settings.host + settings.serv_task.comment.create,
                data: {
                    mode: options.draft ? 0 : 1,
                    status: options.draft ? 0 : 1,
                    place_id: this.get('place_id'),
                    parent_id: this.get('parent_id'),
                    task_id: this.get('task_id'),
                    depth: this.get('depth'),
                    text: this.get('text'),
                    authorvisibility: this.get('authorvisibility'),
                    issolution: this.get('issolution'),
                    addphotos: options.addphotos || []
                },
                emulateJSON: true
            });
            return super.sync('create', model, options);
        } else if (method == 'update' && this.get('isComment')) {
            _.extend(options, {
                url: settings.host + settings.serv_task.comment.change,
                data: {
                    id: this.id,
                    mode: 1,
                    status: 1,
                    place_id: this.get('place_id'),
                    parent_id: this.get('parent_id'),
                    task_id: this.get('task_id'),
                    depth: this.get('depth'),
                    text: this.get('text'),
                    authorvisibility: this.get('authorvisibility'),
                    issolution: this.get('issolution'),
                    delphotos: options.delphotos || [],
                    addphotos: options.addphotos || []
                },
                emulateJSON: true
            });
            return super.sync('create', model, options);
        } else if (method == 'delete') {
            const success = options.success;
            _.extend(options, {
                url: this.get('task_id') ?
                    settings.host + settings.serv_task.comment.delete :
                    settings.host + settings.serv_task.delete,
                data: { id: this.id },
                emulateJSON: true,
                success: function () {
                    model.attributes.isSolution = model.get('isSolution');
                    if (success) {
                        success.apply(this, arguments);
                    }
                }
            });
            return super.sync('create', model, options);
        } else {
            const err = new Error(`Sync method "${method}" is not supported`);
            if (options && options.error) {
                options.error.call(options.context, err);
            }
            //@ts-ignore
            return $.Deferred().reject(err);
        }
    }

    loadReservationIfNotExist() {
        return new Promise((resolve, reject) => {
            const reservationId = Number(this.get('reservation_id'));
            if (reservationId) {
                if (!this.has('reservation')) {
                    const url = settings.host + settings.serv_reservation.base + '/' + reservationId;
                    const self = this;
                    Server.callServer({
                        url,
                        type: "GET",
                        success(data) {
                            if (data) {
                                self.set('reservation', data);
                                resolve(data)
                            } else {
                                resolve();
                            }
                        },
                        error(jqXHR, textStatus, errorThrown) {
                            reject(new AjaxError(jqXHR, textStatus, errorThrown));
                        }
                    });
                } else {
                    resolve(this.get('reservation'));
                }
            } else {
                resolve(null);
            }
        });

    }

    muteNotifications() {
        return new Promise((resolve, reject) => {
            if (this.get('isTask')) {
                const notificationMuteCollection = this.get('notificationMuteCollection');
                if (notificationMuteCollection) {
                    notificationMuteCollection.create(
                        {
                            type: NotificationMuteModel.Type.Task,
                            task_id: this.id
                        },
                        {
                            wait: true,
                            success: resolve,
                            error(m, jqXHR) {
                                reject(new AjaxError(jqXHR))
                            }
                        }
                    );
                } else {
                    reject("cannot mute notifications: notificationMuteCollection is falsey");
                }
            } else {
                reject("cannot mute notifications: message is not a task");
            }
        });
    }

    unmuteNotifications() {
        return new Promise((resolve, reject) => {
            if (this.get('isTask')) {
                const notificationMuteModel = this.get('notificationMuteModel');
                if (notificationMuteModel) {
                    notificationMuteModel.destroy({
                        wait: true,
                        success: resolve,
                        error(m, jqXHR) {
                            reject(new AjaxError(jqXHR))
                        }
                    });
                } else {
                    reject("cannot unmute notifications: notificationMuteModel is falsey");
                }
            } else {
                reject("cannot unmute notifications: message is not a task");
            }
        });
    }

    rateSolution(rating) {
        return new Promise((resolve, reject) => {
            if (this.get('isSolution')) {
                rating = Math.max(0, Number(rating));
                if (this.get('userSolutionRating') != rating) {
                    if (this.id) {
                        const data = {
                            id: this.id
                        };
                        if (rating > 0) {
                            _.extend(data, { rating });
                        }
                        Server.callServer({
                            url: settings.host + settings.serv_task.comment.solutionrate,
                            type: "POST",
                            data,
                            success: (data) => {
                                this.set({
                                    solutionvotes: data.solutionvotes
                                });
                                resolve();
                            },
                            error(jqXHR, textStatus, errorThrown) {
                                reject(new AjaxError(jqXHR, textStatus, errorThrown));
                            }
                        });
                    } else {
                        reject('id is empty');
                    }
                } else {
                    resolve();
                }
            } else {
                reject('message is not a solution');
            }
        });
    }

    toggleLike(like) {
        if (this.get('userLiked')) {
            return this._requestLikeChange(true, false)
                .then(data => {
                    if (data) {
                        this.set(_.pick(data, 'likes_u', 'likes_v', 'dislikes_u', 'dislikes_v', 'curuserlike'));
                    } else {
                        this.set({
                            likes: this.get('_likes') - 1,
                            curuserlike: null
                        })
                    }
                });
        } else {
            return this._requestLikeChange(true, true)
                .then(data => {
                    if (data) {
                        this.set(_.pick(data, 'likes_u', 'likes_v', 'dislikes_u', 'dislikes_v', 'curuserlike'));
                    } else {
                        if (this.get('userDisliked')) {
                            this.set({
                                likes: this.get('_likes') + 1,
                                dislikes: this.get('_dislikes') - 1,
                                curuserlike: 1
                            })
                        } else {
                            this.set({
                                likes: this.get('_likes') + 1,
                                curuserlike: 1
                            })
                        }
                    }
                });
        }
    }

    toggleDislike(dislike) {
        if (this.get('userDisliked')) {
            return this._requestLikeChange(false, false)
                .then(data => {
                    if (data) {
                        this.set(_.pick(data, 'likes_u', 'likes_v', 'dislikes_u', 'dislikes_v', 'curuserlike'));
                    } else {
                        this.set({
                            dislikes: this.get('_dislikes') - 1,
                            curuserlike: null
                        });
                    }
                });
        } else {
            return this._requestLikeChange(false, true)
                .then(data => {
                    if (data) {
                        this.set(_.pick(data, 'likes_u', 'likes_v', 'dislikes_u', 'dislikes_v', 'curuserlike'));
                    } else {
                        if (this.get('userLiked')) {
                            this.set({
                                likes: this.get('_likes') - 1,
                                dislikes: this.get('_dislikes') + 1,
                                curuserlike: -1
                            })
                        } else {
                            this.set({
                                dislikes: this.get('_dislikes') + 1,
                                curuserlike: -1
                            })
                        }
                    }
                });
        }
    }

    _requestLikeChange(isLike, isIncrease) {
        return new Promise((resolve, reject) => {
            if (this.id) {
                const url = settings.host +
                    (this.get('isTask') ? settings.serv_task.like : settings.serv_task.comment.like);
                Server.callServer({
                    url,
                    type: "POST",
                    data: {
                        id: this.id,
                        type: isLike ? 1 : -1,
                        mode: isIncrease ? 1 : -1,
                    },
                    success(data) {
                        if (data && _.has(data, 'curuserlike') &&
                            _.has(data, 'likes_u') && _.has(data, 'likes_v') &&
                            _.has(data, 'dislikes_u') && _.has(data, 'dislikes_v')
                        ) {
                            resolve(data)
                        } else {
                            resolve();
                        }
                    },
                    error(jqXHR, textStatus, errorThrown) {
                        reject(new AjaxError(jqXHR, textStatus, errorThrown));
                    }
                });
            } else {
                reject('id is empty');
            }
        });

    }
};

export default MessageModel;
