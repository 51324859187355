import _ from 'underscore';
import { Model } from '@b2cmessenger/backbone';

let BrandModel = Model.extend({
    defaults: () => _.create({
        id: null,
        logo: '',
        logoThumb: '',
        name: ''
    })
});

export default BrandModel;
