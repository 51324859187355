import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import withStore from 'components/withStore';
import ReactPage from 'pages/ReactPage';
import Page, { PageContent, PageHeader } from 'components/page/Page';
import Header from 'components/UI/Header/Header';
import { BackButton, MenuButton } from 'components/UI/Button/Button';
import { loadWorkingWaitersIds } from 'utils/api/menu';
import { getMenuWaitersAsArray } from 'store/menu/selectors';
import Spinner from 'components/UI/Spinner/Spinner';
import useInitializeMenu from 'hooks/useInitializeMenu';
import MenuWaiters from 'components/menu/MenuWaiters/MenuWaiters';
import * as MenuAPI from 'utils/api/menu';
import {
    initialOrderParams,
    PlaceMenuOrdersContentTabIdsMap,
    PlaceMenuOrdersHeaderTabIdsMap,
} from 'components/page/MenuOrders/PlaceMenuOrders';

const MenuWaitersContent = (props) => {
    const { placeId, waiters, isActive } = props;
    const [workingWaitersIds, setWorkingWaitersIds] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    const updateWaitersWorkingStatus = useCallback(async () => {
        try {
            setLoading(true);
            const result = await loadWorkingWaitersIds({ placeId });

            if (result) {
                setWorkingWaitersIds(result);
            }
        } catch (e) {
            showError(e);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (isActive && waiters && waiters.length > 0) {
            updateWaitersWorkingStatus();
        }
    }, [isActive, waiters, updateWaitersWorkingStatus]);

    const { readyToInitialize, loading: menuInfoLoading } = useInitializeMenu({ placeId, isActive });

    const goToOrdersWithWaiterId = useCallback(
        (waiterId) => {
            app.controller.goToPlaceOrdersPage({
                placeId,
                orderParams: {
                    ...initialOrderParams,
                    waiterId,
                    placeId,
                },
                defaultActiveTab: PlaceMenuOrdersHeaderTabIdsMap.All,
                defaultContentTab: PlaceMenuOrdersContentTabIdsMap.All,
            });
        },
        [placeId]
    );

    const changeEmployeeStatusById = useCallback(
        async (status, userId) => {
            try {
                setDisabled(true);
                const data = await MenuAPI.changeEmployeeStatusById({
                    placeId,
                    userId,
                    status: status ? 1 : 0,
                });

                if (data) {
                    setWorkingWaitersIds((prevIds) => {
                        const ids = prevIds.filter((id) => Number(id) !== Number(userId));
                        if (data.status) {
                            ids.push(userId);
                        }

                        return ids;
                    });
                }
            } catch (e) {
                showError(e);
            } finally {
                setDisabled(false);
            }
        },
        [placeId]
    );

    const onShow = useCallback(async () => {
        if (readyToInitialize) {
            readyToInitialize(true);
        }
    }, [readyToInitialize]);

    const contentReady = !loading && !menuInfoLoading && isActive;
    const content = contentReady ? (
        <MenuWaiters
            waiters={waiters}
            disabled={disabled}
            changeEmployeeStatus={changeEmployeeStatusById}
            onClickWaiterOrders={goToOrdersWithWaiterId}
            workingWaitersIds={workingWaitersIds}
        />
    ) : (
        <Spinner />
    );

    return (
        <Page instance={props.__page__} onShow={onShow}>
            <PageHeader>
                <Header>
                    <BackButton onClick={props.goBack} />
                    <h1 className="title">Waiters</h1>
                    <MenuButton right />
                </Header>
            </PageHeader>
            <PageContent className="scrollable grow with-standard-padding">{content}</PageContent>
        </Page>
    );
};

const ConnectedMenuWaiters = withStore(
    connect((state) => ({
        waiters: getMenuWaitersAsArray(state),
    }))(MenuWaitersContent)
);
const MenuWaitersPage = ReactPage.extend({
    attributes: {
        id: 'page_menu_waiters',
    },
    className: 'menu-waiters-page',
    component: ConnectedMenuWaiters,
});

export default MenuWaitersPage;
