import React from 'react';
import { MenuListItem } from 'components/menu/MenuList/MenuList';
import { UnlistedCategory } from 'utils/api/menu';
import './MenuCategory.scss';

export const getCategoryListItemClassName = ({ is_addition, id }, ...classNames) => {
    return [
        'menu-category',
        Number(is_addition) && 'menu-addition-category',
        id === UnlistedCategory.id && 'menu-unlisted-category',
        ...classNames,
    ]
        .filter(Boolean)
        .join(' ');
};

export const MenuCategoryContent = ({ icon, name, photo_thumb, message = null }) => {
    return (
        <>
            <div className="photo" style={{ backgroundImage: photo_thumb && `url(${photo_thumb})` }}>
                {icon && <i className={icon} />}
            </div>
            <div className="content">
                <div className="name">{name}</div>
                {message !== null && <div className="message">{message}</div>}
            </div>
        </>
    );
};

const MenuCategoryBaseDefaultIcon = 'ion-ios-list';
const MenuCategoryBaseAdditionDefaultIcon = 'ion-ios-add-circle-outline';

export const MenuCategoryBase = (props) => {
    const icon = React.useMemo(() => {
        if (props.icon) {
            return props.icon;
        } if (props.is_addition) {
            return MenuCategoryBaseAdditionDefaultIcon;
        } if (!props.photo_thumb) {
            return MenuCategoryBaseDefaultIcon;
        }

        return null;
    }, [props.icon, props.is_addition, props.photo_thumb]);

    return (
        <div className={getCategoryListItemClassName(props, props.className)} onClick={props.onSelectCategory}>
            <MenuCategoryContent
                icon={icon}
                name={props.name}
                message={props.message}
                photo_thumb={props.photo_thumb}
            />
            {props.children}
        </div>
    );
};

const MenuCategory = (props) => {
    return (
        <MenuListItem
            className={getCategoryListItemClassName(props, props.className, 'menu-list-item')}
            onClick={props.onSelectCategory}
        >
            <>
                <MenuCategoryContent icon={props.icon} name={props.name} photo_thumb={props.photo_thumb} />
                {props.children}
            </>
        </MenuListItem>
    );
};

export default MenuCategory;
