import { getMenuState } from "store/menu/selectors";
import { OrderStatus } from "components/menu/MenuPlaceOrder/MenuPlaceOrder";

export const getMenuOrdersState = state => getMenuState(state).orders;
export const getPlaceMenuOrders = state => getMenuOrdersState(state).orders;

export const getPlaceMenuOrdersAssignedToMe = state => {
    const myId = LoginedUserHandler.getLoginedUserId();
    return getPlaceMenuOrders(state).filter(o => o['waiter_user_id'] === myId);
};

export const getPlaceMenuOrdersAssignedToMeSortedByCreatedAt = state => {
    const orders = getPlaceMenuOrdersAssignedToMe(state);
    return orders.length > 1 ? orders.sort((a, b) => a['created_at'] > b['created_at'] ? 1 : -1) : orders;
};

export const getPlaceMenuOrderById = (state, id) => {
    return getPlaceMenuOrders(state).find(o => o.id === id);
};

export const getCountPlaceMenuOrdersAssignedToMe = state => {
    const orders = getPlaceMenuOrdersAssignedToMe(state);
    return orders && orders.length || 0
};

export const getCountPlaceMenuActiveOrdersAssignedToMe = state => {
    const orders = getPlaceMenuOrdersAssignedToMe(state);
    return orders &&
        orders.filter(o => o.status === OrderStatus.Created || o.status === OrderStatus.Confirmed).length || 0;
};