import _ from 'underscore';
import { ItemView, CollectionView, LayoutView, Model } from '@b2cmessenger/backbone';

import EvidenceModel from 'models/EvidenceModel';
import ConfirmModal from 'windows/Modal/Confirm';

import './Evidence.scss';
import photoTemplate from './Photo.jade';
import template from './Evidence.jade';

let PhotoWidget = ItemView.extend({
    template: photoTemplate,
    className: "evidence-photo-widget",

    bindings: {
        'img': 'attr:{src:thumb}'
    },
});

let PhotoCollectionWidget = CollectionView.extend({
    childView: PhotoWidget,
    className: "evidence-photo-collection-widget",
});

export default LayoutView.extend({
    template: template,
    className: "evidence-widget widget",

    regions: {
        photos: '[data-js-photos]',
    },

    ui: {
        dateCreated: '[data-js-date-created]',
        btnDelete: '[data-js-btn-delete]',
        validation: '[data-js-validation]',
        validationStatus: '[data-js-validation-status]',
        validationUserName: '[data-js-validation-name]',
        validationDate: '[data-js-validation-date]',
        validationComment: '[data-js-validation-comment]',
        validationPanel: '[data-js-validation-panel]',
        btnAccept: '[data-js-btn-accept]',
        btnDecline: '[data-js-btn-decline]',
        error: '[data-js-comment-group] .error',
        commentTextarea: '[data-js-comment-group] textarea',
    },

    bindings: {
        '@ui.dateCreated': 'text:transformDate(created_at)',
        '@ui.btnDelete': 'enabled:all(canDelete,not(isDisabled)),classes:{hidden:not(canDelete)}',
        '@ui.validation': 'classes:{hidden:not(isValidated)}',
        '@ui.validationStatus': 'text:validationStatusText',
        '@ui.validationUserName': 'text:validationUserName',
        '@ui.validationDate': 'text:transformDate(validationDate)',
        '@ui.validationComment': 'text:validationComment,classes:{hidden:isEmpty(validationComment)}',
        '@ui.validationPanel': 'classes:{hidden:not(canValidate)}',
        '@ui.btnAccept': 'classes:{set:isAccepted(newValidationStatus)},disabled:isDisabled',
        '@ui.btnDecline': 'classes:{set:isDeclined(newValidationStatus)},disabled:isDisabled',
        '@ui.commentTextarea': 'value:newValidationComment,disabled:isDisabled',
        '@ui.error': 'text:error',
    },

    bindingFilters: {
        transformDate: date => date ? B2Cjs.datetimeServerToString(date, {timeFirst : true, needSeconds : false}) : "",
        isAccepted: status => status == EvidenceModel.Status.Approved,
        isDeclined: status => status == EvidenceModel.Status.Discarded,
        isEmpty: txt => !txt.trim()
    },

    events: {
        'click @ui.btnDelete': 'onBtnDeleteClick',
        'click @ui.btnAccept': 'onBtnAcceptClick',
        'click @ui.btnDecline': 'onBtnDeclineClick',
    },

    initialize(options) {
        this.viewModel = new Model({
            canDelete: this.model.get('user') && (this.model.get('user_id') == LoginedUserHandler.getLoginedUser().id || this.model.get('user').id == LoginedUserHandler.getLoginedUser().id),
            isValidated: (this.model.get('status') == EvidenceModel.Status.Approved || this.model.get('status') == EvidenceModel.Status.Discarded) && this.model.get('validated_at') && this.model.get('validate_user'),
            validationStatusText: this.model.get('status') == EvidenceModel.Status.Approved ? "Approved by" : this.model.get('status') == EvidenceModel.Status.Discarded ? "Declined by" : "",
            validationUserName: this.model.get('validate_user') ? `${this.model.get('validate_user').firstname} ${this.model.get('validate_user').lastname}` : "",
            validationDate: this.model.get('validated_at') || "",
            validationComment: this.model.get('comment') || this.model.get('comment').trim() || "",
            canValidate: this.model.get('place_id') && LoginedUserHandler.isUserEmployee(this.model.get('place_id')),
            newValidationStatus: this.model.get('status'),
            newValidationComment: this.model.get('comment') || "",
            isDisabled: false,
            error: "",
        });
        this.viewModel.listenTo(this.model, 'change:user', (m, user) =>
            this.viewModel.set('canDelete', user == LoginedUserHandler.getLoginedUser().id || user.id == LoginedUserHandler.getLoginedUser().id));
        this.viewModel.listenTo(this.model, 'change:user_id', (m, id) =>
            this.viewModel.set('canDelete', id == LoginedUserHandler.getLoginedUser().id));

        this.viewModel.listenTo(this.model, 'change:status', (m, status) => {
            this.viewModel.set('isValidated', (status == EvidenceModel.Status.Approved || status == EvidenceModel.Status.Discarded) && this.model.get('validated_at') && this.model.get('validate_user'));
            this.viewModel.set('validationStatusText', status == EvidenceModel.Status.Approved ? "Approved by" : status == EvidenceModel.Status.Discarded ? "Declined by" : "");
            this.viewModel.set('newValidationStatus', status);
        });

        this.viewModel.listenTo(this.model, 'change:validate_user', (m, validate_user) => {
            this.viewModel.set('isValidated', (this.model.get('status') == EvidenceModel.Status.Approved || this.model.get('status') == EvidenceModel.Status.Discarded) && this.model.get('validated_at') && validate_user);
            this.viewModel.set('validationUserName', validate_user ? `${validate_user.firstname} ${validate_user.lastname}` : "");
        });
        this.viewModel.listenTo(this.model, 'change:validated_at', (m, validated_at) => {
            this.viewModel.set('isValidated', (this.model.get('status') == EvidenceModel.Status.Approved || this.model.get('status') == EvidenceModel.Status.Discarded) && validated_at && this.model.get('validate_user'));
            this.viewModel.set('validationDate', validated_at || "");
        });
        this.viewModel.listenTo(this.model, 'change:comment', (m, comment) =>
            this.viewModel.set('validationComment', comment || comment.trim() || ""));
        this.viewModel.listenTo(this.model, 'change:place_id', (m, place_id) =>
            this.viewModel.set('canValidate', place_id && LoginedUserHandler.isUserEmployee(place_id)));

        this.model.listenTo(this.viewModel, 'change:newValidationStatus', (m, newValidationStatus) => {
            if(newValidationStatus == EvidenceModel.Status.Approved || newValidationStatus == EvidenceModel.Status.Discarded) {
                if(newValidationStatus != this.model.get('status')) {
                    this.viewModel.set('error', "");
                    if(newValidationStatus == EvidenceModel.Status.Discarded && (!this.viewModel.get('newValidationComment') || !this.viewModel.get('newValidationComment').trim())) {
                        this.viewModel.set('newValidationStatus', this.model.get('status'));
                        this.viewModel.set('error', "Please, add comment");
                        return;
                    }

                    this.viewModel.set('isDisabled', true);

                    this.model.save({
                        validation: {
                            status: newValidationStatus,
                            comment: this.viewModel.get('newValidationComment')
                        }
                    }, {
                        error: () => { this.viewModel.set('isDisabled', false) },
                        success: () => this.viewModel.set('isDisabled', false),
                    });
                }
            }
        });

        this.listenTo(this.viewModel, 'change:newValidationComment', m => m.set('error', ""));
    },

    onRender() {
        this.photos.show(new PhotoCollectionWidget({ collection: this.model.get('photoCollection') }));
        autosize(this.ui.commentTextarea);
        setTimeout(() => autosize.update(this.ui.commentTextarea), 0);
    },

    onBtnAcceptClick() {
        this.viewModel.set('newValidationStatus', EvidenceModel.Status.Approved);
    },

    onBtnDeclineClick() {
        this.viewModel.set('newValidationStatus', EvidenceModel.Status.Discarded);
    },

    onBtnDeleteClick() {
        new ConfirmModal({ message: "Delete this evidence?" })
            .show()
            .then(confirm => confirm && this.model.destroy());
    }
});
