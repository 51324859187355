import PlatformFeature from 'utils/PlatformFeature';

class GoogleSignInProxy extends PlatformFeature {
    constructor(options) {
        super(options);

        this.auth2 = null;

        if (!this.options.auth2InitOptions) {
            this.options.auth2InitOptions = {
                client_id: this.options.client_id || this.options.webClientId,
                scope: this.options.scope,
                fetch_basic_profile: this.options.fetch_basic_profile
            }
        }
    }

    async initialize() {
        const scriptEl = document.getElementById(GoogleSignInProxy.platformScriptId);

        this.initializePromise = (scriptEl ? Promise.resolve() : this.loadJSPlatform()).then(() => this.loadAuth2());

        return this.initializePromise;
    }

    async loadAuth2() {
        return new Promise((resolve, reject) => {
            window.gapi.load('auth2', () => {
                try {
                    window.gapi.auth2.init(this.options.auth2InitOptions)
                        .then(() => {
                            this.auth2 = window.gapi.auth2.getAuthInstance();
                            resolve();
                        }, error => reject(error.details ? error.details : error));
                } catch (e) {
                    reject(e);
                }
            });
        });
    }

    async loadJSPlatform() {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.id = GoogleSignInProxy.platformScriptId;
            script.src = 'https://apis.google.com/js/platform.js';
            script.onload = resolve;
            script.onreadystatechange = function () { if (this.readyState === 'complete') script.onload(); };

            const firstScript = document.getElementsByTagName('script')[0];
            firstScript.parentNode.insertBefore(script, firstScript);
        });
    }

    async login() {
        return this.ensureInitialized()
            .then(() =>
                new Promise((resolve, reject) => {
                    this.auth2.grantOfflineAccess().then(obj => {
                        if (this.auth2.isSignedIn.get()) {
                            const user = this.auth2.currentUser.get();
                            const profile = user.getBasicProfile();
                            const authResponse = user.getAuthResponse(true);

                            resolve({
                                "accessToken": authResponse['access_token'],
                                "expires": authResponse['expires_at'],
                                "expires_in": authResponse['expires_in'],
                                "idToken": authResponse['id_token'],
                                "serverAuthCode": obj.code,
                                "email": profile.getEmail(),
                                "userId": profile.getId(),
                                "displayName": profile.getName(),
                                "familyName": profile.getFamilyName(),
                                "givenName": profile.getGivenName(),
                                "imageUrl": profile.getImageUrl()
                            })
                        } else {
                            resolve({
                                "accessToken": 'n/a',
                                "expires": 'n/a',
                                "expires_in": 'n/a',
                                "idToken": 'n/a',
                                "serverAuthCode": obj.code,
                                "email": 'n/a',
                                "userId": 'n/a',
                                "displayName": 'n/a',
                                "familyName": 'n/a',
                                "givenName": 'n/a',
                                "imageUrl": 'n/a'
                            });
                        }
                    }, reject);
                })
            )
    }

    async logout() {
        return this.ensureInitialized()
            .then(() =>
                new Promise((resolve, reject) => {
                    this.auth2.signOut().then(resolve, reject);
                })
            );
    }
}

_.assign(GoogleSignInProxy, {
    platformScriptId: 'google-jssdk'
});

export default GoogleSignInProxy;