import { Model, ItemView } from '@b2cmessenger/backbone';
import template from './Gift.jade';
import './Gift.scss';

const Gift = ItemView.extend({
    options: {
        quantityEditable: false,
        forceDefaultQuantity: false,
        additionalMessage: false,
        isAdditionalMessageInterative: false,
        overImageMessage: false
    },

    focusQuantity() {
        if (this.ui.qtyInput[0] && this.viewModel.get('isQuantityEditable')) {
            if (this.ui.qtyInput[0] !== document.activeElement) {
                this.ui.qtyInput.focus();
            }
        }
    },

    className: 'gift-widget',

    template: template,

    ui: {
        image: '[data-js-image]',
        name: '[data-js-name]',
        qty: '[data-js-qty]',
        qtyInput: '[data-js-qty-input]',
        qtyMessage: '[data-js-qty-message]',
        additionalMessage: '[data-js-additional-message]',
        validUntil: '[data-js-expire-date]',
        overImageMessage: '[data-js-over-image-message]'
    },

    computeds: {
        qtyInputIsEmpty: {
            deps: ['quantity'],
            get: qty => !qty
        },
        c_qty: {
            deps: ['quantity', '_recalculate_qty'],
            get: quantity => Math.max(0, Math.floor(Math.abs(Number(quantity)))) || '',
            set(val) {
                if (this.viewModel.get('isQuantityEditable')) {
                    const writeoff = !!this.model.get('writeoff');

                    this.model.set({
                        quantity: (writeoff ? -1 : 1) * Math.max(0, Math.floor(Math.abs(Number(val)))) || 0
                    });

                    this.viewModel.set({
                        _recalculate_qty: _.uniqueId()
                    });
                } else {
                    this.viewModel.set({
                        _recalculate_qty: _.uniqueId()
                    });
                }
            }
        }
    },

    bindings: {
        '@ui.qty': 'classes:{hidden:isQuantityEditable},text:quantity',
        '@ui.qtyInput': 'classes:{hidden:not(isQuantityEditable)},enabled:isQuantityEditable,value:c_qty',
        '@ui.qtyMessage': 'classes:{hidden:not(isQuantityEditable)},text:select(isWriteoff,"Enter quantity to write off:","Enter quantity:")',
        '@ui.validUntil': 'classes:{hidden:not(expireDate),expired:isExpired(expireDate)},text:prettyDate(expireDate)',
        '@ui.additionalMessage': 'text:additionalMessage,classes:{interactive:isAdditionalMessageInterative}',
        '@ui.overImageMessage': 'text:overImageMessage,classes:{hidden:not(overImageMessage)}'
    },

    bindingFilters: {
        prettyDate(date) { 
            return new Date(date).toLocaleDateString(
                [], {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric'
                });
        },
        isExpired(date) {
            const local = new Date(date);
            return local < new Date();
        }
    },

    events: {
        'click @ui.name'() {
            this.focusQuantity();
        },
        'click @ui.qtyMessage'() {
            this.focusQuantity();
        },
        'click @ui.image'() {
            this.trigger('image:click');
        },
        'click @ui.additionalMessage'() {
            if (this.viewModel.get('isAdditionalMessageInterative')) {
                this.trigger('additionalMessage:click');
            }
        },
        'keyup @ui.qtyInput'() {
            if (this.viewModel.get('isQuantityEditable')) {
                if (this.ui.qtyInput[0] === document.activeElement) {
                    this.ui.qtyInput.change();
                }
            }
        },
        'blur @ui.qtyInput'() {
            if (!this.model.get('quantity')) {
                const defaultQuantity = Number(this.options.forceDefaultQuantity);
                if (defaultQuantity) {
                    this.model.set({ quantity: defaultQuantity });
                }
            }
        },
        'focus @ui.qtyInput'() {
            if (this.ui.qtyInput[0] && this.viewModel.get('isQuantityEditable')) {
                try {
                    this.ui.qtyInput[0].setSelectionRange(0, 9999);
                } catch (e) { }
            }
        }
    },

    initialize() {
        this.viewModel = new Model({
            isQuantityEditable: !!this.options.quantityEditable,
            isWriteoff: !!this.model.get('writeoff'),
            _recalculate_qty: 0,
            additionalMessage: this.options.additionalMessage || '',
            isAdditionalMessageInterative: !!this.options.isAdditionalMessageInterative,
            overImageMessage: this.options.overImageMessage || '',
        });
    },

    onRender() {
        if(!this.model.get('thumb')) {
            switch (this.model.get('type')){
                case 1:
                    this.ui.image.addClass('empty coins');
                    break;

                case 2:
                    this.ui.image.addClass('empty gifts');
                    break;

                case 3:
                    this.ui.image.addClass('empty discounts');
                    break;
            }
        } else {
            if(this.model.get('type') == 1) {
                this.ui.image.addClass('empty coins');
            } else {
                this.ui.image.removeClass('empty');
                this.ui.image.css('background-image', `url('${this.model.get('thumb')}')`);
            }
        }
    },
});

window.GiftWidget = Gift;
export default Gift;
