import {ItemView} from "@b2cmessenger/backbone";
import ReactDOM from "react-dom";
import React from "react";

const ReactView = ItemView.extend({
    template: false,
    initialize(options) {
        if (!options.render && !options.component) {
            throw new Error('Missing options: render and component');
        }

        if (!this.options.mountEvent) {
            _.extend(this.options, { mountEvent: 'onRender' });
        }

        if (this.options.model) {
            const eventNames = _.isArray(this.options.modelAttrs) ?
                this.options.modelAttrs.map(e => `change:${e}`).join(' ') : 'change';

            this.listenTo(this.model, eventNames, this._renderReactComponent);

            if (_.isFunction(this.options.modelToProps)) {
                this.modelToProps = this.options.modelToProps;
            } else {
                this.modelToProps = m => m.toJSON();
            }
        }

        if (['onRender', 'onAttach', 'onShow'].indexOf(this.options.mountEvent) !== -1) {
            const mountFn = this[this.options.mountEvent] || _.noop;
            this[this.options.mountEvent] = _.compose(mountFn, this._mountReactComponent);
            this[this.options.mountEvent] = this[this.options.mountEvent].bind(this);
        }

        _.bindAll(this, '_mountReactComponent', '_renderReactComponent');
    },

    onDestroy() {
        if (this._mounted) {
            ReactDOM.unmountComponentAtNode(this._getContainerEl());
        }
    },

    _renderReactComponent() {
        const Component = this.options.component;
        const props = _.result(this.options, 'props') || {};
        const modelProps = this._getModelProps();

        if (modelProps) {
            _.extend(props, modelProps);
        }

        const renderFn = this.options.render || function () {
            return <Component {...props}/>;
        };

        ReactDOM.render(renderFn(), this._getContainerEl(), this.options.componentUpdated);
    },

    _mountReactComponent() {
        this._renderReactComponent();
        this._mounted = true;
    },

    _getModelProps() {
        const { modelToProps, model } = this;

        if (model) {
            if (_.isFunction(modelToProps)) {
                return modelToProps.call(null, model);
            }
        }

        return null;
    },

    _getContainerEl() {
        if (this.options.region) {
            const region = this.view.getRegion(this.options.region);

            if (region) {
                return this.$(region.el)[0];
            }
        } else if (this.options.containerEl) {
            return this.$(this.options.containerEl)[0];
        }

        return this.el;
    }
});

export default ReactView;
