import _ from 'underscore';
import { ItemView, LayoutView, CollectionView, ViewModel, Backbone } from '@b2cmessenger/backbone';

import './HeaderWithTabs.scss';
import template from './HeaderWithTabs.jade';
import templateTab from './HeaderTab.jade';

import buttons from 'widgets/Buttons/Buttons';

//TODO: Inherit Header widget!

let HeaderView = LayoutView.extend({
    template: template,

    tagName: "div",
    className: "widget header-with-tabs",

    ui: {
        title: '[data-js-title]',
        top: '[data-js-header-top]',
    },

    regions: {
        tabs: '[data-js-tabs]'
    },

    /**
     * @typedef {String|Object} ButtonDescription - Either simple button id or object that describes button parameters
     * @property {String} id - Id of the button (used in triggers and ui hash)
     * @property {String} [text] - Caption on button
     * @property {String} [icn=id] - Icon for button
     * @see widgets/Buttons for list of available buttons
     */

    /**
     * @param {Object} options Options
     * @param {String} options.title - Title displayed between left buttons and right buttons
     * @param {ButtonDescription[]} [options.leftButtons] - Array of buttons displayed before title
     * @param {ButtonDescription[]} [options.rightButtons] - Array of buttons displayed after title
     * @fires {btnId}:click - Buttons trigger {btnId}:click events
     */
    initialize(options) {
        this.options = options;

        this.viewModel = new ViewModel;

        this.listenTo(this.viewModel, 'change:disabled',
            (m, disabled) => _(this.ui).each(elem => elem.attr && elem.attr('disabled', disabled)));
    },

    onShow() {
        app.getHeaderPadding().then((padding) => {
            if (this.ui.top && this.ui.top.css && padding) {
                this.ui.top.css('padding-top', padding);
            }
        });
    },

    onRender() {
        if (String(this.options.title)) {
            this.ui.title.text(String(this.options.title));
        }

        if (this.options.tabs) {
            let tabsView = new (CollectionView.extend({
                className: 'header-tabs',
                collection: new Backbone.Collection(this.options.tabs),
                childEvents: {
                    'tab:click': function (view, model) {
                        this.trigger('tab:click', view, model);
                    }
                },
                childView: ItemView.extend({
                    className: 'header-tab',
                    events: {
                        'click': 'onClick'
                    },
                    template: templateTab,
                    onRender() {
                        this.$el.addClass('tab-' + this.model.get('id'));
                        if (this.model.get('active')) this.setActive();
                    },
                    onClick() {
                        this.trigger('tab:click', this.model);
                    },
                    setActive() {
                        this.$el.addClass('active');
                    },
                    resetActive() {
                        this.$el.removeClass('active');
                    }
                }),
                resetTabs(exceptIds = []) {
                    this.children.forEach((view) => {
                        if (exceptIds.indexOf(view.model.get('id')) > -1) return;
                        view.resetActive();
                    });
                }
            }));

            tabsView.on('tab:click', (child) => {
                this.trigger('tab:click', tabsView, child);
            });

            this.tabs.show(tabsView);
        }

        if (this.options.leftButtons) for (let btn of this.options.leftButtons) {
            let id, text, icn;
            if (_.isObject(btn) && (btn.id || btn.text)) {
                id = btn.id;
                text = btn.text;
                icn = btn.icn || id;
            } else {
                id = btn;
                icn = id;
            }

            if (id && buttons[icn]) {
                let $btn = buttons[icn]({ text: text });
                $btn.click(event => this.trigger(`${id}:click`));
                this.ui.title.before($btn);
                this.ui[`btn${id}`] = $btn;
            }
        }

        if (this.options.rightButtons) for (let btn of this.options.rightButtons) {
            let id, text, icn;
            if (_.isObject(btn) && (btn.id || btn.text)) {
                id = btn.id;
                text = btn.text;
                icn = btn.icn || id;
            } else {
                id = btn;
                icn = id;
            }

            if (id && buttons[icn]) {
                let $btn = buttons[icn]({ text: text });
                $btn.click(event => this.trigger(`${id}:click`));
                this.ui.top.append($btn);
                this.ui[`btn${id}`] = $btn;
            }
        }
    }
});

export default HeaderView;
