import { Model } from '@b2cmessenger/backbone';

const Status = {
    Queued: 0,
    InProgress: 1,
    Done: 2,
    Error: 3,
    Warning: 4,
    Skipped: 5
};

const BroadcastModel = Model.extend({
    defaults: {
        _audience_count: null,
        status: null,
        task_target_id: null,
        created_at: null,
        updated_at: null,
        recipient_count: null,
        fail_count: null,
        transaction_id: null,
        error: null,
    },

    computeds: {
        'audience_count': {
            deps: ['_audience_count', 'recipient_count', 'fail_count'],
            get: (_audience_count, recipient_count, fail_count) => {
                const ac = Number(String(_audience_count));

                if (!_.isNaN(ac)) {
                    return ac;
                }

                const rc = Number(String(recipient_count)),
                    fc = Number(String(fail_count));

                if (_.isNaN(rc) && _.isNaN(fc)) {
                    return null;
                } else if (_.isNaN(rc)) {
                    return fc;
                } else if (_.isNaN(fc)) {
                    return rc;
                } else {
                    return rc + fc;
                }
            },
            set(val) {
                return { _audience_count: _.isNumber(val) ? val : null }
            }
        },
        'updatedAt': {
            deps: ['updated_at'],
            get: updated_at => {
                if (updated_at) {
                    if (_.isObject(updated_at) && updated_at.expression == "NOW()") {
                        return new Date;
                    } else {
                        const date = B2Cjs.datetimeServerToJS(updated_at);
                        if (date) {
                            return date;
                        }
                    }
                }

                return null;
            }
        },
        'createdAt': {
            deps: ['created_at'],
            get: created_at => {
                if (created_at) {
                    if (_.isObject(created_at) && created_at.expression == "NOW()") {
                        return new Date;
                    } else {
                        const date = B2Cjs.datetimeServerToJS(created_at);
                        if (date) {
                            return date;
                        }
                    }
                }

                return null;
            }
        },
    },

    sync(method, model, options) {
        const err = new Error(`Sync method "${method}" is not supported`);
        if (options && options.error) {
            options.error.call(options.context, err);
        }

        return $.Deferred().reject(err);
    }
},
    {
        Status,
    }
);
export default BroadcastModel;
