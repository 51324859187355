import { Marionette } from '@b2cmessenger/backbone';

export default Marionette.CollectionView.extend({
    options: {
        transformationAllowed: true,
        choosable: false
    },

    className: 'gift-templates-list',

    childViewOptions() {
        return {
            transformationAllowed: this.options.transformationAllowed,
            choosable: this.options.choosable
        };
    },

    childEvents: {
        reload: 'onChildUpdate',
        click: 'onClick'
    },

    onChildUpdate() {
        this.render();
    },

    onClick(child) {
        this.trigger('click', child);
    }
});
