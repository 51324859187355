import { Router, history } from '@b2cmessenger/backbone';

const originalLoadUrl = history.loadUrl.bind(history);
history.loadUrl = function (fragment) {
    fragment = this.getFixedFragment();
    return originalLoadUrl.call(history, fragment);
};

history.getFixedFragment = function (fragment) {
    return history.getFragment(fragment).split('|')[0];
};

history.checkUrl = (function(e) {
    let current = this.getFixedFragment();
    const fixedFragment = this.getFixedFragment(this.fragment);

    // If the user pressed the back button, the iframe's hash will have
    // changed and we should use that for comparison.
    if (current === fixedFragment && this.iframe) {
        current = this.getHash(this.iframe.contentWindow);
    }

    if (current === fixedFragment) return false;
    if (this.iframe) this.navigate(current);
    this.loadUrl();
}).bind(history);

const AppRouter = Router.extend({
    appRoutes: {
        '': 'index',
        'places': 'places',
        'login': 'login',
        'profile': 'profile',
        'loyalty_cards(/brand/:brand_id)': 'loyalty_cards',
        'my_reservations': 'my_reservations',
        'menu_orders': 'menu_orders',
        'place_reservations(/:id)': 'place_reservations',
        'place/:place_id': 'place',
        'place/:place_id/workplace': 'workplace',
        'place/:place_id/tasks': 'place_tasks',
        'place/:place_id/broadcasts': 'broadcasts',
        'place/:place_id/task/:task_id': 'task_editor',
        'place/:place_id/task/:task_id/broadcast(/target/:target_id)': 'task_editor_broadcast',
        'place/:place_id/evidences/:type': 'evidences',
        'place/:place_id/client/search': 'search_client',
        'place/:place_id/client/:client_id': 'client',
        'place/:place_id/menu/dashboard': 'menu_dashboard',
        'place/:place_id/menu/settings': 'menu_settings',
        'place/:place_id/menu/sections/:section_id': 'menu_section',
        'place/:place_id/menu/sections': 'menu_sections',
        'place/:place_id/menu/waiters': 'menu_waiters',
        'place/:place_id/menu/cart': 'menuCart',
        'place/:place_id/menu/orders': 'place_menu_orders',
        'place/:place_id/menu/tables': 'place_menu_tables',
        'place/:place_id/menu(/:category_id)': 'menu',
        'place/:place_id/menu/item/:item_id': 'menuItem',
        'place/:place_id/table/:table_id': 'place_table',
        'loyalty_kiosk': 'loyalty_kiosk',
        'tasks': 'tasks',
        'task/:id(/:comment_id)': 'task',
        'reservation/:id(/:comment_id)': 'task',
        'broadcast/:broadcast_id': 'broadcast',
        'settings': 'settings',
        'debug': 'debug',
        'tests(/:suites)(/*splat)': 'tests',
        '*path': 'index',
    },
    execute(callback, args, name) {
        if (callback) {
            callback.apply(this, args);
        }

        _.defer(() => {
            const controller = this._getController();
            if (controller && controller._restoreWindowState) {
                controller._restoreWindowState(name, args);
            }
        });
    }
});

export default AppRouter;
