import { Required, Optional } from '@b2cmessenger/backbone';

import ModalWindow from 'windows/Modal/Modal';
import ReservationAccept from 'widgets/Reservation/ReservationAcceptModal/ReservationAccept';

// @ts-ignore
const options = ModalWindow.options;

@options({
    model: Required,
    parentViewModel: Optional,
})
class ReservationAcceptModalWindow extends ModalWindow {
    constructor(options) {
        const acceptView = new ReservationAccept({
            model: options.model,
        });

        super({
            parentViewModel: options.parentViewModel,
            content: acceptView,
            intent: ModalWindow.Intent.Positive,
            buttons: [
                {
                    id: 'back',
                    text: 'BACK',
                    icn: 'empty'
                }, {
                    id: 'accept',
                    text: 'ACCEPT',
                    icn: 'empty',
                    onClick() {
                        this.viewModel.set({ disabled: true });
                        acceptView.accept()
                            .then(model => {
                                app.controller.trigger('reservation:change', model.id, {
                                    status: model.get('status')
                                });
                                this.close(model);
                            })
                            .catch(e => {
                                this.showError(e);
                                this.viewModel.set({ disabled: false });
                            })
                    }
                }
            ]
        });
    }
}

export default ReservationAcceptModalWindow;
