import { markersClassMap } from "components/menu/MenuItemDetail/MenuItemMarker";
import { paramsClassMap } from "components/menu/MenuItemDetail/MenuItemParam";

export const getPreparedMenuItemDetail = (item, filterMarkersByTrueValue = false) => {
    const preparedItem = { ...item, markers: [], params: [] };

    preparedItem.is_food = !!preparedItem.is_food; 
    
    if (!preparedItem.properties) {
        preparedItem.properties = [];
    }

    if (!preparedItem.ingredients) {
        preparedItem.ingredients = [];
    }

    if (preparedItem.additions) {
        preparedItem.additionItems = preparedItem.additions.map(a => a.items);
        preparedItem.additions = preparedItem.additions.map(a => a.category);
    }

    if (preparedItem['is_food']) {
        for (const key in markersClassMap) {
            const keyByServer = markersClassMap[key].keyByServer;

            if (preparedItem.hasOwnProperty(keyByServer)) {
                // need filter by true value
                if (!filterMarkersByTrueValue || (filterMarkersByTrueValue && Boolean(preparedItem[keyByServer]))) {
                    preparedItem.markers.push({ ...markersClassMap[key], key, value: preparedItem[keyByServer] });
                }
                delete preparedItem[keyByServer];
            }
        }

        for (const key in paramsClassMap) {
            const keyByServer = paramsClassMap[key].keyByServer;

            if (preparedItem.hasOwnProperty(keyByServer)) {
                const param = { ...paramsClassMap[key], key };

                if (_.isObject(item[keyByServer])) {
                    param.value = preparedItem[keyByServer].value;
                    param.unit = preparedItem[keyByServer].unit;
                } else {
                    param.value = preparedItem[keyByServer];
                }

                preparedItem.params.push(param);

                delete preparedItem[keyByServer];
            }
        }
    }

    return preparedItem;
};

const getInitialParams = () => {
    return _.keys(paramsClassMap).map(key => ({ key, ...paramsClassMap[key] }));
};

const getInitialMarkers = () => {
    return _.keys(markersClassMap).map(key => ({ key, ...markersClassMap[key] }));
};

export const getInitialPreparedMenuItem = ({ currency = '', isFood = true, selectedCategory }) => ({
    name: '',
    description: '',
    additions: [],
    photo: '',
    photo_thumb: '',
    categories: selectedCategory ? [selectedCategory] : [],
    ingredients: [],
    properties: [],
    price: '',
    currency,
    isFood,
    params: isFood ? getInitialParams() : [],
    markers: isFood ? getInitialMarkers() : []
});

export const getPreparedDataToUpload = (item, oldItem) => {
    const preparedItem = { ...item };
    const propsToRemove = ['markers', 'params', 'additions', 'categories', 'additionItems', 'photo_thumb', 'currency',
        'id', 'isFood', 'hidden_until', 'is_hidden_forever'];

    for (const marker of preparedItem.markers) {
        preparedItem['marker_' + marker.key] = marker.value;
    }

    for (const param of preparedItem.params) {
        const value = (!_.isNull(param.value) && param.hasOwnProperty('units')) ? {
            value: param.value,
            unit: param.unit
        } : param.value;

        preparedItem['param_' + param.key] = value;
    }

    if (preparedItem.additions) {
        preparedItem['addition_ids'] = preparedItem.additions.map(a => a.id);
    }

    if (preparedItem.categories) {
        preparedItem['category_ids'] = preparedItem.categories.map(c => c.id);
    }

    if (!Number.isInteger(preparedItem.photo)) {
        propsToRemove.push('photo');
    }

    return _.omit(preparedItem, propsToRemove);
};

export const getFieldErrorsFromJqXHR = (jqXHR) => {
    const fields = _.isArray(jqXHR.responseJSON) && jqXHR.responseJSON ||
        (jqXHR.responseJSON.error && jqXHR.responseJSON.error.field) || [];

    const errors = _(fields).reduce((fields, o) => {
        let field = o.field && String(o.field),
            msg = o.message && String(o.message);

        if (field && msg) {
            fields[field] = msg;
        }

        return fields;
    }, _.create({}));

    return errors;
};