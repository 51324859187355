var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (c_isDefault, c_isVerified, isEditable) {pug_html = pug_html + "\u003Cp class=\"phone\" data-js-phone\u003E\u003C\u002Fp\u003E\u003Cp class=\"status\" data-js-status\u003E\u003C\u002Fp\u003E";
if (isEditable) {
if (c_isVerified && !c_isDefault) {
pug_html = pug_html + "\u003Cbutton class=\"btn-medium make-default-button\" data-js-make-default type=\"button\" data-role=\"none\"\u003EMake default\u003C\u002Fbutton\u003E";
}
if (!c_isVerified) {
pug_html = pug_html + "\u003Cbutton class=\"btn-medium confirm-button\" data-js-confirm type=\"button\" data-role=\"none\"\u003EConfirm\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003Cbutton class=\"remove-button\" data-js-remove type=\"button\" data-role=\"none\"\u003E\u003Ci class=\"ion-ios-close\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E";
}}.call(this,"c_isDefault" in locals_for_with?locals_for_with.c_isDefault:typeof c_isDefault!=="undefined"?c_isDefault:undefined,"c_isVerified" in locals_for_with?locals_for_with.c_isVerified:typeof c_isVerified!=="undefined"?c_isVerified:undefined,"isEditable" in locals_for_with?locals_for_with.isEditable:typeof isEditable!=="undefined"?isEditable:undefined));;return pug_html;};
module.exports = template;