import { Model, ViewModel, LayoutView } from '@b2cmessenger/backbone';
import Modal from './Modal';
import FooterView from 'widgets/Footer/Footer';

import './Gift.scss';
import GiftView from 'widgets/Gift/Gift';
import escapeHtml from 'utils/escapeHtml';

import template from './Gift.jade';

const GiftModal = Modal.extend({
    options: {
        model: null,
        user: null,
        writeoff: false,
        quantityEditable: true,
        giftTemplate: null,
        startingReceiptAmount: null,
        showMessage: true
    },

    windowName: "gift-modal-window",
    className: "modal-window info-modal-window gift-modal-window",

    initialize() {
        if (!this.model) {
            if (!this.options.giftTemplate) {
                throw new Error("options.giftTemplate is empty");
            }

            this.model = new Model(this.options.giftTemplate.toJSON({ computed: true }));
        }
    },

    onRender() {
        const footerView = new FooterView({
            buttons: [
                {
                    id: "close",
                    text: this.options.showMessage ? "NO" : "CANCEL",
                    icn: "empty"
                },
                {
                    id: "yes",
                    text: this.options.showMessage ? "YES" : "CONFIRM",
                    icn: "empty"
                }]
        });

        this.listenTo(footerView, 'close:click', view => this.close(false));
        this.footer.show(footerView);

        let expireDateLocal = null;

        if(this.model.get('period')) {
            expireDateLocal = new Date();
            expireDateLocal.setDate(expireDateLocal.getDate() + this.model.get('period'));
            expireDateLocal.setHours(23, 59, 59, 999);
        }

        if(this.model.get('total_period')) {
            let totalExpireDate = new Date(this.model.get('total_period').replace(/ /g, "T"));

            let totalExpireDateLocal = new Date(totalExpireDate);
            totalExpireDateLocal.setFullYear(totalExpireDate.getUTCFullYear(), totalExpireDate.getUTCMonth(), totalExpireDate.getUTCDate());
            totalExpireDateLocal.setHours(23, 59, 59, 999);

            if (!expireDateLocal || expireDateLocal > totalExpireDateLocal) {
                expireDateLocal = totalExpireDateLocal;
            }
        }

        const
            fullname = escapeHtml(this.options.user
                ? (this.options.user.name
                    || this.options.user.firstname && (this.options.user.firstname + (this.options.user.lastname ? ' ' + this.options.user.lastname : ''))
                    || this.options.user.email
                    || "Unknown person" + (this.options.user.id ? ` (#${this.options.user.id})` : ''))
                : "Anonym"),
            plural = Math.abs(this.model.get('quantity')) > 1,
            model = new GiftModel({
                giftTemplateModel: this.model,
                quantityEditable: this.options.quantityEditable,
                clientName: fullname
            });

        if (model.get('writeoff')) {
            this.$el.addClass('red-border');
        } else {
            this.$el.addClass('green-border');
        }

        this.content.show(new ContentView({
            startingReceiptAmount: this.options.startingReceiptAmount,
            showMessage: this.options.showMessage,
            model
        }));

        this.listenTo(model, 'change:quantity', (m, quantity) => {
            this.model.set({ quantity });
        });

        this.listenTo(model, 'change:amount', (m, amount) => {
            this.model.set({ receiptAmount: amount });
        });

        this.listenTo(footerView, 'yes:click', view => {
            if (Number(model.get('quantity'))) {
                this.close(this.model);
            }
        });
    },

    cancel() {
        this.close(false);
    }
});

const
    MAX_GIFT_QUANTITY = Math.floor(Number.MAX_SAFE_INTEGER / 10),
    MAX_RATIO_FOR_PERCENT = 100000,
    PERCENT_TRANSFORMATION_COEF = 1000000,
    MAX_AMOUNT_FOR_PERCENT = Math.floor(MAX_GIFT_QUANTITY / PERCENT_TRANSFORMATION_COEF * MAX_RATIO_FOR_PERCENT),
    MAX_RATIO_FOR_PRICE = 100000000,
    MAX_AMOUNT_FOR_PRICE = MAX_GIFT_QUANTITY,
    MAX_RATIO_FOR_MULTIPLIER = 100000,
    MAX_AMOUNT_FOR_MULTIPLIER = Math.floor(MAX_GIFT_QUANTITY / MAX_RATIO_FOR_MULTIPLIER),
    MAX_RATIO_FOR_COINS = 100000000,
    MAX_AMOUNT_FOR_COINS = MAX_GIFT_QUANTITY,
    MAX_SAFE_AMOUNT = Math.pow(10, Math.floor(Math.log10(Math.min(MAX_AMOUNT_FOR_PERCENT, MAX_AMOUNT_FOR_PRICE, MAX_AMOUNT_FOR_MULTIPLIER, MAX_RATIO_FOR_COINS)))) - 1;

const GiftModel = Model.extend({
    defaults: {
        writeoff: false,
        quantityEditable: false,
        amount: 0,
        _quantity: 0,
        _quantityEditable: false
    },

    proxies: {
        name: {
            modelAttribute: 'giftTemplateModel',
            readOnly: true
        },
        thumb: {
            modelAttribute: 'giftTemplateModel',
            readOnly: true
        },
        period: {
            modelAttribute: 'giftTemplateModel',
            readOnly: true
        },
        totalPeriod: {
            submodelAttribute: 'total_period',
            modelAttribute: 'giftTemplateModel',
            readOnly: true
        },
        type: {
            modelAttribute: 'giftTemplateModel',
            readOnly: true
        },
        defaultQuantity: {
            submodelAttribute: 'quantity',
            modelAttribute: 'giftTemplateModel',
            readOnly: true
        },
        totalQuantity: {
            submodelAttribute: 'totalQty',
            modelAttribute: 'giftTemplateModel',
            readOnly: true
        },
        customQuantityAllowed: {
            submodelAttribute: 'custom_quantity_allowed',
            modelAttribute: 'giftTemplateModel',
            readOnly: true
        },
        transformationType: {
            submodelAttribute: 'transformation_type',
            modelAttribute: 'giftTemplateModel',
            readOnly: true
        },
        transformationRatio: {
            submodelAttribute: 'transformation_ratio',
            modelAttribute: 'giftTemplateModel',
            readOnly: true
        }
    },

    computeds: {
        quantityEditable: {
            deps: ['_quantityEditable', 'writeoff', 'totalQuantity', 'customQuantityAllowed'],
            get(_quantityEditable, writeoff, totalQuantity, customQuantityAllowed) {
                return _quantityEditable && (writeoff && totalQuantity || !writeoff && customQuantityAllowed);
            },
            set: val => ({
                _quantityEditable: !!val
            })
        },
        expireDate: {
            deps: ['period', 'totalPeriod'],
            get(period, totalPeriod) {
                let expireDateLocal = null;

                if (period) {
                    expireDateLocal = new Date();
                    expireDateLocal.setDate(expireDateLocal.getDate() + period);
                    expireDateLocal.setHours(23, 59, 59, 999);
                }

                if (totalPeriod) {
                    let totalExpireDate = new Date(totalPeriod.replace(/ /g, "T"));

                    let totalExpireDateLocal = new Date(totalExpireDate);
                    totalExpireDateLocal.setFullYear(totalExpireDate.getUTCFullYear(), totalExpireDate.getUTCMonth(), totalExpireDate.getUTCDate());
                    totalExpireDateLocal.setHours(23, 59, 59, 999);

                    if (!expireDateLocal || expireDateLocal > totalExpireDateLocal) {
                        expireDateLocal = totalExpireDateLocal;
                    }
                } else {
                    expireDateLocal
                }

                return null;
            }
        },
        amount: {
            deps: ['quantityEditable', 'customQuantityAllowed', 'transformationType', '_amount'],
            get: (quantityEditable, customQuantityAllowed, transformationType, _amount) =>
                quantityEditable && customQuantityAllowed && transformationType && _amount || 0,
            set: val => ({
                _amount: Number(val) || 0
            })
        },
        quantity: {
            deps: [
                'quantityEditable', 'writeoff', 'defaultQuantity', '_quantity', 'totalQuantity',
                'customQuantityAllowed', 'transformationType', 'transformationRatio', 'amount'
            ],
            get(quantityEditable, writeoff, defaultQuantity, _quantity, totalQuantity,
                customQuantityAllowed, transformationType, transformationRatio, amount
            ) {
                if (quantityEditable) {
                    if (writeoff) {
                        _quantity = Math.floor(Math.abs(_quantity));
                        totalQuantity = Number(totalQuantity) || 0;

                        return -Math.max(0, Math.min(_quantity, totalQuantity, MAX_GIFT_QUANTITY)) || 0;
                    } else {
                        if (customQuantityAllowed) {
                            amount = Math.floor(Math.max(0, Number(amount) || 0));
                            transformationRatio = Math.floor(Math.max(1, Number(transformationRatio) || 0));

                            switch (transformationType) {
                                default:
                                case 0: {
                                    _quantity = Math.floor(Math.abs(_quantity));
                                    return Math.max(0, Math.min(_quantity, MAX_GIFT_QUANTITY));
                                }
                                case 1: {
                                    //percent
                                    //amount is number of cents in receipt
                                    //transformationRatio is number of 1/ 100 of percents of amount
                                    amount = Math.min(amount, MAX_AMOUNT_FOR_PERCENT);
                                    transformationRatio = Math.min(transformationRatio, MAX_RATIO_FOR_PERCENT);

                                    return Math.floor(amount / PERCENT_TRANSFORMATION_COEF * transformationRatio);
                                }
                                case 2: {
                                    //price
                                    //amount is number of cents in receipt
                                    //transformationRatio is price in cents for 1 gift
                                    amount = Math.min(amount, MAX_AMOUNT_FOR_PRICE);
                                    transformationRatio = Math.min(transformationRatio, MAX_RATIO_FOR_PRICE);

                                    return Math.floor(amount / transformationRatio);
                                }
                                case 3: {
                                    //multiplier
                                    //amount is number of 1/100 items
                                    //transformationRatio 1/100 of multiplier
                                    amount = Math.min(amount, MAX_AMOUNT_FOR_MULTIPLIER);
                                    transformationRatio = Math.min(transformationRatio, MAX_RATIO_FOR_MULTIPLIER);

                                    return Math.floor(amount / 100 * transformationRatio / 100);
                                }
                                case 4: {
                                    //coins
                                    //amount is number of coins
                                    //transformationRation is price in coins for 1 gift
                                    amount = Math.min(amount, MAX_AMOUNT_FOR_COINS);
                                    transformationRatio = Math.min(transformationRatio, MAX_RATIO_FOR_COINS);

                                    return Math.floor(amount / transformationRatio);
                                }
                            }
                        } else {
                            return Math.round(defaultQuantity);
                        }
                    }
                } else {
                    return Math.round(defaultQuantity);
                }
            },
            set: val => ({
                _quantity: Math.round(Number(val) || 0),
            })
        }
    },

    initialize() {
        if (!this.get('giftTemplateModel')) {
            throw new Error('giftTemplateModel is empty');
        }

        this.set({
            writeoff: this.get('defaultQuantity') < 0,
            quantity: this.get('defaultQuantity')
        });
    }
}, {
    MAX_GIFT_QUANTITY,
    MAX_RATIO_FOR_PERCENT,
    PERCENT_TRANSFORMATION_COEF,
    MAX_AMOUNT_FOR_PERCENT,
    MAX_RATIO_FOR_PRICE,
    MAX_AMOUNT_FOR_PRICE,
    MAX_RATIO_FOR_MULTIPLIER,
    MAX_AMOUNT_FOR_MULTIPLIER,
    MAX_RATIO_FOR_COINS,
    MAX_AMOUNT_FOR_COINS,
    MAX_SAFE_AMOUNT
});

const ContentView = LayoutView.extend({
    options: {
        model: null,
        startingReceiptAmount: null,
        showMessage: true
    },

    className: "widget gift-modal-content-widget",
    template,

    regions: {
        gift: "[data-js-gift]"
    },

    ui: {
        conversionGroup: '[data-js-conversion-group]',
        amountInput: '[data-js-amount]',
        ratioInfo: '[data-js-info]',
        message: '[data-js-message]',
        totalCaption: '[data-js-total-caption]',
        totalSign: '[data-js-total-sign]'
    },

    computeds: {
        isGiftWidgetHasQuantityEditable: {
            deps: ['quantityEditable', 'writeoff', 'customQuantityAllowed', 'transformationType'],
            get(quantityEditable, writeoff, customQuantityAllowed, transformationType) {
                return quantityEditable
                    && (
                        writeoff
                        ||
                        customQuantityAllowed && !transformationType
                    );
            }
        },
        isTransformationAllowed: {
            deps: ['writeoff', 'customQuantityAllowed', 'transformationType'],
            get(writeoff, customQuantityAllowed, transformationType) {
                return !writeoff && customQuantityAllowed && transformationType
            }
        },
        c_amount: {
            deps: ['amount', '_recalculate_amount'],
            get(amount) {
                const nAmount = Math.floor(Number(amount));

                if (nAmount > MAX_SAFE_AMOUNT) {
                    this.model.set({ amount: MAX_SAFE_AMOUNT });
                    return;
                }

                if (nAmount > 0) {
                    return nAmount;
                } else {
                    return null;
                }
            },
            set(val) {
                const nAmount = val;
                if (nAmount > 0 && nAmount <= MAX_SAFE_AMOUNT) {
                    this.model.set({
                        amount: nAmount,
                    });

                    this.viewModel.set({
                        _recalculate_amount: _.uniqueId()
                    });
                } else {
                    if (nAmount > MAX_SAFE_AMOUNT) {
                        this.model.set({
                            amount: MAX_SAFE_AMOUNT,
                        });
                    } else {
                        this.model.set({
                            amount: 0,
                        });
                    }

                    this.viewModel.set({
                        _recalculate_amount: _.uniqueId()
                    });
                }
            }
        },
        message: {
            deps: ['showMessage', 'writeoff', 'clientName', 'type', 'quantity'],
            get(showMessage, writeoff, clientName, type, quantity) {
                if (showMessage) {
                    const plural = type != 1 && Math.abs(quantity) > 1;
                    if (writeoff) {
                        return `Writeoff ${plural ? 'these' : 'this'} ${plural ? 'gifts' : 'gift'}\nfrom ${clientName}?`;
                    } else {
                        return `Give ${plural ? 'these' : 'this'} ${plural ? 'gifts' : 'gift'}\nto ${clientName}?`;
                    }
                } else {
                    return '';
                }
            }
        },
        ratioInfoHtml: {
            deps: ['type', 'transformationType', 'transformationRatio'],
            get(type, transformationType, transformationRatio) {
                switch (transformationType) {
                    default:
                    case 0:
                        return '';
                    case 1:
                        return `<em>${(transformationRatio / 100).toFixed(2)}%</em> of receipt total will be converted into ${type == 1 ? 'coins' : 'gifts'}`;
                    case 2:
                        return `For each <em>${(transformationRatio / 100).toFixed(2)}$</em> in receipt total client will get 1 ${type == 1 ? 'coin' : 'gift'}`;
                    case 3:
                        return `For each item client will get <em>${(transformationRatio / 100).toFixed(2)}<span class="multiplier"></span></em> ${type == 1 ? 'coins' : 'gifts'}`
                    case 4:
                        return `<em>${transformationRatio}</em> coins will be converted into gift`;

                }
            }
        },
        totalCaption: {
            deps: ['transformationType'],
            get(transformationType) {
                switch (transformationType) {
                    default:
                        return 'Receipt total';
                    case 3:
                    case 4:
                        return `Total items`;
                }
            }
        },
        totalSign: {
            deps: ['transformationType'],
            get(transformationType) {
                switch (transformationType) {
                    default:
                        return '$';
                    case 3:
                        return '';
                    case 4:
                        return 'Coins';
                }
            }
        }
    },

    bindingHandlers: {
        decimalValue: {
            checkInputFormat: function($element, value) {
                this.isDecimal = value != 4;
                if (this.isDecimal) {
                    $element.attr('step', '0.01');
                    $element.attr('min', '0.01');
                    this.set($element, $element.val());
                } else {
                    $element.attr('step', '1');
                    $element.attr('min', '1');
                    this.set($element, Math.floor($element.val()) || '1');
                }

                $element.trigger('change');
            },
            init: function ($element, value, context) {
                this.isDecimal = true;

                $element.blur(() => {
                    $element.val(Number($element.val()) || null);
                });

                $element.on('keyup', e => {
                    if (!this.isDecimal) return;
                    const maxLength = $element[0].maxLength || Infinity,
                        val = $element.val();

                    if (!/^\d*(\.\d{0,2})?$/.test(val)
                        || !$element[0].validity.valid && (val != "0" && val != "0." && val != "0.0")
                        || val.length >= maxLength && /^0+/.test(val)
                    ) {
                        const nVal = Number(String(val).replace(/(\.[^.]*)(\..*)/, (m, p1) => p1));
                        $element.val(nVal && nVal.toFixed(2) || 0);
                    }
                });

                this.listenTo(this.view.model, 'change:_transformation_type', (m, value) => {
                    if (m.previous('_transformation_type') == 4 || value == 4) {
                        this.checkInputFormat($element, value);
                    }
                });

                if (this.view.model.get('_transformation_type') == 4) {
                    this.checkInputFormat($element, this.view.model.get('_transformation_type'));
                }
            },
            get: function ($element) {
                return Math.round(Number($element.val()) * (this.isDecimal ? 100 : 1));
            },
            set: function ($element, value) {
                value = this.isDecimal ? value / 100 : value;

                try {
                    if ($element[0] === document.activeElement) {
                        const val = $element.val();
                        if (Math.abs(val - value) > 0.001) {
                            $element.val(value);
                        }
                    } else {
                        if ($element.val() + '' != value + '') {
                            $element.val(value);
                        }
                    }
                } catch (e) { }
            }
        },
    },

    bindings: {
        '@ui.conversionGroup': 'classes:{hidden:not(isTransformationAllowed)}',
        '@ui.amountInput': 'decimalValue:c_amount',
        '@ui.ratioInfo': 'html:ratioInfoHtml',
        '@ui.message': 'text:message,classes:{hidden:not(showMessage)}',
        '@ui.totalCaption': 'text:totalCaption',
        '@ui.totalSign': 'text:totalSign'
    },

    events: {
        'keyup @ui.amountInput'(e) {
            if (this.ui.amountInput[0] === document.activeElement) {
                this.ui.amountInput.change();
            }
        },
        'focus @ui.amountInput'() {
            try {
                this.ui.amountInput[0].setSelectionRange(0, 9999);
            } catch (e) { }
        }
    },

    initialize() {
        this.viewModel = new ViewModel({
            _recalculate_amount: 0,
            showMessage: this.options.showMessage
        });
    },

    onRender() {
        if (this.options.startingReceiptAmount) {
            this.setBinding('c_amount', Math.round(Number(this.options.startingReceiptAmount)) || 0);
        }

        this.gift.show(new GiftView({
            model: this.model,
            quantityEditable: this.getBinding('isGiftWidgetHasQuantityEditable'),
        }));

        this.viewModel = new ViewModel({
            _recalculate_amount: _.uniqueId()
        });
    }
});

/**
 * @todo
 * It's fallback for old design
 */
window.GiftModal = GiftModal;
export { GiftModal as default, GiftModel };
