//import Server, $

import settings from 'settings';

class B2CPlace {
    constructor(options) { };

    static server_create(place, onSuccess, onError) {
        Server.callServer({
            url: settings.host + settings.serv_place.create,
            type: "POST",
            data: place,
            success: onSuccess,
            error: onError
        });
    };

    static server_change(place, onSuccess, onError) {
        Server.callServer({
            url: settings.host + settings.serv_place.change,
            type: "POST",
            data: place,
            success: onSuccess,
            error: onError
        });
    };

    static server_search(filter, onSuccess, onError) {
        Server.callServer({
            url: settings.host + settings.serv_place.search,
            type: "POST",
            data: filter,
            success: onSuccess,
            error: onError
        });
    };

    static server_get(request, onSuccess, onError) {
        Server.callServer({
            url: settings.host + settings.serv_place.get,
            type: "POST",
            data: request,
            success: onSuccess,
            error: onError
        });
    };

    static server_checkin_hist(user_id, place_id, onSuccess, onError) {
        Server.callServer({
            url: settings.host + settings.serv_place.checkin_hist,
            type: "POST",
            data: {
                user_id: user_id,
                place_id: place_id
            },
            success: onSuccess,
            error: onError
        });
    };

    static server_business_checkin(user_id, place_id, onSuccess, onError) {
        Server.callServer({
            url: settings.host + settings.serv_place.business_checkin,
            type: "POST",
            data: {
                user_id: user_id,
                place_id: place_id
            },
            success: onSuccess,
            error: onError
        });
    };

    static server_business_checkin_hist(user_id, place_id, onSuccess, onError) {
        var data = { place_id: place_id };
        if (user_id)
            data.user_id = user_id;

        Server.callServer({
            url: settings.host + settings.serv_place.business_checkin_hist,
            type: "POST",
            data: data,
            success: onSuccess,
            error: onError
        });
    };

    static server_isverified(place_id, onSuccess, onError) {
        Server.callServer({
            url: settings.host + settings.serv_place.isverified,
            type: "POST",
            data: {
                place_id: place_id
            },
            success: onSuccess,
            error: onError
        });
    };

    static server_isverified_list(user_id, place_id, onSuccess, onError) {
        Server.callServer({
            url: settings.host + settings.serv_place.isverified_list,
            type: "POST",
            data: {
                user_id: user_id,
                place_id: place_id
            },
            success: onSuccess,
            error: onError
        });
    };

    static build_place_html_place(place, options) {
        var def_options = {
            tag: 'div',
            is_need_rating: true,
            is_need_dis: true,
        };
        options = $.extend(true, {}, def_options, options);

        var logosrc = (place.logoThumb == null || place.logoThumb == '') ? B2CPlace.const.default_thumb : place.logoThumb;
        var result = '<' + options.tag + ' class="place new" data-id="' + place.id + '"><img class="logo" src="' + logosrc + '">';

        var ratingHTML;
        if (options.is_need_rating)
            ratingHTML = '<p class="rating b2crating" data-rating="' + place.rating + '">' +
            '<span class="star"></span>' +
            '<span class="star"></span>' +
            '<span class="star"></span>' +
            '<span class="star"></span>' +
            '<span class="star"></span>' +
            '</p>';
        else
            ratingHTML = '';
        //var infoLeft = '<div class="info_left"><p class="name">' + place.name + '</p><p class="address">' + place.adr_street_name + ', ' + place.adr_street_number + '</p><p class="rating" data-rating="' + place.rating +'"></p></div>';
        var infoLeft = '<div class="info_left"><p class="name">' + place.name + '</p><p class="address">' + place.adr_street_name + ', ' + place.adr_street_number + '</p>' + ratingHTML + '</div>';

        //var currentuserPos = geo.getCurrentPosition();
        var infoRight, distance_html;
        if (options.is_need_dis)
            distance_html = '<p class="distance">' + Number(Math.round(place.dist + 'e2') + 'e-2') + ' miles</p>';
        else
            distance_html = '';

        infoRight = '<div class="info_right">' + distance_html + '</div>';

        result += infoLeft + infoRight;
        result += '</' + options.tag + '>';

        return result;
    };
};


B2CPlace.const = {};
B2CPlace.const.default_thumb = 'common_res/icon/other/house.png';

B2CPlace.const.isverified = {}; // Верифицирован ли текущий пользователь в указанном месте
B2CPlace.const.isverified.no = 0; // нет
B2CPlace.const.isverified.yes = 1; // да

B2CPlace.const.search = {}; // Константы параметров поиска

B2CPlace.const.search.load_limit = 30; // Максимальное количество мест, которое надо загрузить за один раз

B2CPlace.const.search.mode = {}; // Режим - только кол-во или и результаты
B2CPlace.const.search.mode.only_count = false; // только вернуть подсчет кол-ва результатов
B2CPlace.const.search.mode.get_places = true; // вернуть места и кол-во результатов

B2CPlace.const.search.sortby = {}; // Режимы сортировки результата
B2CPlace.const.search.sortby.dis_desc = 'dis.desc'; // По дистанции, в порядке уменьшения
B2CPlace.const.search.sortby.dis_asc = 'dis.asc'; // По дистанции, в порядке увеличения
B2CPlace.const.search.sortby.rating_asc = 'rating.asc'; // По рейтингу, в порядке увеличения
B2CPlace.const.search.sortby.rating_desc = 'rating.desc'; // По рейтингу, в порядке уменьшения

B2CPlace.const.save = {}; // Константы параметров сохранения Плейсов
B2CPlace.const.save.mode = {}; // Режимы сохранения / вадидация
B2CPlace.const.save.mode.save = false; // Режимы сохранения
B2CPlace.const.save.mode.valid = true; // Режимы валидация

B2CPlace.const.status = {}; // Статусы плейса
B2CPlace.const.status.draft = 0; // черновик
B2CPlace.const.status.active = 1; // активный

//TODO: remove globals!
window.B2CPlace = B2CPlace;

export default B2CPlace;