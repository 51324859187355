var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (_, iconClass, icon_code, name, parents, showParentName) {pug_html = pug_html + "\u003Ci" + (pug.attr("class", pug.classes([iconClass(icon_code)], [true]), false, true)+pug.attr("data-js-icon", true, true, true)) + "\u003E\u003C\u002Fi\u003E\u003Cdiv\u003E\u003Cdiv" + (pug.attr("class", pug.classes([[(showParentName && parents.length ? '' : 'hidden'), 'parent-name']], [true]), false, true)+pug.attr("data-js-parent-name", true, true, true)) + "\u003E";
// iterate _(parents).reduceRight(function(arr, p) { arr.push(p); return arr; }, [])
;(function(){
  var $$obj = _(parents).reduceRight(function(arr, p) { arr.push(p); return arr; }, []);
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var parent = $$obj[pug_index0];
pug_html = pug_html + "\u003Cspan\u003E" + (pug.escape(null == (pug_interp = parent.get('name')) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var parent = $$obj[pug_index0];
pug_html = pug_html + "\u003Cspan\u003E" + (pug.escape(null == (pug_interp = parent.get('name')) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"info\"\u003E\u003Ci" + (pug.attr("class", pug.classes([iconClass(icon_code)], [true]), false, true)+pug.attr("data-js-icon", true, true, true)) + "\u003E\u003C\u002Fi\u003E\u003Cspan class=\"name\" data-js-name\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"_" in locals_for_with?locals_for_with._:typeof _!=="undefined"?_:undefined,"iconClass" in locals_for_with?locals_for_with.iconClass:typeof iconClass!=="undefined"?iconClass:undefined,"icon_code" in locals_for_with?locals_for_with.icon_code:typeof icon_code!=="undefined"?icon_code:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined,"parents" in locals_for_with?locals_for_with.parents:typeof parents!=="undefined"?parents:undefined,"showParentName" in locals_for_with?locals_for_with.showParentName:typeof showParentName!=="undefined"?showParentName:undefined));;return pug_html;};
module.exports = template;