import { Collection } from '@b2cmessenger/backbone';

import CategoryModel from 'models/CategoryModel';

const CategoryCollection = Collection.extend({
    model: CategoryModel,

    initialize(models) {
    }
});

window.CategoryCollection = CategoryCollection;

export default CategoryCollection;
