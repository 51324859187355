var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (disableComment, isAuthor) {pug_html = pug_html + "\u003Cdiv data-js-date-time-start\u003E\u003C\u002Fdiv\u003E\u003Cdiv data-js-date-end-variants\u003E\u003C\u002Fdiv\u003E\u003Cdiv data-js-date-time-end\u003E\u003C\u002Fdiv\u003E\u003Cp class=\"non-working-hours\" data-js-non-working-hours\u003EReservation included non-working hours.\u003C\u002Fp\u003E\u003Cdiv class=\"properties\"\u003E";
if (isAuthor) {
pug_html = pug_html + "\u003Cdiv class=\"input-group phone\"\u003E\u003Ci class=\"telephone\"\u003E\u003C\u002Fi\u003E\u003Clabel\u003E\u003Cp\u003EPHONE\u003C\u002Fp\u003E\u003Cp class=\"error\" data-js-phone-error\u003E\u003C\u002Fp\u003E\u003Cp class=\"phone\" data-js-phone\u003E\u003C\u002Fp\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"property\" data-js-prop-guests\u003E\u003Cdiv class=\"label\" data-js-guests-label\u003ENumber of guests\u003C\u002Fdiv\u003E\u003Cdiv class=\"options plus-minus-buttons\" data-js-guests-wrapper\u003E\u003Cbutton class=\"minus\" data-js-minus type=\"button\" data-role=\"none\"\u003E\u003Ci class=\"ion-ios-remove-circle\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cinput data-js-guests-input type=\"number\" name=\"guests\" value=\"0\" step=\"1\"\u003E\u003Cbutton class=\"plus\" data-js-plus type=\"button\" data-role=\"none\"\u003E\u003Ci class=\"ion-ios-add-circle\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbutton class=\"btn-medium ion-ios-switch\" data-js-btn-additional data-role=\"none\"\u003EAdditional options\u003C\u002Fbutton\u003E";
if (!disableComment) {
pug_html = pug_html + "\u003Cdiv class=\"input-group message\"\u003E\u003Ci class=\"ion-ios-chatboxes-outline\"\u003E\u003C\u002Fi\u003E\u003Clabel\u003E\u003Cp class=\"error\"\u003E\u003C\u002Fp\u003E\u003Ctextarea data-js-textarea-message maxlength=\"255\" data-role=\"none\" placeholder=\"Message (optional)\" rows=\"3\"\u003E\u003C\u002Ftextarea\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput data-js-hidden-submit type=\"submit\" hidden\u003E";}.call(this,"disableComment" in locals_for_with?locals_for_with.disableComment:typeof disableComment!=="undefined"?disableComment:undefined,"isAuthor" in locals_for_with?locals_for_with.isAuthor:typeof isAuthor!=="undefined"?isAuthor:undefined));;return pug_html;};
module.exports = template;