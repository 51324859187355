import React from 'react';
import MenuWaiterCallList from 'components/menu/MenuWaiterCall/MenuWaiterCallList';
import MenuWaiterCallForm from 'components/menu/MenuWaiterCall/MenuWaiterCallForm';
import PropTypes from 'prop-types';
import './MenuWaiterCallContainer.scss';

export const actionMap = {
    ActionCall: 0,
    ActionBill: 1,
};
export const optionBitsMap = {
    None: 0,
    BillPlasticCard: 1,
};
export const actionLabelMap = {
    [actionMap.ActionCall]: 'Invite',
    [actionMap.ActionBill]: 'Bill',
};
export const actionIconMap = {
    [actionMap.ActionCall]: 'ion-ios-person-add',
    [actionMap.ActionBill]: 'ion-ios-paper',
};

export const optionsLabelMap = {
    [optionBitsMap.BillPlasticCard]: 'Plastic Card',
};

export const optionsIconMap = {
    [optionBitsMap.BillPlasticCard]: 'ion-ios-card',
};

const MenuWaiterCallContainer = (props) => {
    const {
        models = [],
        placeId,
        readOnly = true,
        order = null,
        forceOpenForm = false,
        isEmployee = false,
        changeOrder = _.noop,
        changeModels = _.noop,
        emptyListText = '',
        tables = [],
        selectedTableId,
        showClient = false,
        showWaiter = false,
    } = props;

    const onListItemClick = (orderId) => {
        if (orderId) {
            if (isEmployee) {
                app.controller.goToPlaceOrdersPage({ showOrderWindowWithId: orderId, placeId });
            } else {
                app.controller.goToOrdersPage({ showOrderWindowWithId: orderId });
            }
        }
    };

    let form = null;
    if (!readOnly) {
        const addModel = (model) => changeModels([...models, model]);

        form = (
            <MenuWaiterCallForm
                order={order}
                placeId={placeId}
                changeOrder={changeOrder}
                addModel={addModel}
                forceOpenForm={forceOpenForm}
                tables={tables}
                selectedTableId={selectedTableId}
            />
        );
    }

    return (
        <div className="menu-waiter-call-container">
            {form}
            <MenuWaiterCallList
                models={models}
                showClient={showClient}
                showWaiter={showWaiter}
                onListItemClick={onListItemClick}
            />
            {models.length === 0 && emptyListText.length > 0 && (
                <div className="menu-waiter-call-list__empty">
                    <i className="ion-ios-information-circle-outline" />
                    <p>{emptyListText}</p>
                </div>
            )}
        </div>
    );
};

MenuWaiterCallContainer.propTypes = {
    isEmployee: PropTypes.bool,
    placeId: PropTypes.number,
    models: PropTypes.array,
    readOnly: PropTypes.bool,
    order: PropTypes.object,
    forceOpenForm: PropTypes.bool,
    changeOrder: PropTypes.func,
    changeModels: PropTypes.func,
    emptyListText: PropTypes.string,
    tables: PropTypes.array,
    selectedTableId: PropTypes.number,
    showClient: PropTypes.bool,
    showWaiter: PropTypes.bool,
};

export default MenuWaiterCallContainer;
