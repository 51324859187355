import React from 'react';

export const priceIconConfig = {
    eur: {
        type: 'span',
        value: '€',
        isIconBefore: true,
    },
    usd: {
        type: 'span',
        value: '$',
        isIconBefore: true,
    },
    bitcoin: {
        type: 'icon',
        value: 'ion-logo-bitcoin',
    },
    gbp: {
        type: 'span',
        value: '£',
        isIconBefore: true,
    },
    rub: {
        type: 'span',
        value: '₽',
    },
    jpy: {
        type: 'span',
        value: '¥',
        isIconBefore: true,
    },
    chf: {
        type: 'span',
        value: 'CHF',
    },
    cad: {
        type: 'span',
        value: 'CAD',
    },
};

export const getPriceIconAndPosition = (value, currency) => {
    let icon = null;
    let isIconBefore = false;

    if (currency && priceIconConfig.hasOwnProperty(currency.toLocaleLowerCase())) {
        const i = priceIconConfig[currency.toLocaleLowerCase()];

        switch (i.type) {
            case 'span':
                icon = <span className="icon">{i.value}</span>;
                break;
            case 'icon':
                icon = <i className={i.value} />;
                break;
            default:
                icon = null;
        }

        if (i.isIconBefore) {
            isIconBefore = true;
        }
    }

    return { icon, isIconBefore };
};
