import { Model } from '@b2cmessenger/backbone';
import settings from 'settings';
import aes from 'aes-js';
import {parseDeepLink, isValidDeepLink, getMenuTableFromDeepLinkPath} from 'utils/DeepLinking';

/**
 * @see https://docs.google.com/document/d/1M1aX2zTFv8yk13Cic1eSJRvnqAECzZnRBIvk18tT3es/edit
 * @type {{UserProfile: number, GiftWriteOff: number, LoyaltyCard: number, DeepLink: number, Other: number}}
 */
const Type = {
    UserProfile: 10,
    GiftWriteOff: 11,
    LoyaltyCard: 12,
    MenuTable: 13,
    DeepLink: 14,
    Other: 0,
};

const Separator = '-';

function encodeCode(params, options) {
    _.defaults(options || (options = {}), {
        secret: settings.qrSecrets[0],
        oldSecrets: settings.qrSecrets.slice(1),
    });

    if (params[0] === Type.DeepLink) {
        return encodeDeepLink(params, options);
    }

    const str = _.toArray(params).join(Separator),
        strCs = `${str}-${checksum(str)}`,
        bytes = aes.utils.utf8.toBytes(strCs),
        aesCtr = new aes.ModeOfOperation.ctr(aes.utils.hex.toBytes(options.secret)),
        encryptedBytes = aesCtr.encrypt(bytes);

    return aes.utils.hex.fromBytes(encryptedBytes);
};

function encodeDeepLink(params, options) {
    _.defaults(options || (options = {}), {
        protocol: 'https'
    });

    const {host, params: hostParams, path, attributes, link} = params[1];

    const search = _.pairs({ ...(hostParams || {}), link })
        .map(
            ([k,v]) => (v === true || v === undefined) ? encodeURIComponent(k) : [k,encodeURIComponent(v)].join('=')
        )
        .join('&');

    return `${options.protocol}://${host}/?${search}`;
}
window.encodeDeepLink = encodeDeepLink;

function decodeCode(code, options) {
    _.defaults(options || (options = {}), {
        secrets: settings.qrSecrets
    });

    const number = Number(code);
    if (number) {
        return [Type.UserProfile, number];
    }

    const encryptedBytes = aes.utils.hex.toBytes(code);
    let errorToThrow = null;

    for (let secret of options.secrets) {
        try {
            const aesCtr = new aes.ModeOfOperation.ctr(aes.utils.hex.toBytes(secret)),
                decryptedBytes = aesCtr.decrypt(encryptedBytes),
                strCs = aes.utils.utf8.fromBytes(decryptedBytes),
                paramsAndCs = strCs.split(Separator);

            if (paramsAndCs && paramsAndCs.length >= 3) {
                const params = paramsAndCs.slice(0, -1);

                if (checksum(params.join(Separator)) == _.last(paramsAndCs)) {
                    return params;
                }
            }
        } catch (e) {
            e.message = `Invalid code=${JSON.stringify(code)}, cannot parse: ${e.message}`;
            errorToThrow = errorToThrow || e;
        }
    }

    if (_.isString(code) && code.length) {
        const dl = parseDeepLink(code);

        if (dl === null) {
            return [Type.LoyaltyCard, code];
        }

        return [Type.DeepLink, dl.host, dl.params, dl.path, dl.attributes, dl.link];
    } else if (!_.isNull(errorToThrow)) {
        throw errorToThrow;
    }

    throw new Error(`Invalid code=${JSON.stringify(code)}, cannot parse: unknown code format or invalid secret`);
};

function checksum(s) {
    let chk = 0x12345678;
    const length = s.length;
    for (let i = 0; i < length; i++) {
        chk += (s.charCodeAt(i) * (i + 1));
    }

    return String(chk & 0x1ff);
};

const QrCodeModel = Model.extend({
    defaults: {
        type: Type.Other,
        userId: null,
        brandId: null,
        giftTemplateId: null,
        giftWriteoffQuantity: null,
        loyaltyCardNumber: null,
        menuTable: null,
        deepLink: null
    },

    computeds: {
        type: {
            deps: ['_type'],
            get: _type => _type,
            set(val) {
                const validationError = this.validate({ _type: val }, { singleAttributeMode: true });

                if (validationError) {
                    throw validationError;
                } else {
                    return _.create(null, { _type: Number(val) });
                }
            }
        },

        userId: {
            deps: ['_userId'],
            get: _userId => _userId,
            set(val) {
                const validationError = this.validate({ _userId: val }, { singleAttributeMode: true });

                if (validationError) {
                    throw validationError;
                } else {
                    return _.create(null, { _userId: Number(val) || null });
                }
            }
        },

        brandId: {
            deps: ['_brandId'],
            get: _brandId => _brandId,
            set(val) {
                const validationError = this.validate({ _brandId: val }, { singleAttributeMode: true });

                if (validationError) {
                    throw validationError;
                } else {
                    return _.create(null, { _brandId: Number(val) || null });
                }
            }
        },

        menuTable: {
            deps: ['_menuTable'],
            get: _menuTable => _menuTable,
            set(val) {
                const validationError = this.validate({ _menuTable: val }, { singleAttributeMode: true });

                if (validationError) {
                    throw validationError;
                } else {
                    return _.create(null, { _menuTable: Number(val) || null });
                }
            }
        },

        giftTemplateId: {
            deps: ['_giftTemplateId'],
            get: _giftTemplateId => _giftTemplateId,
            set(val) {
                const validationError = this.validate({ _giftTemplateId: val }, { singleAttributeMode: true });

                if (validationError) {
                    throw validationError;
                } else {
                    const nval = Number(val);
                    return _.create(null, { _giftTemplateId: _.isUndefined(val) || _.isNull(val) ? null : Number(val) });
                }
            }
        },

        giftWriteoffQuantity: {
            deps: ['_giftWriteoffQuantity'],
            get: _giftWriteoffQuantity => _giftWriteoffQuantity,
            set(val) {
                const validationError = this.validate({ _giftWriteoffQuantity: val }, { singleAttributeMode: true });

                if (validationError) {
                    throw validationError;
                } else {
                    return _.create(null, { _giftWriteoffQuantity: Number(val) || null });
                }
            }
        },

        loyaltyCardNumber: {
            deps: ['_loyaltyCardNumber'],
            get: _loyaltyCardNumber => _loyaltyCardNumber,
            set(val) {
                const validationError = this.validate({ _loyaltyCardNumber: val }, { singleAttributeMode: true });

                if (validationError) {
                    throw validationError;
                } else {
                    return _.create(null, { _loyaltyCardNumber: val || null });
                }
            }
        },

        deepLink: {
            deps: ['_deepLink'],
            get: _deepLink => ({ ..._deepLink }),
            set(val) {
                const validationError = this.validate({ deepLink: val }, { singleAttributeMode: true });

                if (validationError) {
                    throw validationError;
                } else {
                    return _.create(null, { _deepLink: val || null });
                }
            }
        },
        deepLinkMenuTable: {
            deps: ['_type', '_deepLink'],
            get: (_type, _deepLink) => {
                if (_type === Type.DeepLink && _deepLink !== null) {
                    return getMenuTableFromDeepLinkPath(_deepLink.path);
                }

                return null;
            }
        },

        code: {
            deps: ['_type', '_userId', '_brandId', '_giftTemplateId', '_giftWriteoffQuantity', '_loyaltyCardNumber', '_menuTable', '_deepLink'],
            get(_type, _userId, _brandId, _giftTemplateId, _giftWriteoffQuantity, _loyaltyCardNumber, _menuTable, _deepLink) {
                switch (_type) {
                    case Type.UserProfile:
                        if (!this.validate(
                            { _type, _userId },
                            { skipSingleAttributes: true })
                        ) {
                            return encodeCode([
                                _type,
                                _userId
                            ]);
                        }
                    case Type.GiftWriteOff:
                        if (!this.validate(
                            { _type, _userId, _brandId, _giftTemplateId, _giftWriteoffQuantity },
                            { skipSingleAttributes: true })
                        ) {
                            return encodeCode([
                                _type,
                                _userId,
                                _brandId,
                                _giftTemplateId,
                                _giftWriteoffQuantity
                            ]);
                        }
                    case Type.LoyaltyCard:
                        if (!this.validate(
                            { _type, _loyaltyCardNumber },
                            { skipSingleAttributes: true })
                        ) {
                            return encodeCode([
                                _type,
                                _loyaltyCardNumber
                            ]);
                        }
                    case Type.MenuTable:
                        if (!this.validate(
                            { _type, _menuTable },
                            { skipSingleAttributes: true })
                        ) {
                            return encodeCode([
                                _type,
                                _menuTable
                            ]);
                        }
                    case Type.DeepLink:
                        if (!this.validate(
                            { _type, _deepLink },
                            { skipSingleAttributes: true })
                        ) {
                            return encodeCode([
                                _type,
                                _deepLink
                            ]);
                        }
                    case Type.Other:
                    default:
                        return null;
                }
            },
            set(code) {
                if (!code) {
                    throw new Error(`Invalid code=${JSON.stringify(code)}, cannot parse: code is falsey`);
                }

                const decoded = decodeCode(String(code));

                const type = Number(decoded[0]),
                    params = decoded.slice(1);

                let validateError;
                switch (type) {
                    case Type.UserProfile:
                        validateError = this.validate({
                            type,
                            userId: params[0]
                        });

                        if (validateError) {
                            throw validateError;
                        }

                        return _.create(null, {
                            type: type,
                            userId: params[0]
                        });

                    case Type.GiftWriteOff:
                        validateError = this.validate({
                            type,
                            userId:
                            params[0],
                            brandId: params[1],
                            giftTemplateId: params[2],
                            giftWriteoffQuantity: params[3]
                        });

                        if (validateError) {
                            throw validateError;
                        }

                        return _.create(null, {
                            type: type,
                            userId: params[0],
                            brandId: params[1],
                            giftTemplateId: params[2],
                            giftWriteoffQuantity: params[3],
                        });

                    case Type.LoyaltyCard:
                        validateError = this.validate({
                            type,
                            loyaltyCardNumber: params[0]
                        });

                        if (validateError) {
                            throw validateError;
                        }

                        return _.create(null, {
                            type: type,
                            loyaltyCardNumber: params[0]
                        });

                    case Type.MenuTable:
                        validateError = this.validate({
                            type,
                            menuTable: params[0]
                        });

                        if (validateError) {
                            throw validateError;
                        }

                        return _.create(null, {
                            type,
                            menuTable: params[0]
                        });

                    case Type.DeepLink:
                        validateError = this.validate({
                            type,
                            deepLink: {
                                host: params[0],
                                params: params[1],
                                path: params[2],
                                attributes: params[3],
                                link: params[4],
                            }
                        });

                        if (validateError) {
                            throw validateError;
                        }

                        return _.create(null, {
                            type,
                            deepLink: {
                                host: params[0],
                                params: params[1],
                                path: params[2],
                                attributes: params[3],
                                link: params[4],
                            }
                        });

                    default:
                        throw new Error(`Invalid code, cannot parse: unknown type=${type}`);
                }
            }
        },
    },

    sync() {
        throw new Error("Not implemented!");
    },

    validate: function (_attrs, options) {
        _.defaults(options || (options = {}), {
            singleAttributeMode: false,
            skipSingleAttributes: false,
        });

        //TODO: if sync provided check computed attributes if options.validate && options.wait

        try {
            const attrs = _.clone(_attrs);

            _.each(['type', 'userId', 'brandId', 'giftTemplateId', 'giftWriteoffQuantity', 'loyaltyCardNumber', 'menuTable', 'deepLink'], k => {
                const _k = '_' + k;

                if (_.has(attrs, _k) && !_.has(attrs, k)) {
                    attrs[k] = attrs[_k];
                }

                if (!options.skipSingleAttributes && _.has(attrs, k)) {
                    let val = attrs[k];
                    switch (k) {
                        case 'type':
                            if (_.isUndefined(_.find(Type, t => val == t))) {
                                throw new Error(`Invalid type=${JSON.stringify(attrs.type)}`);
                            }
                            break;
                        case 'userId':
                        case 'brandId':
                        case 'giftWriteoffQuantity':
                            if (!_.isNull(val) && !_.isUndefined(val)) {
                                const nval = Number(val);

                                if (!(nval > 0 && nval < Infinity)) {
                                    throw new Error(`Invalid ${k}=${JSON.stringify(val)}`);
                                }
                            }
                            break;
                        case 'giftTemplateId':
                            if (!_.isNull(val) && !_.isUndefined(val)) {
                                const nval = Number(val);

                                if (!(nval >= 0 && nval < Infinity)) {
                                    throw new Error(`Invalid ${k}=${JSON.stringify(val)}`);
                                }
                            }
                            break;
                        case 'menuTable':
                            if (!_.isNull(val) && !_.isUndefined(val)) {
                                const nval = Number(val);

                                if (!(nval >= 0 && nval < Infinity)) {
                                    throw new Error(`Invalid ${k}=${JSON.stringify(val)}`);
                                }
                            }
                            break;
                        case 'loyaltyCardNumber':
                            if (!_.isNull(val) && !_.isUndefined(val)) {
                                if (!(_.isString(val) && val.length)) {
                                    throw new Error(`Invalid ${k}=${JSON.stringify(val)}`);
                                }
                            }
                            break;
                        case 'deepLink':
                            if (!isValidDeepLink(val)) {
                                throw new Error(`Invalid ${k}=${JSON.stringify(val)}`);
                            }
                            break;
                        default:
                            throw new Error(`k=${k} is invalid`);
                    }
                }
            });

            if (!options.singleAttributeMode) {
                const type = attrs.type,
                    userId = attrs.userId,
                    brandId = attrs.brandId,
                    giftTemplateId = attrs.giftTemplateId,
                    giftWriteoffQuantity = attrs.giftWriteoffQuantity,
                    loyaltyCardNumber = attrs.loyaltyCardNumber,
                    menuTable = attrs.menuTable,
                    deepLink = attrs.deepLink;

                switch (type) {
                    case Type.UserProfile:
                        if (!userId) {
                            throw new Error(`Invalid model state with type=Type.UserProfile: userId=${JSON.stringify(userId)}`);
                        }
                        break;
                    case Type.GiftWriteOff:
                        if (!userId) {
                            throw new Error(`Invalid model state with type=Type.GiftWriteOff: userId=${JSON.stringify(userId)}`);
                        }
                        if (!brandId) {
                            throw new Error(`Invalid model state with type=Type.GiftWriteOff: brandId=${JSON.stringify(brandId)}`);
                        }
                        if (!giftTemplateId && giftTemplateId !== 0) {
                            throw new Error(`Invalid model state with type=Type.GiftWriteOff: giftTemplateId=${JSON.stringify(giftTemplateId)}`);
                        }
                        if (!giftWriteoffQuantity) {
                            throw new Error(`Invalid model state with type=Type.GiftWriteOff: giftWriteoffQuantity=${JSON.stringify(giftWriteoffQuantity)}`);
                        }
                        break;
                    case Type.LoyaltyCard:
                        if (!loyaltyCardNumber) {
                            throw new Error(`Invalid model state with type=Type.LoyaltyCard: loyaltyCardNumber=${JSON.stringify(loyaltyCardNumber)}`);
                        }
                        break;
                    case Type.MenuTable:
                        if (!menuTable) {
                            throw new Error(`Invalid model state with type=Type.MenuTable: menuTable=${JSON.stringify(menuTable)}`);
                        }
                    case Type.DeepLink:
                        if (!deepLink) {
                            throw new Error(`Invalid model state with type=Type.DeepLink: deepLink=${JSON.stringify(deepLink)}`);
                        }
                    case Type.Other:
                        break;
                    default:
                        throw new Error(`Invalid model state: unknown type=${type}`);
                }
            }

        } catch (e) {
            return e;
        }
    },
}, {
    Type,
    Separator,
    encodeCode,
    decodeCode,
    checksum
});

////////////////////////
////////////////////////////////////
////////////////////////////////////////
//////////

export default QrCodeModel;
