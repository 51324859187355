import React from 'react';
import PropTypes from 'prop-types';

import MenuList, { MenuListType, byIdAndSortOrder, MenuListItem } from 'components/menu/MenuList/MenuList';
import { UserItemBase } from 'components/menu/UserItem/UserItem';
import './UserItems.scss';

// TODO Make `MenuList` and `MenuListItem` universal components. `MenuList` -> `List`, `MenuListItem` -> `ListItem`.
const UserEditableItems = (props) => {
    const {
        items,
        onSelectItem = _.noop,
        onEditItem = _.noop,
        onRemoveItem = _.noop,
        displayRemoveButton = true,
        displayEditButton = true,
    } = props;

    const itemList = items.map((i) => {
        const leftContent = displayRemoveButton ? (
            <button className="ion-ios-close-outline remove" onClick={onRemoveItem.bind(null, i.id, i)} />
        ) : null;
        const rightContent = displayEditButton ? (
            <button className="ion-md-create edit" onClick={onEditItem.bind(null, i.id, i)} />
        ) : null;
        return (
            <MenuListItem
                className="menu-list-item-with-controls menu-editable-list-item"
                key={byIdAndSortOrder(i)}
                left={leftContent}
                right={rightContent}
            >
                <UserItemBase
                    className="menu-list-item"
                    onSelectItem={onSelectItem.bind(null, i.id, i)}
                    {...i}
                />
            </MenuListItem>
        );
    });

    return (
        <MenuList {...props} type={MenuListType.Users}>
            {itemList}
        </MenuList>
    );
};
UserEditableItems.propTypes = {
    items: PropTypes.array,
    onSelectItem: PropTypes.func,
    onEditItem: PropTypes.func,
    onRemoveItem: PropTypes.func,
    displayRemoveButton: PropTypes.bool,
    displayEditButton: PropTypes.bool,
};

export default UserEditableItems;
