import SimpleMarkdown from 'simple-markdown';

import './prettyText.scss';

class Md2HtmlConverter {
    constructor() {
        if (Md2HtmlConverter.instance) {
            return Md2HtmlConverter.instance;
        } else {
            Md2HtmlConverter.instance = this;
        }

        const rules = _.extend(
            _.pick(SimpleMarkdown.defaultRules,
                ['text', 'lheading', 'hr', 'br', 'blockQuote', 'escape', 'em', 'strong', 'del', /*'list',*/ 'table',
                    'codeBlock', 'fence', 'inlineCode']
                ),
            {
                paragraph: _.extend({}, SimpleMarkdown.defaultRules.paragraph, {
                    html(node, output, state) {
                        return SimpleMarkdown.htmlTag("p", output(node.content, state));
                    }
                }),
                heading: _.extend({}, SimpleMarkdown.defaultRules.heading, {
                    html(node, output, state) {
                        return SimpleMarkdown.htmlTag("h" + Math.min(node.level + 3, 6), output(node.content, state));
                    }
                }),
                // list: _.extend({}, SimpleMarkdown.defaultRules.list, {
                //     html(node, output, state) {
                //         var listItems = node.items.map(function (item) {
                //             return SimpleMarkdown.htmlTag("li", SimpleMarkdown.htmlTag('span', output(item, state)));
                //         }).join("");

                //         var listTag = node.ordered ? "ol" : "ul";
                //         var attributes = {
                //             start: node.start
                //         };
                //         return SimpleMarkdown.htmlTag(listTag, listItems, attributes);
                //     }
                // })
            }
        );
        this.parser = SimpleMarkdown.parserFor(rules);
        this.outputter = SimpleMarkdown.htmlFor(SimpleMarkdown.ruleOutput(rules, 'html'));
    }

    convert(text) {
        return this.outputter(this.parser(text));
    }
}

Md2HtmlConverter.instance = null;

function bindingHandler($container, value) {
    if (value && String(value)) {
        $container.addClass('widget-lite-pretty-text').html(new Md2HtmlConverter().convert(value));
    } else {
        $container.empty();
    }
}

function prettyText(text) {
    return new Md2HtmlConverter().convert(text);
}

function prettyText$(text) {
    return $('<div class="widget-lite-pretty-text">').html(prettyText(text));
}

export { prettyText as default, prettyText$, bindingHandler };