import { Required, ItemView, ViewModel } from '@b2cmessenger/backbone';

import UserModel from 'models/UserModel';

import template from './MessageHeader.jade';
import './MessageHeader.scss';

/** @typedef {import('./MessageHeader')} MessageHeaderViewInterface */

/** @type {typeof import('./MessageHeader').properties} */
// @ts-ignore
const properties = ItemView.properties;

/** @type {typeof import('./MessageHeader').options} */
// @ts-ignore
const options = ItemView.options;

/** @type {typeof import('./MessageHeader').events} */
// @ts-ignore
const events = ItemView.events;


@options({
    model: Required,
    userModel: Required,
    placeModel: Required,
    loginedUserModel: Required
})
@events({
    /** User clicked on author
     * @param {UserModel} userModel - model of user
     * @param {boolean} [isAnonym] - is user is anonym
     * @param {boolean} [isFromBussines] - is user from this place */
    'author:click': (userModel, isAnonym, isFromBussines) => { },
})
@properties({
    className: 'widget message-header-widget',
    template,

    ui: {
        author: '[data-js-author]',
        markSolution: '[data-js-mark-solution]',
        markIdea: '[data-js-mark-idea]',
        markIssue: '[data-js-mark-issue]',
        markQuestion: '[data-js-mark-question]',
        markGift: '[data-js-mark-gift]',
        markMuted: '[data-js-mark-muted]'
    },

    computeds: {
        hasSolution: {
            deps: ['hassolution', 'issolution'],
            get: (hassolution, issolution) => hassolution == 1 || issolution == 1,
        },
        isIdea: {
            deps: ['idea'],
            get: (idea) => idea == 1
        },
        isIssue: {
            deps: ['issue'],
            get: (issue) => issue == 1
        },
        isQuestion: {
            deps: ['question'],
            get: (question) => question == 1
        },
        hasGift: {
            deps: ['hasgift', 'isWithGift'],
            get: (hasgift, isWithGift) => hasgift == 1 || isWithGift
        },
    },

    bindings: {
        '@ui.markSolution': 'classes:{hidden:not(hasSolution)}',
        '@ui.markIdea': 'classes:{hidden:not(isIdea)}',
        '@ui.markIssue': 'classes:{hidden:not(isIssue)}',
        '@ui.markQuestion': 'classes:{hidden:not(isQuestion)}',
        '@ui.markGift': 'classes:{hidden:not(hasGift)}',
        '@ui.markMuted': 'classes:{hidden:not(isMuted)}'
    },

    events: {
        'click @ui.author'() {
            this.trigger('author:click',
                this.userModel, this.model.get('isAuthorAnonym'), this.model.get('isFromBussines'));
        },
    },

    modelEvents: {
        'change:published_at change:created_at change:isFromBussines change:isAuthorVerified change:isAuthorAnonym'() {
            this._debouncedRender();
        },
    },

    templateHelpers() {
        return {
            userModel: this.userModel,
            placeModel: this.placeModel,
            loginedUserModel: this.loginedUserModel,
            B2Cjs: B2Cjs
        }
    }
})
class MessageHeaderView extends ItemView {
    initialize() {
        /** @type {MessageHeaderViewInterface} */
        // @ts-ignore
        const self = this;

        this.viewModel = new ViewModel;
        this.userModel = self.options.userModel;
        this.placeModel = self.options.placeModel;
        this.loginedUserModel = self.options.loginedUserModel;

        this._debouncedRender = _.debounce(() => {
            if (!this.isDestroyed && this.isRendered) {
                this.render();
            }
        }, 1);

        this.listenTo(this.placeModel, 'change:name', this._debouncedRender);

        const onIsAuthorAnonymChange = (isAuthorAnonym) => {
            if (isAuthorAnonym) {
                this.listenTo(this.userModel, 'change:name change:avatarUrl', this._debouncedRender);
            }
        }
        this.listenTo(this.model, 'change:isAuthorAnonym',
            (m, isAuthorAnonym) => onIsAuthorAnonymChange(isAuthorAnonym));

        onIsAuthorAnonymChange(this.model.get('isAuthorAnonym'));
    }
};

export default MessageHeaderView;
