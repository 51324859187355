import { Model, CollectionView, ItemView, LayoutView, Collection } from '@b2cmessenger/backbone';
import Page from 'pages/Page';

import BrandModel from 'models/BrandModel';

import BrandItemView from 'widgets/BrandItem/BrandItem';
import HeaderView from 'widgets/Header/Header';
import { LoyaltyCardCollectionView } from 'widgets/LoyaltyCard/LoyaltyCard';
import LoyaltyCardEditorWindow from 'windows/LoyaltyCardEditor/LoyaltyCardEditor';

import template from './BrandLoyaltyCards.jade';
import './BrandLoyaltyCards.scss';
import ViewWithWindows from 'traits/ViewWithWindows';

const withWindowsTrait = {
    Trait: ViewWithWindows,
    options: {
        windowMap: [
            {
                cls: LoyaltyCardEditorWindow,
                trigger() {
                    this.onAddClick();
                }
            }
        ],
    }
};

let BrandLoyaltyCards = Page.extendWithTraits([withWindowsTrait], {
    getBrandId() {
        return this.model.id;
    },

    attributes: {id: 'brand-loyalty-cards-list'},
    template: template,
    className: "brand-loyalty-cards-list-page",

    regions: {
        header: '[data-js-header]',
        brand: '[data-js-brand]',
        cards: '[data-js-cards]'
    },

    initialize(options) {
        this.model = new BrandModel();
        this.collection = new Collection();
    },

    show(options) {
        if (options.brand) {
            this._setBrand(options.brand);
        } else if (options.brandId) {
            const brand = LoyaltyCardBase.getBrand_by_id(options.brandId);
            if (brand) {
                this._setBrand(brand);
            } else if (this.model.id != options.brandId) {
                //TODO: fetch brand and display page with it
                return app.controller.goToLoyaltyCardsPage();
            }
        }

        const ret = Page.prototype.show.apply(this, arguments);

        if (options && options.openAddCard) {
            this.onAddClick();
        }

        return ret;
    },

    onRender() {
        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: "Loyalty Card",
            rightButtons: [{id: 'add', text: 'Add'}]
        });
        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.listenTo(headerView, 'add:click', this.onAddClick);
        this.header.show(headerView);

        let brandItemView = new BrandItemView({ model: this.model });
        this.brand.show(brandItemView);

        let loyaltyCardCollectionView = new LoyaltyCardCollectionView({
            collection: this.collection,
            validateUsers: LoyaltyCardBase.getUsers(),
        });
        this.cards.show(loyaltyCardCollectionView);
    },

    onAddClick() {
        new LoyaltyCardEditorWindow({
            brand: this.model.toJSON()
        })
            .show()
            .then(card => {
                if (card) {
                    this.collection.add(card.toJSON({ computed: true }));
                }
            });
    },

    _setBrand(brand) {
        this.model.clear();
        this.model.set(brand);
        if (this.cards.currentView) {
            this.cards.currentView.setValidateUsers(LoyaltyCardBase.getUsers(), )
        }
        this.collection.reset(LoyaltyCardBase.getCards_by_brand_id(brand.id));
    },
});

export default BrandLoyaltyCards;
