import { ItemView, ViewModel } from "@b2cmessenger/backbone";
import { Optional, Required } from "@b2cmessenger/backbone";
import { bindingHandler as dateTime } from 'widgets/lite/dateTime/dateTime';

import template from './ReservationChangeCommentAdditionalData.jade';
import './ReservationChangeCommentAdditionalData.scss';
import moment from 'moment-timezone';
import ReservationModel from 'models/ReservationModel';

const SmokingZone = {
    Yes: 'yes',
    No: 'no'
};

const SmokingZoneText = {
    [SmokingZone.Yes]: 'Smoking',
    [SmokingZone.No]: 'No Smoking'
};

function bakeTime(value, unit) {
    return value + ' ' + unit + (value == 1 ? '' : 's');
}

function prettyDuration(time) {
    const duration = moment.duration(time);

    const days = duration.get('days');
    const hours = duration.get('hours');
    const minutes = duration.get('minutes');

    if (days < 7) {
        if (days === 0) {
            if (hours === 0) {
                return bakeTime(minutes, 'minute');
            } else {
                return bakeTime(hours, 'hour') + (minutes ? ' ' + bakeTime(minutes, 'minute') : '');
            }
        } else {
            return bakeTime(days, 'day') + (hours ? ' ' + bakeTime(hours, 'hour') : '');
        }
    } else {
        return bakeTime(days, 'day');
    }
}

function isSameDateBy(a, b, format = 'DD.MM.YYYY HH:mm') {
    return moment(a).format(format) === moment(b).format(format);
}

const MODEL_PROPERTIES = [
    'date_start_local', 'date_end_local',
    'opt_guests_count', 'opt_children_count', 'opt_smoking_area',
    'status'
];

@ItemView.options({
    model: Required,
    parentViewModel: Optional
})
@ItemView.properties({
    template,
    tagName: 'div',
    className: 'widget reservation-comment-content-widget',

    ui: {
        date: '[data-js-date]',
        duration: '[data-js-duration]',
        guests: '[data-js-guests]',
        smokingZone: '[data-js-zone]',
        childSeats: '[data-js-child-seats]',
        status: '[data-js-status]',
        statusBadge: '[data-js-status-badge]',
        expandBtn: '[data-js-expand]',
        field: '[data-js-field]'
    },

    computeds: {
        c_expandButtonText: {
            deps: ['vm_fieldsExpanded'],
            get: isExpanded => isExpanded ? 'Compact' : 'Expand'
        },
        c_statusBadgeText: {
            deps: ['vm_status'],
            get: status => ReservationModel.StatusText[status] || 'Unknown'
        },
        c_isStartDateChanged: {
            deps: ['date_start_local', 'previous_values'],
            get: (date_start_local, previous_values) => {
                if (!date_start_local) {
                    return false;
                }

                return !isSameDateBy(date_start_local, previous_values['date_start_local'], 'DD.MM.YYYY');
            }
        },
        c_isStartTimeChanged: {
            deps: ['date_start_local', 'previous_values'],
            get: (date_start_local, previous_values) => {
                if (!date_start_local) {
                    return false;
                }

                return !isSameDateBy(date_start_local, previous_values['date_start_local'], 'HH:mm');
            }
        },
        c_statusClasses: {
            deps: ['vm_status'],
            get: status => {
                return {
                    'status__warning': [
                        ReservationModel.Status.New,
                        ReservationModel.Status.BusinessSuggestedChanges,
                        ReservationModel.Status.ClientSuggestedChanges
                    ].indexOf(status) != -1,
                    'status__negative': [
                        ReservationModel.Status.CancelledByClient,
                        ReservationModel.Status.RejectedByBusiness
                    ].indexOf(status) != -1,
                    'status__positive': status == ReservationModel.Status.Confirmed,
                    'status__active':   status == ReservationModel.Status.Completed
                };
            }
        },
        c_dateStartLocal: {
            deps: ['vm_date_start_local'],
            get: date_start_local => date_start_local && B2Cjs.datetimeServerLocalToJS(date_start_local)
        }
    },

    bindings: {
        '@ui.date': 'dateTime:c_dateStartLocal,' +
            'classes:{date__changed:c_isStartDateChanged,time__changed:c_isStartTimeChanged}',
        '@ui.field': 'toggleChanged:$viewModel',
        '@ui.expandBtn': 'toggle:vm_canExpand,text:c_expandButtonText',
        '@ui.duration': 'text:prettyDuration(vm_date_start_local,vm_date_end_local)',
        '@ui.statusBadge': 'text:c_statusBadgeText',
        '@ui.status': 'classes:c_statusClasses'
    },

    events: {
        'click @ui.expandBtn'() {
            this.viewModel.set('vm_fieldsExpanded', !this.viewModel.get('vm_fieldsExpanded'));
        }
    },

    bindingHandlers: {
        dateTime,
        toggleChanged: {
            set($el, viewModel) {
                $el.each((i, el) => {
                    const attrs = (el.getAttribute('data-js-field') || '').split(',');
                    const isChanged = attrs.some(attr => viewModel.get('vm_changes')[attr]);
                    el.classList.toggle('row__active', isChanged);

                    el.style.display = (viewModel.get('vm_fieldsExpanded') || isChanged) ? null : 'none';
                });
            }
        }
    },

    bindingFilters: {
        prettyDuration: (from, to) => prettyDuration(moment(to).diff(from))
    },
})
class ReservationChangeCommentAdditionalData extends ItemView {
    initialize() {
        this.viewModel = new ViewModel({
            parentViewModel: this.options.parentViewModel || null,
            vm_fieldsExpanded: false,
            vm_changes: {}
        });

        this.viewModel.addComputed('vm_canExpand', {
            deps: ['vm_changes'],
            get: changes => _.some(changes, (v, k) => !v && k != 'vm_status')
        });

        this._fillViewModelWithRealData();
    }

    templateHelpers() {
        return {
            hasChildrenCount: this.model.get('opt_children_count') > 0,
            smokingZoneText: SmokingZoneText[this.viewModel.get('vm_opt_smoking_area')] || 'Any',
            vm_canExpand: this.viewModel.get('vm_canExpand'),
            ...this.viewModel.pick(MODEL_PROPERTIES.map(p => 'vm_' + p))
        }
    }

    _fillViewModelWithRealData() {
        const prevValues = this.model.get('previous_values');
        const values = this.model.toJSON();

        for (const prop of MODEL_PROPERTIES) {
            const prevValue = prevValues[prop];

            if (_.has(values, prop)) {
                const newValue = this.model.get(prop);
                this.viewModel.set('vm_' + prop, newValue);
                this.viewModel.modifyObject('vm_changes', 'vm_' + prop, prevValue != newValue);
            } else {
                this.viewModel.set('vm_' + prop, prevValue);
                this.viewModel.modifyObject('vm_changes', 'vm_' + prop, false);
            }
        }

        if (_.has(values, 'date_start_local') != _.has(values, 'date_end_local')) {
            this.viewModel.modifyObject('vm_changes', 'vm_duration', true);
        } else if (_.has(values, 'date_start_local') && _.has(values, 'date_end_local')) {
            const oldDuration =
                moment(B2Cjs.datetimeServerLocalToJS(prevValues.date_end_local)).startOf('minute')
                    .diff(moment(B2Cjs.datetimeServerLocalToJS(prevValues.date_start_local)).startOf('minute'));
            const newDuration =
                moment(B2Cjs.datetimeServerLocalToJS(values.date_end_local)).startOf('minute')
                    .diff(moment(B2Cjs.datetimeServerLocalToJS(values.date_start_local)).startOf('minute'));

            this.viewModel.modifyObject('vm_changes', 'vm_duration', Math.abs(oldDuration - newDuration) > 30000);

        } else {
            this.viewModel.modifyObject('vm_changes', 'vm_duration', false);
        }
    }
}

export default ReservationChangeCommentAdditionalData;
