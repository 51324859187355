import { Model, Collection, CollectionView } from '@b2cmessenger/backbone';
import settings from 'settings';
import InfoModal from 'windows/Modal/Info';
import Window from 'windows/Window';
import templateContent from './Content.jade';
import BrandsChild from './BrandsChild';

import HeaderView from 'widgets/HeaderWithTabs/HeaderWithTabs';
import FooterView from 'widgets/Footer/Footer';
import Behaviors from 'utils/Behaviors';
import SearchView from './Search';
import GiftsLayout from './GiftsLayout.jade';
import './Gifts.scss';
import ViewWithWindows from 'traits/ViewWithWindows';
import GiftsList from './GiftsList';

const withWindowsTrait = {
    Trait: ViewWithWindows,
    options: {
        windowMap: [
            {
                cls: GiftsList,
                trigger(value) {
                    if (value && value.brandId) {
                        new GiftsList({
                            userId: LoginedUserHandler.loginedUser.id,
                            brandId: value.brandId
                        }).show();
                    }
                }
            }
        ],
    }
};

export default Window.extendWithTraits([withWindowsTrait], {
    windowName: 'brands-list-window',
    className: 'brands-list-window',
    template: GiftsLayout,
    regions: {
        header: '[data-js-header]',
        search: '[data-js-search-panel]',
        footer: '[data-js-footer]',
        content: '[data-js-content] .brands-list'
    },
    onRender() {
        this.renderHeader();

        let searchView = new SearchView();

        // Обработка фильтра по имени
        searchView.on('change', (keyword) => {
            this.collectionView.filter = function(child, index, collection) {
                return child.get('name').toLowerCase().indexOf(keyword.toLowerCase()) > -1;
            };

            this.collectionView.render();
        });

        this.search.show(searchView);

        this.dataRequest();
    },
    renderHeader() {
        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: 'Gifts'
        });

        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.listenTo(headerView, 'add:click', () => this.showEditWindow());

        this.header.show(headerView);
    },
    dataRequest() {
        Server.callServer({
            url: settings.host + settings.serv_gift.brands,
            data: {
                only_active: ''
            },
            type: 'POST',
            error: this.showError.bind(this)
        }).then(this.onFetch.bind(this));
    },
    onFetch(data) {
        let models = data.brands;

        this.collectionView = new (CollectionView.extend({
            className: 'brands-list-inner',
            childView: BrandsChild,
            collection: new Collection(models)
        }));

        this.content.show(this.collectionView);
    },

    cancel() {
        this.close(this.model);
    }
});
