import React from 'react';
import MenuList, { MenuListItem } from 'components/menu/MenuList/MenuList';
import MenuTextItems, { getTextItemsFromOrderFormattedItems } from 'components/menu/MenuItems/MenuTextItems';
import withScrollWatcher from 'components/withScrollWatcher';
import MenuPlaceOrderActions, { OrderActionButton } from 'components/menu/MenuPlaceOrder/MenuPlaceOrderActions';
import { isOrderActive, isOrderFinished } from 'components/menu/utils';

import './MenuPlaceOrders.scss';
import { MenuOrderComment } from 'components/menu/MenuOrder/MenuOrder';
import { MenuPlaceOrderBase } from './MenuPlaceOrder';

const MenuListItemWithScrollWatcher = withScrollWatcher(MenuListItem);
const MenuPlaceOrders = (props) => {
    const {
        items = [],
        onOpenOrderClick = _.noop,
        onChangeStatusClick = _.noop,
        onEditOrderClick = _.noop,
        displayPosition = true,
        displayPrice = false,
    } = props;

    const itemList = items.map((i, idx, list) => {
        const onInViewportChange = list.length - 1 === idx ? props.onLastItemInViewportChange : _.noop;
        const textItems = getTextItemsFromOrderFormattedItems(i, {
            includePosition: displayPosition,
            includePrice: displayPrice,
        });
        const comment = i.data && i.data.comment;

        const waiterRequestsCount = i.waiterRequests ? i.waiterRequests.length : 0;
        const waiterRequestsBadge =
            waiterRequestsCount > 0 ? <b className="round-badge">{waiterRequestsCount}</b> : null;

        return (
            <MenuListItemWithScrollWatcher
                className="menu-list-item-wrap"
                key={i.id}
                onInViewportChange={onInViewportChange}
            >
                <MenuPlaceOrderBase className="menu-list-item" {...i}>
                    <MenuOrderComment comment={comment} />
                    <MenuTextItems items={textItems} limit={3} onOpenOrderClick={onOpenOrderClick.bind(null, i)} />
                    <MenuPlaceOrderActions>
                        <OrderActionButton className="wide" onClick={onOpenOrderClick.bind(null, i)}>
                            Open{waiterRequestsBadge}
                        </OrderActionButton>
                        {isOrderActive(i) && (
                            <OrderActionButton className="wide" onClick={onEditOrderClick.bind(null, i)}>
                                Edit
                            </OrderActionButton>
                        )}
                        {!isOrderFinished(i) && (
                            <OrderActionButton className="wide" onClick={onChangeStatusClick.bind(null, i)}>
                                Change Status
                            </OrderActionButton>
                        )}
                    </MenuPlaceOrderActions>
                </MenuPlaceOrderBase>
            </MenuListItemWithScrollWatcher>
        );
    });

    return (
        <MenuList {...props} type="place-orders">
            {itemList}
        </MenuList>
    );
};

export default MenuPlaceOrders;
