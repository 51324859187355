import React from 'react';
import PropTypes from 'prop-types';

import './MenuCart.scss';
import TextButton from 'components/UI/TextButton';
import MenuItemsWithControl from 'components/menu/MenuItems/MenuItemsWithControl';
import MenuCartItemInfo from 'components/menu/MenuCart/MenuCartItemInfo';

function toCartMenuItem(cartItem, items) {
    return {
        ...cartItem,
        ...items[cartItem.id],
        count: cartItem.quantity,
        properties: _.chain(cartItem.properties)
            .keys()
            .map((key) => ({ name: key, value: cartItem.properties[key] }))
            .value(),
        additions: _.chain(cartItem.additions)
            .keys()
            .map((key) => {
                const count = cartItem.additions[key];
                const addition = items[key];

                return {
                    ...addition,
                    count: cartItem.quantity > 0 ? count : cartItem.quantity,
                };
            })
            .value(),
    };
}

const MenuCart = (props) => {
    const { isEditingOrder, onChangeItemCount, onChangeAdditionItemCount, goToMainMenuPage = _.noop } = props;

    const onChangeAddition = React.useCallback(
        (uniqueId, changes) => {
            if (_.isFunction(onChangeAdditionItemCount)) {
                onChangeAdditionItemCount(changes, uniqueId);
            }
        },
        [onChangeAdditionItemCount]
    );

    const onChangeCount = React.useCallback(
        ({ item = {}, count = 0 } = {}) => {
            if (_.isFunction(onChangeItemCount)) {
                onChangeItemCount({ item: _.omit(item, 'uniqueId'), count }, item.uniqueId);
            }
        },
        [onChangeItemCount]
    );

    const onSelectItem = React.useCallback(
        (id, item) => {
            if (_.isFunction(props.onSelectItem)) {
                const menuItem = props.items[id];
                const cartItem = props.cartItems.find((ci) => ci.uniqueId === item.uniqueId);
                props.onSelectItem.call(null, { menuItem, cartItem, uniqueId: item.uniqueId });
            }
        },
        [props.onSelectItem, props.items, props.cartItems]
    );

    const childrenFn = React.useCallback(
        (i) => {
            return (
                <MenuCartItemInfo
                    {...i}
                    onChangeAddition={onChangeAddition}
                    onClick={onSelectItem.bind(null, i.id, i)}
                />
            );
        },
        [onChangeAddition, onSelectItem]
    );

    const listItemClassName = React.useCallback((i) => {
        return ['menu-list-item-wrap', i.count === 0 && 'faded'].filter(Boolean).join(' ');
    }, []);

    const items = React.useMemo(() => {
        return _.map(props.cartItems, (cartItem) => toCartMenuItem(cartItem, props.items));
    }, [props.cartItems, props.items]);

    const { existingItems = [], unsavedItems = [] } = React.useMemo(() => {
        return _.groupBy(items, (i) => (_.has(i, 'orderItemId') ? 'existingItems' : 'unsavedItems'));
    }, [items]);

    return (
        <div className="menu-cart-content">
            {existingItems && existingItems.length > 0 ? (
                <MenuItemsWithControl
                    className="menu-list-cart-items"
                    items={existingItems}
                    onChangeCount={onChangeCount}
                    childrenFn={childrenFn}
                    onSelectItem={onSelectItem}
                    listItemClassName={listItemClassName}
                />
            ) : null}
            {isEditingOrder && (
                <>
                    <p className="new-items-info">You can add new items to this order in the catalog</p>
                    <TextButton
                        onClick={goToMainMenuPage}
                        className="go-to-catalog medium normalized border-filled with-top-margin"
                    >
                        Go to catalog
                    </TextButton>
                </>
            )}
            {unsavedItems && unsavedItems.length > 0 ? (
                <MenuItemsWithControl
                    className="menu-list-cart-items"
                    items={unsavedItems}
                    onChangeCount={onChangeCount}
                    childrenFn={childrenFn}
                    onSelectItem={onSelectItem}
                    listItemClassName={listItemClassName}
                />
            ) : null}
        </div>
    );
};

MenuCart.propTypes = {
    cartItems: PropTypes.array,
    items: PropTypes.object,
    onSelectItem: PropTypes.func,
    onChangeItemCount: PropTypes.func,
    onChangeAdditionItemCount: PropTypes.func,
    isEditingOrder: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default MenuCart;
