import _ from 'underscore';
import { Collection } from '@b2cmessenger/backbone';

import EvidenceModel from 'models/EvidenceModel';

export default Collection.extend({
    model: EvidenceModel,

    initialize(models, options) {
        this.defaultFilter = _.defaults(options, {
            type: EvidenceModel.Type.Other,
            only_valid: 1,
            need_users: 1,
        });
    },

    sync(method, model, options) {
        _.defaults(options || (options = { parse: true }), this.defaultFilter);

        if(!options.place_id) throw new Error("options and defaultFilter have no place_id!");

        var error = options.error;
        options.error = function(xhr, textStatus, errorThrown) {
            options.textStatus = textStatus;
            options.errorThrown = errorThrown;
            if (error) error.call(options.context, xhr, textStatus, errorThrown);
        };

        var success = options.success;
        options.success = data => {
            if(_.isArray(data.users)) 
                options.users = _(data.users).reduce(
                    (users, u) => { 
                        users[u.id] = u; 
                        return users; 
                    }, 
                    { 
                        [LoginedUserHandler.getLoginedUser().id]: LoginedUserHandler.getLoginedUser()
                    });
            if(!_.isArray(data.evidences)) data.evidences = [];

            if (success) success.call(options.context, data.evidences);
        };

        if(method == "read") {
            let filter = _(options).pick(['user_id', 'place_id', 'type', 'status', 'only_valid', 'need_users']);
            let xhr = B2CEvidence.server_api_search(filter, options.success, options.error);
            model.trigger('request', model, xhr, options);
            return xhr;
        } else {
            throw new Error("Not implemented!");
        }
    }
});
