import React, { useState, useMemo, useCallback, useEffect } from 'react';

import ReactPage from 'pages/ReactPage';
import { connect } from 'react-redux';
import Page, { PageContent, PageFooter, PageHeader } from 'components/page/Page';
import Header from 'components/UI/Header/Header';
import {
    BackButton,
    CartButton,
    CommentButton,
    EditButton,
    MenuButton,
    ReorderButton,
    SearchButton,
} from 'components/UI/Button/Button';
import {
    getMenuCartItemsMap,
    getMenuCategories,
    getMenuError,
    getMenuIsErrorCritical,
    getMenuItems,
    getMenuLoading,
    getMenuSelectedCategory,
    getMenuSelectedParentCategory,
    getMenuSelectedCategoryId,
    getMenuIsEditPossible,
    getMenuInfoPermissions,
    getMenuCartItemsGroupedByItemId,
    getMenuCartDistinctRegularItems,
    getMenuCartTotalQuantity,
} from 'store/menu/selectors';
import MenuRoot from 'components/menu/MenuRoot/MenuRoot';
import withStore from 'components/withStore';
import {
    changeMenuElementsOrder,
    loadCategory,
    removeCategory,
    removeItem,
    upsertCategory,
    upsertItem,
} from 'store/menu/actions';
import Window from 'windows/Window';
import settings from 'settings';
import MenuCategoryEditorWindow from 'windows/MenuCategoryEditor/MenuCategoryEditor';
import ReactModalWindow from 'windows/ReactModalWindow';
import MenuCategoryRemoveConfirmModal from 'components/window/MenuCategoryRemoveConfirm';
import MenuCategoryModel from 'models/MenuCategoryModel';
import MenuItemEditorWindow from 'windows/MenuItemEditor/MenuItemEditorWindow';
import MenuItemRemoveConfirmModal from 'components/window/MenuItemRemoveConfirm';
import * as MenuAPI from 'utils/api/menu';
import { showLoading, hideLoading } from 'windows/Modal/Loading';
import { showError } from 'windows/Modal/Info';
import MenuVisibilityEditorWindow, {
    MenuVisibilityRemoveWindow,
} from 'windows/MenuVisibilityEditor/MenuVisibilityEditor';
import MenuCartItemCustomizeWindow from 'components/window/MenuCartItemCustomize/MenuCartItemCustomize';
import MenuCartItemRemoveChooserWindow from 'components/window/MenuCartItemRemoveChooser/MenuCartItemRemoveChooser';
import {
    addToCart,
    changeCartItem,
    decreaseQuantityOrRemoveFromCart,
    removeFromCartByUniqueId,
} from 'store/menu/cart/actions';
import { UnlistedCategory } from 'utils/api/menu';
import useInitializeMenu from 'hooks/useInitializeMenu';
import TextButton from 'components/UI/TextButton';
import Footer from 'components/UI/Footer/Footer';
import { getMenuCartOrderParams } from 'store/menu/cart/selectors';
import MenuSearchWindow from 'components/window/MenuSearch/MenuSearch';
import MenuWaiterCallWindow from 'components/window/MenuWaiterCall/MenuWaiterCall';

const MenuContent = (props) => {
    const { placeId, persistedCategoryId, categoryId } = props;
    const {
        loadCategory,
        upsertCategory,
        upsertItem,
        removeCategory,
        removeItem,
        changeMenuElementsOrder,
        addToCart,
        changeCartItem,
        removeFromCartByUniqueId,
        decreaseQuantityOrRemoveFromCart,
    } = props;

    const {
        error,
        isErrorCritical,
        categories,
        selectedCategory,
        parentCategory,
        items,
        loading,
        itemsCountMap,
        groupedCartItems,
        distinctItemsFromCart,
        cartSize,
    } = props;

    const { editable = false, sortable = false, isEditPossible, isWaiterCallPossible } = props;
    const { cancel, isActive } = props;

    const [previousUsedAdditionsItemMap, setPreviousUsedAdditionsItemMap] = useState([]);

    const { shouldInitMenu, readyToInitialize, initialized } = useInitializeMenu({ placeId, categoryId, isActive });

    const shouldChangeCategory = useMemo(
        () => !shouldInitMenu && Number(persistedCategoryId) !== Number(categoryId) && initialized,
        [shouldInitMenu, persistedCategoryId, categoryId, initialized]
    );

    const pageTitle = useMemo(() => {
        return (selectedCategory && selectedCategory.name) || 'Menu';
    }, [selectedCategory]);

    const displayReorderButton = useMemo(() => {
        return editable && Number(categoryId) !== Number(UnlistedCategory.id);
    }, [editable, categoryId]);

    const goBack = useCallback(() => {
        const payload = { placeId, sortable, editable };

        if (sortable) {
            app.controller.goToMenuPage({
                ...payload,
                categoryId: selectedCategory && selectedCategory.id,
                sortable: false,
            });
        } else if (selectedCategory && selectedCategory.parent_id) {
            app.controller.goToMenuPage({
                ...payload,
                categoryId: selectedCategory.parent_id,
            });
        } else if (selectedCategory && selectedCategory.parent_id === null) {
            app.controller.goToMenuPage({
                ...payload,
                categoryId: null,
            });
        } else {
            cancel();
            return true;
        }
    }, [selectedCategory, placeId, cancel, editable, sortable]);

    const toggleEdit = useCallback(() => {
        app.controller.goToMenuPage({ placeId, categoryId, editable: !editable, sortable: false });
    }, [placeId, categoryId, editable, sortable]);

    const toggleSort = useCallback(() => {
        app.controller.goToMenuPage({ placeId, categoryId, editable, sortable: !sortable });
    }, [placeId, categoryId, editable, sortable]);

    const goToCategory = useCallback(
        (categoryId) => {
            app.controller.goToMenuPage({ categoryId, placeId, editable, sortable });
        },
        [placeId, editable, sortable]
    );

    const goToItem = useCallback(
        (itemId) => {
            app.controller.goToMenuItemPage({ itemId, placeId });
        },
        [placeId]
    );

    const goToCart = useCallback(() => {
        app.controller.goToMenuCart({ placeId });
    }, [placeId]);

    const onCreateCategory = useCallback(async () => {
        if (selectedCategory || selectedCategory === null) {
            const categoryModel = await new MenuCategoryEditorWindow({
                placeId,
                category: {
                    parent_id: (selectedCategory && selectedCategory.id) || null,
                },
                parentCategory: selectedCategory,
            }).show();

            if (categoryModel) {
                upsertCategory(categoryModel.toJSON());
            }
        }
    }, [placeId, selectedCategory]);

    const onEditCategory = useCallback(
        async (categoryId) => {
            const category = categories.find((c) => Number(c.id) === Number(categoryId));

            const categoryModel = await new MenuCategoryEditorWindow({
                placeId,
                category,
                parentCategory,
            }).show();

            if (categoryModel) {
                upsertCategory(categoryModel.toJSON());
            }
        },
        [placeId, categories, parentCategory]
    );

    const onRemoveCategory = useCallback(
        async (categoryId) => {
            const category = categories.find((c) => Number(c.id) === Number(categoryId));

            if (category && placeId) {
                const confirm = await new ReactModalWindow({
                    name: 'MenuCategoryRemoveConfirmModal',
                    component: MenuCategoryRemoveConfirmModal,
                    intent: ReactModalWindow.Intent.Negative,
                    props: {
                        category,
                        parentCategory,
                    },
                }).show();

                if (confirm) {
                    showLoading();

                    try {
                        await new Promise((resolve, reject) => {
                            const model = new MenuCategoryModel({ id: categoryId });

                            model.destroy({
                                placeId,
                                moveToParent: confirm.moveToParent,
                                wait: true,
                                success: resolve,
                                error: reject,
                            });
                        });

                        removeCategory(categoryId, confirm.moveToParent);
                    } catch (e) {
                        showError(e);
                    } finally {
                        hideLoading();
                    }
                }
            }
        },
        [placeId, categories, parentCategory]
    );

    const onCreateItem = useCallback(() => {
        new MenuItemEditorWindow({ placeId }).show();
    }, [placeId]);

    const onEditItem = useCallback(
        (itemId) => {
            new MenuItemEditorWindow({ placeId, menuElementId: itemId }).show();
        },
        [placeId]
    );

    const onRemoveItem = useCallback(
        async (itemId, item, parentCategory) => {
            const confirm = await new ReactModalWindow({
                name: 'MenuItemRemoveConfirmModal',
                component: MenuItemRemoveConfirmModal,
                intent: ReactModalWindow.Intent.Negative,
                props: {
                    item: { ...item },
                    category: parentCategory && { ...parentCategory },
                },
            }).show();

            if (confirm) {
                showLoading();

                try {
                    await MenuAPI.removeItem({
                        placeId,
                        id: itemId,
                        categoryId: confirm.fromCategory ? parentCategory.id : null,
                    });
                    removeItem(itemId);
                } catch (e) {
                    showError(e);
                } finally {
                    hideLoading();
                }
            }
        },
        [placeId]
    );

    const onToggleVisibility = useCallback(
        async (obj) => {
            const options = {
                placeId,
                ...(_.has(obj, 'is_addition') ? { category: { ...obj } } : { item: { ...obj } }),
            };

            const window =
                obj.is_hidden_forever || obj.hidden_until
                    ? new MenuVisibilityRemoveWindow(options)
                    : new MenuVisibilityEditorWindow(options);

            const resultObj = await window.show();

            if (resultObj) {
                // @ts-ignore
                if (options.category) {
                    upsertCategory(resultObj);
                } else {
                    upsertItem(resultObj);
                }
            }
        },
        [placeId]
    );

    const displayMenuSettings = useCallback(() => {
        app.controller.goToMenuSettings({ placeId });
    }, [placeId]);

    const changeOrderSave = useCallback(
        (categoriesIds, itemsIds) => {
            const categoriesIdsWithoutVirtual = categoriesIds ? categoriesIds.filter((id) => id > 0) : null;

            changeMenuElementsOrder(categoriesIdsWithoutVirtual, itemsIds, categoryId);
        },
        [categoryId]
    );

    const onAddToCart = useCallback(
        (item) => {
            LoginedUserHandler.ensureUserLoggedIn(async () => {
                if (item.is_customizable) {
                    const result = await new MenuCartItemCustomizeWindow({
                        menuItemId: item.id,
                        placeId,
                        previousUsedAdditions: previousUsedAdditionsItemMap[item.id],
                    }).show();

                    if (result) {
                        const { menuItemId, count, additions, properties, comment } = result;
                        addToCart({ id: menuItemId, additions, properties, comment }, count);
                        setPreviousUsedAdditionsItemMap((prev) => ({
                            ...prev,
                            [menuItemId]: additions,
                        }));
                    }
                    return;
                }

                addToCart({ id: item.id }, 1);
            });
        },
        [placeId, previousUsedAdditionsItemMap]
    );

    const onRemoveFromCart = useCallback(
        async (item, count) => {
            const cartItems = groupedCartItems[item.id];

            if (cartItems.length > 1) {
                const result = await new MenuCartItemRemoveChooserWindow({
                    cartItems,
                    items: distinctItemsFromCart,
                }).show();

                if (result) {
                    for (const changeKey in result.change) {
                        if (result.change.hasOwnProperty(changeKey)) {
                            const value = result.change[changeKey];
                            const cartItem = cartItems.find((c) => String(c.uniqueId) === String(changeKey));
                            changeCartItem(cartItem, value);
                        }
                    }

                    for (const removeKey in result.remove) {
                        if (result.remove.hasOwnProperty(removeKey)) {
                            removeFromCartByUniqueId(removeKey);
                        }
                    }
                }
            } else {
                decreaseQuantityOrRemoveFromCart(_.first(cartItems), count > 1 ? 1 : null);
            }
        },
        [distinctItemsFromCart, groupedCartItems, decreaseQuantityOrRemoveFromCart]
    );

    // handle `backbutton` event
    useEffect(() => {
        const interceptor = (e) => {
            if (Window.isActiveWindow()) return;

            e.preventDefault();
            e.stopPropagation();
            window.removeEventListener(settings.backbutton.eventName, interceptor, true);

            if (!goBack()) {
                window.addEventListener(settings.backbutton.eventName, interceptor, true);
            }
        };

        if (isActive) {
            window.addEventListener(settings.backbutton.eventName, interceptor, true);
        }

        return () => {
            if (isActive) {
                window.removeEventListener(settings.backbutton.eventName, interceptor, true);
            }
        };
    }, [isActive, goBack]);

    // handle category change
    useEffect(() => {
        if (shouldChangeCategory) {
            loadCategory(categoryId);
        }
    }, [shouldChangeCategory, categoryId]);

    const onShow = useCallback(() => {
        if (readyToInitialize) {
            readyToInitialize(true);
        }
    }, [readyToInitialize]);

    const onSearch = useCallback(async () => {
        try {
            new MenuSearchWindow({ placeId }).show();
        } catch (e) {
            showError(e);
        }
    }, [placeId]);

    const onWaiteCallClick = useCallback(async () => {
        try {
            new MenuWaiterCallWindow({
                isEmployee: isEditPossible,
            }).show();
        } catch (e) {
            showError(e);
        }
    }, [isEditPossible]);

    return (
        <Page instance={props.__page__} onShow={onShow}>
            <PageHeader>
                <Header>
                    <BackButton onClick={goBack} />
                    <h1 className="title">{pageTitle}</h1>
                    {isWaiterCallPossible ? <CommentButton onClick={onWaiteCallClick} /> : null}
                    {displayReorderButton ? <ReorderButton onClick={toggleSort} active={sortable} /> : null}
                    {isEditPossible ? <EditButton onClick={toggleEdit} active={editable} /> : null}
                    <SearchButton onClick={onSearch} />
                    <CartButton quantity={cartSize} onClick={goToCart} />
                    <MenuButton right />
                </Header>
            </PageHeader>
            <PageContent className="scrollable grow with-standard-padding">
                <MenuRoot
                    editable={editable}
                    sortable={sortable}
                    canBeEditable={isEditPossible}
                    error={error}
                    isErrorCritical={isErrorCritical}
                    categories={categories}
                    selectedCategory={selectedCategory}
                    parentCategory={parentCategory}
                    items={items}
                    loading={loading}
                    itemsCountMap={itemsCountMap}
                    onSelectCategory={goToCategory}
                    onSelectItem={goToItem}
                    onAddCategory={onCreateCategory}
                    onRemoveCategory={onRemoveCategory}
                    onEditCategory={onEditCategory}
                    onAddItem={onCreateItem}
                    onEditItem={onEditItem}
                    onRemoveItem={onRemoveItem}
                    onToggleVisibility={onToggleVisibility}
                    onDisplayMenuPreferences={displayMenuSettings}
                    onChangeOrderSave={changeOrderSave}
                    onAddToCart={onAddToCart}
                    onRemoveFromCart={onRemoveFromCart}
                />
            </PageContent>
            {Boolean(props.edititableOrderParams && props.edititableOrderParams.number) && (
                <PageFooter>
                    <Footer>
                        <TextButton onClick={goToCart}>
                            BACK TO EDIT ORDER #{props.edititableOrderParams.number}
                        </TextButton>
                    </Footer>
                </PageFooter>
            )}
        </Page>
    );
};

const ConnectedMenuContent = withStore(
    connect(
        (state) => ({
            persistedCategoryId: getMenuSelectedCategoryId(state),
            isEditPossible: getMenuIsEditPossible(state),
            isWaiterCallPossible: getMenuInfoPermissions(state).isLoggedIn,
            error: getMenuError(state),
            isErrorCritical: getMenuIsErrorCritical(state),
            categories: getMenuCategories(state),
            selectedCategory: getMenuSelectedCategory(state),
            parentCategory: getMenuSelectedParentCategory(state),
            items: getMenuItems(state),
            loading: getMenuLoading(state),
            itemsCountMap: getMenuCartItemsMap(state),
            groupedCartItems: getMenuCartItemsGroupedByItemId(state),
            distinctItemsFromCart: getMenuCartDistinctRegularItems(state),
            cartSize: getMenuCartTotalQuantity(state),
            edititableOrderParams: getMenuCartOrderParams(state),
        }),
        {
            loadCategory,
            upsertCategory,
            upsertItem,
            removeCategory,
            removeItem,
            changeMenuElementsOrder,
            addToCart,
            changeCartItem,
            removeFromCartByUniqueId,
            decreaseQuantityOrRemoveFromCart,
        }
    )(MenuContent)
);

const MenuPage = ReactPage.extend({
    immediateRenderOnShow: true,
    attributes: {
        id: 'page_menu',
    },
    className: 'menu-page',
    component: ConnectedMenuContent,
});

export default MenuPage;
