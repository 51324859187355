import React, { Fragment, useCallback, useEffect } from 'react';
import { ButtonFileInput } from 'components/FileInput';

import './MenuCategoryEditorContent.scss';
import { SliderGroup, SwitchInput, TextInputGroup } from 'components/UI/InputGroup/InputGroup';
import MenuCategories from 'components/menu/MenuCategories/MenuCategories';

const MenuCategoryPhoto = ({ photo, photo_thumb, onPhotoSelect }) => {
    const hasPhoto = (photo === null || Number(photo) > 0) && _.isString(photo_thumb);
    const className = ['menu-category-photo', !hasPhoto && 'empty'].filter(Boolean).join(' ');

    const placeholderText = hasPhoto ? 'Tap to change' : 'Add photo';
    const previewStyle = { backgroundImage: (photo_thumb && `url(${photo_thumb})`) || null };

    return (
        <div className={className}>
            <ButtonFileInput onChange={onPhotoSelect} className="wide">
                <div className="photo-preview" style={previewStyle}>
                    <div className="photo-placeholder">{placeholderText}</div>
                </div>
            </ButtonFileInput>
        </div>
    );
};

const MenuCategoryEditorContent = (props) => {
    const {category} = props;
    const parentCategory = props.parentCategory || null;

    const isNew = !category.id;
    const categoryTypeSelectDisabled = !isNew || parentCategory !== null;

    const setCategoryName = useCallback(
        (name) => {
            props.onCategoryChanged({ ...category, name });
        },
        [category, props.onCategoryChanged]
    );

    const setCategoryType = useCallback(
        (value) => {
            props.onCategoryChanged({ ...category, is_addition: Number(Boolean(value)) });
        },
        [category, props.onCategoryChanged]
    );

    useEffect(() => {
        if (isNew && parentCategory !== null) {
            setCategoryType(parentCategory.is_addition);
        }
    }, [parentCategory, isNew, setCategoryType]);

    const sliderGroupInfo = category.is_addition
        ? 'Items of this category could be additions to other menu items'
        : null;

    return (
        <>
            <div className="menu-category-editor-content">
                <TextInputGroup label="Name">
                    <TextInput onChange={setCategoryName} text={category.name} placeholder="Enter category name" />
                </TextInputGroup>
                {parentCategory !== null && (
                    <SliderGroup
                        caption="Parent category"
                        disabled
                        info={<MenuCategories categories={[parentCategory]} className="menu-list-compact" />}
                    />
                )}
                <SliderGroup
                    disabled={categoryTypeSelectDisabled}
                    caption="Category of additions"
                    info={sliderGroupInfo}
                >
                    <SwitchInput
                        disabled={categoryTypeSelectDisabled}
                        onChange={setCategoryType}
                        value={category.is_addition}
                    />
                </SliderGroup>
                <MenuCategoryPhoto
                    photo={category.photo}
                    photo_thumb={category.photo_thumb}
                    onPhotoSelect={props.uploadPhoto}
                />
            </div>
        </>
    );
};

export const InputGroup = ({ caption, icon, error, children, className }) => {
    return (
        <div className={className || 'input-group'}>
            {icon && <i className={icon} />}
            <label>
                {caption && <p>{caption}</p>}
                {error && <p className="error">{error}</p>}
                {children}
            </label>
        </div>
    );
};

const TextInput = ({ placeholder, text, onChange }) => {
    const onChangeHandler = (e) => {
        onChange(e.target.value);
    };

    return <input type="text" placeholder={placeholder} onChange={onChangeHandler} value={text} data-role="none" />;
};

export default MenuCategoryEditorContent;
