import { Marionette } from '@b2cmessenger/backbone';
import { Model } from '@b2cmessenger/backbone';

import searchPanel from './SearchPanel.jade';
import './SearchPanel.scss';

let SearchPanelModel = Model.extend({
    defaults: {
        placeholder: '',
        withFilter: true,
        iconClass: 'ion-ios-search-outline',
        iconContent: ''
    }
})

let SearchPanelView = Marionette.ItemView.extend({
    template: searchPanel,

    tagName: "div",
    className: "input-group",

    ui: {
        inSearch: '[data-js-input-search]',
        btnClear: '[data-js-btn-clear]',
        btnFilter: '[data-js-btn-filter]'
    },

    events: {
        'change @ui.inSearch' : 'onChange',
        'keyup @ui.inSearch' : 'onKeyUp',
        'click @ui.btnClear': 'onClear'
    },

    triggers: {
        'click @ui.btnFilter' : 'filter:click'
    },

    initialize(options) {
        if (!this.model) {
            this.model = new SearchPanelModel(options || {});
        } else {
            this.model.set(options || {});
        }

        this.listenTo(this, 'filter:activate', this.onBtnFilterActivate);
        this.listenTo(this, 'filter:deactivate', this.onBtnFilterDeactivate);

        this.inSearchText = '';
    },

    onRender() {
        var text = this.ui.inSearch.val();

        if(this.ui.inSearch.val()) {
            this.ui.btnClear.removeClass('hidden');
        } else {
            this.ui.btnClear.addClass('hidden');
        }

        this.inSearchText = text;
    },

    onBtnFilterActivate(activate = true) {
        if(activate) {
            this.ui.btnFilter.addClass('active');
        } else {
            this.ui.btnFilter.removeClass('active');
        }
    },

    onBtnFilterDeactivate() {
        this.onBtnFilterActivate(false);
    },

    onChange(event) {
        this.triggerTextChange();

        if(this.ui.inSearch.val()) {
            this.ui.btnClear.removeClass('hidden');
        } else {
            this.ui.btnClear.addClass('hidden');
        }
    },

    onKeyUp(event) {
        var text = this.ui.inSearch.val();

        if(event.which == 13)
            this.triggerTextChange();

        if(this.ui.inSearch.val()) {
            this.ui.btnClear.removeClass('hidden');
        } else {
            this.ui.btnClear.addClass('hidden');
        }

        if (this.inSearchText != text) {
            this.inSearchText = text;
            this.trigger('text:keyup', text);
        }
    },

    onClear() {
        this.inSearchText = '';
        this.ui.inSearch.val('').change();
    },

    triggerTextChange: _.throttle(function() {
        this.trigger('text:change', this.ui.inSearch.val());
    }, 300, { leading: false })
});

export default SearchPanelView;
