import { LayoutView, Model } from '@b2cmessenger/backbone';
import notificationTemplate from './Notification.jade';
import NotificationModel from 'models/NotificationModel';
import GiftTemplatesItemView from 'windows/GiftTemplates/GiftTemplatesItemView';
import './Notification.scss';

const NotificationWidget = LayoutView.extend({
    options: {
        page: undefined
    },
    instances: _.create(null),
    timer: null,

    template: notificationTemplate,
    className: "notification-widget widget",

    regions: {
        gift: '[data-js-gift]'
    },

    ui: {
        title: '[data-js-title]',
        message: '[data-js-message]',
        gift: '[data-js-gift]',
        btnMarkRead: '[data-js-btn-mark-read]',
        btnAccept: '[data-js-btn-accept]',
        btnDecline: '[data-js-btn-decline]'
    },

    bindingHandlers: {
        typeClass: {
            set($element, value) {
                $element.addClass(value);
            }
        }
    },

    bindingFilters: {
        typeToClass(type) {
            if (type == NotificationModel.Type.NewTask) {
                return 'ion-ios-list-box-outline';
            } else if (type == NotificationModel.Type.NewComment) {
                return 'ion-ios-text-outline';
            } else if (type == NotificationModel.Type.TaskSolution) {
                return 'ion-ios-checkmark-circle-outline';
            } else if (type == NotificationModel.Type.EmployeeInvite) {
                return 'ion-ios-people-outline';
            } else if (type == NotificationModel.Type.NewGift) {
                return 'icon-gift2';
            } else if (type == NotificationModel.Type.NewTaskSolutionRating) {
                return 'ion-ios-star-outline';
            }
            return 'ion-ios-information-circle-outline';
        },

        isEmployeeInvite: type => type == NotificationModel.Type.EmployeeInvite,

        prettyDate: (createdAt, now) => {
            if (createdAt && now) {
                let diffMs = Math.abs(Number(now.getTime() - createdAt.getTime()));

                if (diffMs < 2 * 60 * 1000) return 'Now';
                if (diffMs < 60 * 60 * 1000) return `${Math.floor(diffMs / 60 / 1000)} minutes ago`;

                let isToday = (new Date(now).setHours(0, 0, 0, 0)) == (new Date(createdAt).setHours(0, 0, 0, 0));
                if (isToday) return createdAt.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

                let yesterday = new Date(now);
                yesterday.setDate(now.getDate() - 1);

                let isYesterday = (yesterday.setHours(0, 0, 0, 0)) == (new Date(createdAt).setHours(0, 0, 0, 0));
                if (isYesterday) return 'Yesterday ' + createdAt.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit'
                });

                return createdAt.toLocaleDateString(
                    [], {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    });
            } else {
                return '';
            }
        }
    },

    bindings: {
        ':el': 'attr:{"data-id":id},classes:{"is-read":isRead}',
        '.title-content': 'typeClass:typeToClass(app_event)',
        '[data-js-date]': 'text:prettyDate(createdAt,now)',
        '@ui.btnMarkRead': 'classes:{hidden:not(canBeRead)},disabled:isDisabled',
        '@ui.btnAccept': 'disabled:isDisabled',
        '@ui.btnDecline': 'disabled:isDisabled',
        '[data-js-invite-accept-decline-container]': 'classes:{hidden:not(isEmployeeInvite(app_event))}'
    },

    events: {
        'click @ui.btnMarkRead': 'onBtnMarkReadClick',
        'click @ui.btnAccept': 'onBtnAcceptClick',
        'click @ui.btnDecline': 'onBtnDeclineClick',
        'click @ui.title': 'onClick',
        'click @ui.message': 'onClick',
        'click @ui.gift': 'onClick'
    },

    initialize() {
        this.viewModel = new Model({
            isDisabled: false,
            now: new Date
        });

        this.instances[this.cid] = this;

        if (!NotificationWidget.prototype.timer) {
            NotificationWidget.prototype.timer =
                setInterval(
                    () => {
                        const now = new Date;
                        _.each(this.instances, instance => instance.viewModel.set({ now }));
                    },
                    30 * 1000
                );
        }
    },

    onRender() {
        // gift_template
        this.stopListening(this.model, 'change:gift_template');
        this.listenTo(this.model, 'change:gift_template', this.renderGiftTemplate);
        this.renderGiftTemplate();
    },

    onBeforeDestroy() {
        delete this.instances[this.cid];

        if (!_.size(this.instances)) {
            clearInterval(NotificationWidget.prototype.timer);
            delete NotificationWidget.prototype.timer;
        }
    },

    onBtnMarkReadClick() {
        if (this.model.get('canBeRead')) {
            this.viewModel.set('disabled', true);

            this.model.set({
                isNew: false
            });

            this.model.destroy({
                wait: true,
                error: (m, xhr) => this.onError(xhr, xhr.statusText)
            });
        }
    },

    onBtnAcceptClick() {
        let placeId = this.model.get('data').place_id;
        if (placeId) {
            this.viewModel.set('disabled', true);
            B2CEmployee.server_api_accept(
                placeId,
                B2CEmployee.const.status.accepted,
                data => {
                    this.model.destroy({
                        wait: true,
                        error: (m, xhr) => this.onError(xhr, xhr.statusText)
                    });

                    if (this.options.page) {
                        this.options.page.showLoading();
                    }

                    app.controller.restart()
                        .catch(e => this.options.page && this.options.page.showError(e))
                        .then(() => this.options.page && this.options.page.hideLoading());
                },
                (jqXHR, textStatus, errorThrown) => {
                    if (jqXHR.status == 422) {
                        this.model.destroy({
                            wait: true,
                            error: (m, xhr) => this.onError(xhr, xhr.statusText)
                        });
                    }
                    this.onError(jqXHR, textStatus, errorThrown);
                }
            )
        } else {
            this.onError(`placeId is falsey: ${JSON.stringify(this.model.get('data'), null, '  ')}`);
        }
    },

    onBtnDeclineClick() {
        let placeId = this.model.get('data').place_id;
        if (placeId) {
            this.viewModel.set('disabled', true);
            B2CEmployee.server_api_accept(
                placeId,
                B2CEmployee.const.status.declined,
                data => this.model.destroy({
                    wait: true,
                    error: (m, xhr) => this.onError(xhr, xhr.statusText)
                }),
                (jqXHR, textStatus, errorThrown) => {
                    if (jqXHR.status == 422) {
                        this.viewModel.set('disabled', false);
                        this.model.destroy({
                            wait: true,
                            error: (m, xhr) => this.onError(xhr, xhr.statusText)
                        });
                    } else {
                        this.onError(jqXHR, textStatus, errorThrown);
                    }
                }
            )
        } else {
            this.onError(`placeId is falsey: ${JSON.stringify(this.model.get('data'), null, '  ')}`);
        }
    },

    onClick() {
        app.executeNotificationAction(this.model);
    },

    onError(jqXHR, textStatus, errorThrown) {
        this.viewModel.set('disabled', false);

        this.showError(jqXHR, textStatus, errorThrown);
    },

    renderGiftTemplate() {
        const gift_template = this.model.get('gift_template');

        if (gift_template) {
            this.gift.show(new GiftTemplatesItemView({
                model: new Model(gift_template)
            }))
        } else {
            if (this.gift.currentView) {
                this.gift.currentView.destroy();
            }
        }
    },

});

export default NotificationWidget;