B2CGiftTemplate = {}

/**
 * Возвращает дату, до которой будет действителен подарок, сгенерированный на данном шаблоне, если его сгенерировать прямо сейчас
 * @param template {GiftTemplate} - шаблон подарка в формате сервера
 * @returns {null | Date} дата в JS формате
 */
B2CGiftTemplate.get_valid_end_js_date = function(template) {
    var valid_end_js_date; // Дата окончания действия подарка, если подарок будет сгенерирован в текущий момент
    if (template.period != null) {
        valid_end_js_date = new Date(new Date().setHours(23, 59, 59, 999) + template.period * 86400000);
    }
    if (template.total_period != null) {
        var total_js_period = B2Cjs.datetimeServerToJS(template.total_period);
        if (total_js_period) {
            var total_js_period_local = new Date(total_js_period);

            total_js_period_local.setFullYear(total_js_period.getUTCFullYear(), total_js_period.getUTCMonth(), total_js_period.getUTCDate());
            total_js_period_local.setHours(23, 59, 59, 999);
            if (valid_end_js_date == null || total_js_period_local < valid_end_js_date)
                valid_end_js_date = total_js_period_local;
        }
    }
    return valid_end_js_date;
};

/**
 * Статическая функция сохранения (создания / изменения) шаблона подарка на сервере
 * @param template {GiftTemplate} - шаблон подарка в формате сервера
 * @param onSuccess {function(data)} - функция обратного вызова с успешным ответом от сервера
 * @param onError {function(jqXHR, textStatus, errorThrown)} - функция обратного вызова с ошибкой от сервера
 */
B2CGiftTemplate.server_save = function(template, onSuccess, onError) {
    Server.callServer({
        url: pref.host+pref.serv_gift.template.save,
        type: "POST",
        cache: false,
        contentType: false,
        processData: false,
        data: template,
        success : onSuccess,
        error : onError
    });
};

/**
 * Статическая удаления шаблона подарка на сервере
 * @param id {int} - идентификатор шаблон подарка
 * @param onSuccess {function(data)} - функция обратного вызова с успешным ответом от сервера
 * @param onError {function(jqXHR, textStatus, errorThrown)} - функция обратного вызова с ошибкой от сервера
 */
B2CGiftTemplate.server_delete = function(id, onSuccess, onError) {
    Server.callServer({
        url: pref.host+pref.serv_gift.template.delete,
        type: "POST",
        data: {id : id},
        success : onSuccess,
        error : onError
    });
};

/**
 *
 * @param brand_id {int} - идентификатор бренда владельца
 * @param type {int} - тип шаблона B2CGiftTemplate.const.type
 * @param active {int} - активные или нет B2CGiftTemplate.const.active
 * @param onSuccess {function(data)} - функция обратного вызова с успешным ответом от сервера
 * @param onError {function(jqXHR, textStatus, errorThrown)} - функция обратного вызова с ошибкой от сервера
 */
B2CGiftTemplate.server_search = function(brand_id, type, active, onSuccess, onError) {
    Server.callServer({
        url: pref.host+pref.serv_gift.template.search,
        type: "POST",
        data: {
            brand_id : brand_id,
            type : type,
            active : active,
        },
        success : onSuccess,
        error : onError
    });
};


B2CGiftTemplate.const = {};

B2CGiftTemplate.const.type = {}; // Тип шаблона подарка
B2CGiftTemplate.const.type.coins = 1; // Баллы
B2CGiftTemplate.const.type.quantitive  = 2; // предполагает, частичное списание подарка, но не балы (5 чашек (списание одной))
B2CGiftTemplate.const.type.solid = 3; // целостный объект (скидка на все посещения)

B2CGiftTemplate.const.active = {}; // Активен ли текущий шаблон
B2CGiftTemplate.const.active.no = -1; // нет
B2CGiftTemplate.const.active.yes  = 1; // да

B2CGiftTemplate.const.coins = {}; // Константы специфичные для монет
B2CGiftTemplate.const.coins.url = 'common_res/icon/other/coins.png'; // изображение подарка по умолчанию
B2CGiftTemplate.const.coins.name = 'Coins'; // название подарка по умолчанию

B2CGiftTemplate.const.quantitive = {}; // Константы специфичные для исчесляемых подарков
B2CGiftTemplate.const.quantitive.url = 'common_res/icon/other/btn_gift.png'; // изображение подарка по умолчанию

B2CGiftTemplate.const.solid = {}; // Константы специфичные для скидок
B2CGiftTemplate.const.solid.url = 'common_res/icon/other/discount.png';// изображение подарка по умолчанию

B2CGiftTemplate.const.default = {};
B2CGiftTemplate.const.default.period = 14; // Количество дней действия подарка с момента создания его по шаблону

window.B2CGiftTemplate = B2CGiftTemplate; // TODO: remove globals, use module exports
// export default B2CGiftTemplate;