import { Model } from '@b2cmessenger/backbone';

@Model.properties({
    defaults: {
        avatar: null,
        avatar_local: null,
        avatarThumb: null,
        avatarThumb_local: null,
        qr: null,
        qr_local: null,
        firstname: null,
        lastname: null,
        email: null,
    },
    computeds: {
        avatarUrl: {
            deps: ['avatar', 'avatar_local', 'avatarThumb', 'avatarThumb_local'],
            get: (avatar, avatar_local, avatarThumb, avatarThumb_local) => avatarThumb_local || avatar_local || avatarThumb || avatar
        },
        qrUrl: {
            deps: ['qr', 'qr_local'],
            get: (qr, qr_local) => qr_local || qr,
        },
        name: {
            deps: ['firstname', 'lastname', 'email', 'id'],
            get: (firstname, lastname, email, id) =>
                (firstname || lastname) ?
                    ((firstname && lastname) ? firstname + ' ' + lastname : firstname || lastname) :
                    (email ? email : `Unknown person (#${id})`)
        }
    }
})
class UserModel extends Model {

}

export default UserModel;
