import { ViewModel, Collection } from '@b2cmessenger/backbone';
import settings from 'settings';

import PhotoModel from 'models/PhotoModel';
import Window from 'windows/Window';
import HeaderView from 'widgets/Header/Header';
import FooterView from 'widgets/Footer/Footer';
import FileInput from 'widgets/Inputs/FileInput';
import Gallery from 'widgets/Photo/Gallery';
import ConfirmModal from 'windows/Modal/Confirm';

//import contentTemplate from './Content.jade';
import './PlaceGallery.scss';
import ImageInputHelpers from 'widgets/Inputs/ImageInputHelpers';

let PhotoCollection = Collection.extend({
    model: PhotoModel
});

let PlaceGallery = Window.extend({
    windowName: "place-gallery-window",
    className: "place-gallery-window",

    bindings: {
        ':el': 'classes:{"edit-mode":editMode,"show-grid":showGrid}'
    },

    initialize() {
        this.viewModel.set({
            editMode: false,
            showGrid: false,
        });
        this.collection = new PhotoCollection(this.model.get('photos').business || []);
        this.model.listenTo(this.collection, 'update reset', c => this.model.set('photos', { business: c.toJSON() }));
    },

    onRender() {
        let gallery = new Gallery({ collection: this.collection });
        gallery.setEditMode(this.viewModel.get('editMode'));
        this.viewModel.set({ showGrid: gallery.isShowingGrid() });
        this.viewModel.listenTo(gallery, 'change:editMode', (gallery, editMode) => {
            this.viewModel.set({ editMode })
            if(editMode) {
                gallery.showGrid();
            }
        });
        this.viewModel.listenTo(gallery, 'change:showGrid', (gallery, showGrid) => {
            this.viewModel.set({ showGrid });
            if(!showGrid) {
                gallery.setEditMode(false);
            }
        });
        this.content.show(gallery);

        let rightButtons = [];
        if(LoginedUserHandler.isUserEmployee(this.model.id)) {
            rightButtons.push('edit');
        }
        rightButtons.push('grid');

        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: 'Place photos',
            rightButtons: rightButtons
        });
        this.header.show(headerView);
        headerView.listenTo(this.viewModel, 'change:editMode', (m, editMode) => headerView.ui.btnedit.toggleClass('active', editMode));
        headerView.ui.btngrid.toggleClass('active', this.viewModel.get('showGrid'));
        headerView.listenTo(this.viewModel, 'change:showGrid', (m, showGrid) => headerView.ui.btngrid.toggleClass('active', showGrid));
        this.listenTo(headerView, 'back:click', view => this.cancel());
        this.listenTo(headerView, 'edit:click', () => {
            gallery.setEditMode(!this.viewModel.get('editMode'));
        });
        this.listenTo(headerView, 'grid:click', () => {
            if(this.viewModel.get('showGrid')) {
                gallery.showPhoto();
            } else {
                gallery.showGrid();
            }
        });

        if(LoginedUserHandler.isUserEmployee(this.model.id)) {
            let footerView = new FooterView({
                buttons: [{
                    id: 'camera',
                    text: 'ADD PHOTO'
                },{
                    id: 'delete',
                    text: 'DELETE'
                },{
                    id: 'mark',
                    text: 'MARK ALL'
                },{
                    id: 'undo',
                    text: 'UNMARK ALL'
                }]
            });
            this.listenTo(footerView, 'delete:click', () => {
                let ids = gallery.getSelection();

                if(ids.length == 0) {
                    this.showMessage('Select photos for deletion first');
                } else if(ids.length) {
                    new ConfirmModal({ message: 'Delete all marked photos?' })
                        .show()
                        .then(answer => {
                            if(answer) {
                                this.trigger('disable');
                                Server.callServer({
                                    url: settings.host + settings.serv_place.photo.delete,
                                    type: "POST",
                                    data: {
                                        place_id : this.model.id,
                                        ids,
                                    },
                                    success: () => {
                                        this.trigger('enable');
                                        this.collection.remove(ids);
                                    },
                                    error: (jqXHR, textStatus, errorThrown) => {
                                        this.trigger('enable');
                                        this.showError(jqXHR, textStatus, errorThrown);
                                    }
                                });
                            }
                        })
                }
            });
            this.listenTo(footerView, 'mark:click', () => gallery.selectAll());
            this.listenTo(footerView, 'undo:click', () => gallery.clearSelection());
            this.footer.show(footerView);

            if(!this.imageInput) {
                this.imageInput = new FileInput({
                    multiple: true,
                    appearance: FileInput.Appearance.ButtonInline
                });

                function uploadPhoto(placeId, model) {
                    const file = model.get('file') || model.get('fileUrl');

                    if (file instanceof Blob || _.isString(file)) {
                        return ImageInputHelpers.upload(file, 'pp')
                            .then(uploadedFile => {
                                return new Promise((resolve, reject) => {
                                    const data = new FormData();
                                    data.append('id', placeId);
                                    data.append('image', uploadedFile.id);

                                    Server.callServer({
                                        url: settings.host + settings.serv_place.photo.add,
                                        type: "POST",
                                        cache: false,
                                        contentType: false,
                                        processData: false,
                                        data: data,
                                        success: resolve,
                                        error(jqXHR, textStatus, errorThrown) {
                                            reject(new AjaxError(jqXHR, textStatus, errorThrown))
                                        }
                                    });
                                });
                            })
                            .catch(e => {
                                this.showError(e);
                            });
                    }

                    return Promise.reject(new Error('Wrong filetype'));
                }

                this.listenTo(this.imageInput, 'change', collection => {
                    if (collection.length) {
                        this.showLoading();
                        Promise.resolve()
                            .then(() => Promise.all(collection.map(
                                model => uploadPhoto(this.model.id, model)
                                    .then(data => this.collection.add(new PhotoModel(data)))
                                    .catch(e => { e && this.showError(e) })
                            )))
                            .catch(e => { e && this.showError(e) })
                            .then(() => this.hideLoading())
                            .then(() => this.imageInput.reset());
                    }
                });
            }
            this.imageInput.render();
            footerView.getButton$('camera').append(this.imageInput.$el);

        } else {
            this.footer._ensureElement();
        }

        if(this.options && this.options.openPhotoWithId) {
            gallery.showPhotoWithId(this.options.openPhotoWithId);
        }
    },

    cancel() {
        let gallery = this.content.currentView;

        if(gallery) {
            if(this.viewModel.get('editMode')) {
                if(gallery.getSelection().length) {
                    gallery.clearSelection();
                } else {
                    gallery.setEditMode(false);
                }
            } else {
                if(!gallery.isGridMode()) {
                    gallery.showGrid();
                } else {
                    this.close(this.model);
                }
            }
        } else {
            if(this.viewModel.get('editMode')) {
                this.viewModel.set('editMode', false);
            } else {
                if(!this.viewModel.get('showGrid')) {
                    this.viewModel.set('showGrid', true);
                } else {
                    this.close(this.model);
                }
            }
        }
    }
});

export default PlaceGallery;
