import React from 'react';
import PropTypes from 'prop-types';

import 'widgets/Header/Header.scss';
import 'widgets/Footer/Footer.scss';

import { showError } from 'windows/Modal/Info';
import Header from 'components/UI/Header/Header';
import { BackButton, Button } from 'components/UI/Button/Button';
import Footer from 'components/UI/Footer/Footer';
import TextButton from 'components/UI/TextButton';
import KeyboardPadding from 'components/KeyboardPadding';

export const WindowHeader = ({ className, children }) => {
    const classNames = ['header-region', className, !children && 'no-children'].filter(Boolean);

    return (
        <div className={classNames.join(' ')} data-js-header>
            {children}
        </div>
    );
};

export const WindowContent = ({ className, children }) => {
    const classNames = ['content-region', className, !children && 'no-children'].filter(Boolean);

    return (
        <div className={classNames.join(' ')} data-js-content="">
            {children}
        </div>
    );
};

export const WindowFooter = ({ className, children }) => {
    const classNames = ['footer-region', className, !children && 'no-children'].filter(Boolean);

    return (
        <div className={classNames.join(' ')} data-js-footer>
            {children}
        </div>
    );
};

export const WindowWrapper = (props) => {
    const {
        title,
        children,
        cancelButtonText = 'CANCEL',
        continueButtonText = 'CONTINUE',
        onContinueButtonData,
        onClickContinue,
        displayContinueButton = true,
        continueButtonIcon,
        isModalWindow = false,
        disabled,
        headerButtons,
        footerButtons,
    } = props;

    const footer = footerButtons || (
        <>
            <TextButton onClick={() => props.closeWindow()}>{cancelButtonText}</TextButton>
            {displayContinueButton && (
                <Button
                    className="text-button btn-empty text with-icon"
                    icon={continueButtonIcon}
                    disabled={disabled}
                    onClick={onClickContinue || (() => props.closeWindow(onContinueButtonData))}
                >
                    {continueButtonText}
                </Button>
            )}
        </>
    );

    return (
        <>
            {!isModalWindow && (
                <WindowHeader>
                    <Header>
                        <BackButton onClick={() => props.closeWindow()} />
                        {Boolean(title) && <h1 className="title">{title}</h1>}
                        {Boolean(headerButtons) && headerButtons}
                    </Header>
                </WindowHeader>
            )}
            <WindowContent>
                {isModalWindow && Boolean(title) && <h3>{title}</h3>}
                {children}
            </WindowContent>
            <WindowFooter>
                <Footer>{footer}</Footer>
            </WindowFooter>
            <KeyboardPadding />
        </>
    );
};
WindowWrapper.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    cancelButtonText: PropTypes.string,
    continueButtonText: PropTypes.string,
    continueButtonIcon: PropTypes.any,
    onContinueButtonData: PropTypes.any,
    onClickContinue: PropTypes.func,
    displayContinueButton: PropTypes.bool,
    isModalWindow: PropTypes.bool,
    disabled: PropTypes.bool,
    headerButtons: PropTypes.any,
    footerButtons: PropTypes.any,
    closeWindow: PropTypes.func,
};

// TODO: implement React Window Component (that one with `window` className)
const Window = () => {
    showError("You are using `components/window/Window` function. It's not implemented yet.");

    return null;
};

export default Window;
