/**
 * Created by Alex on 03.05.2016.
 */
function initGoogleMap() {
    initGoogleMap.inited = true;
    $(document).trigger(initGoogleMap.event_map_inited);
}
initGoogleMap.inited = false;
initGoogleMap.event_map_inited = 'b2c_goo_m_inited';
initGoogleMap.url = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyC20xBW9Q6_vzYVXCUc26WPBTvxp6tZFQA&signed_in=false&libraries=places&language=en&callback=initGoogleMap';

function loadScript(url) {
    return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = url;
        script.type = 'text/javascript';
        script.onError = reject;
        script.async = true;
        script.onload = resolve;
        script.addEventListener('error', function (e) { reject({ script: script, e: e }); });
        script.addEventListener('load', resolve);
        document.head.appendChild(script);
    });
}

function tryLoad(timeout) {
    loadScript(initGoogleMap.url)
        .catch(function (o) {
            o.script.remove();
            console.error(o.e);
            setTimeout(tryLoad.bind(this, timeout), timeout);
        });
}

function loadGoogleMap() {
    tryLoad(60000);
}

window.loadGoogleMap = loadGoogleMap; // TODO: remove globals, use module exports
window.initGoogleMap = initGoogleMap;
// export { loadGoogleMap, initGoogleMap };