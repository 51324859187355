import { ViewModel, ItemView, LayoutView, CollectionView, Required, Optional } from '@b2cmessenger/backbone';
import settings from 'settings';
import UserModel from 'models/UserModel';
import Window from 'windows/Window';
import templateContent from './EmployeesEdit.jade';
import HeaderView from 'widgets/HeaderWithTabs/HeaderWithTabs';
import UserSearchWindow from 'windows/UserSearch/UserSearch';
import EmployeesChild from './EmployeesChild';
import templateRole from './Role.jade';
import ConfirmModal from 'windows/Modal/Confirm';
import './Employees.scss';

const ERROR_STATUSES = {
    joined: 1003001,
    declined: 1003002,
    pending: 1003003
};

const EmployeesEditWindow = Window.extend({
    options: {
        roles: Required,
        placeId: Required
    },

    windowName: 'employees-edit-window',
    className: 'employees-edit-window',

    initialize() {
        this.viewModel = new ViewModel();
    },

    onRender() {
        this.roles = this.options.roles;
        this.placeId = this.options.placeId;

        const currentUser = LoginedUserHandler.getLoginedUser();
        const rightButtons = [];

        if (this.model && this.model.get('user') && this.model.get('user').id !== currentUser.id) {
            rightButtons.push({
                id: 'delete',
                text: 'Delete',
                icn: 'delete'
            });
        }

        const headerView = new HeaderView({
            leftButtons: ['back'],
            title: !this.model ? 'Add Employee' : 'Employee',
            rightButtons
        });
        this.listenTo(headerView, 'back:click', () => this._addedNewEmployee ? this.close('pending') : this.cancel());
        this.listenTo(headerView, 'delete:click', () => this._deleteEmployee());
        this.header.show(headerView);

        const contentView = new EmployeesEditContent({
            model: this.model,
            placeId: this.placeId,
            roles: this.roles,
            currentUser,
            parentViewModel: this.viewModel
        });
        this.listenTo(contentView, 'close', () => this.close());
        this.listenTo(contentView, 'search:page', () => this._goToSearchPage());

        this.content.show(contentView);
    },

    _goToSearchPage() {
        const placeId = this.options.placeId;
        const self = this;
        const roles = this.options.roles;

        const userSearchWindow = new UserSearchWindow({
            onEmptyResult: m => {
                const email = m.get('email');
                if (!email || !B2Cjs.checkMail(email)) return;

                new ConfirmModal({
                    message: `Do you want to invite ${email} to join your company as employee?`
                }).show()
                    .then(confirm => confirm &&
                        Server.callServer({
                            url: settings.host + settings.serv_place.employee.add,
                            data: {
                                place_id: placeId,
                                email: email,
                                roles: _.pluck(roles, 'id')
                            },
                            type: "POST",
                            success: (data) => {
                                this.model = new Backbone.Model(_.defaults(data.employee, { email }));
                                self._addedNewEmployee = true;
                                this.render();
                                userSearchWindow.close();
                            },
                            error: self.showError.bind(self)
                        })
                    );
            }
        });

        userSearchWindow.show().then(user => {
            if (user) {
                self.model = new Backbone.Model({
                    user: user.attributes,
                    roles: _.pluck(roles, 'id'),
                    editable: false
                });
                // Добавление существующего пользователя
                let headerView = new HeaderView({
                    leftButtons: ['back'],
                    title: 'Add Employee',
                    rightButtons: [{
                        id: 'save',
                        text: '',
                        icn: 'save'
                    }]
                });

                self.render();
                self.listenTo(headerView, 'back:click', () => {
                    new ConfirmModal({
                        message: `Do you want to invite ${user.get('name')} to join your company as employee?`,
                    }).show()
                        .then(confirm => {
                            if (confirm) {
                                self._saveAndClose();
                            } else {
                                self.close()
                            }
                        });
                });

                // Колбэк сохранения
                self.listenTo(headerView, 'save:click', () => self._saveAndClose());
                self.header.show(headerView);
            }
        });
    },

    _saveAndClose(placeId = this.placeId) {
        const self = this;

        this.showLoading();
        Server.callServer({
            url: settings.host + settings.serv_place.employee.add,
            data: {
                place_id: placeId,
                user_id: self.model.get('user').id,
                roles: self.model.get('roles')
            },
            type: "POST",
            success(data) {
                self.hideLoading();
                // Закрываем текущее окно с переходом в pending вкладку
                self.close('pending');
            },
            error(jqXHR, textStatus, errorThrown) {
                self.hideLoading();

                if (jqXHR && jqXHR.responseJSON && jqXHR.responseJSON.error &&
                    _.contains(_.values(ERROR_STATUSES), jqXHR.responseJSON.error.code)
                ) {
                    self.showError(jqXHR.responseJSON.error.message);
                    switch (jqXHR.responseJSON.error.code) {
                        case ERROR_STATUSES.joined:
                            self.close('joined');
                            break;
                        case ERROR_STATUSES.declined:
                            self.close('declined');
                            break;
                        case ERROR_STATUSES.pending:
                            self.close('pending');
                            break;
                        default:
                            self.close();
                    }
                } else {
                    self.showError(jqXHR, textStatus, errorThrown);
                }
            }
        });
    },

    _deleteEmployee() {
        const self = this;
        let message = "Do you really want to remove this employee from your company?";

        if (this.model && this.model.has('user')) {
            let userModel = new UserModel(this.model.get('user'));
            if (userModel.get('name')) {
                message = `Do you really want to remove ${userModel.get('name')} from your company?`;
            }
        }

        new ConfirmModal({ message }).show().then(confirm => {
            if (confirm) {
                this.showLoading();
                Server.callServer({
                    url: settings.host + settings.serv_place.employee.delete,
                    data: {
                        user_id: this.model.get('user').id,
                        place_id: this.placeId
                    },
                    type: "POST",
                    success() {
                        self.hideLoading();
                        self.close('reload');
                    },
                    error(jqXHR, textStatus, errorThrown) {
                        self.hideLoading();
                        self.showError(jqXHR, textStatus, errorThrown);
                    }
                });
            }
        });
    }
});

@LayoutView.options({
    model: Required,
    placeId: Required,
    roles: Required,
    currentUser: Required,
    parentViewModel: Required
})
@LayoutView.properties({
    template: templateContent,
    regions: {
        user: '[data-js-user]',     // Регион под аватар пользователя
        roles: '[data-js-roles]'    // Регион под чебоксы ролей
    },
    ui: {
        search: '[data-js-user-search]'
    },
    triggers: {
        'click @ui.search': 'search:page'
    }
})
class EmployeesEditContent extends LayoutView {
    onRender() {
        if (this.model) {
            // Если есть модель, то показываем пользователя с чекбоксами
            let userCollectionView = new (CollectionView.extend({
                className: 'employees-list-inner',
                childView: EmployeesChild,
                collection: new Backbone.Collection([this.model]),
                childViewEventPrefix: 'child',
                childViewOptions: {
                    // Если текущая модель существует, у ней есть флаг editable
                    // (контролирует отправку при изменение чекбокса)
                    clickable: this.model.get('editable') !== undefined,
                },
            }));
            // По клику на поиск пользователя, открывать UserSearch
            this.listenTo(userCollectionView, 'child:click:user', () => this.trigger('search:page'));
            this.user.show(userCollectionView);

            // Коллекция с ролями
            let rolesCollectionView = new (CollectionView.extend({
                className: 'employees-roles-list',
                childViewOptions: {
                    employeeModel: this.model,
                    currentUser: this.options.currentUser,
                    placeId: this.options.placeId,
                    parentViewModel: this.options.parentViewModel
                },
                childEvents: {
                    'change': 'onChange'
                },
                childView: RoleItem,
                collection: new Backbone.Collection(this.options.roles)
            }))({
                model: this.model
            });
            this.roles.show(rolesCollectionView);
        }
    }
}

const RoleItem = ItemView.extend({
    options: {
        parentViewModel: Required
    },

    template: templateRole,
    className: 'employee-role',

    ui: {
        select: '[data-js-switch-select]'
    },

    events: {
        'change @ui.select': 'onChange'
    },

    computeds: {
        canBeChanged: {
            deps: ['enabled', 'user', 'disabled'],
            get(enabled, user) {
                const isCurrentUser = this.options.currentUser.id === user.id;
                const disabled = this.options.parentViewModel.get('disabled');
                return enabled && user && user.id && !isCurrentUser && !disabled;
            }
        },
    },

    bindings: {
        '@ui.select': 'sliderEnabled:canBeChanged'
    },

    bindingHandlers: {
        sliderEnabled: {
            set($el, value) {
                $el.prop('disabled', !value);
                $el.slider && $el.slider('instance') && (value ? $el.slider('enable') : $el.slider('disable'));
            },
        },
    },

    initialize() {
        this.viewModel = new ViewModel({
            parentViewModel: this.options.parentViewModel
        });
        this.viewModel.set('user', this.options.employeeModel.get('user'));
    },

    onRender() {
        let selectedRoles = this.options.employeeModel.get('roles');

        // Помечаем выбранные роли в чекбоксах
        if (selectedRoles.indexOf(this.ui.select.data('id')) == -1) {
            this.ui.select.find('[value=false]').prop('selected', true);
        }

        // Активируем widget slider
        this.ui.select.slider();
    },

    onChange() {
        const self = this;
        const roles = _.clone(this.options.employeeModel.get('roles'));
        const roleId = this.model.get('id');
        const index = roles.indexOf(roleId);
        const parentViewModel = this.options.parentViewModel;

        // Манипулируем с массивом ролей в модели
        if (this.ui.select.val() == "true") {
            if (index > -1) return false;
            else roles.push(roleId);
        } else {
            if (index == -1) return false;
            else roles.splice(index, 1);
        }

        // Если это еще не сохарненная модель, не сохраняем модели на лету
        if (this.options.employeeModel.get('editable') === false) {
            this.options.employeeModel.set('roles', roles);
            return;
        }

        parentViewModel.set('disabled', true);
        Server.callServer({
            url: settings.host + settings.serv_place.employee.changeroles,
            data: {
                user_id: this.options.employeeModel.get('user').id,
                place_id: this.options.placeId,
                roles: roles
            },
            type: "POST",
            error(jqXHR, textStatus, errorThrown) {
                parentViewModel.set('disabled', false);
                self.render();
                self.showError(jqXHR, textStatus, errorThrown);
            },
            success() {
                parentViewModel.set('disabled', false);
                self.options.employeeModel.set('roles', roles);
                self.render();
            }
        });
    }
});

export default EmployeesEditWindow;
