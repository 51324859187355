var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (_, label, options) {var id = _.uniqueId() +  '-input-group-type-radio'
if (options.icon) {
pug_html = pug_html + "\u003Ci" + (pug.attr("class", pug.classes([options.icon], [true]), false, true)) + "\u003E\u003C\u002Fi\u003E";
}
pug_html = pug_html + "\u003Clabel\u003E\u003Cp data-js-label\u003E" + (pug.escape(null == (pug_interp = label || "") ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003C\u002Flabel\u003E";
// iterate options.options
;(function(){
  var $$obj = options.options;
  if ('number' == typeof $$obj.length) {
      for (var val = 0, $$l = $$obj.length; val < $$l; val++) {
        var label = $$obj[val];
pug_html = pug_html + "\u003Cinput" + (pug.attr("data-js-input", true, true, true)+" type=\"radio\""+pug.attr("id", `${id}-${val}`, true, true)+pug.attr("name", `${id}[]`, true, true)+pug.attr("value", val, true, true)+" data-role=\"none\"") + "\u003E\u003Clabel" + (pug.attr("for", `${id}-${val}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E";
      }
  } else {
    var $$l = 0;
    for (var val in $$obj) {
      $$l++;
      var label = $$obj[val];
pug_html = pug_html + "\u003Cinput" + (pug.attr("data-js-input", true, true, true)+" type=\"radio\""+pug.attr("id", `${id}-${val}`, true, true)+pug.attr("name", `${id}[]`, true, true)+pug.attr("value", val, true, true)+" data-role=\"none\"") + "\u003E\u003Clabel" + (pug.attr("for", `${id}-${val}`, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003Cp class=\"error\" data-js-error\u003E\u003C\u002Fp\u003E\u003Cp class=\"info\" data-js-info\u003E\u003C\u002Fp\u003E";}.call(this,"_" in locals_for_with?locals_for_with._:typeof _!=="undefined"?_:undefined,"label" in locals_for_with?locals_for_with.label:typeof label!=="undefined"?label:undefined,"options" in locals_for_with?locals_for_with.options:typeof options!=="undefined"?options:undefined));;return pug_html;};
module.exports = template;