import { Model } from '@b2cmessenger/backbone';
import settings from 'settings';
import AjaxError from 'utils/AjaxError';
import {LoyaltyKioskCheckInMethods} from "windows/Modal/LoyaltyKiosk/LoyaltyKiosk";

@Model.properties({
    defaults: {
        user: null,
        workPlace: null,
        defaultCheckInMethod: LoyaltyKioskCheckInMethods.None,
        coins: 0,
        loggedInThroughPhone: '',
        loggedInThroughEmail: '',
        loggedInThroughLoyaltyCardNumber: '',
        latestActivityTime: null,
        inactivityTimeout: 60 * 1000,
        secondsBeforeLogout: null,
    },

    computeds: {
        userId: {
            deps: ['user'],
            get: user => user && user.id
        },
        workPlaceId: {
            deps: ['workPlace'],
            get: workPlace => workPlace && workPlace.id
        },
        workPlaceBrandId: {
            deps: ['workPlace'],
            get: workPlace => workPlace && workPlace.brand_id
        },
        timeLeft: {
            deps: ['secondsBeforeLogout'],
            get: secondsBeforeLogout => {
                const m = Math.abs(Math.floor(secondsBeforeLogout / 60));
                const s = Math.abs(secondsBeforeLogout % 60);
                return [m, s].map(n => (n < 10 && n >= 0) ? `0${n}` : `${n}`).join(':');
            }
        }
    }
})
class LoyaltyKioskModel extends Model {
    async performServerCheckin(userId, workPlaceId) {
        userId = userId || this.get('userId');
        workPlaceId = workPlaceId || this.get('workPlaceId');

        return new Promise((resolve, reject) => {
            B2CPlace.server_business_checkin(userId, workPlaceId, resolve, reject);
        });
    }

    async getUserCoins(userId, brandId) {
        userId = userId || this.get('userId');
        brandId = brandId || this.get('workPlaceBrandId');

        if (!userId || !brandId) {
            return Promise.resolve(null);
        }

        return new Promise((resolve, reject) => {
            Server.callServer({
                url: settings.host + settings.serv_gift.search,
                data: {
                    user_id: userId,
                    brand_id: brandId,
                    type: 1
                },
                type: "POST",
                success: data =>
                    resolve(_.reduce(data && data.gifts, (memo, g) => memo + g.quantity, 0)),
                error: (jqXHR, textStatus, errorThrown) => {
                    reject(new AjaxError(jqXHR, textStatus, errorThrown));
                }
            });
        })
            .then(coins => {
                this.set({ coins });

                return coins;
            });
    }

    getTimeSinceLatestActivity() {
        const activityTime = this.get('latestActivityTime');
        return activityTime !== null && Date.now() - activityTime;
    }

    reset() {
        this.set(_.defaults(this.pick('workPlace', 'defaultCheckInMethod', 'inactivityTimeout'), this.defaults));
    }
}

export default LoyaltyKioskModel;