var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (weekdays) {pug_html = pug_html + "\u003Cdiv data-js-calendar-header\u003E\u003C\u002Fdiv\u003E\u003Cul class=\"weekdays-wrapper\"\u003E";
// iterate weekdays
;(function(){
  var $$obj = weekdays;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var day = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli\u003E" + (pug.escape(null == (pug_interp = day) ? "" : pug_interp)) + "\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var day = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli\u003E" + (pug.escape(null == (pug_interp = day) ? "" : pug_interp)) + "\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E\u003Cdiv data-js-calender-content\u003E\u003C\u002Fdiv\u003E";}.call(this,"weekdays" in locals_for_with?locals_for_with.weekdays:typeof weekdays!=="undefined"?weekdays:undefined));;return pug_html;};
module.exports = template;