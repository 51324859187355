import { Model, ViewModel, LayoutView } from '@b2cmessenger/backbone';
import settings from 'settings';
import Window from 'windows/Window';
import AjaxError from 'utils/AjaxError';

import { GiftModel } from 'windows/Modal/Gift';

import HeaderView from 'widgets/HeaderWithTabs/HeaderWithTabs';
import FooterView from 'widgets/Footer/Footer';
import ImageInput from 'widgets/Inputs/ImageInput';

import template from './Content.jade';
import templateImageView from './ImageView.jade';
import './GiftTemplatesEdit.scss';
import '../GiftTemplates/GiftTemplates.scss';
import transformationTypeHelpTemplate from './transformationTypeHelp.jade';
import transformationRatioHelpTemplate from './transformationRatioHelp.jade';
import ImageInputHelpers from "widgets/Inputs/ImageInputHelpers";

const Type = {
    Coins: 1,
    Gift: 2,
    Discount: 3
};

const DefaultRatio = 1000;

export default Window.extend({
    className: 'gift-templates-edit-window',
    clonedModel: null,
    options: {
        type: Type.Coins
    },

    initialize() {
        if(this.model && this.model.get('type')) {
            this.options.type = this.model.get('type');
        }

        this.model = new (Model.extend({
            computeds: {
                custom_quantity_allowed: {
                    deps: ['type', '_custom_quantity_allowed'],
                    get: (type, _custom_quantity_allowed) => (type != 3 && _custom_quantity_allowed) ? 1 : 0,
                    set: (val) => _.create(null, {
                        _custom_quantity_allowed: val ? 1 : 0
                    })
                },
                transformation_type: {
                    deps: ['custom_quantity_allowed', '_transformation_type'],
                    get: (custom_quantity_allowed, _transformation_type) => custom_quantity_allowed && _transformation_type || 0,
                    set: (val) => _.create(null, {
                        _transformation_type: Math.max(0, Math.min(Math.floor(Number(val)) || 0, 4))
                    })
                },
                transformation_ratio: {
                    deps: ['transformation_type', '_transformation_ratio'],
                    get: (transformation_type, _transformation_ratio) => {
                        switch (transformation_type) {
                            default:
                            case 0: return 0;
                            case 1: return Math.max(1, Math.min(_transformation_ratio || DefaultRatio, GiftModel.MAX_RATIO_FOR_PERCENT)) || 1;
                            case 2: return Math.max(1, Math.min(_transformation_ratio || DefaultRatio, GiftModel.MAX_RATIO_FOR_PRICE)) || 1;
                            case 3: return Math.max(1, Math.min(_transformation_ratio || DefaultRatio, GiftModel.MAX_RATIO_FOR_MULTIPLIER)) || 1;
                            case 4: return Math.max(1, Math.min(_transformation_ratio || DefaultRatio, GiftModel.MAX_RATIO_FOR_COINS)) || 1;
                        }
                    },
                    set: (val) => _.create(null, {
                        _transformation_ratio: Math.max(1, Math.floor(Number(val)) || DefaultRatio)
                    })
                }
            }
        }))(this.model.toJSON());
    },

    onRender() {
        this._renderHeader();

        const view = new ContentWidget({
            model: this.model,
            viewModel: this.viewModel
        });

        this.content.show(view);
    },

    save() {
        this.showLoading();

        return new Promise((resolve, reject) => {
            if (this.content.currentView.getBinding('ratioError')) {
                reject();
                this.content.currentView.ui.ratioInput.focus();
                return;
            }

            const formData = this.content.currentView.getFormData();

            Server.callServer({
                url: settings.host + settings.serv_gift.template.save,
                type: "POST",
                data: formData,
                processData: false,
                contentType: false,
                error: (jqXHR, textStatus, errorThrown) => reject(new AjaxError(jqXHR, textStatus, errorThrown)),
                success: resolve,
            });
        })
            .then(data => {
                this.hideLoading();

                this.model.set(_.defaults({
                    newPhotoId: null
                }, data));

                return this.model;
            })
            .catch(err => {
                this.hideLoading();
                throw err;
            });
    },

    _renderHeader() {
        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: this.model.get('id') ? 'Edit gift template' : 'Add gift template',
            rightButtons: [{
                id: 'save',
                text: '',
                icn: 'save'
            }],
            tabs: [
                {
                    id: 'coins',
                    title: 'Coins',
                    icon: 'icon-coins',
                    type: Type.Coins,
                    active: this.model.get('type') == Type.Coins
                },
                {
                    id: 'gifts',
                    title: 'Gifts',
                    icon: 'icon-gift2',
                    type: Type.Gift,
                    active: this.model.get('type') == Type.Gift
                },
                {
                    id: 'discounts',
                    title: 'Discounts',
                    icon: 'icon-discount',
                    type: Type.Discount,
                    active: this.model.get('type') == Type.Discount
                }
            ]
        });

        this.listenTo(headerView, 'back:click', () =>
            this.cancel());

        this.listenTo(headerView, 'save:click', () =>
            this.save()
                .then(() => this.close(this.model))
                .catch(err => err && this.showError(err)));

        this.listenTo(headerView, 'tab:click', (parent, child) => {
            this.model.set('type', child.model.get('type'));

            if (this.model.get('transformation_type') == 4 && this.model.get('type') == Type.Coins) {
                this.model.set('transformation_type', 3);
            }

            parent.resetTabs();
            child.setActive();
        });

        this.header.show(headerView);
    },
});

const ContentWidget = LayoutView.extend({
    className: 'gift-templates-edit-view',
    template: template,

    regions: {
        image: '[data-js-main]',
        properties: '[data-js-properties]'
    },

    ui: {
        quantityLabel: '[data-js-quantity-label]',
        spinnerQty: '[data-js-spinner-qty]',
        spinnerDays: '[data-js-spinner-days]',
        spinnerTransformationRatio: '[data-js-spinner-transformation-ratio]',
        form: '[data-js-form]',
        validityDaysProp: '[data-js-prop-validity-days]',
        validuntilDateProp: '[data-js-prop-validuntil-date]',
        radioValidityInput: 'input[name=validity]',
        radioValidUntilInput: 'input[name=validuntil]',
        allowCustomQuantityProp: '[data-js-prop-allowCustomQuantity]',
        forBusinessCheckinProp: '[data-js-prop-forBusinessCheckin]',
        transformationTypeProp: '[data-js-prop-transformationType]',
        transformationRatioProp: '[data-js-prop-transformationRatio]',
        radioAllowCustomQuantityInput: 'input[name=allowCustomQuantity]',
        radioForBusinessCheckinInput: 'input[name=forBusinessCheckin]',
        radioTransformationTypeInput: 'input[name=transformationType]',
        radioStateInput: 'input[name=state]',
        ratioInput: '[data-js-ratio]',
        ratioCaption: '[data-js-ratio-caption]',
        ratioSign: '[data-js-ratio-sign]',
        ratioInfo: '[data-js-ratio-info]',
        ratioHelp: '[data-js-ratio-help]',
        transformationTypeHelp: '[data-js-transformation-type-help]',
        coinsTransformationLabel: '[type=radio][name=transformationType][value=4] + label'
    },

    computeds: {
        c_ratio: {
            deps: ['transformation_ratio', '_recalculate_ratio'],
            get: transformation_ratio => transformation_ratio || null,
            set(val) {
                const nRatio = val,
                    transformation_type = this.model.get('transformation_type');

                let maxRatio = 0;
                switch (transformation_type) {
                    default:
                    case 0: {
                        return null;
                    }
                    case 1: {
                        maxRatio = GiftModel.MAX_RATIO_FOR_PERCENT;
                        break;
                    }
                    case 2: {
                        maxRatio = GiftModel.MAX_RATIO_FOR_PRICE;
                        break;
                    }
                    case 3: {
                        maxRatio = GiftModel.MAX_RATIO_FOR_MULTIPLIER;
                        break;
                    }
                    case 4: {
                        maxRatio = GiftModel.MAX_RATIO_FOR_COINS;
                    }
                }

                if (nRatio > 0 && nRatio <= maxRatio) {
                    this.model.set({
                        transformation_ratio: nRatio,
                    });

                    this.viewModel.set({
                        _recalculate_ratio: _.uniqueId()
                    });
                } else {
                    if (nRatio > maxRatio) {
                        this.model.set({
                            transformation_ratio: maxRatio,
                        });

                        this.viewModel.set({
                            _recalculate_ratio: _.uniqueId()
                        });
                    } else {
                        this.model.set({
                            transformation_ratio: 0,
                        });

                        this.viewModel.set({
                            _recalculate_ratio: _.uniqueId()
                        });
                    }
                }
            }
        },
        c_transformation_type: {
            deps: ['transformation_type'],
            get: transformation_type => transformation_type || null,
            set(val) {
                const type = this.model.get('type');

                if (type == Type.Coins && val == 4) {
                    val = 3;
                }

                this.model.set({
                    transformation_type: val
                });
            }
        },
        ratioError: {
            deps: ['transformation_ratio', 'transformation_type'],
            get(transformation_ratio, transformation_type) {
                const ratio = Math.floor(Number(transformation_ratio));

                let maxRatio = 0;
                switch (transformation_type) {
                    default:
                    case 0: {
                        return null;
                    }
                    case 1: {
                        maxRatio = GiftModel.MAX_RATIO_FOR_PERCENT;
                        break;
                    }
                    case 2: {
                        maxRatio = GiftModel.MAX_RATIO_FOR_PRICE;
                        break;
                    }
                    case 3: {
                        maxRatio = GiftModel.MAX_RATIO_FOR_MULTIPLIER;
                        break;
                    }
                    case 4: {
                        maxRatio = GiftModel.MAX_RATIO_FOR_COINS;
                    }
                }

                if (ratio > maxRatio) {
                    return `The value is too high! Max allowed value is ${maxRatio / 100}`;
                }

                if (!ratio) {
                    return '0 or negative values are not allowed';
                }

                return false;
            }
        },
        ratioInfoHtml: {
            deps: ['ratioError', 'type', 'transformation_type', 'transformation_ratio'],
            get(ratioError, type, transformationType, transformationRatio) {
                if (ratioError) {
                    return ratioError;
                }

                switch (this.model.get('transformation_type')) {
                    default:
                    case 0:
                        return '';
                    case 1:
                        return `<em>${(transformationRatio / 100).toFixed(2)}%</em> of receipt total will be converted into ${type == 1 ? 'coins' : 'gifts'}`;
                    case 2:
                        return `For each <em>${(transformationRatio / 100).toFixed(2)}$</em> in receipt total client will get 1 ${type == 1 ? 'coin' : 'gift'}`;
                    case 3:
                        return `For each item client will get <em>${(transformationRatio / 100).toFixed(2)}<span class="multiplier"></span></em> ${type == 1 ? 'coins' : 'gifts'}`
                    case 4:
                        return `<em>${transformationRatio}</em> coins will be converted into gift`;
                }
            }
        },
        ratioCaption: {
            deps: ['transformation_type'],
            get(transformationType) {
                switch (this.model.get('transformation_type')) {
                    default:
                    case 0:
                        return '';
                    case 1:
                        return 'Percent of receipt';
                    case 2:
                        return 'Price of single gift'
                    case 3:
                        return `Multiplier`;
                    case 4:
                        return 'Price in coins';
                }
            }
        },
        ratioSign: {
            deps: ['transformation_type'],
            get(transformationType) {
                switch (this.model.get('transformation_type')) {
                    default:
                    case 0:
                        return '';
                    case 1:
                        return '%';
                    case 2:
                        return '$'
                    case 3:
                        return "\u00D7";
                    case 4:
                        return '';
                }
            }
        },
        ratioMaxLength: {
            deps: ['transformation_type'],
            get(transformationType) {
                switch (this.model.get('transformation_type')) {
                    default:
                    case 0:
                        return 1;
                    case 1:
                        return Math.log10(GiftModel.MAX_RATIO_FOR_PERCENT) + 1 + 1;
                    case 2:
                        return Math.log10(GiftModel.MAX_RATIO_FOR_PRICE) + 1 + 1;
                    case 3:
                        return Math.log10(GiftModel.MAX_RATIO_FOR_MULTIPLIER) + 1 + 1;
                    case 4:
                        return Math.log10(GiftModel.MAX_RATIO_FOR_COINS) + 1 + 1;
                }
            }
        },
        c_isCoins: {
            deps: ['type'],
            get: type => Type.Coins == type
        }
    },

    bindingHandlers: {
        decimalValue: {
            checkInputFormat: function($element, value) {
                this.isDecimal = value != 4;
                if (this.isDecimal) {
                    $element.attr('step', '0.01');
                    $element.attr('min', '0.01');
                    this.set($element, $element.val());
                } else {
                    $element.attr('step', '1');
                    $element.attr('min', '1');
                    this.set($element, Math.floor($element.val()) || '1');
                }

                $element.trigger('change');
            },
            init: function ($element, value, context) {
                this.isDecimal = true;

                $element.blur(() => {
                    $element.val(Number($element.val()) || null);
                });

                $element.on('keyup', e => {
                    if (!this.isDecimal) return;
                    const maxLength = $element[0].maxLength || Infinity,
                        val = $element.val();

                    if (!/^\d*(\.\d{0,2})?$/.test(val)
                        || !$element[0].validity.valid && (val != "0" && val != "0." && val != "0.0")
                        || val.length >= maxLength && /^0+/.test(val)
                    ) {
                        const nVal = Number(String(val).replace(/(\.[^.]*)(\..*)/, (m, p1) => p1));
                        $element.val(nVal && nVal.toFixed(2) || 0);
                    }
                });

                this.listenTo(this.view.model, 'change:_transformation_type', (m, value) => {
                    if (m.previous('_transformation_type') == 4 || value == 4) {
                        this.checkInputFormat($element, value);
                    }
                });

                if (this.view.model.get('_transformation_type') == 4) {
                    this.checkInputFormat($element, this.view.model.get('_transformation_type'));
                }
            },
            get: function ($element) {
                return Math.round(Number($element.val()) * (this.isDecimal ? 100 : 1));
            },
            set: function ($element, value) {
                value = this.isDecimal ? value / 100 : value;

                try {
                    if ($element[0] === document.activeElement) {
                        const val = $element.val();
                        if (Math.abs(val - value) > 0.001) {
                            $element.val(value);
                        }
                    } else {
                        if ($element.val() + '' != value + '') {
                            $element.val(value);
                        }
                    }
                } catch (e) { }
            }
        },
    },

    bindingFilters: {
        num2str: {
            get: num => String(Number(num)),
            set: str => Number(str),
        },
    },

    bindings: {
        '@ui.quantityLabel': 'text:select(custom_quantity_allowed,"Default quantity","Quantity")',
        '@ui.transformationTypeProp': 'classes:{hidden:not(custom_quantity_allowed)}',
        '@ui.transformationRatioProp': 'classes:{hidden:not(all(custom_quantity_allowed,transformation_type))}',
        '@ui.radioAllowCustomQuantityInput': 'checked:num2str(custom_quantity_allowed)',
        '@ui.radioForBusinessCheckinInput': 'checked:num2str(is_for_business_checkin)',
        '@ui.radioTransformationTypeInput': 'checked:num2str(c_transformation_type)',
        '@ui.ratioInput': 'decimalValue:c_ratio,attr:{maxlength:ratioMaxLength,size:ratioMaxLength}',
        '@ui.ratioCaption': 'text:ratioCaption',
        '@ui.ratioSign': 'text:ratioSign',
        '@ui.ratioInfo': 'html:ratioInfoHtml,classes:{error:ratioError}',
        '@ui.coinsTransformationLabel': 'classes:{hidden:c_isCoins}'
    },

    events: {
        'change @ui.radioValidityInput': 'onChangeForm',
        'change @ui.radioValidUntilInput': 'onChangeForm',
        'keyup @ui.ratioInput'(e) {
            if (this.ui.ratioInput[0] === document.activeElement) {
                const val = this.ui.ratioInput.val();


                if (val && val != "0" && val != "0." && val != "0.0") {
                    this.ui.ratioInput.change();
                }
            }
        },
        'blur @ui.ratioInput'(e) {
            const
                transformation_type = this.model.get('transformation_type'),
                ratio = this.model.get('transformation_ratio');

            let maxRatio = 0;
            switch (transformation_type) {
                default:
                case 0: {
                    return null;
                }
                case 1: {
                    maxRatio = GiftModel.MAX_RATIO_FOR_PERCENT;
                    break;
                }
                case 2: {
                    maxRatio = GiftModel.MAX_RATIO_FOR_PRICE;
                    break;
                }
                case 3: {
                    maxRatio = GiftModel.MAX_RATIO_FOR_MULTIPLIER;
                    break;
                }
                case 4: {
                    maxRatio = GiftModel.MAX_RATIO_FOR_COINS;
                }
            }

            if (ratio > maxRatio) {
                this.model.set({ transformation_ratio: maxRatio });
            } else if (ratio < 1) {
                this.model.set({ transformation_ratio: 1 });
            }
        },
        'click @ui.transformationTypeHelp'(e) {
            this.showMessage({
                title: 'Gift conversion',
                text: transformationTypeHelpTemplate({ type: this.model.get('type'), Type }),
                isHtml: true
            });
        },
        'click @ui.ratioHelp'(e) {
            this.showMessage({
                title: this.getBinding('ratioCaption'),
                text: transformationRatioHelpTemplate({
                    Type, GiftModel,
                    type: this.model.get('type'),
                    transformationType: this.model.get('transformation_type')
                }),
                isHtml: true
            });

            e.preventDefault();
        }
    },

    propertiesForTypes: {
        quantity: [1, 2],
        allowCustomQuantity: [1, 2],
        validity: [2, 3],
        validuntil: [2, 3],
        state: [1, 2, 3]
    },

    dependsProperties: { // prop name : [registered `ui` name list]
        validity: ['validityDaysProp'],
        validuntil: ['validuntilDateProp'],
    },

    initialize() {
        this.viewModel.set({
            _recalculate_ratio: _.uniqueId()
        });
    },

    onRender() {
        const imageView = new ImageWidget({
            model: this.model,
            viewModel: this.viewModel
        });

        imageView.showError = this.showError.bind(this);

        this.model.on('change:type', (m, type) => {
            this.initializeProperties();
            this.initializeAdditionalProperties();
            imageView.render();
        });

        this.initializeProperties();

        if (this.model.get('period')) {
            this.ui.radioValidityInput.filter('[value=unlimited]').prop('checked', false);
            this.ui.radioValidityInput.filter('[value=limited]').prop('checked', true);
            this.ui.validityDaysProp.find('input').val(this.model.get('period'));
        }

        if (this.model.get('total_period')) {
            this.ui.radioValidUntilInput.filter('[value=unlimited]').prop('checked', false);
            this.ui.radioValidUntilInput.filter('[value=limited]').prop('checked', true);
            this.ui.validuntilDateProp.find('input').val(this.model.get('total_period'));
            this.ui.validuntilDateProp.find('input').change();
            _.defer(() => this.ui.validuntilDateProp.find('input')[0].dispatchEvent(new Event('update', {
                bubbles: true,
                cancelable: true,
            })));
        }

        if (this.model.get('active') == 1 || this.model.get('active') == undefined) {
            this.ui.radioStateInput.filter('[value="-1"]').prop('checked', false);
            this.ui.radioStateInput.filter('[value="1"]').prop('checked', true);
        } else {
            this.ui.radioStateInput.filter('[value="-1"]').prop('checked', true);
            this.ui.radioStateInput.filter('[value="1"]').prop('checked', false);
        }

        this.initializeSpinner(this.ui.spinnerQty, 'quantity');
        this.initializeSpinner(this.ui.spinnerDays, 'days');
        this.initializeAdditionalProperties();

        this.image.show(imageView);
    },

    initializeAdditionalProperties() {
        if (this.propertyIsVisible('validity')) {
            if (this.ui.radioValidityInput.filter(':checked').val() == 'unlimited') {
                this.ui.validityDaysProp.addClass('hidden');
            } else {
                this.ui.validityDaysProp.removeClass('hidden');
            }
        }

        if (this.propertyIsVisible('validuntil')) {
            if (this.ui.radioValidUntilInput.filter(':checked').val() == 'unlimited') {
                this.ui.validuntilDateProp.addClass('hidden');
            } else {
                this.ui.validuntilDateProp.removeClass('hidden');
            }
        }
    },

    propertyIsVisible(propName) {
        let types = this.propertiesForTypes[propName];
        if (types) return types.indexOf(this.model.get('type') > -1);
    },

    initializeProperties() {
        for (let propIndex in this.propertiesForTypes) {
            let propValue = this.propertiesForTypes[propIndex];

            let $propContainer = this.$el.find('[data-js-prop-' + propIndex + ']');
            if (propValue.indexOf(this.model.get('type')) > -1) {
                $propContainer.removeClass('hidden');

                if (this.dependsProperties[propIndex]) {
                    this.dependsProperties[propIndex].forEach((depend) => {
                        if (this.ui[depend]) this.ui[depend].removeClass('hidden');
                    });
                }
            } else {
                $propContainer.addClass('hidden');

                if (this.dependsProperties[propIndex]) {
                    this.dependsProperties[propIndex].forEach((depend) => {
                        if (this.ui[depend]) this.ui[depend].addClass('hidden');
                    });
                }
            }
        }
    },

    onChangeForm() {
        this.initializeAdditionalProperties();
    },

    initializeSpinner($spinner, type) {
        let $input = $spinner.find('[data-js-spinner-input]'),
            $plus = $spinner.find('[data-js-spinner-plus]'),
            $minus = $spinner.find('[data-js-spinner-minus]'),
            self = this;

        function getSpinnerValue() {
            let val = parseInt($input.val());
            if (isNaN(val) || val < 1) val = 0;
            return val;
        }

        function setSpinnerValue(val) {
            if (val < 1) val = 1;
            $minus.prop('disabled', val == 1);
            $input.val(val);

            if (type == 'quantity') self.model.set('quantity', val);
        }

        $minus.click(() => {
            let val = getSpinnerValue();
            setSpinnerValue(--val);
        });

        $plus.click(() => {
            let val = getSpinnerValue();
            setSpinnerValue(++val);
        });

        $input.on('change', () => {
            let val = getSpinnerValue();
            setSpinnerValue(val);
        });

        $input.trigger('change');
    },

    getFormData() {
        const formData = new FormData(),
            period = parseInt(this.ui.spinnerDays.find('input').val()),
            totalPeriod = this.ui.validuntilDateProp.find('input').val();

        if (this.model.get('id')) formData.append('id', this.model.get('id'));

        formData.append('brand_id', this.model.get('brand_id'));
        formData.append('type', this.model.get('type'));
        formData.append('name', this.model.get('name') || "");
        if (this.model.get('newPhotoId')) {
            formData.append('photo', this.model.get('newPhotoId'));
        }
        formData.append('quantity', parseInt(this.ui.spinnerQty.find('input').val()));
        formData.append('period', this.propertyIsVisible('validity') && this.ui.radioValidityInput.filter(':checked').val() == 'unlimited' ? "" : period);
        formData.append('total_period', this.propertyIsVisible('validuntil') && this.ui.radioValidUntilInput.filter(':checked').val() == 'unlimited' ? "" : totalPeriod);
        formData.append('active', parseInt(this.ui.radioStateInput.filter(':checked').val()));
        formData.append('custom_quantity_allowed', this.model.get('custom_quantity_allowed'));
        formData.append('is_for_business_checkin', this.model.get('is_for_business_checkin'));
        formData.append('transformation_type', this.model.get('transformation_type'));
        formData.append('transformation_ratio', this.model.get('transformation_ratio'));

        return formData;
    }
});

const ImageWidget = LayoutView.extend({
    template: templateImageView,

    ui: {
        qty: '[data-js-qty]',
        name: '[data-js-gift-name]',
        image: '[data-js-image]'
    },

    regions: {
        image: '[data-js-image]'
    },

    computeds: {
        isCoins: {
            deps: ['type'],
            get: type => type == Type.Coins
        },
        isGift: {
            deps: ['type'],
            get: type => type == Type.Gift,
        },
        isDiscount: {
            deps: ['type'],
            get: type => type == Type.Discount,
        },
        cThumb: {
            deps: ['isCoins', 'thumb'],
            get: (isCoins, thumb) => !isCoins && thumb
        }
    },

    bindingFilters: {
        srcToUrl: src => src && `url(${src})` || '',
    },

    bindings: {
        '@ui.qty': 'text:quantity',
        '@ui.name': 'value:name',
        '@ui.image': 'classes:{coins:isCoins,gifts:isGift,discounts:isDiscount,empty:not(cThumb)},' +
        'css:{"background-image":srcToUrl(cThumb)}'
    },

    initialize() {
        if (!this.model.has('type')) {
            this.model.set({ type: null });
        }

        if (!this.model.has('quantity')) {
            this.model.set({ quantity: null });
        }

        if (!this.model.has('name')) {
            this.model.set({ name: null });
        }

        if (!this.model.has('thumb')) {
            this.model.set({ thumb: null });
        }
    },

    onRender() {
        if (this.model.get('type') != Type.Coins) {
            const imageInput = new ImageInput({
                model: new Model
            });

            this.listenTo(imageInput, 'change', file => {
                if (file instanceof Blob || _.isString(file)) {
                    this.viewModel.set({ disabled: true });

                    ImageInputHelpers.upload(file, 'gt')
                        .then(uploadedFile => {
                            this.viewModel.set({ disabled: false });
                            this.model.set({
                                newPhotoId: uploadedFile.id,
                                photo: uploadedFile.full,
                                thumb: uploadedFile[640] || uploadedFile[320] || uploadedFile.full
                            });
                        })
                        .catch(e => {
                            this.viewModel.set({ disabled: false });
                            this.showError(e);
                        });
                }
            });

            this.image.show(imageInput);
        } else {
            this.image.reset();
        }
    }
});

