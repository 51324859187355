import PlatformFeature from './PlatformFeature';

class DeepLinking extends PlatformFeature {
    async initialize() {
///////////////////////
//////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
///////////
///////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////

/////////////////
        this.initializePromise = Promise.reject(DeepLinking.UnSupported);
//////////////////

        return this.initializePromise.then((apiSource) => {
            this.ready = true;
            this.apiSource = apiSource;
        });
    }

    async setDynamicLinkHandler(handler) {
        return this.ensureInitialized()
            .then(() => {
                this.apiSource.onDynamicLink(handler);
            });
    }
}

export function parseURL(url) {
    let parser = document.createElement('a'),
        searchObject = {},
        queries, split, i;

    parser.href = url;
    queries = parser.search.replace(/^\?/, '');
    queries = queries.length > 0 ? queries.split('&') : [];

    for(i = 0; i < queries.length; i++ ) {
        split = queries[i].split('=');
        searchObject[split[0]] = split[1] || true;
    }

    return {
        protocol: parser.protocol,
        host: parser.host,
        hostname: parser.hostname,
        port: parser.port,
        pathname: parser.pathname,
        search: parser.search,
        searchObject: searchObject,
        hash: parser.hash
    };
}

export const DeepLinkAllowedHostNames = ['say2b.page.link', 'link.say2b.com', 'link.b2cmessenger.com'];
export const DeepLinkAllowedDomains = ['say2b.com', 'web.say2b.com', 'web.b2cmessenger.com'];

export function parseDeepLink(url) {
    const parsedUrl = parseURL(url) || null;
    const parsedLink = parsedUrl && parsedUrl.searchObject && parsedUrl.searchObject.link &&
        parseURL(decodeURIComponent(parsedUrl.searchObject.link)) || null;

    if (parsedLink !== null) {
        return {
            host: parsedUrl.hostname,
            params: _.omit(parsedUrl.searchObject, 'link'),
            path: (parsedLink.hash || '').substr(1),
            attributes: parsedLink.searchObject,
            link: decodeURIComponent(parsedUrl.searchObject.link)
        };
    }

    return null;
}

export function isValidDeepLink(dl) {
    const { host = '', link = '' } = dl;
    const { hostname: linkHost = '' } = parseURL(link);

    return DeepLinkAllowedHostNames.indexOf(host.toLowerCase()) > -1 && DeepLinkAllowedDomains.indexOf(linkHost) > -1;
}

export function getMenuTableFromDeepLinkPath(path) {
    const matches = path.replace('#', '').match(/^place\/(\d+)\/table\/(\d+)$/);

    if (matches !== null) {
        return {
            placeId: Number(matches[1]),
            tableId: Number(matches[2])
        };
    }

    return null;
}

const DLService = new DeepLinking();
export default DLService;

window.isValidDeepLink = isValidDeepLink;
window.parseDeepLink = parseDeepLink;
window.__DeepLinking = DLService;
