export const getAppState        = state => state.app;

export const getLoginedUser                     = state => getAppState(state).loginedUser || null;
export const getLoginedUserDefaultWorkPlaceId   = state => getAppState(state).defaultWorkPlaceId || null;
export const getLoginedUserWorkPlaces           = state => getAppState(state).workPlaces;
export const getLoginedUserWorkPlacesRoles      = state => getAppState(state).workPlacesRoles;
export const getLoginedUserWorkPlacesStatuses   = state => getAppState(state).workPlacesStatuses;

export const getIsUserLoggedIn  = state => getLoginedUser(state) !== null;
export const getLoginedUserId   = state => (getLoginedUser(state) || { id: null }).id;

export const getLoginedUserWorkPlacesIds = state => {
    const workPlaces = getLoginedUserWorkPlaces(state);
    return _.keys(workPlaces).map(Number).sort();
};
export const getLoginedUserWorkPlacesAsArray = state => {
    const workPlacesIds = getLoginedUserWorkPlacesIds(state);
    return workPlacesIds.map(getLoginedUserWorkPlaceById.bind(null, state));
};
export const getLoginedUserWorkPlaceById = (state, placeId) => {
    const workPlaces = getLoginedUserWorkPlaces(state);
    return workPlaces[placeId] || null;
};

export function getLoginedUserDefaultWorkPlace(state) {
    const defaultWorkPlaceId = getLoginedUserDefaultWorkPlaceId(state);

    if (defaultWorkPlaceId === null) {
        return defaultWorkPlaceId;
    }

    return getLoginedUserWorkPlaceById(state, defaultWorkPlaceId);
}

export function getHasLoginedUserRoleInPlaceById(state, placeId, role) {
    const workPlacesRoles = getLoginedUserWorkPlacesRoles(state);

    if (_.has(workPlacesRoles, placeId)) {
        return (workPlacesRoles[placeId] || []).indexOf(role) > -1;
    }

    return false;
}

export function getLoginedUserWorkPlaceStatusById(state, placeId) {
    return getLoginedUserWorkPlacesStatuses(state)[placeId] || 0;
}

export function getIsLoginedUserNowWorkingByPlaceId(state, placeId) {
    const status = getLoginedUserWorkPlaceStatusById(state, placeId);

    return Boolean(status);
}
