import React from 'react';
import './MenuCategories.scss';
import MenuList, { MenuListType, byIdAndSortOrder, MenuListItem } from 'components/menu/MenuList/MenuList';
import { UnlistedCategory } from 'utils/api/menu';
import { MenuCategoryBase } from 'components/menu/MenuCategory/MenuCategory';
import { MenuVisibilityToggle } from 'components/menu/MenuVisibility/MenuVisibility';

const MenuEditableCategories = (props) => {
    const {
        categories,
        onSelectCategory = _.noop,
        onRemoveCategory = _.noop,
        onEditCategory = _.noop,
        onToggleVisibility = _.noop,
    } = props;

    const categoriesList = categories.map((c) => {
        const isUnlisted = c.id === UnlistedCategory.id;
        const leftContent = isUnlisted ? (
            <div className="remove" />
        ) : (
            // eslint-disable-next-line react/button-has-type
            <button className="ion-ios-close-outline remove" onClick={onRemoveCategory.bind(null, c.id, c)} />
        );

        const rightContent = isUnlisted ? (
            <div className="edit" />
        ) : (
            <button className="ion-md-create edit" onClick={onEditCategory.bind(null, c.id, c)} />
        );

        return (
            <MenuListItem
                className="menu-list-item-with-controls menu-editable-list-item"
                key={byIdAndSortOrder(c)}
                left={leftContent}
                right={rightContent}
            >
                <MenuCategoryBase
                    className="menu-list-item"
                    {...c}
                    onSelectCategory={onSelectCategory.bind(null, c.id, c)}
                >
                    {!isUnlisted && (
                        <MenuVisibilityToggle
                            onClick={onToggleVisibility.bind(null, c)}
                            active={Boolean(c.is_hidden_forever || c.hidden_until)}
                        />
                    )}
                </MenuCategoryBase>
            </MenuListItem>
        );
    });

    return (
        <MenuList {...props} type={MenuListType.Categories}>
            {categoriesList}
        </MenuList>
    );
};

export default MenuEditableCategories;
