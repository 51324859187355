import {
    initFromOfflineStorage, logout, setLoginedUser,
    setLoginedUserDefaultWorkplaceId, setLoginedUserWorkplaceRoles,
    setLoginedUserWorkplaces,
    setLoginedUserWorkplacesRoles, setLoginedUserWorkplacesStatuses,
    setLoginedUserWorkplaceStatus
} from "store/app/actions";
import store from "store";
import ConfirmModalWindow from "windows/Modal/Confirm";

var LoginedUserHandler = {
    loginedUser : null, // Объект - текущий залогиненный пользователь
    accessToken : null, // токен взаимодействия с сервером

    user_work_places_statuses: null,
    user_work_places_roles : null, // Объект типа place_id : [role_name, role_name ...]
    user_work_places : null, // Объект типа place_id : [role_name, role_name ...]
    user_def_work_place_id : null, // индентификатор рабочего места по умолчанию

    syncOfflineStorage: function () {
        if (!this.fileStore) this.fileStore = new OfflineStorage("LoginedUser");

        var urls = [];
        if (this.loginedUser) {
            if (this.loginedUser.avatar || this.loginedUser.avatarThumb)
                urls.push(this.loginedUser.avatar || this.loginedUser.avatarThumb);

            var places = this.get_user_work_places();
            if (places) {
                _(places).each(function (place) {
                    if (place.logo) urls.push(place.logo);
                    if (place.logoThumb) urls.push(place.logoThumb);
                })
            }

            var that = this;

            this.fileStore
                .sync(urls)
                .then(function (entries) {
                    console.log("LoginedUserHandler OfflineStorage.sync result", entries);
                    if (that.loginedUser) {
                        var safe = false;

                        if (entries[that.loginedUser.avatar]) {
                            var avatar_local = entries[that.loginedUser.avatar];

                            if (_(avatar_local).isError() || avatar_local instanceof FileTransferError) {
                                if (that.loginedUser.avatar_local) {
                                    delete that.loginedUser.avatar_local;
                                    safe = true;
                                }
                            } else {
                                if (that.loginedUser.avatar_local != avatar_local) {
                                    safe = true;
                                    that.loginedUser.avatar_local = avatar_local;
                                }
                            }
                        }

                        if (entries[that.loginedUser.avatarThumb]) {
                            var avatarThumb_local = entries[that.loginedUser.avatarThumb];

                            if (_(avatarThumb_local).isError() || avatarThumb_local instanceof FileTransferError) {
                                if (that.loginedUser.avatarThumb_local) {
                                    delete that.loginedUser.avatarThumb_local;
                                    safe = true;
                                }
                            } else {
                                if (that.loginedUser.avatarThumb_local != avatarThumb_local) {
                                    safe = true;
                                    that.loginedUser.avatarThumb_local = avatarThumb_local;
                                }
                            }
                        }

                        var places = that.get_user_work_places();
                        if (places) {
                            _(places).each(function (place) {
                                if (entries[place.logo]) {
                                    var local = entries[place.logo];

                                    if (_(local).isError() || local instanceof FileTransferError) {
                                        if (place.logo_local) {
                                            delete place.logo_local;
                                            safe = true;
                                        }
                                    } else {
                                        if (place.logo_local != local) {
                                            safe = true;
                                            place.logo_local = local;
                                        }
                                    }
                                }

                                if (entries[place.logoThumb]) {
                                    var local = entries[place.logoThumb];

                                    if (_(local).isError() || local instanceof FileTransferError) {
                                        if (place.logoThumb_local) {
                                            delete place.logoThumb_local;
                                            safe = true;
                                        }
                                    } else {
                                        if (place.logoThumb_local != local) {
                                            safe = true;
                                            place.logoThumb_local = local;
                                        }
                                    }
                                }
                            })
                        }

                        if (safe) {
                            that.saveToLocalStorage();
                        }
                    }
                })
                .catch(function (err) { console.warn(err); });
        }
    },

    logout: function () {
        if (!this.fileStore) this.fileStore = new OfflineStorage("LoginedUser");

        this.loginedUser = null;
        this.accessToken = null;
        delete localStorage.initer_LastUserInitedTime;

        delete localStorage.loginedUser;
        delete localStorage.loginedUserPassword;
        delete localStorage.accessToken;

        delete localStorage.brand_id_to_card_id;
        delete localStorage.loyalty_cards;
        delete localStorage.loyalty_cards_brands;
        LoyaltyCardBase.init_local_storage([], []);

        delete localStorage.user_work_places;
        delete localStorage.user_work_places_roles;
        delete localStorage.user_def_work_place_id;

        this.user_work_places_roles = null;
        this.user_work_places = null;
        this.user_def_work_place_id = null;

        if (window.notificationCollection) notificationCollection.reset();
        if (window.notificationMuteCollection) notificationMuteCollection.reset();
        this.fileStore.sync([]);
        this.trigger('logout');

        store.dispatch(logout());
    },

    initFromLocalStorage: function () {
        if (localStorage.loginedUser == null)
            return false;
        this.loginedUser = JSON.parse(localStorage.loginedUser);

        store.dispatch(
            initFromOfflineStorage(
                this.loginedUser,
                this.get_user_work_places(),
                this.get_work_places_roles(),
                this.get_default_work_place_id(),
                this.get_work_places_statuses()
            )
        );

        this.accessToken = localStorage.accessToken;

        this.syncOfflineStorage();

        return this.loginedUser;
    },

    saveToLocalStorage: function () {
        if (!this.fileStore) this.fileStore = new OfflineStorage("LoginedUser");

        localStorage.loginedUser  = JSON.stringify(this.loginedUser);
        localStorage.accessToken = this.accessToken;
    },

    isUserLogined : function() {
        if (this.loginedUser)
            return true;
        if (localStorage.loginedUser) {
            this.initFromLocalStorage();
            return true;
        }
        return false;
    },

    getLoginedUser : function() {
        if (this.loginedUser == null) {
            if (localStorage.loginedUser != null) {
                this.loginedUser = JSON.parse(localStorage.loginedUser);
            }
            return this.loginedUser;
        } else {
            return this.loginedUser;
        }
    },

    getLoginedUserId: function () {
        return this.loginedUser && this.loginedUser.id;
    },

    getLoginedUserName: function () {
        if (this.loginedUser) {
            var firstname = this.loginedUser.firstname,
                lastname = this.loginedUser.lastname;

            return firstname + (lastname ? ' ' + lastname : '');
        } else {
            return ''
        }
    },

    setLoginedUser: function (loginedUser) {
        if (!this.loginedUser && window.notificationCollection) notificationCollection.fetch({ remove: false });

        this.loginedUser = loginedUser;
        localStorage.loginedUser = JSON.stringify(this.loginedUser);
        store.dispatch(setLoginedUser(loginedUser));

        this.syncOfflineStorage();
    },

    setLoyaltyKioskMode(placeId = null, options = null) {
        const user = _.clone(this.getLoginedUser());

        const prevValue = user.loyaltyKioskEnabledFor;
        let newValue = null;

        if (placeId !== null && (!prevValue || Number(prevValue.placeId) !== Number(placeId))) {
            newValue = {
                placeId, ...options
            };

            user.loyaltyKioskEnabledFor = { ...newValue };
        } else if (prevValue && placeId == null) {
            delete user.loyaltyKioskEnabledFor;
        }

        this.setLoginedUser(user);
        this.trigger('change:loyaltyKioskEnabledFor', newValue !== null ? { ...newValue } : newValue);
    },

    isLoyaltyKioskModeEnabledForDefaultWorkPlace() {
        const user = this.getLoginedUser();
        const placeId = this.get_default_work_place_id();

        if (user && placeId) {
            if (_.isObject(user.loyaltyKioskEnabledFor)) {
                return Number(user.loyaltyKioskEnabledFor.placeId) === Number(placeId);
            }

            if (_.isString(user.loyaltyKioskEnabledFor) || _.isNumber(user.loyaltyKioskEnabledFor)) {
                return Number(user.loyaltyKioskEnabledFor) === Number(placeId);
            }
        }

        return false;
    },

    getLoyaltyKioskOptions() {
        const user = this.getLoginedUser();

        if (user) {
            if (_.isObject(user.loyaltyKioskEnabledFor)) {
                return _.omit({ ...user.loyaltyKioskEnabledFor }, 'placeId')
            }
        }

        return {};
    },

    setAccessToken : function(accessToken) {
        this.accessToken = accessToken;
        localStorage.accessToken  = accessToken;
    },

    getAccessToken : function() {
        if (this.accessToken == null) {
            if (localStorage.accessToken != null) {
                this.accessToken = localStorage.accessToken;
            }
            return this.accessToken;
        } else {
            return this.accessToken;
        }
    },

    isUserEmployee : function(placeid) {
        //return this.isUserLogined() && PlaceEmployee != null && PlaceEmployee[placeid] != null;
        return this.isUserLogined() && this.get_work_places_roles() != null && this.get_work_places_roles()[placeid] != null;
    },

    isHasRole : function(place_id, role) {
        if (!this.isUserLogined())
            return false;

        var user_work_places_roles = this.get_work_places_roles();
        if (user_work_places_roles == null)
            return false;

        var roles = user_work_places_roles[place_id];
        if(roles == null)
            return false;

        for(var i=0; i<roles.length; i++) {
            if(roles[i] == role)
                return true;
        }
        return false;
    },

    isHasRoleInAnyBrandPlace : function(brand_id, role) {
        if (!this.isUserLogined())
            return false;
        var places_ids = this.getUserWorkPlacesIDSByBrandId(brand_id);
        for(var i=0; i<places_ids.length; i++) {
            if(this.isHasRole(places_ids[i], role))
                return true;
        }
        return false;
    },

    getUserWorkPlacesIDSByBrandId : function(brand_id) {
        var places_ids = [], place;
        var places = this.get_user_work_places();
        if (places != null) {
            for(var i=0; i<places.length; i++) {
                place = places[i];
                if(place.brand_id == brand_id)
                    places_ids.push(place.id);
            }
        }
        return places_ids;
    },

    get_work_places_roles : function() {
        if (this.user_work_places_roles == null) {
            if (localStorage.user_work_places_roles != null) {
                this.user_work_places_roles = JSON.parse(localStorage.user_work_places_roles);
            }
            return this.user_work_places_roles;
        } else {
            return this.user_work_places_roles;
        }
    },

    get_work_places_statuses: function() {
        if (this.user_work_places_statuses == null) {
            if (localStorage.user_work_places_statuses != null) {
                this.user_work_places_statuses = JSON.parse(localStorage.user_work_places_statuses);
            }
            return this.user_work_places_statuses;
        } else {
            return this.user_work_places_statuses;
        }
    },

    update_work_place_status: function(place_id, status) {
        if (!this.isUserLogined()) {
            return;
        }

        this.user_work_places_statuses = {
            ...this.user_work_places_statuses,
            [place_id]: status
        };
        localStorage.user_work_places_statuses = JSON.stringify(this.user_work_places_statuses);
        store.dispatch(setLoginedUserWorkplaceStatus(place_id, status));

        this.trigger('change:user_work_place_status', place_id, status);

        if (place_id == this.get_default_work_place_id()) {
            this.trigger('change:user_default_work_place_status', status);
        }
    },


    init_work_place: function (user_work_places_roles, user_work_places_statuses = {}) {
        return new Promise((function (resolve, reject) {
            this.user_work_places = null;
            this.user_work_places_roles = null;

            localStorage.user_work_places_roles = JSON.stringify(user_work_places_roles);
            store.dispatch(setLoginedUserWorkplacesRoles(user_work_places_roles));

            localStorage.user_work_places_statuses = JSON.stringify(user_work_places_statuses);
            store.dispatch(setLoginedUserWorkplacesStatuses(user_work_places_statuses));

            if (user_work_places_roles && _.size(user_work_places_roles)) {
                var filter = {
                    ids: _.keys(user_work_places_roles)
                };

                var that = this;
                B2CPlace.server_search(filter,
                    function (data) {
                        that.on_server_load_work_places_ok(data);
                        resolve();
                    },
                    function (jqXHR, textStatus, errorThrown) {
                        that.on_server_load_work_places_error(jqXHR, textStatus, errorThrown);
                        reject(new AjaxError(jqXHR, textStatus, errorThrown));
                    }
                )
            } else {
                resolve();
            }
        }).bind(this));

    },
    update_work_place_roles: function(place_id, roles) {
        if (!this.isUserLogined()) {
            return;
        }

        this.user_work_places_roles = {
            ...this.user_work_places_roles,
            [place_id]: roles
        };
        localStorage.user_work_places_roles = JSON.stringify(this.user_work_places_roles);
        store.dispatch(setLoginedUserWorkplaceRoles(place_id, roles));

        this.trigger('change:user_work_place_roles', place_id, roles);

        if (place_id == this.get_default_work_place_id()) {
            this.trigger('change:user_default_work_place_roles', roles);
        }
    },
    save_user_work_places : function(places) {
        localStorage.user_work_places = JSON.stringify(places);
        store.dispatch(setLoginedUserWorkplaces(places));

        this.syncOfflineStorage();
    },
    get_user_work_places : function() {
        if (this.user_work_places == null) {
            if (localStorage.user_work_places != null) {
                this.user_work_places = JSON.parse(localStorage.user_work_places);
            }
        }
        return this.user_work_places;
    },
    set_default_work_place_id: function (place_id) {
        var workPlaces = this.get_user_work_places();
        if (_.find(workPlaces, function (wp) { return wp.id == place_id })) {
            this.user_def_work_place_id = place_id;
            localStorage.user_def_work_place_id = place_id;
            store.dispatch(setLoginedUserDefaultWorkplaceId(place_id));

            if (window.GoogleAnalytics)
                window.GoogleAnalytics.addCustomDimension(2, (place_id ? place_id : -1), function () { }, function () { });

            this.trigger('change:user_def_work_place_id', this, this.user_def_work_place_id);
        }
    },
    get_default_work_place_id : function() {
        if (!this.user_def_work_place_id) {
            if (localStorage.user_def_work_place_id != null) {
                this.user_def_work_place_id = localStorage.user_def_work_place_id;
            }

            if (!this.user_def_work_place_id) {
                var workPlaces = this.get_user_work_places();
                return workPlaces && workPlaces[0] && workPlaces[0].id;
            } else {
                return this.user_def_work_place_id;
            }
        } else {
            return this.user_def_work_place_id;
        }
    },
    get_work_place_by_id : function(id) {
        var places = this.get_user_work_places(), place;

        if (places) {
            for (var i = 0; i < places.length; i++) {
                place = places[i];
                if (place.id == id)
                    return place;
            }
        } else {
            return null;
        }
    },
    get_default_work_place : function() {
        var place_id = this.get_default_work_place_id();
        if (!place_id)
            return null;
        return this.get_work_place_by_id(place_id);
    },
    on_server_load_work_places_ok: function (data) {
        this.user_work_places = null;
        this.save_user_work_places(data.places);
        var def_place_id = this.user_def_work_place_id || localStorage.user_def_work_place_id;
        if (!def_place_id || !_.find(data.places, function (place) { return place.id == def_place_id })) {
            if (data.places.length == 1) {
                this.set_default_work_place_id(data.places[0].id);
            } else {
                new DefaultWorkPlaceChooserWindow({ closeOnSelect: true }).show().then(function () {
                    app.controller.index();
                });
            }
        }
    },
    on_server_load_work_places_error : function(jqXHR, textStatus, errorThrown) {
        showError(new AjaxError(jqXHR, textStatus, errorThrown));
    },
    ensureUserLoggedIn: function (callback) {
        if (this.isUserLogined()) {
            _.isFunction(callback) && callback();
            return true;
        }
        new ConfirmModalWindow({
            title: 'You must be logged in to perform this action.<br><br> Proceed to login page?',
            isHtml: true,
        })
            .show()
            .then(result => {
                result && app.controller.goToLoginPage({afterLoginCallback: callback});
            });
    },
}

_.extend(LoginedUserHandler, Backbone.Events);

window.LoginedUserHandler = LoginedUserHandler; // TODO: remove globals, use module exports
// export default LoginedUserHandler;
