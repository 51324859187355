import _ from 'underscore';
import { ItemView, ViewModel } from '@b2cmessenger/backbone';

import './Footer.scss';
import template from './Footer.jade';

import buttons from 'widgets/Buttons/Buttons';

/**
 * Footer widget for Windows and Pages. Can have buttons
 * @listens disable Disables buttons
 * @listens enable Enable buttons
 */
const FooterView = ItemView.extend({
    getButton$(id) {
        return this.ui[`btn${id}`];
    },

    template: template,

    tagName: "div",
    className: "widget footer",

    ui: {},

    /**
     * @typedef {String|Object} ButtonDescription - Either simple button id or object that describes button parameters
     * @property {String} id - Id of the button (used in triggers and ui hash)
     * @property {String} [text] - Caption on button
     * @property {String} [icn=id] - Icon for button
     * @property {String} [class=""] - Class for button element
     * @see widgets/Buttons for list of available buttons
     */

    /**
     * @param {Object} options Options
     * @param {ButtonDescription[]} [options.buttons] - Array of buttons
     * @fires {btnId}:click - Buttons trigger {btnId}:click events
     */
    initialize(options) {
        this.options = options;

        this.viewModel = new ViewModel;

        this.listenTo(this.viewModel, 'change:disabled',
            (m, disabled) => _(this.ui).each(elem => elem.attr && elem.attr('disabled', disabled)));

        this.listenTo(this.viewModel, 'change:moreOpened',
            (m, opened) => this.ui.btnmore.toggleClass('set', opened)
        );
        this.listenTo(this, 'more:click',
            () => this.viewModel.set('moreOpened', !this.viewModel.get('moreOpened'))
        );
    },

    onRender() {
        if(this.options.buttons) for(let btn of this.options.buttons) {
            let $btn = this._renderButton(btn);
            if (!_.isNull($btn)) {
                this.$el.append($btn);
            }
        }

        if (this.options.moreButtons && this.options.moreButtons.length > 0) {
            const $moreButton = this._renderButton('more');
            this.$el.append($moreButton);
            const $morelist = $moreButton.find('.more-list');

            for (let btn of this.options.moreButtons) {
                let $btn = this._renderButton(btn);
                if (!_.isNull($btn)) {
                    $morelist.append($btn);
                }
            }
        }
    },

    _renderButton(btn) {
        let id, text, icn, cl;
        if (_.isObject(btn) && (btn.id || btn.text)) {
            id = btn.id;
            text = btn.text;
            icn = btn.icn || btn.icon || (buttons[id] && id) || 'empty';
            cl = btn.class;
        } else {
            id = btn;
            icn = id;
        }

        if (id && buttons[icn]) {
            let $btn = buttons[icn]({text: text});
            $btn.data('footer-button-id', id);
            $btn.click(event => this.trigger(`${id}:click`, event));
            if (cl) $btn.addClass(cl);
            //this.$el.append($btn);
            this.ui[`btn${id}`] = $btn;

            return $btn;
        }

        return null;
    }
});

export default FooterView;
