import React from 'react';
import moment from 'moment-timezone';
import './MenuWaiterCallList.scss';
import {
    optionBitsMap,
    actionLabelMap,
    optionsLabelMap,
    actionIconMap,
    optionsIconMap,
} from 'components/menu/MenuWaiterCall/MenuWaiterCallContainer';
import PropTypes from 'prop-types';
import InlineUser from 'components/UI/User/InlineUser';

const getListOfAppliedOptions = (options) => {
    return Object.values(optionBitsMap).filter((v) => options && v);
};

const MenuWaiterCallListItem = (props) => {
    const { model, showClient, showWaiter, onListItemClick } = props;

    const onClick = () => onListItemClick(model.order && model.order.id ? model.order.id : null);
    const time = moment(B2Cjs.datetimeServerToJS(model.created_at)).format('LT');

    const action = (
        <div className="menu-waiter-call-list__item-action">
            <i className={actionIconMap[model.action]} />
            <span>{actionLabelMap[model.action]}</span>
        </div>
    );

    const options = getListOfAppliedOptions(model.options).map((option) => {
        const label = optionsLabelMap[option];
        const icon = optionsIconMap[option];
        return (
            <div className="menu-waiter-call-list__item-option" key={label}>
                {icon && <i className={icon} />}
                <span>{label}</span>
            </div>
        );
    });

    return (
        <div className="menu-waiter-call-list__item" onClick={onClick}>
            <div className="menu-waiter-call-list__item-details-wrapper">
                <div className="menu-waiter-call-list__item-details">
                    {model.table_number && (
                        <span className="menu-waiter-call-list__item-details-additional">
                            <span>Table</span>
                            {' '}
                            <span>{`#${model.table_number}`}</span>
                        </span>
                    )}
                    {action}
                    {options}
                </div>
                <span className="menu-waiter-call-list__item-time">{time}</span>
            </div>
            {model.comment ? <div className="menu-waiter-call-list__item-comment">{model.comment}</div> : null}
            <div className="menu-waiter-call-list__item-order-info">
                {model.order && model.order.order_number && (
                    <span className="menu-waiter-call-list__item-details-additional">
                        Order #
                        {model.order.order_number}
                    </span>
                )}
                {showWaiter && model.waiterUser && (
                    <div className="menu-waiter-call-list__waiter-client-user">
                        <InlineUser {...model.waiterUser} nameFirst />
                    </div>
                )}

                {showClient && model.user && (
                    <div className="menu-waiter-call-list__waiter-client-user">
                        <InlineUser {...model.user} nameFirst />
                    </div>
                )}
            </div>
        </div>
    );
};

const MenuWaiterCallList = (props) => {
    const { models, showClient = false, showWaiter = false, onListItemClick = _.noop } = props;

    return (
        <div className="menu-waiter-call-list">
            <h3>History</h3>
            {_.sortBy(models, (m) => -m.id).map((model) => {
                return (
                    <MenuWaiterCallListItem
                        key={model.id}
                        model={model}
                        showClient={showClient}
                        showWaiter={showWaiter}
                        onListItemClick={onListItemClick}
                    />
                );
            })}
        </div>
    );
};

MenuWaiterCallList.propTypes = {
    models: PropTypes.array,
    showClient: PropTypes.bool,
    showWaiter: PropTypes.bool,
    onListItemClick: PropTypes.func,
};

export default MenuWaiterCallList;
