import {
    REMOVE_ORDER,
    RESET_ORDERS,
    UPLOAD_ORDER, UPLOAD_ORDER_FAILED,
    UPLOAD_ORDER_SUCCEEDED,
    UPSERT_ORDER,
    UPSERT_ORDERS
} from "store/menu/orders/actions";
import { upsertItemsInArrayByKey, upsertItemInArrayByKey, removeItemFromArrayById } from "store/menu/utils";
import { INIT_MENU } from "store/menu/actions";

const initialState = {
    orders: [],
    loadingOrder: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UPSERT_ORDER: {
            const { order } = action.payload;
            const { orders: prevOrders } = state;

            const orders = upsertItemInArrayByKey({item: order, array:prevOrders, insertAtTheEnd: false});

            return {
                ...state,
                orders
            }
        }

        case UPSERT_ORDERS: {
            const { orders: ordersFromPayload = [] } = action.payload;
            const { orders: prevOrders = [] } = state;

            const orders = upsertItemsInArrayByKey({ items: ordersFromPayload, array: prevOrders, insertAtTheEnd: false});

            return {
                ...state,
                orders
            }
        }

        case REMOVE_ORDER: {
            const { orderOrOrderId } = action.payload;
            const { orders: prevOrders = [] } = state;

            const orders = removeItemFromArrayById(orderOrOrderId, prevOrders);

            return {
                ...state,
                orders
            }
        }

        case INIT_MENU:
        case RESET_ORDERS: {
            const { orders: ordersFromPayload = [] } = action.payload;

            return {
                ...state,
                orders: ordersFromPayload
            };
        }

        case UPLOAD_ORDER: {
            return {
                ...state,
                loadingOrder: true
            }
        }

        case UPLOAD_ORDER_SUCCEEDED: {
            return {
                ...state,
                loadingOrder: false
            }
        }

        case UPLOAD_ORDER_FAILED: {
            return {
                ...state,
                loadingOrder: false
            }
        }

        default:
            return state
    }
}