import _ from 'underscore';
import { CollectionView } from '@b2cmessenger/backbone';

import Window from 'windows/Window';
import HeaderView from 'widgets/Header/Header';
import PlaceItemWidget from 'widgets/PlaceItem/PlaceItem';

import contentTemplate from './Content.jade';
import './PlaceChooser.scss';

let PlaceCollectionView = CollectionView.extend({
    tagName: 'ul',
    className: "widget place-chooser-collection-widget",
    childView: PlaceItemWidget,
    sort: false,

    initialize(options) {
        this.listenTo(this, 'childview:select', view => {
            let selectedView;
            if(view.model.get('cl_selected') && !options.strictSingle) {
                view.model.unset('cl_selected');
                view.el.classList.remove('selected');
            } else {
                view.model.set('cl_selected', true);
                view.el.classList.add('selected');
                selectedView = view;
            }

            if(!options.multiple) {
                this.collection.each(m => {
                    if(m != view.model) { m.unset('cl_selected') }
                });
                this.children.each(c => {
                    if(c != view) c.el.classList.remove('selected');
                });
            }

            this.trigger('select', selectedView);
        });
    },

    attachHtml(collectionView, childView, index) {
        if(childView.model.get('cl_selected')) childView.el.classList.add('selected');

        return CollectionView.prototype.attachHtml.call(this, collectionView, childView, index);
    }
});

let PlaceChooser = Window.extend({
    windowName: "place-chooser-window",
    className: "place-chooser-window",

    initialize(options) {
        this.collection = options.collection;
        this.options = options;

        this.el.classList.toggle('has-selected', this.collection.findWhere({ cl_selected: true }));
    },

    onRender() {
        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: 'Switch work place',
        })
        this.listenTo(headerView, 'back:click', view => this.cancel());
        this.header.show(headerView);

        let contentWidget = new PlaceCollectionView(_.extend(this.options, { collection: this.collection }));
        this.listenTo(this.collection, 'change:cl_selected', () => this.el.classList.toggle('has-selected', this.collection.findWhere({ cl_selected: true })));
        this.content.show(contentWidget);

        this.$el.enhanceWithin();
    },

    cancel() {
        this.close(this.collection);
    }
});

export default PlaceChooser;
