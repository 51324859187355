import React from 'react';
import PropTypes from 'prop-types';
import LeftMenuWindow from 'windows/LeftMenu/LeftMenu';

import 'widgets/Buttons/Buttons.scss';
import './Button.scss';
import useEventListener from 'hooks/useEventListener';

export const Button = (props) => {
    const { wrapChildren = true } = props;

    const className = React.useMemo(() => {
        return [
            'btn-react',
            React.Children.count(props.children) && 'text',
            props.className,
            Boolean(props.active) && 'active',
        ]
            .filter(Boolean)
            .join(' ');
    }, [props.children, props.className, props.active]);

    const children = props.children ? wrapChildren ? <p className="text">{props.children}</p> : props.children : null;

    return (
        <button data-role="none" className={className} onClick={props.onClick} disabled={props.disabled}>
            {(props.standalone || props.icon) && <i className={props.icon} />}
            {(props.standalone || React.Children.count(props.children) > 0) && children}
        </button>
    );
};

export const ButtonPropTypes = {
    standalone: PropTypes.bool,
    className: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.any,
    wrapChildren: PropTypes.bool,
};

Button.propTypes = ButtonPropTypes;

export const MenuButton = (props) => {
    const [className, setClassName] = React.useState('btn-menu');

    const openMenu = React.useCallback(() => {
        new LeftMenuWindow({ right: Boolean(props.right) }).show();
    }, [props.right]);
    const onAppLayoutChange = React.useCallback(
        (isMobile) => {
            setClassName(isMobile ? 'btn-menu' : 'btn-menu invisible');
        },
        [setClassName]
    );

    useEventListener(app, 'change:is:mobile:view', onAppLayoutChange);

    React.useEffect(() => {
        onAppLayoutChange(app.isMobileView);
    }, [onAppLayoutChange]);

    return <Button className={className} standalone onClick={openMenu} {...props} />;
};

MenuButton.propTypes = {
    ...ButtonPropTypes,
    right: PropTypes.bool,
};

export const BackButton = (props) => {
    return <Button className="btn-back" standalone icon="ion-ios-arrow-round-back" {...props} />;
};

BackButton.propTypes = ButtonPropTypes;

export const EditButton = (props) => {
    return <Button className="btn-edit" standalone icon="ion-md-create" {...props} />;
};

export const CommentButton = (props) => {
    return <Button className="btn-comment" standalone icon="ion-md-text" {...props} />;
};

EditButton.propTypes = ButtonPropTypes;

export const ReorderButton = (props) => {
    return <Button className="btn-reorder" standalone icon="ion-ios-swap" {...props} />;
};

ReorderButton.propTypes = ButtonPropTypes;

export const CartButton = (props) => {
    const text = React.useMemo(
        () => (_.isNumber(props.quantity) && props.quantity > 0 ? <>{props.quantity}</> : null),
        [props.quantity]
    );

    return (
        <Button className="btn-cart" icon="ion-md-cart" {...props}>
            {text}
        </Button>
    );
};

CartButton.propTypes = ButtonPropTypes;

export const AddButton = (props) => {
    return <Button className="btn-add" standalone icon="ion-ios-add-circle-outline" {...props} />;
};

AddButton.propTypes = ButtonPropTypes;

export const QrButton = (props) => {
    return <Button className="btn-qr" standalone icon="icon-qrcode" {...props} />;
};

QrButton.propTypes = ButtonPropTypes;

export const SearchButton = (props) => {
    return <Button className="btn-search" standalone icon="ion-ios-search" {...props} />;
};
SearchButton.propTypes = ButtonPropTypes;

export const SendButton = (props) => {
    return <Button className="send ion-md-paper-plane" wrapChildren={false} {...props} />;
};
SendButton.propTypes = ButtonPropTypes;
