import React from 'react';
import TextButton from "components/UI/TextButton";
import PropTypes from 'prop-types';
import './LoadingError.scss';

const LoadingError = (props) => {
    const {
        message = 'An error has occurred. Try again later.',
        className = 'loading-error-content centered',
        displayRetryButton = false,
        retryButtonText = 'Try again',
        retryButtonClassName = 'wide normalized border-filled with-top-margin',
        onClickRetryButton = _.noop,
        disabled = false,
        icon = 'ion-ios-warning-outline'
    } = props;

    const iconEl = icon ? (_.isString(icon) ? <i className={icon} /> : icon) : null;

    return (
        <div className={className}>
            {iconEl}
            {Boolean(message) && <p className='message'>{message}</p>}
            {displayRetryButton && (
                <TextButton className={retryButtonClassName} onClick={onClickRetryButton} disabled={disabled}>
                    {retryButtonText}
                </TextButton>
            )}
        </div>
    );
};
LoadingError.propTypes = {
    message: PropTypes.string,
    displayRetryButton: PropTypes.bool,
    retryButtonText: PropTypes.string,
    retryButtonClassName: PropTypes.string,
    onClickRetryButton: PropTypes.func,
    icon: PropTypes.any,
    disabled: PropTypes.bool
};

export default LoadingError;