import { CollectionView, ViewModel } from '@b2cmessenger/backbone';

import CategoryWidget from 'widgets/Category/Category';

import './Categories.scss';

const CategoriesWidget = CollectionView.extend({
    className: 'widget categories-widget',
    childView: CategoryWidget,
    childViewOptions() {
        return {
            showParentName: !!this.viewModel.get('showParentName'),
            selectable: !!this.viewModel.get('selectable'),
            selection: this.viewModel.get('selection'),
        };
    },

    options: {
        showParentName: false,
        selectable: false,
    },

    initialize() {
        this.viewModel = new ViewModel({
            showParentName: !!this.options.showParentName,
            _selectable: !!this.options.selectable,
            selection: this.options.selection
        });

        this.viewModel.addComputed('selectable', {
            deps: ['_selectable', 'selection'],
            get: (_selectable, selection) => selection && _selectable
        });

        this.listenTo(this.viewModel, 'change:showParentName change:selectable change:selection',
            () => this.children.each(v => v.viewModel.set(this.childViewOptions())));

        this.setFilter(this.options.filter);
    },

    showParentName(show) {
        show = _(show).isUndefined() ? true : !!show;
        this.viewModel.set('showParentName', show);
    },

    hideParentName() {
        this.showParentName(false);
    },

    setSelection(selection) {
        this.viewModel.set({ selection });
    },

    setSelectable(selectable) {
        selectable = !!selectable;
        this.viewModel.set({ _selectable: selectable });
    },

    setFilter(filter) {
        if(filter) {
            this.options.filter = _.isFunction(filter) ? filter : c => c.get('searchName').indexOf(filter) > -1
        } else {
            this.options.filter = undefined;
        }

        if(this.isRendered)
            this.resortView();
    }
});

export default CategoriesWidget;
