const B2CEvidence = {};

B2CEvidence.server_api_save = function(evidence, onSuccess, onError) {
    return Server.callServer({
        url: pref.host+pref.serv_evidence.save,
        type: "POST",
        data: evidence,
        success : onSuccess,
        error : onError
    });
};

B2CEvidence.server_api_delete = function(evidenceid, onSuccess, onError) {
    return Server.callServer({
        url: pref.host+pref.serv_evidence.delete,
        type: "POST",
        data: {id : evidenceid},
        success : onSuccess,
        error : onError
    });
};

B2CEvidence.server_api_search = function(filter, onSuccess, onError) {
    return Server.callServer({
        url: pref.host+pref.serv_evidence.search,
        type: "POST",
        data: filter,
        success : onSuccess,
        error : onError
    });
};

B2CEvidence.server_api_businessvalidate = function(evidenceid, status, comment, onSuccess, onError) {
    return Server.callServer({
        url: pref.host+pref.serv_evidence.businessvalidate,
        type: "POST",
        data: {
            id: evidenceid,
            status: status,
            comment: comment,
        },
        success : onSuccess,
        error : onError
    });
};

B2CEvidence.server_api_photo_add = function(formData, onSuccess, onError) {
    return Server.callServer({
        url: pref.host+pref.serv_evidence.photo.add,
        type: "POST",
        cache: false,
        contentType: false,
        processData: false,
        data: formData,
        success : onSuccess,
        error : onError
    });
};

B2CEvidence.server_api_photo_delete = function(evidence_id, photoDeleteIds, onSuccess, onError) {
    return Server.callServer({
        url: pref.host+pref.serv_evidence.photo.delete,
        type: "POST",
        data: {
            evidence_id: evidence_id,
            ids: photoDeleteIds,
        },
        success : onSuccess,
        error : onError
    });
};

B2CEvidence.const = {};

B2CEvidence.const.mode = {}; // режим вызова метода
B2CEvidence.const.mode.check = true; // проверка без сохранения
B2CEvidence.const.mode.save = false; // сохранение

B2CEvidence.const.status = {}; // статус объекта
B2CEvidence.const.status.draft = 0; // черновик
B2CEvidence.const.status.active = 1; // объект виден бизнесу и не отклонен
B2CEvidence.const.status.approved = 2; // объект был подтвержден бизнесом
B2CEvidence.const.status.discard = -1; // объект был отклонен бизнесом

B2CEvidence.const.type = {}; // тип фото-доказательства
B2CEvidence.const.type.selfie = 1; // селфи
B2CEvidence.const.type.receipt = 2; // чек
B2CEvidence.const.type.otherevidence = 3; // прочие фото-доказательства

B2CEvidence.const.need_users = {}; // Нужно ли в ответ добавлять массив users, который содержит публичные профили представителей безнеса, валидировавших какой-либо из evidence из результата
B2CEvidence.const.need_users.no = 0; // нет
B2CEvidence.const.need_users.yes = 1; // да

B2CEvidence.const.comment_maxlength = 255; // максимальная длина комментария

window.B2CEvidence = B2CEvidence; // TODO: remove globals, use module exports
// export default B2CEvidence;
