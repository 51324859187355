import React from 'react';
import './MenuList.scss';

export const MenuListType = {
    Categories: 'categories',
    Items: 'items',
    Orders: 'orders',
    Users: 'users',
};

export const byIdAndSortOrder = ({ id, sort_order = 'unset' }) => [id, sort_order].join('-');

const MenuList = ({ children, className, type = MenuListType.Categories, emptyContent = null }) => {
    const classNames = ['menu-list', `menu-${type}`, className].filter(Boolean);
    const listContent = (React.Children.count(children) && children) || emptyContent;
    return listContent ? <div className={classNames.join(' ')}>{listContent}</div> : null;
};

export const MenuListItem = (props) => {
    const ref = React.useRef(null);

    return (
        <div ref={props.forwardedRef || ref} className={props.className || 'menu-list-item'} onClick={props.onClick}>
            {props.left && (
                <div className={['menu-list-item-control left', props.leftContentClassName].filter(Boolean).join(' ')}>
                    {props.left}
                </div>
            )}
            {props.children}
            {props.right && (
                <div
                    className={['menu-list-item-control right', props.rightContentClassName].filter(Boolean).join(' ')}
                >
                    {props.right}
                </div>
            )}
        </div>
    );
};

export const MenuListEmptyItem = (props) => {
    const { type = null, message = null, children } = props;

    return (
        <div className="menu-list-item-empty">
            {children ||
                ((type || message) && (
                    <>
                        <i className="ion-ios-information-circle-outline" />
                        <p className="empty-message">{(type && `No ${type} found`) || message}</p>
                    </>
                ))}
        </div>
    );
};

export default MenuList;
