import PlatformFeature from 'utils/PlatformFeature';
import settings from 'settings';
import NotificationModel from 'models/NotificationModel';
import {userDeviceInfo} from 'utils/UserDeviceInfo';

const PUSH_LATEST_REGISTERED_TOKEN_KEY = 'push_latestRegisteredId';
const PUSH_TOKEN_STORAGE_KEY = 'push_registrationId';
const PUSH_LOG_KEY = 'push_log';

class PushService extends PlatformFeature {
    constructor(options) {
        super(options);

        this.onDeviceTokenChanged = _.debounce(this.onDeviceTokenChanged.bind(this), 500);
    }

    async initialize() {
///////////////////////
////////////////////////////////////////////////////////////////////////
/////////////////
        this.initializePromise = Promise.reject(PushService.UnSupported);
//////////////////

        return this.initializePromise
            .then(plugin => {
                this.ready = true;
                this.hasNotificationSubscription = false;
                this.FirebaseAPI = plugin;
                return plugin;
            });
    }

    async getToken() {
        return this.ensureInitialized()
            .then(plugin =>
                new Promise((resolve, reject) => {
                    plugin.getToken(resolve, reject);
                })
            );
    }

    async init() {
        return this.hasPermission()
            .then(hasPermission => {
                if (!hasPermission) {
                    return this.grantPermission();
                }

                return hasPermission;
            })
            .then(hasPermission => {
                this.watchTokenUpdate();

                return this.getToken()
                    .then(token => {
                        this.persistDeviceToken(token);

                        return hasPermission;
                    });
            })
            .then(hasPermission => {
                if (hasPermission) {
                    return this.subscribeToNotifications();
                }

                return Promise.resolve(hasPermission);
            })
    }

    async registerTokenOnServer(token) {
        token = token || localStorage[PUSH_TOKEN_STORAGE_KEY];

        return new Promise((resolve, reject) => {
            if (!token) {
                reject(new Error('PUSH Token missing'));
                return;
            }

            this.log('PushService.registerTokenOnServer(): token:\n' + JSON.stringify(token, null, '  '));

            const { device_platform: platform = '' } = userDeviceInfo.getInfo();

            Server.callServer({
                url: settings.host + settings.serv_notification.registration,
                type: 'POST',
                data: {
                    os: platform.toLowerCase(),
                    registration_id: token,
                },
                success: resolve,
                error: reject,
            });
        });
    }

    async subscribeToNotifications() {
        this.log('PushService.subscribeToNotifications()');

        return this.ensureInitialized()
            .then(plugin => {
                plugin.onMessageReceived(message => {
                    this.log('PushService.onMessageReceived(\'message\'): data:\n' + JSON.stringify(message, null, '  '));

                    const additionalData = (message.data && JSON.parse(message.data)) || {};
                    const {
                        title: apsTitle = null,
                        body: apsBody = null
                    } = message.aps && message.aps.alert || {};

                    const notification = {
                        id: message.notification_id || additionalData.notification_id,
                        status: NotificationModel.Status.New,
                        app_event: message.app_event || additionalData.app_event,
                        template_type: message.template_type || additionalData.template_type,
                        title: apsTitle || message.title,
                        message: apsBody || message.body,
                        data: additionalData.data || additionalData,
                        created_at: message.created_at || additionalData.created_at || B2Cjs.datetimeJSToServer(new Date)
                    };

                    notificationCollection.add([notification], { merge: true, fetchIfNeeded: true, parse: true });

                    if (message.messageType == 'notification' &&
                        (message.tap === 'background' || message.tap === 'foreground')
                    ) {
                        app.executeNotificationAction(notification.id);
                    }
                }, error => {
                    console.error(error);
                });

                this.hasNotificationSubscription = true;
                return Promise.resolve(plugin);
            });
    }

    async hasPermission() {
        return this.ensureInitialized()
            .then(plugin =>
                new Promise((resolve, reject) => {
                    plugin.hasPermission(value => {
                        this.log(`PushService.hasPermission(): ${value ? 'true' : 'false'}`);
                        resolve(value);
                    }, error => {
                        this.log(`PushService.hasPermission(): error: ${JSON.stringify(error)}`);
                        reject(error);
                    })
                })
            );
    }

    async grantPermission() {
        return this.ensureInitialized()
            .then(plugin =>
                new Promise((resolve, reject) => {
                    plugin.grantPermission(value => {
                        this.log(`PushService.grantPermission(): ${value ? 'true' : 'false'}`);
                        resolve(value);
                    }, error => {
                        this.log(`PushService.grantPermission(): error: ${JSON.stringify(error)}`);
                        reject(error);
                    })
                })
            );
    }

    setBadgeNumber(value) {
        return this.ensureInitialized()
            .then(plugin => {
                if (this.isIOS()) {
                    plugin.setBadgeNumber(value);
                }
            })
            .catch(e => {
                if (e != PushService.UnSupported) {
                    throw e;
                }
            });
    }

    async getBadgeNumber() {
        return this.ensureInitialized()
            .then(plugin =>
                new Promise((resolve) => {
                    if (this.isIOS()) {
                        plugin.getBadgeNumber(resolve);
                    } else {
                        resolve(0);
                    }
                })
            );
    }

    watchTokenUpdate() {
        return this.ensureInitialized()
            .then(plugin => {
                plugin.onTokenRefresh(
                    (token) => {
                        localStorage[PUSH_TOKEN_STORAGE_KEY] = token;
                        this.log('PushService.onTokenRefresh(): token:\n' + JSON.stringify(token, null, '  '));
                        this.persistDeviceToken(token);
                    }
                );
            })
    }

    persistDeviceToken(token) {
        this.log('PushService.persistDeviceToken(): token:\n' + JSON.stringify(token, null, '  '));
        localStorage[PUSH_TOKEN_STORAGE_KEY] = token;

        if (token !== localStorage[PUSH_LATEST_REGISTERED_TOKEN_KEY]) {
            this.onDeviceTokenChanged(token);
        }
    }

    onDeviceTokenChanged(token) {
        this.log('PushService.onDeviceTokenChanged(): token:\n' + JSON.stringify(token, null, '  '));
        this.registerTokenOnServer(token)
            .then(data => {
                this.log('PushService.registerTokenOnServer() resolve: data:\n' + JSON.stringify(data, null, '  '));

                if (data) {
                    localStorage[PUSH_LATEST_REGISTERED_TOKEN_KEY] = token;
                }
            })
            .catch(e => {
                this.log('PushService.registerTokenOnServer() reject: error:\n' + JSON.stringify(e, null, '  '));
            })
    }

    unregister() {
        if (this.ready) {
            this.log('PushService.onMessageReceived(noop)');
            this.FirebaseAPI.onMessageReceived(_.noop);

            this.log('PushService.onTokenRefresh(noop)');
            this.FirebaseAPI.onTokenRefresh(_.noop);

            this.log('PushService.unregister()');
            this.FirebaseAPI.unregister();

            this.log('PushService.clearAllNotifications()');
            this.FirebaseAPI.clearAllNotifications();

            delete localStorage[PUSH_LATEST_REGISTERED_TOKEN_KEY];
            delete localStorage[PUSH_LOG_KEY];

            this.ready = false;
            this.hasNotificationSubscription = false;
            this.initializePromise = null;
        }
    }

    log(string) {
        const logString = `${new Date}\n${string}`;
        localStorage[PUSH_LOG_KEY] = `${logString}\n\n${localStorage[PUSH_LOG_KEY] || ''}`;
        $(document).trigger('event_push_log', logString);
        $(document).trigger('event_push_log_update');
    }
}

const service = new PushService();
window.__PushService = service;

export default service;
