import { Optional, ItemView, ViewModel } from '@b2cmessenger/backbone';
import Modal from 'windows/Modal/Modal';
import FooterView from 'widgets/Footer/Footer';
import template from './PhoneEditor.jade';
import './PhoneEditor.scss';
import PhoneModel from 'models/PhoneModel';
import { intlTelInputDisabledHandler, IntlTelInputHandler } from 'widgets/lite/intlTelInput/intlTelInput';
import getUserCountryCode from 'utils/getUserCountryCode';

const PhoneEditorModal = Modal.extend({
    options: {
        collection: Optional,
        parentViewModel: Optional
    },

    windowName: "phone-editor-modal-window",
    className: "modal-window phone-editor-modal-window green-border",

    initialize() {
        this.viewModel = new ViewModel({
            parentViewModel: this.options.parentViewModel || null,
            vm_phoneError: '',
            vm_phone: null,
            vm_canContinue: false
        });

        this.viewModel.addComputed('vm_canContinue', {
            deps: ['vm_phone', 'disabled'],
            get: (vm_phone, disabled) => !!(vm_phone && !disabled)
        });

        return Modal.prototype.initialize.apply(this, arguments);
    },

    onRender() {
        const contentView = new PhoneEditorContent({
            viewModel: this.viewModel
        });

        this.listenTo(contentView, 'continue', () => { 
            if (this.viewModel.get('vm_canContinue')) {
                this._onContinue();
            }
        });

        const footerView = new FooterView({
            buttons: [{
                id: "close",
                text: "CANCEL",
                icn: "empty"
            }, {
                id: "continue",
                text: "CONTINUE",
                icn: "empty"
            }]
        });

        footerView.listenTo(this.viewModel, 'change:vm_canContinue', () => {
            footerView.ui.btncontinue.prop('disabled', !this.viewModel.get('vm_canContinue'));
        });

        this.listenTo(footerView, 'close:click', view => this.close(false));
        this.listenTo(footerView, 'continue:click', view => this._onContinue());

        this.content.show(contentView);
        this.footer.show(footerView);

        footerView.ui.btncontinue.prop('disabled', !this.viewModel.get('vm_canContinue'));
    },

    cancel() {
        this.close(false);
    },

    _onContinue() {
        const phone = this.viewModel.get('vm_phone');

        if (this.collection && this.collection.findWhere({ phone })) {
            this.close(this.collection.findWhere({ phone }));
        } else {
            const model = new PhoneModel({ phone });

            this.viewModel.set({ disabled: true });

            model.save(null, {
                success: () => {
                    if (this.collection) {
                        this.collection.add(model);
                    }

                    this.close(model);
                },
                error: (m, resp) => {
                    if (resp.status === 422 && m.validationError.phone) {
                        this.viewModel.set('vm_phoneError', m.validationError.phone);
                    } else {
                        this.showError(resp);
                    }

                    this.viewModel.set({ disabled: false });
                }
            });
        }

    }
});

export default PhoneEditorModal;

@ItemView.options({
    collection: Optional,
})
@ItemView.properties({
    template: template,
    tagName: 'form',
    className: "widget phone-editor-modal-content-widget",

    ui: {
        phone: '[data-js-phone]',
        phoneError: '[data-js-phone-error]'
    },

    computeds: {
        c_Phone: {
            deps: ['vm_phone'],
            get: phone => phone && `+${phone}` || "",
            set(value) {
                this.viewModel.set('vm_phone', B2Cjs.phone_getInternalFormat(value) || null);
                this.viewModel.set('vm_phoneError', '');
            }
        }
    },

    bindings: {
        '@ui.phone': 'intlTelInput:c_Phone, intlTelInputDisabled:disabled, attr:{readonly:disabled},events:["change","input"]',
        '@ui.phoneError': 'text:vm_phoneError'
    },

    bindingHandlers() {
        return {
            intlTelInput: new IntlTelInputHandler({
                preferredCountries() {
                    return getUserCountryCode()
                        .then(cc => cc && [cc] || []);
                }
            }),
            intlTelInputDisabled: intlTelInputDisabledHandler
        };
    },

    events: {
        'submit'(e) {
            this.trigger('continue');
            e.preventDefault();
        }
    }
})
class PhoneEditorContent extends ItemView {
    onShow() {
        _.defer(() => {
            this.ui.phone.focus();
        });
    }
}
