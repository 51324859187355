import React from 'react';
import { useScrollState } from 'scrollmonitor-hooks';

export default function withScrollWatcher(WrappedComponent, offsets) {
    return function (props) {
        const { onInViewportChange = _.noop, onFullyInViewportChange = _.noop } = props;

        const ref = React.useRef(null);
        const scrollState = useScrollState(ref, offsets);

        React.useEffect(() => {
            onInViewportChange(scrollState.isInViewport, scrollState);
        }, [onInViewportChange, scrollState.isInViewport]);

        React.useEffect(() => {
            onFullyInViewportChange(scrollState.isFullyInViewport, scrollState);
        }, [onFullyInViewportChange, scrollState.isFullyInViewport]);

        return <WrappedComponent forwardedRef={ref} {...props} />;
    };
}
