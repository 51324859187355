import React from 'react';

import './MenuItemDetail.scss';

import MenuItemParam from 'components/menu/MenuItemDetail/MenuItemParam';
import MenuItemProperty from 'components/menu/MenuItemDetail/MenuItemProperty';
import Price from 'components/UI/Price/Price';
import MenuCategories from 'components/menu/MenuCategories/MenuCategories';
import MenuItemMarker from 'components/menu/MenuItemDetail/MenuItemMarker';
import MenuItemQuantityControl from 'components/menu/MenuItemQuantityControl/MenuItemQuantityControl';

export const MenuItemMarkersList = ({ markers }) => {
    return (
        <div className="markers">
            {markers.map((marker) => {
                return <MenuItemMarker key={marker.key} type={marker.key} />;
            })}
        </div>
    );
};

export const MenuItemParamsList = ({ params }) => {
    return (
        <div className="params">
            {params.map((param) => {
                if (_.isNull(param.value) || param.value === '') {
                    return null;
                }
                return <MenuItemParam key={param.key} {...param} id={param.key} />;
            })}
        </div>
    );
};

export const MenuItemIngredients = ({ ingredients }) => {
    return ingredients && ingredients.length > 0 ? (
        <div className="ingredients">
            <p className="title">Ingredients</p>
            <p className="value">{ingredients.join(', ')}</p>
        </div>
    ) : null;
};

export const MenuItemAdditionsList = ({ additions, displayAdditionCategory }) => {
    return additions && additions.length > 0 ? (
        <div className="additions">
            <p className="title">Additions</p>
            <MenuCategories categories={additions} onSelectCategory={displayAdditionCategory} />
        </div>
    ) : null;
};

export const MenuItemProperties = ({ properties }) => {
    return properties && properties.length > 0 ? (
        <div className="properties">
            <p className="title">Properties: </p>
            {properties.map((prop, i) => (
                <MenuItemProperty key={i} {...prop} />
            ))}
        </div>
    ) : null;
};

const MenuItemDetail = (props) => {
    const {
        name,
        description,
        photo,
        ingredients,
        is_food,
        additions,
        displayAdditionCategory,
        markers,
        params,
        price,
        currency,
        properties,
    } = props;

    const { onAddToCart, onRemoveFromCart, count } = props;

    let infoEl = null;
    if (price || is_food) {
        infoEl = (
            <div className="info">
                {Boolean(price) && <Price value={price} currency={currency} />}
                {is_food && <MenuItemMarkersList markers={markers} />}
            </div>
        );
    }

    return (
        <div className="menu-item-detail">
            <h3 className="name">{name}</h3>
            {photo && <img src={photo} alt={`${name} image`} />}
            <MenuItemQuantityControl
                count={count}
                onPlusClickHandler={onAddToCart}
                displayTextButton
                buttonText="Add to cart"
                onMinusClickHandler={onRemoveFromCart}
            />
            {infoEl}
            <p className="description">{description}</p>
            <MenuItemIngredients ingredients={ingredients} />
            <MenuItemAdditionsList additions={additions} displayAdditionCategory={displayAdditionCategory} />
            <MenuItemProperties properties={properties} />
            {is_food && <MenuItemParamsList params={params} />}
        </div>
    );
};

export default MenuItemDetail;
