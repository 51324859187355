import React, { useCallback, useMemo, useState, useEffect } from 'react';
import ReactPage from 'pages/ReactPage';
import { connect } from 'react-redux';
import './MenuTables.scss';

import Page, { PageContent, PageHeader } from 'components/page/Page';
import { BackButton, MenuButton } from 'components/UI/Button/Button';
import withStore from 'components/withStore';
import Spinner from 'components/UI/Spinner/Spinner';
import { getMenuError, getMenuInfoLoading } from 'store/menu/selectors';
import MenuItemError from 'components/menu/MenuItemDetail/MenuItemError';
import { getMenuSectionsAsArray, getMenuTablesAsArray } from 'store/menu/sections/selectors';
import { MenuListEmptyItem } from 'components/menu/MenuList/MenuList';
import { loadOrders } from 'utils/api/menu';
import { showError } from 'windows/Modal/Info';
import HeaderWithTabs from 'components/UI/Header/HeaderWithTabs';
import MenuPlaceTables from 'components/menu/MenuPlaceOrder/MenuPlaceTables';
import { OrderStatus } from 'components/menu/MenuPlaceOrder/MenuPlaceOrder';
import InputGroup, { RadioInput } from 'components/UI/InputGroup/InputGroup';
import Tabs, { Tab } from 'components/UI/Tabs/Tabs';
import useInitializeMenu from 'hooks/useInitializeMenu';

const HeaderTabs = [
    { id: 'all', title: 'All' },
    { id: 'my', title: 'My' },
];
const ContentTabs = [
    { id: '', title: 'All', status: [OrderStatus.Created, OrderStatus.Confirmed, OrderStatus.Done] },
    { id: 'confirmation', title: 'Waiting for confirm', status: [OrderStatus.Created] },
    { id: 'confirmed', title: 'Confirmed', status: [OrderStatus.Confirmed] },
    { id: 'completed', title: 'Completed', status: [OrderStatus.Done] },
    { id: 'canceled', title: 'Canceled', status: [OrderStatus.Canceled] },
];
const MenuTablesContent = (props) => {
    const { placeId, error, loading: initialization, isActive } = props;
    const { shouldInitMenu, readyToInitialize } = useInitializeMenu({ placeId, isActive });

    const [orders, setOrders] = useState([]);
    const [activeTab, setActiveTab] = useState(HeaderTabs[0].id);
    const [activeContentTab, setActiveContentTab] = useState(ContentTabs[0].id);

    const [loading, setLoading] = useState(false);
    const [sectionId, setSectionId] = useState(null);

    const tables = useMemo(() => {
        return _.chain(props.tables)
            .filter((t) => (sectionId !== null ? t.sectionId === sectionId : true))
            .map((table) => {
                const { id, number } = table;
                const idx = _.random(0, orders.length - 1);
                return {
                    id,
                    number,
                    // orders: orders.filter(o => Number(o.tableNumber) === Number(number))
                    orders: orders.slice(idx, idx + _.random(0, 3)),
                };
            })
            .sortBy((t) => Number(t.number))
            .value();
    }, [props.tables, sectionId, orders]);

    const loadOrdersList = useCallback(async () => {
        setLoading(true);

        try {
            const { status } = ContentTabs.find((t) => t.id === activeContentTab) || { status: [] };
            const { orders: ordersList } = await loadOrders({
                includeUser: true,
                status,
                page: 100,
                placeId: props.placeId,
            });

            setOrders(() => [...ordersList]);
        } catch (e) {
            setOrders([]);
            showError(e);
        } finally {
            setLoading(false);
        }
    }, [setOrders, props.placeId, activeTab, activeContentTab]);

    const pageTitle = useMemo(() => (activeTab === 'my' ? 'My Tables' : 'Tables'), [activeTab]);

    const contentReady = useMemo(() => {
        return error === null && !shouldInitMenu;
    }, [shouldInitMenu, error]);

    const sectionList = useMemo(() => {
        if (!props.sections) return [];

        return [{ name: 'All', id: null }, ...props.sections].map((s) => ({
            id: _.uniqueId(`section${s.id}`),
            label: s.name,
            value: s.id || '',
            checked: sectionId === s.id,
        }));
    }, [props.sections, sectionId]);

    const menuOrdersEmptyContent = useMemo(() => {
        return !loading && <MenuListEmptyItem message="No orders was found" />;
    }, [loading, activeTab]);

    const onSectionClick = useCallback(
        (e) => {
            const value = Number(e.currentTarget.value) || null;

            setSectionId((prevSectionId) => {
                return prevSectionId === value ? null : value;
            });
        },
        [setSectionId]
    );

    useEffect(() => {
        loadOrdersList();
    }, [activeContentTab, activeTab]);

    const onShow = useCallback(() => {
        if (readyToInitialize) {
            readyToInitialize(true);
        }
    }, [readyToInitialize]);

    return (
        <Page instance={props.__page__} onShow={onShow}>
            <PageHeader>
                <HeaderWithTabs tabs={HeaderTabs} activeTab={activeTab} setActiveTab={setActiveTab}>
                    <BackButton onClick={props.goBack} />
                    <h1 className="title">{pageTitle}</h1>
                    <MenuButton right />
                </HeaderWithTabs>
            </PageHeader>
            <PageContent className="scrollable grow with-standard-padding">
                {contentReady && (
                    <>
                        <Tabs className="status-tabs">
                            {ContentTabs.map(({ title, id }) => (
                                <Tab
                                    key={id}
                                    active={id === activeContentTab}
                                    id={id}
                                    title={title}
                                    onClick={setActiveContentTab}
                                />
                            ))}
                        </Tabs>
                        <InputGroup className="input-group no-background">
                            <div className="children">Section</div>
                            <div className="options">
                                <RadioInput id="section" options={sectionList} onClick={onSectionClick} />
                            </div>
                        </InputGroup>
                        <MenuPlaceTables items={tables} emptyContent={menuOrdersEmptyContent} onAddOrder={_.noop} />
                    </>
                )}
                {((!contentReady && !error) || loading || initialization) && <Spinner />}
                {error !== null && <MenuItemError errorText={error} displayRetryButton={false} />}
            </PageContent>
        </Page>
    );
};

const ConnectedMenuTablesContent = withStore(
    connect((state) => ({
        title: 'Tables',
        loading: getMenuInfoLoading(state),

        error: getMenuError(state),
        sections: getMenuSectionsAsArray(state),
        tables: getMenuTablesAsArray(state),
    }))(MenuTablesContent)
);

const MenuTablesPage = ReactPage.extend({
    attributes: {
        id: 'page_menu_tables',
    },
    className: 'menu-tables-page',
    component: ConnectedMenuTablesContent,
});

export default MenuTablesPage;
