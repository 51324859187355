import { Required, Optional } from '@b2cmessenger/backbone';

import ModalWindow from 'windows/Modal/Modal';
import MessageSolutionRatingsView from './MessageSolutionRatings';

/**@type {typeof import('./MessageSolutionRatingsModal').options} */
// @ts-ignore
const options = ModalWindow.options;

@options({
    model: Required,
    loginedUserModel: Required,
    parentViewModel: Optional,
})
class MessageSolutionRatingsModalWindow extends ModalWindow {
    /**@param {import('./MessageSolutionRatingsModal').Options} options*/
    constructor(options) {
        super({
            parentViewModel: options.parentViewModel,
            content: new MessageSolutionRatingsView({
                model: options.model,
                loginedUserModel: options.loginedUserModel
            }),
            intent: ModalWindow.Intent.Active
        });
    }
}

export default MessageSolutionRatingsModalWindow;
