import { Required, Collection, CollectionView, ItemView } from '@b2cmessenger/backbone';
import Modal from 'windows/Modal/Modal';

import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils';

import 'intl-tel-input/build/css/intlTelInput.min.css';
import './intlTelInput.scss';

export class IntlTelInputHandler {
    constructor(options) {
        this.iti = null;
        this.options = _.defaults(options, {
            allowDropdown: true,
            nationalMode: true,
            autoPlaceholder: 'aggressive',
            separateDialCode: true
        });

        Object.assign(this, {
            init: IntlTelInputHandler.prototype.init,
            get: IntlTelInputHandler.prototype.get,
            set: IntlTelInputHandler.prototype.set
        });
    }

    init($element) {
        let dropdownWindow = null;

        const input = $element.get(0);
        const countryCollection = new Collection();

        if (_.isFunction(this.options.preferredCountries)) {
            const countries = this.options.preferredCountries.call();

            if (countries instanceof Promise) {
                countries
                    .then(result => {
                        this.options.preferredCountries = (result || []).slice();
                        $element.trigger('reinit', this.options);
                    })
                    .catch(() => {
                        delete this.options.preferredCountries;
                        $element.trigger('reinit', this.options);
                    });
            } else {
                this.options.preferredCountries = countries;
            }
        } else if (_.isArray(this.options.preferredCountries) && !this.options.preferredCountries.length) {
            delete this.options.preferredCountries;
        }

        this.iti = intlTelInput(input, this.options);

        countryCollection.add(this.iti.preferredCountries);

        if (countryCollection.length) {
            countryCollection.add({ divider: true });
        }

        countryCollection.add(this.iti.countries);

        const label = this.iti._getClosestLabel();
        label && label.removeEventListener('click', this.iti._handleLabelClick);

        Object.assign(this.iti, {
            _showDropdown() {
                this.selectedFlag.blur();

                _.defer(() => {
                    if ($(input).is(':focus')) {
                       input.blur();
                    }
                });

                dropdownWindow = new SelectCountryModal({
                    content: new SelectCountriesCollectionView({
                       collection: countryCollection,
                       activeCountryCode: this.selectedCountryData.iso2
                    }),
                });

                dropdownWindow.show()
                    .then(selectedCountry => {
                        if (selectedCountry instanceof HTMLElement) {
                            this._selectListItem(selectedCountry);

                            this.telInput.dispatchEvent(new Event('change', {
                                bubbles: true,
                                cancelable: true,
                            }));
                        }

                        this._closeDropdown();
                        dropdownWindow = null;
                    });
           }
        });

        $element.off('reinit').on('reinit', options => {
            if (dropdownWindow) {
                dropdownWindow.close();
            }

            _.extend(this.options, {}, options);

            let value = null;

            if (this.iti) {
                value = this.iti.getNumber();
                this.iti.destroy();
            }

            if (value !== null) {
                $element.val(value);
            }

            this.init($element);
        });
    }

    set($element, number) {
        this.iti.setNumber(number || '');
    }

    get() {
        return this.iti.getNumber();
    }
}

export const intlTelInputDisabledHandler = {
    set($el, value) {
        const disabled = $el.prop('disabled');

        if (disabled !== value) {
            $el.prop('disabled', value);
            $el.trigger('reinit', { allowDropdown: !value });
        }

        const container = $el.closest('.iti');
        container.toggleClass('iti--disabled', value);
    }
};

const SelectCountryModal = Modal.extend({
    options: {
        countries: Required
    },

    className: 'modal-window select-country-modal-window green-border',
    windowName: 'select-country-modal-window',

    onShow() {
        if (this.content && this.content.currentView) {
            this.listenToOnce(this.content.currentView, 'childview:click', cv => {
                this.close(cv && cv.el);
            })
        }

        _.defer(() => this.$el.focus());

        return Modal.prototype.onShow.apply(this, arguments);
    }
});

const SelectCountryItemView = ItemView.extend({
    tagName: 'li',
    className: 'iti__country iti__standard',
    template: _.template(`
        <div class='iti__flag-box'><div class='iti__flag iti__<%= iso2 %>'></div></div>
        <span class='iti__country-name'><%= name %></span>
        <span class='iti__dial-code'>+<%= dialCode %></span>`.trim()
    ),
    attributes() {
        return {
            id: `iti-item-${this.model.get('iso2')}`,
            'data-country-code': this.model.get('iso2'),
            'data-dial-code': this.model.get('dialCode')
        }
    },
    triggers: {
        click: 'click'
    },
    onRender() {
        if (this.options.active) {
            this.$el.addClass('active');
        }
    }
});

const SelectCountryDividerView = ItemView.extend({
    tagName: 'li',
    className: 'iti__divider',
    template: _.template(''),
    attributes: {
        role: 'separator',
        'aria-disabled': 'true'
    }
});

const SelectCountriesCollectionView = CollectionView.extend({
    tagName: 'ul',
    className: "select-countries-collection-widget widget",
    getChildView(child) {
         if (child.get('preferred')) {
             return SelectCountryItemView.extend({
                 className: 'iti__country iti__preferred'
             })
         } else if (child.get('divider')) {
             return SelectCountryDividerView;
         }

         return SelectCountryItemView;
    },
    childViewOptions(model) {
        if (this._compareCountryCodes(this.options.activeCountryCode, model.get('iso2'))) {
            return { active: true };
        }

        return {};
    },
    onAttach() {
        const foundChild = this.children.find(c => this._compareCountryCodes(this.options.activeCountryCode, c.model.get('iso2')));

        if (foundChild) {
            foundChild.el.scrollIntoCenter();
        }
    },
    _compareCountryCodes(a, b) {
        return a && b && a.toLowerCase() === b.toLowerCase();
    }
});
