import { CollectionView, ItemView } from '@b2cmessenger/backbone';
import NotificationWidget from './Notification';
import './NotificationCollection.scss';

const NotificationCollectionView = CollectionView.extend({
    options: {
        page: undefined
    },
    childView: NotificationWidget,
    className: "notification-collection-widget widget",
    reorderOnSort: true,

    viewComparator: (l, r) => l.get('isRead') - r.get('isRead') || r.id - l.id,

    filter: m => !m.get('hidden'),

    emptyView: ItemView.extend({
        className: "notification-collection-empty-widget widget",
        template: () => '<p>Cool!</p><p>You have read all notifications!</p>'
    }),

    childViewOptions() {
        return {
            page: this.options.page
        };
    },

    initialize() {
        this.listenTo(this.collection, 'change:isRead change:hidden', _.debounce(() => this.resortView(), 0));
    }
});

export default NotificationCollectionView;