import React, { useCallback } from 'react';

import './TagsInput.scss';
import { Button } from 'components/UI/Button/Button';

const TagsInput = ({ tags = [], setTags, placeholder = 'Input tags', disabled, addHashPrefix = true }) => {
    const addTag = (event) => {
        if (event.target.value !== '') {
            if (!tags.find((t) => t === event.target.value)) {
                setTags([...tags, event.target.value]);
            }
            event.target.value = '';
        }
    };
    const removeTag = (removeTag) => {
        setTags([...tags.filter((tag) => tag !== removeTag)]);
    };

    const removeLastTag = useCallback(
        _.debounce(() => {
            setTags(tags.slice(0, -1));
        }, 100),
        [tags]
    );

    const tagsContent = (
        <span className="b2ctags_cont">
            {tags.map((tag) => (
                <span key={tag} className={`b2ctag ${addHashPrefix ? 'with-hash' : ''}`}>
                    <span className="name">{tag}</span>
                    <span className="del" onClick={() => removeTag(tag)} />
                </span>
            ))}
        </span>
    );

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            addTag(e);
        }
        if ((e.key === 'Delete' || e.key === 'Backspace') && !e.currentTarget.value.length) {
            removeLastTag();
        }
    };

    return (
        <div className="input-group tags-widget">
            {tagsContent}
            <div className="tags-input-group">
                <input
                    type="text"
                    placeholder={placeholder}
                    disabled={disabled}
                    onBlur={(event) => addTag(event)}
                    onKeyDown={onKeyDown}
                />
                <Button icon="ion-ios-add-circle-outline" />
            </div>
        </div>
    );
};

export default TagsInput;
