import React from 'react';
import PropTypes from 'prop-types';

import MenuList, { MenuListItem, MenuListType } from 'components/menu/MenuList/MenuList';
import { getItemListItemClassName } from 'components/menu/MenuItem/MenuItem';
import './MenuOrders.scss';
import { OrderStatusClassName, OrderStatusMap } from 'components/menu/MenuOrderStatus/MenuOrderStatus';
import withScrollWatcher from 'components/withScrollWatcher';
import MenuTextItems, { getTextItemsFromOrderFormattedItems } from 'components/menu/MenuItems/MenuTextItems';
import { MenuOrderBase, MenuOrderComment, MenuUserOrderActions } from 'components/menu/MenuOrder/MenuOrder';

const MenuListItemWithScrollWatcher = withScrollWatcher(MenuListItem);
const MenuOrders = (props) => {
    const {
        orders,
        onSelectItem = _.noop,
        displayPosition = false,
        displayPrice = true,
        displayOrderActions = true,
        displayOrderComment = false,
    } = props;

    const orderList = _(orders).map((order, idx) => {
        const { status = null } = order;
        const className = getItemListItemClassName(
            props,
            props.className,
            'menu-list-item',
            OrderStatusClassName[status]
        );

        const textItems = getTextItemsFromOrderFormattedItems(order, {
            includePosition: displayPosition,
            includePrice: displayPrice,
        });
        const onInViewportChange = orders.length - 1 === idx ? props.onLastItemInViewportChange : _.noop;
        const isOrderActive = [OrderStatusMap.New, OrderStatusMap.Confirmed].findIndex((i) => i === order.status) > -1;
        const showCancelButton = isOrderActive;
        const showCallWaiterButton = isOrderActive;
        const showEditButton = OrderStatusMap.New === order.status;
        const comment = order.data && order.data.comment;

        return (
            <MenuListItemWithScrollWatcher
                key={order.id}
                onInViewportChange={onInViewportChange}
                className={className}
                onClick={() => onSelectItem(order)}
            >
                <MenuOrderBase order={order}>
                    <MenuTextItems items={textItems} limit={3} />
                    {displayOrderComment && <MenuOrderComment comment={comment} />}
                    {displayOrderActions && (
                        <MenuUserOrderActions
                            showCancelButton={showCancelButton}
                            showEditButton={showEditButton}
                            onEditOrder={() => props.onEditOrder(order)}
                            onCancelOrder={() => props.onCancelOrder(order)}
                            showCallWaiterButton={showCallWaiterButton}
                            onCallWaiterClick={() => onSelectItem(order, { forceOpenWaiterCallForm: true })}
                        />
                    )}
                </MenuOrderBase>
            </MenuListItemWithScrollWatcher>
        );
    });

    return (
        <MenuList {...props} type={MenuListType.Orders}>
            {orderList}
        </MenuList>
    );
};
MenuOrders.propTypes = {
    orders: PropTypes.array,
    onSelectItem: PropTypes.func,
    onCancelOrder: PropTypes.func,
    showCancelButton: PropTypes.bool,
    displayPrice: PropTypes.bool,
    displayOrderActions: PropTypes.bool,
    displayOrderComment: PropTypes.bool,
};

export default MenuOrders;
