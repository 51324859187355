import { Model } from '@b2cmessenger/backbone';
import AjaxError from 'utils/AjaxError';

const Type = {
    NewTask: 'newTask',
    NewComment: 'newComment',
    TaskSolution: 'taskSolution',
    EmployeeInvite: 'employeeInvite',
    EmployeePermissionsChange: 'employeePermissionsChange',
    NewGift: 'newGift',
    NewTaskSolutionRating: 'newTaskSolutionRating',
    NewMenuOrder: 'menuOrderCreated',
    MenuOrderUpdate: 'menuOrderUpdated',
    Other: '',
    MenuOrderCreated: 'menuOrderCreated',
    MenuOrderUpdated: 'menuOrderUpdated',
    MenuWaiterCall: 'menuWaiterCall',
};

export const MenuOrderNotificationTemplates = {
    NoWorkingWaiters: 'no_working_waiters',
    OrderCreatedForYou: 'order_created_for_you',
    OrderAssignedForYou: 'order_assigned_to_you',
    OrderUpdatedClientNotify: 'order_updated_client_notify',
    OrderUpdatedWaiterNotify: 'order_updated_waiter_notify',
    OrderWaiterNotify: 'waiter_notify',
};

export const isOrderNoticeForEmployee = (templateType) => {
    switch (templateType) {
        case MenuOrderNotificationTemplates.NoWorkingWaiters:
        case MenuOrderNotificationTemplates.OrderAssignedForYou:
        case MenuOrderNotificationTemplates.OrderUpdatedWaiterNotify:
        case MenuOrderNotificationTemplates.OrderWaiterNotify:
            return true;
        default:
            return false;
    }
};

const Status = {
    New: 0,
    Read: 3,
};

const Category = {
    Common: 'common',
    Workplace: 'workplace',
    Reservation: 'reservation',
    PlaceReservation: 'placeReservation',
    Gift: 'gift',
    Task: 'task',
    MenuOrder: 'menuOrder',
    MenuWaiterNotify: 'menuWaiterNotify',
};

export const getNotificationCategoryName = (app_event, template_type) => {
    switch (app_event) {
        case Type.NewTask:
            switch (template_type) {
                case 'personal_message_recipient':
                    return Category.Common;
                case 'personal_message_employee':
                case 'place_employee':
                    return Category.Workplace;
                case 'reservation_created':
                    return Category.PlaceReservation;
                default:
                    return Category.Task;
            }
        case Type.NewComment:
            switch (template_type) {
                case 'reservation_author_notify':
                    return Category.Reservation;
                case 'reservation_business_notify':
                    return Category.PlaceReservation;
                default:
                    return Category.Task;
            }

        case Type.TaskSolution:
            return Category.Task;

        case Type.NewTaskSolutionRating:
            return Category.Workplace;

        case Type.NewGift:
            return Category.Gift;

        case Type.MenuOrderCreated:
        case Type.MenuOrderUpdated:
            return Category.MenuOrder;

        case Type.MenuWaiterCall:
            return Category.MenuWaiterNotify;

        default:
            return Category.Common;
    }
};

const NotificationModel = Model.extend({
    markAsRead(options) {
        _.defaults(options || (options = {}), {
            wait: false,
            canBeNotReadable: true
        });

        if (this.get('canBeRead')) {
            return new Promise((resolve, reject) => {
                this.save({ status: NotificationModel.Status.Read }, {
                    wait: !!options.wait,
                    success: () => resolve(),
                    error: (m, resp) => reject(resp instanceof Error ? resp : new AjaxError(resp))
                });
            })
        } else {
            if (!options.canBeNotReadable) {
                return Promise.reject(new Error('cannot be read'));
            } else {
                return Promise.resolve();
            }
        }
    },

    defaults: {
        app_event: Type.Other,
        status: Status.New,
        title: "",
        message: "",
        created_at: ""
    },

    computeds: {
        isNew: {
            deps: ['status'],
            get: status => status == Status.New,
            set: val => ({ status: val ? Status.New : Status.Read })
        },
        isRead: {
            deps: ['status'],
            get: status => status != Status.New,
        },
        createdAt: {
            deps: ['created_at'],
            get: created_at => B2Cjs.datetimeServerToJS(created_at),
        },
        canBeRead: {
            deps: ['status', 'app_event'],
            get: (status, app_event) => status != Status.Read && app_event != Type.EmployeeInvite
        },
        isNewComment: {
            deps: ['app_event'],
            get: app_event => app_event == Type.NewComment || app_event == Type.TaskSolution
        },
        isTaskSolution: {
            deps: ['app_event'],
            get: app_event => app_event == Type.TaskSolution
        },
        isNewGift: {
            deps: ['app_event'],
            get: app_event => app_event == Type.NewGift
        },
        isNewTaskSolutionRating: {
            deps: ['app_event'],
            get: app_event => app_event == Type.NewTaskSolutionRating
        },
        isNewTask: {
            deps: ['app_event'],
            get: app_event => app_event == Type.NewTask
        },
        isNewMenuOrder: {
            deps: ['app_event'],
            get: app_event => app_event == Type.NewMenuOrder
        },
        isMenuOrderUpdate: {
            deps: ['app_event'],
            get: app_event => app_event == Type.MenuOrderUpdate
        },
        isFetchNeeded: {
            deps: ['gift_template_id', 'gift_template', 'gift_writedown'],
            get: (gift_template_id, gift_template, gift_writedown) => gift_template_id && !gift_writedown && !gift_template
        },
        isNewAndVisible: {
            deps: ['isNew', 'hidden'],
            get: (isNew, hidden) => isNew && !hidden
        },
        hidden: {
            deps: ['template_type'],
            get: (template_type) => 'silent' === template_type
        },
        categoryName: {
            deps: ['app_event', 'template_type'],
            get: (app_event, template_type) => getNotificationCategoryName(app_event, template_type)
        }
    },

    initialize(attrs) {
        this.on('change:data', this._onChangeData);

        this._onChangeData(this, this.get('data'));

        return Model.prototype.initialize.call(this, arguments);
    },

    destroy(options) {
        options = _.defaults(options || {}, { saveComputedValuesAsAttributes: true });
        return Model.prototype.destroy.call(this, options)
    },

    parse(attrs, options) {
        const data = attrs.data;
        if (data && _.isObject(data) && _.size(data)) {
            if (data.brand_id == 0) data.brand_id = null;
            if (data.comment_id == 0) data.comment_id = null;
            data.commentscount_u = Number(data.commentscount_u) || null;
            data.commentscount_v = Number(data.commentscount_v) || null;
            if (data.created_by == 0) data.created_by = null;
            if (data.gift_brand_id == 0) data.gift_brand_id = null;
            if (data.parent_id == 0) data.parent_id = null;
            if (data.place_id == 0) data.place_id = null;
            if (data.task_id == 0) data.task_id = null;
            if (data.template_id == 0) data.template_id = null;
        }
        return attrs;
    },

    _onChangeData(model, value, options) {
        const prevData = this.previous('data'),
            data = value;

        if (prevData) {
            Object.defineProperties(prevData, {
                gift_brand_id: {
                    value: prevData.brand_id,
                    configurable: true,
                    writable: true,
                    enumerable: true
                },
                template_id: {
                    value: prevData.template_id,
                    configurable: true,
                    writable: true,
                    enumerable: true
                },
                gift_template: {
                    value: prevData.gift_template,
                    configurable: true,
                    writable: true,
                    enumerable: true
                },
                writedown: {
                    value: prevData.writedown,
                    configurable: true,
                    writable: true,
                    enumerable: true
                }
            });
        }

        this.set({
            gift_brand_id: data && data.brand_id || null,
            gift_template_id: data && data.template_id || null,
            gift_template: data && data.gift_template || null,
            gift_writedown: data && data.writedown
        });

        if (data) {
            Object.defineProperties(data, {
                brand_id: {
                    get: () => this.get('gift_brand_id'),
                    set: value => this.set('gift_brand_id', value),
                    configurable: true,
                    enumerable: true
                },
                template_id: {
                    get: () => this.get('gift_template_id'),
                    set: value => this.set('gift_template_id', value),
                    configurable: true,
                    enumerable: true
                },
                gift_template: {
                    get: () => this.get('gift_template'),
                    set: value => this.set('gift_template', value),
                    configurable: true,
                    enumerable: true
                },
                writedown: {
                    get: () => this.get('gift_writedown'),
                    set: value => this.set('gift_writedown', value),
                    configurable: true,
                    enumerable: true
                }
            });
        }
    },

}, {
    Type,
    Status,
    Category
});

export default NotificationModel;
