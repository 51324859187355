import PlatformFeature from './PlatformFeature';

class FileOpener extends PlatformFeature {
    async initialize() {
///////////////////////
//////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
///////////
/////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////

/////////////////
        this.initializePromise = Promise.reject(FileOpener.UnSupported);
//////////////////

        return this.initializePromise.then((apiSource) => {
            this.ready = true;
            this.apiSource = apiSource;
        });
    }

    async open(options) {
        _.defaults(options || (options = {
            path: null,
            mimeType: null,
            displayDialog: false,
        }));

        return this.ensureInitialized()
            .then(() =>
                new Promise((resolve, reject) => {
                    if (options.path === null) {
                        reject('Invalid `path` value in `FileOpener:open()`: null');
                    } else if (options.mimeType === null) {
                        reject('Invalid `mimeType` value in `FileOpener:open()`: null');
                    } else {
                        const fn = this.apiSource[options.displayDialog ? 'showOpenWithDialog': 'open'];
                        fn.call(this.apiSource, options.path, options.mimeType, { success: resolve, error: reject });
                    }
                })
            );

    }

    async showOpenWithDialog(options) {
        return this.open({ ...options, displayDialog: true });
    }
}

export default new FileOpener;
