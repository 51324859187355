import PlaceCollection from 'models/PlaceCollection';
import B2CPlace from 'widgets/B2CPlace';
import { CollectionView, ItemView, LayoutView, Model } from '@b2cmessenger/backbone';
import HeaderView from 'widgets/Header/Header';
import LeftMenuWindow from 'windows/LeftMenu/LeftMenu';
import FilterPanelView from 'widgets/FilterPanel/FilterPanel';
import SearchPanelView from 'widgets/SearchPanel/SearchPanel';
import PlaceEditorWindow from 'windows/PlaceEditor/PlaceEditor';
import MapWidget from 'widgets/Map/Map';
import Page from 'pages/Page';
import AjaxError from 'utils/AjaxError';
import './PlaceSearchCore.scss';
import template from './PlaceSearch.jade';
import footerTemplate from "./Footer.jade";
import addNewPlaceItemTemplate from "./AddNewPlaceItem.jade";
import PlaceItemWidget from 'widgets/PlaceItem/PlaceItem';
import GoogleAnalytics from "utils/GoogleAnalytics";

const maxPlaces = 200, maxMarkers = 50;

let FooterView = LayoutView.extend({
    template: footerTemplate,

    tagName: "div",
    className: "container",

    ui: {
        search: '[data-js-panel-search]',
        filter: '[data-js-panel-filter]'
    },

    initialize() {
        this.listenTo(this, 'mode:search', this.onModeSearch);
        this.listenTo(this, 'mode:filter', this.onModeFilter);
    },

    onModeSearch() {
        this.ui.filter.addClass('hidden');
        this.ui.search.removeClass('hidden');
    },

    onModeFilter() {
        this.ui.filter.removeClass('hidden');
        this.ui.search.addClass('hidden');
    }
});

let AddNewPlaceItemWidget = ItemView.extend({
    template: addNewPlaceItemTemplate,

    tagName: "li",
    className: "add-place",

    triggers: {
        'click': 'click'
    }
});

let PlaceCollectionView = CollectionView.extend({
    tagName: 'ul',
    className: "places_ls place-collection",
    childView: PlaceItemWidget,
    childViewOptions(model, index) {
        return {
            mapModel: this.options.mapModel
        };
    },

    filter(child, index, collection) {
        return index < maxPlaces && !child.get('_fromMap');
    },

    options: {
        reorderOnSort: true
    },

    initialize() {
        this.addNewPlaceItemWidget = new AddNewPlaceItemWidget();
        this.addNewPlaceItemWidget.on('click', () => {
            this.trigger('addnew:click');
        })
    },

    onRender() {
        this.addNewPlaceItemWidget.render();
        this.el.appendChild(this.addNewPlaceItemWidget.el);
    },

    onAddChild() {
        this.el.appendChild(this.addNewPlaceItemWidget.el);
    },

    onReorder() {
        this.el.appendChild(this.addNewPlaceItemWidget.el);
    }
});

const protoProps = {
    template: template,

    regions: {
        header: '[data-js-header]',
        searchPanel: '[data-js-search-panel]',
        results: '[data-js-results]',
        map: '[data-js-map]',
        filterPanel: '[data-js-filter]',
        footer: '[data-js-footer]'
    },

    ui: {
        content: '[data-js-content]',
        results: '[data-js-results]',
        map: '[data-js-map]',
        scrollingElem: '[data-js-results]',
        background: '> .background'
    },

    initialize() {
        this.places = new PlaceCollection();

        this.page_id = this.el.id;

        this.goo_places = {}; // {google_place_id : Object} Объекты мест, сгенерированный в результате обращения к Google Map Place Api
        this.goo_place_pagination = null; // Объект пагинации поиска мест через Google Map Place Api
        this.goo_place_id_to_b2c = {}; // Меппинг идентификаторов плейсов google в идентификаторв B2C Messenger
        this.nearUserMode = true;
        this.filter = {
            name: null,
            favorites: false,
            point_lat: null,
            point_long: null,
            corners: {
                nw: { lt: null, lg: null },
                se: { lt: null, lg: null },
            },
            maxdistance: null,
            minrating: '',
            anonym: null,
            unverified: null,
            categories: null,
            properties: null,
            open: null,
            sortby: B2CPlace.const.search.sortby.dis_asc,
            mode: B2CPlace.const.search.mode.get_places,
            limit: B2CPlace.const.search.load_limit,
            offset: 0
        };
        this.isFilterChanged = false; // маркер - изменен ли фильтр
        this.isLoading = false; // маркер - что идет загрузка
        this.is_google_loading = false; // маркер - что идет загрузка плейсов через Google Place Api
        this.isAllLoaded = false; // маркер - что все места соответсвующие текущему фильтру загружены
        this.is_google_all_loaded = false; // маркер - что все места соответсвующие текущему фильтру загружены через Google Place Api

        this.mapModel = new Model({
            show: false
        });

        let lt = geo.getCurrentPosition().lt,
            lg = geo.getCurrentPosition().lg;

        if (lt !== null && lg !== null) {
            this.mapModel.set({
                userLat: lt,
                userLng: lg
            });
        } else {
            this.mapModel.set({
                userLat: void 0,
                userLng: void 0
            });
        }

        this.listenTo(this.mapModel, 'change:show', (m, show) => {
            if (!this.filterPanel.$el.hasClass('hidden')) {
                this._toggleResultFilterView();
            }

            if (show) {
                this.map.$el.removeClass('hidden');
                this.ui.results.addClass('hidden');

                this.mapModel.set({
                    lat: this.filter.point_lat,
                    lng: this.filter.point_long,
                });

                if (this.mapBoundsHasChangedWhileMapWasHidden) {
                    this._onMapWithPlaces_bounds_changed();
                    this.mapBoundsHasChangedWhileMapWasHidden = false;
                }

                this.map.currentView.refresh(true);
            } else {
                this.map.$el.addClass('hidden');
                this.ui.results.removeClass('hidden');

                this.check_and_load_more();
                this.goo_check_and_load_more();
            }
        });

        this.searchId = _.uniqueId();
    },

    onDestroy() {
        this.ui.results.off('scrollstop');
    },

    onRender() {
        let headerView = new HeaderView({
            leftButtons: ['menu', 'back'],
            title: 'Company / Place search',
            rightButtons: [{
                id: 'flipswitch',
                model: this.mapModel,
                attrName: 'show',
                labelOn: '\uf1bc',
                labelOff: '\uf1c3',
            }, 'notifications', 'menu']
        })
        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.listenTo(headerView, 'menuleft:click', () => new LeftMenuWindow().show());
        this.listenTo(headerView, 'menuright:click', () => new LeftMenuWindow({ right: true }).show());
        this.listenTo(headerView, 'notifications:click', () => app.controller.showNotificationsWindow());
        this.header.show(headerView);

        let filterPanelView = new FilterPanelView();
        this.filterCont = filterPanelView.filterCont;
        this.filterPanel.show(filterPanelView);

        let searchPanelView = new SearchPanelView({ placeholder: 'Company / Place name' });
        this.listenTo(searchPanelView, 'text:change', text => this.filterCont.changeFilter('name', text));
        this.listenTo(searchPanelView, 'filter:click', this._toggleResultFilterView.bind(this));
        this.searchPanel.show(searchPanelView);

        let placeCollectionView = new PlaceCollectionView({ collection: this.places, mapModel: this.mapModel });
        this.listenTo(placeCollectionView, 'childview:select', view => this._onPlaceClickHandler.call(this, view.$el));
        this.listenTo(placeCollectionView, 'addnew:click', () => {
            this.stopCheckingLoadingOnScroll = true;
            new PlaceEditorWindow().show()
                .then(place => {
                    this.stopCheckingLoadingOnScroll = false;
                    if (place) {
                        app.controller.goToPlacePage({
                            place: place.attributes
                        });
                    }
                });
        });
        this.results.show(placeCollectionView);

        let mapView = new MapWidget({
            model: this.mapModel,
            collection: this.places,
            maxMarkers: maxMarkers
        });
        this.listenTo(mapView, 'map:bounds_changed', this._onMapWithPlaces_bounds_changed.bind(this));
        this.listenTo(mapView, 'childview:select', view => this._onPlaceClickHandler.call(this, view.$el));
        this.map.show(mapView);

        this.footer.show(new FooterView());

        this.$el.enhanceWithin();

        this.ui.results.on('scrollstop', event => {
            this.check_and_load_more();
            this.goo_check_and_load_more();
        });
    },

    show(options) {
        const ret = Page.prototype.show.apply(this, arguments);

        _.defaults(options || (options = {}), {
            selectPlaceOnce: false,
            favorites: undefined,
        });

        if (options.selectPlaceOnce) {
            this._enableSelectMode(options.selectPlaceOnce);
        } else {
            if (_.isFunction(this._onPlaceSelectedOnce)) {
                try {
                    this._onPlaceSelectedOnce(null);
                } catch (e) {
                    this.showError(e);
                }
            }
            this._disableSelectMode();
        }

        if (options.resetToDefaults) {
            if (_.isObject(options.resetToDefaults)) {
                this.filterCont.setFilterToDefaults(
                    _.defaults(
                        options.resetToDefaults,
                        _.pick(options, ['favorites'])
                    )
                );
            } else {
                this.filterCont.setFilterToDefaults(_.pick(options, ['favorites']));
            }
        } else if (!_.isUndefined(options.favorites)) {
            this.filterCont.setIsFavoriteSearchMode(!!options.favorites);
        }

        return ret;
    },

    cancel(options) {
        _.defaults(options || (options = {}), {
            cancelAll: false
        });

        if (this.isShowingFilter) {
            this._toggleResultFilterView();
            if (!options.cancelAll) {
                return true;
            }
        }

        if (this.mapModel.get('show')) {
            this.mapModel.set({ show: false });
            if (!options.cancelAll) {
                return true;
            }
        }

        if (_.isFunction(this._onPlaceSelectedOnce)) {
            try {
                this._onPlaceSelectedOnce(null);
            } catch (e) {
                this.showError(e);
            }
        }
        this._disableSelectMode();

        return Page.prototype.cancel.apply(this, _.omit(options, 'cancelAll'));
    },

    onAfterShow() {
        if (this.map.currentView && !this.map.$el.hasClass('hidden'))
            this.map.currentView.refresh(true);
    },

    onBeforeHide() {
        if (this.isShowingFilter) {
            this._toggleResultFilterView();
        }
    },

    init() {
        this.jqSearchPlacesPage = this.$el;

        this.initFilterParam();

        this.initBottomBar();

        this.filterCont.initialize(this.filterPanel.currentView.$el, this.footer.$el, this.filter, this);

        let lt = geo.getCurrentPosition().lt,
            lg = geo.getCurrentPosition().lg;

        if (lt !== null && lg !== null) {
            this.mapModel.set({
                userLat: lt,
                userLng: lg
            });
        } else {
            this.mapModel.set({
                userLat: void 0,
                userLng: void 0
            });
        }

        geo.add_callback_curr_user_pos_change(this._callback_curr_user_pos_change.bind(this));

        this.search(this.filter);
    },

    initBottomBar() {
        this.jqNavbarMain = this.footer.$el.find('.footer.main');
        this.jqNavbarFilter = this.footer.$el.find('.footer.filter');
    },

    initFilterParam() {
        var userGeoPos = geo.getCurrentPosition();

        this.filter.point_lat = userGeoPos.lt;
        this.filter.point_long = userGeoPos.lg;

        var loginedUser = LoginedUserHandler.getLoginedUser();
        if (loginedUser != null) {
            this.filter.anonym = loginedUser.settings.viewanonym || loginedUser.settings.viewanonym == 1;
            this.filter.unverified = loginedUser.settings.viewunverified || loginedUser.settings.viewunverified == 1;
        }

        if (this.filter.sortby && this.filter.sortby != B2CPlace.const.search.sortby.dis_asc) {
            this.filter.sortby = B2CPlace.const.search.sortby.dis_asc;
        }

        switch (this.filter.sortby) {
            case B2CPlace.const.search.sortby.dis_desc: // По дистанции, в порядке уменьшения
                this.places.comparator = PlaceSearchCore.byDistanceDesc;
                break;
            case B2CPlace.const.search.sortby.dis_asc: // По дистанции, в порядке увеличения
                this.places.comparator = PlaceSearchCore.byDistanceAsc;
                break;
            case B2CPlace.const.search.sortby.rating_asc: // По рейтингу, в порядке увеличения
                this.places.comparator = PlaceSearchCore.byRatingAsc;
                break;
            case B2CPlace.const.search.sortby.rating_desc: // По рейтингу, в порядке уменьшения
                this.places.comparator = PlaceSearchCore.byRatingDesc;
                break;
        }
    },

    search(filter, searchId, silent) {
        this.isLoading = true;
        filter = filter || this.filter;

        var lc_filter = $.extend(true, {}, filter);

        if (lc_filter.corners === null || lc_filter.corners.nw.lt === null) {
            lc_filter.corners = null;
        }

        if (lc_filter.point_lat == null && lc_filter.corners == null) {
            lc_filter.maxdistance = null;
            lc_filter.corners = null;
        }

        for (let key in lc_filter) {
            if (lc_filter[key] === null)
                delete lc_filter[key];
        }

        lc_filter.point_current = (this.nearUserMode && filter.point_lat !== null && filter.point_long !== null) ? 1 : 0;

        searchId = searchId || this.searchId;
        return new Promise((resolve, reject) => {
            B2CPlace.server_search(lc_filter, data => {
                if (this.searchId == searchId) {
                    this.isLoading = false;
                    this._handleServerResponse(data);
                    resolve(_.extend(data, { searchId }));
                } else {
                    resolve(_.extend(data, { searchId }));
                }
            }, (jqXHR, textStatus, errorThrown) => {
                this.on_server_search_error(jqXHR, textStatus, errorThrown, searchId, silent, filter);
                reject(new AjaxError(jqXHR, textStatus, errorThrown));
            });
        });
    },

    check_and_load_more() {
        if (this._is_need_load_more())
            this.load_more();
    },

    load_more() {
        if (this.isLoading || this.isAllLoaded)
            return;
        if (!this.places.length)
            return;

        this.search(_.extend({}, this.filter, { offset: this.filter.offset + this.filter.limit }))
            .then(data => data.searchId == this.searchId && (this.filter.offset += this.filter.limit));
    },

    /**
     * Возвращает TRUE, если нужно грузить еще плейсы из БД B2C Messenger
     * @returns {boolean}
     * @private
     */
    _is_need_load_more() {
        if (this.isLoading || this.isAllLoaded)
            return false;

        if (this.places.length > maxPlaces) {
            this.results.$el.addClass('overload');
            return false;
        }

        if (!this._is_need_load_more_by_page_scroll())
            return false;

        return true;
    },

    goo_check_and_load_more() {
        if (this._goo_is_need_load_more())
            this.goo_load_more();
    },

    goo_load_more() {
        if (this.is_google_loading || this.is_google_all_loaded)
            return;

        if (this.goo_place_pagination == null) {
            this._google_search_places();
        } else {
            this.goo_place_pagination.nextPage();
        }
    },

    /**
     * Возвращает TRUE, если нужно грузить еще плейсы из Google Place API
     * @returns {boolean}
     * @private
     */
    _goo_is_need_load_more() {
        if (!this.map.currentView.map)
            return false;

        if (this.is_google_loading || this.is_google_all_loaded)
            return false;

        let isSortedByDistance = this.filter.sortby == B2CPlace.const.search.sortby.dis_asc;

        if (isSortedByDistance) {
            let closestB2CPlace = this.places.find(p => !p.has('__goo_place'));

            if (closestB2CPlace && closestB2CPlace.get('dist') > 5) {
                return true;
            }
        }

        if (!this.isAllLoaded)
            return false; // Еще не все плейсы B2C Messenger загружены

        if (!this._is_need_load_more_by_page_scroll())
            return false;

        return true;
    },

    /**
     * Вовращает TRUE, если страница результатов проскролина так, что нужно еще грузить плейсы
     * @private
     */
    _is_need_load_more_by_page_scroll() {
        if (this.stopCheckingLoadingOnScroll) return false;
        var jq_activePage = $.mobile.pageContainer.pagecontainer("getActivePage");
        if (jq_activePage.attr('id') != this.page_id)
            return false;

        const lastPlace = this.results.currentView.children.last(),
            lastPlaceHeight = lastPlace ? lastPlace.el.clientHeight : 0,
            threshold = B2CPlace.const.search.load_limit / 2 * lastPlaceHeight;

        if (this.results.el.scrollHeight > 0) {
            return this.results.el.scrollHeight - this.results.el.scrollTop - this.results.el.clientHeight <= threshold;
        } else {
            return false;
        }
    },

    /**
     * Иницирует запрос к Google Map Place API для получения информации по плейсу с google индентификатором @goo_place_id, через указанное в timeout миллисекунд времени
     * @param goo_place_id
     * @private
     */
    _goo_request_place_info(goo_place_id) {
        return new Promise((resolve, reject) => {
            googlePlace.request_place_info(
                this.map.currentView.map,
                goo_place_id,
                (b2c_place) => {
                    b2c_place.mode = B2CPlace.const.save.mode.save;
                    b2c_place.status = B2CPlace.const.status.active;

                    var place = _.extend({}, b2c_place);
                    place.__goo_place = null;

                    place.categories = [];
                    for (var i = 0; i < b2c_place.categories.length; i++) {
                        place.categories.push(b2c_place.categories[i].id);
                    }

                    B2CPlace.server_create(place,
                        (respData) => {
                            b2c_place.id = respData.id;
                            b2c_place.brand_id = respData.brand_id;
                            this.goo_places[b2c_place.__goo_place.place_id] = b2c_place;

                            this.places.remove(b2c_place.__goo_place.place_id);
                            this.places.add([_.extend(respData, {
                                point_latitude: this.filter.point_lat,
                                point_longitude: this.filter.point_long
                            })]);

                            resolve();
                        },
                        (jqXHR, textStatus, errorThrown) => {
                            reject(new AjaxError(jqXHR, textStatus, errorThrown));
                        }
                    );

                },
                (error, goo_place_id) => {
                    reject(new Error(error));

                    if (GoogleAnalytics) {
                        GoogleAnalytics.trackException(`googlePlace.request_place_info() error: ${JSON.stringify(error)}`, false);
                    }

                    if (window.Sentry) {
                        Sentry.captureMessage(`googlePlace.request_place_info() error: ${JSON.stringify(error)}`);
                    }
                },
                null
            );
        });
    },

    _addPlacesToResults(places, fromMap) {
        if (places.length == 0)
            return;

        const newPlaces = _.filter(places, place => !this.places.get(place.id));
        _.each(newPlaces, place => {
            if (place.ext_id != null) {
                this.goo_place_id_to_b2c[place.ext_id] = place.id;
            }
        });

        if (fromMap) {
            _.each(places, p => p._fromMap = true);

            if (!this.places.length) {
                this.places.reset(places);
            } else {
                this.places.add(places, { merge: false });
            }
        } else {
            if (!this.places.length) {
                this.places.reset(places);
            } else {
                this.places.add(places, { merge: true });
            }
        }
    },

    _addGooglePlacesToResults(places) {
        if (places.length == 0)
            return;

        const newPlaces = _.filter(places, place =>
            !this.goo_places[place.__goo_place.place_id] && !this.goo_place_id_to_b2c[place.__goo_place.place_id]);

        const models = _.map(newPlaces, place => {
            if (!place.id) {
                place.id = place.__goo_place.place_id;
            }

            if (isNaN(Number(place.dist))) {
                place.dist = geo.distance(place.adr_latitude, place.adr_longitude, this.filter.point_lat, this.filter.point_long, 'M');
            }

            this.goo_places[place.__goo_place.place_id] = place;

            return _.extend(place, {
                point_latitude: this.filter.point_lat,
                point_longitude: this.filter.point_long
            });
        });

        if (!this.places.length) {
            this.places.reset(models);
        } else {
            this.places.add(models, { merge: true });
        }
    },

    _handleServerResponse(response) {
        this.filterCont.jqResultsCount.text(response.totalresults);

        if (this.filter.mode == B2CPlace.const.search.mode.get_places) {
            this._addPlacesToResults(response.places);

            if (response.places != null) {
                if (response.places.length > 0) {
                    this.check_and_load_more();
                } else if (response.places.length == 0) {
                    this.isAllLoaded = true;
                }
            } else {
                this.isAllLoaded = true;
            }
            this.goo_check_and_load_more();
        }
    },

    _toggleResultFilterView() {
        if (this.filterPanel.$el.hasClass('hidden')) {
            this.ui.content.children().removeClass('prev').not('.hidden').first().addClass('prev');
            this.ui.content.children().addClass('hidden');
            this.filterPanel.$el.removeClass('hidden');
            this.searchPanel.currentView.trigger('filter:activate');
            this.footer.currentView.trigger('mode:filter');
            this.filterCont.onShow();
            this.el.classList.add('showing-filter');
            this.isShowingFilter = true;
        } else {
            let prev = this.ui.content.children('.prev');
            if (prev.length) {
                this.ui.content.children().addClass('hidden');
                prev.removeClass('hidden').removeClass('prev');
            } else {
                this.ui.content.children('.hidden').first().removeClass('hidden');
                this.filterPanel.$el.addClass('hidden');
            }

            if (!this.map.$el.hasClass('hidden')) {
                this._onMapWithPlaces_bounds_changed();
            }

            this.searchPanel.currentView.trigger('filter:deactivate');
            this.footer.currentView.trigger('mode:search');
            this.filterCont.onHide();
            this.el.classList.remove('showing-filter');
            this.isShowingFilter = false;
        }
    },

    clearResults() {
        this.results.$el.removeClass('overload');
        this.places.reset();
        this.goo_places = {};
        this.goo_place_pagination = null;
        this.goo_place_id_to_b2c = {};
        this.is_google_all_loaded = false;
        this.isAllLoaded = false;
        this.filter.offset = 0;
        this.searchId = _.uniqueId();
        this.mapModel.set({ overload: false });
    },

    _google_search_places() {
        if (googlePlace.search_places(
            this.map.currentView.map,
            this.filter,
            (places, paginationObject) => {
                this.is_google_loading = false;
                this.goo_place_pagination = paginationObject;

                if (this.filter.mode == B2CPlace.const.search.mode.get_places) {
                    // Поиск был в режиме показа плейсов
                    if (!paginationObject.hasNextPage) {
                        this.is_google_all_loaded = true;
                    }

                    this._addGooglePlacesToResults(places);
                } else {
                    // Поиск был в режиме подсчета кол-ва результатов
                }
            },
            this._callback_on_google_place_search_error.bind(this))) {
            this.is_google_loading = true;
        }
    },

    _google_load_places() {
        const timeout = 2000;
        const searchId = this.searchId;

        return _.reduce(this.goo_places,
            (promise, place) =>
                promise
                    .then(() => {
                        if (searchId != this.searchId) {
                            throw 'stop';
                        }
                    })
                    .then(() => this._goo_request_place_info(place.__goo_place.place_id))
                    .then(() => new Promise(resolve => _.delay(resolve, timeout))),
            Promise.resolve()
        );
    },

    _enableSelectMode(cb) {
        this._onPlaceSelectedOnce = cb;
        this.$el.addClass('mode-select');
        this.header.currentView.ui.btnback.removeClass('hidden');
        this.header.currentView.ui.btnmenu.addClass('hidden');
        this.header.currentView.ui.btnnotifications.addClass('hidden');
    },

    _disableSelectMode() {
        delete this._onPlaceSelectedOnce;
        this.$el.removeClass('mode-select');
        this.header.currentView.ui.btnback.addClass('hidden');
        this.header.currentView.ui.btnmenu.removeClass('hidden');
        this.header.currentView.ui.btnnotifications.removeClass('hidden');

        if (this != app.controller.mainPage) {
            this.header.currentView.ui.btnback.removeClass('hidden');
            this.header.currentView.ui.btnmenuleft.addClass('hidden');
            this.header.currentView.ui.btnmenuright.removeClass('hidden');
        } else {
            this.header.currentView.ui.btnmenuright.addClass('hidden');
        }
    },

    _onPlaceClickHandler(jqPlace, event) {
        const id = jqPlace.attr('data-id');

        if (id) {
            const place = this.goo_places[id];
            if (place) {
                if (place.id == id) {
                    this.showLoading();

                    this._goo_request_place_info(place.__goo_place.place_id)
                        .then(() => {
                            this._on_place_click_sub_handler(this.goo_places[id]);
                        })
                        .catch(e => {
                            this.showError(e);
                            this.hideLoading();
                        })
                        .then(() => this.hideLoading())
                        .then(() => this._google_load_places()).catch(e => {
                        if (e != 'stop') {
                            throw e;
                        }
                    })

                } else {
                    this._on_place_click_sub_handler(place);
                }
            } else {
                const place = _.clone(this.places.get(id).attributes);
                this._on_place_click_sub_handler(place);
            }
        }
    },

    _on_place_click_sub_handler(place) {
        this.trigger('place:click', this.places.get(place.id));

        if (this._onPlaceSelectedOnce) {
            if (_.isFunction(this._onPlaceSelectedOnce)) {
                try {
                    this._onPlaceSelectedOnce(place);
                    this.cancel({ cancelAll: true });
                } catch (e) {
                    this.showError(e);
                }
            }

            this._disableSelectMode();
            return;
        }

        app.controller.goToPlacePage({
            place
        });
    },

    _onFilterChanged(key, value, oldValues) {
        let needsClearResults = false;
        if (!_.isUndefined(key)) {
            switch (key) {
                case 'point_lat':
                case 'point_long': {
                    if (!this.nearUserMode && !this.filter.maxdistance) {
                        const prevLat = oldValues && oldValues['point_lat'] || this.filter.point_lat,
                            prevLng = oldValues && oldValues['point_long'] || this.filter.point_long;

                        needsClearResults = geo.distance(this.filter.point_lat, this.filter.point_long, prevLat, prevLng, 'M') > 1;
                    } else {
                        needsClearResults = this.filter.maxdistance;
                    }
                    break;
                }
                case 'maxdistance':
                case 'minrating':
                case 'open':
                    needsClearResults = !!value;
                    break;
                case 'categories':
                case 'properties':
                    needsClearResults = value && _.size(value);
                    break;
                default:
                    needsClearResults = true;
                    break;
            }
        }

        if (needsClearResults) {
            this.clearResults();
        } else {
            this.isAllLoaded = false;
            this.filter.offset = 0;
            this.searchId = _.uniqueId();

            if (this.filter.mode == B2CPlace.const.search.mode.get_places) {
                if (key == 'point_lat' || key == 'point_long') {
                    this.places.each(p => p.set({
                        point_latitude: this.filter.point_lat,
                        point_longitude: this.filter.point_long
                    }));
                }
            }
        }

        if (this.filter.mode == B2CPlace.const.search.mode.get_places) {
            switch (this.filter.sortby) {
                case B2CPlace.const.search.sortby.dis_desc: // По дистанции, в порядке уменьшения
                    this.places.comparator = PlaceSearchCore.byDistanceDesc;
                    break;
                case B2CPlace.const.search.sortby.dis_asc: // По дистанции, в порядке увеличения
                    this.places.comparator = PlaceSearchCore.byDistanceAsc;
                    break;
                case B2CPlace.const.search.sortby.rating_asc: // По рейтингу, в порядке увеличения
                    this.places.comparator = PlaceSearchCore.byRatingAsc;
                    break;
                case B2CPlace.const.search.sortby.rating_desc: // По рейтингу, в порядке уменьшения
                    this.places.comparator = PlaceSearchCore.byRatingDesc;
                    break;
            }

            this.search(this.filter);

            if (!this.nearUserMode) {
                this.mapModel.set({
                    lat: this.filter.point_lat,
                    lng: this.filter.point_long
                });
            }
        } else {
            this.search(this.filter);
        }

        if (this.filterPanel.currentView && !this.filterPanel.currentView.filterCont.isFilterDefault()) {
            if (this.searchPanel.currentView) this.searchPanel.currentView.$el.addClass('is-changed');
        } else {
            if (this.searchPanel.currentView) this.searchPanel.currentView.$el.removeClass('is-changed');
        }
    },

    _deltaTwoPoints(p1, p2, span) {
        const b = new google.maps.LatLngBounds();
        b.extend(p1);
        b.extend(p2);

        const s = b.toSpan();

        return Math.sqrt(Math.pow(s.lat() / span.lat(), 2) + Math.pow(s.lng() / span.lng(), 2));
    },

    _deltaTwoBounds(b1, b2) {
        const span = b2.toSpan();

        return this._deltaTwoPoints(b1.getNorthEast(), b2.getNorthEast(), span) + this._deltaTwoPoints(b1.getSouthWest(), b2.getSouthWest(), span);
    },

    _onMapWithPlaces_bounds_changed: _.debounce(function (noDelta) {
        if (!this.map || !this.map.currentView || !this.map.currentView.map) {
            return console.error('_onMapWithPlaces_bounds_changed() error. this.map.currentView.map is undefined');
        }

        if (!this.map.$el.hasClass('hidden')) {
            const bounds = this.map.currentView.map.getBounds(),
                oldBounds = this._mapOldBounds || new google.maps.LatLngBounds;

            if (noDelta || this._deltaTwoBounds(oldBounds, bounds) > 0.3) {
                this._mapOldBounds = new google.maps.LatLngBounds(bounds.getSouthWest(), bounds.getNorthEast());

                const limit = maxMarkers,
                    filter = _.omit(_.extend({}, this.filter, {
                        corners: {
                            nw: { lt: bounds.getNorthEast().lat(), lg: bounds.getSouthWest().lng() },
                            se: { lt: bounds.getSouthWest().lat(), lg: bounds.getNorthEast().lng() },
                        },
                        limit,
                        point_current: 0,
                    }), val => val === null);

                if (this.places.length > maxPlaces + maxMarkers) {
                    const markerPlaces = this.places.where({ _fromMap: true });
                    if (this.places.length - markerPlaces > maxPlaces + maxMarkers) {
                        this.clearResults();
                        this.search(this.filter).then(data => data && this._onMapWithPlaces_bounds_changed());
                        return;
                    } else {
                        this.places.remove(markerPlaces);
                    }
                }

                function fetch(offset, retreived) {
                    B2CPlace.server_search(_.extend(filter, { offset: offset || 0 }), data => {
                        this._addPlacesToResults(data.places, true);

                        const count = data.places && data.places.length || 0;
                        retreived += count;

                        if (count && (data.totalresults > retreived) && retreived < maxMarkers) {
                            fetch.call(this, (offset || 0) + limit, retreived);
                        } else if (retreived >= maxMarkers) {
                            this.mapModel.set({ overload: true });
                        } else {
                            this.mapModel.set({ overload: false });
                        }
                    }, (jqXHR, textStatus, errorThrown) => {
                    });
                }

                fetch.call(this, 0, 0);
            }
        } else {
            this.mapBoundsHasChangedWhileMapWasHidden = true;
        }
    }, 300),

    _callback_curr_user_pos_change(lt, lg) {
        if (this.mapModel && lt !== null && lg !== null) {
            this.mapModel.set({
                userLat: lt,
                userLng: lg
            });
        } else {
            this.mapModel.set({
                userLat: void 0,
                userLng: void 0
            });
        }

        if (!this.nearUserMode)
            return;

        let needsSearch = false, // Повторный поиск с новым центром не требуется
            needsClear = false;

        if (this.filter.point_lat == null || this.filter.point_long == null || (
            geo.distance(this.filter.point_lat, this.filter.point_long, lt, lg, 'M') > 0.1
        )) {
            needsSearch = true; // Требуется повторный поиск!
        }

        if (this.filter.point_lat == null || this.filter.point_long == null || (
            geo.distance(this.filter.point_lat, this.filter.point_long, lt, lg, 'M') > 1
        )) {
            needsClear = true; // Требуется сбросить список!
        }

        if (needsClear) {
            this.clearResults();
            this.mapModel.set({
                lat: this.filter.point_lat,
                lng: this.filter.point_long,
            });
            this.map.currentView.refresh(true);
        }

        if (needsSearch) {
            this.filterCont.set_point_search_widget(lt, lg);
            this.filterCont.changeFilter('point_lat', lt, true);
            this.filterCont.changeFilter('point_long', lg);
        }
    },

    _callback_on_google_place_search_error(error) {
        this.is_google_loading = false;

        if (GoogleAnalytics) {
            GoogleAnalytics.trackException(`PlaceSearchCore._callback_on_google_place_search_error: ${JSON.stringify(error)}`, false);
        }

        if (window.Sentry) {
            Sentry.captureMessage(`PlaceSearchCore._callback_on_google_place_search_error: ${JSON.stringify(error)}`);
        }
    },

    on_server_search_error(jqXHR, textStatus, errorThrown, searchId, silent, filter) {
        this.isLoading = false;
        if (!silent) {
            this.showError(jqXHR, textStatus, errorThrown);
        }

        if (jqXHR.status == 0 && filter && this.searchId == searchId && !this.places.length) {
            _.delay(() => {
                if (filter && this.searchId == searchId && !this.places.length) {
                    this.search(filter, null, true);
                }
            }, 10000);
        }
    },
};

const staticProps = {
    byDistanceDesc: (p1, p2) => {
        if (p1.get('dist') != p2.get('dist')) {
            if (!p1.get('dist')) {
                return 1;
            }
            if (!p2.get('dist')) {
                return -1;
            }
            return p2.get('dist') - p1.get('dist');
        } else {
            return p2.get('ratings').t.r - p1.get('ratings').t.r;
        }
    },
    byDistanceAsc: (p1, p2) => {
        if (p1.get('dist') != p2.get('dist')) {
            if (!p1.get('dist')) {
                return -1;
            }
            if (!p2.get('dist')) {
                return 1;
            }
            return p1.get('dist') - p2.get('dist');
        } else {
            return p2.get('ratings').t.r - p1.get('ratings').t.r;
        }
    },
    byRatingDesc: (p1, p2) => {
        if (p1.get('ratings').t.r != p2.get('ratings').t.r) {
            return p2.get('ratings').t.r - p1.get('ratings').t.r;
        } else {
            if (!p1.get('dist')) {
                return 1;
            }
            if (!p2.get('dist')) {
                return -1;
            }
            return p1.get('dist') - p2.get('dist');
        }
    },
    byRatingAsc: (p1, p2) => {
        if (p1.get('ratings').t.r != p2.get('ratings').t.r) {
            return p1.get('ratings').t.r - p2.get('ratings').t.r;
        } else {
            if (!p1.get('dist')) {
                return 1;
            }
            if (!p2.get('dist')) {
                return -1;
            }
            return p1.get('dist') - p2.get('dist');
        }
    },
};

export const PlaceSearchCore = { protoProps, staticProps };
