import React from 'react';
import { MenuListType, byIdAndSortOrder } from 'components/menu/MenuList/MenuList';
import MenuSortableList, {
    MenuSortableListDragHandle,
    MenuSortableListItem,
    MenuSortaBleListItemDragClassName,
} from 'components/menu/MenuSortable/MenuSortableList';
import { UnlistedCategory } from 'utils/api/menu';
import { MenuCategoryBase } from 'components/menu/MenuCategory/MenuCategory';
import { MenuHiddenVisibility } from 'components/menu/MenuVisibility/MenuVisibility';
import '../MenuCategories/MenuCategories.scss';

const MenuSortableCategories = (props) => {
    const { categories, onSelectCategory, onChangeOrder, dragPressDelay, ...restProps } = props;

    const categoriesList = categories
        .filter((c) => c.id !== UnlistedCategory.id)
        .map((c, index) => (
            <MenuSortableListItem
                className="menu-list-item-with-controls menu-sortable-list-item"
                key={byIdAndSortOrder(c)}
                index={index}
                left={<MenuSortableListDragHandle />}
                right={<MenuSortableListDragHandle />}
            >
                <MenuCategoryBase className="menu-list-item" {...c}>
                    {(c.is_hidden_forever || c.hidden_until) && <MenuHiddenVisibility />}
                </MenuCategoryBase>
            </MenuSortableListItem>
        ));

    return (
        <MenuSortableList
            onSortEnd={onChangeOrder}
            pressDelay={dragPressDelay}
            helperClass={MenuSortaBleListItemDragClassName}
            lockAxis="y"
            type={MenuListType.Categories}
            {...restProps}
        >
            <div className="menu-sortable-items">{categoriesList}</div>
        </MenuSortableList>
    );
};

export default MenuSortableCategories;
