import { Model, Collection } from '@b2cmessenger/backbone';

import PhotoModel from 'models/PhotoModel';
import Window from 'windows/Window';
import HeaderView from 'widgets/Header/Header';
import Gallery from 'widgets/Photo/Gallery';

import './MessageGallery.scss';

let PhotoCollection = Collection.extend({
    model: PhotoModel
});

let MessageGallery = Window.extend({
    windowName: "message-gallery-window",
    className: "message-gallery-window",

    bindings: {
        ':el': 'classes:{"show-grid":showGrid}'
    },

    initialize() {
        this.viewModel = new Model({
            showGrid: false,
        });
        this.collection = new PhotoCollection(_(this.model.get('photos')).map(p => _.create(null, {
            id: p.id,
            photo: p.ph,
            photo_w: p.ph_w,
            photo_h: p.ph_h,
            thumb: p.th,
            thumb_w: p.th_w,
            thumb_h: p.th_h
        })) || []);
    },

    onRender() {
        let gallery = new Gallery({ collection: this.collection });
        gallery.setEditMode(false);
        this.viewModel.set({ showGrid: gallery.isShowingGrid() });
        this.viewModel.listenTo(gallery, 'change:showGrid', (gallery, showGrid) => this.viewModel.set({ showGrid }));
        this.content.show(gallery);

        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: 'Message photos',
            rightButtons: ['grid']
        });
        this.header.show(headerView);
        headerView.ui.btngrid.toggleClass('active', this.viewModel.get('showGrid'));
        headerView.listenTo(this.viewModel, 'change:showGrid', (m, showGrid) => headerView.ui.btngrid.toggleClass('active', showGrid));
        this.listenTo(headerView, 'back:click', view => this.cancel());
        this.listenTo(headerView, 'grid:click', () => {
            if(this.viewModel.get('showGrid')) {
                gallery.showPhoto();
            } else {
                gallery.showGrid();
            }
        });

        if(this.options && this.options.openPhotoWithId) {
            gallery.showPhotoWithId(this.options.openPhotoWithId);
        }
    },

    cancel() {
        this.close(this.model);
    }
});

export default MessageGallery;
