import { Required, Optional, ViewModel, ItemView } from '@b2cmessenger/backbone';

import CommentEditorPhotoModel from './PhotoModel';

import backgroundImageHandler from 'bindings/handlers/backgroundImage';
import template from './PhotoItem.jade';
import './PhotoItem.scss';

/** @type {typeof import('./PhotoItem').properties} */
// @ts-ignore
const properties = ItemView.properties;

/** @type {typeof import('./PhotoItem').options} */
// @ts-ignore
const options = ItemView.options;

/** @type {typeof import('./PhotoItem').events} */
// @ts-ignore
const events = ItemView.events;

@options({
    model: Required,
    parentViewModel: Optional
})
@properties({
    className: 'widget message-comment-editor-photo-item-widget',
    template,

    ui: {
        arForcer: '[data-js-ar-forcer]',
        fileShield: '[data-js-file-shield]',
        spinner: '[data-js-spinner]',
        btnDelete: '[data-js-btn-delete]',
    },

    computeds: {
        paddingBottomPercentStr: {
            deps: ['ar'],
            get: ar => ar && ((100 / ar).toFixed(2) + '%') || '100%'
        },
        isReadyToUpload: {
            deps: ['status'],
            get: status => status == CommentEditorPhotoModel.Status.ReadyToUpload
        },
        isUploading: {
            deps: ['status'],
            get: status => status == CommentEditorPhotoModel.Status.Uploading
        },
        isUploaded: {
            deps: ['status'],
            get: status => status == CommentEditorPhotoModel.Status.Uploaded
        },
        isSelectedToDelete: {
            deps: ['status'],
            get: status => status == CommentEditorPhotoModel.Status.SelectedToDelete
        },
        canBeDeleted: {
            deps: ['status'],
            get: status => status == CommentEditorPhotoModel.Status.ReadyToUpload ||
                status == CommentEditorPhotoModel.Status.Uploaded ||
                status == CommentEditorPhotoModel.Status.SelectedToDelete ||
                status == CommentEditorPhotoModel.Status.UploadedNotSaved
        }
    },

    bindings: {
        ':el': 'backgroundImage:thumbUrl,classes:{new:isReadyToUpload},css:{width:width,left:left,top:top}',
        '@ui.arForcer': 'css:{"padding-bottom":paddingBottomPercentStr}',
        '@ui.fileShield': 'classes:{hidden:not(fileUrl)},attr:{src:fileUrl}',
        '@ui.spinner': 'classes:{hidden:not(any(isUploading,not(ar)))}',
        '@ui.btnDelete': 'classes:{selected:isSelectedToDelete,hidden:any(disabled,not(canBeDeleted))},' +
            'disabled:any(disabled,not(canBeDeleted))'
    },

    bindingHandlers: {
        backgroundImage: backgroundImageHandler
    },

    events: {
        'click @ui.btnDelete'() {
            if (this.model.get('status') == CommentEditorPhotoModel.Status.ReadyToUpload) {
              this.model.destroy();
            } else if (this.model.get('status') == CommentEditorPhotoModel.Status.UploadedNotSaved) {
              this.model.collection && this.model.collection.remove(this.model);
            } else if (this.model.get('status') == CommentEditorPhotoModel.Status.Uploaded) {
                this.model.set({ status: CommentEditorPhotoModel.Status.SelectedToDelete });
            } else if (this.model.get('status') == CommentEditorPhotoModel.Status.SelectedToDelete) {
                this.model.set({ status: CommentEditorPhotoModel.Status.Uploaded });
            }
      }
    },

    modelEvents: {
        'change:ar change:fileUrl': _.debounce(function () {
            this._onArOrFileUrlChange();
        }, 1)
    }
})
class PhotoItemView extends ItemView {
    constructor(options) {
        super(options)
    }
    
    setDimensions(width, left, top) {
        this.viewModel.set({
            width: Number(width) || 0,
            left: Number(left) || 0,
            top: Number(top) || 0
        });
    }

    initialize() {
        /**@type {import('./PhotoItem')} */
        // @ts-ignore
        const self = this;
        this.viewModel = new ViewModel({
            parentViewModel: self.options.parentViewModel,
            width: 0,
            left: 0,
            top: 0
        });
    }

    onAttach(view) {
        this._onArOrFileUrlChange();
    }

    _onArOrFileUrlChange() {
        if (this.isRendered && !this.isDestroyed) {
            if (!this.model.get('ar') && this.model.get('fileUrl')) {
                const imageEl = this.ui.fileShield[0];
                if (imageEl.complete) {
                    this.model.set({
                        file_w: imageEl.naturalWidth,
                        file_h: imageEl.naturalHeight
                    });
                } else {
                    imageEl.onload = () => {
                        this.model.set({
                            file_w: imageEl.naturalWidth,
                            file_h: imageEl.naturalHeight
                        });
                    }
                }
            }
        }
    }
}

export default PhotoItemView;
