import React, { useState, useCallback } from 'react';
import ReactWindow from 'windows/ReactWindow';
import './MenuSearch.scss';

import { WindowContent, WindowFooter, WindowHeader } from 'components/window/Window';
import Header from 'components/UI/Header/Header';
import { BackButton } from 'components/UI/Button/Button';
import Footer from 'components/UI/Footer/Footer';
import TextButton from 'components/UI/TextButton';
import KeyboardPadding from 'components/KeyboardPadding';
import * as MenuAPI from 'utils/api/menu';
import { showError } from 'windows/Modal/Info';
import Spinner from 'components/UI/Spinner/Spinner';
import MenuCategories from 'components/menu/MenuCategories/MenuCategories';
import MenuItems from 'components/menu/MenuItems/MenuItems';
import { TextInput, TextInputGroup } from 'components/UI/InputGroup/InputGroup';

const MenuSearch = (props) => {
    const { placeId, closeWindow } = props;
    const [query, setQuery] = useState('');
    const [reqQuery, setReqQuery] = useState('');

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    const fetchData = useCallback(
        async (query) => {
            if (!query || loading) {
                return;
            }

            setLoading(true);
            setReqQuery('');

            try {
                const data = await MenuAPI.search({ placeId, query });

                if (data && !data.items.length && !data.categories.length) {
                    setReqQuery(query);
                }

                setData(data);
            } catch (e) {
                showError(e);
            } finally {
                setLoading(false);
            }
        },
        [loading]
    );

    const onSubmit = useCallback(
        (e) => {
            e.preventDefault();
            fetchData(query);
        },
        [query]
    );

    const onSelectCategory = (id) => {
        app.controller.goToMenuPage({
            placeId,
            categoryId: id,
        });
        closeWindow();
    };
    const onSelectItem = (id) => {
        app.controller.goToMenuItemPage({
            placeId,
            itemId: id,
        });
        closeWindow();
    };

    const disabled = loading || !query;

    let content = null;

    if (loading) {
        content = <Spinner />;
    } else if (reqQuery) {
        content = (
            <div className="menu-search-list empty">
                <p>{`Search for your query "${reqQuery}" returned no results`}</p>
            </div>
        );
    } else if (data) {
        const { items = [], categories = [] } = data;

        content = (
            <div className="menu-search-list">
                {categories && <MenuCategories categories={categories} onSelectCategory={onSelectCategory} />}
                {items && <MenuItems items={items} onSelectItem={onSelectItem} />}
            </div>
        );
    }

    return (
        <>
            <WindowHeader>
                <Header>
                    <BackButton onClick={() => closeWindow()} />
                    <h1 className="title">Menu search</h1>
                </Header>
            </WindowHeader>
            <WindowContent>
                <form onSubmit={onSubmit} className="menu-search-form">
                    <TextInputGroup label="Search by full match" icon="ion-ios-search">
                        <TextInput
                            value={query}
                            placeholder="Enter query"
                            onChange={(e) => setQuery(e.currentTarget.value)}
                        />
                    </TextInputGroup>
                    <div className="menu-search-content">{content}</div>
                </form>
            </WindowContent>
            <WindowFooter>
                <Footer>
                    <TextButton onClick={() => closeWindow()}>CLOSE</TextButton>
                    <TextButton disabled={disabled} onClick={onSubmit}>
                        SEARCH
                    </TextButton>
                </Footer>
            </WindowFooter>
            <KeyboardPadding />
        </>
    );
};

const MenuSearchWindow = ReactWindow.extend({
    constructor: function MenuSearchWindow(props) {
        const options = {
            props,
            component: MenuSearch,
            name: 'MenuSearch',
        };

        return ReactWindow.apply(this, [options]);
    },
});

export default MenuSearchWindow;
