import dateTimeTemplate from './dateTime.jade';
import radioTemplate from './radio.jade';
import './inputGroup.scss';

/** @typedef {"datetime-local" | "radio"} Type */
/** @typedef {{ icon?: string }} BaseOptions */
/** @typedef { BaseOptions & { placeholder?: string, step?: number } } DateTimeOptions */
/** @typedef { BaseOptions & { options?: { [value:string]: string }[] } } RadioOptions */
/** @typedef { DateTimeOptions | RadioOptions } Options */

/**
 * @param {Type} type
 * @param {string} label
 * @param {Options} [options = {}]
 */
export function inputGroup(type, label, options = {}) {
    switch (type) {
        case "datetime-local": {
            _.defaults(options, {
                icon: 'ion-ios-calendar-outline',
                placeholder: "Pick date and time",
                step: 60,
                min: null
            });
            return dateTimeTemplate({ type, label, options });
        }
        case "radio": {
            _.defaults(options, {
                icon: false,
                options: []
            });
            return radioTemplate({ type, label, options });
        }
        default:
            throw new Error(`Invalid type=${type}`);
    }
}

export class InputGroupBindingHandler {
    /**
     * @param {Type} type 
     * @param {string} [label]
     * @param {Options} [options]
     * @param {boolean} [clearErrorOnSet]
     */
    constructor(type, label, options, clearErrorOnSet = false) {
        this.type = type;
        this.label = label;
        this.options = options;
        this.clearErrorOnSet = !!clearErrorOnSet;
        this.isChangable = true;

        Object.assign(this, {
            init: InputGroupBindingHandler.prototype.init,
            get: InputGroupBindingHandler.prototype.get,
            set: InputGroupBindingHandler.prototype.set
        });
    }

    init($el) {
        $el.addClass(`widget-lite-input-group input-group type-${this.type}`);
        if (this.type == 'radio') {
            $el.addClass('horizontal');
        }
        $el.html(inputGroup(this.type, this.label, this.options));
    }

    set($el, value) {
        const $input = $el.find("[data-js-input]");

        const currentVal = this.type == 'radio' ? $input.filter(':checked').val() : $input.val();
        if (currentVal != value) {
            $input.val(this.type =='radio' ? [value] : value);

            _.defer(() => $input[0].dispatchEvent(new Event('update', {
                bubbles: true,
                cancelable: true,
            })));
        }

        if (this.clearErrorOnSet) {
            $el.find("[data-js-error]").text('');
        }
    }

    get($el) {
        if (this.type == 'radio') {
            return $el.find("[data-js-input]").filter(':checked').val();
        } else if (this.type == 'datetime-local') {
            const $input = $el.find("[data-js-input]");
            const currentVal = $input.val();
            const minValue = $input.prop('min');

            if (minValue && B2Cjs.datetimeInputToJs(minValue) > B2Cjs.datetimeInputToJs(currentVal)) {
                $input.val(minValue);
                return minValue;
            }

            return currentVal;
        } else {
            return $el.find("[data-js-input]").val();
        }
    }
}

export const inputGroupDisabledBindingHandler = {
    set($el, value) {
        $el.find("[data-js-input]").prop('disabled', !!value);
    }
};

export const inputGroupEnabledBindingHandler = {
    set($el, value) {
        $el.find("[data-js-input]").prop('disabled', !value);
    }
};

export const inputGroupErrorBindingHandler = {
    set($el, value) {
        $el.find("[data-js-error]").text(value || '');
    }
};

export const inputGroupInfoBindingHandler = {
    set($el, value) {
        $el.find("[data-js-info]").text(value || '');
    }
};

export const inputGroupLabelBindingHandler = {
    set($el, value) {
        $el.find("[data-js-label]").text(value || '');
    }
};

export const inputGroupMinBindingHandler = {
    set($el, value) {
        $el.find("[data-js-input]").prop('min', value)
    }
};
