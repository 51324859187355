import { Model, ViewModel, CollectionView, ItemView } from '@b2cmessenger/backbone';

import Page from 'pages/Page';
import RegionWithPagination from 'traits/RegionWithPagination';
import ContainerWithPullToRefresh from 'traits/ContainerWithPullToRefresh';
import HeaderView from 'widgets/Header/Header';
import FooterView from 'widgets/Footer/Footer';
import LeftMenuWindow from 'windows/LeftMenu/LeftMenu';
import BroadcastCampaignCollectionView from 'widgets/BroadcastCampaign/BroadcastCampaignCollection';
import BroadcastCampaignPage from 'pages/BroadcastCampaign/BroadcastCampaign';

import BroadcastCampaignCollection from 'models/BroadcastCampaignCollection';

import template from './BroadcastCampaigns.jade';
import './BroadcastCampaigns.scss';

const BroadcastCampaignsPage = Page.extendWithTraits([
    {
        Trait: RegionWithPagination,
        options: {
            region: 'content',
            wrapperContainerElement: '> .container'
        }
    }, {
        Trait: ContainerWithPullToRefresh,
        options: {
            container: '> .container',
            element: '@ui.content > .widget'
        }
    }
], {
    getPlaceId() {
        return this.collection.placeId;
    },

    options: {
        pageSize: 20,
        collection: false,
    },

    template: template,
    attributes: {
        'id': "broadcast-campaigns-page"
    },

    className: "broadcast-campaigns-page",

    ui: {
        header: '[data-js-header]',
        content: '[data-js-content]',
        footer: '[data-js-footer]',
        btnScheduled: '[data-js-btn-scheduled]',
        btnInProgress: '[data-js-btn-in-progress]',
        btnDone: '[data-js-btn-done]'
    },

    regions: {
        header: '[data-js-header]',
        content: '[data-js-content]',
        footer: '[data-js-footer]'
    },

    bindings: {
        '@ui.btnScheduled': 'classes:{set:onlyScheduled}',
        '@ui.btnInProgress': 'classes:{set:onlyInProgress}',
        '@ui.btnDone': 'classes:{set:onlyDone}',
    },

    events: {
        'click @ui.btnScheduled'() {
            this.viewModel.set({ onlyScheduled: !this.viewModel.get('onlyScheduled') });
            this.checkPagination();
        },
        'click @ui.btnInProgress'() {
            this.viewModel.set({ onlyInProgress: !this.viewModel.get('onlyInProgress') });
            this.checkPagination();
        },
        'click @ui.btnDone'() {
            this.viewModel.set({ onlyDone: !this.viewModel.get('onlyDone') });
            this.checkPagination();
        },
    },

    initialize(options) {
        this.collection = options.collection ||
            new BroadcastCampaignCollection(null, {
                comparator: 'smart',
            });

        if (!this.viewModel) {
            this.viewModel = new ViewModel;
        }

        this.viewModel.set({
            onlyScheduled: false,
            onlyInProgress: false,
            onlyDone: false,
        });

        this.viewModel.addComputed('onlyScheduled', {
            deps: ['_onlyScheduled'],
            get: _onlyScheduled => _onlyScheduled,
            set(val) {
                if (val && this.get('_onlyInProgress') && this.get('_onlyDone')) {
                    return {
                        _onlyScheduled: false,
                        _onlyInProgress: false,
                        _onlyDone: false
                    };
                } else {
                    return {
                        _onlyScheduled: !!val
                    };
                }
            }
        });

        this.viewModel.addComputed('onlyInProgress', {
            deps: ['_onlyInProgress'],
            get: _onlyInProgress => _onlyInProgress,
            set(val) {
                if (val && this.get('_onlyScheduled') && this.get('_onlyDone')) {
                    return {
                        _onlyScheduled: false,
                        _onlyInProgress: false,
                        _onlyDone: false
                    };
                } else {
                    return {
                        _onlyInProgress: !!val
                    };
                }
            }
        });

        this.viewModel.addComputed('onlyDone', {
            deps: ['_onlyDone'],
            get: _onlyDone => _onlyDone,
            set(val) {
                if (val && this.get('_onlyScheduled') && this.get('_onlyInProgress')) {
                    return {
                        _onlyScheduled: false,
                        _onlyInProgress: false,
                        _onlyDone: false
                    };
                } else {
                    return {
                        _onlyDone: !!val
                    };
                }
            }
        });

        this.viewModel.addComputed('traitContainerWithPullToRefreshCanBePulledToRefresh', {
            deps: ['traitRegionWithPaginationIsLoading'],
            get: traitRegionWithPaginationIsLoading => !traitRegionWithPaginationIsLoading
        });
    },

    show(options) {
        _.extend(this.options, _.defaults(options || (options = {}), {
            placeId: null,
            refresh: true
        }));

        this.collection.placeId = this.options.placeId;

        const ret = Page.prototype.show.apply(this, arguments);

        if (options.refresh) {
            this.refresh({ clear: !(options.backFrom && options.backFrom instanceof BroadcastCampaignPage) });
        }

        return ret;
    },

    _reInitializeRegions() {
        const headerView = this.header.currentView;
        if (headerView) {
            this.stopListening(headerView);
        }

        const contentView = this.content.currentView;
        if (contentView) {
            this.stopListening(contentView);
        }

        return Page.prototype._reInitializeRegions.apply(this, arguments);
    },

    onRender() {
        const headerView = new HeaderView({
            leftButtons: ['back'],
            title: "Broadcast campaigns",
            rightButtons: ['add', 'notifications', 'menu']
        });
        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.listenTo(headerView, 'add:click', () => this._addNewCampaign());
        this.listenTo(headerView, 'notifications:click', () => app.controller.showNotificationsWindow());
        this.listenTo(headerView, 'menu:click', () => new LeftMenuWindow({ right: true }).show());
        this.header.show(headerView);

        const broadcastCampaignCollectionView = new BroadcastCampaignCollectionView({
            collection: this.collection,
            viewModel: this.viewModel
        });

        this.listenTo(broadcastCampaignCollectionView, 'click:add:new', () => this._addNewCampaign());

        this.content.show(broadcastCampaignCollectionView);
    },

    onPulledToRefresh() {
        this.refresh({ clear: false });
    },

    onChildviewChildviewSelect(collectionView, campaignView) {
        if (collectionView == this.content.currentView) {
            if (campaignView && campaignView.model) {
                app.controller.goToBroadcastCampaignPage({ campaign: campaignView.model });
            }
        }
    },

    _addNewCampaign() {
        if (this.collection.placeId) {
            this.showLoading();

            B2CPlace.server_get(
                {
                    id: this.collection.placeId,
                },
                place => {
                    app.controller.goToTaskEditorPage({
                        new: true,
                        place,
                        onlyBroadcast: true,
                        onSave: () => {
                            app.controller._goBackPage({ refresh: false });
                            this.refresh({ clear: false });
                        }
                    });
                    this.hideLoading();
                },
                (jqXHR, textStatus) => {
                    this.showError(jqXHR, textStatus);
                    this.hideLoading();
                }
            );
        }
    }
});

export default BroadcastCampaignsPage;
