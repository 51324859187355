import { Optional, ViewModel, Model } from "@b2cmessenger/backbone";
import Page from 'pages/Page';
import HeaderView from 'widgets/HeaderWithTabs/HeaderWithTabs';
import template from './ReservationsPage.jade'
import './ReservationsPage.scss';
import ReservationCalendarWindow from "../../windows/Reservation/ReservationCalendar/ReservationCalendar";
import ReservationModel from "../../models/ReservationModel";
import ReservationInfiniteCollectionView from 'widgets/Reservation/ReservationInfiniteCollection/ReservationInfiniteCollection';
import ReservationsDayCView from 'pages/Reservation/ReservationsDayCView';
import ReservationInfiniteCollection from 'models/ReservationInfiniteCollection';
import scrollmonitor from 'scrollmonitor';
import ViewWithWindows from 'traits/ViewWithWindows';

const TAB_FILTER_TYPES = {
    All: 0,
    Accepted: 1,
    Pending: 2,
    Canceled: 3
};
const RESERVATION_TYPES = {
    my: 'user',
    place: 'business'
};
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_ACTIVE_TYPE = TAB_FILTER_TYPES.Accepted;
const RESERVATION_SORTING = {
    [TAB_FILTER_TYPES.All]: 'date_start asc',
    [TAB_FILTER_TYPES.Accepted]: 'date_start asc',
    [TAB_FILTER_TYPES.Pending]: 'date_start asc',
    [TAB_FILTER_TYPES.Canceled]: 'date_start desc'
};

const withWindowsTrait = {
    Trait: ViewWithWindows,
    options: {
        windowMap: [
            {
                cls: ReservationCalendarWindow,
                trigger() {
                    this.header.currentView.trigger('calendar:click');
                }
            }
        ],
    }
};

const ReservationsPage = Page.extendWithTraits([withWindowsTrait], {
    attributes: { id: `reservations-page` },
    options: {
        reservationCollection: Optional,
        pageSize: Optional,
        placeId: Optional,
        userId: Optional
    },

    show(options) {
        const pageType = this.viewModel.get('reservationType');
        const isUserIdChanged = options && options.userId && this.options.userId &&
            options.userId != this.options.userId;
        const isPlaceIdChanged = options && options.placeId && this.options.placeId &&
            options.placeId != this.options.placeId;

        _.extend(this.options, _.pick(options, ['placeId', 'userId']));
        this._initData(options);

        if (isUserIdChanged && pageType === RESERVATION_TYPES.my ||
            isPlaceIdChanged && pageType === RESERVATION_TYPES.place) {
            this._setActiveTab(DEFAULT_ACTIVE_TYPE);
            this._fetchInitial(new Date, { reset: true });
            return Page.prototype.show.apply(this, arguments);
        }

        const ret = Page.prototype.show.apply(this, arguments);

        if ((Date.now() - this.latestLoadTime) >= 300000) {
            const date = this.isRendered && this.content && this.content.currentView.getDateFromViewportMostFittedChild();
            this._fetchInitial(date || new Date, { reset: true, jumpToDate: false });
        }

        return ret;
    },

    getPlaceId() {
        return this.options.placeId;
    },

    template,

    className: 'reservations-list-page',

    regions: {
        header: '[data-js-header]',
        content: '[data-js-content]',
        footer: '[data-js-footer]',
    },

    initialize() {
        const vm_tab_type = DEFAULT_ACTIVE_TYPE;
        const sortBy = RESERVATION_SORTING[vm_tab_type];

        this.filtersModel = new Model({
            status: this._getStatusesByType(DEFAULT_ACTIVE_TYPE),
            includeTaskId: true,
            pageSize: DEFAULT_PAGE_SIZE,
            placeId: null
        });

        this.viewModel = new ViewModel({
            filtersModel: this.filtersModel,
            vm_tab_type,
            sortBy
        });

        this.reservationCollection = this.options.reservationCollection || new ReservationInfiniteCollection([], {
            pageSize: this.options.pageSize || DEFAULT_PAGE_SIZE,
            limit: 7
        });
        this.reservationCollection.comparator = sortBy;

        this._syncReservation = (id, options) => {
            _.defaults(options || (options = {}));

            const reservationModel = Number(id) && this.reservationCollection.get(id);

            if (reservationModel) {
                const status = options.status;
                const activeStatuses = this.filtersModel.get('status');

                if (Number(status) && reservationModel.get('status') != status &&
                    activeStatuses.length && activeStatuses.indexOf(status) === -1) {
                        this.reservationCollection.remove(reservationModel);
                } else {
                    (new ReservationModel({ id: Number(id) }))
                        .fetch({
                            success(model, data) {
                                reservationModel.set(data);
                            }
                        });
                }
            }
        };

        this.listenTo(notificationCollection, 'add', notification => {
            if (notification.get('isNewComment')) {
                const data = notification.get('data');
                if (data) {
                    this._syncReservation(data.reservation_id, { status: data.reservation_status });
                }
            }
        });

        this.listenTo(app.controller, 'reservation:change', this._syncReservation);

        this.listenTo(this.reservationCollection, 'sync error', () => {
            this.latestLoadTime = Date.now();
        });
    },

    onDestroy() {
        this.stopListening(this.viewModel);
    },

    onAttach() {
        const type = this.viewModel.get('vm_tab_type');
        const title = this.viewModel.get('reservationType') === RESERVATION_TYPES.my ?
            'My reservations' : 'Place reservations';
        const headerView = new HeaderView({
            leftButtons: ['back'],
            title,
            rightButtons: [{
                id: 'calendar'
            }],
            tabs: [{
                id: 'accepted',
                title: 'ACCEPTED',
                type: TAB_FILTER_TYPES.Accepted,
                active: type === TAB_FILTER_TYPES.Accepted
            }, {
                id: 'pending',
                title: 'PENDING',
                type: TAB_FILTER_TYPES.Pending,
                active: type === TAB_FILTER_TYPES.Pending
            }, {
                id: 'canceled',
                title: 'CANCELED',
                type: TAB_FILTER_TYPES.Canceled,
                active: type === TAB_FILTER_TYPES.Canceled
            }]
        });
        this.header.show(headerView);

        const contentWidget = new ReservationInfiniteCollectionView({
            collection: this.reservationCollection,
            reservationCollection: this.reservationCollection,
            childViewType: this.viewModel.get('reservationType'),
            collectionViewClass: ReservationsDayCView,
            collectionViewOptions: {
                className: 'reservations-content-widget'
            },
            parentViewModel: this.viewModel,
            filtersModel: this.filtersModel
        });

        contentWidget.once('before:show', () => {
            const containerMonitor = scrollmonitor.createContainer(this.content.el);
            _.extend(contentWidget.options, { containerMonitor });

            this._createContainerMonitorInterval(containerMonitor);
        });

        this.content.show(contentWidget);

        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.listenTo(headerView, 'calendar:click', () => {
            const date = contentWidget.getDateFromViewportMostFittedChild();
            new ReservationCalendarWindow(this.filtersModel.toJSON())
                .show({ calendarInitialDate: date })
                .then(selectedDate => {
                if (selectedDate) {
                    this._fetchInitial(selectedDate, { reset: true, wait: true });
                }
            });
        });
        this.listenTo(headerView, 'tab:click', (parent, child) => {
            if (this.viewModel.get('disabled') || this.viewModel.get('isLoading')) {
                return;
            }
            const oldType = this.viewModel.get('vm_tab_type');
            let type = child.model.get('type');

            if (oldType === type) {
                type = TAB_FILTER_TYPES.All;
            }

            this.viewModel.set('vm_tab_type', type);
            this._fetchInitial(new Date, { reset: true, wait: true })
                .then(() => {
                    parent.resetTabs();
                    if (type !== TAB_FILTER_TYPES.All) {
                        child.setActive();
                    }
                }, () => {
                    this.viewModel.set('vm_tab_type', oldType);
                });
        });

        this.listenTo(this.viewModel, 'change:vm_tab_type', (model, type) => {
            this.filtersModel.set('status', this._getStatusesByType(type));
            this.viewModel.set('sortBy', RESERVATION_SORTING[type]);
        });
        this.listenTo(this.viewModel, 'change:disabled', (model, val) => {
            const disabled = this.viewModel.get('disabled') || this.viewModel.get('isLoading');
            headerView.$el.find('.header-tab button').prop('disabled', disabled);
        });
        this.listenTo(this.viewModel, 'change:isLoading', (model, isLoading) => {
            isLoading ? this.showLoading(500) : this.hideLoading();
            const disabled = this.viewModel.get('disabled') || this.viewModel.get('isLoading');
            headerView.$el.find('.header-tab button').prop('disabled', disabled);
        });
        this.listenTo(this.viewModel, 'show:place:search', () => {
            app.controller.goToPlaceSearchPage({ favorites: false });
        });
        this.listenTo(this.viewModel, 'change:sortBy', () => {
            this.reservationCollection.comparator = this.viewModel.get('sortBy');
        });
        this.listenTo(LoginedUserHandler, 'change:user_def_work_place_id', () => {
            this.options.placeId = LoginedUserHandler.get_default_work_place_id();
            this.filtersModel.set('placeId', this.options.placeId);
            this._fetchInitial();
        });


        this._fetchInitial();
    },

    _fetchInitial(date = new Date, options) {
        _.defaults(options || (options = {}));

        this.viewModel.set({ isLoading: true });
        const finishLoading = (() => this.viewModel.set({ isLoading: false })).bind(this);

        return this.reservationCollection.fetchInitial(_.extend({
            referenceDate: B2Cjs.datetimeJSToServerLocal(date),
            jumpToDate: date
        }, this.filtersModel.toJSON(), options))
            .then(finishLoading, finishLoading);
    },

    _setActiveTab(value = DEFAULT_ACTIVE_TYPE) {
        const tab = this.header.hasView() &&
            this.header.currentView.options.tabs.find(tab => tab.type === value);

        if (tab) {
            this.viewModel.set({
                vm_tab_type: value
            });

            this.header.currentView.tabs.currentView.resetTabs();
            const tabModel = this.header.currentView.tabs.currentView.collection.get(tab.id);

            if (tabModel) {
                tabModel.set({ active: true });
                const tabView = this.header.currentView.tabs.currentView.children.findByModel(tabModel);

                if (tabView) {
                    tabView.setActive();
                }
            }
        }
    },

    _getStatusesByType(type) {
        switch (type) {
            case TAB_FILTER_TYPES.Accepted:
                return [ReservationModel.Status.Confirmed];
            case TAB_FILTER_TYPES.Pending:
                return [ReservationModel.Status.New,
                    ReservationModel.Status.BusinessSuggestedChanges,
                    ReservationModel.Status.ClientSuggestedChanges];
            case TAB_FILTER_TYPES.Canceled:
                return [ReservationModel.Status.CancelledByClient,
                    ReservationModel.Status.RejectedByBusiness];
            default:
                return [];
        }
    },

    _initData() {
        const isMyReservationPage = this.options.reservationType === RESERVATION_TYPES.my;
        this.viewModel.set({
            refreshPromise: Promise.resolve(),
            pages: [],
            isLoading: false,
            isRefreshing: false,
            reservationType: isMyReservationPage ? RESERVATION_TYPES.my : RESERVATION_TYPES.place
        });
        const options = _.extend({
            placeId: this.options.placeId || null,
            userId: isMyReservationPage && this.options.userId ? this.options.userId : null,
        }, isMyReservationPage ? { includePlace: true } : { includeUser: true });

        this.filtersModel.set(options);
    },

    _createContainerMonitorInterval(containerMonitor) {
        const self = this;

        this._containerMonitorUpdateInterval = setInterval((containerMonitor) => {
            if (!self.isActive) {
                return false;
            }

            containerMonitor.watchers.forEach(watcher => {
                const initialOffset = watcher.initialOffset;

                const top = containerMonitor.item.getBoundingClientRect().top + _.result(initialOffset, 'top');
                watcher.offsets = {
                    top,
                    bottom: -top + _.result(initialOffset, 'bottom')
                };
                watcher.recalculateLocation();
            })
        }, 16, containerMonitor);
    },
});

export default ReservationsPage;
