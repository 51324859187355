import React, { useEffect, useState, useCallback } from 'react';
import { connect } from "react-redux";

import withStore from "components/withStore";
import Spinner from "components/UI/Spinner/Spinner";
import MenuItemEditor from "components/menu/MenuItemEditor/MenuItemEditor";
import Footer from "components/UI/Footer/Footer";
import * as MenuAPI from 'utils/api/menu';
import { showError } from 'windows/Modal/Info';
import { getMenuCurrency, getMenuIsFood, getMenuSelectedCategory } from "store/menu/selectors";
import { showLoading, hideLoading } from 'windows/Modal/Loading';
import { removeItem, upsertItem, uploadMenuItemSucceed } from "store/menu/actions";
import {
    getFieldErrorsFromJqXHR,
    getInitialPreparedMenuItem,
    getPreparedDataToUpload,
    getPreparedMenuItemDetail
} from "containers/menu/MenuItemHelpers";
import { UnlistedCategory } from "utils/api/menu";
import { WindowContent, WindowFooter, WindowHeader } from "components/window/Window";
import Header from "components/UI/Header/Header";
import MenuBrowserWindow from "windows/MenuBrowser/MenuBrowser";
import KeyboardPadding from 'components/KeyboardPadding';
import {BackButton} from "components/UI/Button/Button";

const MenuItemEditorContainer = props => {
    const {
        placeId, menuElementId, item, uploadMenuItemSucceed, upsertItem, removeItem,
        closeWindow, currency, isFood, selectedCategory
    } = props;

    const [errors, setErrors] = useState({});
    const [initItem, setInitItem] = useState(item);
    const [preparedItem, setPreparedItem] = useState();
    const [loading, setLoading] = useState();
    const [loadingPhoto, setLoadingPhoto] = useState();

    const setItemParamByKey = useCallback((key, val) => {
        setPreparedItem(prev => ({
            ...prev,
            [key]: val
        }));

        if (errors[key]) {
            setErrors((prev) => ({
                ...prev,
                [key]: null
            }));
        }
    }, [setPreparedItem, setErrors, errors]);

    const setPhoto = useCallback(({ photo, photo_thumb }) => {
        setPreparedItem(prev => ({
            ...prev,
            photo,
            photo_thumb
        }));
    });

    const updateItemFromServer = useCallback(({ placeId, id }) => {
        setLoading(true);
        MenuAPI.loadItem({ placeId, id, fetchOptions: { ['include_categories']: 1 } })
            .then(data => {
                setInitItem(data);
                setPreparedItem(getPreparedMenuItemDetail(data));
                setLoading(false);
            })
            .catch(e => {
                showError(e instanceof Error ? e.message : e);
                closeWindow();
            });
    }, [closeWindow, showError, getPreparedMenuItemDetail]);

    const uploadItem = useCallback(() => {
        showLoading();

        const categoryIds = preparedItem.categories.map(c => c.id);
        const preparedData = getPreparedDataToUpload(preparedItem, initItem);
        const data = JSON.stringify(preparedData);

        MenuAPI.uploadItem({ placeId, id: preparedItem.id, data })
            .then((data) => {
                uploadMenuItemSucceed(data);
                upsertItem(data, categoryIds);

                hideLoading();
                closeWindow(data);
            })
            .catch((e) => {
                hideLoading();

                if (e instanceof Error && e.jqXHR && e.jqXHR.status === 422) {
                    setErrors((prev) => ({
                        ...prev,
                        ...getFieldErrorsFromJqXHR(e.jqXHR)
                    }));
                    showError('The form contains errors. Please correct and try again.', undefined, undefined,
                        { dontSendToGaAndSentry: true });
                } else {
                    showError(e instanceof Error ? e.message : e);
                }
            });

    }, [placeId, preparedItem, initItem, closeWindow, selectedCategory, getPreparedDataToUpload]);

    useEffect(() => {
        const isNew = !Boolean(menuElementId);

        if (item && item.id === menuElementId) {
            setInitItem(item);
            setPreparedItem(getPreparedMenuItemDetail(item));
        } else if (menuElementId && placeId) {
            updateItemFromServer({ placeId, id: menuElementId });
        } else if (isNew) {
            const item = getInitialPreparedMenuItem({ currency, isFood, selectedCategory });
            setPreparedItem(item);
        }
    }, [item, menuElementId, placeId]);

    const categoriesEditHandler = useCallback(() => {
        const browser = new MenuBrowserWindow({
            placeId,
            displayCategories: MenuBrowserWindow.DisplayCategories.All,
            displayItems: false,
            multiple: true,
            minimumSelection: 0,
            title: 'Choose categories',
            message: 'Navigate through categories by clicking them.',
            selected: preparedItem.categories,
            select: MenuBrowserWindow.Select.Category,
        });

        browser.show()
            .then(result => {
                if (result) {
                    setItemParamByKey('categories', result);
                } else {
                    console.log('Nothing selected or canceled');
                }
            });
    }, [placeId, preparedItem]);

    const additionsEditHandler = useCallback(() => {
        const browser = new MenuBrowserWindow({
            placeId,
            displayCategories: MenuBrowserWindow.DisplayCategories.Additions,
            displayItems: false,
            multiple: true,
            minimumSelection: 0,
            title: 'Choose add-on categories',
            message: 'Navigate through categories by clicking them. Only items from the selected category will be available, without child categories.',
            selected: preparedItem.additions,
            select: MenuBrowserWindow.Select.Category,
        });

        browser.show()
            .then(result => {
                if (result) {
                    setItemParamByKey('additions', result);
                } else {
                    console.log('Nothing selected or canceled');
                }
            });
    }, [placeId, preparedItem]);

    let content = null;
    if (loading || !preparedItem) {
        content = <Spinner className='fit-to-container'/>;
    } else {
        content = <MenuItemEditor {...preparedItem} {...{
            setPhoto, setLoadingPhoto, loadingPhoto,
            setItemParamByKey, categoriesEditHandler, additionsEditHandler, setErrors, errors
        }} imageUploadType='mi'/>
    }

    const hasError = _(errors).filter(Boolean).length;

    const disabled = loading || loadingPhoto || hasError;
    const buttons = [{
        text: 'CANCEL',
        id: 'cancel',
        onClick: () => closeWindow()
    }, {
        text: menuElementId ? 'SAVE' : 'ADD',
        id: 'continue',
        disabled,
        onClick: uploadItem
    }];

    return <>
        <WindowHeader>
            <Header>
                <BackButton onClick={() => closeWindow()}/>
                <h1 className="title">{menuElementId ? 'Edit menu element': 'Add menu element'}</h1>
            </Header>
        </WindowHeader>
        <WindowContent>{content}</WindowContent>
        <WindowFooter>
            <Footer buttons={buttons}/>
        </WindowFooter>
        <KeyboardPadding/>
    </>;
};

const mapStateToProps = state => {
    return {
        currency: getMenuCurrency(state),
        isFood: getMenuIsFood(state),
        selectedCategory: getMenuSelectedCategory(state)
    };
};

export default withStore(connect(mapStateToProps, {
    uploadMenuItemSucceed, upsertItem, removeItem
})(MenuItemEditorContainer));
