import React from 'react';

import { Button } from 'components/UI/Button/Button';
import './MenuItemPropertyInput.scss';

const MenuItemPropertyInput = (props) => {
    const { name, options = [], onChange } = props;

    const onChangeName = (e) => {
        onChange({ name: e.currentTarget.value, options });
    };
    const onAddOptionClick = () => {
        onChange({ name, options: [...options, ''] });
    };
    const onChangeOption = (index, value) => {
        const updatedOptions = options.map((o, i) => (i === index ? value : o));
        onChange({ name, options: updatedOptions });
    };
    const onRemoveOptionClick = (index) => {
        const updatedOptions = options.filter((o, i) => i !== index);
        onChange({ name, options: updatedOptions });
    };
    const onRemoveProperty = () => {
        onChange(null);
    };

    const optionsEl =
        options.length === 0
            ? null
            : options.map((option, i) => (
                <div key={i} className="option">
                    <input
                        type="text"
                        placeholder="Option"
                        value={option}
                        onChange={(e) => onChangeOption(i, e.currentTarget.value)}
                    />
                    <span className="remove-wrapper">
                        {options.length > 2 && (
                            <i onClick={() => onRemoveOptionClick(i)} className="ion-ios-trash-outline" />
                        )}
                    </span>
                </div>
            ));

    return (
        <div className="property-input-group">
            <div className="name">
                <input type="text" className="name" placeholder="Name" value={name} onChange={onChangeName} />
                <span className="remove-wrapper">
                    <i onClick={onRemoveProperty} className="ion-ios-trash-outline" />
                </span>
            </div>
            <div className="options-wrapper">
                {optionsEl && <div className="options">{optionsEl}</div>}
                <Button className="btn-medium-wide" wrapChildren={false} onClick={onAddOptionClick}>
                    Add value
                </Button>
            </div>
        </div>
    );
};

export default MenuItemPropertyInput;
