import { Optional, ItemView, Backbone } from '@b2cmessenger/backbone';

import Window from 'windows/Window';
import FooterView from 'widgets/Footer/Footer';

import template from './Modal.jade';
import contentTemplate from './Content.jade';
import './Modal.scss';

import getNextPrettyColor from 'utils/randomPrettyColor';

const Intent = {
    Positive: 1,
    Negative: 2,
    Warning: 3,
    Active: 4
};

/**
 Modal window with black background over app content
 */
const Modal = Window.extend({
    options: {
        content: Optional,
        model: Optional,
        prettyColor: Optional,
        intent: Optional,
        buttons: Optional
    },
    scope: 'global',
    windowName: "modal-window",
    className: "modal-window",

    template,

    regions: {
        header: '[data-js-header]',
        content: '[data-js-content]',
        footer: '[data-js-footer]',
    },

    isModalWindow: true,

    initialize() {
        this.isModalWindow = true;
    },

    onRender() {
        const buttons = _.filter(this.options.buttons, b => b.id);
        if (!buttons.length) {
            buttons.push({
                id: "close",
                text: "CLOSE",
                icn: "empty",
                value: true
            });
        }

        const footerView = new FooterView({ buttons });

        _.each(buttons, b => {
            this.listenTo(footerView, `${b.id}:click`, (e) => {
                if (_.isFunction(b.onClick)) {
                    b.onClick.call(this, e);
                } else {
                    this.close(_.has(b, 'value') ? b.value : b.id);
                }
            });
        });

        this.footer.show(footerView);

        if (!this.options.content) {
            this.el.classList.remove('custom-content');
            this.content.show(new ItemView({
                model: this.model,
                template: contentTemplate,
                className: "widget my-id-content-widget",
            }));
        } else {
            this.el.classList.add('custom-content');
            this.content.show(this.options.content);
        }

        if (this.options.intent) {
            switch (this.options.intent) {
                case Intent.Positive:
                    this.el.classList.add('intent-positive');
                    break;
                case Intent.Negative:
                    this.el.classList.add('intent-negative');
                    break;
                case Intent.Warning:
                    this.el.classList.add('intent-warning');
                    break;
                case Intent.Active:
                    this.el.classList.add('intent-active');
                    break;
                default:
                    this.el.classList.remove('intent-positive', 'intent-negative', 'intent-warning', 'intent-active');
            }
        } else {
            this.el.classList.remove('intent-positive', 'intent-negative', 'intent-warning', 'intent-active');
        }

        if (this.options.prettyColor) {
            this.el.classList.add('pretty-color');
            this.content.el.style['border-color'] = getNextPrettyColor(this.options.prettyColor);
        } else {
            this.el.classList.remove('pretty-color');
        }
    },

    onShow() {
        _.defer(() => {
            this.el.classList.add('overlay');
        });
    },

    _overlayPrevWindowOrPage() {
        const prevWindowOrPage = app.controller.getPreviosPageOrWindow(this);

        if (prevWindowOrPage) {
            const missingCids = _.difference(
                this._prevWindowOrPage && this._prevWindowOrPage.map(o => o.cid),
                prevWindowOrPage.map(o => o.cid)
            );

            if (missingCids.length) {
                _.each(missingCids, cid => {
                    const obj = app.controller.windowStack.find(o => o.cid == cid)
                        || app.controller.pageStack.find(o => o.cid == cid);

                    if (obj && !obj.isDestroyed) {
                        obj.$el.removeClass('overlayed');
                    }
                });
            }

            this._prevWindowOrPage = prevWindowOrPage.filter(obj => {
                return obj.windowName ?
                    app.controller.windowStack.indexOf(obj) < app.controller.windowStack.indexOf(this) : obj
            });

            _.each(this._prevWindowOrPage, obj => {
                obj.$el.addClass('overlayed');
            });
        }
    },

    _removeOverlayPrevWindowOrPage() {
        let prevWindowOrPage = app.controller.getPreviosPageOrWindow(this);

        if (prevWindowOrPage) {
            // Если есть еще модальные окна, удалить блюр только до уровня другого модального окна
            if (app.controller.windowStack.filter(obj => obj.isModalWindow).length > 1) {
                prevWindowOrPage = prevWindowOrPage.filter(obj => obj.windowName ?
                    app.controller.windowStack.indexOf(obj) < app.controller.windowStack.indexOf(this) : obj);
            }

            _.each(prevWindowOrPage, obj => {
                obj.$el.removeClass('overlayed');
            });
        }
    }
}, { Intent });

export default Modal;
