import { Required, CollectionView, Model, Collection, ViewModel } from '@b2cmessenger/backbone';

// @ts-ignore
import elementResizeDetectorFactory from 'element-resize-detector';

import MessagePhotoItemWidget from './MessagePhotoItem';
import MessageGalleryWindow from 'windows/MessageGallery/MessageGallery';
import './MessagePhotoCollection.scss';

/** @typedef {import('./MessagePhotoCollection')} MessagePhotoCollectionWidgetInterface */
/** @type {typeof import('./MessagePhotoCollection').properties} */
// @ts-ignore
const properties = CollectionView.properties;

/** @type {typeof import('./MessagePhotoCollection').options} */
// @ts-ignore
const options = CollectionView.options;

@options({
    collection: Required,
    idealAr: 16 / 9,
    padding: 10
})
@properties({
    childView: MessagePhotoItemWidget,
    className: 'widget message-photo-collection-widget',

    filter(child, index, collection) {
        return index < 4;
    },

    childEvents: {
        'click'(cw) {
            new MessageGalleryWindow({
                model: new Model({ photos: this.collection.toJSON() }),
                openPhotoWithId: cw && cw.model && !cw.model.get('isLastAndHasMore') ? cw.model.id : false
            }).show();
        }
    },
})
class MessagePhotoCollectionWidget extends CollectionView {
    initialize() {
        this.viewModel = new ViewModel({
            containerWidth: null
        });
    }

    onAttach(view) {
        if (!this._recalculateLayoutListener) {
            this._recalculateLayoutListener = _.debounce(this._recalculateLayout.bind(this), 1);
            this.listenTo(this.collection, 'update reset change:ar', this._recalculateLayoutListener);
        }

        this.listenTo(this.viewModel, 'change:containerWidth', this._recalculateLayoutListener);

        if (!this.erd) {
            this.erd = elementResizeDetectorFactory({
                strategy: 'scroll'
            });
            this.erd.listenTo(this.el, () => this.viewModel.set({
                containerWidth: getContentWidth(this.el)
            }));
        }

        this.viewModel.set({
            containerWidth: getContentWidth(this.el)
        });
    }

    onDestroy(view) {
        if (this._recalculateLayoutListener) {
            if (this.erd) {
                this.erd.removeListener(this.el, this._recalculateLayoutListener);
            }
            this.stopListening(this.collection, 'update reset change:ar', this._recalculateLayoutListener);
            this.stopListening(this.viewModel, 'change:containerWidth', this._recalculateLayoutListener);
            this._recalculateLayoutListener = null;
        }
    }

    _recalculateLayout() {
        /** @type {MessagePhotoCollectionWidgetInterface} */
        // @ts-ignore
        const self = this;
        this.$el.data('childrenCount', self.children.length);

        const padding = self.options.padding;
        const containerWidth = this.viewModel.get('containerWidth');

        if (self.children.length < 1) {

        } else if (self.children.length == 1) {
            self.children.findByIndex(0).viewModel.set({
                widthPercent: 100,
                leftMargin: null,
                topMargin: null,
                isLastAndHasMore: false
            });
            this.el.classList.remove('single-row', 'single-column', 'double-row', 'double-column');
            this.el.classList.add('single');
        } else if (self.children.length == 2) {
            const variants = [];
            {   //  variant 1: [] []
                const ar = self.children.reduce((w, c, i) => w + c.model.get('ar'), 0) *
                    (1 + padding / (containerWidth - padding));

                variants.push({
                    ar,
                    apply() {
                        self.children.each((c, i) => c.viewModel.set({
                            widthPercent: Math.floor(c.model.get('ar') / ar * 10000) / 100,
                            leftMargin: i > 0 && padding || 0,
                            topMargin: null,
                            isLastAndHasMore: false
                        }));
                        this.el.classList.remove('single', 'single-column', 'double-row', 'double-column');
                        this.el.classList.add('single-row');
                    }
                });
            }
            {   //  variant 2: []
                //             []
                let ar = 1 / self.children.reduce((h, c, i) => h + 1 / c.model.get('ar'), 0);
                ar = containerWidth / (containerWidth / ar + padding);

                variants.push({
                    ar,
                    apply() {
                        self.children.each((c, i) => c.viewModel.set({
                            widthPercent: 100,
                            leftMargin: null,
                            topMargin: i > 0 && padding || 0,
                            isLastAndHasMore: false
                        }));
                        this.el.classList.remove('single', 'single-row', 'double-row', 'double-column');
                        this.el.classList.add('single-column');
                    }
                });
            }
            this._applyBestVariant(variants);

        } else {
            const variants = [];
            {   //  variant 1: [] [] []
                const paddingsCount = self.children.length - 1;
                const ar = self.children.reduce((w, c, i) => w + c.model.get('ar'), 0) *
                    (1 + paddingsCount * padding / (containerWidth - paddingsCount * padding));

                variants.push({
                    ar,
                    apply() {
                        self.children.each((c, i) => c.viewModel.set({
                            widthPercent: Math.floor(c.model.get('ar') / ar * 10000) / 100,
                            leftMargin: i > 0 && padding || 0,
                            topMargin: null,
                            isLastAndHasMore: i == self.children.length - 1 &&
                                this.collection.length > self.children.length && this.collection.length - self.children.length
                        }));
                        this.el.classList.remove('single', 'single-column', 'double-row', 'double-column');
                        this.el.classList.add('single-row');
                    }
                });
            }
            {   //  variant 2: []
                //             []
                //             []
                const paddingsCount = self.children.length - 1;
                let ar = 1 / self.children.reduce((h, c, i) => h + 1 / c.model.get('ar'), 0);
                ar = containerWidth / (containerWidth / ar + paddingsCount * padding);

                variants.push({
                    ar,
                    apply() {
                        self.children.each((c, i) => c.viewModel.set({
                            widthPercent: 100,
                            leftMargin: null,
                            topMargin: i > 0 && padding || 0,
                            isLastAndHasMore: i == self.children.length - 1 &&
                                this.collection.length > self.children.length && this.collection.length - self.children.length
                        }));
                        this.el.classList.remove('single', 'single-row', 'double-row', 'double-column');
                        this.el.classList.add('single-column');
                    }
                });
            }
            {   //  variant 3: [   ] []
                //             [   ] []
                const paddingsCount = self.children.length - 2;
                const c0 = self.children.findByIndex(0);

                const leftColumnAr = c0.model.get('ar');
                const rightColumnAr = 1 / self.children.reduce((h, c, i) => h + (i && (1 / c.model.get('ar'))), 0);

                const ar = containerWidth * (leftColumnAr + rightColumnAr) /
                    (containerWidth + rightColumnAr * padding * paddingsCount - padding);

                variants.push({
                    ar,
                    apply() {
                        c0.viewModel.set({
                            widthPercent: Math.floor(leftColumnAr / ar * 100000) / 1000,
                            leftMargin: null,
                            topMargin: null,
                            isLastAndHasMore: false
                        });
                        self.children.each((c, i) => i > 0 && c.viewModel.set({
                            widthPercent: Math.floor((1 - padding / containerWidth - leftColumnAr / ar) * 100000) / 1000,
                            leftMargin: padding,
                            topMargin: i > 1 && padding || 0,
                            isLastAndHasMore: i == self.children.length - 1 &&
                                this.collection.length > self.children.length && this.collection.length - self.children.length
                        }));
                        this.el.classList.remove('single', 'single-row', 'single-column', 'double-row');
                        this.el.classList.add('double-column');
                    }
                });
            }
            {   //  variant 4: [     ]
                //             [ ] [ ]
                const paddingsCount = self.children.length - 2;
                const c0 = self.children.findByIndex(0);

                const bottomRowAr = self.children.reduce((w, c, i) => w + (i && c.model.get('ar')), 0) *
                    (1 + paddingsCount * padding / (containerWidth - paddingsCount * padding));

                const ar = 1 / (1 / c0.model.get('ar') + 1 / bottomRowAr);

                variants.push({
                    ar,
                    apply() {
                        c0.viewModel.set({
                            widthPercent: 100,
                            leftMargin: null,
                            topMargin: null,
                            isLastAndHasMore: false
                        });
                        self.children.each((c, i) => i != 0 ? c.viewModel.set({
                            widthPercent: Math.floor(c.model.get('ar') / bottomRowAr * 10000) / 100,
                            leftMargin: i > 1 && padding || 0,
                            topMargin: padding,
                            isLastAndHasMore: i == self.children.length - 1 &&
                                this.collection.length > self.children.length && this.collection.length - self.children.length
                        }) : null);
                        this.el.classList.remove('single', 'single-row', 'single-column', 'double-column');
                        this.el.classList.add('double-row');
                    }
                });
            }

            this._applyBestVariant(variants);

            if (this.collection.length >= 4) {
                this.el.classList.add('has-more');
            } else {
                this.el.classList.remove('has-more');
            }
        }
    }

    _applyBestVariant(variants) {
        /** @type {MessagePhotoCollectionWidgetInterface} */
        // @ts-ignore
        const self = this;
        const idealAr = self.options.idealAr || 16 / 9;
        const idealArLn = Math.log(idealAr);
        const best = _.reduce(variants,
            (best, v) => {
                if (Math.abs(Math.log(v.ar) - idealArLn) <= Math.abs(Math.log(best.ar) - idealArLn)) {
                    return v;
                }
                return best;
            },
            {
                ar: 0,
                apply: _.noop
            }
        );

        if (_.isFunction(best.apply)) {
            best.apply.apply(this);
        }
    }
}

function getContentWidth(element) {
    var styles = getComputedStyle(element)

    return element.clientWidth - parseFloat(styles.paddingLeft) - parseFloat(styles.paddingRight);
}

export { MessagePhotoCollectionWidget as default };
