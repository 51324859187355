import { Optional, ViewModel } from '@b2cmessenger/backbone';
import Window from 'windows/Window';
import HeaderView from 'widgets/Header/Header';
import './PhoneChooser.scss';
import PhoneCollection from "models/PhoneCollection";
import PhoneEditorModal from "windows/Modal/Phone/PhoneEditor";
import PhoneVerifyModal from "windows/Modal/Phone/PhoneVerify";
import PhoneCollectionView from "windows/Phone/PhoneChooserCollectionView";
import ConfirmModalWindow from "windows/Modal/Confirm";

const PhoneChooser = Window.extend({
    options: {
        collection: Optional,
        selectedPhoneId: Optional,
        title: Optional,
        isEditable: Optional
    },

    windowName: "phone-chooser-window",
    className: "phone-chooser-window",

    initialize() {
        this.collection = this.options.collection || new PhoneCollection();
        this.viewModel = new ViewModel({
            selectedPhoneId: this.options.selectedPhoneId,
            isEditable: this.options.isEditable
        });
    },

    onShow() {
        if (this.collection.length === 0) {
            this.showLoading();
            this.viewModel.set('disabled', true);
            this.collection.fetch().always(() => {
                this.hideLoading();
                this.viewModel.set('disabled', false);
            });
        }
    },

    onRender() {
        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: this.options.title || 'Select phone',
            rightButtons: [{ text: 'Add', id: 'add' }]
        });
        this.listenTo(headerView, 'back:click', view => this.cancel());
        this.listenTo(headerView, 'add:click', () => this._addPhone())
        this.header.show(headerView);

        let contentWidget = new PhoneCollectionView(_.extend(this.options, {
            collection: this.collection,
            parentViewModel: this.viewModel
        }));
        this.listenTo(contentWidget, 'childview:selected', view => {
            this.viewModel.set('selectedPhoneId', view.model.get('id'));
            this.close(view.model)
        });
        this.listenTo(contentWidget, 'childview:add', () => this._addPhone());
        this.listenTo(contentWidget, 'childview:make:default', (view) => this._makeDefaultPhone(view.model));
        this.listenTo(contentWidget, 'childview:confirm', (view) => this._confirmPhone(view.model));
        this.listenTo(contentWidget, 'childview:remove', (view) => this._removePhone(view.model));

        this.content.show(contentWidget);
    },

    _makeDefaultPhone(model) {
        const prevDefaultModel = model.collection.findWhere('default');

        this.viewModel.set({ disabled: true });
        model.save(null, {
            makeDefault: true,
            success: () => {
                this.viewModel.set({ disabled: false });
                if (prevDefaultModel) {
                    prevDefaultModel.set('default', false);
                }
            },
            error: () => {
                this.viewModel.set({ disabled: false });
            }
        });
    },

    _removePhone(model) {
        new ConfirmModalWindow({
            title: `Do you really want to delete ${model.get('phone')}?`
        }).show().then(confirm => {
            if (confirm) {
                this.viewModel.set({ disabled: true });
                model.destroy({
                    wait: true,
                    success: () => {
                        this.viewModel.set({ disabled: false });
                    },
                    error: (m, jqXHR) => {
                        this.showError(jqXHR);
                        this.viewModel.set({ disabled: false });
                    }
                });
            }
        });
    },

    _confirmPhone(model) {
        new PhoneVerifyModal({ model }).show();
    },

    _addPhone() {
        const phoneEditorModal = new PhoneEditorModal({
            collection: this.collection,
        });

        phoneEditorModal.show()
            .then((model) => {
                if (model) {
                    const phoneVerifyModal = new PhoneVerifyModal({
                        model: model
                    });

                    phoneVerifyModal.show()
                        .then((model) => {
                            if (model && model.get('c_isVerified')) {
                                if (!this.options.isEditable) {
                                    this.close(model);
                                }
                            } else if (!this.options.isEditable) {
                                this.showError('No verified phone selected', undefined, undefined,
                                    { dontSendToGaAndSentry: true });
                            }
                        });
                }
            });
    },

    cancel() {
        this.close();
    }
});

export default PhoneChooser;
