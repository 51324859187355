import { ItemView } from '@b2cmessenger/backbone';
import Modal from './Modal';
import FooterView from 'widgets/Footer/Footer';
import ResetPasswordWindow from 'windows/ResetPassword/ResetPassword.js';

import template from './PasswordReset.jade';
import './PasswordReset.scss';

export default Modal.extend({
    windowName: "reset-password-modal-window",
    className: "modal-window reset-password-modal-window green-border",
    ui: {
        btnResetPassword: '[data-js-btn-reset-password]'
    },
    events: {
        'click @ui.btnResetPassword': 'showResetPasswordWindow'
    },
    onRender() {
        let footerView = new FooterView({
            buttons: [{
                id: "close",
                text: "CANCEL",
                icn: "empty"
            }]
        });

        this.listenTo(footerView, 'close:click', view => this.close(true));
        this.footer.show(footerView);

        this.content.show(new (ItemView.extend({
            template: template,
            className: "widget password-reset-modal-content-widget"
        }))({
            model: this.model
        }));
    },
    showResetPasswordWindow() {
        this.close();
        new ResetPasswordWindow({
            email: this.options.email
        }).show();
    },

    cancel() {
        this.close(true);
    }
});
