import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import ReactPage from 'pages/ReactPage';
import { connect } from 'react-redux';
import './MenuOrders.scss';

import withStore from 'components/withStore';
import { loadOrders, changeOrder } from 'utils/api/menu';
import { showError } from 'windows/Modal/Info';
import Page, { PageContent, PageHeader } from 'components/page/Page';
import { BackButton, MenuButton } from 'components/UI/Button/Button';
import HeaderWithTabs from 'components/UI/Header/HeaderWithTabs';
import Spinner from 'components/UI/Spinner/Spinner';
import MenuPlaceOrders from 'components/menu/MenuPlaceOrder/MenuPlaceOrders';
import { MenuListEmptyItem } from 'components/menu/MenuList/MenuList';
import { withScrollContainer } from 'scrollmonitor-hooks';
import usePagination from 'hooks/usePagination';
import './PlaceMenuOrdersPage.scss';
import MenuOrderStatusChangeWindow from 'components/window/MenuOrderStatusChange/MenuOrderStatusChange';
import MenuOrderWindow, { MenuOrderWindowContentType } from 'components/window/MenuOrder/MenuOrder';
import { OrderStatus } from 'components/menu/MenuPlaceOrder/MenuPlaceOrder';
import { getMenuSections, getMenuTablesAsArray } from 'store/menu/sections/selectors';
import InputGroup, { RadioInput } from 'components/UI/InputGroup/InputGroup';
import {
    getMenuError,
    getMenuInfoLoading,
    getMenuIsWaiter,
    getMenuPlaceId,
    getMenuWaitersAsArray,
} from 'store/menu/selectors';
import MenuItemError from 'components/menu/MenuItemDetail/MenuItemError';
import Tabs, { Tab } from 'components/UI/Tabs/Tabs';
// eslint-disable-next-line no-unused-vars
import Scrollparent from 'scrollparent';
import Select from 'components/UI/Select';
import InlineUser from 'components/UI/User/InlineUser';
import UserSelectWindow from 'components/window/UserSelect/UserSelect';
import { getPlaceMenuOrdersAssignedToMeSortedByCreatedAt } from 'store/menu/orders/selectors';
import { onEditOrder, upsertOrder } from 'store/menu/orders/actions';
import useInitializeMenu from 'hooks/useInitializeMenu';
import { getLoginedUser } from 'store/app/selectors';

export const PlaceMenuOrdersHeaderTabIdsMap = {
    All: 'all',
    My: 'my',
};

const HeaderTabs = [
    { id: PlaceMenuOrdersHeaderTabIdsMap.All, title: 'All' },
    { id: PlaceMenuOrdersHeaderTabIdsMap.My, title: 'My' },
];

export const PlaceMenuOrdersContentTabIdsMap = {
    All: '',
    WaitingForConfirm: 'confirmation',
    Confirmed: 'confirmed',
    Completed: 'completed',
    Canceled: 'canceled',
};
const ContentTabs = [
    {
        id: PlaceMenuOrdersContentTabIdsMap.All,
        title: 'All',
        status: [OrderStatus.Created, OrderStatus.Confirmed, OrderStatus.Done],
    },
    {
        id: PlaceMenuOrdersContentTabIdsMap.WaitingForConfirm,
        title: 'Waiting for confirm',
        status: [OrderStatus.Created],
    },
    { id: PlaceMenuOrdersContentTabIdsMap.Confirmed, title: 'Confirmed', status: [OrderStatus.Confirmed] },
    { id: PlaceMenuOrdersContentTabIdsMap.Completed, title: 'Completed', status: [OrderStatus.Done] },
    { id: PlaceMenuOrdersContentTabIdsMap.Canceled, title: 'Canceled', status: [OrderStatus.Canceled] },
];

// eslint-disable-next-line no-unused-vars
function getStatusValuesByTabId(id) {
    switch (id) {
        case HeaderTabs[0].id:
            return [1, 2];
        case HeaderTabs[1].id:
            return [3];
        case HeaderTabs[2].id:
            return [-1];
        default:
            return null;
    }
}

const PlaceMenuOrdersPageContent = withScrollContainer(
    React.forwardRef((props, ref) => <PageContent {...props} forwardedRef={ref} />)
);

const MenuOrdersTableList = ({ className: classNameFromProps, value, tableList, onTablesChange, type = 'radio' }) => {
    let optionsEl = null;
    const className = ['input-group', classNameFromProps].filter(Boolean).join(' ');

    switch (type) {
        case 'select':
            optionsEl = (
                <div className="options options-select">
                    <Select value={value} options={tableList} groupByOptGroup onChange={onTablesChange} />
                </div>
            );
            break;
        case 'radio':
        default:
            optionsEl = (
                <div className="options options-radio">
                    <RadioInput options={tableList} onClick={(e) => onTablesChange(e.currentTarget.value)} />
                </div>
            );
    }

    return (
        <InputGroup className={className}>
            <div className="children">Table</div>
            {optionsEl}
        </InputGroup>
    );
};

const MenuOrdersWaiterFilter = (props) => {
    const { waiter, onRemove = _.noop, editable } = props;
    const selectWaiter = props.selectWaiter && editable ? props.selectWaiter : _.noop;

    const content = waiter ? (
        <>
            <InlineUser onClick={selectWaiter} {...waiter} />
            {editable && <button className="ion-ios-close-outline remove" onClick={onRemove} />}
        </>
    ) : (
        <span>All</span>
    );

    return (
        <>
            <InputGroup className={props.className}>
                <div className="children">Waiter</div>
                <div className="waiter" onClick={waiter ? _.noop : selectWaiter}>
                    {content}
                </div>
            </InputGroup>
        </>
    );
};

export const initialOrderParams = {
    pageSize: 10,
    waiterId: null,
    sectionId: null,
    tableId: null,
    includeUser: true,
    includeWaiterRequests: true,
    sort: '-created_at',
};

const infinityPagination = {
    pageSize: 0,
    pageCount: 0,
    page: 0,
    totalCount: 0,
};

const getIsAssignedToMeActiveOrders = (activeTab, activeContentTab) => {
    return (
        activeTab === PlaceMenuOrdersHeaderTabIdsMap.My &&
        (activeContentTab === PlaceMenuOrdersContentTabIdsMap.WaitingForConfirm ||
            activeContentTab === PlaceMenuOrdersContentTabIdsMap.Confirmed)
    );
};

const getFilteredOrders = ({ orders, orderParams, activeContentTab }) => {
    const { status } = ContentTabs.find((t) => t.id === activeContentTab) || { status: [] };

    const filterCallback = (order) => {
        if (!order) {
            return;
        }

        const isSuitableStatus = Boolean(status.findIndex((s) => s === order.status) !== -1);
        let isSuitableForOrderParams = true;

        if (orderParams && orderParams.tableId && order.data.table_id !== orderParams.tableId) {
            isSuitableForOrderParams = false;
        }

        return isSuitableStatus && isSuitableForOrderParams;
    };

    return orders.filter(filterCallback);
};

const MenuOrdersContent = (props) => {
    const { placeId, error, loading: initialization, isActive, loginedUser } = props;
    const { waiters = [] } = props;
    const { ordersAssignedToMe, isWaiter, upsertOrder } = props;
    const {
        defaultActiveTab = isWaiter ? PlaceMenuOrdersHeaderTabIdsMap.My : PlaceMenuOrdersHeaderTabIdsMap.All,
        defaultContentTab = isWaiter
            ? PlaceMenuOrdersContentTabIdsMap.WaitingForConfirm
            : PlaceMenuOrdersContentTabIdsMap.All,
    } = props;

    const contentRef = useRef();

    const [orders, setOrders] = useState([]);
    const [activeTab, setActiveTab] = useState(defaultActiveTab);
    const [activeContentTab, setActiveContentTab] = useState(defaultContentTab);
    const [loading, setLoading] = useState(true);
    const [orderParams, setOrderParams] = useState(initialOrderParams);
    const [selectedWaiter, setSelectedWaiter] = useState();
    const [pagination, hasMore, setPagination] = usePagination();

    const isUsingOrdersFromStore = useMemo(() => {
        return isWaiter && getIsAssignedToMeActiveOrders(activeTab, activeContentTab);
    }, [activeTab, activeContentTab, isWaiter]);

    useEffect(() => {
        if (defaultActiveTab) {
            setActiveTab(defaultActiveTab);
        }
    }, [defaultActiveTab]);

    useEffect(() => {
        if (defaultContentTab) {
            setActiveContentTab(defaultContentTab);
        }
    }, [defaultContentTab]);

    const loadOrdersList = useCallback(
        async (options = {}) => {
            setLoading(true);

            try {
                // There is no need to load active orders for `Waiter`, because they are always relevant in the store
                if (isUsingOrdersFromStore || !isActive || !loginedUser) {
                    return;
                }

                if (options.reset) {
                    setOrders([]);
                }

                const { orders: ordersList, pagination: newPagination } = await props.loadOrders({
                    ...orderParams,
                    page: options.reset ? 1 : orderParams.page,
                });

                if (options.reset) {
                    setOrderParams((p) => ({ ...p, page: 1 }));
                }

                setPagination(newPagination);
                setOrders((prevOrders) => [...prevOrders, ...ordersList]);
            } catch (e) {
                setOrders([]);
                showError(e);
            } finally {
                setLoading(false);
            }
        },
        [orderParams, setOrderParams, setLoading, props.loadOrders, isUsingOrdersFromStore, isActive, loginedUser]
    );

    const loadMoreOrders = useCallback(
        (shouldLoadMore) => {
            if (!loading && shouldLoadMore && hasMore) {
                setOrderParams((params) => ({ ...params, page: pagination.page + 1 }));
            }
        },
        [loading, pagination, hasMore, setOrderParams, loadOrdersList]
    );

    // Set active orders for `Waiter` from store
    useEffect(() => {
        if (isUsingOrdersFromStore && ordersAssignedToMe) {
            setOrders(getFilteredOrders({ orders: ordersAssignedToMe, orderParams, activeContentTab }));
            setPagination(infinityPagination);
        }
    }, [ordersAssignedToMe, activeTab, activeContentTab, isWaiter, orderParams]);

    useEffect(() => {
        if (isActive && orderParams.placeId) {
            loadOrdersList({ reset: true });
        }
    }, [
        isActive,
        orderParams.placeId,
        orderParams.status,
        orderParams.sectionId,
        orderParams.tableId,
        orderParams.waiterId,
    ]);

    useEffect(() => {
        if (orderParams.placeId && orderParams.page > 1) {
            loadOrdersList();
        }
    }, [orderParams.page]);

    useEffect(() => {
        const { status, id } = ContentTabs.find((t) => t.id === activeContentTab) || { status: [] };

        setOrderParams((value) => ({
            ..._.omit(value, 'page'),
            placeId: props.placeId,
            status: [...status],
            sort: id === 'confirmation' ? 'created_at' : '-created_at',
        }));
    }, [props.placeId, activeContentTab]);

    useEffect(() => {
        if (_.isFunction(props.transformOrderParams) && isActive) {
            setOrderParams(props.transformOrderParams);
        }
    }, [props.transformOrderParams, isActive]);

    useEffect(() => {
        // TODO Разобраться с плагином, возможно заменить - выбивает ошибку при смене табов
        // const sp = contentRef.current && Scrollparent(contentRef.current);
        // sp && sp.scrollTo(0, 0);
        setOrderParams((value) => ({
            ...value,
            waiterId: activeTab === PlaceMenuOrdersHeaderTabIdsMap.My ? LoginedUserHandler.getLoginedUserId() : null,
        }));
    }, [activeTab]);

    const pageTitle = useMemo(
        () => (activeTab === PlaceMenuOrdersHeaderTabIdsMap.My ? 'Assigned to me' : 'All Orders'),
        [activeTab]
    );

    const { shouldInitMenu, readyToInitialize } = useInitializeMenu({ placeId, isActive });

    const contentReady = useMemo(() => {
        return error === null && !shouldInitMenu;
    }, [shouldInitMenu, error]);

    const sectionList = useMemo(() => {
        const sectionOfAll = {
            id: 'sectionnull',
            label: 'All',
            name: 'All',
            value: '',
            checked: orderParams.sectionId === null,
        };

        return [
            sectionOfAll,
            ..._(props.sections).map((s) => ({
                id: `section${s.id}`,
                label: s.name,
                name: s.name,
                value: s.id || '',
                checked: orderParams.sectionId === s.id,
            })),
        ];
    }, [props.sections, orderParams.sectionId]);

    const menuOrdersEmptyContent = useMemo(() => {
        return !loading && <MenuListEmptyItem message="No orders was found" />;
    }, [loading, activeTab]);

    const onSectionClick = useCallback(
        (e) => {
            const value = Number(e.currentTarget.value) || null;

            setOrderParams((params) => {
                const newValue = params.sectionId === value ? null : value;

                return {
                    ...params,
                    tableId: params.tableId !== null && newValue !== null ? null : params.tableId,
                    sectionId: newValue,
                };
            });
        },
        [setOrderParams]
    );

    const onTablesChange = useCallback(
        (val) => {
            const value = Number(val) || null;

            setOrderParams((params) => ({
                ...params,
                tableId: params.tableId === value ? null : value,
            }));
        },
        [setOrderParams]
    );

    const onChangeOrder = useCallback(
        (changedOrder) => {
            if (isUsingOrdersFromStore) {
                upsertOrder(changedOrder);
            } else {
                setOrders((prevOrders) =>
                    orderParams.status &&
                    orderParams.status.length &&
                    orderParams.status.indexOf(changedOrder.status) >= 0
                        ? prevOrders.map((o) => (o.id !== changedOrder.id ? o : { ...o, ...changedOrder }))
                        : prevOrders.filter((o) => o.id !== changedOrder.id)
                );
            }
        },
        [orderParams, setOrders, isUsingOrdersFromStore, upsertOrder]
    );

    const onChangeOrderStatus = useCallback(
        async (order) => {
            const window = new MenuOrderStatusChangeWindow({ order });
            const result = await window.show();

            if (!result) {
                return;
            }

            setLoading(true);
            try {
                const changedOrder = await changeOrder({
                    placeId: props.placeId,
                    id: order.id,
                    data: {
                        status: result.status,
                    },
                    includeUser: Boolean(order.user || order.user_id),
                    items: undefined,
                });

                onChangeOrder({ ...order, ...changedOrder });
            } catch (e) {
                showError(e instanceof Error ? e.message : e);
            } finally {
                setLoading(false);
            }
        },
        [setLoading, onChangeOrder, props.placeId]
    );

    const onOpenOrder = useCallback(
        (order) => {
            new MenuOrderWindow({
                orderId: order.id,
                onChangeOrder,
                placeId: props.placeId,
                windowContentType: MenuOrderWindowContentType.Place,
            }).show();
        },
        [props.placeId, onChangeOrder]
    );

    const onEditOrder = useCallback(
        (order) => {
            props.onEditOrder(order);
        },
        [props.placeId, props.onEditOrder]
    );

    const tableList = useMemo(() => {
        return [
            { name: 'All', label: 'All', id: null, checked: orderParams.tableId === null },
            ..._.chain([...props.tables])
                .filter((t) => (orderParams.sectionId !== null ? t.sectionId === orderParams.sectionId : true))
                .sortBy((t) => Number(t.number))
                .map((t) => {
                    const section = props.sections[t.sectionId];
                    const optgroup = section && section.name;

                    return {
                        id: `table${t.id}`,
                        label: String(t.number),
                        value: t.id,
                        name: String(t.number),
                        optgroup,
                        checked: orderParams.tableId === t.id,
                    };
                })
                .value(),
        ];
    }, [props.tables, orderParams.tableId, orderParams.sectionId, props.sections]);

    // update selected waiter
    useEffect(() => {
        if (activeTab !== PlaceMenuOrdersHeaderTabIdsMap.My) {
            setSelectedWaiter(waiters && waiters.find((w) => w.id === orderParams.waiterId));
        }
    }, [waiters, orderParams.waiterId, activeTab]);

    const selectWaiter = useCallback(async () => {
        try {
            const waiterIds = await new UserSelectWindow({
                isModalWindow: true,
                users: waiters,
                title: 'Choose waiter',
                selectedUserIds: orderParams.waiterId ? [orderParams.waiterId] : [],
                multiple: false,
                closeOnSelect: true,
                displayContinueButton: false,
            }).show();

            if (waiterIds && waiterIds.length > 0) {
                setOrderParams((prev) => {
                    return {
                        ...prev,
                        waiterId: waiterIds[0],
                    };
                });
            }
        } catch (e) {
            showError(e);
        }
    }, [waiters, orderParams.waiterId]);

    const onRemoveWaiter = useCallback(() => {
        setOrderParams((prev) => ({ ...prev, waiterId: null }));
    }, []);

    const onShowPage = useCallback(
        ({ idle }) => {
            if (readyToInitialize) {
                readyToInitialize(true);
            }

            if (idle > 2 * 60 * 1000 && !loading) {
                loadOrdersList({ reset: true });
            }
        },
        [readyToInitialize, loading, loadOrdersList]
    );

    const contentTabs = useMemo(() => {
        return isWaiter && activeTab === PlaceMenuOrdersHeaderTabIdsMap.My
            ? [...ContentTabs.slice(1), ContentTabs[0]]
            : ContentTabs;
    }, [isWaiter, activeTab]);

    return (
        <Page instance={props.__page__} onShow={onShowPage}>
            <PageHeader>
                <HeaderWithTabs disabled={loading} tabs={HeaderTabs} activeTab={activeTab} setActiveTab={setActiveTab}>
                    <BackButton onClick={props.goBack} />
                    <h1 className="title">{pageTitle}</h1>
                    <MenuButton right />
                </HeaderWithTabs>
            </PageHeader>
            <PlaceMenuOrdersPageContent className="scrollable grow with-standard-padding">
                <div className="scroll-anchor" ref={contentRef} />
                {contentReady && (
                    <>
                        <Tabs className="status-tabs">
                            {contentTabs.map(({ title, id }) => (
                                <Tab
                                    key={id}
                                    active={id === activeContentTab}
                                    id={id}
                                    title={title}
                                    onClick={setActiveContentTab}
                                />
                            ))}
                        </Tabs>
                        <InputGroup className="input-group input-group-section-filter">
                            <div className="children">Section</div>
                            <div className="options options-radio">
                                <RadioInput options={sectionList} onClick={onSectionClick} />
                            </div>
                        </InputGroup>
                        <MenuOrdersTableList
                            tableList={tableList}
                            value={Number(orderParams.tableId)}
                            className={activeTab === PlaceMenuOrdersHeaderTabIdsMap.All ? '' : 'no-background'}
                            type={props.tables && tableList.length > 10 ? 'select' : 'radio'}
                            onTablesChange={onTablesChange}
                        />
                        <MenuOrdersWaiterFilter
                            className={`input-group waiter-input no-background ${
                                activeTab === PlaceMenuOrdersHeaderTabIdsMap.All ? '' : 'hidden'
                            }`}
                            waiter={selectedWaiter}
                            selectWaiter={selectWaiter}
                            editable={activeTab !== PlaceMenuOrdersHeaderTabIdsMap.My}
                            onRemove={onRemoveWaiter}
                        />
                        <MenuPlaceOrders
                            items={orders}
                            emptyContent={menuOrdersEmptyContent}
                            onOpenOrderClick={onOpenOrder}
                            onEditOrderClick={onEditOrder}
                            onChangeStatusClick={onChangeOrderStatus}
                            onLastItemInViewportChange={loadMoreOrders}
                        />
                    </>
                )}
                {((!contentReady && !error) || loading || initialization) && <Spinner />}
                {error !== null && <MenuItemError errorText={error} displayRetryButton={false} />}
            </PlaceMenuOrdersPageContent>
        </Page>
    );
};

const ConnectedPlaceMenuOrdersContent = withStore(
    connect(
        (state) => ({
            loading: getMenuInfoLoading(state),
            persistedPlaceId: getMenuPlaceId(state),
            error: getMenuError(state),

            loadOrders,
            changeOrder,
            sections: getMenuSections(state),
            tables: getMenuTablesAsArray(state),
            waiters: getMenuWaitersAsArray(state),
            ordersAssignedToMe: getPlaceMenuOrdersAssignedToMeSortedByCreatedAt(state),
            isWaiter: getMenuIsWaiter(state),
            loginedUser: getLoginedUser(state),
        }),
        {
            upsertOrder,
            onEditOrder,
        }
    )(MenuOrdersContent)
);

const PlaceMenuOrdersPage = ReactPage.extend({
    attributes: {
        id: 'page_place_menu_orders',
    },
    className: 'place-menu-orders-page menu-orders-base-page',
    component: ConnectedPlaceMenuOrdersContent,
    show(originalProps) {
        const props = { ..._.omit(originalProps, 'orderParams') };

        if (originalProps.orderParams) {
            props.transformOrderParams = _.once((orderParams) => ({ ...orderParams, ...originalProps.orderParams }));
        }

        return ReactPage.prototype.show.call(this, props);
    },
});

export default PlaceMenuOrdersPage;
