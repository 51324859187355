import { Collection, Model } from '@b2cmessenger/backbone';
import settings from 'settings';

import NotificationModel, { getNotificationCategoryName } from 'models/NotificationModel';
import store from "store/index";
import { updateOrdersDueToNotifications } from "store/menu/orders/actions";

const NotificationCollection = Collection.extend({
    getNewVisibleNotificationsCount() {
        return this._newVisibleNotificationsCount;
    },

    getNewVisibleNotificationsCountByCategories() {
        return _.clone(this._newVisibleNotificationsCountByCategory);
    },

    getNewVisibleNotificationIdsBy(predicate) {
        return this.chain()
            .filter(m => m.get('isNewAndVisible'))
            .filter(predicate)
            .pluck('id')
            .value();
    },

    getNewVisibleNotificationIdsForTask(taskId) {
        return this.getNewVisibleNotificationIdsBy(
            n => n.get('isNewTask') && n.get('data') && n.get('data').task_id == taskId
        );
    },

    getNewVisibleNotificationIdsForComment(commentId) {
        return this.getNewVisibleNotificationIdsBy(
            n => (n.get('isNewComment') || n.get('isNewTaskSolutionRating')) && n.get('data') && n.get('data').comment_id == commentId
        );
    },

    getNewVisibleNotificationIdsForMessage(messageModel) {
        if (!messageModel) {
            return [];
        }

        return messageModel.get('isTask') ?
            this.getNewVisibleNotificationIdsForTask(messageModel.id) :
            this.getNewVisibleNotificationIdsForComment(messageModel.id);
    },

    getNewVisibleNotificationIdsForGift(giftId) {
        return this.getNewVisibleNotificationIdsBy(
            n => n.get('isNewGift') && n.get('data') && n.get('data').gift_id == giftId
        );
    },

    markAsReadByIds(ids, options) {
        _.defaults(options || (options = {}), {
            filter: null
        });

        const _ids = _(ids),
            filter = _.isFunction(options.filter) ?
                m => options.filter(m) && _ids.contains(m.id) && m.get('canBeRead') :
                m => _ids.contains(m.id) && m.get('canBeRead');

        return this._markAsReadByIds(_.pluck(this.filter(filter), 'id'), options);
    },

    markAllAsRead(options) {
        return this.markAsReadByIds(this.pluck('id'), options);
    },

    model: NotificationModel,

    comparator: (l, r) => l.id == r.id ? 0 : l.id < r.id ? 1 : -1,

    url() {
        return settings.host + settings.serv_notification.v2;
    },

    initialize() {
        this._newVisibleNotificationsCount = 0;
        this._newVisibleNotificationsCountByCategory = _.chain(NotificationModel.Category)
            .values()
            .reduce((memo, key) => {
                memo[key] = 0;
                return memo
            }, _.create(null))
            .value();

        this.on('reset', () => this._recalculateNewVisibleNotificationsCount());

        this.on('add', (m, collection, options) => {
            _.defaults(options || (options = {}), {
                fetchIfNeeded: false
            });

            if (options.fetchIfNeeded && m.get('isFetchNeeded')) {
                m.fetch();
            }

            if (m.get('isNewAndVisible')) {
                this._newVisibleNotificationsCount++;
                this._increaseCategoryCounterByModel(m);

                this.trigger('change:newVisibleNotificationsCount', this, this._newVisibleNotificationsCount, options);
                this.trigger('change:newVisibleNotificationsCountByCategory', this, this._newVisibleNotificationsCountByCategory);
            }
        });

        this.on('remove', (m, collection, options) => {
            if (m.get('isNewAndVisible')) {
                this._newVisibleNotificationsCount--;
                this._decreaseCategoryCounterByModel(m);

                this.trigger('change:newVisibleNotificationsCount', this, this._newVisibleNotificationsCount, options);
                this.trigger('change:newVisibleNotificationsCountByCategory', this, this._newVisibleNotificationsCountByCategory);
            }
        });

        this.on('change:isNewAndVisible', (m, isNewAndVisible, options) => {
            if (isNewAndVisible) {
                this._newVisibleNotificationsCount++;
                this._increaseCategoryCounterByModel(m);
            } else {
                this._newVisibleNotificationsCount--;
                this._decreaseCategoryCounterByModel(m);
            }

            this.trigger('change:newVisibleNotificationsCount', this, this._newVisibleNotificationsCount, options);
            this.trigger('change:newVisibleNotificationsCountByCategory', this, this._newVisibleNotificationsCountByCategory);
        });
    },

    fetch(options) {
        if (options && options.onlyNew === true) {
            if (this.length && this.first().get('created_at')) {
                options.data = _.defaults(
                    options.data || {},
                    { created_after: this.first().get('created_at') }
                );
            }
        }

        return Collection.prototype.fetch.call(this, options);
    },

    _markAsReadByIds(ids, options) {
        _.defaults(options || (options = {}), {
            error: null,
            success: null,
            context: this,
        });

        options.ids = ids;

        return new Promise((resolve, reject) => {
            const _ids = _(ids);
            if (!_ids.size()) {
                options.success && options.success.call(options.context, this, [], options);
                resolve([]);
            } else {
                const error = options.error;
                options.error = (xhr, textStatus, errorThrown) => {
                    options.textStatus = textStatus;
                    options.errorThrown = errorThrown;
                    error && error.call(options.context, this, xhr, options);
                    reject(new AjaxError(xhr, textStatus, errorThrown));
                };

                const success = options.success;
                options.success = data => {
                    const models = this.filter(m => _ids.contains(m.id));
                    _(models).each(m => m.set('status', NotificationModel.Status.Read));
                    success && success.call(options.context, this, data, options);
                    resolve(models);
                };

                const xhr = Server.callServer({
                    url: settings.host + settings.serv_notification.readstatus,
                    type: "POST",
                    data: { ids: _ids.toArray() },
                    success: options.success,
                    error: options.error
                });
                this.trigger('request', this, xhr, options);
            }
        });
    },

    _recalculateNewVisibleNotificationsCount() {
        const prev = this._newVisibleNotificationsCount;
        const prevCategories = _.clone(this._newVisibleNotificationsCountByCategory);
        const models = this.filter(m => m.get('isNewAndVisible'));
        this._newVisibleNotificationsCount = models.length;
        this._newVisibleNotificationsCountByCategory = _.countBy(models, m => m.get('categoryName'));

        if (prev != this._newVisibleNotificationsCount) {
            this.trigger('change:newVisibleNotificationsCount', this, this._newVisibleNotificationsCount);
        }

        if (!_.isEqual(prevCategories, this._newVisibleNotificationsCountByCategory)) {
            this.trigger('change:newVisibleNotificationsCountByCategory', this, this._newVisibleNotificationsCountByCategory);
        }
    },

    _increaseCategoryCounterByModel(model) {
        const category = model.get('categoryName');
        if (!_.isNumber(this._newVisibleNotificationsCountByCategory[category])) {
            this._newVisibleNotificationsCountByCategory[category] = 1;
        } else {
            this._newVisibleNotificationsCountByCategory[category]++;
        }
    },

    _decreaseCategoryCounterByModel(model) {
        const category = model.get('categoryName');
        if (Number(this._newVisibleNotificationsCountByCategory[category])) {
            this._newVisibleNotificationsCountByCategory[category]--;
        }
    }
});

export default NotificationCollection;
