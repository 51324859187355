import { takeEvery, put, select } from 'redux-saga/effects';
import { isOrderNoticeForEmployee, MenuOrderNotificationTemplates } from "models/NotificationModel";
import { getPlaceMenuOrderById } from "store/menu/orders/selectors";
import * as MenuAPI from 'utils/api/menu';
import {
    insertOrderIntoCart,
    ON_EDIT_ORDER,
    UPDATE_ORDERS_DUE_TO_NOTIFICATIONS,
    UPLOAD_ORDER,
    uploadOrderFailed,
    uploadOrderSucceed, upsertOrder,
    upsertOrders
} from "store/menu/orders/actions";
import {
    getMenuCartItems,
    getMenuCartOrder, getMenuCartState,
    getMenuIsInitialized,
    getMenuIsOrderManager,
    getMenuIsWaiter, getMenuItemById,
    getMenuPlaceId
} from "store/menu/selectors";
import { OrderStatus } from "components/menu/MenuPlaceOrder/MenuPlaceOrder";
import {
    collapseCartItems,
    removeFromCart,
    removeFromCartByItemId,
    resetCart,
    setCartErrors, updateCartItem
} from "store/menu/cart/actions";
import { getExtendedCartItemsMap, getMenuCartOrderParams } from "store/menu/cart/selectors";
import { getEditedOrderItems } from "store/menu/orders/utils";
import InfoModal from 'windows/Modal/Info';
import { getMenuDraftCartFromCartsByPlaceId } from "store/menu/carts/selectors";
import { getMenuPermissionsInCurrentPlace } from "store/menu/sections/selectors";
import { getLoginedUserId } from "store/app/selectors";
import AjaxError from "utils/AjaxError";
import { loadMenuItemDetail } from "store/menu/actions";
import MenuCartErrorWindow from "components/window/MenuCartError/MenuCartError";
import { getMenuCartItemsAsOrderPayload } from "store/menu/utils";

function* updateOrdersDueToNotificationsAsync(action) {
    try {
        const { notifications = [] } = action.payload;

        const isMenuInitialized = yield select(getMenuIsInitialized);

        if (!isMenuInitialized) {
            return;
        }

        const placeOrderIdsNeededToFetch = [];
        const paramKeysWithoutFetchToOrderUpdateNeeded = ['status', 'user_id', 'waiter_user_id', 'table_id'];
        const placeId = yield select(getMenuPlaceId);
        const ordersToUpsert = [];

        for (const notification of notifications) {
            const { template_type: templateType } = notification;

            if (!isOrderNoticeForEmployee(templateType)) {
                return;
            }

            const { data = {} } = notification;

            const {
                order_id: orderId,
                previous_values: previousValues = {},
            } = data;

            switch (templateType) {
                case MenuOrderNotificationTemplates.NoWorkingWaiters:
                case MenuOrderNotificationTemplates.OrderAssignedForYou:
                case MenuOrderNotificationTemplates.OrderWaiterNotify:
                    placeOrderIdsNeededToFetch.push(orderId);
                    break;
                case MenuOrderNotificationTemplates.OrderUpdatedWaiterNotify:
                    let isFetchOrderNeeded = false;
                    const updatedOrderParams = {};

                    for (const key in previousValues) {
                        if (paramKeysWithoutFetchToOrderUpdateNeeded.findIndex(k => k === key) === -1) {
                            isFetchOrderNeeded = true;
                        }

                        if (data.hasOwnProperty(key)) {
                            updatedOrderParams[key] = data[key];
                        }
                    }

                    if (isFetchOrderNeeded) {
                        placeOrderIdsNeededToFetch.push(orderId);
                    } else {
                        const prevOrder = yield select(getPlaceMenuOrderById, orderId);
                        ordersToUpsert.push({
                            ...prevOrder,
                            ...updatedOrderParams
                        });
                    }
                    break;
            }
        }

        if (placeOrderIdsNeededToFetch.length > 0) {
            const status = _(OrderStatus).map(s => s);
            const { orders: placeOrders = [] } = yield MenuAPI.loadOrders({
                placeId,
                ids: placeOrderIdsNeededToFetch,
                includeUser: true,
                sort: 'created_at',
                includeWaiterRequests: true,
                status
            });

            ordersToUpsert.push(...placeOrders);
        }

        if (ordersToUpsert.length > 0) {
            yield put(upsertOrders(ordersToUpsert));
        }
    } catch (e) {
        showError(e)
    }
}

function* onEditOrder(action) {
    try {
        const { order } = action.payload;
        if (!order) {
            return;
        }
        const placeId = order['place_id'] || order['placeId'] || null;

        yield put(insertOrderIntoCart(order));
        yield app.controller.goToMenuCart({ placeId });
    } catch (e) {
        showError(e);
    }
}

function* uploadOrderAsync(action) {
    const {
        placeId = yield select(getMenuPlaceId),
        orderData
    } = action.payload;

    try {
        const permissions = yield select(getMenuPermissionsInCurrentPlace);
        const isPlaceEmployee = Boolean(permissions.isOrderManager || permissions.isMenuManager || permissions.isWaiter);

        yield put(collapseCartItems());

        const order = yield select(getMenuCartOrder);
        const orderParams = yield select(getMenuCartOrderParams);
        const orderId = orderData.id || orderParams && orderParams.id || null;
        const orderNumber = orderData.number || (orderParams && orderParams.number) || null;
        const isEditingOrder = Boolean(orderParams && orderParams.id);

        const preparedOrderData = {
            comment: orderData.comment,
            ['table_id']: orderData.tableId,
        };
        if (isPlaceEmployee) {
            preparedOrderData['user_id'] = orderData.user ? orderData.user.id : 0
        }

        const data = {
            ...order,
            data: preparedOrderData
        };
        if (orderParams && orderParams.items) {
            const newItems = order.items.filter(i => !Boolean(i.id));
            const editedItems = getEditedOrderItems(order.items, orderParams.items);

            data.items = [...newItems, ...editedItems];
        }

        const result = yield MenuAPI.uploadOrder({
            placeId,
            data,
            orderId
        });

        if (result && orderData.user && !result.hasOwnProperty('user')) {
            result.user = { ...orderData.user };
        }

        const loginedUserId = yield select(getLoginedUserId);

        if (loginedUserId === result['waiter_user_id']) {
            yield put(upsertOrder(result));
        }

        if (loginedUserId === result['user_id']) {
            app.controller.menuOrdersPage.changeOrder(result);
        }

        yield put(uploadOrderSucceed(result));

        let draftCart = isEditingOrder ? yield select(getMenuDraftCartFromCartsByPlaceId, placeId) : null;
        yield put(resetCart(draftCart));

        if (loginedUserId === result['user_id']) {
            app.controller.goToOrdersPage({ placeId, showOrderWindowWithId: result.id, replace: true });
        } else if (isPlaceEmployee) {
            app.controller.goToPlaceOrdersPage({ placeId, showOrderWindowWithId: result.id });
        }

        yield new InfoModal({
            additionalClassName: 'green-border',
            title: orderId ? `Order #${orderNumber} successfully updated` : "Your order has been sent successfully!"
        }).show();
    } catch (e) {
        if (e instanceof AjaxError && e.jqXHR.status === 422) {
            const { item = { errors: [], definition: {} } } = e.jqXHR.responseJSON;

            for (const i in item.errors) {
                const { code, message } = item.errors[i];
                switch (code) {
                    case 21: { // Элемент меню не найден или не доступен для заказа (скрыт)
                        const id = item.definition['item_id'];
                        const itemWithError = yield select(getMenuItemById, id);
                        yield new MenuCartErrorWindow({
                            message,
                            placeId,
                            items: [itemWithError]
                        }).show();

                        yield put(removeFromCartByItemId(id));

                        break;
                    }

                    case 22: // Не все необходимые варианты свойств элемента указаны
                    case 23: // Указано не допустимое значение свойства элемента
                    {
                        const id = item.definition['item_id'];
                        const itemWithError = yield select(getMenuItemById, id);

                        yield new MenuCartErrorWindow({
                            message,
                            placeId,
                            items: [itemWithError]
                        }).show();

                        yield put(loadMenuItemDetail(placeId, id, true));

                        break;
                    }

                    case 24 : { // Дополнение не может быть применено к текщему элементу (откреплено, скрыто, удалено)
                        const id = item.definition['addition_parent_id'];
                        const additionItemId = item.definition['item_id'];
                        const itemWithError = yield select(getMenuItemById, id);

                        yield new MenuCartErrorWindow({
                            message,
                            placeId,
                            items: [itemWithError]
                        }).show();

                        yield put(loadMenuItemDetail(placeId, id, true));

                        const items = yield select(getMenuCartItems);
                        for (const uniqId in items) {
                            if (items[uniqId].id === id) {
                                const item = { ...items[uniqId] };

                                if (item.additions[additionItemId]) {
                                    delete item.additions[additionItemId];
                                }

                                yield put(updateCartItem(item, uniqId));
                            }
                        }

                        break;
                    }

                    case 11: // В заказе должена быть хотя бы одна не нулевая позиция
                    default: {
                        showError(message);
                        break;
                    }
                }
            }
        } else {
            showError(e instanceof Error ? e.message : e);
        }
        yield put(uploadOrderFailed(e instanceof Error ? e.message : e));
    }
}

export default function* watchMenuOrdersEffects() {
    yield takeEvery(UPDATE_ORDERS_DUE_TO_NOTIFICATIONS, updateOrdersDueToNotificationsAsync);
    yield takeEvery(ON_EDIT_ORDER, onEditOrder);
    yield takeEvery(UPLOAD_ORDER, uploadOrderAsync);
}
