import { Trait } from '@b2cmessenger/backbone';
import getNextPrettyColor from 'utils/randomPrettyColor';
import './ViewWithPrettyColor.scss';

const traitId = Symbol;

class ViewWithPrettyColor extends Trait {
    constructor(options, _traitId) {
        super(_traitId || traitId);

        _.extend(this.methods || (this.methods = {}), {
            onRender: this.onRender,
            onPrettyColorRefresh: this.onPrettyColorRefresh
        });

        this.options = _.defaults(options || (options = {}), {
            modelAttribute: 'id'
        });

        this.parentOptions = _.defaults(options || (options = {}), {
            enablePrettyColor: true
        });
    };

    initialize(initialize, trait, ...args) {
        if (this.model) {
            this.listenTo(this.model, `change:${trait.options.modelAttribute}`, () => this.triggerMethod('pretty:color:refresh'));
        }

        return initialize.apply(this, args);
    };

    onRender(f) {
        f && f.apply(this);

        if (this.options.enablePrettyColor) {
            this.el.classList.add('trait-view-with-pretty-color');
            this.triggerMethod('pretty:color:refresh');
        }
    };

    onPrettyColorRefresh(f, trait, m, v) {
        if (this.options.enablePrettyColor) {
            if (_.isUndefined(v)) {
                m = _.isUndefined(m) ? this.model : m;
                if (m) {
                    v = m.get(trait.options.modelAttribute);
                }
            }

            if (this.el) {
                this.el.style.borderColor = getNextPrettyColor(v);
            }

            if (f) f.apply(this, arguments);
        }
    }
};

export default ViewWithPrettyColor;

export function decorator(options) {
    options = _.defaults(options || (options = {}), {
        modelAttribute: 'id'
    });

    return function decorator(target) {
        target.prototype.options = _.defaults(target.prototype.options || (target.prototype.options = {}), {
            enablePrettyColor: true
        });

        target.prototype.onPrettyColorRefresh = function onPrettyColorRefresh(m, v) {
            if (this.options.enablePrettyColor) {
                if (_.isUndefined(v)) {
                    m = _.isUndefined(m) ? this.model : m;
                    if (m) {
                        v = m.get(options.modelAttribute);
                    }
                }

                if (this.el) {
                    this.el.style.borderColor = getNextPrettyColor(v);
                }
            }
        };

        const initialize = target.prototype.initialize;
        target.prototype.initialize = function ViewWithPrettyColor_initialize() { 
            const ret = initialize && initialize.apply(this, arguments);

            if (this.model) {
                this.listenTo(this.model, `change:${options.modelAttribute}`,
                    () => this.triggerMethod('pretty:color:refresh'));
            }

            this.listenTo(this, 'render', () => { 
                if (this.options.enablePrettyColor) {
                    this.el.classList.add('trait-view-with-pretty-color');
                    this.triggerMethod('pretty:color:refresh');
                }
            });

            return ret;
        }
    }
}
