import { Required, Optional } from '@b2cmessenger/backbone';

import ModalWindow from 'windows/Modal/Modal';
import MessageReport from './MessageReport';

/**@type {typeof import('./MessageReportModal').options} */
// @ts-ignore
const options = ModalWindow.options;

@options({
    model: Required,
    parentViewModel: Optional,
})
class MessageReportModalWindow extends ModalWindow {
    /**@param {import('./MessageReportModal').Options} options */
    constructor(options) {
        const reportView = new MessageReport({
            model: options.model,
        });

        super({
            parentViewModel: options.parentViewModel,
            content: reportView,
            intent: ModalWindow.Intent.Warning,
            buttons: [
                {
                    id: 'close',
                    text: 'CLOSE'
                }, {
                    id: 'report',
                    text: 'REPORT',
                    icn: 'empty',
                    onClick(e) {
                        this.viewModel.set({ disabled: true });
                        reportView.report()
                            .then(() => this.close(true))
                            .catch(e => {
                                this.showError(e);
                                this.viewModel.set({ disabled: false });
                            })
                    }
                }
            ]
        });
    }
}

export default MessageReportModalWindow;
