import ReactWindow from "windows/ReactWindow";
import 'windows/Modal/Modal.scss';

const ReactModalWindowIntent = {
    Positive: 1,
    Negative: 2,
    Warning: 3,
    Active: 4
};

const ReactModalWindow = ReactWindow.extend({
    constructor: function ReactModalWindow(options) {
        const classNames = options.className && options.className.split(' ') || [];
        const intentClassName = options.intent && this.getIntentClassName(options.intent);

        if (classNames.indexOf('modal-window') === -1) {
            classNames.push('modal-window');
        }

        if (intentClassName && classNames.indexOf(intentClassName) === -1) {
            classNames.push(intentClassName);
        }

        _.extend(options, {
            className: classNames.join(' ')
        });

        this.isModalWindow = true;

        return ReactWindow.apply(this, [options]);
    },

    onShow() {
        _.defer(() => {
            this.el.classList.add('overlay');
        });
    },

    getIntentClassName(intent) {
        switch (intent) {
            case ReactModalWindowIntent.Positive:
                return 'intent-positive';
            case ReactModalWindowIntent.Negative:
                return 'intent-negative';
            case ReactModalWindowIntent.Warning:
                return 'intent-warning';
            case ReactModalWindowIntent.Active:
                return 'intent-active';
            default:
                return null;
        }
    }
}, { Intent: ReactModalWindowIntent });

export default ReactModalWindow;
