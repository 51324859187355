import ReactWindow from 'windows/ReactWindow';
import ReactModalWindow from 'windows/ReactModalWindow';
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import './UserSelect.scss';
import { WindowWrapper } from 'components/window/Window';
import UserSelect from 'components/menu/UserSelect/UserSelect';
import Spinner from 'components/UI/Spinner/Spinner';

export const UserSelectContainer = (props) => {
    const {
        title = 'User select',
        loading,
        isWindow = false,
        isModalWindow = false,
        multiple = true,
        emptyContentMessage,
    } = props;
    const { users = [], selectedUserIds: selectedUserIdsFromProps = [] } = props;
    const [selectedUserIds, setSelectedUserIds] = useState(selectedUserIdsFromProps);
    const disabled = loading || !users || !users.length;

    const onSelectedChange = useCallback(
        (user, isSelected) => {
            setSelectedUserIds((prev) => {
                const selected = multiple
                    ? isSelected
                        ? [user.id, ...prev]
                        : prev.filter((p) => p !== user.id)
                    : [user.id];
                if (props.closeOnSelect) {
                    props.closeWindow(selected);
                }
                return selected;
            });
        },
        [multiple, props.closeOnSelect, props.closeWindow]
    );

    const content = loading ? (
        <Spinner />
    ) : (
        <UserSelect
            multiple={multiple}
            users={users}
            selectedUserIds={selectedUserIds}
            emptyContentMessage={emptyContentMessage}
            onSelectedChange={onSelectedChange}
        />
    );

    return isWindow || isModalWindow ? (
        <WindowWrapper
            {...props}
            title={title}
            disabled={disabled}
            onContinueButtonData={selectedUserIds}
            cancelButtonText="CLOSE"
            continueButtonText="SELECT"
            isModalWindow={isModalWindow}
        >
            {content}
        </WindowWrapper>
    ) : (
        content
    );
};
UserSelectContainer.propTypes = {
    users: PropTypes.array,
    loading: PropTypes.bool,
    selectedUserIds: PropTypes.array,
};

const UserSelectWindow = ReactModalWindow.extend({
    constructor: function UserSelectWindow(props) {
        const options = {
            props: {
                isWindow: true,
                isModalWindow: true,
                ...props,
            },
            component: UserSelectContainer,
            name: 'UserSelectContainer',
        };

        return options.props.isModalWindow
            ? ReactModalWindow.apply(this, [options])
            : ReactWindow.apply(this, [options]);
    },
});

export default UserSelectWindow;
