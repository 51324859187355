var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (status, user) {pug_html = pug_html + "\u003Cdiv class=\"avatar\" data-js-avatar\u003E\u003C\u002Fdiv\u003E";
if ((user.firstname || user.lastname)) {
pug_html = pug_html + ("\u003Cdiv class=\"name\"\u003E" + (pug.escape(null == (pug_interp = user.firstname) ? "" : pug_interp)) + " " + (pug.escape(null == (pug_interp = user.lastname) ? "" : pug_interp)));
if ((status == 1)) {
pug_html = pug_html + "\u003Cdiv class=\"email\"\u003E" + (pug.escape(null == (pug_interp = user.email) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
else
if (user.email) {
pug_html = pug_html + "\u003Cdiv class=\"name\"\u003E" + (pug.escape(null == (pug_interp = user.email) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"name\"\u003EUnknown person (#" + (pug.escape(null == (pug_interp = user.id) ? "" : pug_interp)) + ")\u003C\u002Fdiv\u003E";
}}.call(this,"status" in locals_for_with?locals_for_with.status:typeof status!=="undefined"?status:undefined,"user" in locals_for_with?locals_for_with.user:typeof user!=="undefined"?user:undefined));;return pug_html;};
module.exports = template;