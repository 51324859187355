import { Optional, Required, ItemView, ViewModel } from '@b2cmessenger/backbone';
import Modal from 'windows/Modal/Modal';
import FooterView from 'widgets/Footer/Footer';
import template from './PhoneVerify.jade';
import './PhoneVerify.scss';

const BLOCK_SECONDS_UPDATE_INTERVAL = 500;

const PhoneVerifyModal = Modal.extend({
    options: {
        model: Required,
        autoRequestVerificationCodeOnShow: true,
        parentViewModel: Optional
    },

    windowName: "phone-verify-modal-window",
    className: "modal-window phone-verify-modal-window green-border",

    initialize() {
        this.viewModel = new ViewModel({
            parentViewModel: this.options.parentViewModel || null,
            vm_code: null,
            vm_codeError: '',
            vm_now: null,
            vm_nextSmsAttemptTimeout: null,
            vm_canContinue: null
        });

        this.viewModel.addComputed('vm_canContinue', {
            deps: ['vm_code', 'disabled'],
            get: (vm_code, disabled) => !!(vm_code && !disabled)
        });

        //@ts-ignore
        this._contentView = new PhoneVerifyContent({
            model: this.model,
            viewModel: this.viewModel
        });
    },

    onRender() {
        const contentView = this._contentView;
        this.listenTo(contentView, 'continue', () => this._verifyWithCode());

        const footerView = new FooterView({
            buttons: [{
                id: "close",
                text: 'CANCEL',
                icn: "empty"
            }, {
                id: "continue",
                text: "CONTINUE",
                icn: "empty"
            }]
        });

        footerView.listenTo(this.viewModel, 'change:vm_canContinue', () => {
            footerView.ui.btncontinue.prop('disabled', !this.viewModel.get('vm_canContinue'));
        });
        footerView.listenTo(this.viewModel, 'change:disabled', () => {
            footerView.ui.btncontinue.prop('disabled', !this.viewModel.get('vm_canContinue'));
        });

        this.listenTo(footerView, 'close:click', view => this.close(false));
        this.listenTo(footerView, 'continue:click', view => this._verifyWithCode());

        this.content.show(contentView);
        this.footer.show(footerView);

        footerView.ui.btncontinue.prop('disabled', !this.viewModel.get('vm_canContinue'));
    },

    onShow() {
        if (this.model && this.model.get('c_isVerified')) {
            this.showMessage({
                additionalClassName: 'green-border',
                title: "Your number has been successfully confirmed!"
            });
            return this.close(this.model);
        }

        if (this.options.autoRequestVerificationCodeOnShow) {
            _.defer(() => this._contentView.requestCode());
        }

        //@ts-ignore
        return Modal.prototype.onShow.call(this);
    },

    cancel() {
        this.close(false);
    },

    async _verifyWithCode() {
        if (this.viewModel.get('vm_canContinue')) {
            this.viewModel.set('disabled', true);

            try {
                await this.model.verifyWithCode(this.viewModel.get('vm_code'));
                this.showMessage({
                    additionalClassName: 'green-border',
                    title: "Your number has been successfully confirmed!"
                });
                this.close(this.model);
            } catch (e) {
                this.showError(e);
            } finally {
                this.viewModel.set('disabled', false);
            }
        }
    },
});

@ItemView.options({
    model: Required,
    parentViewModel: Optional,
})
@ItemView.properties({
    template: template,
    tagName: 'form',
    className: "widget phone-verify-modal-content-widget",

    ui: {
        phone: '[data-js-phone]',
        code: '[data-js-code]',
        codeError: '[data-js-code-error]',
        retryBtn: '[data-js-retry-btn]',
        nextTimeSms: '[data-js-next-time-sms]',
        nextTimeSmsCounter: '[data-js-next-time-sms-counter]'
    },

    computeds: {
        c_Phone: {
            deps: ['phone'],
            get: phone => phone && B2Cjs.phone_getExternalFormat(phone) || "",
        },
        c_Code: {
            deps: ['vm_code'],
            get: vm_code => vm_code || '',
            set(vm_code) {
                this.viewModel.set('vm_code', vm_code);
                this.viewModel.set('vm_codeError', '');
            }
        },
        c_BlockSeconds: {
            deps: ['vm_now', 'vm_nextSmsAttemptTimeout'],
            get(vm_now, vm_nextSmsAttemptTimeout) {
                if (vm_nextSmsAttemptTimeout && vm_now && (vm_nextSmsAttemptTimeout > vm_now)) {
                    //@ts-ignore
                    return Math.floor((vm_nextSmsAttemptTimeout - vm_now) / 1000, 0);
                } else {
                    return null;
                }
            }
        },
        c_RetryBtnDisabled: {
            deps: ['disabled', 'c_BlockSeconds'],
            get: (disabled, c_BlockSeconds) => disabled || c_BlockSeconds
        },
    },

    events: {
        'click @ui.retryBtn'() {
            //@ts-ignore
            this._requestCode();
        },
        'submit'(e) {
            this.trigger('continue');
            e.preventDefault();
        },
        'keyup @ui.code'(e) {
            this.viewModel.set('vm_code', this.ui.code.val());
        }
    },

    bindings: {
        '@ui.phone': 'value:c_Phone',
        '@ui.code': 'value:c_Code, disabled:disabled, attr:{readonly:disabled}',
        '@ui.retryBtn': 'disabled:c_RetryBtnDisabled, attr:{readonly:c_RetryBtnDisabled}',
        '@ui.codeError': 'text:vm_codeError',
        '@ui.nextTimeSmsCounter': 'text:c_BlockSeconds',
        '@ui.nextTimeSms': 'classes:{hidden:not(c_BlockSeconds)}',
    },
})
class PhoneVerifyContent extends ItemView {
    async requestCode() {
        if (!this.viewModel.get('disabled')) {
            return this._requestCode();
        } else {
            throw new Error('PhoneVerifyContent is disabled');
        }
    }

    onShow() {
        _.defer(() => {
            this.ui.code.focus();
        });
    }

    onDestroy() {
        clearInterval(this._timer);
    }

    async _requestCode() {
        this.viewModel.set('disabled', true);
        try {
            //@ts-ignore
            const data = await this.model.requestVerificationCode();
            if (data && data.next_sms_attempt_timeout) {
                this._updateBlockTimer(data.next_sms_attempt_timeout);
            }
        } catch (e) {
            if (e.jqXHR && e.jqXHR.status === 429 && e.jqXHR.responseJSON.next_sms_attempt_timeout) {
                this._updateBlockTimer(e.jqXHR.responseJSON.next_sms_attempt_timeout);
            } else if (e.jqXHR && e.jqXHR.status === 429) {
                //@ts-ignore
                this.showError('An error has occurred. Try again later.');
            } else {
                //@ts-ignore
                this.showError(e);
            }
        } finally {
            this.viewModel.set('disabled', false);
        }
    }

    _updateBlockTimer(nextSmsAttemptTimeout) {
        this.viewModel.set('vm_nextSmsAttemptTimeout', Date.now() + nextSmsAttemptTimeout * 1000);

        if (!this._timer) {
            this._timer = setInterval(() => {
                this.viewModel.set('vm_now', Date.now());
                if (!this.getBinding('c_BlockSeconds')) {
                    this.viewModel.set('vm_nextSmsAttemptTimeout', null);
                    clearInterval(this._timer);
                    delete this._timer;
                }
            }, BLOCK_SECONDS_UPDATE_INTERVAL);
        }
    }
}

export { PhoneVerifyModal as default, PhoneVerifyContent };
