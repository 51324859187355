import { Model } from '@b2cmessenger/backbone';

const Status = {
    Unknown: 0,
    ReadyToUpload: 1,
    Uploading: 2,
    Uploaded: 3,
    SelectedToDelete: 4,
    Deleted: 5,
    UploadedNotSaved: 6
};

@Model.properties({
    defaults: {
        status: Status.Unknown,
        file: null,
        fileUrl: null,
        file_w: null,
        file_h: null,
        full: null,
        '320s': null,
        '320': null,
        '640': null,
        '1280': null,
        sizes: null
    },

    computeds: {
        file: {
            deps: ['_file'],
            get: _file => _file,
            set(file) {
                const oldFile = this.attributes._file;
                if (oldFile && oldFile != file) {
                    this.attributes.fileUrl && URL.revokeObjectURL(this.attributes.fileUrl);
                }

                if (file instanceof Blob) {
                    return {
                        _file: file,
                        fileUrl: URL.createObjectURL(file)
                    }
                } else {
                    return {
                        _file: null,
                        fileUrl: null
                    }
                }
            }
        },
        fullUrl: {
            deps: ['fileUrl', 'full', '320', '640', '1280'],
            get: (fileUrl, full, u320, u640, u1280) => fileUrl || full || u1280 || u640 || u320 || '',
        },
        thumbUrl: {
            deps: ['fileUrl', 'full', '320', '640', '1280'],
            get: (fileUrl, full, u320, u640, u1280) => fileUrl || (full && /\.gif$/.test(full) && full) ||
                u320 || u640 || u1280 || full || '',
        },
        ar: {
            deps: ['fileUrl', 'file_w', 'file_h', 'full', '320', '640', '1280', 'sizes'],
            get: (fileUrl, file_w, file_h, full, u320, u640, u1280, sizes) =>
                fileUrl && file_w && file_h ? file_w / file_h :
                    sizes && (
                        full && sizes.full ? sizes.full.w / sizes.full.h :
                            u1280 && sizes['1280'] ? sizes['1280'].w / sizes['1280'].h :
                                u640 && sizes['640'] ? sizes['640'].w / sizes['640'].h :
                                    u320 && sizes['320'] ? sizes['320'].w / sizes['320'].h :
                                        0
                    ) || 0
        },
        dimesions: {
            deps: ['fileUrl', 'file_w', 'file_h', 'full', '320', '640', '1280', 'sizes'],
            get: (fileUrl, file_w, file_h, full, u320, u640, u1280, sizes) =>
                fileUrl && file_w && file_h ? { w: file_w, h: file_h } :
                    sizes && (
                        full && sizes.full ||
                        u1280 && sizes['1280'] ||
                        u640 && sizes['640'] ||
                        u320 && sizes['320']
                    ) || { w: 0, h: 0 }
        }
    }
})
class CommentEditorPhotoModel extends Model { }

CommentEditorPhotoModel.Status = Status;

export default CommentEditorPhotoModel;
