import _ from 'underscore';
import { ItemView, ViewModel } from '@b2cmessenger/backbone';

import PropertyModel from 'models/PropertyModel';
import Behaviors from 'utils/Behaviors';

import template from './PropertyEdit.jade';
import './PropertyItem.scss';

let PropertyEdit = ItemView.extend({
    template: template,
    className: "widget property-edit-widget",

    initialize() {
        if(!this.viewModel)
            this.viewModel = new ViewModel;
    },

    behaviors: [{
        behaviorClass : Behaviors.InputGroupWithError,
        name: 'value',
        transformVal: function(val) { return val == null ? null : val == undefined ? undefined : this.model.get('field_type') == PropertyModel.FIELD_TYPE_INTEGER ? Number(val) : String(val); },
        inputBindingToModel: true,
        deselectRadioEnable: true
    }],

    bindings: {
        '.input-group': 'classes:{disabled:disabled}',
        'input, button': 'disabled:disabled'
    }
});

export default PropertyEdit;
