export const UPSERT_SECTION             = '[Menu] Upsert Section';
export const UPSERT_SECTIONS            = '[Menu] Upsert Sections';
export const REMOVE_SECTION             = '[Menu] Remove Section';

export const UPSERT_TABLE             = '[Menu] Upsert Table';
export const UPSERT_TABLES            = '[Menu] Upsert Tables';
export const REMOVE_TABLE             = '[Menu] Remove Table';

export const RESET_SECTIONS = '[Menu] Reset sections';

export const upsertSection = (section) => ({
    type: UPSERT_SECTION,
    payload: {
        id: section.id, section
    }
});

export const upsertSections = (sections) => ({
    type: UPSERT_SECTIONS,
    payload: {
        sections
    }
});

export const removeSection = (id) => ({
    type: REMOVE_SECTION,
    payload: {
        id
    }
});

export const resetSections = (values, tables) => ({
    type: RESET_SECTIONS,
    payload: {
        values, tables
    }
});

export const upsertTable = (table) => ({
    type: UPSERT_TABLE,
    payload: {
        id: table.id, table
    }
});

export const upsertTables = (tables) => ({
    type: UPSERT_TABLES,
    payload: {
        tables
    }
});

export const removeTable = (id) => ({
    type: REMOVE_TABLE,
    payload: {
        id
    }
});