import { createMenuCartItemInfo } from "store/menu/utils";

export const ADD_TO_CART = '[Menu] Add To Cart';
export const REMOVE_FROM_CART = '[Menu] Remove From Cart';
export const CHANGE_CART_ITEM = '[Menu] Change Cart Item';

export const CHANGE_CART_ITEM_QUANTITY = '[Menu] Change Cart Item Quantity';

export const UPDATE_CART_ITEM = '[Menu] Update Cart Item';
export const RESET_CART = '[Menu] Reset Cart';
export const RESTORE_CART_FROM_DRAFT = '[Menu] Restore cart from draft';

export const UPDATE_CART_ITEM_QUANTITY = '[Menu] Update Cart Item Quantity';
export const UPDATE_CART_ADDITION_ITEM_QUANTITY = '[Menu] Update Cart Addition Item Quantity';

export const CHANGE_CART_COMMENT = '[Menu] Change Cart Comment';

export const COLLAPSE_CART_ITEMS = '[Menu] Collapse Cart Items';

export const SET_SELECTED_TABLE_ID = '[Menu] Set Selected Table Id';

export const PERFORM_DISCARD_CART_ORDER = '[Menu] Perform Discard Cart Order';
export const DISCARD_CART_ORDER = '[Menu] Discard Cart Order';
export const DISCARD_CART_ORDER_CANCELED = '[Menu] Discard Cart Order Canceled';

export const addToCart = (item, quantity = 0) => ({
    type: ADD_TO_CART,
    payload: {
        item,
        quantity,
        ...createMenuCartItemInfo(item)
    }
});

export const decreaseQuantityOrRemoveFromCart = (item, quantity = null) => {
    if (quantity !== null && Number(quantity)) {
        return changeCartItemQuantity(item, -quantity);
    } else {
        return removeFromCart(item);
    }
};

export const removeFromCartByUniqueId = (uniqueCartId) => ({
    type: REMOVE_FROM_CART,
    payload: {
        uniqueCartId
    }
});

export const removeFromCartByItemId = (itemId) => ({
   type: REMOVE_FROM_CART,
   payload: {
       itemId
   }
});

export const removeFromCart = (item) => ({
    type: REMOVE_FROM_CART,
    payload: {
        item,
        ...createMenuCartItemInfo(item)
    }
});

export const changeCartItem = (item, changes) => ({
    type: CHANGE_CART_ITEM,
    payload: {
        item,
        changes,
        ...createMenuCartItemInfo(item),
        ...createMenuCartItemInfo({ ...item, ...changes }, 'changedCartItem', 'changedUniqueCartId')
    }
});

export const updateCartItem = (item, uniqueId) => ({
    type: UPDATE_CART_ITEM,
    payload: {
        item,
        uniqueId
    }
});

export const resetCart = (data = null) => ({
    type: RESET_CART,
    payload: {
        data
    }
});

export const changeCartItemQuantity = (item, dt) => ({
    type: CHANGE_CART_ITEM_QUANTITY,
    payload: {
        item,
        dt: Number(dt),
        ...createMenuCartItemInfo(item),
    }
});

export const increaseCartItemQuantity = (item) => changeCartItemQuantity(item, 1);
export const decreaseCartItemQuantity = (item) => changeCartItemQuantity(item, -1);

export const updateCartItemQuantity = (cartId, quantity) => ({
    type: UPDATE_CART_ITEM_QUANTITY,
    payload: {
        cartId, quantity
    }
});

export const updateCartItemAdditionQuantity = (cartId, itemId, quantity) => ({
    type: UPDATE_CART_ADDITION_ITEM_QUANTITY,
    payload: {
        cartId, itemId, quantity
    }
});

export const changeCartComment = (comment) => ({
    type: CHANGE_CART_COMMENT,
    payload: {
        comment
    }
});

export const collapseCartItems = () => ({
    type: COLLAPSE_CART_ITEMS
});

export const setSelectedTableId = (id) => ({
    type: SET_SELECTED_TABLE_ID,
    payload: {
        id
    }
});

export const performDiscardCartOrder = ({ promptUser = false } = {}) => ({
    type: PERFORM_DISCARD_CART_ORDER,
    payload: {
        promptUser: Boolean(promptUser)
    }
});

export const discardCartOrder = () => ({ type: DISCARD_CART_ORDER });
export const discardCartOrderCanceled = () => ({ type: DISCARD_CART_ORDER_CANCELED });

export const restoreCartFromDraft = () => ({ type: RESTORE_CART_FROM_DRAFT });