import { Model } from '@b2cmessenger/backbone';
import settings from 'settings';

import Page from 'pages/Page';
import RegisterWindow from 'windows/Register/Register';
import PasswordResetModal from 'windows/Modal/PasswordReset';
import { userDeviceInfo } from 'utils/UserDeviceInfo';

import template from './Login.jade';
import './Login.scss';
import ViewWithWindows from 'traits/ViewWithWindows';
import GoogleAuth from 'utils/GoogleSignIn';
import FacebookAuth from 'utils/FacebookAuth';
import AppleSignIn from "utils/AppleSignIn";

const withWindowsTrait = {
    Trait: ViewWithWindows,
    options: {
        windowMap: [
            {
                cls: RegisterWindow,
                trigger() {
                    this.ui.signUp.click();
                }
            }
        ],
    }
};

const LoginPage = Page.extendWithTraits([withWindowsTrait], {
    className: "login-page",
    attributes: {
        'id': "page_userlogin",
    },

    template: template,
    templateHelpers() {
        return {
            iOS: AppleSignIn.isIOS()
        };
    },

    ui: {
        btnSkip: '[data-js-btn-skip]',
        form: '[data-js-form]',
        inEmail: '[data-js-input-email]',
        inPassword: '[data-js-input-password]',
        btnSignIn: '[data-js-input-signin]',
        btnFb: '[data-js-btn-fb]',
        btnGoo: '[data-js-btn-goo]',
        btnApple: '[data-js-btn-apple]',
        signUp: '[data-js-link-signup]',
        btnHelp: '[data-js-btn-help]'
    },

    events: {
        'submit @ui.form': 'login',
        'click @ui.btnSignIn': 'login',
        'click @ui.btnFb': 'onFbSignIn',
        'click @ui.btnGoo': 'onGooSignIn',
        'click @ui.btnApple': 'onAppleSignIn',
        'click @ui.signUp': 'signup',
        'click @ui.btnHelp': 'showHelp'
    },

    triggers: {
        'click @ui.btnSkip': 'skip',
    },

    initialize() {
        this.on('loggedIn', () => {
            const user = LoginedUserHandler.getLoginedUser();

            if (user && user.email && this.ui.inEmail && this.ui.inEmail.val) {
                this.ui.inEmail.val(user.email);
            }

            if (this.ui.inPassword && this.ui.inPassword.val) {
                this.ui.inPassword.val('');
            }
        });
    },

    show(options) {
        if(options && options.afterLoginCallback){
            this.viewModel.set('afterLoginCallback', options.afterLoginCallback);
        }
        Page.prototype.show.apply(this, arguments);
    },

    onAfterShow(options) {
        _.defaults(options || (options = {}));

        if (options.email) {
            this.ui.inEmail.val(options.email);
        }

        return
    },

    login(e) {
        e.preventDefault();

        var email = this.ui.inEmail.val();
        var password = this.ui.inPassword.val();
        if (password.length == 0)
            return;

        this.showLoading();

        Server.callServer({
            url: settings.host + settings.serv_user.authorize,
            dataType: 'json',
            method: 'POST',
            data: {
                email: email,
                password: password,
                info: userDeviceInfo.getInfo()
            },
            success: (data, textStatus, jqXHR) => {
                if (data.access_token)
                    LoginedUserHandler.setAccessToken(data.access_token);

                LoginedUserHandler.setLoginedUser({
                    id: data.id,
                    email: data.email,
                    firstname: data.firstname,
                    lastname: data.lastname || "",
                    phone: data.phone,
                    birthday: data.birthday,
                    gender: data.gender,
                    avatar: data.avatar,
                    avatarThumb: data.avatarThumb ? data.avatarThumb : data.avatar,
                    settings: {
                        viewanonym: (data.viewanonym == null ? true : data.viewanonym),
                        viewunverified: (data.viewunverified == null ? true : data.viewunverified),
                    }
                });

                this.viewModel.set({ hideLoadingOnHideOnce: true });
                this.trigger('loggedIn');
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.hideLoading();
                this.showError(jqXHR, textStatus, errorThrown);
            },
        });
    },

    signup(e) {
        e.preventDefault();
        new RegisterWindow().show()
            .then(userModel => {
                if (!userModel) {
                    this.trigger('skip');
                } else if (userModel.get('loggedIn')) {
                    this.showLoading();
                    this.viewModel.set({ hideLoadingOnHideOnce: true });
                    this.trigger('loggedIn');
                }
            });
    },

    onFbSignIn() {
        this.showLoading();
        FacebookAuth.login().then(
            (data, textStatus, jqXHR) => {
                if (data.access_token)
                    LoginedUserHandler.setAccessToken(data.access_token);

                LoginedUserHandler.setLoginedUser({
                    id: data.id,
                    email: data.email,
                    firstname: data.firstname,
                    lastname: data.lastname || "",
                    phone: data.phone,
                    birthday: data.birthday,
                    gender: data.gender,
                    avatar: data.avatar,
                    avatarThumb: data.avatarThumb ? data.avatarThumb : data.avatar,
                    settings: {
                        viewanonym: (data.viewanonym == null ? true : data.viewanonym),
                        viewunverified: (data.viewunverified == null ? true : data.viewunverified),
                    }
                });

                this.viewModel.set({ hideLoadingOnHideOnce: true });
                this.trigger('loggedIn');
            },
            (error) => {
                this.hideLoading();

                if (error == "User cancelled." || Number(error.errorCode) == 4201) {
                } else {
                    this.showError(error);
                }
            }
        );
    },

    onGooSignIn() {
        this.showLoading();

        GoogleAuth.authorize().then(
            (data, textStatus, jqXHR) => {
                if (data.access_token)
                    LoginedUserHandler.setAccessToken(data.access_token);

                LoginedUserHandler.setLoginedUser({
                    id: data.id,
                    email: data.email,
                    firstname: data.firstname,
                    lastname: data.lastname || "",
                    phone: data.phone,
                    birthday: data.birthday,
                    gender: data.gender,
                    avatar: data.avatar,
                    avatarThumb: data.avatarThumb ? data.avatarThumb : data.avatar,
                    settings: {
                        viewanonym: (data.viewanonym == null ? true : data.viewanonym),
                        viewunverified: (data.viewunverified == null ? true : data.viewunverified),
                    }
                });

                this.viewModel.set({ hideLoadingOnHideOnce: true });
                this.trigger('loggedIn');
            },
            (jqXHRorTextError, textStatus, errorThrown) => {
                this.hideLoading();
                if (jqXHRorTextError == "The user canceled the sign-in flow."
                    || jqXHRorTextError == "access_denied"
                    || jqXHRorTextError == 13
                    || jqXHRorTextError == 12500 || jqXHRorTextError == 12501
                ) {

                } else {
                    if (_.isUndefined(textStatus) && _.isUndefined(errorThrown) && _.isString(jqXHRorTextError)) {
                        this.showError(
                            jqXHRorTextError,
                            undefined, undefined,
                            { dontSendToGaAndSentry: true }
                        );
                    } else {
                        this.showError(jqXHRorTextError, textStatus, errorThrown);
                    }
                }
            }
        );
    },

    onAppleSignIn() {
        this.showLoading();

        AppleSignIn.authorize().then(
            (data, textStatus, jqXHR) => {
                if (data.access_token)
                    LoginedUserHandler.setAccessToken(data.access_token);

                LoginedUserHandler.setLoginedUser({
                    id: data.id,
                    email: data.email,
                    firstname: data.firstname,
                    lastname: data.lastname || "",
                    phone: data.phone,
                    birthday: data.birthday,
                    gender: data.gender,
                    avatar: data.avatar,
                    avatarThumb: data.avatarThumb ? data.avatarThumb : data.avatar,
                    settings: {
                        viewanonym: (data.viewanonym == null ? true : data.viewanonym),
                        viewunverified: (data.viewunverified == null ? true : data.viewunverified),
                    }
                });

                this.viewModel.set({ hideLoadingOnHideOnce: true });
                this.trigger('loggedIn');
            },
            (jqXHRorTextError, textStatus, errorThrown) => {
                this.hideLoading();
                if (jqXHRorTextError == "The user canceled the sign-in flow."
                    || jqXHRorTextError == AppleSignIn.UnSupported
                ) {

                } else {
                    if (_.isUndefined(textStatus) && _.isUndefined(errorThrown) && _.isString(jqXHRorTextError)) {
                        this.showError(
                            jqXHRorTextError,
                            undefined, undefined,
                            { dontSendToGaAndSentry: true }
                        );
                    } else {
                        this.showError(jqXHRorTextError, textStatus, errorThrown);
                    }
                }
            }
        );
    },

    showHelp(e) {
        e.preventDefault();
        new PasswordResetModal({
            email: this.ui.inEmail.val()
        }).show();
    },

    onBeforeHide() {
        if (this.viewModel.get('hideLoadingOnHideOnce')) {
            this.viewModel.set({ hideLoadingOnHideOnce: false });
            this.hideLoading(500);
        }
        this.viewModel.set('afterLoginCallback', null);
    }
});


export default LoginPage;
