import { ItemView } from '@b2cmessenger/backbone';

import './BrandItem.scss';
import template from './BrandItem.jade';

import getNextPrettyColor from 'utils/randomPrettyColor';

let BrandItem = ItemView.extend({
    template: template,

    tagName: "li",
    className: "brand-item brand new",

    modelEvents: {
        'change': 'onModelChange'
    },

    events: {
        'click': 'onClick'
    },

    onRender() {
        this.el.setAttribute('data-id', this.model.id);
        this.el.style['border-color'] = getNextPrettyColor(this.model.get('name'));
    },

    onClick() {
        this.trigger('brand:click', this.model.id);
    },

    onModelChange() {
        this.render();
    },

    serializeData() {
        let data = _.clone(this.model.attributes);
        data.logoThumb = data.logoThumb || 'common_res/icon/other/house.png';

        return data;
    }
});

export default BrandItem;
