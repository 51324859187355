import { Model } from '@b2cmessenger/backbone';

import Window from 'windows/Window';
import HeaderView from 'widgets/Header/Header';
import FooterView from 'widgets/Footer/Footer';
import './Notifications.scss';
import NotificationCollectionView from 'widgets/Notifications/NotificationCollection';

const Notifications = Window.extend({
    show(options) {
        _.extend(this.options, _.defaults(options || (options = {}), {
            scrollToNotification: false
        }));

        return Window.prototype.show.apply(this, arguments);
    },

    onShow() {
        if (this.options.scrollToNotification && this.content.hasView()) {
            const collectionView = this.content.currentView,
                notificationId = _.isObject(this.options.scrollToNotification)
                    ? this.options.scrollToNotification.id
                    : this.options.scrollToNotification;

            if (collectionView && notificationId) {
                const view = collectionView.children.find(v => v && v.model && v.model.id == notificationId);
                if (view && view.el) {
                    view.el.scrollIntoCenter();
                }
            }
        }
    },

    windowName: "notifications-window",

    regions: {
        header: '[data-js-header]',
        footer: '[data-js-footer]',
        content: '[data-js-content]'
    },

    bindings: {
        ':el': 'classes:{"has-notifications-to-read":hasNotificationsToRead,empty:isEmpty}'
    },

    filter: () => true,

    initialize(options) {
        if (!this.collection) {
            if (!options.collection) throw new Error("Empty notification collection!");

            this.collection = options.collection;
        }

        if (_.isFunction(options.filter)) {
            this.filter = options.filter;
        }

        this.viewModel = new Model({
            hasNotificationsToRead: !!this.collection.filter(this.filter).find(m => m.get('canBeRead')),
            isEmpty: !this.collection.filter(this.filter).length
        });

        this.viewModel.listenTo(this.collection, 'reset update change:status', () => this.viewModel.set({
            hasNotificationsToRead: !!this.collection.filter(this.filter).find(m => m.get('canBeRead')),
            isEmpty: !this.collection.filter(this.filter).length
        }));
    },

    onRender() {
        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: 'Notifications'
        })
        this.listenTo(headerView, 'back:click', view => this.cancel());
        this.header.show(headerView);

        let footerView = new FooterView({
            buttons: [{
                id: 'mark',
                text: 'MARK ALL AS READ',
                icn: 'empty'
            }]
        });
        this.listenTo(footerView, 'mark:click', view => this.collection.markAllAsRead({
            filter: this.filter,
            error: (m, xhr) => this.onError(xhr, xhr.statusText),
            //success: (data, models) => _(this.collection.remove(models)).each(m => m.destroy())
        }));
        this.footer.show(footerView);

        this.content.show(new NotificationCollectionView({
            collection: this.collection,
            page: this,
            filter: m => {
                return NotificationCollectionView.prototype.filter.call(null, m) && this.filter(m);
            }
        }));
    },

    onError(jqXHR, textStatus, errorThrown) {
        this.viewModel.set('disabled', false);

        this.showError(jqXHR, textStatus, errorThrown);
    }
});

export default Notifications;
