import { history, Model, Events } from '@b2cmessenger/backbone';
import settings from 'settings';

////////////////////
//////////////////////////////////////////////
//////////

import Page from 'pages/Page';
import Window from 'windows/Window';
import { showError } from 'windows/Modal/Info';
import NotificationCollection from 'models/NotificationCollection';
import KeyboardService from 'utils/KeyboardService';
import LoginPage from 'pages/Login/Login';
import PlaceSearchPage from './pages/PlaceSearch/PlaceSearch';
import PlacePage from 'pages/Place/Place';
import ProfileEditorPage from 'pages/ProfileEditor/ProfileEditor';
import EvidenceListPage from 'pages/EvidenceList/EvidenceList';
import EvidenceModel from 'models/EvidenceModel';
import TaskEditorPage from 'pages/TaskEditor/TaskEditor';
import TaskListPage from 'pages/TaskList/TaskList';
import TaskPage from "pages/TaskPage/TaskPage";
import LoyaltyCardsListPage from 'pages/LoyaltyCardsList/LoyaltyCardsList';
import BrandLoyaltyCardsPage from 'pages/BrandLoyaltyCards/BrandLoyaltyCards';
import OtherUserPage from 'pages/OtherUser/OtherUser';
import NotificationsWindow from 'windows/Notifications/Notifications';
import UserSearchPage from 'pages/UserSearch/UserSearch';
import BroadcastCampaignsPage from 'pages/BroadcastCampaigns/BroadcastCampaigns';
import BroadcastCampaignPage from 'pages/BroadcastCampaign/BroadcastCampaign';
import WorkplacePage from 'pages/Workplace/Workplace';
import ReservationsPage from 'pages/Reservation/ReservationsPage';
import MenuPage from 'components/page/Menu/Menu';
import LoyaltyKioskPage from 'pages/LoyaltyKiosk/LoyaltyKiosk';
import SettingsWindow from 'windows/Settings/Settings';
import LeftMenuWindow from "./windows/LeftMenu/LeftMenu";
import { removeWindowFromFragment, splitFragment } from './RouterUtils';
import GoogleAnalytics from "utils/GoogleAnalytics";
import PushService from './PushService';
import MenuItemPage from "components/page/MenuItemDetail/MenuItemDetail";
import MenuCartPage from "components/page/MenuCart/MenuCart";
import MenuOrdersPage from "components/page/MenuOrders/MenuOrders";
import PlaceMenuOrdersPage from "components/page/MenuOrders/PlaceMenuOrders";
import MenuSettingsPage from "components/page/MenuSettings/MenuSettings";
import MenuTablesPage from 'components/page/MenuTables/MenuTables';
import MenuSectionsPage from "components/page/MenuSections/MenuSections";
import MenuSectionPage from "components/page/MenuSection/MenuSection";
import MenuDashboardPage from "components/page/MenuDashboard/MenuDashboard";
import NotificationModel, { getNotificationCategoryName, isOrderNoticeForEmployee } from "models/NotificationModel";
import MenuOrderWindow, { MenuOrderWindowContentType } from "components/window/MenuOrder/MenuOrder";
import store from "store/index";
import {scanMenuTable} from "store/menu/actions";
import {updateOrdersDueToNotifications, upsertOrder} from "store/menu/orders/actions";
import { addNotifications } from "store/app/actions";
import getMenuPermissions from "utils/getMenuPermissions";
import MenuWaitersPage from "components/page/MenuWaiters/MenuWaiters";
import MenuWaiterCallWindow from "components/window/MenuWaiterCall/MenuWaiterCall";

class Controller {
    constructor() {
        _.extend(this, Events);

        this.notificationCollection = new NotificationCollection();
        this.pageStack = [];
        this.windowStack = [];
        this.mainPage = null;
        this.inited = false;
    }

    start(router) {
        return new Promise((resolve, reject) => {
///////////////////////////
/////////////////////////////////////////////////////////////
//////////////////////

            LoginedUserHandler.initFromLocalStorage();

///////////////////////////
////////////////////////////////////////////////////////
//////////////////////
            Initer.init(
                () => {
///////////////////////////////////
//////////////////////////////////////////////////////////////
//////////////////////////////

                    this.inited = true;
                    this.trigger('controller:inited');
                },
                () => {
///////////////////////////////////
////////////////////////////////////////////////////////////////////////
//////////////////////////////
                    try {
                        this.router = router;
                        this._initPages();
                        this._initNotificationCollection();

                        if (LoginedUserHandler.isUserLogined()) {
                            const defaultWorkPlace = LoginedUserHandler.get_default_work_place();
                            if (defaultWorkPlace) {
                                if (LoginedUserHandler.isLoyaltyKioskModeEnabledForDefaultWorkPlace()) {
                                    this.mainPage = this.loyaltyKioskPage;
                                    this.router.navigate('loyalty_kiosk', { replace: true });
                                } else {
                                    this.mainPage = this.workplacePage;
                                    this._goToWorkplacePage({ placeId: defaultWorkPlace.id });
                                }
                            } else {
                                this.mainPage = this.placeSearchPage;
                                this._goToPlaceSearchPage();
                            }
                        } else {
                            this.mainPage = this.placeSearchPage;
                            this.router.navigate('login', { replace: true });
                            this._goToLoginPage();
                        }

                        $.mobile.pageContainer.addClass('page-shown');

                        history.start();

                        if (LoginedUserHandler.isLoyaltyKioskModeEnabledForDefaultWorkPlace()) {
                            this.goToLoyaltyKioskPage();
                        }

////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
//////////////////////////////////

///////////////////////////////////////
                        $(document).on({
                                focus: e => {
                                    const event = new Event("keyboardDidShow");
                                    event.keyboardHeight = 100;
                                    window.dispatchEvent(event);
                                },
                                blur: e => {
                                    window.dispatchEvent(new Event("keyboardDidHide"));
                                }
                            },
                            'textarea,input[type="text"],input[type="color"],input[type="datetime-local"],' +
                            'input[type="date"],input[type="time"],input[type="text"],' +
                            'input[type="email"],input[type="month"],input[type="number"],' +
                            'input[type="password"],input[type="search"],input[type="tel"],' +
                            'input[type="url"],input[type="week"],input[type="text"]'
                        );
//////////////////////////////////

///////////////////////////////////////
///////////////////////////////////////////////////////////////////////
//////////////////////////////////

                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                }
            );
        });
    }

    restart() {
        this.taskListPage.markAsDirty();

        return new Promise((resolve, reject) => {
            Initer.init(() => {
                if (LoginedUserHandler.getLoginedUser()) {
                    if (GoogleAnalytics) {
                        GoogleAnalytics.setUserId(LoginedUserHandler.getLoginedUserId());
                        GoogleAnalytics.addCustomDimension(1, LoginedUserHandler.getLoginedUserId(), () => {
                        }, () => {
                        });
                        GoogleAnalytics.addCustomDimension(2, (LoginedUserHandler.get_default_work_place_id() ? LoginedUserHandler.get_default_work_place_id() : -1), () => {
                        }, () => {
                        });
                    }

                    if (window.Sentry) {
                        Sentry.configureScope(scope => {
                            scope.setUser({
                                id: LoginedUserHandler.getLoginedUserId(),
                                email: LoginedUserHandler.getLoginedUser().email,
                                username: LoginedUserHandler.getLoginedUserName()
                            });
                        });
                    }
                }

                const defaultWorkPlace = LoginedUserHandler.get_default_work_place();

                if (LoginedUserHandler.isUserLogined() && defaultWorkPlace) {
                    if (LoginedUserHandler.isLoyaltyKioskModeEnabledForDefaultWorkPlace()) {
                        this.mainPage = this.loyaltyKioskPage;
                    } else {
                        this.mainPage = this.workplacePage;
                    }
                } else {
                    this.mainPage = this.placeSearchPage;
                }

///////////////////////////////
                if (this.leftMenuWindow) {
                    this.leftMenuWindow.refresh();
                }
//////////////////////////

                const afterLoginCallback = this.loginPage.viewModel.get('afterLoginCallback');
                if(_.isFunction(afterLoginCallback)){
                    this.loginPage.cancel();
                    afterLoginCallback();
                }else{
                    this.pageStack.length = 0;
                    this.index();
                }

                resolve();
            }, null, reject);
        });
    }

    index() {
        if (LoginedUserHandler.isUserLogined()) {
            const defaultWorkPlace = LoginedUserHandler.get_default_work_place();
            if (defaultWorkPlace) {
                this.goToWorkplacePage({ placeId: defaultWorkPlace.id, replace: true });
            } else {
                this.goToPlaceSearchPage({ replace: true });
            }
        } else {
            this.router.navigate('login', { replace: true });
            this._goToLoginPage();
        }
    }


    places() {
        this.goToPlaceSearchPage();
    }

    my_reservations() {
        this.goToMyReservations();
    }

    place_reservations(placeId) {
        this.goToPlaceReservations({ placeId })
    }

    workplace(placeId) {
        this.goToWorkplacePage({ placeId });
    }

    login(email) {
        this.goToLoginPage({ email });
    }

    profile() {
        this.goToProfileEditorPage();
    }

    notifications() {
        this.showNotificationsWindow();
    }

    loyalty_cards(brandId) {
        if (Number(brandId)) {
            this.goToLoyaltyCardsPage({ brandId });
        } else {
            this.goToLoyaltyCardsPage();
        }
    }

    place(id) {
        id = Number(id);

        if (id) {
            this._goToPlacePage({ place: { id } });
        } else {
            this.index();
        }
    }

    place_tasks(placeId) {
        placeId = Number(placeId);

        if (placeId) {
            this.goToPlaceTasksPage({
                placeId
            });
        } else {
            this.index();
        }
    }

    broadcasts(placeId) {
        this.goToBroadcastCampaignsPage({
            placeId
        });
    }

    task_editor(placeId, taskId, options) {
        //TODO: fetch place and task queued
        if (placeId) {
            placeId = Math.max(Number(placeId), 1) || 1;
            if (taskId == "new") {
                this.goToPlacePage({
                    place: { id: placeId },
                    openNewTask: _.extend({}, options),
                    replace: true,
                });
            } else {
                this.goToPlacePage({ place: { id: placeId }, replace: true });
            }
        } else {
            this.index();
        }
    }

    task_editor_broadcast(placeId, taskId, targetId) {
        return this.task_editor(placeId, taskId, {
            isBroadcast: true,
            openTargetChooser: targetId && {
                targetId
            }
        });
    }

    evidences(placeId, type) {
        placeId = Number(placeId);
        if (placeId) {
            this.goToEvidencesPage({ place: { id: placeId }, type });
        } else {
            this.index();
        }
    }


    broadcast(id) {
        id = Number(id);

        if (id) {
            this.goToBroadcastCampaignPage({ campaign: id });
        } else {
            this.goToBroadcastCampaignsPage();
        }
    }

    search_client(placeId) {
        this.goToUserSearchPage({ placeId });
    }

    client(placeId, userId) {
        if (placeId && userId) {
            this.goToOtherUserPage({ placeId, userId });
        } else if (placeId) {
            placeId = Math.max(Number(placeId), 1) || 1;
            this.goToUserSearchPage({ placeId, replace: true });
        } else {
            this.index();
        }
    }

    menu(placeId, categoryId = null) {
        this.goToMenuPage({ placeId, categoryId });
    }

    place_table(placeId, tableId) {
        store.dispatch(scanMenuTable(placeId, tableId));
    }

    menuItem(placeId, itemId = null) {
        this.goToMenuItemPage({ placeId, itemId });
    }

    menuCart(placeId) {
        this.goToMenuCart({ placeId });
    }

    menu_dashboard(placeId) {
        this.goToMenuDashboard({ placeId });
    }

    menu_settings(placeId) {
        this.goToMenuSettings({ placeId });
    }

    menu_sections(placeId) {
        this.goToMenuSections({ placeId });
    }

    menu_waiters(placeId) {
        this.goToMenuWaitersPage({ placeId })
    }

    menu_section(placeId, sectionId) {
        this.goToMenuSection({ placeId, sectionId });
    }

    menu_orders() {
        this.goToOrdersPage();
    }

    place_menu_orders(placeId) {
        this.goToPlaceOrdersPage({ placeId });
    }

    place_menu_tables(placeId) {
        this.goToPlaceTablesPage({ placeId });
    }

    loyalty_kiosk() {
        this.goToLoyaltyKioskPage();
    }

    tasks() {
        this.goToTasksPage();
    }

    task(taskId, commentId) {
        if (taskId) {
            this.goToTaskPage({
                taskId,
                commentId,
                includeReservation: location.hash.substr(1).indexOf('reservation/') === 0
            });
        }
    }

    settings() {
        new SettingsWindow().show();
    }

    debug() {
////////////////////////////
/////////////////////////////////////////////////////
/////////////////
        this.router.navigate('', { trigger: true });
//////////////////
    }

    tests(suite, scrolltorunning) {
////////////////////////////
/////////////////////////////////////////
///////////////////////////////////
//////////////////////////
////////////////
///////////////////////////////////////////////////////////////
////////////////////////////////////////////////
/////////

/////////////////////
/////////////////////////////////
//////////////////////////////////////
////////////////////////////////////////////////////
/////////////////////////
/////////////////
//////////
/////////////////
        this.router.navigate('', { trigger: true });
//////////////////
    }

    getCurrentPage() {
        return _.last(this.pageStack);
    }

    getPreviosPageOrWindow(obj) {
        const overlayList = [];

        const page = app.controller.getCurrentPage();
        if (page) {
            overlayList.push(page);
        }

        if (app.controller.leftMenuWindow && !app.controller.leftMenuWindow.isDestroyed) {
            overlayList.push(app.controller.leftMenuWindow);
        }

        const pageScopedWindowStack = page && page.getPageScopedWindowStack();

        if (pageScopedWindowStack && pageScopedWindowStack.length) {
            const windowIndex = _.indexOf(pageScopedWindowStack, obj);
            const prevWindow = pageScopedWindowStack[windowIndex != -1 ?
                windowIndex - 1 :
                pageScopedWindowStack.length - 1];

            if (prevWindow) {
                overlayList.push(prevWindow);
            }
        } else {
            const topRegularWindow = Window.getTopRegularWindow();

            if (topRegularWindow) {
                overlayList.push(topRegularWindow);
            }
        }

        if (obj && obj.isModalWindow) {
            const previousModalIdx = _.findIndex(app.controller.windowStack, w => w.isModalWindow && w !== obj);

            if (previousModalIdx !== -1 && app.controller.windowStack.indexOf(obj) - 1 == previousModalIdx) {
                overlayList.push(app.controller.windowStack[previousModalIdx]);
            }
        }

        return overlayList;
    }

    goToPlaceSearchPage(options) {
        _.defaults(options || (options = {}));

        this.router.navigate(`places`, { replace: options.replace });
        this._goToPlaceSearchPage(options);
    }

    goToMyReservations(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            _.extend(options, { userId: LoginedUserHandler.getLoginedUserId() })
        }

        this.router.navigate('my_reservations', { replace: options.replace });
        this._goToMyReservations(options);
    }

    goToPlaceReservations(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            const placeId = LoginedUserHandler.get_work_place_by_id(options.placeId) &&
                (options.placeId && Number(options.placeId)) ||
                LoginedUserHandler.get_default_work_place_id();

            if (placeId) {
                _.extend(options, { placeId });

                this.router.navigate(`place_reservations/${placeId}`, { replace: options.replace });
                this._goToPlaceReservations(options);
            } else {
                this.index();
            }
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToLoginPage(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            this.index();
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToProfileEditorPage(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            this.router.navigate('profile', { replace: options.replace });
            this._goToProfileEditorPage();
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    showNotificationsWindow(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            // this.router.navigate('notifications', { replace: options.replace });
            this._showNotificationsWindow(options);
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToLoyaltyCardsPage(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            if (options.brandId || options.brand && options.brand.id) {
                this.router.navigate(`loyalty_cards/brand/${options.brandId || options.brand && options.brand.id}`, { replace: options.replace });
            } else {
                this.router.navigate('loyalty_cards', { replace: options.replace });
            }
            this._goToLoyaltyCardsPage(options);
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToPlacePage(options) {
        _.defaults(options || (options = {}));

        if (!options.place || !options.place.id) {
            console.warn('incorrect options.place');
            this.index();
        } else {
            this.router.navigate(`place/${options.place.id}`, { replace: options.replace });
            this._goToPlacePage(options);
        }
    }

    goToWorkplacePage(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            const placeId = LoginedUserHandler.get_work_place_by_id(options.placeId) && options.placeId || LoginedUserHandler.get_default_work_place_id();

            if (placeId) {
                _.extend(options, { placeId });

                this.router.navigate(`place/${placeId}/workplace`, { replace: options.replace });
                this._goToWorkplacePage(options);
            } else {
                this.index();
            }
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToPlaceTasksPage(options) {
        _.defaults(options || (options = {}), {
            optionalLoad: true
        });

        if (options.placeId || options.place && options.place.id) {
            const placeId = options.place && options.place.id || options.placeId;
            this.router.navigate(`place/${placeId}/tasks`, { replace: options.replace });
            this._goToPlaceTasksPage(options);
        } else if (options.backFrom && !this.taskListPage.isSingleUserMode()
            && !this.taskListPage.isSingleTaskMode() && !this.taskListPage.isMultiPlaceMode()
            && this.taskListPage.getSinglePlaceId()) {
            const placeId = this.taskListPage.getSinglePlaceId();

            _.extend(options, {
                placeId
            });

            this.router.navigate(`place/${placeId}/tasks`, { replace: options.replace });
            this._goToPlaceTasksPage(options);
        } else {
            console.warn('incorrect options.place or options.placeId');
            this.index();
        }
    }

    goToTaskEditorPage(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            if (options.backFrom) {
                const placeId = this.taskEditorPage.getPlaceId(),
                    taskId = this.taskEditorPage.getTaskId() || 'new',
                    isBroadcast = this.taskEditorPage.isBroadcast();

                this.router.navigate(
                    `place/${placeId}/task/${taskId}${isBroadcast ? '/broadcast' : ''}`,
                    { replace: options.replace }
                );
                this._goToTaskEditorPage(options);
            } else if (!options.place) {
                console.warn('incorrect options.place');
                this.index();
            } else if (!options.new && !options.task) {
                console.warn('incorrect options.task and options.new is false');
                this.router.navigate(`place/${options.place.id}`, { replace: options.replace });
                this.goToPlacePage({
                    place: options.place
                });
            } else {
                const placeId = options.place.id,
                    taskId = options.new && 'new' || options.task && (options.task.id || options.task.message.id),
                    isBroadcast = options.isBroadcast || options.onlyBroadcast,
                    isChoosingTarget = options.openTargetChooser,
                    targetId = options.openTargetChooser && options.openTargetChooser.targetId

                this.router.navigate(
                    `place/${placeId}/task/${taskId}${
                        isBroadcast ?
                            `/broadcast${options.openTargetChooser ? `/target/${targetId}` : ''}`
                            : ''
                        }`,
                    { replace: options.replace }
                );
                this._goToTaskEditorPage(options);
            }
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToEvidencesPage(options) {
        _.defaults(options || (options = {}));

        if (!options.place || !options.place.id) {
            console.warn('incorrect options.place');
            this.index();
        } else {
            options.type = Number(options.type);
            switch (options.type) {
                case EvidenceModel.Type.Selfie:
                case EvidenceModel.Type.Receipt:
                case EvidenceModel.Type.Other:
                    break;
                default:
                    options.type = EvidenceModel.Type.Other;
            }

            this.router.navigate(`place/${options.place.id}/evidences/${options.type}`, { replace: options.replace });
            this._goToEvidencesPage(options);
        }
    }

    goToBroadcastCampaignsPage(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            const placeId = Number(options.placeId || LoginedUserHandler.get_default_work_place_id());
            if (placeId) {
                _.extend(options, { placeId });

                this.router.navigate(`place/${placeId}/broadcasts`, { replace: options.replace });
                this._goToBroadcastCampaignsPage(options);
            } else {
                console.warn('incorrect options.placeId');
                this.index();
            }
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToBroadcastCampaignPage(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            if (options.campaign instanceof Model && options.campaign.id) {
                this.router.navigate(`broadcast/${options.campaign.id}`, { replace: options.replace });
                this._goToBroadcastCampaignPage(options);
            } else if (_.isNumber(options.campaign) || _.isString(options.campaign)) {
                this.router.navigate(`broadcast/${options.campaign}`, { replace: options.replace });
                this._goToBroadcastCampaignPage(options);
            } else {
                this.router.navigate('broadcasts', { replace: options.replace });
                this._goToBroadcastCampaignsPage();
            }
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToUserSearchPage(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            const placeId = LoginedUserHandler.get_work_place_by_id(options.placeId) && options.placeId || LoginedUserHandler.get_default_work_place_id();

            if (placeId) {
                _.extend(options, { placeId });

                this.router.navigate(`place/${placeId}/client/search`, { replace: options.replace });
                this._goToUserSearchPage(options);
            } else {
                this.index();
            }
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToOtherUserPage(options) {
        _.defaults(options || (options = {}), {
            placeId: undefined
        });

        if (LoginedUserHandler.isUserLogined()) {
            if (options.place && options.user) {
                this.router.navigate(`place/${options.place.id}/client/${options.user.id}`, { replace: options.replace });
                this._goToOtherUserPage(options);
            } else if (options.placeId && options.userId) {
                this.router.navigate(`place/${options.placeId}/client/${options.userId}`, { replace: options.replace });
                this._goToOtherUserPage(options);
            } else {
                const userId = this.otherUserPage.getUserId(),
                    placeId = this.otherUserPage.getPlaceId();

                if (placeId && userId) {
                    this.router.navigate(`place/${placeId}/client/${userId}`, { replace: options.replace });
                    this._goToOtherUserPage(options);
                } else {
                    this.index();
                }
            }
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToMenuPage(options) {
        _.defaults(options || (options = {}));

        if (!_.has(options, 'categoryId')) {
            _.extend(options, { categoryId: null });
        }

        const path = ['place', options.placeId, 'menu'];

        if (options.categoryId) {
            path.push(options.categoryId);
        }

        this.router.navigate(path.join('/'), { replace: options.replace });
        this._goToMenuPage(options);
    }

    goToMenuItemPage(options) {
        _.defaults(options || (options = {}));

        const path = ['place', options.placeId, 'menu'];

        if (options.itemId) {
            path.push('item', options.itemId);
        }

        this.router.navigate(path.join('/'), { replace: options.replace });
        this._goToMenuItemPage(options);
    }

    goToOrderFromNotification(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            const { notification = {} } = options;
            const templateType = notification.get('template_type');
            const data = notification.get('data') || {};
            const { place_id: placeId, order_id: orderId } = data;

            _.extend(options, {
                showOrderWindowWithId: orderId,
                placeId,
                forceOpenWaiterCallForm: templateType === NotificationModel.Type.MenuWaiterCall,
            });

            if (!placeId || !orderId) {
/////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////
                return;
            }

            if (isOrderNoticeForEmployee(templateType)) {
                this.goToPlaceOrdersPage(options)
            } else {
                this.goToOrdersPage(options);
            }
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToMenuCart(options) {
        _.defaults(options || (options = {}));
        LoginedUserHandler.ensureUserLoggedIn(() => {
            const path = ['place', options.placeId, 'menu', 'cart'];

            this.router.navigate(path.join('/'), { replace: options.replace });
            this._goToMenuCartPage(options);
        });
    }

    goToMenuDashboard(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            const { isWaiter, isOrderManager } = getMenuPermissions(options.placeId);

            if (!(isWaiter || isOrderManager)) {
                this.index();
                return;
            }

            const path = ['place', options.placeId, 'menu', 'dashboard'];

            this.router.navigate(path.join('/'), { replace: options.replace });
            this._goToMenuDashboardPage(options);
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToMenuSettings(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            const path = ['place', options.placeId, 'menu', 'settings'];

            this.router.navigate(path.join('/'), { replace: options.replace });
            this._goToMenuSettingsPage(options);
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToMenuSections(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            const path = ['place', options.placeId, 'menu', 'sections'];

            this.router.navigate(path.join('/'), { replace: options.replace });
            this._goToMenuSectionsPage(options);
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToMenuSection(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            const path = ['place', options.placeId, 'menu', 'sections', options.sectionId];

            this.router.navigate(path.join('/'), { replace: options.replace });
            this._goToMenuSectionPage(options);
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToMenuWaitersPage(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            const { isOrderManager } = getMenuPermissions(options.placeId);

            if (!isOrderManager) {
                this.index();
                return;
            }


            const path = ['place', options.placeId, 'menu', 'waiters'];

            this.router.navigate(path.join('/'), { replace: options.replace });
            this._goToMenuWaitersPage(options);
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToOrdersPage(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            this.router.navigate('menu_orders', { replace: options.replace });
            this._goToOrdersPage(options);
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToPlaceOrdersPage(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            const { isWaiter, isOrderManager, isMenuManager } = getMenuPermissions(options.placeId);

            if (!(isWaiter || isOrderManager || isMenuManager)) {
                this.index();
                return;
            }

            const path = ['place', options.placeId, 'menu', 'orders'];

            this.router.navigate(path.join('/'), { replace: options.replace });
            this._goToPlaceOrdersPage(options);
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToPlaceTablesPage(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            const path = ['place', options.placeId, 'menu', 'tables'];

            this.router.navigate(path.join('/'), { replace: options.replace });
            this._goToPlaceTablesPage(options);
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToLoyaltyKioskPage(options) {
        _.defaults(options || (options = LoginedUserHandler.getLoyaltyKioskOptions()));

        if (LoginedUserHandler.isUserLogined()) {
            _.defaults(options, {
                defaultWorkPlace: LoginedUserHandler.get_default_work_place()
            });

            this.router.navigate('loyalty_kiosk', { replace: options.replace });
            this._goToLoyaltyKioskPage(options);
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToTasksPage(options) {
        _.defaults(options || (options = {}));

        if (LoginedUserHandler.isUserLogined()) {
            _.defaults(options, {
                userId: LoginedUserHandler.getLoginedUserId()
            });

            this.router.navigate('tasks', { replace: options.replace });
            this._goToTasksPage(options);
        } else {
            this.router.navigate('login', { replace: options.replace });
            this._goToLoginPage(options);
        }
    }

    goToTaskPage(options) {
        _.defaults(options || (options = {}));

        let taskId = Number(options.taskId);
        const commentId = options.commentId;
        const pageName = options.includeReservation ? 'reservation' : 'task';

        if (!taskId && options.backFrom) {
            taskId = this.taskListPage.getSingleTaskId();
        }

        if (taskId) {
            if (options.commentId) {
                _.extend(options, {
                    taskId,
                    scrollToComment: commentId
                });
                this.router.navigate(`${pageName}/${taskId}/${options.commentId}`, { replace: options.replace });
                this._goToTaskPage(options);
            } else {
                _.extend(options, {
                    taskId
                });
                this.router.navigate(`${pageName}/${taskId}`, { replace: options.replace });
            }
            this._goToTaskPage(options);
        } else {
            this.goToTasksPage();
        }
    }

    goToTaskInClosestPage(options) {
        const taskId = Number(options.taskId);

        if (taskId) {
            if (this.taskListPage.hasTask(taskId)) {
                if (this.taskListPage.isSingleUserMode()) {
                    this.goToTasksPage(_.extend(options, {
                        userId: this.taskListPage.getSingleUserId(),
                        scrollToTask: taskId
                    }));
                } else if (this.taskListPage.isSingleTaskMode()) {
                    this.goToTaskPage(_.extend(options, {
                        taskId
                    }));
                } else if (this.taskListPage.getSinglePlaceId()) {
                    this.goToPlaceTasksPage(_.extend(options, {
                        placeId: this.taskListPage.getSinglePlaceId(),
                        scrollToTask: taskId
                    }));
                } else {
                    console.error('incorrect taskListPage state');
                }
            } else {
                this.goToTaskPage(_.extend(options, {
                    taskId
                }));
            }
        } else {
            console.warn('incorrect options.taskId');
            if (this.taskListPage.isSingleUserMode()) {
                this.goToTasksPage({ userId: this.taskListPage.getSingleUserId() });
            } else if (this.taskListPage.isSingleTaskMode()) {
                this.goToTaskPage(options);
            } else {
                this.goToPlaceTasksPage(options);
            }
        }
    }

    _goToPlaceSearchPage(options) {
        this.placeSearchPage.show(options);
    }

    _goToLoginPage(options) {
        this.loginPage.show(options);
    }

    _goToProfileEditorPage() {
        this.profileEditorPage.show();
    }

    _showNotificationsWindow(options) {
        new NotificationsWindow({ collection: this.notificationCollection }).show(options);
    }

    _goToLoyaltyCardsPage(options) {
        if (options.brandId || options.brand && options.brand.id) {
            this.brandLoyaltyCardsPage.show(options);
        } else {
            this.loyaltyCardsListPage.show();
        }
    }

    _goToPlacePage(options) {
        this.placePage.show({
            place: options.dontSetPlace ? null : options.place,
            openNewTask: options.openNewTask,
        });
    }

    _goToWorkplacePage(options) {
        this.workplacePage.show(options);
    }

    _goToPlaceTasksPage(options) {
        this.taskListPage.show(options);
    }

    _goToTaskEditorPage(options) {
        this.taskEditorPage.show(options);
    }

    _goToEvidencesPage(options) {
        this.evidenceListPage.show(options);
    }

    _goToBroadcastCampaignsPage(options) {
        this.broadcastCampaignsPage.show(options);
    }

    _goToBroadcastCampaignPage(options) {
        this.broadcastCampaignPage.show(options);
    }

    _goToUserSearchPage(options) {
        this.userSearchPage.show(options);
    }

    _goToOtherUserPage(options) {
        this.otherUserPage.show(options);
    }

    _goToMenuPage(options) {
        this.menuPage.show(options);

        if (options.showMenuWaiterCallWindow) {
            new MenuWaiterCallWindow().show();
        }
    }

    _goToMenuItemPage(options) {
        this.menuItemPage.show(options);
    }

    _goToMenuCartPage(options) {
        this.menuCartPage.show(options);
    }

    _goToMenuDashboardPage(options) {
        this.menuDashboardPage.show(options);
    }

    _goToMenuSettingsPage(options) {
        this.menuSettingsPage.show(options);
    }

    _goToMenuSectionsPage(options) {
        this.menuSectionsPage.show(options);
    }

    _goToMenuSectionPage(options) {
        this.menuSectionPage.show(options);
    }

    _goToMenuWaitersPage(options) {
        this.menuWaitersPage.show(options);
    }

    _goToOrdersPage(options) {
        this.menuOrdersPage.show(options);

        if (options.showOrderWindowWithId) {
            new MenuOrderWindow({
                orderId: options.showOrderWindowWithId,
                displayOrderActions: options.displayOrderActions,
                windowContentType: MenuOrderWindowContentType.User,
                onChangeOrder: this.menuOrdersPage.changeOrder.bind(this.menuOrdersPage),
                forceOpenWaiterCallForm: options.forceOpenWaiterCallForm || false,
            }).show();
        }
    }

    _goToPlaceOrdersPage(options) {
        this.placeMenuOrdersPage.show(options);

        if (options.showOrderWindowWithId) {
            new MenuOrderWindow({
                placeId: options.placeId,
                orderId: options.showOrderWindowWithId,
                windowContentType: MenuOrderWindowContentType.Place,
                onChangeOrder: order => store.dispatch(upsertOrder(order)),
            }).show();
        }
    }

    _goToPlaceTablesPage(options) {
        this.placeMenuTablesPage.show(options);
    }

    _goToLoyaltyKioskPage(options) {
        this.loyaltyKioskPage.show(options);
    }

    _goToTasksPage(options) {
        this.taskListPage.show({
            multi_place_mode: true,
            singleUserMode: true,
            filter: { user_id: options.userId },
            scrollToTask: options.scrollToTask,
            scrollToComment: options.scrollToComment,
            scrollToSubComment: options.scrollToSubComment
        });
    }

    _goToTaskPage(options) {
        this.taskPage.show({
            multi_place_mode: true,
            taskId: options.taskId,
            reload: options.reload,
            scrollToTask: options.scrollToTask,
            scrollToComment: options.scrollToComment,
            scrollToSubComment: options.scrollToSubComment,
            includeReservation: options.includeReservation
        });
    }

    _goToMyReservations(options) {
        this.myReservationsPage.show(options);
    }

    _goToPlaceReservations(options) {
        console.log('_goToPlaceReservations() options', options);
        this.placeReservationsPage.show(options);
    }

    _restoreWindowState(routeName, args, callback) {
        const fragment = history.getFragment();
        const page = this.getCurrentPage();
        const { path, windows } = splitFragment(null, true);

        if (page && this._hasPageOrWindowSomeWindowMap(page, windows)) {
            page.triggerMethod('restore:windows:state', windows, { routeName, args });
        } else if (this.leftMenuWindow && this._hasPageOrWindowSomeWindowMap(this.leftMenuWindow, windows)) {
            this.leftMenuWindow.triggerMethod('restore:windows:state', windows, { routeName, args });
        } else if (windows.length) {
            this.router.navigate(fragment, { replace: true });
            this.router.navigate(path, { replace: true });
        }
    }

    _hasPageOrWindowSomeWindowMap(pageOrWindow, windows) {
        return pageOrWindow && pageOrWindow.hasWindowMapping && _.some(windows, w => pageOrWindow.hasWindowMapping(w.name));
    }

    _initNotificationCollection() {
        this.notificationCollection.on(
            'change:newVisibleNotificationsCount',
            _.debounce((c, count) => PushService.setBadgeNumber(count), 100)
        );

        this.notificationCollection.on('update', (collection, options) => {
            if (options.changes) {
                const newNotifications = options.changes.added.map(m => m.attributes);
                if (newNotifications && newNotifications.length > 0) {
                    store.dispatch(addNotifications(newNotifications));
                }

                const newSilentNotifications = _.sortBy(options.changes.added.filter(m => m.get('hidden')), m => m.id);
                const newPermissionChangeNotifications = newSilentNotifications
                    .filter(m => m.get('app_event') === NotificationModel.Type.EmployeePermissionsChange);

                if (newPermissionChangeNotifications.length > 0) {
                    const latestPermissionChangeNotification = _.last(newPermissionChangeNotifications);

                    newSilentNotifications.forEach(m => {
                        if (m.get('app_event') === NotificationModel.Type.EmployeePermissionsChange) {
                            if (m.id === latestPermissionChangeNotification.id) {
                                app.executeSilentNotificationAction(m);
                            } else {
                                m.markAsRead().catch(e => showError(e));
                            }
                        } else {
                            app.executeSilentNotificationAction(m);
                        }
                    })
                } else {
                    newSilentNotifications.forEach(m => {
                        app.executeSilentNotificationAction(m);
                    })
                }
            }
        });

        this.notificationFetcher = {
            fetch: _.debounce(() => {
                if (LoginedUserHandler.isUserLogined() && !app.isPaused) {
                    this.notificationCollection.fetch({
                        remove: false,
                        autoRetryOnUnauthorized: false,
                        onlyNew: true
                    });
                }
            }, settings.notificationFetcher.period / 10, true),

            start() {
                if (!this._intervalId) {
                    this._intervalId = setInterval(this.fetch.bind(this), settings.notificationFetcher.period)
                }

                this.fetch();
            },

            resume() {
                this.start();
            },

            pause() {
                clearInterval(this._intervalId);
                this._intervalId = null;
            }
        };

        this.notificationFetcher.start();
    }

    _applyObjectOverlay(obj, isOverlayed) {
        if (obj && obj.$el) {
            obj.$el.toggleClass('overlayed', isOverlayed);
        }
    }

    _applyModalOverlay() {
        const overlayList = [];

        const page = app.controller.getCurrentPage();

        const lastModalIdx = _.findLastIndex(app.controller.windowStack, w => w.isModalWindow);

        if (lastModalIdx !== -1) {
            page && this._applyObjectOverlay(page, true);
            for (let i = 0; i < app.controller.windowStack.length; i++) {
                this._applyObjectOverlay(app.controller.windowStack[i], i < lastModalIdx);
            }
        } else {
            _.each(this.pageStack, p => this._applyObjectOverlay(p, false));
            _.each(app.controller.windowStack, w => this._applyObjectOverlay(w, false));
        }

        return overlayList;
    }

    _initPages() {
///////////////////////
//////////////////////////////////////////////////////////////
//////////////////

        if (!app.isMobileView) {
            this.leftMenuWindow = new LeftMenuWindow();
            this.leftMenuWindow.show();
        }

        this.listenTo(app, 'change:is:mobile:view', (isMobileView) => {
            if (isMobileView) {
                this.leftMenuWindow.close();
                this.leftMenuWindow.destroy();
            } else {
                this.leftMenuWindow = new LeftMenuWindow();
                this.leftMenuWindow.show();
            }

            this._applyModalOverlay();
        });

        this.on('page:hide page:show window:show window:close', this._applyModalOverlay.bind(this));

/////////////////////
////////////////////////////////////////////////////
//////////////////

        this.placeSearchPage = new PlaceSearchPage();
        this.loginPage = new LoginPage();
        this.profileEditorPage = new ProfileEditorPage();
        this.loyaltyCardsListPage = new LoyaltyCardsListPage();
        this.brandLoyaltyCardsPage = new BrandLoyaltyCardsPage();
        this.placePage = new PlacePage();
        this.evidenceListPage = new EvidenceListPage();
        this.broadcastCampaignsPage = new BroadcastCampaignsPage();
        this.broadcastCampaignPage = new BroadcastCampaignPage();
        this.userSearchPage = new UserSearchPage();
        this.otherUserPage = new OtherUserPage();
        this.taskEditorPage = new TaskEditorPage();
        this.taskListPage = new TaskListPage();
        this.taskPage = new TaskPage();
        this.workplacePage = new WorkplacePage();
        this.myReservationsPage = new (ReservationsPage.extend({
            attributes: { id: `my-reservations-page` },
            className: 'place-reservations-page'
        }))({
            reservationType: 'user'
        });
        this.placeReservationsPage = new (ReservationsPage.extend({
            attributes: { id: `place-reservations-page` },
            className: 'place-reservations-page'
        }))({
            reservationType: 'business'
        });
        this.menuPage = new MenuPage();
        this.menuItemPage = new MenuItemPage();
        this.menuCartPage = new MenuCartPage();
        this.menuDashboardPage = new MenuDashboardPage();
        this.menuSettingsPage = new MenuSettingsPage();
        this.menuSectionsPage = new MenuSectionsPage();
        this.menuSectionPage = new MenuSectionPage();
        this.menuOrdersPage = new MenuOrdersPage();
        this.menuWaitersPage = new MenuWaitersPage();
        this.placeMenuOrdersPage = new PlaceMenuOrdersPage();
        this.placeMenuTablesPage = new MenuTablesPage();
        this.loyaltyKioskPage = new LoyaltyKioskPage();

        this.loginPage.on('skip', event => this._goBackPage() || this.goToPlaceSearchPage());
        this.loginPage.on('loggedIn', () => this.restart());

        //TODO: remove globals!
        window.pagePlacesSearch = this.placeSearchPage;
        window.taskListPage = this.taskListPage;

///////////////////////
////////////////////////////////////////////////////////////
//////////////////

        this.listenTo(LoginedUserHandler, 'change:user_def_work_place_id', (luh, placeId) => {
            const currentPage = _.last(this.pageStack);

            if (currentPage == this.workplacePage) {
                this.goToWorkplacePage({
                    placeId,
                    replace: true
                });
            }
        });
    }

    _showPage(page, options) {
        let prevPage;
        if (options && options.replace) {
            const currentPage = this.pageStack.pop();

            do {
                prevPage = _.last(this.pageStack);
                if (prevPage == currentPage) {
                    this.pageStack.pop();
                }
            } while (prevPage == currentPage && this.pageStack.length);
        }

        $.mobile.pageContainer.pagecontainer('change', '#' + page.el.id, { allowSamePageTransition: true });

        if (_.last(this.pageStack) != page) {
            this.pageStack.push(page);
        }

        this.trigger('page:show', page);

        if (GoogleAnalytics) {
/////////////////////////
//////////////////////////////////////////////////////////////////
/////////////////////
            GoogleAnalytics.trackView(`Page #${page.pageName}`, '', !!(options && options.newSession));
        }

        if (window.Sentry) {
            Sentry.addBreadcrumb({
                message: `show page #${page.pageName}`,
                category: 'page/window',
                data: {
                    prevPage: prevPage && prevPage.pageName,
                }
            });
        }
    }

    _hidePage(page, options) {
        if (this.pageStack.length <= 1) return false;

        const currentPage = this.pageStack.pop();
        let prevPage;

        do {
            prevPage = _.last(this.pageStack);
            if (prevPage == currentPage) {
                this.pageStack.pop();
            }
        } while (prevPage == currentPage && this.pageStack.length)

        this.trigger('page:hide', page);

        if (prevPage) {
            _.extend(options || (options = {}), {
                backFrom: currentPage
            });

            if (prevPage == this.placeSearchPage) {
                this.goToPlaceSearchPage(options)
            } else if (prevPage == this.loginPage) {
                this.goToLoginPage(options);
            } else if (prevPage == this.profileEditorPage) {
                this.goToProfileEditorPage(options);
            } else if (prevPage == this.loyaltyCardsListPage) {
                this.goToLoyaltyCardsPage(options);
            } else if (prevPage == this.brandLoyaltyCardsPage) {
                this.goToLoyaltyCardsPage(_.extend(options, {
                    brandId: this.brandLoyaltyCardsPage.getBrandId()
                }));
            } else if (prevPage == this.placePage) {
                this.goToPlacePage(_.extend(options, {
                    place: { id: this.placePage._getPlaceId() },
                    dontSetPlace: true,
                }));
            } else if (prevPage == this.evidenceListPage) {
                this.goToEvidencesPage(_.extend(options, {
                    place: { id: this.evidenceListPage.getPlaceId() },
                    type: this.evidenceListPage.getType()
                }));
            } else if (prevPage == this.broadcastCampaignsPage) {
                this.goToBroadcastCampaignsPage(_.extend(options, {
                    placeId: this.broadcastCampaignsPage.getPlaceId()
                }));
            } else if (prevPage == this.broadcastCampaignPage) {
                this.goToBroadcastCampaignPage(_.extend(options, {
                    campaign: this.broadcastCampaignPage.getCampaignId()
                }));
            } else if (prevPage == this.userSearchPage) {
                this.goToUserSearchPage(options);
            } else if (prevPage == this.workplacePage) {
                this.goToWorkplacePage(_.extend(options, {
                    placeId: this.workplacePage.getPlaceId()
                }));
            } else if (prevPage == this.otherUserPage) {
                this.goToOtherUserPage(options);
            } else if (prevPage == this.taskEditorPage) {
                this.goToTaskEditorPage(options);
            } else if (prevPage == this.taskListPage) {
                if (this.taskListPage.isSingleUserMode()) {
                    this.goToTasksPage({ userId: this.taskListPage.getSingleUserId() });
                } else if (this.taskListPage.isSingleTaskMode()) {
                    this.goToTaskPage(options);
                } else {
                    this.goToPlaceTasksPage(options);
                }
            } else if (prevPage == this.myReservationsPage) {
                this.goToMyReservations(options);
            } else if (prevPage == this.placeReservationsPage) {
                this.goToPlaceReservations(_.extend(options, {
                    placeId: this.placeReservationsPage.getPlaceId()
                }));
            } else if (prevPage == this.menuPage) {
                this.goToMenuPage(_.extend(options, {
                    placeId: prevPage.props.get('placeId'),
                    categoryId: prevPage.props.get('categoryId'),
                    editable: prevPage.props.get('editable')
                }))
            } else if (prevPage == this.menuItemPage) {
                this.goToMenuItemPage(_.extend(options, {
                    placeId: this.menuItemPage.getPlaceId(),
                    itemId: this.menuItemPage.getMenuItemId()
                }));
            } else if (prevPage == this.menuDashboardPage) {
                this.goToMenuDashboard(_.extend(options, {
                    placeId: prevPage.props.get('placeId')
                }));
            }  else if (prevPage == this.menuWaitersPage) {
                this.goToMenuWaitersPage(_.extend(options, {
                    placeId: prevPage.props.get('placeId')
                }));
            } else if (prevPage == this.menuSettingsPage) {
                this.goToMenuSettings(_.extend(options, {
                    placeId: prevPage.props.get('placeId')
                }))
            } else if (prevPage == this.menuOrdersPage) {
                this.goToOrdersPage(_.extend(options, {}))
            } else if (prevPage == this.placeMenuOrdersPage) {
                this.goToPlaceOrdersPage(_.extend(options, {
                    placeId: prevPage.props.get('placeId')
                }))
            } else if (prevPage == this.placeMenuTablesPage) {
                this.goToPlaceTablesPage(_.extend(options, {
                    placeId: prevPage.props.get('placeId')
                }))
            } else if (prevPage == this.menuSectionsPage) {
                this.goToMenuSections(_.extend(options, {
                    placeId: prevPage.props.get('placeId')
                }))
            } else if (prevPage == this.menuSectionPage) {
                this.goToMenuSection(_.extend(options, {
                    placeId: prevPage.props.get('placeId'),
                    sectionId: prevPage.props.get('sectionId')
                }))
            } else {
                prevPage.show({
                    backFrom: currentPage
                });
            }

            return prevPage;
        } else {
            return false;
        }
    }

    _goBackPage(options) {
        const currentPage = _.last(this.pageStack);
        return !!currentPage && currentPage.cancel(options);
    }
};

export default Controller;
