import { Marionette } from '@b2cmessenger/backbone';
import ChildView from './UserChild'
import './UserList.scss';

export default Marionette.CollectionView.extend({
    className: 'user-list',
    childView: ChildView,
    childEvents: {
        'click:user': function(view, model) {
            this.trigger('click:user', model);
        }
    }
});
