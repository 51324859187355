import { Required, Optional, ItemView, ViewModel } from '@b2cmessenger/backbone';

//@ts-ignore
import autosize from 'autosize';

import template from './ReservationDecline.jade';
import './ReservationDecline.scss';
import ReservationModel from 'models/ReservationModel';
import AjaxError from 'utils/AjaxError';

// @ts-ignore
const options = ItemView.options;
// @ts-ignore
const properties = ItemView.properties;

@options({
    model: Required,
    isReservationManager: Required,
    reservationCancelText: Required,
    message: Optional
})
@properties({
    className: 'widget reservation-decline-widget',
    template,

    ui: {
        message: '[data-js-message]',
        messageError: '[data-js-message-error]',
        editBtn: '[data-js-edit]'
    },

    bindings: {
        '@ui.editBtn': 'disabled:disabled',
        '@ui.message': 'value:message,disabled:disabled',
        '@ui.messageError': 'toggle:messageError,disabled:disabled'
    },

    triggers: {
        'click @ui.editBtn': 'edit'
    },

    events: {
        'input @ui.message'(e) {
            if (e.target.value && this.viewModel.get('messageError')) {
                this.viewModel.set('messageError', false);
            }
        }
    },

    templateHelpers() {
        return {
            reservationCancelText: this.options.reservationCancelText
        }
    }
})
class ReservationDeclineView extends ItemView {
    initialize() {
        this.viewModel = new ViewModel({
            message: this.options.message || '',
            messageError: false
        });
    }

    decline() {
        const message = this.viewModel.get('message');

        if (!message) {
            this.viewModel.set('messageError', true);
            return Promise.reject('message too short');
        }

        return new Promise((resolve, reject) => {
            this.viewModel.set({ disabled: true });
            this.model.save({
                status: this.options.isReservationManager ?
                    ReservationModel.Status.RejectedByBusiness :
                    ReservationModel.Status.CancelledByClient
            }, {
                message,
                includeLastChangeComment: true,
                wait: true,
                success: () => {
                    this.viewModel.set({ disabled: false });
                    resolve(this.model);
                },
                error: (model, jqXHR, options) => {
                    this.viewModel.set({ disabled: false });

                    if (jqXHR.status == 422 && _.isObject(this.model.validationError) &&
                        this.model.validationError['_general']) {
                        reject(new AjaxError(this.model.validationError['_general']));
                    } else {
                        reject(new AjaxError(jqXHR));
                    }
                }
            })
        });
    }

    onAttach(view) {
        _.defer(() => autosize(this.ui.message));
    }
}

export default ReservationDeclineView;
