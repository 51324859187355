import {ViewModel} from "@b2cmessenger/backbone";
import Window from 'windows/Window';
import Header from "widgets/Header/Header";
import ReactView from "widgets/ReactView";
import FooterView from "widgets/Footer/Footer";
import MenuBrowser, {MenuBrowserDisplayCategories, MenuBrowserSelect} from "components/menu/MenuBrowser/MenuBrowser";
import './MenuBrowser.scss';

const MenuBrowserWindow = Window.extend({
    options: {
        placeId: null,
        displayCategories: null,
        displayItems: null,
        select: null,
        multiple: false,
        selected: null,
        minimumSelection: 1,
        message: null,
        closeOnFirstSelect: false,
    },

    windowName: 'menu-browser-window',

    regions: {
        header: '[data-js-header]',
        content: '[data-js-content]',
        footer: '[data-js-footer]'
    },

    initialize() {
        let selected = [];

        if (this.options.selected && this.options.multiple) {
            selected = [...this.options.selected];
        } else if (this.options.selected) {
            selected = { ...this.options.selected };
        }

        this.viewModel = new ViewModel({ selected, multiple: Boolean(this.options.multiple), minimumSelection: this.options.minimumSelection });

        this.viewModel.addComputed('c_isValid', {
            deps: ['selected', 'multiple', 'minimumSelection'],
            get(selected, multiple, minimumSelection) {
                return selected && (multiple ? _.isArray(selected) && selected.length >= minimumSelection : _.isObject(selected));
            }
        });
    },

    onRender() {
        const headerView = new Header({
            title: this.options.title || 'Menu Browser',
            leftButtons: ['back']
        });

        this.listenTo(headerView, 'back:click', () => this.close());
        this.header.show(headerView);

        const { placeId, displayCategories, displayItems, select, multiple, message, categories, items, additions, includeUnlistedCategory } = this.options;

        const contentView = new ReactView({
            className: 'menu-browser-widget',
            component: MenuBrowser,
            model: this.viewModel,
            modelAttrs: ['selected'],
            modelToProps: m => ({ value: m.get('selected') }),
            props: {
                placeId, displayCategories, displayItems, select, multiple, message, categories, items, additions, includeUnlistedCategory,
                showError: this.showError.bind(this),
                onSelect: (value) => {
                    let selected = [];

                    if (_.isArray(value)) {
                        selected = [...value];
                    } else if (_.isObject(value) && _.isArray(value.categories) && _.isArray(value.items)) {
                        selected =  [...value.categories, ...value.items];
                    } else if (_.isObject(value)) {
                        selected = {...value};
                    }

                    this.viewModel.set({ selected });
                },
            },
            mountEvent: 'onRender'
        });
        this.content.show(contentView);

        const footerButtons = [
            { id: "close", text: "CLOSE", icn: "empty" },
        ];

        if (select !== MenuBrowserSelect.None) {
            footerButtons.push({ id: "select", text: "SELECT", icn: "empty" });
        }

        const footerView = new FooterView({
            buttons: footerButtons
        });

        footerView.listenTo(this.viewModel, 'change:c_isValid', (m, canContinue) => {
            footerView.ui.btnselect.prop('disabled', !canContinue);
        });

        this.listenTo(footerView, 'close:click', () => this.close(false));
        this.listenTo(footerView, 'select:click', () => {
            this.close(this.viewModel.get('selected'));
        });

        if (this.options.closeOnFirstSelect) {
            this.viewModel.once('change:selected', () => {
                this.close(this.viewModel.get('selected'));
            });
        }

        this.footer.show(footerView);

        if (footerButtons.find(b => b.id === 'select')) {
            footerView.ui.btnselect.prop('disabled', !this.viewModel.get('c_isValid'));
        }
    }
});

_.assign(MenuBrowserWindow, {
    DisplayCategories: MenuBrowserDisplayCategories,
    Select: MenuBrowserSelect
});

export default MenuBrowserWindow;
