import React from 'react';

import './MenuCategories.scss';

import { MenuHiddenVisibility } from 'components/menu/MenuVisibility/MenuVisibility';
import MenuList, { byIdAndSortOrder, MenuListItem, MenuListType } from 'components/menu/MenuList/MenuList';
import { MenuCategoryBase } from '../MenuCategory/MenuCategory';

const MenuCategories = (props) => {
    const { categories, onSelectCategory = _.noop } = props;

    const categoriesList = categories.map((c) => (
        <MenuListItem className="menu-list-item-wrap" key={byIdAndSortOrder(c)}>
            <MenuCategoryBase className="menu-list-item" onSelectCategory={onSelectCategory.bind(null, c.id)} {...c}>
                {(c.is_hidden_forever || c.hidden_until) && <MenuHiddenVisibility />}
            </MenuCategoryBase>
        </MenuListItem>
    ));

    return (
        <MenuList {...props} type={MenuListType.Categories}>
            {categoriesList}
        </MenuList>
    );
};

export default MenuCategories;
