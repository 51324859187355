import PhoneEditorModal from "windows/Modal/Phone/PhoneEditor";
import UserSearchHelpers from "windows/Modal/UserSearchModal/UserSearchHelpers";

const UserPhoneSearchModal = PhoneEditorModal.extend({
    windowName: "phone-editor-modal-window",
    className: "modal-window phone-editor-modal-window green-border",

    _onContinue() {
        if (!this.kioskHelpers) {
            this.kioskHelpers = new UserSearchHelpers({
                viewMode: this.viewModel
            });
        }

        this.kioskHelpers.findClient({
            phone: this.viewModel.get('vm_phone')
        }).then((data) => {
            this.close({
                user: data && data.length > 0 && _.extend({phone: this.viewModel.get('vm_phone')}, data[0])
            });
        }).catch(error => {
            this.showError(...error);
            this.close();
        });
    }
});

export default UserPhoneSearchModal;