import { Model, ViewModel, Backbone } from '@b2cmessenger/backbone';
import settings from 'settings';

import Page from 'pages/Page';
import HeaderView from 'widgets/Header/Header';
import FileInput from 'widgets/Inputs/FileInput';
import EvidenceEditorWindow from 'windows/EvidenceEditor/EvidenceEditor';
import TaskTargetChooserWindow from 'windows/TaskTargetChooser/TaskTargetChooser';
import TaskTargetEditorWindow from 'windows/TaskTargetEditor/TaskTargetEditor';
import GiftTemplatesWindow from 'windows/GiftTemplates/GiftTemplates';
import GiftWidget from 'widgets/Gift/Gift';
import UserSearchWindow from 'windows/UserSearch/UserSearch';
import quoteattr from 'utils/quoteattr';

import './TaskEditor.scss';
import template from './TaskEditor.jade';
import AjaxError from 'utils/AjaxError';

import photoTemplate from './photo.jade';
import PhotoModel from "widgets/MessageWithComments/MessageComments/CommentEditor/PhotoGrid/PhotoModel";
import ImageInputHelpers from "widgets/Inputs/ImageInputHelpers";
import ViewWithWindows from 'traits/ViewWithWindows';
import ReservationEditorWindow from 'windows/ReservationEditor/ReservationEditor';
import GoogleAnalytics from "utils/GoogleAnalytics";

const withWindowsTrait = {
    Trait: ViewWithWindows,
    options: {
        windowMap: [
            {
                cls: EvidenceEditorWindow,
                trigger() {

                }
            },
            {
                cls: TaskTargetChooserWindow,
                trigger() {
                    this.ui.targetLabel.click();
                }
            },
            {
                cls: TaskTargetEditorWindow,
                trigger() {
                    this.ui.targetLabel.click();
                }
            },
            {
                cls: GiftTemplatesWindow,
                trigger() {

                }
            },
            {
                cls: UserSearchWindow,
                trigger() {

                }
            },
            {
                cls: ReservationEditorWindow,
                trigger() {

                }
            }
        ],
    }
};

let TaskEditorPage = Page.extendWithTraits([withWindowsTrait], {
    getPlaceId() {
        return pageTaskEditor.place && pageTaskEditor.place.id;
    },

    getTaskId() {
        return pageTaskEditor.task && pageTaskEditor.task.id;
    },

    isBroadcast() {
        return this.viewModel.get('isBroadcast');
    },

    isChoosingTarget() {
        return this.viewModel.get('isChoosingTarget');
    },

    attributes: { id: 'page_task_editor' },
    template: template,
    className: "task-editor-page",

    regions: {
        header: '[data-js-header]',
        gift: '[data-js-gift]',
        addPhoto: '[data-js-add-photo]',
    },

    ui: {
        broadcastGroup: '[data-js-group-only-broadcast]',
        broadcast: '[data-js-only-broadcast]',
        targetGroup: '[data-js-group-target]',
        target: '[data-js-target]',
        targetLabel: '[data-js-target-label]',
        targetId: '[data-js-target-id]',
        btnTargetChoose: '[data-js-btn-target-choose]',
        btnTargetClear: '[data-js-btn-target-clear]',
        btnTargetEdit: '[data-js-btn-target-edit]',
        btnSubjectHelp: '[data-js-subject-help]',
        subject: '[data-js-subject]',
        text: '[data-js-text]',
        btnTextHelp: '[data-js-text-help]',
        gift: '[data-js-gift]',
        btnGiftChoose: '[data-js-btn-gift-choose]',
        giftId: '[data-js-gift-template-id]',
        rating: '[data-js-rating]',
        sentTo: '[data-js-sentto]',
        authorVisibility: '[data-js-author-visibility]',
        messVisibility: '[data-js-mess-visibility]',
        photoVisibility: '[data-js-photo-visibility]',
        subjectGroup: '[data-js-subject-group]',
        marks: '[data-js-marks]',
        tags: '[data-js-tags]',
    },

    bindingHandlers: {
        sliderValue: {
            get($element) {
                return $element.val();
            },
            set($element, value) {
                try {
                    if ($element.val() + '' != value + '') {
                        $element.val(value);
                        $element.slider && $element.slider('instance') && $element.slider('refresh');
                    }

                } catch (error) { }
            }
        },
        sliderDisabled: {
            set($element, value) {
                $element.prop('disabled', !!value);
                $element.slider && $element.slider('instance') && (value ? $element.slider('disable') : $element.slider('enable'))
            },
        },
        datasetKeyboardPanel: {
            get($element) {
                return $element[0].dataset.jsKeyboardPanel;
            },
            set($element, value) {
                try {
                    $element[0].dataset.jsKeyboardPanel = value;
                } catch (e) { console.error(e); }
            }
        }
    },

    bindingFilters: {
        bool: {
            get: bool => String(!!bool),
            set: str => str == "true",
        },
    },

    bindings: {
        '@ui.broadcastGroup': 'classes:{hidden:any(toUser,isReservation,not(isBusiness),all(not(isNew),not(isBroadcast)))}',
        '@ui.broadcast': 'sliderValue:bool(isBroadcast),sliderDisabled:any(toUser,not(isNew),onlyBroadcast)',
        '@ui.targetGroup': 'classes:{hidden:any(not(isBroadcast),isReservation),empty:not(target),loading:isTargetLoading}',
        '@ui.target': 'value:targetName',
        '@ui.targetId': 'value:targetId',
        '@ui.btnTargetChoose': 'classes:{hidden:any(isReservation,not(isNew),target)}',
        '@ui.btnTargetClear': 'classes:{hidden:any(isReservation,not(isNew),not(target))}',
        '@ui.btnTargetEdit': 'classes:{hidden:any(isNew,isReservation)}',
        '@ui.btnSubjectHelp': 'classes:{hidden:any(not(isBroadcast),isReservation)},disabled:not(isBroadcast)',
        '@ui.subjectGroup': 'classes:{hidden:isReservation}',
        '@ui.subject': 'datasetKeyboardPanel:select(isBroadcast,"TaskEditorBroadcastKeyboardPanel","")',
        '@ui.text':
            'attr:{rows:select(isBroadcast,3,2),' +
            'placeholder:select(isBroadcast,"For example: Hello {firstname}! We have an awesome news for you...","Write your idea, issue, question or review")},' +
            'datasetKeyboardPanel:select(isBroadcast,"TaskEditorBroadcastKeyboardPanel","")',
        '@ui.btnTextHelp': 'classes:{hidden:any(not(isBroadcast),isReservation)},disabled:not(isBroadcast)',
        '@ui.gift': 'classes:{hidden:any(not(isBroadcast),not(gift_template),isReservation),empty:not(gift_template)}',
        '@ui.giftId': 'value:gift_template_id',
        '@ui.btnGiftChoose': 'classes:{hidden:any(not(isBroadcast),isReservation),disabled:hasBeenSentOnce},disabled:not(isBroadcast)',
        '@ui.rating': 'classes:{hidden:any(isBroadcast,isBusiness,isReservation)}',
        '@ui.sentTo': 'classes:{hidden:isBroadcast}',
        '@ui.authorVisibility': 'classes:{hidden:any(isBroadcast,isReservation)}',
        '@ui.messVisibility': 'classes:{hidden:any(isBroadcast,isReservation)}',
        '@ui.marks': 'classes:{hidden:isReservation}',
        '@ui.tags': 'classes:{hidden:isReservation}',
        '@ui.photoVisibility': 'classes:{hidden:isReservation}',
    },

    events: {
        'click @ui.targetLabel'(e) {
            if (this.viewModel.get('isBroadcast')) {
                if (this.viewModel.get('isNew')) {
                    new TaskTargetChooserWindow({
                        placeId: this.viewModel.get('place').id,
                        selectId: this.viewModel.get('target') ? this.viewModel.get('target').id : undefined
                    })
                        .show()
                        .then(target => {
                            if (target) {
                                this.viewModel.set({ target });
                            }
                        });
                } else {
                    const target = this.viewModel.get('target');
                    new TaskTargetEditorWindow({
                        model: target,
                        validateAtStart: true
                    }).show();
                    target.fetch();
                }
            }

        },
        'click @ui.btnTargetChoose'(e) {
            this._chooseTarget();
        },
        'click @ui.btnTargetClear'(e) {
            if (this.viewModel.get('isNew')) {
                this.viewModel.set({ target: null });
            }
        },
        'click @ui.btnTargetEdit'(e) {
            if (!this.viewModel.get('isNew') && this.viewModel.get('target')) {
                const target = this.viewModel.get('target');
                new TaskTargetEditorWindow({
                    model: target,
                    validateAtStart: true
                }).show();
                target.fetch();
            }
        },
        'click @ui.btnGiftChoose'(e) {
            if (this.viewModel.get('isBroadcast')) {
                if (this.viewModel.get('hasBeenSentOnce')) {
                    this.showError('The gift will be changed only for broadcasts that has not been sent!');
                } else {
                    if (this.viewModel.get('gift_template_id')) {
                        this.viewModel.set({ gift_template: null, gift_quantity: null });
                    } else {
                        new GiftTemplatesWindow({
                            brandId: this.viewModel.get('place').id,
                            choosable: true,
                            transformationAllowed: false
                        })
                            .show()
                            .then(template => this.viewModel.set({ gift_template: template || null }));
                    }
                }
            }
        },
        'click @ui.btnSubjectHelp'(e) {
            this.showMessage({
                title: "Subject template",
                text: "Set subject for your message template using {firstname}, {lastname} or {fullname} variables.\n\nThey will be replaced by real recipient's first name, last name or full name."
            });
        },
        'click @ui.btnTextHelp'(e) {
            this.showMessage({
                title: "Message template",
                text: "Set your message template using {firstname}, {lastname} or {fullname} variables.\n\nThey will be replaced by real recipient's first name, last name or full name."
            });
        }
    },

    initialize() {
        pageTaskEditor.options = this.options;

        this.viewModel = new ViewModel({
            isNew: false,
            isBusiness: false,
            isBroadcast: false,
            isReservation: false,
            onlyBroadcast: false,
            target: null,
            isTargetLoading: false,
            gift_template: null,
            hasBeenSentOnce: false,
            toUser: null,
        });

        this.viewModel.addComputed('target', {
            deps: ['_target'],
            get: _target => _target,
            set(val) {
                const target = this.get('target');
                if (target) {
                    this.stopListening(target);
                }

                if (val) {
                    this.listenTo(val, 'change', target =>
                        this.trigger('change:target change', this, target, {}));
                }

                return _.create(null, { _target: val });
            }
        });

        this.viewModel.addComputed('targetName', {
            deps: ['target'],
            get: target => target && (target.get('title') || ('#' + target.id)) || ''
        });

        this.viewModel.addComputed('targetId', {
            deps: ['target'],
            get: target => target && target.id || ''
        });

        this.viewModel.addComputed('gift_template', {
            deps: ['_gift_template'],
            get: _gift_template => _gift_template || null,
            set(val) {
                this.set({ _gift_template: val ? val instanceof Backbone.Model ? val : new Model(val) : null });
                return {
                    _gift_template_id: val ? val.id : null
                };
            }
        });

        this.viewModel.addComputed('gift_template_id', {
            deps: ['_gift_template', '_gift_template_id'],
            get: (_gift_template, _gift_template_id) => _gift_template && _gift_template.id || _gift_template_id || null,
            set(val) {
                const _gift_template = this.get('_gift_template');
                if (val) {
                    if (!_gift_template || _gift_template.id != val) {
                        app.ajax({
                            url: settings.host + settings.serv_gift.template.search,
                            data: {
                                brand_id: this.get('place').id
                            },
                            type: "POST",
                        })
                            .done(data => {
                                if (this.get('_gift_template_id') == val && data.templates) {
                                    const template = _(data.templates).find(t => t.id == val);
                                    if (template) {
                                        this.set({
                                            _gift_template: new Model(template),
                                        });
                                    }
                                }
                            })
                            .fail((jqXHR, textStatus, errorThrown) => {
                                if (this.get('_gift_template_id') == val) {
                                    this.set({
                                        _gift_template: null,
                                        _gift_template_id: null,
                                    });
                                }
                            });

                        return {
                            _gift_template: null,
                            _gift_template_id: val,
                        };
                    }
                } else if (_gift_template) {
                    return { _gift_template: null };
                }
            }
        });

        this.viewModel.addComputed('gift_quantity', {
            deps: ['_gift_quantity', '_gift_template'],
            get: (_gift_quantity, _gift_template) => _gift_template && _gift_quantity || null,
            set(val) {
                return { _gift_quantity: val };
            }
        });

        this.viewModel.addComputed('isValid', {
            deps: ['isBroadcast', 'target'],
            get: (isBroadcast, target) => !!isBroadcast == !!target
        });

        this.listenTo(this.viewModel, 'change:isBroadcast', _.debounce(() => {
            autosize.update(pageTaskEditor.jqText);
        }, 10));

        pageTaskEditor.showLoading = this.showLoading.bind(this);
        pageTaskEditor.hideLoading = this.hideLoading.bind(this);
        pageTaskEditor.showError = this.showError.bind(this);
        pageTaskEditor.viewModel = this.viewModel;
    },

    onRender() {
        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: "Task editor",
            rightButtons: [{ id: 'send', text: "Send" }]
        });
        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.listenTo(headerView, 'send:click', () => pageTaskEditor.sendTask());

        headerView.listenTo(this.viewModel, 'change:disabled change:isValid',
            m => headerView.ui.btnsend.prop('disabled', m.get('disabled') || !m.get('isValid')));

        this.header.show(headerView);

        pageTaskEditor.initialize(this.$el);

        this.listenTo(this.viewModel, 'change:gift_template',
            (m, gift_template) => this._onGiftTemplateChanged(gift_template));

        this._onGiftTemplateChanged(this.viewModel.get('gift_template'));

        const addPhotoWidget = new FileInput({
            multiple: true,
            appearance: FileInput.Appearance.Button
        });


        this.listenTo(addPhotoWidget, 'change', collection => {
            if (collection.length) {
                this.showLoading();
                const models = [];

                collection
                    .reduce((promise, m) => {
                        const file = m.get('file');
                        const fileUrl = m.get('fileUrl');

                        return promise
                            .then(() => ImageInputHelpers.upload(file === null && fileUrl ? fileUrl : file, 'tp'))
                            .then(uploadedFile => {
                                models.push(new PhotoModel(uploadedFile));
                            });
                    }, Promise.resolve())
                    .then(() => {
                        this.hideLoading();
                        pageTaskEditor.add_photos_to_photo_cont(models.map(m => ({
                            model: m,
                            // localFileUrl: URL.createObjectURL(m.get('file'))
                        })));
                    })
                    .catch(e => {
                        this.hideLoading();
                        this.showError(e);
                    });

                addPhotoWidget.reset()
            }
        });

        this.addPhoto.show(addPhotoWidget);
    },

    show(options) {
        _.defaults(options || (options = {}), {
            onSave: null,
            onlyBroadcast: false,
            isBroadcast: false
        });

        this._initAsPage();

        if (options.new) {
            if (GoogleAnalytics) {
                console.log('ga.trackEvent', 'Task', 'create start', 'place.id', options.place.id);
                GoogleAnalytics.trackEvent('Task', 'create start', 'place.id', options.place.id);
            }

            pageTaskEditor.isNew = true;
            pageTaskEditor.initialize(this.$el);
            pageTaskEditor.clearPage();
            pageTaskEditor.task = null;
            pageTaskEditor.place = options.place;
            pageTaskEditor.place_id = options.place.id;

            if (options.place.id == 1) {
                this.$el.addClass('place-say2b');
            } else {
                // Запрашиваем у сервера статус верифкации пользователя
                pageTaskEditor.server_isverified();
                this.$el.removeClass('place-say2b');
            }

            if (LoginedUserHandler.isUserEmployee(options.place.id)) {
                this.$el.addClass('business');
            } else {
                this.$el.removeClass('business');
            }

            this.viewModel.set({
                isNew: true,
                isReservation: false,
                isBroadcast: options.onlyBroadcast || options.isBroadcast,
                isBusiness: LoginedUserHandler.isUserEmployee(options.place.id),
                place: options.place,
                target: null,
                gift_template: null,
                gift_quantity: null,
                isTargetLoading: false,
                hasBeenSentOnce: false,
                toUser: options.toUser || null,
                toUserDisabled: !!options.toUser
            });
        } else if (options.task) {
            this.viewModel.set({
                isNew: false,
                isReservation: !!options.task.reservation,
                isBroadcast: !!options.task.task_target_id,
                isBusiness: LoginedUserHandler.isUserEmployee(options.task.place_id),
                place: options.place,
                target: options.task.target || null,
                hasBeenSentOnce: options.task.target_sent_times > 0,
                toUser: options.task.to_user_id ?
                    options.toUser && options.toUser.id == options.task.to_user_id && options.toUser ||
                    options.task.to_user || { id: options.task.to_user_id } :
                    null,
                toUserDisabled: true
            });

            this.viewModel.set({
                gift_quantity: options.task.gift_quantity || null
            });

            this.viewModel.set({
                gift_template_id: options.task.gift_template_id || null,
            });

            if (this.viewModel.get('isBroadcast') && !this.viewModel.get('target')) {
                const target = new TaskTargetModel({ id: options.task.task_target_id });
                target.fetch({
                    success: () => { this.viewModel.set({ isTargetLoading: false }) },
                    error: () => { this.viewModel.set({ isTargetLoading: false }) }
                });

                this.viewModel.set({
                    isTargetLoading: true,
                    target
                });
            }

            pageTaskEditor.isNew = false;
            pageTaskEditor.initialize(this.$el);
            pageTaskEditor.clearPage();

            if (options.task.place_id == 1) {
                this.$el.addClass('place-say2b');
            } else {
                this.$el.removeClass('place-say2b');
            }

            if (LoginedUserHandler.isUserEmployee(options.task.place_id)) {
                this.$el.addClass('business');
            } else {
                this.$el.removeClass('business');
            }

            pageTaskEditor.task = options.task;
            pageTaskEditor.setTask(options.task, options.task.place);
        }

        if (this.viewModel.get('isBusiness')) {
            pageTaskEditor.isverified = true;
        }

        this.viewModel.set({
            onlyBroadcast: !!options.onlyBroadcast
        });

        this.options.onSave = options.onSave;

        pageTaskEditor.options = _.extend(pageTaskEditor.options, this.options);

        if (this.viewModel.get('isBroadcast') && options.openTargetChooser) {
            this._chooseTarget(_.isObject(options.openTargetChooser) && options.openTargetChooser.targetId);
        }

        return Page.prototype.show.apply(this, arguments);
    },

    onShow() {
        pageTaskEditor.initialize(this.$el);

        // Инициализирован ли виджет просмтора и выбора методов верификации пользователя
        if (pageTaskEditor.jqVerifyMethodChooser) {
            // Запрашиваем у сервера список методов, которыми верифицирован пользователь
            pageTaskEditor.server_isverified_list();
            pageTaskEditor.jqVerifyMethodChooser.b2cpopup('close');
        }

        if (this.addPhoto.currentView) {
            this.addPhoto.currentView.reset();
        }

        Page.prototype.onShow.call(this);

        _.defer(() => autosize.update(pageTaskEditor.jqText));
    },

    cancel() {
        if (pageTaskEditor.jqVerifyMethodChooser && pageTaskEditor.jqVerifyMethodChooser.b2cpopup('isOpen')) {
            pageTaskEditor.jqVerifyMethodChooser.b2cpopup('close');
            return true;
        }

        return Page.prototype.cancel.apply(this, arguments);
    },

    _onGiftTemplateChanged(template) {
        if (this.gift.currentView) {
            this.stopListening(this.gift.currentView.model);
        }

        if (template) {
            let expireDateLocal = null;

            if (template.get('period')) {
                expireDateLocal = new Date();
                expireDateLocal.setDate(expireDateLocal.getDate() + template.get('period'));
                expireDateLocal.setHours(23, 59, 59, 999);
            }

            if (template.get('total_period')) {
                let totalExpireDate = new Date(template.get('total_period').replace(/ /g, "T"));

                let totalExpireDateLocal = new Date(totalExpireDate);
                totalExpireDateLocal.setFullYear(totalExpireDate.getUTCFullYear(), totalExpireDate.getUTCMonth(), totalExpireDate.getUTCDate());
                totalExpireDateLocal.setHours(23, 59, 59, 999);

                if (!expireDateLocal || expireDateLocal > totalExpireDateLocal) expireDateLocal = totalExpireDateLocal;
            }

            const
                giftModel = new Model({
                    name: template.get('name') || 'Coins',
                    thumb: template.get('thumb'),
                    expireDate: expireDateLocal,
                    type: template.get('type'),
                    quantity: this.viewModel.get('_gift_quantity') || template.get('quantity')
                }),
                giftWidget = new GiftWidget({
                    model: giftModel,
                    quantityEditable: !!template.get('custom_quantity_allowed') && !this.viewModel.get('hasBeenSentOnce'),
                    forceDefaultQuantity: template.get('quantity')
                });

            this.viewModel.set({ gift_quantity: giftModel.get('quantity') });

            this.listenTo(giftModel, 'change:quantity', (m, quantity) => {
                this.viewModel.set({ gift_quantity: quantity })
            });

            this.gift.show(giftWidget);
        } else {
            this.gift.reset();
        }
    },

    _chooseTarget(targetId) {
        if (this.viewModel.get('isNew') && this.viewModel.get('isBroadcast')) {
            this.viewModel.set('isChoosingTarget', true);
            new TaskTargetChooserWindow({
                placeId: this.viewModel.get('place').id,
                selectId: targetId || (this.viewModel.get('target') ? this.viewModel.get('target').id : undefined)
            })
                .show()
                .then(target => {
                    this.viewModel.set('isChoosingTarget', false);
                    if (target) {
                        this.viewModel.set({ target });
                    }
                });
        }
    }

});

export default TaskEditorPage;

/**
 * Управляет страницей создания и редактирования Таксов (Постов)
 *
 * Created by Alex on 16.03.2016.
 */
var pageTaskEditor = {
    isInited: false, // Проведена ли инициализация страницы
    task: {},  // {Object} Task : Текущее состояние задачи на сервере
    place_id: null, // {Integer} идентификатор места, к которому относится задача
    place: null, // {Object} Place место, к которому относится задача
    jqPageTaskEditor: null, // {jQuery} данная страница
    jqInfoPopup: null, // {jQuery} контейнер со всплывающим сообщением
    jqSubject: null,  // {jQuery} поле ввода темы Таска
    jqText: null, // {jQuery} текстовая область
    jqRating: null, // {jQuery} виджет User Expirience Rating
    jqAthorvisibility: null, // {jQuery} виджет переключения видимости автора
    jqMessvisibility: null, // {jQuery} виджет переключения видимости сообщения
    jqTags: null, // {jQuery} Виджет тегов к Таску
    jqMarks: null, // {jQuery} Маркеры к Таску (idea, issue, question)
    jqPhotosCont: null, // {jQuery} контейнер для отображения миниатюр подгруженных фотографий
    jqBtnVerify: null, // {jQuery} Кнопка откртия виджета выбора методов верификации
    jqVerifyMethodChooser: null, // {jQuery} виджет с методами верификации пользователя
    isNew: false,
    isverified: false,
    /**
     * Основной метод инициализации страницы.
     */
    initialize: function ($el) {
        if (this.isInited)
            return;
        var that = this;

        this.jqPageTaskEditor = $el;
        this.initContentCont();

        // Делаем отметку, что страница уже инициализирована
        this.isInited = true;
    },
    initContentCont: function () {
        var that = this; // Для замкания
        var jqContent = this.jqPageTaskEditor.children('.content-region');

        // {jQuery} поле ввода темы Таска
        this.jqSubject = jqContent.children('.subject').children('input');
        // {jQuery} текстовая область
        this.jqText = jqContent.children('.text').children('textarea');
        autosize(this.jqText); // Подключаем автоматическое расширение текстовой области по вертикале

        // {jQuery} виджет User Expirience Rating
        this.jqRating = jqContent.find('.stars');
        this.jqRating.b2crating();

        this.jqSentTo = jqContent.find('.sentto');
        this.jqSentTo.find('input').val("");

        const toUser = this.viewModel.get('toUser');
        // {jQuery} виджет переключения видимости автора
        this.jqAthorvisibility = jqContent.children('.authorvisibility');
        this.jqAthorvisibility.b2ctoggle({
            multiMode: false,
            readOnly: !!toUser
        });

        // {jQuery} виджет переключения видимости сообщения
        this.jqMessvisibility = jqContent.children('.messvisibility');
        this.jqMessvisibility.b2ctoggle({
            multiMode: false,
            readOnly: !!toUser
        });

        this.viewModel.on('change:toUserDisabled', (m, toUserDisabled) => {
            if (toUserDisabled) {
                this.jqSentTo.addClass('disabled');
                this.jqSentTo.find('button').prop('disabled', true);
            } else {
                this.jqSentTo.removeClass('disabled');
                this.jqSentTo.find('button').prop('disabled', false);
            }
        });

        this.viewModel.on('change:toUser', (m, toUser) => {
            if (toUser) {
                this.jqSentTo.removeClass('empty');
                this.jqSentTo.find('input').val(
                    toUser.firstname
                        ? toUser.firstname + (toUser.lastname ? (' ' + toUser.lastname) : '')
                        : `Unknown person (#${toUser.id})`
                );
                this.jqAthorvisibility.b2ctoggle('set', [
                    { val: 'public', set: true },
                    { val: 'anonymous', set: false }
                ]);
                this.jqAthorvisibility.b2ctoggle('disable');
                this.jqMessvisibility.b2ctoggle('set', [
                    { val: 'public', set: true },
                    { val: 'business', set: false }
                ]);
                this.jqMessvisibility.b2ctoggle('disable');
                this.jqMessvisibility.addClass('hidden');
            } else {
                this.jqSentTo.addClass('empty');
                this.jqSentTo.find('input').val('');
                this.jqMessvisibility.b2ctoggle('enable');
                this.jqAthorvisibility.b2ctoggle('enable');
                if (!this.viewModel.get('isReservation')) {
                    this.jqMessvisibility.removeClass('hidden');
                }
            }
        });

        if (!this.viewModel.get('toUserDisabled')) {
            this.jqSentTo.removeClass('disabled');
            this.jqSentTo.find('button').prop('disabled', false);
        } else {
            this.jqSentTo.addClass('disabled');
            this.jqSentTo.find('button').prop('disabled', true);
        }

        if (toUser) {
            this.jqSentTo.removeClass('empty');
            this.jqSentTo.find('input').val(
                toUser.firstname
                    ? toUser.firstname + (toUser.lastname ? (' ' + toUser.lastname) : '')
                    : `Unknown person (#${toUser.id})`
            );
            this.jqMessvisibility.addClass('hidden');
        } else {
            this.jqSentTo.addClass('empty');
            this.jqSentTo.find('input').val('');
            if (!this.viewModel.get('isReservation')) {
                this.jqMessvisibility.removeClass('hidden');
            }
        }

        this.jqSentTo.click(event => {
            if (!this.viewModel.get('toUserDisabled')) {
                if (!this.viewModel.get('toUser')) {
                    new UserSearchWindow().show().then(user => {
                        if (user) {
                            this.jqSentTo.removeClass('empty');
                            this.jqSentTo.find('input').val(user.get('name'));
                            this.viewModel.set({ toUser: user.toJSON() });
                        }
                    });
                } else {
                    this.jqSentTo.addClass('empty');
                    this.jqSentTo.find('input').val('');
                    this.viewModel.set({ toUser: null });
                }
            }
        });

        // {jQuery} Маркеры к Таску (idea, issue, question)
        this.jqMarks = jqContent.find('.marks_cont .mark');
        this.jqMarks.click(function (event) { that.onMarkClick($(this)) });

        // {jQuery} Виджет тегов к Таску
        this.jqTags = jqContent.children('.tags_cont').b2ctags();
        this.jqPhotosCont = jqContent.children('.photos_cont');

        // {jQuery} Кнопка откртия виджета выбора методов верификации
        this.jqBtnVerify = jqContent.children('.btn_verify');
        this.jqBtnVerify.click(function () { that.onBtnVerifyClick(); });
    },
    /**
     * Устанавливает таск на редактирование
     *
     * @param task {Object} Task - таск
     * @param place {Object} Place - объект места в рамках, которого создан таск
     */
    setTask: function (task, place) {
        this.place_id = task.place_id;
        this.place = place;
        this.task = task;

        this.jqSubject.val(task.subject);
        this.jqText.val(task.text);
        // Настраиваем звездный виджет User Expirience Rating
        this.jqRating.b2crating('set', task.rating);
        // Настраиваем виджет видимости автора
        this.jqAthorvisibility.b2ctoggle('set', [
            { val: 'public', set: task.authorvisibility == 1 },
            { val: 'anonymous', set: task.authorvisibility == 0 }
        ]);
        // Настраиваем виджет видимости сообщения
        this.jqMessvisibility.b2ctoggle('set', [
            { val: 'public', set: task.messvisibility == 1 },
            { val: 'business', set: task.messvisibility == 0 }
        ]);
        // Устанавливаем маркеры
        for (let i = 0; i < this.jqMarks.length; i++) {
            var jqMark = $(this.jqMarks[i]);
            if ((jqMark.hasClass('idea') && task.idea == 1)
                || (jqMark.hasClass('issue') && task.issue == 1)
                || (jqMark.hasClass('question') && task.question == 1)) {
                jqMark.addClass('set');
            }
        }

        // Задаем теги
        this.jqTags.b2ctags('set', task.tags);
        // Создаем миниатюры с нопками пометки на удаление для прикреплаенных фоток
        this.add_photos_to_photo_cont(task.photos);
        // Запрашиваем у сервера текущий статус верификации пользователя
        this.server_isverified();
    },
    /**
     * Для фотографий из массива создает миниатюры с нопками пометки на удаление
     *
     * @param photos {Array} Array of Photo, Photo {Object} - в серверном формате
     */
    add_photos_to_photo_cont: function (photos) {
        if (!photos || !photos.length)
            return;

        var jqPhotoConts = $(_.reduce(photos, (html, p) => html + photoTemplate(p), '')).appendTo(this.jqPhotosCont);
        jqPhotoConts.click(e => e.currentTarget.dataset.id &&
            new MessageGalleryWindow({ model: new Model(this.task) }).show());

        // Оработчик нажатия на кнопку - пометить на удаление
        const that = this;
        jqPhotoConts.find('img.remote').one('load', e => {
            const $thumb = $(e.currentTarget).closest('.thumb_cont');
            $thumb.addClass('loaded');
            $thumb.find('img.local').remove();
        });
        jqPhotoConts.find('.delmark').click(e => {
            e.stopPropagation();
            const $thumb = $(e.target).closest('.thumb_cont');
            const id = $thumb.attr('data-id');
            if (id) {
                if (this.isNew) {
                    if ($thumb && $thumb.length) {
                        if (this.task.photos) {
                            this.task.photos = _.reject(this.task.photos, p => {
                                if (p.id == id) {
                                    if (p.localFileUrl) {
                                        URL.revokeObjectURL(p.localFileUrl);
                                    }
                                    return true;
                                }
                            });
                        }

                        if (!this.task.delphotos) {
                            this.task.delphotos = [];
                        }

                        this.task.delphotos.push(id);
                        $thumb.remove();
                    }
                } else {
                    $(e.target).closest('.thumb_cont').toggleClass('set');
                }
            } else {
                const cid = $thumb.attr('data-cid');
                if (cid) {
                    const index = _.findIndex(this.newPhotos, p => p.model && p.model.cid == cid);
                    if (index > -1) {
                        this.newPhotos.splice(index, 1);
                    }
                }
                $thumb.remove();
            }
        });

        this.newPhotos = this.newPhotos || [];

        _.each(photos, p => {
            if (!p.id && p.model) {
                this.newPhotos.push(p);
            }
        });
    },

    /**
     * Строит HTML-code виджета просмотра и выбора методов верификации пользователя в Place
     *
     * @returns {string}
     */
    build_html_verify_method_cont: function () {
        return '' +
            '<div class="verify_methods"><h3 class="header">Verification methods:</h3>' +
            this.build_html_verify_method_btn('checkin', 'Check-In') +
            this.build_html_verify_method_btn('loyaltycard', 'Loyalty card') +
            this.build_html_verify_method_btn('selfie', 'Selfie') +
            this.build_html_verify_method_btn('receipt', 'Receipt') +
            this.build_html_verify_method_btn('otherevidence', 'Other photo evidence') +
            '</div>';
    },
    /**
     * Строит HTML-code кнопки метода верификации пользователя в Place
     *
     * @param type {String} HTML-class - тип метода верификации (checkin | loyaltycard | selfie | receipt | otherevidence)
     * @param name {String} текстовое название метода верификации
     * @returns {string}
     */
    build_html_verify_method_btn: function (type, name) {
        return '<div class="btn_verify ' + quoteattr(type) + '"><span class="icon"></span><span class="name">' + quoteattr(name) + '</span></div>';
    },
    /**
     * Устанавливает обработчиков DOM-событий для виджета выбора метода верификации в Place
     */
    add_handlers_verify_method_cont: function () {
        var jqVerifyMethodChooser = this.jqVerifyMethodChooser.b2cpopup('getUserCont');
        var that = this; // Для замыкания

        // Верификация через чекин
        this.jqBtnCheckIn = jqVerifyMethodChooser.find('.btn_verify.checkin');
        var closure_onBtnCheckInClick = function (event) { that.onBtnCheckInClick() };
        this.jqBtnCheckIn.click(closure_onBtnCheckInClick);

        // Верификация через добавление карты лояльности
        this.jqBtnLoyaltyCard = jqVerifyMethodChooser.find('.btn_verify.loyaltycard');
        var closure_onBtnLoyaltyCardClick = function (event) { that.onBtnLoyaltyCardClick() };
        this.jqBtnLoyaltyCard.click(closure_onBtnLoyaltyCardClick);

        // Замыкание для функции обратчика события успешного создания какого-либо фото-доказетельства (selfie | receipt | otherevidence)
        this.closure_on_evidence_created = function (evidence) { that.on_evidence_created(evidence) };

        // Верификация через фото селфи в заведении
        this.jqBtnSelfie = jqVerifyMethodChooser.find('.btn_verify.selfie');
        var closure_onBtnSelfieClick = function (event) { that.onBtnSelfieClick() };
        this.jqBtnSelfie.click(closure_onBtnSelfieClick);

        // Верификация через фото чека
        this.jqBtnReceipt = jqVerifyMethodChooser.find('.btn_verify.receipt');
        var closure_onBtnReceiptClick = function (event) { that.onBtnReceiptClick() };
        this.jqBtnReceipt.click(closure_onBtnReceiptClick);

        // Верификация через иное фото-доказательство
        this.jqBtnOtherEvidence = jqVerifyMethodChooser.find('.btn_verify.otherevidence');
        var closure_onBtnOtherEvidenceClick = function (event) { that.onBtnOtherEvidenceClick() };
        this.jqBtnOtherEvidence.click(closure_onBtnOtherEvidenceClick);
    },
    /**
     * Отчищает страницу и локальные переменные для повтороного использования
     */
    clearPage: function () {
        this.newPhotos = [];
        this.isverified = false;
        this.jqSubject.val('');
        this.jqText.val('');
        this.jqRating.b2crating('set', 0);
        this.jqAthorvisibility.b2ctoggle('set', [
            { val: 'public', set: true },
            { val: 'anonymous', set: false }
        ]);
        this.jqMessvisibility.b2ctoggle('set', [
            { val: 'public', set: true },
            { val: 'business', set: false }
        ]);
        this.jqMarks.removeClass('set');
        this.jqTags.b2ctags('set', []);
        this.jqPhotosCont.find('.thumb_cont').remove();

        if (this.task && this.task.photos) {
            _.each(this.task.photos, p => {
                if (p.localFileUrl) {
                    URL.revokeObjectURL(p.localFileUrl);
                }
            });
        }
    },
    /**
     * Генерирует объект Task в формате сервера для его дальнейше отправки на сервер,
     * собирая информацию из виджетов на странице
     *
     * @returns {Object} Task
     */
    gatherFields: function () {
        var task = {};
        if (this.task != null) {
            task = $.extend(true, {}, this.task);
        }
        task.place_id = this.place_id;
        task.text = this.jqText.val();
        task.subject = this.jqSubject.val();

        const taskTargetId = Number(this.jqPageTaskEditor.find('[data-js-target-id]').val());
        if (taskTargetId) {
            task.task_target_id = taskTargetId;
            task.gift_template_id = this.jqPageTaskEditor.find('[data-js-gift-template-id]').val() || null;
            if (task.gift_template_id) {
                if (this.viewModel.get('gift_quantity')) {
                    task.gift_quantity = this.viewModel.get('gift_quantity');
                } else {
                    const gift_template = this.viewModel.get('gift_template');
                    task.gift_quantity = gift_template && gift_template.get('quantity') || null;
                }
            }
            delete task.rating;
            task.authorvisibility = 1;
            task.messvisibility = 0;
            delete task.to_user_id;
        } else {
            task.rating = this.jqRating.b2crating('get');
            task.rating = task.rating = 0 ? null : task.rating;
            task.authorvisibility = this.jqAthorvisibility.b2ctoggle('isset', 'anonymous') ? 0 : 1;
            task.messvisibility = this.jqMessvisibility.b2ctoggle('isset', 'business') ? 0 : 1;
            if (this.viewModel.get('toUser'))
                task.to_user_id = this.viewModel.get('toUser').id;
            else
                delete task.to_user_id;
        }

        // Собирает маркеры
        for (i = 0; i < this.jqMarks.length; i++) {
            var jqMark = $(this.jqMarks[i]);
            if (jqMark.hasClass('idea')) {
                task.idea = jqMark.hasClass('set') ? 1 : 0;
            } else if (jqMark.hasClass('issue')) {
                task.issue = jqMark.hasClass('set') ? 1 : 0;
            } else if (jqMark.hasClass('question')) {
                task.question = jqMark.hasClass('set') ? 1 : 0;
            }
        }
        task.tags = this.jqTags.b2ctags('get');
        if (task.tags.length == 0) {
            task.tags = null;
        }

        // Массив идентификаторов фотографий помеченых на удаление
        if (!task.delphotos) {
            task.delphotos = [];
        }
        task.photos = [];
        var jqThumbConts = this.jqPhotosCont.children('.thumb_cont');
        for (var i = 0; i < jqThumbConts.length; i++) {
            var jqThumbCont = $(jqThumbConts[i]);
            var id = jqThumbCont.attr('data-id');
            if (id) {
                if (jqThumbCont.hasClass('set'))
                    task.delphotos.push(id);
                else {
                    var photo = {
                        id: id,
                        ph: jqThumbCont.attr('data-ph'),
                        th: jqThumbCont.attr('data-th'),
                        p: 1 // 1 – фотография видна всем, кому видно само сообщение
                    }
                    task.photos.push(photo);
                }
            }
        }

        return task;
    },
    /**
     * Выводит popup с сообщением
     *
     * @param message {String}
     * @private
     */
    _info_popup_show: function (message) {
        if (this.jqInfoPopup == null) {
            var that = this;
            this.jqInfoPopup = $('<div class="b2c_info_popup"></div>').appendTo(this.jqPageTaskEditor).b2cpopup({
                html_content: '<p class="message"></p>',
                lightMode: true
            });
            this.jqInfoPopup.b2cpopup('getUserCont').click(function (event) { that.jqInfoPopup.b2cpopup('close'); });
        }
        this.jqInfoPopup.b2cpopup('getUserCont').text(message);
        this.jqInfoPopup.b2cpopup('show');
    },
    /**
     * Отображает виджет с просмотром и выбором методов верификации пользователя в Place.
     * Если виджет еще не был создан, то создает его
     *
     * @private
     */
    _show_verif_method_chooser: function () {
        if (this.jqVerifyMethodChooser == null) {
            var that = this;
            this.jqVerifyMethodChooser = $('<div class="verify_method_dialog"></div>').appendTo(this.jqPageTaskEditor).b2cpopup({
                html_content: this.build_html_verify_method_cont(),
                lightMode: false
            });
            // Добавляем обработчиков DOM-событий
            this.add_handlers_verify_method_cont();
            // Запрашиваем у сервера список методов, которыми верифицирован пользователь
            this.server_isverified_list();
        }
        this.jqVerifyMethodChooser.b2cpopup('show');
    },
    /**
     * Отправляет Task на сервер для создания / изменения
     */
    sendTask: function () {
        this.showLoading();
        try {
            // Собириаем объект Task на оснве значений виджетов страницы
            var task = this.gatherFields();

            if (/^\s*$/i.test(task.subject) && /^\s*$/i.test(task.text)) {
                this.hideLoading();
                this.showError("Please enter either subject or description", undefined, undefined,
                    { dontSendToGaAndSentry: true }).then(() => this.jqSubject.focus());
                return;
            }

            const newPhotos = this.newPhotos || [];

            Promise.resolve()
                .then(() => new Promise((resolve, reject) => {
                    task.mode = 1; // Режим создания/изменения, а не верфикации полей
                    task.status = 1; // Говорим, что таск будет опуликован, а не в Draft режиме

                    if (this.newPhotos.length) {
                        task.addphotos = this.newPhotos.map(p => p.model.id);
                    }

                    if (task.id == null) {
                        B2Cjs.clear_nulls_attrs_from_object(task);

                        Server.callServer({
                            url: settings.host + settings.serv_task.create,
                            type: "POST",
                            data: task,
                            success: resolve,
                            error: (jqXHR, textStatus, errorThrown) =>
                                reject(new AjaxError(jqXHR, textStatus, errorThrown))
                        });
                    } else {
                        Server.callServer({
                            url: settings.host + settings.serv_task.change,
                            type: "POST",
                            data: task,
                            success: resolve,
                            error: (jqXHR, textStatus, errorThrown) =>
                                reject(new AjaxError(jqXHR, textStatus, errorThrown))
                        });
                    }
                }))
                .then(task => {
                    this.task = task;
                    if (this.newPhotos.length) {
                        this.newPhotos = [];
                    }

                    return task;
                })
                .then(task => this.onTaskSaved(task))
                .catch(e => this.onTaskSaveError(e));

        } catch (error) {
            this.hideLoading();
            this.showError(error);
        }

    },
    /**
     * Создает драфт таска на сервере
     *
     * @param callback {Function} callback() - функция, которая должна быть вызвана после успешного создания драфта таска
     */
    create_draft: function (callback) {
        var draft = {
            mode: 0,
            place_id: this.place_id,
        };

        B2Cjs.clear_nulls_attrs_from_object(draft);

        Server.callServer({
            url: settings.host + settings.serv_task.create,
            type: "POST",
            data: draft,
            success: (data) => this.on_server_create_draft_ok(data, callback),
            error: (jqXHR, textStatus, errorThrown) =>
                this.on_server_create_draft_error(jqXHR, textStatus, errorThrown, callback)
        });
    },
    /**
     * Отправляет на сервер запрос на чекин текущего пользователя в Place, в рамках которого создается задача
     */
    server_checkin: function () {
        var currUserPos = geo.getCurrentPosition();

        if (this.closure_on_server_checkin_ok == null) {
            var that = this;
            this.closure_on_server_checkin_ok = function (data) { that.on_server_checkin_ok(data) };
            this.closure_on_server_checkin_error = function (jqXHR, textStatus, errorThrown) { that.on_server_checkin_error(jqXHR, textStatus, errorThrown) };
        }

        Server.callServer({
            url: settings.host + settings.serv_place.checkin,
            type: "POST",
            data: {
                place_id: this.place_id,
                latitude: currUserPos.lt,
                longitude: currUserPos.lg,
            },
            success: this.closure_on_server_checkin_ok,
            error: this.closure_on_server_checkin_error
        });
    },
    /**
     * Запрашивает у сервера текущий статус верификации пользователя в Place, в рамках которого создается/редактируется задача
     */
    server_isverified: function () {
        if (this.closure_on_server_isverified_ok == null) {
            var that = this;

            this.closure_on_server_isverified_ok = function (data) { that.on_server_isverified_ok(data) };
            this.closure_on_server_isverified_error = function (jqXHR, textStatus, errorThrown) { that.on_server_isverified_error(jqXHR, textStatus, errorThrown) };
        }

        B2CPlace.server_isverified(this.place_id, this.closure_on_server_isverified_ok, this.closure_on_server_isverified_error);
    },
    /**
     * Запрашивает у сервера список методов верификации, которыии верифицирован пользователь
     * в Place, в рамках которого создается/редактируется задача
     */
    server_isverified_list: function () {
        if (this.closure_on_server_isverified_list_ok == null) {
            var that = this;

            this.closure_on_server_isverified_list_ok = function (data) { that.on_server_isverified_list_ok(data) };
            this.closure_on_server_isverified_list_error = function (jqXHR, textStatus, errorThrown) { that.on_server_isverified_list_error(jqXHR, textStatus, errorThrown) };
        }

        B2CPlace.server_isverified_list(null, this.place_id, this.closure_on_server_isverified_list_ok, this.closure_on_server_isverified_list_error);
    },
    /**
     * @param {Object} data - сохраененая задача
     */
    onTaskSaved: function (data) {
        if (GoogleAnalytics && this.isNew) {
            console.log('ga.trackEvent', 'Task', 'created', 'data.idd', data.id);
            GoogleAnalytics.trackEvent('Task', 'created', 'data.id', data.id);
        }
        this.hideLoading();

        if (this.task.id == data.id) {
            _.extend(this.task, data);
        }

        if (_.isFunction(this.options.onSave)) {
            if (!data.task_target && data.task_target_id) {
                if (this.task.task_target && this.task.task_target.id == data.task_target_id) {
                    data.task_target = this.task.task_target
                } else {
                    data.task_target = this.viewModel.get('target') &&
                        this.viewModel.get('target').toJSON({ computed: true })
                }

            }

            if (data.gift_template_id && !data.gift_template) {
                if (this.task.gift_template && data.gift_template_id == this.task.gift_template.id) {
                    data.gift_template = this.task.gift_template;
                } else {
                    data.gift_template = this.viewModel.get('gift_template');
                }
            }

            const to_user = this.viewModel.get('toUser');

            this.options.onSave.call(this, data, to_user, this.isverified);

            this.isNew = false;
            this.viewModel.set({ isNew: false });
        } else {
            Page.goBack();
        }
    },
    /**
     * Обрабатывает событие ошибки созранения таска на сервере.
     * Выводит сообщение об ошибке
     *
     * @param {Error | AjaxError} e
     */
    onTaskSaveError: function (e) {
        this.hideLoading();

        if (e instanceof AjaxError) {
            if (e.jqXHR && e.jqXHR.status == 422 && _.isArray(e.jqXHR.responseJSON)) {
                const fields = e.jqXHR.responseJSON,
                    task_target_id_error = _(fields).find(r => r.field == 'task_target_id'),
                    gift_template_id_error = _(fields).find(r => r.field == 'gift_template_id');

                if (gift_template_id_error && gift_template_id_error.code == 1001001) {
                    this.viewModel.set({ hasBeenSentOnce: true });
                    this.showError(gift_template_id_error.message);
                } else if (task_target_id_error) {
                    const error = this.showError(task_target_id_error.message);
                    if (task_target_id_error.code == 1002001) {
                        error.then(() => {
                            if (this.viewModel.get('target')) {
                                const target = this.viewModel.get('target');
                                new TaskTargetEditorWindow({
                                    model: target,
                                    validateAtStart: true
                                }).show()
                                    .then(target => {
                                        if (!target || target.validationError) {
                                            if (this.viewModel.get('isNew')) {
                                                this.viewModel.set({ target: null });
                                            }
                                        }
                                    });
                                target.fetch();
                            }
                        });
                    }
                } else {
                    this.showError(e.jqXHR, e.textStatus, e.errorThrown);
                }
            } else {
                this.showError(e.jqXHR, e.textStatus, e.errorThrown);
            }
        } else {
            this.showError(e);
        }

        if (GoogleAnalytics && this.isNew) {
            GoogleAnalytics.trackEvent('Task', 'create failed', JSON.stringify(e, null, '  '));
        }
    },
    /**
     * Обрабатывает событие нажатия на маркер (idea, issue, question)
     * Переключает нажатость маркера
     *
     * @param jqMark
     */
    onMarkClick: function (jqMark) {
        jqMark.toggleClass('set');
    },
    /**
     * Обрабатывает событие нажатия на кнопку добавить фото.
     * Инициирует диалог выбора источника фото - камера или библиотеке
     */
    onBtnAddPhotoClick: function () {
        this.jqInputPhotos.click();
    },
    /**
     * Обрабатывает событие нажатия на кнопку открытия виджета просмотра и выбора методов верификации
     */
    onBtnVerifyClick: function () {
        this._show_verif_method_chooser();
    },
    /**
     * Обрабатывает событие нажатия на кнопку зачекниться
     * Отправялет запрос на чекин на сервер
     */
    onBtnCheckInClick: function () {
        this.jqBtnCheckIn.addClass('set');
        this.server_checkin();
    },
    /**
     * Обрабатывает событие нажатия на кнопку верификации через карту лояльности
     * Открывает страницу со свписком карт лояльности пользователя в Brand соответсвующем Place,
     * в рамках которого создается/редактируется задача
     */
    onBtnLoyaltyCardClick: function () {
        var brand = {
            id: this.place.brand_id,
            name: this.place.name,
            logoThumb: this.place.logoThumb,
            logo: this.place.logo,
        };

        app.controller.goToLoyaltyCardsPage({
            brand,
            openAddCard: !this.isverified || !this.isverified.loyaltyCard
        });
    },
    /**
     * Обрабатывает событие нажатия на кнопку верификации через фото селфи в заведеии
     * Открывает страницу создания фото-доказательства
     */
    onBtnSelfieClick: function () {
        var that = this;
        new EvidenceEditorWindow({
            place_id: this.place_id,
            type: B2CEvidence.const.type.selfie
        })
            .show()
            .then(function (evidence) {
                evidence && evidence.get('status') && that.jqBtnSelfie.addClass('set');
                that.server_isverified_list();
            });
    },
    /**
     * Обрабатывает событие нажатия на кнопку верификации через фото чека
     * Открывает страницу создания фото-доказательства
     */
    onBtnReceiptClick: function () {
        var that = this;
        new EvidenceEditorWindow({
            place_id: this.place_id,
            type: B2CEvidence.const.type.receipt
        })
            .show()
            .then(function (evidence) {
                evidence && evidence.get('status') && that.jqBtnReceipt.addClass('set');
                that.server_isverified_list();
            });
    },
    /**
     * Обрабатывает событие нажатия на кнопку верификации через прочие фото-доказательства
     * Открывает страницу создания фото-доказательства
     */
    onBtnOtherEvidenceClick: function () {
        var that = this;
        new EvidenceEditorWindow({
            place_id: this.place_id,
            type: B2CEvidence.const.type.otherevidence
        })
            .show()
            .then(function (evidence) {
                evidence && evidence.get('status') && that.jqBtnOtherEvidence.addClass('set');
                that.server_isverified_list();
            });
    },
    /**
     * Обрабатывает событие успешного создания дафта таска на сервере.
     * Сохраняет драфт в атриюутах данного контроллера
     *
     * @param data {Object} Task - созданный драфт
     * @param callback {Function} callback() - функция которая вызвается после успешного создания дафта таска
     */
    on_server_create_draft_ok: function (data, callback) {
        if (GoogleAnalytics && this.isNew) {
            console.log('ga.trackEvent', 'Task', 'draft created', 'data.id', data.id);
            GoogleAnalytics.trackEvent('Task', 'draft created', 'data.id', data.id);
        }

        if (data.created_at == null)
            data.created_at = B2Cjs.datetimeJSToServer(new Date);

        this.task = data;

        if (callback != null)
            callback(true);
    },
    /**
     * Обрабатывает событие ошибки сохранения дравта таска на сервере.
     * Выводит сообщение об ошибке
     *
     * @param jqXHR
     * @param textStatus
     * @param errorThrown
     */
    on_server_create_draft_error: function (jqXHR, textStatus, errorThrown, callback) {
        var message = 'Some error occurred';
        if (jqXHR.responseJSON != null)
            message += ': ' + jqXHR.responseJSON.message;
        this._info_popup_show(message);

        if (GoogleAnalytics && this.isNew) {
            console.log('ga.trackEvent', 'Task', 'draft rejected', message);
            GoogleAnalytics.trackEvent('Task', 'draft rejected', message);
        }

        if (callback != null)
            callback(false);
    },
    /**
     * Обрабатывает событие ошибки сохранения фотографии таска на сервере.
     * Выводит сообщение об ошибке
     *
     * @param jqXHR
     * @param textStatus
     * @param errorThrown
     */
    on_server_photo_add_error: function (jqXHR, textStatus, errorThrown) {
        var message = 'Some error occurred';
        if (jqXHR.responseJSON != null)
            message += ': ' + jqXHR.responseJSON.message;
        this._info_popup_show(message);
    },
    /**
     * Обрабатывает событие успешного чекина пользователя (с сохранением на сервере)
     * Выводит сообщение об успешном чекине
     *
     * @param data {Object}
     */
    on_server_checkin_ok: function (data) {
        this._info_popup_show('You are checked in!');

        const onError = this.on_server_isverified_error.bind(this);

        B2CPlace.server_isverified(this.place_id, data => {
            if (B2CPlace.const.isverified.yes == data.isverified) {
                this.on_server_isverified_ok(data);
            } else {
                _.delay(() =>
                    B2CPlace.server_isverified(this.place_id, data => {
                        if (B2CPlace.const.isverified.yes == data.isverified) {
                            this.on_server_isverified_ok(data);
                        } else {
                            _.delay(() =>
                                B2CPlace.server_isverified(this.place_id, this.on_server_isverified_ok.bind(data), onError),
                                300
                            );
                        }
                    }, onError),
                    300
                );
            }
        }, onError);
    },
    /**
     * Обрабатывает событие ошибки сохранения чекина на сервере.
     * Выводит сообщение об ошибке
     *
     * @param jqXHR
     * @param textStatus
     * @param errorThrown
     */
    on_server_checkin_error: function (jqXHR, textStatus, errorThrown) {
        var message;

        message = 'Some error occurred';
        if (jqXHR.responseJSON != null) {
            if (_.isArray(jqXHR.responseJSON) && jqXHR.responseJSON.length > 0)
                message = jqXHR.responseJSON[0].message;
            else if (jqXHR.responseJSON.error && jqXHR.responseJSON.error.message)
                message = jqXHR.responseJSON.error.message;
        }

        this._info_popup_show(message);

        this.jqBtnCheckIn.removeClass('set');
    },
    /**
     * Обрабатывает событие успешного получения от сервера статуса верифкации пользователя в Place
     * Проставялет статус верфикации в кнопке открытия виджета просмотра методов верификации пользователя
     * @param data {Object} ответ сервера
     */
    on_server_isverified_ok: function (data) {
        if (B2CPlace.const.isverified.yes == data.isverified) {
            this.jqBtnVerify.removeClass('unverified');
            this.jqBtnVerify.addClass('verified');
            return true;
        } else {
            this.jqBtnVerify.removeClass('verified');
            this.jqBtnVerify.addClass('unverified');
            return false;
        }
    },
    /**
     * Обрабатывает событие ошибки получения от сервера статуса верифкации пользователя в Place
     *
     * @param jqXHR
     * @param textStatus
     * @param errorThrown
     */
    on_server_isverified_error: function (jqXHR, textStatus, errorThrown) {
        this.showError(jqXHR, textStatus, errorThrown);
    },
    /**
     * Обрабатывает событие успешного получения от сервера списка методов,
     * которыми пользователь верифицирован в Place.
     *
     * Проставляет соответствующие статусы кнопкам выбора методов верификации
     *
     * @param data {Object} ответ сервера
     */
    on_server_isverified_list_ok: function (data) {
        this.isverified = _.find(data, count => count > 0) ? data : false;

        if (this.isverified) {
            this.jqBtnVerify.removeClass('unverified');
            this.jqBtnVerify.addClass('verified');
        } else {
            this.jqBtnVerify.removeClass('verified');
            this.jqBtnVerify.addClass('unverified');
        }

        if (data.checkin > 0)
            this.jqBtnCheckIn.addClass('set');
        else
            this.jqBtnCheckIn.removeClass('set');

        if (data.loyaltyCard > 0)
            this.jqBtnLoyaltyCard.addClass('set');
        else
            this.jqBtnLoyaltyCard.removeClass('set');

        if (data.evidece_selfie > 0)
            this.jqBtnSelfie.addClass('set');
        else
            this.jqBtnSelfie.removeClass('set');

        if (data.evidece_receipt > 0)
            this.jqBtnReceipt.addClass('set');
        else
            this.jqBtnReceipt.removeClass('set');

        if (data.evidece_other > 0)
            this.jqBtnOtherEvidence.addClass('set');
        else
            this.jqBtnOtherEvidence.removeClass('set');
    },
    /**
     * Обрабатывает событие ошибки получения от сервера списка методов,
     * которыми пользователь верифицирован в Place.
     *
     * @param jqXHR
     * @param textStatus
     * @param errorThrown
     */
    on_server_isverified_list_error: function (jqXHR, textStatus, errorThrown) {

    },
    /**
     * Обрабатывает событие успешного создания фото-доказательства
     * Проставляет соответствующий статус кнопки выбора метода верификации,
     * Которая соответствует данному виду доказательстваф
     *
     * @param evidence {Object} Evidence - созданное фото-доказательство
     */
    on_evidence_created: function (evidence) {
        switch (evidence.type) {
            case B2CEvidence.const.type.selfie:
                this.jqBtnSelfie.addClass('set'); break;
            case B2CEvidence.const.type.receipt:
                this.jqBtnReceipt.addClass('set'); break;
            case B2CEvidence.const.type.otherevidence:
                this.jqBtnOtherEvidence.addClass('set'); break;
        }
    },
}
