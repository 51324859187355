import { Model } from '@b2cmessenger/backbone';

import TaskTargetModel from 'models/TaskTargetModel';

const BroadcastCampaignModel = Model.extend({
    defaults: {
        _target: null,
    },

    computeds: {
        'target': {
            deps: ['_target'],
            get: _target => _target,
            set(target) {
                if (!target) {
                    return _.create(null, {
                        id: undefined,
                        _target: null,
                    });
                }

                if (target instanceof TaskTargetModel) {
                    return _.create(null, {
                        id: target.id,
                        _target: target,
                    });
                } else {
                    throw new TypeError("target must be TaskTargetModel instance");
                }
            }
        },
        'title': {
            deps: ['task'],
            get: task => task && task.subject
        },
    },

    proxies: {
        'status': {
            modelAttribute: 'target',
            readOnly: true,
        },
        'task': {
            modelAttribute: 'target',
            readOnly: true
        },
        'targetPaused': {
            modelAttribute: 'target',
            submodelAttribute: 'paused',
            readOnly: true
        },
        'targetCreatedBy': {
            modelAttribute: 'target',
            submodelAttribute: 'createdBy',
            readOnly: true
        },
        'targetUpdatedBy': {
            modelAttribute: 'target',
            submodelAttribute: 'updatedBy',
            readOnly: true
        },
        'taskCreatedBy': {
            modelAttribute: 'target',
            readOnly: true
        },
        'targetTitle': {
            modelAttribute: 'target',
            submodelAttribute: 'title',
            readOnly: true
        },
        'updatedAt': {
            modelAttribute: 'target',
            readOnly: true
        },
        'lastSendAt': {
            modelAttribute: 'target',
            readOnly: true
        },
        'nextSendAt': {
            modelAttribute: 'target',
            readOnly: true
        }
    },

    fetch(options) {
        options = _.extend({
            includeTask: true,
            includeUsers: true
        }, options);

        const target = this.get('target');
        if (!target) {
            const error = new Error('target is not set!');
            options.error.call(options.context, this, error, options);
            this.trigger('error', this, error, options);
            return false;
        } else {
            const success = options.success,
                error = options.error;

            options.success = (target, resp, options) => {
                if (target == this.get('target')) {
                    if (success) success.call(options.context, this, resp, options);
                    this.trigger('sync', this, resp, options);
                } else {
                    const err = new Error('target has changed since request started!');
                    if (error) error.call(options.context, this, err, options);
                    this.trigger('error', this, err, options);
                }
            };

            options.error = (target, resp, options) => {
                if (error) error.call(options.context, this, resp, options);
                this.trigger('error', this, resp, options);
            };

            const xhr = target.fetch(options);

            if (xhr) {
                this.trigger('request', this, xhr, options);
            }

            return xhr;
        }
    },

    parse(data, options) {
        options = _.extend(options || (options = {}), { parse: true });
        const target = this.get('target');
        if (target) {
            target.set(data, options);
            return {
                target
            };
        } else {
            return _.create(null, {
                target: new TaskTargetModel(data, options)
            });
        }
    },

    sync(method, model, options) {
        const err = new Error(`Sync method "${method}" is not supported`);
        if (options && options.error) {
            options.error.call(options.context, err);
        }

        return $.Deferred().reject(err);
    },

    isNew: function () {
        return !this.attributes._target || this.attributes._target.isNew();
    },
}, {
    Status: TaskTargetModel.Status
});

Object.defineProperty(BroadcastCampaignModel.prototype, 'id', {
    configurable: false,
    get() {
        return this.attributes._target && this.attributes._target.id || undefined;
    },
    set(id) {
        if (!id) {
            this.set({
                _target: null
            });
        } else {
            const _target = this.get('_target');
            if (!_target || _target.id != id) {
                this.set({ _target: new TaskTargetModel({ id }) });
            }
        }
        return id;
    }
});

export default BroadcastCampaignModel;
