/**
 * Created by Alex on 05.03.2016.
 */
var shortDateRx = /^\s*\d{4}-\d{2}-\d{2}\s*$/i;

var B2Cjs = {
    getFormattedAddress(postalCode, streetName, streeNumber, city, state, country) {
        return [postalCode, streetName, streeNumber, city, state, country].filter(s => !!s).join(', ');
    },
    /**
     * Delete null attributes from object
     *
     * @param obj {Object}
     */
    clear_nulls_attrs_from_object : function(obj) {
        for(var key in obj) {
            if(obj[key] === null)
                delete obj[key];
        }
    },
    _emailCheck: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    checkMail : function(mail) {
        return this._emailCheck.test(mail);
    },
    /* Добавляет к числу @num нули слева, чтобы длина строки стала @size */
    addLeftZeros : function pad(num, size) {
        var s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    },
    datetimeServerToJS: function (datetimeFrom) {
        if (!datetimeFrom) { 
            return new Date(0);
        }
        if (shortDateRx.test(datetimeFrom)) {
            return new Date(datetimeFrom + 'T00:00Z');
        } else {
            return new Date(datetimeFrom.replace(/ /g, 'T').replace(/Z$/i, '') + 'Z');
        }
    },
    datetimeServerOffsetMs: function (datetimeFrom, offset) {
        var dateMs = +B2Cjs.datetimeServerToJS(datetimeFrom);

        return B2Cjs.datetimeJSToServer(new Date(Math.max(0, dateMs + offset)));
    },
    datetimeJSToServer: function (date, onlydate) {
        var strDate = date.getUTCFullYear() + '-' + B2Cjs.addLeftZeros((date.getUTCMonth() + 1), 2) + '-' + B2Cjs.addLeftZeros(date.getUTCDate(), 2);
        if (onlydate) return strDate;

        var strTime = B2Cjs.addLeftZeros(date.getUTCHours(), 2) + ":" + B2Cjs.addLeftZeros(date.getUTCMinutes(), 2) + ":" + B2Cjs.addLeftZeros(date.getUTCSeconds(), 2);
        return strDate + ' ' + strTime;
    },
    datetimeServerLocalToJS: function (datetimeFrom) {
        try {
            var date = this.datetimeServerToJS(datetimeFrom);
            return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
        } catch (e) {
            return null;
        }
    },
    datetimeJSToServerLocal: function (date, onlydate) {
        if (date instanceof Date) {
            var strDate = date.getFullYear() + '-' + B2Cjs.addLeftZeros((date.getMonth() + 1), 2) + '-' + B2Cjs.addLeftZeros(date.getDate(), 2);
            if (onlydate) return strDate;

            var strTime = B2Cjs.addLeftZeros(date.getHours(), 2) + ":" + B2Cjs.addLeftZeros(date.getMinutes(), 2) + ":" + B2Cjs.addLeftZeros(date.getSeconds(), 2);
            return strDate + ' ' + strTime;
        } else {
            return null;
        }
    },
    datetimeServerToString : function(datetimeFrom, options) {
        var date = this.datetimeServerToJS(datetimeFrom);
        return this.datetimeJSToString(date, options);
    },
    datetimeJSToString: function (datetimeFrom, options) {
        options = options || {};
        var strTime = B2Cjs.addLeftZeros(datetimeFrom.getHours(), 2) + ":" + B2Cjs.addLeftZeros(datetimeFrom.getMinutes(),2);
        if (options.needSeconds)
            strTime += ":" + B2Cjs.addLeftZeros(datetimeFrom.getSeconds(),2);
        var strDate = datetimeFrom.getFullYear() + '-'+ B2Cjs.addLeftZeros(datetimeFrom.getDate(), 2) + '-'+ B2Cjs.addLeftZeros((datetimeFrom.getMonth()+1), 2);

        if (options.timeFirst)
            return strTime + ' ' + strDate;
        else
            return strDate + ' ' + strTime;
    },
    datetimeInputToJs: function (str) {
        if (str) {
            if (shortDateRx.test(str)) {
                str+= 'T00:00Z';
            } else {
                str += 'Z';
            }
            var date = new Date(str);
            return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
        } else {
            return null;
        }
    },
    datetimeJsToInput: function (datetimeFrom, onlydate) {
        if (datetimeFrom) {
            datetimeFrom = _.isDate(datetimeFrom) ? datetimeFrom : Date(datetimeFrom);

            var strDate = datetimeFrom.getFullYear() + '-' + B2Cjs.addLeftZeros((datetimeFrom.getMonth() + 1), 2) + '-' + B2Cjs.addLeftZeros(datetimeFrom.getDate(), 2);

            if (onlydate) {
                return strDate;
            }

            var strTime = B2Cjs.addLeftZeros(datetimeFrom.getHours(), 2) + ":" + B2Cjs.addLeftZeros(datetimeFrom.getMinutes(), 2) + ":" + B2Cjs.addLeftZeros(datetimeFrom.getSeconds(), 2);

            return strDate + 'T' + strTime;
        } else {
            return '';
        }
    },
    /**
     * Разница в минутах между date1 и date2
     *
     * @param date1 {Date}
     * @param date2 {Date}
     * @returns {*|number}
     */
    date_diff_in_minutes : function(date1, date2) {
        return Math.round((((date2-date1) % 86400000) % 3600000) / 60000);
    },
    array_intersection : function(arr1, arr2) {
        var result = [];
        for(var i=0; i<arr1.length; i++) {
            if(arr2.indexOf(arr1[i]) >= 0)
                result.push(arr1[i]);
        }
        return result;
    },
    array_HasIntersection : function(arr1, arr2) {
        for(var i=0; i<arr1.length; i++) {
            if(arr2.indexOf(arr1[i]) >= 0)
                return true;
        }
        return false;
    },
    array_one_minus_two : function(arr1, arr2) {
        var result = [];
        for(var i=0; i<arr1.length; i++) {
            if(arr2.indexOf(arr1[i]) < 0)
                result.push(arr1[i]);
        }
        return result;
    },
    array_merge : function(arr1, arr2) {
        var result = [];
        for(var i=0; i<arr1.length; i++) {
            result.push(arr1[i]);
        }
        for(var i=0; i<arr2.length; i++) {
            if(arr1.indexOf(arr2[i]) < 0)
                result.push(arr2[i]);
        }
        return result;
    },
    phone_getInternalFormat : function(phone_in_external_format) {
        return phone_in_external_format &&
            phone_in_external_format.replace(/[^0-9]/g, '')/*.replace(/\++/, '+')*/
            || "";
    },
    phone_getExternalFormat: function (phone_in_internal_format) {
        const internal = this.phone_getInternalFormat(phone_in_internal_format);
        if (internal) {
            switch(internal.length) {
                case 12:
                case 11:
                    return "+" + internal.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2,3})/, "$1 ($2) $3-$4-$5");
                case 10:
                    return internal.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "($1) $2-$3-$4");
                case 7:
                    return internal.replace(/(\d{3})(\d{2})(\d{2})/, "$1-$2-$3");
                case 6:
                    return internal.replace(/(\d{2})(\d{2})(\d{2})/, "$1-$2-$3");
                default:
                    return "+" + internal;
            }
        } else
            return "";
    },
    /**
     * Удаляет все пробелы в строке
     * @param str
     * @returns {string|void|XML}
     */
    spaces_delete_all : function(str) {
        return str.replace(/\s/g, '');
    }
};

window.B2Cjs = B2Cjs; // TODO: remove globals, use module exports
// export default B2Cjs;
