import { Required, Optional, LayoutView, ViewModel } from '@b2cmessenger/backbone';
import { bindingHandler as starsBindingHandler } from 'widgets/lite/stars/stars';

import MessageSolutionRatingsModal from '../MessageSolutionRatingsModal/MessageSolutionRatingsModal';
import MessageMenuView from '../MessageMenu/MessageMenu';

import template from './MessageFooter.jade';
import './MessageFooter.scss';

/**@type {typeof import('./MessageFooter').properties} */
// @ts-ignore
const properties = LayoutView.properties;

/**@type {typeof import('./MessageFooter').options} */
// @ts-ignore
const options = LayoutView.options;

/** @type {typeof import('./MessageFooter').events} */
// @ts-ignore
const events = LayoutView.events;

@options({
    model: Required,
    loginedUserModel: Required,
    placeModel: Required,
    toUserModel: Optional,
    reservationModel: Optional
})
@events({
    /** User opened menu */
    'menu:open': () => { },

    /** User closed menu
     * @param {string} [entryName] - Name of the entry
     * @param {any} [actionResult] - Result if any with which action finished */
    'menu:close': (entryName, actionResult) => { },

    /** User clicked on menu entry
     * @param {string} entryName - Name of the entry */
    'menu:entry:click': (entryName) => { },

    /** Action related to menu entry finished sucessfully
     * @param {string} entryName - Name of the entry
     * @param {any} [actionResult] - Result if any with which action finished */
    'menu:entry:finish': (entryName, actionResult) => { },

    /** Action related to menu entry failed
     * @param {string} entryName - Name of the entry
     * @param {Error | string} [actionError] */
    'menu:entry:fail': (entryName, actionError) => { },

    /** User clicked on comments button */
    'comments:click': () => { },

    /** User clicked on reply button */
    'reply:click': () => { }
})
@properties({
    className: 'widget message-footer-widget',
    template,

    ui: {
        btnLike: '[data-js-btn-like]',
        btnDislike: '[data-js-btn-dislike]',
        btnSolutionRating: '[data-js-btn-solution-rating]',
        solutionRating: '[data-js-solution-rating]',
        solutionVotes: '[data-js-solution-votes]',
        btnMenu: '[data-js-btn-menu]',
        menu: '[data-js-menu]',
        btnComments: '[data-js-btn-comments]',
        btnReply: '[data-js-btn-reply]'
    },

    regions: {
        menu: '[data-js-menu]'
    },

    computeds: {
        likesProportionLn: {
            deps: ['likes', 'dislikes'],
            get: (likes, dislikes) => Math.log(likes + 2) - Math.log(dislikes + 2)
        },
        likesSignificantlyBigger: {
            deps: ['likesProportionLn'],
            get: likesProportionLn => likesProportionLn > 1
        },
        dislikesSignificantlyBigger: {
            deps: ['likesProportionLn'],
            get: likesProportionLn => likesProportionLn < -1
        },
        solutionVotesText: {
            deps: ['solutionVotes'],
            get: solutionVotes => `${solutionVotes} vote${solutionVotes > 1 ? 's' : ''}`
        },
        canHaveComments: {
            deps: ['isTask', 'depth'],
            get: (isTask, depth) => isTask || depth == 0 || depth == 1
        },
    },

    bindings: {
        '@ui.btnLike':
            'text:likes,' +
            'classes:{set:userLiked,significant:likesSignificantlyBigger},' +
            'disabled:disabled',
        '@ui.btnDislike':
            'text:dislikes,' +
            'classes:{set:userDisliked,significant:dislikesSignificantlyBigger},' +
            'disabled:disabled',
        '@ui.btnSolutionRating': 'disabled:disabled',
        '@ui.solutionRating': 'starsBindingHandler:solutionRating',
        '@ui.solutionVotes': 'text:solutionVotesText',
        '@ui.btnMenu': 'classes:{hidden:not(loginedUserModel_isLoggedIn),open:isMenuOpen},disabled:disabled',
        '@ui.menu': 'classes:{hidden:not(isMenuOpen)}',
        '@ui.btnComments': 'text:commentsCount,classes:{hidden:not(canHaveComments)},disabled:disabled',
        '@ui.btnReply': 'classes:{hidden:any(canHaveComments,not(loginedUserModel_isLoggedIn))},disabled:disabled',
    },

    bindingHandlers: {
        starsBindingHandler
    },

    events: {
        'click @ui.btnLike'() {
            if (this.loginedUserModel.get('isLoggedIn')) {
                this.viewModel.set({ disabled: true });
                this.model.toggleLike()
                    .catch(e => this.showError(e))
                    .then(() => this.viewModel.set({ disabled: false }));
            }
        },
        'click @ui.btnDislike'() {
            if (this.loginedUserModel.get('isLoggedIn')) {
                this.viewModel.set({ disabled: true });
                this.model.toggleDislike()
                    .catch(e => this.showError(e))
                    .then(() => this.viewModel.set({ disabled: false }));
            }
        },
        'click @ui.btnSolutionRating'() {
            new MessageSolutionRatingsModal({
                model: this.model,
                loginedUserModel: this.loginedUserModel,
                parentViewModel: this.viewModel,
            }).show();
        },
        'click @ui.btnMenu'() {
            if (this.loginedUserModel.get('isLoggedIn')) {
                this.openMenu(!this.viewModel.get('isMenuOpen'));
            }
        },
        'click @ui.btnComments'() {
            this.trigger('comments:click');
        },
        'click @ui.btnReply'() {
            this.trigger('reply:click');
        }
    },

    modelEvents: {
        'change:tags change:isSolution': _.debounce(function () {
            if (!this.isDestroyed && this.isRendered) {
                this.render();
            }
        }, 1)
    },

    bindingSources() {
        return { loginedUserModel: this.loginedUserModel };
    },

    templateHelpers() {
        return { loginedUserModel: this.loginedUserModel };
    }
})
class MessageFooterView extends LayoutView {
    openMenu(open = true) {
        /** @type {import('./MessageFooter')} */
        // @ts-ignore
        const self = this;

        this.viewModel.set({ isMenuOpen: !!open });
        if (open) {
            if (this.isRendered && !this.isDestroyed && !this.getRegion('menu').hasView()) {
                const menuView = new MessageMenuView({
                    model: self.model,
                    viewModel: this.viewModel,
                    placeModel: this.placeModel,
                    loginedUserModel: this.loginedUserModel,
                    toUserModel: this.toUserModel,
                    reservationModel: this.reservationModel
                });

                this.getRegion('menu').show(menuView);

                this.listenTo(menuView, 'entry:click', (entryName) => {
                    this.trigger('menu:entry:click', entryName);
                });

                this.listenTo(menuView, 'entry:finish', (entryName, actionResult) => {
                    this.viewModel.set({ isMenuOpen: false });
                    this.trigger('menu:entry:finish', entryName, actionResult);
                    this.trigger('menu:close', entryName, actionResult);
                });

                this.listenTo(menuView, 'entry:fail', (entryName, actionError) => {
                    this.viewModel.set({ isMenuOpen: false });
                    this.trigger('menu:entry:fail', entryName, actionError);
                    this.trigger('menu:close', entryName, actionError);
                });

                this.listenTo(menuView, 'destroy', () => this.stopListening(menuView));
            }
            this.trigger('menu:open');
        } else {
            this.trigger('menu:close');
        }
    }

    closeMenu() {
        return this.openMenu(false);
    }

    initialize() {
        /** @type {import('./MessageFooter')} */
        // @ts-ignore
        const self = this;

        this.viewModel = new ViewModel({
            isMenuOpen: false
        });

        this.loginedUserModel = self.options.loginedUserModel;
        this.placeModel = self.options.placeModel;
        this.toUserModel = self.options.toUserModel;
        this.reservationModel = self.options.reservationModel;

        this.listenTo(this.viewModel, 'change:isMenuOpen', (vm, isMenuOpen) => {
            if (isMenuOpen) {
                const $page = this.$el.closest('.window, .page');
                if ($page.length) {
                    this._closestPage = $page[0];
                    this._onPageClick = (e) => {
                        this._onPageClick = null;
                        this._closestPage = null;

                        if (!$.contains(this.ui.menu[0], e.target) && e.target != this.ui.btnMenu[0]) {
                            this.closeMenu();
                        }
                    }

                    this._closestPage.addEventListener('click', this._onPageClick, {
                        capture: true,
                        passive: true,
                        once: true
                    });
                }
            } else {
                if (this._closestPage && this._onPageClick) {
                    this._closestPage.removeEventListener('click', this._onPageClick, {
                        capture: true,
                    });
                }
            }
        });
    }

    onRender() {
        if (this.viewModel.get('isMenuOpen')) {
            this.viewModel.set({ isMenuOpen: false });
            this.trigger('menu:close');
        }
    }
};

export default MessageFooterView;
