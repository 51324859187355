/**
 * Created by Alex on 10.03.2016.
 */
var Server = {
    callServer : function(settings) {
        return Backbone.ajax(settings);
    },
    callServerWithParameters: function(settings, paramsMap) {
        var getString = paramsMap && $.param(paramsMap);
        if (!!getString) {
            settings.url = settings.url + '?' + getString;
        }

        return Server.callServer(settings);
    }
};

window.Server = Server; // TODO: remove globals, use module exports
// export default Server;
