import React from 'react';
import PropTypes from 'prop-types';

import './UserSelect.scss';
import UserSelectableItems from 'components/menu/UserItem/UserSelectableItems';

const UserSelect = (props) => {
    const {
        users = [],
        selectedUserIds: selected = [],
        onSelectedChange = _.noop,
        onSelectUser = _.noop,
        multiple,
        emptyContentMessage,
    } = props;

    return (
        <div className="user-select-content">
            <UserSelectableItems
                users={users}
                selected={selected}
                onSelectedChange={onSelectedChange}
                onSelectUser={onSelectUser}
                multiple={multiple}
                emptyContentMessage={emptyContentMessage}
            />
        </div>
    );
};
UserSelect.propTypes = {
    users: PropTypes.array,
    selectedUserIds: PropTypes.array,
    onSelectedChange: PropTypes.func,
    onSelectUser: PropTypes.func,
};

export default UserSelect;
