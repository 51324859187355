var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (name, thumb, type) {if (type == 3) {
pug_html = pug_html + "\u003Cdiv class=\"gift-item\"\u003E\u003Cdiv class=\"title\"\u003E\u003Cdiv class=\"whogift\" data-js-who-gift\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"whengift\" data-js-when-gift\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"image\" data-js-image\u003E";
if (!thumb) {
pug_html = pug_html + "\u003Cdiv class=\"gift-title\"\u003E\u003Cdiv class=\"gift-name\" data-js-gift-name\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"gift-date\" data-js-expire-date\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (thumb) {
pug_html = pug_html + "\u003Cdiv class=\"gift-title\"\u003E\u003Cdiv class=\"gift-name\" data-js-gift-name\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"gift-date\" data-js-expire-date\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"gift-item\"\u003E\u003Cdiv class=\"title\"\u003E\u003Cdiv class=\"name\"\u003E";
if (type == 3) {
pug_html = pug_html + "\u003Cspan\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"qty\" data-js-total-qty\u003E\u003C\u002Fspan\u003E";
if (type == 1) {
pug_html = pug_html + "\u003Cspan\u003E x Coins\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan\u003E x " + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"image\" data-js-image\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"write-off\" data-js-write-off\u003E\u003Cdiv class=\"spinner\" data-js-spinner-qty\u003E\u003Cbutton class=\"minus\" data-js-spinner-minus\u003E\u003Ci class=\"ion-ios-remove-circle\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cinput data-js-spinner-input type=\"number\" name=\"qty\" value=\"1\"\u003E\u003Cbutton class=\"plus\" data-js-spinner-plus\u003E\u003Ci class=\"ion-ios-add-circle\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cbutton data-js-btn-write-off\u003E\u003Ci class=\"ion-ios-close-circle-outline\"\u003E\u003C\u002Fi\u003E\u003Cspan\u003EWrite-off\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cbutton class=\"qr\" data-js-btn-qr-write-off\u003E\u003Ci class=\"icon-qrcode\"\u003E\u003C\u002Fi\u003E\u003Cspan\u003EWrite-off via QR\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"history\" data-js-gifts-history\u003E\u003C\u002Fdiv\u003E";
}}.call(this,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined,"thumb" in locals_for_with?locals_for_with.thumb:typeof thumb!=="undefined"?thumb:undefined,"type" in locals_for_with?locals_for_with.type:typeof type!=="undefined"?type:undefined));;return pug_html;};
module.exports = template;