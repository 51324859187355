import { Collection } from '@b2cmessenger/backbone';
import _ from 'underscore';

import PropertyModel from 'models/PropertyModel';

let PropertyCollection = Collection.extend({
    model: PropertyModel,
    comparator: 'position',

    resetByIds(ids) {
        this.reset(new PropertyBase().get_properties_by_ids(ids));

        return this;
    },

    resetByVarnamesWithValues(values) {
        this.reset(_.map(_.pairs(values),
            pair => (pair[1] != null && pair[1] != undefined) 
                ? _.extend(_.clone(_.find(PropertyBrowserStorage.properties, p => p.varname == pair[0])), { value: pair[1] })
                : _.clone(_.find(PropertyBrowserStorage.properties, p => p.varname == pair[0]))));

        return this;
    },

    toVarnamesWithValues() {
        let obj = Object.create(null);
        this.each(m => m.has('value') && (
            obj[m.get('varname')] =
                m.get('widget') == PropertyModel.WIDGET_TYPE_SINGLE
                ? m.get('field_type') == PropertyModel.FIELD_TYPE_INTEGER
                    ? Number(m.get('value'))
                    : String(m.get('value'))
                : m.get('field_type') == PropertyModel.FIELD_TYPE_INTEGER
                    ? _(m.get('value')).map(Number)
                    : _(m.get('value')).map(String)));
        return obj;
    }
});

PropertyCollection.createFromIds = ids => new PropertyCollection().resetByIds(ids);
PropertyCollection.createFromVarnamesWithValues = values => new PropertyCollection().resetByVarnamesWithValues(values);

export default PropertyCollection;
