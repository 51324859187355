import { Model, ItemView } from '@b2cmessenger/backbone';

import './PlaceItem.scss';
import template from './PlaceItem.jade';

import getNextPrettyColor from 'utils/randomPrettyColor';

let PlaceItem = ItemView.extend({
    template: template,

    tagName: "li",
    className: "place-item place new",

    ui: {
        address: '[data-js-address]',
        direction: '[data-js-direction]',
        distance: '[data-js-distance]'
    },

    computeds: {
        prettyDistance: {
            deps: ['dist'],
            get: _dist => {
                let dist = Number(_dist);
                if(_dist == null || !dist && dist !== 0) {
                    return '';
                }

                if(dist < 1/1760) {
                    return 'Near you';
                } else if(dist < 0.1) {
                    let yards = Math.ceil(dist * 1760)
                    return  yards + (yards >= 1 ? ' yards' : 'yard');
                } else if(dist < 10) {
                    return dist.toFixed(1) + (dist >= 1 && dist < 2 ? ' mile' : ' miles');
                } else if(dist < 999.5) { 
                    return Math.round(dist) + ' miles';
                } else if(dist < 10000) { 
                    return (dist/1000).toFixed(1) + 'k miles';
                } else {
                    return Math.round(dist/1000) + 'k miles';
                }
            }
        }
    },

    bindings: {
        ':el': 'attr:{"data-id":id,"data-gooid":googleId},classes:{goo:googleId},css:{"border-color":color}',
        '@ui.address': 'text:shortAddress',
        '@ui.direction': 'classes:{hidden:not(prettyDistance)}',
        '@ui.distance': 'text:prettyDistance'
    },

    initialize() {
        this.viewModel = new Model({
            color: getNextPrettyColor(this.model.get('name')),
            googleId: this.model.get('__goo_place') && this.model.get('__goo_place').place_id
        });

        if(this.options.mapModel) {
            this.viewModel.listenTo(this.options.mapModel, 'change:lat change:lng', 
                m => this.model.set('dist', 
                    geo.distance(
                        Number(this.model.get('adr_latitude')), 
                        Number(this.model.get('adr_longitude')), 
                        m.get('lat'), 
                        m.get('lng')
                    )
                )
            );
        }
    },

    triggers: {
        'click' : 'select'
    }
});

export default PlaceItem;
