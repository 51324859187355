import { OrderStatus } from 'components/menu/MenuPlaceOrder/MenuPlaceOrder';

export const getAdditionItems = (additions) => {
    return _(additions).reduce((memo, a) => {
        const addOnItems = _(a.items).map((addOnItem) => {
            return {
                ...addOnItem,
                categoryId: a.category.id,
            };
        });

        memo.push(...addOnItems);
        return memo;
    }, []);
};

export const getSelectedAdditionItems = ({ additions, additionItems = [] }) => {
    const additionIds = _(additions).map((a, id) => a.itemId || a.id || id);
    const getAdditionItem = (id) =>
        _.isArray(additionItems) ? additionItems.find((a) => a.id === Number(id)) : additionItems[id];

    const items = _(additionIds)
        .reduce((memo, id) => {
            memo.push(getAdditionItem(id));
            return memo;
        }, [])
        .filter(Boolean);

    return items;
};

export function isOrderFinished(order) {
    return _.isObject(order) && [OrderStatus.Canceled, OrderStatus.Done].indexOf(order.status) > -1;
}

export function isOrderActive(order) {
    return _.isObject(order) && [OrderStatus.Created, OrderStatus.Confirmed].indexOf(order.status) > -1;
}

export function isFullMenuItemModel(model) {
    return model && model.hasOwnProperty('additions');
}
