import { Backbone } from '@b2cmessenger/backbone';
import ImageInput from 'widgets/Inputs/ImageInput';
import FileInput from 'widgets/Inputs/FileInput';
import _getNextPrettyColor from 'utils/randomPrettyColor';

export function createSingleImageInput() {
    let widget = new ImageInput({ model: new Backbone.Model({ image: '' }) });
    widget.render();
    return widget;
}

export function createFileInput(options) {
    const w = new FileInput(options);
    w.render();
    return w;
}

export const getNextPrettyColor = _getNextPrettyColor;
