export const UPSERT_ORDER = '[Menu] Upsert order';
export const UPSERT_ORDERS = '[Menu] Upsert orders';
export const REMOVE_ORDER = '[Menu] Remove order';
export const RESET_ORDERS = '[Menu] Reset orders';

export const UPDATE_ORDERS_DUE_TO_NOTIFICATIONS = '[Menu] Update orders due to notifications';

export const INSERT_ORDER_INTO_CART = '[Menu] Insert order to cart';

export const ON_EDIT_ORDER = '[Menu] On edit order';

export const UPLOAD_ORDER = '[Menu] Upload Order';
export const UPLOAD_ORDER_SUCCEEDED = '[Menu] Upload Order Succeed';
export const UPLOAD_ORDER_FAILED = '[Menu] Upload Order Failed';


export const upsertOrder = (order) => ({
    type: UPSERT_ORDER,
    payload: { order }
});

export const upsertOrders = (orders) => ({
    type: UPSERT_ORDERS,
    payload: { orders }
});

export const removeOrder = (orderOrOrderId) => ({
    type: REMOVE_ORDER,
    payload: { orderOrOrderId }
});

export const resetOrders = (orders = []) => ({
    type: RESET_ORDERS,
    payload: { orders }
});

export const updateOrdersDueToNotifications = (notifications = []) => ({
    type: UPDATE_ORDERS_DUE_TO_NOTIFICATIONS,
    payload: { notifications }
});

export const insertOrderIntoCart = (order = null) => ({
    type: INSERT_ORDER_INTO_CART,
    payload: { order }
});

export const onEditOrder = (order) => ({
    type: ON_EDIT_ORDER,
    payload: { order }
});

export const uploadOrder = (placeId, orderData) => ({
    type: UPLOAD_ORDER,
    payload: {
        placeId, orderData
    }
});

export const uploadOrderSucceed = (order) => ({
    type: UPLOAD_ORDER_SUCCEEDED,
    payload: {
        order
    }
});

export const uploadOrderFailed = (error) => ({
    type: UPLOAD_ORDER_FAILED,
    payload: {
        error
    }
});
