import { ItemView, ViewModel } from '@b2cmessenger/backbone';
import BroadcastCampaignModel from 'models/BroadcastCampaignModel';
import ViewWithPrettyColor from 'traits/ViewWithPrettyColor';

import template from './BroadcastCampaignItem.jade';
import './BroadcastCampaignItem.scss';

import escapeHtml from 'utils/escapeHtml';

const BroadcastCampaignItemView = ItemView.extendWithTraits([
    {
        Trait: ViewWithPrettyColor,
        options: {
            modelAttribute: 'name'
        }
    }
], {
     options: {
         compact: false,
    },
    template,
    className: 'widget broadcast-campaign-item',

    ui: {
        targetTitle: '[data-js-target-title]',
        taskTitle: '[data-js-task-title]',
        likesCount: '[data-js-likes-count]',
        dislikesCount: '[data-js-dislikes-count]',
        commentsCount: '[data-js-comments-count]',
        nextBroadcast: '[data-js-next-broadcast]',
        lastBroadcast: '[data-js-last-broadcast]',
        moreBroadcasts: '[data-js-more-broadcasts]',
        giftTemplate: '[data-js-gift-template]',
        giftTemplateThumb: '[data-js-gift-template-icon]',
        giftTemplateTitle: '[data-js-gift-template-title]',
    },

    computeds: {
        isStatusUnknown: {
            deps: ['status'],
            get: status => status == BroadcastCampaignModel.Status.Unknown
        },
        isStatusDraft: {
            deps: ['status'],
            get: status => status == BroadcastCampaignModel.Status.Draft
        },
        isStatusScheduled: {
            deps: ['status', 'targetPaused'],
            get: (status, targetPaused) => !targetPaused && status == BroadcastCampaignModel.Status.Scheduled
        },
        isStatusInProgress: {
            deps: ['status', 'targetPaused'],
            get: (status, targetPaused) => !targetPaused && status == BroadcastCampaignModel.Status.InProgress
        },
        isStatusDone: {
            deps: ['status'],
            get: status => status == BroadcastCampaignModel.Status.Done
        },
        isPaused: {
            deps: ['status', 'targetPaused'],
            get: (status, targetPaused) => targetPaused &&
                (status == BroadcastCampaignModel.Status.Scheduled
                    || status == BroadcastCampaignModel.Status.InProgress)
        },
        isTaskIdea: {
            deps: ['task'],
            get: task => task && task.idea
        },
        isTaskIssue: {
            deps: ['task'],
            get: task => task && task.issue
        },
        isTaskQuestion: {
            deps: ['task'],
            get: task => task && task.question
        },
        likesCount: {
            deps: ['task'],
            get: task => task && Number(task.likes)
        },
        dislikesCount: {
            deps: ['task'],
            get: task => task && Number(task.dislikes)
        },
        commentsCount: {
            deps: ['task'],
            get: task => task && Number(task.commentscount)
        },
        hasGiftTemplate: {
            deps: ['task'],
            get: task => task && task.gift_template
        },
        isGiftTemplateCoins: {
            deps: ['task'],
            get: task => task && task.gift_template && task.gift_template.type == 1
        },
        isGiftTemplateGift: {
            deps: ['task'],
            get: task => task && task.gift_template && task.gift_template.type == 2
        },
        isGiftTemplateDiscount: {
            deps: ['task'],
            get: task => task && task.gift_template && task.gift_template.type == 3
        },
        giftTemplateTitle: {
            deps: ['task'],
            get: task => {
                if (task && task.gift_template) {
                    switch (task.gift_template.type) {
                        case 1:
                            return `<span class="qty">${escapeHtml(task.gift_quantity || task.gift_template.quantity)}</span><span class="title">coins</span>`;
                        case 2:
                            return `<span class="qty">${escapeHtml(task.gift_quantity || task.gift_template.quantity)}</span><span class="title">${escapeHtml(task.gift_template.name)}</span>`;
                        case 3:
                        default:
                            return `<span class="title">${escapeHtml(task.gift_template.name)}</span>`;
                    }
                }
                return '';
            }
        },
        giftTemplateThumb: {
            deps: ['task'],
            get: task => task && task.gift_template &&
                task.gift_template.type != 1 &&
                task.gift_template.thumb && `url(${task.gift_template.thumb})`
        },
        moreBroadcasts: {
            deps: ['task'],
            get: task => task && Number(task.target_sent_times) > 1 &&
                Math.floor(Number(task.target_sent_times) - 1) || ""
        }
    },

    bindingFilters: {
        prettyDate: val => val && val.toLocaleString && val.toLocaleString(undefined, {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        }) || '',
        singular: val => val == 1
    },

    bindings: {
        ':el':
            'attr:{"data-id":id},' +
            'classes:{' +
                'compact:compact,' +
                '"status-unknown": isStatusUnknown,' +
                '"status-draft": isStatusDraft,' +
                '"status-scheduled": isStatusScheduled,' +
                '"status-in-progress": isStatusInProgress,' +
                '"status-done": isStatusDone,' +
                '"status-paused":isPaused' +
            '}',
        '@ui.targetTitle': 'text:targetTitle',
        '@ui.taskTitle':
            'classes:{idea:isTaskIdea,issue:isTaskIssue,question:isTaskQuestion},attr:{"data-text":title}',
        '@ui.commentsCount': 'text:commentsCount,classes:{empty:not(commentsCount)}',
        '@ui.likesCount': 'text:likesCount,classes:{empty:not(likesCount)}',
        '@ui.dislikesCount': 'text:dislikesCount,classes:{empty:not(dislikesCount)}',
        '@ui.nextBroadcast': 'text:prettyDate(nextSendAt)',
        '@ui.lastBroadcast': 'text:prettyDate(lastSendAt)',
        '@ui.moreBroadcasts': 'classes:{singular:singular(moreBroadcasts)},text:moreBroadcasts',
        '@ui.giftTemplateThumb':
            'classes:{' +
                'empty:not(hasGiftTemplate),' +
                'custom:giftTemplateThumb,' +
                'coins:isGiftTemplateCoins,' +
                'gift:isGiftTemplateGift,' +
                'discount:isGiftTemplateDiscount' +
            '},' +
            'css:{"background-image":giftTemplateThumb}',
        '@ui.giftTemplateTitle': 'classes:{empty:not(hasGiftTemplate)},html:giftTemplateTitle',
    },

    events: {
        'click'() {
            this.triggerMethod('select');
        }
    },

    initialize() {
        if (!this.viewModel) {
            this.viewModel = new ViewModel;
        }

        this.viewModel.set({
            compact: this.options.compact
        });
    },

    serializeModel(model) {
        return model.toJSON({ computed: true });
    }
});

export default BroadcastCampaignItemView;
