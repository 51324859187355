import React from 'react';

import MenuList, {
    byIdAndSortOrder,
    MenuListEmptyItem,
    MenuListItem,
    MenuListType,
} from 'components/menu/MenuList/MenuList';
import { MenuCategoryBase } from 'components/menu/MenuCategory/MenuCategory';
import './MenuRemovableCategories.scss';

const MenuRemovableCategories = (props) => {
    const { categories, onSelectCategory = _.noop, onRemoveCategory = _.noop } = props;

    const categoriesList = categories.map((c) => {
        const rightContent = <i className="ion-ios-trash-outline" onClick={onRemoveCategory.bind(null, c.id)} />;

        return (
            <MenuListItem key={byIdAndSortOrder(c)} className="menu-list-item-with-controls" right={rightContent}>
                <MenuCategoryBase
                    className="menu-list-item"
                    {...c}
                    onSelectCategory={onSelectCategory.bind(null, c.id, c)}
                />
            </MenuListItem>
        );
    });

    return (
        <MenuList
            className="menu-removable-categories menu-list-compact no-horizontal-margin"
            emptyContent={<MenuListEmptyItem message={props.emptyContentMessage || 'No categories selected'} />}
            type={MenuListType.Categories}
            {...props}
        >
            {categoriesList}
        </MenuList>
    );
};

export default MenuRemovableCategories;
