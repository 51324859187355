import React from 'react';
import PropTypes from 'prop-types';

import InlineUser, { DisplayInlineUser } from 'components/UI/User/InlineUser';

export const UserItemContent = (props) => {
    const { id, firstname = '', lastname = '', email = '', avatarThumb } = props;

    return (
        <>
            <div className="content">
                <InlineUser
                    display={DisplayInlineUser.AvatarAndFullName}
                    id={id}
                    firstname={firstname}
                    lastname={lastname}
                    email={email}
                    avatarThumb={avatarThumb}
                />
            </div>
        </>
    );
};
UserItemContent.propTypes = {
    id: PropTypes.number,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    avatarThumb: PropTypes.string,
};

export const UserItemBase = (props) => {
    const { children, className: classNameFromProps, onClick = _.noop } = props;
    const className = ['user-item', classNameFromProps].filter(Boolean).join(' ');

    return (
        <div className={className} onClick={onClick}>
            <UserItemContent {...props} />
            {children}
        </div>
    );
};
UserItemBase.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.any,
    id: PropTypes.number,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    avatar: PropTypes.string,
    avatarThumb: PropTypes.string,
};
