import { ItemView, ViewModel } from "@b2cmessenger/backbone";
import { Optional, Required } from "@b2cmessenger/backbone";
import { bindingHandler as dateTime } from 'widgets/lite/dateTime/dateTime';
import moment from 'moment-timezone';

import template from './ReservationContent.jade';
import './ReservationContent.scss';
import ReservationModel from 'models/ReservationModel';

const SmokingZone = {
    Yes: 'yes',
    No: 'no'
};

const SmokingZoneText = {
    [SmokingZone.Yes]: 'Smoking',
    [SmokingZone.No]: 'No Smoking'
};

const bakeTime = (value, unit) => {
    return value + ' ' + unit + (value == 1 ? '' : 's');
};

const prettyDuration = (time) => {
    const momentDuration = moment.duration(time);
    const days = momentDuration.get('days');
    const hours = momentDuration.get('hours');
    const minutes = momentDuration.get('minutes');

    if (days < 7) {
        if (days === 0) {
            if (hours === 0) { // minutes
                return bakeTime(minutes, 'minute');
            } else { // hours and minutes
                return bakeTime(hours, 'hour') + (minutes ?' ' + bakeTime(minutes, 'minute') : '');
            }
        } else { // days and hours
            return bakeTime(days, 'day') + (hours ?' ' + bakeTime(hours, 'hour') : '');
        }
    } else { // days
        return bakeTime(days, 'day');
    }
};

@ItemView.options({
    model: Required,
    messageModel: Required,
    parentViewModel: Optional,
})
@ItemView.properties({
    template,
    tagName: 'div',
    className: 'widget reservation-content-widget',

    ui: {
        date: '[data-js-date]',
        duration: '[data-js-duration]',
        guests: '[data-js-guests]',
        smokingZone: '[data-js-zone]',
        childSeats: '[data-js-child-seats]',
        status: '[data-js-status]',
        statusBadge: '[data-js-status-badge]'
    },

    computeds: {
        c_statusBadgeText: {
            deps: ['status'],
            get: status => ReservationModel.StatusText[status] || 'Unknown'
        },
        c_statusClasses: {
            deps: ['status'],
            get: status => {
                return {
                    'status__warning': [
                        ReservationModel.Status.New,
                        ReservationModel.Status.BusinessSuggestedChanges,
                        ReservationModel.Status.ClientSuggestedChanges
                    ].indexOf(status) != -1,
                    'status__negative': [
                        ReservationModel.Status.CancelledByClient,
                        ReservationModel.Status.RejectedByBusiness
                    ].indexOf(status) != -1,
                    'status__positive': status == ReservationModel.Status.Confirmed,
                    'status__active':   status == ReservationModel.Status.Completed
                };
            }
        },
        c_DateStartLocal: {
            deps: ['date_start_local'],
            get: date_start_local => B2Cjs.datetimeServerLocalToJS(date_start_local)
        }
    },

    bindings: {
        '@ui.date': 'dateTime:c_DateStartLocal',
        '@ui.duration': 'text:prettyDuration(date_start_local,date_end_local)',
        '@ui.statusBadge':'text:c_statusBadgeText',
        '@ui.status': 'classes:c_statusClasses'
    },

    bindingHandlers: {
        dateTime
    },

    bindingFilters: {
        prettyDuration: (from, to) => prettyDuration(moment(to).diff(from))
    },

    modelEvents: {
        'change:opt_children_count change:opt_smoking_area change:opt_guests_count'() {
            this._debouncedRender();
        }
    }
})
class ReservationContent extends ItemView {
    initialize() {
        this.viewModel = new ViewModel({
            parentViewModel: this.options.parentViewModel || null,
        });

        this.messageModel = this.options.messageModel;

        this._debouncedRender = _.debounce(function () {
            if (!this.isDestroyed && this.isRendered) {
                this.render();
            }
        }, 1);
    }

    onShow() {
        this.listenTo(this.messageModel, 'change:text', () => {
            this._debouncedRender();
        });
    }

    templateHelpers() {
        const comment = this.messageModel.get('text');

        return {
            hasChildrenCount: this.model.get('opt_children_count') > 0,
            smokingZoneText: SmokingZoneText[this.model.get('opt_smoking_area')] || 'Any',
            hasComment: !!comment,
            comment
        }
    }
}

export default ReservationContent;
