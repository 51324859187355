import LoginedUserModel from "models/LoginedUserModel";
import UserModel from "models/UserModel";

export default function _getLoginedUserModel() {
    // @ts-ignore
    return new LoginedUserModel(_.extend({},
        _.defaults({}, LoginedUserHandler.getLoginedUser(), UserModel.prototype.defaults, { id: null }),
        {
            workplaceRoles: LoginedUserHandler.get_work_places_roles()
        }
    ));
}

export function hasRoleInPlace(placeId, role) {
    const loginedUserModel = _getLoginedUserModel();

    return loginedUserModel
        && loginedUserModel.get('isLoggedIn')
        && loginedUserModel.hasRoleInPlace(placeId, role);
}

export function isLoggedIn() {
    return LoginedUserHandler.isUserLogined();
}
