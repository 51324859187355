import _ from 'underscore';
import { Backbone, Marionette } from '@b2cmessenger/backbone';

import PropertiesWidget from 'widgets/Properties/Properties';
import CategoryChooserWindow from 'windows/Category/CategoryChooser';

import './FilterPanel.scss';
import template from './FilterPanel.jade';

import getNextPrettyColor from 'utils/randomPrettyColor';

let FilterPanelView = Marionette.LayoutView.extend({
    template: template,

    tagName: "div",
    className: "container filter-panel",

    regions: {
        properties: '> .subcont2 > .properties',
    },

    initialize() {
        let view = this;

        this.model = new Backbone.Model({ properties: [] });
        this.listenTo(this.model, 'change:properties', (model, properties) => this.filterCont.changeFilter('properties', properties));

        this.filterCont = {
            pagePlacesSearch: null, // Родительский класс - страница поиска
            filter: null, // Общий с родительским классом объект содержащий текущий фильтр

            jqFilterCont: null, // Контейнер с фильтрами

            jqBarBottom: null, // Нижний бар страницы
            jqBtnSearch: null, // Кнопка включения обычного поиска
            jqBtnFavorite: null, // Кнопка включения поиска избранным местам
            jqBtnClear: null, // Кнопка - сбросить фильтры
            jqBtnDone: null, // Кнопка - искать с учетом выставленных фильтров
            jqResultsCount: null, // Счетчик результатов

            jq_users_verified_switch: null, // Переключатель режима расчета рейтинга по верификации пользователей
            jq_users_anonym_switch: null, // Переключатель режима расчета рейтинга по видимости пользователей

            jq_place_sortby_toggle: null, // Переключатель режима сортировки результата
            jq_b2crating: null, // Фильтр по рейтингу места

            jqPointSearchCont: null, // Контейнер для элементов указания точки, вокруг которой необходимо совершать поиск мест (инпут полей и карты)
            jqGoogleMapContainer: null, // контейнер с картой для указания точки, вокруг которой идет поиск
            jg_input_search_point: null, // поле ввода адреса точки поиска
            jqBtnMapViewPoint: null, // Кнопка показать/скрыть jqGoogleMapContainer
            myGoogleMap: null, // Карта для указания точки поиска
            myGoogleMapInited: false, // true - если myGoogleMap инициализирован

            jqCatsViewer: null, // Виджет отображения выбранных категрий мест

            jq_work_hours_toggle: null, // Виджет установки открыто ли заведение сейчас или в указанное время
            jq_work_hours_time_cont: null, // Контейнер с уточнением времени, когда заведение должно быть открыто
            jq_work_hours_input: null, // Поле с датой и временем, когда заведение должно быть открыто

            initialize: function (jqFilterCont, jqBarBottom, filter, pagePlacesSearch) {
                var that = this;

                this._prevValues = {};

                this.jqFilterCont = jqFilterCont;
                this.filter = filter;
                this.pagePlacesSearch = pagePlacesSearch;

                this.initBarBottom(jqBarBottom);
                this.initNearPointToggle();

                var jq_subcont2 = this.jqFilterCont.children('.subcont2');
                this.initPlaceRatingFilter(jq_subcont2);
                this.initDistanceControl(jq_subcont2);
                this.initUsersVerifiedSwitch(jq_subcont2);
                this.initUsersAnonymSwitch(jq_subcont2);
                this.initSortByToggle(jq_subcont2);
                this.init_work_hours_filter(jq_subcont2);
                this.init_point_search_cont();

                this.initCategories();

                //this.on_btn_near_user_click();
            },

            initBarBottom: function (jqBarBottom) {
                var that = this;
                this.jqBarBottom = jqBarBottom;
                this.jqBtnSearch = this.jqBarBottom.find('[data-js-btn-search]');
                this.jqBtnSearch.addClass('active');
                this.jqBtnSearch.click(function () {
                    that.on_btn_search_click()
                });

                this.jqBtnFavorite = this.jqBarBottom.find('[data-js-btn-favorites]');
                this.jqBtnFavorite.click(function () {
                    that.on_btn_favorite_click()
                });

                this.jqBtnDone = this.jqBarBottom.find('[data-js-btn-done]');
                this.jqBtnDone.click(function () {
                    that.on_btn_done_click()
                });

                this.jqResultsCount = this.jqBtnDone.find('.number');

                this.jqBtnClear = this.jqBarBottom.find('[data-js-btn-clear]');
                this.jqBtnClear.click(this.setFilterToDefaults.bind(this));
            },

            initNearPointToggle: function () {
                var that = this;
                var jq_near_point_toggle = this.jqFilterCont.children('[data-js-near-toggle]');
                this.jqBtnNearUser = jq_near_point_toggle.find('[data-js-btn-nearuser]');
                this.jqBtnNearOther = jq_near_point_toggle.find('[data-js-btn-nearother]');

                this.jqBtnNearUser.click(function () {
                    that.on_btn_near_user_click();
                });
                this.jqBtnNearOther.click(function () {
                    that.on_btn_near_other_click();
                });
            },

            initDistanceControl: function (jq_parent) {
                var jq_distance_cont = jq_parent.children('.distance_cont');
                this.jqDistanceSlider = jq_distance_cont.find('.distance_slider');
                this.jqDistanceValue = jq_distance_cont.find('.distance');
                this.jqDistanceValue.text(this.jqDistanceSlider.val() + (this.jqDistanceSlider.val() > 1 ? ' miles' : ' mile'));
                this.jqDistanceSlider.on("slidestop", event => this.on_distance_slider_change_finished());

                this.jqDistanceSlider.change(event => {
                        var maxdistance = this.jqDistanceSlider.val();
                        var text;
                        if (maxdistance && maxdistance < FilterPanelView.CONST_UNLIMITED_DISTANCE_SINCE)
                            text = maxdistance + (maxdistance > 1 ? ' miles' : ' mile');
                        else
                            text = 'unlimited';
                        this.jqDistanceValue.text(text);
                    }
                );

                this.jqDistanceSlider.val(
                    (this.filter.maxdistance && this.filter.maxdistance < FilterPanelView.CONST_UNLIMITED_DISTANCE_SINCE) ?
                        this.filter.maxdistance :
                        FilterPanelView.CONST_MAX_VALUE_DISTANCE_SLIDER
                );
                this.jqDistanceSlider.change();
                this.jqDistanceSlider.slider('refresh');
            },

            initUsersVerifiedSwitch: function (jq_parent) {
                var that = this;

                this.jq_users_verified_switch = jq_parent.children('.users_verified').children('.users_verified_switch');
                this.set_users_verified_switch(this.filter.unverified);
                this.jq_users_verified_switch.on('change', function () {
                    that.on_users_verified_switch_change();
                });
            },

            initUsersAnonymSwitch: function (jq_parent) {
                var that = this;

                this.jq_users_anonym_switch = jq_parent.children('.users_anonym').children('.users_anonym_switch');
                this.set_users_anonym_switch(this.filter.anonym);
                this.jq_users_anonym_switch.on('change', function () {
                    that.on_users_anonym_switch_change();
                });
            },

            initPlaceRatingFilter: function (jq_parent) {
                var that = this;
                this.jq_b2crating = jq_parent.children('.minrating_cont').children('.b2crating');
                this.jq_b2crating.b2crating({
                    rating: 0,
                    readOnly: false,
                    onRatingChange: function (rating) {
                        that.on_place_rating_filter_change(rating)
                    }
                });
            },

            initSortByToggle: function (jq_parent) {
                var that = this;

                var isSortByDistance = true;
                if (this.filter.sortby != B2CPlace.const.search.sortby.dis_asc)
                    isSortByDistance = false;

                this.jq_place_sortby_toggle = jq_parent.children('.place_sortby_toggle');
                this.jq_place_sortby_toggle.b2ctoggle({
                    multiMode: false,
                    states: [
                        { val: 'distance', set: isSortByDistance },
                        { val: 'rating', set: !isSortByDistance }
                    ],
                    onValueChange: function (states) {
                        that.on_place_sortby_change(states)
                    }
                });
            },

            init_point_search_cont: function () {
                var that = this;

                this.jqPointSearchCont = this.jqFilterCont.children('.point_search_cont');
                var jq_point_search_control = this.jqPointSearchCont.children('.point_search_control');
                this.jg_input_search_point = jq_point_search_control.find('.search.point');

                this.jqGoogleMapContainer = this.jqPointSearchCont.children('.google_map_container');

                this.jqBtnMapViewPoint = jq_point_search_control.find('.map');
                this.jqBtnMapViewPoint.click(function () {
                    that.on_btn_map_view_point_click()
                });

                this.jg_input_search_point.prop('disabled', true);
                this.jqBtnMapViewPoint.prop('disabled', true);

                $(document).on(initGoogleMap.event_map_inited, () => this.init_myGoogleMap());
                this.init_myGoogleMap();
            },

            init_myGoogleMap: function () {
                if (this.myGoogleMap || !initGoogleMap.inited)
                    return;

                var that = this;
                var jq_inputs = this.jqGoogleMapContainer.children('.inputs');

                this.myGoogleMap = new MyGoogleMap({
                    //jqGoogleMapAutocompleteInput : $('#google_map_autocomplete'),
                    default_center_latitude: this.filter.point_lat,
                    default_center_longitude: this.filter.point_long,
                    jqGoogleMapAutocompleteInput: this.jg_input_search_point,
                    jqGoogleMapCanvas: this.jqGoogleMapContainer.children('.google_map_canvas'),
                    jqGoogleMapCrosshair: this.jqGoogleMapContainer.children('.google_map_crosshair'),
                    jqLatitudeInput: jq_inputs.find('.tmp_fr_latitude'),
                    jqLongitudeInput: jq_inputs.find('.tmp_fr_longitude'),
                    htmlAdrInputFields: {
                        street_number: jq_inputs.find('.tmp_street_number'),
                        route: jq_inputs.find('.tmp_route'),
                        locality: jq_inputs.find('.tmp_locality'),
                        administrative_area_level_1: jq_inputs.find('.tmp_administrative_area_level_1'),
                        country: jq_inputs.find('.tmp_country'),
                        postal_code: jq_inputs.find('.tmp_postal_code')
                    },
                    onCenterChanged: _.debounce((place) => {
                        if (!this.pagePlacesSearch.nearUserMode) {
                            this.on_myGoogleMap_center_changed(place)
                        }
                    }, 500)
                });

                this.jg_input_search_point.prop('disabled', false);
                this.jqBtnMapViewPoint.prop('disabled', false);
            },

            initCategories: function () {
                var jqCategoriesCont = this.jqFilterCont.find('.categories_cont');

                this.jqCatsViewer = jqCategoriesCont.find('.cats_view');
                this.jqCatsViewer.b2ccategoryviewer();

                jqCategoriesCont.find('.btn_edit_cats').click(event => {
                    new CategoryChooserWindow()
                        .show(this.filter.categories || [])
                        .then(selection => {
                            const selected = selection.get('selectedOrHasSelectedChildren'),
                                hasSelectedChildren = selection.get('hasSelectedChildren'),
                                allChildrenSelected = selection.get('allChildrenSelected');

                            const cats = selected.filter(c => !hasSelectedChildren.get(c.id) || allChildrenSelected.get(c.id));
                            cats.sort((c1, c2) => {
                                const parents1 = c1.get('parents').slice().reverse(),
                                    parents2 = c2.get('parents').slice().reverse();

                                parents1.push(c1);
                                parents2.push(c2);

                                for (let i = 0; i < Math.max(parents1.length, parents2.length); i++) {
                                    let name1 = parents1[i] && parents1[i].get('name') || '',
                                        name2 = parents2[i] && parents2[i].get('name') || '';

                                    if (name1 < name2) return -1;
                                    else if (name1 > name2) return 1;
                                }

                                let name1 = c1.get('name'), name2 = c2.get('name');

                                return name1 < name2 ? -1 : name1 > name2;
                            });
                            this.changeFilter('categories', _(cats).map(cat => cat.id));
                            this.jqCatsViewer.b2ccategoryviewer('set', _(cats).map(c => c.toJSON({ computeds: true })));
                            this.jqCatsViewer.children('.b2c_cat').each((index, el) => el.style['border-color'] = getNextPrettyColor(el.text));

                            view.onCategoriesChange();
                        });
                });
            },

            init_work_hours_filter: function (jq_parent) {
                var that = this;

                var jq_working_hours_cont = jq_parent.children('.working_hours_cont');
                this.jq_work_hours_toggle = jq_working_hours_cont.children('.work_hours_toggle');
                this.jq_work_hours_toggle.b2ctoggle({
                    readOnly: false,
                    multiMode: false,
                    unsetAllEnabled: true,
                    onValueChange: function (states) {
                        that.on_work_hours_toggle_change(states)
                    }, // callback function(states)
                });

                this.jq_work_hours_time_cont = jq_working_hours_cont.children('.datetime_cont');
                this.jq_work_hours_input = this.jq_work_hours_time_cont.find('.open_datetime');
                this.jq_work_hours_input.change(function () {
                    that.on_work_hours_input_change()
                });
            },

            changeFilter: function (key, value, partlyChanged) {
                this._prevValues[key] = this.filter[key];

                this.pagePlacesSearch.isFilterChanged = true;
                if (value == null)
                    delete this.filter[key];
                else
                    this.filter[key] = value;

////////////////////////////
///////////////////////////////////////////////////////////
////////////////////////
//////////////////////////
//////////////////////////////////
/////////////////////////////////////////////////
////////////////////////////////////////////////
///////////////////
/////////////////////////

                if (!partlyChanged) {
                    this.pagePlacesSearch._onFilterChanged(key, value, this._prevValues);
                    this._prevValues = {};
                }
            },

            isFilterDefault() {
                let anonym, unverified, loginedUser = LoginedUserHandler.getLoginedUser()
                if (loginedUser) {
                    anonym = loginedUser.settings.viewanonym;
                    unverified = loginedUser.settings.viewunverified;
                }

                if (!this.pagePlacesSearch.nearUserMode ||
                    this.filter.maxdistance != null ||
                    this.filter.minrating ||
                    (this.filter.categories && this.filter.categories.length) ||
                    (this.filter.properties && this.filter.properties.length) ||
                    this.filter.open != null ||
                    this.filter.sortby != B2CPlace.const.search.sortby.dis_asc ||
                    this.filter.anonym != anonym ||
                    this.filter.unverified != unverified)
                    return false;

                return true;
            },

            setFilterToDefaults: function (options) {
                options = options || {};
                this.pagePlacesSearch.initFilterParam();

                this.filter.favorites = options.favorites ? true : false;
                this.filter.maxdistance = null;
                this.filter.minrating = null;
                this.filter.categories = null;
                this.filter.properties = [];
                this.filter.open = null;
                this.filter.sortby = B2CPlace.const.search.sortby.dis_asc;
                this.filter.offset = 0;

                view.model.set('properties', []);

                this.setBtnsFavoriteSearch(this.filter.favorites);
                this.set_widget_maxdistance(this.filter.maxdistance);
                this.set_users_anonym_switch(this.filter.anonym);
                this.set_users_verified_switch(this.filter.unverified);
                this.set_sort_by_toggle(this.filter.sortby);
                this.set_place_rating_filter(this.filter.minrating);
                this.jqCatsViewer.b2ccategoryviewer('set', []);
                this.set_point_search_widget(this.filter.point_lat, this.filter.point_long);
                this.set_work_hours_filter(this.filter.open);

                this.pagePlacesSearch.isFilterChanged = true;
                this._prevValues = {};
                this.pagePlacesSearch._onFilterChanged('*');
            },

            setIsFavoriteSearchMode: function (mode) {
                this.setBtnsFavoriteSearch(mode);
                if (mode) {
                    this.set_widget_maxdistance(FilterPanelView.CONST_MAX_VALUE_DISTANCE_SLIDER);
                    this.changeFilter('maxdistance', null, true);
                }
                this.changeFilter('favorites', mode);
            },

            set_widget_maxdistance: function (maxdistance) {
                if (maxdistance === null) maxdistance = FilterPanelView.CONST_MAX_VALUE_DISTANCE_SLIDER;

                this.jqDistanceSlider.val(maxdistance);
                this.jqDistanceSlider.slider('refresh');
                var text;
                if (maxdistance < FilterPanelView.CONST_UNLIMITED_DISTANCE_SINCE)
                    text = maxdistance + (maxdistance > 1 ? ' miles' : ' mile');
                else
                    text = 'unlimited';
                this.jqDistanceValue.text(text);
            },

            set_users_verified_switch: function (unverified) {
                //this.jq_users_anonym_switch.slider( 'option', 'disabled', anonym);
                this.jq_users_verified_switch.val(unverified ? 'false' : 'true');
                this.jq_users_verified_switch.slider('refresh');
            },

            set_users_anonym_switch: function (anonym) {
                //this.jq_users_anonym_switch.slider( 'option', 'disabled', anonym);
                this.jq_users_anonym_switch.val(anonym ? 'false' : 'true');
                this.jq_users_anonym_switch.slider('refresh');
            },

            set_sort_by_toggle: function (sortby) {
                var isSortByDistance = false;

                if (sortby == B2CPlace.const.search.sortby.dis_asc)
                    isSortByDistance = true;

                var states = [
                    { val: 'distance', set: isSortByDistance },
                    { val: 'rating', set: !isSortByDistance }
                ];

                this.jq_place_sortby_toggle.b2ctoggle('set', states);
            },

            set_place_rating_filter: function (rating) {
                this.jq_b2crating.b2crating('set', rating);
            },

            set_point_search_widget: function (lt, lg) {
                if (this.myGoogleMap) this.myGoogleMap.setCenter(lt, lg, true);
            },

            set_work_hours_filter: function (open) {
                var states = [
                    { val: 'now', set: open != null },
                    { val: 'other', set: false }
                ];
                this.jq_work_hours_toggle.b2ctoggle('set', states);
                this.jq_work_hours_time_cont.addClass('hidden');
            },

            setBtnsFavoriteSearch: function (mode) {
                if (mode) {
                    this.jqBtnFavorite.addClass('active');
                    this.jqBtnSearch.removeClass('active');
                } else {
                    this.jqBtnFavorite.removeClass('active');
                    this.jqBtnSearch.addClass('active');
                }
            },

            onShow: function () {
                this.filter.mode = B2CPlace.const.search.mode.only_count;
            },

            onHide: function () {
                this.filter.mode = B2CPlace.const.search.mode.get_places;
                if (this.pagePlacesSearch.isFilterChanged) {
                    this.pagePlacesSearch._onFilterChanged();
                    this.pagePlacesSearch.isFilterChanged = false;
                }
            },

            on_btn_search_click: function () {
                this.setIsFavoriteSearchMode(false);
            },

            on_btn_favorite_click: function () {
                this.setIsFavoriteSearchMode(true);
            },

            on_btn_done_click: function () {
                this.pagePlacesSearch._toggleResultFilterView();
            },

            on_btn_near_user_click: function () {
                if (!this.jqBtnNearUser.hasClass('active')) {
                    this.jqBtnNearUser.addClass('active');
                    this.jqBtnNearOther.removeClass('active');
                    this.jqPointSearchCont.addClass('hidden');

                    var currPoss = geo.getCurrentPosition();

                    this.pagePlacesSearch.nearUserMode = true;

                    this.changeFilter('point_lat', currPoss.lt, true);
                    this.changeFilter('point_long', currPoss.lg, false);
                }
            },

            on_btn_near_other_click: function () {
                if (!this.jqBtnNearOther.hasClass('active')) {
                    this.jqBtnNearOther.addClass('active');
                    this.jqBtnNearUser.removeClass('active');
                    this.jqPointSearchCont.removeClass('hidden');
                    this.pagePlacesSearch.nearUserMode = false;

                    if (this.myGoogleMap) {
                        var adr = this.myGoogleMap.getAddress();

                        this.changeFilter('point_lat', adr.latitude, true);
                        this.changeFilter('point_long', adr.longitude, false);
                    }
                }
            },

            on_btn_map_view_point_click: function () {
                if (this.myGoogleMap) {
                    this.jqGoogleMapContainer.toggleClass('hidden');
                    if (!this.jqGoogleMapContainer.hasClass('hidden')) {
                        this.myGoogleMap.refresh();
                        this.jqBtnMapViewPoint.addClass('active');
                    } else {
                        this.jqBtnMapViewPoint.removeClass('active');
                    }
                } else {
                    this.jqGoogleMapContainer.addClass('hidden');
                    this.jqBtnMapViewPoint.removeClass('active');
                }
            },

            on_myGoogleMap_center_changed(place) {
                if (!this.myGoogleMapInited) {
                    this.myGoogleMapInited = true;
                }

                const dont_need_search = (
                    this.filter.point_lat !== null &&
                    this.filter.point_long !== null &&
                    geo.distance(
                        this.filter.point_lat, this.filter.point_long, place.latitude, place.longitude, 'M'
                    ) <= geo.change_lim
                );

                this.changeFilter('point_lat', place.latitude, true);
                this.changeFilter('point_long', place.longitude, dont_need_search);
            },

            on_distance_slider_change_finished: function () {
                var distance = this.jqDistanceSlider.val();
                distance = distance >= FilterPanelView.CONST_UNLIMITED_DISTANCE_SINCE ? null : distance;

                /*if (distance && this.filter.point_lat != null && this.filter.point_long != null) {
                    this.changeFilter('corners', geo.getCorners(this.filter.point_lat, this.filter.point_long, distance), true);
                } else {
                    this.changeFilter('corners', null, true);
                }*/
                this.changeFilter('maxdistance', distance);
            },

            on_users_verified_switch_change: function () {
                var unverified = false;
                if (this.jq_users_verified_switch.find('option:selected').val() == 'false')
                    unverified = true;

                var loginedUser = LoginedUserHandler.getLoginedUser();
                if (loginedUser != null) {
                    loginedUser.settings.viewunverified = unverified;
                    LoginedUserHandler.setLoginedUser(loginedUser);
                }

                this.changeFilter('unverified', unverified);
            },

            on_users_anonym_switch_change: function () {
                var anonym = false;
                if (this.jq_users_anonym_switch.find('option:selected').val() == 'false')
                    anonym = true;

                var loginedUser = LoginedUserHandler.getLoginedUser();
                if (loginedUser != null) {
                    loginedUser.settings.viewanonym = anonym;
                    LoginedUserHandler.setLoginedUser(loginedUser);
                }

                this.changeFilter('anonym', anonym);
            },

            on_place_rating_filter_change: function (rating) {
                this.changeFilter('minrating', rating);
            },

            on_place_sortby_change: function (states) {
                if (this.jq_place_sortby_toggle.b2ctoggle('isset', 'distance')) {
                    this.changeFilter('sortby', B2CPlace.const.search.sortby.dis_asc);
                } else {
                    this.changeFilter('sortby', B2CPlace.const.search.sortby.rating_desc);
                }
            },

            on_google_map_inited: function () {

            },

            on_work_hours_toggle_change: function (states) {
                var is_open_now = false,
                    is_other_day_time = false;
                for (var i = 0; i < states.length; i++) {
                    var state = states[i];
                    if (state.set) {
                        if (state.val == 'now')
                            is_open_now = true;
                        else
                            is_other_day_time = true;
                        break;
                    }
                }

                var d = new Date();
                var open = {};
                open.d = d.getDay();
                open.h = d.getHours();
                open.m = d.getMinutes();

                if (is_open_now) {
                    this.jq_work_hours_time_cont.addClass('hidden');
                    this.changeFilter('open', open);
                } else if (is_other_day_time) {
                    this.jq_work_hours_time_cont.removeClass('hidden');
                    this.jq_work_hours_input.val(B2Cjs.datetimeJsToInput(new Date));
                    this.jq_work_hours_input.change();
                    this.changeFilter('open', open);
                } else {
                    this.jq_work_hours_time_cont.addClass('hidden');
                    this.changeFilter('open', null);
                }
            },
            on_work_hours_input_change: function () {
                var val = this.jq_work_hours_input.val();
                if (val == '' || val == null)
                    this.jq_work_hours_input.addClass('error');
                else {
                    var d = B2Cjs.datetimeInputToJs(val);
                    var open = {};
                    open.d = d.getDay();
                    open.h = d.getHours();
                    open.m = d.getMinutes();
                    this.changeFilter('open', open);
                }
            },
        };
    },

    onRender() {
        this.properties.show(new PropertiesWidget({ model: this.model, edit: true, limit: 2 }));
    },

    onCategoriesChange() {
        this.model.set('properties', _(
            new PropertyBase().get_properties_by_ids(
                _(new CategoryBase().getCatsByIds(this.filterCont.filter.categories || []))
                    .reduce((ids, c) => _.union(ids, c.prop_ids), [])
            )
            ).reduce(
            (ret, p) => _.extend(ret, { [p.varname]: this.model.get('properties') && this.model.get('properties')[p.varname] }),
            Object.create(null)
            )
        );
    }
}, {
    CONST_UNLIMITED_DISTANCE_SINCE: 45, // Дистанция считается не ограниченной с 45 миль
    CONST_MAX_VALUE_DISTANCE_SLIDER: 50, // Максимальное значение слайдера дистанции
});

export default FilterPanelView;
