import { Model, ItemView } from '@b2cmessenger/backbone';
import UserModel from 'models/UserModel';

import template from './UserChild.jade';
import './UserItemView.scss';
import getNextPrettyColor from 'utils/randomPrettyColor';

export default ItemView.extend({
    className: 'user-list-child widget',
    template: template,

    ui: {
        avatar: '[data-js-avatar]',
        name: '[data-js-name]'
    },

    bindings: {
        ':el': 'css:{"border-color":prettyColor}',
        '@ui.avatar': 'css:{"background-image":srcToUrl(avatarUrl)},classes:{empty:not(avatarUrl)}',
        '@ui.name': 'text:name',
    },

    bindingFilters: {
        srcToUrl: src => `url(${src})`,
    },

    events: {
        'click': 'onClick'
    },

    initialize() {
        this.viewModel = new Model({
            prettyColor: getNextPrettyColor(this.model.get('name') || this.model.get('id'))
        });

        this.viewModel.listenTo(this.model, 'change:name change:id',
            () => this.viewModel.set('prettyColor', getNextPrettyColor(this.model.get('name') || this.model.get('id'))))
    },

    onClick() {
        if(this.options.clickable) {
            this.trigger('click:user', this.model);
        }
    }
});
