import { CompositeView, ViewModel } from '@b2cmessenger/backbone';
import BroadcastItem from 'widgets/Broadcast/BroadcastItem';
import template from './BroadcastCollection.jade';
import './BroadcastCollection.scss';

const BroadcastCollection = CompositeView.extend({
    getSortBy() {
        return this.viewModel.get('sort');
    },

    options: {
        sortBy: "updated_at desc",
    },

    tagName: 'table',
    template,
    className: 'widget broadcast-collection',
    childView: BroadcastItem,
    childViewContainer: '[data-js-body]',

    ui: {
        dateHeader: '[data-js-date-header]',
        recipientsHeader: '[data-js-recipients-header]'
    },

    computeds: {
        'isSortedByDate': {
            deps: ['sort'],
            get: sort => sort == 'updated_at'
        },
        'isSortedByDateDesc': {
            deps: ['sort'],
            get: sort => sort == 'updated_at desc',
        },
        'isSortedByRecipients': {
            deps: ['sort'],
            get: sort => sort == 'recipient_count'
        },
        'isSortedByRecipientsDesc': {
            deps: ['sort'],
            get: sort => sort == 'recipient_count desc'
        }
    },

    bindings: {
        ':el':
            'classes:{' +
                '"sorted-by-date": isSortedByDate,' +
                '"sorted-by-date-desc": isSortedByDateDesc,' +
                '"sorted-by-recipients": isSortedByRecipients,' +
                '"sorted-by-recipients-desc": isSortedByRecipientsDesc' +
            '}',
    },

    events: {
        'click @ui.dateHeader'() {
            let sort = this.viewModel.get('sort');
            if (sort == 'updated_at') {
                sort = 'updated_at desc';
            } else {
                sort = 'updated_at';
            }
            this.viewModel.set({ sort });
        },
        'click @ui.recipientsHeader'() {
            let sort = this.viewModel.get('sort');
            if (sort == 'recipient_count desc') {
                sort = 'recipient_count';
            } else {
                sort = 'recipient_count desc';
            }
            this.viewModel.set({ sort });
        }
    },

    initialize() {
        if (!this.viewModel) {
            this.viewModel = new ViewModel;
        }

        this.viewModel.addComputed('sort', {
            deps: ['_sort'],
            get: _sort => _sort || _.first(_.keys(this.collection.comparators)),
            set: val => _.create(null, {
                _sort: this.collection.comparators[val] ? val : null
            })
        });

        this.collection.listenTo(this.viewModel, 'change:sort', (m, sort) => this.triggerMethod('change:sortBy', sort));

        this.viewModel.set({
            sort: this.options.sortBy
        });
    },

    onChangeSortBy(sort) {
        this.collection.comparator = sort;
        this.collection.sort();
    }
});

export default BroadcastCollection;
