import React, { useCallback } from 'react';

import { WindowWrapper } from 'components/window/Window';
import ReactWindow from 'windows/ReactWindow';
import MenuSelectableOrders from 'components/menu/MenuOrder/MenuSelectableOrders';
import './MenuSelectOrder.scss';

const MenuSelectOrder = (props) => {
    const { selectedOrderIds, displaySwitch, orders, disabled } = props;
    const onSelectedChange = useCallback(
        (selected) => {
            props.closeWindow(selected || null);
        },
        [props.closeWindow]
    );

    return (
        <WindowWrapper
            closeWindow={props.closeWindow}
            title="Select order"
            disabled={disabled}
            isModalWindow={false}
            displayContinueButton={false}
        >
            <MenuSelectableOrders
                items={orders}
                selected={selectedOrderIds}
                displaySwitch={displaySwitch}
                onSelectedChange={onSelectedChange}
            />
        </WindowWrapper>
    );
};

const MenuSelectOrderWindow = ReactWindow.extend({
    constructor: function MenuSelectOrderWindow(props) {
        const options = {
            props,
            component: MenuSelectOrder,
            name: 'MenuSelectOrder',
        };

        return ReactWindow.apply(this, [options]);
    },
});

export default MenuSelectOrderWindow;
