import React from 'react';
import './InlineUser.scss';
import getNextPrettyColor from 'utils/randomPrettyColor';

export const DisplayInlineUser = {
    AvatarOnly: 0,
    AvatarAndName: 1,
    AvatarAndFullName: 2,
    AvatarAndEmail: 3,
};

const InlineUserName = (props) => {
    if (!props.name) {
        return null;
    }

    return <div className="name">{props.name}</div>;
};
const InlineUserAvatar = (props) => {
    const avatarStyle = React.useMemo(
        () => ({
            backgroundImage: props.avatarThumb ? `url(${props.avatarThumb})` : null,
            backgroundColor: props.id ? getNextPrettyColor(props.id || 'anonymId') : null,
        }),
        [props.avatarThumb, props.id]
    );

    return <div className="avatar" style={avatarStyle} data-empty={props.id ? null : true} />;
};
const InlineUser = (props) => {
    const { display = DisplayInlineUser.AvatarAndName, onClick = _.noop } = props;

    const list = React.useMemo(() => {
        const avatar = <InlineUserAvatar key="a" id={props.id} avatarThumb={props.avatarThumb} />;

        let nameVal = null;
        switch (display) {
            case DisplayInlineUser.AvatarOnly:
                nameVal = null;
                break;
            case DisplayInlineUser.AvatarAndName:
                nameVal = props.firstname ? props.firstname : props.email ? props.email : null;
                break;
            case DisplayInlineUser.AvatarAndFullName:
                nameVal = [props.firstname, props.lastname].filter(Boolean).join(' ');

                if (!nameVal) {
                    nameVal = props.email ? props.email : null;
                }
                break;
            case DisplayInlineUser.AvatarAndEmail:
                nameVal = props.email ? props.email : null;
                break;
            default:
                nameVal = null;
                break;
        }

        const name = <InlineUserName key="n" name={nameVal} />;

        return props.nameFirst ? [name, avatar] : [avatar, name];
    }, [display, props.nameFirst, props.avatarThumb, props.firstname, props.lastname]);

    return (
        <div onClick={onClick} className={['user user-inline', props.className].filter(Boolean).join(' ')}>
            {list}
        </div>
    );
};

export const InlineUsers = (props) => {
    const { display = DisplayInlineUser.AvatarAndName } = props;

    const list = React.useMemo(() => {
        const avatar = (
            <React.Fragment key="a">
                {props.users.map(({ id, avatarThumb }) => (
                    <InlineUserAvatar key={id} id={id} avatarThumb={avatarThumb} />
                ))}
            </React.Fragment>
        );
        const name =
            display !== DisplayInlineUser.AvatarOnly ? (
                <div key="n" className="name">
                    {props.users
                        .map(({ firstname, lastname }) =>
                            display === DisplayInlineUser.AvatarAndFullName
                                ? [firstname, lastname].filter(Boolean).join(' ')
                                : firstname
                        )
                        .join(', ')}
                </div>
            ) : null;

        return props.nameFirst ? [name, avatar] : [avatar, name];
    }, [display, props.users, props.nameFirst]);

    return <div className={['user user-inline users-inline', props.className].filter(Boolean).join(' ')}>{list}</div>;
};

export default InlineUser;
