import React, { useMemo, useState, useEffect } from 'react';

import './MenuPlaceOrder.scss';
import MenuTextItems, { getTextItemsFromOrderFormattedItems } from 'components/menu/MenuItems/MenuTextItems';
import { isOrderFinished } from 'components/menu/utils';
import MenuPlaceOrderActions, { OrderActionButton } from 'components/menu/MenuPlaceOrder/MenuPlaceOrderActions';
import InlineUser, { InlineUsers } from 'components/UI/User/InlineUser';
import { MenuOrderComment } from 'components/menu/MenuOrder/MenuOrder';
import MenuWaiterCallContainer from 'components/menu/MenuWaiterCall/MenuWaiterCallContainer';

export const OrderStatus = {
    Created: 1,
    Confirmed: 2,
    Done: 3,
    Canceled: -1,
    Unknown: null,
};

export const PlaceOrderStatusText = {
    [OrderStatus.Created]: 'Waiting for confirmation',
    [OrderStatus.Confirmed]: 'Accepted',
    [OrderStatus.Done]: 'Done',
    [OrderStatus.Canceled]: 'Canceled',
    [OrderStatus.Unknown]: 'Unknown',
};

const PlaceOrderStatusClassName = {
    [OrderStatus.Created]: 'status-warning',
    [OrderStatus.Confirmed]: 'status-positive',
    [OrderStatus.Done]: 'status-active',
    [OrderStatus.Canceled]: 'status-negative',
    [OrderStatus.Unknown]: 'status-warning',
};

export const getPlaceOrderListItemClassName = (item, ...classNames) => {
    return ['menu-place-order', ...classNames].filter(Boolean).join(' ');
};

const MenuPlaceOrderClient = (props) => {
    if (_.isUndefined(props.client) && (!_.isArray(props.clients) || props.clients.length === 0)) {
        return null;
    }

    const prefix = useMemo(() => {
        if (_.isArray(props.clients) && props.clients.length > 1) {
            return 'Clients:';
        }
        if (!_.isUndefined(props.client) || (_.isArray(props.clients) && props.clients.length === 1)) {
            return 'Client:';
        }
    }, [props.client, props.clients]);

    return (
        <div className={['order-client', props.className].filter(Boolean).join(' ')}>
            <span>{props.clients && props.clients.length > 1}</span>
            <span>{prefix}</span>
            <span>&nbsp;</span>
            {props.client && <InlineUser {...props.client} nameFirst />}
            {props.clients && <InlineUsers users={props.clients} nameFirst />}
        </div>
    );
};

const MenuOrderWaiter = ({ waiterUser, className }) => {
    return waiterUser ? (
        <div className={['order-waiter', className].filter(Boolean).join(' ')}>
            <span>Waiter:</span>
            <span>&nbsp;</span>
            <InlineUser {...waiterUser} nameFirst />
        </div>
    ) : null;
};

const MenuPlaceOrderTime = (props) => {
    const [now, setNow] = useState(Date.now());
    const [time, setTime] = useState();

    useEffect(() => {
        setTime(_.isNumber(props.time) ? props.time : null);
    }, [props.time, setTime]);

    useEffect(() => {
        const t = setInterval(() => setNow(Date.now()), 1000);
        return () => clearInterval(t);
    }, [setNow]);

    const content = useMemo(() => {
        const value = Math.floor((now - time) / 1000 / 60);

        if (value < 1) {
            return (
                <span>
                    less <span>1</span> min
                </span>
            );
        }

        if (value >= 60) {
            return (
                <span>
                    more <span>1</span> hr
                </span>
            );
        }
        return (
            <span>
                <span>{value}</span> min
            </span>
        );
    }, [now, time]);

    if (!_.isNumber(now) || !_.isNumber(time)) {
        return null;
    }

    return (
        <div className={['order-time', props.className].filter(Boolean).join(' ')}>
            <i className={props.icon} />
            {content}
        </div>
    );
};

const MenuPlaceOrderInfo = (props) => {
    const order = useMemo(() => {
        if (_.isNumber(props.id)) {
            return { prefix: 'Order', id: props.id };
        }
        if (_.isArray(props.orders) && props.orders.length > 0) {
            return {
                prefix: `Order${props.orders.length > 1 ? 's' : ''}`,
                id: props.orders.map((o) => o.id).join(', '),
            };
        }
        return { prefix: 'Empty', id: null };
    }, [props.id, props.orders]);

    const { showAnonymousClient = true } = props;
    const anonymousClient = { firstname: 'Anonymous' };

    const showClient = Boolean(props.client || (props.clients && props.clients.length));
    const clients = !showClient && showAnonymousClient ? [anonymousClient] : props.clients;

    const clientWaiterMetaRow =
        showClient || showAnonymousClient || props.waiterUser ? (
            <div className="order-meta-row client-waiter">
                {(showClient || showAnonymousClient) && (
                    <MenuPlaceOrderClient client={props.client} clients={clients} />
                )}
                {props.waiterUser && <MenuOrderWaiter waiterUser={props.waiterUser} />}
            </div>
        ) : null;

    return (
        <div className={['order-info', props.className].filter(Boolean).join(' ')}>
            <div className="order-meta">
                <div className="order-meta-row">
                    <div className="order-property">
                        {order.id !== null && (
                            <>
                                {order.prefix}
                                &nbsp;
                                <span className="order-id">{order.id}</span>
                            </>
                        )}
                        {order.id === null && <span className="empty">{order.prefix}</span>}
                    </div>
                    {props.tableNumber && (
                        <div className="order-property">
                            Table <span>{props.tableNumber}</span>
                        </div>
                    )}
                    {props.status === OrderStatus.Created && (
                        <MenuPlaceOrderTime
                            className="total"
                            icon="ion-md-time"
                            time={props.time && props.time.created}
                        />
                    )}
                </div>
                {clientWaiterMetaRow}
            </div>
        </div>
    );
};

const MenuPlaceOrderStatus = (props) => {
    return (
        <div className={['order-status', props.className].filter(Boolean).join(' ')} onClick={props.onClick}>
            <span className={PlaceOrderStatusClassName[props.status]}>{PlaceOrderStatusText[props.status]}</span>
        </div>
    );
};

export const MenuPlaceOrderContent = (props) => {
    const orders = useMemo(() => [{ id: props.id, status: props.status }], [props.id, props.status]);
    const clients = useMemo(() => props.clients || (props.client && [props.client]), [props.client, props.clients]);

    return (
        <>
            <MenuPlaceOrderInfo
                orders={orders}
                tableNumber={props.tableNumber}
                status={props.status}
                clients={clients}
                waiterUser={props.waiterUser}
                time={props.time}
            />
            <MenuPlaceOrderStatus status={props.status} />
            {props.children}
        </>
    );
};

export const MenuPlaceOrderBase = (props) => {
    const time = {
        created: props.created_at,
        updated: props.updated_at,
    };

    const tableNumber = props && props.data && props.data.table_number;

    return (
        <div className={getPlaceOrderListItemClassName(props, props.className)} onClick={props.onClick}>
            <MenuPlaceOrderContent
                id={props.order_number}
                status={props.status}
                tableNumber={tableNumber}
                client={props.user}
                waiterUser={props.waiterUser}
                clients={props.users}
                time={time}
            />
            {props.children}
        </div>
    );
};

const MenuPlaceOrder = (props) => {
    const {
        order,
        maxOrderItems,
        displayPosition,
        displayPrice,
        displayAdditions,
        displayProperties,
        onChangeStatusClick = _.noop,
        onEditOrderClick = _.noop,
    } = props;

    const textItems = getTextItemsFromOrderFormattedItems(order, {
        includePosition: displayPosition,
        includePrice: displayPrice,
    });

    const comment = order.data && order.data.comment;

    return (
        <MenuPlaceOrderBase {...order} className={props.className} onClick={props.onClick}>
            <MenuOrderComment comment={comment} />
            <MenuTextItems
                items={textItems}
                limit={maxOrderItems}
                displayAdditions={displayAdditions}
                displayProperties={displayProperties}
            />
            <MenuPlaceOrderActions>
                {!isOrderFinished(order) && (
                    <>
                        <OrderActionButton className="wide" onClick={onEditOrderClick.bind(null, order)}>
                            Edit
                        </OrderActionButton>
                        <OrderActionButton className="wide" onClick={onChangeStatusClick.bind(null, order)}>
                            Change Status
                        </OrderActionButton>
                    </>
                )}
            </MenuPlaceOrderActions>
            <MenuWaiterCallContainer
                isEmployee
                models={order.waiterRequests}
                readOnly
                order={order}
                emptyListText="No requests"
            />
        </MenuPlaceOrderBase>
    );
};

export const MenuPlaceTableContent = (props) => {
    const clients = useMemo(() => {
        return _.chain(props.orders)
            .reduce((memo, o) => {
                if (o.user) {
                    memo.push(o.user);
                } else if (_.isArray(o.users)) {
                    memo.push(...o.users);
                }
                return memo;
            }, [])
            .uniq(false, 'id')
            .value();
    }, [props.orders]);

    return (
        <>
            <MenuPlaceOrderInfo
                orders={props.orders}
                tableNumber={props.tableNumber}
                clients={clients}
                waiter={props.waiter}
                time={props.time}
            />
            {!_.isUndefined(props.status) && <MenuPlaceOrderStatus status={props.status} />}
            {props.children}
        </>
    );
};

export const MenuPlaceTableBase = (props) => {
    return (
        <div
            className={getPlaceOrderListItemClassName(props, 'menu-place-table-order', props.className)}
            onClick={props.onClick}
        >
            <MenuPlaceTableContent
                orders={props.orders}
                status={props.status}
                tableNumber={props.number}
                waiter={props.waiter}
            />
            {props.children}
        </div>
    );
};

export const MenuPlaceTable = (props) => {
    const { onAddOrder = _.noop, onShowOnScheme = _.noop, onChangeStatus = _.noop } = props;

    const accumulatedStatus = useMemo(() => {
        const result = _.chain(props.orders)
            .map((o) => o.status)
            .countBy((n) => String(n))
            .pairs()
            .sortBy(([status, count]) => -count + Number(status))
            .first()
            .value();
        return result && Number(result[0]);
    }, [props.orders]);

    return (
        <MenuPlaceTableBase
            className={props.className}
            onClick={props.onClick}
            id={props.id}
            number={props.number}
            orders={props.orders}
            status={accumulatedStatus}
            waiter={props.waiter}
        >
            <MenuPlaceOrderActions>
                <OrderActionButton
                    className="wide"
                    disabled={_.isUndefined(accumulatedStatus)}
                    onClick={onShowOnScheme.bind(null, props.number)}
                >
                    Show on scheme
                </OrderActionButton>
                {!_.isUndefined(accumulatedStatus) && !isOrderFinished({ status: accumulatedStatus }) && (
                    <OrderActionButton className="wide" onClick={onChangeStatus.bind(null, props.orders)}>
                        Change Status
                    </OrderActionButton>
                )}
                <OrderActionButton className="wide" onClick={onAddOrder.bind(null, props.number)}>
                    Add Order
                </OrderActionButton>
            </MenuPlaceOrderActions>
        </MenuPlaceTableBase>
    );
};

export default MenuPlaceOrder;
