import { Model } from '@b2cmessenger/backbone';

@Model.properties({
    defaults: {
        selected: null,
        accepted: 0,
        pending: 0,
        canceled: 0
    },
    computeds: {
        total: {
            deps: ['accepted', 'pending', 'canceled'],
            get: (a, p, c) => a + p + c
        },
        c_DateFormatted: {
            deps: ['id'],
            get: id => new Date(id).getDate()
        }
    }
})
class CalendarModel extends Model {
}

export default CalendarModel;
