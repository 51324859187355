export default function wrapAjax(ajax) {
    function sentryAddBreadcrumb(options) {
        if (window.Sentry) {
            window.Sentry.addBreadcrumb({
                message: `${options.type || 'GET'} ${options.url}`,
                category: 'ajax',
                data: {
                    requestBody: options.data,
                    requestHeaders: options.headers
                }
            });

            const error = options.error,
                success = options.success;

            options.error = function (jqXHR, textStatus, errorThrown) {
                window.Sentry.addBreadcrumb({
                    message: `${options.type || 'GET'} ${options.url} [${jqXHR && jqXHR.status || 0}] ` +
                        `${jqXHR && jqXHR.statusText || textStatus || 'error'}`,
                    category: 'ajax',
                    data: {
                        requestId: jqXHR.getResponseHeader('request-id') || '',
                        requestBody: options.data,
                        requestHeaders: options.headers,
                        responseHeaders: jqXHR && jqXHR.getAllResponseHeaders() || ''
                    }
                });

                if (error)
                    return error(jqXHR, textStatus, errorThrown);
            };

            options.success = function (data, textStatus, jqXHR) {
                window.Sentry.addBreadcrumb({
                    message: `${options.type || 'GET'} ${options.url} [${jqXHR && jqXHR.status || 0}] ` +
                        `${jqXHR && jqXHR.statusText || textStatus || 'success'}`,
                    category: 'ajax',
                    data: {
                        requestId: jqXHR.getResponseHeader('request-id') || '',
                        requestBody: options.data,
                        requestHeaders: options.headers,
                        responseHeaders: jqXHR && jqXHR.getAllResponseHeaders() || ''
                    }
                });

                if (success)
                    return success(data, textStatus, jqXHR);
            };
        }

        return options;
    };

/////////////////
//////////////////////////////////
//////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////
///////////////////////////

////////////////////////////////////
//////////////////////////////////////

///////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
////////////
////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////
///////////////////////////////////
//////////////////////////////////////////////////
///////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
/////////////////////////////////////
///////////////////////////////////////////////////////////////////
/////////////
/////////////////////////////////////////////
//////////////////////////////////////////////
/////////////////////////
/////////////////////////////////////
//////////////////////////////////////////////////////////////
//////////////////////////////////////
/////////////////////////////////////////////////////////////////
//////////////////////////////////
///////////////////////////////////
////////////////////////////////////////////////////////////////////
/////////////////////////////////////////
//////////////
///////////////////////////////
///////////////////////////////

//////////////////////
/////////////////////////////////////////////////////////////
//////////

//////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////////////
///////////////////////////////////////////////////////
/////////////////////////////////////////////
//////////////////////////////////////////////////////////////
////////////////////////////////////////////////
//////////////////////////////////////////////
/////////////////////////
/////////////////////////////////////
//////////////////////////////////////////////////////////////
//////////////////////////////////////
/////////////////////////////////////////////////////////////////
//////////////////////////////////
////////////////////////////////////////////////
//////////////
///////////////////////////////
///////////////////////////////

////////////////////////
////////////////////////////////////////////////////////
//////////

///////////////////////
/////

////////////////////////////////////////////////////////////
/////////////
    return _.compose(ajax, sentryAddBreadcrumb);
//////////////
}
