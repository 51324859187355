import { Required, Optional, ItemView, ViewModel } from '@b2cmessenger/backbone';
import settings from 'settings';
import AjaxError from 'utils/AjaxError';

//@ts-ignore
import autosize from 'autosize';

import template from './MessageReport.jade';
import './MessageReport.scss';

/**@type {typeof import('./MessageReport').options} */
// @ts-ignore
const options = ItemView.options;
/**@type {typeof import('./MessageReport').properties} */
// @ts-ignore
const properties = ItemView.properties;

const Type = {
    Inappropriate: 1,
    Profanity: 2,
    Spam: 3,
    Other: 4
};

@options({
    model: Required
})
@properties({
    className: "widget message-report-widget",
    template,

    ui: {
        type: '[data-js-type]',
        comment: '[data-js-comment]'
    },

    bindings: {
        '@ui.type': 'checked:reportType',
        '@ui.comment': 'value:reportComment'
    },

    templateHelpers: {
        Type
    }
})
class MessageReportView extends ItemView {
    initialize() {
        /** @type {import('./MessageReport')} */
        // @ts-ignore
        const self = this;

        this.viewModel = new ViewModel({
            reportType: Type.Inappropriate,
            reportComment: ''
        });
    }

    report() {
        return new Promise((resolve, reject) => {
            this.viewModel.set({ disabled: true });

            const url = settings.host + (
                this.model.get('isTask') ? settings.serv_task.report : settings.serv_task.comment.report
            );

            Server.callServer({
                url,
                type: "POST",
                data: {
                    id: this.model.id,
                    type: this.viewModel.get('reportType') || Type.Other,
                    comment: this.viewModel.get('reportComment') || ''
                },
                success: () => { 
                    this.viewModel.set({ disabled: false });
                    resolve();
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    this.viewModel.set({ disabled: false });
                    reject(new AjaxError(jqXHR, textStatus, errorThrown));
                }
            });
        });
    }

    onAttach(view) {
        _.defer(() => autosize(this.ui.comment));
    }
}

export default MessageReportView;
