function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = ((hash << 5) - hash) + str.charCodeAt(i);
        hash |= 0;
    }
    return hash;
};

function stripFileProtocolPrefix(url) {
    const prefix = 'file://';
    if (url && url.startsWith(prefix + '/')) {
        return url.slice(prefix.length);
    } else {
        return url;
    }
}

function ionicWebViewConvertFileUrl(url) {
    if (window.Ionic && window.Ionic.WebView && window.Ionic.WebView.convertFileSrc) {
        return window.Ionic.WebView.convertFileSrc(url);
    }

    return url;
}

class OfflineStorage {
    constructor(dirname, temporary) {
        this.dirname = String(dirname);
        this.temporary = Boolean(temporary);
    };

    getDataDir() {
        if(!this.getDataDirPromise) {
            let temporary = this.temporary;
            this.getDataDirPromise = new Promise((resolve, reject) => requestFileSystem(temporary ? window.TEMPORARY : window.PERSISTENT, 0, 
                fs => { 
                    try {
                        resolveLocalFileSystemURL((temporary ? cordova.file.cacheDirectory : cordova.file.dataDirectory), 
                            entry => !this.dirname ? resolve(entry) : entry.getDirectory(this.dirname, { create: true }, 
                                dir => resolve(dir), 
                                err => reject(err)
                            ),
                            err => reject(err)
                        );
                    } catch(err) {
                        reject(err);
                    }
                },
                err => reject(err))
            );
        }
        return this.getDataDirPromise;
    };

    getDataDirEntries() {
        return this.getDataDir()
            .then(dir => new Promise((resolve, reject) => 
                dir.createReader().readEntries(entries => resolve(entries), err => reject(err))));
    };

    getFileIfExists(path, dir) {
        let dirPromise;
        if(!dir) {
            dirPromise = this.getDataDir();
        } else {
            dirPromise = Promise.resolve(dir);
        }

        return dirPromise
            .then(dir => new Promise((resolve, reject) => {
                dir.getFile(path, { create: false }, file => resolve(file), err => reject(err));
            }));
    };

    downloadFile(url, path) {
        return new Promise((resolve, reject) => {
            const fileTransfer = new FileTransfer(),
                uri = encodeURI(url);

            fileTransfer.download(
                uri, path,
                entry => resolve(entry),
                err => reject(err),
                false, { 
                    Authorization: 'Bearer ' + LoginedUserHandler.getAccessToken()
                }
            );
        });
    };

    getLocalCopyUrl(url) {
        url = String(url);
        let name = hashCode(url).toString(16).replace(/^-/, '0');

        return this.getDataDir().then(dataDir => 
            this.getFileIfExists(name)
                .then(file =>
                    ionicWebViewConvertFileUrl(
                        stripFileProtocolPrefix(
                            file.toURL()
                        )
                    )
                )
                .catch(err => {
                    if(err.code && err.code == FileError.NOT_FOUND_ERR) {
                        console.log('Store.getLocalCopyUrl file not found, downloading...', url);
                        return this.downloadFile(url, dataDir.toURL().replace(/\/$/, '') + '/' + name)
                            .then(file => {
                                console.log('Store.getLocalCopyUrl file downloaded', url, file.toURL());
                                return ionicWebViewConvertFileUrl(
                                    stripFileProtocolPrefix(
                                        file.toURL()
                                    )
                                );
                            });
                    } else {
                        throw err;
                    }
                })
        );
    };

    sync(urls) {
//////////////////////
        return Promise.resolve({});
////////////////
//////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////
//////////////////////////////
//////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
/////////////////////
///////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////
////////////////////////////////////
////////////////////////////////////////////
///////////////
/////////////////
    };
};

window.OfflineStorage = OfflineStorage;
///////////////
window.FileTransferError = function FileTransferError(code, source, target, status, body, exception) {};
//////////

export default OfflineStorage;