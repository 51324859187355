/**
 * Created by Alex on 05.03.2016.
 */
var CategoryBase;
(function(){
    var instance;

    CategoryBase = function Singleton() {
        if (instance) {
            return instance;
        }
        instance = this;

        this.init();
    }

    CategoryBase.prototype = {
        init : function() {
            if(app.localStorage)
                CategoryBrowserStorage.init();
        },

        init_local_storage : function(server_cats) {
            if(app.localStorage)
                CategoryBrowserStorage.init_local_storage(server_cats);
        },
        /**
         * Возвращает всю базу категорий в виде ассоциативного массива
         */
        get_all_cars_as_assoc : function() {
            if(app.localStorage)
                return CategoryBrowserStorage.get_all_cars_as_assoc();
        },
        getCats: function (parentid) {
            var result;
            if(app.localStorage)
                result = CategoryBrowserStorage.getCats(parentid);

            return result;
        },
        getCatsByParentIds: function (parentids) {
            var result;
            if(app.localStorage)
                result = CategoryBrowserStorage.getCatsByParentIds(parentids);

            return result;
        },
        getCatsIds: function (parentid) {
            var result;
            if(app.localStorage)
                result = CategoryBrowserStorage.getCatsIds(parentid);

            return result;
        },
        getCatsIdsByParentIds: function (parentids) {
            var result;
            if(app.localStorage)
                result = CategoryBrowserStorage.getCatsIdsByParentIds(parentids);

            return result;
        },
        getCat: function (id) {
            if (app.localStorage) {
                return CategoryBrowserStorage.getCat(id);
            } else {
                return undefined;
            }
        },
        getCatsByIds: function (ids) {
            var result;
            if(app.localStorage)
                result = CategoryBrowserStorage.getCatsByIds(ids);

            return result;
        },
        getParentIds: function (id) {
            var result;
            if(app.localStorage)
                result = CategoryBrowserStorage.getParentIds(id);

            return result;
        },
        getParentIdsForIds: function (ids) {
            var result;
            if(app.localStorage)
                result = CategoryBrowserStorage.getParentIdsForIds(ids);

            return result;
        },
        getAncestorIds : function (ids) {
            var result;
            if(app.localStorage)
                result = CategoryBrowserStorage.getAncestorIds(ids);

            return result;
        },
        getAncestorCats : function (ids) {
            var result;
            if(app.localStorage)
                result = CategoryBrowserStorage.getAncestorCats(ids);

            return result;
        },
        getHierarchyIds : function(ids) {
            var result;
            if(app.localStorage)
                result = CategoryBrowserStorage.getHierarchyIds(ids);

            return result;
        },
        getHierarchyCats : function(ids) {
            var result;
            if(app.localStorage)
                result = CategoryBrowserStorage.getHierarchyCats(ids);

            return result;
        },
    }
}());

window.CategoryBase = CategoryBase; // TODO: remove globals, use module exports
// export default CategoryBase;
