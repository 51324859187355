import { getMenuPlaceId, getMenuState } from "store/menu/selectors";
import getMenuPermissions from "utils/getMenuPermissions";

export const getMenuSectionsState = state => getMenuState(state).sections;
export const getMenuSections = state => getMenuSectionsState(state).values;
export const getMenuSectionById = (state, sectionId) => getMenuSections(state)[sectionId] || null;

export const getMenuTables = state => getMenuSectionsState(state).tables;
export const getMenuTableById = (state, tableId) => getMenuTables(state)[tableId] || null;

export const getMenuSectionsAsArray = state => {
    const values = getMenuSections(state);

    return _.chain(values)
        .pairs()
        .map(([k, v]) => ({ ...v, tables: getMenuTablesAsArrayBySectionId(state, v.id) }))
        .value();
};

export const getMenuTablesAsArray = state => {
    const values = getMenuTables(state);
    return _.chain(values).pairs().map(([k, v]) => v).value();
};

export const getMenuTablesAsArrayBySectionId = (state, sectionId) => {
    const tables = getMenuTablesAsArray(state);
    return tables.filter(t => Number(t.sectionId) === Number(sectionId));
};

export const getMenuPermissionsInCurrentPlace = (state) => {
    const placeId = getMenuPlaceId(state);

    return getMenuPermissions(placeId);
};