import aes from 'aes-js';
import settings from 'settings';
import { initialCartState } from "store/menu/reducers/cart";

export const basicMenuItemKeys = [
    'id', 'menu_id', 'is_customizable',
    'hidden_until', 'is_hidden_forever',
    'photo', 'photo_thumb',
    'name', 'description',
    'price', 'price_addition', 'currency'
];

export const detailMenuItemKeys = [
    ...basicMenuItemKeys.filter(k => k !== 'is_customizable'),
    'is_food',
    'properties', 'ingredients', 'additions',
    'marker_glutenfree', 'marker_sugarfree',
    'marker_lactosefree', 'marker_spicy', 'marker_vegan',
    'param_weight', 'param_volume', 'param_energy',
    'param_protein', 'param_fat', 'param_sugar', 'param_cooking_time'
];

export function isDetailItem(source) {
    return _.has(source, 'is_food');
}

export function getBasicItem(source) {
    return {
        ..._.pick(source, basicMenuItemKeys),
        is_customizable: isItemCustomizable(source)
    };
}

export function getDetailItem(source) {
    return {
        ..._.pick(source, detailMenuItemKeys),
        is_customizable: isItemCustomizable(source)
    };
}

export function getBasicSection(source) {
    return {
        ..._.omit(source, 'tables')
    };
}

export function getBasicTable(source) {
    return {
        ..._.omit(source, 'section_id', 'qr_string'),
        sectionId: source.section_id,
        qrString: source.qr_string,
    }
}

export function isItemCustomizable(source) {
    if (!source) {
        return false;
    }

    const { properties = [], additions = [] } = source;

    return (_.isArray(properties) && properties.length > 0) || (_.isArray(additions) && additions.length > 0) || source.is_customizable
}

export const MenuInvalidationTimeout = 2 * 60 * 1000;

export const isOutOfDate = (timeout, value) => {
    return Date.now() - value > timeout;
};

export function isCategoryOutOfDate(categoryUpdatedAt, invalidationTimeout = MenuInvalidationTimeout) {
    return isOutOfDate(invalidationTimeout, categoryUpdatedAt);
}

export function isItemOutOfDate(itemUpdatedAt, invalidationTimeout = MenuInvalidationTimeout) {
    return isOutOfDate(invalidationTimeout, itemUpdatedAt);
}

export function getMenuCartItem(item) {
    const properties = !_.isArray(item.properties) && _.isObject(item.properties) && { ...item.properties } ||
        _.reduce(
            item.properties,
            (memo, { name = null, value = null }) => ({ ...memo, [name]: value }), {}
        );

    const additions = !_.isArray(item.additions) && _.isObject(item.additions) && { ...item.additions } ||
        _.reduce(
            item.additions,
            (memo, { id = null, count = 0 }) => ({ ...memo, [id]: (memo[id] || 0) + count }), {}
        );

    return {
        id: Number(item.id),
        properties: properties && toSortedObject(properties),
        additions: additions && toSortedObject(additions),
        comment: item.comment
    };
}

export function getMenuCartItemUniqueId({ id, properties, additions }) {
    return cipherAES([id, JSON.stringify(properties), JSON.stringify(additions)].join(''));
}

export function createMenuCartItemInfo(item, itemKey = 'cartItem', idKey = 'uniqueCartId') {
    if (!item) {
        return {}
    }

    const isEditingOrderItem = Boolean(item.orderItemId);

    const cartItem = getMenuCartItem(item);
    const uniqueCartId = isEditingOrderItem ? item.uniqueId : getMenuCartItemUniqueId(cartItem);

    return { [itemKey]: cartItem, [idKey]: uniqueCartId, isEditingOrderItem };
}

export function getMenuCartItemsAsOrderPayload(cartItems = []) {
    return cartItems.map(
        ({ id, quantity, additions, properties, comment, orderItemId }) => {
            const cartItem = {
                item_id: id,
                count: quantity,
                properties: _.chain(properties).keys().map(name => ({ name, value: properties[name] })).value(),
                additions: _.chain(additions).keys().map(item_id => ({ item_id, count: additions[item_id] })).value(),
                comment
            };

            if (orderItemId) {
                cartItem.id = orderItemId
            }

            return cartItem;
        }
    );
}

export function getAdditionItemPrice(item) {
    if (_.has(item, 'price_addition') && _.isNumber(item.price_addition)) {
        return item.price_addition;
    }

    return getItemPrice(item);
}

export function getItemPrice(item) {
    return item.price;
}

function cipherAES(str) {
    const bytes = aes.utils.utf8.toBytes(str);
    const aesCtr = new aes.ModeOfOperation.ctr(aes.utils.hex.toBytes(settings.qrSecrets[0]));
    const encryptedBytes = aesCtr.encrypt(bytes);

    return aes.utils.hex.fromBytes(encryptedBytes);
}

export function toSortedObject(source) {
    return _.chain(source).pairs().sortBy(([k]) => k).object().value();
}

export function upsertItemInArrayByKey({item, array = [], insertAtTheEnd = true, key = 'id'}) {
    const idx = array.findIndex(i => i[key] === item[key]);
    const updatedArray = idx !== -1 ? array.map((i, index) => {
        return index === idx ? item : i
    }) : insertAtTheEnd ? [...array, item] : [item, ...array];

    return updatedArray
}

export function upsertItemsInArrayByKey({items = [], array = [], insertAtTheEnd = true, key = 'id'}) {
    if (!array || array.length === 0) {
        return [...items];
    }

    let updatedArray = [...array];

    for (const item of items) {
        updatedArray = upsertItemInArrayByKey({item, array: updatedArray, insertAtTheEnd, key});
    }

    return updatedArray;
}

export function removeItemFromArrayById(itemOrItemId, array = []) {
    const id = _.isObject(itemOrItemId) ? itemOrItemId.id : itemOrItemId;
    return array.filter(i => i.id !== id);
}

const getPreparedPropertiesFromItem = (item) => {
    return !_.isArray(item.properties) && _.isObject(item.properties) && { ...item.properties } ||
    _.reduce(item.properties,
        (memo, { name = null, value = null }) => ({ ...memo, [name]: value }), {});
};

const getPreparedAdditionsFromItem = (item) => {
    return !_.isArray(item.additions) && _.isObject(item.additions) && { ...item.additions } ||
        _.reduce(item.additions,
            (memo, addItem) => {
                const { item_id = null, count = 0 } = addItem;
                return { ...memo, [item_id]: (memo[item_id] || 0) + count }
            },
            {});
};

export const getCartFromOrder = (order) => {
    const orderIds = [];
    const items = _(order.items).reduce((memo, item) => {
        const orderItemId = item.id;
        const properties = getPreparedPropertiesFromItem(item);
        const additions = getPreparedAdditionsFromItem(item);

        const cartItem = {
            properties,
            additions,
            quantity: item.count,
            id: item['item_id'],
            orderItemId,
            comment: item.comment
        };

        orderIds.push(orderItemId);

        memo[orderItemId] = {
            ...cartItem
        };

        return memo;
    }, {});

    const orderParams = {
        id: order.id,
        number: order['order_number'],
        status: order.status,
        isAnonymous: order['is_anonymous'],
        waiterUserId: order['waiter_user_id'],
        waiterUser: order['waiterUser'],
        user: order['user'],
        items: [...order.items],
        comment: order.data['comment']
    };

    return {
        ...initialCartState,
        items,
        order: orderIds,
        comment: order.data['comment'],
        selectedTableId: order.data['table_id'],
        orderParams,
        placeId: order['place_id']
    };
};
