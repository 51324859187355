import React from 'react';

import MenuList, { MenuListType, byIdAndSortOrder, MenuListItem } from 'components/menu/MenuList/MenuList';
import { UnlistedCategory } from 'utils/api/menu';
import { MenuHiddenVisibility } from 'components/menu/MenuVisibility/MenuVisibility';
import './MenuCategories.scss';
import { SliderGroup, SwitchInput } from 'components/UI/InputGroup/InputGroup';
import { MenuCategoryBase } from '../MenuCategory/MenuCategory';

const MenuSelectableCategories = (props) => {
    const { categories, onSelectCategory = _.noop, selected = [], onSelectedChange = _.noop } = props;

    const categoriesList = categories.map((c) => (
        <MenuListItem className="menu-list-item-wrap" key={byIdAndSortOrder(c)}>
            <MenuCategoryBase className="menu-list-item" onSelectCategory={onSelectCategory.bind(null, c.id)} {...c}>
                {(c.is_hidden_forever || c.hidden_until) && <MenuHiddenVisibility />}
                {c.id !== UnlistedCategory.id && (
                    <div className="menu-list-item-selection" onClick={(e) => e.stopPropagation()}>
                        <SliderGroup>
                            <SwitchInput
                                value={selected.indexOf(c.id) > -1}
                                onChange={onSelectedChange.bind(null, c)}
                            />
                        </SliderGroup>
                    </div>
                )}
            </MenuCategoryBase>
        </MenuListItem>
    ));

    return (
        <MenuList {...props} type={MenuListType.Categories}>
            {categoriesList}
        </MenuList>
    );
};

export default MenuSelectableCategories;
