import { Model } from '@b2cmessenger/backbone';
import template from './place.jade';
import './place.scss';

function place(data) {
    return '<span class="widget-lite-place">' + template(data) + '</span>';
};

function place$(data) {
    return $('<span class="widget-lite-place"></span>').html(template(data))
};

const bindingHandler = {
    init($el) {
        $el.addClass('widget-lite-place');
    },
    set($el, placeOrPlaceModel) {
        let place = placeOrPlaceModel;
        if (placeOrPlaceModel instanceof Model) {
            place = placeOrPlaceModel.toJSON({ computed: true });
        }
        $el.html(template(place));
    }
};

export { place as default, place$, bindingHandler };
