import React, { useCallback } from 'react';
import ReactModalWindow from 'windows/ReactModalWindow';

import './MenuQrActions.scss';
import { WindowWrapper } from 'components/window/Window';
import { Button } from 'components/UI/Button/Button';

const MenuQrActions = (props) => {
    const { title = 'Choose an action', disabled, closeWindow = _.noop, placeId } = props;

    const callTheWaiter = useCallback(() => {
        app.controller.goToMenuPage({ placeId, showMenuWaiterCallWindow: true });
        closeWindow();
    }, []);
    const createOrderForThisTable = useCallback(() => {
        app.controller.goToMenuPage({ placeId });
        closeWindow();
    }, [placeId, closeWindow]);

    return (
        <WindowWrapper
            closeWindow={closeWindow}
            title={title}
            disabled={disabled}
            isModalWindow
            displayContinueButton={false}
        >
            <div className="button-links">
                <Button className="btn-big ion-md-person" onClick={callTheWaiter}>
                    Call the waiter
                </Button>
                <Button className="btn-big ion-md-cart" onClick={createOrderForThisTable}>
                    Create order
                </Button>
            </div>
        </WindowWrapper>
    );
};

const MenuQrActionsWindow = ReactModalWindow.extend({
    constructor: function MenuQrActionsWindow(props) {
        const options = {
            props,
            component: MenuQrActions,
            intent: ReactModalWindow.Intent.Active,
            name: 'MenuQrActions',
        };

        return ReactModalWindow.apply(this, [options]);
    },
});

export default MenuQrActionsWindow;
