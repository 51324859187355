import React from 'react';

import { WindowContent, WindowFooter } from 'components/window/Window';
import TextButton from 'components/UI/TextButton';

import './MenuItemRemoveConfirm.scss';
import MenuItems from 'components/menu/MenuItems/MenuItems';
import { UnlistedCategory } from 'utils/api/menu';
import { SliderGroup, SwitchInput } from 'components/UI/InputGroup/InputGroup';
import Footer from 'components/UI/Footer/Footer';

const MenuItemRemoveConfirmModal = ({ closeWindow, item, category }) => {
    const [fromCategory, setFromCategory] = React.useState(false);
    const captionText = ['Remove from category', category && `‘${category.name}‘`, 'only?'].filter(Boolean).join(' ');

    const infoText = fromCategory
        ? "Item will be removed from it's category only"
        : 'Item will be permanently removed from menu';

    return (
        <>
            <WindowContent>
                <h3>Do you really want to delete item?</h3>
                <MenuItems items={[item]} onSelectItem={_.noop} className="menu-list-compact menu-list-transparent" />
                {category && category.id !== UnlistedCategory.id && (
                    <SliderGroup caption={captionText} info={infoText}>
                        <SwitchInput onChange={setFromCategory} value={fromCategory} />
                    </SliderGroup>
                )}
            </WindowContent>
            <WindowFooter>
                <Footer>
                    <TextButton onClick={() => closeWindow()}>NO</TextButton>
                    <TextButton onClick={() => closeWindow({ fromCategory })}>YES</TextButton>
                </Footer>
            </WindowFooter>
        </>
    );
};

export default MenuItemRemoveConfirmModal;
