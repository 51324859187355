import React, { useCallback } from 'react';

import './QuantityChangeControl.scss';
import NumericInput from 'components/UI/NumericInput/NumericInput';

const QuantityChangeControl = (props) => {
    const { value = 0, disabled, onChange = _.noop, canBeChangedByInput = false, autosize } = props;
    const { isHiddenMinus, isHiddenCountInput, disabledMinus, disabledPlus } = props;

    const onMinusClickHandler = props.onMinusClickHandler || useCallback(() => onChange(value - 1), [value]);
    const onPlusClickHandler = props.onPlusClickHandler || useCallback(() => onChange(value + 1), [value]);

    return (
        <div
            className={['quantity-change-control', props.wideClickArea && 'wide-click-area'].filter(Boolean).join(' ')}
        >
            <button
                data-role="none"
                className={['control-button ion-ios-remove-circle-outline animated', isHiddenMinus && 'not-shown']
                    .filter(Boolean)
                    .join(' ')}
                disabled={disabled || disabledMinus}
                onClick={onMinusClickHandler}
            />
            <NumericInput
                autosize={autosize}
                className={[
                    'numeric-input',
                    (!canBeChangedByInput || disabled) && 'can-not-be-changed-by-input',
                    'animated',
                    isHiddenCountInput && 'not-shown',
                ]
                    .filter(Boolean)
                    .join(' ')}
                value={value}
                onChange={onChange}
                disabled={disabled || !canBeChangedByInput}
            />
            <button
                data-role="none"
                className="control-button ion-ios-add-circle-outline"
                disabled={disabled || disabledPlus}
                onClick={onPlusClickHandler}
            />
        </div>
    );
};

export default QuantityChangeControl;
