import AjaxError from './AjaxError';
const UPDATE_TIMEOUT = 24 * 60 * 60  * 1000;

function geocodeCurrentPosition() {
    const { lt, lg } = geo.getCurrentPosition();

    if (lt === null && lg === null) {
        return Promise.resolve(null);
    }

    return new Promise((resolve, reject) => {
        $.ajax({
            url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lt},${lg}&key=AIzaSyC20xBW9Q6_vzYVXCUc26WPBTvxp6tZFQA`,
            success: ({ results, status }) => {
                const hasCountryType = obj => obj.types.indexOf('country') !== -1;

                if (status.toLowerCase() == 'ok' && results && results.length) {
                    const result = results.find(hasCountryType);
                    const countryComponent = result && result.address_components &&
                        result.address_components.find(hasCountryType);

                    if (countryComponent) {
                        resolve(countryComponent.short_name.toLowerCase())
                    } else {
                        resolve(null)
                    }
                } else {
                    reject(new AjaxError({ status, results }));
                }
            },
            error: e => reject(new AjaxError(e))
        });
    });
}

function geocodeCurrentIpAddress() {
    return new Promise((resolve, reject) => {
       $.ajax({
           url: `https://api.ipgeolocation.io/ipgeo?apiKey=e9abfbfaea2243c287d40a08cc3d5a4e&fields=country_code2`,
           success: ({ ip, country_code2 }) => {
               const code = country_code2 && country_code2.toLowerCase();

               if (code) {
                   resolve(code);
               } else {
                   reject({ ip, country_code2 });
               }
           },
           error: e => new AjaxError(e),
       })
    });
}

function saveCountryCodeToLocalStorage(code) {
    localStorage.countryCode = JSON.stringify({ code, latestUpdateTime: Date.now() });
}

function getCountryCodeFromLocalStorage() {
    try {
        const countryCode = JSON.parse(localStorage.countryCode || '{}');

        if (countryCode && countryCode.code && (Date.now() - countryCode.latestUpdateTime) < UPDATE_TIMEOUT) {
            return countryCode.code
        }

        return null;
    } catch (e) {
        return null;
    }

}

export default function getUserCountryCode() {
    const storedCode = getCountryCodeFromLocalStorage();
    if (storedCode !== null) {
        return Promise.resolve(storedCode);
    }

    return geocodeCurrentIpAddress()
        .then(code => {
            saveCountryCodeToLocalStorage(code);
            return code;
        })
        .catch(e => {
            console.error(e);
            return null;
        });
}