import _ from 'underscore';
import { Marionette } from '@b2cmessenger/backbone';

import template from './PropertyItem.jade';
import './PropertyItem.scss';

let PropertyItem = Marionette.ItemView.extend({
    template: template,
    className: "widget property-item-widget",

    initialize() {
        this.listenTo(this.model, 'change', () => this.render());
    }
});

export default PropertyItem;
