import { LayoutView, ViewModel } from '@b2cmessenger/backbone';

import './Page.scss';

const Page = LayoutView.extend({
    isPage: true,

    _initAsPage() {
        if (!this.isRendered) {
            $("#pageContainer").append(this.el);
            this.render();
            this.triggerMethod('before:attach');
            this.triggerMethod('attach');

            this.$el.on("pageshow", () => this.onShow());
            this.$el.on("pagehide", () => this.onHide());
        }
    },

    show(options) {
        _.defaults(options || (options = {}), {
            preserveWindowStack: false,
        });

        this._initAsPage();

        if (!options.preserveWindowStack) {
            this.cleanPageScopedWindowStack();
        }

        app.controller._showPage(this, options);
    },

    cancel(options) {
        if (!this.viewModel.get('disabled')) {
            return app.controller._hidePage(this, options);
        } else {
            return true;
        }
    },

    displayWindows(windows, options) {
        _.defaults(options || (options = {}), {
            routeName: null,
            args: null
        });

        if (this.windowMap) {
            for (const w of windows) {
                const foundWindow = _.find(this.windowMap, wnd => wnd.cls.prototype.windowName == w.name);

                if (foundWindow) {
                    foundWindow.trigger();
                }
            }
        }
    },

    constructor: function(options) {
        _.extend(this, _.pick(Object.getPrototypeOf(this), ['pageName']), _.pick(options, ['pageName']));

        const ret = LayoutView.apply(this, arguments);

        if(!this.viewModel) {
            this.viewModel = new ViewModel;
        }

        this.on('disable', () => this.viewModel.set('disable', true));
        this.on('enable', () => this.viewModel.set('disable', false));

        return ret;
    },

    onShow() {
        this.isActive = true;
        this.$el.addClass('active');
        this.init();

        if (this.onAfterShow) try {
            this.onAfterShow();
        } catch (e) {
            _.defer(() => {
                app.controller._hidePage(this, {});
                throw e;
            });
        }
    },

    onHide() {
        this.isActive = false;

        if (this.onBeforeHide) try {
            this.onBeforeHide();
        } catch (e) {
            _.defer(() => { throw e });
        }

        this.$el.removeClass('active');
    },

    getPageScopedWindowStack() {
        return app.controller.windowStack.filter(w => w.$el.closest(this.$el).length);
    },

    cleanPageScopedWindowStack() {
        const windows = this.getPageScopedWindowStack();

        _.each(windows, w => {
            w.cancel(false);
        });

        return windows;
    },
}, {
    extendWithTraits(traits, protoProps, staticProps) {
        protoProps.attributes = _.extend({
            'data-role': "page"
        }, protoProps.attributes);

        protoProps.pageName = protoProps.pageName || (protoProps.attributes.id) || "unknown-page";

        protoProps.className = "page b2cpage" + (protoProps.className ? ' ' + protoProps.className : '');

        const init = protoProps.init;
        protoProps.init = function() {
            if(this.inited) return;

            if(_.isFunction(init))
                init.call(this);

            this.inited = true;
        }

        return LayoutView.extendWithTraits.call(this, traits, protoProps, staticProps);
    },

    extend(protoProps, staticProps) {
        return Page.extendWithTraits.call(this, null, protoProps, staticProps);
    },

    goBack(options) {
        return app.controller._goBackPage(options);
    }
});

export default Page;
