import React from 'react';
import PropTypes from 'prop-types';

import { WindowContent, WindowFooter } from 'components/window/Window';
import TextButton from 'components/UI/TextButton';
import { SliderGroup, SwitchInput } from 'components/UI/InputGroup/InputGroup';
import MenuCategories from 'components/menu/MenuCategories/MenuCategories';
import './MenuCategoryRemoveConfirm.scss';
import Footer from 'components/UI/Footer/Footer';

const MenuCategoryRemoveConfirmModal = (props) => {
    const { closeWindow, category, parentCategory } = props;
    const [moveToParent, setMoveToParent] = React.useState(false);

    return (
        <>
            <WindowContent>
                <h3>Do you really want to delete category?</h3>
                <MenuCategories categories={[category]} onSelectItem={_.noop} className="menu-list-compact" />
                {parentCategory !== null && (
                    <SliderGroup
                        caption="Move items to parent category"
                        info="Items of category will be moved into parent category"
                    >
                        <SwitchInput onChange={setMoveToParent} value={moveToParent} />
                    </SliderGroup>
                )}
            </WindowContent>
            <WindowFooter>
                <Footer>
                    <TextButton onClick={() => closeWindow()}>NO</TextButton>
                    <TextButton onClick={() => closeWindow({ moveToParent })}>YES</TextButton>
                </Footer>
            </WindowFooter>
        </>
    );
};
MenuCategoryRemoveConfirmModal.propTypes = {
    closeWindow: PropTypes.func,
    category: PropTypes.any,
    parentCategory: PropTypes.any,
};

export default MenuCategoryRemoveConfirmModal;
