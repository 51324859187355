var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (GiftModel, Type, transformationType, type) {var gift = type == Type.Coins ? 'coin' : 'gift';
var gifts = type == Type.Coins ? 'coin' : 'gifts';
switch (transformationType){
case 1:
pug_html = pug_html + "\u003Cp\u003EEnter \u003Cem\u003E%\u003C\u002Fem\u003E of the total receipt value to be converted into " + (pug.escape(null == (pug_interp = gifts) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003Cp\u003EMin: \u003Cem\u003E0.01%\u003C\u002Fem\u003E, max: \u003Cem\u003E" + (pug.escape(null == (pug_interp = GiftModel.MAX_RATIO_FOR_PERCENT / 100) ? "" : pug_interp)) + "%\u003C\u002Fem\u003E\u003C\u002Fp\u003E";
  break;
case 2:
pug_html = pug_html + "\u003Cp\u003EEnter the \u003Cem\u003Eprice of the " + (pug.escape(null == (pug_interp = gift) ? "" : pug_interp)) + " in $\u003C\u002Fem\u003E to calculate how many " + (pug.escape(null == (pug_interp = gifts) ? "" : pug_interp)) + " the client has earned for his or her purchase\u003C\u002Fp\u003E\u003Cp\u003EMin: \u003Cem\u003E0.01$\u003C\u002Fem\u003E, max: \u003Cem\u003E" + (pug.escape(null == (pug_interp = GiftModel.MAX_RATIO_FOR_PRICE / 100) ? "" : pug_interp)) + "$\u003C\u002Fem\u003E\u003C\u002Fp\u003E";
  break;
case 3:
pug_html = pug_html + "\u003Cp\u003EEnter \u003Cem\u003Emultiplier\u003C\u002Fem\u003E quotient to calculate " + (pug.escape(null == (pug_interp = gifts) ? "" : pug_interp)) + " your client will earn\u003C\u002Fp\u003E\u003Cp\u003EMin: \u003Cem\u003E0.01\u003C\u002Fem\u003E, max: \u003Cem\u003E" + (pug.escape(null == (pug_interp = GiftModel.MAX_RATIO_FOR_MULTIPLIER / 100) ? "" : pug_interp)) + "\u003C\u002Fem\u003E\u003C\u002Fp\u003E";
  break;
}}.call(this,"GiftModel" in locals_for_with?locals_for_with.GiftModel:typeof GiftModel!=="undefined"?GiftModel:undefined,"Type" in locals_for_with?locals_for_with.Type:typeof Type!=="undefined"?Type:undefined,"transformationType" in locals_for_with?locals_for_with.transformationType:typeof transformationType!=="undefined"?transformationType:undefined,"type" in locals_for_with?locals_for_with.type:typeof type!=="undefined"?type:undefined));;return pug_html;};
module.exports = template;