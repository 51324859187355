import React from 'react';
import PropTypes from 'prop-types';

import Price from 'components/UI/Price/Price';
import { MenuOrderStatus, OrderStatusMap } from 'components/menu/MenuOrderStatus/MenuOrderStatus';
import MenuTextItems, { getTextItemsFromOrderFormattedItems } from 'components/menu/MenuItems/MenuTextItems';
import './MenuOrder.scss';
import TextButton from 'components/UI/TextButton';
import { TextInputGroup } from 'components/UI/InputGroup/InputGroup';
import MenuWaiterCallContainer from 'components/menu/MenuWaiterCall/MenuWaiterCallContainer';

export const MenuUserOrderInfo = (props) => {
    const { currency, total_price, status, place } = props.order;
    const { logo, name } = place;

    return (
        <div className="order-info">
            <div className="photo" style={{ backgroundImage: `url(${logo})` }} />
            <div className="content">
                <div className="name">{name}</div>
                <Price value={total_price} currency={currency} />
                <MenuOrderStatus status={status} />
            </div>
        </div>
    );
};
MenuUserOrderInfo.propTypes = {
    currency: PropTypes.string,
    total_price: PropTypes.number,
    status: PropTypes.number,
    place: PropTypes.object,
};

export const MenuUserOrderActions = (props) => {
    const { showCancelButton, showCallWaiterButton, showBillButton, showEditButton, isWindow } = props;
    const onCancelClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.onCancelOrder) {
            props.onCancelOrder();
        }
    };

    const onEditClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.onEditOrder) {
            props.onEditOrder();
        }
    };

    const onCallWaiterClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.onCallWaiterClick) {
            props.onCallWaiterClick();
        }
    };

    const onBillClick = _.noop;

    return (
        <div className={['order-actions', props.className].filter(Boolean).join(' ')}>
            {showEditButton && (
                <TextButton className="wide border-filled" disabled={props.disabled} onClick={onEditClick}>
                    Edit
                </TextButton>
            )}
            {showCancelButton && (
                <TextButton className="wide border-filled" disabled={props.disabled} onClick={onCancelClick}>
                    {`Cancel${isWindow ? ' the order' : ''}`}
                </TextButton>
            )}
            {showCallWaiterButton && (
                <TextButton className="wide border-filled" disabled={props.disabled} onClick={onCallWaiterClick}>
                    Call the waiter
                </TextButton>
            )}
            {showBillButton && (
                <TextButton className="wide border-filled" disabled={props.disabled} onClick={onBillClick}>
                    Ask for a bill
                </TextButton>
            )}
        </div>
    );
};
MenuUserOrderActions.propTypes = {
    showCancelButton: PropTypes.bool,
    showCallWaiterButton: PropTypes.bool,
    showBillButton: PropTypes.bool,
    onCancelOrder: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
};

export const MenuOrderBase = (props) => {
    const { children, order } = props;

    return (
        <div className="menu-order-base">
            <MenuUserOrderInfo order={order} />
            {children}
        </div>
    );
};
MenuOrderBase.propTypes = {
    order: PropTypes.object,
};

export const MenuOrderComment = ({ comment }) => {
    return comment ? (
        <>
            <TextInputGroup className="input-group order-comment" icon="ion-ios-text-outline">
                <p>{comment}</p>
            </TextInputGroup>
        </>
    ) : null;
};

const MenuUserOrder = (props) => {
    const {
        order,
        displayAdditions,
        displayProperties,
        changeOrder,
        forceOpenWaiterCallForm = false,
        isWindow,
    } = props;
    const textItems = getTextItemsFromOrderFormattedItems(order, {
        includePosition: false,
        includePrice: true,
    });

    const isActiveOrder = [OrderStatusMap.New, OrderStatusMap.Confirmed].indexOf(order.status) > -1;

    const showEditButton = OrderStatusMap.New === order.status;
    const showCancelButton = isActiveOrder;
    const showCallWaiterButton = isWindow ? false : isActiveOrder;
    const showBillButton = false; // OrderStatusMap.Confirmed === order.status;

    const comment = order.data && order.data.comment;

    return (
        <div className="menu-order-content">
            <MenuOrderBase order={order}>
                <MenuTextItems
                    items={textItems}
                    displayAdditions={displayAdditions}
                    displayProperties={displayProperties}
                />
                <MenuOrderComment comment={comment} />
                <MenuUserOrderActions
                    disabled={props.disabled}
                    onEditOrder={props.onEditOrderClick}
                    onCancelOrder={props.onCancelOrder}
                    {...{
                        showEditButton,
                        showCancelButton,
                        showCallWaiterButton,
                        showBillButton,
                    }}
                    isWindow={isWindow}
                />
                {isActiveOrder && (
                    <hr />
                    //
                    // <>
                    //     <h3>Call the waiter</h3>
                    // </>
                )}
                <MenuWaiterCallContainer
                    isEmployee={false}
                    models={order.waiterRequests}
                    readOnly={!isActiveOrder}
                    order={order}
                    forceOpenForm={forceOpenWaiterCallForm}
                    changeOrder={changeOrder}
                    emptyListText="No waiter calls"
                />
            </MenuOrderBase>
        </div>
    );
};

MenuUserOrder.propTypes = {
    order: PropTypes.object,
    isWindow: PropTypes.bool,
};

export default MenuUserOrder;
