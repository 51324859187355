import { Collection, ViewModel, ItemView, LayoutView, CollectionView } from '@b2cmessenger/backbone';

import Window from 'windows/Window';
import HeaderView from 'widgets/Header/Header';
import FooterView from 'widgets/Footer/Footer';
import TaskTargetCollection from 'models/TaskTargetCollection';
import TaskTargetEditorWindow from 'windows/TaskTargetEditor/TaskTargetEditor';
import TaskTargetModel from 'models/TaskTargetModel';

import itemTemplate from './TaskTargetChooserItem.jade';
import chooserTemplate from './TaskTargetChooser.jade';
import './TaskTargetChooser.scss';

const TaskTargetChooserWindow = Window.extend({
    options: {
        placeId: undefined,
        refreshOnStart: true,
        selectId: undefined,
    },
    windowName: 'task-target-chooser-window',

    initialize() {

    },

    onAttach() {
        const chooser = new TaskTargetChooser({
            placeId: this.options.placeId,
        });

        this.listenTo(chooser, 'before:refresh', () => this.trigger('disable'));
        this.listenTo(chooser, 'after:refresh', () => this.trigger('enable'));

        this.content.show(chooser);

        const headerView = new HeaderView({
            leftButtons: ['back'],
            rightButtons: ['edit'],
            title: 'Choose task target'
        });
        headerView.listenTo(chooser, 'selected',
            selected => selected ? headerView.ui.btnedit.removeClass('hidden') : headerView.ui.btnedit.addClass('hidden'));
        this.listenTo(headerView, 'back:click', view => this.close(chooser.getSelected()));
        this.listenTo(headerView, 'edit:click', view => chooser.triggerMethod('edit:item'));
        this.header.show(headerView);

        if (chooser.getSelected()) {
            headerView.ui.btnedit.removeClass('hidden');
        } else {
            headerView.ui.btnedit.addClass('hidden');
        }

        const footerView = new FooterView({
            buttons: [{
                id: 'done',
                text: 'DONE',
            }, {
                id: 'cancel',
                text: 'CANCEL',
            }]
        });
        footerView.listenTo(chooser, 'selected',
            selected => footerView.ui.btndone.prop('disabled', !selected || this.viewModel.get('disabled')));
        footerView.listenTo(this.viewModel, 'change:disabled',
            m => footerView.ui.btndone.prop('disabled', !chooser.getSelected() || m.get('disabled')));
        this.listenTo(footerView, 'cancel:click', view => this.cancel());
        this.listenTo(footerView, 'done:click', view => {
            const selected = chooser.getSelected();
            if (selected) {
                this.close(selected);
            }
        });
        this.footer.show(footerView);
        footerView.ui.btndone.prop('disabled', !chooser.getSelected() || this.viewModel.get('disabled'));

        if (this.options.selectId == 'new') {
            if (this.options.refreshOnStart) {
                chooser.refresh();
            }
            chooser.openNew();
        } else {
            if (this.options.refreshOnStart) {
                if (this.options.selectId) {
                    this.listenToOnce(chooser, 'after:refresh', () => chooser.setSelected(this.options.selectId));
                }
                chooser.refresh();
            } else if (this.options.selectId) {
                chooser.setSelected(this.options.selectId);
            }
        }
    }
});

const TaskTargetItem = ItemView.extend({
    options: {
        selectable: false,
        editable: false,
        selection: undefined,
    },
    template: itemTemplate,
    className: 'widget task-target-chooser-item-widget',

    ui: {
        updatedAt: '[data-js-updated]',
        title: '[data-js-title]',
        broadcastSettings: '[data-js-broadcast-settings]',
        targetSettings: '[data-js-target-settings]',
        editBtn: '[data-js-edit]'
    },

    computeds: {
        'c_targetSettings': {
            deps: [
                'age_min', 'age_max', 'gender', 'days_before_birthday', 'distance',
                'client_knowledge', 'interest_categories', 'interest_strength'
            ],
            get: (age_min, age_max, gender, days_before_birthday, distance,
                client_knowledge, interest_categories, interest_strength) =>
            {
                const settings = new Array;
                if (age_min && age_max) settings.push(`age: ${age_min}-${age_max}`);
                else if (age_min) settings.push(`age > ${age_min}`);
                else if (age_max) settings.push(`age < ${age_max}`);

                if (gender && gender.length) {
                    const genders = _.intersection(gender, ['male', 'female', 'other']);
                    if (genders.length == 1) {
                        settings.push(`only ${genders[0]}s`);
                    } else if (genders.length == 2) {
                        settings.push(`only ${genders[0]}s and ${genders[1]}s`);
                    }
                }

                if (!_.isNull(days_before_birthday)) {
                    const days = Number(days_before_birthday);
                    if (days > 0) {
                        settings.push(`${days} day${days == 1 ? '' : 's'} before birthday`);
                    } else {
                        settings.push(`birthday is today`);
                    }    
                }

                if (!_.isNull(distance)) {
                    settings.push(`within ${distance} mile${distance == 1 ? '' : 's'}`)
                }

                if (client_knowledge) {
                    settings.push(`clients aware of brand`);
                }

                if (interest_categories && interest_categories.length) {
                    let str;
                    if (interest_categories.length == 1) {
                        const cat = window.categoryCollection.get(interest_categories[0]);
                        if (cat) {
                            str = cat.get('name');
                        } else {
                            str = '1 category';
                        }
                    } else if (interest_categories.length == 2) { 
                        const cat0 = window.categoryCollection.get(interest_categories[0]),
                              cat1 = window.categoryCollection.get(interest_categories[1]);
                        
                        if (cat0 && cat1) {
                            str = cat0.get('name') + ' and ' + cat1.get('name');
                        } else {
                            str = '2 categories';
                        }
                    } else { 
                        str = interest_categories.length + ' categories';
                    }

                    if (interest_strength == 1 || interest_strength == 2) {
                        settings.push(`took interest in ${str} over a ${interest_strength == 1 ? 'week' : 'month'}`);
                    } else {
                        settings.push(`interested in ${str}`);
                    } 
                }

                return settings.join(', ');
            }
        },
        'c_broadcastSettings': {
            deps: ['client_limit', 'repeat', 'dateStart', 'dateEnd', 'sendHour', 'sendMinute'],
            get: (client_limit, repeat, dateStart, dateEnd, sendHour, sendMinute) => {
                const settings = new Array;

                if (client_limit) {
                    settings.push(`limit: ${client_limit}`);
                }

                if (!repeat || repeat == TaskTargetModel.RepeatPeriods.Once) {
                    if (dateStart && !_.isNull(sendHour)) {
                        const date = new Date(dateStart);
                        date.setHours(sendHour, sendMinute || 0, 0, 0);
                        settings.push(`once at ${date.toLocaleString(undefined, { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}`);
                    } else if (dateStart) {
                        settings.push(`once at ${dateStart.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}`);
                    } else if (!_.isNull(sendHour)) {
                        const date = new Date;
                        date.setHours(sendHour, sendMinute || 0, 0, 0);
                        settings.push(`once at ${date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })}`);
                    }
                } else {
                    const repeatStr =
                        repeat == TaskTargetModel.RepeatPeriods.Day ?   'every day' :
                        repeat == TaskTargetModel.RepeatPeriods.Week ?  'every week' :
                        repeat == TaskTargetModel.RepeatPeriods.Month ? 'every month' :
                                                                        'every year';         
                
                    let hourStr = '';
                    if (!_.isNull(sendHour)) {
                        const date = new Date;
                        date.setHours(sendHour, sendMinute || 0, 0, 0);
                        hourStr = ` at ${date.toLocaleTimeString(undefined, { hour: 'numeric' })}`;
                    }

                    let periodStr = ''
                    if (dateStart && dateEnd) {
                        periodStr = ` from ${dateStart.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })} until ${dateEnd.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}`;
                    } else if (dateStart) {
                        periodStr = ` from ${dateStart.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}`;
                    } else if (dateEnd) {
                        periodStr = ` until ${dateEnd.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}`;
                    }

                    settings.push(repeatStr + hourStr + periodStr);
                }

                return settings.join(', ');
            }
        }
    },

    bindingFilters: {
        date: date => _.isDate(date) && date.toLocaleString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        }) || "",
    },

    bindings: {
        ':el': 'classes:{selectable:selectable,selected:selected}',
        '@ui.updatedAt': 'text:date(updatedAt)',
        '@ui.title': 'text:title',
        '@ui.broadcastSettings': 'text:c_broadcastSettings',
        '@ui.targetSettings': 'text:c_targetSettings',
        '@ui.editBtn': 'classes:{hidden:not(editable)}'
    },

    events: {
        'click'(e) {
            this.triggerMethod('select');
        },
        'click @ui.editBtn'(e) {
            this.triggerMethod('edit');
        },
    },

    initialize() {
        this.viewModel = new ViewModel;

        this.viewModel.addComputed('selection', {
            deps: ['_selection'],
            get: _selection => _selection,
            set(val) {
                const selection = this.get('selection');
                if (selection) {
                    this.stopListening(selection);
                }

                if (val) {
                    this.listenTo(val, 'update reset', selection =>
                        this.trigger('change:selection change', this, selection, {}));
                }

                return _.create(null, { _selection: val });
            }
        });

        this.viewModel.addComputed('selected', {
            deps: ['model', 'selection', 'selectable'],
            get: (model, selection, selectable) => !!selectable && !!selection && selection.contains(model)
        });

        this.viewModel.set({
            selectable: this.options.selectable,
            editable: this.options.editable,
            selection: this.options.selection,
            model: this.model
        });
    },

    onEdit() {
        new TaskTargetEditorWindow({
            model: this.model.clone(),
            returnNullIfModelHasNotBeenSaved: true,
            validateAtStart: true
        })
            .show()
            .then(model => {
                if (model) {
                    this.model.set(model.attributes);
                }
            });
    }
});

const TaskTargetCollectionView = CollectionView.extend({
    options: {
        selectable: false,
        editable: false,
        selection: undefined,
    },
    className: 'widget task-target-collection-view',
    childView: TaskTargetItem,
    childViewOptions() {
        return {
            selectable: !!this.viewModel.get('selectable'),
            editable: !!this.viewModel.get('editable'),
            selection: this.viewModel.get('selection')
        };
    },

    bindings: {
        ':el': 'classes:{selectable:selectable}'
    },

    initialize() {
        if(!this.collection) {
            this.collection = new TaskTargetCollection(null, { placeId: this.options.placeId });
        }

        this.viewModel = new ViewModel({
            selectable: this.options.selectable,
            editable: this.options.editable,
            selection: this.options.selection
        });

        this.listenTo(this.viewModel, 'change:selectable change:editable change:selection',
            () => this.children.each(v => v.viewModel.set(this.childViewOptions())));
    },

    onRefresh() {
        this.collection.fetch();
    }
});

const TaskTargetChooser = LayoutView.extend({
    options: {
        placeId: undefined,
    },

    openNew() {
        this.triggerMethod('add:new');
    },

    setSelected(modelOrId) {
        const m = modelOrId && (modelOrId.id ? modelOrId : this.collection.find(m => m.id == modelOrId));
        if (m) {
            this.viewModel.set({ selected: m });
        }
    },

    getSelected() {
        return this.viewModel.get('selected');
    },

    refresh(options) {
        _.defaults(options || (options = {}));

        this.triggerMethod('before:refresh');

        this.collection.fetch({
            success: (collection, response) => {
                this.triggerMethod('refresh');
                if (options.success) {
                    options.success.call(options.context, this, response, options);
                }    
                this.triggerMethod('after:refresh');
            },
            error: (collection, response) => {
                this.showError(response);

                if (options.error) {
                    options.error.call(options.context, this, response, options);
                }

                this.triggerMethod('after:refresh');
            }
        });
    },

    viewModelEvents: {
        'change:selected'(m, selected) { this.triggerMethod('selected', selected) }
    },

    template: chooserTemplate,
    className: 'widget task-target-chooser-widget',

    regions: {
        free: '[data-js-free-items]',
        locked: '[data-js-locked-items]'
    },

    ui: {
        newBtn: '[data-js-btn-new]',
        free: '[data-js-free-items]',
        locked: '[data-js-locked-items]'
    },

    events: {
        'click @ui.newBtn'(e) {
            this.triggerMethod('add:new');
        }
    },

    initialize() {
        if(!this.collection) {
            this.collection = new TaskTargetCollection(null, { placeId: this.options.placeId });
        }
        
        if (!this.viewModel) {
            this.viewModel = new ViewModel;
        }

        this.viewModel.addComputed('selection', {
            deps: ['collection', '_selection'],
            get: (collection, _selection) => {
                if (_selection && _selection.find(m => !collection.contains(m))) {
                    _selection.remove(_selection.filter(m => !collection.contains(m)));
                }
                return _selection;
            },
            set(val) {
                const selection = this.get('selection');
                if (selection) {
                    this.stopListening(selection);
                }

                if (val) {
                    this.listenTo(val, 'update reset', selection =>
                        this.trigger('change:_selection change', this.viewModel, selection, {}));
                }

                return _.create(null, { _selection: val });
            }
        });

        this.viewModel.addComputed('selected', {
            deps: ['_selection'],
            get: _selection => _selection && _selection.first(),
            set(val) {
                const selection = this.get('selection'),
                      collection = this.get('collection');
                if (val && collection && collection.find(m => m == val)) {
                    selection.reset([val]);
                } else {
                    selection.reset([]);
                }    
                return {};
            }
        });

        this.viewModel.set({
            selection: new Collection,
            collection: this.collection
        });
    },

    onAttach() {
        const freeItems = new TaskTargetCollectionView({
            collection: this.collection,
            filter: model => !model.get('task_id'),
            selectable: true,
            selection: this.viewModel.get('selection'),
            editable: true
        });
        this.listenTo(freeItems, 'childview:select', cw => this.viewModel.set({ selected: cw.model }));

        this.free.show(freeItems);

        const lockedItems = new TaskTargetCollectionView({
            collection: this.collection,
            filter: model => !!model.get('task_id'),
            editable: true
        });
        this.listenTo(lockedItems, 'childview:select', cw => { 
            new TaskTargetEditorWindow({
                model: cw.model.clone({ onlySettings: true }),
                validateAtStart: true,
            })
                .show()
                .then(model => {
                    if (model && model.id) {
                        this.collection.add(model);
                        this.viewModel.set({ selected: model })
                        this.refresh();
                    }
                });
        });

        this.locked.show(lockedItems);
    },

    onAddNew() {
        new TaskTargetEditorWindow({ placeId: this.options.placeId })
            .show()
            .then(model => {
                if (model) {
                    this.collection.add(model);
                    this.viewModel.get('selection').reset([model]);
                    this.refresh();
                }
            });
    },

    onEditItem(selected) {
        if (this.free.currentView) {
            if (!selected) {
                selected = this.viewModel.get('selected');
            }
            if (selected) {
                const item = this.free.currentView.children.findByModel(selected);
                item && item.triggerMethod('edit');
            }    
        }
    }
});

export default TaskTargetChooserWindow;
