const backgroundImage = {
    set($element, url) {
        if (url) {
            $element.css({
                "background-image": `url(${url})`
            });
        } else {
            $element.css({
                "background-image": ''
            });
        }
    }
};

export default backgroundImage;

