import { Required, Optional } from '@b2cmessenger/backbone';

import ModalWindow from 'windows/Modal/Modal';
import ReservationDecline from './ReservationDecline';
import ReservationModel from 'models/ReservationModel';

// @ts-ignore
const options = ModalWindow.options;

@options({
    model: Required,
    isAuthor: Required,
    isReservationManager: Required,
    message: Optional,
    parentViewModel: Optional,
})
class ReservationDeclineModalWindow extends ModalWindow {
    constructor(options) {
        const status = options.model.get('status');
        let reservationCancelText = 'Cancel';

        if ((options.isAuthor && ReservationModel.Status.ClientSuggestedChanges == status)
            || (options.isReservationManager && ReservationModel.Status.BusinessSuggestedChanges == status)
        ) {
            reservationCancelText = 'Decline';
        }

        const declineView = new ReservationDecline({
            model: options.model,
            isReservationManager: options.isReservationManager,
            message: options.message,
            reservationCancelText
        });

        super({
            parentViewModel: options.parentViewModel,
            content: declineView,
            intent: ModalWindow.Intent.Negative,
            buttons: [
                {
                    id: 'back',
                    text: 'BACK',
                    icn: 'empty'
                }, {
                    id: 'decline',
                    text: 'PROCEED',
                    icn: 'empty',
                    onClick(e) {
                        this.viewModel.set({ disabled: true });
                        declineView.decline()
                            .then(model => {
                                this.close(model);
                                app.controller.trigger('reservation:change', model.id, {
                                    status: model.get('status')
                                });
                            })
                            .catch(e => {
                                if (e !== 'message too short') {
                                    this.showError(e);
                                }
                                this.viewModel.set({ disabled: false });
                            })
                    }
                }
            ]
        });

        this.listenTo(declineView, 'edit', () => this.close('edit'));
    }
}

export default ReservationDeclineModalWindow;
