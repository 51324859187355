import React from 'react';

import './MenuCartEmpty.scss';
import TextButton from 'components/UI/TextButton';

const MenuCartEmpty = ({ goToMainMenuPage = _.noop }) => {
    return (
        <div className="menu-cart-empty">
            <i className="ion-ios-information-circle-outline" />
            <p>Your cart is empty!</p>
            <TextButton className="medium normalized border-filled with-top-margin" onClick={goToMainMenuPage}>
                Go to catalog
            </TextButton>
        </div>
    );
};

export default MenuCartEmpty;
