import { Required, Optional, ViewModel, ItemView } from '@b2cmessenger/backbone';

import template from './LoadMore.jade';
import './LoadMore.scss';

/**@type {typeof import('./LoadMore').properties} */
// @ts-ignore
const properties = ItemView.properties;

/**@type {typeof import('./LoadMore').options} */
// @ts-ignore
const options = ItemView.options;

/**
* User clicked on newest button
* @event LoadMoreView#click:newest
*/
/**
* User clicked on oldest button
* @event LoadMoreView#click:oldest
*/
/**
* User clicked on more button
* @event LoadMoreView#click:more
*/

@options({
    model: Required,
    parentViewModel: Optional
})
@properties({
    className: 'widget message-comments-load-more-widget',
    template,

    ui: {
        newest: '[data-js-btn-newest]',
        oldest: '[data-js-btn-oldest]',
        more: '[data-js-btn-more]',
        count: '[data-js-count]'
    },

    bindings: {
        ':el': 'classes:{hidden:isHidden}',
        '@ui.newest': 'disabled:disabled',
        '@ui.oldest': 'disabled:disabled',
        '@ui.more': 'disabled:disabled',
    },

    triggers: {
        'click @ui.newest': 'click:newest',
        'click @ui.oldest': 'click:oldest',
        'click @ui.more': 'click:more'
    },
})
class LoadMoreView extends ItemView {
    show(show = true) {
        this.viewModel.set({ isHidden: !show });
    }

    hide() {
        this.show(false);
    }

    initialize() {
        /**@type {import('./LoadMore')} */
        // @ts-ignore
        const self = this;
        this.viewModel = new ViewModel({
            isHidden: false,
            parentViewModel: self.options.parentViewModel
        });
    }
};

export default LoadMoreView;
