import { Model } from '@b2cmessenger/backbone';
import template from './user.jade';
import './user.scss';

function user(data) {
    return '<span class="widget-lite-user">' + template(data) + '</span>';
};

function user$(data) {
    return $('<span class="widget-lite-user"></span>').html(template(data))
};

const bindingHandler = {
    init($el) {
        $el.addClass('widget-lite-user');
    },
    set($el, userOrUserModel) {
        let user = userOrUserModel;
        if (userOrUserModel instanceof Model) {
            user = userOrUserModel.toJSON({ computed: true });
        }
        $el.html(template(user));
    }
};

export { user as default, user$, bindingHandler };
