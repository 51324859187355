import React from 'react';
import InputGroup, { TextInputGroup } from 'components/UI/InputGroup/InputGroup';
import MenuItemsWithControl from 'components/menu/MenuItems/MenuItemsWithControl';
import Price from 'components/UI/Price/Price';
import { getAdditionItemPrice } from 'store/menu/utils';
import './MenuCartItemInfo.scss';

const MenuCartItemInfo = (props) => {
    const { onClick = _.noop } = props;

    const properties = React.useMemo(() => {
        if (_.isArray(props.properties) && props.properties.length > 0) {
            return props.properties.map(({ name, value }) => {
                return (
                    <TextInputGroup
                        className="input-group menu-cart-item-info-properties"
                        key={name}
                        label={`${name}:`}
                        info={value}
                    />
                );
            });
        }

        return null;
    }, [props.properties]);

    const additions = React.useMemo(() => {
        if (_.isArray(props.additions) && props.additions.length > 0) {
            return (
                <>
                    <TextInputGroup label="Additions:" />
                    <MenuItemsWithControl
                        className="menu-list-cart-item-additions menu-list-compact"
                        items={props.additions}
                        additions
                        onChangeCount={props.onChangeAddition.bind(null, props.uniqueId)}
                    />
                </>
            );
        }

        return null;
    }, [props.uniqueId, props.additions, props.onChangeAddition]);

    const comment = React.useMemo(() => {
        if (props.comment) {
            return (
                <TextInputGroup
                    className="input-group menu-cart-item-info-comment"
                    label="Comment:"
                    info={props.comment}
                />
            );
        }

        return null;
    }, [props.comment]);

    const price = React.useMemo(() => {
        const className = [
            'input-group',
            'menu-cart-item-info-price',
            props.changes && props.changes.isPriceChanged
                ? props.changes.price > props.changes.oldPrice
                    ? 'increase'
                    : 'decrease'
                : null,
        ]
            .filter(Boolean)
            .join(' ');

        if (props.count > 0 && Boolean(props.price && props.currency)) {
            const priceValue =
                (props.price + props.additions.reduce((a, b) => a + getAdditionItemPrice(b) * b.count, 0)) *
                props.count;

            return (
                <InputGroup label="Total price:" className={className}>
                    <Price value={priceValue} currency={props.currency} />
                </InputGroup>
            );
        }

        return null;
    }, [props.price, props.currency, props.additions, props.count, props.changes]);

    const changes = React.useMemo(() => {
        const label = 'Changes:';
        const { error = null, info = null } = props.changes || {};

        if (error === null && info === null) {
            return null;
        }

        return (
            <TextInputGroup
                className="input-group menu-cart-item-info-changes"
                label={label}
                error={error}
                info={info}
            />
        );
    }, [props.changes, props.maximum, props.additions]);

    return (
        <div data-role="none" className="menu-cart-item-info" onClick={onClick}>
            {properties}
            {additions}
            {comment}
            {changes}
            {price}
        </div>
    );
};

export default MenuCartItemInfo;
