import { ItemView, CollectionView, LayoutView, Collection, Model } from '@b2cmessenger/backbone';
import Window from 'windows/Window';

import ConfirmModal from 'windows/Modal/Confirm';
import PhotoModel from 'models/PhotoModel';
import PhotoWidget from 'widgets/Photo/Photo';
import LoyaltyCardEditorWindow from 'windows/LoyaltyCardEditor/LoyaltyCardEditor';
import { bindingHandler as barcodeBindingHandler } from 'widgets/lite/barcode/barcode';

import template from './LoyaltyCard.jade';
import addTemplate from './AddNewLoyaltyCard.jade';
import './LoyaltyCard.scss';
import qrCodeGenerator from 'utils/qrCodeGenerator';
import QrCodeModel from 'models/QrCodeModel';

const AddNewLoyaltyCardView = LayoutView.extend({
    template: addTemplate,
    tagName: "div",
    className: "B2CLoyaltyCard add-new-loyalty-card",
    events: {
        'click button[data-js-btn-add]'(){
            if (this.brand == null) {
                if (this.options.brand && !this.brand) {
                    this.brand = this.options.brand;
                }
            }

            new LoyaltyCardEditorWindow({
                brand: this.brand,
                userId: this.options.userId,
                isBusinessView: this.options.isBusinessView
            })
                .show()
                .then(card => {
                    if (card) {
                        this.model && this.model.collection
                            && this.model.collection.add(card.toJSON({ computed: true }));
                    }
                })
        }
    }
});

export const LoyaltyCardView = LayoutView.extend({
    setValidateUser(u) {
        this.viewModel.set({ validateUser: u || null });
        this.options.validateUser = u || null;
    },

    template: template,
    tagName: "div",
    className: "B2CLoyaltyCard",

    ui: {
        validation: '[data-js-validation]',
        validationPanel: '[data-js-validation-panel]',
        validationCommentInput: '[data-js-validation-comment-input]',
        validationCommentError: '[data-js-validation-comment-error]',
        btnAccept: '[data-js-btn-accept]',
        btnDecline: '[data-js-btn-decline]',
        btnChange: '[data-js-btn-change]',
        btnEdit: '[data-js-btn-edit]',
        photo: '[data-js-photo]',
        photo2: '[data-js-photo2]',
        barcode: '[data-js-barcode]',
        qr: '[data-js-qr]',
        photoWrapper: '[data-js-photo-wrapper]',
        photo2Wrapper: '[data-js-photo2-wrapper]',
    },

    regions: {
        photo: '[data-js-photo]',
        photo2: '[data-js-photo2]',
    },

    events: {
        'click .btn_more': 'onMoreClick',
        'click .btn_delete': 'onDeleteClick',
        'click .btn_edit': 'onEditClick',
        'click @ui.btnAccept': 'onAcceptDecline',
        'click @ui.btnDecline': 'onAcceptDecline',
        'click @ui.btnChange': 'onChangeClick',
        'change @ui.validationCommentInput': 'onValidationCommentInputChange',
        'click @ui.photo': 'onPhotoClicked',
        'click @ui.photo2': 'onPhoto2Clicked',
        'click @ui.barcode': 'onBarcodeClicked',
        'click @ui.qr': 'onQrClicked'
    },

    computeds: {
        isAccepted: {
            deps: ['status'],
            get: status => status == B2CLoyaltyCard.const.status.approved,
        },
        isDeclined: {
            deps: ['status'],
            get: status => status == B2CLoyaltyCard.const.status.discard,
        },
        isValidated: {
            deps: ['status'],
            get: status => status == B2CLoyaltyCard.const.status.discard || status == B2CLoyaltyCard.const.status.approved,
        }
    },

    bindings: {
        '@ui.barcode': 'barcodeBindingHandler:number',
        '@ui.qr': 'attr:{src:qrDataUrl}',
        '@ui.validation': 'classes:{hidden:any(not(validateUser),not(isValidated))}',
        '@ui.validationPanel': 'classes:{hidden:not(isBusinessView)}',
        '@ui.validation .comment': 'text:comment, classes:{hidden:not(commentExists(comment))}',
        '@ui.validation .name': 'text:fullName(validateUser)',
        '@ui.validation .status': 'text:statusTitle(status)',
        '@ui.validation .date-validated': 'text:formattedValidatedAt(validatedAt)',
        '@ui.validationPanel button.approved': 'classes:{set:isAccepted}',
        '@ui.validationPanel button.discard': 'classes:{set:isDeclined}',
        '@ui.btnAccept': 'classes:{set:isAccepted},disabled:isDisabled',
        '@ui.btnDecline': 'classes:{set:isDeclined},disabled:isDisabled',
        '@ui.btnChange': 'classes:{hidden:not(isBusinessView)}',
        '@ui.validationCommentError': 'text:error'
    },

    bindingFilters: {
        commentExists: comment => !(comment == null || comment == ''),
        fullName: validateUser => {
            let fullName = '';
            try {
                fullName = validateUser.firstname + (
                    validateUser.lastname ? ' ' + validateUser.lastname : ''
                );
            } catch (e) {}

            return fullName;
        },
        statusTitle: status => status == B2CLoyaltyCard.const.status.approved
            ? 'Approved by '
            : 'Declined by ',
        formattedValidatedAt: validatedAt => validatedAt
            ? B2Cjs.datetimeServerToString(validatedAt, {timeFirst: true, needSeconds: false})
            : ''
    },

    bindingHandlers: {
        barcodeBindingHandler
    },

    initialize() {
        this.viewModel = new Model({
            isDisabled: false,
            status: this.model.get('status'),
            isBusinessView: this.options.isBusinessView || false,
            validateUser: this.options.validateUser || null,
            validatedAt: this.model.get('validated_at'),
            comment: this.model.get('comment'),
            qrDataUrl: null,
            error: "",
        });

        this.viewModel.listenTo(this.model, 'change:status', (m, status) => {
            this.viewModel.set({status: status});
        });
        this.viewModel.listenTo(this.model, 'change:validated_at', (m, validated_at) => {
            this.viewModel.set({validatedAt: validated_at});
        });
        this.viewModel.listenTo(this.model, 'change:comment', (m, comment) => {
            this.viewModel.set({comment: comment});
        });

        const updateQrDataUrl = (m, number) => {
            if (_.isUndefined(m)) {
                number = this.model.get('number');
            }

            this.viewModel.set({ qrDataUrl: '' });

            number && qrCodeGenerator(number)
                .then(url => this.viewModel.set({ qrDataUrl: url }));
        };

        this.viewModel.listenTo(this.model, 'change:number', updateQrDataUrl);
        updateQrDataUrl();
    },

    onRender() {
        let photoUrl = this.model.get('photo_local') || this.model.get('photo') || this.model.get('thumb');
        if (photoUrl) {
            let photoWidget = new PhotoWidget({
                model: new PhotoModel({ photo: photoUrl }),
                maxZoom: 1,
                minZoom: 1,
            });
            this.photo.show(photoWidget);

            this.photo.listenTo(photoWidget, 'gesture:expand', this.onPhotoClicked.bind(this));
        } else {
            this.ui.photoWrapper.addClass('empty');
        }

        let photoUrl2 = this.model.get('photo2_local') || this.model.get('photo2') || this.model.get('thumb2');
        if (photoUrl2) {
            let photoWidget = new PhotoWidget({
                model: new PhotoModel({ photo: photoUrl2 }),
                maxZoom: 1,
                minZoom: 1,
            });
            this.photo2.show(photoWidget);

            this.photo2.listenTo(photoWidget, 'gesture:expand', this.onPhoto2Clicked.bind(this));
        } else {
            this.ui.photo2Wrapper.addClass('empty');
        }
    },
    
    onPhotoClicked() {
        if(!this.viewModel.get('isBusinessView')) {
            let photoUrl = this.model.get('photo_local') || this.model.get('photo') || this.model.get('thumb');
            if(photoUrl) {
                new FullscreenPhotoWindow({ 
                    model: new PhotoModel({ photo: photoUrl }),
                    additionalClass: 'photo'
                }).show();
            }
        }
    },

    onPhoto2Clicked() {
        if(!this.viewModel.get('isBusinessView')) {
            let photoUrl = this.model.get('photo2_local') || this.model.get('photo2') || this.model.get('thumb2');
            if(photoUrl) {
                new FullscreenPhotoWindow({ 
                    model: new PhotoModel({ photo: photoUrl }),
                    additionalClass: 'photo'
                }).show();
            }
        }
    },

    onBarcodeClicked() {
        if (!this.viewModel.get('isBusinessView')) {
            if (this.model.get('number')) {
                new FullscreenBarcodeWindow({ 
                    number: this.model.get('number'),
                    additionalClass: 'barcode'
                }).show();
            }
        }
    },

    onQrClicked() {
        if (!this.viewModel.get('isBusinessView')) {
            const qrDataUrl = this.viewModel.get('qrDataUrl');

            if (qrDataUrl) {
                new FullscreenPhotoWindow({
                    model: new PhotoModel({ photo: qrDataUrl }),
                    additionalClass: 'qr'
                }).show();
            }
        }
    },

    onValidationCommentInputChange() {
        this.viewModel.set('error', '');
    },

    onAcceptDecline(e) {
        var $targetEl = $(e.target),
            comment = this.$el.find('textarea.comment').val(),
            statusId = $targetEl.data('val'),
            isApproved = statusId == B2CLoyaltyCard.const.status.approved,
            isDeclined = statusId == B2CLoyaltyCard.const.status.discard;

        if (isDeclined && comment == '') {
            this.viewModel.set('error', 'Please, add comment');
            return;
        }

        this.viewModel.set('isDisabled', true);

        B2CLoyaltyCard.server_api_businessvalidate(
            this.model.id,
            statusId,
            comment,
            data => {
                this.viewModel.set({
                    isDisabled: false,
                    validateUser: LoginedUserHandler.getLoginedUser()
                });

                this.model.set({
                    comment: comment,
                    validated_at: B2Cjs.datetimeJSToServer(new Date),
                    status: statusId
                });

                this.trigger('validate');
            },
            (jqXHR, textStatus, errorThrown) =>  {
                this.viewModel.set('isDisabled', false);
                this.showError(jqXHR, textStatus, errorThrown);
            }
        );
    },

    onChangeClick(e) {
        ;
    },

    onMoreClick() {
        this.$el.find('.more_cont').toggleClass('hidden');
    },

    onDeleteClick() {
        new ConfirmModal({ message: "Do you really want to delete this card?" })
            .show()
            .then(confirm => confirm && this.deleteCard());
    },

    onEditClick() {
        if (this.brand == null) {
            var loyaltyCardBase = LoyaltyCardBase;
            this.brand = loyaltyCardBase.getBrands_by_ids([this.model.get('brand_id')])[0];

            if (this.options.brand && !this.brand) {
                this.brand = this.options.brand;
            }
        }

        new LoyaltyCardEditorWindow({
            brand: this.brand,
            card: this.model.toJSON({ computed: true }),
            isBusinessView: this.viewModel.get('isBusinessView')
        })
            .show()
            .then(card => {
                if (card) {
                    this.model.clear();
                    this.model.set(card.toJSON({ computed: true }));
                    this.render();
                }
            })
    },

    deleteCard() {
        this.viewModel.set('isDisabled', true);
        const isBusinessView = this.viewModel.get('isBusinessView');
        const deleteFn = isBusinessView ?
            B2CLoyaltyCard.server_api_business_delete :
            B2CLoyaltyCard.server_api_delete;

        deleteFn(
            this.model.id,
            () => {
                this.viewModel.set('isDisabled', false);
                if (!isBusinessView) {
                    var loyaltyCardBase = LoyaltyCardBase;
                    loyaltyCardBase.delete_card_from_client(this.model.id);
                }
                this.destroy();
                this.trigger('delete');
            },
            (jqXHR, textStatus, errorThrown) => {
                this.viewModel.set('isDisabled', false);
                this.showError(jqXHR, textStatus, errorThrown);
            }
        );
    },

    serializeData() {
        let data = this.model.attributes;

        data.isOwner = data.user_id == LoginedUserHandler.getLoginedUser().id;
        data.formattedNumber = B2CLoyaltyCard.format_card_to_user_view(data.number);
        data.formatredCreatedAt = B2Cjs.datetimeServerToString(data.created_at, {timeFirst : false, needSeconds : false});

        data.isBusinessView = this.viewModel.get('isBusinessView');

        data.commentMaxLength = B2CEvidence.const.comment_maxlength;
        data.approvedAlias = B2CLoyaltyCard.const.status.approved;
        data.declinedAlias = B2CLoyaltyCard.const.status.discard;

        return data;
    }
});

const FullscreenPhotoWindow = Window.extend({
    className: 'fullscreen-photo-window',
    events: {
        'dblclick'() {
            this.close();
        }
    },

    onRender() {
        if (this.options.additionalClass)
            this.el.classList.add(this.options.additionalClass);

        let photoWidget = new PhotoWidget({
            model: this.model,
            maxZoom: 1,
            minZoom: 1,
        });

        let photoArChanged = () => {
            if (this.model.get('photoAR') > 1.1) {
                this.content.el.classList.add('rotated');
                photoWidget.setRotation(90);
            } else {
                this.content.el.classList.remove('rotated');
                photoWidget.setRotation(0);
            }
        };

        photoWidget.listenTo(this.model, 'change:photoAR', photoArChanged);

        this.content.show(photoWidget);

        this.listenTo(photoWidget, 'gesture:collapse gesture:swipe gesture:swipe:long gesture:vertical:swipe ' +
            'gesture:vertical:swipe:long', () => this.close());

        _.defer(photoArChanged);
    }
});

const FullscreenBarcodeWindow = Window.extend({
    options: {
        number: ''
    },

    className: 'fullscreen-barcode-window',
    events: {
        'dblclick'() {
            this.close();
        }
    },

    onRender() {
        if (this.options.additionalClass)
            this.el.classList.add(this.options.additionalClass);

        const photoWidget = new PhotoWidget({
            maxZoom: 1,
            minZoom: 1,
            isCanvas: true
        });

        this.content.show(photoWidget);

        this.listenTo(photoWidget, 'gesture:collapse gesture:swipe gesture:swipe:long ' +
            'gesture:vertical:swipe gesture:vertical:swipe:long', () => this.close());

        this.content.el.classList.add('rotated');
        photoWidget.setRotation(90);
        barcodeBindingHandler($(photoWidget.getCanvas()), this.options.number);
    },
});

export const LoyaltyCardCollectionView = CollectionView.extend({
    setValidateUsers(users) {
        users = users || null;
        this.options.validateUsers = users;

        this.children.each(cw => {
            if (this.options.validateUsers && cw.model.get('validate_user')) {
                cw.setValidateUser(this.options.validateUsers[cw.model.get('validate_user')]);
            }
        });
    },
    getChildView(child) {
        if (child.has('__add__')) {
            return AddNewLoyaltyCardView;
        }

        return LoyaltyCardView;
    },
    childViewOptions(model) {
        const options = _.create(null, {
            isBusinessView: this.options.isBusinessView,
            brand: this.options.brand,
            userId: this.options.userId
        });

        if(this.options.validateUsers && model.get('validate_user')) {
            _.extend(options, { validateUser: this.options.validateUsers[model.get('validate_user')] });
        }

        return options;
    },

    options: {
        isBusinessView: false,
        brand: null,
        userId: null,
        validateUsers: null
    },

    initialize() {
        if (!this.collection) {
            this.collection = new Collection();
        }
    }
});
