import { Model, Collection, CollectionView, LayoutView } from '@b2cmessenger/backbone';
import settings from 'settings';
import AjaxError from 'utils/AjaxError';
import InfoModal from 'windows/Modal/Info';
import Window from 'windows/Window';

import HeaderView from 'widgets/HeaderWithTabs/HeaderWithTabs';
import FooterView from 'widgets/Footer/Footer';
import GiftItemView from './GiftItemView';
import UserView from 'widgets/UserList/UserChild';
import UserModel from 'models/UserModel';
import GiftModal from 'windows/Modal/Gift';
import 'utils/Element.scrollIntoCenter';

import './GiftsList.scss';
import ContainerWithNotiicationsToRead from "traits/ContainerWIthNotificationsToRead";

const GTYPE_COINS = 1;
const GTYPE_GIFTS = 2;
const GTYPE_DISCOUNTS = 3;

const notificationsReadTrait = {
    Trait: ContainerWithNotiicationsToRead,
    options: {
        container: '[data-js-content]',
        notificationElSelector: '[data-notification-ids]'
    }
};

const GiftCollectionView = CollectionView.extend({
    options: {
        collection: null,
        viewModel: null,
        writeoff: false,
        qrWriteoff: false,
        history: true,
        user: null,
    },
    className: 'gift-templates-list',
    childView: GiftItemView,
    childViewOptions() {
        return _.create(null, {
            writeoff: this.options.writeoff,
            qrWriteoff: this.options.qrWriteoff,
            history: this.options.history,
            user: this.options.user
        });
    },

    childEvents: {
        reload() {
            this.triggerMethod('reload');
        },
        writeoff(child, gifts) {
            this.triggerMethod('writeoff', child, gifts);
        },
        before_writeoff(child, gifts) {
            this.triggerMethod('before_writeoff', child, gifts);
        },
        after_writeoff(child, gifts) {
            this.triggerMethod('after_writeoff', child, gifts);
        }
    },

    filter(child, index, collection) {
        if (this.viewModel) {
            let activityFilter = this.viewModel.get('filterActive') ?
                child.get('totalQty') > 0 :
                child.get('totalQty') <= 0;

            if (child.get('type') == 3) {
                if (child.get('gifts')[0].expireDateLocal) {
                    const gift = child.get('gifts')[0],
                        valid = gift.expireDateLocal > new Date();
                    activityFilter = (this.viewModel.get('filterActive') && valid || !this.viewModel.get('filterActive') && !valid) && activityFilter;
                }
            }

            if (this.viewModel.get('filterType')) {
                return child.get('type') == this.viewModel.get('filterType') && activityFilter;
            }

            return activityFilter;
        } else {
            return true;
        }
    }
});

const GiftsView = LayoutView.extend({
    options: {
        collection: null,
        viewModel: null,
        user: null,
        writeoff: false,
        qrWriteoff: false,
        history: true,
        filter: null
    },
    className: 'widget gifts-widget',

    template() {
        return '<div data-js-user class="user"></div>' +
            '<div class="delimeter"></div>' +
            '<div data-js-gifts class="gifts"></div>' +
            '<div data-js-keyboard-padding></div>';
    },

    regions: {
        user: '[data-js-user]',
        gifts: '[data-js-gifts]'
    },

    childEvents: {
        reload() {
            this.triggerMethod('reload');
        },
        writeoff(cw, child, gifts) {
            this.triggerMethod('writeoff', child, gifts);
        },
        before_writeoff(cw, child, gifts) {
            this.triggerMethod('before_writeoff', child, gifts);
        },
        after_writeoff(cw, child, gifts) {
            this.triggerMethod('after_writeoff', child, gifts);
        }
    },

    initialize() {
        Object.defineProperty(this, 'children', {
            get() {
                return this.gifts.currentView && this.gifts.currentView.children;
            }
        });
    },

    onRender() {
        this.user.show(new UserView({ model: new UserModel(this.options.user) }));

        this.gifts.show(new GiftCollectionView({
            collection: this.options.collection,
            viewModel: this.viewModel,
            writeoff: this.options.writeoff,
            qrWriteoff: this.options.qrWriteoff,
            history: this.options.history,
            user: this.options.user
        }));
    }
});

export default Window.extendWithTraits([notificationsReadTrait], {
    options: {
        user: null,
        userId: null,
        brandId: null,
        scrollToGiftWithId: null,
        initiateWriteoffGiftTemplateWithId: null,
        initiateWriteoffGiftQuantity: 1
    },

    windowName: 'gifts-list-window',
    className: 'gifts-list-window',

    initialize() {
        this.collection = new Collection;

        this.viewModel.set({
            filterActive: true,
            filterType: null
        });

        const onChangeFilter = () => {
            if (this.content.currentView) {
                this.content.currentView.render();
            }
        };
        this.listenTo(this.viewModel, 'change:filterActive change:filterType', _.debounce(onChangeFilter, 1));

        this.usersMap = {};
        this.clearedTemplates = [];

        if (this.options.user) {
            this.options.userId = this.options.user.id;
        }

        this.listenTo(notificationCollection, 'add', notification => {
            if (notification.get('isNewGift') && notification.get('gift_brand_id') == this.options.brandId) {
                try {
                    this.content.currentView && this.content.currentView.children.each(c => _.isFunction(c.closeQrModal) && c.closeQrModal());
                } catch (e) { }
                this._dataRequest();
            }
        });
    },

    cancel() {
        this.close(this.model);
    },

    onUnreadNotificationElIsVisible(trait, els) {
        const ids =_.chain(els)
            .reduce((memo, el) => {
                _.each(el.getAttribute('data-notification-ids').split(','), id => {
                    memo[id] = 1;
                });

                el.removeAttribute('data-notification-ids');

                return memo;
            }, {})
            .keys()
            .map(Number)
            .value();

        notificationCollection.markAsReadByIds(ids);
        console.log(`Mark as read notifications: ${ids.join(',')}`, els);
    },

    onRender() {
        const rightButtons = [];

        if (this.options.user &&
            LoginedUserHandler.isHasRoleInAnyBrandPlace(this.options.brandId, B2CEmployee.const.roles.GIFT_CREATOR))
        {
            rightButtons.push({
                id: 'add',
                text: 'Add',
                icn: 'add'
            })
        }

        const headerView = new HeaderView({
            leftButtons: ['back'],
            title: this.options.user ? 'Client\'s gifts' : 'Gifts',
            rightButtons: rightButtons,
            tabs: [
                {
                    id: 'coins',
                    title: 'Coins',
                    icon: 'icon-coins',
                    type: GTYPE_COINS,
                    active: this.viewModel.get('filterType') == GTYPE_COINS
                },
                {
                    id: 'gifts',
                    title: 'Gifts',
                    icon: 'icon-gift2',
                    type: GTYPE_GIFTS,
                    active: this.viewModel.get('filterType') == GTYPE_GIFTS
                },
                {
                    id: 'discounts',
                    title: 'Discounts',
                    icon: 'icon-discount',
                    type: GTYPE_DISCOUNTS,
                    active: this.viewModel.get('filterType') == GTYPE_DISCOUNTS
                },
                {
                    id: 'active',
                    title: 'Active',
                    icon: 'ion-ios-checkmark-circle-outline',
                    active: this.viewModel.get('filterActive')
                }
            ]
        });

        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.listenTo(headerView, 'add:click', () => {
            // Выбор темплейта для подарка
            new GiftTemplates({
                brandId: this.options.brandId,
                choosable: true
            }).show().then((giftTemplateModel) => {
                if (giftTemplateModel) {
                    // Модальное окно с подтверждением
                    new GiftModal({
                        model: giftTemplateModel,
                        user: this.options.user,
                    }).show()
                        .then(templateModel => {
                            if (templateModel) {
                                Server.callServer({
                                    url: settings.host + settings.serv_gift.create,
                                    data: {
                                        gifts: [{
                                            template_id: templateModel.get('id'),
                                            brand_id: templateModel.get('brand_id'),
                                            user_id: this.options.userId,
                                            cl_task_id: null,
                                            cl_comment_id: null,
                                            comment_id: null,
                                            quantity: templateModel.get('quantity'),
                                            created_by: LoginedUserHandler.loginedUser.id,
                                            user_datetime: new Date().toISOStringTZ(),
                                            transformation_type: templateModel.get('transformation_type') || null,
                                            receipt_amount: templateModel.get('receiptAmount') || null
                                        }]
                                    },
                                    type: 'POST',
                                    success: (res) => {
                                        this._dataRequest();
                                    }
                                });
                            }
                        });
                }
            });
        });

        this.listenTo(headerView, 'tab:click', (parent, child) => {
            const type = child.model.get('type');
            if(type) {
                if(this.viewModel.get('filterType') == type) {
                    this.viewModel.set({ filterType: null });
                } else {
                    this.viewModel.set({ filterType: type });
                }
            }

            if (child.model.get('id') == 'active') {
                const filterActive = !this.viewModel.get('filterActive');
                this.viewModel.set({ filterActive });
                if (filterActive) {
                    child.setActive();
                } else {
                    child.resetActive();
                }
            } else {
                parent.resetTabs(['active']);
                if (this.viewModel.get('filterType')) child.setActive();
            }
        });

        this.header.show(headerView);

        const loginedUser = LoginedUserHandler.getLoginedUser(),
            canWriteOffGifts = this.options.user && LoginedUserHandler.isHasRoleInAnyBrandPlace(this.options.brandId, B2CEmployee.const.roles.GIFT_CREATOR),
            canWriteoffViaQr = !canWriteOffGifts && loginedUser && (loginedUser.id == this.options.userId),
            collectionView = this.options.user
                ? new GiftsView({
                    collection: this.collection,
                    user: this.options.user,
                    viewModel: this.viewModel,
                    writeoff: canWriteOffGifts,
                    qrWriteoff: canWriteoffViaQr
                })
                : new GiftCollectionView({
                    collection: this.collection,
                    viewModel: this.viewModel,
                    writeoff: canWriteOffGifts,
                    qrWriteoff: canWriteoffViaQr
                });

        collectionView.on('reload', () => this._dataRequest());

        collectionView.on('writeoff', (child, gifts) => {
            this._aggregateTemplates(this.clearedTemplates, gifts, this.collection);

            if (child.model.get('totalQty') > 0) {
                // Частичный рендер
                child.refresh();
            } else {
                // Если количество 0, то подарок попадает под фильтр по активности
                child.destroy();
            }
        });

        collectionView.on('before_writeoff', (child, gifts) => {
            this.showLoading();
        });

        collectionView.on('after_writeoff', (child, gifts) => {
            this.hideLoading();
        });

        this.content.show(collectionView);

        this._dataRequest()
            .then(() => {
                delete this.options.initiateWriteoffGiftTemplateWithId;
                delete this.options.initiateWriteoffGiftQuantity;
                delete this.options.scrollToGiftWithId;
            })
            .catch(e => this.showError(e));
    },

    _dataRequest(options) {
        return new Promise((resolve, reject) => {
            _.defaults(options || (options = {}), this.options);

            this.showLoading();
            Server.callServer({
                url: settings.host + settings.serv_gift.search,
                data: {
                    user_id: this.options.userId,
                    brand_id: this.options.brandId,
                    type: "",
                    need_brands: 0,
                    need_templ: 1,
                    need_bus_users: 1
                },
                type: "POST",
                success: (data) => {
                    this.hideLoading();

                    this.collection.reset(this._prepareData(data).models);

                    if (!_.isNull(options.initiateWriteoffGiftTemplateWithId)) {
                        _.defer(() => {
                            let targetModel;
                            if (options.initiateWriteoffGiftTemplateWithId == 0) {
                                targetModel = this.collection.find(g => g.get('type') == 1);
                            } else {
                                targetModel = this.collection.find(g => g.get('template').id == options.initiateWriteoffGiftTemplateWithId)
                            }

                            if (targetModel) {
                                const targetItemView = this.content.currentView && this.content.currentView.children.findByModel(targetModel);

                                if (targetItemView) {
                                    if (LoginedUserHandler.isHasRoleInAnyBrandPlace(options.brandId, B2CEmployee.const.roles.GIFT_CREATOR)) {
                                        targetItemView.writeOff(options.initiateWriteoffGiftQuantity);
                                    }
                                    targetItemView.el.scrollIntoCenter();
                                }
                            }

                            resolve();
                        });
                    } else if (this.options.scrollToGiftWithId) {
                        _.defer(() => {
                            const targetModel = (this.collection.find(i => i.id == this.options.scrollToGiftWithId || _(i.get('gifts')).find(g => g.id == this.options.scrollToGiftWithId))),
                                targetItemView = targetModel && this.content.currentView && this.content.currentView.children.findByModel(targetModel);

                            if (targetItemView) {
                                targetItemView.openHistory();
                                targetItemView.el.scrollIntoCenter();
                            }

                            resolve();


                        });
                    }

                    this.viewModel.trigger('check:notifications:in:viewport');
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    this.hideLoading();
                    reject(new AjaxError(jqXHR, textStatus, errorThrown));
                }
            });
        });
    },

    _prepareData(result) {
        let gifts = result.gifts,
            templates = result.gift_templates,
            coinTemplate = null;

        this.clearedTemplates = new Collection();

        // Создаем хэшмап с ключем – айди пользователя
        result.b_users.forEach((user) => {
            this.usersMap[user.id] = user;
        });

        let loginedUser = LoginedUserHandler.getLoginedUser();
        if(loginedUser && !this.usersMap[loginedUser.id]) {
            this.usersMap[loginedUser.id] = loginedUser;
        }

        // Проходимся по шаблон и приводим к уникальности подарок с бонусами
        templates.forEach(template => {
            template.gifts = [];
            template.giftsUnlimited = [];
            template.giftsExpiring = [];
            template.giftsCharging = [];
            template.totalChargeQty = 0;
            template.totalQty = 0;

            //if(template.type == 1 && !coinsTemplateAssigned || template.type !== 1) {
            this.clearedTemplates.add(new Model(template));
            //}

            if(template.type == 1) {
                if (!coinTemplate) {
                    coinTemplate = new Model(template);
                }
            }
        });

        return this._aggregateTemplates(this.clearedTemplates, gifts);
    },

    _aggregateTemplates(templates, gifts, collection) {
        const now = new Date(),
            items = collection || new Collection,
            coinTemplate = templates.find(t => t.get('type') == 1);

        gifts.forEach(gift => {
            let template = gift.template_id ? templates.get(gift.template_id) : coinTemplate,
                item,
                unlimited = !gift.expire,
                expiring = false;

            if(template.get('type') == 1)
                template = coinTemplate;

            if(!unlimited) {
                let now = new Date(),
                    expireDate = new Date(gift.expire.replace(/ /g, "T")),
                    expireDateLocal = new Date(expireDate);

                expireDateLocal.setFullYear(expireDate.getUTCFullYear(), expireDate.getUTCMonth(), expireDate.getUTCDate());
                expireDateLocal.setHours(23, 59, 59, 999);

                expiring = expireDateLocal > now;

                gift.expireDateLocal = expireDateLocal;
            }

            if(template && (unlimited || expiring || true)) {
                if(template.get('type') == 1 || template.get('type') == 2) {
                    item = items.find(i => i.has('template') && i.get('template').id == template.id);

                    if(!item) {
                        item = template.clone();
                        item.set({
                            id:  gift.id,
                            template: template,
                            gifts: [],
                            giftsUnlimited: [],
                            giftsExpiring: [],
                            giftsCharging: [],
                            totalChargeQty: 0,
                            totalQty: 0,
                        });
                        items.add(item);
                    }
                } else {
                    gift.quantity = gift.quantity || 1;
                    item = items.get(gift.id);
                    if(!item) {
                        item = template.clone();
                        item.set({
                            id:  gift.id,
                            template: template,
                            gifts: [],
                            giftsUnlimited: [],
                            giftsExpiring: [],
                            giftsCharging: [],
                            totalChargeQty: 0,
                            totalQty: 0
                        });
                        items.add(item);
                    }
                }

                if(item) {
                    gift.user = this.usersMap[gift.created_by];
                    gift.template = item;

                    item.modifyArray('gifts', 'push', gift);

                    if(gift.quantity > 0) {
                        if(unlimited) {
                            item.modifyArray('giftsUnlimited', 'push', gift);
                            item.set('totalQty', item.get('totalQty') + gift.quantity);
                        } else if(expiring)  {
                            item.modifyArray('giftsExpiring', 'push', gift);
                            item.set('totalQty', item.get('totalQty') + gift.quantity);
                        }
                    } else if(gift.quantity < 0) {
                        item.modifyArray('giftsCharging', 'push', gift);
                        item.set('totalChargeQty', item.get('totalChargeQty') - gift.quantity);

                        if(template.get('type') == 1) {
                            item.set('totalQty', item.get('totalQty') + gift.quantity);
                        }
                    }
                }
            }
        });

        items.each(item => item.modifyArray('gifts', 'forEach', gift => {
            gift.chargingGifts = _(item.get('giftsCharging')).where({parent_gift: gift.id});
            gift.chargedQty = _(gift.chargingGifts).reduce((qty, gift) => qty - gift.quantity, 0);
        }));

        return items;
    },
});
