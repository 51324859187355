import React from 'react';

import './MenuCartItemCustomize.scss';
import InputGroup, { RadioInput, TextareaInput, TextInputGroup } from 'components/UI/InputGroup/InputGroup';
import QuantityChangeControl from 'components/UI/QuantityChangeControl/QuantityChangeControl';
import MenuItemsWithControl from 'components/menu/MenuItems/MenuItemsWithControl';
import Price from 'components/UI/Price/Price';
import MenuItems from 'components/menu/MenuItems/MenuItems';
import { Button } from 'components/UI/Button/Button';
import { getAdditionItemPrice } from 'store/menu/utils';

export const MenuCartItemAdditionsList = (props) => {
    const {
        selectedAdditionItems,
        additions,
        additionsEditHandler,
        onChangeAdditionCount,
        setAdditionsFromPreviousAdditions,
    } = props;

    return (
        <div className="additions">
            <p className="title">Select addition items</p>
            <MenuItemsWithControl
                className="addition-items-with-control menu-list-compact"
                additions
                items={selectedAdditionItems}
                itemsCountMap={additions}
                onChangeCount={onChangeAdditionCount}
            />
            <Button className="btn-medium-wide choose-addition" wrapChildren={false} onClick={additionsEditHandler}>
                {additions.length ? 'Update add-ons' : 'Choose add-ons'}
            </Button>
            {!_.isNull(setAdditionsFromPreviousAdditions) && (
                <Button
                    className="btn-medium-wide choose-addition apply-previous-additions"
                    wrapChildren={false}
                    onClick={setAdditionsFromPreviousAdditions}
                >
                    Use previous add-ons
                </Button>
            )}
        </div>
    );
};

export const MenuCartPropertiesList = (props) => {
    const { properties, selectedProperties, onChangeProperty, disabled = false } = props;
    return (
        <div className="properties">
            <p className="title">Select required properties</p>
            {properties.map((prop) => {
                const { name, options: menuItemOptions = [] } = prop;
                const selectedProperty = selectedProperties && selectedProperties.find((p) => p.name === name);

                const onChange = (e) => onChangeProperty({ name, value: e.currentTarget.value });

                const options = menuItemOptions.map((value) => {
                    const checked = Boolean(selectedProperty && selectedProperty.value === value);
                    return { id: value, label: value, value, checked };
                });

                return (
                    <InputGroup key={name} className="input-group properties" label={name}>
                        <div className="radio-input">
                            <RadioInput id={name} onChange={onChange} options={options} disabled={disabled} />
                        </div>
                    </InputGroup>
                );
            })}
        </div>
    );
};

const MenuCartItemCustomize = (props) => {
    const {
        menuItem,
        properties,
        additions,
        additionItems,
        selectedAdditionItems,
        onChangeAdditionCount,
        additionsEditHandler,
        comment,
        setComment,
        onChangeProperty,
        count,
        setCount,
        disabled,
        setAdditionsFromPreviousAdditions,
    } = props;

    const { properties: itemProperties = null } = menuItem;

    const additionsPrice = _(selectedAdditionItems).reduce((memo, item) => {
        return memo + getAdditionItemPrice(item) * additions.find((a) => a.id === item.id).count;
    }, 0);
    const priceWithAdditions = menuItem ? menuItem.price + additionsPrice : 0;
    const totalPrice = count * priceWithAdditions;

    const priceWithAdditionsEl =
        priceWithAdditions && selectedAdditionItems && selectedAdditionItems.length > 0 ? (
            <InputGroup label="Price with additions">
                <Price value={priceWithAdditions} currency={menuItem.currency} />
            </InputGroup>
        ) : null;

    const totalPriceEl = menuItem ? (
        <InputGroup label="Total price">
            <Price value={totalPrice} currency={menuItem.currency} />
        </InputGroup>
    ) : null;

    return (
        <div className="menu-cart-item-customize-content">
            <MenuItems items={[{ ...menuItem }]} />
            {itemProperties && itemProperties.length > 0 && (
                <MenuCartPropertiesList
                    properties={itemProperties}
                    selectedProperties={properties}
                    onChangeProperty={onChangeProperty}
                />
            )}
            {additionItems && additionItems.length > 0 && (
                <MenuCartItemAdditionsList
                    additions={additions}
                    setAdditionsFromPreviousAdditions={setAdditionsFromPreviousAdditions}
                    additionsEditHandler={additionsEditHandler}
                    onChangeAdditionCount={onChangeAdditionCount}
                    selectedAdditionItems={selectedAdditionItems}
                />
            )}
            <InputGroup label="Count">
                <QuantityChangeControl
                    value={count}
                    onChange={setCount}
                    disabled={disabled}
                    disabledMinus={Boolean(count <= 1)}
                />
            </InputGroup>

            {priceWithAdditionsEl}
            {totalPriceEl}
            <div className="comment">
                <TextInputGroup label="Comment" className="input-group comment">
                    <TextareaInput
                        value={comment}
                        placeholder="Enter comment"
                        onChange={(e) => setComment(e.currentTarget.value)}
                        disabled={disabled}
                    />
                </TextInputGroup>
            </div>
        </div>
    );
};

export default MenuCartItemCustomize;
