import React from 'react';

import './MenuItemParam.scss';

export const paramsClassMap = {
    volume: {
        value: null,
        keyByServer: 'param_volume',

        icnClass: 'volume',
        text: 'Volume',
        unit: 'pt',
        units: ['l', 'ml', 'cl', 'pt', 'gal', 'fl oz'],
        min: 0,
    },
    weight: {
        value: null,
        keyByServer: 'param_weight',

        icnClass: 'weight',
        text: 'Weight',
        unit: 'lb',
        units: ['lb', 'oz', 'kg', 'gr'],
        min: 0,
    },
    energy: {
        value: null,
        keyByServer: 'param_energy',

        icnClass: 'energy',
        text: 'Energy',
        unit: 'KCal',
        min: 0,
    },
    protein: {
        value: null,
        keyByServer: 'param_protein',

        icnClass: 'protein',
        text: 'Protein',
        unit: '%',
        min: 0,
        max: 100,
    },
    fat: {
        value: null,
        keyByServer: 'param_fat',

        icnClass: 'drop',
        text: 'Fat',
        unit: '%',
        min: 0,
        max: 100,
    },
    sugar: {
        value: null,
        keyByServer: 'param_sugar',

        icnClass: 'sugar',
        text: 'Sugar',
        unit: '%',
        min: 0,
        max: 100,
    },
    cooking_time: {
        value: null,
        keyByServer: 'param_cooking_time',

        icnClass: 'timer',
        text: 'Cooking time',
        unit: 'min',
        min: 0,
    },
};

const MenuItemParam = ({ id, icnClass, text, value, unit }) => {
    const val = value + (unit === '%' ? unit : ` ${unit}`);

    return (
        <div className={`param ${  id}`}>
            <div className={`param-icon ${  icnClass}`} />
            <span className="value">
                {`${text  }:`}
                <strong className="param-value">{val}</strong>
            </span>
        </div>
    );
};

export default MenuItemParam;
