import { ItemView, Collection, CollectionView, Required, ViewModel } from "@b2cmessenger/backbone";
import { DayModel } from "./ReservationsDayView";
import ReservationsDayView from "./ReservationsDayView";
import template from './ReservationsDay.jade';
import scrollmonitor from 'scrollmonitor';

const FILTER_TYPES = {
    All: 0,
    Accepted: 1,
    Pending: 2,
    Canceled: 3
};
const RESERVATION_TYPES = {
    my: 'user',
    place: 'business'
};
const EMPTY_STRING_TYPE = {
    [FILTER_TYPES.All]: 'active',
    [FILTER_TYPES.Accepted]: 'active',
    [FILTER_TYPES.Pending]: 'pending',
    [FILTER_TYPES.Canceled]: 'canceled'
};

@CollectionView.options({
    reservationCollection: Required,
    parentViewModel: Required,
    childViewType: Required
})
@CollectionView.properties({
    childView: ReservationsDayView,
    emptyView: ItemView.extend({
        className: 'reservation-collection-empty-widget',
        template,

        ui: {
            placeSearch: '[data-js-place-search]'
        },

        events: {
            'click @ui.placeSearch'() {
                const parentViewModel = this._parent.viewModel.get('parentViewModel');
                parentViewModel.trigger('show:place:search');
            }
        },

        onAttach() {
            this.listenTo(this._parent.viewModel.get('parentViewModel'), 'change:vm_tab_type', () => {
                if (!this.collection.length) {
                    this.render();
                }
            });
        },

        onDestroy() {
            this.stopListening(this._parent.viewModel.get('parentViewModel'));
        },


        templateHelpers() {
            const type = this._parent && this._parent.viewModel.get('parentViewModel').get('vm_tab_type') || undefined;
            const reservationType = _.isUndefined(type) ? EMPTY_STRING_TYPE[FILTER_TYPES.All] : EMPTY_STRING_TYPE[type];
            const parentViewModel = this._parent.viewModel.get('parentViewModel');
            const showGoToPlaceSearch = (type === FILTER_TYPES.Accepted && parentViewModel &&
                parentViewModel.get('reservationType') === RESERVATION_TYPES.my);

            return {
                description: `You don't have any ${reservationType} reservations.`,
                showGoToPlaceSearch
            }
        }
    }),
    childViewOptions(model) {
        const ids = model.get('modelIds') || [];
        const collection = new Collection(this.reservationCollection.filter(m => ids.includes(m.id)));

        return {
            reservationCollection: this.reservationCollection,
            collection: collection,
            childViewType: this.options.childViewType,
            placeId: this.reservationCollection && this.reservationCollection.options.placeId,
        };
    }
})
class ReservationsDayCView extends CollectionView {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.reservationCollection = this.options.reservationCollection;
        this.collection = new DayCollection();
        this.viewModel = new ViewModel({
            parentViewModel: this.options.parentViewModel
        });

        this._resetDayCollection();
        this.listenTo(this.reservationCollection, 'update reset', () => this._resetDayCollection());
    }

    _resetDayCollection() {
        const grouped = this.reservationCollection.groupBy(model => {
            return new Date(B2Cjs.datetimeServerLocalToJS(model.get('date_start_local')).getTime()).setHours(0, 0, 0, 0);
        });
        const dayModels = _.keys(grouped).map(key => ({
            id: Number(key),
            modelIds: grouped[key].map(m => m.id)
        }));

        this.collection.comparator = (l, r) => {
            const parentViewModel = this.viewModel.get('parentViewModel');
            const sortBy = parentViewModel.get('sortBy');
            let comparator = !!(sortBy && sortBy.match('desc')) ? (l.id < r.id ? 1 : -1) : (l.id > r.id ? 1 : -1);

            if (l.id == r.id) {
                comparator = 1;
            }

            return comparator;
        };

        this.collection.set(dayModels);
    }

    findChildByModelDeeply(targetModel) {
        let targetChildView;

        let parentViewModel = this.collection.find(model => {
            const childView = this.children.findByModel(model);

            if (childView && childView.container && childView.container.currentView) {
                const childModel = childView.container.currentView.collection.findWhere({ id: targetModel.id });

                if (childModel) {
                    targetChildView = childView.container.currentView.children.findByModel(childModel);
                    return targetChildView;
                }
            }
        });

        return { view: targetChildView, parentView: this.children.findByModel(parentViewModel) };
    }
}

const DayCollection = Collection.extend({
    model: DayModel
});

export default ReservationsDayCView;
