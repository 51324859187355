import { LayoutView, Model } from '@b2cmessenger/backbone';
import Window from 'windows/Window';
import settings from 'settings';

import B2CLoyaltyCard from 'widgets/LoyaltyCard/B2CLoyaltyCard';
import HeaderView from 'widgets/Header/Header';
import BrandItemView from 'widgets/BrandItem/BrandItem';
import ImageInput from 'widgets/Inputs/ImageInput';
import ConfirmModal from 'windows/Modal/Confirm';
import qrCodeScanner from "utils/qrCodeScanner";
import { bindingHandler as barcodeBindingHandler } from 'widgets/lite/barcode/barcode';

import template from './LoyaltyCardEditor.jade';
import './LoyaltyCardEditor.scss';
import ImageInputHelpers from "widgets/Inputs/ImageInputHelpers";
import PlaceSearchWindow from 'windows/PlaceSearch/PlaceSearch';

const LoyaltyCardEditor = Window.extend({
    options: {
        brand: null,
        card: null,
        isBusinessView: false
    },

    windowName: 'loyalty-card-editor-window',
    template: template,

    ui: {
        content: '[data-js-content]',
        brand: '[data-js-brand]',
        brandBtn: '[data-js-btn-brand]',
        number: '[data-js-number]',
        barcodeBtn: '[data-js-btn-barcode]',
        barcode: '[data-js-barcode]',
        photo: '[data-js-photo]',
        photo2: '[data-js-photo2]'
    },

    regions: {
        header: '[data-js-header]',
        brand: '[data-js-brand]',
        photo: '[data-js-photo]',
        photo2: '[data-js-photo2]'
    },

    computeds: {
        'c_barcode': {
            deps: ['number'],
            get(number) {
                return number || "";
            },
            set(val) {
                this.setBinding('number', String(val).replace(/[^\040-\177]+/ig, ''))
            }
        },
        'c_barcodeIsValid': {
            deps: ['number'],
            get(number) {
                return number && number.length && number.length <= 30;
            }
        }
    },

    bindingHandlers: {
        barcodeBindingHandler
    },

    bindings: {
        '@ui.brand': 'classes:{hidden:not(brand_id)}',
        '@ui.brandBtn': 'classes:{hidden:brand_id},disabled:disabled',
        '@ui.number': 'value:c_barcode,disabled:disabled',
        '@ui.barcode': 'classes:{hidden:not(c_barcodeIsValid)},barcodeBindingHandler:number',
        '@ui.photo': 'classes:{loaded:photo}',
        '@ui.photo2': 'classes:{loaded:photo2}'
    },

    events: {
        'click @ui.brand'() {
            this._onBrandClick();
        },
        'click @ui.brandBtn'() {
            this._onBrandClick();
        },
        'click @ui.barcodeBtn'() {
            qrCodeScanner({ rawResult: true })
                .then(number => number && this.model.set({ number }))
                .catch(e => {
                    if (e) {
////////////////////////////////////
//////////////////////////////////////////
////////////////////////////////
                        e && e.message && this.showError(e.message);
/////////////////////////////////
                    }
                });
        }
    },

    initialize() {
        const ModelPrototype = this.options.isBusinessView ? BusinessLoyaltyCardModel : LoyaltyCardModel;

        if (!this.model) {
            if (this.options.card) {
                this.model = new ModelPrototype(this.options.card);
            } else {
                this.model = new ModelPrototype;
            }
        }

        if (this.options.userId) {
            this.model.set('userId', this.options.userId);
        }

        this.viewModel.set({
            modelHasChanged: false
        });

        this.viewModel.listenTo(this.model, 'change', () => {
            this.viewModel.set({ modelHasChanged: true });
        });

        this.viewModel.listenTo(this.model, 'sync', () => {
            this.viewModel.set({ modelHasChanged: false });
        });

        if (this.options.brand) {
            const brand_id = this.model.get('brand_id');
            if (brand_id && brand_id != this.options.brand.id) {
                throw new Error(`incompatible brand_id=${brand_id} and options.brand.id=${this.options.brand.id}`);
            }

            this.brandModel = new Model(this.options.brand);
            if (!brand_id) {
                this.model.set({ brand_id: this.brandModel.id });
            }
            this.viewModel.set({ canChooseBrand: false })
        } else {
            this.brandModel = new Model;
            this.viewModel.set({ canChooseBrand: true });
        }

        this.model.listenTo(this.brandModel, 'change:id', (m, id) => this.model.set({ brand_id: id }));
    },

    onShow() {
        this.interceptor = e => {
            e.preventDefault();
            e.stopPropagation();
            window.removeEventListener(settings.backbutton.eventName, this.interceptor, true);

            this._onBackBtnClick().then(data => {
                if (data) {
                    this.close(_.isBoolean(data) ? false : data);
                } else {
                    window.addEventListener(settings.backbutton.eventName, this.interceptor, true);
                }
            });
        };

        window.addEventListener(settings.backbutton.eventName, this.interceptor, true);
    },

    close(...args) {
        window.removeEventListener(settings.backbutton.eventName, this.interceptor, true);

        return Window.prototype.close.call(this, ...args);
    },

    onRender: function () {
        const headerView = new HeaderView({
            leftButtons: ['back'],
            title: "Card editor",
            rightButtons: ['save']
        });

        this.listenTo(headerView, 'save:click', () => {
            if (!this.model.isValid({ assumeStatusActive: true })) {
                const validationError = this.model.validationError;
                let message = "Card is invalid";
                if (validationError && validationError.field == 'number') {
                    message = "Please, scan/insert card number or add card photo";
                } else if (validationError && validationError.field == 'brand_id') {
                    message = "Please, choose company first";
                } else if (validationError) {
                    message = validationError;
                }

                this.showError(message);
            } else {
                this.showLoading();
                this.model.save(
                    {
                        status: LoyaltyCardModel.Status.Active
                    },
                    {
                        wait: true,
                        success: () => {
                            this.hideLoading();
                            if (!this.options.isBusinessView) {
                                LoyaltyCardBase.add_card_to_local_storage(this.model.toJSON({computed: true}), this.brandModel.toJSON({computed: true}))
                            }
                        },
                        error: (model, resp, options) => {
                            this.hideLoading();
                            this.showError(resp);
                        }
                    }
                );
            }
        });

        this.listenTo(headerView, 'back:click', () => {
            this._onBackBtnClick().then(data => data && this.close(_.isBoolean(data) ? false : data));
        });

        this.header.show(headerView);

        const toggleSaveButton = () => {
            const isNew = this.model.isNew(),
                isEmpty = !(this.model.get('brand_id') || this.model.get('number') || this.model.get('photo') || this.model.get('photo2')),
                hasChanged = this.viewModel.get('modelHasChanged'),
                isDraft = this.model.get('status') == LoyaltyCardModel.Status.Draft;

            if ((isNew && !isEmpty) || (!isNew && (hasChanged || isDraft))) {
                headerView.ui.btnsave.removeClass('hidden');
            } else {
                headerView.ui.btnsave.addClass('hidden');
            }
        };

        headerView.listenTo(this.model, 'change:id change:status change:brand_id change:number change:photo change:photo2', toggleSaveButton);
        headerView.listenTo(this.viewModel, 'change:modelHasChanged', toggleSaveButton);
        toggleSaveButton();

        const brandItemView = new BrandItemView({
            model: this.brandModel
        });
        this.brand.show(brandItemView);

        const
            photoWidget = new ImageInput({
                model: new Model({
                    image: this.model.get('photo') || 'common_res/icon/other/photo-loyalty-card.png'
                })
            }),
            photoWidget2 = new ImageInput({
                model: new Model({
                    image: this.model.get('photo2') || 'common_res/icon/other/photo-loyalty-card.png'
                })
            });

        this.listenTo(photoWidget, 'change', file => {
            if (file instanceof Blob || _.isString(file)) {
                this._uploadAndSaveCardImage(file, 1);
            }
        });

        photoWidget2.on('change', file => {
            if (file instanceof Blob || _.isString(file)) {
                this._uploadAndSaveCardImage(file, 2);
            }
        });

        this.listenTo(photoWidget, 'loading', () => this.showLoading());
        this.listenTo(photoWidget, 'loaded loadfailed', () => this.hideLoading());
        this.listenTo(photoWidget2, 'loading', () => this.showLoading());
        this.listenTo(photoWidget2, 'loaded loadfailed', () => this.hideLoading());

        photoWidget.listenTo(this.model, 'change:photo', (m, photo) => {
            photoWidget.model.set({ image: photo || 'common_res/icon/other/photo-loyalty-card.png' });
            if (photo) {
                photoWidget.$el.addClass('loaded');
            } else {
                photoWidget.$el.removeClass('loaded');
            }
        });

        photoWidget2.listenTo(this.model, 'change:photo2', (m, photo) => {
            photoWidget2.model.set({ image: photo || 'common_res/icon/other/photo-loyalty-card.png' });
            if (photo) {
                photoWidget2.$el.addClass('loaded');
            } else {
                photoWidget2.$el.removeClass('loaded');
            }
        });

        this.photo.show(photoWidget);
        this.photo2.show(photoWidget2);
    },

    _uploadAndSaveCardImage(file, photoIndex = 0) {
        this.trigger('disable');
        this.showLoading();

        ImageInputHelpers.upload(file, 'lc')
            .then(uploadedFile => {
                this.model.set({
                    [`photo${photoIndex && photoIndex > 1 ? photoIndex : ''}`]: uploadedFile.full,
                    [`photoFile${photoIndex && photoIndex > 1 ? photoIndex : ''}`]: uploadedFile.id,
                    status: this.model.get('status') == LoyaltyCardModel.Status.Draft ?
                        LoyaltyCardModel.Status.Draft : LoyaltyCardModel.Status.Active
                });
                this.trigger('enable');
                this.hideLoading();
            })
            .catch(e => {
                this.trigger('enable');
                this.hideLoading();
                this.showError(e);
            });
    },

    _onBrandClick() {
        if (!this.viewModel.get('canChooseBrand'))
            return;

        new PlaceSearchWindow().show({
            selectPlaceOnce: place => {
                if (place) {
                    this.brandModel.clear();
                    this.brandModel.set({
                        id: place.brand_id,
                        name: place.name,
                        logoThumb: place.logoThumb,
                        logo: place.logo,
                    });

                    return false;
                }
            }
        });
    },

    _onBackBtnClick() {
        return new Promise((resolve, reject) => {
            const isValid = this.model.isValid({ assumeStatusActive: true });
            const isNew = this.model.isNew();
            const isEmpty = !(this.model.get('brand_id') || this.model.get('number') || this.model.get('photo') ||
                this.model.get('photo2'));
            const hasChanged = this.viewModel.get('modelHasChanged');
            const isDraft = this.model.get('status') == LoyaltyCardModel.Status.Draft;

            if (isEmpty || !(hasChanged || isDraft)) {
                if (isNew) {
                    resolve(true);
                } else {
                    if (this.options.isBusinessView) {
                        resolve(new LoyaltyCardModel(this.model.toJSON({ computed: true })) || true);
                        return;
                    }

                    const models = LoyaltyCardBase.gatCards_by_ids([this.model.id]),
                        model = models && models.length && models[0];

                    resolve(model && new LoyaltyCardModel(model) || true);
                }
            } else if (!isValid) {
                new ConfirmModal({
                    message: "Cancel editing? All changes will be lost!",
                })
                    .show()
                    .then(confirm => resolve(confirm));
            } else {
                new ConfirmModal({
                    message: "Save loyalty card before close?",
                    buttons: [
                        {
                            id: "no",
                            text: "DISCARD",
                            icn: "empty",
                            value: false
                        },
                        {
                            id: "yes",
                            text: "SAVE",
                            icn: "empty",
                            value: true
                        }
                    ],
                }).show()
                    .then(confirm => {
                        if (confirm) {
                            this.showLoading();
                            this.model.save(
                                {
                                    status: LoyaltyCardModel.Status.Active
                                },
                                {
                                    wait: true,
                                    success: () => {
                                        this.hideLoading();
                                        resolve(this.model);
                                        if (!this.options.isBusinessView) {
                                            LoyaltyCardBase.add_card_to_local_storage(this.model.toJSON({computed: true}),
                                                this.brandModel.toJSON({computed: true}))
                                        }
                                    },
                                    error: (model, resp, options) => {
                                        this.hideLoading();
                                        this.showError(resp);
                                        reject();
                                    }
                                }
                            );
                        } else {
                            if (this.model.isNew()) {
                                resolve(true);
                            } else {
                                const models = LoyaltyCardBase.gatCards_by_ids([this.model.id]),
                                    model = models && models.length && models[0];

                                resolve(model && new LoyaltyCardModel(model) || true);
                            }
                        }
                    })
            }
        });
    }
});

const Status = {
    MarkedForDeletion: -2,
    Discard: -1,
    Draft: 0,
    Active: 1,
    Approved: 2,
};

const LoyaltyCardModel = Model.extend({
    defaults: {
        status: Status.Draft,
        brand_id: null,
        number: '',
        photo: null,
        thumb: null,
        photo2: null,
        thumb2: null,
        barcode: null,
        photoFile: null,
        photoFile2: null
    },

    computeds: {
        number: {
            deps: ['_number'],
            get: _number => _number || '',
            set: val => _.create(null, {
                _number: val && String(val).replace(/[^\040-\177]+/ig, '') || ''
            })
        }
    },

    urlRoot: settings.host + settings.serv_loyaltycard.save,

    url() {
        if (this instanceof BusinessLoyaltyCardModel) {
            return Model.prototype.url.call(this, arguments);
        }

        var base =
            _.result(this, 'urlRoot') ||
            _.result(this.collection, 'url') ||
            urlError();
        return base;
    },

    validate(attrs, options) {
        _.defaults(options || (options = {}), {
            assumeStatusActive: false
        });

        if (!_.some([attrs.number, attrs._number, attrs.photo, attrs.photo2, attrs.photoFile, attrs.photoFile2])) {
            const error = new Error("At least card number or photo should be provided");
            error.field = 'number';
            return error;
        }

        if (attrs.status == 1 || options.assumeStatusActive) {
            if (!attrs.brand_id) {
                const error = new Error("Brand should be selected");
                error.field = 'brand_id';
                return error;
            }
        }
    },

    sync(method, model, options) {
        _.defaults(options || (options = {}), {
            contentType: false,
        });

        if (method === 'create' || method === 'update') {
            const isBusinessModel = this instanceof BusinessLoyaltyCardModel;
            const success = options.success;
            options.success = function (data) {
                if (success) success.call(options.context, data);
                model.unset('photoFile', { silent: true });
                model.unset('photoFile2', { silent: true });
            };

            if (options.data == null) {
                options.data = new FormData();
                const attributes = options.attrs || model.toJSON({ computed: true });

                if (attributes.id) {
                    options.data.append('id', model.id);
                }

                if (attributes.brand_id) {
                    options.data.append('brand_id', attributes.brand_id)
                }

                options.data.append('number', attributes.number || '');

                const photoFile = attributes.photoFile;
                if (photoFile && photoFile instanceof Blob) {
                    options.data.append('LoyaltyCard[__photo_file__]', photoFile);
                } else if (photoFile && _.isNumber(photoFile)) {
                    options.data.append('photo', photoFile);
                }

                const photoFile2 = attributes.photoFile2;
                if (photoFile2 && photoFile2 instanceof Blob) {
                    options.data.append('LoyaltyCard[__photo2_file__]', photoFile2);
                } else if (photoFile2 && _.isNumber(photoFile2)) {
                    options.data.append('photo2', photoFile2);
                }

                options.type = 'POST';

                if (isBusinessModel) {
                    options.contentType = 'application/json';

                    if (method === 'update') {
                        method = 'patch';
                        options.type = 'PATCH';
                    } else if (method === 'create' && attributes.userId) {
                        options.data.append('user_id', attributes.userId);
                    }

                    const payload = {};
                    for (const [k, v] of options.data.entries()) {
                        payload[k] = v;
                    }

                    options.data = JSON.stringify(payload);
                } else {
                    options.data.append('status', attributes.status || Status.Draft);
                }
            }
            return Model.prototype.sync.call(this, method, model, options);
        } else {
            const err = new Error(`Method "${method}" is not supported`);
            if (options && _.isFunction(options.error)) {
                options.error.call(options.context, err);
            }

            return $.Deferred().reject(err);
        }
    },
}, {
    Status
});

const BusinessLoyaltyCardModel = LoyaltyCardModel.extend({
    urlRoot: settings.host + settings.serv_loyaltycard.business.base,
});

export default LoyaltyCardEditor;
