import { CollectionView, LayoutView, Optional, ViewModel } from "@b2cmessenger/backbone";
import template from './CalendarLayoutView.jade';
import CalendarItemView from "./CalendarItemView";
import CalendarCollection from './CalendarCollection';
import HeaderView from "widgets/Header/Header";
import './CalendarLayoutView.scss';
import ReservationModel from "models/ReservationModel";
import ReservationSummaryCollection from 'models/ReservationSummaryCollection';
window.ReservationSummaryCollection = ReservationSummaryCollection;

@LayoutView.options({
    calendarInitialDate: Optional,
    additionalCollection: Optional,
    additionalCollectionDateAttr: Optional
})
@LayoutView.properties({
    template,
    className: 'widget calendar-widget',
    regions: {
        header: '[data-js-calendar-header]',
        content: '[data-js-calender-content]'
    }
})
class CalendarLayoutView extends LayoutView {
    updateSelected() {
        this._setSelected(this.additionalCollection);
    }

    constructor(options) {
        super(options)
    }

    initialize() {
        const date = this.options.calendarInitialDate || new Date();
        const headerTitle = date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
        });

        this.viewModel = new ViewModel({ headerTitle });
        this.collection = new CalendarCollection(null, { date });

        if (this.options.additionalCollection) {
            this.additionalCollection = this.options.additionalCollection;
            this.additionalCollectionDateAttr = this.options.additionalCollectionDateAttr || 'date_start_local';

            this.listenTo(this.additionalCollection, 'update', () => {
                this._setSelected(this.additionalCollection);
            });
        }
    }

    templateHelpers() {
        return {
            weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
        }
    }

    onAttach() {
        const headerView = new HeaderView({
            leftButtons: ['back'],
            rightButtons: ['prev', 'next'],
            title: this.viewModel.get('headerTitle')
        });

        this.listenTo(headerView, 'back:click', () => this.trigger('close', false));
        this.listenTo(headerView, 'prev:click', () => {
            this.collection.setPrevMonthGrid();
            this._onCurrentMonthChange();
        });
        this.listenTo(headerView, 'next:click', () => {
            this.collection.setNextMonthGrid();
            this._onCurrentMonthChange();
        });

        headerView.listenTo(this.viewModel, 'change:disabled',
            m => headerView.ui.btnback.prop('disabled', m.get('disabled')));
        headerView.listenTo(this.viewModel, 'change:headerTitle',
            m => headerView.ui.title.text(m.get('headerTitle')));

        this.header.show(headerView);

        const contentView = new CalendarCView({
            collection: this.collection
        });

        this.listenTo(contentView, 'child:selected', view => this.trigger('close', new Date(view.model.id)));
        this.content.show(contentView);

        this._updateAdditionalCollection();
    }

    _onCurrentMonthChange() {
        this.viewModel.set('headerTitle', this.collection.currentDateMoment.format('MMMM YYYY'));
        this._updateAdditionalCollection();
    }

    _updateAdditionalCollection() {
        this.trigger('update:additional:collection', {
            start: this.collection.start,
            end: this.collection.end
        });
    }

    _setSelected(additionalCollection) {
        additionalCollection.each(m => {
            const model = this.collection.get(m.id);

            if (model) {
                model.set(m.pick('accepted', 'pending', 'canceled'));
            }
        });
    }
}

@CollectionView.properties({
    tagName: 'ul',
    childView: CalendarItemView,
    childViewEventPrefix: 'child',
    childViewOptions() {
        return { parentViewModel: this.viewModel }
    }
})
class CalendarCView extends CollectionView {
    constructor(options) {
        super(options)
    }
}

export default CalendarLayoutView;
