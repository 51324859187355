import { Model, ViewModel } from '@b2cmessenger/backbone';
import settings from 'settings';

import Page from 'pages/Page';
import Window from 'windows/Window';
import HeaderView from 'widgets/Header/Header';
import FooterView from 'widgets/Footer/Footer';
import AjaxError from 'utils/AjaxError';
import Behaviors from 'utils/Behaviors';
import ImageInput from 'widgets/Inputs/ImageInput';

import template from './ProfileEditor.jade';
import passwordChangeTemplate from './PasswordChange.jade';
import './ProfileEditor.scss';
import ImageInputHelpers from 'widgets/Inputs/ImageInputHelpers';
import ConfirmModalWindow from 'windows/Modal/Confirm';
import { IntlTelInputHandler } from 'widgets/lite/intlTelInput/intlTelInput';
import getUserCountryCode from 'utils/getUserCountryCode';

const ProfileEditorPage = Page.extend({
    template: template,

    attributes: {
        'id': "page_useredit",
    },

    className: "profile-editor-page",

    regions: {
        header: '[data-js-header]',
        avatar: '[data-js-avatar]',
        footer: '[data-js-footer]'
    },

    ui: {
        form: '[data-js-form]',
        avatarInput: '[data-js-avatar]',
        password: '[data-js-btn-password]',
        firstname: '[data-js-firstname]',
        firstnameError: '[data-js-firstname-error]',
        lastname: '[data-js-lastname]',
        lastnameError: '[data-js-lastname-error]',
        email: '[data-js-email]',
        emailError: '[data-js-email-error]',
        birthday: '[data-js-birthday]',
        birthdayError: '[data-js-birthday-error]',
        phone: '[data-js-phone]',
        phoneError: '[data-js-phone-error]',
        gender: '[data-js-input-group-gender] > input[type="radio"]',
        genderError: '[data-js-gender-error]'
    },

    computeds: {
        prettyPhone: {
            deps: ['phone'],
            get: phone => phone && `+${phone}` || "",
            set(val) {
                this.model.set({
                    phone: val && B2Cjs.phone_getInternalFormat(val)
                });
            }
        }
    },

    bindings: {
        '@ui.firstname': 'value:firstname',
        '@ui.firstnameError': 'text:firstnameError',
        '@ui.lastname': 'value:lastname',
        '@ui.lastnameError': 'text:lastnameError',
        '@ui.email': 'value:email',
        '@ui.emailError': 'text:emailError',
        '@ui.birthday': 'value:birthday',
        '@ui.birthdayError': 'text:birthdayError',
        '@ui.phone': 'intlTelInput:prettyPhone',
        '@ui.phoneError': 'text:phoneError',
        '@ui.gender': 'checked:gender',
        '@ui.genderError': 'text:genderError',
        '@ui.form': 'disabled:disabled',
        '@ui.password': 'disabled:disabled'
    },

    bindingHandlers() {
        return {
            intlTelInput: new IntlTelInputHandler({
                preferredCountries() {
                    return getUserCountryCode()
                        .then(cc => cc && [cc] || []);
                }
            })
        };
    },

    events: {
        'submit @ui.form': 'onSubmit',
        'change @ui.avatarInput': 'onAvatarChanged',
        'click @ui.password'() {
            new PasswordChangeWindow({
                model: new Model({
                    id: this.model.id,
                    email: this.model.get('email'),
                })
            }).show();
        }
    },

    initialize() {
        if (!this.model)
            this.model = new Model({
                firstname: null,
                lastname: null,
                email: null,
                birthday: null,
                phone: null,
                gender: null
            });

        if (!this.model.has('gender')) {
            this.model.set('gender', null);
        }

        this.viewModel = new ViewModel({
            firstnameError: '',
            lastnameError: '',
            emailError: '',
            birthdayError: '',
            phoneError: '',
            genderError: '',
        });

        this.viewModel.listenTo(this.model, 'change', _.debounce(() => this.validate(), 500));
        this.listenTo(this, 'submit', () => this.ui.form.submit());
    },

    onRender() {
        console.log(this.model);
        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: 'Edit profile',
            rightButtons: ['save']
        });
        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.listenTo(headerView, 'save:click', () => this.ui.form.submit());
        this.header.show(headerView);

        const avatarImageInput = new ImageInput({
            model: this.model,
            modelKey: 'avatar'
        });

        this.listenTo(avatarImageInput, 'loading', () => this.showLoading());
        this.listenTo(avatarImageInput, 'loaded loadfailed', () => this.hideLoading());

        this.listenTo(avatarImageInput, 'change', avatar => {
            if (avatar instanceof Blob || _.isString(avatar)) {
                this.showLoading();
                this.trigger('disable');

                ImageInputHelpers.upload(avatar, 'av')
                    .then(uploadedFile => {
                        this.viewModel.set('uploadedFile', uploadedFile);
                        if (uploadedFile && uploadedFile.full) {
                            this.model.set({
                                avatar: uploadedFile.full,
                                avatarThumb: uploadedFile.full
                            });
                        }
                        this.hideLoading();
                        this.trigger('enable');

                    })
                    .catch(e => {
                        this.hideLoading();
                        this.trigger('enable');
                        this.showError(e);
                    })
            }
        });
        this.avatar.show(avatarImageInput);

        let footerView = new FooterView({
            buttons: [{
                id: 'cancel',
                text: 'CANCEL',
                icn: 'empty'
            }, {
                id: 'save',
                text: 'SAVE CHANGES',
                icn: 'empty'
            }]
        });
        this.listenTo(footerView, 'cancel:click', () => this.cancel());
        this.listenTo(footerView, 'save:click', () => this.ui.form.submit());
        this.footer.show(footerView);

        this.$el.enhanceWithin();
    },

    onShow() {
        this.model.set(LoginedUserHandler.getLoginedUser());
        this.render();
        Page.prototype.onShow.apply(this);

        this.interceptor = e => {
            e.preventDefault();
            e.stopPropagation();
            window.removeEventListener(settings.backbutton.eventName, this.interceptor, true);
            this.cancel()
                .then(data => !data && window.addEventListener(settings.backbutton.eventName, this.interceptor, true));
        };

        window.addEventListener(settings.backbutton.eventName, this.interceptor, true);
    },

    cancel(closeWithoutSaving) {
        return this._discardChanges(closeWithoutSaving).then(confirm => {
            if (confirm) {
                window.removeEventListener(settings.backbutton.eventName, this.interceptor, true);

                this.viewModel.unset('uploadedFile');
                return Page.prototype.cancel.apply(this, arguments);
            }

            return Promise.resolve();
        });
    },

    _discardChanges(closeWithoutSaving) {
        if (!this.model.changedAttributes(LoginedUserHandler.getLoginedUser()) || closeWithoutSaving) {
            return Promise.resolve(true);
        }

        return new ConfirmModalWindow({
            title: 'Discard changes?'
        }).show();
    },

    validate() {
        this.viewModel.set({
            firstnameError: '',
            lastnameError: '',
            emailError: '',
            birthdayError: '',
            phoneError: '',
            genderError: '',
        });

        Server.callServer({
            url: settings.host + settings.serv_user.change,
            type: "POST",
            data: _.extend(_.omit(this.model.toJSON({ computed: true }), ['password']), {
                mode: 'true',
                viewanonym: this.model.get('settings').viewanonym,
                viewunverified: this.model.get('settings').viewunverified
            }),
            success: () => this.trigger('enable'),
            error: this.onServerError.bind(this)
        });
    },

    onSubmit(e) {
        e.preventDefault();
        this._saveData();
        return false;
    },

    onServerSuccess(user) {
        this.trigger('enable');
        this.hideLoading();

        //loginedUser = user;
        this.model.set(user);
        let loginedUser = {};
        loginedUser.id = user.id;
        loginedUser.email = user.email;
        loginedUser.avatar = user.avatar;
        loginedUser.avatarThumb = user.avatarThumb;
        loginedUser.firstname = user.firstname;
        loginedUser.lastname = user.lastname;
        loginedUser.birthday = user.birthday;
        loginedUser.phone = user.phone;
        loginedUser.gender = user.gender;

        loginedUser.settings = {
            viewanonym: Boolean(user.viewanonym),
            viewunverified: Boolean(user.viewunverified)
        };

        LoginedUserHandler.setAccessToken(user.access_token);
        LoginedUserHandler.setLoginedUser(loginedUser);

        this.cancel(true);
    },

    onServerError(jqXHR, textStatus, errorThrown) {
        this.trigger('enable');
        this.hideLoading();

        if (jqXHR.status == 422) { // Fields validation error
            _(jqXHR.responseJSON).each(o => {
                switch (o.field) {
                    case 'firstname':
                        this.viewModel.set({ firstnameError: o.message });
                        break;
                    case 'lastname':
                        this.viewModel.set({ lastnameError: o.message });
                        break;
                    case 'email':
                        this.viewModel.set({ emailError: o.message });
                        break;
                    case 'birthday':
                        this.viewModel.set({ birthdayError: o.message });
                        break;
                    case 'phone':
                        this.viewModel.set({ phoneError: o.message });
                        break;
                    case 'gender':
                        this.viewModel.set({ genderError: o.message });
                        break;
                }
            });
        } else { // Other error
            this.showError(jqXHR, textStatus, errorThrown);
        }
    },

    _prepareAvatar() {
        return new Promise((resolve, reject) => {
            if (this.viewModel.get('uploadedFile')) {
                const form = new FormData();
                form.append('avatar', this.viewModel.get('uploadedFile').id);

                B2CUser.server_api_avatarset(form, data => {
                        resolve(data);
                    },
                    (jqXHR, textStatus, errorThrown) => {
                        reject(jqXHR);
                    }
                );
            } else {
                resolve();
            }
        });
    },

    _saveData() {
        this.showLoading();
        this.trigger('disable');

        this._prepareAvatar()
            .then((data) => {
                if (data) {
                    this.model.set({
                        avatar: data.avatar,
                        avatarThumb: data.avatarThumb
                    }, { silent: true });
                }

                Server.callServer({
                    url: settings.host + settings.serv_user.change,
                    type: "POST",
                    data: _.extend(this.model.toJSON({ computed: true }), {
                        mode: 'false',
                        viewanonym: this.model.get('settings').viewanonym,
                        viewunverified: this.model.get('settings').viewunverified
                    }),
                    success: data => this.onServerSuccess(data),
                    error: this.onServerError.bind(this)
                });
            })
            .catch(error => this.showError(error));
    }
});

const PasswordChangeWindow = Window.extend({
    template: passwordChangeTemplate,
    className: 'password-change-window',

    ui: {
        form: '[data-js-form]',
        currentPassword: '[data-js-current]',
        currentPasswordError: '[data-js-current-error]',
        currentPasswordGroup: '[data-js-current-group]',
        newPassword: '[data-js-new]',
        newPasswordError: '[data-js-new-error]',
        newPasswordGroup: '[data-js-new-group]',
        repeatPassword: '[data-js-repeat]',
        repeatPasswordError: '[data-js-repeat-error]',
        repeatPasswordGroup: '[data-js-repeat-group]',
    },

    bindings: {
        '@ui.form': 'disabled:disabled',
        '@ui.currentPassword': 'value:current_password',
        '@ui.currentPasswordError': 'text:currentPasswordError',
        '@ui.currentPasswordGroup': 'classes:{error:currentPasswordError}',
        '@ui.newPassword': 'value:new_password',
        '@ui.newPasswordError': 'text:newPasswordError',
        '@ui.newPasswordGroup': 'classes:{error:newPasswordError}',
        '@ui.repeatPassword': 'value:repeat_password',
        '@ui.repeatPasswordError': 'text:repeatPasswordError',
        '@ui.repeatPasswordGroup': 'classes:{error:repeatPasswordError}',
    },

    events: {
        'submit @ui.form': 'onSubmit',
    },

    initialize() {
        if (!this.model.has('current_password')) this.model.set({ current_password: '' });
        if (!this.model.has('new_password')) this.model.set({ new_password: '' });
        if (!this.model.has('repeat_password')) this.model.set({ repeat_password: '' });

        this.viewModel = new ViewModel({
            currentPasswordError: '',
            newPasswordError: '',
            repeatPasswordError: '',
        });

        this.viewModel.listenTo(this.model, 'change', _.debounce(() => this.validate(), 500));
    },

    onRender() {
        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: 'Change password',
        })
        this.listenTo(headerView, 'back:click', () => this.close());
        this.header.show(headerView);

        let footerView = new FooterView({
            buttons: [{
                id: 'cancel',
                text: 'CANCEL',
                icn: 'empty'
            }, {
                id: 'save',
                text: 'CHANGE PASSWORD',
                icn: 'empty'
            }]
        });
        this.listenTo(footerView, 'cancel:click', () => this.close());
        this.listenTo(footerView, 'save:click', () => this.ui.form.submit());
        this.footer.show(footerView);
    },

    onSubmit(e) {
        e.preventDefault();

        if (this.validate(true)) {
            this.trigger('disable');
            Server.callServer({
                url: settings.host + settings.serv_user.change,
                type: "POST",
                data: {
                    mode: 'false',
                    id: this.model.id,
                    email: this.model.get('email'),
                    current_password: this.model.get('current_password'),
                    new_password: this.model.get('new_password')
                },
                success: user => {
                    this.trigger('enable');
                    LoginedUserHandler.setAccessToken(user.access_token);
                    this.close();
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    this.trigger('enable');
                    if (jqXHR.status == 422) { // Fields validation error
                        _(jqXHR.responseJSON).each(o => {
                            switch (o.field) {
                                case 'current_password':
                                    this.viewModel.set({ currentPasswordError: o.message });
                                    break;
                                case 'new_password':
                                    this.viewModel.set({ newPasswordError: o.message });
                                    break;
                            }
                        });
                    } else { // Other error
                        this.showError(jqXHR, textStatus, errorThrown);
                    }
                },
            });
        }

        return false;
    },

    validate(includeEmptyValues) {
        this.viewModel.set({
            currentPasswordError: '',
            newPasswordError: '',
            repeatPasswordError: '',
        });

        const newPassword = this.model.get('new_password'),
            repeatPassword = this.model.get('repeat_password');

        if (newPassword && repeatPassword) {
            if (newPassword != repeatPassword) {
                this.viewModel.set({ repeatPasswordError: "Not equal" });
                return false;
            }
        } else if (includeEmptyValues) {
            if (!newPassword) {
                this.viewModel.set({ newPasswordError: "Fill password" });
            }
            if (!repeatPassword) {
                this.viewModel.set({ repeatPasswordError: "Fill password" });
            }
            return false;
        }

        return true;
    },
});


export default ProfileEditorPage;
