/**
 * Created by Alex on 05.03.2016.
 */

function MyGoogleMap(options) {
    this.options = _.defaults(options, {
        default_zoom: 16,
        default_center_latitude: '39.74716760',
        default_center_longitude: '-75.54998970'
    });

    this.placeMyFormat = {
        premise: '',
        street_number: '',
        route: '',
        locality: '',
        administrative_area_level_1: '',
        country: '',
        postal_code: ''
    };

    this.$address = options.jqGoogleMapAutocompleteInput;

    this.$lat = options.jqLatitudeInput;
    this.$lng = options.jqLongitudeInput;
    this.$addressComponents = options.htmlAdrInputFields;

    this._initialize();
    this._initAutocomplete();
}

MyGoogleMap.prototype = {
    componentForm: {
        premise: 'long_name',
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'long_name',
        //country: 'long_name',
        country: 'short_name',
        postal_code: 'short_name'
    },

    _initAutocomplete: function () {
        this.google_map_autocomplete = new google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(this.$address.get(0)),
            { types: ['geocode'] });

        var deferredAutocompleteForcer = _.debounce((function (force) {

            if (force) {
                var stringAddress = this.$address.val();

                if (!stringAddress) {
                    this.setGoogleMapAutocompleteInput(this.placeMyFormat);
                } else {
                    var activeElement = document.activeElement;
                    var e = $.Event('keydown');
                    e.keyCode = 40;

                    try {
                        this.$address.focus();
                        google.maps.event.trigger(this.$address[0], 'keydown', e);
                    } catch (error) {
                        console.error('google.maps.event.trigger() ', this.$address[0], 'code:', 40, 'error: ', error);
                    }

                    if (stringAddress != this.$address.val()) {
                        try {
                            var e = $.Event('keydown');
                            e.keyCode = 13;

                            google.maps.event.trigger(this.$address[0], 'keydown', e);
                        } catch (e) {
                            console.error('google.maps.event.trigger() ', this.$address[0], 'code:', 13, 'error: ', e);
                        }
                    } else {
                        this.setGoogleMapAutocompleteInput(this.placeMyFormat);
                    }

                    if (document.activeElement) {
                        document.activeElement.blur();
                    }

                    if (activeElement) {
                        $(activeElement).focus();
                    }
                }
            }
        }).bind(this), 500);

        this.google_map_autocomplete.addListener('place_changed', (function () {
            deferredAutocompleteForcer(false);
            this._onAutocomplete()
        }).bind(this));

        this.$address.on('blur', function () {
            deferredAutocompleteForcer(true);
        });

        this.$address.on('change', function () {
            deferredAutocompleteForcer(true);
        });

        this.$address.on('focus', function () {
            deferredAutocompleteForcer(false);
        });

        this._onAutocomplete();
    },

    _onAutocomplete: function () {
        var place = this.google_map_autocomplete.getPlace();

        if (!place) {
            return;
        }

        if (!place.address_components) {
            var stringAddress = this.$address.val();

            if (stringAddress) {
                var activeElement = document.activeElement;
                var e = $.Event('keydown');
                e.keyCode = 40;

                try {
                    this.$address.focus();
                    google.maps.event.trigger(this.$address[0], 'keydown', e);
                } catch (e) {
                    console.error('google.maps.event.trigger() ', 'code:', 40, 'error: ', e);
                }

                if (stringAddress !== this.$address.val()) {
                    var e = new Event('keydown');
                    e.keyCode = 13;

                    try {
                        google.maps.event.trigger(this.$address[0], 'keydown', e);
                    } catch (e) {
                        console.error('google.maps.event.trigger() ', this.$address[0], 'code:', 13, 'error: ', e);
                    }
                } else {
                    this.setGoogleMapAutocompleteInput(this.placeMyFormat);
                }

                if (document.activeElement) {
                    document.activeElement.blur();
                }

                if (activeElement) {
                    $(activeElement).focus();
                }
            }
            return;
        }

        this.latLng = new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());

        this.map.setZoom(17);
        this.map.setCenter(this.latLng);

        this.fillInAddressUsePlace(place, true);
    },

    fillInAddressUsePlace: function (place, updateLatLng) {
        _.each(this.$addressComponents, function (field) {
            field.val('');
        });

        if (updateLatLng) {
            var latitude = place.geometry.location.lat();
            var longitude = place.geometry.location.lng();

            this.$lat.val(latitude);
            this.$lng.val(longitude);

            this.placeMyFormat = {
                latitude: latitude,
                longitude: longitude,
                premise: '',
                street_number: '',
                route: '',
                locality: '',
                administrative_area_level_1: '',
                country: '',
                postal_code: ''
            };
        } else {
            this.placeMyFormat = {
                latitude: this.placeMyFormat.latitude,
                longitude: this.placeMyFormat.longitude,
                premise: '',
                street_number: '',
                route: '',
                locality: '',
                administrative_area_level_1: '',
                country: '',
                postal_code: ''
            };
        }

        var sublocality_level_1;
        _.each(place.address_components, function (addressComponent) {
            var addressType = addressComponent.types[0];

            if (addressType == 'sublocality_level_1')
                sublocality_level_1 = addressComponent['long_name'];

            if (this.componentForm[addressType]) {
                var val = addressComponent[this.componentForm[addressType]];
                var input = this.$addressComponents[addressType];
                if (input) {
                    input.val(val);
                }
                this.placeMyFormat[addressType] = val;
            }
        }, this);

        if (this.$addressComponents['locality'].val() == '') {
            this.$addressComponents['locality'].val(sublocality_level_1);
        }

        this.setGoogleMapAutocompleteInput(this.placeMyFormat);

        if (this.options.onCenterChanged && updateLatLng) {
            this.options.onCenterChanged(this.placeMyFormat);
        }
    },

    setGoogleMapAutocompleteInput: function (placeMyFormat) {
        var value = [];

        if (placeMyFormat.premise)
            value.push(placeMyFormat.premise);

        if (placeMyFormat.route)
            value.push(placeMyFormat.route);

        if (placeMyFormat.street_number)
            value.push(placeMyFormat.street_number);

        if (placeMyFormat.locality)
            value.push(placeMyFormat.locality);

        if (placeMyFormat.administrative_area_level_1)
            value.push(placeMyFormat.administrative_area_level_1);

        if (placeMyFormat.country)
            value.push(placeMyFormat.country);

        this.$address.val(value.join(', '));
    },

    // [START region_geolocation]
    // Bias the google_map_autocomplete object to the user's geographical location,
    // as supplied by the browser's 'navigator.geolocation' object.
    geolocate: function () {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                var geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                var circle = new google.maps.Circle({
                    center: geolocation,
                    radius: position.coords.accuracy
                });
                this.google_map_autocomplete.setBounds(circle.getBounds());
            });
        }
    },
    // [END region_geolocation]

    _initialize: function () {
        var myOptions = {
            zoom: this.options.default_zoom,
            center: new google.maps.LatLng(this.options.default_center_latitude, this.options.default_center_longitude),
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        this.map = new google.maps.Map(this.options.jqGoogleMapCanvas.get(0), myOptions);
        this.bounds = new google.maps.LatLngBounds();
        this.googleEvent = google.maps.event;
        this.geocoder = new google.maps.Geocoder();
        this.reverseGeocodedLast = new Date();
        this.centerChangedLast = new Date();

        setInterval((function () {
            if ((new Date()).getSeconds() - this.centerChangedLast.getSeconds() > 1) {
                if (this.reverseGeocodedLast.getTime() < this.centerChangedLast.getTime())
                    this.reverseGeocode();
            }
        }).bind(this), 1000);

        google.maps.event.addListener(this.map, 'center_changed', this.centerChanged.bind(this));
        google.maps.event.addDomListener(this.options.jqGoogleMapCrosshair.get(0), 'dblclick', (function () {

            this.map.setZoom(this.map.getZoom() + 1);
        }).bind(this));

        this.centerChanged();

        google.maps.event.addListenerOnce(this.map, 'idle', (function () {

            google.maps.event.trigger(this.map, 'resize');
        }).bind(this));
    },

    getCenterLatLngText: function () {
        return '(' + this.map.getCenter().lat() + ', ' + this.map.getCenter().lng() + ')';
    },

    centerChanged: function () {
        var latlng = this.map.getCenter();

        if (window.DEBUG) {
            console.debug('MyGoogleMap.centerChanged()', latlng.lat(), latlng.lng());
        }

        this.centerChangedLast = new Date();

        this.placeMyFormat.latitude = latlng.lat();
        this.placeMyFormat.longitude = latlng.lng();

        if (this.options.onCenterChanged) {
            this.options.onCenterChanged(this.placeMyFormat);
        }

        this.currentReverseGeocodeResponse = null;
    },

    reverseGeocode: function () {
        this.reverseGeocodedLast = new Date();
        this.geocoder.geocode({ latLng: this.map.getCenter() }, this.reverseGeocodeResult.bind(this));
    },

    reverseGeocodeResult: function (results, status) {
        this.currentReverseGeocodeResponse = results;
        if (status == 'OK') {
            if (results.length == 0) {
            } else {
                this.fillInAddressUsePlace(results[0]);
            }
        } else {
        }
    },

    refresh: function () {
        var latlng = this.map.getCenter();

        if (window.DEBUG) {
            console.debug('MyGoogleMap.refresh()', latlng.lat(), latlng.lng());
        }

        google.maps.event.trigger(this.map, 'resize');
        this.map.setCenter(latlng);
    },

    setCenter: function (lt, lg, autoFillAutocomplete) {
        lt = Number(lt);
        lg = Number(lg);
        var latlng = new google.maps.LatLng(lt, lg);
        this.map.setCenter(latlng);
        if (autoFillAutocomplete) {
            this._onAutocomplete();
        }
    },

    clear: function () {
        this.placeMyFormat = {
            premise: '',
            street_number: '',
            route: '',
            locality: '',
            administrative_area_level_1: '',
            country: '',
            postal_code: ''
        };

        _.each(this.$addressComponents, function (field) {
            field.val('');
        });

        this.$address.val('');
    },

    getAddress: function () {
        return $.extend(true, {}, this.placeMyFormat);
    },

    setAddress: function (adr) {
        this.placeMyFormat = adr;
        this.setGoogleMapAutocompleteInput(adr);
        this.setCenter(adr.latitude, adr.longitude);
    }
}

window.MyGoogleMap = MyGoogleMap; // TODO: remove globals, use module exports
// export default MyGoogleMap;
