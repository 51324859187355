import React, { useState, useEffect } from 'react';
import './Spinner.scss';

const initialState = 'loading-spinner';

const Spinner = (props) => {
    const [className, setClassName] = useState(() => {
        return [initialState, props.className].filter(Boolean).join(' ');
    });

    useEffect(() => {
        setClassName((prev) => `${prev} open`);

        return () => {
            setClassName((prev) => prev.replace(/open/, 'closing'));
        };
    }, []);

    return <div className={className} />;
};

export default Spinner;
