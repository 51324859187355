import {
    isCategoryOutOfDate,
    isDetailItem,
    isItemOutOfDate,
    getBasicItem,
    getDetailItem,
    getMenuCartItemsAsOrderPayload
} from "store/menu/utils";
import {filterObjectIntoArray, sortBySortOrder} from "store/utils";

export const getMenuState               = state => state.menu;
export const getMenuCategoriesState     = state => getMenuState(state).categories;
export const getMenuItemsState          = state => getMenuState(state).items;
export const getMenuCatalogState        = state => getMenuState(state).catalog;
export const getMenuInfoState           = state => getMenuState(state).info;
export const getMenuItemDetailState     = state => getMenuState(state).item;
export const getMenuCartState           = state => getMenuState(state).cart;

export const getMenuCategoriesValues        = state => getMenuCategoriesState(state).values;
export const getMenuCategoriesUpdatedAt     = state => getMenuCategoriesState(state).updatedAt;
export const getMenuCategoriesItemsMap      = state => getMenuCategoriesState(state).itemsMap;
export const getMenuCategoryItemsMapById    = (state, categoryId) => getMenuCategoriesItemsMap(state)[categoryId] || null;
export const getMenuCategoriesByParentId    = (state, parentId) => {
    const categories = getMenuCategoriesValues(state);

    return filterObjectIntoArray(categories, (id, category) => {
        return Number(category.parent_id) === Number(parentId);
    });
};

export const getMenuItemsValues         = state => getMenuItemsState(state).values;
export const getMenuItemsUpdatedAt      = state => getMenuItemsState(state).updatedAt;
export const getMenuItemById            = (state, itemId) => getMenuItemsValues(state)[itemId] || null;
export const getMenuBasicItemById       = (state, itemId) => getBasicItem(getMenuItemById(state, itemId));
export const getMenuDetailItemById      = (state, itemId) => getDetailItem(getMenuItemById(state, itemId));
export const getMenuIsDetailItemById    = (state, itemId) => isDetailItem(getMenuItemById(state, itemId));
export const getMenuItemDetailId        = state => getMenuItemDetailState(state).id;

export const getMenuPlaceId = state => getMenuInfoState(state).placeId;
export const getMenuCurrency = state => getMenuInfoState(state).currency;
export const getMenuIsFood = state => getMenuInfoState(state).isFood;
export const getMenuWaiters = state => getMenuInfoState(state).waiters;
export const getMenuInfoLoading = state => getMenuInfoState(state).loading;
export const getMenuSelectedCategoryId = state => getMenuCatalogState(state).selectedCategoryId;
export const getMenuLoading = state => getMenuCatalogState(state).loading;

export const getMenuInfoPermissions = state => {
    const { isWaiter, isMenuManager, isOrderManager, isLoggedIn } = getMenuInfoState(state);
    return { isWaiter, isMenuManager, isOrderManager, isLoggedIn }
};

export const getMenuIsInitialized = state => {
    return Boolean(
        getMenuInfoState(state).initialized &&
        !getMenuLoading(state) &&
        getMenuPlaceId(state) !== null
    );
};


export const getMenuLoadingItems = state => getMenuItemsState(state).loadingItems;
export const getMenuLoadingItemsError = state => getMenuItemsState(state).loadingItemsError;


export const getMenuItems = state => {
    const selectedCategoryId = getMenuSelectedCategoryId(state);
    const itemsMap = getMenuCategoryItemsMapById(state, selectedCategoryId);

    if (_.isArray(itemsMap)) {
        return itemsMap.map(id => getMenuBasicItemById(state, id));
    }

    return [];
};

export const getMenuCategories = (state) => {
    const { isWaiter, isMenuManager, isOrderManager } = getMenuInfoPermissions(state);
    const hideAdditionCategories = Boolean(!isWaiter && !isMenuManager && !isOrderManager);

    const selectedCategoryId = getMenuSelectedCategoryId(state);
    const categories = getMenuCategoriesByParentId(state, selectedCategoryId);

    if (hideAdditionCategories) {
        return categories
            .filter(c => !c.is_addition)
            .sort(sortBySortOrder);
    }


    return categories.sort(sortBySortOrder);
};
export const getMenuCategory = (state, categoryId) => {
    if (categoryId === null) {
        return categoryId;
    }

    return getMenuCategoriesValues(state)[categoryId];
};
export const getMenuParentCategory = (state, categoryId) => {
    const category = getMenuCategory(state, categoryId);

    if (category) {
        return getMenuCategory(state, category.parent_id);
    }

    return category;
};
export const getMenuSelectedCategory     = (state) => getMenuCategory(state, getMenuSelectedCategoryId(state)) || null;
export const getMenuSelectedCategoryName = (state) => {
    const selectedCategory = getMenuSelectedCategory(state);

    if (selectedCategory) {
        return selectedCategory.name;
    }

    return selectedCategory;
};
export const getMenuSelectedParentCategory = (state) => {
    const selectedCategoryId = getMenuSelectedCategoryId(state);

    if (selectedCategoryId) {
        return getMenuParentCategory(state, selectedCategoryId);
    }

    return null;
};
const getMenuRequestError = errorText => {
    if (errorText === null) {
        return errorText;
    } else if (errorText.match(/not found/)) {
        return 'Nothing was found';
    } else if (errorText.match(/not available/)) {
        return 'Menu was not found for this place';
    }

    return 'An error has occurred. Please try again later';
};
export const getMenuError = state => getMenuRequestError(getMenuCatalogState(state).error);
export const getMenuIsErrorCritical = state => getMenuError(state) === 'Menu was not found for this place';
export const getMenuIsEditPossible  = state => getMenuInfoState(state).isMenuManager;
export const getMenuIsManager       = state => getMenuInfoState(state).isMenuManager;
export const getMenuIsWaiter        = state => getMenuInfoState(state).isWaiter;
export const getMenuIsOrderManager  = state => getMenuInfoState(state).isOrderManager;
export const getMenuIsReadyToInitialize = state => getMenuInfoState(state).isReadyToInitialize;

export const getMenuItem            = state => getMenuDetailItemById(state, getMenuItemDetailId(state));
export const getMenuLoadingItem     = state => getMenuItemDetailState(state).loading;
export const getMenuItemError       = state => getMenuRequestError(getMenuItemDetailState(state).error);
export const getMenuIsItemErrorCritical = state =>
    getMenuItemError(state) !== 'An error has occurred. Please try again later';

export const getMenuCategoryUpdatedAtById       = (state, categoryId) => getMenuCategoriesUpdatedAt(state)[categoryId] || null;
export const getMenuItemUpdatedAtById           = (state, itemId) => getMenuItemsUpdatedAt(state)[itemId] || null;
export const getMenuCategoryItemsUpdatedAtById  = (state, categoryId) => {
    const itemIds = getMenuCategoryItemsMapById(state, categoryId);

    if (itemIds !== null) {
        return itemIds.map(id => getMenuItemUpdatedAtById(state, id));
    }

    return null;
};

export const getMenuCategoryIsOutOfDate = (state, categoryId) => {
    const categoryUpdatedAt = getMenuCategoryUpdatedAtById(state, categoryId);
    return isCategoryOutOfDate(categoryUpdatedAt);
};

export const getMenuItemIsOutOfDate = (state, itemId, isDetail = true) => {
    const item = getMenuItemById(state, itemId);
    const itemUpdatedAt = getMenuItemUpdatedAtById(state, itemId);

    if (isDetail && !isDetailItem(item)) {
        return true;
    }

    return isItemOutOfDate(itemUpdatedAt);
};

export const getMenuCartItems           = state => getMenuCartState(state).items;
export const getMenuCartItemsOrder      = state => getMenuCartState(state).order;
export const getMenuCartComment         = state => getMenuCartState(state).comment;
export const getMenuCartItemByUniqueId  = (state, uniqueId, includeUniqueId) => {
    const result = getMenuCartItems(state)[uniqueId] || null;
    if (result !== null && includeUniqueId) {
        return { ...result, uniqueId };
    }

    return result;
};

export const getMenuCartItemsByItemId   = (state, itemId, includeUniqueId) => {
    const order = getMenuCartItemsOrder(state);

    return _.chain(order)
        .filter(uniqueId => {
            const item = getMenuCartItemByUniqueId(state, uniqueId);

            return item && item.id === itemId;
        })
        .map(uniqueId => getMenuCartItemByUniqueId(state, uniqueId, includeUniqueId))
        .value();
};

export const getMenuItemCartQuantity = (state, itemId) => {
    let count = null;
    let id = itemId;

    if (!itemId) {
        const item = getMenuItem(state);
        id = item && item.id;
    }

    if (id) {
        const cart = getMenuCartItemsByItemId(state, id);
        count = _(cart).reduce((memo, i) => memo + i.quantity, 0);
    }

    return count
};

export const getMenuCartItemsMap     = state => {
    const order = getMenuCartItemsOrder(state);

    return _.reduce(order, (memo, uniqueId) => {
        const item = getMenuCartItemByUniqueId(state, uniqueId);

        if (item && item.quantity > 0) {
            const value = memo[item.id];

            if (value) {
                memo[item.id] = { count: value.count + item.quantity, hasMultipleVariations: true };
            } else {
                memo[item.id] = { count: item.quantity };
            }
        }

        return memo;
    }, {});
};

export const getMenuCartSliceByItemId = (state, itemId) => {
    const cartItems = getMenuCartItemsByItemId(state, itemId, true);
    const items = {};

    for (const cartItem of cartItems) {
        if (!items[cartItem.id]) {
            items[cartItem.id] = getMenuItemById(state, cartItem.id);
        }

        for (const key in cartItem.additions) {
            if (cartItem.additions.hasOwnProperty(key)) {
                const additionId = Number(key);

                if (!items[additionId]) {
                    items[additionId] = getMenuItemById(state, additionId)
                }
            }
        }
    }

    return { cartItems, items };
};

export const getMenuCartItemsGroupedByItemId = (state) => {
    const order = getMenuCartItemsOrder(state);

    const groupedCartItems = _
        .chain(order)
        .map(uniqueId => {
            return getMenuCartItemByUniqueId(state, uniqueId, true);
        })
        .groupBy(cartItem => {
            return cartItem.id;
        })
        .value();

    return groupedCartItems;
};

export const getMenuCartDistinctRegularItems = (state) => {
    const order = getMenuCartItemsOrder(state);

    const items = _
        .chain(order)
        .reduce((memo, uniqueId) => {
            const item = getMenuCartItemByUniqueId(state, uniqueId, true);

            if (memo.indexOf(item.id) === -1) {
                memo.push(item.id);
            }

            for (const key in item.additions) {
                if (item.additions.hasOwnProperty(key)) {
                    const additionId = Number(key);

                    if (memo.indexOf(additionId) === -1) {
                        memo.push(additionId);
                    }
                }
            }

            return memo;
        }, [])
        .map(itemId => [itemId, getMenuItemById(state, itemId)])
        .object()
        .value();

    return items;
};

export const getMenuCartOrder = (state) => {
    const order = getMenuCartItemsOrder(state);
    const itemValues = getMenuCartItems(state);

    const cartItems = order.map(key => ({ ...itemValues[key] }));
    const items = getMenuCartItemsAsOrderPayload(cartItems);

    return { items };
};

export const getMenuCartTotalQuantity = state => {
    const items = getMenuCartItems(state);
    const quantity = _(items).reduce((memo, i) => i.quantity + memo, 0)

    return quantity;
};

export const getMenuWaiterById = (state, waiterId) => getMenuWaiters(state) || null;
export const getMenuWaitersAsArray = state => {
    const waiters = getMenuWaiters(state);
    return _
        .chain(waiters)
        .pairs()
        .sortBy(([id]) => -id)
        .map(([k, v]) => v)
        .value();
};
