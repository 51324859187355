import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { WindowContent, WindowFooter, WindowHeader } from 'components/window/Window';
import TextButton from 'components/UI/TextButton';
import InputGroup, { TextInputGroup } from 'components/UI/InputGroup/InputGroup';
import Footer from 'components/UI/Footer/Footer';
import ReactWindow from 'windows/ReactWindow';
import Header from 'components/UI/Header/Header';
import { BackButton } from 'components/UI/Button/Button';
import { toSortedObject } from 'store/menu/utils';
import ConfirmModalWindow from 'windows/Modal/Confirm';
import NumericInput from 'components/UI/NumericInput/NumericInput';
import './MenuTableEditor.scss';
import MenuSections from 'components/menu/MenuSections/MenuSections';
import Spinner from 'components/UI/Spinner/Spinner';
import getXHRValidationErrors from 'utils/getXHRValidationErrors';
import AjaxError from 'utils/AjaxError';
import UserEditableItems from 'components/menu/UserItem/UserEditableItems';
import UserSelectWindow from 'components/window/UserSelect/UserSelect';
import KeyboardPadding from 'components/KeyboardPadding';

const EmptyTable = { number: '', qrString: '', sectionId: null, waiters: [] };

const MenuTableEditorWaiters = (props) => {
    const { waiters = [], onRemoveItem } = props;
    const content =
        Boolean(waiters) && waiters.length > 0 ? (
            <>
                <InputGroup label="Waiters" className="input-group menu-list-input-group" />
                <UserEditableItems
                    className="menu-table-waiters menu-list-compact"
                    items={waiters}
                    displayEditButton={false}
                    onRemoveItem={onRemoveItem}
                />
            </>
        ) : null;

    return content;
};
MenuTableEditorWaiters.propTypes = {
    waiters: PropTypes.array,
    onRemoveItem: PropTypes.func,
};

const getSelectedWaiters = (waiters = [], selectedIds = []) => {
    return (
        (selectedIds &&
            selectedIds.reduce((memo, id) => {
                const w = waiters && waiters.find((w) => w.id === id);

                if (w) {
                    memo.push(w);
                }

                return memo;
            }, [])) ||
        []
    );
};

const MenuTableEditor = (props) => {
    const [loading, setLoading] = useState(false);
    const [table, setTable] = useState({ ...EmptyTable });
    const [numberError, setNumberError] = useState(null);

    const isExistingTable = useMemo(() => props.table && _.isNumber(props.table.id), [props.table]);
    const windowTitle = useMemo(() => (props.table ? 'Edit table' : 'Create table'), [props.table]);
    const hasUnsavedChanges = useMemo(
        () =>
            JSON.stringify(toSortedObject({ ...EmptyTable, sectionId: props.sectionId, ...props.table })) !==
            JSON.stringify(toSortedObject(table)),
        [props.table, props.sectionId, table]
    );
    const isValidTable = useMemo(() => table && table.number, [table]);
    const sections = useMemo(() => {
        const prevSectionId = (props.table && props.table.sectionId) || null;

        return (props.sections || []).filter(!isExistingTable ? (s) => s.id === table.sectionId : Boolean).map((s) => ({
            ...s,
            selected: s.id === table.sectionId,
            message:
                prevSectionId !== null &&
                table.sectionId !== null &&
                prevSectionId !== table.sectionId &&
                s.id === prevSectionId ? (<em>previous section</em>) : null,
        }));
    }, [isExistingTable, table.sectionId, props.sections, props.table]);
    const selectedWaiters = useMemo(() => getSelectedWaiters(props.waiters, table && table.waiters), [
        table,
        props.waiters,
    ]);

    const setTableNumber = useCallback(
        (number) => {
            setTable((table) => {
                setNumberError(null);
                return { ...table, number };
            });
        },
        [setTable, setNumberError]
    );

    const setTableSection = useCallback(
        ({ section }, isSelected) => {
            setTable((t) => ({
                ...t,
                sectionId: isSelected ? section.id : props.table ? props.table.sectionId : t.sectionId,
            }));
        },
        [setTable, props.table]
    );

    const closeDiscardingChanges = useCallback(() => {
        if (hasUnsavedChanges) {
            new ConfirmModalWindow({ title: 'Discard changes?' }).show().then((result) => {
                result && props.closeWindow();
            });
        } else {
            props.closeWindow();
        }
    }, [hasUnsavedChanges, props.closeWindow]);

    const saveTable = useCallback(async () => {
        try {
            setLoading(true);
            await props.saveTable(table);
            props.closeWindow();
        } catch (e) {
            setLoading(false);

            if (e instanceof AjaxError && e.jqXHR.status === 422) {
                const { number = null } = getXHRValidationErrors(e.jqXHR);

                if (number !== null) {
                    setNumberError(number);
                }
            } else {
                props.showError(e instanceof Error ? e.message : e);
            }
        }
    }, [table, setNumberError, props.closeWindow, props.showError, props.saveTable]);

    useEffect(() => {
        setTable((table) => ({ ...table, ...props.table }));
    }, [props.table]);

    useEffect(() => {
        if (!_.isUndefined(props.sectionId)) {
            setTable((table) => ({ ...table, sectionId: props.sectionId }));
        }
    }, [props.sectionId]);

    const updateWaiters = useCallback((waiters = []) => {
        setTable((prev) => {
            return {
                ...prev,
                waiters,
            };
        });
    }, []);

    const onRemoveWaiter = useCallback((id) => {
        setTable((prev) => {
            const waiters = (prev.waiters && prev.waiters.filter((w) => w !== id)) || [];

            return {
                ...prev,
                waiters,
            };
        });
    }, []);

    const onChangeWaiters = useCallback(async () => {
        try {
            const selectedWaitersIds = (table && table.waiters) || [];
            const newSelectedWaiters = await new UserSelectWindow({
                isModalWindow: true,
                users: props.waiters,
                selectedUserIds: selectedWaitersIds,
                title: 'Waiters',
            }).show();

            if (newSelectedWaiters) {
                updateWaiters(newSelectedWaiters);
            }
        } catch (err) {
            showError(err);
        }
    }, [table, props.waiters]);

    return (
        <>
            <WindowHeader>
                <Header>
                    <BackButton onClick={closeDiscardingChanges} />
                    <h1 className="title">{windowTitle}</h1>
                </Header>
            </WindowHeader>
            <WindowContent>
                <TextInputGroup label="Number" error={numberError}>
                    <NumericInput
                        onChange={setTableNumber}
                        value={table.number}
                        isInteger
                        disabled={isExistingTable}
                        placeholder="Enter table number"
                    />
                </TextInputGroup>
                {_.isArray(sections) && sections.length > 0 && (
                    <>
                        <InputGroup label="Section" className="input-group menu-list-input-group" />
                        <MenuSections
                            className="menu-list-compact"
                            selectable
                            onSelectionChange={setTableSection}
                            sections={sections}
                        />
                    </>
                )}
                <MenuTableEditorWaiters waiters={selectedWaiters} onRemoveItem={onRemoveWaiter} />
                <TextButton
                    onClick={onChangeWaiters}
                    className="text-button btn-empty text medium normalized border-filled with-top-margin appoint-waiters"
                >
                    Appoint waiters
                </TextButton>
            </WindowContent>
            <WindowFooter>
                <Footer>
                    <TextButton onClick={closeDiscardingChanges}>CLOSE</TextButton>
                    <TextButton
                        className={isValidTable && (!isExistingTable || hasUnsavedChanges) ? 'filled' : ''}
                        onClick={saveTable}
                        disabled={!isValidTable}
                    >
                        SAVE
                    </TextButton>
                </Footer>
            </WindowFooter>
            <KeyboardPadding />
            {loading && <Spinner />}
        </>
    );
};

const MenuTableEditorWindow = ReactWindow.extend({
    constructor: function MenuSectionEditorWindow(props) {
        const options = {
            props,
            component: MenuTableEditor,
            name: 'MenuTableEditor',
        };

        return ReactWindow.apply(this, [options]);
    },
});

export default MenuTableEditorWindow;
