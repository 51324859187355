import React from "react";
import ReactDOM from "react-dom";
import Page from './Page';
import {Model, ViewModel} from "@b2cmessenger/backbone";

const ReactPage = Page.extend({
    isReactPage: true,
    template: false,

    renderFn: null,
    component: null,

    constructor: function ReactPage(options) {
        if (!this.renderFn && !this.component) {
            throw new Error('Neither `renderFn` or `component` property of `ReactPage` is presented');
        }

        this.setStaticProps();

        this.props = new Model;
        this.viewModel = new ViewModel;

        this.debouncedRenderReactComponent = _.debounce(this.renderReactComponent.bind(this), 10);

        return Page.call(this, options);
    },

    show(props) {
        this.setProps(props);

        if (this.immediateRenderOnShow) {
            this.renderReactComponent();
        }

        return Page.prototype.show.call(this, props);
    },

    setProps(value) {
        const props = { ...this.props.toJSON(), ...value };
        this.props.set(props);
    },

    setStaticProps(value = {}) {
        this.staticProps = {
            goBack: Page.goBack,
            cancel: this.cancel.bind(this),
            show: this.show.bind(this),
            __page__: this,
            ...value
        };

        if (this.props) {
            this.props.trigger('change');
        }
    },

    renderReactComponent() {
        const Component = this.component;

        const props = {
            ...this.props.toJSON(),
            ...this.viewModel.pick(['disabled', 'isActive']),
            ...this.staticProps,
        };

        const renderFn = this.renderFn || function () {
            return <Component {...props}/>;
        };

        ReactDOM.render(renderFn(), this.el);
    },

    onAfterShow() {
        _.defer(() => {
            this.trigger('after:show');
            this.viewModel.set({ isActive: this.isActive });
        });
    },

    onBeforeHide() {
        _.defer(() => {
            this.trigger('before:hide');
            this.viewModel.set({isActive: this.isActive});
        });
    },

    onRender() {
        this.renderReactComponent();

        this.listenTo(this.props, 'change', this.debouncedRenderReactComponent);
        this.listenTo(this.viewModel, 'change', this.debouncedRenderReactComponent);

        this._mounted = true;
    },

    onDestroy() {
        if (this._mounted) {
            ReactDOM.unmountComponentAtNode(this.el);
        }
    },
});

export default ReactPage;
