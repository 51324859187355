import React from 'react';
import Modal from 'windows/Modal/Modal';

import template from './LoyaltyKioskEnter.jade';
import FooterView from 'widgets/Footer/Footer';
import {LayoutView} from '@b2cmessenger/backbone';
import {SliderGroup, SwitchInput, TextInputGroup} from "components/UI/InputGroup/InputGroup";
import './LoyaltyKioskEnter.scss'
import ReactView from "widgets/ReactView";
import {Model} from 'backbone';
import {LoyaltyKioskCheckInMethods} from "windows/Modal/LoyaltyKiosk/LoyaltyKiosk";

const LoyaltyKioskEnter = Modal.extend({
    windowName: "loyalty-kiosk-enter-modal-window",
    className: "modal-window loyalty-kiosk-enter-modal-window green-border",

    initialize() {
        this.model = new Model({
            defaultCheckInMethod: LoyaltyKioskCheckInMethods.None,
        })
    },

    onRender() {
        const contentView = new LoyaltyKioskEnterContent({ model: this.model });
        const footerView = new FooterView({
            buttons: [
                { id: "close", text: "CANCEL", icn: "empty" },
                { id: "enter", text: "ENTER", icn: "empty" }
            ]
        });

        this.listenTo(footerView, 'close:click', () => this.close(null));
        this.listenTo(footerView, 'enter:click', () => this.close(this.model.toJSON()));

        this.content.show(contentView);
        this.footer.show(footerView);
    }
});

@LayoutView.properties({
    template,
    className: "widget loyalty-kiosk-enter-content-widget",
    regions: {
        kioskAdditional: '[data-js-kiosk-additional]',
    },
})
class LoyaltyKioskEnterContent extends LayoutView {
    onRender() {
        const contentView = new ReactView({
            className: 'loyalty-kiosk-options',
            component: LoyaltyKioskOptions,
            model: this.model,
            props: {
                onDefaultMethodChange: (value) => {
                    this.model.set({ defaultCheckInMethod: value });
                },
            },
            mountEvent: 'onRender'
        });

        this.kioskAdditional.show(contentView);
    }
}

const LoyaltyKioskOptions = (props) => {
    const onChange = React.useCallback(value => {
        props.onDefaultMethodChange(value ?
            LoyaltyKioskCheckInMethods.QrCodeScanner :
            LoyaltyKioskCheckInMethods.None
        );
    }, [props.onDefaultMethodChange]);

    const info = React.useMemo(() => {
        if (props.defaultCheckInMethod === LoyaltyKioskCheckInMethods.QrCodeScanner) {
            return '`Scan QR` option will be pre-selected on check-in screen. Other check-in options are also available.'
        }
    }, [props.defaultCheckInMethod]);

    const changeOptionsMessage = React.useMemo(() => {
        if (props.defaultCheckInMethod !== LoyaltyKioskCheckInMethods.None) {
            return (
                <TextInputGroup info='To change these options it`s required to restart kiosk mode.' />
            );
        }

        return null;
    }, [props.defaultCheckInMethod]);

    const value = React.useMemo(
        () => props.defaultCheckInMethod === LoyaltyKioskCheckInMethods.QrCodeScanner,
        [props.defaultCheckInMethod]
    );

    return (
        <React.Fragment>
            <h4>Loyalty Kiosk options:</h4>
            <SliderGroup caption="Enable loyalty card check-in by default" info={info}
                         className='default-method-qr-scanner normalized'
                         disabled={props.disabled}>
                 <SwitchInput value={value} onChange={onChange} disabled={props.disabled} />
            </SliderGroup>
            {changeOptionsMessage}
        </React.Fragment>
    )
}

export default LoyaltyKioskEnter;