var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (custom_quantity_allowed, name, quantity, transformationAllowed, transformation_ratio, transformation_type, type) {pug_html = pug_html + "\u003Cdiv class=\"title\"\u003E\u003Cdiv class=\"name\"\u003E";
if (type == 3) {
pug_html = pug_html + "\u003Cspan\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
else {
if (custom_quantity_allowed) {
if (transformationAllowed && transformation_type) {
pug_html = pug_html + "\u003Cspan class=\"qty\"\u003E";
switch (transformation_type){
case 1:
pug_html = pug_html + (pug.escape(null == (pug_interp = transformation_ratio / 100) ? "" : pug_interp)) + "%  ";
  break;
case 2:
pug_html = pug_html + "$" + (pug.escape(null == (pug_interp = transformation_ratio / 100) ? "" : pug_interp)) + "  ";
  break;
case 3:
pug_html = pug_html + "x" + (pug.escape(null == (pug_interp = transformation_ratio / 100) ? "" : pug_interp)) + "  ";
  break;
case 4:
pug_html = pug_html + (pug.escape(null == (pug_interp = transformation_ratio) ? "" : pug_interp)) + " coins  ";
  break;
}
pug_html = pug_html + "\u003C\u002Fspan\u003E";
if (type == 1) {
pug_html = pug_html + "\u003Cspan class=\"ion-ios-repeat\"\u003E Coins\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"ion-ios-repeat\"\u003E " + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
}
else {
pug_html = pug_html + "[\u003Cspan class=\"qty\"\u003E" + (pug.escape(null == (pug_interp = quantity) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E]  ";
if (type == 1) {
pug_html = pug_html + "\u003Cspan\u003Ex Coins\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan\u003Ex " + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
}
}
else {
pug_html = pug_html + "\u003Cspan class=\"qty\"\u003E" + (pug.escape(null == (pug_interp = quantity) ? "" : pug_interp)) + "  \u003C\u002Fspan\u003E";
if (type == 1) {
pug_html = pug_html + "\u003Cspan\u003Ex Coins\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan\u003Ex " + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"options\"\u003E\u003Cspan class=\"hidden-menu\" data-js-hidden-menu\u003E\u003Cbutton data-js-edit\u003E\u003Ci class=\"icon-shape\"\u003E\u003C\u002Fi\u003E\u003Cspan\u003EEdit\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Fspan\u003E\u003Cbutton class=\"more\" data-js-more-button\u003E\u003Ci class=\"ion-ios-more-outline\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"image\" data-js-image\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"validuntil\" data-js-validuntil\u003E\u003Cspan class=\"label\"\u003EValid until:\u003C\u002Fspan\u003E\u003Cspan class=\"date\" data-js-date\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"time\" data-js-time\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";}.call(this,"custom_quantity_allowed" in locals_for_with?locals_for_with.custom_quantity_allowed:typeof custom_quantity_allowed!=="undefined"?custom_quantity_allowed:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined,"quantity" in locals_for_with?locals_for_with.quantity:typeof quantity!=="undefined"?quantity:undefined,"transformationAllowed" in locals_for_with?locals_for_with.transformationAllowed:typeof transformationAllowed!=="undefined"?transformationAllowed:undefined,"transformation_ratio" in locals_for_with?locals_for_with.transformation_ratio:typeof transformation_ratio!=="undefined"?transformation_ratio:undefined,"transformation_type" in locals_for_with?locals_for_with.transformation_type:typeof transformation_type!=="undefined"?transformation_type:undefined,"type" in locals_for_with?locals_for_with.type:typeof type!=="undefined"?type:undefined));;return pug_html;};
module.exports = template;