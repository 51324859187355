import React from 'react';

import 'widgets/HeaderWithTabs/HeaderWithTabs.scss';
import Tabs, { Tab } from 'components/UI/Tabs/Tabs';

const HeaderWithTabs = (props) => {
    const [activeTabId, setActiveTabId] = React.useState(null);

    React.useEffect(() => {
        setActiveTabId(props.activeTab);
    }, [props.activeTab]);

    const setActiveTab = React.useCallback(
        (id) => {
            setActiveTabId((prevId) => {
                const value = prevId === id && props.resetActive ? null : id;
                props.setActiveTab(value);

                return value;
            });
        },
        [setActiveTabId, props.resetActive, props.setActiveTab]
    );

    return (
        <div className="widget header-with-tabs">
            <div className="header-top">{props.children}</div>
            <Tabs className="header-tabs">
                {props.tabs.map(({ id, icon, title }) => (
                    <Tab
                        className="header-tab"
                        iconClassName="header-tab-icon"
                        titleClassName="header-tab-title"
                        active={id === activeTabId}
                        key={id}
                        id={id}
                        icon={icon}
                        title={title}
                        disabled={props.disabled}
                        onClick={setActiveTab}
                    />
                ))}
            </Tabs>
        </div>
    );
};

export default HeaderWithTabs;
