import { Trait, Model, ViewModel } from '@b2cmessenger/backbone';

const traitId = Symbol("ContainerWithNotificationsToRead");

class ContainerWithNotiicationsToRead extends Trait {
    constructor(options, _traitId) {
        super(_traitId || traitId);

        const trait = this;

        _.extend(this.options, _.defaults(options || (options = {}), {
            container() { return this.$el; },
            element() { return trait.container.children(); },
            initializeOnAttach: false,
            notificationReadClassName: 'trait-container-with-notifications-to-read--notification-read',
            notificationElSelector: options.notificationElSelector || '[data-js-unread-notification-id]'
        }));

        _.extend(this.methods || (this.methods = {}),
            {
                onUnreadNotificationElIsVisible: this.onUnreadNotificationElIsVisible,
                onRender: this.onRender
            },
            this.options.initializeOnAttach ?
                {
                    onAttach: this.onRenderOrAttach
                }
                : {
                    onRender: this.onRenderOrAttach
                }
        );

        this._resultOption('container', { allowStringAsSelector: true });

        this.checkNotificationsInViewportDebounced = _.debounce(this._checkNotificationsInViewport.bind(this), 300, true);
    };

    initialize(initialize, trait, ...args) {
        if (!this.viewModel) {
            this.viewModel = new ViewModel;
        }

        this.listenTo(this.viewModel, 'check:notifications:in:viewport', () => {
            trait && trait.checkNotificationsInViewportDebounced()
        });

        return initialize.apply(this, args);
    }

    _checkNotificationsInViewport(e, trait) {
        const { top:containerTop, height:containerHeight } = this.container[0].getBoundingClientRect();
        const els = [];
        this.container
            .find(this.options.notificationElSelector)
            .each((i, el) => {
                const { top, height } = el.getBoundingClientRect();

                const isOvelappingWholeContainer = top <= containerTop && height >= containerHeight;
                const isInContainerViewport = top >= containerTop && height <= containerHeight &&
                    top + height <= containerTop + containerHeight;

                if (isOvelappingWholeContainer || isInContainerViewport) {
                    els.push(el);
                }
            });

        if (els.length) {
            this.o.triggerMethod('unread:notification:el:is:visible', els);
        }
    }

    onUnreadNotificationElIsVisible(f, ...args) {
        return f && f.apply(this, args);
    }

    onRenderOrAttach(f, trait) {
        f && f.apply(this);

        trait.container.attr('data-js-trait-container-with-notifications-to-read', '');
        trait.container.addClass('trait-container-with-notifications-to-read');

        trait.container.on('scrollstop', trait.checkNotificationsInViewportDebounced);
        trait.container.on('click', trait.checkNotificationsInViewportDebounced);
    }
}

export default ContainerWithNotiicationsToRead;
