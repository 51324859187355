import { ViewModel, ItemView } from '@b2cmessenger/backbone';
import Modal from './Modal';
import FooterView from 'widgets/Footer/Footer';
import QrCodeModel from 'models/QrCodeModel';
import qrCodeGenerator from 'utils/qrCodeGenerator';

import template from './GiftQr.jade';
import './GiftQr.scss';

const GiftQrModal = Modal.extend({
    options: {
        model: null,
        qty: undefined
    },

    setQuantity(qty) {
        if (this.content.currentView) {
            this.content.currentView.setQuantity(qty)
        } else {
            this.options.qty = qty;
        }
    },

    windowName: "gift-qr-modal-window",
    className: "modal-window gift-qr-modal-window",

    onRender() {
        this.content.show(new ContentView({
            model: this.model,
        }));

        const footerView = new FooterView({
            buttons: [{ id: 'close', icn: 'empty', text: 'CLOSE' }]
        });

        this.listenTo(footerView, 'close:click', this.cancel.bind(this));
        this.footer.show(footerView);
    },

    cancel() {
        this.close(this.model);
    }
});

const ContentView = ItemView.extend({
    options: {
        model: null,
        viewModel: null,
        qty: undefined
    },

    setQuantity(qty) {
        this.viewModel.set({ qty });
    },

    className: "gift-qr-modal-content-widget widget",
    template,

    ui: {
        title: '[data-js-title]',
        qrImage: '[data-js-qr-image]',
        qrPlaceholder: '[data-js-qr-placeholder]',
        qrPlaceholderMessage: '[data-js-qr-placeholder-message]',
        qty: '[data-js-qty]',
    },

    computeds: {
        qtyInputIsEmpty: {
            deps: ['qty'],
            get: qty => !qty
        },
        c_qty: {
            deps: ['qty', '_recalculate_qty'],
            get: qty => qty || '',
            set(val) {
                this.viewModel.set({
                    qty: Number(val) || 0,
                    _recalculate_qty: _.uniqueId()
                });
            }
        }
    },

    bindings: {
        '@ui.qrImage': 'attr:{src:qrUrl},classes:{hidden:any(not(qrUrl),qtyInputIsEmpty)}',
        '@ui.qrPlaceholder': 'classes:{hidden:all(qrUrl,not(qtyInputIsEmpty)),error:error,empty:qtyInputIsEmpty}',
        '@ui.qrPlaceholderMessage': 'text:error',
        '@ui.qty': 'value:c_qty'
    },

    events: {
        'click @ui.title'() {
            if (this.ui.qty[0] !== document.activeElement) {
                this.ui.qty.focus();
                this.ui.qty[0].setSelectionRange(0, 9999);
            }
        },
        'keyup @ui.qty'() {
            if (this.ui.qty[0] === document.activeElement) {
                this.ui.qty.change();
            }
        },
        'focus @ui.qty'() {
            try {
                this.ui.qty[0].setSelectionRange(0, 9999);
            } catch (e) { }
        }
    },

    initialize() {
        if (!this.viewModel) {
            this.viewModel = new ViewModel;
        }

        this.viewModel.set({
            qrUrl: '',
            error: '',
            _qty: Number(this.options.qty) || 1,
            totalQty: this.model.get('totalQty'),
            _recalculate_qty: false,
        });

        this.viewModel.listenTo(this.model, 'change:totalQty', (m, totalQty) => this.viewModel.set({ totalQty: totalQty }))

        this.viewModel.addComputed('qty', {
            deps: ['_qty', 'totalQty'],
            get: (_qty, totalQty) => Math.max(0, Math.min(Math.abs(Number(_qty)), Number(totalQty) || 0)) || 0,
            set: (val) => _.create(null, {
                _qty: Number(val) || 0,
            })
        });

        const updateQr = _.debounce(this._updateQr.bind(this), 10);
        this.listenTo(this.viewModel, 'change:qty', updateQr);
        this.listenTo(this.model, 'change:user_id change:gifts change:brand_id change:template', updateQr);

        this._updateQr();
    },

    _updateQr() {
        if (this.viewModel.get('qty')) {
            if (!this._updateQrQueue) {
                this._updateQrQueue = Promise.resolve();
            }

            this._updateQrQueue =
                this._updateQrQueue
                    .then(() => {
                        this.viewModel.set({
                            qrUrl: '',
                            error: '',
                        });

                        let userId = this.model.get('user_id');

                        if (!userId) {
                            const gifts = this.model.get('gifts');
                            if (gifts && gifts.length) {
                                userId = gifts[0].user_id;
                            }
                        }

                        return userId;
                    })
                    .then(userId => {
                        return qrCodeGenerator(new QrCodeModel({
                            type: QrCodeModel.Type.GiftWriteOff,
                            userId,
                            brandId: this.model.get('brand_id'),
                            giftTemplateId: this.model.get('template').id,
                            giftWriteoffQuantity: this.viewModel.get('qty')
                        }));
                    })
                    .then(url => this.viewModel.set({
                        qrUrl: url,
                    }))
                    .catch(e => {
                        this.viewModel.set({
                            error: String(e),
                        });
////////////////////////////////////
////////////////////////////////
/////////////////////////////////
                    });
        }
    }
});

export default GiftQrModal;
