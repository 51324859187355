import { Required, LayoutView } from "@b2cmessenger/backbone";
import Window from 'windows/Window';
import HeaderView from "widgets/Header/Header";
import template from './ReservationOptions.jade';
import './ReservationOptions.scss';

const CHILDREN_STEP = 1;
const MIN_CHILDREN_COUNT = 0;
const MAX_CHILDREN_COUNT = Number.MAX_SAFE_INTEGER;
const SMOKING_ZONE = {
    yes: 'yes',
    no: 'no',
    any: null
};

const ReservationOptionsWindow = Window.extend({
    options: {
        viewModel: Required
    },

    windowName: 'reservation-options-window',
    className: 'reservation-options-window',

    initialize() {
        this.viewModel = this.options.viewModel;
    },

    onRender() {
        const contentView = new ReservationOptions({
            viewModel: this.viewModel
        });
        this.content.show(contentView);

        const headerView = new HeaderView({
            leftButtons: ['back'],
            title: 'Reservation options'
        });
        this.listenTo(headerView, 'back:click', view => this.cancel());

        this.header.show(headerView);
    },

    cancel() {
        this.close(false);
    },
});

@LayoutView.options({
    model: Required,
    viewModel: Required
})
@LayoutView.properties({
    template,
    className: 'widget reservation-options-widget',

    ui: {
        childrenPlusBtn: '[data-js-prop-children] [data-js-plus]',
        childrenMinusBtn: '[data-js-prop-children] [data-js-minus]',
        childrenInput: '[data-js-prop-children] [data-js-children-input]',
        smokingRadio: '[data-js-smoking]'
    },

    events: {
        'click @ui.childrenPlusBtn'() {
            const value = this.viewModel.get('vm_opt_children_count') + CHILDREN_STEP;
            this.viewModel.set('vm_opt_children_count',
                this._getValidNumberByInterval(value, MIN_CHILDREN_COUNT, MAX_CHILDREN_COUNT));
        },
        'click @ui.childrenMinusBtn'() {
            const value = this.viewModel.get('vm_opt_children_count') - CHILDREN_STEP;
            this.viewModel.set('vm_opt_children_count',
                this._getValidNumberByInterval(value, MIN_CHILDREN_COUNT, MAX_CHILDREN_COUNT));
        },
        'blur @ui.childrenInput'(e) {
            $(e.currentTarget).val(this.viewModel.get('vm_opt_children_count'));
        }
    },

    computeds: {
        c_ChildrenMinusBtnDisabled: {
            deps: ['vm_opt_children_count', 'disabled'],
            get: (value, disabled) => !(value) || disabled
        },
        c_ChildrenCount: {
            deps: ['vm_opt_children_count'],
            get: value => value,
            set(value) {
                this.viewModel.set('vm_opt_children_count',
                    this._getValidNumberByInterval(value, MIN_CHILDREN_COUNT, MAX_CHILDREN_COUNT));
            }
        },
        c_Smoking: {
            deps: ['vm_opt_smoking_area'],
            get: value => value || '',
            set(value) {
                switch (value) {
                    case 'yes':
                        value = SMOKING_ZONE.yes;
                        break;
                    case 'no':
                        value = SMOKING_ZONE.no;
                        break;
                    default:
                        value = SMOKING_ZONE.any;
                }

                this.viewModel.set('vm_opt_smoking_area', value);
            }
        }
    },

    bindings: {
        '@ui.childrenInput': 'value:c_ChildrenCount, disabled:disabled, attr:{readonly:disabled}',
        '@ui.childrenMinusBtn': 'disabled:c_ChildrenMinusBtnDisabled, attr:{readonly:c_ChildrenMinusBtnDisabled}',
        '@ui.childrenPlusBtn': 'disabled:disabled, attr:{readonly:disabled}',
        '@ui.smokingRadio': 'checked:c_Smoking, disabled:disabled, attr:{readonly:disabled}'
    }
})
class ReservationOptions extends LayoutView {
    _getValidNumberByInterval(value, min = 0, max = Number.MAX_SAFE_INTEGER) {
        return Math.max(min, Math.min(Number(value) || min, max));
    }
}

export default ReservationOptionsWindow;
