import _ from 'underscore';
import { Marionette } from '@b2cmessenger/backbone';

import Window from 'windows/Window';
import CategoryChooserWidget from 'widgets/Category/CategoryChooser';
import FooterView from 'widgets/Footer/Footer';

import './CategoryChooser.scss';
import HeaderView from 'widgets/Header/Header';

let CategoryChooserWindow = Window.extend({
    windowName: 'category-chooser-window',
    className: "category-chooser-window",

    initialize() {
        this._cachedIds = [];
    },

    onRender() {
        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: 'Choose categories'
        });
        this.listenTo(headerView, 'back:click', view => this.cancel());
        this.header.show(headerView);

        const categoryChooserWidget = new CategoryChooserWidget();
        this.content.show(categoryChooserWidget);

        let footerView = new FooterView({
            buttons: [{
                id: 'left',
                text: 'CANCEL',
                icn: 'empty'
            },{
                id: 'right',
                text: 'DONE',
                icn: 'empty'
            }]
        });
        this.listenTo(footerView, 'left:click', view => this.cancel());
        this.listenTo(footerView, 'right:click', view => this.close(categoryChooserWidget.getSelection()));
        this.footer.show(footerView);
    },

    onShow(catIds) {
        this._cachedIds = _.clone(catIds);
        this.content.currentView && this.content.currentView.selectCategoriesWithIds(catIds);
    },

    cancel() {
        const categoryChooserWidget = this.content.currentView;
        if(categoryChooserWidget) {
            categoryChooserWidget.selectCategoriesWithIds(this._cachedIds);
            this.close(categoryChooserWidget.getSelection());
        }
    }
});

export default CategoryChooserWindow;
