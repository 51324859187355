/**
 * Created by Alex on 11.03.2016.
 */
// B2C Toggle
(function( $ ){
    var methods = {
        init : function( options ) {

            return this.each(function(){

                var $this = $(this),
                    data = $this.data('b2ctoggle');

                if ( ! data ) {

                    data = {};
                    data.$this = $this;
                    data.options = $.extend(true, {}, $.fn.b2ctoggle.defaults, options);
                    data.$values = $this.find('.'+data.options.cl_toggle_value);
                    data.$values.click(function(event) { $.fn.b2ctoggle.onValueClick($(this), data) });

                    $.fn.b2ctoggle.disable($this, !!data.readOnly);

                    $(this).data('b2ctoggle', data);

                    if (data.options.states != null) {
                        $.fn.b2ctoggle.setValuesState(data, data.options.states);
                    }

                    if (data.options.needEqualWidth)
                        $.fn.b2ctoggle.setValuesWidthToEqual(data.$values);
                }
            });
        },
        get : function() {
            var $first = this.first();
            var data = $first.data('b2ctoggle');
            return $.fn.b2ctoggle.getValuesState(data);
        },
        set : function(states) {
            return this.each(function() {

                var $this = $(this),
                    data = $this.data('b2ctoggle');

                if (data != null) {
                    $.fn.b2ctoggle.setValuesState(data, states);
                }
            });
        },
        isset : function(valName) {
            var $first = this.first();
            var data = $first.data('b2ctoggle');

            var isNotFound = true, result = false;

            for(var i=0; i<data.$values.length && isNotFound; i++) {
                var $value = $(data.$values[i]);
                if (valName == $value.attr(data.options.attr_toggle_value)) {
                    result = $value.hasClass(data.options.cl_toggle_value_set);
                    isNotFound = false;
                }
            }

            return result;
        },
        disable: function (disabled) {
            var $this = $(this),
                data = $this.data('b2ctoggle'),
                options = data.options;

            options.readOnly = _.isUndefined(disabled) ? true : !!disabled;

            $.fn.b2ctoggle.disable($this, options.readOnly);
        },
        enable: function (enabled) {
            var $this = $(this),
                data = $this.data('b2ctoggle'),
                options = data.options;

            options.readOnly = _.isUndefined(enabled) ? false : !enabled;

            $.fn.b2ctoggle.disable($this, options.readOnly);
        },
        /**
         *
         * @returns first set value
         */
        get_set_value : function() {
            var $first = this.first();
            var data = $first.data('b2ctoggle');
            var states = $.fn.b2ctoggle.getValuesState(data);
            for(var i=0; i<states.length; i++) {
                var state = states[i];
                if (state.set)
                    return state.val;
            }
            return null;
        },
        /* destroy : function( ) {

         return this.each(function(){

         var $this = $(this),
         data = $this.data('b2ctoggle');

         // пїЅпїЅпїЅпїЅпїЅпїЅпїЅпїЅпїЅпїЅпїЅпїЅ пїЅпїЅпїЅ пїЅпїЅпїЅпїЅпїЅ!!11
         $(window).unbind('.tooltip');
         data.tooltip.remove();
         $this.removeData('tooltip');

         })

         },
         reposition : function( ) { },
         show : function( ) {  },
         hide : function( ) { },
         update : function( content ) { } */
    };

    $.fn.b2ctoggle = function( method ) {

        if ( methods[method] ) {
            return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ));
        } else if ( typeof method === 'object' || ! method ) {
            return methods.init.apply( this, arguments );
        } else {
            $.error( 'Cannot find ' +  method + ' in jQuery.b2ctoggle' );
        }

    };

    $.fn.b2ctoggle.defaults = {
        cl_toggle_value : 'togglevalue',
        attr_toggle_value : 'data-val',
        cl_toggle_value_set : 'set',
        cl_container : 'b2ctoggle',
        readOnly : false,
        multiMode : true,
        unsetAllEnabled : false,
        onBeforeValueChange : null, // callback function(val, states) @return [true | false] - false, то действие отменяется
        onValueChange : null, // callback function(states)
        states : null, // array of objects {val : 'valueName', set : boolean}
        needEqualWidth : false,
    };

    $.fn.b2ctoggle.disable = function($this, disable) {
        if (disable) {
            $this.addClass('disabled');
            $this.attr('data-disabled', '');
        } else {
            $this.removeClass('disabled');
            $this.attr('data-disabled', null);
        }
    };

    $.fn.b2ctoggle.onValueClick = function($value, data) {
        var options = data.options;

        if (options.readOnly)
            return;
        if(!options.unsetAllEnabled && $value.hasClass(options.cl_toggle_value_set)) {
            if(data.$this.find('.'+options.cl_toggle_value +'.'+options.cl_toggle_value_set).length == 1)
                return;
        }

        var value = $value.attr(options.attr_toggle_value);
        if (options.onBeforeValueChange != null) {
            if (! options.onBeforeValueChange(value, $.fn.b2ctoggle.getValuesState(data)))
                return;
        }

        if(!options.multiMode) {
            if (! $value.hasClass(options.cl_toggle_value_set)) {
                data.$values.removeClass(options.cl_toggle_value_set);
                //$value.addClass(data.options.cl_toggle_value_set);
            }
        }
        $value.toggleClass(options.cl_toggle_value_set);
        if(options.onValueChange != null) {
            options.onValueChange($.fn.b2ctoggle.getValuesState(data));
        }
    };

    $.fn.b2ctoggle.getValuesState = function(data) {
        var result = [];

        for(var i=0; i<data.$values.length; i++) {
            var $value = $(data.$values[i]);
            var res = { };
            res.val = $value.attr(data.options.attr_toggle_value);
            res.set = $value.hasClass(data.options.cl_toggle_value_set);
            result.push(res);
        }

        return result;
    };

    $.fn.b2ctoggle.setValuesState = function(data, states) {
        for(var i=0; i<data.$values.length; i++) {
            var $value = $(data.$values[i]);
            var valName = $value.attr(data.options.attr_toggle_value);
            for(var j = 0; j<states.length && states[j].val != valName; j++) {
                ; // empty operator
            }
            if (j < states.length) {
                if (states[j].set)
                    $value.addClass(data.options.cl_toggle_value_set);
                else
                    $value.removeClass(data.options.cl_toggle_value_set);
            } else
                $value.removeClass(data.options.cl_toggle_value_set);
        }
    };

    $.fn.b2ctoggle.setValuesWidthToEqual = function($values) {
        var maxWidth = 0, width;
        for(var i = 0; i < $values.length; i++) {
            //width = Number($($values[i]).width().replace(/\D/g,''));
            width = $($values[i]).text().length;
            console.log('width='+width);
            if (maxWidth < width)
                maxWidth = width;
        }
        for(var i = 0; i < $values.length; i++) {
            $($values[i]).css('min-width', maxWidth+'em');
        }
    }

})( jQuery );