import { Backbone } from '@b2cmessenger/backbone';

import './Buttons.scss';
import emptyBtnTemplate from './Empty.jade';
import menuBtnTemplate from './Menu.jade';
import pinBtnTemplate from './Pin.jade';
import backBtnTemplate from './Back.jade';
import notificationsBtnTemplate from './Notifications.jade';
import editBtnTemplate from './Edit.jade';
import checkinBtnTemplate from './Checkin.jade';
import newtaskBtnTemplate from './Newtask.jade';
import tasksBtnTemplate from './Tasks.jade';
import galleryBtnTemplate from './Gallery.jade';
import favoritesBtnTemplate from './Favorites.jade';
import reportBtnTemplate from './Report.jade';
import expandBtnTemplate from './Expand.jade';
import gridBtnTemplate from './Grid.jade';
import cameraBtnTemplate from './Camera.jade';
import deleteBtnTemplate from './Delete.jade';
import markBtnTemplate from './Mark.jade';
import undoBtnTemplate from './Undo.jade';
import saveBtnTemplate from './Save.jade';
import reversecameraBtnTemplate from './Reversecamera.jade';
import imagesBtnTemplate from './Images.jade';
import addBtnTemplate from './Add.jade';
import sendBtnTemplate from './Send.jade';
import infoBtnTemplate from './Info.jade';
import ideaBtnTemplate from './Idea.jade';
import issueBtnTemplate from './Issue.jade';
import questionBtnTemplate from './Question.jade';
import filterBtnTemplate from './Filter.jade';
import listBtnTemplate from './List.jade';
import moreBtnTemplate from './More.jade';
import broadcastBtnTemplate from './Broadcast.jade';
import muteNotificationsBtnTemplate from './MuteNotifications.jade';
import flipswitchBtnTemplate from './Flipswitch.jade';
import calendarBtnTemplate from './Calendar.jade';
import prevBtnTemplate from './Prev.jade';
import nextBtnTemplate from './Next.jade';
import reorderBtnTemplate from './Reorder.jade';
import digitalMenuBtnTemplate from './DigitalMenu.jade';
import cartBtnTemplate from './Cart.jade';
import searchBtnTemplate from './Search.jade';

const buttons = {
        empty: (locals) => Backbone.$(emptyBtnTemplate(locals)),
        menu: (locals) => {
            const $el = Backbone.$(menuBtnTemplate(locals));
            const cb = (isMobileView) => {
                $el.toggleClass('invisible', !isMobileView && !app.isKioskMode);
            };

            app.on('change:is:mobile:view', cb);
            $el.on('remove', () => app.controller.off('change:is:mobile:view', cb));

            cb(app.isMobileView);
            return $el;
        },
        pin: (locals) => Backbone.$(pinBtnTemplate(locals)),
        back: (locals) => Backbone.$(backBtnTemplate(locals)),
        notifications: (locals) => {
            const $el = Backbone.$(notificationsBtnTemplate(_.extend(locals, {
                notificationsCount: notificationCollection.getNewVisibleNotificationsCount()
            })));

            const cb = (c, count) => $el.html(notificationsBtnTemplate(_.extend({}, locals, {
                notificationsCount: count,
                onlyInternal: true
            })));

            notificationCollection.on('change:newVisibleNotificationsCount', cb);
            $el.on('remove', () => notificationCollection.off('change:newVisibleNotificationsCount', cb));
            return $el;
        },
        edit: (locals) => Backbone.$(editBtnTemplate(locals)),
        checkin: (locals) => Backbone.$(checkinBtnTemplate(locals)),
        newtask: (locals) => Backbone.$(newtaskBtnTemplate(locals)),
        bookreservation: (locals) => Backbone.$(newtaskBtnTemplate(locals)),
        reservations: locals => Backbone.$(tasksBtnTemplate(locals)),
        digitalmenu: locals => Backbone.$(digitalMenuBtnTemplate(locals)),
        digitalmenudashboard: locals => Backbone.$(digitalMenuBtnTemplate(locals)),
        tasks: (locals) => Backbone.$(tasksBtnTemplate(locals)),
        gallery: (locals) => Backbone.$(galleryBtnTemplate(locals)),
        favorites: (locals) => Backbone.$(favoritesBtnTemplate(locals)),
        report: (locals) => Backbone.$(reportBtnTemplate(locals)),
        expand: (locals) => Backbone.$(expandBtnTemplate(locals)),
        grid: (locals) => Backbone.$(gridBtnTemplate(locals)),
        camera: (locals) => Backbone.$(cameraBtnTemplate(locals)),
        delete: (locals) => Backbone.$(deleteBtnTemplate(locals)),
        mark: (locals) => Backbone.$(markBtnTemplate(locals)),
        undo: (locals) => Backbone.$(undoBtnTemplate(locals)),
        save: (locals) => Backbone.$(saveBtnTemplate(locals)),
        add: (locals) => Backbone.$(addBtnTemplate(locals)),
        reversecamera: (locals) => Backbone.$(reversecameraBtnTemplate(locals)),
        images: (locals) => Backbone.$(imagesBtnTemplate(locals)),
        send: locals => Backbone.$(sendBtnTemplate(locals)),
        info: locals => Backbone.$(infoBtnTemplate(locals)),
        idea: locals => Backbone.$(ideaBtnTemplate(locals)),
        issue: locals => Backbone.$(issueBtnTemplate(locals)),
        question: locals => Backbone.$(questionBtnTemplate(locals)),
        filter: locals => Backbone.$(filterBtnTemplate(locals)),
        list: locals => Backbone.$(listBtnTemplate(locals)),
        more: locals => Backbone.$(moreBtnTemplate(locals)),
        broadcast: locals => Backbone.$(broadcastBtnTemplate(locals)),
        muteNotifications: locals => Backbone.$(muteNotificationsBtnTemplate(locals)),
        calendar: locals => Backbone.$(calendarBtnTemplate(locals)),
        prev: locals => Backbone.$(prevBtnTemplate(locals)),
        next: locals => Backbone.$(nextBtnTemplate(locals)),
        reorder: locals => Backbone.$(reorderBtnTemplate(locals)),
        cart: locals => Backbone.$(cartBtnTemplate(locals)),
        search: locals => Backbone.$(searchBtnTemplate(locals)),
        /**
         * @param {String} options.id
         * @param {String} options.viewModel
         * @param {String} [options.model=options.viewModel]
         * @param {String} [options.attrName=options.id]
         * @param {String} [options.attrOnValue=true]
         * @param {String} [options.attrOffValue=false]
         * @param {String} [options.labelOn]
         * @param {String} [options.labelOff]
         * @param {String} [options.disableDrag]
         */
        flipswitch: options => {
            options = _.defaults(options, {
                model: options.viewModel,
                attrName: options.id,
                attrOnValue: true,
                attrOffValue: false,
                disableDrag: true
            });

            const $el = Backbone.$(flipswitchBtnTemplate(options));

            function onChangeDisabled(m, disabled) {
                if ($el.flipswitch('instance')) {
                    if (disabled) {
                        $el.flipswitch('disable');
                    } else {
                        $el.flipswitch('enable');
                    }
                }
            }

            options.viewModel.on('change:disabled', onChangeDisabled);
            $el.on('remove', () => options.viewModel.off('change:disabled', onChangeDisabled));

            function onChangeAttr(m, attr) {
                if (String($el.val()) != String(attr)) {
                    $el.val(String(attr));
                    $el.flipswitch && $el.flipswitch('instance') && $el.flipswitch('refresh');
                }
            }

            options.model.on(`change:${options.attrName}`, onChangeAttr);
            $el.on('remove', () => options.model.off(`change:${options.attrName}`, onChangeAttr));

            $el.change(() => {
                if (String($el.val()) == String(options.attrOnValue)) {
                    options.model.set(options.attrName, options.attrOnValue);
                } else {
                    options.model.set(options.attrName, options.attrOffValue);
                }
            });

            onChangeAttr(options.model, options.model.get(options.attrName));

            if (options.disableDrag) {
                $el.on('flipswitchcreate', () => {
                    const $switch = $el.flipswitch('instance').flipswitch;
                    $switch.addClass('select-flipswitch');
                });
            }

            return $el;
        },
    }
;

/**
 * @param {String|Object} options type name or object with button options
 */
export function createButton(options) {
    const btn = _.extend(options, _.isObject(options)
        ? {
            id: options.id,
            text: options.text,
            type: options.icn || options.type || options.id,
            class: options.class
        }
        : {
            id: options,
            type: options
        }
    );

    if (buttons[btn.type]) {
        const $btn = buttons[btn.type](btn);
        if (btn.class) {
            $btn.addClass(btn.class);
        }

        if (btn.viewModel) {
            const cb = (m, disabled) => $btn.attr("disabled", disabled);
            btn.viewModel.on('change:disabled', cb);

            $btn.on('remove', () => btn.viewModel.off('change:disabled', cb));
        }

        return $btn;
    } else {
//////////////////////
        _.defer(() => {
            throw new Error(`createButton() invalid btn.type, options: ${JSON.stringify(options)}`)
        });
        return $("<span></span>").css("display", "none");
////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////
    }
};

export default buttons;
