import React from 'react';
import PropTypes from 'prop-types';

import MenuList, { byIdAndSortOrder, MenuListItem, MenuListType , MenuListEmptyItem } from 'components/menu/MenuList/MenuList';
import { RadioSwitch, SliderGroup, SwitchInput } from 'components/UI/InputGroup/InputGroup';
import { UserItemBase } from 'components/menu/UserItem/UserItem';

import './UserItems.scss';

// TODO Make `MenuList` and `MenuListItem` universal components. `MenuList` -> `List`, `MenuListItem` -> `ListItem`.
const UserSelectableItems = (props) => {
    const { users, selected = [], onSelectedChange = _.noop, multiple = true } = props;

    const itemList = users.map((i) => {
        const value = selected.indexOf(i.id) > -1;
        const onChange = onSelectedChange.bind(null, i);
        const switchInput = multiple ? (
            <SwitchInput value={value} onChange={onChange} />
        ) : (
            <RadioSwitch value={value} onChange={onChange} />
        );

        return (
            <MenuListItem className="menu-list-item-wrap" key={byIdAndSortOrder(i)}>
                <UserItemBase className="menu-list-item" onClick={onChange.bind(null, !value)} {...i}>
                    <div className="menu-list-item-selection" onClick={(e) => e.stopPropagation()}>
                        <SliderGroup>{switchInput}</SliderGroup>
                    </div>
                </UserItemBase>
            </MenuListItem>
        );
    });

    return (
        <MenuList
            emptyContent={<MenuListEmptyItem message={props.emptyContentMessage || 'No users to choose'} />}
            {...props}
            type={MenuListType.Users}
            className="selectable"
        >
            {itemList}
        </MenuList>
    );
};
UserSelectableItems.propTypes = {
    users: PropTypes.array,
    selected: PropTypes.array,
    onSelectedChange: PropTypes.func,
};

export default UserSelectableItems;
