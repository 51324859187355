import { Collection } from '@b2cmessenger/backbone';
import settings from 'settings';

import ReservationModel from 'models/ReservationModel';

const ReservationCollection = Collection.extend({
    options: {
        placeId: undefined,
        userId: undefined,
        includeMap: 1,
        limit: 10,
        comparators: {
            'id': 'id',
            'id desc': {
                alias: '-id',
                comparator: (a, b) => b.id - a.id
            },
            'date_start asc': {
                alias: 'date_start',
                comparator: (a, b) => {
                    const d = (a.get('dateStart') || 0) - (b.get('dateStart') || 0);
                    return d == 0 ? (a.id - b.id) : d;
                }
            },
            'date_start desc': {
                alias: '-date_start',
                comparator: (a, b) => {
                    const d = (b.get('dateStart') || 0) - (a.get('dateStart') || 0);
                    return d == 0 ? (b.id - a.id) : d;
                }
            },
            'date_end desc': {
                alias: '-date_end',
                comparator: (a, b) => {
                    const d = (b.get('dateEnd') || 0) - (a.get('dateEnd') || 0);
                    return d == 0 ? (b.id - a.id) : d;
                }
            },
            'updated_at asc': {
                alias: 'updated_at',
                comparator: (a, b) => {
                    const d = (a.get('updatedAt') || 0) - (b.get('updatedAt') || 0);
                    return d == 0 ? (a.id - b.id) : d;
                }
            },
            'updated_at desc': {
                alias: '-updated_at',
                comparator: (a, b) => {
                    const d = (b.get('updatedAt') || 0) - (a.get('updatedAt') || 0);
                    return d == 0 ? (b.id - a.id) : d;
                }
            },
        }
    },

    model: ReservationModel,
    url: settings.host + settings.serv_reservation.withmap,

    initialize() {
        const comparators = _.reduce(this.options.comparators, (m, v, k) => {
            if (_.isFunction(v)) {
                m[k] = {
                    comparator: v,
                    alias: k
                };
            } else if (_.isString(v)) {
                m[k] = {
                    comparator: k,
                    alias: v
                };
            } else if (v.comparator) {
                m[k] = v;
            }

            return m;
        }, {});

        if (comparators) {
            Object.defineProperties(this, {
                'comparator': {
                    get() {
                        return this._comparator;
                    },
                    set(val) {
                        if (val) {
                            const comparator = comparators[val];
                            if (!comparator) {
                                throw new TypeError(`Cannot set comparator to ${JSON.stringify(val)}: invalid value`);
                            } else {
                                this._comparator = comparator.comparator;
                                this._comparatorAlias = comparator.alias;
                                this._comparatorSort = !!val.match(/desc$/) ? 'desc' : 'asc'
                            }
                        } else {
                            delete this._comparator;
                            delete this._comparatorAlias;
                            delete this._comparatorSort;
                        }
                    }
                },
                'comparators': {
                    value: comparators
                }
            });
        }
    },

    create(attrs, options) {
        _.defaults(attrs || (attrs = {}), {
            placeId: this.options.placeId
        });
        return Collection.prototype.create.call(this, attrs, options);
    },

    parse(data, options) {
        if ((options && options.includeMap) || this.options.includeMap) {
            if (data && data.map) {
                this._updateMap(data.map);
            }

            return data && data.items;
        }

        return data;
    },

    set() {
        this.trigger('before:set', ...arguments);
        return Collection.prototype.set.apply(this, arguments);
    },

    fetch(options) {
        _.defaults(options || (options = {}),
            _.pick(this.options,
                'includeMap', 'includePlace', 'includeUser', 'includePhone',
                'includeTaskId', 'includeTask', 'includeLastChangeComment')
        );

        const success = options.success,
            error = options.error;

        options.success = (collection, data) => {
            _.extend(this.options, _.pick(options, 'sort', 'status', 'placeId', 'userId'));

            if (success) success.call(options.context, this, data, options);
        };

        _.defaults(options || (options = {}), {
            data: {},
        });

        _.defaults(options.data, {
            'place_id': options.placeId || this.options.placeId || null,
            'user_id': options.userId || this.options.userId || options.userId,
            'include_map': +!!options.includeMap || this.options.includeMap,
            'include_place': +!!options.includePlace,
            'include_user': +!!options.includeUser,
            'include_phone': +!!options.includePhone,
            'include_task_id': +!!options.includeTaskId,
            'include_task': +!!options.includeTask,
            'include_last_change_comment': +!!options.includeLastChangeComment
        });

        if (!_.isUndefined(options.referenceDate)) {
            _.defaults(options.data, {
                'reference_date': options.referenceDate
            });
        }

        if (!_.isUndefined(options.limitBefore)) {
            _.defaults(options.data, {
                'limit_before': options.limitBefore
            });
        }

        if (!_.isUndefined(options.limitAfter)) {
            _.defaults(options.data, {
                'limit_after': options.limitAfter
            });
        }

        if (!_.isUndefined(options.ids)) {
            _.defaults(options.data, {
                'ids': options.ids
            });
        }

        if (!_.isUndefined(options.status)) {
            const status = [];
            const statusValues = _.values(_.omit(ReservationModel.Status, 'Unknown'));

            if (_.isArray(options.status)) {
                status.push(..._.intersection(statusValues, options.status));
            } else if (statusValues.includes(options.status)) {
                status.push(options.status);
            }

            if (status.length) {
                _.extend(options.data, {
                    'status': status
                });
            }
        }

        _.defaults(options.data, {
            'sort': options.sort || (this._comparator && this._comparatorAlias) || 'id',
        });

        return Collection.prototype.fetch.call(this, options);
    },

    fetchInitial(options) {
        return this.fetch(_.defaults(options, {
            fetchInitial: true,
            limitAfter: this.options.limit,
            limitBefore: this.options.limit
        }));
    },

    fetchPrevious(options) {
        const isAscSort = this._comparatorSort === 'asc';

        return this.fetch(_.defaults(options, {
            fetchPrevious: true,
            referenceDate: this._getPreviousReferenceDate(isAscSort),
            limitBefore: isAscSort ? this.options.limit * 2 : 0,
            limitAfter: isAscSort ? 0 : this.options.limit * 2,
            remove: false,
        }));
    },

    fetchNext(options) {
        const isAscSort = this._comparatorSort === 'asc';

        return this.fetch(_.defaults(options, {
            fetchNext: true,
            referenceDate: this._getNextReferenceDate(isAscSort),
            limitBefore: isAscSort ? 0 : this.options.limit * 2,
            limitAfter: isAscSort ? this.options.limit * 2 : 0,
            remove: false,
        }));
    },

    hasPrevious() {
        if (this.idMap && _.isArray(this.idMap)) {
            const first = this.first();

            if (first) {
                const idx = this.idMap.indexOf(first.id);
                return idx !== this.idMap.length - 1 && idx > 0;
            } else if (this.idMap.length) {
                return true;
            }
        }

        return false;
    },

    hasNext() {
        if (this.idMap && _.isArray(this.idMap)) {
            const last = this.last();

            if (last) {
                const idx = this.idMap.indexOf(last.id);
                return idx !== 0 && idx < this.idMap.length - 1;
            } else if (this.idMap.length) {
                return true;
            }
        }

        return false;
    },

    findNearestModelToDate(date, dateAttribute = 'dateStart') {
        if (!this.size()) {
            return null;
        }

        let minDt = null, minDtId, sameDate = false;
        const updateMin = (dt, id, isSame) => {
            minDt = dt;
            minDtId = id;
            sameDate = isSame;
        };

        this.each(m => {
            const isSameDate = m.get(dateAttribute).toLocaleDateString() === date.toLocaleDateString();
            const dt = Math.abs(m.get(dateAttribute).getTime() - date.getTime());

            if (minDt == null ||
                (!sameDate && isSameDate) ||
                (minDt > dt)
            ) {
                updateMin(dt, m.id, sameDate || isSameDate);
            }

            return dt;
        });

        return this.get(minDtId);
    },

    _updateMap(newMap) {
        if (this.idMap && _.isArray(this.idMap)) {
            if (this.idMap.length) {
                newMap = newMap.map(Number);
                const added = newMap.filter(id => this.idMap.indexOf(+id) === -1);
                const removed = this.idMap.filter(id => newMap.indexOf(+id) === -1);
                const reordered = this.idMap.filter(id => newMap.indexOf(+id) !== -1)
                    .filter(id => this.idMap.indexOf(+id) !== newMap.indexOf(+id));

                let mapChanges = null;
                if (added.length) {
                    mapChanges = _.extend(mapChanges || {}, { added });
                }

                if (removed.length) {
                    mapChanges = _.extend(mapChanges || {}, { removed });
                }

                if (reordered.length) {
                    mapChanges = _.extend(mapChanges || {}, { reordered });
                }

                return this._setMap(newMap, mapChanges);
            }
        }

        return this._setMap(newMap);
    },

    _setMap(map, mapChanges) {
        if (map !== null) {
            this.idMap = _.chain(map).clone().map(Number).value();
        } else {
            this.idMap = map;
        }

        if (mapChanges) {
            this.trigger('idMap:changes', mapChanges);
        }

        return this.idMap;
    },

    _getPreviousReferenceDate(isAscSort) {
        const first = this.first();
        const dt = isAscSort ? -1000 : 1000;

        if (first) {
            return B2Cjs.datetimeJSToServerLocal(
                new Date(B2Cjs.datetimeServerLocalToJS(first.get('date_start_local')).getTime())
            );
        }

        return null;
    },

    _getNextReferenceDate(isAscSort) {
        const last = this.last();
        const dt = isAscSort ? 1000 : -1000;

        if (last) {
            return B2Cjs.datetimeJSToServerLocal(
                new Date(B2Cjs.datetimeServerLocalToJS(last.get('date_start_local')).getTime())
            );
        }

        return null;
    }
});

export default ReservationCollection;
