import React from 'react';
import PropTypes from 'prop-types';
import MenuList, { MenuListItem } from 'components/menu/MenuList/MenuList';
import { MenuItemTextBase } from 'components/menu/MenuItem/MenuItem';
import InputGroup, { TextInputGroup } from 'components/UI/InputGroup/InputGroup';
import Price from 'components/UI/Price/Price';

const MenuTextItems = (props) => {
    const { items, limit = null, displayAdditions, displayProperties, onOpenOrderClick = _.noop } = props;

    const itemList = _.first(items, limit === null ? items.length : limit).map((i) => {
        const hasAdditions = Boolean(_.isArray(i.additions) && i.additions.length > 0);
        const totalPrice = _.isUndefined(i.price)
            ? i.price
            : i.price !== i.totalPrice && Boolean((hasAdditions && displayAdditions) || i.count > 1)
                ? i.totalPrice
                : undefined;

        const subTotalText =
            `${['Total', Boolean(hasAdditions && displayAdditions) && 'with additions', i.count > 1 && `for ${i.count}`]
                .filter(Boolean)
                .join(' ')  }:`;

        return (
            <MenuListItem className="menu-list-item-wrap menu-list-item-text-wrap " key={i.id}>
                <MenuItemTextBase
                    className="menu-list-item"
                    {...i}
                    price={hasAdditions && !displayAdditions && i.count === 1 ? i.totalPrice : i.price}
                    onClick={props.onClick && props.onClick.bind(null, i.id)}
                />
                {displayProperties && _.isArray(i.properties)
                    ? i.properties.map((p) => (
                        <TextInputGroup key={p.name} label={`${p.name}:`}>
                            {p.value}
                        </TextInputGroup>
                    ))
                    : null}
                {hasAdditions && displayAdditions ? (
                    <MenuTextItems className="menu-item-text-additions" items={i.additions}>
                        <InputGroup label="Additions:" />
                    </MenuTextItems>
                ) : null}
                {!_.isUndefined(totalPrice) ? (
                    <div className="menu-item-text-subtotal">
                        <TextInputGroup label={subTotalText} />
                        <Price value={totalPrice} currency={i.currency} />
                    </div>
                ) : null}
            </MenuListItem>
        );
    });

    if (itemList.length < items.length) {
        itemList.push(
            <MenuListItem
                onClick={onOpenOrderClick}
                className="menu-list-item-wrap menu-list-item-text-wrap "
                key="more"
            >
                <span>
                    ... and
                    {items.length - itemList.length}
                    {' '}
                    more
                </span>
            </MenuListItem>
        );
    }

    return (
        <MenuList {...props} type="text-items">
            {props.children}
            {itemList}
        </MenuList>
    );
};

MenuTextItems.propTypes = {
    items: PropTypes.array,
    limit: PropTypes.number,
    displayAdditions: PropTypes.bool,
    displayProperties: PropTypes.bool,
};

export default MenuTextItems;

export function getTextItemsFromOrderFormattedItems(order, options) {
    const { includePosition = true, includePrice = true } = options || {};

    const mapItem = (i, idx) => ({
        ..._.pick(i, 'id', 'item_id', 'count', 'properties'),
        name: i.item_name,
        ...(includePosition ? { position: idx + 1 } : {}),
        ...(includePrice
            ? {
                price: i.price,
                totalPrice: i.total_price,
                currency: order.currency,
            }
            : {}),
    });

    return (
        _.isArray(order && order.items) &&
        order.items.map((i, idx) => ({
            ...mapItem(i, idx),
            additions: (i.additions || []).map(mapItem).map((i) => _.omit(i, 'totalPrice')),
        }))
    );
}
