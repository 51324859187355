var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (B2Cjs, created_at, isAuthorAnonym, isAuthorVerified, isFromBussines, loginedUserModel, placeModel, published_at, updated_at, userModel, user_id) {const avatarUrl = !isAuthorAnonym && userModel && userModel.get('avatarUrl');
const avatarStyle = avatarUrl ? { "background-image": `url(${avatarUrl})` } : {}
const userName = !isAuthorAnonym ? 
  userModel && userModel.get('name') || 'Unknown person' : 
  user_id == loginedUserModel.id ? 
    isFromBussines ? 'Me as somebody': 'Me as anonym'
    : isFromBussines ? 'Somebody': 'Anonym'
const placeName = placeModel && placeModel.get('name');
const messageDate = B2Cjs.datetimeServerToJS(published_at || updated_at || created_at)
const messageDateStr = messageDate && messageDate.toLocaleString([], { 
    day: 'numeric', 
    month: 'short', 
    year: 'numeric', 
    hour: '2-digit',  minute: '2-digit' 
}) || ''

pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["avatar",(isAuthorAnonym ? 'anonym' : '')], [false,true]), false, true)+pug.attr("style", pug.style(avatarStyle), true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"right\"\u003E\u003Cdiv class=\"line\"\u003E\u003Cp class=\"user-name\" data-js-author\u003E" + (pug.escape(null == (pug_interp = userName) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003Cdiv class=\"marks\" data-js-marks\u003E\u003Ci class=\"ion-ios-checkmark-circle\" data-js-mark-solution\u003E\u003C\u002Fi\u003E\u003Ci class=\"icon-gift\" data-js-mark-gift\u003E\u003C\u002Fi\u003E\u003Ci class=\"ion-ios-bulb\" data-js-mark-idea\u003E\u003C\u002Fi\u003E\u003Ci class=\"ion-ios-sad\" data-js-mark-issue\u003E\u003C\u002Fi\u003E\u003Ci class=\"ion-ios-help-circle\" data-js-mark-question\u003E\u003C\u002Fi\u003E\u003Ci class=\"ion-ios-notifications-outline\" data-js-mark-muted\u003E\u003C\u002Fi\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"line\"\u003E";
if (isFromBussines) {
pug_html = pug_html + "\u003Cp class=\"workplace\" data-js-author\u003Efrom \u003Cspan class=\"place-name\"\u003E" + (pug.escape(null == (pug_interp = placeName || '') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fp\u003E";
}
else
if (isAuthorVerified) {
pug_html = pug_html + "\u003Cp class=\"verified\" data-js-author\u003E\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"unverified\" data-js-author\u003E\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003Cp class=\"date\"\u003E" + (pug.escape(null == (pug_interp = messageDateStr) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"B2Cjs" in locals_for_with?locals_for_with.B2Cjs:typeof B2Cjs!=="undefined"?B2Cjs:undefined,"created_at" in locals_for_with?locals_for_with.created_at:typeof created_at!=="undefined"?created_at:undefined,"isAuthorAnonym" in locals_for_with?locals_for_with.isAuthorAnonym:typeof isAuthorAnonym!=="undefined"?isAuthorAnonym:undefined,"isAuthorVerified" in locals_for_with?locals_for_with.isAuthorVerified:typeof isAuthorVerified!=="undefined"?isAuthorVerified:undefined,"isFromBussines" in locals_for_with?locals_for_with.isFromBussines:typeof isFromBussines!=="undefined"?isFromBussines:undefined,"loginedUserModel" in locals_for_with?locals_for_with.loginedUserModel:typeof loginedUserModel!=="undefined"?loginedUserModel:undefined,"placeModel" in locals_for_with?locals_for_with.placeModel:typeof placeModel!=="undefined"?placeModel:undefined,"published_at" in locals_for_with?locals_for_with.published_at:typeof published_at!=="undefined"?published_at:undefined,"updated_at" in locals_for_with?locals_for_with.updated_at:typeof updated_at!=="undefined"?updated_at:undefined,"userModel" in locals_for_with?locals_for_with.userModel:typeof userModel!=="undefined"?userModel:undefined,"user_id" in locals_for_with?locals_for_with.user_id:typeof user_id!=="undefined"?user_id:undefined));;return pug_html;};
module.exports = template;