// import custom jQuery plug-ins
import './widgets/b2crating.js';
import './widgets/b2ctoggle.js';
import './widgets/b2ctags.js';
import './widgets/b2cpopup.js';
import './widgets/b2creporter.js';
import './widgets/b2ccategoryviewer.js';

// import all "legacy" files (just "window.{module} = {module}" assignments)
import './B2Cjs.js';
// import './b2cGCMpush.js';
import './geo.js';
import './googlePlace.js';
import './widgets/MyGoogleMap.js';
import './CategoryBase.js';
import './CategoryBrowserStorage.js';
import './PropertyBase.js';
import './PropertyBrowserStorage.js';
import './widgets/B2CUser.js';
import './widgets/B2CEvidence.js';
import './widgets/B2CGiftTemplate.js';
import './widgets/B2CGift.js';
import './widgets/b2cgift_give_dialog.js';
import './widgets/B2CEmployee.js';
import './widgets/B2CWorkHours.js';
// import './b2cFacebook.js';
// import './b2cGooglePlus.js';
import './LoginedUserHandler.js';
import './Initer.js';
import './server.js';
import './initGoogleMap';