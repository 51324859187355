import { Collection } from '@b2cmessenger/backbone';
import settings from 'settings';

import BroadcastCampaignModel from 'models/BroadcastCampaignModel';
import CollectionWithPagination from 'traits/CollectionWithPagination';

const BroadcastCampaignCollection = Collection.extendWithTraits([
    new CollectionWithPagination({
        restrictComparators: true,
        comparators: {
            'smart': (a, b) => {
                const statusA = a.get('status') || Number.MAX_SAFE_INTEGER,
                    statusB = b.get('status') || Number.MAX_SAFE_INTEGER;

                if (statusA != statusB) {
                    return statusA - statusB;
                } else {
                    let d = 0;
                    switch (statusA) {
                        case TaskTargetModel.Status.Scheduled:
                        case TaskTargetModel.Status.InProgress:
                            d = (a.get('nextSendAt') || 0) - (b.get('nextSendAt') || 0);
                            break;
                        case TaskTargetModel.Status.Done:
                            d = (b.get('lastSendAt') || 0) - (a.get('lastSendAt') || 0);
                            break;
                        default:
                            d = (b.get('updatedAt') || 0) - (a.get('updatedAt') || 0);
                    }
                    return d == 0 ? (a.id - b.id) : d;
                }
            },
            'updated_at desc': {
                alias: '',
                comparator: (a, b) => {
                    const d = (b.get('updatedAt') || 0) - (a.get('updatedAt') || 0);
                    return d == 0 ? (a.id - b.id) : d;
                }
            },
            'id': 'id'
        }
    })
],{
    options: {
        placeId: undefined,
        pageSize: 200
    },
    model: BroadcastCampaignModel,
    url: settings.host + settings.serv_task.target,

    initialize() {
        this.placeId = this.options.placeId;
    },

    fetch(options) {
        _.defaults(options || (options = {}), {
            data: {},
            placeId: this.placeId
        });

        _.defaults(options.data, {
            'place_id': options.placeId,
            'include_task': true,
            'include_users': true,
            'status': [
                BroadcastCampaignModel.Status.Scheduled,
                BroadcastCampaignModel.Status.InProgress,
                BroadcastCampaignModel.Status.Done
            ]
        });

        _.extend(options, { parse: true });

        return Collection.prototype.fetch.call(this, options);
    },

    _getDefaultPlaceId() {
        return LoginedUserHandler.get_default_work_place_id() || undefined;
    },
});

Object.defineProperties(BroadcastCampaignCollection.prototype, {
    'placeId': {
        get() {
            return this._placeId || this._getDefaultPlaceId();
        },
        set(val) {
            this._placeId = val;
        }
    }
});

export default BroadcastCampaignCollection;
