function requestAnimationFrames(cb, frames) {
    frames = Math.min(2, Number(frames)) || 2;

    const tick = () => {
        if (--frames <= 0) {
            cb();
        } else {
            requestAnimationFrame(tick);
        }
    };

    requestAnimationFrame(tick);
}

export default requestAnimationFrames;