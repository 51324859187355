import React from 'react';

import { WindowContent, WindowFooter, WindowHeader } from 'components/window/Window';
import TextButton from 'components/UI/TextButton';
import InputGroup, { TextInputGroup, ControlledTextInput } from 'components/UI/InputGroup/InputGroup';
import Footer from 'components/UI/Footer/Footer';
import ReactWindow from 'windows/ReactWindow';
import Header from 'components/UI/Header/Header';
import { BackButton } from 'components/UI/Button/Button';
import { toSortedObject } from 'store/menu/utils';
import ConfirmModalWindow from 'windows/Modal/Confirm';
import Spinner from 'components/UI/Spinner/Spinner';
import MenuTables from 'components/menu/MenuTables/MenuTables';
import KeyboardPadding from 'components/KeyboardPadding';

const EmptySection = { name: '', color: '' };

const MenuSectionEditor = (props) => {
    const [loading, setLoading] = React.useState(false);
    const [section, setSection] = React.useState({ ...EmptySection });

    const windowTitle = React.useMemo(() => (props.section ? 'Edit section' : 'Create section'), [props.section]);
    const hasUnsavedChanges = React.useMemo(
        () => JSON.stringify(toSortedObject(props.section || EmptySection)) !== JSON.stringify(toSortedObject(section)),
        [props.section, section]
    );
    const isValidSection = React.useMemo(() => section && section.name && section.name.length > 2, [section]);
    const tables = React.useMemo(
        () => section.tables || (section.id && (props.tables || []).filter((t) => t.sectionId === section.id)) || [],
        [props.tables, section.tables]
    );

    const setSectionName = React.useCallback(
        (name) => {
            setSection((section) => ({ ...section, name }));
        },
        [setSection]
    );

    const setSectionColor = React.useCallback(
        (color) => {
            setSection((section) => ({ ...section, color }));
        },
        [setSection]
    );

    const closeDiscardingChanges = React.useCallback(() => {
        if (hasUnsavedChanges) {
            new ConfirmModalWindow({ title: 'Discard changes?' }).show().then((result) => {
                result && props.closeWindow();
            });
        } else {
            props.closeWindow();
        }
    }, [hasUnsavedChanges, props.closeWindow]);

    const saveSection = React.useCallback(async () => {
        try {
            setLoading(true);
            await props.saveSection(section);
            props.closeWindow();
        } catch (e) {
            setLoading(false);
            props.showError(e instanceof Error ? e.message : e);
        }
    }, [section, setLoading, props.closeWindow]);

    React.useEffect(() => {
        setSection((section) => ({ ...section, ...props.section }));
    }, [props.section]);

    return (
        <>
            <WindowHeader>
                <Header>
                    <BackButton onClick={closeDiscardingChanges} />
                    <h1 className="title">{windowTitle}</h1>
                </Header>
            </WindowHeader>
            <WindowContent>
                <TextInputGroup label="Name">
                    <ControlledTextInput
                        onChange={setSectionName}
                        value={section.name}
                        placeholder="Enter section name"
                    />
                </TextInputGroup>
                <TextInputGroup label="Color">
                    <ControlledTextInput
                        onChange={setSectionColor}
                        value={section.color}
                        placeholder="Specify section color"
                    />
                </TextInputGroup>
                {_.isArray(tables) && tables.length > 0 && (
                    <>
                        <InputGroup label="Tables of section" className="input-group menu-list-input-group" />
                        <MenuTables className="menu-list-compact" tables={tables} />
                    </>
                )}
            </WindowContent>
            <WindowFooter>
                <Footer>
                    <TextButton onClick={closeDiscardingChanges}>CLOSE</TextButton>
                    <TextButton
                        className={isValidSection && hasUnsavedChanges ? 'filled' : ''}
                        onClick={saveSection}
                        disabled={!isValidSection}
                    >
                        SAVE
                    </TextButton>
                </Footer>
            </WindowFooter>
            <KeyboardPadding />
            {loading && <Spinner />}
        </>
    );
};

const MenuSectionEditorWindow = ReactWindow.extend({
    constructor: function MenuSectionEditorWindow(props) {
        const options = {
            props,
            component: MenuSectionEditor,
            name: 'MenuSectionEditor',
        };

        return ReactWindow.apply(this, [options]);
    },
});

export default MenuSectionEditorWindow;
