/**
 * Created by erinsasha on 29/08/16.
 */
PropertyBrowserStorage = {
    properties : null, // {<id> : Property} Ассоциативный массив свойств
    /**
     * Инициализирует базу свойств Плейса
     */
    init : function() {
        this._init_base_in_memory();
    },
    /**
     * Инициализирует базу свойств Плейса в оперативную память
     * из localStorage
     * @private
     */
    _init_base_in_memory : function() {
        if(this.properties == null) {
            if (localStorage.properties != null)
                this.properties = JSON.parse(localStorage.properties);
            else
                this.properties = {};
        }
    },
    /**
     * Обновляет локальную базу свойств мест/заведений на основе данных от сервера.
     * Новые свойства просто добавляются, старые перезатираются.
     *
     * @param server_properties {Array} Array of Property массив свойств от сервера
     */
    init_local_storage : function(server_properties) {
        this.init();
        if (server_properties == null)
            return;
        for(var i= 0, prop; i<server_properties.length; i++) {
            prop = server_properties[i];
            this.properties[prop.id] = prop;
        }
        this._save_memory_base_to_local_storage();
    },
    /**
     * Сохраняет в localStorage базу свойств мест/заведений из оперативной памяти
     * @private
     */
    _save_memory_base_to_local_storage : function() {
        localStorage.properties = JSON.stringify(this.properties);
    },
    /**
     * Возращается Property с указанным идентификатором
     *
     * @param id
     * @returns {Object} Property
     */
    get_property_by_id : function(id) {
        return this.properties[id];
    },
    /**
     * Возвращает массив Property с указанными идентификаторами
     *
     * @param ids {Array} Array of Integer - массив идентификаторов Property
     * @param sort_by_position {Boolean} true - результат будет отсортирован по атрибуту position по возврастанию
     * @param sort_by_title {Boolean} true - результат будет отсортирован по атрибуту title по возврастанию
     * @returns {Array} Array of Property - массив Property
     */
    get_properties_by_ids : function(ids, sort_by_position, sort_by_title) {
        var result = [];
        for(var i= 0, prop; i<ids.length; i++) {
            prop = this.properties[ids[i]];
            if (prop)
                result.push(prop);
        }
        if(sort_by_position) {
            PropertyBrowserStorage._sort_properties_array_by_position(result);
        } else if (sort_by_title) {
            PropertyBrowserStorage._sort_properties_array_by_title(result);
        }

        return result;
    },

};

/**
 * Сортирует массив @properties по атрибуту position по возврастанию
 *
 * @param properties
 * @private
 */
PropertyBrowserStorage._sort_properties_array_by_position = function(properties) {
    properties.sort(PropertyBrowserStorage._compare_properties_by_position);
};

/**
 * Сортирует массив @properties по атрибуту title по возврастанию
 *
 * @param properties
 * @private
 */
PropertyBrowserStorage._sort_properties_array_by_title = function(properties) {
    properties.sort(PropertyBrowserStorage._compare_properties_by_title);
};

/**
 * Сравнивает свойства @prop1 и @prop2 по их позиции (position)
 *
 * @param prop1 {Object} Property - свойство места / заведения
 * @param prop2 {Object} Property - свойство места / заведения
 * @private
 */
PropertyBrowserStorage._compare_properties_by_position = function(prop1, prop2) {
    if (prop1.position < prop2.position)
        return -1;
    else
        return 1;
};

/**
 * Сравнивает свойства @prop1 и @prop2 по их названию (title)
 *
 * @param prop1 {Object} Property - свойство места / заведения
 * @param prop2 {Object} Property - свойство места / заведения
 * @private
 */
PropertyBrowserStorage._compare_properties_by_title = function(prop1, prop2) {
    return prop1.title.toLocaleLowerCase().localeCompare(prop2.title.toLocaleLowerCase());
};

window.PropertyBrowserStorage = PropertyBrowserStorage; // TODO: remove globals, use module exports
// export default PropertyBrowserStorage;
