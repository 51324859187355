import { ItemView, ViewModel } from '@b2cmessenger/backbone';

import './Header.scss';
import template from './Header.jade'

import { createButton } from 'widgets/Buttons/Buttons';

/**
 * Header widget for Windows and Pages. Can have title and buttons
 * @listens disable Disables buttons
 * @listens enable Enable buttons
 */
const Header = ItemView.extend({
    template: template,

    tagName: "div",
    className: "widget header",

    ui: {
        title: '[data-js-title]'
    },

    bindings: {
        '@ui.title': 'text:c_Title'
    },

    computeds: {
        c_Title: {
            deps: ['title'],
            get: title => title || ''
        }
    },

    /**
     * @typedef {String|Object} ButtonDescription - Either simple button id or object that describes button parameters
     * @property {String} id - Id of the button (used in triggers and ui hash)
     * @property {String} [text] - Caption on button
     * @property {String} [icn=id] - Icon for button
     * @property {String} [class=""] - Class for button element
     * @see widgets/Buttons for list of available buttons
     */

    /**
     * @param {Object} options Options
     * @param {String} options.title - Title displayed between left buttons and right buttons
     * @param {ButtonDescription[]} [options.leftButtons] - Array of buttons displayed before title
     * @param {ButtonDescription[]} [options.rightButtons] - Array of buttons displayed after title
     * @fires {btnId}:click - Buttons trigger {btnId}:click events
     */
    initialize(options) {
        this.options = options;

        this.viewModel = new ViewModel({ title: this.options.title || '' });
    },

    onShow() {
        app.getHeaderPadding().then((padding) => {
            if (this.$el && this.$el.css && padding) {
                this.$el.css('padding-top', padding);
            }
        });
    },

    onRender() {
        _.each(this.options.leftButtons, btn => this._addButton(btn, true));
        _.each(this.options.rightButtons, btn => this._addButton(btn, false));
    },

    _addButton(options, left) {
        options = _.isObject(options) ? options : { id: options };
        const id = options.id,
            $btn = createButton(_.extend(options, { viewModel: this.viewModel }));

        $btn.click(event => {
            this.trigger(`${id}:click`);
            this.trigger(`${id}${left ? 'left' : 'right'}:click`);
        });

        if (left) {
            this.ui.title.before($btn);
        } else {
            this.$el.append($btn);
        }

        if (this.ui[`btn${id}`]) {
            this.ui[`btn${id}`].add($btn);
        } else {
            this.ui[`btn${id}`] = $btn;
        }
        this.ui[`btn${id}${left ? 'left' : 'right'}`] = $btn;
    }
});

export default Header;
