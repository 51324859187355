import GoogleAnalyticsProxy from './GoogleAnalyticsProxy';
import GoogleAnalyticsFirebaseProxy from './GoogleAnalyticsFirebaseProxy';
import settings from "../settings";
import PlatformFeature from "utils/PlatformFeature";

class GoogleAnalytics extends PlatformFeature {
    constructor(options) {
        super(options);
    }

    async initialize() {
        if (this.isCordova()) {
            this.initializePromise = GoogleAnalyticsFirebaseProxy.init();
        } else {
            this.initializePromise = GoogleAnalyticsProxy.init();
        }

        return this.initializePromise
            .then((apiSource) => {
                this.gaApi = apiSource;
            })
            .catch(error => {
                return Promise.reject(error);
            })
    }

    async setUserId(userId) {
        return this.ensureInitialized()
            .then(() => {
                new Promise((resolve, reject) => {
                    try {
                        this.gaApi.setUserId(userId);
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                });
            });
    }

    async set(key, val) {
        return this.ensureInitialized()
            .then(() => {
                new Promise((resolve, reject) => {
                    try {
                        this.gaApi.set(key, val);
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                });
            });
    }

    async send(eventName, options) {
        return this.ensureInitialized()
            .then(() => {
                new Promise((resolve, reject) => {
                    try {
                        this.gaApi.send(eventName, options);
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                });
            });
    }

    async setAppVersion(version) {
        return this.set('app_version', version);
    }

    async trackView(screen) {
        return this.ensureInitialized()
            .then(() => {
                new Promise((resolve, reject) => {
                    try {
                        this.gaApi.trackView(screen);
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                });
            });
    }

    async trackException(description, fatal) {
        return this.send('exception', {
            description: description,
            fatal: fatal
        });
    }

    async trackTiming(category, intervalInMilliseconds, name, label) {
        this.send('timing_complete', {
            event_category: category,
            name: name,
            value: intervalInMilliseconds,
            event_label: label
        });
    }

    async addCustomDimension(key, value) {
        return this.set('dimension' + key, value);
    }

    async enableUncaughtExceptionReporting(enable) {
        return this.ensureInitialized()
            .then(() => {
                new Promise((resolve, reject) => {
                    if (enable) {
                        if (!this._bindedUncaughtExceptionHandler) {
                            this._bindedUncaughtExceptionHandler = this._uncaughtExceptionHandler.bind(this)
                            window.addEventListener('error', this._bindedUncaughtExceptionHandler);
                        }
                    } else {
                        window.removeEventListener('error', this._bindedUncaughtExceptionHandler);
                        delete this._bindedUncaughtExceptionHandler;
                    }
                    resolve();
                });
            });
    }

    async _uncaughtExceptionHandler(err) {
        return this.trackException(err.message, true);
    }

    async setAnonymizeIp(val) {
        return this.set('anonymizeIp', val);
    }

    async setDebugMode(val) {
        return this.ensureInitialized()
            .then(() => {
                new Promise((resolve, reject) => {
                    this._isDebug = val;
                    resolve();
                });
            });
    }

    async trackEvent() {
        const args = Array.prototype.slice.call(arguments);
        return this.send('track:event', ...args);
    }

}

export default new GoogleAnalytics();
