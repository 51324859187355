import { takeEvery, takeLatest, put, select } from 'redux-saga/effects';
import {
    PERFORM_DISCARD_CART_ORDER,
    discardCartOrder,
    discardCartOrderCanceled, RESTORE_CART_FROM_DRAFT,
} from "./actions";
import { getMenuCartItems, getMenuCartState, getMenuPlaceId } from "store/menu/selectors";
import { resetCart } from "store/menu/cart/actions";
import { getCartFromOrder } from "store/menu/utils";
import { INSERT_ORDER_INTO_CART } from "store/menu/orders/actions";
import ConfirmModal from "windows/Modal/Confirm";
import { CartTypes } from "store/menu/reducers/cart";
import { upsertCartIntoCarts } from "store/menu/carts/actions";
import { getMenuDraftCartFromCartsByPlaceId } from "store/menu/carts/selectors";

function* performDiscardCartOrderAsync(action) {
    const { promptUser } = action.payload;

    if (promptUser) {
        const cartItems = yield select(getMenuCartItems);

        if (_.keys(cartItems).length > 0) {
            const confirm = yield new ConfirmModal({ message: 'Discard order?' }).show();

            if (!confirm) {
                yield put(discardCartOrderCanceled());
                return;
            }
        }
    }

    yield put(discardCartOrder());
}

function* insertOrderIntoCart(action) {
    try {
        const { order, placeId = yield select(getMenuPlaceId)} = action.payload;
        if (!order) {
            return;
        }

        const cart = yield select(getMenuCartState);

        if (cart && cart.type !== CartTypes.Edit && cart.order.length > 0) {
            yield put(upsertCartIntoCarts({
                ...cart,
                type: CartTypes.Draft,
                placeId
            }));
        }

        const newCart = yield getCartFromOrder(order);
        yield put(resetCart({
            ...newCart,
            type: CartTypes.Edit
        }));
    } catch (e) {
        showError(e);
    }
}

function* restoreCartFromDraftSaga(action) {
    try {
        const placeId = yield select(getMenuPlaceId);
        const draftCart = yield select(getMenuDraftCartFromCartsByPlaceId, placeId);
        yield put(resetCart(draftCart));
    } catch (e) {
        showError(e);
    }
}

export default function* watchMenuCartEffects() {
    yield takeLatest(PERFORM_DISCARD_CART_ORDER, performDiscardCartOrderAsync);
    yield takeEvery(INSERT_ORDER_INTO_CART, insertOrderIntoCart);
    yield takeEvery(RESTORE_CART_FROM_DRAFT, restoreCartFromDraftSaga);
}
