import { Model } from '@b2cmessenger/backbone';
import settings from 'settings';

const Status = {
    Unknown: -1,
    New: 0, // Только что создано, ждем реакции от бизнеса
    BusinessSuggestedChanges: 1, // Бизнес предложил изменения, ждем реакции от клиента
    ClientSuggestedChanges: 2, // Клиент предложил изменения, ждем реакции от бизнеса
    CancelledByClient: 3,
    RejectedByBusiness: 4,
    Confirmed: 5,
    Completed: 6
};

const StatusText = {
    [Status.Unknown]: 'Unknown',
    [Status.New]: 'New',
    [Status.BusinessSuggestedChanges]: 'Business requested changes',
    [Status.ClientSuggestedChanges]: 'Client requested changes',
    [Status.CancelledByClient]: 'Canceled',
    [Status.RejectedByBusiness]: 'Canceled by business',
    [Status.Confirmed]: 'Accepted',
    [Status.Completed]: 'Finished',
};

const OptSmokingArea = {
    Yes: 'yes',
    No: 'no'
};

const IncludeParams = {
    include_place: 1,
    include_user: 1,
    include_phone: 1,
    include_task_id: 1,
    include_task: 1,
    include_last_change_comment: 1
};

@Model.properties({
    urlRoot: settings.host + settings.serv_reservation.base,
    defaults: {
        place_id: null,
        user_id: null,
        phone_id: null,
        status: null,
        created_at: null,
        updated_at: null,
        last_change_comment_id: null,
        date_start: null, // Дата бронирования UTC (начало брони)
        date_start_local: null, // Дата бронирования по часовому поясу заведения (начало брони)
        date_end: null,
        date_end_local: null,
        opt_guest_count: null,
        opt_children_count: null,
        opt_smoking_area: null, // Enum [yes, no]
        place: null,
        user: null,
        phone: null,
        task_id: null,
        task: null,
        last_change_comment: null
    },

    computeds: {
        status: {
            deps: ['_status'],
            get(_status) {
                return _(Status).values().indexOf(_status) !== -1 ? _status : Status.Unknown;
            },
            set(val) {
                return { _status: val };
            }
        },
        isInActiveState: {
            deps: ['status'],
            get: status => [
                Status.New,
                Status.BusinessSuggestedChanges,
                Status.ClientSuggestedChanges, Status.Confirmed
            ].indexOf(status) !== -1
        },
        isAcceptableForAuthor: {
            deps: ['status'],
            get: status => [Status.BusinessSuggestedChanges].indexOf(status) !== -1
        },
        isAcceptableForBusiness: {
            deps: ['status'],
            get: status => [Status.ClientSuggestedChanges, Status.New].indexOf(status) !== -1
        },
        isNewReservation: {
            deps: ['status'],
            get: status => Status.New == status,
        },
        isRejected: {
            deps: ['status'],
            get: status => [Status.CancelledByClient, Status.RejectedByBusiness].indexOf(status) !== -1
        },
        isRejectable: {
            deps: ['status'],
            get: status => [Status.CancelledByClient, Status.RejectedByBusiness, Status.Completed, Status.Unknown].indexOf(status) === -1
        },
        opt_smoking_area: {
            deps: ['_opt_smoking_area'],
            get(_opt_smoking_area) {
                return _(OptSmokingArea).values().indexOf(_opt_smoking_area) !== -1 ? _opt_smoking_area : null;
            },
            set(val) {
                return { _opt_smoking_area: val };
            }
        },
        duration: {
            deps: ['date_start_local', 'date_end_local'],
            get(date_start_local, date_end_local) {
                return B2Cjs.datetimeServerToJS(date_end_local).getTime()
                    - B2Cjs.datetimeServerToJS(date_start_local).getTime();
            }
        },
        dateStart: {
            deps: ['date_start'],
            get: (date_start) => date_start && B2Cjs.datetimeServerToJS(date_start)
        },
        dateEnd: {
            deps: ['date_end'],
            get: (date_end) => date_end && B2Cjs.datetimeServerToJS(date_end),
        },
        updatedAt: {
            deps: ['update_at'],
            get(updated_at) {
                if (updated_at) {
                    if (_.isObject(updated_at) && updated_at.expression == "NOW()") {
                        return new Date;
                    } else {
                        const date = B2Cjs.datetimeServerToJS(updated_at);
                        if (date) {
                            return date;
                        }
                    }
                }

                return null;
            }
        }
    }
})
class ReservationModel extends Model {
    constructor(attributes, options) {
        super(attributes, options);
    }

    /** @returns {JQueryXHR} */
    sync(method, model, options) {
        const error = options.error,
            success = options.success,
            self = this;

        options.success = function (data, textStatus, jqXHR) {
            if (success) success.call(this, data, textStatus, jqXHR);
        };

        options.error = function (jqXHR, textStatus, errorThrown) {
            if (jqXHR.status == 422) {
                self._onServerResponse422(jqXHR, textStatus, errorThrown)
            }

            if (error) error.call(this, jqXHR, textStatus, errorThrown);
        };


        if (['read', 'create', 'patch'].includes(method)) {
            // all possible request params for both GET/POST and PATCH
            const requestParameters = {
                include_place: !!options.includePlace,
                include_user: !!options.includeUser,
                include_phone: !!options.includePhone,
                include_task_id: !!options.includeTaskId,
                include_task: !!options.includeTask,
                include_last_change_comment: !!options.includeLastChangeComment
            };

            if (method == 'create' || method == 'patch') {
                if (method == 'create') {
                    options.attrs = _.pick(options.attrs || model.toJSON(options),
                        'place_id', 'phone_id',
                        'date_start_local', 'date_end_local',
                        'opt_guests_count', 'opt_children_count', 'opt_smoking_area'
                    );
                } else if (method == 'patch') {
                    _.extend(requestParameters, {
                        include_comment_id: !!options.includeCommentId,
                        include_comment: !!options.includeComment,
                    });

                    options.attrs = _.pick(options.attrs || model.toJSON(options),
                        'status',
                        'date_start_local', 'date_end_local',
                        'opt_guests_count', 'opt_children_count', 'opt_smoking_area', 'phone_id'
                    );

                    _.extend(options.attrs, {
                        'last_change_comment_id': model.get('last_change_comment_id')
                    });
                }

                if (options.message) {
                    _.extend(options.attrs, {
                        message_text: options.message
                    });
                }
            }

            if (_.values(requestParameters).some(v => !!v)) {
                options.url = this.urlRoot;

                if (['read', 'patch'].includes(method)) {
                    options.url = options.url + '/' + this.id;
                }

                options.url = options.url + '?' + $.param(
                    _.chain(requestParameters)
                        .keys()
                        .filter(k => !!requestParameters[k])
                        .reduce((memo, key) => {
                            memo[key] = 1;
                            return memo;
                        }, {})
                        .value()
                )
            }

            return super.sync(method, model, options);
        } else {
            const err = new Error(`Sync method "${method}" is not supported`);
            if (options && options.error) {
                options.error.call(options.context, err);
            }
            //@ts-ignore
            return $.Deferred().reject(err);
        }
    }

    save(key, val, options) {
        if (key == null || typeof key === 'object') {
            options = val;
        }
        _.defaults(options || (options = {}), {
            patch: true
        });
        return super.save(...arguments);
    }

    toJSON(options) {
        return _.extend(
            super.toJSON(options),
            {
                status: this.get('status'),
                opt_smoking_area: this.get('opt_smoking_area')
            }
        );
    }

    _onServerResponse422(jqXHR, textStatus, errorThrown) {
        const fields = _.isArray(jqXHR.responseJSON) && jqXHR.responseJSON
            || (jqXHR.responseJSON.error && jqXHR.responseJSON.error.field) || [];

        this.validationError = _(fields).reduce((fields, o) => {
            let field = o.field && String(o.field),
                msg = o.message && String(o.message);

            if (field && msg) {
                fields[field] = msg;
            }

            return fields;
        }, _.create(null));

        this.trigger('invalid', this, this.validationError);
    }
}

_.assign(ReservationModel, {
    Status,
    StatusText,
    SmokingArea: OptSmokingArea,
    IncludeParams
});

export default ReservationModel;
