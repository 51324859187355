import { ItemView, ViewModel } from '@b2cmessenger/backbone';

import CategoryModel from 'models/CategoryModel';

import template from './Category.jade';
import './Category.scss';

import getNextPrettyColor from 'utils/randomPrettyColor';

const CategoryWidget = ItemView.extend({
    template,
    className: "widget category-widget",

    options: {
        showParentName: false,
        selectable: false,
        selection: undefined
    },

    ui: {
        icon: '[data-js-icon]',
        parentName: '[data-js-parent-name]',
        name: '[data-js-name]'
    },

    bindingFilters: {
        iconClass: icon_code => icon_code ? 'icon-' + icon_code : 'ion-md-disc',
        parentNameHtml: (showParentName, parents) => 
            showParentName && parents.length ?
                _(parents).reduceRight((name, parent) => name + `<span>${escapeHtml(parent.get('name'))}</span>`, []) :
                '',
        prettyColor: getNextPrettyColor
    },

    bindings: {
        ':el': 'attr:{"data-id":id},'+
                'classes:{'+
                    'selectable:selectable,selected:all(selectable,any(selected,all(hasSelectedChildren,allChildrenSelected))),'+
                    'partial:all(selectable,hasSelectedChildren,not(allChildrenSelected))'+
                '},'+
               'css:{"border-color":prettyColor(name)}',
        '@ui.icon': 'attr:{class:iconClass(icon_code)}',
        '@ui.parentName': 'classes:{hidden:not(all(showParentName,parents.length))},html:parentNameHtml(showParentName, parents)',
        '@ui.name': 'text:name'
    },

    triggers: {
        'click': 'select'
    },

    templateHelpers() {
        return _.extend({}, 
            this.bindingFilters,
            this.viewModel.toJSON({ computed: true }));
    },

    initialize() {
        this.viewModel = new ViewModel({
            showParentName: !!this.options.showParentName,
            selectable: !!this.options.selectable,
            selection: this.options.selection,
            selected: false,
            hasSelectedChildren: false,
            allChildrenSelected: false
        });

        const onSelectionChange = (m, selection) => {
            if(selection) {
                const updateSelected = (selected) => 
                    m.set({ selected: !!selected.get(this.model.id) });
                const updateHasSelectedChildren = (hasSelectedChildren) => 
                    m.set({ hasSelectedChildren: !!hasSelectedChildren.get(this.model.id) });
                const updateAllChildrenSelected = (allChildrenSelected) => 
                    m.set({ allChildrenSelected: !!allChildrenSelected.get(this.model.id) });

                const selected = selection.get('selected'),
                      hasSelectedChildren = selection.get('hasSelectedChildren'),
                      allChildrenSelected = selection.get('allChildrenSelected');

                this.listenTo(selected, 'update reset', updateSelected);
                this.listenTo(hasSelectedChildren, 'update reset', updateHasSelectedChildren);
                this.listenTo(allChildrenSelected, 'update reset', updateAllChildrenSelected);

                updateSelected(selected);
                updateHasSelectedChildren(hasSelectedChildren);
                updateAllChildrenSelected(allChildrenSelected);
            } else {
                m.set({
                    selected: false,
                    hasSelectedChildren: false,
                    allChildrenSelected: false
                });
            }
        };

        this.listenTo(this.viewModel, 'change:selection', onSelectionChange);
        onSelectionChange(this.viewModel, this.viewModel.get('selection'));

        const bindUIElementsOnce = _.once(() => {
            ItemView.prototype.bindUIElements.call(this);
        });

        const bindUIElementsOnceAndRestore = () => {
            delete this.bindUIElements;
            this.isRendered && bindUIElementsOnce();
        };

        this.listenToOnce(this.viewModel, 'change', bindUIElementsOnceAndRestore);
        this.listenToOnce(this.model, 'change', bindUIElementsOnceAndRestore);
    },

    bindUIElements: _.noop,

    onRender() {
        this.el.setAttribute('data-id', this.model.id);
        const selectable = this.viewModel.get('selectable');
        this.el.classList.toggle('selectable', selectable);
        this.el.classList.toggle('selected', selectable && (!!this.viewModel.get('selected') || !!this.viewModel.get('hasSelectedChildren') && !!this.viewModel.get('allChildrenSelected')));
        this.el.classList.toggle('partial', selectable && !!this.viewModel.get('hasSelectedChildren') && !this.viewModel.get('allChildrenSelected'));
        this.el.style.borderColor = getNextPrettyColor(this.model.get('name'))
    },

    showParentName(show) {
        show = _(show).isUndefined() ? true : !!show;
        this.viewModel.set('showParentName', show);
    },

    hideParentName() {
        this.showParentName(false);
    },

    setSelectable(selectable) {
        selectable = !!selectable;
        this.viewModel.set('selectable', selectable);
    }
});

export default CategoryWidget;
