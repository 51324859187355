import { Trait } from '@b2cmessenger/backbone';

export default class CollectionWithPagination extends Trait {
    constructor(options) {
        super();

        _.extend(this.parentOptions || (this.parentOptions = {}), {
            pageSize: 200
        });

        this.options = _.defaults(options || (options = {}), {
            restrictComparators: true
        });
    };

    fetch(fetch, trait, options, ...args) {
        _.defaults(options || (options = {}), {
            data: {},
            pageSize: this.options.pageSize || trait.parentOptions.pageSize,
            page: 1,
        });

        _.defaults(options.data, {
            'per-page': options.pageSize,
            'page': options.page,
        });

        if (this._comparator) {
            _.defaults(options.data, {
                'sort': this._comparatorAlias,
            });
        }

        const success = options.success;
        options.success = (collection, resp, options) => {
            if (success) {
                _.extend(options, {
                    pageSize: Number(options.xhr.getResponseHeader('X-Pagination-Per-Page')),
                    page: Number(options.xhr.getResponseHeader('X-Pagination-Current-Page')),
                    pageCount: Number(options.xhr.getResponseHeader('X-Pagination-Page-Count')),
                    totalCount: Number(options.xhr.getResponseHeader('X-Pagination-Total-Count'))
                });

                success.call(options.context, collection, resp, options);
            }
        };

        return fetch.call(this, options, ...args);
    };

    set(f, trait, models, options, ...args) {
        const enrichOptions = (_collection, _options) => {
            options.changes = _options.changes;
        }

        this.listenTo(this, 'update', enrichOptions);
        const ret = f.call(this, models, options, ...args);
        this.stopListening(this, 'update', enrichOptions);

        return ret;
    };

    applyTo(o) {
        const ret = super.applyTo(o);

        const comparators = _.reduce(this.options.comparators, (m, v, k) => {
            if (_.isFunction(v)) {
                m[k] = {
                    comparator: v,
                    alias: k
                };
            } else if (_.isString(v)) {
                m[k] = {
                    comparator: k,
                    alias: v
                };
            } else if (v.comparator) {
                m[k] = v;
            }

            return m;
        }, {})

        const restrictComparators = this.options.restrictComparators;

        if (comparators) {
            Object.defineProperties(o, {
                'comparator': {
                    get() {
                        return this._comparator;
                    },
                    set(val) {
                        if (val) {
                            const comparator = comparators[val];
                            if (!comparator) {
                                if (restrictComparators) {
                                    throw new TypeError(`Cannot set comparator to ${JSON.stringify(val)}: invalid value`);
                                } else {
                                    this._comparator = val;
                                    this._comparatorAlias = val;
                                }
                            } else {
                                this._comparator = comparator.comparator;
                                this._comparatorAlias = comparator.alias;
                            }
                        } else {
                            delete this._comparator;
                            delete this._comparatorAlias;
                        }
                    }
                },
                'comparators': {
                    value: comparators
                }
            });
        }

        return ret;
    };
};
