import React from 'react';
import { Provider } from 'react-redux';

import generalStore from 'store';

export default function withStore(WrappedComponent, store = generalStore) {
    return function (props = {}) {
        return (
            <Provider store={store}>
                <WrappedComponent {...props} />
            </Provider>
        );
    };
}
