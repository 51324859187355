import { Model, ViewModel, Collection, CollectionView } from '@b2cmessenger/backbone';
import settings from 'settings';

import PlaceModel from 'models/PlaceModel';
import UserModel from 'models/UserModel';
import MessageModel from 'models/MessageModel';
import LoginedUserModel from 'models/LoginedUserModel';

import Page from 'pages/Page';
import ContainerWithPullToRefresh from 'traits/ContainerWithPullToRefresh';

import HeaderView from 'widgets/Header/Header';
import FooterView from 'widgets/Footer/Footer';
import MessageWithCommentsView from 'widgets/MessageWithComments/MessageWithComments';

import LeftMenuWindow from 'windows/LeftMenu/LeftMenu';
import PlaceGalleryWindow from 'windows/PlaceGallery/PlaceGallery';
import UserSearchWindow from 'windows/UserSearch/UserSearch';
import AjaxError from 'utils/AjaxError';

import './TaskList.scss';
import template from './TaskList.jade';

import getNextPrettyColor from 'utils/randomPrettyColor';
import ContainerWithNotiicationsToRead from "traits/ContainerWIthNotificationsToRead";
import NotificationModel from 'models/NotificationModel';
import ViewWithWindows from 'traits/ViewWithWindows';

const pullToRefreshTrait = {
    Trait: ContainerWithPullToRefresh,
    options: {
        container: '[data-js-tasks-container]',
    }
};

const notificationsReadTrait = {
    Trait: ContainerWithNotiicationsToRead,
    options: {
        container: '[data-js-content]',
        notificationElSelector: '[data-js-tasks] > div [data-notification-ids]'
    }
};

const withWindowsTrait = {
    Trait: ViewWithWindows,
    options: {
        windowMap: [
            {
                cls: PlaceGalleryWindow,
                trigger() {
                    this.footer.currentView.trigger('gallery:click');
                }
            },
            {
                cls: UserSearchWindow,
                trigger() {
                    pageTasksList.jqBtnFilter.click();
                    this.ui.sentToChooseBtn.click();
                }
            }
        ],
    }
};

const TaskListPage = Page.extendWithTraits([pullToRefreshTrait, notificationsReadTrait, withWindowsTrait], {
    isSingleTaskMode() {
        return !!pageTasksList.single_task_id;
    },

    getSingleTaskId() {
        return pageTasksList.single_task_id;
    },

    isMultiPlaceMode() {
        return pageTasksList.multi_place_mode;
    },

    getSinglePlaceId() {
        return !pageTasksList.multi_place_mode && pageTasksList.placeId;
    },

    isSingleUserMode() {
        return this.model.get('singleUserMode');
    },

    getSingleUserId() {
        return this.isSingleUserMode() && this.model.get('user_id');
    },

    hasTask(id) {
        return !!this.collection.get(id);
    },

    addMessage(...args) {
        this._initAsPage();

        return pageTasksList.addMessage(...args);
    },

    goToTask(...args) {
        this._initAsPage();

        return pageTasksList.goToTask(...args);
    },

    goToComment(taskId, commentId, subCommentId) {
        this._initAsPage();

        return pageTasksList.goToComment(taskId, commentId, subCommentId);
    },

    addUser(user) {
        this._initAsPage();

        this.taskListPage.userCollection.add(user);
    },

    markAsDirty() {
        pageTasksList._dirty = true;
    },

    show(options) {
        this._initAsPage();

        if (this.loginedUserModel.id != LoginedUserHandler.getLoginedUserId()) {
            this.markAsDirty();
            this.loginedUserModel.set(_.extend({},
                _.defaults({}, LoginedUserHandler.getLoginedUser(), UserModel.prototype.defaults, { id: null }),
                {
                    workplaceRoles: LoginedUserHandler.get_work_places_roles()
                }
            ));
        } else {
            const oldWorkplaceRoles = this.loginedUserModel.get('workplaceRoles');
            if (!_.isEqual(oldWorkplaceRoles, LoginedUserHandler.get_work_places_roles())) {
                this.loginedUserModel.set(_.extend({},
                    _.defaults({}, LoginedUserHandler.getLoginedUser()),
                    {
                        workplaceRoles: LoginedUserHandler.get_work_places_roles()
                    }
                ));
            } else {
                this.loginedUserModel.set(LoginedUserHandler.getLoginedUser());
            }
        }

        this.model.set({ singleUserMode: !!(options.singleUserMode && options.filter && options.filter.user_id) });

        if (pageTasksList._dirty ||
            options.reload ||
            options.addMessage || options.addUser ||
            options.place ||
            options.placeId != pageTasksList.placeId ||
            !!options.multi_place_mode != pageTasksList.multi_place_mode ||
            options.single_task_id != pageTasksList.single_task_id ||
            !!options.singleUserMode != !!this.model.get('singleUserMode') ||
            (options.singleUserMode && options.filter && options.filter.user_id) != this.model.get('user_id') ||
            options.includeUnverified
        ) {
            pageTasksList.set_show_params(
                _.pick(options, [
                    'reload',
                    'addMessage', 'addUser',
                    'place', 'placeId',
                    'multi_place_mode',
                    'single_task_id',
                    'singleUserMode',
                    'filter',
                    'scrollToTask',
                    'scrollToComment',
                    'scrollToSubComment',
                    'optionalLoad',
                    'includeUnverified',
                    'includeReservation'
                ]),
                this.$el,
                () => {
                    this.viewModel.trigger('check:notifications:in:viewport');
                }
            );
        }

        const headerView = this.header.currentView;
        if (headerView) {
            if (pageTasksList.single_task_id) {
                const messageModel = this.collection.get(pageTasksList.single_task_id);
                if (messageModel) {
                    headerView.options.title = `Task: ${messageModel.get('subject')}`;
                    headerView.render();
                } else {
                    headerView.options.title = `Loading...`;
                    headerView.render();
                    pageTasksList.on_load_once = success => {
                        const messageModel = this.collection.get(pageTasksList.single_task_id);
                        if (success && pageTasksList.single_task_id && messageModel) {
                            headerView.options.title = `Task: ${messageModel.get('subject')}`;
                            headerView.render();
                        }
                    }
                }
            } else {
                headerView.options.title = `Tasks and Posts`;
                headerView.render();
            }
        }

        const ret = Page.prototype.show.apply(this, arguments);

        if (options.scrollToTask && options.scrollToComment) {
            this.goToComment(options.scrollToTask, options.scrollToComment, options.scrollToSubComment)
                .then(() => {
                    this.viewModel.trigger('check:notifications:in:viewport');
                })
                .catch(() => {
                    this.goToTask(options.scrollToTask);
                    this.viewModel.trigger('check:notifications:in:viewport');
                });
        } else if (options.scrollToTask) {
            this.goToTask(options.scrollToTask);
        }

        this.viewModel.trigger('check:notifications:in:viewport');
    },

    cancel() {
        if (pageTasksList.jqPageTasksList.hasClass('filter_view')) {
            pageTasksList.toggle_filter_results_views(true);
            return true;
        }

        return Page.prototype.cancel.apply(this);
    },

    attributes: { id: 'page_task_list' },
    template: template,
    className: "task-list-page",

    regions: {
        header: '[data-js-header]',
        tasks: '[data-js-tasks]',
        footer: '[data-js-footer]',
    },

    ui: {
        rating: '[data-js-rating]',
        subject: '[data-js-subject]',
        onlyBroadcastGroup: '[data-js-group-only-broadcast]',
        onlyBroadcast: '[data-js-only-broadcast]',
        postedBy: '[data-js-postedby]',
        myTasks: '[data-js-my-tasks]',
        authorGroup: '[data-js-group-author]',
        author: '[data-js-author]',
        authorChooseBtn: '[data-js-btn-author-choose]',
        authorClearBtn: '[data-js-btn-author-clear]',
        authorVisibility: '[data-js-author-visibility]',
        authorVerified: '[data-js-author-verified]',
        sentToMe: '[data-js-sent-to-me]',
        sentToMeGroup: '[data-js-group-only-to-me]',
        sentToGroup: '[data-js-group-sent-to]',
        sentTo: '[data-js-sent-to]',
        sentToChooseBtn: '[data-js-btn-sent-to-choose]',
        sentToClearBtn: '[data-js-btn-sent-to-clear]',
        taskVisibility: '[data-js-task-visibility]',
        taskHasSolution: '[data-js-task-has-solution]',
        createdAfter: '[data-js-created-after]',
        createdBefore: '[data-js-created-before]',
        tags: '[data-js-tags]'
    },

    bindingHandlers: {
        ratingValue: {
            init($element, value) {
                const $input = $element.children('input');
                $element.b2crating({
                    onRatingChange: rating => {
                        $input.val(rating);
                        $input.change();
                    }
                });
                $element.b2crating('set', value);
            },
            get($element) {
                const $input = $element.children('input');
                return $input.val();
            },
            set($element, value) {
                const $input = $element.children('input');
                try {
                    if ($input.val() + '' != value + '') {
                        $input.val(value);
                    }
                    $element.b2crating('set', value);
                } catch (error) {
                }
            }
        },
        ratingDisabled: {
            set($element, value) {
                const data = $element.data('b2crating');
                _.defaults(data, { options: { readOnly: false } });
                if (value) {
                    $element.addClass('disabled');
                    data.options.readOnly = true;
                } else {
                    $element.removeClass('disabled');
                    data.options.readOnly = false;
                }
                $element.data('b2crating', data);
            },
        },
        sliderValue: {
            get($element) {
                return $element.val();
            },
            set($element, value) {
                try {
                    if ($element.val() + '' != value + '') {
                        $element.val(value);
                        $element.slider && $element.slider('instance') && $element.slider('refresh');
                    }

                } catch (error) {
                }
            }
        },
        sliderDisabled: {
            set($element, value) {
                $element.prop('disabled', !!value);
                $element.slider && $element.slider('instance') && (value ? $element.slider('disable') : $element.slider('enable'))
            },
        },
        multiToggleValue: {
            init($element, value) {
                const $values = $element.find('.values > .togglevalue');

                $values.click(function (e) {
                    if ($element.attr('data-disabled') || $element.prop('disabled')) {
                        return;
                    }

                    const $this = $(this);
                    if ($this.hasClass('set')) {
                        if ($values.not(this).filter('.set').length) {
                            $this.removeClass('set');
                            $element.change();
                        }
                    } else {
                        $this.addClass('set');
                        $element.change();
                    }
                });
            },
            get($element) {
                const $values = $element.find('.values > .togglevalue'),
                    $selected = $values.filter('.set');

                return $values.length == $selected.length ? null : _($selected.toArray()).map(el => $(el).data('val'));
            },
            set($element, value) {
                const $values = $element.find('.values > .togglevalue');

                if (_.isNull(value) || _.isUndefined(value) || _.isArray(value) && !value.length) {
                    $values.addClass('set');
                } else {
                    const vals = _.isArray(value) ? value.slice() : [value];

                    $values.each((index, el) => {
                        const $el = $(el),
                            i = _(vals).indexOf($el.data('val'));
                        if (i != -1) {
                            vals.splice(i, 1);
                            $el.addClass('set');
                        } else {
                            $el.removeClass('set');
                        }
                    })
                }
            }
        },
        multiToggleDisabled: {
            set($element, value) {
                $element.prop('disabled', !!value);
                $element.attr('data-disabled', !!value || null);
            }
        },
        dateValue: {
            get($element) {
                return B2Cjs.datetimeInputToJs($element.val());
            },
            set($element, value) {
                value = B2Cjs.datetimeJsToInput(value);
                try {
                    if ($element.val() != value) {
                        $element.val(value);

                        _.defer(() => $element[0].dispatchEvent(new Event('update', {
                            bubbles: true,
                            cancelable: true,
                        })));
                    }
                } catch (e) {
                }
            }
        },
        tagsValue: {
            init($element, value) {
                $element.b2ctags({
                    onChanged: tags => {
                        //$element.find("input[type='hidden'].value").first().change()
                    }
                });
                $element.b2ctags('set', value || null);
            },
            get($element) {
                const tags = $element.b2ctags('get');
                return tags && tags.slice() || [];
            },
            set($element, value) {
                $element.b2ctags('set', value || null);
            }
        }
    },

    bindingFilters: {
        bool: {
            get: bool => String(!!bool),
            set: str => str == "true",
        }
    },

    onUnreadNotificationElIsVisible(trait, els) {
        if (!trait.o.isActive) {
            return;
        }

        const ids = _.chain(els)
            .reduce((memo, el) => {
                _.each(el.getAttribute('data-notification-ids').split(','), id => {
                    memo[id] = 1;
                });

                el.removeAttribute('data-notification-ids');

                return memo;
            }, {})
            .keys()
            .map(Number)
            .value();

        notificationCollection.markAsReadByIds(ids);
        console.log(`Mark as read notifications: ${ids.join(',')}`, els);
    },

    bindings: {
        '@ui.rating': 'ratingValue:integer(rating),ratingDisabled:isOnlyBroadcast',
        '@ui.subject': 'value:subject',
        '@ui.onlyBroadcastGroup': 'classes:{hidden:not(isEmployee)}',
        '@ui.onlyBroadcast': 'sliderValue:bool(isOnlyBroadcast),sliderDisabled:not(isEmployee),classes:{hidden:not(isEmployee)}',
        '@ui.postedBy': 'multiToggleValue:postedByArr,multiToggleDisabled:isOnlyBroadcast',
        '@ui.myTasks': 'sliderValue:bool(isMyTasks),sliderDisabled:any(not(isUserLoggedin),singleUserMode)',
        '@ui.author': 'value:authorName',
        '@ui.authorGroup': 'classes:{empty:not(isAuthor),disabled:any(isMyTasks,singleUserMode)}',
        '@ui.authorChooseBtn': 'disabled:any(isMyTasks,singleUserMode)',
        '@ui.authorClearBtn': 'disabled:singleUserMode',
        '@ui.authorVisibility': 'multiToggleValue:authorVisibilityArr,multiToggleDisabled:any(isOnlyBroadcast,all(isAuthor,not(isMyTasks)))',
        '@ui.authorVerified': 'multiToggleValue:authorVerifiedArr,multiToggleDisabled:any(isOnlyBroadcast,isAuthor,isPostedByBusiness)',
        '@ui.sentToMe': 'sliderValue:bool(isSentToMe),sliderDisabled:any(isOnlyBroadcast,not(isUserLoggedin))',
        '@ui.sentToMeGroup': 'classes:{disabled:any(isOnlyBroadcast,not(isUserLoggedin))}',
        '@ui.sentTo': 'value:sentToName',
        '@ui.sentToGroup': 'classes:{empty:not(isSentTo),disabled:any(isOnlyBroadcast,isSentToMe,not(isEmployee)),hidden:not(isEmployee)}',
        '@ui.sentToChooseBtn': 'disabled:any(isOnlyBroadcast,isSentToMe,not(isEmployee))',
        '@ui.taskVisibility': 'multiToggleValue:taskVisibilityArr,multiToggleDisabled:any(isOnlyBroadcast,isSentTo)',
        '@ui.taskHasSolution': 'multiToggleValue:taskHasSolutionArr,multiToggleDisabled:isOnlyBroadcast',
        '@ui.createdAfter': 'dateValue:createdAfter',
        '@ui.createdBefore': 'dateValue:createdBefore',
        '@ui.tags': 'tagsValue:tags'
    },

    events: {
        'click @ui.author'(e) {
            if (this.ui.author.is(e.currentTarget)) {
                this.ui.authorChooseBtn.click();
            }
        },
        'click @ui.authorChooseBtn'(e) {
            new UserSearchWindow({ onEmptyResult: null }).show().then(user => {
                if (user) {
                    this.model.set({
                        user_id: user.id,
                        authorName: user.get('name')
                    });
                }
            });
        },
        'click @ui.authorClearBtn'(e) {
            this.model.set({
                user_id: null,
                authorName: null
            });
        },
        'click @ui.sentTo'(e) {
            this.ui.sentToChooseBtn.click();
        },
        'click @ui.sentToChooseBtn'(e) {
            new UserSearchWindow({ onEmptyResult: null }).show().then(user => {
                if (user) {
                    this.model.set({
                        to_user_id: user.id,
                        sentToName: user.get('name')
                    });
                }
            });
        },
        'click @ui.sentToClearBtn'(e) {
            this.model.set({
                to_user_id: null,
                sentToName: null
            });
        }
    },

    initialize() {
        this.model = new (Model.extend({
            defaults: {
                singleUserMode: false,
                offset: 0,
                limit: 10,
                rating: null,
                subject: '',
                tags: [],
            },
            computeds: {
                rating: {
                    deps: ['_rating', 'isOnlyBroadcast'],
                    get: (_rating, isOnlyBroadcast) => isOnlyBroadcast ? null : _rating || null,
                    set: val => _.create(null, { _rating: Number(val) || null })
                },
                isUserLoggedin: {
                    deps: ['loginedUser'],
                    get: loginedUser => !!loginedUser
                },
                isEmployee: {
                    deps: ['loginedUser', 'place_id'],
                    get: (loginedUser, place_id) => !!(loginedUser && place_id && LoginedUserHandler.isUserEmployee(place_id))
                },
                isOnlyBroadcast: {
                    deps: ['_isOnlyBroadcast', 'isEmployee'],
                    get: (_isOnlyBroadcast, isEmployee) => isEmployee && !!_isOnlyBroadcast,
                    set: val => _.create(null, { _isOnlyBroadcast: !!val })
                },
                istarget: {
                    deps: ['isOnlyBroadcast'],
                    get: isOnlyBroadcast => isOnlyBroadcast ? 1 : 0,
                    set: val => _.create(null, { isOnlyBroadcast: !!Number(val) })
                },
                postedby: {
                    deps: ['_postedby', 'isOnlyBroadcast'],
                    get: (_postedby, isOnlyBroadcast) => isOnlyBroadcast ? null : _postedby,
                    set: val => _.create(null, { _postedby: val == 1 ? 1 : val == -1 ? -1 : null })
                },
                postedByArr: {
                    deps: ['postedby'],
                    get: postedby => postedby == -1 ? [-1] : postedby == 1 ? [1] : [],
                    set: val => _.create(null, { postedby: val && val.length ? val[0] : null })
                },
                isPostedByBusiness: {
                    deps: ['postedby'],
                    get: postedby => postedby == 1
                },
                isAuthor: {
                    deps: ['user_id'],
                    get: user_id => !!user_id
                },
                isMyTasks: {
                    deps: ['user_id', 'loginedUser'],
                    get: (user_id, loginedUser) => loginedUser && user_id == loginedUser.id,
                    set(val) {
                        const loginedUser = this.get('loginedUser');
                        if (val) {
                            if (loginedUser) {
                                return { user_id: loginedUser.id };
                            } else {
                                return { user_id: null };
                            }
                        } else {
                            const user_id = this.get('user_id');
                            if (loginedUser && user_id == loginedUser.id) {
                                return { user_id: null };
                            }
                        }
                        return {};
                    }
                },
                authorName: {
                    deps: ['_authorName', 'user_id', 'loginedUser'],
                    get: (_authorName, user_id, loginedUser) => (loginedUser && loginedUser.id == user_id) ?
                        (loginedUser.firstname + (loginedUser.lastname ? (' ' + loginedUser.lastname) : '')) :
                        (user_id && _authorName || ""),
                    set: val => _.create(null, { _authorName: !!val && String(val) || "" })
                },
                authorvisibility: {
                    deps: ['_authorvisibility', 'isOnlyBroadcast', 'isMyTasks', 'isAuthor'],
                    get: (_authorvisibility, isOnlyBroadcast, isMyTasks, isAuthor) => (isOnlyBroadcast || isAuthor && !isMyTasks) ? null : _authorvisibility,
                    set: val => _.create(null, { _authorvisibility: val == 1 ? 1 : val == 0 ? 0 : null })
                },
                authorVisibilityArr: {
                    deps: ['authorvisibility'],
                    get: authorvisibility => authorvisibility == 1 ? [1] : authorvisibility == 0 ? [0] : [],
                    set: val => _.create(null, { authorvisibility: (val && val.length) ? val[0] : null })
                },
                isuserverified: {
                    deps: ['_isuserverified', 'isOnlyBroadcast', 'isAuthor', 'isPostedByBusiness'],
                    get: (_isuserverified, isOnlyBroadcast, isAuthor, isPostedByBusiness) =>
                        (isOnlyBroadcast || isAuthor || isPostedByBusiness) ? null : _isuserverified,
                    set: val => _.create(null, { _isuserverified: val == 1 ? 1 : val == 0 ? 0 : null })
                },
                authorVerifiedArr: {
                    deps: ['isuserverified'],
                    get: isuserverified => isuserverified == 1 ? [1] : isuserverified == 0 ? [0] : [],
                    set: val => _.create(null, { isuserverified: (val && val.length) ? val[0] : null })
                },
                to_user_id: {
                    deps: ['_to_user_id', 'isOnlyBroadcast', 'isEmployee', 'loginedUser'],
                    get: (_to_user_id, isOnlyBroadcast, isEmployee, loginedUser) => (isOnlyBroadcast || (!isEmployee && !(loginedUser && _to_user_id && loginedUser.id == _to_user_id))) ? null : _to_user_id,
                    set: val => _.create(null, { _to_user_id: val })
                },
                isSentTo: {
                    deps: ['to_user_id'],
                    get: to_user_id => !!to_user_id
                },
                isSentToMe: {
                    deps: ['to_user_id', 'loginedUser'],
                    get: (to_user_id, loginedUser) => loginedUser && to_user_id == loginedUser.id,
                    set(val) {
                        const loginedUser = this.get('loginedUser');
                        if (val) {
                            if (loginedUser) {
                                return { to_user_id: loginedUser.id };
                            } else {
                                return { to_user_id: null };
                            }
                        } else {
                            const to_user_id = this.get('to_user_id');
                            if (loginedUser && to_user_id == loginedUser.id) {
                                return { to_user_id: null };
                            }
                        }
                        return {};
                    }
                },
                sentToName: {
                    deps: ['_sentToName', 'to_user_id', 'loginedUser'],
                    get: (_sentToName, to_user_id, loginedUser) => (loginedUser && loginedUser.id == to_user_id) ?
                        (loginedUser.firstname + (loginedUser.lastname ? (' ' + loginedUser.lastname) : '')) :
                        (to_user_id && _sentToName || ""),
                    set: val => _.create(null, { _sentToName: !!val && String(val) || "" })
                },
                messvisibility: {
                    deps: ['_messvisibility', 'isOnlyBroadcast', 'isSentTo'],
                    get: (_messvisibility, isOnlyBroadcast, isSentTo) => isOnlyBroadcast ? null : isSentTo ? 0 : _messvisibility,
                    set: val => _.create(null, { _messvisibility: val == 1 ? 1 : val == 0 ? 0 : null })
                },
                taskVisibilityArr: {
                    deps: ['messvisibility'],
                    get: messvisibility => messvisibility == 1 ? [1] : messvisibility == 0 ? [0] : [],
                    set: val => _.create(null, { messvisibility: (val && val.length) ? val[0] : null })
                },
                hassolution: {
                    deps: ['_hassolution', 'isOnlyBroadcast'],
                    get: (_hassolution, isOnlyBroadcast) => isOnlyBroadcast ? null : _hassolution,
                    set: val => _.create(null, { _hassolution: val == 1 ? 1 : val == 0 ? 0 : null }),
                },
                taskHasSolutionArr: {
                    deps: ['hassolution'],
                    get: hassolution => hassolution == 1 ? [1] : hassolution == 0 ? [0] : [],
                    set: val => _.create(null, { hassolution: (val && val.length) ? val[0] : null })
                },
                createdAfter: {
                    deps: ['publishedafter'],
                    get: publishedafter => publishedafter && B2Cjs.datetimeServerToJS(publishedafter) || null,
                    set: val => _.create(null, { publishedafter: val && B2Cjs.datetimeJSToServer(val) || null })
                },
                createdBefore: {
                    deps: ['publishedbefore'],
                    get: publishedbefore => publishedbefore && B2Cjs.datetimeServerToJS(publishedbefore) || null,
                    set: val => _.create(null, { publishedbefore: val && B2Cjs.datetimeJSToServer(val) || null })
                }
            }
        }))({
            loginedUser: LoginedUserHandler.getLoginedUser(),
        });

        //this.viewModel = new ViewModel();
        if (!this.viewModel) {
            this.viewModel = new ViewModel;
        }

        this.loginedUserModel = new LoginedUserModel(_.extend({},
            _.defaults({}, LoginedUserHandler.getLoginedUser(), UserModel.prototype.defaults, { id: null }),
            {
                workplaceRoles: LoginedUserHandler.get_work_places_roles()
            }
        ));

        this.collection = new Collection([], {
            model: MessageModel,
            comparator: '_publishedAtValueDesc'
        });

        this.userCollection = new Collection([], {
            model: UserModel
        });

        this.placeCollection = new Collection([], {
            model: PlaceModel,
        });

        if (this.loginedUserModel.get('isLoggedIn')) {
            this.userCollection.add(this.loginedUserModel.clone({ asUserModel: true }));
        }

        this.listenTo(this.loginedUserModel, 'change:id', (m, id) => {
            if (id) {
                this.userCollection.add(m.clone({ asUserModel: true }));
            }
        });

        this.listenTo(notificationCollection, 'add', notification => {
            if (notification.get('isNewComment')) {
                const data = notification.get('data');
                if (data) {
                    const taskId =
                        Number(data.task_dummy_id)
                        && Number(data.place_id)
                        && LoginedUserHandler.isUserEmployee(Number(data.place_id))
                        && Number(data.task_dummy_id)
                        ||
                        Number(data.task_id);

                    const commentId = data.comment_id;

                    if (taskId && commentId) {
                        const taskModel = this.collection.get(taskId);
                        if (taskModel) {
                            if (notification.get('isTaskSolution')) {
                                taskModel.set({ hasSolution: true });
                            }

                            /**@type {MessageWithCommentsView} */
                            const taskView = this.tasks.currentView &&
                                this.tasks.currentView.children.findByModel(taskModel);

                            if (taskView) {
                                const parentId = data.parent_id;
                                const loadCommentHandler = (commentModel => {
                                    if (commentModel) {
                                        this._setUnreadNotificationsForComment(commentModel.id);

                                        this.viewModel.trigger('check:notifications:in:viewport');

                                        if (commentModel.get('isWithGift')) {
                                            taskModel.set('hasgift', 1);
                                        }
                                    }
                                }).bind(this);

                                if (parentId) {
                                    taskView.loadNewSubComment(parentId, commentId)
                                        .then(loadCommentHandler);
                                } else {
                                    taskView.loadNewComment(commentId, notification.get('isTaskSolution'))
                                        .then(loadCommentHandler);
                                }
                            }
                        }
                    }
                }
            } else if (notification.get('isNewTaskSolutionRating')) {
                const data = notification.get('data');
                const taskId = data.task_id;
                const commentId = data.comment_id;

                if (taskId && commentId) {
                    const taskModel = this.collection.get(taskId);
                    if (taskModel) {
                        /**@type {MessageWithCommentsView} */
                        const taskView = this.tasks.currentView &&
                            this.tasks.currentView.children.findByModel(taskModel);

                        if (taskView) {
                            taskView.updateComment(commentId);
                            this._setUnreadNotificationsForComment(commentId);
                            this.viewModel.trigger('check:notifications:in:viewport');
                        }
                    }
                }
            } else if (notification.get('isNewTask')) {
                const data = notification.get('data');

                if (data) {
                    this._setUnreadNotificationsForTask(data.task_id);
                }
            }
        });

        pageTasksList.taskListPage = this;
        pageTasksList.filter = this.model;
        this.pageTasksList = pageTasksList;
    },

    onPulledToRefresh() {
        pageTasksList.onFilterChanged();
    },

    onRender() {
        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: "Tasks and Posts",
            rightButtons: [{
                id: 'notifications',
                class: 'no-single-task'
            }, {
                id: 'menu',
                class: 'no-single-task'
            }, {
                id: 'list',
                class: 'single-task'
            }]
        });
        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.listenTo(headerView, 'notifications:click', () => app.controller.showNotificationsWindow());
        this.listenTo(headerView, 'menu:click', () => new LeftMenuWindow({ right: true }).show());
        this.listenTo(headerView, 'list:click', () => {
            const task_id = pageTasksList.single_task_id,
                messageModel = this.collection.get(pageTasksList.single_task_id),
                placeModel = messageModel && this.placeCollection.get(messageModel.get('place_id'));

            if (placeModel) {
                app.controller.goToPlaceTasksPage({ place: placeModel.toJSON() });
            }
        });
        this.header.show(headerView);

        let footerView = new FooterView({
            buttons: [{
                id: 'broadcast',
                text: 'BROADCASTS',
                class: 'results_view single_place business'
            }, {
                id: 'checkin',
                text: 'CHECK-IN',
                class: 'results_view single_place not-business'
            }, {
                id: 'newtask',
                text: 'NEW TASK',
                class: 'results_view single_place'
            }, {
                id: 'info',
                text: 'INFO',
                class: 'results_view single_place'
            }, {
                id: 'gallery',
                text: 'GALLERY',
                class: 'results_view single_place'
            }, {
                id: 'done',
                text: 'DONE',
                icn: 'empty',
                class: 'filter_view'
            }, {
                id: 'clear',
                text: 'CLEAR',
                icn: 'empty',
                class: 'filter_view'
            }]
        });

        this.listenTo(footerView, 'broadcast:click', () => app.controller.goToBroadcastCampaignsPage({ placeId: pageTasksList.placeId }));
        this.listenTo(footerView, 'checkin:click', () => {
            pageTasksList.jqBtnCheckIn.addClass('set');
            pageTasksList.server_checkin();
        });
        this.listenTo(footerView, 'newtask:click', () => pageTasksList.onBtnNewTaskClick());
        this.listenTo(footerView, 'info:click', () => pageTasksList.onBtnInfoClick());
        this.listenTo(footerView, 'gallery:click', () => pageTasksList.onBtnGalleryClick());
        this.listenTo(footerView, 'done:click', () => pageTasksList.toggle_filter_results_views());
        this.listenTo(footerView, 'clear:click', () => pageTasksList.onBtnClearClick());
        this.footer.show(footerView);

        this.tasks.show(new CollectionView({
            collection: this.collection,
            childView: MessageWithCommentsView,
            /**@returns {MessageWithCommentsView.Options} */
            childViewOptions: (child) => {
                return {
                    model: child,
                    userModel: this.userCollection.get(child.get('user_id')),
                    placeModel: this.placeCollection.get(child.get('place_id')),
                    loginedUserModel: this.loginedUserModel,
                    toUserModel: child.get('to_user_id') ? this.userCollection.get(child.get('to_user_id')) : null,
                    parentViewModel: this.viewModel,
                    showPlaceInfo: !!pageTasksList.multi_place_mode,
                    showCommentsOnRender: !!pageTasksList.single_task_id,
                }
            }
        }));

        this.listenTo(this.tasks.currentView, 'childview:full:page', (view) => {
            app.controller.goToTaskPage({ taskId: view.model.get('id') });
        });

        this.listenTo(this.tasks.currentView, 'childview:user:click', (cv, userModel, isAnonym, isFromBussines) => {
            const userIsAuthor = userModel && userModel.id == this.loginedUserModel.id;
            const userIsEmployee = this.loginedUserModel.isEmployee(cv.model.get('place_id'));

            if (userModel && (!isAnonym || userIsAuthor || userIsEmployee)) {
                app.controller.goToOtherUserPage({
                    user: userModel.toJSON(),
                    place: cv.placeModel.toJSON(),
                    anonym: isAnonym &&
                    !(userIsAuthor || isFromBussines && userIsEmployee)
                });
            }
        });

        this.listenTo(this.tasks.currentView, 'childview:place:click', (cv, placeModel) => {
            app.controller.goToPlacePage({ place: placeModel.toJSON() });
        });

        pageTasksList.jqBottomBar = footerView.$el;
        pageTasksList.jqBtnCheckIn = footerView.ui.btncheckin;
        pageTasksList.jqResultsCount = footerView.ui.btndone.find(".text");
        pageTasksList.initialize(this.$el);
        pageTasksList.showLoading = this.showLoading.bind(this);
        pageTasksList.hideLoading = this.hideLoading.bind(this);
        pageTasksList.showError = this.showError.bind(this);
        pageTasksList.showMessage = this.showMessage.bind(this);

        pageTasksList.jqPageTasksList.find('.create_task_fake').css('border-left-color', getNextPrettyColor());
    },

    onShow() {
        if (pageTasksList.page_top_position)
            $.mobile.silentScroll(pageTasksList.page_top_position);

        Page.prototype.onShow.call(this);
    },

    onHide() {
        pageTasksList.page_top_position = pageTasksList.jqFilterPannel.offset().top;

        Page.prototype.onHide.call(this);
    },

    on() {
        if (_.has(this._events, 'visibility:change')) {
            return Page.prototype.on.apply(this, arguments);
        } else {
            const ret = Page.prototype.on.apply(this, arguments);
            if (_.has(this._events, 'visibility:change')) {
                if (!this._onVisibilityChangedBinded) {
                    this._onVisibilityChangedBinded = this._onVisibilityChanged.bind(this)
                }

                this.$el.on('load resize scrollstop', this._onVisibilityChangedBinded);
            }
            return ret;
        }
    },

    off() {
        if (!_.has(this._events, 'visibility:change')) {
            return Page.prototype.off.apply(this, arguments);
        } else {
            const ret = Page.prototype.off.apply(this, arguments);
            if (!_.has(this._events, 'visibility:change')) {
                this.$el.off('load resize scrollstop', this._onVisibilityChangedBinded);
            }
            return ret;
        }
    },

    listenTo() {
        if (_.has(this._events, 'visibility:change')) {
            return Page.prototype.listenTo.apply(this, arguments);
        } else {
            const ret = Page.prototype.listenTo.apply(this, arguments);
            if (_.has(this._events, 'visibility:change')) {
                if (!this._onVisibilityChangedBinded) {
                    this._onVisibilityChangedBinded = this._onVisibilityChanged.bind(this)
                }

                this.$el.on('load resize scrollstop', this._onVisibilityChangedBinded);
            }
            return ret;
        }
    },

    stopListening() {
        if (!_.has(this._events, 'visibility:change')) {
            return Page.prototype.stopListening.apply(this, arguments);
        } else {
            const ret = Page.prototype.stopListening.apply(this, arguments);
            if (!_.has(this._events, 'visibility:change')) {
                this.$el.off('load resize scrollstop', this._onVisibilityChangedBinded);
            }
            return ret;
        }
    },

    _setUnreadNotificationsForComment(commentId) {
        if (this.isRendered) {
            const $commentEl = this.$(`[data-comment][data-id=${commentId}]`);

            if ($commentEl.length) {
                const notificationIds = notificationCollection.getNewVisibleNotificationIdsForComment(commentId);

                $commentEl.attr(
                    'data-notification-ids',
                    notificationIds.length ? notificationIds.join(',') : null
                );
            }
        }
    },

    _setUnreadNotificationsForTask(taskId) {
        if (this.isRendered) {
            const $taskEl = this.$(`[data-task][data-id=${taskId}]`);

            if ($taskEl.length) {
                const notificationIds = notificationCollection.getNewVisibleNotificationIdsForTask(taskId);

                $taskEl.attr(
                    'data-notification-ids',
                    notificationIds.length ? notificationIds.join(',') : null
                );
            }
        }
    },

    _onVisibilityChanged: _.debounce(function () {
        this.trigger('visibility:change');
    }, 300)
});

export default TaskListPage;

var pageTasksList = {
    page_id: 'page_task_list',  // идентификатор страницы
    page_top_position: null, // Положение верхнего бара на странице
    filterDefault: {},
    multi_place_mode: false, // true - страница в режиме отображения Тасков из нескольких Place
    isLoading: false, // маркер - что идет загрузка
    isAllLoaded: false, // маркер - что все таски соответсвующие текущему фильтру загржены

    scroll_pos_result: 0, // теущий позиция скрола окна в режиме показа списка результатов

    placeId: null, // PlaceId - id места, в рамках которого происходит отображение тасков
    jqPageTasksList: null, // {jQuery} данная страница
    jqBtnIdeas: null, // {jQuery} Кнопка быстрой фильтрации - показывать задачи с пометкой Idea
    jqBtnIssues: null, // {jQuery} Кнопка быстрой фильтрации - показывать задачи с пометкой Issue
    jqBtnQuestions: null, // {jQuery} Кнопка быстрой фильтрации - показывать задачи с пометкой Question
    jqBtnClear: null, // {jQuery} Кнопка - сбросить фильтры
    jqsFilters: {
        jqFiltersCont: null, // {jQuery} Контейнер с дополнительными фильтрами
        jqPostedby: null, // {jQuery} Виджет фильтрации по авторам Тасков (постов) (Клиента или Бизнес)
        jqAthorvisibility: null, // {jQuery} Виджет фильтрации по видимости автора таска (анонимный или публичный)
        jqIsuserverified: null, // {jQuery} Виджет фильтрации по верифицированности автора таска
        jqMessvisibility: null, // {jQuery} Виджет фильтрации по видимости таска (все или только бизнес и автор)
        jqHassolution: null, // {jQuery} Виджет фильтрации по наличию у таска решения
        jqRating: null, // {jQuery} Виджет фильтрации по рейтингу (User Experience Rating)
        jqMytasks: null, // {jQuery} Виджет фильтрации : показывать только созданные мной таски?
        jqPublishedBefore: null, // {jQuery} Виджет фильтрации по дате публикации (созданные до)
        jqPublishedAfter: null, // {jQuery} Виджет фильтрации по дате публикации (созданные после)
        jqTags: null, // {jQuery} Виджет фильтрации по тегам
    },

    initialize: function ($el) {
        if (this.inited)
            return;

        var that = this;

        this.jqPageTasksList = $el;

        this.initTopBar();
        this.initContentCont();

        this.filter.on('change:place_id change:user_id change:to_user_id change:istarget ' +
            'change:to_user_name change:postedby change:subject change:authorvisibility ' +
            'change:messvisibility change:idea change:issue change:question change:isuserverified ' +
            'change:hassolution change:rating change:publishedafter change:publishedbefore ' +
            'change:tags change:needuserinfo change:needplaceinfo',
            this.onFilterChanged.bind(this));

        this.inited = true;
    },

    initTopBar: function () {
        var that = this;
        // {jQuery} Панель с быстрыми фильтрами и кнопкой показать/скрыть прочие фильтры
        this.jqFilterPannel = this.jqPageTasksList.children('.filter-panel-region');
        // {jQuery} Кнопка быстрой фильтрации - показывать задачи с пометкой Idea
        this.jqBtnIdeas = this.jqFilterPannel.find('.btn-idea').click(function (event) {
            that.onBtnTaskTypeClick($(this), event)
        });
        // {jQuery} Кнопка быстрой фильтрации - показывать задачи с пометкой Issue
        this.jqBtnIssues = this.jqFilterPannel.find('.btn-issue').click(function (event) {
            that.onBtnTaskTypeClick($(this), event)
        });
        // {jQuery} Кнопка быстрой фильтрации - показывать задачи с пометкой Question
        this.jqBtnQuestions = this.jqFilterPannel.find('.btn-question').click(function (event) {
            that.onBtnTaskTypeClick($(this), event)
        });
        // {jQuery} кнопка показать/скрыть прочие фильтры
        this.jqBtnFilter = this.jqFilterPannel.find('.btn-filter').click(function (event) {
            that.onBtnFilterClick($(this), event)
        });
    },

    initContentCont: function () {
        var that = this; // Для замыкания
        // {jQuery} Контейнер для контента
        var jqContent = this.jqPageTasksList.children('.content-region');
        // Инициализирует фейковый виджет создания Тасков. Клик по нему будет переводить на экран создания таска
        this.init_create_task_fake(jqContent.children('.create_task_fake'));
        // {jQuery} Контейнер для списка Тасков
        this.jqTasksList = jqContent.children('[data-js-tasks-container]').children('[data-js-tasks]');
        // {jQuery} Контейнер с детальными фильтрами
        this.jqsFilters.jqFiltersCont = jqContent.children('.filters_cont');
        // Ловим события скрола
//////////////////////
        jqContent.on('scrollstop', function (event) {
            that.on_scrollstop(event)
        });
////////////////
///////////////////////////////////////////////////////
/////////////////////////////////////
///////////
/////////////////
    },

    init_create_task_fake: function (jq_create_task_fake) {
        var that = this;

        var avatar_src, user_name;
        var loginedUser = LoginedUserHandler.getLoginedUser();
        if (loginedUser) {
            avatar_src = loginedUser.avatarThumb;
            user_name = B2CUser.get_full_name(loginedUser);
        } else {
            user_name = 'Anonymous';
        }
        if (avatar_src == null)
            avatar_src = B2CUser.const.avatar.noavatar;

        jq_create_task_fake.click(function () {
            that.onBtnNewTaskClick()
        });

        this.jq_fake_task_avatar = jq_create_task_fake.find('.avatar');
        this.jq_fake_task_avatar.attr('src', avatar_src);

        this.jq_fake_task_username = jq_create_task_fake.find('.username');
        this.jq_fake_task_username.text(user_name);
    },

    refresh_create_task_fake: function () {
        var avatar_src, user_name;
        var loginedUser = LoginedUserHandler.getLoginedUser();
        if (loginedUser) {
            avatar_src = loginedUser.avatarThumb;
            user_name = B2CUser.get_full_name(loginedUser);
        } else {
            user_name = 'Anonymous';
        }
        if (avatar_src == null)
            avatar_src = B2CUser.const.avatar.noavatar;
        this.jq_fake_task_avatar.attr('src', avatar_src);
        this.jq_fake_task_username.text(user_name);
    },

    addMessage: function (message) {
        const messageModel = new MessageModel(_.extend({}, message, {
            includeUnverified: this.filter.get('isuserverified') != 1,
            parentMessageModel: null,
            notificationMuteCollection: app.notificationMuteCollection,
            _publishedAtValueDesc: -(B2Cjs.datetimeServerToJS(message.published_at || message.created_at || message.updated_at) || 0)
        }));

        if (this.taskListPage.isSingleTaskMode()) {
            const model = this.taskListPage.collection.get(messageModel.id);
            if (model && (model.get('isCorruptedReservationTask') && messageModel.get('isCorruptedReservationTask')) ||
                !model && messageModel.get('isCorruptedReservationTask')) {
                messageModel.loadReservationIfNotExist()
                    .then(() => {
                        this.taskListPage.collection.add(messageModel, { merge: true });
                    })
                    .catch(e => this.showError(e));
                return;
            }
        }

        this.taskListPage.collection.add(messageModel, { merge: true });
    },

    removeMessage(id) {
        this.taskListPage.collection.remove(id);
    },

    goToTask: function (taskid) {
        const messageModel = this.taskListPage.collection.get(taskid);
        if (messageModel) {
            const messageView = this.taskListPage.tasks.currentView.children.findByModel(messageModel);
            if (messageView) {
                messageView.el.scrollIntoCenter();
            }
        }
    },

    goToComment: function (taskId, commentId, subCommentId) {
        const messageModel = this.taskListPage.collection.get(taskId);
        if (messageModel) {
            const messageView = this.taskListPage.tasks.currentView.children.findByModel(messageModel);
            if (messageView) {
                return messageView.scrollToComment(commentId, subCommentId);
            }
        }

        return Promise.reject(`no message with \`id == ${taskId}\``);
    },

    set_multi_place_mode: function (multi_place_mode) {
        this.multi_place_mode = multi_place_mode;
        if (multi_place_mode) {
            this.jqPageTasksList.addClass('multi_place');
            this.jqPageTasksList.removeClass('single_place');
        } else {
            this.jqPageTasksList.removeClass('multi_place');
            this.jqPageTasksList.addClass('single_place');
        }
    },

    set_single_task_mode: function (single_task_id) {
        this.single_task_id = Boolean(single_task_id) ? Number(single_task_id) : null;

        if (this.single_task_id) {
            this.jqPageTasksList.addClass('single-task');
        } else {
            this.jqPageTasksList.removeClass('single-task');
        }
    },

    resetIsBusiness() {
        if (!this.multi_place_mode && !this.single_task_id && this.placeId) {
            if (LoginedUserHandler.isUserEmployee(this.placeId)) {
                this.jqPageTasksList.addClass('business');
                return;
            }
        }
        this.jqPageTasksList.removeClass('business');
    },

    /**
     * Задает параметры отображения и работы текущей страницы
     *
     * @param options
     * @param options.place {Object} место в формате сервера
     * @param options.placeId {ID} id места
     * @param options.filter {Object} фильтр выборки тасков
     * @param options.multi_place_mode {Boolean} true - отображаем по нескольким Place, иначе по одному
     */
    set_show_params: function (options, $el, cb) {
        if (options.includeUnverified && this.filter.get('isuserverified') == 1) {
            options.filter = _.extend(options.filter || {}, {
                isuserverified: null
            });
        }

        let updateMode = false;
        if (this._dirty) {
            this._dirty = false;
        } else if (options.optionalLoad && !options.reload && !options.filter
            && !options.addMessage && !options.addUser
        ) {
            let shouldLoad = true;
            if (!!options.single_task_id) {//single task mode
                if (options.single_task_id == this.single_task_id
                    && !!options.multi_place_mode == !!this.multi_place_mode) {
                    shouldLoad = false;
                }
            } else if (options.singleUserMode) {//single user mode
                if (!!options.singleUserMode == !!this.filter.get('singleUserMode')
                    && options.filter.user_id == this.filter.get('user_id')
                    && options.multi_place_mode == this.multi_place_mode
                ) {
                    shouldLoad = false;
                }
            } else {//single place mode
                const placeId = options.place && options.place.id || options.placeId;
                if (!!options.multi_place_mode == !!this.multi_place_mode
                    && !this.filter.get('singleUserMode')
                    && !this.single_task_id
                    && placeId == this.placeId
                    && this.taskListPage.placeCollection.get(placeId)
                ) {
                    shouldLoad = false;
                }
            }

            if (!shouldLoad) {
                if ((Date.now() - this._lastLoadTime) < 300000) {
                    return;
                } else {
                    updateMode = true;
                }
            }
        }

        this.page_top_position = null;

        this.initialize($el);

        if (options.singleUserMode && options.filter && options.filter.user_id) {
            this.filterDefault.user_id = options.filter.user_id;
        } else {
            this.filterDefault.user_id = null;
        }

        this.dontLoadTasksFromServerOnFilterChanged = true;

        if (!updateMode) {
            // Отчищаем страницу
            this.clear();
            this.refresh_create_task_fake();

            // Включает / выключает на странице режим отображения тасков из нескольких Places
            this.set_multi_place_mode(options.multi_place_mode);
            this.set_single_task_mode(options.single_task_id);

            this.placeId = options.place && options.place.id || options.placeId;

            if (options.place && options.place.id) {
                this.taskListPage.placeCollection.add(options.place, { merge: true });
            }

            this.resetIsBusiness();

            this.filter.set(
                _.extend(
                    {
                        place_id: this.placeId || null,
                        needplaceinfo: 1,
                        loginedUser: LoginedUserHandler.getLoginedUser(),
                    },
                    (options.single_task_id ? {
                        ids: [options.single_task_id],
                        authorvisibility: null,
                        isuserverified: null,
                        includeReservation: options.includeReservation
                    } : {
                        includeReservation: false
                    }),
                    options.filter
                )
            );

            if (options.addUser) {
                this.taskListPage.userCollection.add(options.addUser, { merge: true });
            }

            if (options.addMessage) {
                this.addMessage(options.addMessage);
            }
        }

        this.dontLoadTasksFromServerOnFilterChanged = false;

        this.load_tasks_from_server(!updateMode, () => {
            if (this.single_task_id && options.scrollToComment) {
                this.goToComment(this.single_task_id, options.scrollToComment, options.scrollToSubComment)
                    .then(() => cb && cb());
            } else {
                if (options.scrollToTask && options.scrollToComment) {
                    this.goToComment(options.scrollToTask, options.scrollToComment, options.scrollToSubComment)
                        .then(() => cb && cb())
                        .catch(() => {
                            this.goToTask(options.scrollToTask);
                            cb && cb();
                        });
                } else if (options.scrollToTask) {
                    this.goToTask(options.scrollToTask);
                }
            }

            cb && cb();
        }, true, updateMode);
    },

    clear: function () {
        this.taskListPage.userCollection.reset();
        if (this.taskListPage.loginedUserModel.get('isLoggedIn')) {
            this.taskListPage.userCollection.add(this.taskListPage.loginedUserModel.clone({ asUserModel: true }));
        }
        this.placeId = null;
        this.taskListPage.placeCollection.reset();
        this.taskListPage.collection.reset();

        this.hideLoading();
        this.isLoading = false;
        this.isAllLoaded = false;

        if (this.jqPageTasksList.hasClass('filter_view')) {
            this.toggle_filter_results_views();
        } else {
            this.toggle_filter_results_views(true);
        }

        this.jqResultsCount.text('0 TASKS');
        this.page_top_position = null;

        this.taskListPage.collection.reset();
        this.resetFilterToDefaults();
        this.jqBtnCheckIn.removeClass('set');
    },

    resetFilterToDefaults: function () {
        let authorvisibility, isuserverified;
        const loginedUser = LoginedUserHandler.getLoginedUser();
        if (loginedUser) {
            if (!loginedUser.settings.viewanonym) {
                authorvisibility = null;
            }
            if (!loginedUser.settings.viewunverified) {
                isuserverified = 1;
            }
        }

        var needplaceinfo = this.multi_place_mode ? 1 : 0;

        this.filter.set({
            ids: null,
            loginedUser,
            user_id: this.filterDefault.user_id || null, // Идентификатор автора сообщения
            istarget: 0,
            to_user_id: null,
            to_user_name: null,
            postedby: null, // Тип автора: 0 – обычные пользователи, 1 – представители бизнеса
            subject: null, // тема сообщения
            authorvisibility: authorvisibility, // Видимость автора сообщения: 0 – автор анонимен, 1 – автор виден всем
            messvisibility: null, // Видимость сообщения: 0 – сообщение видно только представителям бизнеса, 1 –видно всем
            idea: null, // Это "идея": 0 - фильтр не задан, 1 - да
            issue: null, // Это "проблема": 0 - фильтр не задан, 1 - да
            question: null, // Это "вопрос": 0 - фильтр не задан, 1 - да
            isuserverified: isuserverified, // Верифицирован ли пользователь на момент написания сообщения: 0 – нет, 1 - да
            hassolution: null, // Имеет ли данное сообщение решение: 0 – нет, 1 - да
            rating: null, // Оценка пользователя своего опыта взаимодействия float  {range (0;5] }
            publishedafter: null, // Искать сообщения, созданные не ранее указанной даты {format yyyy-MM-dd}
            publishedbefore: null, // Искать сообщения созданные не позднее указанной даты {format yyyy-MM-dd}
            tags: [], // Массив тегов к сообщению. Релевантно только для постов и задач.
            needuserinfo: 1, // Нужны ли юзеры в результате поиска сообщений
            needplaceinfo: needplaceinfo, // Нужны ли плейса в результате поиска сообщений
            limit: 10,
            offset: 0,
        });
    },

    isFilterDefault() {
        let authorvisibility,
            isuserverified,
            loginedUser = LoginedUserHandler.getLoginedUser();

        if (loginedUser) {
            if (!loginedUser.settings.viewanonym) {
                authorvisibility = null;
            }
            if (!loginedUser.settings.viewunverified) {
                isuserverified = 1;
            }
        }

        return this.filter.get('ids') == null &&
            this.filter.get('user_id') == (this.filterDefault.user_id || null) &&
            this.filter.get('istarget') == 0 &&
            this.filter.get('to_user_id') == null &&
            this.filter.get('to_user_name') == null &&
            this.filter.get('postedby') == null &&
            !this.filter.get('subject') &&
            this.filter.get('authorvisibility') == authorvisibility &&
            this.filter.get('messvisibility') == null &&
            this.filter.get('isuserverified') == isuserverified &&
            this.filter.get('hassolution') == null &&
            !this.filter.get('rating') &&
            !this.filter.get('publishedafter') &&
            !this.filter.get('publishedbefore') &&
            !(this.filter.get('tags') && this.filter.get('tags').length);
    },

    load_tasks_from_server: _.debounce(function (clear, cb, first, updateMode) {
        this._lastLoadTime = Date.now();
        this.isLoading = true; // Отмечаем, что идет загрузка
        this.showLoading(first ? 500 : 0);

        var filter = _.defaults({},
            _(this.filter.toJSON({ computed: true })).chain()
                .pick(["ids", "place_id", "user_id", "istarget", "to_user_id", "to_user_name", "postedby", "subject",
                    "authorvisibility", "messvisibility", "idea", "issue", "question", "isuserverified",
                    "hassolution", "rating", "publishedafter", "publishedbefore", "tags", "needuserinfo",
                    "needplaceinfo", "limit", "offset"])
                .reduce((o, v, i) => {
                    if (!_.isNull(v) && !_.isNaN(v) && !_.isUndefined(v)) {
                        o[i] = v;
                    }
                    return o;
                }, _.create(null))
                .value(),
            {
                tags: [], // Массив тегов к сообщению. Релевантно только для постов и задач.
                needuserinfo: 1, // Нужны ли юзеры в результате поиска сообщений
                limit: 10,
                offset: 0,
            });

        if (updateMode) {
            _.extend(filter, { offset: 0, limit: this.taskListPage.collection.length });
        }

        B2Cjs.clear_nulls_attrs_from_object(filter);

        Server.callServerWithParameters({
            url: settings.host + settings.serv_task.search,
            type: "POST",
            data: filter,
            success: data => {
                if (clear) {
                    this.taskListPage.collection.reset();
                }
                this.on_load_tasks_from_server_ok(data, updateMode);
                cb && cb();
            },
            error: (jqXHR, textStatus, errorThrown) => this.showError(jqXHR, textStatus, errorThrown)
        }, this.filter.get('includeReservation') ? { include_reservation: 1 } : null);
    }, 0),

    check_and_load_more: function () {
        if (this._is_need_load_more())
            this.load_more();
    },

    load_more: function () {
        this.filter.set({ offset: this.filter.get('offset') + this.filter.get('limit') });
        this.load_tasks_from_server();
    },

    _is_need_load_more: function () {
        if (
            this.isLoading || this.isAllLoaded // Таски либо уже грузятся, либо все таски загружены на клиента
            || !this.taskListPage.collection.length // Еще не было первичной загрузки тасков
            || app.controller.getCurrentPage() !== this.taskListPage // Сейчас другая страница открыта (не страница списка тасков)
        ) {
            return false;
        }

        var task_list_height = this.jqTasksList.height(),
            offset_bottom_task_list = this.jqTasksList.offset().top + task_list_height,
            //height_offset_add_elem = this.jqElemAddPlace.outerHeight(),
            offset_top_bottom_bar = this.jqBottomBar.offset().top,
            screenHeight = $.mobile.getScreenHeight();

        if (
            (offset_top_bottom_bar > 0 && offset_bottom_task_list - screenHeight / 2 > offset_top_bottom_bar)
            || (offset_top_bottom_bar <= 0 && offset_bottom_task_list - screenHeight / 2 > screenHeight)
            || task_list_height <= 0
        ) {
            // либо еще не докуртили до элемента добавления плейса
            // либо вообще списко плейсов сейчас скрыт, а потому элемент добавления нового плейса, вложенный в него, имеет нулевую высоту
            return false;
        }

        return true;
    },

    changeFilter: function (key, value, isNotFinalChange) {
        this.filter.set({ [key]: value });
    },

    toggleFilterChangeHandler: function (key, mapWidgetValToFilter, states) {
        if (states[0].set && states[1].set) {
            this.changeFilter(key, null, false);
        } else {
            for (var i = 0; i < states.length; i++) {
                if (states[i].set) {
                    this.changeFilter(key, mapWidgetValToFilter[states[i].val], false);
                }
            }
        }
    },
    /**
     * Переключает видимость списка тасков и контейнера с детальными фильтрами
     */
    toggle_filter_results_views: function (forceResultsView) {
        if (this.jqPageTasksList.hasClass('filter_view') || forceResultsView) {
            this.jqPageTasksList.removeClass('filter_view');
            this.jqPageTasksList.addClass('results_view');
            $.mobile.silentScroll(this.scroll_pos_result);
            this.jqBtnFilter.removeClass('set');
        } else {
            this.jqPageTasksList.addClass('filter_view');
            this.jqPageTasksList.removeClass('results_view');
            this.scroll_pos_result = this.jqFilterPannel.offset().top;
            $.mobile.silentScroll(0);
            this.jqBtnFilter.addClass('set');
        }

        this.check_and_load_more();
    },
    /**
     * Отправляет на сервер запрос на чекин текущего пользователя в Place, в рамках которого создается задача
     */
    server_checkin: function () {
        if (this.placeId) {
            var currUserPos = geo.getCurrentPosition();

            Server.callServer({
                url: settings.host + settings.serv_place.checkin,
                type: "POST",
                data: {
                    place_id: this.placeId,
                    latitude: currUserPos.lt,
                    longitude: currUserPos.lg,
                },
                success: () => {
                    this.showMessage('You are checked in!')
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    this.showError(jqXHR, textStatus, errorThrown);
                    this.jqBtnCheckIn.removeClass('set');
                },
            });
        }
    },

    onBtnFilterClick: function (jqBtnFilter, event) {
        this.toggle_filter_results_views();
    },

    onBtnTaskTypeClick(jqBtnTaskType, event) {
        if (jqBtnTaskType.hasClass('set')) {
            if (jqBtnTaskType.hasClass('btn-idea'))
                this.filter.set('idea', null);
            else if (jqBtnTaskType.hasClass('btn-issue'))
                this.filter.set('issue', null);
            else
                this.filter.set('question', null);
        } else {
            if (jqBtnTaskType.hasClass('btn-idea')) {
                this.filter.set({
                    issue: null,
                    question: null,
                    idea: 1,
                });
            } else if (jqBtnTaskType.hasClass('btn-issue')) {
                this.filter.set({
                    issue: 1,
                    question: null,
                    idea: null,
                });
            } else {
                this.filter.set({
                    issue: null,
                    question: 1,
                    idea: null,
                });
            }
        }
    },

    onBtnNewTaskClick: function () {
        if (this.placeId) {
            Promise.resolve()
                .then(() => {
                    const placeModel = this.taskListPage.placeCollection.get(this.placeId);
                    if (placeModel) {
                        return placeModel;
                    } else {
                        this.showLoading(500);
                        return this.getFullPlace(this.placeId)
                            .catch(e => {
                                this.showError(e)
                            })
                            .then(placeModel => {
                                this.hideLoading();
                                return placeModel
                            });
                    }
                })
                .then(placeModel => {
                    if (placeModel) {
                        const place = placeModel.toJSON();
                        app.controller.goToTaskEditorPage({
                            new: true,
                            place,
                            onSave: (task_for_send, to_user, isverified) => {
                                // task was not added to task list yet
                                if (to_user) {
                                    this.taskListPage.userCollection.add(to_user, { merge: true });
                                }
                                this.addMessage(_.extend(task_for_send, { _publishedAtValueDesc: -Date.now() }));

                                app.controller.goToPlaceTasksPage({
                                    replace: true, optionalLoad: true,
                                    place,
                                    scrollToTask: task_for_send.id,
                                    includeUnverified: !isverified
                                });
                            }
                        });
                    }
                })
        }
    },

    onBtnInfoClick: function () {
        if (this.placeId) {
            const place = this.taskListPage.placeCollection.get(this.placeId).toJSON({ computed: true });
            if (place) {
                app.controller.goToPlacePage({ place });
            } else {
                app.controller.goToPlacePage({ place: { id: this.placeId } });
            }
        }
    },

    onBtnGalleryClick: function () {
        if (this.placeId) {
            const placeModel = this.taskListPage.placeCollection.get(this.placeId);
            if (placeModel && placeModel.get('full')) {
                new PlaceGalleryWindow({
                    model: placeModel
                })
                    .show();
            } else {
                this.showLoading(500);
                this.getFullPlace(this.placeId)
                    .then(placeModel => {
                        new PlaceGalleryWindow({ model: placeModel }).show();
                    })
                    .catch(e => {
                        this.showError(e)
                    })
                    .then(() => this.hideLoading());
            }
        }
    },

    onBtnClearClick: function () {
        this.resetFilterToDefaults();
        //this.onFilterChanged();
    },

    onFilterChanged(m, v, options) {
        this.jqBtnFilter.toggleClass('is-changed', !this.isFilterDefault());
        this.jqBtnIdeas.toggleClass('set', !!this.filter.get('idea'));
        this.jqBtnIssues.toggleClass('set', !!this.filter.get('issue'));
        this.jqBtnQuestions.toggleClass('set', !!this.filter.get('question'));

        this.isAllLoaded = false;
        this.filter.set({ offset: 0 });

        if (!this.dontLoadTasksFromServerOnFilterChanged) {
            this.load_tasks_from_server(true);
        }
    },

    on_scrollstop: function (event) {
        var jq_activePage = $.mobile.pageContainer.pagecontainer("getActivePage");
        if (jq_activePage.attr('id') != this.page_id)
            return;

        this.check_and_load_more();
    },

    on_load_tasks_from_server_ok: function (data, updateMode) {
        //TODO: updateMode - доделать так, чтобы он реально их обновлял, сейчас он заменяет те, у которых не раскрыты комментарии и не трогает те, у которых раскрыты

        this.hideLoading();
        this.isLoading = false; // Фиксируем, что загрузка завершилась
        // Отображаем общее количество тасков, которые соответствуют фильтру
        this.jqResultsCount.text(data.total + (data.total == 1 ? ' TASK' : ' TASKS'));

        // Сохраняем пользователей - авторов тасков
        this.taskListPage.userCollection.add(data.users, { merge: true });

        // Сохраняем Places, в рамках которых были созданы загруженные таски
        this.taskListPage.placeCollection.add(data.places, { merge: true });

        if (!updateMode) {
            // Сохраняем таски и добавляем их на страницу
            _.each(data.tasks, message => {
                this.addMessage(message);
            });
        } else {
            // First: remove messages that currently are in the list but not in the server response
            const dataTaskIds = _.map(data.tasks, t => String(t.id)),
                messageIds = this.taskListPage.collection.map(m => String(m.id));

            _.each(_.difference(messageIds, dataTaskIds), this.removeMessage.bind(this));

            // Second: add or update existing messages
            _.each(data.tasks, message => {
                this.addMessage(message);
            });
        }

        if (data.tasks.length == 0) {
            // Отмечаем, что все таски загружены
            this.isAllLoaded = true;
            if (this.on_load_once) {
                this.on_load_once(true);
                delete this.on_load_once;
            }
        } else {
            if (this.taskListPage.collection.length >= Number(data.total)) {
                // Отмечаем, что все таски загружены
                this.isAllLoaded = true;
                if (this.on_load_once) {
                    this.on_load_once(true);
                    delete this.on_load_once;
                }
            } else
            // Проверяем надо ли еще грузить таски
                this.check_and_load_more();
        }
    },
    getFullPlace(placeId) {
        return new Promise((resolve, reject) => {
            const loginedUser = LoginedUserHandler.getLoginedUser(),
                anonym = loginedUser && loginedUser.settings.viewanonym == 1,
                unverified = loginedUser && loginedUser.settings.viewunverified == 1;

            B2CPlace.server_get(
                {
                    id: placeId,
                    anonym: anonym,
                    unverified: unverified,
                    limphotosb: 999,
                    limphotosu: 999,
                },
                place => {
                    if (place.id) {
                        const placeModel = new PlaceModel(_.extend(place, { full: true }));
                        this.taskListPage.placeCollection.add(placeModel, { merge: true });
                        resolve(placeModel);
                    } else {
                        reject(new Error('Incorrect place.id'));
                    }
                },
                (jqXHR, textStatus, errorThrown) => reject(new AjaxError(jqXHR, textStatus, errorThrown))
            );
        });

    }
}
