import { Required, Optional, LayoutView, ViewModel } from '@b2cmessenger/backbone';
import AjaxError from 'utils/AjaxError';

import TaskTargetModel from 'models/TaskTargetModel';
import { bindingHandler as placeBindingHandler } from 'widgets/lite/place/place';
import { bindingHandler as userBindingHandler } from 'widgets/lite/user/user';
import MessageView from 'widgets/Message/Message';
import MessageCommentsView from './MessageComments/MessageComments';
import MessageTargetView from './MessageTarget/MessageTarget';
import UserModel from 'models/UserModel';
import PlaceModel from 'models/PlaceModel';

import template from './MessageWithComments.jade';
import './MessageWithComments.scss';
import ReservationModel from 'models/ReservationModel';

/**@type {typeof import('./MessageWithComments').properties} */
// @ts-ignore
const properties = LayoutView.properties;

/**@type {typeof import('./MessageWithComments').options} */
// @ts-ignore
const options = LayoutView.options;

/** @type {typeof import('./MessageWithComments').events} */
// @ts-ignore
const events = LayoutView.events;

@options({
    model: Required,
    userModel: Required,
    placeModel: Required,
    loginedUserModel: Required,
    targetModel: Optional,
    toUserModel: Optional,
    parentViewModel: Optional,
    showPlaceInfo: Optional || false,
    showCommentsOnRender: Optional
})
@events({
    /** User opened menu */
    'menu:open': () => {
    },

    /** User closed menu
     * @param {string} [entryName] - Name of the entry
     * @param {any} [actionResult] - Result if any with which action finished */
    'menu:close': (entryName, actionResult) => {
    },

    /** User clicked on menu entry
     * @param {string} entryName - Name of the entry */
    'menu:entry:click': (entryName) => {
    },

    /** Action related to menu entry finished sucessfully
     * @param {string} entryName - Name of the entry
     * @param {any} [actionResult] - Result if any with which action finished */
    'menu:entry:finish': (entryName, actionResult) => {
    },

    /** Action related to menu entry failed
     * @param {string} entryName - Name of the entry
     * @param {Error | string} [actionError] */
    'menu:entry:fail': (entryName, actionError) => {
    },

    /** User clicked on user
     * @param {UserModel} userModel - model of user
     * @param {boolean} [isAnonym] - is user is anonym
     * @param {boolean} [isFromBussines] - is user from this place */
    'user:click': (userModel, isAnonym, isFromBussines) => {
    },

    /** User clicked on place panel
     * @param {PlaceModel} placeModel - model of place */
    'place:click': (placeModel) => {
    },

    /** User opened comments (fires after coomments refreshed) */
    'comments:open': () => {
    },

    /** User opened comments closed */
    'comments:close': () => {
    },
})
@properties({
    className: 'widget message-with-comments-widget',
    template,

    attributes() {
        return {
            'data-id': this.model.id
        }
    },

    regions: {
        target: '[data-js-target]',
        message: '[data-js-message]',
        comments: '[data-js-comments]'
    },

    ui: {
        placeInfo: '[data-js-place-info]',
        place: '[data-js-place]',
        toUserInfo: '[data-js-to-user-info]',
        toUser: '[data-js-to-user]',
        target: '[data-js-target]',
        message: '[data-js-message]',
        comments: '[data-js-comments]'
    },

    bindings: {
        ':el': 'classes:{hidden:isHidden}',
        '@ui.placeInfo': 'classes:{hidden:not(showPlaceInfo)}',
        '@ui.place': 'placeBindingHandler:$placeModel',
        '@ui.toUserInfo': 'classes:{hidden:not($toUserModel)}',
        '@ui.toUser': 'userBindingHandler:$toUserModel',
        '@ui.comments': 'classes:{hidden:not(isCommentsOpen)}'
    },

    events: {
        'click @ui.toUserInfo'() {
            if (this.toUserModel) {
                this.trigger('user:click', this.toUserModel, false);
            }
        },
        'click @ui.placeInfo'() {
            this.trigger('place:click', this.placeModel);
        }
    },

    bindingHandlers: {
        placeBindingHandler, userBindingHandler
    },

    bindingSources() {
        return {
            placeModel: this.placeModel,
            toUserModel: this.toUserModel
        }
    },

    templateHelpers() {
        return { toUserModel: this.toUserModel }
    },
})
class MessageWithCommentsView extends LayoutView {
    show(show = true) {
        this.viewModel.set({ isHidden: !show });
    }

    hide() {
        this.show(false);
    }

    openMenu(open = true) {
        /** @type {import('./MessageWithComments')} */
            // @ts-ignore
        const self = this;
        const messageView = self.message.currentView;
        if (messageView) {
            return messageView.openMenu(open);
        }
    }

    closeMenu() {
        /** @type {import('./MessageWithComments')} */
            // @ts-ignore
        const self = this;
        const messageView = self.message.currentView;
        if (messageView) {
            return messageView.closeMenu();
        }
    }

    loadNewComment(commentId, isSolutionHint = false) {
        /**@type {MessageCommentsView} */
            //@ts-ignore
        const commentsView = this.getChildView('comments');
        if (commentsView) {
            return commentsView.loadNewComment(commentId, isSolutionHint);
        } else {
            return this._enqueueModelFetch();
        }
    }

    loadNewSubComment(id, subId) {
        /**@type {MessageCommentsView} */
            //@ts-ignore
        const commentsView = this.getChildView('comments');
        if (commentsView) {
            return commentsView.loadNewSubComment(id, subId);
        } else {
            return Promise.resolve(null);
        }
    }

    updateComment(commentId) {
        /**@type {MessageCommentsView} */
            //@ts-ignore
        const commentsView = this.getChildView('comments');
        if (commentsView) {
            return commentsView.updateComment(commentId);
        } else {
            return Promise.resolve();
        }
    }

    scrollToComment(commentId, subCommentId) {
        /** @type {import('./MessageWithComments')} */
            // @ts-ignore
        const self = this;
        return self._initializeComments()
            .then(commentsView => (this.viewModel.set({ isCommentsOpen: true }), commentsView))
            .then(commentsView => {
                if (commentId == 'new') {
                    return commentsView.openCommentEditor();
                } else {
                    return commentsView.scrollToComment(commentId, subCommentId);
                }
            })
            .catch(e => self.showError(e));
    }

    initialize() {
        /** @type {import('./MessageWithComments')} */
            // @ts-ignore
        const self = this;

        this.viewModel = new ViewModel({
            isHidden: false,
            parentViewModel: self.options.parentViewModel,
            isCommentsOpen: false,
            isCommentsLoaded: false,
            showPlaceInfo: !!self.options.showPlaceInfo
        });

        this.listenTo(this.viewModel, 'change:isCommentsOpen', (vm, isCommentsOpen) => {
            if (isCommentsOpen) {
                this.trigger('comments:open');
            } else {
                this.trigger('comments:close');
            }
        });

        this.userModel = self.options.userModel;
        this.placeModel = self.options.placeModel;
        this.loginedUserModel = self.options.loginedUserModel;
        this.targetModel = self.options.targetModel || null;
        this.toUserModel = self.options.toUserModel || null;
        this.reservationModel = self.options.reservationModel || (self.model.get('isReservation') ?
                new ReservationModel(self.model.get('reservation')) :
                null
        );
    }

    onRender() {
        /** @type {import('./MessageWithComments')} */
            // @ts-ignore
        const self = this;

        this.viewModel.set({ isCommentsOpen: false, isCommentsLoaded: false });

        if (this.model.get('isBroadcast')) {
            if (!this.targetModel) {
                const target = this.model.get('task_target');
                if (target) {
                    this.targetModel = new TaskTargetModel(target);
                } else {
                    const targetId = this.model.get('task_target_id');
                    if (targetId && this.loginedUserModel.isEmployee(this.model.get('place_id'))) {
                        this.targetModel = new TaskTargetModel({ id: targetId });

                        this.targetModel.fetch({
                            success: () => this.model.set({
                                task_target: this.targetModel.toJSON({ computed: true })
                            })
                        });
                    }
                }
            }

            if (this.targetModel) {
                this.showChildView('target', new MessageTargetView({
                    model: this.targetModel
                }));
            }
        }

        const oldMessageView = this.getChildView('message');
        if (oldMessageView) {
            this.stopListening(oldMessageView);
        }
        const messageView = new MessageView({
            model: self.model,
            userModel: this.userModel,
            placeModel: this.placeModel,
            loginedUserModel: this.loginedUserModel,
            parentViewModel: this.viewModel,
            toUserModel: this.toUserModel,
            reservationModel: this.reservationModel
        });
        this.showChildView('message', messageView);

        if (this.options.showCommentsOnRender) {
            this._initializeComments()
                .then(() => this.viewModel.set({ isCommentsOpen: true }))
                .catch(e => self.showError(e))
        }

        this.listenTo(messageView, 'menu:open', () => this.trigger('menu:open'));
        this.listenTo(messageView, 'menu:close', (entryName, actionResult) =>
            this.trigger('menu:close', entryName, actionResult));
        this.listenTo(messageView, 'menu:entry:click', (entryName) =>
            this.trigger('menu:entry:click', entryName));
        this.listenTo(messageView, 'menu:entry:finish', (entryName, actionResult) => {
            if (entryName == 'gift') {
                self._initializeComments()
                    .then(commentsView => (this.viewModel.set({ isCommentsOpen: true }), commentsView))
                    .then(commentsView => commentsView.openCommentEditorWithGiftSelect())
                    .catch(e => self.showError(e))
                    .then(() => this.trigger('menu:entry:finish', entryName, actionResult))
            } else if ((self.model.get('isReservation') && ['edit', 'decline', 'accept'].indexOf(entryName) !== -1) ||
                (self.model.get('isReservationChangeComment') && entryName == 'decline' && actionResult == 'edit')) {
                if (self.model.get('isReservationChangeComment') && this.model.get('depth') == 1) {
                    this.trigger('menu:entry:finish', entryName, actionResult);
                    return;
                }

                if (entryName == 'decline' && actionResult == 'edit') {
                    this.scrollToComment('new')
                        .then(editorView => editorView && editorView.setReservationSuggestChanges());
                    return;
                }

                if (actionResult instanceof ReservationModel) {
                    const commentId = actionResult.get('last_change_comment_id');

                    this.loadNewComment(commentId)
                        .then(() => {
                            if (this.viewModel.get('isCommentsOpen')) {
                                this.scrollToComment(commentId);
                            }
                        });
                }
                this.trigger('menu:entry:finish', entryName, actionResult);
            } else {
                this.trigger('menu:entry:finish', entryName, actionResult);
            }
        });

        this.listenTo(messageView, 'menu:entry:fail', (entryName, actionError) =>
            this.trigger('menu:entry:fail', entryName, actionError));

        this.listenTo(messageView, 'user:click',
            (userModel, isAnonym, isFromBussines) => this.trigger('user:click', userModel, isAnonym, isFromBussines));

        this.listenTo(messageView, 'full:page', () => this.trigger('full:page'));

        this.listenTo(messageView, 'comments:click', () => {
            if (!this.viewModel.get('isCommentsOpen')) {
                this._initializeComments()
                    .then(() => this.viewModel.set({ isCommentsOpen: true }))
                    .catch(e => self.showError(e))
            } else {
                this.viewModel.set({ isCommentsOpen: false });
            }
        });
    }

    _initializeComments() {
        /** @type {import('./MessageWithComments')} */
            // @ts-ignore
        const self = this;
        return new Promise((resolve, reject) => {
            const commentsView = this.getChildView('comments');
            if (!commentsView) {
                const commentsView = new MessageCommentsView({
                    model: self.model,
                    loginedUserModel: self.loginedUserModel,
                    placeModel: self.placeModel,
                    reservationModel: self.reservationModel,
                    parentViewModel: this.viewModel,
                });

                this.showChildView('comments', commentsView);
                this.listenTo(commentsView, 'destroy', () => this.stopListening(commentsView));
                this.listenTo(commentsView, 'user:click', (childView, userModel, isAnonym, isFromBussines) =>
                    this.trigger('user:click', userModel, isAnonym, isFromBussines));

                if (self.model.get('isReservation')) {
                    this.listenTo(commentsView, 'childview:menu:entry:finish',
                        (cv, entryName, actionResult) => {
                            if (this.viewModel.get('isCommentsOpen') && cv.model.get('isReservationChangeComment')
                                && (entryName == 'accept' || entryName == 'decline')
                            ) {
                                if (actionResult instanceof ReservationModel) {
                                    const commentId = actionResult.get('last_change_comment_id');

                                    this.loadNewComment(commentId)
                                        .then(() => this.scrollToComment(commentId));
                                } else if (entryName == 'decline' && actionResult == 'edit') {
                                    this.scrollToComment('new')
                                        .then(editorView => editorView && editorView.setReservationSuggestChanges());
                                }
                            }
                        }
                    );
                }

                this.viewModel.set({ disabled: true });
                commentsView.refresh()
                    .then(() => this.viewModel.set({ isCommentsLoaded: true }))
                    .then(() => resolve(commentsView))
                    .catch(reject)
                    .then(() => this.viewModel.set({ disabled: false }));
            } else {
                if (!this.viewModel.get('isCommentsLoaded')) {
                    /**@type {MessageCommentsView} */
                        // @ts-ignore
                    const currentCommentsView = this.getRegion('comments').currentView;

                    this.viewModel.set({ disabled: true });
                    currentCommentsView.refresh()
                        .then(() => this.viewModel.set({ isCommentsLoaded: true }))
                        .then(() => resolve(commentsView))
                        .catch(reject)
                        .then(() => this.viewModel.set({ disabled: false }));
                } else {
                    resolve(commentsView);
                }
            }
        })
    }

    _enqueueModelFetch() {
        if (!this._modelFetchPromise) {
            return this._modelFetchPromise = new Promise((resolve, reject) => {
                this.model.fetch({
                    success: () => {
                        resolve;
                        this._modelFetchPromise = null;
                    },
                    error: (m, resp) => {
                        if (resp instanceof Error) {
                            reject(resp);
                        } else {
                            reject(new AjaxError(resp));
                        }
                        this._modelFetchPromise = null;
                    }
                })
            });
        } else {
            return this._modelFetchPromise;
        }
    }
};

export default MessageWithCommentsView;
