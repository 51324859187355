import { put, takeEvery, select, all, take } from 'redux-saga/effects';

import * as MenuAPI from 'utils/api/menu';
import * as SectionAPI from 'utils/api/sections';
import * as TableAPI from 'utils/api/tables';

import {
    LOAD_CATEGORY,
    loadCategorySucceed,
    loadCategoryFailed,
    LOAD_CATEGORY_SUCCEEDED,
    LOAD_CATEGORY_FAILED,
    setLoading,
    LOAD_MENU_ITEM_DETAIL,
    loadMenuItemDetailFailed,
    loadMenuItemDetailSucceed,
    INIT_MENU,
    INIT_MENU_SUCCEEDED,
    INIT_MENU_FAILED,
    initMenuSucceeded,
    initMenuFailed,
    SAVE_MENU_INFO,
    saveMenuInfoSucceeded,
    saveMenuInfoFailed,
    CHANGE_MENU_ELEMENTS_ORDER,
    loadCategory,
    REMOVE_CATEGORY,
    initMenuPartialSucceeded,
    displayCategory,
    displayMenuItemDetail,
    initMenu, upsertCategories, upsertItems, LOAD_MENU_ITEM_DETAIL_SUCCEEDED,

    LOAD_MENU_DETAILED_ITEMS,
    loadDetailedMenuItemsFailed,
    loadDetailedMenuItemsSucceed, SCAN_MENU_TABLE, resetItems, resetCategories
} from "./actions";

import {
    getMenuIsEditPossible,
    getMenuItemIsOutOfDate,
    getMenuPlaceId,
    getMenuSelectedCategoryId,
    getMenuCategoryIsOutOfDate,
    getMenuIsWaiter,
    getMenuIsOrderManager,
    getMenuIsManager,
    getMenuIsInitialized,
    getMenuInfoPermissions, getMenuCartState
} from "store/menu/selectors";
import {getLoginedUserId} from "store/app/selectors";
import { showError } from 'windows/Modal/Info';
import { showLoading, hideLoading } from 'windows/Modal/Loading';
import watchMenuCartEffects from "store/menu/cart/sagas";
import watchMenuOrdersEffects from "store/menu/orders/sagas";
import { resetOrders, upsertOrders } from "store/menu/orders/actions";
import {race, takeLatest} from "@redux-saga/core/effects";
import getMenuPermissions from "utils/getMenuPermissions";
import { getMenuPermissionsInCurrentPlace, getMenuTableById } from "store/menu/sections/selectors";
import {loadUserOrders} from "utils/api/menu";
import {
    DISCARD_CART_ORDER,
    DISCARD_CART_ORDER_CANCELED,
    performDiscardCartOrder, resetCart,
    setSelectedTableId
} from "store/menu/cart/actions";
import {getMenuSelectedTableId} from "store/menu/cart/selectors";
import { resetMenuInfo, updateMenuPermissions } from "store/menu/info/actions";
import { resetCarts } from "store/menu/carts/actions";
import { resetSections } from "store/menu/sections/actions";
import { SET_LOGINED_USER_WORKPLACE_ROLES, SET_LOGINED_USER_WORKPLACES_ROLES } from "store/app/actions";
import { initialCartState } from "store/menu/reducers/cart";
import MenuQrActionsWindow from "components/window/MenuQrActions/MenuQrActions";

function* updateSelectedCategoryOnCategoryItemsMovedToParentAsync(action) {
    if (action.payload.itemsMovedToParent) {
        const selectedCategoryId = yield select(getMenuSelectedCategoryId);
        yield put(loadCategory(selectedCategoryId, true));
    }
}

function* loadCategoryAsync(action) {
    try {
        const categoryId = action.payload.id;
        const isOutOfDate = yield select(getMenuCategoryIsOutOfDate, categoryId);
        if (!isOutOfDate && !action.payload.forceFetch) {
            yield put(displayCategory(categoryId));
            return;
        }

        yield put(setLoading(true));

        const isMenuEditable = yield select(getMenuIsEditPossible);
        const placeId = yield select(getMenuPlaceId);

        const { categories, items } = yield MenuAPI.loadCategory({
            placeId, id: action.payload.id,
            includeUnlisted: isMenuEditable && action.payload.id === null
        });

        yield put(loadCategorySucceed(action.payload.id, categories, items));
    } catch (e) {
        yield put(loadCategoryFailed(e instanceof Error ? e.message : e));
    }
}

function* loadMenuItemDetailAsync(action) {
    try {
        const { itemId, forceUpdate } = action.payload;

        const isOutOfDate = yield select(getMenuItemIsOutOfDate, itemId);
        if (!isOutOfDate && !forceUpdate) {
            yield put(displayMenuItemDetail(itemId));
            return;
        }

        const menuItemInfo = yield MenuAPI.loadItem({placeId: action.payload.placeId, id: itemId});
        yield put(loadMenuItemDetailSucceed(menuItemInfo));
    } catch ( e ) {
        yield put(loadMenuItemDetailFailed(e instanceof Error ? e.message : e));
    }
}

function* upsertItemDetailAdditionsAsync(action) {
    const { item } = action.payload;

    if (_.isArray(item.additions) && item.additions.length) {
        const categories = [];
        const items = [];
        const categoriesItemsMap = {};

        for (const a of item.additions) {
            const { category } = a;
            categoriesItemsMap[category.id] = a.items.map(({ id }) => id);
            categories.push(category);
            items.push(...a.items);
        }

        yield put(upsertItems(items));
        yield put(upsertCategories(categories, categoriesItemsMap));
    }
}

function* loadMenuDetailedItemsAsync(action) {
    try {
        const { ids } = action.payload;
        const placeId = action.payload.placeId ? action.payload.placeId : yield select(getMenuPlaceId);

        if (ids && ids.length > 0) {
            const items = yield MenuAPI.loadItems({placeId, ids});
            yield put(loadDetailedMenuItemsSucceed(items));
        } else {
            yield put(loadDetailedMenuItemsFailed('try loading items by empty ids'));
        }

    } catch (e) {
        yield put(loadDetailedMenuItemsFailed(e));
    }
}

function* initMenuAsync(action) {
    try {
        const {placeId, categoryId} = action.payload;

        if (!placeId) {
            return;
        }

        yield put(setLoading(true));

        const loginedUserId = yield(select(getLoginedUserId));
        const isWaiter = yield(select(getMenuIsWaiter));
        const isOrderManager = yield(select(getMenuIsOrderManager));
        const isEditPossible = yield(select(getMenuIsEditPossible));

        const cartState = yield select(getMenuCartState);

        if (!cartState.placeId || Number(cartState.placeId) !== Number(placeId)) {
            yield put(resetCart({
                ...initialCartState,
                placeId
            }));
        }

        let currency = null, isFood = false, sections = [], waiters = [], tables = null;

        if (loginedUserId !== null) {
            if (isEditPossible) {
                const response = yield MenuAPI.loadMenuInfo({placeId});
                currency = response.currency;
                isFood = response.isFood;
            }

            if (isWaiter || isOrderManager) {
                sections = yield SectionAPI.getSections({placeId, includeTables: true});

                if (isOrderManager) {
                    waiters = yield MenuAPI.loadWaiters({placeId});
                }
            } else {
                tables = yield TableAPI.getTables({placeId});
            }

            if (isWaiter) {
                const data = yield MenuAPI.loadOrders({
                    waiterId: loginedUserId,
                    includeUser: true,
                    includeWaiterRequests: true,
                    placeId,
                    sort: '-created_at',
                    pageSize: 9999
                });

                yield put(upsertOrders(data.orders));
            }
        }

        let categories = yield MenuAPI.loadCategories({ placeId, includeUnlisted: isEditPossible });
        let items = [];

        if (categoryId !== null) {
            try {
                const response = yield MenuAPI.loadCategory({placeId, id: categoryId});
                categories = [...categories, response.categories];
                items = response.items;
            } catch (categoryLoadError) {
                yield put(initMenuPartialSucceeded({
                    placeId, categoryId, currency, isFood, categories, items, sections, tables, waiters,
                    error: categoryLoadError instanceof Error ? categoryLoadError.message : categoryLoadError,
                }));
                return;
            }
        }

        yield put(initMenuSucceeded({
            placeId, categoryId,
            currency, isFood,
            categories, items,
            sections, tables, waiters
        }))
    } catch (e) {
        yield put(initMenuFailed(e instanceof Error ? e.message : e))
    }
}

function* saveMenuInfoAsync(action) {
    try {
        const placeId = yield select(getMenuPlaceId);
        const {currency, is_food: isFood} = yield MenuAPI.saveMenuInfo({
            placeId,
            ..._.pick(action.payload, 'currency', 'isFood')
        });
        yield put(saveMenuInfoSucceeded(currency, isFood))
    } catch (e) {
        showError(e);
        yield put(saveMenuInfoFailed(e instanceof Error ? e.message : e));
    }
}

function* scanMenuTableAsync(action) {
    const permissions = getMenuPermissions(action.payload.placeId);
    const isPlaceEmployee = Boolean(permissions.isOrderManager || permissions.isMenuManager || permissions.isWaiter);

    const {tableId} = action.payload;
    const placeId = yield select(getMenuPlaceId);
    const isInitialized = yield select(getMenuIsInitialized);

    try {
        if (isInitialized && placeId !== action.payload.placeId) {
            yield put(performDiscardCartOrder({promptUser: true}));

            const { fail } = yield race({
                success: take(DISCARD_CART_ORDER),
                fail: take(DISCARD_CART_ORDER_CANCELED)
            });

            if (fail) {
                throw new Error('User persists its order');
            }
        }

        if (!isInitialized || placeId !== action.payload.placeId) {
            showLoading();
            yield put(initMenu(action.payload.placeId, null, permissions));

            const { fail } = yield race({
                success: take(INIT_MENU_SUCCEEDED),
                fail: take(INIT_MENU_FAILED)
            });

            if (fail) {
                throw new Error(fail.payload.error);
            }
            hideLoading();
        }

        const table = yield select(getMenuTableById, tableId);

        if (isPlaceEmployee) {
            if (table === null) {
                console.warn(`table id ${tableId} not found`);
            }

            app.controller.goToPlaceOrdersPage({
                placeId: action.payload.placeId,
                orderParams: {
                    tableId: table ? table.id : null
                }
            });
        } else {
            showLoading();
            const { orders } = yield loadUserOrders({ placeId: action.payload.placeId, pageSize: 1 });
            hideLoading();

            const [order] = (orders || []);

            if (order && order.data && Number(order.data.table_id) === Number(tableId)) {
                app.controller.goToOrdersPage({ showOrderWindowWithId: order.id, displayOrderActions: true });
            } else {

                yield put(setSelectedTableId(tableId));
                yield new MenuQrActionsWindow({ placeId: action.payload.placeId, tableId }).show();
            }
        }
    } catch (e) {
        const message = e instanceof Error ? e.message : e;

        if (isPlaceEmployee) {
            app.controller.goToPlacePage({ placeId: action.payload.placeId });
        } else {
            if (message.match(/not available/)) {
                console.warn('Menu not available for placeId', action.payload.placeId);
                app.controller.goToPlacePage({ placeId: action.payload.placeId });
            } else if (message.match(/persists its order/)) {
                console.warn('User did not discard its draft order');
                app.controller.goToMenuCart({ placeId });
            } else {
                app.controller.index();
                showError(e, undefined, undefined, { dontSendToGaAndSentry: true });
            }
        }
    }
}

function* changeMenuElementsOrderAsync(action) {
    try {
        yield put(setLoading(true));

        const placeId = yield select(getMenuPlaceId);
        const data = yield JSON.stringify({
            category_id: action.payload.selectedCategoryId,
            categories_ids: action.payload.categoriesIds,
            items_ids: action.payload.itemsIds,
        });
        let {categories, items} = yield MenuAPI.changeMenuElementsOrder({placeId, data});

        yield put(loadCategorySucceed(action.payload.selectedCategoryId, categories, items));
    } catch (e) {
        yield put(loadCategoryFailed(e instanceof Error ? e.message : e));
    }
}

function* toggleLoading(action) {
    if (action.payload.loading) {
        showLoading(action.payload.delay);
    } else {
        hideLoading();
    }
}

function* stopLoadingOnRequestEnd() {
    yield put(setLoading(false));
}

function* resetMenu() {
    yield put(resetCart());
    yield put(resetCarts());
    yield put(resetOrders());
    yield put(resetSections());
    yield put(resetItems());
    yield put(resetCategories());
    yield put(resetMenuInfo());
}

export function* onLogoutMenuSaga() {
    const isMenuInitialized = yield select(getMenuIsInitialized);
    if (isMenuInitialized) {
        yield resetMenu();
    }
}

export function* updateMenuPermissionsSaga() {
    const isMenuInitialized = yield select(getMenuIsInitialized);
    if (isMenuInitialized) {
        const permissions = yield select(getMenuPermissionsInCurrentPlace);

        const placeId = yield select(getMenuPlaceId);
        const categoryId = yield select(getMenuSelectedCategoryId);

        yield put(initMenu(placeId, categoryId, permissions));
        yield put(updateMenuPermissions(permissions));
    }
}

function* watchMenuEffects() {
    yield takeEvery(LOAD_CATEGORY, loadCategoryAsync);
    yield takeEvery(CHANGE_MENU_ELEMENTS_ORDER, changeMenuElementsOrderAsync);
    yield takeEvery(REMOVE_CATEGORY, updateSelectedCategoryOnCategoryItemsMovedToParentAsync);
    yield takeEvery(SET_LOGINED_USER_WORKPLACES_ROLES, updateMenuPermissionsSaga);
    yield takeEvery(SET_LOGINED_USER_WORKPLACE_ROLES, updateMenuPermissionsSaga);

    yield takeEvery([
        LOAD_CATEGORY_SUCCEEDED,
        LOAD_CATEGORY_FAILED,
        INIT_MENU_SUCCEEDED,
        INIT_MENU_FAILED,
    ], stopLoadingOnRequestEnd);

    // yield takeEvery(SET_LOADING, toggleLoading);
    yield takeEvery(LOAD_MENU_ITEM_DETAIL, loadMenuItemDetailAsync);
    yield takeEvery(LOAD_MENU_ITEM_DETAIL_SUCCEEDED, upsertItemDetailAdditionsAsync);
    yield takeEvery(LOAD_MENU_DETAILED_ITEMS, loadMenuDetailedItemsAsync);

    yield takeEvery(INIT_MENU, initMenuAsync);
    yield takeEvery(SAVE_MENU_INFO, saveMenuInfoAsync);

    yield takeLatest(SCAN_MENU_TABLE, scanMenuTableAsync);
}

export default function* menuRootSaga() {
    yield all([
        watchMenuEffects(),
        watchMenuCartEffects(),
        watchMenuOrdersEffects()
    ])
}
