import { Optional, ItemView, ViewModel } from '@b2cmessenger/backbone';
import Modal from 'windows/Modal/Modal';
import FooterView from 'widgets/Footer/Footer';
import template from './LoyaltyKioskDisableModal.jade';
import './LoyaltyKioskDisableModal.scss';
import settings from "../../../settings";

const LoyaltyKioskDisableModal = Modal.extend({
    options: {
        parentViewModel: Optional
    },

    windowName: "kiosk-disable-modal-window",
    className: "modal-window kiosk-disable-modal-window green-border",

    initialize() {
        this.viewModel = new ViewModel({
            parentViewModel: this.options.parentViewModel || null,
            vm_email: '',
            vm_password: '',
            vm_passwordError: '',
            vm_canContinue: false
        });

        if (LoginedUserHandler.getLoginedUser()) {
            this.viewModel.set('vm_email', LoginedUserHandler.getLoginedUser().email);
        } else {
            this.showError('User not found', undefined, undefined,
                { dontSendToGaAndSentry: true });
            this.close(false);
        }

        this.viewModel.addComputed('vm_canContinue', {
            deps: ['vm_password', 'disabled'],
            get: (vm_email, disabled) => !!(vm_email && !disabled)
        });

        return Modal.prototype.initialize.apply(this, arguments);
    },

    onRender() {
        const contentView = new LoyaltyKioskDisableContent({
            viewModel: this.viewModel
        });
        this.listenTo(contentView, 'continue', () => this._onContinue());
        this.content.show(contentView);

        const footerView = new FooterView({
            buttons: [{
                id: "close",
                text: "CANCEL",
                icn: "empty"
            }, {
                id: "continue",
                text: "CONTINUE",
                icn: "empty"
            }]
        });
        footerView.listenTo(this.viewModel, 'change:vm_canContinue', () => {
            footerView.ui.btncontinue.prop('disabled', !this.viewModel.get('vm_canContinue'));
        });

        this.listenTo(footerView, 'close:click', view => this.close(false));
        this.listenTo(footerView, 'continue:click', view => this._onContinue());
        this.footer.show(footerView);
        footerView.ui.btncontinue.prop('disabled', !this.viewModel.get('vm_canContinue'));
    },

    onShow() {
        const ret = Modal.prototype.onShow.call(this);

        this.content && this.content.currentView && this.content.currentView.ui &&
            this.content.currentView.ui.password && this.content.currentView.ui.password.focus();

        return ret;
    },

    cancel() {
        this.close(false);
    },

    async _checkPassword() {
        return new Promise((resolve, reject) => {
            this.viewModel.set('disabled', true);
            this.showLoading();

            Server.callServer({
                url: settings.host + settings.serv_user.authorize,
                dataType: 'json',
                method: 'POST',
                data: {
                    email: this.viewModel.get('vm_email'),
                    password: this.viewModel.get('vm_password')
                },
                success: (data, textStatus, jqXHR) => {
                    this.viewModel.set('disabled', false);
                    this.hideLoading();

                    resolve(data);
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    this.viewModel.set('disabled', false);
                    this.hideLoading();

                    if (jqXHR && jqXHR.status === 422) {
                        this.viewModel.set('vm_passwordError', 'Invalid password');
                    } else {
                        this.showError(jqXHR, textStatus, errorThrown);
                    }
                },
            });
        });
    },

    _onContinue() {
        if (this.viewModel.get('vm_canContinue')) {
            this._checkPassword().then(data => this.close(data));
        }
    }
});

export default LoyaltyKioskDisableModal;

@ItemView.options({
    collection: Optional,
})
@ItemView.properties({
    template: template,
    tagName: 'form',
    className: "widget email-editor-modal-content-widget",

    ui: {
        email: '[data-js-email]',
        password: '[data-js-password]',
        passwordError: '[data-js-password-error]'
    },

    computeds: {
        c_Email: {
            deps: ['vm_email'],
            get: email => email
        },
        c_Password: {
            deps: ['vm_password'],
            get: pas => pas,
            set(val) {
                this.viewModel.set('vm_password', val);
                this.viewModel.set('vm_passwordError', '');
            }
        }
    },

    bindings: {
        '@ui.email': 'value:c_Email, disabled:true, attr:{readonly:true}',
        '@ui.password': 'value:c_Password,events:["change","input"]',
        '@ui.passwordError': 'text:vm_passwordError'
    },

    events: {
        'submit'(e) {
            this.trigger('continue');
            e.preventDefault();
        }
    }
})
class LoyaltyKioskDisableContent extends ItemView {
}
