import { Model } from '@b2cmessenger/backbone';
import _ from 'underscore';

let FIELD_TYPE_INTEGER = "INTEGER",
    FIELD_TYPE_VARCHAR = "VARCHAR",
    WIDGET_TYPE_SINGLE = "WSelectBox",
    WIDGET_TYPE_MULTIPLE = "WMultiSelectBox";

let defaults = {
    varname: "unknown",
    position: 9999,
    required: false,
    title: "Unknown",
    field_type: FIELD_TYPE_INTEGER,
    widget: WIDGET_TYPE_SINGLE,
    widgetparams: { default: 0 },
    FIELD_TYPE_INTEGER,
    FIELD_TYPE_VARCHAR,
    WIDGET_TYPE_SINGLE,
    WIDGET_TYPE_MULTIPLE,
};

let PropertyModel = Model.extend({
    defaults: defaults,

    constructor: function(attrs, ...args) {
        attrs = attrs || {};
        return Model.call(this, this.parse(attrs), ...args);
    },

    parse(data) {
        return _.chain(data)
            .clone()
            .pick(value => _.isNumber(value) || _.isArray(value) || _.isObject(value) || _.isBoolean(value) || value)
            .defaults(defaults)
            .mapObject((val, key) =>
                key == 'varname' ? String(val) :
                key == 'position' ? Number(val) :
                key == 'required' ? Boolean(val) :
                key == 'title' ? String(val) :
                key == 'field_type' ? (val == FIELD_TYPE_INTEGER ? FIELD_TYPE_INTEGER : FIELD_TYPE_VARCHAR) :
                key == 'widget' ? (val == WIDGET_TYPE_MULTIPLE ? WIDGET_TYPE_MULTIPLE : WIDGET_TYPE_SINGLE) :
                key == 'widgetparams' ? (_.isObject(val) ? val : { default: 0 }) :
                val
            )
            .value();
    },

    toJSON(options) {
        let data = Model.prototype.toJSON.call(this, options);
        data.required = Number(Boolean(data.required));
        return data;
    }
});

PropertyModel.FIELD_TYPE_INTEGER = FIELD_TYPE_INTEGER;
PropertyModel.FIELD_TYPE_VARCHAR = FIELD_TYPE_VARCHAR;
PropertyModel.WIDGET_TYPE_SINGLE = WIDGET_TYPE_SINGLE;
PropertyModel.WIDGET_TYPE_MULTIPLE = WIDGET_TYPE_MULTIPLE;

export default PropertyModel;
