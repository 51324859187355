import './style.scss';
import './style-ios.scss';
import './style-ios9.scss';
import './style-ios10.scss';
import './style-iphone-x.scss';
import './style-android.scss';
import './style-browser.scss';
import 'npm-font-open-sans/open-sans.css';
import 'ionicons/dist/css/ionicons.css';
import 'utils/Date.toISOStringTZ';
import requestAnimationFrames from 'utils/requestAnimationFrames';

import settings from 'settings';
import { Application, Model, View, DeferredPromise, Backbone } from '@b2cmessenger/backbone';
import AjaxError from 'utils/AjaxError';
import escapeHtml from 'utils/escapeHtml';
import quoteattr from 'utils/quoteattr';
import Controller from './Controller';
import Router from './Router';
import Page from 'pages/Page';
import Window from 'windows/Window';
import { showLoading, hideLoading } from 'windows/Modal/Loading';
import { showMessage, showError } from 'windows/Modal/Info';
import ConfirmModal from 'windows/Modal/Confirm';

import MessageGalleryWindow from 'windows/MessageGallery/MessageGallery';

import QrCodeModel from 'models/QrCodeModel';
import NotificationModel from 'models/NotificationModel';
import NotificationMuteCollection from "models/NotificationMuteCollection";
import GiftsList from 'windows/Gifts/GiftsList';
import DefaultWorkPlaceChooserWindow from 'windows/PlaceChooser/DefaultWorkPlaceChooser';

import LoyaltyCardBase from 'utils/LoyaltyCardsOfflineStorage';
import TaskTargetModel from 'models/TaskTargetModel';
import { userDeviceInfo } from 'utils/UserDeviceInfo';
import GoogleAnalytics from 'utils/GoogleAnalytics';
import ScreenSleep from 'utils/ScreenSleep';

////////////
////////////////////////////////////////////////////////////

////////////////////////
/////////

import * as Crutches from 'utils/crutches';
import wrapAjax from 'utils/wrapAjax';
import autosize from 'autosize';
import store from './store';
import './legacy';
import DeepLinking, { getMenuTableFromDeepLinkPath, parseURL } from 'utils/DeepLinking';
import { scanMenuTable } from "store/menu/actions";

///////////////
import * as Sentry from "@sentry/browser";
//////////

///////////////
//////////////////////////////////////
//////////

View.prototype.showMessage = showMessage;
View.prototype.showError = showError;
Window.prototype.showLoading = showLoading;
Window.prototype.hideLoading = hideLoading;
Page.prototype.showLoading = showLoading;
Page.prototype.hideLoading = hideLoading;

const MIN_WIDTH = 960;

const App = Application.extend({
    showLoading, hideLoading, showMessage, showError,
    host: document.body.getAttribute('API_ENDPOINT') || HOST,
    regions: {
        keyboardAdditionalPanel: '#keyboardAdditionalPanel'
    },

    getHeaderPadding() {
        return new Promise((resolve, reject) => {
            if (_.isUndefined(this._headerPadding)) {
                if (window.device && window.device.platform == "Android" && window.StatusBar) {
                    StatusBar.styleBlackTranslucent();

                    StatusBar.height(height => {
/////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////

                        this._headerPadding = height * 0.85;
                        resolve(this._headerPadding);
                    }, e => {
                        console.error('StatusBar.height()', e);
                    });
                } else {
                    resolve(this._headerPadding);
                }
            } else {
                resolve(this._headerPadding);
            }
        });
    },

    initialize() {
        document.body.removeAttribute('API_ENDPOINT');
        this.isPaused = false;

        this.localStorage = true;
        this.version = VERSION.webVersion;
        this.gitHash = VERSION.gitHash;
        this.cordovaAppVersion = VERSION.appVersion;

        this.deferredReady = new DeferredPromise;

        this.controller = new Controller();
        this.router = new Router({ controller: this.controller });

        this.notificationCollection = window.notificationCollection = this.controller.notificationCollection;
        this.notificationMuteCollection = window.notificationMuteCollection = new NotificationMuteCollection();
        loadGoogleMap();

        window.addEventListener('resize', this.calculateWindowLayoutType.bind(this));

        this.calculateWindowLayoutType();

        Object.defineProperty(this, 'isKioskMode', {
            get() {
                return LoginedUserHandler.isLoyaltyKioskModeEnabledForDefaultWorkPlace();
            }
        });

        this.listenTo(LoginedUserHandler, 'change:loyaltyKioskEnabledFor', val => {
            this.onKioskModeChanged(val);
            this.trigger('change:isKioskMode', val !== null);
        });
    },

    calculateWindowLayoutType() {
        const isMobileView = window.innerWidth < MIN_WIDTH;
        const shouldTriggerEvent = _.isBoolean(this.isMobileView);

        if (this.isMobileView != isMobileView) {
            this.isMobileView = isMobileView;

            if (shouldTriggerEvent) {
                this.trigger('change:is:mobile:view', this.isMobileView);
            }
        }
    },

    onStart() {
        try {
///////////////////////////
////////////////////////////////////////////////////////
//////////////////////

            const app = this;
            settings.host = this.host;

///////////////////////////
            if (!window.Sentry) {
/////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
/////////////////////////
                window.Sentry = Sentry;
                Sentry.init({
                    dsn: settings.sentryDsn,
                    release: `${app.version}-web-app-release`,
                });
//////////////////////////
            }
//////////////////////

///////////////////////////
//////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////
//////////////////////////////////////////////////

/////////////////////////////
////////////////////////////////////////////
//////////////////////////////////////////////////////////////
/////////////////////////
///////////////////////////////////////////////////////////
//////////////////
//////////////////////////
/////////////

///////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
/////////////

////////////////////////////////
//////////////////////////////////////////////////////
///////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
/////////////////////
///////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////
/////////////////////
///////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
/////////////////////
/////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
/////////////////
/////////////

/////////////////////////////////////////////////////////////
/////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
//////////////////

///////////////////////////////////////////////////////////////////////
///////////////////////////////////////
///////////////////////////////////////////////////////
///////////////////////
/////////////////

/////////////////////////////
///////////////////////////////////
/////////////////////////////////
//////////////////
//////////////////////////

/////////////////////////////////////////////////////////
///////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
//////////////////////
////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////
//////////////////
/////////////

/////////////////////
            document.body.classList.add('browser');
//////////////////////

            // GoogleAnalytcs wrapper
            window.GoogleAnalytics = GoogleAnalytics;
            GoogleAnalytics.initialize().then(() => {
/////////////////////////////
///////////////////////////////////////////////////
//////////////////////////

                GoogleAnalytics.setAppVersion(app.version);
                GoogleAnalytics.enableUncaughtExceptionReporting(true);

                const globalOnError = window.onerror;
                window.onerror = (msg, url, line, column, errorObj) => {
                    GoogleAnalytics.trackException(String(msg) + ',\n' + JSON.stringify({
                        url,
                        line,
                        column,
                        errorObj
                    }, null, '  '), false);
                    if (globalOnError) return globalOnError(msg, url, line, column, errorObj);
                    return false;
                };

                const globalOnunhandledrejection = window.onunhandledrejection;
                window.onunhandledrejection = function (reason) {
                    try {
                        let err = 'Unhandled promise rejection';
                        if (reason.reason) {
                            err += ',\n' + ((reason.reason instanceof String) ? reason.reason : JSON.stringify(reason.reason));
                        } else if (reason instanceof Error) {
                            err += ',\n' + JSON.stringify(reason);
                        } else if (String(reason)) {
                            err += ',\n' + String(reason);
                        }
                        GoogleAnalytics.trackException(err, false);
                    } catch (e) {
                    }

                    if (globalOnunhandledrejection) {
                        return globalOnunhandledrejection.apply(this, arguments);
                    }
                };

                if (LoginedUserHandler.isUserLogined()) {
                    GoogleAnalytics.setUserId(LoginedUserHandler.getLoginedUser().id);
                    GoogleAnalytics.addCustomDimension(1, LoginedUserHandler.getLoginedUser().id);
                    GoogleAnalytics.addCustomDimension(2, (LoginedUserHandler.get_default_work_place_id() ?
                        LoginedUserHandler.get_default_work_place_id() : -1));
                }
            });

            const ajax = wrapAjax(Backbone.ajax);

            Backbone.ajax = function (_options) {
                const options = _.create(null, _options);

                _.defaults(options, {
                    headers: {},
                    autoRetryOnUnauthorized: true
                });

                if (app.version) {
                    _.defaults(options.headers, {
                        'App-Version': app.cordovaAppVersion || app.version,
                        'Core-Version': app.version
                    });
                }

                if (window.device && window.device.uuid) {
                    _.defaults(options.headers, {
                        'Device-Uuid': window.device.uuid
                    });
                }

                const token = LoginedUserHandler.getAccessToken();
                if (token) {
                    _.defaults(options.headers, {
                        'Authorization': `Bearer ${token}`
                    });
                }

                if (options.autoRetryOnUnauthorized) {
                    const error = options.error,
                        that = this;

                    options.error = function (jqXHR, textStatus, errorThrown) {
                        if (jqXHR.status == 401 &&
                            options.url != settings.host + settings.serv_user.authorize &&
                            options.url != settings.host + settings.serv_user.create &&
                            options.url != settings.host + settings.serv_user.facebook_login &&
                            options.url != settings.host + settings.serv_user.google_login
                        ) {
                            if (error) {
                                error(jqXHR, textStatus, errorThrown);
                            }

                            if (LoginedUserHandler.isUserLogined()) {
                                LoginedUserHandler.logout();
                                app.controller.restart();
                            } else {
                                app.controller.goToLoginPage();
                            }

                        } else if (error) {
                            error(jqXHR, textStatus, errorThrown);
                        }
                    }
                }

                return ajax.call(this, options);
            };

            this.ajax = Backbone.ajax;

            geo.init();

            $(() => Promise.resolve()
                .then(() => {
///////////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////////

                    $(document.body).on(
                        'change blur update',
                        '.window input[type="datetime-local"], .window input[type="date"], .window input[type="time"], ' +
                        '.page input[type="datetime-local"], .page input[type="date"], .page input[type="time"]',
                        function (e) {
                            const $this = $(this);
                            _.defer(() => {
                                if (!$this.val()) $this.addClass('has-no-value');
                                else $this.removeClass('has-no-value');
                            });
                        }
                    );
                })
                .then(() => this.controller.start(this.router))
                .then(() => new Promise((resolve, reject) => {
                    document.addEventListener("backbutton", e => {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        e.stopPropagation();

                        if (app.isKioskMode) return;
                        document.dispatchEvent(new Event(settings.backbutton.eventName));
                    }, false);

//////////////////////////////////
                    document.addEventListener('keyup', e => {
                        if (e.key === 'Escape' || e.keyCode === 27) {
                            e.preventDefault();
                            e.stopImmediatePropagation();
                            e.stopPropagation();

                            if (app.isKioskMode) return;
                            document.dispatchEvent(new Event(settings.backbutton.eventName));
                        }
                    }, false);
/////////////////////////////

                    document.addEventListener(settings.backbutton.eventName, e => {
                        e.preventDefault();
                        if (Window.isActiveWindow()) {
                            Window.cancelActiveWindow();
                        } else {
                            if (!Page.goBack()) {
                                if (navigator.app && navigator.app.exitApp) {
                                    new ConfirmModal({
                                        message: "Close Say2B?",
                                        additionalClassName: 'red-border',
                                        buttons: [{
                                            id: "close",
                                            text: "CANCEL",
                                            icn: "empty",
                                            value: false,
                                        }, {
                                            id: "yes",
                                            text: "CLOSE",
                                            icn: "empty",
                                            value: true
                                        }]
                                    })
                                        .show()
                                        .then(ans => ans && navigator.app.exitApp());
                                }
                            }
                        }
                    }, false);

                    if (app.isKioskMode) {
                        this.onKioskModeChanged(app.isKioskMode);
                    }

                    requestAnimationFrame(() => {
                        requestAnimationFrames(() => {
                            navigator.splashscreen && navigator.splashscreen.hide()
                        }, 5);

///////////////////////////////////////
////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
///////////////////////////////////////
/////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////
////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
///////////////////////////////////////////
//////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////
                        resolve();
                    });
                }))
                .then(() => this.deferredReady.resolve())
                .catch(e => this.deferredReady.reject(e))
            );

            document.addEventListener('pause', this.onPause.bind(this), false);
            document.addEventListener('resume', this.onResume.bind(this), false);

//////////////////////////
            document.addEventListener("visibilitychange", () => {
                if (document.hidden) {
                    document.dispatchEvent(new Event("pause"));
                } else {
                    document.dispatchEvent(new Event("resume"));
                }
            }, false);
/////////////////////

///////////////////////////
//////////////////////////////////////////////////////
//////////////////////
        } catch (e) {
            this.deferredReady.reject(e);
        }
    },

    onPause() {
////////////////////
/////////////////////////////////////
/////////////////

        window.geo && geo.pause();
        this.controller.notificationFetcher && this.controller.notificationFetcher.pause();

        this.isPaused = true;
    },

    onResume() {
////////////////////
//////////////////////////////////////
/////////////////

        this.isPaused = false;

        window.geo && geo.resume();
        this.controller.notificationFetcher && this.controller.notificationFetcher.resume();

    },

    onKioskModeChanged(value) {
        const isKioskMode = value !== null;

        document.body.classList.toggle('loyalty-kiosk', isKioskMode);
        ScreenSleep.keepScreenAlwaysOn(isKioskMode)
            .then(() => {
/////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////
            })
            .catch(e => this.showError(e));
    },

    executeNotificationAction(notification) {
        return this._addToActionQueue(this._executeNotificationAction.bind(this, notification), notification);
    },

    executeSilentNotificationAction(notification) {
        return this._addToActionQueue(this._executeSilentNotificationAction.bind(this, notification), notification);
    },

    executeQrAction(qr, workPlaceId) {
        return this._addToActionQueue(this._executeQrAction.bind(this, qr, workPlaceId), qr, workPlaceId);
    },

    executeDynamicLink(dl) {
        return this._addToActionQueue(this._executeDynamicLink.bind(this, dl));
    },

    _addToActionQueue(action, ...additionalInfo) {
        if (!this.actionQueue) {
            this.actionQueue = this.deferredReady;
        }

        return this.actionQueue = this.actionQueue
            .then(() => Promise.resolve()
                .then(action)
                .catch(e => {
                    e.additionalInfo = additionalInfo;
                    window.onunhandledrejection && window.onunhandledrejection(e);

/////////////////////////////////
/////////////////////////////////////////////////////
//////////////////////////////
                })
            );
    },

    _executeNotificationAction(notification) {
        if (_.isString(notification) || _.isNumber(notification)) {
            notification = this.notificationCollection.get(notification);
        }

        notification.markAsRead().catch(e => this.showError(e));

        if (notification) {
            if (Window.isActiveWindow()) {
                Window.cancelActiveWindow();
            }

            const data = notification.get('data'),
                app_event = notification.get('app_event');

            switch (app_event) {
                case NotificationModel.Type.NewGift: {
                    if (!data.brand_id || isNaN(data.brand_id)) {
                        return;
                    }

                    new GiftsList({
                        userId: LoginedUserHandler.loginedUser.id,
                        brandId: data.brand_id,
                        scrollToGiftWithId: data.gift_id || false
                    }).show();

                    break;
                }
                case NotificationModel.Type.NewTask: {
                    const taskId =
                        Number(data.task_dummy_id)
                        && Number(data.place_id)
                        && LoginedUserHandler.isUserEmployee(Number(data.place_id))
                        && Number(data.task_dummy_id)
                        ||
                        Number(data.task_id);

                    if (taskId) {
                        app.controller.goToTaskInClosestPage({
                            taskId,
                            includeReservation: !!data.reservation_id
                        });
                    }
                    break;
                }
                case NotificationModel.Type.NewTaskSolutionRating:
                case NotificationModel.Type.TaskSolution:
                case NotificationModel.Type.NewComment: {
                    const taskId =
                        Number(data.task_dummy_id)
                        && Number(data.place_id)
                        && LoginedUserHandler.isUserEmployee(Number(data.place_id))
                        && Number(data.task_dummy_id)
                        ||
                        Number(data.task_id);

                    if (taskId) {
                        app.controller.goToTaskInClosestPage({
                            taskId,
                            scrollToTask: taskId,
                            scrollToComment: (data.parent_id && data.parent_id != taskId) ?
                                data.parent_id : data.comment_id,
                            scrollToSubComment: (data.parent_id && data.parent_id != taskId) ?
                                data.comment_id : null,
                            includeReservation: !!data.reservation_id
                        });
                    }
                    break;
                }
                case NotificationModel.Type.EmployeeInvite: {
                    app.controller.showNotificationsWindow({ scrollToNotification: notification.id });
                    break;
                }
                case NotificationModel.Type.MenuOrderCreated:
                case NotificationModel.Type.MenuOrderUpdated:
                case NotificationModel.Type.MenuWaiterCall:
                    if (notification && notification.get('data') && notification.get('data').order_id) {
                        app.controller.goToOrderFromNotification({ notification })
                    } else if (notification && notification.get('data') && notification.get('data').place_id) {
                        app.controller.goToMenuDashboard({ placeId: notification.get('data').place_id });
                    }
                    break;
                default: {
                    const taskId =
                        Number(data.task_dummy_id)
                        && Number(data.place_id)
                        && LoginedUserHandler.isUserEmployee(Number(data.place_id))
                        && Number(data.task_dummy_id)
                        ||
                        Number(data.task_id);

                    if (taskId) {
                        app.controller.goToTaskPage({
                            taskId,
                            includeReservation: !!data.reservation_id,
                            reload: true
                        });
                    }
                }
            }
        }
    },

    _executeSilentNotificationAction(notification) {
        if (_.isString(notification) || _.isNumber(notification)) {
            notification = this.notificationCollection.get(notification);
        }

        notification.markAsRead().catch(e => this.showError(e));

        if (notification) {
            const data = notification.get('data'),
                app_event = notification.get('app_event');

            switch (app_event) {
                case NotificationModel.Type.EmployeePermissionsChange: {
                    if (data.place_id && data.roles !== undefined) {
                        LoginedUserHandler.update_work_place_roles(data.place_id, data.roles);
                    }
                    break;
                }
                default:
                    void 0;
            }

////////////////////////
///////////////////////////////////////////////////////////////////////
/////////////////////
        }
    },
    /**
     *
     * @param {QrCodeModel} qr
     * @returns {Promise}
     * @private
     */
    _executeQrAction(qr, workPlaceId) {
        const loginedUser = LoginedUserHandler.getLoginedUser(),
            workPlaces = loginedUser && LoginedUserHandler.get_user_work_places(),
            defaultWorkPlace = LoginedUserHandler.get_work_place_by_id(workPlaceId) || LoginedUserHandler.get_default_work_place();

        switch (qr.get('type')) {
            case QrCodeModel.Type.UserProfile: {
                GoogleAnalytics && GoogleAnalytics.trackEvent('QR', 'scan', 'user', qr.get('userId'));

                if (loginedUser) {
                    return new Promise((resolve, reject) => {
                        if (defaultWorkPlace) {
                            resolve(defaultWorkPlace);
                        } else if (workPlaces) {
                            new DefaultWorkPlaceChooserWindow({ closeOnSelect: true })
                                .show()
                                .then(defaultWorkPlace =>
                                    resolve(defaultWorkPlace && defaultWorkPlace.toJSON()));
                        } else {
                            resolve();
                        }
                    })
                        .then(place => place || new Promise((resolve, reject) => {
                            B2CPlace.server_get(
                                {
                                    id: 1, //Say2b!!!
                                    anonym: loginedUser && loginedUser.settings.viewanonym,
                                    unverified: loginedUser && loginedUser.settings.viewunverified,
                                    limphotosb: 0,
                                    limphotosu: 0,
                                },
                                resolve,
                                (jqXHR, textStatus, errorThrown) => {
                                    reject(new AjaxError(jqXHR, textStatus, errorThrown));
                                }
                            )
                        }))
                        .then(place => place && new Promise((resolve, reject) =>
                            Server.callServer({
                                url: settings.host + settings.serv_user.search,
                                type: 'POST',
                                data: { id: qr.get('userId') },
                                success(users) {
                                    try {
                                        const user = _.find(users, u => u.id == qr.get('userId'));
                                        if (user) {
                                            if (LoginedUserHandler.isHasRole(place.id, B2CEmployee.const.roles.EVIDENCE_MANAGER)) {
                                                B2CPlace.server_business_checkin(user.id, place.id);
                                            }
                                            app.controller.goToOtherUserPage({
                                                user: user,
                                                place: place
                                            });
                                        }
                                        resolve();
                                    } catch (e) {
                                        reject(e);
                                    }
                                },
                                error(jqXHR, textStatus, errorThrown) {
                                    reject(new AjaxError(jqXHR, textStatus, errorThrown));
                                },
                            })
                        ));
                }
                break;
            }
            case QrCodeModel.Type.GiftWriteOff: {
                GoogleAnalytics && GoogleAnalytics.trackEvent('QR', 'scan', 'gift', qr.get('brandId'));

                return new Promise((resolve, reject) =>
                    Server.callServer({
                        url: settings.host + settings.serv_user.search,
                        type: 'POST',
                        data: { id: qr.get('userId') },
                        success(users) {
                            try {
                                const user = _.find(users, u => u.id == qr.get('userId'));
                                if (user) {
                                    new GiftsList({
                                        user,
                                        brandId: qr.get('brandId'),
                                        initiateWriteoffGiftTemplateWithId: qr.get('giftTemplateId'),
                                        initiateWriteoffGiftQuantity: qr.get('giftWriteoffQuantity'),
                                    }).show().then(resolve, resolve);
                                } else {
                                    resolve();
                                }
                            } catch (e) {
                                reject(e);
                            }
                        },
                        error(jqXHR, textStatus, errorThrown) {
                            reject(new AjaxError(jqXHR, textStatus, errorThrown));
                        },
                    })
                );
            }
            case QrCodeModel.Type.LoyaltyCard: {
                GoogleAnalytics && GoogleAnalytics.trackEvent('QR', 'scan', 'loyalty card', qr.get('loyaltyCardNumber'));

                if (loginedUser) {
                    return new Promise((resolve, reject) => {
                        if (defaultWorkPlace) {
                            resolve(defaultWorkPlace);
                        } else if (workPlaces) {
                            new DefaultWorkPlaceChooserWindow({ closeOnSelect: true })
                                .show()
                                .then(defaultWorkPlace =>
                                    resolve(defaultWorkPlace && defaultWorkPlace.toJSON()));
                        } else {
                            resolve();
                        }
                    })
                        .then(workPlace => workPlace && new Promise((resolve, reject) =>
                            Server.callServer({
                                url: settings.host + settings.serv_user.search,
                                type: 'POST',
                                data: {
                                    loyalty_card_number: qr.get('loyaltyCardNumber'),
                                    limit: 99,
                                    current_place_id: workPlace.id,
                                    current_brand_id: workPlace.brand_id
                                },
                                success(users) {
                                    try {
                                        if (users.length === 1) {
                                            const user = users[0];
                                            if (LoginedUserHandler.isHasRole(workPlace.id, B2CEmployee.const.roles.EVIDENCE_MANAGER)) {
                                                B2CPlace.server_business_checkin(user.id, workPlace.id);
                                            }
                                            app.controller.goToOtherUserPage({
                                                user: user,
                                                place: workPlace
                                            });
                                        } else if (users.length > 1) {
                                            app.controller.goToUserSearchPage({
                                                placeId: workPlace.id,
                                                users,
                                                loyaltyCardNumber: qr.get('loyaltyCardNumber')
                                            })
                                        }
                                        resolve();
                                    } catch (e) {
                                        reject(e);
                                    }
                                },
                                error(jqXHR, textStatus, errorThrown) {
                                    reject(new AjaxError(jqXHR, textStatus, errorThrown));
                                },
                            })
                        ))
                }
                break;
            }
            case QrCodeModel.Type.DeepLink: {
                const { link = null } = qr.get('deepLink');

                if (link !== null) {
                    GoogleAnalytics && GoogleAnalytics.trackEvent('QR', 'scan', 'deeplink', link);
                    this.executeDynamicLink({ deepLink: link, clickTimestamp: Date.now(), from: 'qrcodescanner' });
                }
                break;
            }
        }
    },
    _executeDynamicLink(payload) {
        const { deepLink = null, clickTimestamp } = payload;

        if (deepLink !== null) {
            const link = parseURL(deepLink);
            const options = {
                ..._.omit(link.searchObject, 'replace'),
                deepLink: { ...payload, parsedLink: link },
                trigger: true
            };

            const menuTable = getMenuTableFromDeepLinkPath(link.hash.substr(1));

            if (menuTable) {
                store.dispatch(scanMenuTable(menuTable.placeId, menuTable.tableId));
            } else {
                app.router.navigate(link.hash.substr(1), options);
                console.log(`Navigate to ${link.hash} via dynamic link with options`, options);
            }
        } else {
            console.warn('Received dynamic link within empty deep link value', payload);
        }
    }
});

//TODO: remove globals!
window.pref = settings;
window.App = App;
window.MessageGalleryWindow = MessageGalleryWindow;
window.VERSION = VERSION;
window.autosize = autosize;
/////////////
////////////////////
//////////
///////////////
window.RELEASE = true;
//////////
window.Crutches = Crutches;
window._ = _;
window.LoyaltyCardBase = LoyaltyCardBase;
window.TaskTargetModel = TaskTargetModel;
window.userDeviceInfo = userDeviceInfo;
window.showMessage = showMessage;
window.showError = showError;
window.NotificationModel = NotificationModel;
window.AjaxError = AjaxError;
window.escapeHtml = escapeHtml;
window.quoteattr = quoteattr;
///////////////
//////////////////////
////////////////////////////////////////
//////////
