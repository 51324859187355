import { Model, ViewModel, LayoutView } from '@b2cmessenger/backbone';
import settings from 'settings';

import Modal from './Modal';
import FooterView from 'widgets/Footer/Footer';
import GiftTemplateChooseWindow from 'windows/GiftTemplates/GiftTemplates';
import GiftWidget from 'widgets/Gift/Gift';
import GiftModal from 'windows/Modal/Gift';

import template from './Invite.jade';
import './Invite.scss';

const InviteModal = Modal.extend({
    windowName: "invite-modal-window",
    className: "modal-window info-modal-window invite-modal-window",

    onRender() {
        if(this.options.additionalClassName) {
            this.$el.addClass(this.options.additionalClassName);
        }

        let footerView = new FooterView({
            buttons: [
                {
                    id: "close",
                    text: "CANCEL",
                    icn: "empty"
                },
                {
                    id: "send",
                    text: "SEND",
                    icn: "empty"
                }]
        });

        this.listenTo(footerView, 'close:click', view => this.close(false));
        this.listenTo(footerView, 'send:click', view => {
            this.trigger('disable');

            let data = _.create(null, {
                place_id: this.model.get('place_id'),
                email: this.model.get('email'),
                user_datetime: new Date().toISOStringTZ(),
                gift_quantity: this.model.get('gift_quantity') || null,
                gift_transformation_type: this.model.get('gift_transformation_type') || null,
                gift_receipt_amount: this.model.get('gift_receipt_amount') || null
            });

            if (this.model.get('firstname')) {
                data.firstname = this.model.get('firstname');
            }

            if (this.model.get('lastname')) {
                data.lastname = this.model.get('lastname');
            }

            if (this.model.get('gift_template_id')) {
                data.gift_template_id = this.model.get('gift_template_id');
            }

            Server.callServer({
                url: settings.host + settings.serv_invite.invite,
                data,
                type: 'POST',
                success: () => {
                    this.trigger('enable');

                    const firstname = this.model.get('firstname'),
                        lastname = this.model.get('lastname'),
                        email = this.model.get('email'),
                        toName = (firstname || lastname) ?
                            'to ' + ((firstname && lastname) ? (firstname + ' ' + lastname) : (firstname || lastname)) :
                            (email ? ('to ' + email) : '');

                    this.showMessage(`Invite ${toName} successfully sent!`);
                    this.close(this.model);
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    this.trigger('enable');
                    this.showError(jqXHR, textStatus, errorThrown);
                }
            });
        });
        this.footer.show(footerView);

        this.content.show(new ContentWidget({
            model: this.model
        }));
    },

    cancel() {
        this.close(false);
    }
});

const ContentWidget = LayoutView.extend({
    template,
    className: 'widget gift-choose-widget',

    regions: {
        gift: '[data-js-gift]'
    },

    ui: {
        firstname: '[data-js-input-group-firstname] input',
        lastname: '[data-js-input-group-lastname] input',
        gift: '[data-js-gift]',
        btnAddGift: '[data-js-btn-add-gift]',
    },

    bindings: {
        '@ui.firstname': 'value:firstname',
        '@ui.lastname': 'value:lastname',
        '@ui.gift': 'classes:{hidden:not(gift_template_id)},disabled:disabled',
        '@ui.btnAddGift': 'classes:{hidden:gift_template_id},disabled:disabled'
    },

    events: {
        'click @ui.btnAddGift': 'onBtnAddGiftClick',
    },

    initialize() {
        this.model.set({
            firstname: this.model.get('firstname') || "",
            lastname: this.model.get('lastname') || "",
            gift_template_id: this.model.get('gift_template_id') || false,
            gift_quantity: null
        });

        this.viewModel = new ViewModel;
    },

    onBtnAddGiftClick() {
        if (!this.viewModel.get('disabled')) {
            new GiftTemplateChooseWindow({
                brandId: this.model.get('place_id'),
                choosable: true
            })
                .show()
                .then(template => {
                    if (template) {
                        let expireDateLocal = null;

                        if (template.get('period')) {
                            expireDateLocal = new Date();
                            expireDateLocal.setDate(expireDateLocal.getDate() + template.get('period'));
                            expireDateLocal.setHours(23, 59, 59, 999);
                        }

                        if (template.get('total_period')) {
                            let totalExpireDate = new Date(template.get('total_period').replace(/ /g, "T"));

                            let totalExpireDateLocal = new Date(totalExpireDate);
                            totalExpireDateLocal.setFullYear(totalExpireDate.getUTCFullYear(), totalExpireDate.getUTCMonth(), totalExpireDate.getUTCDate());
                            totalExpireDateLocal.setHours(23, 59, 59, 999);

                            if (!expireDateLocal || expireDateLocal > totalExpireDateLocal) expireDateLocal = totalExpireDateLocal;
                        }

                        const
                            giftModel = new Model({
                                name: template.get('name') || 'Coins',
                                thumb: template.get('thumb'),
                                expireDate: expireDateLocal,
                                type: template.get('type'),
                                quantity: template.get('quantity')
                            }),
                            giftWidget = new GiftWidget({
                                model: giftModel,
                                quantityEditable: !!template.get('custom_quantity_allowed'),
                                additionalMessage: template.get('transformation_type') ?
                                    'or go to receipt conversion' : '',
                                isAdditionalMessageInterative: !!template.get('transformation_type'),
                                overImageMessage: 'Tap to change gift'
                            });

                        this._stopListeningCurrentGiftView();
                        this.gift.show(giftWidget);

                        this.listenTo(giftModel, 'change:quantity', (m, quantity) => {
                            this.model.set({ gift_quantity: quantity })
                        });

                        this.listenTo(giftWidget, 'image:click',
                            () => this.onBtnAddGiftClick());

                        this.listenTo(giftWidget, 'additionalMessage:click', () =>
                            this._showGiftModal(template,
                                this.model.get('gift_receipt_amount') || template.get('receiptAmount') || 0
                            )
                                .then(template => {
                                    if (template && template.get('quantity')) {
                                        giftModel.set({ quantity: template.get('quantity') });

                                        if (template.get('receiptAmount') && template.get('transformation_type')) {
                                            this.model.set({
                                                gift_template_id: template.id,
                                                gift_quantity: giftModel.get('quantity') || null,
                                                gift_transformation_type: template.get('transformation_type') || null,
                                                gift_receipt_amount: template.get('receiptAmount') || null
                                            });

                                            this.model.listenToOnce(giftModel, 'change:quantity', () => {
                                                this.model.set({
                                                    gift_transformation_type: null,
                                                    gift_receipt_amount: null
                                                });

                                                template.set({
                                                    receiptAmount: 0
                                                })
                                            });
                                        }
                                    }
                                })
                        );

                        this.model.set({
                            gift_template_id: template.id,
                            gift_quantity: giftModel.get('quantity') || null,
                            gift_transformation_type: null,
                            gift_receipt_amount: null
                        });
                    }
                });
        }
    },

    _showGiftModal(template, startingReceiptAmount) {
        return new GiftModal({
            startingReceiptAmount,
            model: template,
            user: {
                firstname: this.model.get('firstname'),
                lastname: this.model.get('lastname'),
                email: this.model.get('email')
            }
        }).show();
    },

    _stopListeningCurrentGiftView() {
        if (this.gift.hasView()) {
            const gw = this.gift.currentView;
            this.stopListening(gw.model);
            this.stopListening(gw);
        }
    }
});

export default InviteModal;
