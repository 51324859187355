import { Model, ViewModel, Collection, ItemView, LayoutView, CollectionView } from '@b2cmessenger/backbone';
import settings from 'settings';

import Window from 'windows/Window';
import HeaderView from 'widgets/Header/Header';
import NotificationSwitchWidget from 'widgets/NotificationControl/NotificationSwitch';
import NotificationSwitchModel from 'widgets/NotificationControl/NotificationSwitchModel';
import NotificationMuteModel from "models/NotificationMuteModel";

import template from './Content.jade';
import './Settings.scss';

import notificationMuteTemplate from './NotificationMute.jade';

import buildPlaceLiteWidget from 'widgets/lite/place/place';

const NotificationSwitchCollectionView = CollectionView.extend({
    childView: NotificationSwitchWidget,
    childViewOptions(model, index) {
        const place = model.get('place') || {
            name: 'Unknwon place'
        };
        return {
            invert: true,
            caption: `Receive work related notifications from ${buildPlaceLiteWidget(place)}`,
            title: 'Receive notifications?',
            text: `<p>If this option is not checked, you won't receive notifications about <em>new tasks</em> and <em>comments</em> from ${buildPlaceLiteWidget(place)}.\n\n` +
                `This doesn't affect <em>personal tasks</em> from employees.</p>`
        };
    }
});

const NotificationMuteWidget = ItemView.extend({
    template: notificationMuteTemplate,
    className: 'widget notification-mute-widget input-group horizontal multiline-label',

    ui: {
        btnRemove: '[data-js-remove]',
        btnHelp: '[data-js-help]'
    },

    bindings: {
        'ui.btnRemove': 'disabled:disabled'
    },

    events: {
        'click @ui.btnRemove'() {
            this.viewModel.set({ disabled: true });
            this.model.destroy({
                wait: true,
                success: () => this.viewModel.set({ disabled: false }),
                error: (model, jqXHR) => {
                    this.viewModel.set({ disabled: false });
                    this.showError(jqXHR);
                }
            });
        },
        'click @ui.btnHelp'() {
            let title = 'Blocked notifications',
                text = `<p>You have blocked notifications of type "${this.model.get('type_title') || this.model.get('type')}".\n\n` +
                    `You can remove block by pressing appropriate button blah-blah-blah</p>`;

            switch (this.model.get('type')) {
                case 'fave':
                    title = 'Blocked news';
                    text = `<p>You have blocked news from ${buildPlaceLiteWidget({ name: (this.model.get('content_subject') || ('#' + this.model.get('place_id'))), thumb: false })}.\n\n` +
                        `You won't receive any notifications about new tasks from this place.\n\n` +
                        `Click <em>Remove</em> to reenable news.</p>`;
                    break;
                case 'task':
                    title = 'Blocked notifications';
                    text = `<p>You have blocked notifications related to task <span class="task"><span class="task-name">` +
                        (this.model.get('content_subject') || ('#' + this.model.get('task_id'))) +
                        `</span></span>.\n\n` +
                        `Click <em>Remove</em> to reenable notifications.</p>`;
                    break;
            }

            this.showMessage({
                title,
                text,
                isHtml: true
            }, {
                border: 'no-border'
            });
        }
    },

    modelEvents: {
        'change:content_subject'() { this.render() },
        'change:type_title'() { this.render() }
    }
});

const NotificationMuteCollectionView = CollectionView.extend({
    childView: NotificationMuteWidget,
    childViewOptions(model, index) {
        return {
            viewModel: this.viewModel
        };
    },

    buildChildView() {
        const view = CollectionView.prototype.buildChildView.apply(this, arguments);
        view.showError = this.showError;
        return view;
    },

    filter(child, index, collection) {
        const type = child.get('type');

        return type == NotificationMuteModel.Type.Fave || type == NotificationMuteModel.Type.Task;
    },

    initialize() {
        this.viewModel = new ViewModel({
            disabled: false
        });
    }
});

const ContentView = LayoutView.extend({
    template: template,
    className: "widget settings-content-widget",

    regions: {
        muteWorkplaces: '[data-js-mute-workplaces]',
        muteFaveAll: '[data-js-mute-fave-all]',
        mutes: '[data-js-mutes]'
    },

    ui: {
        viewAnonymRadio: '[data-js-input-group-viewanonym] > input[type="radio"]',
        viewUnverifiedRadio: '[data-js-input-group-viewunverified] > input[type="radio"]',
        infoVisibility: '[data-js-visibility-info]',
        infoVerification: '[data-js-verification-info]'
    },

    bindings: {
        '@ui.viewAnonymRadio': 'checked:viewanonymNum',
        '@ui.viewUnverifiedRadio': 'checked:viewunverifiedNum',
    },

    events: {
        'click @ui.infoVisibility'() {
            this.showMessage({
                title: "Author visibility",
                text: `<p>If you choose <em>only public</em>, you will see posts, companies' ratings and solutions ratings,` +
                    ` that were set only by <em>public users</em> (users, who don't hide their identities).\n\n` +
                    `If you choose <em>all</em>, you will see posts, companies' ratings and solutions ratings,` +
                    ` that were set by both <em>public</em> and <em>anonymous</em> users.</p>`,
                isHtml: true
            }, {
                border: 'no-border'
            });
        },
        'click @ui.infoVerification'() {
            this.showMessage({
                title: "Author verification",
                text: `<p>If you choose <em>only verified</em>, you will see posts, companies' ratings and solutions ratings,` +
                    ` that were set only by <em>verified users</em> (users, who have <em>evidences</em> of their experiences with these companies/services).\n\n` +
                    `If you choose <em>all</em>, you will see posts, companies' ratings and solutions ratings,` +
                    ` that were set by both <em>verified</em> and <em>unverified</em> users.</p>`,
                isHtml: true
            }, {
                border: 'no-border'
            });
        }
    },

    onRender() {
        const user = LoginedUserHandler.getLoginedUser();
        if (user) {
            const workPlaces = user && LoginedUserHandler.get_user_work_places();

            if (workPlaces && workPlaces.length) {
                const workplaceNotificationSwitchCollection = new Collection(
                    _.map(workPlaces, wp => new NotificationSwitchModel({
                        place: wp,
                    }, {
                        notificationMuteCollection: notificationMuteCollection,
                        notificationMuteAttributes: {
                            type: NotificationMuteModel.Type.BusinessDnd,
                            place_id: wp.id
                        }
                    }))
                );

                this.muteWorkplaces.show(new NotificationSwitchCollectionView({
                    collection: workplaceNotificationSwitchCollection
                }));
            }

            this.muteFaveAll.show(new NotificationSwitchWidget({
                notificationMuteCollection: notificationMuteCollection,
                notificationMuteAttributes: { type: NotificationMuteModel.Type.FaveAll },
                invert: true,
                caption: "Receive news from favorite places?",
                title: 'Receive news?',
                text: "<p>If this option is not checked, you won't receive any notifications about <em>new tasks</em> from <em>favorite</em> places.</p>"
            }));

            const mutesView = new NotificationMuteCollectionView({
                collection: notificationMuteCollection
            });

            mutesView.showError = this.showError;

            this.mutes.show(mutesView);
        }
    }
});

const Settings = Window.extend({
    windowName: "settings-window",
    className: "settings-window",

    initialize() {
        const user = LoginedUserHandler.getLoginedUser();

        this.model = new (Model.extend({
            defaults: {
                viewanonym: false,
                viewunverified: false,
                firstname: "",
                email: ""
            },
            computeds: {
                viewanonymNum: {
                    deps: ["viewanonym"],
                    get: viewanonym => Number(!!viewanonym),
                    set: value => _.create(null, { viewanonym: Boolean(Number(value)) })
                },
                viewunverifiedNum: {
                    deps: ["viewunverified"],
                    get: viewunverified => Number(!!viewunverified),
                    set: value => _.create(null, { viewunverified: Boolean(Number(value)) })
                },
            }
        }))({
            viewanonym: user && user.settings && Boolean(user.settings.viewanonym),
            viewunverified: user && user.settings && Boolean(user.settings.viewunverified),
            firstname: user && user.firstname,
            email: user && user.email
        });

        this.listenTo(this, 'disable', () => {
            this.header.currentView.trigger('disable');
        });

        this.listenTo(this, 'enable', () => {
            this.header.currentView.trigger('enable');
        });

        this.listenTo(this.model, 'change:viewanonym change:viewunverified', this.onChange);
    },

    onRender() {
        const headerView = new HeaderView({
            leftButtons: ['back'],
            title: 'Settings'
        })
        this.listenTo(headerView, 'back:click', view => this.cancel());
        this.header.show(headerView);

        const contentView = new ContentView({
            model: this.model
        });

        contentView.showError = this.showError.bind(this);

        this.content.show(contentView);
    },

    onChange() {
        this.trigger('disable');
        Server.callServer({
            url: settings.host + settings.serv_user.settingssave,
            type: "POST",
            data: this.model.attributes,
            success : data => {
                this.trigger('enable');
                let user = LoginedUserHandler.getLoginedUser();
                user.settings.viewanonym = this.model.get('viewanonym');
                user.settings.viewunverified = this.model.get('viewunverified');
                LoginedUserHandler.setLoginedUser(user);
            },
            error: this.showError.bind(this)
        });
    }
});

export default Settings;
