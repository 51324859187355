import { Optional, LayoutView, ViewModel } from '@b2cmessenger/backbone';
import template from './Window.jade';
import './Window.scss';
import * as RouterUtils from "../RouterUtils";
import GoogleAnalytics from "utils/GoogleAnalytics";

const RegularWindowSelector = '.window:not(.modal-window):not(.left-menu-window):not(.loading-modal-window)';
const RegularWindowTopClassName = 'top';

let Window = LayoutView.extend({
    options: {
        parentViewModel: Optional
    },
    scope: 'global',
    isWindow: true,
    canBeClosedOnRoute: true,
    template: template,

    regions: {
        header: '[data-js-header]',
        content: '[data-js-content]',
        footer: '[data-js-footer]',
    },

    constructor: function(options) {
        _.extend(this,
            _.pick(Object.getPrototypeOf(this), ['windowName', 'canBeClosedOnRoute']),
            _.pick(options, ['windowName', 'canBeClosedOnRoute', 'viewModel']));

        if (!this.viewModel) {
            this.viewModel = new ViewModel({
                parentViewModel: this.options.parentViewModel
            });
        }

        this.on('disable', () => this.viewModel.set('disable', true));
        this.on('enable', () => this.viewModel.set('disable', false));

        return LayoutView.apply(this, arguments);;
    },

    /**
        Opens window and appends it to DOM.
        @param args You can pass options with show()
        @return promise that resolves with data when window closes: this.close(data)
    */
    show(...args) {
        const currentPage = $.mobile.pageContainer.pagecontainer("getActivePage").attr('id');

        return Promise.resolve()
            .then(() => this.render())
            .then(() => _(_.union([this], _.result(this, '_getNestedViews') || []))
                .each(view => view.triggerMethod('before:attach')))
            .then(() => {
                let $selector = $('body');

                if (_.isString(this.scope)) {
                    if (this.scope == 'local') {
                        $selector = $('.page.active').length == 0 ? $('body') : $('.page.active');
                    }
                } else if (this.scope instanceof HTMLElement) {
                    $selector = $(this.scope);
                }

                if (this.$el.is(RegularWindowSelector)) {
                    $selector.find(RegularWindowSelector).removeClass(RegularWindowTopClassName);
                    this.$el.addClass(RegularWindowTopClassName);
                }

                this.$el.appendTo($selector);
            })
            .then(() => _(_.union([this], _.result(this, '_getNestedViews') || []))
                .each(view => view.triggerMethod('attach')))
            .then(() => this.onShow(...args))
            .then(() => {
                app.controller.windowStack.push(this);
                app.controller.trigger('window:show', this);

                if (this.$el.is(RegularWindowSelector)) {
                    app.router.navigate(RouterUtils.addWindowToFragment({ name: this.windowName }), { replace: true });
                }

                if (this.canBeClosedOnRoute) {
                    this.listenToOnce(Backbone.history, 'route', () => this.close())
                }

                if (GoogleAnalytics) {
////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
/////////////////////////////
                    GoogleAnalytics.trackView(`Window .${this.windowName}`);
                }
                if (window.Sentry) {
                    Sentry.addBreadcrumb({
                        message: `open window .${this.windowName}`,
                        category: 'page/window',
                        data: {
                            windowsStack: _.map(app.controller.windowStack, w => w.windowName),
                        }
                    });
                }
            })
            .then(() => {
                if (_.has(this, '_resolved')) {
                    return this._resolved;
                } else {
                    return new Promise(resolve => this._resolve = resolve);
                }
            })
            .then(data => this.onClose(data))
            .then(data => {
                const index = _.indexOf(app.controller.windowStack, this);
                if (index > -1) {
                    app.controller.windowStack.splice(index, 1);
                }

                if (this.$el.is(RegularWindowSelector)) {
                    app.router.navigate(RouterUtils.removeWindowFromFragment(this.windowName), {replace: true});
                }

                if (this.canBeClosedOnRoute) {
                    this.stopListening(Backbone.history, 'route');
                }

                app.controller.trigger('window:close', this);
                const currentWindow = _(app.controller.windowStack).last(),
                    currentPage = app.controller.getCurrentPage();

                if (GoogleAnalytics) {
                    if (currentWindow) {
////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////
                        GoogleAnalytics.trackView(`Window .${currentWindow.windowName}`);
                    } else {
////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////
                        GoogleAnalytics.trackView(`Page #${currentPage.pageName}`);
                    }
                }

                if (window.Sentry) {
                    if (currentWindow) {
                        Sentry.addBreadcrumb({
                            message: `return to window .${currentWindow.windowName}`,
                            category: 'page/window',
                            data: {
                                prevWindow: this.windowName,
                                windowsStack: _.map(app.controller.windowStack, w => w.windowName),
                            }
                        });
                    } else if(currentPage) {
                        Sentry.addBreadcrumb({
                            message: `return to page #${currentPage.pageName}`,
                            category: 'page/window',
                            data: {
                                prevWindow: this.windowName,
                            }
                        });
                    }
                }

                return data;
            })
            .then(data => {
                _.defer(() => this.destroy());

                if (data instanceof Error) {
                    throw data;
                }
                return data;
            })
            .catch(err => {
                this.destroy();
                throw err;
            });
    },

    /**
        Overload it to add some code to execute after window was created and appended to DOM.
        @param arguments You can get access to arguments window.show() was called with
    */
    onShow(...args) { },

    /**
     * Overload it to intercept `.close()` calls
     * You can return new data or Promise which will be resolved to new data
     * @param {any} data
     * @returns {Promise<any> | any}
     */
    onBeforeClose(data) { return data; },

    /**
        Overload it to add some code to execute before window closes and destroys.
        @apram data You can get and transform data window.close() was called with
    */
    onClose(data) { return data; },

    /** Closes window
        @param data that window.show() promise will be resolved with
    */
    close(data) {
        return Promise.resolve(data)
            .then(data => this.onBeforeClose(data))
            .then(data => {
                if (this._resolve) {
                    this._resolve(data);
                } else {
                    this._resolved = data;
                }
            });
    },

    /**
        Method for cancelling window, eg. on Back button pressed
    */
    cancel() {
        if (!this.viewModel.get('disabled'))
            this.close();
    },

    destroy() {
        if (this.$el.is(RegularWindowSelector)) {
            this.$el.siblings(RegularWindowSelector).last().addClass(RegularWindowTopClassName);
            this.$el.removeClass(RegularWindowTopClassName);
        }

        const ret = LayoutView.prototype.destroy.apply(this, arguments);

        if (this.options.parentViewModel && this.viewModel) {
            this.viewModel.clear();
        }
        return ret;
    }
}, {
        extendWithTraits(traits, protoProps, staticProps) {
            protoProps.windowName = protoProps.windowName || protoProps.className || "unknown-window";
            protoProps.className = "window " + (protoProps.className || protoProps.windowName || '');

            const init = protoProps.init;
            protoProps.init = function() {
                if(this.inited) return;

                if(_.isFunction(init))
                    init.call(this);

                this.inited = true;
            }

            return LayoutView.extendWithTraits.call(this, traits, protoProps, staticProps);
        },
        extend(protoProps, staticProps) {
            return Window.extendWithTraits.call(this, null, protoProps, staticProps);
            protoProps.windowName = protoProps.windowName || protoProps.className || "unknown-window";
            protoProps.className = "window " + (protoProps.className || protoProps.windowName || '');

            return LayoutView.extend.call(this, protoProps, staticProps);
        },

        isActiveWindow() {
            return !!app.controller.windowStack.length;
        },

        cancelActiveWindow() {
            const window = _(app.controller.windowStack).last();
            if (window) {
                window.cancel();
                return window;
            }
        },

        getActiveWindow() {
            return _(app.controller.windowStack).last();
        },

        getTopRegularWindow() {
            return _(app.controller.windowStack).chain()
                .filter(v => v.$el && v.$el.length && v.$el.is(RegularWindowSelector))
                .last()
                .value();
        },

        getCloseableOpenWindows(predicate = null) {
            return _(app.controller.windowStack)
                .filter(w => (predicate ? predicate(w) : true)
                    && w.windowName != 'left-menu-window' && w.$el && w.$el.length);
        }
    });

export default Window;
