import { Model, LayoutView } from '@b2cmessenger/backbone';
import BroadcastCampaignModel from 'models/BroadcastCampaignModel';
import BroadcastCollection from 'models/BroadcastCollection';

import Window from 'windows/Window';
import RegionWithPagination from 'traits/RegionWithPagination';
import ContainerWithPullToRefresh from 'traits/ContainerWithPullToRefresh';
import HeaderView from 'widgets/Header/Header';
import BroadcastCollectionView from 'widgets/Broadcast/BroadcastCollection';
import BroadcastCampaignItemView from 'widgets/BroadcastCampaign/BroadcastCampaignItem';

import template from './BroadcastHistoryContent.jade';
import './BroadcastHistory.scss';

const BroadcastHistoryContentWidget = LayoutView.extendWithTraits([{
    Trait: RegionWithPagination,
    options: {
        region: 'history',
        wrapperContainerElement() { return this._parent && this._parent.$el || this.history.$el; },
        initializeOnAttach: true
    }
}, {
    Trait: ContainerWithPullToRefresh,
    options: { 
        initializeOnAttach: true
    }
}], {
    options: {
        campaign: false,
        pageSize: 30,
        sortBy: undefined
    },

    refresh() {
        this.model.fetch();
    },

    template,
    className: "broadcasts-content-widget",

    ui: {
        history: '[data-js-history]'
    },

    regions: {
        campaign: '[data-js-campaign]',
        history: '[data-js-history]'
    },

    initialize() {
        if (!this.model) {
            if (_.isNumber(this.options.campaign) || _.isString(this.options.campaign)) {
                this.model = new BroadcastCampaignModel({ id: this.options.campaign })
            } else if (this.options.campaign instanceof Model) {
                this.model = this.options.campaign;
            } else {
                throw new Error("options.campaign is empty or incorrect!");
            }
        }

        if (!this.collection) {
            this.collection = new BroadcastCollection(null, { campaign: this.model });
        }

        this.viewModel.addComputed('traitContainerWithPullToRefreshCanBePulledToRefresh', {
            deps: ['traitRegionWithPaginationIsLoading'],
            get: traitRegionWithPaginationIsLoading => !traitRegionWithPaginationIsLoading
        });
    },

    onAttach() {
        this.campaign.show(new BroadcastCampaignItemView({
            model: this.model,
            compact: true
        }));

        const broadcastCollectionView = new BroadcastCollectionView({
            collection: this.collection,
            sortBy: this.options.sortBy
        });
        this.history.show(broadcastCollectionView);

        this.listenTo(broadcastCollectionView, 'change:sortBy', this.refresh.bind(this));
    },

    onPulledToRefresh() {
        this.refresh();
    },
});

const BroadcastHistoryWindow = Window.extend({
    options: {
        campaign: false,
        pageSize: 30,
        sortBy: undefined
    },

    windowName: "broadcasts-window",

    initialize() {
        if (_.isNumber(this.options.campaign) || _.isString(this.options.campaign)) {
            this.model = new BroadcastCampaignModel({ id: this.options.campaign })
        } else if (this.options.campaign instanceof Model) {
            this.model = this.options.campaign;
        } else {
            throw new Error("options.campaign is empty or incorrect!");
        }
    },

    onAttach() {
        const headerView = new HeaderView({
            leftButtons: ['back'],
            title: "Broadcasts history",
        });
        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.header.show(headerView);

        this.content.show(new BroadcastHistoryContentWidget({
            campaign: this.model,
            pageSize: this.options.pageSize,
            sortBy: this.options.sortBy
        }));
    },

    onShow() {
        this.content.currentView.refresh();
        return Window.prototype.onShow.apply(this, arguments);
    },
});

export default BroadcastHistoryWindow;
