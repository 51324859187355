import { Model } from '@b2cmessenger/backbone';

import Page from 'pages/Page';
import UserSearchView from 'widgets/UserSearch/UserSearch';
import HeaderView from 'widgets/HeaderWithTabs/HeaderWithTabs';
import InviteModal from 'windows/Modal/Invite';

import template from './Layout.jade';
import './UserSearch.scss';

const UserSearchPage = Page.extend({
    options: {
        placeId: undefined
    },

    getPlaceId() {
        return this.options.placeId;
    },

    setPlaceId(placeId, force) {
        if (force || LoginedUserHandler.get_work_place_by_id(placeId)) {
            this.options.placeId = placeId;
        }
    },

    show(options) {
        _.extend(this.options, _.pick(options, ['placeId']));

        if (!this.options.placeId || !LoginedUserHandler.get_work_place_by_id(this.options.placeId)) {
            throw new Error('this.options.placeId is incorrect!');
        }

        const ret = Page.prototype.show.apply(this, arguments);

        if (this.content.hasView() && this.content.currentView.getPlaceId() != this.options.placeId) {
            this.content.currentView.setPlaceId(this.options.placeId);
        }

        if (this.content.hasView() && options && options.loyaltyCardNumber) {
            this.content.currentView.setLoyaltyCardNumber(options.loyaltyCardNumber, {
                resetModel: true,
                users: options.users
            });
        }

        return ret;
    },

    resetSearchForm() {
        if (this.content && this.content.hasView()) {
            this.content.currentView.resetSearchForm();
        }
    },

    className: 'user-search-page page',
    template,

    attributes: {
        'id': "page_user_search"
    },

    regions: {
        header: '[data-js-header]',
        content: '[data-js-content]'
    },

    onRender() {
        const headerView = new HeaderView({
            leftButtons: ['back'],
            title: 'User search'
        });

        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.header.show(headerView);

        const userSearchView = new UserSearchView({
            viewModel: this.viewModel,
            placeId: this.options.placeId
        });

        this.listenTo(userSearchView, 'select', m => {
            const workPlace = LoginedUserHandler.get_work_place_by_id(this.options.placeId);

            if (workPlace) {
                app.controller.goToOtherUserPage({
                    user: m.toJSON(),
                    place: workPlace
                });
            }
        });

        this.listenTo(userSearchView, 'empty:result', m => {
            const email = m && m.get('email'),
                workPlace = LoginedUserHandler.get_work_place_by_id(this.options.placeId);

            if (B2Cjs.checkMail(email) && workPlace) {
                new InviteModal({
                    model: new Model({
                        place_id: workPlace.id,
                        email: email,
                        firstname: m && m.get('fullname')
                    })
                })
                    .show()
                    .then(model => model && userSearchView.search(true));
            }
        });

        this.listenTo(LoginedUserHandler, 'change:user_def_work_place_id logout', this.resetSearchForm);

        this.content.show(userSearchView);
    }
});

export default UserSearchPage;
