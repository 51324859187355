import { REMOVE_CART_FROM_CARTS, RESET_CARTS, UPSERT_CART_INTO_CARTS } from "store/menu/carts/actions";
import { upsertItemInArrayByKey } from "store/menu/utils";

const initialState = {
    values: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UPSERT_CART_INTO_CARTS: {
            const { cart } = action.payload;
            const { values: oldValues } = state;

            const values = upsertItemInArrayByKey({ item: cart, array: oldValues, key: 'placeId' });

            return {
                ...state,
                values
            }
        }

        case REMOVE_CART_FROM_CARTS: {
            const { cart } = action.payload;
            const { values: oldValues } = state;

            const values = oldValues.filter(v => v.placeId !== cart.placeId);

            return {
                ...state,
                values
            }
        }

        case RESET_CARTS: {
            const { carts } = action.payload;

            return {
                ...state,
                values: carts
            };
        }


        default:
            return state;
    }
}