import React from 'react';

import 'widgets/Footer/Footer.scss';

const Footer = (props) => {
    const buttonsEl = props.buttons ? <FooterButtons buttons={props.buttons} /> : null;

    return (
        <div className="widget footer">
            {buttonsEl}
            {props.children}
        </div>
    );
};

const FooterButtons = ({ buttons }) => {
    return buttons
        ? buttons.map((button, i) => {
            const { disabled = false, className = 'text btn-empty', text } = button;
            const key = button.id ? button.id : i;
            const onClickHandler = () => button.onClick && button.onClick();

            return (
                <button key={key} className={className} disabled={disabled} onClick={onClickHandler}>
                    {text && <p className="text">{text}</p>}
                </button>
            );
        })
        : null;
};

export default Footer;
