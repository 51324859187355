import {Model} from '@b2cmessenger/backbone';
import settings from 'settings';

/**
 * @see https://deanar.restlet.io/#type_notificationmute_type
 * @type {{Fave: string, FaveAll: string, BusinessDnd: string, Task: string}}
 */
const Type = {
    Fave: 'fave',
    FaveAll: 'fave_all',
    BusinessDnd: 'business_dnd',
    Task: 'task'
};

const NotificationMuteModel = Model.extend({
    urlRoot: settings.host + settings.serv_notification.mute,

    defaults: {
        user_id: null,
        type: null,
        place_id: null,
        task_id: null,
        type_title: null,
        content_subject: null,
        created_at: null
    },

    sync(method, model, options) {
        if (method !== 'create' && method !== 'delete') {
            const err = new Error(`Sync method "${method}" is not supported`);
            if (options && options.error) {
                options.error.call(options.context, err);
            }
            return $.Deferred().reject(err);
        }

        return Model.prototype.sync.call(this, method, model, options);
    },
}, {
    Type
});

export default NotificationMuteModel;
