import ReactWindow from 'windows/ReactWindow';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { WindowContent, WindowFooter, WindowHeader } from 'components/window/Window';
import Header from 'components/UI/Header/Header';
import { BackButton } from 'components/UI/Button/Button';
import Footer from 'components/UI/Footer/Footer';
import KeyboardPadding from 'components/KeyboardPadding';
import Spinner from 'components/UI/Spinner/Spinner';
import MenuCartItemCustomize from 'components/menu/MenuCartItemCustomize/MenuCartItemCustomize';
import withStore from 'components/withStore';
import * as MenuAPI from 'utils/api/menu';
import MenuAdditionsManagementWindow from 'components/window/MenuAdditionsManagement/MenuAdditionsManagement';
import TextButton from 'components/UI/TextButton';
import { getAdditionItems, getSelectedAdditionItems, isFullMenuItemModel } from 'components/menu/utils';
import { loadMenuItemDetailSucceed } from 'store/menu/actions';
import { getMenuItemById } from 'store/menu/selectors';

const defaultCustomizationOptions = {
    properties: [],
    count: 1,
    additions: [],
    comment: '',
};

const MenuCartItemCustomizeContainer = (props) => {
    const {
        getMenuItem,
        loadMenuItemDetailSucceed,
        closeWindow,
        placeId,
        menuItem,
        menuItemId,
        menuCartItem,
        customizationOptions,
        previousUsedAdditions = [],
    } = props;

    const [loading, setLoading] = useState();
    const [item, setItem] = useState();
    const [isPreviousUsedAdditionsApplied, setPreviousUsedAdditionsApplied] = useState(false);

    const [cartItem, setCartItem] = useState(
        menuCartItem || _.extend(
            { menuItemId: menuItem ? menuItem.id : menuItemId },
            defaultCustomizationOptions,
            customizationOptions
        )
    );

    const [additionItems, setAdditionItems] = useState();
    const [additionCategories, setAdditionCategories] = useState();
    const selectedAdditionItems = useMemo(
        () =>
            getSelectedAdditionItems({
                additions: cartItem.additions,
                additionItems,
            }),
        [cartItem, additionItems]
    );

    const setItemParamByKey = useCallback(
        (key, val) => {
            setCartItem((prev) => ({
                ...prev,
                [key]: val,
            }));
        },
        [setCartItem]
    );

    const onChangeAdditionCount = ({ item, count }) => {
        setCartItem((prev) => {
            const oldAdditions = prev.additions;

            const idx = _.findIndex(oldAdditions, (a) => a.id === item.id);
            let additions = [];

            if (idx !== -1) {
                additions = prev.additions
                    .map((i, itemIdx) => (idx === itemIdx ? (count ? { ...i, count } : null) : i))
                    .filter(Boolean);
            } else {
                additions = [...oldAdditions, { id: item.id, count }];
            }

            return {
                ...prev,
                additions,
            };
        });
    };

    const additionsEditHandler = useCallback(() => {
        new MenuAdditionsManagementWindow({
            additionCategories,
            additionItems,
            additions: cartItem.additions,
            setAdditionItems: (additions) => setItemParamByKey('additions', additions),
        }).show();
    }, [additionItems, additionCategories, cartItem]);

    const setComment = (val) => setItemParamByKey('comment', val);
    const setCount = (val) => setItemParamByKey('count', val);
    const onChangeProperty = (property) => {
        const { name } = property;

        setCartItem((prev) => {
            const oldProps = prev.properties || [];
            const propIdx = _.findIndex(oldProps, (p) => p.name === name);

            const properties =
                propIdx === -1 ? oldProps.concat(property) : oldProps.map((p, idx) => (idx === propIdx ? property : p));

            return {
                ...prev,
                properties,
            };
        });
    };

    useEffect(() => {
        function updateStateUsingItem(item) {
            setItem(item);
            setAdditionItems(getAdditionItems(item.additions));
            setAdditionCategories(_(item.additions).map((a) => a.category));
        }

        const itemFromStore = getMenuItem(menuItemId);

        if (menuItem) {
            updateStateUsingItem(menuItem);
        } else if (itemFromStore && isFullMenuItemModel(itemFromStore)) {
            updateStateUsingItem(itemFromStore);
        } else if (cartItem.menuItemId) {
            setLoading(true);
            MenuAPI.loadItem({ placeId, id: cartItem.menuItemId })
                .then((item) => {
                    loadMenuItemDetailSucceed && loadMenuItemDetailSucceed(item);
                    updateStateUsingItem(item);
                    setLoading(false);
                })
                .catch((e) => {
                    showError(e);
                    closeWindow();
                });
        } else {
            showError('Unable to open customization window');
            closeWindow();
        }
    }, [menuItem, menuItemId, getMenuItemById]);

    const setAdditionsFromPreviousAdditions =
        !isPreviousUsedAdditionsApplied && previousUsedAdditions.length > 0
            ? () => {
                setPreviousUsedAdditionsApplied(true);
                setItemParamByKey('additions', previousUsedAdditions);
            }
            : null;

    let content = null;
    if (loading) {
        content = <Spinner className="fit-to-container" />;
    } else if (cartItem && item) {
        content = (
            <MenuCartItemCustomize
                {...cartItem}
                additionItems={additionItems}
                selectedAdditionItems={selectedAdditionItems}
                onChangeAdditionCount={onChangeAdditionCount}
                menuItem={item}
                setComment={setComment}
                setCount={setCount}
                additionsEditHandler={additionsEditHandler}
                setAdditionsFromPreviousAdditions={setAdditionsFromPreviousAdditions}
                onChangeProperty={onChangeProperty}
            />
        );
    }

    const disabled =
        loading || (item && _.isArray(item.properties) && item.properties.length !== cartItem.properties.length);

    return (
        <>
            <WindowHeader>
                <Header>
                    <BackButton onClick={() => closeWindow()} />
                    <h1 className="title">{menuCartItem ? 'Customize cart item' : 'Add to cart'}</h1>
                </Header>
            </WindowHeader>
            <WindowContent>{content}</WindowContent>
            <WindowFooter>
                <Footer>
                    <TextButton onClick={() => closeWindow()}>CLOSE</TextButton>
                    <TextButton disabled={disabled} onClick={() => closeWindow(cartItem)}>
                        {menuCartItem ? 'SAVE' : 'ADD'}
                    </TextButton>
                </Footer>
            </WindowFooter>
            <KeyboardPadding />
        </>
    );
};

const ConnectedMenuCartItemCustomize = withStore(
    connect(
        (state) => ({
            getMenuItem: (id) => getMenuItemById(state, id),
        }),
        { loadMenuItemDetailSucceed }
    )(MenuCartItemCustomizeContainer)
);

const MenuCartItemCustomizeWindow = ReactWindow.extend({
    constructor: function MenuCartItemCustomizeWindow(props) {
        const options = {
            props,
            component: ConnectedMenuCartItemCustomize,
            name: 'MenuCartItemCustomizeContainer',
        };

        return ReactWindow.apply(this, [options]);
    },
});

export default MenuCartItemCustomizeWindow;
