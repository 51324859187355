import { LayoutView, CollectionView, Collection, Required } from '@b2cmessenger/backbone';
import settings from 'settings';
import Window from 'windows/Window';
import templateContent from './Content.jade';
import EmployeesChild from './EmployeesChild';
import HeaderView from 'widgets/HeaderWithTabs/HeaderWithTabs';
import SearchView from './Search';
import './Employees.scss';
import EmployeesEditWindow from "windows/Employees/EmployeesEditWindow";

const STATUSES = {
    STATUS_JOINED: 1,
    STATUS_PENDING: 0,
    STATUS_DECLINED: -1,
};
const STATUS_MAP = {
    joined: STATUSES.STATUS_JOINED,
    pending: STATUSES.STATUS_PENDING,
    declined: STATUSES.STATUS_DECLINED
};

const EmployeesWindow = Window.extend({
    options: {
        status: STATUSES.STATUS_JOINED
    },

    windowName: 'employees-list-window',
    className: 'employees-list-window',

    roles: [],

    onRender() {
        this.options.status = this.options.status || STATUSES.STATUS_JOINED;

        this.renderHeader();
        this.dataRequest();
    },

    renderHeader() {
        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: 'Employees',
            rightButtons: [{
                id: 'add',
                text: 'Add'
            }],
            tabs: [{
                id: 'joined',
                title: 'Joined',
                icon: 'ion-ios-people-outline',
                active: this.options.status == STATUSES.STATUS_JOINED
            }, {
                id: 'pending',
                title: 'Pending',
                icon: 'ion-ios-refresh',
                active: this.options.status == STATUSES.STATUS_PENDING
            }, {
                id: 'declined',
                title: 'Declined',
                icon: 'ion-ios-close-circle-outline',
                active: this.options.status == STATUSES.STATUS_DECLINED
            }]
        });

        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.listenTo(headerView, 'add:click', () => this.showEditWindow());
        this.listenTo(headerView, 'tab:click', (parent, child) => {
            const status = STATUS_MAP[child.model.get('id')];

            if (!_.isUndefined(status)) {
                this.showLoading();

                parent.resetTabs();
                child.setActive();

                this.options.status = status;
                this.dataRequest().then(() => this.hideLoading(), () => this.hideLoading());
            }
        });

        this.header.show(headerView);
    },

    async dataRequest() {
        return Server.callServer({
            url: settings.host + settings.serv_place.employee.search,
            data: {
                place_id: this.options.place.id,
                status: this.options.status,
                need_roles: 1
            },
            type: "POST",
            error: this.showError.bind(this)
        }).then(this.onFetch.bind(this));
    },

    onFetch(data) {
        this.roles = data.roles;

        const view = new EmployeesContent({
            data: data,
            place: this.options.place,
            status: STATUSES.STATUS_JOINED
        });

        view.on('close', () => {
            this.close();
        });

        view.on('click:user', this.showEditWindow.bind(this));
        this.content.show(view);
    },

    // Показывает окно редактирования ролей
    showEditWindow(model) {
        let employeesEditWindow = new EmployeesEditWindow({
            model: model,
            roles: this.roles,
            placeId: this.options.place.id
        });

        // После выхода из окна редактирования, может быть потребность изменить вкладку
        employeesEditWindow.show().then((data) => {
            const status = STATUS_MAP[data];

            if (!_.isUndefined(status)) {
                this.options.status = status;
                this.renderHeader();
                this.dataRequest();
            }

            if (data === 'reload') {
                this.dataRequest();
            }
        });

        // @todo remove after usersearch refactoring
        employeesEditWindow.on('hide', () => {
            this.hide();
        });

        employeesEditWindow.on('restore', () => {
            this.restore();
        });
    },

    // @todo remove after usersearch refactoring
    hide() {
        this.el.style.setProperty('display', 'none', 'important');
    },

    restore() {
        this.el.style.removeProperty('display');
    },

    cancel() {
        this.close(this.model)
    }
});

@LayoutView.options({
    data: Required,
    place: Required,
    status: Required
})
@LayoutView.properties({
    template: templateContent,
    regions: {
        search: '[data-js-employees-list-search]',
        list: '[data-js-employees-list]'
    },
    events: {},
})
class EmployeesContent extends LayoutView {
    onRender() {
        const models = this.options.data.employees || [];

        const collectionView = new (CollectionView.extend({
            className: 'employees-list-inner',
            childView: EmployeesChild,
            collection: new Collection(models),
            childViewOptions: {
                clickable: true
            },
            childEvents: {
                'click:user': function (view, model) {
                    this.trigger('click:user', model);
                }
            }
        }));

        collectionView.on('click:user', (model) => {
            this.trigger('click:user', model);
        });

        const searchView = new SearchView();
        searchView.on('change', function (keyWord) {
            const keyword = keyWord.replace(/\W/g, '').toLowerCase();

            collectionView.filter = function (child, index, collection) {
                let user = child.get('user');
                if (!user) return false;

                let fname = String(user.firstname).replace(/\W/g, '').toLowerCase(),
                    lname = String(user.lastname).replace(/\W/g, '').toLowerCase(),
                    email = String(user.email).replace(/\W/g, '').toLowerCase();

                return (fname.indexOf(keyword) > -1) ||
                    (lname.indexOf(keyword) > -1) ||
                    (email.indexOf(keyword) > -1) ||
                    ((fname + lname).indexOf(keyword) > -1) ||
                    ((lname + fname).indexOf(keyword) > -1);
            };

            collectionView.render();
        });

        this.search.show(searchView);
        this.list.show(collectionView);
    }
}

export default EmployeesWindow;
