import {combineReducers} from "redux";

import menuItemsReducer from './reducers/items';
import menuCategoriesReducer from './reducers/categories';
import menuItemReducer from './reducers/item';
import menuCatalogReducer from './reducers/catalog';
import menuInfoReducer from './reducers/info';
import menuCartReducer from './reducers/cart';
import menuCartsReducer from './reducers/carts';
import sectionsReducer from './reducers/sections';
import ordersReducer from './reducers/orders';

export default combineReducers({
    items: menuItemsReducer,
    categories: menuCategoriesReducer,
    item: menuItemReducer,
    catalog: menuCatalogReducer,
    info: menuInfoReducer,
    cart: menuCartReducer,
    carts: menuCartsReducer,
    sections: sectionsReducer,
    orders: ordersReducer,
});
