/**
 * Created by Alex on 20.03.2016.
 */

// B2C Category Viewer
(function( $ ){
    var methods = {
        init : function( options ) {

            return this.each(function(){

                var $this = $(this),
                    data = $this.data('b2ccategoryviewer');

                if ( ! data ) {

                    data = {};
                    data.$this = $this;
                    data.options = $.extend(true,
                        {
                            showParents: false,
                            indentChildren: false,
                        },
                        $.fn.b2ccategoryviewer.defaults, options);

                    $(this).data('b2ccategoryviewer', data);

                    $this.addClass(data.options.cl_main);

                    $.fn.b2ccategoryviewer.init(data);
                }
            });
        },
        get : function() {
            var $first = this.first();
            var data = $first.data('b2ccategoryviewer');
            return data.options.cats;
        },
        set : function(cats) {
            return this.each(function(){
                var $this = $(this),
                    data = $this.data('b2ccategoryviewer');
                data.options.cats = cats;
                $.fn.b2ccategoryviewer.clear(data);
                $.fn.b2ccategoryviewer.init(data);
            });
        },
        
    };

    $.fn.b2ccategoryviewer = function( method ) {

        if ( methods[method] ) {
            return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ));
        } else if ( typeof method === 'object' || ! method ) {
            return methods.init.apply( this, arguments );
        } else {
            $.error( 'Cannot find ' +  method + ' in jQuery.b2ccategoryviewer' );
        }

    };

    $.fn.b2ccategoryviewer.defaults = {
        
        cl_main : 'b2ccategoryviewer',
        cl_cat : 'b2c_cat',
        cl_cat_name : 'b2c_cat_name',
        cl_cat_icon : 'b2c_cat_icon',
        cats : []
    };

    $.fn.b2ccategoryviewer.init = function (data) {
        if (data.options.indentChildren) {
            data.$this.addClass('indent-children');
        } else {
            data.$this.removeClass('indent-children');
        }
        var html = $.fn.b2ccategoryviewer.buildHTML(data);
        var jqCats = $(html).appendTo(data.$this);
    };

    $.fn.b2ccategoryviewer.buildHTML = function (data) {
        var cats = [];
        if (data.options.showParents) {
            cats = _.values(_(data.options.cats).reduce(function (cats, c) {
                if (!cats[c.id]) {
                    var cat = categoryCollection.get(c.id);
                    if (cat) {
                        cats[cat.id] = cat;

                        _(cat.get('parents')).each(function (cat) {
                            if (!cats[cat.id]) {
                                cats[cat.id] = cat;
                            }
                        });
                    }
                }
                return cats;
            }, {}));
        } else {
            cats = _(data.options.cats)
                .map(function (c) {
                    return categoryCollection.get(c.id);
                });
        }

        cats.sort(function (c1, c2) {
            var parents1 = c1.get('parents').slice().reverse(),
                parents2 = c2.get('parents').slice().reverse();

            parents1.push(c1);
            parents2.push(c2);

            for (var i = 0; i < Math.max(parents1.length, parents2.length); i++) {
                var name1 = parents1[i] && parents1[i].get('name') || '',
                    name2 = parents2[i] && parents2[i].get('name') || '';

                if (name1 < name2) return -1;
                else if (name1 > name2) return 1;
            }

            var name1 = c1.get('name'), name2 = c2.get('name');

            return name1 < name2 ? -1 : name1 > name2;
        });

        return _(cats).reduce(function (html, c) {
            html += $.fn.b2ccategoryviewer.buildHTML_Cat(data, c);
            return html;
        }, '');
    };

    $.fn.b2ccategoryviewer.buildHTML_Cat = function(data, cat) {
        var options = data.options;

        var iconClass = cat.get('icon_code') ? ("icon-" + quoteattr(cat.get('icon_code'))) : "ion-md-disc"

        return '' +
            '<span class="' + quoteattr(options.cl_cat) + '" data-id="' + escapeHtml(cat.id) + '" data-depth="' + cat.get('depth') + '">' +
            '   <i class="' + iconClass + '"/>' +
            '   <span class="' + quoteattr(options.cl_cat_name) + '">' + escapeHtml(cat.get('name')) + '</span>' +
            '</span>';
    };

    $.fn.b2ccategoryviewer.clear = function(data) {
        data.$this.children('.'+data.options.cl_cat).remove();
    };

})( jQuery );