import React, { useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getMenuInfoState } from "store/menu/selectors";
import { initMenu } from "store/menu/actions";
import getMenuPermissions from "utils/getMenuPermissions";
import { updateMenuInfo } from "store/menu/info/actions";

const useInitializeMenu = ({ placeId: newPlaceId, categoryId = null, isActive = false }) => {
    const dispatch = useDispatch();
    const { placeId, loading, isReadyToInitialize, initialized } = useSelector(getMenuInfoState);

    const shouldInitMenu = useMemo(() => {
        return Boolean((placeId === null || Number(placeId) !== Number(newPlaceId)) && isActive);
    }, [placeId, newPlaceId, isActive]);

    const readyToInitialize = useCallback(_.debounce((value) => {
        if (dispatch && updateMenuInfo && !isReadyToInitialize) {
            dispatch(updateMenuInfo({
                isReadyToInitialize: value
            }));
        }
    }, 1), [dispatch, updateMenuInfo, isReadyToInitialize]);

    useEffect(() => {
        if (!isActive || !isReadyToInitialize || loading) {
            return;
        }

        if (shouldInitMenu) {
            dispatch(initMenu(newPlaceId, categoryId, getMenuPermissions(newPlaceId)));
        }
    }, [dispatch, shouldInitMenu, newPlaceId, categoryId, isActive, isReadyToInitialize, loading]);


    return { shouldInitMenu, loading, readyToInitialize, initialized };
};

export default useInitializeMenu;