import {
    LOAD_MENU_ITEM_DETAIL,
    LOAD_MENU_ITEM_DETAIL_SUCCEEDED,
    LOAD_MENU_ITEM_DETAIL_FAILED, DISPLAY_MENU_ITEM_DETAIL
} from "../actions";

const initialState = {
    id: null,
    loading: false,
    error: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_MENU_ITEM_DETAIL:
            return { ...state, loading: true, error: null };

        case DISPLAY_MENU_ITEM_DETAIL:
            return { ...state, id: action.payload.id, loading: false };

        case LOAD_MENU_ITEM_DETAIL_SUCCEEDED:
            return {
                ...state,
                loading: false,
                id: action.payload.item.id
            };

        case LOAD_MENU_ITEM_DETAIL_FAILED:
            return { ...state, loading: false, error: action.payload.error };

        default:
            return state
    }
}
