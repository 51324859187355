import { Backbone, Model } from '@b2cmessenger/backbone';

export function ensureFragment(fragment) {
    return fragment || Backbone.history.getFragment();
}

export function splitFragment(fragment, isParseValuesNeeded) {
    fragment = ensureFragment(fragment);

    const [path, windows = ''] = fragment.split('|');
    return {
        path: path,
        windows: windows ? windows.split(';')
            .map(w => {
                let [name, value = null] = w.split('=');

                if (value && isParseValuesNeeded) {
                    value = JSON.parse(decodeURIComponent(value));
                }

                return { name, value }
            }) : []
    };
}

export function joinFragment(path, windows = []) {
    return path +
        (windows.length ? '|' : '') +
        windows.map(w => w.name + (w.value ? '=' + interpolateWindowValue(w.value) : '')).join(';');
}

export function interpolateWindowValue(obj) {
    if (_.isString(obj)) {
        return obj
    } else if (obj instanceof Model) {
        return obj.toJSON();
    } else if (!_.isFunction(obj)) {
        return JSON.stringify(obj);
    }

    return obj;
}

export function getWindowsFromFragment(fragment, isParseValuesNeeded) {
    fragment = ensureFragment(fragment);

    const { windows } = splitFragment(fragment, isParseValuesNeeded);

    return windows;
}

export function addWindowToFragment(obj, options = {}) {
    _.defaults(options || (options = {}));

    options.fragment = ensureFragment(options.fragment);

    const { path, windows } = splitFragment(options.fragment);
    if (obj && obj.name && !_.find(windows, w => w.name == obj.name)) {
        windows.push(obj);
    }

    return joinFragment(path, windows);
}

export function removeWindowFromFragment(windowName, options) {
    _.defaults(options || (options = {}));

    options.fragment = ensureFragment(options.fragment);

    const { path, windows } = splitFragment(options.fragment);

    return joinFragment(path, windows.filter(w => w.name != windowName));
}
