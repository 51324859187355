/**
 * Created by Alex on 13.03.2016.
 */
// B2C Popup
(function( $ ){
    var methods = {
        init : function( options ) {

            return this.each(function(){

                var $this = $(this),
                    data = $this.data('b2cpopup');

                if(data) {
                    $this.children().remove();
                }

                //if ( ! data ) {

                    data = {};
                    data.$this = $this;
                    data.options = $.extend(true, {}, $.fn.b2cpopup.defaults, options);

                    $(this).data('b2cpopup', data);

                    $this.addClass(data.options.cl_main);
                    if(! data.options.defaultOpen)
                        $this.addClass(data.options.cl_hidden);

                    if (data.options.lightMode)
                        $this.addClass(data.options.cl_light);

                    $.fn.b2cpopup.init(data);

                //}
            });
        },
        close : function() {
            return this.each(function(){
                var $this = $(this),
                    data = $this.data('b2cpopup');
                $.fn.b2cpopup.close(data);
            });
        },
        show : function() {
            return this.each(function(){
                var $this = $(this),
                    data = $this.data('b2cpopup');
                $.fn.b2cpopup.show(data);
            });
        },
        isOpen : function() {
            var $this = this.first(),
                data = $this.data('b2cpopup');
            return $.fn.b2cpopup.isOpen(data);
        },
        getUserCont : function() {
            var $first = this.first();
            var data = $first.data('b2cpopup');
            return data.jqUserCont;
        },
        option : function(key, value) {
            return this.each(function(){
                var $this = $(this),
                    data = $this.data('b2cpopup');
                data.options[key] = value;
            });
        },
    };

    $.fn.b2cpopup = function( method ) {

        if ( methods[method] ) {
            return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ));
        } else if ( typeof method === 'object' || ! method ) {
            return methods.init.apply( this, arguments );
        } else {
            $.error( 'Cannot find ' +  method + ' in jQuery.b2cpopup' );
        }

    };

    $.fn.b2cpopup.defaults = {
        onClose : null, // onTagAdded(tagName, tags)
        onShow : null, // onTagAdded(tagName, tags)
        onClick : null, // onTagAdded(tagName, tags)
        cl_main : 'b2cpopup',
        cl_b2cPopupBackground : 'b2cPopupBackground',
        cl_excontent : 'b2cexcontent',
        cl_content : 'b2ccontent',
        cl_btnclose : 'btnclose',
        cl_hidden : 'hidden',
        cl_light : 'b2clight',
        html_content : '',
        jq_content : null,
        lightMode : false,
        defaultOpen : true,
    };

    $.fn.b2cpopup.init = function(data) {
        data.$this.append($($.fn.b2cpopup.buildHtml(data)));
        if (data.options.onClick)
            data.$this.click(data.options.onClick);
        var fclose = function(event) { $.fn.b2cpopup.close(data) };
        data.$this.children('.'+data.options.cl_b2cPopupBackground).click(fclose);
        var jqExcontent = data.$this.children('.'+data.options.cl_excontent);
        jqExcontent.children('.'+data.options.cl_btnclose).click(fclose);

        var jqContent = jqExcontent.children('.'+data.options.cl_content);
        if (data.options.jq_content != null)
            jqContent.append(data.options.jq_content);
        data.jqUserCont = jqContent.children();
    };

    $.fn.b2cpopup.buildHtml = function(data) {
        var html_content = data.options.html_content != null ? data.options.html_content : '';

        return '<div class="'+quoteattr(data.options.cl_b2cPopupBackground)+'"></div><div class="'+data.options.cl_excontent+'"><button class="'+data.options.cl_btnclose+'"></button><div class="'+data.options.cl_content+'">'+html_content+'</div></div> ';
    };

    $.fn.b2cpopup.close = function(data) {
        data.$this.addClass(data.options.cl_hidden);
        if(data.options.onClose != null)
            data.options.onClose();
    };

    $.fn.b2cpopup.show = function(data) {
        data.$this.removeClass(data.options.cl_hidden);
        if(data.options.onShow != null)
            data.options.onShow();
    };

    $.fn.b2cpopup.isOpen = function(data) {
        return ! data.$this.hasClass(data.options.cl_hidden);
    };

})( jQuery );