import { CompositeView, ViewModel } from '@b2cmessenger/backbone';

import BroadcastCampaignItemView from 'widgets/BroadcastCampaign/BroadcastCampaignItem';

import BroadcastCampaignModel from 'models/BroadcastCampaignModel';

import template from './BroadcastCampaignCollection.jade';
import './BroadcastCampaignCollection.scss';

const BroadcastCampaignCollectionView = CompositeView.extend({
    template,
    className: 'widget broadcast-campaign-collection',
    childView: BroadcastCampaignItemView,
    childViewContainer: '[data-js-items]',

    events: {
        'click [data-js-btn-add-new]'() {
            this.triggerMethod('click:add:new');
        }
    },

    initialize(options) {
        if (options.viewModel) {
            this.viewModel = options.viewModel;
            this.listenTo(
                this.viewModel,
                'change:onlyScheduled change:onlyInProgress change:onlyDone',
                this._updateFilter.bind(this)
            );
            this._updateFilter();
        }
    },

    _updateFilter() {
        if (this.viewModel) {
            const onlyScheduled = this.viewModel.get('onlyScheduled'),
                onlyInProgress = this.viewModel.get('onlyInProgress'),
                onlyDone = this.viewModel.get('onlyDone');

            if (onlyScheduled || onlyInProgress || onlyDone) {
                this.options.filter = m => {
                    const status = m.get('status');
                    if (onlyScheduled && status == BroadcastCampaignModel.Status.Scheduled) {
                        return true;
                    }

                    if (onlyInProgress && status == BroadcastCampaignModel.Status.InProgress) {
                        return true;
                    }

                    if (onlyDone && status == BroadcastCampaignModel.Status.Done) {
                        return true;
                    }

                    return false;
                }
            } else {
                delete this.options.filter;
            }
        }

        if (this.isRendered)
            this.resortView();
    }
});

export default BroadcastCampaignCollectionView;
