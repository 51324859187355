import { Required, ItemView, ViewModel } from "@b2cmessenger/backbone";
import template from "windows/Phone/PhoneChooserItem.jade";
import PhoneVerifyModal from "windows/Modal/Phone/PhoneVerify";
import ConfirmModalWindow from "windows/Modal/Confirm";
import getNextPrettyColor from "utils/randomPrettyColor";

@ItemView.options({
    parentViewModel: Required
})
@ItemView.properties({
    tagName: 'li',
    className: 'phone-chooser-item',
    template,

    ui: {
        phone: '[data-js-phone]',
        makeDefault: '[data-js-make-default]',
        remove: '[data-js-remove]',
        confirm: '[data-js-confirm]',
        status: '[data-js-status]'
    },

    modelEvents: {
        'change': 'render'
    },
    triggers: {
        'click @ui.makeDefault': 'make:default',
        'click @ui.remove': 'remove',
        'click @ui.confirm': 'confirm'
    },
    events: {
        'click': '_selectPhone',
    },

    computeds: {
        c_isSelected: {
            deps: ['selectedPhoneId', 'id'],
            get: (selectedPhoneId, id) => selectedPhoneId && (selectedPhoneId === id)
        },
        c_StatusText: {
            deps: ['c_isDefault', 'c_isVerified'],
            get: (c_isDefault, c_isVerified) => c_isDefault ? 'Default' : c_isVerified ? 'Confirmed' : 'Unconfirmed'
        },
        c_StatusToggle: {
            deps: ['c_isDefault'],
            get(c_isDefault) {
                return !this.viewModel.get('isEditable') || c_isDefault;
            }
        },
        prettyPhone: {
            deps: ['phone'],
            get: phone => phone && B2Cjs.phone_getExternalFormat(phone) || ""
        }
    },

    bindings: {
        ':el': 'attr:{"data-id":id},classes:{selected:c_isSelected,editable:isEditable},css:{"border-color":color}',
        '@ui.confirm': 'disabled:disabled',
        '@ui.makeDefault': 'disabled:disabled',
        '@ui.remove': 'disabled:disabled',
        '@ui.status': 'text:c_StatusText,classes:{default:c_isDefault,confirmed:c_isVerified},toggle:c_StatusToggle',
        '@ui.phone': 'text:prettyPhone'
    }
})
class PhoneChooserItemView extends ItemView {
    initialize() {
        this.viewModel = new ViewModel({
            color: getNextPrettyColor(this.model.get('phone')),
            parentViewModel: this.options.parentViewModel
        });
        this.viewModel.addProxyAttribute('isEditable', 'parentViewModel', 'isEditable');
        this.viewModel.addProxyAttribute('selectedPhoneId', 'parentViewModel', 'selectedPhoneId');
    }

    _selectPhone() {
        if (this.viewModel.get('isEditable')) {
            return;
        }

        if (this.model.get('c_isVerified')) {
            this.trigger('selected', this.model);
        } else {
            new PhoneVerifyModal({
                model: this.model
            }).show().then((model) => {
                if (model && model.get('c_isVerified')) {
                    this.trigger('selected', this.model);
                }
            });
        }
    }

    templateHelpers() {
        return {
            isEditable: this.viewModel.get('isEditable')
        }
    }
}

export default PhoneChooserItemView;
