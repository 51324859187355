import ImageSourceModal from "windows/Modal/ImageSource";
import settings from 'settings';
import AjaxError from "utils/AjaxError";

const ImageInputHelpers = {
    resolveLocalFile(fileUrl) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            if (!fileUrl) {
                resolve(false);
            }

            let isFileUri = (fileUrl.indexOf('file:/') > -1);
            let isContentUri = (fileUrl.indexOf('content:/') > -1);

            if (!isFileUri && !isContentUri) {
                fileUrl = 'file:///' + fileUrl.replace(/^(file:)*\/*/, '');
            }

            window.resolveLocalFileSystemURL(
                fileUrl,
                fileEntry => fileEntry.file(
                    file => {
                        resolve({ fileUrl, file });
                        // reader.onload = e => {
                        //     const blob = new Blob([e.target.result], { type: "image/jpeg" });
                        //     resolve({ blob, fileUrl, fileEntry });
                        // };
                        //
                        // reader.readAsArrayBuffer(file);
                    },
                    err => reject(err)
                ),
                err => reject(err)
            );
        });
    },

    getTransparentPixelImage() {
        return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
    },

    getImageSize(fileUrl) {
      return new Promise((resolve, reject) => {
        const img = new Image();
         img.onload = () => {
             resolve({ width: img.naturalWidth, height: img.naturalHeight });
         };

         img.src = fileUrl;
      });
    },

    getImageFromNavigator(type) {
        return new Promise((resolve, reject) => {
            if (!type) {
                resolve(false);
                return;
            }

            navigator.camera.getPicture(
                fileUrl => {
                    resolve(fileUrl);
                    console.log(`getPicture(): ${fileUrl}`);
                },
                e => reject(e),
                {
                    sourceType: Camera.PictureSourceType[type === 'camera' ? 'CAMERA' : 'PHOTOLIBRARY'],
                    destinationType: Camera.DestinationType.FILE_URI,
                    encodingType: Camera.EncodingType.JPEG,
                    mediaType: Camera.MediaType.PICTURE,
                }
            );
        });
    },

    getImageSourceType() {
        return new ImageSourceModal().show();
    },

    async uploadFileFromNativeFS(path, type) {
        return new Promise((resolve, reject) => {
            const fileTransfer = new FileTransfer();

            fileTransfer.upload(
                path,
                settings.host + '/api/v2/upload',
                entry => {
                    if (entry.response) {
                        try {
                            const jsonResponse = JSON.parse(entry.response);
                            resolve(jsonResponse);
                        } catch (e) {
                            reject(new Error('No valid response from image uploader'));
                        }
                    } else {
                        reject(new Error('No response from image uploader'));
                    }
                },
                err => reject(err),
                {
                    params: {
                        type
                    },
                    headers: {
                        Authorization: 'Bearer ' + LoginedUserHandler.getAccessToken()
                    }
                }
            );
        });
    },

    async uploadFromDOMEvent(type, e) {
        const files = e && e.target && e.target.files;

        if (files && files.length && files.item(0).size > 0) {
            return ImageInputHelpers.upload(files.item(0), type);
        }

        return Promise.resolve(null);
    },

    async upload(obj, type) {
      if (obj instanceof Blob) {
        return ImageInputHelpers.uploadFile(obj, type);
      } else if (_.isString(obj)) {
        return ImageInputHelpers.uploadFileFromNativeFS(obj, type);
      } else {
        return Promise.reject(new Error('`upload` first parameter must be File or String'));
      }
    },

    async uploadFile(file, type) {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          data.append('file', file);
          data.append('type', type);

          Server.callServer({
            url: settings.host + '/api/v2/upload',
            type: "POST",
            cache: false,
            contentType: false,
            processData: false,
            data,
            success: resolve,
            error: (jqXHR, textStatus, errorThrown) =>
              reject(new AjaxError(jqXHR, textStatus, errorThrown))
          });
        });
    },

    async createBlobUrlAsync(value) {
        return new Promise((resolve, reject) => {
            try {
                const maxSize = 300;
                const url = value instanceof Blob ? URL.createObjectURL(value) : value;
                var image = new Image();

                image.onload = function () {
                    // Resize image
                    var canvas = document.createElement('canvas'),
                        width = image.width,
                        height = image.height;
                    if (width > height) {
                        if (width > maxSize) {
                            height *= maxSize / width;
                            width = maxSize;
                        }
                    } else {
                        if (height > maxSize) {
                            width *= maxSize / height;
                            height = maxSize;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext('2d').drawImage(image, 0, 0, width, height);

                    canvas.toBlob(function (blob) {
                        const previewUrl = URL.createObjectURL(blob);
                        resolve(previewUrl);
                        // self.postMessage(previewUrl);
                    }, 'image/jpeg', 0.8);
                };

                image.src = url;
            } catch(e) {
                reject(e);
            }
        });
    }
};

export default ImageInputHelpers;
