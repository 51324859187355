import _ from 'underscore';
import { Marionette } from '@b2cmessenger/backbone';
import ReactDOM from "react-dom";
import React from 'react';

let Behaviors = {
    InputGroupWithError : Marionette.Behavior.extend({
        defaults: {
            modelSave: true,
            transformVal: val => val,
            onBlur: false,
            listenToEnableDisable: true,
            inputBindingToModel: false,
            transformValFromModel: val => val,
            deselectRadioEnable: false,
            dontClearErrorsOnBlur: false
        },

        initialize(options) {
            this.listenTo(this.view, `${this.options.name}:error:add`, msg => this.addError(msg));
            this.listenTo(this.view, `${this.options.name}:error:clear`, () => this.clearErrors());
            this.listenTo(this.view, `errors:clear`, () => this.clearErrors());
            if(this.options.listenToEnableDisable) {
                this.listenTo(this.view, `disable`, () => this.ui.input.attr('disabled', true));
                this.listenTo(this.view, `enable`, () => this.ui.input.attr('disabled', false));
            }
            if(this.options.inputBindingToModel) {
                this.listenTo(this.view.model || this.view.options.model, `change:${this.options.modelKey || this.options.name}`, (model, value) => this.onModelChange(value));
            }
        },

        ui: function() { return {
            group: this.options.selector || `[data-js-input-group-${this.options.name}]`,
            errors: `${this.options.selector || `[data-js-input-group-${this.options.name}]`} .error`,
            input: `${this.options.selector || `[data-js-input-group-${this.options.name}]`} input, ${this.options.selector || `[data-js-input-group-${this.options.name}]`} textarea`,
        }},

        events: {
            'touchstart @ui.group': 'onTouchstart',
            'click @ui.input': 'onClick',
            'change @ui.input': 'onChange',
            'focusout @ui.input': 'onChange'
        },

        onTouchstart(e) {
            if(/*this.options.fastSelectCheckboxOrRadioEnable() || */this.options.deselectRadioEnable) {
                this.ui.input.each((i, el) => $(el).attr('_checked', $(el).prop('checked')));
            }
        },

        onClick(e) {
            let $input = $(e.target);

            if($input.attr('type') == 'radio' && (/*this.options.fastSelectCheckboxOrRadioEnable() || */this.options.deselectRadioEnable)) {
                if($input.attr('_checked') == "true" && this.options.deselectRadioEnable) {
                    $input.prop('checked', false);
                    $input.attr('_checked', false);
                    $input.change();
                } /*else if(this.options.fastSelectCheckboxOrRadioEnable(e.target)) {
                    $input.prop('checked', true);
                    $input.attr('_checked', true);
                    $input.change();
                }
            } else if($input.attr('type') == 'checkbox' && this.options.fastSelectCheckboxOrRadioEnable(e.target)) {
                if($input.attr('_checked') == "true") {
                    $input.prop('checked', false);
                    $input.attr('_checked', false);
                    $input.change();
                } else {
                    $input.prop('checked', true);
                    $input.attr('_checked', true);
                    $input.change();
                }*/
            }
        },

        onChange(e) {
            if (this.options.dontClearErrorsOnBlur && e.type == "focusout") {

            } else {
                this.clearErrors();
            }
            if (!this.options.onBlur && e.type == "change" || this.options.onBlur && e.type == "focusout") {
                if (this.options.dontClearErrorsOnBlur && e.type != "focusout") {
                    this.clearErrors();
                }

                let val = this.getInputVal();

                if(this.options.modelSave) {
                    this.setInputVal(this.options.transformValFromModel(val));
                    this.view.model.set(this.options.modelKey || this.options.name, val);
                }
                (this.options.validate || this.view.validate && this.view.validate.bind(this.view) || (e => {}))();
                return (this.options.onChange && this.options.onChange.bind(this.view) || this.view[`on${this.options.name[0].toUpperCase()}${this.options.name.slice(1)}Change`] && this.view[`on${this.options.name[0].toUpperCase()}${this.options.name.slice(1)}Change`].bind(this.view) || (e => {}))(e, val);
            }
        },

        onModelChange(value) {
            this.setInputVal(this.options.transformValFromModel(value === undefined ? "" : String(value)));
        },

        getInputVal() {
            let val;

            if(this.ui.input.attr('type') == 'radio') {
                val = this.options.transformVal.call(this.view, this.ui.input.filter(':checked').val());
            } else if(this.ui.input.attr('type') == 'checkbox') {
                val = [];
                this.ui.input.filter(':checked').each((i, el) => val.push(this.options.transformVal.call(this.view, $(el).val())));
            } else {
                val = this.options.transformVal.call(this.view, this.ui.input.val());
            }

            return val;
        },

        setInputVal(val) {
            if(this.ui.input.attr('type') == 'radio') {
                this.ui.input.prop('checked', false).filter((i, el) => $(el).val() == val).prop('checked', true);
            } else if(this.ui.input.attr('type') == 'checkbox') {
                this.ui.input.prop('checked', false).filter((i, el) => $(el).val() == val || val.indexOf && val.indexOf($(el).val()) > -1).prop('checked', true);
            } else {
                this.ui.input.val(val);
            }
        },

        clearErrors() {
            this.ui.errors.text('');
            this.ui.group.removeClass('error');
        },

        addError(msg) {
            this.ui.errors.text(`${this.ui.errors.text() ? this.ui.errors.text() + ', ' : ''}${msg}`);
            this.ui.group.addClass('error');
        }
    }),

    InputGroupsWithErrorBehavior : Marionette.Behavior.extend({

    }),
    ReactComponentBehavior: Marionette.Behavior.extend({
        initialize(options) {
            if (!options.render && !options.component) {
                throw new Error('Missing options: render and component');
            }
        },

        onRender() {
            if (this.options.mountEvent === 'onRender') {
                this._mountReactComponent();
            }
        },

        onAttach() {
            if (this.options.mountEvent === 'onAttach') {
                this._mountReactComponent();
            }
        },

        onShow() {
            if (this.options.mountEvent === 'onShow') {
                this._mountReactComponent();
            }
        },

        onDestroy() {
            if (this._mounted) {
                ReactDOM.unmountComponentAtNode(this._getContainerEl());
            }
        },

        _mountReactComponent() {
            const Component = this.options.component;
            const props = this.options.props || {};

            const renderFn = this.options.render || function () {
                return <Component {...props}/>;
            };

            ReactDOM.render(renderFn(), this._getContainerEl(), this.options.componentUpdated);
            this._mounted = true;
        },

        _getContainerEl() {
            if (this.options.region) {
                const region = this.view.getRegion(this.options.region);

                if (region) {
                    return this.$(region.el)[0];
                }
            } else if (this.options.containerEl) {
                return this.$(this.options.containerEl)[0];
            }

            return this.el;
        }
    }),
    ReduxStoreViewConnect: Marionette.Behavior.extend({
        onRender() {
            if (_.isObject(this.options.store) && _.isObject(this.options.stateMappings)) {
                this.unsubscribeFromStore = this.options.store
                    .subscribe(this.applyStateMappingsToViewTarget.bind(this));
            }

            this.applyStateMappingsToViewTarget();
        },

        applyStateMappingsToViewTarget() {
            const { stateMappings, store, target } = this.options;

            const state = store.getState();
            const payload = _.chain(stateMappings)
                .keys()
                .reduce((memo, key) => {
                    if (_.isFunction(stateMappings[key])) {
                        memo[key] = stateMappings[key](state);
                    }

                    return memo;
                }, _.create(null))
                .value();

            if (_.isObject(target) && _.isFunction(target.toJSON)) {
                target.set(payload);
            } else if (_.isFunction(target)) {
                target(payload);
            }
        },

        onDestroy() {
            this.unsubscribeFromStore && this.unsubscribeFromStore();
        }
    })
};

export default Behaviors;
