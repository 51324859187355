import Page from 'pages/Page';
import Window from 'windows/Window';
import { ViewModel, Collection, Model } from '@b2cmessenger/backbone';
import template from './LoyaltyKiosk.jade';
import './LoyaltyKiosk.scss';
import LoyaltyKioskAuthorizeView from 'widgets/LoyaltyKiosk/LoyaltyKioskAuthorize';
import HeaderWithPlaceInfo from 'widgets/HeaderWithPlaceInfo/HeaderWithPlaceInfo';
import LoyaltyKioskDisableModal from 'windows/Modal/LoyaltyKioskDisableModal/LoyaltyKioskDisableModal';
import LoyaltyKioskGiftsView from 'widgets/LoyaltyKiosk/LoyaltyKioskGifts';
import LoyaltyKioskModel from 'models/LoyaltyKioskModel';
import settings from 'settings';
import AjaxError from 'utils/AjaxError';
import LoyaltyKioskRedeemedGiftModal from "windows/Modal/LoyaltyKiosk/LoyaltyKioskRedeemedGiftModal";
import LoyaltyKioskCheckinGiftModal from "windows/Modal/LoyaltyKiosk/LoyaltyKioskCheckinGiftModal";

const DEFAULT_CHECKIN_METHOD_TIMEOUT = 15 * 1000;

const LoyaltyKioskGiftCollection = Collection.extend({
    comparator: 'transformation_ratio'
});

const LoyaltyKioskPage = Page.extendWithTraits([], {
    template: template,

    attributes: {
        'id': "page_loyalty_kiosk"
    },

    className: 'loyalty-kiosk-page',

    regions: {
        header: '[data-js-header]',
        content: '[data-js-content]'
    },

    ui: {
        content: '[data-js-content]'
    },

    initialize() {
        this.viewModel = new ViewModel({
            parentViewModel: this.options.parentViewModel
        });

        this.model = new LoyaltyKioskModel();
        this.giftCollection = new LoyaltyKioskGiftCollection();

        this.model.listenTo(this, 'activity', e => {
            const now = Date.now();
/////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////

            this.model.set('latestActivityTime', now);
        });

        this.activityHandler = _.debounce((e) => this.trigger('activity', e), 100);
    },

    show(options) {
        if (options.defaultWorkPlace) {
            _.invoke(
                [this.viewModel, this.model],
                'set', { workPlace: options.defaultWorkPlace }
            );
        }

        if (options.defaultCheckInMethod) {
            _.invoke(
                [this.viewModel, this.model],
                'set', {
                    defaultCheckInMethod: options.defaultCheckInMethod,
                    inactivityTimeout: this.model.defaults.inactivityTimeout / 2
                }
            );
        } else {
            _.invoke(
                [this.viewModel, this.model],
                'set', {
                    inactivityTimeout: this.model.defaults.inactivityTimeout
                }
            );
        }

        return Page.prototype.show.apply(this, arguments);
    },

    onAttach() {
        const headerView = new HeaderWithPlaceInfo({
            leftButtons: ['menu'],
            title: 'Loyalty Kiosk',
            parentViewModel: this.viewModel
        });

        if (headerView.viewModel) {
            headerView.viewModel.addProxyAttribute('place', 'parentViewModel', 'workPlace', true);
        }

        this.listenTo(headerView, 'menu:click', () => {
            this.stopActivityTimer();

            new LoyaltyKioskDisableModal()
                .show()
                .then(result => {
                    if (_.isObject(result)) {
                        if (this.model.get('userId') !== null) {
                            this.logOutAuthorizedUser();
                        }

                        LoginedUserHandler.setLoyaltyKioskMode(null);
                        app.controller.restart();
                        this.cancel();
                        this.destroy();
                        app.controller.loyaltyKioskPage = new LoyaltyKioskPage();
                    }

                    this.trigger('activity', { type: 'abstract' });
                    _.defer(() => {
                        this.startActivityTimer();
                    });
                });
        });
        this.header.show(headerView);

        this.showAuthorization();
    },

    startActivityTimer() {
        this.activityTimer = setInterval(() => {
            this.trigger('tick');
        }, 500);

        $(document).on(
            'click scroll scrollstop touchstart touchmove touchend focus blur keydown',
            this.activityHandler
        );

        this.model.set({ latestActivityTime: Date.now() });
    },

    stopActivityTimer() {
        if (this.activityTimer) {
            clearInterval(this.activityTimer);
        }

        $(document).off(
            'click scroll scrollstop touchstart touchmove touchend focus blur keydown',
            this.activityHandler
        );
    },

    logOutAuthorizedUser() {
        this.model.reset();
        if (Window.isActiveWindow()) Window.cancelActiveWindow();
        this.stopActivityTimer();
        this.showAuthorization();
    },

    showAuthorization(options) {
        _.defaults(options || (options = {}), {
            ...this.viewModel.pick(['workPlace', 'defaultCheckInMethod']),
            model: this.model
        });
        const authorizeView = new LoyaltyKioskAuthorizeView(options);

        this.listenTo(authorizeView, 'authorize', (user, options) => {
            this.stopActivityTimer();

            let giftTemplate = null;

            this.model.set({
                user,
                ..._.pick(options, 'loggedInThroughEmail', 'loggedInThroughPhone', 'loggedInThroughLoyaltyCardNumber')
            });

            this.showLoading(500);
            this.model
                .performServerCheckin()
                .then(data => {
                    giftTemplate = data.template || null;
                    return this.model.getUserCoins(user.id);
                })
                .then(coins => {
                    return this.getWorkPlaceKioskAvailableGiftTemplates(this.model.get('workPlaceBrandId'))
                }).then(gifts => {
                    this.giftCollection.set(gifts);
                    this.showKiosk({
                        giftTemplate,
                        giftCollection: this.giftCollection
                    });
                })
                .then(() => this.hideLoading())
                .catch(e => {
                    this.hideLoading();
                    this.showError(e);
                });
        });

        if (authorizeView.options.defaultCheckInMethod) {
            authorizeView.listenTo(this, 'tick', () => {
                const time = this.model.getTimeSinceLatestActivity();

                if (this.isActive && !authorizeView.viewModel.get('checkingInVia')) {
                    if (time > DEFAULT_CHECKIN_METHOD_TIMEOUT) {
                        authorizeView.startDefaultCheckInMethod();

/////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////
                    } else {
                        if (Math.floor((DEFAULT_CHECKIN_METHOD_TIMEOUT - time) / 1000) % 1 === 0) {
/////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////
                        }
                    }
                } else if (authorizeView.viewModel.get('checkingInVia')) {
                    this.trigger('activity', { type: 'checking-in' });
                } else {
                    this.trigger('activity', { type: 'abstract' });
                }
            });
        }

        this.startActivityTimer();

        this.content.show(authorizeView);
    },

    showKiosk(options) {
        _.defaults(options || (options = {}), {
            giftTemplate: null,
            model: this.model
        });

        const giftsView = new LoyaltyKioskGiftsView(options);

        if (options.giftTemplate) {
            new LoyaltyKioskCheckinGiftModal({
                model: new Model(options.giftTemplate),
                loyaltyKioskModel: this.model,
            }).show()
                .then(result => {
                    if (result === 'logout') {
                        this.logOutAuthorizedUser();
                    }
                });
        }

        this.model.set({
            secondsBeforeLogout: Math.floor(this.model.get('inactivityTimeout') / 1000)
        });

        giftsView.listenTo(this, 'tick', () => {
            const time = this.model.getTimeSinceLatestActivity();

            if (this.isActive) {
                const timeout = this.model.get('inactivityTimeout');
                const timeLeft = Math.floor((timeout - time) / 1000);
                const timeSince = Math.floor(time / 1000);

                if (time > timeout) {
                    this.model.set({ secondsBeforeLogout: 0 });
                    giftsView.trigger('logout');

/////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////
                } else {
                    if (timeLeft % 10 === 0) {
/////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////
                    }

                    this.model.set({ secondsBeforeLogout: timeLeft });
                }
            } else {
                this.trigger('activity', { type: 'abstract' });
            }
        });

        this.startActivityTimer();

        this.listenTo(giftsView, 'logout', this.logOutAuthorizedUser.bind(this));

        this.listenTo(giftsView, 'redeem', giftTemplateId => {
            this.showLoading(500);

            this.redeemGiftTemplate(this.model.get('userId'), giftTemplateId)
                .then(data => {
                    if (_.has(data, 'coins_remain')) {
                        this.model.set({ coins: data.coins_remain });
                    }
                    if (this.giftCollection.get(giftTemplateId)) {
                        new LoyaltyKioskRedeemedGiftModal({
                            model: this.giftCollection.get(giftTemplateId),
                            loyaltyKioskModel: this.model,
                        }).show().then((data) => {
                            if (!data) {
                                this.model.reset();
                                this.showAuthorization();
                            }
                        })
                    }
                })
                .then(() => this.hideLoading())
                .catch(e => {
                    this.hideLoading();
                    this.showError(e);
                })
        });

        this.content.show(giftsView);
    },

    async getWorkPlaceKioskAvailableGiftTemplates(brandId) {
        brandId = brandId || this.model.get('workPlaceBrandId');

        return new Promise((resolve, reject) => {
            Server.callServer({
                url: settings.host + settings.serv_gift.template.search,
                data: {
                    brand_id: brandId
                },
                type: "POST",
                success: data =>
                    resolve(_.filter(data && data.templates, t => t.custom_quantity_allowed && t.transformation_type == 4)),
                error: (jqXHR, textStatus, errorThrown) => {
                    reject(new AjaxError(jqXHR, textStatus, errorThrown));
                }
            });
        });
    },

    async redeemGiftTemplate(userId, giftTemplateId) {
        return new Promise((resolve, reject) => {
            Server.callServer({
                url: settings.host + settings.serv_gift.redeem,
                data: {
                    user_id: userId,
                    gift_template_id: giftTemplateId
                },
                type: "POST",
                success: resolve,
                error: (jqXHR, textStatus, errorThrown) => {
                    reject(new AjaxError(jqXHR, textStatus, errorThrown));
                }
            });
        });
    }
});

export default LoyaltyKioskPage;