import {UnlistedCategory} from "utils/api/menu";

export const SET_LOADING                = '[Menu] Set Loading';

export const INIT_MENU                  = '[Menu] Init Menu';
export const INIT_MENU_SUCCEEDED        = '[Menu] Init Menu Succeed';
export const INIT_MENU_FAILED           = '[Menu] Init Menu Failed';

export const SAVE_MENU_INFO             = '[Menu] Save Menu Info';
export const SAVE_MENU_INFO_SUCCEEDED   = '[Menu] Save Menu Info Succeed';
export const SAVE_MENU_INFO_FAILED      = '[Menu] Save Menu Info Failed';

export const LOAD_MENU_ITEM_DETAIL           = '[Menu] Load Item Detail';
export const LOAD_MENU_ITEM_DETAIL_SUCCEEDED = '[Menu] Load Item Detail Succeed';
export const LOAD_MENU_ITEM_DETAIL_FAILED    = '[Menu] Load Item Detail Failed';

export const DISPLAY_MENU_ITEM_DETAIL        = '[Menu] Display Item Detail';

export const LOAD_CATEGORY              = '[Menu] Load Category';
export const LOAD_CATEGORY_SUCCEEDED    = '[Menu] Load Category Succeed';
export const LOAD_CATEGORY_FAILED       = '[Menu] Load Category Failed';

export const DISPLAY_CATEGORY           = '[Menu] Display Category';

export const UPSERT_CATEGORY            = '[Menu] Upsert Category';
export const UPSERT_CATEGORIES          = '[Menu] Upsert Categories';
export const REMOVE_CATEGORY            = '[Menu] Remove Category';
export const RESET_CATEGORIES           = '[Menu] Reset categories'

export const UPSERT_ITEM                = '[Menu] Upsert Item';
export const UPSERT_ITEMS               = '[Menu] Upsert Items';
export const REMOVE_ITEM                = '[Menu] Remove Item';

export const RESET_ITEMS = '[Menu] Reset items';

export const CHANGE_MENU_ELEMENTS_ORDER = '[Menu] Change Menu Elements Order';

export const UPLOAD_MENU_ITEM_SUCCESS   = '[Menu] Upload Menu Item Success';

export const LOAD_MENU_DETAILED_ITEMS           = '[Menu] Load Menu Detailed Items';
export const LOAD_MENU_DETAILED_ITEMS_SUCCEEDED = '[Menu] Load Menu Detailed Items Succeeded';
export const LOAD_MENU_DETAILED_ITEMS_FAILED    = '[Menu] Load Menu Detailed Items Failed';

export const SCAN_MENU_TABLE                    = '[Menu] Scan Menu Table';

export const setLoading = (loading) => ({
    type: SET_LOADING,
    payload: {
        loading
    }
});

export const initMenu = (placeId, categoryId = null, { isLoggedIn = false, isMenuManager = false, isWaiter = false, isOrderManager = false} = {}) =>
    ({
        type: INIT_MENU,
        payload: {
            placeId,
            isLoggedIn,
            isMenuManager,
            isWaiter,
            isOrderManager,
            categoryId: categoryId === null ? categoryId : Number(categoryId)
        }
    });

export const initMenuSucceeded = ({ placeId, categoryId, currency, isFood, categories, items, sections, waiters, tables = null }) =>
    ({
        type: INIT_MENU_SUCCEEDED,
        payload: {
            placeId, categoryId, currency, isFood, categories, items, sections,
            waiters: _.chain(waiters).map(w => [w.id, w]).sortBy(([id]) => -id).object().value(),
            ...(_.isArray(tables) ? { tables } : {})
        }
    });

export const initMenuPartialSucceeded = (options) => {
    const action = initMenuSucceeded(options);

    return {
        ...action,
        payload: { ...action.payload, error: options.error }
    }
};

export const initMenuFailed = (error) =>
    ({ type: INIT_MENU_FAILED, payload: { error } });

export const saveMenuInfo = (currency, isFood) =>
    ({ type: SAVE_MENU_INFO, payload: { currency, isFood } });

export const saveMenuInfoSucceeded = (currency, isFood) =>
    ({ type: SAVE_MENU_INFO_SUCCEEDED, payload: { currency, isFood } });

export const saveMenuInfoFailed = (error) =>
    ({ type: SAVE_MENU_INFO_FAILED, payload: { error } });

export const loadCategory = (id, forceFetch = false) => ({
    type: LOAD_CATEGORY,
    payload: {
        id: id === null ? id : Number(id),
        forceFetch
    }
});

export const loadCategorySucceed = (id, categories, items) => ({
   type: LOAD_CATEGORY_SUCCEEDED,
   payload: {
       id,
       categories,
       items
   }
});

export const loadCategoryFailed = (error) => ({
    type: LOAD_CATEGORY_FAILED,
    payload: {
        error
    }
});

export const displayCategory = (id) => ({
    type: DISPLAY_CATEGORY,
    payload: {
        id
    }
});

export const resetCategories = (categories, categoriesItemsMap = undefined) => ({
    type: RESET_CATEGORIES,
    payload: {
        categories,
        categoriesItemsMap
    }
});

export const loadMenuItemDetail = (placeId, id, forceUpdate) => ({
    type: LOAD_MENU_ITEM_DETAIL,
    payload: {
        placeId: Number(placeId),
        itemId: Number(id),
        forceUpdate
    }
});

export const loadMenuItemDetailSucceed = (item) => ({
    type: LOAD_MENU_ITEM_DETAIL_SUCCEEDED,
    payload: {
        item
    }
});

export const loadMenuItemDetailFailed = (error) => ({
    type: LOAD_MENU_ITEM_DETAIL_FAILED,
    payload: {
        error
    }
});

export const displayMenuItemDetail = (id) => ({
    type: DISPLAY_MENU_ITEM_DETAIL,
    payload: {
        id
    }
});

export const upsertCategory = (category) => ({
    type: UPSERT_CATEGORY,
    payload: {
        id: category.id,
        category
    }
});

export const upsertCategories = (categories, categoriesItemsMap = undefined) => ({
    type: UPSERT_CATEGORIES,
    payload: {
        categories,
        categoriesItemsMap
    }
});

export const removeCategory = (id, itemsMovedToParent) => ({
    type: REMOVE_CATEGORY,
    payload: {
        id,
        itemsMovedToParent
    }
});

export const upsertItem = (item, categoryIds = undefined) => ({
    type: UPSERT_ITEM,
    payload: {
        id: item.id,
        categoryIds: _.isArray(categoryIds) && categoryIds.length === 0 ? [UnlistedCategory.id] : categoryIds,
        item
    }
});

export const upsertItems = (items) => ({
    type: UPSERT_ITEMS,
    payload: {
        items
    }
});

export const resetItems = (items) => ({
    type: RESET_ITEMS,
    payload: {
        items
    }
});

export const removeItem = (id) => ({
    type: REMOVE_ITEM,
    payload: {
        id
    }
});

export const changeMenuElementsOrder = (categoriesIds, itemsIds, selectedCategoryId) => ({
    type: CHANGE_MENU_ELEMENTS_ORDER,
    payload: {
        categoriesIds,
        itemsIds,
        selectedCategoryId
    }
});

export const uploadMenuItemSucceed = (item) => ({
    type: UPLOAD_MENU_ITEM_SUCCESS,
    payload: { item }
});

export const loadDetailedMenuItems = (ids = [], placeId) => ({
    type: LOAD_MENU_DETAILED_ITEMS,
    payload: { ids, placeId }
});

export const loadDetailedMenuItemsSucceed = (items) => ({
    type: LOAD_MENU_DETAILED_ITEMS_SUCCEEDED,
    payload: { items }
});

export const loadDetailedMenuItemsFailed = (error) => ({
    type: LOAD_MENU_DETAILED_ITEMS_FAILED,
    payload: { error }
});

export const scanMenuTable = (placeId, tableId = null) => ({
    type: SCAN_MENU_TABLE,
    payload: {
        placeId: Number(placeId),
        tableId: Number(tableId)
    }
})
