import {
    INIT_FROM_OFFLINE_STORAGE,
    LOGOUT,
    SET_LOGINED_USER,
    SET_LOGINED_USER_DEFAULT_WORKPLACE_ID,
    SET_LOGINED_USER_WORKPLACE_ROLES,
    SET_LOGINED_USER_WORKPLACES,
    SET_LOGINED_USER_WORKPLACES_ROLES,
    SET_LOGINED_USER_WORKPLACE_STATUS,
    SET_LOGINED_USER_WORKPLACES_STATUSES
} from "store/app/actions";

const initialState = {
    loginedUser: null,
    defaultWorkPlaceId: null,

    workPlaces: {},
    workPlacesRoles: {},
    workPlacesStatuses: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case INIT_FROM_OFFLINE_STORAGE: {
            const {
                loginedUser = null, defaultWorkPlaceId = null, workPlacesRoles = {}, workPlacesStatuses = {}
            } = action.payload;

            const workPlaces = {};

            if (_.isArray(action.payload.workPlaces)) {
                for (const place of action.payload.workPlaces) {
                    workPlaces[place.id] = { ...place };
                }
            }

            return {
                ...state,
                loginedUser, defaultWorkPlaceId, workPlaces, workPlacesRoles, workPlacesStatuses
            };
        }

        case LOGOUT: {
            return {
                ...state,
                loginedUser: initialState.loginedUser,
                defaultWorkPlaceId: initialState.defaultWorkPlaceId,
                workPlaces: { ...initialState.workPlaces },
                workPlacesRoles: { ...initialState.workPlacesRoles },
                workPlacesStatuses: { ...initialState.workPlacesStatuses }
            }
        }

        case SET_LOGINED_USER: {
            const { loginedUser } = action.payload;

            return {
                ...state, loginedUser: { ...loginedUser }
            };
        }

        case SET_LOGINED_USER_WORKPLACES: {
            const workPlaces = {};

            if (_.isArray(action.payload.workPlaces)) {
                for (const place of action.payload.workPlaces) {
                    workPlaces[place.id] = { ...place };
                }
            }

            return {
                ...state, workPlaces
            };
        }

        case SET_LOGINED_USER_WORKPLACES_ROLES:
            return { ...state, workPlacesRoles: action.payload.workPlacesRoles };

        case SET_LOGINED_USER_WORKPLACE_ROLES: {
            const { placeId, roles = [] } = action.payload;

            return {
                ...state,
                workPlacesRoles: {
                    ...state.workPlacesRoles,
                    [placeId]: roles
                }
            };
        }

        case SET_LOGINED_USER_DEFAULT_WORKPLACE_ID:
            return { ...state, defaultWorkPlaceId: action.payload.placeId };

        case SET_LOGINED_USER_WORKPLACES_STATUSES:
            return { ...state, workPlacesStatuses: action.payload.workPlacesStatuses };

        case SET_LOGINED_USER_WORKPLACE_STATUS: {
            const { placeId, status } = action.payload;

            return {
                ...state,
                workPlacesStatuses: {
                    ...state.workPlacesStatuses,
                    [placeId]: status
                }
            };
        }

        default:
            return state;
    }
}
