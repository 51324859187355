import { ItemView } from '@b2cmessenger/backbone';
import template from './GiftTemplatesItemView.jade';
import GiftTemplatesEdit from 'windows/GiftTemplatesEdit/GiftTemplatesEdit';

const GiftTemplatesItemView = ItemView.extend({
    options: {
        choosable: false,
        transformationAllowed: true
    },

    className: 'gift-templates-list-item',
    template: template,

    ui: {
        moreBtn: '[data-js-more-button]',
        editBtn: '[data-js-edit]',
        hiddenMenu: '[data-js-hidden-menu]',
        image: '[data-js-image]',
        validUntil: '[data-js-validuntil]'
    },

    events: {
        'click @ui.moreBtn': 'toggleHiddenMenu',
        'click @ui.editBtn': 'openEdit',
        'click': 'choose'
    },

    toggleHiddenMenu(e) {
        e.stopPropagation();
        this.ui.hiddenMenu.is(':visible') ? this.ui.hiddenMenu.hide() : this.ui.hiddenMenu.show();
    },

    choose(e) {
        if(this.options.choosable){
            this.trigger('click');
        }
    },

    openEdit(e) {
        e.stopPropagation();

        new GiftTemplatesEdit({
            model: this.model.clone()
        })
            .show()
            .then(model => {
                if (model) {
                    this.model.set(model.toJSON({ computed: true }));

                    if (this.model.changed && this.model.changed['is_for_business_checkin']) {
                        const previousModel = this.model.collection.find(m =>
                            m.get('is_for_business_checkin') && m.id !== this.model.id);

                        if (previousModel) {
                            previousModel.set('is_for_business_checkin', 0);
                        }
                    }

                    this.trigger('reload');
                }
            });
    },

    templateHelpers() {
        return {
            transformationAllowed: this.options.transformationAllowed
        };
    },

    onRender() {
        if(!this.model.get('thumb')) {
            switch (this.model.get('type')){
                case 1:
                    this.ui.image.addClass('empty coins');
                    break;

                case 2:
                    this.ui.image.addClass('empty gifts');
                    break;

                case 3:
                    this.ui.image.addClass('empty discounts');
                    break;
            }
        } else {
            if(this.model.get('type') == 1) {
                this.ui.image.addClass('empty coins');
            } else {
                this.ui.image.removeClass('empty');
                this.ui.image.css('background-image', `url("${this.model.get('thumb')}")`);
            }
        }

        if(this.model.get('type') == 1) {
            this.ui.validUntil.css('display', 'none');
        } else {
            if(!this.model.get('total_period') && !this.model.get('period')) {
                this.ui.validUntil.css('display', 'none');
            } else {
                this.ui.validUntil.css('display', 'block');
                let expireDateLocal = null;

                if(this.model.get('period')) {
                    expireDateLocal = new Date();
                    expireDateLocal.setDate(expireDateLocal.getDate() + this.model.get('period'));
                    expireDateLocal.setHours(23, 59, 59, 999);
                }

                if(this.model.get('total_period')) {
                    let totalExpireDate = new Date(this.model.get('total_period').replace(/ /g, "T"));

                    let totalExpireDateLocal = new Date(totalExpireDate);
                    totalExpireDateLocal.setFullYear(totalExpireDate.getUTCFullYear(), totalExpireDate.getUTCMonth(), totalExpireDate.getUTCDate());
                    totalExpireDateLocal.setHours(23, 59, 59, 999);

                    if(!expireDateLocal || expireDateLocal > totalExpireDateLocal) expireDateLocal = totalExpireDateLocal;
                }

                if(new Date() > expireDateLocal) this.ui.validUntil.find('.label').addClass('expired').text('Expired:');

                this.ui.validUntil.find('[data-js-date]').text(expireDateLocal.toLocaleDateString(
                    [], {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric'
                    }));
            }
        }
    }
});

export default GiftTemplatesItemView;
