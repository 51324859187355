import { Required, LayoutView, Model, Collection, Optional, ViewModel } from '@b2cmessenger/backbone';

import prettyText from 'widgets/lite/text/prettyText';
import { bindingHandler as starsBindingHandler } from 'widgets/lite/stars/stars';

import MessagePhotoModel from './MessagePhotoModel';
import MessagePhotoCollectionWidget from './MessagePhotoCollection';
import GiftWidget from 'widgets/Gift/Gift';

import template from './MessageContent.jade';
import './MessageContent.scss';
import ReservationContent from "widgets/Reservation/ReservationContent/ReservationContent";
import ReservationModel from "models/ReservationModel";
import ReservationChangeCommentAdditionalData
    from "widgets/Reservation/ReservationChangeCommentAdditionalData/ReservationChangeCommentAdditionalData";

/** @type {typeof import('./MessageContent').properties} */
// @ts-ignore
const properties = LayoutView.properties;

/** @type {typeof import('./MessageContent').options} */
// @ts-ignore
const options = LayoutView.options;

@options({
    model: Required,
    reservationModel: Optional
})
@properties({
    className: 'widget message-content-widget',
    template,

    ui: {
        rating: '[data-js-rating]',
        subject: '[data-js-subject]',
        text: '[data-js-text]'
    },

    regions: {
        gallery: '[data-js-gallery]',
        gift: '[data-js-gift]',
        reservation: '[data-js-reservation]'
    },

    bindings: {
        '@ui.rating': 'classes:{hidden:not(rating)},starsBindingHandler:rating',
    },

    triggers: {
        'click @ui.subject': 'full:page',
        'click @ui.text': 'full:page'
    },

    bindingHandlers: {
        starsBindingHandler
    },

    modelEvents: {
        'change:subject change:text change:photos change:gift change:isBroadcast change:gift_template':
            _.debounce(function () {
                if (!this.isDestroyed && this.isRendered) {
                    this.render();
                }
            }, 1)
    },

    templateHelpers() {
        return {
            prettyText
        }
    }
})
class MessageContentView extends LayoutView {
    initialize() {

        this.viewModel = new ViewModel({
            isHidden: false,
            parentViewModel: this.options.parentViewModel,
        });

        this.reservationModel = this.options.reservationModel || (this.model.get('isReservation') ?
                new ReservationModel(this.model.get('reservation')) :
                null
        );
    }

    onRender() {
        /** @type {import('./MessageContent')} */
            // @ts-ignore
        const self = this;

        this.showChildView('gallery', new MessagePhotoCollectionWidget({
            collection: new Collection(self.model.get('photos') || [], {
                model: MessagePhotoModel
            })
        }));

        const gift = this.model.get('gift');

        if (this.model.get('isReservation')) {
            this.showChildView('reservation', new ReservationContent({
                model: this.reservationModel || new ReservationModel(this.model.get('reservation')),
                messageModel: this.model
            }));
        } else if (this.model.get('isReservationChangeComment')) {
            this.showChildView('reservation', new ReservationChangeCommentAdditionalData({
                model: new Model(this.model.get('reservationChange').data)
            }));
        } else if (gift) {
            const template = gift.template || {
                name: 'Gifts',
                type: 2
            };

            const expireDate = gift.expire && B2Cjs.datetimeServerLocalToJS(gift.expire) || null;
            if (expireDate) {
                expireDate.setHours(23, 59, 59, 999);
            }

            this.showChildView('gift', new GiftWidget({
                model: new Model({
                    name: template.name,
                    thumb: template.thumb,
                    expireDate: expireDate,
                    type: template.type,
                    quantity: gift.quantity_initial
                }),
                quantityEditable: false
            }));
        } else if (this.model.get('isBroadcast')) {
            const template = this.model.get('gift_template');
            if (template) {
                let expireDate = null;
                if (template.period) {
                    expireDate = B2Cjs.datetimeServerLocalToJS(this.model.get('published_at'));
                    expireDate.setDate(expireDate.getDate() + template.period);
                    expireDate.setHours(23, 59, 59, 999);
                }

                if (template.total_period) {
                    const totalExpireDate = B2Cjs.datetimeServerLocalToJS(template.total_period);
                    totalExpireDate.setHours(23, 59, 59, 999);

                    if (!expireDate || expireDate > totalExpireDate) expireDate = totalExpireDate;
                }

                this.showChildView('gift', new GiftWidget({
                    model: new Model({
                        name: template.name,
                        thumb: template.thumb,
                        expireDate: expireDate,
                        type: template.type,
                        quantity: this.model.get('gift_quantity') || 0
                    }),
                }));
            }
        }
    }
};

export default MessageContentView;
