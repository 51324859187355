import React, { useEffect, useState, useCallback } from 'react';
import MenuCategories from 'components/menu/MenuCategories/MenuCategories';
import MenuItems from 'components/menu/MenuItems/MenuItems';
import Spinner from 'components/UI/Spinner/Spinner';
import MenuSelectableCategories from 'components/menu/MenuCategories/MenuSelectableCategories';
import MenuSelectableItems from 'components/menu/MenuItems/MenuSelectableItems';
import * as MenuAPI from 'utils/api/menu';
import './MenuBrowser.scss';
import { MenuListEmptyItem, MenuListType } from 'components/menu/MenuList/MenuList';

export const MenuBrowserDisplayCategories = {
    All: 'all',
    Additions: 'additions',
    None: null,
};

export const MenuBrowserSelect = {
    Any: 'any',
    Category: 'category',
    Item: 'item',
    None: null,
};


function toArray(value, multiple) {
    if (value) {
        if (multiple && _.isArray(value)) {
            return [...value];
        }

        return [_.isObject(value) ? { ...value } : value];
    }

    return [];
}

function byCategorySortOrderAndIsAddition(a, b) {
    return a.sort_order > b.sort_order ? 1 : -1;
}

function onlyCategoryOfType(type) {
    switch (type) {
        case MenuBrowserDisplayCategories.None:
            return () => false;
        case MenuBrowserDisplayCategories.Additions:
            return (c) => Number(c.is_addition) === 1;
        case MenuBrowserDisplayCategories.All:
        default:
            return () => true;
    }
}

function byId(id) {
    return (c) => c.id === id;
}

function allExceptId(id) {
    return (c) => c.id !== id;
}

function isCategory(obj) {
    return obj.parent_id !== undefined;
}

const MenuBrowser = (props) => {
    const {
        placeId,
        displayCategories = MenuBrowserDisplayCategories.All,
        includeUnlistedCategory = false,
        displayItems = false,
        select = MenuBrowserSelect.Any,
        multiple = false,
        value,
        onSelect = _.noop,
        message = null,
        showError,
    } = props;

    const persistedCategories = props.categories || null;
    const persistedItems = props.items || props.additions || null;

    const [selected, setSelected] = useState([]);

    const updateSelected = useCallback(
        (value = []) => {
            if (_.isFunction(onSelect)) {
                onSelect(
                    multiple
                        ? {
                            categories: value.filter(isCategory),
                            items: value.filter(_.negate(isCategory)),
                        }
                        : value[0]
                );
            }

            setSelected(value);
        },
        [setSelected, multiple, onSelect]
    );

    useEffect(() => {
        setSelected(() => toArray(value, multiple));
    }, [value, multiple]);

    const [categories, setCategories] = useState([]);
    const [items, setItems] = useState([]);
    const [displayedCategoryId, setDisplayedCategoryId] = useState(null);
    const [displayedCategoryIsAddition, setDisplayedCategoryIsAddition] = useState(0);
    const [path, setPath] = useState([]);
    const [loading, setLoading] = useState(true);

    const selectedCategories = selected.filter(isCategory);
    const setSelectedCategories = useCallback(
        (category, isSelected) => {
            if (!multiple) {
                updateSelected(isSelected ? [category] : []);
                return;
            }

            const newCategories = isSelected
                ? [...selectedCategories, { ...category }]
                : selectedCategories.filter((c) => c.id !== category.id);
            const anythingButCategories = selected.filter(_.negate(isCategory));

            updateSelected([...anythingButCategories, ...newCategories]);
        },
        [selectedCategories, selected, updateSelected, multiple]
    );

    const selectedItems = selected.filter(_.negate(isCategory));
    const setSelectedItems = useCallback(
        (item, isSelected) => {
            if (!multiple) {
                updateSelected(isSelected ? [item] : []);
                return;
            }

            const newItems = isSelected
                ? [...selectedItems, { ...item }]
                : selectedItems.filter((c) => c.id !== item.id);
            const anythingButItems = selected.filter(isCategory);

            updateSelected([...anythingButItems, ...newItems]);
        },
        [selectedItems, selected, updateSelected, multiple]
    );

    const onCategoryClick = useCallback(
        (id) => {
            const category = categories.find(byId(id)) || null;

            setPath((path) => {
                if (category !== null || id !== null) {
                    const idx = path.findIndex(byId(id));

                    if (idx < 0 && category) {
                        return [...path].concat({ ...category });
                    }
                    if (idx === path.length - 2) {
                        return path.filter(allExceptId(id));
                    }
                    return path;
                }
                return [];
            });

            setDisplayedCategoryId(id);
            setLoading(true);
        },
        [categories, displayedCategoryId, setPath, setDisplayedCategoryId]
    );

    // handle categories browsing
    useEffect(() => {
        async function load() {
            let newCategories = [];
            let newItems = [];

            try {
                if (displayedCategoryId === null) {
                    if (persistedCategories !== null || persistedItems !== null) {
                        newCategories = (persistedCategories && [...persistedCategories]) || newCategories;
                        newItems = (persistedItems && [...persistedItems]) || newItems;
                    } else {
                        const response = await MenuAPI.loadCategories({
                            placeId,
                            includeUnlisted: includeUnlistedCategory,
                        });
                        newCategories = (response || []).filter((c) => c.parent_id === null);
                    }
                } else {
                    const response = await MenuAPI.loadCategory({ placeId, id: displayedCategoryId });
                    newCategories = response.categories;
                    newItems = response.items;
                }

                setCategories(
                    newCategories.filter(onlyCategoryOfType(displayCategories)).sort(byCategorySortOrderAndIsAddition)
                );
                setItems(newItems);
            } catch (e) {
                showError && showError(e);
            } finally {
                setLoading(false);
            }
        }

        load();
    }, [displayedCategoryId, displayCategories]);

    useEffect(() => {
        if (displayedCategoryId === null) {
            setDisplayedCategoryIsAddition(props.additions ? 1 : 0);
        } else {
            const category = categories.find((c) => c.id === displayedCategoryId);

            if (category) {
                setDisplayedCategoryIsAddition(category.is_addition);
            }
        }
    }, [displayedCategoryId, categories, setDisplayedCategoryIsAddition, props.additions]);
    const emptyItemsContent = (
        <MenuListEmptyItem
            type={
                select === MenuBrowserSelect.Item
                    ? MenuListType.Items
                    : select === MenuBrowserSelect.Category
                        ? MenuListType.Categories
                        : 'categories or items'
            }
        />
    );

    const parentCategory = path.length
        ? path.length > 1
            ? path[path.length - 2]
            : { name: 'Menu Index', id: null }
        : null;

    let categoriesEl = null;
    if (displayCategories) {
        if (select === MenuBrowserSelect.Any || select === MenuBrowserSelect.Category) {
            categoriesEl = (
                <MenuSelectableCategories
                    categories={categories}
                    onSelectCategory={onCategoryClick}
                    selected={selectedCategories.map((c) => c.id)}
                    onSelectedChange={setSelectedCategories}
                    emptyContent={!displayItems && emptyItemsContent}
                />
            );
        } else {
            categoriesEl = (
                <MenuCategories
                    categories={categories}
                    onSelectCategory={onCategoryClick}
                    emptyContent={!displayItems && emptyItemsContent}
                />
            );
        }
    }

    let itemsEl = null;
    if (displayItems) {
        if (select === MenuBrowserSelect.Any || select === MenuBrowserSelect.Item) {
            itemsEl = (
                <MenuSelectableItems
                    additions={displayedCategoryIsAddition ? true : null}
                    items={items}
                    onSelectItem={() => {}}
                    selected={selectedItems.map((i) => i.id)}
                    onSelectedChange={setSelectedItems}
                    emptyContent={emptyItemsContent}
                />
            );
        } else {
            itemsEl = (
                <MenuItems
                    additions={displayedCategoryIsAddition ? true : null}
                    items={items}
                    onSelectItem={() => {}}
                    emptyContent={emptyItemsContent}
                />
            );
        }
    }

    return (
        <div className="menu-browser">
            {message && <div className="menu-browser-message">{message}</div>}
            <div className="menu-browser-content" style={{ display: loading ? 'none' : null }}>
                {parentCategory && (
                    <MenuCategories
                        categories={[{ ...parentCategory, icon: 'ion-ios-arrow-round-back' }]}
                        onSelectCategory={() => onCategoryClick(parentCategory.id)}
                    />
                )}
                {categoriesEl}
                {itemsEl}
            </div>
            {loading && <Spinner />}
        </div>
    );
};

_.assign(MenuBrowser, { DisplayCategories: MenuBrowserDisplayCategories });

export default MenuBrowser;
