import React from 'react';

import { Button } from 'components/UI/Button/Button';
import InputGroup, { RadioInput, TextareaInput, TextInput, TextInputGroup } from 'components/UI/InputGroup/InputGroup';
import './MenuItemEditor.scss';
import '../MenuItemDetail/MenuItemMarker.scss';
import TagsInput from 'components/UI/TagsInput/TagsInput';
import MenuItemParamInput from 'components/menu/MenuItemEditor/MenuItemParamInput';
import MenuItemPropertyInput from 'components/menu/MenuItemEditor/MenuItemPropertyInput';
import { ImageInputWithUpload } from 'components/UI/ImageInput/ImageInput';
import MenuRemovableCategories from 'components/menu/MenuCategories/MenuRemovableCategories';
import MenuItemMarker from 'components/menu/MenuItemDetail/MenuItemMarker';
import PriceInput from '../../UI/PriceInput/PriceInput';

export const MenuItemEditorCategories = (props) => {
    const { categories, onSelectCategory, onRemoveCategory, categoriesEditHandler } = props;

    return (
        <div className="categories">
            <p className="title">Categories</p>
            <MenuRemovableCategories
                categories={categories}
                onSelectCategory={onSelectCategory}
                onRemoveCategory={onRemoveCategory}
            />
            <Button className="btn-medium-wide" wrapChildren={false} onClick={categoriesEditHandler}>
                Choose categories
            </Button>
        </div>
    );
};

export const MenuItemEditorAdditions = (props) => {
    const { additions, onSelectAddition, onRemoveAddition, additionsEditHandler } = props;

    return (
        <div className="additions">
            <p className="title">Categories of addition items</p>
            <MenuRemovableCategories
                categories={additions}
                onSelectCategory={onSelectAddition}
                onRemoveCategory={onRemoveAddition}
            />
            <Button className="btn-medium-wide" wrapChildren={false} onClick={additionsEditHandler}>
                Choose add-on categories
            </Button>
        </div>
    );
};

export const MenuItemEditorIngredients = (props) => {
    const { ingredients, setIngredients } = props;

    return (
        <div className="ingredients">
            <p className="title">Ingredients</p>
            <TagsInput
                tags={ingredients}
                setTags={setIngredients}
                addHashPrefix={false}
                placeholder="Input ingredient"
            />
        </div>
    );
};

export const MenuItemEditorMarkers = (props) => {
    const { markers, setMarker } = props;

    return (
        markers && (
            <div className="markers">
                {markers.map((marker) => {
                    const options = [
                        { id: marker.key, label: 'Yes', value: 1, checked: marker.value === 1 },
                        { id: marker.key, label: 'No', value: 0, checked: marker.value === 0 },
                    ];
                    const onClick = (e) => setMarker(marker.key, e.currentTarget.value);
                    const onChange = () => {};

                    return (
                        <InputGroup key={marker.key}>
                            <div className="children">
                                <MenuItemMarker type={marker.key} caption={marker.text} />
                            </div>
                            <RadioInput {...{ id: marker.key, options, onClick, onChange }} />
                        </InputGroup>
                    );
                })}
            </div>
        )
    );
};

export const MenuItemEditorParams = (props) => {
    const { params, setParam, errors } = props;

    return (
        params && (
            <div className="params">
                {params.map((param) => (
                    <MenuItemParamInput
                        key={param.key}
                        id={param.key}
                        error={errors[param.keyByServer]}
                        {...param}
                        onChangeUnit={(unit) => setParam({ ...param, unit })}
                        onChange={(value) => setParam({ ...param, value })}
                    />
                ))}
            </div>
        )
    );
};

export const MenuItemEditorProperties = (props) => {
    const { properties, errors, setProperties, addProperty } = props;

    return (
        <div className="properties">
            {errors.properties && <p className="error">{errors.properties}</p>}
            <p className="title">Properties</p>
            {properties.map((p, i) => (
                <MenuItemPropertyInput key={i} {...p} onChange={(property) => setProperties(i, property)} />
            ))}
            <Button className="btn-medium-wide" wrapChildren={false} onClick={addProperty}>
                Add property
            </Button>
        </div>
    );
};

const MenuItemEditor = (props) => {
    const {
        photo,
        photo_thumb,
        name,
        description,
        categories = [],
        additions = [],
        ingredients = [],
        properties = [],
        price,
        price_addition,
        currency,
        params = [],
        markers = [],
        setItemParamByKey,
        loadingPhoto,
        categoriesEditHandler,
        additionsEditHandler,
        setPhoto,
        setLoadingPhoto,
        imageUploadType = 'mi',
        errors,
        setErrors,
    } = props;

    const setName = (e) => setItemParamByKey('name', e.currentTarget.value);
    const setDescriptions = (e) => setItemParamByKey('description', e.currentTarget.value);
    const setPrice = (newPrice) => setItemParamByKey('price', newPrice);
    const setPriceAddition = (newPriceAdd) => setItemParamByKey('price_addition', newPriceAdd);
    const setIngredients = (newIngredients) => setItemParamByKey('ingredients', newIngredients);
    const addProperty = () => setItemParamByKey('properties', [...properties, { name: '', options: ['', ''] }]);
    const setParam = (param) => {
        const paramErrors = {};
        const newParams = params.map((p) => {
            if (p.key === param.key) {
                _.extend(paramErrors, { [param.keyByServer]: null });
                return param;
            }
            return p;
        });

        setItemParamByKey('params', newParams);
        setErrors((prev) => ({ ...prev, ...paramErrors }));
    };

    const setMarker = (key, value) => {
        const val = Number.parseInt(value);
        setItemParamByKey(
            'markers',
            markers.map((m) =>
                m.key !== key
                    ? m
                    : {
                        ...m,
                        value: val !== m.value ? val : null,
                    }
            )
        );
    };
    const setProperties = (index, value) => {
        const updatedProperties = properties.map((p, i) => (i === index ? value : p)).filter((p) => p);
        setItemParamByKey('properties', updatedProperties);
    };

    const onSelectCategory = (id) => {
        console.log('onSelectCategory id:', id);
    };
    const onRemoveCategory = (id) => {
        setItemParamByKey(
            'categories',
            categories.filter((p) => p.id !== id)
        );
    };
    const onSelectAddition = (id) => {
        console.log('onSelectAddition id:', id);
    };
    const onRemoveAddition = (id) => {
        setItemParamByKey(
            'additions',
            additions.filter((p) => p.id !== id)
        );
    };

    return (
        <div className="menu-item-editor-content">
            <ImageInputWithUpload
                photo={photo}
                photo_thumb={photo_thumb}
                setLoadingPhoto={setLoadingPhoto}
                type={imageUploadType}
                onChange={setPhoto}
                loadingPhoto={loadingPhoto}
            />
            <TextInputGroup error={errors.name} label="Name">
                <TextInput value={name} placeholder="Enter name" onChange={setName} />
            </TextInputGroup>
            <TextInputGroup error={errors.description} label="Description" className="input-group description">
                <TextareaInput value={description} placeholder="Enter description" onChange={setDescriptions} />
            </TextInputGroup>

            <PriceInput label="Price" error={errors.price} currency={currency} value={price} onChange={setPrice} />
            <PriceInput
                label="Price as addition"
                error={errors.price_addition}
                currency={currency}
                value={price_addition}
                onChange={setPriceAddition}
            />
            <MenuItemEditorCategories
                categories={categories}
                categoriesEditHandler={categoriesEditHandler}
                onSelectCategory={onSelectCategory}
                onRemoveCategory={onRemoveCategory}
            />
            <MenuItemEditorProperties
                properties={properties}
                errors={errors}
                addProperty={addProperty}
                setProperties={setProperties}
            />
            <MenuItemEditorAdditions
                additions={additions}
                additionsEditHandler={additionsEditHandler}
                onSelectAddition={onSelectAddition}
                onRemoveAddition={onRemoveAddition}
            />
            <MenuItemEditorIngredients ingredients={ingredients} setIngredients={setIngredients} />
            <MenuItemEditorMarkers markers={markers} setMarker={setMarker} />
            <MenuItemEditorParams params={params} errors={errors} setParam={setParam} />
        </div>
    );
};

export default MenuItemEditor;
