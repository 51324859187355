export const INIT_FROM_OFFLINE_STORAGE  = '[App] Init From Offline Storage';
export const LOGOUT                     = '[App] Log Out';

export const SET_LOGINED_USER                       = '[App] Set Logined User';
export const SET_LOGINED_USER_WORKPLACES            = '[App] Set Logined User Workplaces';
export const SET_LOGINED_USER_WORKPLACES_ROLES      = '[App] Set Logined User Workplaces Roles';
export const SET_LOGINED_USER_WORKPLACE_ROLES       = '[App] Set Logined User Workplace Roles';
export const SET_LOGINED_USER_DEFAULT_WORKPLACE_ID  = '[App] Set Logined User Default Workplace Id';

export const SET_LOGINED_USER_WORKPLACES_STATUSES   = '[App] Set Logined User Workplaces Statuses';
export const SET_LOGINED_USER_WORKPLACE_STATUS      = '[App] Set Logined User Workplace Status';

export const ADD_NOTIFICATIONS = '[App] Add Notifications';

export const initFromOfflineStorage = (loginedUser, workPlaces = null, workPlacesRoles = null, defaultWorkPlaceId = null, workPlacesStatuses = null) => ({
    type: INIT_FROM_OFFLINE_STORAGE,
    payload: {
        loginedUser,
        workPlaces,
        workPlacesRoles,
        defaultWorkPlaceId: defaultWorkPlaceId && Number(defaultWorkPlaceId),
        workPlacesStatuses
    }
});

export const logout = () => ({ type: LOGOUT });

export const setLoginedUser = (loginedUser = null) => ({
    type: SET_LOGINED_USER,
    payload: {
        loginedUser
    }
});

export const setLoginedUserWorkplaces = (workPlaces, workPlacesRoles) => ({
    type: SET_LOGINED_USER_WORKPLACES,
    payload: {
        workPlaces,
        workPlacesRoles
    }
});

export const setLoginedUserWorkplacesRoles = workPlacesRoles => ({
    type: SET_LOGINED_USER_WORKPLACES_ROLES,
    payload: {
        workPlacesRoles
    }
});

export const setLoginedUserWorkplaceRoles = (placeId, roles) => ({
    type: SET_LOGINED_USER_WORKPLACE_ROLES,
    payload: {
        placeId: Number(placeId),
        roles
    }
});

export const setLoginedUserDefaultWorkplaceId = placeId => ({
    type: SET_LOGINED_USER_DEFAULT_WORKPLACE_ID,
    payload: {
        placeId: placeId && Number(placeId)
    }
});

export const setLoginedUserWorkplacesStatuses = workPlacesStatuses => ({
    type: SET_LOGINED_USER_WORKPLACE_STATUS,
    payload: {
        workPlacesStatuses
    }
});

export const setLoginedUserWorkplaceStatus = (placeId, status) => ({
    type: SET_LOGINED_USER_WORKPLACE_STATUS,
    payload: {
        placeId: placeId && Number(placeId),
        status: Number(status)
    }
});

export const addNotifications = (notifications) => ({
    type: ADD_NOTIFICATIONS,
    payload: {
        notifications
    }
});
