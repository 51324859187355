import QrCodeModel from "models/QrCodeModel";
import settings from 'settings';

/**
 * Initialize QR code scan
 *
 * @param {Object} [options] Refer to: https://github.com/phonegap/phonegap-plugin-barcodescanner
 *
 * @returns {Promise}
 */

function qrCodeScanner(options) {
///////////////////
    return Promise.reject('This feature is temporarily unavailable.');
//////////////

/////////////////
////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
/////
//////////////

    options = _.defaults(options || (options = {}), {
        promt: "Place a barcode inside the viewfinder rectangle to scan it.",
        rawResult: false,
        showCancelButton: false,
        cancelButtonText: "Cancel",
        dontCloseOnBack: false,
    });

    return new Promise((resolve, reject) => {
        const interceptor = setBackbuttonInterception();
        cordova.plugins.barcodeScanner.scan(
            (result) => {
                if (!result.cancelled) {
                    try {
                        if (options.rawResult) {
                            resolve(result.text);
                        } else {
                            resolve(new QrCodeModel({ code: result.text }));
                        }
                    } catch (e) {
                        reject(e);
                    }
                } else {
                    resolve();
                }

                _.delay(() => removeBackbuttonInterceptor(interceptor), 1000);
            },
            (error) => {
                removeBackbuttonInterceptor(interceptor);
                reject(new Error('Unable to scan QR code: ' + JSON.stringify(error)));
            },
            options
        );
    });
};

export default qrCodeScanner;

function setBackbuttonInterception() {
    const interceptor = e => {
        e.preventDefault();
        e.stopPropagation();
        removeBackbuttonInterceptor(interceptor);
    };

    window.addEventListener(settings.backbutton.eventName, interceptor, true);

    return interceptor;
}

function removeBackbuttonInterceptor(interceptor) {
    window.removeEventListener(settings.backbutton.eventName, interceptor, true);
}
