import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const defaultParser = ({
    value,
    nullable,
    min = Number.MIN_SAFE_INTEGER,
    max = Number.MAX_SAFE_INTEGER,
    isInteger,
}) => {
    if (nullable && value === '') {
        return null;
    }
    const result = Math.max(min, Math.min(value, max));
    return isInteger ? parseInt(result) : parseFloat(result);
};

const defaultDisplayParser = (value) => {
    return _.isNaN(parseFloat(value)) ? '' : value;
};

const isNumberKeyValue = (keyValue, isInteger) => {
    const reg = new RegExp(isInteger ? /[0-9]/ : /[0-9]|,|\./);
    return reg.test(keyValue);
};

const NumericInput = (props) => {
    const { value, onChange, disabled, className, placeholder, autosize = false, type = 'number' } = props;
    const {
        onlyPositiveNumbers = true,
        nullable = true,
        isInteger = false,
        step,
        max,
        min = onlyPositiveNumbers ? 0 : Number.MIN_SAFE_INTEGER,
    } = props;
    const { parser, displayParser = defaultDisplayParser } = props;

    const classNameVal = ['numeric-input', className, disabled && 'disabled'].filter(Boolean).join(' ');

    const onKeyPress = useCallback(
        (e) => {
            const keyCode = e.keyCode || e.which;
            const keyValue = String.fromCharCode(keyCode);

            if (onlyPositiveNumbers && !isNumberKeyValue(keyValue, isInteger)) {
                e.preventDefault();
            }
        },
        [isNumberKeyValue, onlyPositiveNumbers, isInteger]
    );

    const onChangeHandler = useCallback(
        (e) => {
            const { value } = e.currentTarget;
            const parsedVal = parser ? parser(value) : defaultParser({ value, min, max, nullable, isInteger });

            onChange(parsedVal);
        },
        [min, max, nullable, isInteger, defaultParser, onChange, parser]
    );

    const inputOptions = {
        className: classNameVal,
        value: displayParser(value),
        disabled,
        placeholder,
        type,
        onKeyPress,
        onChange: onChangeHandler,
    };

    if (!nullable && (_.isNumber(min) || onlyPositiveNumbers)) {
        inputOptions.min = _.isNumber(min) ? min : 0;
    }

    if (_.isNumber(max)) {
        inputOptions.max = max;
    }

    if (step) {
        inputOptions.step = step;
    }

    if (autosize) {
        _.extend(inputOptions, {
            style: {
                width: `${inputOptions.value ? inputOptions.value.toString().length : '1'}em`,
            },
        });
    }

    return <input data-role="none" {...inputOptions} />;
};

NumericInput.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    parser: PropTypes.func,
    displayParser: PropTypes.func,
    placeholder: PropTypes.string,
    autosize: PropTypes.bool,
    type: PropTypes.string,
    onlyPositiveNumbers: PropTypes.bool,
    step: PropTypes.any,
    min: PropTypes.any,
    max: PropTypes.any,
    nullable: PropTypes.bool,
    isInteger: PropTypes.bool,
};

export default NumericInput;
