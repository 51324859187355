import {
    LOAD_CATEGORY,
    INIT_MENU,
    INIT_MENU_SUCCEEDED,
    SAVE_MENU_INFO_SUCCEEDED,
    INIT_MENU_FAILED, SAVE_MENU_INFO
} from "../actions";
import { RESET_MENU_INFO, UPDATE_MENU_INFO, UPDATE_MENU_PERMISSIONS } from "store/menu/info/actions";
import getMenuPermissions from "utils/getMenuPermissions";

const initialState = {
    isMenuManager: false,
    isOrderManager: false,
    isWaiter: false,
    isLoggedIn: false,

    placeId: null,
    currency: null,
    isFood: false,
    waiters: {},
    initialized: false,

    loading: false,
    isReadyToInitialize: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case INIT_MENU:
            const { isMenuManager, isOrderManager, isWaiter, isLoggedIn } = action.payload;

            return {
                ...state,
                placeId: action.payload.placeId,
                isMenuManager, isOrderManager, isWaiter, isLoggedIn,
                initialized: false,
                loading: true,
            };

        case SAVE_MENU_INFO:
            return {
                ...state,
                loading: true,
            };

        case INIT_MENU_SUCCEEDED:
            return {
                ...state,
                initialized: true,
                currency: action.payload.currency,
                isFood: action.payload.isFood,
                waiters: action.payload.waiters || {},
                loading: false,
            };

        case SAVE_MENU_INFO_SUCCEEDED:
            return {
                ...state,
                currency: action.payload.currency,
                isFood: action.payload.isFood,
                loading: false,
            };

        case INIT_MENU_FAILED:
            return {
                ...state,
                isEditPossible: false,
                isMenuManager: false, isOrderManager: false, isWaiter: false,
                waiters: {},
                initialized: false,
                loading: false
            };

        case LOAD_CATEGORY:
            const { placeId: newPlaceId = null } = action.payload;
            return newPlaceId !== null ? { ...state, placeId: newPlaceId } : state;

        case UPDATE_MENU_INFO:
            const { props = {} } = action.payload;

            return {
                ...state,
                ...props
            };

        case UPDATE_MENU_PERMISSIONS:
            const { permissions = getMenuPermissions() } = action.payload;

            return {
                ...state,
                ...permissions
            };

        case RESET_MENU_INFO:
            const { info = {} } = action.payload;

            return {
                ...initialState,
                ...info
            };

        default:
            return state
    }
}
