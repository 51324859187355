export const UPDATE_MENU_INFO = '[Menu] Update menu info';
export const RESET_MENU_INFO = '[Menu] Reset menu info';
export const UPDATE_MENU_PERMISSIONS = '[Menu] Update menu permissions'

export const updateMenuInfo = (props) => ({
    type: UPDATE_MENU_INFO,
    payload: {
        props
    }
});

export const resetMenuInfo = (info) => ({
    type: RESET_MENU_INFO,
    payload: {
        info
    }
});

export const updateMenuPermissions = (permissions) => ({ type: UPDATE_MENU_PERMISSIONS, payload: { permissions } });