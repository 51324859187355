import NotificationModel, { getNotificationCategoryName } from "models/NotificationModel";

export const getFilteredNotificationsByCategory = (notifications, category) => {
    return notifications.filter(n => {
        const categoryName = getNotificationCategoryName(n['app_event'], n['template_type']);
        return categoryName === category;
    });
}

export const getMenuOrderNotificationsFromNotifications = (notifications = []) => {
    return getFilteredNotificationsByCategory(notifications, NotificationModel.Category.MenuOrder);
};

export const getMenuWaiterCallNotifications = (notifications = []) => {
    return getFilteredNotificationsByCategory(notifications, NotificationModel.Category.MenuWaiterNotify);
};
