import {Model, ViewModel} from '@b2cmessenger/backbone';
import ReactView from 'widgets/ReactView';
import Modal from "windows/Modal/Modal";
import MenuVisibilityEditorContent from "components/menu/MenuVisibility/MenuVisibilityEditorContent";
import './MenuVisibilityEditor.scss';
import * as MenuAPI from 'utils/api/menu';
import InfoModalWindow from "windows/Modal/Info";

const MenuVisibilityEditorWindow = Modal.extend({
    options: {
        placeId: null,
        item: null,
        category: null
    },

    windowName: "menu-visibility-editor-modal-window",
    className: "modal-window info-modal-window menu-visibility-editor-modal-window",

    regions: {
        header: '[data-js-header]',
        content: '[data-js-content]',
        footer: '[data-js-footer]'
    },

    initialize() {
        const ret = Modal.prototype.initialize.call(this, arguments);

        this.viewModel = new ViewModel;

        const modelPayload = { item: null, category: null, value: 0 };
        const hasCategory = _.isObject(this.options.category);
        const hasItem = _.isObject(this.options.item);

        if (hasItem || hasCategory) {
            const payload = _.pick(this.options.category || this.options.item,
                ['id', 'name', 'is_addition', 'is_hidden_forever', 'hidden_until', 'photo', 'photo_thumb']);

            this.model = new Model({
                ...modelPayload,
                ...(hasItem ? { item: payload } : { category: payload })
            });
        } else {
            this.model = new Model({ ...modelPayload });
        }

        _.extend(this.options, {
            buttons: [{ id: "close", text: "CLOSE", icn: "empty", value: false },
                {
                    id: "hide",
                    text: "HIDE",
                    icn: "empty",
                    onClick: async () => {
                        this.showLoading();

                        let promise;
                        const period = this.model.get('value');

                        if (hasItem) {
                            promise = MenuAPI.hideItem({ placeId: this.options.placeId, id: this.options.item.id, period });
                        } else if (hasCategory) {
                            promise = MenuAPI.hideCategory({ placeId: this.options.placeId, id: this.options.category.id, period });
                        } else {
                            promise = Promise.resolve();
                        }

                        promise
                            .then(response => {
                                this.hideLoading();
                                if (response) {
                                    this.close(response);
                                } else {
                                    this.showError('Something went wrong, try again later');
                                }
                            })
                            .catch(e => {
                                this.hideLoading();
                                this.showError(e);
                            })
                    }
                }
            ],
            content: new ReactView({
                className: 'menu-visibility-editor',
                component: MenuVisibilityEditorContent,
                model: this.model,
                props: {
                    onPeriodChange: value => {
                        this.model.set({ value });
                    }
                },
                mountEvent: 'onRender'
            })
        });

        return ret;
    },
});

const MenuVisibilityRemoveWindow = InfoModalWindow.extend({
    options: {
        placeId: null,
        item: null,
        category: null
    },

    windowName: "menu-visibility-remove-modal-window",
    className: "modal-window info-modal-window menu-visibility-remove-modal-window intent-negative",

    regions: {
        header: '[data-js-header]',
        content: '[data-js-content]',
        footer: '[data-js-footer]'
    },

    bindings: {},

    initialize() {
        const hasCategory = _.isObject(this.options.category);
        const hasItem = _.isObject(this.options.item);
        const obj = this.options.category || this.options.item;

        const hiddenUntilDate = obj.hidden_until &&
            B2Cjs.datetimeServerToJS(obj.hidden_until).toLocaleString('en-US', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }) || null;

        const hidden = hiddenUntilDate ? `until ${hiddenUntilDate}`: 'forever';
        const type = hasCategory ? 'Category' : 'Item';

        _.extend(this.options, {
            title: `Do you want to remove hide from ${type.toLowerCase()}?`,
            text: `<p>${type} <em>${obj.name}</em> is hidden <em>${hidden}</em></p>`,
            isHtml: true,
            intent: InfoModalWindow.Intent.Negative,
            buttons: [{ id: "close", text: "NO", icn: "empty", value: false },
                {
                    id: "yes",
                    text: "YES",
                    icn: "empty",
                    value: true
                }
            ],
        });

        return InfoModalWindow.prototype.initialize.call(this, arguments);
    },

    close(value) {
        if (value) {
            this.showLoading();

            let promise;

            if (this.options.item) {
                promise = MenuAPI.removeHideFromItem({ placeId: this.options.placeId, id: this.options.item.id });
            } else if (this.options.category) {
                promise = MenuAPI.removeHideFromCategory({ placeId: this.options.placeId, id: this.options.category.id });
            } else {
                promise = Promise.resolve();
            }

            return promise
                .then(response => {
                    this.hideLoading();
                    if (response) {
                        return InfoModalWindow.prototype.close.call(this, response);
                    } else {
                        this.showError('Something went wrong, try again later');
                    }
                })
                .catch(e => {
                    this.hideLoading();
                    this.showError(e);
                })
        }

        return InfoModalWindow.prototype.close.call(this, value);
    }
});

export { MenuVisibilityRemoveWindow };

export default MenuVisibilityEditorWindow;
