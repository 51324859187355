import { Model } from '@b2cmessenger/backbone';
import settings from 'settings';

const Status = {
    Unknown: -1,
    Draft: 0,
    Scheduled: 1,
    InProgress: 2,
    Done: 3
};

const Gender = {
    Male: 'male',
    Female: 'female',
    Other: 'other'
};

const RepeatPeriods = {
    Once: 'once',
    Day: 'daily',
    Week: 'weekly',
    Month: 'monthly',
    Year: 'yearly'
};

/**@type {(properties: Model.Properties<TaskTargetModel>) => (target: any) => typeof TaskTargetModel} */
//@ts-ignore
const properties = Model.properties;

@properties({
    urlRoot: settings.host + settings.serv_task.target,

    defaults: {
        _status: Status.Unknown,
        _updated_by: null,
        _users: _.create(null),
        place_id: null,
        title: null,
        age_min: null,
        age_max: null,
        gender: null,
        days_before_birthday: null,
        distance: null,
        client_knowledge: null,
        interest_categories: null,
        interest_strength: null,
        client_limit: null,
        dateStart: null,
        dateEnd: null,
        sendHour: null,
        sendMinute: null,
        task_id: null,
        paused: 0,
    },

    computeds: {
        'status': {
            deps: ['_status'],
            get: _status => _status,
            set(val) {
                let status = _.find(Status, s => s == val);
                if (_.isUndefined(status)) {
                    status = Status.Unknown
                }

                return _.create(null, {
                    _status: status
                });
            }
        },
        'canBeChanged': {
            deps: ['status'],
            get: status => status != Status.Done
        },
        'updated_by': {
            deps: ['_updated_by', 'created_by'],
            get: (_updated_by, created_by) => _updated_by || created_by,
            set(val) {
                return { _updated_by: val };
            }
        },
        'updatedAt': {
            deps: ['updated_at'],
            get: updated_at => {
                if (updated_at) {
                    if (_.isObject(updated_at) && updated_at.expression == "NOW()") {
                        return new Date;
                    } else {
                        const date = B2Cjs.datetimeServerToJS(updated_at);
                        if (date) {
                            return date;
                        }
                    }
                }

                return null;
            }
        },
        'nextSendAt': {
            deps: ['next_send_time'],
            get: next_send_time => next_send_time && B2Cjs.datetimeServerToJS(next_send_time)
        },
        'lastSendAt': {
            deps: ['last_send_time'],
            get: last_send_time => last_send_time && B2Cjs.datetimeServerToJS(last_send_time)
        },
        'createdBy': {
            deps: ['created_by', 'users'],
            get: (created_by, users) => created_by && users && users[created_by]
        },
        'updatedBy': {
            deps: ['updated_by', 'users'],
            get: (updated_by, users) => updated_by && users && users[updated_by]
        },
        'taskCreatedBy': {
            deps: ['task', 'users'],
            get: (task, users) => task && task.user_id && users && users[task.user_id]
        },
        'gender': {
            deps: ['_gender'],
            get: _gender => {
                if (_.isArray(_gender)) {
                    const genders = _.intersection(_gender, _.values(Gender));
                    if (genders.length) {
                        return genders;
                    }
                }
                return _.values(Gender);
            },
            set(val) {
                if (_.isArray(val)) {
                    const genders = _.intersection(val, _.values(Gender));
                    if (genders.length) {
                        return _.create(null, {
                            _gender: genders
                        });
                    }
                } else if (_.isString(val) && _(Gender).contains(val)) {
                    return _.create(null, {
                        _gender: [val]
                    });
                }

                return _.create(null, {
                    _gender: _.values(Gender)
                });
            }
        },
        'days_before_birthday': {
            deps: ['_days_before_birthday'],
            get: _days => !_.isNull(_days) ? _days : null,
            set: val => _.create(null, {
                _days_before_birthday: !_.isNull(val) && val >= 0 && val <= 99 ?
                    Number(val) : null
            })
        },
        'distance': {
            deps: ['_distance'],
            get: _distance => !_.isNull(_distance) && _distance ? _distance : null,
            set: val => _.create(null, {
                _distance: !_.isNull(val) && val > 0 && val <= 25 ?
                    Number(val) : null
            })
        },
        'client_knowledge': {
            deps: ['_client_knowledge'],
            get: _client_knowledge => _client_knowledge,
            set: val => _.create(null, {
                _client_knowledge: !!val
            })
        },
        'interest_categories': {
            deps: ['_interest_categories'],
            get: _interest_categories => _interest_categories,
            set: val => _.create(null, {
                _interest_categories: val ? _.isArray(val) ? val : val.pluck ? val.pluck('id') : [] : []
            })

        },
        'repeat': {
            deps: ['_repeat'],
            get: _repeat => _repeat &&
                _(RepeatPeriods).contains(_repeat) && _repeat || RepeatPeriods.Once,
            set: val => _.create(null, {
                _repeat: val && _(RepeatPeriods).contains(val) && val || RepeatPeriods.Once
            })

        },
        'isRepeat': {
            deps: ['repeat'],
            get: repeat => repeat && repeat != RepeatPeriods.Once
        },
        'date_start': {
            deps: ['dateStart'],
            get: dateStart => _.isDate(dateStart) && B2Cjs.datetimeJSToServerLocal(dateStart, true) || null,
            set: val => _.create(null, { dateStart: val && B2Cjs.datetimeServerLocalToJS(val) || null })
        },
        'date_end': {
            deps: ['dateEnd', 'repeat'],
            get: (dateEnd, repeat) => (repeat && repeat != 'once') && _.isDate(dateEnd) && B2Cjs.datetimeJSToServerLocal(dateEnd, true) || null,
            set: val => _.create(null, { dateEnd: val && B2Cjs.datetimeServerLocalToJS(val) || null })
        },
        'sendHour': {
            deps: ['_sendHour', 'repeat'],
            get: (_sendHour, repeat) => {
                if (!_.isNull(_sendHour)) {
                    return _sendHour;
                } else {
                    if (!repeat || repeat == 'once') {
                        return null;
                    } else {
                        return new Date().getHours();
                    }
                }
            },
            set: val => {
                if (!_.isNull(val)) {
                    const nval = Number(val);
                    if (!_.isNaN(nval)) {
                        return { _sendHour: Math.max(0, Math.min(Math.round(nval), 23)) };
                    }
                }

                return { _sendHour: null };
            }
        },
        'sendMinute': {
            deps: ['_sendMinute', 'repeat'],
            get: (_sendMinute, repeat) => {
                if (!_.isNull(_sendMinute)) {
                    return _sendMinute;
                } else {
                    if (!repeat || repeat == 'once') {
                        return null;
                    } else {
                        return new Date().getMinutes();
                    }
                }
            },
            set: val => {
                if (!_.isNull(val)) {
                    const nval = Number(val);
                    if (!_.isNaN(nval)) {
                        return { _sendMinute: Math.max(0, Math.min(Math.round(nval), 59)) };
                    }
                }

                return { _sendMinute: null };
            }
        },
        'send_time_hour': {
            deps: ['sendHour'],
            get: sendHour => sendHour,
            set: val => _.create(null, { sendHour: val })
        },
        'send_time_minute': {
            deps: ['sendMinute'],
            get: sendMinute => sendMinute,
            set: val => _.create(null, { sendMinute: val })
        },
        'hasUnsavedChanges': {
            deps: ['_hasUnsavedChanges'],
            get: _hasUnsavedChanges => !!_hasUnsavedChanges
        },
        'data': {
            deps: [
                'paused',
                'age_max', 'age_min', 'client_limit', 'dateEnd', 'dateStart', 'interest_strength',
                'place_id', 'title', '_client_knowledge', '_days_before_birthday', '_distance', '_gender',
                '_interest_categories', '_repeat', '_sendHour', '_sendMinute'
            ],
            get: (
                paused,
                age_max, age_min, client_limit, dateEnd, dateStart, interest_strength,
                place_id, title, _client_knowledge, _days_before_birthday, _distance, _gender,
                _interest_categories, _repeat, _sendHour, _sendMinute
            ) => _.create(null, {
                paused,
                age_max, age_min, client_limit, dateEnd, dateStart, interest_strength,
                place_id, title, _client_knowledge, _days_before_birthday, _distance, _gender,
                _interest_categories, _repeat, _sendHour, _sendMinute
            })
        },
        'users': {
            deps: ['_users'],
            get: _users => _users,
            set(val) {
                if (_.isArray(val)) {
                    return _.create(null, {
                        _users: _.reduce(val, (users, user) => {
                            if (user.id) {
                                users[user.id] = user;
                            }
                            return users;
                        }, {})
                    });
                } else if (_.isObject(val)) {
                    return _.create(null, {
                        _users: _.reduce(val, (users, user, id) => {
                            if (user.id == id) {
                                users[user.id] = user;
                            }
                            return users;
                        }, {})
                    });
                } else {
                    return _.create(null, { _users: _.create(null) });
                }
            }
        },
        'paused': {
            deps: ['_paused'],
            get: _paused => _paused,
            set: val => _.create(null, { _paused: val ? 1 : 0 })
        }
    },
})
//@ts-ignore
class TaskTargetModel extends Model {
    initialize() {
        this.listenTo(this, 'change:data', () => this.set({ _hasUnsavedChanges: true }));
    }

    clone(options) {
        _.defaults(options || (options = {}), {
            onlySettings: false
        });

        if (options.onlySettings) {
            const attributes = this.get('data');
            
            attributes.title = 'Copy of ' + (attributes.title ? attributes.title : '#' + this.id);
            attributes._hasUnsavedChanges = true;

            //@ts-ignore
            return new this.constructor(attributes);
        } else {
            return Model.prototype.clone.apply(this, arguments);
        }
    }

    sync(method, model, options) {
        _.defaults(options || (options = {}), {
            computed: true,
            prepareData: options.attrs ? false : true,
        });

        if (method == 'read' && options.includeTask) {
            _.extend(options.data || (options.data = {}), {
                include_task: true
            });
        }

        if (method == 'read' && options.includeUsers) {
            _.extend(options.data || (options.data = {}), {
                include_users: true
            });
        }

        const error = options.error,
              success = options.success,
              that = this;

        options.success = function (data, textStatus, jqXHR) {
            if (success) success.call(this, data, textStatus, jqXHR);

            that.set({ _hasUnsavedChanges: false });
        };

        options.error = function (jqXHR, textStatus, errorThrown) {
            that.set({ recipient_count: null });

            if (jqXHR.status == 422) {
                that._onServerResponse422(jqXHR, textStatus, errorThrown)
            }

            if (error) error.call(this, jqXHR, textStatus, errorThrown);
        };

        if (options.data == null && model && (method === 'create' || method === 'update' || method === 'patch')) {
            _.defaults(options, {
                contentType: 'application/json',
                data: JSON.stringify(
                    options.prepareData ?
                        model._prepareData(options.attrs || _.extend(model.toJSON(options), model.attributes), options) :
                        options.attrs || model.toJSON(options)
                )
            });
        }

        return super.sync.apply(model, arguments);
    }

    validate(_attrs, options) {
        _.defaults(options || (options = {}), {
            context: this,
            validateOnServer: false,
        });

        if (options.validateOnServer) {
            _.defaults(options, {
                computed: true,
                omitNulls: true,
            });
        }

        const attrs = _.extend({}, _attrs || options.attrs || this.toJSON(options));

        if (!attrs.place_id) {
            this.trigger('invalid:place_id', this, 'place_id is not defined!');
            return [{ field: 'place_id', message: 'place_id is not defined!' }];
        }

        if (options.validateOnServer) {
            const success = options.success,
                error = options.error;

            options.success = data => {
                if (data.recipient_count) {
                    this.set({ recipient_count: Number(data.recipient_count) || 0 });
                }

                if (success) success.call(options.context, this, data, options);
            };

            options.error = (jqXHR, textStatus, errorThrown) => {
                this.set({ recipient_count: null });

                if (jqXHR.status == 422) {
                    this._onServerResponse422(jqXHR, textStatus, errorThrown)
                }

                if (error) error.call(options.context, jqXHR, textStatus, errorThrown);
            };

            const urlRoot = _.result(this, 'urlRoot') || _.result(this.collection, 'url');
            if (!urlRoot) {
                throw new Error('A "url" property or function must be specified')
            }

            app.ajax(
                _.extend(
                    {
                        url: urlRoot + '/recipient/count',
                        type: 'GET',
                        contentType: 'application/json',
                        data: options.data || this._prepareData(attrs, options)
                    },
                    options
                )
            )
        }
    }

    _validate(attrs, options) {
        if (!options.validate || !this.validate) return true;

        if (options.validateOnServer) {
            _.defaults(options, {
                computed: true,
                omitNulls: true,
            });
        }

        attrs = _.extend({}, this.toJSON(options), attrs);
        const error = this.validationError = this.validate(attrs, options) || null;
        if (!error) return true;
        this.trigger('invalid', this, error, _.extend(options, { validationError: error }));
        return false;
    }

    _prepareData(data, options) {
        _.defaults(options || (options = {}), {
            omitNulls: false,
            omitId: false,
        });

        return _(data)
            .chain()
            .omit((v, k) => String(k)[0] == '_')
            .omit(options.omitId ? 'id' : undefined)
            .pick(
                'paused', 'title', 'place_id', 'task_id',
                'age_min', 'age_max', 'gender', 'days_before_birthday',
                'distance', 'interest_categories', 'interest_strength', 'client_knowledge',
                'client_limit', 'date_start', 'date_end', 'repeat', 'send_time_hour', 'send_time_minute'
            )
            //.omit(
            //    'status', 'recipient_count', 'dateStart', 'dateEnd', 'sendHour', 'created_at',
            //    'created_by', 'updated_by', 'updated_at', 'updatedAt', 'isRepeat', 'hasUnsavedChanges',
            //    'data', 'last_send_time', 'next_send_time'
            //)
            .pick((v, k) => {
                if (_.isUndefined(v) || _.isNaN(v)) {
                    return false;
                }

                switch (k) {
                    case 'interest_categories': if (!_.isArray(v) || !v.length) v = null;
                    case 'client_knowledge': if (!v) v = null;
                    default:
                }
                
                if (options.omitNulls && _.isNull(v)) {
                    return false;
                }

                return true;
            })
            .reduce((data, v, k) => {
                switch (String(k)) {
                    case 'gender':
                        const genders = _.intersection(v, _.values(Gender));
                        if (genders.length > 0 && genders.length < _.size(Gender)) {
                            data[k] = genders;
                        } else if (!options.omitNulls) {
                            data[k] = null;
                        }
                        break;
                    case 'client_knowledge':
                        data[k] = v ? 1 : 0;
                        break;
                    default:
                        data[k] = v;
                }

                return data;
            }, _.create(null))
            .value();
    }

    _onServerResponse422(jqXHR, textStatus, errorThrown) {
        const fields = _.isArray(jqXHR.responseJSON) && jqXHR.responseJSON
            || (jqXHR.responseJSON.error && jqXHR.responseJSON.error.field) || [];

        this.validationError = _(fields).reduce((fields, o) => {
            let field = o.field,
                msg = o.message;

            switch (field) {
                case '_general':
                case 'task_id':
                    field = 'general';
                    msg = o.message && String(o.message);
                    break;
                case 'date_start':
                    field = 'dateStart';
                    msg = o.message && String(o.message);
                    break;
                case 'date_end':
                    field = 'dateEnd';
                    msg = o.message && String(o.message);
                    break;
                case 'send_time_hour':
                    field = 'sendHour';
                    msg = o.message && String(o.message);
                    break;
                case 'send_time_minute':
                    field = 'sendMinute';
                    msg = o.message && String(o.message);
                    break;
                default:
                    field = field && String(field);
                    msg = o.message && String(o.message);
            }

            if (field && msg) {
                if (!fields[field]) {
                    fields[field] = [msg];
                } else {
                    fields[field].push(msg);
                }
            }

            return fields;
        }, _.create(null));

        this.trigger('invalid', this, this.validationError);
    }
}

_.assign(TaskTargetModel, {
    Status,
    Gender,
    RepeatPeriods,
});

export default TaskTargetModel;
