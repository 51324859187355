import { filterObject, filterObjectIntoArray } from "store/utils";
import { INIT_MENU_SUCCEEDED } from "../actions";
import {
    REMOVE_SECTION,
    REMOVE_TABLE,
    UPSERT_SECTION,
    UPSERT_SECTIONS,
    UPSERT_TABLE,
    UPSERT_TABLES,
    SET_SELECTED_TABLE_ID, RESET_SECTIONS,
} from "store/menu/sections/actions";
import { getBasicSection, getBasicTable } from "../utils";

const initialState = {
    values: {},
    tables: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case INIT_MENU_SUCCEEDED: {
            const values = {};
            const tables = {};

            const { sections } = action.payload;

            for (const section of sections) {
                values[section.id] = getBasicSection(section);

                if (_.isArray(section.tables)) {
                    for (const table of section.tables) {
                        tables[table.id] = getBasicTable(table);
                    }
                }
            }

            if (_.isArray(action.payload.tables)) {
                for (const table of action.payload.tables) {
                    tables[table.id] = getBasicTable(table);
                }
            }

            return {
                ...state, values, tables
            };
        }

        case UPSERT_SECTIONS: {
            const values = {};
            const tables = {};

            const { sections } = action.payload;

            for (const section of sections) {
                values[section.id] = getBasicSection(section);

                if (_.isArray(section.tables)) {
                    for (const table of section.tables) {
                        tables[table.id] = getBasicTable(table);
                    }
                }
            }

            return {
                ...state, values, tables
            };
        }

        case UPSERT_SECTION: {
            const { section } = action.payload;

            const values = { ...state.values };
            const tables = { ...state.tables };

            if (_.isObject(values[section.id]) && _.isArray(section.tables)) {
                // replace existing tables of section by new ones
                const existingTables = filterObjectIntoArray(tables, (k, v) => v.section_id === section.id);
                for (const existingTable of existingTables) {
                    delete tables[existingTable.id];
                }

                for (const table of section.tables) {
                    tables[table.id] = getBasicTable(table);
                }
            }

            values[section.id] = getBasicSection(section);

            return {
                ...state, values, tables
            };
        }

        case REMOVE_SECTION: {
            const values = { ...state.values };
            const tables = { ...state.tables };

            delete values[action.payload.id];

            return {
                ...state,
                values,
                tables: filterObject(tables, (k, v) => v.sectionId !== action.payload.id)
            };
        }

        case RESET_SECTIONS: {
            const { values = {}, tables = {} } = action.payload;

            return {
                ...initialState,
                values, tables
            }
        }

        case UPSERT_TABLE: {
            const tables = { ...state.tables };

            tables[action.payload.id] = getBasicTable(action.payload.table);

            return {
                ...state, tables
            };
        }

        case UPSERT_TABLES: {
            const tables = { ...state.tables };

            for (const table of action.payload.tables) {
                tables[table.id] = getBasicTable(table);
            }

            return {
                ...state, tables
            };
        }

        case REMOVE_TABLE: {
            const tables = { ...state.tables };

            delete tables[action.payload.id];

            return {
                ...state, tables
            };
        }

        default:
            return state
    }
}
