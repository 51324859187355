import React from 'react';

import './MenuOrderStatus.scss';

export const OrderStatusMap = {
    New: 1,
    Confirmed: 2,
    Completed: 3,
    Canceled: -1,
    Unknown: null,
};

export const OrderStatusText = {
    [OrderStatusMap.New]: 'In Progress',
    [OrderStatusMap.Confirmed]: 'Confirmed',
    [OrderStatusMap.Completed]: 'Completed',
    [OrderStatusMap.Canceled]: 'Canceled',
    [OrderStatusMap.Unknown]: 'Unknown',
};

export const OrderStatusClassName = {
    [OrderStatusMap.New]: 'status-warning',
    [OrderStatusMap.Confirmed]: 'status-positive',
    [OrderStatusMap.Completed]: 'status-active',
    [OrderStatusMap.Canceled]: 'status-negative',
    [OrderStatusMap.Unknown]: 'status-warning',
};

export const MenuOrderStatus = (props) => {
    const { status = null, text = OrderStatusText[status], orderStatusClass = OrderStatusClassName[status] } = props;

    return (
        <div className="menu-order-status">
            <span className={orderStatusClass}>{text}</span>
        </div>
    );
};
