import React from 'react';

import './Price.scss';
import { getPriceIconAndPosition } from 'components/UI/Price/priceIconHelpers';

const Price = (props) => {
    const { value, currency, coefficient = 100, priceConversionFunctionToDisplay } = props;

    const { icon, isIconBefore } = getPriceIconAndPosition(value, currency);
    const price = priceConversionFunctionToDisplay
        ? priceConversionFunctionToDisplay(value)
        : parseFloat(value) / coefficient;

    return (
        <span className="price widget">
            {isIconBefore && icon}
            <span className="price-value">{price}</span>
            {!isIconBefore && icon}
        </span>
    );
};

export default Price;
