import { Model } from '@b2cmessenger/backbone';

/**@type {(properties: Model.Properties) => (target: (new (...args: any[]) => {})) => typeof LoadMoreModel} */
const properties = Model.properties;

@properties({
    defaults: {
        _publishedAtValue: null,
        count: 0,
        ids: [],
        publishedAfter: null,
        publishedBefore: null
    }
})
class LoadMoreModel extends Model {
    constructor(attributes, options) {
        super(attributes, options);
    }
}

export default LoadMoreModel;
