/**
 * Created by Alex on 13.03.2016.
 */

// B2C Tags
(function( $ ){
    var methods = {
        init : function( options ) {

            return this.each(function(){

                var $this = $(this),
                    data = $this.data('b2ctags');

                if ( ! data ) {

                    data = {};
                    data.$this = $this;
                    data.options = $.extend(true, {}, $.fn.b2ctags.defaults, options);

                    $(this).data('b2ctags', data);

                    $this.addClass(data.options.cl_main);

                    if (! data.options.readOnly) {
                        $.fn.b2ctags.initInputer(data);
                    }
                    $.fn.b2ctags.initTagsCont(data);

                    if(data.options.tags != null) {
                        var tags = data.options.tags;
                        data.options.tags = null;
                        $.fn.b2ctags.addTags(data, tags);
                    }
                }
            });
        },
        get : function() {
            var $first = this.first();
            var data = $first.data('b2ctags');
            return data.options.tags;
        },
        set : function(tags) {
            return this.each(function() {

                var $this = $(this),
                    data = $this.data('b2ctags');

                if (data != null) {
                    data.options.tags = [];
                    data.options.jqTagsContainer.find('.'+data.options.cl_tag).remove();
                    $.fn.b2ctags.addTags(data, tags);
                    data.jqValue.val(tags && tags.length && tags.join(',') || '');
                    data.jqValue.change();
                }
            });
        },
    };

    $.fn.b2ctags = function( method ) {

        if ( methods[method] ) {
            return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ));
        } else if ( typeof method === 'object' || ! method ) {
            return methods.init.apply( this, arguments );
        } else {
            $.error( 'Cannot find ' +  method + ' in jQuery.b2ctags' );
        }

    };

    $.fn.b2ctags.defaults = {
        onTagAdded : null, // onTagAdded(tagName, tags)
        onTagDeleted : null, // onTagDeleted(tagName, tags)
        onChanged : null, // onChanged(tags)
        onTagClick : null, // onTagClick(tagName)
        jqTagInputerCont : null,
        jqTagsContainer : null,
        readOnly : false,
        tags : [],
        cl_main : 'b2ctags',
        cl_input_cont : 'b2ctags_input_cont',
        cl_inputer : 'b2ctags_input',
        cl_tags_cont : 'b2ctags_cont',
        cl_tag : 'b2ctag',
        cl_tag_name : 'name',
        cl_tag_btn_del : 'del',
        cl_hidden : 'hidden',
        maxlength : 30,
        placeholder : 'input tags',
        inputerAttrDataRole : 'none',
    };

    $.fn.b2ctags.onTagClick = function(data, jqTag) {
        if (data.options.onTagClick == null)
            return;
        var tagName = jqTag.find('.'+data.options.cl_tag_name).text();
        data.options.onTagClick(tagName);
    };

    $.fn.b2ctags.onTagDeleteClick = function(data, jqBtnTagDelete) {
        if (data.options.readOnly)
            return;
        var jqTag = jqBtnTagDelete.closest('.'+data.options.cl_tag);
        var tagName = jqTag.find('.'+data.options.cl_tag_name).text();
        var tagIndex = data.options.tags.indexOf(tagName);
        data.options.tags.splice(tagIndex, 1);
        var tags = data.options.tags;
        data.jqValue.val(tags && tags.length && tags.join(',') || '');
        data.jqValue.change();

        jqTag.remove();
        if (data.options.onTagDeleted != null)
            data.options.onTagDeleted(tagName, tags);
        if (data.options.onChanged != null)
            data.options.onChanged(tags);
    };

    $.fn.b2ctags.onInputChange = function(data) {
        var val = data.jqInput.val().replace(/#/g,'');
        if (val == '')
            return;
        var addedTags = $.fn.b2ctags.addTags(data, [val]);
        if (addedTags != null && addedTags.length == 1) {
            var tags = data.options.tags;
            data.jqValue.val(tags && tags.length && tags.join(',') || '');
            data.jqValue.change();

            if (data.options.onTagAdded != null)
                data.options.onTagAdded(val, tags);

            if (data.options.onChanged != null)
                data.options.onChanged(tags);
        }
        data.jqInput.val('');
    };

    $.fn.b2ctags.addTags = function(data, tags) {
        if (data.options.tags == null)
            data.options.tags = [];
        var tagsToAdd = [];

        if (tags != null) {
            var tag;
            for(var i=0; i<tags.length; i++) {
                if (data.options.tags.indexOf(tags[i]) == -1)
                    tagsToAdd.push(tags[i]);
            }

            if (tagsToAdd.length == 0)
                return;

            var htmlTags = $.fn.b2ctags.buildTagsHTML(data, tags);
            var jqTags = $(htmlTags).appendTo(data.options.jqTagsContainer);
            jqTags.click(function(event) { $.fn.b2ctags.onTagClick(data, $(this)) });
            jqTags.find('.'+data.options.cl_tag_btn_del).click(function(event) { $.fn.b2ctags.onTagDeleteClick(data, $(this)) });
        }

        data.options.tags = data.options.tags.concat(tagsToAdd);

        return tagsToAdd;
    }

    $.fn.b2ctags.buildTagsHTML = function(data, tags) {
        var result = '';
        if(tags == null)
            return result;
        for(var i=0; i<tags.length; i++) {
            result += $.fn.b2ctags.buildTagHTML(data, tags[i]);
        }

        return result;
    }

    $.fn.b2ctags.buildTagHTML = function(data, tag) {
        var hidden = '';
        if (data.options.readOnly)
            hidden = ' '+data.options.cl_hidden;
        return '<span class="'+quoteattr(data.options.cl_tag)+'"><span class="'+quoteattr(data.options.cl_tag_name)+'">'+escapeHtml(tag)+'</span><span class="'+quoteattr(data.options.cl_tag_btn_del)+hidden+'"></span></span>';
    };

    $.fn.b2ctags.initInputer = function(data) {
        if (data.options.jqTagInputerCont == null) {
            data.options.jqTagInputerCont = $($.fn.b2ctags.buildInputContHTML(data)).prependTo(data.$this);
        }
        data.jqInput = data.options.jqTagInputerCont.find('.' + data.options.cl_inputer);
        data.jqValue = data.options.jqTagInputerCont.children('input[type="hidden"].value');
        if (data.jqInput.length == 0) {
            data.jqInput = $($.fn.b2ctags.buildInputHTML(data)).appendTo(data.options.jqTagInputerCont);
        }
        data.jqInput.attr('maxlength', data.options.maxlength);
        data.jqInput.attr('placeholder', data.options.placeholder);
        data.jqInput.attr('data-role', data.options.inputerAttrDataRole);
        data.jqInput.change(function(event) { $.fn.b2ctags.onInputChange(data) });
    };

    $.fn.b2ctags.buildInputContHTML = function(data) {
        return '<span class="' + quoteattr(data.options.cl_input_cont) + '">' + $.fn.b2ctags.buildInputHTML(data) + '<input class="value" type="hidden"></span>';
    }

    $.fn.b2ctags.buildInputHTML = function(data) {
        return '<input class="'+quoteattr(data.options.cl_inputer)+'" type="text">';
    };

    $.fn.b2ctags.initTagsCont = function(data) {
        if (data.options.jqTagsContainer == null) {
            data.options.jqTagsContainer = $('<span class="'+quoteattr(data.options.cl_tags_cont)+'"></span>').appendTo(data.$this);
        } else {
            data.options.jqTagsContainer.addClass(data.options.cl_tags_cont);
        }
    }

})( jQuery );