import settings from "../settings";

const GoogleAnalyticsProxy = {
    init() {
        const namespace = window.GoogleAnalyticsObject || 'gtag';
        return this.loadGoogleAnalytics(namespace);
    },

    loadGoogleAnalytics(namespace) {
        return new Promise((resolve, reject) => {
            const googleAnalyticsScript = document.createElement("script");

            googleAnalyticsScript.type = "text/javascript";
            googleAnalyticsScript.setAttribute("async", "true");
            googleAnalyticsScript.setAttribute("src", `https://www.googletagmanager.com/gtag/js?id=${settings.ga.gtag_id}`);

            googleAnalyticsScript.onload = () => {
                window.dataLayer = window.dataLayer || [];

                window[namespace] = function () {
                    window.dataLayer.push(arguments);
                };

                this.gtag = window[namespace];
                this.gtag('config', settings.ga.gtag_id, { 'app_version': app.version });

                window[namespace]('js', new Date());

                resolve(this);
            };
            googleAnalyticsScript.onerror = error => {
                reject(error);
            };

            document.documentElement.firstChild.appendChild(googleAnalyticsScript);
        });
    },

    send() {
        const args = Array.prototype.slice.call(arguments);
        console.log('send GoogleAnalyticsProxy: ', arguments);

        this.gtag('event', ...args);
    },

    set(key, value) {
        console.log('set GoogleAnalyticsProxy: ', arguments);

        const customVariable = {};
        customVariable[key] = value;

        this.gtag('set', customVariable);
    },

    setUserId(userId) {
        this.set('user_id', userId);
    },

    trackView(screen) {
        this.send('screen_view', {
            screen_name: screen,
            app_version: app.version
        });
    }
};

export default GoogleAnalyticsProxy;
