/**
 * Created by Alex on 05.03.2016.
 */
// B2C Rating
(function( $ ){
    var methods = {
        init : function( options ) {

            return this.each(function(){

                var $this = $(this),
                    data = $this.data('b2crating');

                // пїЅпїЅпїЅпїЅ пїЅпїЅпїЅпїЅпїЅпїЅ пїЅпїЅ пїЅпїЅ пїЅпїЅпїЅпїЅпїЅпїЅпїЅпїЅпїЅпїЅпїЅпїЅпїЅпїЅпїЅпїЅпїЅпїЅ
                if ( ! data ) {

                    data = {};
                    data.$this = $this;
                    data.options = $.extend(true, {}, $.fn.b2crating.defaults, options);

                    var $stars = $this.find('.'+data.options.cl_star);
                    data.$stars = $stars;

                    for(var i=0; i < $stars.length; i++) {
                        $($stars[i]).attr(data.options.attr_star_num, i+1);
                    }

                    var rattingRound = Math.round(data.options.rating);
                    for(var i=0; i < $stars.length && i < rattingRound; i++) {
                        $($stars[i]).addClass(data.options.cl_star_set);
                    }

                    $stars.click(function(event){
                        if (data.options.readOnly)
                            return;

                        var $this = $(this);
                        var needSetRating = false;
                        var starNum;

                        if ($this.hasClass(data.options.cl_star_set)) {
                            starNum = Math.round(Number($this.attr(data.options.attr_star_num)));
                            if (starNum != data.options.rating) {
                                needSetRating = true;
                            } else {
                                data.$stars.removeClass(data.options.cl_star_set);
                                data.options.rating = 0;
                            }
                        } else {
                            needSetRating = true;
                        }

                        if (needSetRating) {
                            data.$stars.removeClass(data.options.cl_star_set);
                            starNum = Number($this.attr(data.options.attr_star_num));
                            data.options.rating = starNum;

                            var rattingRound = Math.round(data.options.rating);
                            for(var i=0; i < data.$stars.length && i < rattingRound; i++) {
                                $(data.$stars[i]).addClass(data.options.cl_star_set);
                            }
                        }

                        if (data.options.onRatingChange != null) {
                            data.options.onRatingChange(data.options.rating, data.$this);
                        }
                    });

                    $(this).data('b2crating', data);
                }
            });
        },
        get : function() {
            var $first = this.first();
            var data = $first.data('b2crating');
            return data.options.rating;
        },
        set : function(rating) {
            return this.each(function() {

                var $this = $(this),
                    data = $this.data('b2crating');

                if (data != null) {
                    rating = rating==null ? 0 : rating;

                    data.options.rating = rating;
                    data.$stars.removeClass(data.options.cl_star_set);

                    var rattingRound = Math.round(data.options.rating);

                    for(var i=0; i < data.$stars.length && i < rattingRound; i++) {
                        $(data.$stars[i]).addClass(data.options.cl_star_set);
                    }
                }
            });
        }
    };

    $.fn.b2crating = function( method ) {

        if ( methods[method] ) {
            return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ));
        } else if ( typeof method === 'object' || ! method ) {
            return methods.init.apply( this, arguments );
        } else {
            $.error( 'Cannot find ' +  method + ' in jQuery.b2crating' );
        }

    };

    $.fn.b2crating.defaults = {
        rating : 0,
        cl_star : 'star',
        cl_star_set : 'set',
        //cl_clear : 'clear',
        cl_container : 'b2crating',
        //attr_rating : 'data-rating',
        attr_star_num : 'data-num',
        readOnly : false,
        onRatingChange : null
    };

})( jQuery );