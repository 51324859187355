import { Model } from '@b2cmessenger/backbone';
import settings from 'settings';
import AjaxError from 'utils/AjaxError';

const Status = {
    Unverified: 0,
    Verified: 1,
    Deleted: 2,
    Detached: 3 // (когда кто-то другой добавляет и подтверждает его)
};

@Model.properties({
    urlRoot: settings.host + settings.serv_user.phones.base,
    defaults: {
        user_id: null,
        status: null,
        phone: null,
        created_at: null,
        verified_at: null,
        default: null
    },

    computeds: {
        c_isVerified: {
            deps: ['status'],
            get: (status) => {
                return !!(status === Status.Verified);
            }
        },
        c_isDefault: {
            deps: ['default'],
            get: (isDefault) => !!isDefault
        }
    }
})
class PhoneModel extends Model {
    async requestVerificationCode() {
        return new Promise((resolve, reject) => {
            Server.callServer({
                url: settings.host + settings.serv_user.phones.request_code.replace('{id}', this.id),
                type: 'POST',
                success: resolve,
                error: (jqXHR, textStatus, errorThrown) => {
                    reject(new AjaxError(jqXHR, textStatus, errorThrown));
                }
            });
        });
    }

    async verifyWithCode(code) {
        return new Promise((resolve, reject) => {
            if (code) {
                this.save(null, {
                    //@ts-ignore
                    verifyCode: code,
                    success: () => resolve(),
                    error: (m, resp) => reject(new AjaxError(resp))
                });
            } else {
                reject('Code is required field');
            }
        });
    }

    /** @returns {JQueryXHR} */
    sync(method, model, options) {
        const error = options.error,
            self = this;

        options.error = function (jqXHR, textStatus, errorThrown) {
            if (jqXHR.status == 422) {
                self._onServerResponse422(jqXHR, textStatus, errorThrown)
            }

            if (error) error.call(this, jqXHR, textStatus, errorThrown);
        };

        if (method === 'create') {
            options.attrs = {
                phone: this.get('phone')
            };
            return super.sync(method, model, options);
        } else if (method === 'update' && options.verifyCode) {
            options.url = settings.host + settings.serv_user.phones.verify.replace('{id}', this.id);
            options.type = 'POST';
            options.attrs = {
                code: options.verifyCode
            };
            return super.sync(method, model, options);
        } else if (method === 'update' && options.makeDefault) {
            options.url = settings.host + settings.serv_user.phones.make_default.replace('{id}', this.id);
            options.type = 'POST';

            return super.sync(method, model, options);
        } else if (method === 'read' || method === 'delete') {
            return super.sync(method, model, options);
        } else {
            const err = new Error(`Sync method "${method}" is not supported`);
            if (options && options.error) {
                options.error.call(options.context, err);
            }
            //@ts-ignore
            return $.Deferred().reject(err);
        }

    }

    _onServerResponse422(jqXHR, textStatus, errorThrown) {
        const fields = _.isArray(jqXHR.responseJSON) && jqXHR.responseJSON
            || (jqXHR.responseJSON.error && jqXHR.responseJSON.error.field) || [];

        this.validationError = _(fields).reduce((fields, o) => {
            let field = o.field && String(o.field),
                msg = o.message && String(o.message);

            if (field && msg) {
                fields[field] = msg;
            }

            return fields;
        }, _.create(null));

        this.trigger('invalid', this, this.validationError);
    }
}

_.assign(PhoneModel, {
    Status
});

export default PhoneModel;
