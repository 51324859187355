import { Required, ItemView, ViewModel } from '@b2cmessenger/backbone';

import { bindingHandler as stars, bindingHandlerInteractive as starsInteractive } from 'widgets/lite/stars/stars';
import template from './MessageSolutionRatings.jade';
import './MessageSolutionRatings.scss';

/**@type {typeof import('./MessageSolutionRatings').properties} */
// @ts-ignore
const properties = ItemView.properties;
/**@type {typeof import('./MessageSolutionRatings').options} */
// @ts-ignore
const options = ItemView.options;

@options({
    model: Required,
    loginedUserModel: Required
})
@properties({
    className: "widget message-solution-ratings-widget",
    template,

    ui: {
        averageRating: '[data-js-average-rating]',
        totalVotes: '[data-js-total-votes]',
        taskAuthorRatingContainer: '[data-js-task-author-rating-container]',
        taskAuthorRatingHeader: '[data-js-task-author-rating-header]',
        taskAuthorRating: '[data-js-task-author-rating]',
        userRatingContainer: '[data-js-user-rating-container]',
        userRatingHeader: '[data-js-user-rating-header]',
        userRating: '[data-js-user-rating]',
    },

    computeds: {
        solutionVotesText: {
            deps: ['solutionVotes'],
            get: solutionVotes => `${solutionVotes} vote${solutionVotes > 1 ? 's' : ''}`
        },
        isUserIsTaskAuthor: {
            deps: ['parentTaskModel', 'loginedUser_id'],
            get: (parentTaskModel, loginedUser_id) => loginedUser_id &&
                parentTaskModel && parentTaskModel.get('user_id') == loginedUser_id
        },
        taskAuthorRatingHeaderText: {
            deps: ['isUserIsTaskAuthor', 'taskAuthorSolutionRating'],
            get: (isUserIsTaskAuthor, taskAuthorSolutionRating) => !isUserIsTaskAuthor && taskAuthorSolutionRating ?
                'Task author rating' : 'Task author hasn\'t rated yet'
        },
        isUserCanRateSolution: {
            deps: ['$loginedUser', 'loginedUser_workplaces', 'place_id'],
            get: (loginedUser, loginedUser_workplaces, place_id) => loginedUser.get('isLoggedIn') &&
                !loginedUser.isEmployee(place_id)
        },
        userRatingHeaderText: {
            deps: ['isUserCanRateSolution', 'userSolutionRating'],
            get: (isUserCanRateSolution, userSolutionRating) => isUserCanRateSolution && userSolutionRating ?
                'You rated' : 'You haven\'t rated yet'
        },
        userRating: {
            deps: ['isUserCanRateSolution', 'userRatingSelected', 'userSolutionRating'],
            get: (isUserCanRateSolution, userRatingSelected, userSolutionRating) => isUserCanRateSolution &&
                (_.isNull(userRatingSelected) ? userSolutionRating : userRatingSelected) || 0,
            set(val) {
                if (!this.getBinding('disabled')) {
                    this.viewModel.set({
                        disabled: true,
                        userRatingSelected: Number(val)
                    });
                    this.model.rateSolution(val)
                        .catch(e => this.showError(e))
                        .then(() => this.viewModel.set({
                            disabled: false,
                            userRatingSelected: null
                        }));
                }
            }
        }
    },

    bindings: {
        '@ui.averageRating': 'stars:solutionRating',
        '@ui.totalVotes': 'text:solutionVotesText',
        '@ui.taskAuthorRatingContainer': 'classes:{hidden:isUserIsTaskAuthor}',
        '@ui.taskAuthorRatingHeader': 'text:taskAuthorRatingHeaderText',
        '@ui.taskAuthorRating': 'stars:taskAuthorSolutionRating',
        '@ui.userRatingContainer': 'classes:{hidden:not(isUserCanRateSolution)}',
        '@ui.userRatingHeader': 'text:userRatingHeaderText',
        '@ui.userRating': 'starsInteractive:userRating,classes:{disabled:disabled}'
    },

    bindingHandlers: {
        stars,
        starsInteractive
    },

    bindingSources() {
        return { loginedUser: this.loginedUserModel }
    },

    templateHelpers() {
        return { loginedUserModel: this.loginedUserModel };
    },
})
class MessageSolutionRatingsView extends ItemView {
    initialize() {
        /** @type {import('./MessageSolutionRatings')} */
        // @ts-ignore
        const self = this;

        this.loginedUserModel = self.options.loginedUserModel;
        this.viewModel = new ViewModel({
            userRatingSelected: null
        });
    }
}

export default MessageSolutionRatingsView;
