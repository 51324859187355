import { Model, ItemView } from '@b2cmessenger/backbone';
import settings from 'settings';

import Window from 'windows/Window';
import HeaderView from 'widgets/Header/Header';
import FooterView from 'widgets/Footer/Footer';
import Behaviors from 'utils/Behaviors';
import 'utils/Element.scrollIntoCenter';

import contentTemplate from './Content.jade';
import './Register.scss';
import GoogleAuth from 'utils/GoogleSignIn';
import FacebookAuth from 'utils/FacebookAuth';
import { intlTelInputDisabledHandler, IntlTelInputHandler } from 'widgets/lite/intlTelInput/intlTelInput';
import getUserCountryCode from 'utils/getUserCountryCode';
import AppleSignIn from "utils/AppleSignIn";

let ContentWidget = ItemView.extend({
    template: contentTemplate,
    templateHelpers() {
        return {
            iOS: AppleSignIn.isIOS()
        };
    },

    className: 'widget register-widget',

    ui: {
        form: '[data-js-form]',
        signup: '[data-js-btn-signup]',
        skip: '[data-js-skip]',
        btnFb: '[data-js-btn-fb]',
        btnGoo: '[data-js-btn-goo]',
        btnApple: '[data-js-btn-apple]',
        phone: '[data-js-phone]',
        phoneError: '[data-js-phone-error]',
    },

    computeds: {
        prettyPhone: {
            deps: ['phone'],
            get: phone => phone && `+${phone}` || "",
            set(val) {
                this.model.set({
                    phone: val && B2Cjs.phone_getInternalFormat(val) || undefined
                });
            }

        }
    },

    bindings: {
        '[data-js-input-passwordrepeat]': 'value:passwordrepeat',
        '[data-js-input-group-passwordrepeat] p.error': 'text:passwordrepeatError',
        '@ui.phone': 'intlTelInput:prettyPhone',
        '@ui.phoneError': 'text:phoneError',
        '@ui.signup': 'enabled:signupEnabled,classes:{disabled:not(signupActive)}'
    },

    bindingHandlers() {
        return {
            intlTelInput: new IntlTelInputHandler({
                preferredCountries() {
                    return getUserCountryCode()
                        .then(cc => cc && [cc] || []);
                }
            }),
            intlTelInputDisabled: intlTelInputDisabledHandler
        };
    },

    events: {
        'submit @ui.form': 'onSubmit',
        'click @ui.skip': 'onSkip',
        'click @ui.btnFb': 'onFbSignIn',
        'click @ui.btnGoo': 'onGooSignIn',
        'click @ui.btnApple': 'onAppleSignIn',
    },

    behaviors: [{
        behaviorClass: Behaviors.InputGroupWithError,
        name: 'email',
        dontClearErrorsOnBlur: true,
    }, {
        behaviorClass: Behaviors.InputGroupWithError,
        name: 'password',
        dontClearErrorsOnBlur: true,
    }, {
        behaviorClass: Behaviors.InputGroupWithError,
        name: 'firstname',
        dontClearErrorsOnBlur: true,
    }, {
        behaviorClass: Behaviors.InputGroupWithError,
        name: 'lastname',
    }, {
        behaviorClass: Behaviors.InputGroupWithError,
        name: 'birthday',
    }, {
        behaviorClass: Behaviors.InputGroupWithError,
        name: 'gender'
    }],

    initialize() {
        this.viewModel = new Model({
            passwordrepeatError: '',
            phoneError: '',
            signupEnabled: true,
            signupActive: true,
        });

        this.viewModel.listenTo(this.model, 'change:passwordrepeat change:phone', _.debounce(() => this.validate(), 500));
    },

    onSubmit(e) {
        this.validate(true)
            .then(() => new Promise((resolve, reject) => {
                this.showLoading();
                e.preventDefault();
                e.stopPropagation();
                Server.callServer({
                    url: settings.host + settings.serv_user.create,
                    type: "POST",
                    data: _.extend(this.model.attributes, { mode: 'false' }),
                    success: data => {
                        this.hideLoading();
                        this.onServerSuccess(data);
                        resolve();
                    },
                    error: (jqXHR, textStatus, errorThrown) => {
                        this.hideLoading();
                        this.onServerError(jqXHR, textStatus, errorThrown, false);
                        reject();
                    }
                });
            }))
            .catch(() => {
                const el = this.$el.find(".input-group:has(.error:not(:empty))")[0];
                el && el.scrollIntoCenter();
            });
    },

    onSkip(e) {
        e.preventDefault();
        this.trigger('skip');
    },

    validate(onlyLocal) {
        return new Promise((resolve, reject) => {
            let localClear = true;

            this.trigger('errors:clear');
            this.viewModel.set({
                passwordrepeatError: '',
                phoneError: '',
            });

            if (this.model.has('password')) {
                if (this.model.get('password') != this.model.get('passwordrepeat')) {
                    if (!this.model.get('passwordrepeat')) {
                        this.viewModel.set(`passwordrepeatError`, 'Repeat password');
                    } else {
                        this.viewModel.set(`passwordrepeatError`, 'Not equal');
                    }

                    localClear = false;
                }
            }

            if (!onlyLocal) {
                this.viewModel.set({
                    //signupEnabled: false,
                });

                Server.callServer({
                    url: settings.host + settings.serv_user.create,
                    type: "POST",
                    data: _.extend(this.model.attributes, { mode: 'true' }),
                    success: () => {
                        this.viewModel.set({
                            signupEnabled: true,
                            signupActive: true,
                        });

                        if (localClear)
                            resolve()
                        else
                            reject();
                    },
                    error: (jqXHR, textStatus, errorThrown) => {
                        this.onServerError(jqXHR, textStatus, errorThrown, true);
                        reject();
                    }
                });
            } else {
                if (localClear)
                    resolve()
                else
                    reject();
            }
        });
    },

    onFbSignIn() {
        this.showLoading();
        FacebookAuth.login().then(
            (data, textStatus, jqXHR) => {
                if (data.access_token)
                    LoginedUserHandler.setAccessToken(data.access_token);

                LoginedUserHandler.setLoginedUser({
                    id: data.id,
                    email: data.email,
                    firstname: data.firstname,
                    lastname: data.lastname || "",
                    phone: data.phone,
                    birthday: data.birthday,
                    gender: data.gender,
                    avatar: data.avatar,
                    avatarThumb: data.avatarThumb ? data.avatarThumb : data.avatar,
                    settings: {
                        viewanonym: (data.viewanonym == null ? true : data.viewanonym),
                        viewunverified: (data.viewunverified == null ? true : data.viewunverified),
                    }
                });
                this.hideLoading(500);
                this.model.set(LoginedUserHandler.getLoginedUser());
                this.model.set('loggedIn', true);
            },
            (error) => {
                this.hideLoading();
                if (error == "User cancelled." || Number(error.errorCode) == 4201) {

                } else {
                    this.showError(error);
                }
            }
        );
    },

    onGooSignIn() {
        this.showLoading();
        GoogleAuth.authorize().then(
            (data, textStatus, jqXHR) => {
                if (data.access_token)
                    LoginedUserHandler.setAccessToken(data.access_token);

                LoginedUserHandler.setLoginedUser({
                    id: data.id,
                    email: data.email,
                    firstname: data.firstname,
                    lastname: data.lastname || "",
                    phone: data.phone,
                    birthday: data.birthday,
                    gender: data.gender,
                    avatar: data.avatar,
                    avatarThumb: data.avatarThumb ? data.avatarThumb : data.avatar,
                    settings: {
                        viewanonym: (data.viewanonym == null ? true : data.viewanonym),
                        viewunverified: (data.viewunverified == null ? true : data.viewunverified),
                    }
                });
                this.hideLoading(500);
                this.model.set(LoginedUserHandler.getLoginedUser());
                this.model.set('loggedIn', true);
            },
            (jqXHRorTextError, textStatus, errorThrown) => {
                this.hideLoading();
                if (jqXHRorTextError == "The user canceled the sign-in flow."
                    || jqXHRorTextError == "access_denied"
                    || jqXHRorTextError == 13
                    || jqXHRorTextError == 12500 || jqXHRorTextError == 12501
                ) {

                } else {
                    if (_.isUndefined(textStatus) && _.isUndefined(errorThrown) && _.isString(jqXHRorTextError)) {
                        this.showError(
                            {
                                title: jqXHRorTextError,
                                text: `Please, try to login via Facebook or use Sign Up button at the bottom of the screen`
                            },
                            undefined, undefined,
                            { dontSendToGaAndSentry: true }
                        );
                    } else {
                        this.showError(jqXHRorTextError, textStatus, errorThrown);
                    }
                }
            }
        );
    },

    onAppleSignIn() {
        this.showLoading();
        AppleSignIn.authorize().then(
            (data, textStatus, jqXHR) => {
                if (data.access_token)
                    LoginedUserHandler.setAccessToken(data.access_token);

                LoginedUserHandler.setLoginedUser({
                    id: data.id,
                    email: data.email,
                    firstname: data.firstname,
                    lastname: data.lastname || "",
                    phone: data.phone,
                    birthday: data.birthday,
                    gender: data.gender,
                    avatar: data.avatar,
                    avatarThumb: data.avatarThumb ? data.avatarThumb : data.avatar,
                    settings: {
                        viewanonym: (data.viewanonym == null ? true : data.viewanonym),
                        viewunverified: (data.viewunverified == null ? true : data.viewunverified),
                    }
                });
                this.hideLoading(500);
                this.model.set(LoginedUserHandler.getLoginedUser());
                this.model.set('loggedIn', true);
            },
            (jqXHRorTextError, textStatus, errorThrown) => {
                this.hideLoading();
                if (jqXHRorTextError == "The user canceled the sign-in flow."
                    || jqXHRorTextError == AppleSignIn.UnSupported
                ) {

                } else {
                    if (_.isUndefined(textStatus) && _.isUndefined(errorThrown) && _.isString(jqXHRorTextError)) {
                        this.showError(
                            {
                                title: jqXHRorTextError,
                                text: `Please, try to login via Facebook, Google or use Sign Up button at the bottom of the screen`
                            },
                            undefined, undefined,
                            { dontSendToGaAndSentry: true }
                        );
                    } else {
                        this.showError(jqXHRorTextError, textStatus, errorThrown);
                    }
                }
            }
        );
    },

    onServerSuccess(user) {
        //loginedUser = user;
        this.model.set(user);
        let loginedUser = {};
        loginedUser.id = user.id;
        loginedUser.email = user.email;
        loginedUser.avatar = user.avatar;
        loginedUser.avatarThumb = user.avatarThumb;
        loginedUser.firstname = user.firstname;
        loginedUser.lastname = user.lastname;
        loginedUser.birthday = user.birthday;
        loginedUser.phone = user.phone;
        loginedUser.gender = user.gender;

        loginedUser.settings = {
            viewanonym: true,
            viewunverified: true
        }

        LoginedUserHandler.setAccessToken(user.access_token);
        localStorage.loginedUser = JSON.stringify(loginedUser);

        this.model.set('loggedIn', true);
    },

    onServerError(jqXHR, textStatus, errorThrown, onlyValidation) {
        if (jqXHR.status == 422) { // Fields validation error
            const fields = _.isArray(jqXHR.responseJSON) && jqXHR.responseJSON
                || (jqXHR.responseJSON.error && jqXHR.responseJSON.error.field) || [];
            this.viewModel.set({
                signupEnabled: true,
                signupActive: false
            });

            for (let err of fields) if (!onlyValidation || this.model.has(err.field)) {
                switch (err.field) {
                    case 'phone': {
                        this.viewModel.set({ phoneError: err.message });
                        break;
                    }
                    default: {
                        this.trigger(`${err.field}:error:add`, err.message);
                        this.viewModel.set(`${err.field}Error`, err.message);
                    }
                }
            }
        } else { // Other error
            this.showError(jqXHR, textStatus, errorThrown);
        }
    },
});

let Register = Window.extend({
    windowName: "register-window",
    className: "register-window",

    initialize() {
        this.model = new Backbone.Model({
            passwordrepeat: '',
            phone: '',
            loggedIn: false,
        });

        this.listenTo(this.model, 'change:loggedIn', (m, loggedIn) => {
            if (loggedIn) {
                this.close(this.model);
            }
        });
    },

    onRender() {
        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: 'Sign Up',
            rightButtons: [{ id: 'skip', text: 'Skip', icn: 'empty' }]
        })
        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.listenTo(headerView, 'skip:click', () => this.close());
        this.header.show(headerView);

        this.footer._ensureElement();

        let contentWidget = new ContentWidget({ model: this.model });
        contentWidget.showLoading = this.showLoading.bind(this);
        contentWidget.hideLoading = this.hideLoading.bind(this);
        this.listenTo(contentWidget, 'skip', () => this.close());
        this.content.show(contentWidget);

        this.$el.enhanceWithin();
    },

    cancel() {
        this.close(this.model);
    }
});

export default Register;
