import { Required, Optional, LayoutView, ViewModel } from '@b2cmessenger/backbone';
import { decorator as ViewWithPrettyColor } from 'traits/ViewWithPrettyColor';
import MessageHeaderView from './MessageHeader/MessageHeader';
import MessageContentView from './MessageContent/MessageContent';
import MessageFooterView from './MessageFooter/MessageFooter';
import UserModel from 'models/UserModel';

import template from './Message.jade';
import './Message.scss';
import ReservationContent from "widgets/Reservation/ReservationContent/ReservationContent";
import ReservationModel from "models/ReservationModel";

/**@type {typeof import('./Message').properties} */
// @ts-ignore
const properties = LayoutView.properties;

/**@type {typeof import('./Message').options} */
// @ts-ignore
const options = LayoutView.options;

/** @type {typeof import('./Message').events} */
// @ts-ignore
const events = LayoutView.events;

@options({
    model: Required,
    userModel: Required,
    placeModel: Required,
    reservationModel: Optional,
    loginedUserModel: Required,
    parentViewModel: Optional,
    toUserModel: Optional
})
@events({
    /** User opened menu */
    'menu:open': () => {
    },

    /** User closed menu
     * @param {string} [entryName] - Name of the entry
     * @param {any} [actionResult] - Result if any with which action finished */
    'menu:close': (entryName, actionResult) => {
    },

    /** User clicked on menu entry
     * @param {string} entryName - Name of the entry */
    'menu:entry:click': (entryName) => {
    },

    /** Action related to menu entry finished sucessfully
     * @param {string} entryName - Name of the entry
     * @param {any} [actionResult] - Result if any with which action finished */
    'menu:entry:finish': (entryName, actionResult) => {
    },

    /** Action related to menu entry failed
     * @param {string} entryName - Name of the entry
     * @param {Error | string} [actionError] */
    'menu:entry:fail': (entryName, actionError) => {
    },

    /** User clicked on user
     * @param {UserModel} userModel - model of user
     * @param {boolean} [isAnonym] - is user is anonym
     * @param {boolean} [isFromBussines] - is user from this place */
    'user:click': (userModel, isAnonym, isFromBussines) => {
    },

    /** User clicked on comments button */
    'comments:click': () => {
    },

    /** User clicked on reply button */
    'reply:click': () => {
    }
})
@ViewWithPrettyColor({
    modelAttribute: 'visibleUserId'
})
@properties({
    className: 'widget message-widget',
    template,

    attributes() {
        return {
            'data-id': this.model.id,
            'data-comment': this.model.get('isComment') ? '' : null,
            'data-task': this.model.get('isTask') ? '' : null,
        }
    },

    regions: {
        header: '[data-js-message-header]',
        content: '[data-js-message-content]',
        footer: '[data-js-message-footer]'
    },

    ui: {
        header: '[data-js-message-header]',
        content: '[data-js-message-content]',
        footer: '[data-js-message-footer]'
    },

    bindings: {
        ':el': 'classes:{hidden:isHidden}',
    },

    modelEvents: {
        'change:reservation'(m, reservation) {
            this.reservationModel.set(reservation);
        }
    }
})
class MessageView extends LayoutView {
    show(show = true) {
        this.viewModel.set({ isHidden: !show });
    }

    hide() {
        this.show(false);
    }

    openMenu(open = true) {
        /** @type {import('./Message')} */
            // @ts-ignore
        const self = this;
        const footerView = self.footer.currentView;
        if (footerView) {
            return footerView.openMenu(open);
        }
    }

    closeMenu() {
        /** @type {import('./Message')} */
            // @ts-ignore
        const self = this;
        const footerView = self.footer.currentView;
        if (footerView) {
            return footerView.closeMenu();
        }
    }

    initialize() {
        /** @type {import('./Message')} */
            // @ts-ignore
        const self = this;

        this.viewModel = new ViewModel({
            isHidden: false,
            parentViewModel: self.options.parentViewModel,
        });

        this.userModel = self.options.userModel;
        this.placeModel = self.options.placeModel;
        this.loginedUserModel = self.options.loginedUserModel;
        this.toUserModel = self.options.toUserModel;
        this.reservationModel = self.options.reservationModel || (this.model.get('isReservation') ?
                new ReservationModel(this.model.get('reservation')) :
                null
        );
    }

    onRender() {
        /** @type {import('./Message')} */
            // @ts-ignore
        const self = this;

        const headerView = new MessageHeaderView({
            model: self.model,
            userModel: self.userModel,
            loginedUserModel: self.loginedUserModel,
            placeModel: self.placeModel
        });
        this.showChildView('header', headerView);
        this.listenTo(headerView, 'author:click',
            (userModel, isAnonym, isFromBussines) => this.trigger('user:click', userModel, isAnonym, isFromBussines));

        const contentView = new MessageContentView({
            model: self.model,
            reservationModel: self.reservationModel
        });
        this.showChildView('content', contentView);
        this.listenTo(contentView, 'full:page', () => this.trigger('full:page'));

        const oldFooterView = this.getChildView('footer');
        if (oldFooterView) {
            this.stopListening(oldFooterView);
        }

        const footerView = new MessageFooterView({
            model: self.model,
            loginedUserModel: self.loginedUserModel,
            placeModel: self.placeModel,
            toUserModel: self.toUserModel,
            reservationModel: self.reservationModel
        });
        this.showChildView('footer', footerView);
        this.listenTo(footerView, 'menu:open', () => this.trigger('menu:open'));
        this.listenTo(footerView, 'menu:close', (entryName, actionResult) =>
            this.trigger('menu:close', entryName, actionResult));
        this.listenTo(footerView, 'menu:entry:click', (entryName) =>
            this.trigger('menu:entry:click', entryName));
        this.listenTo(footerView, 'menu:entry:finish', (entryName, actionResult) =>
            this.trigger('menu:entry:finish', entryName, actionResult));
        this.listenTo(footerView, 'menu:entry:fail', (entryName, actionError) =>
            this.trigger('menu:entry:fail', entryName, actionError));
        this.listenTo(footerView, 'comments:click', () =>
            this.trigger('comments:click'));
        this.listenTo(footerView, 'reply:click', () =>
            this.trigger('reply:click'));

        const unreadNotificationIds = notificationCollection.getNewVisibleNotificationIdsForMessage(this.model);
        if (unreadNotificationIds.length) {
            this.$el.attr('data-notification-ids', unreadNotificationIds.join(','));
        }
    }
};

export { MessageView as default };
