import { Model } from '@b2cmessenger/backbone';

import Page from 'pages/Page';
import HeaderView from 'widgets/HeaderWithTabs/HeaderWithTabs';
import LeftMenuWindow from 'windows/LeftMenu/LeftMenu';
import BrandItemView from 'widgets/BrandItem/BrandItem';
import qrCodeScanner from "utils/qrCodeScanner";

import template from './Workplace.jade';
import './Workplace.scss';
import Behaviors from "utils/Behaviors";
import store from "store/index";
import LoginedUserModel from "models/LoginedUserModel";
import {getHasLoginedUserRoleInPlaceById, getLoginedUserWorkPlacesRoles} from "store/app/selectors";

const WorkplacePage = Page.extend({
    options: {
        placeId: undefined
    },

    behaviors() {
        return [{
            behaviorClass: Behaviors.ReduxStoreViewConnect,
            store: store,
            stateMappings: {
                displayMenuDashboardButton: state => {
                    const { id } = this.model.get('workplace');
                    const roles = getLoginedUserWorkPlacesRoles(state);
                    if (roles && roles[id]) {
                        return _.intersection(roles[id], [
                            LoginedUserModel.Roles.ORDER_MANAGER,
                            LoginedUserModel.Roles.WAITER
                        ]).length > 0;
                    }

                    return false;
                }
            },
            target: payload => {
                this.model.set(payload);
            }
        }]
    },

    getPlaceId() {
        return this.options.placeId;
    },

    setPlaceId(placeId, force) {
        if (force || LoginedUserHandler.get_work_place_by_id(placeId)) {
            this.options.placeId = placeId;
        }
    },

    show(options) {
        _.extend(this.options, _.pick(options, ['placeId']));

        const workplace = this.options.placeId && LoginedUserHandler.get_work_place_by_id(this.options.placeId);
        if (!workplace) {
            throw new Error('this.options.placeId is incorrect!');
        }

        this.model.set({
            workplace
        });

        const ret = Page.prototype.show.apply(this, arguments);

        this.place.show(new BrandItemView({
            model: new Model(this.model.get('workplace'))
        }));

        return ret;
    },

    className: 'workplace-page page',
    template,

    attributes: {
        'id': "page_workplace"
    },

    ui: {
        place: '[data-js-place]',
        menuDashboard: '[data-js-menu-dashboard]',
        scanQr: '[data-js-scan-qr]',
        findClient: '[data-js-find-client]'
    },

    regions: {
        header: '[data-js-header]',
        place: '[data-js-place]'
    },

    bindings: {
        '@ui.menuDashboard': 'classes:{hidden:not(displayMenuDashboardButton)}'
    },

    events: {
        'click @ui.place'() {
            app.controller.goToPlacePage({
                place: this.model.get('workplace')
            });
        },
        'click @ui.menuDashboard'() {
            const { id: placeId } = this.model.get('workplace');
            app.controller.goToMenuDashboard({ placeId });
        },
        'click @ui.scanQr'() {
            qrCodeScanner()
                .then(qrCodeModel =>
                    qrCodeModel &&
                    window.app.executeQrAction(qrCodeModel, this.model.get('workplace').id)
                )
                .catch(e => {
                    if (e) {
////////////////////////////////////
//////////////////////////////////////////
////////////////////////////////
                        e && e.message && this.showError(e.message);
/////////////////////////////////
                    }
                });
        },
        'click @ui.findClient'() {
            app.controller.goToUserSearchPage({ placeId: this.model.get('workplace').id });
        }
    },

    initialize() {
        this.model = new Model({ displayMenuDashboardButton: false });
    },

    onRender() {
        const headerView = new HeaderView({
            leftButtons: ['menu'],
            title: 'Workplace'
        });

        this.listenTo(headerView, 'menu:click', () => new LeftMenuWindow().show());

        this.header.show(headerView);
    }
});

export default WorkplacePage;
