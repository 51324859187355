import { CollectionView, ItemView, LayoutView, Optional, Required, ViewModel } from '@b2cmessenger/backbone';
import Scrollparent from 'scrollparent';

import './ReservationInfiniteCollection.scss';

@LayoutView.options({
    collection: Required,
    collectionViewClass: Optional,
    collectionViewOptions: Optional
})
@LayoutView.properties({
    className: 'reservation-infinite-collection-view',
    template: _.template(`<div data-js-top></div><div class="content-region" data-js-content></div><div data-js-bottom></div>`),
    regions: {
        top: '[data-js-top]',
        content: '[data-js-content]',
        bottom: '[data-js-bottom]'
    }
})
class ReservationInfiniteCollection extends LayoutView {
    initialize() {
        this.viewModel = new ViewModel({
            parentViewModel: this.options.parentViewModel
        });

        this.viewModel.addProxyAttribute('isLoading', 'parentViewModel', 'isLoading');

        this.filtersModel = this.options.filtersModel;

        this.collection = this.options.collection;
    }

    onAttach() {
        const iOSPlatform = document.body.classList.contains('ios');

        this.scrollParent = Scrollparent(this.el);

        this.collectionView = new (this.options.collectionViewClass || CollectionView)(
            _.extend(_.create(null), this.options, this.options.collectionViewOptions)
        );

        this.content.show(this.collectionView);

        this.top.show(new ScrollPlaceholder({
            position: 'top',
            watcherOffset: {
                top: () => {
                    const model = this.collectionView.collection.first();
                    const first = model && this.collectionView.children.findByModel(model);
                    const firstEl = first && first.$el.find('.reservation-item-widget').first();

                    if (firstEl && firstEl.length) {
                        return this.top.el.offsetTop - firstEl[0].offsetTop - firstEl[0].clientHeight;
                    }

                    return 0;
                }
            },
            containerMonitor: this.options.containerMonitor,
            parentViewModel: this.viewModel
        }));

        this.bottom.show(new ScrollPlaceholder({
            position: 'bottom',
            watcherOffset: {
                top: () => {
                    const model = this.collectionView.collection.last();
                    const last = model && this.collectionView.children.findByModel(model);
                    const lastEl = last && last.$el.find('.reservation-item-widget').last();

                    if (lastEl && lastEl.length) {
                        return this.bottom.el.offsetTop - lastEl[0].offsetTop;
                    }

                    return 0;
                }
            },
            containerMonitor: this.options.containerMonitor,
            parentViewModel: this.viewModel
        }));

        this.top.currentView.on('viewport:in', e => {
            if (!this.viewModel.get('isLoading') && this.viewModel.get('hasMoreTop')) {
                const { view:childView } = this.collectionView.findChildByModelDeeply(this.collection.first());
                const childViewOffsetTop = childView && childView.el.offsetTop - this.scrollParent.offsetTop || 0;

                let prevScrollTop = this.scrollParent.scrollTop;

                const beforeSetHandler = () => {
                    prevScrollTop = this.scrollParent.scrollTop;
                    if (iOSPlatform) {
                        this.scrollParent && this.scrollParent.classList.add('no-momentum-scrolling');
                    }
                };
                this.listenToOnce(this.collection, 'before:set', beforeSetHandler);

                this.collection.fetchPrevious(this.filtersModel.toJSON())
                    .always(() => {
                        this.scrollParentToChild(childView.el, -childViewOffsetTop + prevScrollTop);
                        this.stopListening(this.collection, 'before:set', beforeSetHandler);
                        _.defer(() => {
                            this.viewModel.set({ isLoading: false });
                            this.scrollParent && this.scrollParent.classList.remove('no-momentum-scrolling');
                        });
                    });
            }
        });

        this.bottom.currentView.on('viewport:in', e => {
            if (!this.viewModel.get('isLoading') && this.viewModel.get('hasMoreBottom')) {
                this.collection.fetchNext(this.filtersModel.toJSON())
                    .always(() => {
                        _.defer(() => {
                            this.viewModel.set({ isLoading: false });
                            this.scrollParent && this.scrollParent.classList.remove('no-momentum-scrolling');
                        });
                    });
            }
        });

        if (this.collection) {
            this.listenTo(this.collection, 'request', () => {
                this.viewModel.set({ isLoading: true });
            });

            this.listenTo(this.collection, 'sync error', (collection, resp_or_xhr, options) => {
                this.viewModel.set({
                    hasMoreTop: this.collection.hasPrevious(),
                    hasMoreBottom: this.collection.hasNext()
                });

                if (!(options.fetchPrevious || options.fetchNext)) {
                    this.viewModel.set({ isLoading: false });
                }

                if (options && options.jumpToDate) {
                    const nearestModel = this.collection.findNearestModelToDate(options.jumpToDate);

                    if (nearestModel) {
                        const { parentView } = this.collectionView.findChildByModelDeeply(nearestModel);
                        if (parentView) {
                            this.scrollParentToChild(parentView.el);
                        }
                    }
                }
            });

            this.listenToOnce(this.collection, 'sync', () => {
                if (this.collectionView && this.collectionView.isRendered) {
                    this.scrollParent.scrollTop = this.collectionView.$el.height() / 2;
                }
            });
        }
    }

    scrollParentToChild(el, offset = 0) {
        this.scrollParent.scrollTop = el.offsetTop - this.scrollParent.offsetTop + offset;
    }

    getDateFromViewportMostFittedChild() {
        let date = new Date();
        const cview = this.content && this.content.currentView;

        if (cview && cview.collection.size()) {
            const dayModel = cview.collection.max(dayModel => {
                const dayView = cview.children.findByModel(dayModel);

                if (dayView) {
                    const rect = dayView.el.getBoundingClientRect();
                    const bottomEdge = rect.top + rect.height - this.scrollParent.offsetTop;
                    const topEdge = rect.top - this.scrollParent.offsetTop;

                    const visibleHeight = _.min([this.scrollParent.clientHeight, bottomEdge]) - _.max([topEdge, 0]);
                    return visibleHeight;
                }

                return null;
            });

            if (dayModel) {
                return new Date(dayModel.get('id'));
            }
        }

        return date;
    }
}

@ItemView.options({
    model: Required,
    position: Required,
    containerMonitor: Required,
    watcherOffset: Optional
})
@ItemView.properties({
    template: _.template(``),
    tagName: 'div',
    className: 'scroll-placeholder',
    ui: {
        span: 'span'
    },
    computeds: {
        placeholderHeight: {
            deps: ['hasMore'],
            get: hasMore => hasMore ? 100 : 0
        }
    },
    bindings: {
        ':el': {
            css: {
                height: 'placeholderHeight'
            },
            classes: {
                'scroll-placeholder-loading': 'all(isParentLoading,hasMore)'
            }
        }
    }
})
class ScrollPlaceholder extends ItemView {
    initialize() {
        this.viewModel = new ViewModel({
            parentViewModel: this.options.parentViewModel
        });

        this.containerMonitor = this.options.containerMonitor;

        const pos = this.options.position;
        const hasMorePropName = 'hasMore' + (pos && pos[0].toUpperCase() + pos.slice(1));
        this.viewModel.addProxyAttribute('isParentLoading', 'parentViewModel', 'isLoading', true);
        this.viewModel.addProxyAttribute('hasMore', 'parentViewModel', hasMorePropName, true);
    }

    onAttach() {
        this.elementWatcher = this.containerMonitor.create(this.el, this.options.watcherOffset);
        this.elementWatcher.initialOffset = _.extend({ top: 0, bottom: 0 }, this.options.watcherOffset);

        this.viewModel.set(this.getWatcherProperties());

        this.elementWatcher.on('stateChange', () => {
            const props = this.getWatcherProperties();
            this.viewModel.set(props);
        });

        this.elementWatcher.enterViewport(() => {
            this.trigger('viewport:in');
        });

        this.elementWatcher.exitViewport(() => {
            this.trigger('viewport:out');
        });
    }

    getWatcherProperties() {
        return _.pick(this.elementWatcher, 'isInViewport', 'isFullyInViewport', 'isAboveViewport',
            'isBelowViewport', 'top', 'bottom', 'height', 'watchItem', 'offsets');
    }
}

export default ReservationInfiniteCollection;
