import settings from "settings";
import * as Api from "utils/api/Api";
import './jqAjaxTransportArrayBuffer';

export async function getSection({ placeId, id, includeTables = false }) {
    const url = [settings.host + settings.serv_place.menu.section.base, id].join('/');
    const parameters = {
        include_tables: Number(Boolean(includeTables))
    };

    return Api.call({
        method: 'GET',
        url: url.replace('${place_id}', placeId),
    }, parameters);
}

export async function getSections({ placeId, includeTables = false }) {
    const url = settings.host + settings.serv_place.menu.section.list;
    const parameters = {
        include_tables: Number(Boolean(includeTables))
    };

    return Api.call({
        method: 'GET',
        url: url.replace('${place_id}', placeId),
    }, parameters);
}

export async function createSection({ placeId, name, color }) {
    const url = settings.host + settings.serv_place.menu.section.base;
    const data = { name, color };

    return Api.call({
        method: 'POST',
        url: url.replace('${place_id}', placeId),
        contentType: 'application/json',
        data: JSON.stringify(data)
    });
}

export async function updateSection({ placeId, id, name, color }) {
    const url = [settings.host + settings.serv_place.menu.section.base, id].join('/');
    const data = { name, color };

    return Api.call({
        method: 'PATCH',
        url: url.replace('${place_id}', placeId),
        contentType: 'application/json',
        data: JSON.stringify(data)
    });
}

export async function removeSection({ placeId, id }) {
    const url = [settings.host + settings.serv_place.menu.section.base, id].join('/');

    return Api.call({
        method: 'DELETE',
        url: url.replace('${place_id}', placeId),
    });
}

export async function downloadQrCodes({placeId}){
    const url = [settings.host + settings.serv_place.menu.table.qr].join('/');

    return Api.call({
        method: 'GET',
        dataType: 'arraybuffer',
        url: url.replace('${place_id}', placeId),
    });
}
