import { Model, ViewModel, LayoutView } from '@b2cmessenger/backbone';
import settings from 'settings';
import AjaxError from 'utils/AjaxError';

import template from './GiftItemView.jade';

import GiftHistoryView from './GiftHistoryView';
import GiftModal from 'windows/Modal/Gift';
import GiftQrModal from 'windows/Modal/GiftQr';

export default LayoutView.extend({
    options: {
        writeoff: false,
        qrWriteoff: false,
        history: false,
        user: null
    },

    writeOff(qty) {
        return this._writeOff(qty);
    },

    openHistory() {
        this.history.currentView && this.history.currentView.open();
    },

    closeQrModal() {
        this._closeQrModal();
    },

    className: 'gift-templates-list-item gifts-list-item',
    template,

    ui: {
        image: '[data-js-image]',
        spinner: '[data-js-spinner-qty]',
        writeoffArea: '[data-js-write-off]',
        writeoff: '[data-js-btn-write-off]',
        qrWriteOff: '[data-js-btn-qr-write-off]',
        totalQty: '[data-js-total-qty]',
        whoGift: '[data-js-who-gift]',
        whenGift: '[data-js-when-gift]',
        expireDate: '[data-js-expire-date]',
    },

    bindings: {
        ':el': 'attr:{"data-id":id}',
        '@ui.writeoffArea': 'classes:{hidden:not(writeoff)}',
        '@ui.qrWriteOff': 'classes:{hidden:any(not(qrWriteoff),not(totalQty))}'
    },

    regions: {
        history: '[data-js-gifts-history]'
    },

    events: {
        'click @ui.writeoff': '_writeOff',
        'click @ui.qrWriteOff'() {
            this._openQrModal();
        }
    },

    refresh() {
        if(this.model.get('type') !== 3) {
            this.ui.totalQty.text(this.model.get('totalQty'));
            this.ui.spinner.find('[data-js-spinner-input]').trigger('change');
        }
    },

    initialize() {
        this.viewModel = new ViewModel({
            writeoff: this.options.writeoff,
            qrWriteoff: this.options.qrWriteoff,
        });
    },

    onRender() {
        if(!this.model.get('thumb')) {
            switch (this.model.get('type')){
                case 1:
                    this.ui.image.addClass('empty coins');
                    break;

                case 2:
                    this.ui.image.addClass('empty gifts');
                    break;

                case 3:
                    this.ui.image.addClass('empty discounts');
                    break;
            }
        } else {
            if(this.model.get('type') == 1) {
                this.ui.image.addClass('empty coins');
            } else {
                this.ui.image.removeClass('empty');
                this.ui.image.css('background-image', `url('${this.model.get('thumb')}')`);
            }
        }

        if(this.model.get('type') != 3) {
            this.ui.totalQty.text(this.model.get('totalQty'));

            if(this.options.history) {
                this.historyView = new GiftHistoryView({model: this.model});
                this.history.show(this.historyView);

                if(this.model.get('totalQty') == 0) this.ui.writeoffArea.hide();
                else this.initializeSpinner(this.ui.spinner);
            }
        } else {
            this.ui.whoGift.text('Gifted by ' + this.model.get('gifts')[0].user.firstname + ' ' + (this.model.get('gifts')[0].user.lastname || ''));
            const date = B2Cjs.datetimeServerToJS(this.model.get('gifts')[0].created_at);
            this.ui.whenGift.text(date && date.toLocaleString([], {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }) || '');

            let expireDateLocal = this.model.get('gifts')[0].expireDateLocal;

            if (expireDateLocal) {
                let prefix = new Date() < expireDateLocal ? 'Expire: ' : '<span>Expired: </span>';

                this.ui.expireDate.html(prefix +
                    expireDateLocal.toLocaleDateString(
                        [], {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                        }));
            } else {
                this.ui.expireDate.hide();
            }
        }

        const unreadNotificationIds = notificationCollection.getNewVisibleNotificationIdsForGift(this.model.id);
        if (unreadNotificationIds.length) {
            this.$el.attr('data-notification-ids', unreadNotificationIds.join(','));
        }
    },

    onBeforeDestroy() {
        this._closeQrModal();
    },

    initializeSpinner($spinner) {
        let $input = $spinner.find('[data-js-spinner-input]'),
            $plus = $spinner.find('[data-js-spinner-plus]'),
            $minus = $spinner.find('[data-js-spinner-minus]'),
            self = this;

        function getSpinnerValue() {
            let val = parseInt($input.val());
            if(isNaN(val) || val < 1) val = 0;
            return val;
        }

        function setSpinnerValue(val) {
            if (val < 1) val = 1;
            if (val > self.model.get('totalQty')) val = self.model.get('totalQty');

            $minus.prop('disabled', val == 1);
            $plus.prop('disabled', val == self.model.get('totalQty'));
            $input.val(val);
        }

        setSpinnerValue(Number(this.options.writeoff) || 1);

        $minus.click(() => {
            let val = getSpinnerValue();
            setSpinnerValue(--val);
        });

        $plus.click(() => {
            let val = getSpinnerValue();
            setSpinnerValue(++val);
        });

        $input.on('change', () => {
            let val = getSpinnerValue();
            setSpinnerValue(val);
        });

        $input.trigger('change');
    },

    _writeOff(qty) {
        const createWriteOffGifts = (qty) => {
            qty = Math.max(0, Number(qty)) || 0;
            const gifts = [];

            if (this.model.get('type') == 1) {
                const gift = _.first(this.model.get('gifts'));
                if (gift) {
                    gifts.push({
                        brand_id: gift.brand_id,
                        parent_gift: null,
                        template_id: null,
                        user_id: gift.user_id,
                        quantity: -Math.min(qty, this.model.get('totalQty')),
                    });
                }
            } else {
                let totalChargeQty = 0,
                    gift = this._findNearestExpiring(totalChargeQty);

                while (qty > 0 && gift) {
                    let capacity = gift.quantity,
                        qtyToCharge = 0;

                    if (qty <= capacity) {
                        qtyToCharge = qty;
                    }
                    else {
                        qtyToCharge = capacity;
                    }

                    qty -= qtyToCharge;

                    gifts.push({
                        brand_id: gift.brand_id,
                        parent_gift: gift.id,
                        template_id: gift.template_id,
                        user_id: gift.user_id,
                        quantity: -qtyToCharge,
                    });

                    // Списываем у родительского подарка
                    totalChargeQty += qtyToCharge;

                    if (qty > 0) gift = this._findNearestExpiring(totalChargeQty);
                    else break;
                }
            }

            return gifts;
        };

        const showGiftModal = gifts => {
            if (gifts.length) {
                const qty = _.reduce(gifts, (qty, gift) => (qty += gift.quantity), 0);
                return new GiftModal({
                        model: new Model({
                            name: this.model.get('name'),
                            thumb: this.model.get('thumb'),
                            type: this.model.get('type'),
                            quantity: qty,
                            totalQty: this.model.get('totalQty'),
                        }),
                        user: this.options.user,
                    })
                    .show()
                    .then(model => {
                        if (model) {
                            const newQty = model.get('quantity');
                            if (qty != newQty) {
                                const gifts = createWriteOffGifts(-newQty);
                                if (_.reduce(gifts, (qty, gift) => (qty += gift.quantity), 0) != newQty) {
                                    return showGiftModal(gifts);
                                } else {
                                    return gifts;
                                }
                            } else {
                                return gifts;
                            }
                        } else {
                            return false;
                        }
                    });
            } else {
                return false;
            }
        };

        qty = Number(qty) || parseInt(this.ui.spinner.find('input').val()) || 1;

        return Promise.resolve()
            .then(() => createWriteOffGifts(qty))
            .then(gifts => showGiftModal(gifts))
            .then(gifts => new Promise((resolve, reject) => {
                if (gifts && gifts.length) {
                    this.trigger('before_writeoff', gifts);
                    if (gifts.length > 1 && this.model.get('type') == 1) {
                        let parentCoinGift = gifts[0],
                            totalCoin = 0;

                        let userDate = new Date().toISOStringTZ();

                        gifts.forEach((parentGift) => {
                            totalCoin += parentGift.quantity;
                            parentGift.user_datetime = userDate;
                        });

                        parentCoinGift.quantity = totalCoin;
                        gifts = [parentCoinGift];
                    }

                    Server.callServer({
                        url: settings.host + settings.serv_gift.create,
                        data: { gifts },
                        type: "POST",
                        success: data => {
                            data.ids.forEach((newId, index) => {
                                gifts[index]['id'] = newId;
                                gifts[index]['created_at'] = B2Cjs.datetimeJSToServer(new Date());
                                gifts[index]['created_by'] = LoginedUserHandler.loginedUser.id;
                            });

                            //// Списываем из общего количества
                            //if (this.model.get('type') == 1) {
                            //    this.model.set('totalQty', this.model.get('totalQty') + _.reduce(gifts, (qty, g) => (qty += g.quantity), 0));
                            if (this.model.get('type') == 2) {
                                // Списываем у родительского подарка
                                _.each(gifts, gift => {
                                    const parentGift = _.find(this.model.get('gifts'), g => g.id == gift.parent_gift);
                                    if (parentGift) {
                                        parentGift.quantity += gift.quantity;
                                    }
                                    this.model.set({ totalQty: this.model.get('totalQty') + gift.quantity });
                                });
                            }

                            this.trigger('writeoff', gifts);

                            resolve(gifts);

                            this.trigger('after_writeoff', gifts);
                        },
                        error: (jqXHR, textStatus, errorThrown) => {
                            this.trigger('after_writeoff', gifts);

                            if (jqXHR && jqXHR.status == 422) {
                                this.showError(jqXHR, textStatus, errorThrown, {
                                    buttons: [{
                                        id: "close",
                                        text: "RELOAD",
                                        icn: "empty"
                                    }]
                                })
                                    .then(() => this.trigger('reload'));
                            } else {
                                this.showError(jqXHR, textStatus, errorThrown);
                            }

                            reject(new AjaxError(jqXHR, textStatus, errorThrown));
                        }
                    });
                }
            }));
    },

    _findNearestExpiring(tmpChargedQty) {
        return _.find(
            this.model.get('giftsExpiring').concat(this.model.get('giftsUnlimited')),
            gift => {
                if (gift.quantity == 0) {
                    return false;
                } else {
                    if (gift.quantity - tmpChargedQty > 0) {
                        return true;
                    }
                    tmpChargedQty -= gift.quantity;
                    return false;
                }
            }
        );
    },

    _closeQrModal() {
        if (this._giftQrModal) {
            this._giftQrModal.close();
            delete this._giftQrModal;
        }
    },

    _openQrModal() {
        this._closeQrModal();

        this._giftQrModal = new GiftQrModal({ model: this.model });

        this._giftQrModal.show();
    }
});
