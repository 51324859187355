import { Collection } from '@b2cmessenger/backbone';
import PhoneModel from './PhoneModel';
import settings from "settings";

const PhoneCollection = Collection.extend({
    model: PhoneModel,
    url: settings.host + settings.serv_user.phones.base,
});

export default PhoneCollection;
