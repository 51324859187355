/**
 * Created by Alex on 28.04.2016.
 */
import PushService from '../PushService';

var Initer = {
    init : function(success, partialSuccess, error) {
        var categoryBase = new CategoryBase();
        var propertyBase = new PropertyBase();
        if (LoginedUserHandler.isUserLogined()) {
            if (partialSuccess && this.getLastUserInitedTime()) {
                partialSuccess();
                this.server_get_user_updates(success, error);
            } else {
                this.server_get_user_updates(function () {
                    partialSuccess && partialSuccess();
                    success();
                }, error);
            }
        } else {
            if (partialSuccess && this.getLastInitedTime()) {
                partialSuccess();
                PushService.unregister();
                this.server_get_updates(success, false, error);
            } else {
                this.server_get_updates(function () {
                    partialSuccess && partialSuccess();
                    PushService.unregister();
                    success();
                }, false, error);
            }
        }            
    },
    server_get_updates : function (callback, skipLocal, error) {
        var that = this;

        if (!skipLocal && !this.getLastInitedTime()) {
            Server.callServer({
                url: pref.init_precache,
                type: "GET",
                dataType: "json",
                success: function (data) {
                    that.on_server_get_updates_ok(data);
                    callback && callback();
                },
                error: function () {
                    that.server_get_updates(callback, true, error)
                },
            });
        } else {
            Server.callServer({
                url: pref.host + pref.init,
                type: "POST",
                data: {
                    datetime: this.getLastInitedTime(),
                    info: userDeviceInfo.getInfo()
                },
                success: function (data) {
                    that.on_server_get_updates_ok(data);
                    if (callback != null)
                        callback();
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    error && error(new AjaxError(jqXHR, textStatus, errorThrown));
                },
            });
        }

        LoyaltyCardBase.update_cards_from_server();
    },
    server_get_user_updates : function (success, error) {
        var that = this,
            timezone = window.Intl && Intl.DateTimeFormat && Intl.DateTimeFormat().resolvedOptions().timeZone || null;

        Server.callServer({
            autoRetryOnUnauthorized: false,
            url: pref.host+pref.serv_user.init_user,
            type: "POST",
            data: {
                datetime : that.getLastUserInitedTime(),
                info: userDeviceInfo.getInfo(),
                timezone: timezone
            },
            success : function(data) {
                that.on_server_get_user_updates_ok(data).then(success || _.noop);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                that.on_server_get_user_updates_error(jqXHR, textStatus, errorThrown, success, error);
            },
        });

        LoyaltyCardBase.update_cards_from_server();
    },
    setLastInitedTime : function(time) {
        localStorage.initer_LastInitedTime = time;
    },
    getLastInitedTime : function() {
        return localStorage.initer_LastInitedTime;
    },
    setLastUserInitedTime : function(time) {
        localStorage.initer_LastUserInitedTime = time;
    },
    getLastUserInitedTime : function() {
        return localStorage.initer_LastUserInitedTime;
    },

    on_server_get_updates_ok: function (data) {
        if (window.DEBUG)
            console.debug('Initer.on_server_get_updates_ok', data);

        var categoryBase = new CategoryBase(),
            propertyBase = new PropertyBase();

        categoryBase.init_local_storage(data.categories);
        propertyBase.init_local_storage(data.properties);

        this.setLastInitedTime(data.updated_at);
    },
    on_server_get_user_updates_ok: function (data) {
        if (window.DEBUG)
            console.debug('Initer.on_server_get_updates_ok', data);

        var categoryBase = new CategoryBase(),
            propertyBase = new PropertyBase();

        categoryBase.init_local_storage(data.categories);
        propertyBase.init_local_storage(data.properties);

        var promise = LoginedUserHandler.init_work_place(data.employers, data.employee_statuses);

        if (PushService.ready) {
            PushService.unregister();
        }

        if (!LoginedUserHandler.isLoyaltyKioskModeEnabledForDefaultWorkPlace()) {
            PushService.init()
                .then((hasPermission) => {
                    if (hasPermission) {
                        PushService.subscribeToNotifications();
                    }
                })
                .catch(e => {
                    if (e != PushService.UnSupported) {
                        window.showError(e);
                    }
                });
        } else {
/////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////
        }

        this.setLastUserInitedTime(data.updated_at);

        notificationMuteCollection.reset(data.mutes);

        return promise;
    },
    on_server_get_user_updates_error: function (jqXHR, textStatus, errorThrown, success, error) {
        if (jqXHR.status == 401) {
            LoginedUserHandler.logout();
            this.init(success, null, error);
        } else {
            console.error('Initer.on_server_get_user_updates_error', jqXHR);
            error && error(new AjaxError(jqXHR, textStatus, errorThrown));
        }
    },
};

window.Initer = Initer;
// export default Initer;