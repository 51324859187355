import React from 'react';
import ReactModalWindow from 'windows/ReactModalWindow';

import './MenuCartError.scss';

import { WindowWrapper } from 'components/window/Window';
import MenuItems from 'components/menu/MenuItems/MenuItems';

const MenuCartError = (props) => {
    const { message, items } = props;

    return (
        <WindowWrapper
            closeWindow={props.closeWindow}
            title={message}
            isModalWindow
            displayContinueButton={false}
            cancelButtonText="CLOSE"
        >
            <MenuItems items={items} className="error" />
        </WindowWrapper>
    );
};

const MenuCartErrorWindow = ReactModalWindow.extend({
    constructor: function MenuCartErrorWindow(props) {
        const options = {
            props,
            component: MenuCartError,
            intent: ReactModalWindow.Intent.Negative,
            name: 'MenuCartError',
        };

        return ReactModalWindow.apply(this, [options]);
    },
});

export default MenuCartErrorWindow;
