import _ from 'underscore';
import { Model } from '@b2cmessenger/backbone';

import Window from 'windows/Window';
import Page from 'pages/Page';

import './Loading.scss';

const LoadingModal = Window.extend({
    scope: 'global',
    windowName: "loading-modal-window",
    className: "loading-modal-window",
    onShow() {
        _.defer(() => this.$el.addClass('open'));

        if (Window.getTopRegularWindow()) {
            _.defer(() => this.$el.addClass('centered'));
        }

        const delayBeforeOpen = Number(this.options.delayBeforeOpen);
        if (delayBeforeOpen) {
            const seconds = (delayBeforeOpen / 1000);
            this.$el.css('transition-delay', seconds.toPrecision(2) + 's');
        }
    },

    onClose(data) {
        return new Promise(
            resolve =>
                Number(this.options.delayBeforeClose) ?
                    _.delay(resolve, Number(this.options.delayBeforeClose)) :
                    resolve()
            )
            .then(() => {
                this.$el.css('transition-delay', '');
                this.$el.addClass('closing');
                return new Promise(resolve => _.delay(resolve.bind(this, data), 500));
            });
    },

    cancel() { }
});

let loadingModal = null;
let loadingModalCount = 0;
let promise = null;

export function showLoading(delay) {
    loadingModalCount++;
    if(loadingModalCount == 1) {
        loadingModal = new LoadingModal({ delayBeforeOpen: delay });
        promise = loadingModal.show();
    }
    return promise;
};

export function hideLoading(delay, data) {
    if (loadingModalCount > 0) {
        loadingModalCount--;
        if (loadingModalCount <= 0) {
            loadingModalCount = 0;

            const p = promise;
            promise = null;

            if (loadingModal) {
                loadingModal.options.delayBeforeClose = delay;
                loadingModal.close(data);
            }
            loadingModal = null;

            return p;
        }
    }

    return promise;
};

export default LoadingModal;
