import React from 'react';
import './Tabs.scss';

export const Tab = (props) => {
    const className = React.useMemo(
        () =>
            ['tab', 'widget', props.id && `tab-${props.id}`, props.active && 'active', props.className]
                .filter(Boolean)
                .join(' '),
        [props.id, props.active, props.className]
    );

    return (
        <div className={className}>
            <button
                data-role="none"
                onClick={props.onClick && props.onClick.bind(null, props.id)}
                disabled={props.disabled}
            >
                <span className={props.iconClassName || 'tab-icon'}>
                    <i className={props.icon} />
                </span>
                <span className={props.titleClassName || 'tab-title'}>{props.title}</span>
            </button>
        </div>
    );
};

const Tabs = (props) => {
    const className = React.useMemo(() => ['tabs', 'widget', props.className].filter(Boolean).join(' '), [
        props.className,
    ]);

    return <div className={className}>{props.children}</div>;
};

export default Tabs;
