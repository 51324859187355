import React, { useEffect, useState, useCallback, useMemo } from 'react';

import withStore from 'components/withStore';
import { connect } from 'react-redux';

import ReactPage from 'pages/ReactPage';
import Page, { PageContent, PageFooter, PageHeader } from 'components/page/Page';
import Header from 'components/UI/Header/Header';
import { BackButton } from 'components/UI/Button/Button';
import Footer from 'components/UI/Footer/Footer';
import TextButton from 'components/UI/TextButton';
import MenuCart from 'components/menu/MenuCart/MenuCart';
import Spinner from 'components/UI/Spinner/Spinner';
import MenuCartEmpty from 'components/menu/MenuCart/MenuCartEmpty';
import {
    getMenuCartItemsAsArray,
    getMenuCartLoadingOrder,
    getMenuCartOrderParams,
    getMenuCartTotalPrice,
    getMenuExtendedCartItemsSortedByOrder,
    getMenuMissingCartItemIds,
} from 'store/menu/cart/selectors';
import {
    getMenuCartDistinctRegularItems,
    getMenuCurrency,
    getMenuLoadingItems,
    getMenuLoadingItemsError,
} from 'store/menu/selectors';
import Price from 'components/UI/Price/Price';
import './MenuCart.scss';
import { loadDetailedMenuItems } from 'store/menu/actions';
import {
    collapseCartItems,
    removeFromCart,
    resetCart,
    restoreCartFromDraft,
    updateCartItem,
    updateCartItemAdditionQuantity,
    updateCartItemQuantity,
} from 'store/menu/cart/actions';
import MenuCartItemCustomizeWindow from 'components/window/MenuCartItemCustomize/MenuCartItemCustomize';
import MenuOrderConfirmWindow from 'components/window/MenuOrderCofirm/MenuOrderConfirm';
import { updateMenuInfo } from 'store/menu/info/actions';
import ConfirmModalWindow from 'windows/Modal/Confirm';
import useInitializeMenu from 'hooks/useInitializeMenu';
import LoadingError from 'components/menu/LoadingError/LoadingError';

const MenuCartContainer = (props) => {
    const {
        extendedCartItems = [],
        missingIds = [],
        placeId,
        loadingItems,
        loadingItemsError,
        loadingOrder,
        totalPrice,
        currency: currencyFromProps,
        updateCartItemQuantity,
        loadDetailedMenuItems,
        updateCartItemAdditionQuantity,
        updateCartItem,
        collapseCartItems,
        orderParams,
        updateMenuInfo,
        isActive,
    } = props;

    const isEditingOrder = useMemo(() => Boolean(orderParams && orderParams.id), [orderParams]);

    const [currency, setCurrency] = useState();
    const { readyToInitialize } = useInitializeMenu({ placeId, isActive });

    const onHide = useCallback(
        _.debounce(() => {
            collapseCartItems();
        }, 200),
        [collapseCartItems]
    );

    const onShow = useCallback(() => {
        if (readyToInitialize) {
            readyToInitialize(true);
        }

        if (!currency) {
            const firstItem = extendedCartItems.find((c) => c && c.menuItem && c.menuItem.currency);
            if (firstItem && firstItem.menuItem.currency) {
                updateMenuInfo({ currency: firstItem.menuItem.currency });
            }
        }
    }, [readyToInitialize, currency, extendedCartItems]);

    const loadMissingItems = useCallback(() => {
        if (missingIds && missingIds.length) {
            loadDetailedMenuItems(missingIds, placeId);
        }
    }, [missingIds, placeId]);

    useEffect(() => setCurrency(currencyFromProps), [currencyFromProps]);
    useEffect(() => {
        if (!loadingItems && !loadingItemsError) {
            loadMissingItems();
        }
    }, [loadMissingItems, loadingItems, loadingItemsError]);

    const onSelectItem = useCallback(
        async (extendedCartItem) => {
            const { menuItem, cartItem, uniqueId } = extendedCartItem;

            const properties = _(cartItem.properties).map((value, key) => ({ value, name: key }));
            const additions = _(cartItem.additions).map((value, key) => ({ id: Number(key), count: value }));

            const menuCartItem = {
                properties,
                additions,
                comment: cartItem.comment,
                count: cartItem.quantity,
            };

            const result = await new MenuCartItemCustomizeWindow({ menuItem, menuCartItem, placeId }).show();

            if (result) {
                const { count, additions, properties, comment } = result;

                const newCartItem = {
                    ...cartItem,
                    quantity: count,
                    additions: _(additions).reduce((memo, a) => {
                        _.extend(memo, { [a.id]: a.count });
                        return memo;
                    }, {}),
                    properties: _(properties).reduce((memo, p) => {
                        _.extend(memo, { [p.name]: p.value });
                        return memo;
                    }, {}),
                    comment,
                };

                updateCartItem(newCartItem, uniqueId);
            }
        },
        [updateCartItem]
    );

    const sendOrder = useCallback(() => {
        new MenuOrderConfirmWindow({
            orderId: orderParams && orderParams.id,
            orderNumber: orderParams && orderParams.number,
            selectedUser: (orderParams && orderParams.user) || null,
        }).show();
    }, [orderParams]);

    const onChangeItemCount = useCallback(({ item, count }, id) => {
        updateCartItemQuantity(id, count);
    }, []);

    const onChangeAdditionItemCount = useCallback(({ item, count }, id) => {
        updateCartItemAdditionQuantity(id, item.id, count);
    }, []);

    const goToMainMenuPage = useCallback(() => app.controller.goToMenuPage({ placeId }), [placeId]);

    const disabled = loadingItems || loadingOrder || extendedCartItems.length === 0 || loadingItemsError;

    let content = null;
    if (loadingItems || loadingOrder) {
        content = <Spinner />;
    } else if (extendedCartItems.length === 0) {
        content = <MenuCartEmpty goToMainMenuPage={goToMainMenuPage} />;
    } else if (loadingItemsError) {
        content = <LoadingError displayRetryButton onClickRetryButton={loadMissingItems} />;
    } else {
        content = (
            <MenuCart
                cartItems={props.cartItems}
                items={props.distinctRegularItems}
                disabled={disabled}
                onSelectItem={onSelectItem}
                goToMainMenuPage={goToMainMenuPage}
                isEditingOrder={isEditingOrder}
                onChangeItemCount={onChangeItemCount}
                onChangeAdditionItemCount={onChangeAdditionItemCount}
            />
        );
    }

    const onClose = useCallback(async () => {
        try {
            if (orderParams && orderParams.number) {
                const confirm = await new ConfirmModalWindow({
                    title: `Do you really want to stop editing order #${orderParams.number}?`,
                }).show();

                if (confirm) {
                    props.restoreCartFromDraft();
                    props.cancel();
                }
            } else {
                props.cancel();
            }
        } catch (e) {
            showError(e);
        }
    }, [orderParams, props.cancel, props.restoreCartFromDraft]);

    return (
        <Page instance={props.__page__} onHide={onHide} onShow={onShow}>
            <PageHeader className="a">
                <Header>
                    <BackButton onClick={props.goBack} />
                    <h1 className="title">{isEditingOrder ? `Edit order #${orderParams.number}` : 'Menu cart'}</h1>
                    {Boolean(totalPrice) && <Price value={totalPrice} currency={currency} />}
                </Header>
            </PageHeader>
            <PageContent className="scrollable grow with-standard-padding">{content}</PageContent>
            <PageFooter>
                <Footer>
                    <TextButton onClick={onClose}>{isEditingOrder ? `CANCEL` : 'CLOSE'}</TextButton>
                    <TextButton onClick={sendOrder} disabled={disabled}>
                        {isEditingOrder ? `SAVE` : 'PLACE AN ORDER'}
                    </TextButton>
                </Footer>
            </PageFooter>
        </Page>
    );
};

const ConnectedMenuCartContainer = withStore(
    connect(
        (state) => ({
            missingIds: getMenuMissingCartItemIds(state),
            extendedCartItems: getMenuExtendedCartItemsSortedByOrder(state),
            cartItems: getMenuCartItemsAsArray(state),
            distinctRegularItems: getMenuCartDistinctRegularItems(state),
            loadingItems: getMenuLoadingItems(state),
            currency: getMenuCurrency(state),
            totalPrice: getMenuCartTotalPrice(state),
            loadingOrder: getMenuCartLoadingOrder(state),
            orderParams: getMenuCartOrderParams(state),
            loadingItemsError: getMenuLoadingItemsError(state),
        }),
        {
            updateCartItem,
            removeFromCart,
            resetCart,
            loadDetailedMenuItems,
            updateCartItemQuantity,
            updateCartItemAdditionQuantity,
            collapseCartItems,
            updateMenuInfo,
            restoreCartFromDraft,
        }
    )(MenuCartContainer)
);

const MenuCartPage = ReactPage.extend({
    immediateRenderOnShow: true,
    attributes: {
        id: 'page_menu_cart',
    },
    className: 'menu-cart-page',
    component: ConnectedMenuCartContainer,
});

export default MenuCartPage;
