import { Required, Optional, ItemView, ViewModel, Model } from '@b2cmessenger/backbone';
import AjaxError from 'utils/AjaxError';

import TaskTargetModel from 'models/TaskTargetModel';

import ConfirmModalWindow from 'windows/Modal/Confirm';
import LoginedUserModel from 'models/LoginedUserModel';
import MessageReportModalWindow from '../MessageReportModal/MessageReportModal';

import template from './MessageMenu.jade';
import './MessageMenu.scss';
import ReservationDeclineModalWindow from 'widgets/Reservation/ReservationDeclineModal/ReservationDeclineModal';
import ReservationModel from 'models/ReservationModel';
import ReservationAcceptModalWindow from 'widgets/Reservation/ReservationAcceptModal/ReservationAcceptModal';
import ReservationEditorWindow from 'windows/ReservationEditor/ReservationEditor';

/** @typedef {import('./MessageMenu')} MessageMenuInterface */
/** @type {typeof import('./MessageMenu').properties} */
// @ts-ignore
const properties = ItemView.properties;

/** @type {typeof import('./MessageMenu').options} */
// @ts-ignore
const options = ItemView.options;

/** @type {typeof import('./MessageMenu').events} */
// @ts-ignore
const events = ItemView.events;

@options({
    model: Required,
    viewModel: Optional,
    loginedUserModel: Required,
    placeModel: Required,
    toUserModel: Optional,
    reservationModel: Optional
})
@events({
    /** Menu requested close */
    'close': () => {
    },

    /** User clicked on menu entry
     * @param {string} entryName - Name of the entry */
    'entry:click': (entryName) => {
    },

    /** Action related to menu entry finished sucessfully
     * @param {string} entryName - Name of the entry
     * @param {any} [actionResult] - Result if any with which action finished */
    'entry:finish': (entryName, actionResult) => {
    },

    /** Action related to menu entry failed
     * @param {string} entryName - Name of the entry
     * @param {Error | string} [actionError] */
    'entry:fail': (entryName, actionError) => {
    },

    /** User clicked on menu entry {entryName} */
    'entry:{entryName}:click': () => {
    },

    /** Action related to menu entry {entryName} finished sucessfully
     * @param {any} [actionResult] - Result if any with which action finished */
    'entry:{entryName}:finish': (actionResult) => {
    },

    /** Action related to menu entry {entryName} failed
     * @param {Error | string} [actionError] */
    'entry:{entryName}:fail': (actionError) => {
    }
})
@properties({
    className: 'widget message-menu-widget',
    template,

    ui: {
        blockNotifications: '[data-js-block-notifications]',
        unblockNotifications: '[data-js-unblock-notifications]',
        report: '[data-js-report]',
        edit: '[data-js-edit]',
        delete: '[data-js-delete]',
        gift: '[data-js-gift]',
        broadcast: '[data-js-broadcast]',
        accept: '[data-js-accept]',
        decline: '[data-js-decline]',
        suggestChange: '[data-js-suggest-change]'
    },

    bindings: {
        '@ui.blockNotifications': 'classes:{hidden:isMuted},disabled:any(isMuted,disabled)',
        '@ui.unblockNotifications': 'classes:{hidden:not(isMuted)},disabled:any(not(isMuted),disabled)',
        '@ui.report': 'disabled:disabled',
        '@ui.edit': 'disabled:disabled',
        '@ui.delete': 'disabled:disabled',
        '@ui.gift': 'disabled:disabled',
        '@ui.broadcast': 'disabled:disabled',
        '@ui.accept': 'disabled:disabled',
        '@ui.decline': 'disabled:disabled'
    },

    events: {
        'click @ui.blockNotifications'() {
            this.trigger('entry:click', 'blockNotifications');
            this.viewModel.set({ disabled: true });
            this.model.muteNotifications()
                .then(result => {
                    this.viewModel.set({ disabled: false });
                    this.trigger('entry:finish', 'blockNotifications', result)
                })
                .catch(e => {
                    this.viewModel.set({ disabled: false })
                    this.showError(e);
                    this.trigger('entry:fail', 'blockNotifications', e);
                });
        },
        'click @ui.unblockNotifications'() {
            this.trigger('entry:click', 'unblockNotifications');
            this.viewModel.set({ disabled: true });
            this.model.unmuteNotifications()
                .then(result => {
                    this.viewModel.set({ disabled: false })
                    this.trigger('entry:finish', 'unblockNotifications', result)
                })
                .catch(e => {
                    this.viewModel.set({ disabled: false })
                    this.showError(e);
                    this.trigger('entry:fail', 'unblockNotifications', e);
                });
        },
        'click @ui.report'() {
            this.trigger('entry:click', 'report');
            new MessageReportModalWindow({
                model: this.model
            })
                .show()
                .then(result => this.trigger('entry:finish', 'report', result))
                .catch(e => this.trigger('entry:fail', 'report', e));
        },
        'click @ui.edit'() {
            this.trigger('entry:click', 'edit');
            if (this.model.get('isComment')) {
                this.trigger('entry:finish', 'edit');
            } else {
                if (this.model.get('isTask') ||
                    (this.model.get('isReservation') && this.reservationModel.get('isNewReservation'))) {
                    app.controller.goToTaskEditorPage({
                        task: this.model.toJSON({ b2cmessage: true }),
                        place: this.placeModel.toJSON(),
                        toUser: this.toUserModel && this.toUserModel.toJSON({ computed: true }),
                        onSave: (task) => {
                            this.model.set(task);
                            //@ts-ignore
                            app.controller._goBackPage();
                        }
                    });

                    this.trigger('entry:finish', 'edit');
                }
            }
        },
        'click @ui.suggestChange'() {
            new ReservationEditorWindow({
                model: this.reservationModel
            })
                .show()
                .then(result => {
                    this.trigger('entry:finish', 'edit', result);
                });
        },
        'click @ui.delete'() {
            this.trigger('entry:click', 'delete');

            if (this.model.get('isBroadcast')) {
                this.showError('You can pause broadcast campaign instead',
                    'Task linked to active broadcast cannot be deleted.');

                this.trigger('entry:fail', 'delete', new Error('Task linked to active broadcast cannot be deleted.'));
                return;
            }

            new ConfirmModalWindow({
                title: 'Do you really want to delete this message?'
            })
                .show()
                .then(confirm => {
                    if (confirm) {
                        this.viewModel.set({ disabled: true });
                        this.model.destroy({
                            wait: true,
                            success: () => {
                                this.viewModel.set({ disabled: false });
                                this.trigger('entry:finish', 'delete', true);
                            },
                            error: (m, jqXHR) => {
                                this.showError(jqXHR);
                                this.viewModel.set({ disabled: false });
                                this.trigger('entry:fail', 'delete', new AjaxError(jqXHR));
                            }
                        })
                    } else {
                        this.trigger('entry:finish', 'delete', false);
                    }
                });
        },
        'click @ui.gift'() {
            this.trigger('entry:click', 'gift');
            this.trigger('entry:finish', 'gift');
        },
        'click @ui.broadcast'() {
            this.trigger('entry:click', 'broadcast');
            if (this.model.get('isBroadcast')) {
                app.controller.goToBroadcastCampaignPage({
                    campaign: this.model.get('task_target_id')
                });
                this.trigger('entry:finish', 'broadcast');
            } else {
                this.trigger('entry:fail', 'broadcast', new Error('message is not a broadcast'));
            }
        },
        'click @ui.accept'() {
            this.trigger('entry:click', 'accept');
            new ReservationAcceptModalWindow({
                model: this.reservationModel
            })
                .show()
                .then(result => this.trigger('entry:finish', 'accept', result))
                .catch(e => this.trigger('entry:fail', 'accept', e));
        },
        'click @ui.decline'() {
            this.trigger('entry:click', 'decline');
            const isUserLoggedIn = this.loginedUserModel.get('isLoggedIn');
            const isAuthor = isUserLoggedIn && this.reservationModel && this.reservationModel.get('user_id') == this.loginedUserModel.id;
            const isReservationManager = isUserLoggedIn
                && this.loginedUserModel.hasRoleInPlace(this.model.get('place_id'),
                    LoginedUserModel.Roles.RESERVATION_MANAGER) && !isAuthor;

            new ReservationDeclineModalWindow({
                model: this.reservationModel,
                isAuthor,
                isReservationManager
            })
                .show()
                .then(result => this.trigger('entry:finish', 'decline', result))
                .catch(e => this.trigger('entry:fail', 'decline', e));
        }
    },

    modelEvents: {
        'change:isTask change:isBroadcast change:isFromBussines change:user_id change:place_id'() {
            this._debouncedRender();
        }
    },

    bindingSources() {
        return { loginedUserModel: this.loginedUserModel };
    },

    templateHelpers() {
        const isUserLoggedIn = this.loginedUserModel.get('isLoggedIn');
        const placeId = this.model.get('place_id');
        const Roles = LoginedUserModel.Roles;

        const isNewReservation = this.reservationModel && this.reservationModel.get('isNewReservation');
        const isReservationInActiveState = this.reservationModel && this.reservationModel.get('isInActiveState');
        const isAuthor = isUserLoggedIn && this.reservationModel &&
            this.reservationModel.get('user_id') == this.loginedUserModel.id;
        const isReservationManager = isUserLoggedIn
            && this.loginedUserModel.hasRoleInPlace(placeId, Roles.RESERVATION_MANAGER) && !isAuthor;
        const isReservationAcceptable = this.reservationModel && (
            (isAuthor && this.reservationModel.get('isAcceptableForAuthor'))
            ||
            (isReservationManager && this.reservationModel.get('isAcceptableForBusiness')));
        const isUserCanGift = isUserLoggedIn && this.loginedUserModel.hasRoleInPlace(placeId, Roles.GIFT_CREATOR) &&
            !isAuthor;
        const isReservationCancellable = this.reservationModel && this.reservationModel.get('isRejectable');
        const isReservationChangeComment = this.model.get('isReservationChangeComment');
        const isReservationLastChangeComment = isReservationChangeComment && this.reservationModel
            && this.reservationModel.get('last_change_comment_id') == this.model.get('id');

        let reservationCancelText = 'Cancel';

        if (this.reservationModel && (
            (isAuthor && ReservationModel.Status.ClientSuggestedChanges == this.reservationModel.get('status'))
            ||
            (isReservationManager && ReservationModel.Status.BusinessSuggestedChanges == this.reservationModel.get('status'))
        )) {
            reservationCancelText = 'Decline';
        }

        return {
            isUserLoggedIn,
            isUserCanEdit: isUserLoggedIn && (
                this.model.get('user_id') == this.loginedUserModel.id
                ||
                this.model.get('isFromBussines')
                && this.loginedUserModel.hasRoleInPlace(placeId, Roles.MESSAGE_MODERATOR)
            ),
            isUserCanViewBroadcastCampaign: isUserLoggedIn && this.loginedUserModel.isEmployee(placeId),

            isUserCanEditReservation: this.model.get('isReservation') && isReservationInActiveState && (
                isAuthor || isReservationManager
            ),
            isReservationAuthor: isAuthor,
            isUserCanGift,
            isReservationManager,
            isReservationInActiveState,
            isReservationAcceptable,
            isReservationCancellable,
            isReservationLastChangeComment,
            isNewReservation,
            reservationCancelText
        };
    }
})
class MessageMenuView extends ItemView {
    initialize() {
        /** @type {MessageMenuInterface} */
            // @ts-ignore
        const self = this;

        if (!this.viewModel) {
            self.viewModel = new ViewModel;
        }

        this.loginedUserModel = self.options.loginedUserModel;
        this.placeModel = self.options.placeModel;
        this.toUserModel = self.options.toUserModel;
        this.reservationModel = self.options.reservationModel;

        this._debouncedRender = _.debounce(() => {
            if (!this.isDestroyed && this.isRendered) {
                this.render()
            }
        }, 1);

        this.listenTo(this.loginedUserModel, 'change:isLoggedIn change:workplaceRoles', this._debouncedRender);
        if (this.reservationModel) {
            this.listenTo(this.reservationModel, 'change:last_change_comment_id change:status', this._debouncedRender);
        }

        this.listenTo(this, 'entry:click', (entryName) => this.trigger(`entry:${entryName}:click`));
        this.listenTo(this, 'entry:finish', (entryName, result) => this.trigger(`entry:${entryName}:finish`, result));
        this.listenTo(this, 'entry:fail', (entryName, error) => this.trigger(`entry:${entryName}:fail`, error));
    }
}

export default MessageMenuView;
