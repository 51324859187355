import { Model, Collection, CollectionView, ItemView } from '@b2cmessenger/backbone';
import Page from 'pages/Page';
import UserModel from 'models/UserModel';
import settings from 'settings';

import HeaderView from 'widgets/Header/Header';
import EvidenceWidget from 'widgets/Evidence/Evidence';
import EvidenceCollection from 'models/EvidenceCollection';
import { LoyaltyCardCollectionView } from 'widgets/LoyaltyCard/LoyaltyCard';
import GiftTemplates from 'windows/GiftTemplates/GiftTemplates';
import GiftsList from 'windows/Gifts/GiftsList';
import GiftModal from 'windows/Modal/Gift';
import InfoModal from 'windows/Modal/Info';

import template from './OtherUser.jade';
import './OtherUser.scss';
import ViewWithWindows from 'traits/ViewWithWindows';

let EvidenceCollectionWidget = CollectionView.extend({
    childView: EvidenceWidget,
    className: "evidence-collection-widget widget",
    filter: m => !m.get('isDraft')
});

const withWindowsTrait = {
    Trait: ViewWithWindows,
    options: {
        windowMap: [
            {
                cls: GiftTemplates,
                trigger() {
                    this.on_btn_gift_add_click();
                }
            },
            {
                cls: GiftsList,
                trigger() {
                    this.on_btn_gifts_list_click();
                }
            }
        ],
    }
};

let OtherUserPage = Page.extendWithTraits([withWindowsTrait], {
    getPlaceId() {
        return pageOtherUser.place && pageOtherUser.place.id;
    },

    getUserId() {
        return pageOtherUser.user && pageOtherUser.user.id;
    },

    template: template,

    attributes: {
        'id': "page_other_user"
    },

    className: "other-user-page",

    regions: {
        header: '[data-js-header]',
        loyaltyCards: '[data-js-loyalty-cards]',
        selfie: '[data-js-selfie]',
        otherevidence: '[data-js-otherevidence]',
        receipt: '[data-js-receipt]',
    },

    ui: {
        content: '[data-js-content]'
    },

    events: {
        'click .btn_gifts_list'     : 'on_btn_gifts_list_click',
        'click .btn_gift_add'       : 'on_btn_gift_add_click',
        'click [data-js-btn-message]'() {
            app.controller.goToTaskEditorPage({
                new: true,
                place: pageOtherUser.place,
                toUser: pageOtherUser.user,
                onSave: (task_for_send, to_user) => {
                    app.controller.goToPlaceTasksPage({
                        replace: true,
                        place: pageOtherUser.place,
                        filter: to_user ? {
                            to_user_id: to_user.id,
                            sentToName: new UserModel(to_user).get('name')
                        } : null,
                        addUser: to_user,
                        addMessage: task_for_send,
                        scrollToTask: task_for_send.id
                    });
                }
            });
        }
    },

    initialize() {
        this.loyaltyCardsCollection = new Collection();
        this.evidenceCollection = new EvidenceCollection([], {});
        this.otherPhotoEvidenceCollection = new EvidenceCollection([], {});
        this.receiptEvidenceCollection = new EvidenceCollection([], {});

        pageOtherUser.setOptions(this.$el, this.evidenceCollection, this.otherPhotoEvidenceCollection, this.receiptEvidenceCollection);
    },

    show(options) {
        if (options.place && options.user) {
            pageOtherUser.set_show_params({
                place: options.place,
                user: options.user,
                is_verified: options.is_verified,
                anonym: options.anonym
            });

            return Page.prototype.show.apply(this, arguments);

        } else if ((options.placeId || options.place && options.place.id) && options.userId) {
            const placeId = options.placeId || options.place && options.place.id,
                workPlace = LoginedUserHandler.get_work_place_by_id(placeId);

            if (workPlace) {
                this.showLoading();

                Server.callServer({
                    url: settings.host + settings.serv_user.search,
                    type: 'POST',
                    data: {
                        id: options.userId,
                    },
                    success: data => {
                        this.hideLoading();
                        const user = data.length && data[0];

                        if (user) {
                            pageOtherUser.set_show_params({
                                place: workPlace,
                                user,
                                is_verified: options.is_verified,
                                anonym: options.anonym
                            });
                            return Page.prototype.show.apply(this, arguments);
                        }
                    },
                    error: (jqXHR, textStatus, errorThrown) => {
                        this.hideLoading();
                        this.showError(jqXHR, textStatus, errorThrown);
                    },
                })
            }
        }
    },

    on_btn_gift_add_click() {
        pageOtherUser.on_btn_gift_add_click();
    },

    on_btn_gifts_list_click() {
        pageOtherUser.on_btn_gifts_list_click();
    },

    onRender() {
        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: 'User info'
        });

        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.header.show(headerView);

        this.selfie.show(new EvidenceCollectionWidget({ collection: this.evidenceCollection }));
        this.otherevidence.show(new EvidenceCollectionWidget({ collection: this.otherPhotoEvidenceCollection }));
        this.receipt.show(new EvidenceCollectionWidget({ collection: this.receiptEvidenceCollection }));

        pageOtherUser.loyaltyCards = this.loyaltyCards;
    },

    onShow() {
        pageOtherUser.onShow();
        return Page.prototype.onShow.call(this);
    },
});

var pageOtherUser = {
    page_id: 'page_other_user',
    inited: false,

    user: null,
    place: null,
    is_verified: null,
    verification_list: null,

    checkins: null,
    business_checkins: null,

    jq_page_other_user: null,

    jqAvatar: null,
    jqName: null,

    jq_verification_cont: null,
    jq_verification_methods_cont: null,

    jq_gift_contr_cont: null,

    jt_btn_verify_checkin: null,
    jt_btn_verify_loyaltycard: null,
    jt_btn_verify_selfie: null,
    jt_btn_verify_receipt: null,
    jt_btn_verify_otherevidence: null,
    jt_btn_verify_business: null,

    jq_list_checkin: null,
    jq_list_loyaltycard: null,
    jq_list_business: null,

    initialize: function () {
        if (this.inited)
            return;
        var that = this;

        this.jq_page_other_user = $('#' + this.page_id);

        this.init_content();

        this.closure_callback_business_validate = function (data) { that.callback_business_validate(data); };

        this.inited = true;
    },
    setOptions: function ($el, evidenceCollection, otherPhotoEvidenceCollection, receiptEvidenceCollection) {
        this.jq_page_other_user = $el;
        this.loyaltyCardsCollection = new Collection();
        this.evidenceCollection = evidenceCollection;
        this.otherPhotoEvidenceCollection = otherPhotoEvidenceCollection;
        this.receiptEvidenceCollection = receiptEvidenceCollection;
    },
    init_content: function () {
        var that = this;

        var jqContent = this.jq_page_other_user.find('.content');

        var jq_avatar_name_cont = jqContent.children('.avatar_name_cont');
        this.jqAvatar = jq_avatar_name_cont.find('.avatar');
        this.jqName = jq_avatar_name_cont.find('.name');

        this.jq_gift_contr_cont = jqContent.children('.gift_buttons_container');

        this.init_verification_elements(jqContent);
    },
    init_verification_elements: function (jqContent) {
        var that = this;

        this.jq_verification_cont = jqContent.children('.verification_cont');
        this.jq_verification_cont.click(function () { that.on_verification_cont_click(); });

        var jq_verification_methods_cont = jqContent.children('.verification_methods_cont');
        this.init_verification_methods_cont(jq_verification_methods_cont);
    },
    init_verification_methods_cont: function (jq_verification_methods_cont) {
        var that = this;
        this.jq_verification_methods_cont = jq_verification_methods_cont;
        var jq_buttons = jq_verification_methods_cont.children('.buttons');

        var closure_on_btn_verify_click = function () { that.on_btn_verify_click($(this)) };

        this.jt_btn_verify_checkin = jq_buttons.children('.btn_verify.checkin');
        this.jt_btn_verify_checkin.click(closure_on_btn_verify_click);

        this.jt_btn_verify_loyaltycard = jq_buttons.children('.btn_verify.loyaltycard');
        this.jt_btn_verify_loyaltycard.click(closure_on_btn_verify_click);

        const server_isverified_list = _.debounce(this.server_isverified_list.bind(this), 200);

        this.loyaltyCardsCollection.on('update reset change:status', server_isverified_list);
        this.evidenceCollection.on('remove reset change:status', server_isverified_list);
        this.otherPhotoEvidenceCollection.on('remove reset change:status', server_isverified_list);
        this.receiptEvidenceCollection.on('remove reset change:status', server_isverified_list);

        this.jt_btn_verify_selfie = jq_buttons.children('.btn_verify.selfie');
        this.jt_btn_verify_selfie.click(closure_on_btn_verify_click);

        this.jt_btn_verify_receipt = jq_buttons.children('.btn_verify.receipt');
        this.jt_btn_verify_receipt.click(closure_on_btn_verify_click);

        this.jt_btn_verify_otherevidence = jq_buttons.children('.btn_verify.otherevidence');
        this.jt_btn_verify_otherevidence.click(closure_on_btn_verify_click);

        this.jt_btn_verify_business = jq_buttons.children('.btn_verify.business');
        this.jt_btn_verify_business.click(closure_on_btn_verify_click);

        this.jq_lists = jq_verification_methods_cont.children('.lists');
        this.jq_list_checkin = this.jq_lists.children('.list.checkin');
        this.jq_list_loyaltycard = this.jq_lists.children('.list.loyaltycard');
        this.jq_list_business = this.jq_lists.children('.list.business');
    },

    set_avatar: function () {
        if (this.options.anonym || this.user.avatarThumb == null) {
            this.jqAvatar.addClass('noavatar');
            this.jqAvatar.css('background-image', 'url("' + B2CUser.const.avatar.noavatar + '")');
        } else {
            this.jqAvatar.removeClass('noavatar');
            this.jqAvatar.css('background-image', 'url("' + this.user.avatarThumb + '")');
        }
    },
    set_name: function () {
        if (!this.options.anonym) {
            var name = this.user.firstname;
            if (this.user.lastname) name += ' ' + this.user.lastname;
            this.jqName.text(name || ("Unknown person" + (this.user.id ? ` (#${this.user.id})` : '')));
        } else {
            this.jqName.text("Anonym");
        }
    },
    set_verification_cont: function () {
        if (this.is_verified) {
            this.jq_verification_cont.removeClass('unverified');
            this.jq_verification_cont.addClass('verified');
        } else {
            this.jq_verification_cont.removeClass('verified');
            this.jq_verification_cont.addClass('unverified');
        }
    },
    set_verification_methods_buttons: function () {
        if (this.verification_list == null)
            return;

        if (this.verification_list.checkin > 0)
            this.jt_btn_verify_checkin.addClass('verified');
        else
            this.jt_btn_verify_checkin.removeClass('verified');

        if (this.verification_list.loyaltyCard > 0)
            this.jt_btn_verify_loyaltycard.addClass('verified');
        else
            this.jt_btn_verify_loyaltycard.removeClass('verified');

        if (this.verification_list.evidece_selfie > 0)
            this.jt_btn_verify_selfie.addClass('verified');
        else
            this.jt_btn_verify_selfie.removeClass('verified');

        if (this.verification_list.evidece_receipt > 0)
            this.jt_btn_verify_receipt.addClass('verified');
        else
            this.jt_btn_verify_receipt.removeClass('verified');

        if (this.verification_list.evidece_other > 0)
            this.jt_btn_verify_otherevidence.addClass('verified');
        else
            this.jt_btn_verify_otherevidence.removeClass('verified');

        if (this.verification_list.business_checkin > 0)
            this.jt_btn_verify_business.addClass('verified');
        else
            this.jt_btn_verify_business.removeClass('verified');
    },

    clear: function () {
        this._clear_vars();
        this._clear_widgets();
    },
    _clear_vars: function () {
        this.user = null;
        this.place = null;
        this.is_verified = null;
        this.verification_list = null;

        this.checkins = null;
        this.loyaltyCardsCollection.reset();
        this.evidenceCollection.reset();
        this.otherPhotoEvidenceCollection.reset();
        this.receiptEvidenceCollection.reset();

        this.business_checkins = null;
    },
    _clear_widgets: function () {
        this.jq_verification_cont.removeClass('checked');
        this.jq_verification_methods_cont.removeClass('hidden');

        this.jt_btn_verify_checkin.removeClass('set').removeClass('verified-set').removeClass('verified');
        this.jt_btn_verify_loyaltycard.removeClass('set').removeClass('verified-set').removeClass('verified');
        this.jt_btn_verify_selfie.removeClass('set').removeClass('verified-set').removeClass('verified');
        this.jt_btn_verify_receipt.removeClass('set').removeClass('verified-set').removeClass('verified');
        this.jt_btn_verify_otherevidence.removeClass('set').removeClass('verified-set').removeClass('verified');
        this.jt_btn_verify_business.removeClass('set').removeClass('verified-set').removeClass('verified');

        this.jq_list_checkin.children().remove();
        this.jq_list_business.children().remove();
    },

    add_checkins_on_page: function (checkins) {
        for (var i = 0; i < checkins.length; i++) {
            this.add_checkin_on_page(checkins[i]);
        }
    },
    add_checkin_on_page: function (checkin, el) {
        const elem = el || this.jq_list_checkin;
        const datetime = B2Cjs.datetimeServerToJS(checkin.created_at);
        if (datetime) {
            var html =
                '<div class="input-group"><div class="date">' +
                datetime.toLocaleDateString(
                    [], {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    }) +
                '</div><div class="time">' +
                datetime.toLocaleTimeString() +
                '</div></div>';
            $(html).prependTo(elem);
        }
    },

    add_business_checkins_on_page: function (checkins) {
        for (var i = 0; i < checkins.length; i++) {
            this.add_checkin_on_page(checkins[i], this.jq_list_business);
        }
    },

    server_isverified_list: function () {
        if (this.closure_on_server_isverified_list_ok == null) {
            var that = this;

            this.closure_on_server_isverified_list_ok = function (data) { that.on_server_isverified_list_ok(data) };
            this.closure_on_server_isverified_list_error = function (jqXHR, textStatus, errorThrown) { that.on_server_isverified_list_error(jqXHR, textStatus, errorThrown) };
        }

        B2CPlace.server_isverified_list(this.user.id, this.place.id, this.closure_on_server_isverified_list_ok, this.closure_on_server_isverified_list_error);
    },

    server_load_checkins: function () {
        if (this.closure_on_server_load_checkins_ok == null) {
            var that = this;

            this.closure_on_server_load_checkins_ok = function (data) { that.on_server_load_checkins_ok(data) };
            this.closure_on_server_load_checkins_error = function (jqXHR, textStatus, errorThrown) { that.on_server_load_checkins_error(jqXHR, textStatus, errorThrown) };
        }
        B2CPlace.server_checkin_hist(this.user.id, this.place.id, this.closure_on_server_load_checkins_ok, this.closure_on_server_load_checkins_error);
    },
    server_load_loyalty_cards: function () {
        if (this.closure_on_server_load_loyalty_cards_ok == null) {
            var that = this;

            this.closure_on_server_load_loyalty_cards_ok = function (data) { that.on_server_load_loyalty_cards_ok(data) };
            this.closure_on_server_load_loyalty_cards_error = function (jqXHR, textStatus, errorThrown) { that.on_server_load_loyalty_cards_error(jqXHR, textStatus, errorThrown) };
        }
        B2CLoyaltyCard.server_api_search({
            user_id: this.user.id,
            brand_id: this.place.brand_id,
            need_users: B2CLoyaltyCard.const.need_users.yes,
        }, this.closure_on_server_load_loyalty_cards_ok, this.closure_on_server_load_loyalty_cards_error);
    },
    server_load_selfies: function () {

        this.evidenceCollection.fetch({
            place_id: this.place.id,
            user_id: this.user.id,
            type: B2CEvidence.const.type.selfie,
            status: null,
            need_users: B2CEvidence.const.need_users.yes
        }, { reset: true });
    },
    server_load_receipts: function () {

        this.receiptEvidenceCollection.fetch({
            place_id: this.place.id,
            user_id: this.user.id,
            type: B2CEvidence.const.type.receipt,
            status: null,
            need_users: B2CEvidence.const.need_users.yes
        }, { reset: true });
    },
    server_load_other_evidence: function () {

        this.otherPhotoEvidenceCollection.fetch({
            place_id: this.place.id,
            user_id: this.user.id,
            type: B2CEvidence.const.type.otherevidence,
            status: null,
            need_users: B2CEvidence.const.need_users.yes
        }, { reset: true });
    },
    server_load_business_checkins: function () {
        B2CPlace.server_business_checkin_hist(
            this.user.id,
            this.place.id,
            this.on_server_load_business_checkins_ok.bind(this),
            this.on_server_load_business_checkins_error.bind(this)
        )
    },

    /*set : function(user, place, is_verified) {
     this.initialize();
     this.clear();

     this.user = user;
     this.place = place;
     this.is_verified = is_verified;
     //if(! is_verified)
     this.server_isverified_list();

     this.set_avatar();
     this.set_name();
     this.set_verification_cont();
     },*/

    _info_popup_show: function (message) {
        if (this.jqInfoPopup == null) {
            var that = this;
            this.jqInfoPopup = $('<div class="b2c_info_popup"></div>').appendTo(this.jq_page_other_user).b2cpopup({
                html_content: '<p class="message"></p>',
                lightMode: true
            });
            this.jqInfoPopup.b2cpopup('getUserCont').click(function (event) { that.jqInfoPopup.b2cpopup('close'); });
        }
        this.jqInfoPopup.b2cpopup('getUserCont').text(message);
        this.jqInfoPopup.b2cpopup('show');
    },

    /**
     *
     * @param options
     * @param options.user - (Object) пользователь чью страницу показываем
     * @param options.is_verified - (boolean) является ли пользователь верифицированным
     * @param options.place - (Object) место, в контексте которого мы смотрим инофрмацию о пользователе
     * @param options.anonym - (boolean) смотрим ли мы на полльзователя в режиме анонимности
     */
    set_show_params: function (options) {
        this._clear_vars();

        this.options = options;

        this.user = options.user;
        this.place = options.place;
        this.is_verified = options.is_verified;
    },
    onShow: function () {
        this.initialize();
        this._clear_widgets();

        this.server_isverified_list();

        this.set_avatar();
        this.set_name();
        this.set_verification_cont();

        if (!this.options.anonym && LoginedUserHandler.isUserEmployee(this.place.id)) {
            this.jq_gift_contr_cont.removeClass('hidden');

            this.jq_gift_contr_cont.find('.btn_gifts_list,.btn_gift_add')
                .toggleClass('hidden', !LoginedUserHandler.isHasRole(this.place.id, B2CEmployee.const.roles.GIFT_CREATOR));
        } else {
            this.jq_gift_contr_cont.addClass('hidden');
        }

        if (this.place.id != 1 && (LoginedUserHandler.getLoginedUser() != null  && LoginedUserHandler.getLoginedUser().id == this.user.id)
            || (LoginedUserHandler.isUserEmployee(this.place.id) && LoginedUserHandler.isHasRole(this.place.id, B2CEmployee.const.roles.EVIDENCE_MANAGER))) {
            this.jq_verification_cont.removeClass('hidden');
            this.jq_verification_methods_cont.removeClass('hidden');
        } else {
            this.jq_verification_cont.addClass('hidden');
            this.jq_verification_methods_cont.addClass('hidden');
        }
    },

    show_b2cgift_give_dialog: function (template) {
        if (template) {
            var that = this;
            if (this.closure_on_server_new_gift_created == null) {
                this.closure_on_server_new_gift_created = function (gift, template) { that.on_server_new_gift_created(gift, template) };
            }

            var options = {
                template: template.attributes, //(Object in Server format) шаблон подарок, на котором необходимо сгенерировать подарок
                user: this.user, //(Object in Server format) пользователь которому дарим
                //user_id : this.curr_user_id, //(integer) идентификатор пользователя, которому дарим подарк (нужен в случае отсутствия user)
                cl_task_id: null, //(integer) Идентификатор таска клиента, за который бизнес дарит подарок
                cl_comment_id: null, //(integer) Идентификатор коммента клиента, за который бизнес дарит подарок
                comment_id: null, //(integer) Идентификатор коммента бизнеса, к которому прикрепляется подарок
                on_cancel: null, //(function) функция обратного вызова, в случае отмены
                on_created: this.closure_on_server_new_gift_created, //(function(gift, template)) функция обратного вызова, в случае создания гифта на сервере
            };

            //this.jq_b2cgift_give_dialog = null; // В ержиме динамической подкгрузки
            if (this.jq_b2cgift_give_dialog == null) {
                this.jq_b2cgift_give_dialog = $('<div class="b2cgift_give_dialog"></div>');//.appendTo(this.jq_page_other_user);
                this.jq_b2cgift_give_dialog.b2cgift_give_dialog(options);
            } else {
                this.jq_b2cgift_give_dialog.b2cgift_give_dialog('set_options', options);
            }

            new GiftModal({
                giftTemplate: template,
                user: this.user,
            }).show().then((templateModel) => {
                if (templateModel) {
                    this.jq_b2cgift_give_dialog.b2cgift_give_dialog('create_gift', templateModel);
                }
            });
        }
    },

    on_verification_cont_click : function() {
        this.jq_verification_cont.toggleClass('checked');
        this.jq_verification_methods_cont.toggleClass('hidden');
    },
    on_btn_gifts_list_click : function() {
        new GiftsList({
            brandId: this.place.brand_id,
            user: this.user
        }).show();
    },
    on_btn_gift_add_click : function() {
        new GiftTemplates({
            brandId: this.place.brand_id,
            choosable: true
        }).show().then((giftTemplateModel) => {
            this.callback_on_template_chosen(giftTemplateModel);
        });
    },

    on_btn_verify_click: function (jqBtn) {
        if (!this.options.anonym) {
            var type = jqBtn.attr('data-type');
            var jq_sibling;
            var jq_list = this.jq_lists.find('.list[data-type=' + type + ']');


            if (jqBtn.hasClass('set')) {
                jqBtn.removeClass('set');
                if (jqBtn.hasClass('verified-set')) {
                    jqBtn.removeClass('verified-set');
                }
                jq_list.addClass('hidden');
            } else {
                jq_sibling = jqBtn.siblings('.set');
                if (jq_sibling.length > 0) {
                    this.jq_lists.find('.list[data-type=' + jq_sibling.attr('data-type') + ']').addClass('hidden');
                }
                jq_sibling.removeClass('set');

                if (jq_sibling.hasClass('verified')) {
                    jq_sibling.removeClass('verified-set');
                }

                jqBtn.addClass('set');

                if (jqBtn.hasClass('verified')) {
                    jqBtn.addClass('verified-set');
                }

                jq_list.removeClass('hidden');

                switch (type) {
                    case 'checkin': this.on_btn_verify_checkin_click(); break;
                    case 'loyaltycard': this.on_btn_verify_loyaltycard_click(); break;
                    case 'selfie': this.on_btn_verify_selfie_click(); break;
                    case 'receipt': this.on_btn_verify_receipt_click(); break;
                    case 'otherevidence': this.on_btn_verify_otherevidence_click(); break;
                    case 'business': this.on_btn_verify_business_click(); break;
                }
            }
        }
    },

    on_btn_verify_checkin_click : function() {
        if (this.checkins == null)
            this.server_load_checkins();
    },
    on_btn_verify_loyaltycard_click : function() {
        if (!this.loyaltyCardsCollection.length) {
            this.server_load_loyalty_cards();
        }
    },
    on_btn_verify_selfie_click : function() {
        this.server_load_selfies();
    },
    on_btn_verify_receipt_click : function() {
        this.server_load_receipts();
    },
    on_btn_verify_otherevidence_click : function() {
        this.server_load_other_evidence();
    },
    on_btn_verify_business_click : function() {
        if (this.business_checkins == null)
            this.server_load_business_checkins();
    },

    on_server_isverified_list_ok : function(data) {
        this.is_verified = false;
        for(var methodName in data) {
            if(data[methodName] > 0)
                this.is_verified = true;
        }
        this.verification_list = data;

        this.set_verification_cont();
        this.set_verification_methods_buttons();
    },
    on_server_isverified_list_error : function(jqXHR, textStatus, errorThrown) {

    },
    on_server_load_checkins_ok : function(data) {
        this.checkins = data;
        this.add_checkins_on_page(this.checkins);
    },
    on_server_load_checkins_error : function(jqXHR, textStatus, errorThrown) {

    },
    on_server_load_loyalty_cards_ok : function(data) {
        const users = {};
        const isBusinessView = LoginedUserHandler.isUserEmployee(this.place.id)
            && LoginedUserHandler.isHasRole(this.place.id, B2CEmployee.const.roles.EVIDENCE_MANAGER);

        if(data.users != null) {
            var user;
            for(var i=0; i<data.users.length; i++) {
                user = data.users[i];
                users[user.id] = user;
            }
        }
        this.loyaltyCardsCollection.reset(isBusinessView ? [{ __add__: true }, ...data.cards] : data.cards);

        let brand = null;

        if (isBusinessView) {
            const place = LoginedUserHandler.get_default_work_place();

            if (place) {
                brand = _.pick(place, ['logo', 'logoThumb', 'name']);
                brand.id = place.brand_id;
            }
        }

        let widget = new LoyaltyCardCollectionView({
            collection: this.loyaltyCardsCollection,
            validateUsers : users,
            userId: this.user.id,
            brand,
            isBusinessView,
        });

        widget.on('childview:delete', this.server_isverified_list.bind(this));

        this.loyaltyCards.show(widget);
    },
    on_server_load_loyalty_cards_error : function(jqXHR, textStatus, errorThrown) {

    },
    on_server_load_business_checkins_ok : function(data) {
        this.business_checkins = data.checkin;
        this.add_business_checkins_on_page(this.business_checkins);
    },
    on_server_load_business_checkins_error : function(jqXHR, textStatus, errorThrown) {

    },

    callback_on_template_chosen : function(template) {
        this.show_b2cgift_give_dialog(template);
    },
    on_server_new_gift_created : function(gift, template) {
        new InfoModal({
            additionalClassName: 'green-border',
            title: 'Well done!'
        }).show();
    }
};

export default OtherUserPage;

