import { Required, Optional, ViewModel, ItemView } from '@b2cmessenger/backbone';
import template from './CalendarItemView.jade';
import './CalendarItemView.scss'
import moment from 'moment-timezone';

const STATUS_CLASSES = {
    currentDate: 'current-date',
    currentMonth: 'current-month',
    selected: 'selected'
};

@ItemView.properties({
    template,

    tagName: 'li',
    className: 'calendar-widget-item',

    ui: {
        itemWrapper: '.item-wrapper',
        accepted: '[data-accepted]',
        pending: '[data-pending]',
        canceled: '[data-canceled]'
    },

    computeds: {
        c_Classes: {
            deps: ['id', 'selected'],
            get(id, selected) {
                const curDate = this.model.collection && this.model.collection.currentDateMoment || false;
                const momentDate = moment(new Date(id));
                const classes = {};

                if (curDate && curDate instanceof moment) {
                    classes[STATUS_CLASSES.currentDate] = moment().isSame(momentDate, 'day');
                    classes[STATUS_CLASSES.currentMonth] = curDate.isSame(momentDate, 'month');
                }

                classes[STATUS_CLASSES.selected] = !!(selected);

                return classes;
            }
        }
    },

    bindings: {
        ':el': 'classes:c_Classes',
        '@ui.accepted': 'css:{height:cssRelativePercents(accepted,total)}',
        '@ui.pending': 'css:{height:cssRelativePercents(pending,total)}',
        '@ui.canceled': 'css:{height:cssRelativePercents(canceled,total)}'
    },

    bindingFilters: {
      cssRelativePercents: (val, base) => base == 0 ? base : (val / base) * 100 + '%'
    },

    triggers: {
        'click': 'selected'
    }
})
class CalendarItemView extends ItemView {}

export default CalendarItemView;
