import { Model, ItemView } from '@b2cmessenger/backbone';
import settings from 'settings';
import Window from 'windows/Window';
import HeaderView from 'widgets/Header/Header';
import FooterView from 'widgets/Footer/Footer';
import Behaviors from 'utils/Behaviors';

import template from './Content.jade';
import './NewPassword.scss';

const Content = ItemView.extend({
    template,

    ui: {
        form: 'form',
        passwordInput: '[data-js-input-password]',
        passwordRepeatInput: '[data-js-input-passwordrepeat]'
    },

    events: {
        'submit @ui.form': 'save'
    },

    bindings: {
        '[data-js-input-group-password] p.error': 'text:passwordError',
        '[data-js-input-group-passwordrepeat] p.error': 'text:passwordrepeatError',
        '@ui.passwordInput': 'value:password',
        '@ui.passwordRepeatInput': 'value:passwordrepeat'
    },

    initialize() {
        this.viewModel.set({
            password: '',
            passwordrepeat: '',
            passwordError: '',
            passwordrepeatError: '',
        });

        this.viewModel.addComputed('new_passwordError', {
            deps: ['passwordError'],
            get: passwordError => passwordError,
            set: val => _.create(null, { passwordError: val })
        });

        this.listenTo(this.viewModel, 'change:password change:passwordrepeat', this.validate.bind(this));
    },

    comparePasswords(password, passwordRepeat) {
        return password == passwordRepeat;
    },

    validate() {
        this.viewModel.set(`passwordError`, '');
        this.viewModel.set(`passwordrepeatError`, '');

        if (!this.viewModel.get('password')) {
            this.viewModel.set(`passwordError`, 'Fill password');
            return false;
        }

        if (this.viewModel.get('passwordrepeat')) {
            let isValidated = this.comparePasswords(
                this.viewModel.get('password'),
                this.viewModel.get('passwordrepeat')
            );

            if (!isValidated) {
                this.viewModel.set(`passwordrepeatError`, 'Not equal');
                return false;
            }
        } else {
            this.viewModel.set(`passwordrepeatError`, 'Repeat password');
            return false;
        }

        return true;
    },

    save(e) {
        e.preventDefault();

        if (this.validate()) {
            this.viewModel.set({
                disabled: true
            });

            Server.callServer({
                url: settings.host + settings.serv_user.settingssave,
                method: 'post',
                data: {
                    current_password: this.viewModel.get('oldPassword'),
                    new_password: this.viewModel.get('password'),
                    email: LoginedUserHandler.getLoginedUser().email
                },
                success: (data, textStatus, jqXHR) => {
                    this.viewModel.set({
                        disabled: false
                    });

                    if (data.access_token) {
                        LoginedUserHandler.setAccessToken(data.access_token);
                    }
                    LoginedUserHandler.setLoginedUser({
                        id: data.id,
                        email: data.email,
                        firstname: data.firstname,
                        lastname: data.lastname || "",
                        phone: data.phone,
                        birthday: data.birthday,
                        gender: data.gender,
                        avatar: data.avatar,
                        avatarThumb: data.avatarThumb ? data.avatarThumb : data.avatar,
                        settings: {
                            viewanonym: (data.viewanonym == null ? true : data.viewanonym),
                            viewunverified: (data.viewunverified == null ? true : data.viewunverified),
                        }
                    });
                    this.trigger('close');
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    this.viewModel.set({
                        disabled: false
                    });

                    if (jqXHR.status == 422 && _.isArray(jqXHR.responseJSON)) { // Fields validation error
                        for (let err of jqXHR.responseJSON) {
                            this.viewModel.set(`${err.field}Error`, err.message);
                        }
                    } else { // Other error
                        this.showError(jqXHR, textStatus, errorThrown);
                    }
                }
            });
        }
    },
});

export default Window.extend({
    options: {
        password: null
    },

    windowName: 'new-password-window',

    initialize() {
        this.viewModel.set({ oldPassword: this.options.password });
    },

    close(...args) {
        this.showLoading();
        app.controller.restart()
            .then(() => {
                this.hideLoading();
                return Window.prototype.close.call(this, ...args);
            })
            .catch((error) => {
               this.hideLoading();
               this.showError(error);
            });
    },

    onRender() {
        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: 'New password',
            rightButtons: []
        });

        this.listenTo(headerView, 'back:click', () => {
            this.close();
        });

        this.header.show(headerView);

        const content = new Content({ viewModel: this.viewModel });

        content.on('close', () => {
            this.close();
        });

        this.content.show(content);
    }
});
