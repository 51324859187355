import OfflineStorage from 'utils/OfflineStorage';
import AjaxError from 'utils/AjaxError';
import { Events } from '@b2cmessenger/backbone';

var LoyaltyCardBrowserStorage = {
    cards : null,
    brands: null,
    users: {},
    brand_id_to_card_id : null,

    init : function() {
        if(!this.fileStore) this.fileStore = new OfflineStorage("LoyaltyCards");
        this.init_base_in_memory();
    },
    init_base_in_memory : function() {
        if(!this.fileStore) this.fileStore = new OfflineStorage("LoyaltyCards");

        if (this.cards != null)
            return true;
        if(localStorage.loyalty_cards == null)
            return false;
        this.cards = JSON.parse(localStorage.loyalty_cards);

        if (localStorage.loyalty_cards_brands)
            this.brands = JSON.parse(localStorage.loyalty_cards_brands);

        if (localStorage.loyalty_cards_users)
            this.users = JSON.parse(localStorage.loyalty_cards_users);

        this.brand_id_to_card_id = JSON.parse(localStorage.brand_id_to_card_id);
        return true;
    },
    init_local_storage : function(server_cards, server_brands, server_users) {
        if (!this.fileStore) {
            this.fileStore = new OfflineStorage("LoyaltyCards");
        }
        this.init_firsttime_local_storage(server_cards, server_brands, server_users);
    },
    init_firsttime_local_storage : function(server_cards, server_brands, server_users) {
        this.cards = {};
        this.brands = {};
        this.users = {};

        if (server_users != null) {
            _.each(server_users, user => {
                this.users[user.id] = user;
            });
        }

        this.brand_id_to_card_id = {};

        var brand, card;
        for(var i=0; i<server_brands.length; i++) {
            brand = server_brands[i];
            this.brands[brand.id] = brand;
        }

        for(var i=0; i<server_cards.length; i++) {
            card = server_cards[i];
            this.cards[card.id] = card;
            if (this.brand_id_to_card_id[card.brand_id] == null) {
                this.brand_id_to_card_id[card.brand_id] = [];
            }
            this.brand_id_to_card_id[card.brand_id].push(card.id);
        }

        this.save_memory_base_to_local_storage();
    },
    init_delta_local_storage : function(server_cards, server_brands) {
        this.init_base_in_memory();

        var brand, card;
        for(var i=0; i<server_brands.length; i++) {
            brand = server_brands[i];
            this.brands[brand.id] = brand;
        }

        for(var i=0; i<server_cards.length; i++) {
            card = server_cards[i];
            this.cards[card.id] = card;
            if (this.brand_id_to_card_id[card.brand_id] == null) {
                this.brand_id_to_card_id[card.brand_id] = [];
            }
            if (this.brand_id_to_card_id[card.brand_id].indexOf(card.id) < 0)
                this.brand_id_to_card_id[card.brand_id].push(card.id);
        }

        this.save_memory_base_to_local_storage();
    },
    save_memory_base_to_local_storage(dontSyncLocalFiles) {
        if(!dontSyncLocalFiles) {
            let urlsToSync = [];
            _(this.cards).each(card => {
                if(card.photo) {
                    urlsToSync.push(card.photo);
                }

                if(card.photo2) {
                    urlsToSync.push(card.photo2);
                }
            });

            _(this.brands).each(brand => {
                if(brand.logoThumb) {
                    urlsToSync.push(brand.logoThumb);
                }
            });

            if(urlsToSync.length) {
                this.fileStore
                    .sync(urlsToSync)
                    .then(entries => {
                        console.log("LoyaltyCardsOfflineStorage OfflineStorage.sync result", entries);
                        _(this.cards).each(card => {
                            if(card.photo) {
                                if(_(entries[card.photo]).isError() || entries[card.photo] instanceof FileTransferError) {
                                    delete card.photo_local;
                                } else {
                                    card.photo_local = entries[card.photo];
                                }
                            }

                            if(card.photo2) {
                                if(_(entries[card.photo2]).isError() || entries[card.photo2] instanceof FileTransferError) {
                                    delete card.photo2_local;
                                } else {
                                    card.photo2_local = entries[card.photo2];
                                }
                            }
                        });

                        _(this.brands).each(brand => {
                            if(_(entries[brand.logoThumb]).isError() || entries[brand.logoThumb] instanceof FileTransferError) {
                                delete brand.logoThumb_local;
                            } else {
                                brand.logoThumb_local = entries[brand.logoThumb];
                            }
                        });

                        this.save_memory_base_to_local_storage(true);
                    })
                    .catch(function (err) { console.warn(err); });
            }
        }

        localStorage.loyalty_cards = JSON.stringify(this.cards);
        localStorage.loyalty_cards_brands = JSON.stringify(this.brands);
        localStorage.loyalty_cards_users = JSON.stringify(this.users);
        localStorage.brand_id_to_card_id = JSON.stringify(this.brand_id_to_card_id);
    },
    add_card_to_local_storage : function(card, brand) {
        this.init_delta_local_storage([card], [brand]);
        return this.cards[card.id];
    },
    getBrands : function(part_name) {
        return this.getBrands_by_ids(this.getBrandsIds(part_name));
    },
    getBrandsIds : function(part_name) {
        var result = [];
        if (part_name == '')
            part_name = null;
        for(var key in this.brands) {
            if(part_name == null || this.brands[key].name.toLowerCase().search(part_name) >= 0) {
                result.push(key);
            }
        }
        if (this.closure_compareBrand == null) {
            var that = this;
            this.closure_compareBrand = function(brand_id_1, brand_id_2) { that.compareBrand(brand_id_1, brand_id_2) };
        }
        return result.sort(this.closure_compareBrand);
    },
    getBrands_by_ids : function(brands_ids) {
        var result = [];
        for(var i=0; i<brands_ids.length; i++)
            result.push(this.brands[brands_ids[i]]);
        return result;
    },
    getBrand_by_id : function(brand_id) {
        return this.brands && this.brands[brand_id];
    },
    compareBrand : function(brand_id_1, brand_id_2) {
        if(this.brands[brand_id_1].name < this.brands[brand_id_2].name)
            return -1;
        else
            return 1;
    },
    gatCards_by_ids : function(cards_ids) {
        var result = [];
        if (cards_ids != null)
            for(var i=0; i<cards_ids.length; i++)
                result.push(this.cards[cards_ids[i]]);
        return result;
    },
    getCards_by_brand_id : function(brand_id) {
        return this.gatCards_by_ids(this.brand_id_to_card_id[brand_id]);
    },
    getLatestDateOfUpdate : function() {
        if(! this.init_base_in_memory())
            return null;

        var latest_date, latest_date_js, curr_date_js, card;

        for(var card_id in this.cards) {
            card = this.cards[card_id];

            if (card.updated_at == null || card.updated_at == '0000-00-00 00:00:00') {
                curr_date_js = B2Cjs.datetimeServerToJS(card.created_at);
            } else {
                curr_date_js = B2Cjs.datetimeServerToJS(card.updated_at);
            }
            if (latest_date_js == null) {
                latest_date_js = curr_date_js;
            } else if (curr_date_js > latest_date_js) {
                latest_date_js = curr_date_js;
            }
        }

        return latest_date_js == null ? null : B2Cjs.datetimeJSToServer(latest_date_js);
    },
    delete_card_from_client : function(id) {
        var card = this.cards[id];
        if (card == null)
            return;
        var brand_to_cards = this.brand_id_to_card_id[card.brand_id];
        brand_to_cards.splice(brand_to_cards.indexOf(card.id), 1);
        if (brand_to_cards.length == 0) {
            delete this.brand_id_to_card_id[card.brand_id];
            delete this.brands[card.brand_id];
        }

        delete this.cards[id];

        this.save_memory_base_to_local_storage();
    },
};

const LoyaltyCardBase = _.extend({
    init: function () {
        if (app.localStorage)
            LoyaltyCardBrowserStorage.init();
    },

    init_local_storage: function (server_cards, server_brands, server_users) {
        if (app.localStorage)
            return LoyaltyCardBrowserStorage.init_local_storage(server_cards, server_brands, server_users);
    },
    add_card_to_local_storage: function (card, brand) {
        if (app.localStorage)
            return LoyaltyCardBrowserStorage.add_card_to_local_storage(card, brand);
    },
    getUsers: function () {
        return LoyaltyCardBrowserStorage.users;
    },
    getBrands: function (part_name) {
        if (app.localStorage)
            return LoyaltyCardBrowserStorage.getBrands(part_name);
    },
    getBrandsIds: function (part_name) {
        if (app.localStorage)
            return LoyaltyCardBrowserStorage.getBrandsIds(part_name);
    },
    getBrand_by_id: function (brand_id) {
        if (app.localStorage)
            return LoyaltyCardBrowserStorage.getBrand_by_id(brand_id);
    },
    getBrands_by_ids: function (brands_ids) {
        if (app.localStorage)
            return LoyaltyCardBrowserStorage.getBrands_by_ids(brands_ids);
    },
    gatCards_by_ids: function (cards_ids) {
        if (app.localStorage)
            return LoyaltyCardBrowserStorage.gatCards_by_ids(cards_ids);
    },
    getCards_by_brand_id: function (brand_id) {
        if (app.localStorage)
            return LoyaltyCardBrowserStorage.getCards_by_brand_id(brand_id);
    },
    getLatestDateOfUpdate: function () {
        if (app.localStorage)
            return LoyaltyCardBrowserStorage.getLatestDateOfUpdate();
    },
    delete_card_from_client: function (id) {
        if (app.localStorage)
            return LoyaltyCardBrowserStorage.delete_card_from_client(id);
    },
    update_cards_from_server: function () {
        var loginedUser = LoginedUserHandler.getLoginedUser();
        if (!loginedUser) {
            this.init_local_storage([], []);
            return;
        };

        var latest_date = this.getLatestDateOfUpdate();

        var that = this;

        return new Promise((resolve, reject) => {
            B2CLoyaltyCard.server_api_search(
                {
                    user_id: loginedUser.id,
                    //updated_after: latest_date,
                    need_brands: B2CLoyaltyCard.const.need_brands.yes,
                    need_users: B2CEvidence.const.need_users.yes
                },
                data => {
                    this.on_update_cards_from_server_ok(data);
                    resolve(this);
                },
                (jqXHR, textStatus, errorThrown) => {
                    this.on_update_cards_from_server_error(jqXHR, textStatus, errorThrown);
                    reject(new AjaxError(jqXHR, textStatus, errorThrown));
                }
            );
        });
    },
    on_update_cards_from_server_ok: function (data) {
        this.init_local_storage(data.cards, data.brands, data.users);
        this.trigger('sync');
    },
    on_update_cards_from_server_error: function (jqXHR, textStatus, errorThrown) {
        console.log('on_update_cards_from_server_error');
    },
}, Events);

export default LoyaltyCardBase;
