import React from 'react';
import PropTypes from 'prop-types';

import './MenuItemQuantityControl.scss';

import QuantityChangeControl from 'components/UI/QuantityChangeControl/QuantityChangeControl';
import Price from 'components/UI/Price/Price';
import TextButton from 'components/UI/TextButton';

const MenuItemQuantityControl = (props) => {
    const {
        count = 0,
        price,
        currency,
        maximum = null,
        minimum = null,
        displayTextButton = false,
        buttonText = 'Add',
    } = props;

    const options = {
        ...props,
        value: count,
        isHiddenMinus: count === 0,
        isHiddenCountInput: count === 0,
        disabledMinus: Boolean(count <= 0) || (minimum !== null && count <= minimum),
        disabledPlus: maximum !== null && count >= maximum,
    };

    const content =
        displayTextButton && !count ? (
            <TextButton
                className="wide border-filled"
                disabled={options.disabledPlus}
                onClick={props.onPlusClickHandler}
            >
                {buttonText}
            </TextButton>
        ) : (
            <>
                <QuantityChangeControl {...options} />
                {Boolean(price) && <Price value={price} currency={currency} />}
            </>
        );

    return (
        <div className="menu-item-quantity-control" onClick={props.onClick || ((e) => e.stopPropagation())}>
            {content}
        </div>
    );
};
MenuItemQuantityControl.propTypes = {
    count: PropTypes.number,
    price: PropTypes.number,
    currency: PropTypes.string,
    maximum: PropTypes.number,
    minimum: PropTypes.number,
    onMinusClickHandler: PropTypes.func,
    onPlusClickHandler: PropTypes.func,
    onChange: PropTypes.func,
    displayTextButton: PropTypes.bool,
    buttonText: PropTypes.string,
};

export default MenuItemQuantityControl;
