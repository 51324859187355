var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (Date, Math, String, WIDGET_TYPE_MULTIPLE, title, value, varname, widget, widgetparams) {var id = 'input-group-' + varname + '-' + (new Date()).getTime() + Math.random().toString(16).slice(2)
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(['property-' + varname,"input-group","horizontal"], [true,false,false]), false, true)+pug.attr("data-js-input-group-value", true, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(['icon-' + varname], [true]), false, true)) + "\u003E\u003C\u002Fi\u003E\u003Clabel\u003E\u003Cp\u003E" + (pug.escape(null == (pug_interp = title) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003C\u002Flabel\u003E";
// iterate widgetparams
;(function(){
  var $$obj = widgetparams;
  if ('number' == typeof $$obj.length) {
      for (var val = 0, $$l = $$obj.length; val < $$l; val++) {
        var name = $$obj[val];
if (widget == WIDGET_TYPE_MULTIPLE) {
pug_html = pug_html + "\u003Cinput" + (" type=\"checkbox\""+pug.attr("name", id + String(val), true, true)+pug.attr("value", val, true, true)+" data-role=\"none\""+pug.attr("id", id + String(val), true, true)+pug.attr("checked", (val == value || value && value.indexOf && value.indexOf(val) > -1), true, true)) + "\u003E";
}
else {
pug_html = pug_html + "\u003Cinput" + (" type=\"radio\""+pug.attr("name", id, true, true)+pug.attr("value", val, true, true)+" data-role=\"none\""+pug.attr("id", id + String(val), true, true)+pug.attr("checked", (val == value), true, true)) + "\u003E";
}
pug_html = pug_html + "\u003Clabel" + (pug.attr("for", id + String(val), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E";
      }
  } else {
    var $$l = 0;
    for (var val in $$obj) {
      $$l++;
      var name = $$obj[val];
if (widget == WIDGET_TYPE_MULTIPLE) {
pug_html = pug_html + "\u003Cinput" + (" type=\"checkbox\""+pug.attr("name", id + String(val), true, true)+pug.attr("value", val, true, true)+" data-role=\"none\""+pug.attr("id", id + String(val), true, true)+pug.attr("checked", (val == value || value && value.indexOf && value.indexOf(val) > -1), true, true)) + "\u003E";
}
else {
pug_html = pug_html + "\u003Cinput" + (" type=\"radio\""+pug.attr("name", id, true, true)+pug.attr("value", val, true, true)+" data-role=\"none\""+pug.attr("id", id + String(val), true, true)+pug.attr("checked", (val == value), true, true)) + "\u003E";
}
pug_html = pug_html + "\u003Clabel" + (pug.attr("for", id + String(val), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003Cp class=\"error\"\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";}.call(this,"Date" in locals_for_with?locals_for_with.Date:typeof Date!=="undefined"?Date:undefined,"Math" in locals_for_with?locals_for_with.Math:typeof Math!=="undefined"?Math:undefined,"String" in locals_for_with?locals_for_with.String:typeof String!=="undefined"?String:undefined,"WIDGET_TYPE_MULTIPLE" in locals_for_with?locals_for_with.WIDGET_TYPE_MULTIPLE:typeof WIDGET_TYPE_MULTIPLE!=="undefined"?WIDGET_TYPE_MULTIPLE:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined,"value" in locals_for_with?locals_for_with.value:typeof value!=="undefined"?value:undefined,"varname" in locals_for_with?locals_for_with.varname:typeof varname!=="undefined"?varname:undefined,"widget" in locals_for_with?locals_for_with.widget:typeof widget!=="undefined"?widget:undefined,"widgetparams" in locals_for_with?locals_for_with.widgetparams:typeof widgetparams!=="undefined"?widgetparams:undefined));;return pug_html;};
module.exports = template;