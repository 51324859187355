import settings from 'settings';

class UserSearchHelpers {
    constructor(options) {
        this.viewModel = options && options.viewModel || null;
    }

    async callServerPromise(url, data, type = 'POST') {
        return new Promise((resolve, reject) => {
            if (this.viewModel) {
                this.viewModel.set('disabled', true);
            }

            Server.callServer({
                url,
                type,
                data,
                success: responseData => {
                    if (this.viewModel) {
                        this.viewModel.set('disabled', false);
                    }
                    resolve(responseData);
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    if (this.viewModel) {
                        this.viewModel.set('disabled', false);
                    }
                    reject([jqXHR, textStatus, errorThrown]);
                }
            })
        });
    }

    async inviteUser(requestDataObj) {
        return this.callServerPromise(settings.host + settings.serv_invite.invite, requestDataObj);
    }

    async findClient(requestDataObj) {
        return this.callServerPromise(settings.host + settings.serv_user.search, requestDataObj);
    }

    async requestCode(requestDataObj) {
        return this.callServerPromise(settings.host + settings.serv_user.verification.request_code, requestDataObj);
    }

    async verifyWithCode(requestDataObj) {
        const url = settings.host + settings.serv_user.verification.verify.replace('{id}', requestDataObj.id);
        return this.callServerPromise(url, { code: requestDataObj.code });
    }
}

export default UserSearchHelpers;