import _ from 'underscore';
import { ItemView, Model } from '@b2cmessenger/backbone';
import ImageInputHelpers from './ImageInputHelpers';
import template from './ImageInput.jade';
import './ImageInput.scss';

/**
 * Button for uploading an image
 * In ios behaves similar to input type=file, in android intermediatly opens modal window to choose between gallery or camera
 *
 * @fires change(file) User selected file or cancel. If no file was selected, file will be falsey. Can be either Blob or File.
 */
const ImageInput = ItemView.extend({
    tagName: 'button',
    attributes: {
        type: 'button'
    },
    template,
    className: "widget image-input-widget-old needs-native-click",

    ui: {
        input: '[data-js-input]',
        image: '[data-js-image]'
    },

    events: {
        'click': 'onClick',
        'click @ui.input': e => e.stopPropagation(),
        'change @ui.input': 'onInputChange',
    },

    bindings: {
        ':el': 'classes:{empty:not(src),disabled:disabled},disabled:disabled',
        '@ui.image': 'css:{"background-image":srcToUrl(src)}',
        '@ui.input': 'classes:{hidden:isAndroid},disabled:disabled'
    },

    bindingFilters: {
        srcToUrl: src => `url(${src})`,
    },

    /**
     * @param {Object} options Options
     * @param {String} [options.modelKey="image"] - Which model's attribute to use for image url
     */
    initialize(options) {
        _.defaults(options, {
            modelKey: 'image'
        });

        this.viewModel = new ImageModel({
            url: this.model.get(options.modelKey),
            disable: false,
            isAndroid: window.device && window.device.platform == "Android"
        }, options);

        this.listenTo(this.viewModel, 'change:file', (m, file) => this.trigger('change', file));
        this.on('disable', () => this.viewModel.set('disable', true));
        this.on('enable', () => this.viewModel.set('disable', false));
    },

    clear() {
        this.ui.input.val('');
        this.viewModel.clear();
    },

    onClick() {
        if (this.viewModel.get('isAndroid')) {
            this.ui.input.val('');

            ImageInputHelpers.getImageSourceType()
                .then(type => {
                    if (type === 'camera') {
                        return ImageInputHelpers
                            .getImageFromNavigator(type)
                            .then(fileUrl => {
                                if (fileUrl) {
                                    this.viewModel.set({ file: null }, { silent: true });
                                    this.viewModel.set('file', fileUrl);
                                }
                            });
                    } else if (type === 'gallery') {
                        this.ui.input.click();
                    }
                })
                .catch((error) => {
                    if (error !== 'No Image Selected') {
                        this.showError(error);
                    }
                });
        } else {
            this.ui.input.click();
        }
    },

    onInputChange() {
        if (!this.viewModel.get('disable')) {
            if (this.ui.input[0].files && this.ui.input[0].files.length && this.ui.input[0].files[0]) {
                const file = this.ui.input[0].files[0];

                const checkAndProceed = (repeat) => {
                    if (file.size) {
                        if (file.type.match(/^image\/(png|jpeg|jpg)$/)) {
                            this.trigger('enable');
                            this.trigger('loaded');
                            this.viewModel.set({'file': null}, {silent: true});
                            this.viewModel.set('file', file);
                            return true;
                        } else {
                            this.trigger('enable');
                            this.trigger('loadfailed');
                            this.ui.input.val("");
                            this.viewModel.unset('file');
                            return false;
                        }
                    } else {
                        if (repeat > 0) {
                            this.trigger('loading');
                            this.trigger('disable');
                            _.delay(checkAndProceed.bind(this), 300, repeat - 1);
                        } else {
                            this.trigger('enable');
                            this.trigger('loadfailed');
                            this.ui.input.val("");
                            this.viewModel.unset('file');
                        }
                        return false;
                    }
                };

                checkAndProceed(25);
            } else {
                this.viewModel.unset('file');
            }
        }
    }
});

const ImageModel = Model.extend({
    computeds: {
        'src': {
            deps: ['url', 'fileUrl'],
            get: (url, fileUrl) => fileUrl || url || ""
        },
        'disabled': {
            deps: ['disable', 'parentDisabled'],
            get: (disable, parentDisabled) => !!disable || !!parentDisabled
        },
        'enabled': {
            deps: ['disabled'],
            get: (disabled) => !disabled
        }
    },

    initialize(a, options) {
        this.listenTo(options.model, `change:${options.modelKey}`, (m, image) => {
            if (_.isString(image)) {
                this.set('url', image);
                this.unset('fileUrl');
            }
        });
    }
});

export default ImageInput;
