import React from 'react';
import MenuList, { MenuListItem } from 'components/menu/MenuList/MenuList';
import { MenuPlaceTable } from './MenuPlaceOrder';

const MenuPlaceTables = (props) => {
    const { items = [], onAddOrder = _.noop } = props;

    const itemList = items.map((i) => {
        return (
            <MenuListItem className="menu-list-item-wrap" key={i.id}>
                <MenuPlaceTable className="menu-list-item" {...i} onAddOrder={onAddOrder} />
            </MenuListItem>
        );
    });

    return (
        <MenuList {...props} type="place-tables">
            {itemList}
        </MenuList>
    );
};

export default MenuPlaceTables;
