export function byId(obj) {
    return obj && obj['id'] || null;
}

export function sortByCreatedAt(a, b) {
    return B2Cjs.datetimeServerToJS(a.created_at) > B2Cjs.datetimeServerToJS(b.created_at) ? 1 : -1;
}

export function sortByUpdatedAt(a, b) {
    return B2Cjs.datetimeServerToJS(a.updated_at) > B2Cjs.datetimeServerToJS(b.updated_at) ? 1 : -1;
}

export function sortBySortOrder(a, b) {
    return a.sort_order > b.sort_order ? 1 : -1;
}

export function getUpdatedAtTimeStamp(prevValue) {
    return Date.now();
}

export function filterObject(source, predicate) {
    return _.chain(source)
        .pairs()
        .filter((([k, v], idx, list) => predicate.call(null, k, v, idx, list)))
        .object()
        .value();
}

export function filterObjectIntoArray(source, predicate) {
    return _.chain(source)
        .pairs()
        .filter((([k, v], idx, list) => predicate.call(null, k, v, idx, list)))
        .map(([k, v]) => v)
        .value();
}

export function isThereNeedToUpdateItem(oldItem, newItem) {
    return !oldItem || (new Date(newItem['updated_at']) > new Date(oldItem['updated_at']));
}