import {hasRoleInPlace, isLoggedIn} from "utils/getLoginedUserModel";
import LoginedUserModel from "models/LoginedUserModel";

export default function getMenuPermissions(placeId) {
    return {
        isMenuManager: getIsMenuManager(placeId),
        isWaiter: getIsMenuWaiter(placeId),
        isOrderManager: getIsMenuOrderManager(placeId),
        isLoggedIn: getIsLoggedIn()
    };
}

function getIsMenuManager(placeId) {
    return hasRoleInPlace(placeId, LoginedUserModel.Roles.MENU_MANAGER);
}

function getIsMenuWaiter(placeId) {
    return hasRoleInPlace(placeId, LoginedUserModel.Roles.WAITER);
}

function getIsMenuOrderManager(placeId) {
    return hasRoleInPlace(placeId, LoginedUserModel.Roles.ORDER_MANAGER);
}

function getIsLoggedIn() {
    return isLoggedIn();
}