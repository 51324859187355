import React from 'react';

import { Button } from 'components/UI/Button/Button';
import './MenuItemError.scss';

const MenuItemError = (props) => {
    const {
        error,
        errorText = null,
        buttonText = null,
        displayRetryButton = true,
        fetchData,
        goToMainMenuPage,
    } = props;

    let content;
    if (error && error.jqXHR && error.jqXHR.status === 404) {
        content = (
            <>
                <p>
                    <strong>Oops...</strong> 
                    {' '}
                    <span>Item not found</span>
                </p>
                {displayRetryButton && (
                    <Button className="btn-medium-wide" wrapChildren={false} onClick={goToMainMenuPage}>
                        {buttonText || 'Return to the main menu page'}
                    </Button>
                )}
            </>
        );
    } else {
        content = (
            <>
                <p>{errorText || 'An error has occurred. Please try again later.'}</p>
                {displayRetryButton && (
                    <Button className="btn-medium-wide" wrapChildren={false} onClick={fetchData}>
                        {buttonText || 'Try loading the page again'}
                    </Button>
                )}
            </>
        );
    }

    return <div className="menu-item-error">{content}</div>;
};

export default MenuItemError;
