import {ItemView, Optional, Required, ViewModel} from '@b2cmessenger/backbone';
import template from './LoyaltyKioskAuthorize.jade';
import './LoyaltyKioskAuthorize.scss';
import qrCodeScanner from 'utils/qrCodeScanner';
import QrCodeModel from 'models/QrCodeModel';
import settings from 'settings';
import AjaxError from 'utils/AjaxError';
import UserEmailSearchModal from "windows/Modal/UserSearchModal/UserEmailSearchModal";
import UserEmailSearchVerifyModal from "windows/Modal/UserSearchModal/UserEmailSearchVerifyModal";
import UserEmailSearchRegisterModal from "windows/Modal/UserSearchModal/UserEmailSearchRegisterModal";
import UserPhoneSearchModal from "windows/Modal/UserSearchModal/UserPhoneSearchModal";
import UserPhoneSearchVerifyModal from "windows/Modal/UserSearchModal/UserPhoneSearchVerifyModal";
import {LoyaltyKioskCheckInMethods} from "windows/Modal/LoyaltyKiosk/LoyaltyKiosk";
import Window from 'windows/Window';

const UserNotFoundRejection = 'User not found';

@ItemView.options({
    workPlace: Required,
    defaultCheckInMethod: Optional,
})
@ItemView.properties({
    className: 'widget loyalty-kiosk-authorize-widget',
    template,

    events: {
        'click @ui.scanQr'() {
            this.checkInViaQrScanner();
        },
        'click @ui.emailCheckin'() {
            this.checkInViaEmail();
        },
        'click @ui.receiveSms'() {
            this.checkInViaPhone();
        }
    },

    ui: {
        'scanQr': '[data-js-scan-qr]',
        'emailCheckin': '[data-js-email-checkin]',
        'receiveSms': '[data-js-receive-sms]'
    },
})
class LoyaltyKioskAuthorize extends ItemView {
    initialize(options) {
        this.options = options;
        this.viewModel = new ViewModel();
    }

    authorizeUser(user, options) {
        _.defaults(options || (options = {}));

        const payload = _.create(null);
        const { qrCodeModel = null } = options;

        if (qrCodeModel && qrCodeModel.get('type') === QrCodeModel.Type.LoyaltyCard) {
            payload.loggedInThroughLoyaltyCardNumber = qrCodeModel.get('loyaltyCardNumber');
        }

        this.trigger('authorize', user, payload);
    }

    onShow() {
        _.defer(this.startDefaultCheckInMethod.bind(this));
    }

    startDefaultCheckInMethod() {
        const { QrCodeScanner, Email, Phone } = LoyaltyKioskCheckInMethods;

        if ([QrCodeScanner, Email, Phone].indexOf(this.options.defaultCheckInMethod) > -1) {
            if (Window.isActiveWindow()) Window.cancelActiveWindow();
        }

        switch (this.options.defaultCheckInMethod) {
            case QrCodeScanner:
                this.checkInViaQrScanner();
                break;

            case Email:
                this.checkInViaEmail();
                break;

            case Phone:
                this.checkInViaPhone()
                break;

            default:
                return;
        }
    }

    checkInViaQrScanner() {
        this.viewModel.set({ checkingInVia: LoyaltyKioskCheckInMethods.QrCodeScanner });

        const scanOptions = {
            preferFrontCamera: true,
            showCancelButton: true,
            cancelButtonText: this.options.defaultCheckInMethod === LoyaltyKioskCheckInMethods.QrCodeScanner ?
                'Other check-in options' :
                'Back to other check-in options'
        };

        qrCodeScanner(scanOptions)
            .then(qrCodeModel =>
                qrCodeModel && this.findUserByQrCodeModel(qrCodeModel)
                    .then(user => this.authorizeUser(user, { qrCodeModel }))
            )
            .catch(e => {
                if (e) {
////////////////////////////////
/////////////////////////////////////////////
////////////////////////////
                    if (e && e.message) {
                        return this.showError(e.message);
                    }
/////////////////////////////
                }

                return Promise.resolve();
            })
            .then(() => { this.viewModel.set({ checkingInVia: null }); });
    }

    checkInViaEmail() {
        this.viewModel.set({ checkingInVia: LoyaltyKioskCheckInMethods.Email });
        this.findUserByEmail()
            .then(user => user && this.authorizeUser(user, {}))
            .catch(error => this.showError(error))
            .then(() => { this.viewModel.set({ checkingInVia: null }); });
    }

    checkInViaPhone() {
        this.viewModel.set({ checkingInVia: LoyaltyKioskCheckInMethods.Phone });
        this.findUserByPhone()
            .then(user => user && this.authorizeUser(user, {}))
            .catch(error => this.showError(error))
            .then(() => { this.viewModel.set({ checkingInVia: null }); });
    }

    async findUserByPhone() {
        return new Promise((resolve, reject) => {
            new UserPhoneSearchModal().show()
                .then(data => {
                    if (data && data.user) {
                        new UserPhoneSearchVerifyModal({
                            user: data.user
                        }).show().then(dataUser => resolve(dataUser));
                    } else if (data) {
                        reject(`User not found`);
                    } else {
                        resolve();
                    }
                })
        })
    }

    async findUserByEmail(options) {
        return new Promise((resolve, reject) => {
            new UserEmailSearchModal(options).show()
                .then(data => {
                    if (data && data.user) {
                        new UserEmailSearchVerifyModal({
                            userData: data.user
                        }).show().then(userData => {
                            resolve(userData);
                        });
                    } else if (data && data.email) {
                        new UserEmailSearchRegisterModal({
                            email: data.email,
                            placeId: this.options.workPlace.id,
                        }).show()
                            .then(registerData => {
                                if (registerData) {
                                    resolve(registerData);
                                } else {
                                    this.findUserByEmail({ email: data.email })
                                        .then(data => resolve(data))
                                        .catch(error => reject(error));
                                }
                            });
                    } else {
                        resolve(data);
                    }
                })
                .catch(() => resolve(false));
        });
    }

    async findUserByQrCodeModel(qr) {
        let payload = null;

        if (qr) {
            switch (qr.get('type')) {
                case QrCodeModel.Type.UserProfile: {
                    payload = { id: qr.get('userId') };
                    break;
                }

                case QrCodeModel.Type.LoyaltyCard: {
                    payload = { loyalty_card_number: qr.get('loyaltyCardNumber') };
                    break;
                }
            }
        }

        if (payload) {
            _.extend(payload, {
                limit: 1,
                current_place_id: this.options.workPlace.id,
                current_brand_id: this.options.workPlace.brand_id
            });

            return this.findClient(payload);
        }

        return Promise.reject(UserNotFoundRejection);
    }

    async findClient(payload) {
        return new Promise((resolve, reject) => {
            Server.callServer({
                url: settings.host + settings.serv_user.search,
                type: 'POST',
                data: payload,
                success: users => {
                    if (users && users.length) {
                        resolve(users[0]);
                        return;
                    }

                    reject(UserNotFoundRejection)
                },
                error(jqXHR, textStatus, errorThrown) {
                    reject(new AjaxError(jqXHR, textStatus, errorThrown));
                }
            })
        });
    }
}

export default LoyaltyKioskAuthorize;