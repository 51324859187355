import { Collection } from '@b2cmessenger/backbone';
import settings from 'settings';

import TaskTargetModel from 'models/TaskTargetModel';
import CollectionWithPagination from 'traits/CollectionWithPagination';

const TaskTargetCollection = Collection.extendWithTraits([
    new CollectionWithPagination({
        restrictComparators: true,
        comparators: {
            'smart': (a, b) => {
                const statusA = a.get('status') || Number.MAX_SAFE_INTEGER,
                    statusB = b.get('status') || Number.MAX_SAFE_INTEGER;

                if (statusA != statusB) {
                    return statusA - statusB;
                } else {
                    let d = 0;
                    switch (statusA) {
                        case TaskTargetModel.Status.Scheduled:
                        case TaskTargetModel.Status.InProgress:
                            d = (a.get('nextSendAt') || 0) - (b.get('nextSendAt') || 0);
                            break;
                        case TaskTargetModel.Status.Done:
                            d = (b.get('lastSendAt') || 0) - (a.get('lastSendAt') || 0);
                            break;
                        default:
                            d = (b.get('updatedAt') || 0) - (a.get('updatedAt') || 0);
                    }
                    return d == 0 ? (a.id - b.id) : d;
                }
            },
            'updated_at desc': {
                alias: '',
                comparator: (a, b) => {
                    const d = (b.get('updatedAt') || 0) - (a.get('updatedAt') || 0);
                    return d == 0 ? (a.id - b.id) : d;
                }
            },
            'id': 'id'
        }
    })
], {
    options: {
        placeId: undefined,
        pageSize: 200
    },
    model: TaskTargetModel,
    url: settings.host + settings.serv_task.target,

    create(attrs, options) {
        _.defaults(attrs || (attrs = {}), {
            placeId: this.options.placeId || this._getDefaultPlaceId()
        });
        return Collection.prototype.create.call(this, attrs, options);
    },

    fetch(options) {
        _.defaults(options || (options = {}), {
            data: {},
        });

        _.defaults(options.data, {
            'place_id': this.options.placeId || this._getDefaultPlaceId(),
            'include_task': !!options.includeTask,
            'include_users': !!options.includeUsers
        });

        if (!_.isUndefined(options.status)) {
            const status = [];
            if (_.isArray(options.status)) {
                status.push(..._.intersection(_.values(TaskTargetModel.Status), options.status));
            } else if (_.values(TaskTargetModel.Status).includes(options.status)) {
                status.push(options.status);
            }

            if (!status.length) {
                options.error.call(options.context, this, new Error(`options.status ${options.status} is incorrect!`), options);
                return;
            }

            _.defaults(options.data, {
                'status': status
            });
        }

        return Collection.prototype.fetch.call(this, options);
    },

    _getDefaultPlaceId() {
        return LoginedUserHandler.get_default_work_place_id() || undefined;
    }
});

export default TaskTargetCollection;
