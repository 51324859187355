import settings from "settings";
import * as Api from "utils/api/Api";

export async function getTable({ placeId, id }) {
    const url = [settings.host + settings.serv_place.menu.table.base, id].join('/');

    return Api.call({
        method: 'GET',
        url: url.replace('${place_id}', placeId),
    });
}

export async function getTables({ placeId }) {
    const url = settings.host + settings.serv_place.menu.table.list;

    return Api.call({
        method: 'GET',
        url: url.replace('${place_id}', placeId),
    });
}

export async function getSectionTables({ placeId, sectionId }) {
    const url = settings.host + settings.serv_place.menu.section.tables;

    return Api.call({
        method: 'GET',
        url: url.replace('${place_id}', placeId).replace('${section_id}', sectionId),
    });
}

export async function createTable({ placeId, number, sectionId, qrString, waiters}) {
    const url = settings.host + settings.serv_place.menu.table.base;
    const data = {
        number: String(number),
        section_id: Number(sectionId),
        qr_string: qrString,
        waiters
    };

    return Api.call({
        method: 'POST',
        url: url.replace('${place_id}', placeId),
        contentType: 'application/json',
        data: JSON.stringify(data)
    });
}

export async function updateTable({ placeId, id, number, sectionId, qrString, waiters }) {
    const url = [settings.host + settings.serv_place.menu.table.base, id].join('/');
    const data = {
        number: String(number),
        section_id: Number(sectionId),
        qr_string: qrString,
        waiters
    };

    return Api.call({
        method: 'PATCH',
        url: url.replace('${place_id}', placeId),
        contentType: 'application/json',
        data: JSON.stringify(data)
    });
}

export async function removeTable({ placeId, id }) {
    const url = [settings.host + settings.serv_place.menu.table.base, id].join('/');

    return Api.call({
        method: 'DELETE',
        url: url.replace('${place_id}', placeId),
    });
}
