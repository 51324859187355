import React from 'react';
import './MenuVisibilityEditorContent.scss';

const plural = (word, n) => `${n} ${word}${n !== 1 ? 's' : ''}`;
const makeInterval = (word, n, multiplier = 1) => ({ value: n * multiplier, label: plural(word, n) });
const HideInterval = {
    hour: [1, 2, 4, 6, 8],
    day: [1, 2, 3],
    week: [1, 2, 3],
    month: [1],
};

const HidePeriods = Object.keys(HideInterval).reduce(
    (memo, key) => {
        let multiplier = 1;
        if (key === 'month') {
            multiplier = 24 * 7 * 30;
        } else if (key === 'week') {
            multiplier = 24 * 7;
        } else if (key === 'day') {
            multiplier = 24;
        }

        memo.push(...HideInterval[key].map((n) => makeInterval(key, n, multiplier)));
        return memo;
    },
    [{ value: 0, label: 'Forever' }]
);

const MenuVisibilityEditorContent = ({ value, onPeriodChange, item, category }) => {
    const title = `Hide ${item ? 'item' : 'category'}`;
    const name = (item && item.name) || (category && category.name) || '';
    return (
        <>
            <div className="menu-visibility-editor-content">
                <i className="ion-ios-help-circle-outline" />
                <p className="text">
                    {title} 
                    {' '}
                    <span>
                        {name}
                        ?
                    </span>
                </p>
                <div className="label">
                    <label>Time Period</label>
                    <select value={value} onChange={(e) => onPeriodChange(Number(e.target.value))} data-role="none">
                        {HidePeriods.map(({ value, label }) => (
                            <option value={value} key={value}>
                                {label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </>
    );
};

export default MenuVisibilityEditorContent;
