var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (prettyText, subject, text) {pug_html = pug_html + "\u003Cdiv class=\"rating\" data-js-rating\u003E";
// iterate [1, 2, 3, 4, 5]
;(function(){
  var $$obj = [1, 2, 3, 4, 5];
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var i = $$obj[pug_index0];
pug_html = pug_html + "\u003Ci" + (" class=\"ion-ios-star-outline not-set\""+pug.attr("data-js-rating-star", i, true, true)) + "\u003E\u003C\u002Fi\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var i = $$obj[pug_index0];
pug_html = pug_html + "\u003Ci" + (" class=\"ion-ios-star-outline not-set\""+pug.attr("data-js-rating-star", i, true, true)) + "\u003E\u003C\u002Fi\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Ch3 class=\"subject\" data-js-subject\u003E" + (pug.escape(null == (pug_interp = subject) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E";
if (text) {
pug_html = pug_html + "\u003Cdiv class=\"text pretty-text\" data-js-text\u003E" + (null == (pug_interp = prettyText(text)) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"gallery\" data-js-gallery\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"gift\" data-js-gift\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"reservation\" data-js-reservation\u003E\u003C\u002Fdiv\u003E";}.call(this,"prettyText" in locals_for_with?locals_for_with.prettyText:typeof prettyText!=="undefined"?prettyText:undefined,"subject" in locals_for_with?locals_for_with.subject:typeof subject!=="undefined"?subject:undefined,"text" in locals_for_with?locals_for_with.text:typeof text!=="undefined"?text:undefined));;return pug_html;};
module.exports = template;