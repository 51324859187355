/**
 * Created by Alex on 05.03.2016.
 */
var geo = {
    lt: null,
    lg: null,
    callbacks_curr_user_pos_change: [],

    callback_change_limit: 0.001,
    change_lim: 0.015,

    earth_radius: 3959.0,
    degrees_to_radians: Math.PI / 180.0,
    radians_to_degrees: 180.0 / Math.PI,

    init: function () {
        var that = this;

        this.watchId = null;
        this.restartIntervalId = null;
        this.isPaused = false;

        this.start();
    },

    start: function () {
        if (this.restartIntervalId) {
            return;
        }

        this.restartAfterResumeWithDelay = true;

        navigator.geolocation.getCurrentPosition(
            (function (pos) {
                this.saveCurrentLocation(pos);

                if (this.watchId) {
                    navigator.geolocation.clearWatch(this.watchId);
                }

                this.watchId = navigator.geolocation.watchPosition(
                    geo.saveCurrentLocation.bind(geo),
                    function (error) {
                        console.error({
                            type: 'PositionError',
                            code: error.code,
                            message: error.message
                        }, error);
                    }, {
                        enableHighAccuracy: true,
                        timeout: 5000,
                        maximumAge: 10000
                    }
                );

            }).bind(this),
            (function (error) {
                app.onPause();

                this._scheduleRestart();

                console.error({
                    type: 'PositionError',
                    code: error.code,
                    message: error.message
                }, error);

                _.defer(function () { app.onResume() });

            }).bind(this),
            {
                enableHighAccuracy: true,
                timeout: 30000,
                maximumAge: 10000
            }
        );
    },

    pause: function () {
        if (this.watchId) {
            navigator.geolocation.clearWatch(this.watchId);
            this.watchId = null;
        }

        this.isPaused = true;
    },

    resume: function () {
        this.isPaused = false;

        this.start();
    },

    _scheduleRestart: function () {
        if (!this.restartIntervalId) {
            this.restartIntervalId = setInterval((function () {
                if (this.restartIntervalId) {
                    clearInterval(this.restartIntervalId);
                    this.restartIntervalId = null;
                }
                if (this.isPaused) {
                    this._scheduleRestart();
                } else {
                    this.start();
                }
            }).bind(this), 30000);
        }
    },

    getCurrentPosition: function () {
        return { lt: this.lt, lg: this.lg };
    },

    saveCurrentLocation: function (position) {
        var old_lt = this.lt, old_lg = this.lg;

        this.lt = position.coords.latitude;
        this.lg = position.coords.longitude;

        if (old_lt != this.lt || old_lg != this.lg) {
            console.debug('Current user position changed: old(' + old_lt + ', ' + old_lg + '), new (' + this.lt + ', ' + this.lg + ')');

            if (old_lt == null || old_lg == null || (
                this.distance(old_lt, old_lg, this.lt, this.lg, 'M') > this.callback_change_limit
            )) {
                for (var i = 0; i < this.callbacks_curr_user_pos_change.length; i++) {
                    try {
                        this.callbacks_curr_user_pos_change[i](this.lt, this.lg);
                    } catch (error) {
                        console.error(error, this.callbacks_curr_user_pos_change, i, this.callbacks_curr_user_pos_change[i]);
                    }
                }
            }
        }
    },

    add_callback_curr_user_pos_change: function (funcname) {
        this.callbacks_curr_user_pos_change.push(funcname);
    },

    //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    //:::                                                                         :::
    //:::  This routine calculates the distance between two points (given the     :::
    //:::  latitude/longitude of those points). It is being used to calculate     :::
    //:::  the distance between two locations using GeoDataSource (TM) prodducts  :::
    //:::                                                                         :::
    //:::  Definitions:                                                           :::
    //:::    South latitudes are negative, east longitudes are positive           :::
    //:::                                                                         :::
    //:::  Passed to function:                                                    :::
    //:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
    //:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
    //:::    unit = the unit you desire for results                               :::
    //:::           where: 'M' is statute miles (default)                         :::
    //:::                  'K' is kilometers                                      :::
    //:::                  'N' is nautical miles                                  :::
    //:::                                                                         :::
    //:::  Worldwide cities and other features databases with latitude longitude  :::
    //:::  are available at http://www.geodatasource.com                          :::
    //:::                                                                         :::
    //:::  For enquiries, please contact sales@geodatasource.com                  :::
    //:::                                                                         :::
    //:::  Official Web site: http://www.geodatasource.com                        :::
    //:::                                                                         :::
    //:::               GeoDataSource.com (C) All Rights Reserved 2015            :::
    //:::                                                                         :::
    //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    distance: function (lat1, lon1, lat2, lon2, unit) {
        var radlat1 = Math.PI * lat1 / 180
        var radlat2 = Math.PI * lat2 / 180
        var theta = lon1 - lon2
        var radtheta = Math.PI * theta / 180
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist)
        dist = dist * 180 / Math.PI
        dist = dist * 60 * 1.1515
        if (unit == "K") { dist = dist * 1.609344 }
        if (unit == "N") { dist = dist * 0.8684 }
        return dist
    },
    getCorners: function (lt, lg, distanceMiles) {
        lt = Number(lt);
        lg = Number(lg);
        distanceMiles = Number(distanceMiles);
        var corners = {
            nw: { lt: null, lg: null }, // north_west
            se: { lt: null, lg: null }, // south_east;
        }
        var lt_change = this.change_in_latitude(distanceMiles);
        corners.nw.lt = lt + lt_change;
        corners.nw.lg = lg - this.change_in_longitude(distanceMiles, corners.nw.lt);

        corners.se.lt = lt - lt_change;
        corners.se.lg = lg + this.change_in_longitude(distanceMiles, corners.se.lt);
        return corners;
    },
    change_in_latitude: function (miles) {
        // Given a distance north, return the change in latitude.
        return (miles / this.earth_radius) * this.radians_to_degrees;
    },
    change_in_longitude: function (miles, latitude) {
        //"Given a latitude and a distance west, return the change in longitude."
        //# Find the radius of a circle around the earth at given latitude.
        var r = this.earth_radius * Math.cos(latitude * this.degrees_to_radians);
        return (miles / r) * this.radians_to_degrees;
    }
}

window.geo = geo; // TODO: remove globals, use module exports