import React from 'react';
import { MenuItemBase } from '../MenuItem/MenuItem';
import MenuList, { MenuListType, byIdAndSortOrder, MenuListItem } from 'components/menu/MenuList/MenuList';
import { MenuHiddenVisibility } from 'components/menu/MenuVisibility/MenuVisibility';
import './MenuItems.scss';
import { SliderGroup, SwitchInput } from 'components/UI/InputGroup/InputGroup';
import { getAdditionItemPrice, getItemPrice } from 'store/menu/utils';

const MenuSelectableItems = (props) => {
    const { items, onSelectItem = _.noop, selected = [], onSelectedChange = _.noop } = props;

    const itemList = items.map((e) => {
        const i = {
            ...e,
            price: props.additions ? getAdditionItemPrice(e) : getItemPrice(e),
        };

        return (
            <MenuListItem className="menu-list-item-wrap" key={byIdAndSortOrder(i)}>
                <MenuItemBase className="menu-list-item" onSelectItem={onSelectItem.bind(null, i.id)} {...i}>
                    {(i.is_hidden_forever || i.hidden_until) && <MenuHiddenVisibility />}
                    <div className="menu-list-item-selection" onClick={(e) => e.stopPropagation()}>
                        <SliderGroup>
                            <SwitchInput
                                value={selected.indexOf(i.id) > -1}
                                onChange={onSelectedChange.bind(null, i)}
                            />
                        </SliderGroup>
                    </div>
                </MenuItemBase>
            </MenuListItem>
        );
    });

    return (
        <MenuList {...props} type={MenuListType.Items} className="selectable">
            {itemList}
        </MenuList>
    );
};

export default MenuSelectableItems;
