import React from 'react';
import MenuList, { MenuListItem } from 'components/menu/MenuList/MenuList';
import { MenuTableBase } from 'components/menu/MenuTables/MenuTable';
import { Button } from 'components/UI/Button/Button';
import { byId } from 'store/utils';
import './MenuTables.scss';
import { DisplayInlineUser, InlineUsers } from 'components/UI/User/InlineUser';

const MenuTableListItemClassName = 'menu-list-item-with-controls menu-editable-list-item menu-list-item-wrap';
const MenuTables = (props) => {
    const { tables, editable } = props;
    const obtainWaitersByUserIds = React.useCallback(
        (ids) => {
            if (_.isArray(ids) && ids.length > 0 && _.isArray(props.waiters) && props.waiters.length > 0) {
                return props.waiters.filter((w) => ids.indexOf(w.id) > -1).slice(0, 3);
            }

            return null;
        },
        [props.waiters]
    );

    const { onEditTable = _.noop, onRemoveTable = _.noop } = props;

    const tableList = tables.map((c) => {
        const waiters = obtainWaitersByUserIds(c.waiters);
        const waiterList = waiters && <InlineUsers display={DisplayInlineUser.AvatarOnly} users={waiters} />;

        const leftContent = Boolean(editable) && (
            <Button className="ion-ios-close-outline remove" onClick={onRemoveTable.bind(null, { table: c })} />
        );
        const rightContent = Boolean(editable) && (
            <Button className="ion-md-create edit" onClick={onEditTable.bind(null, { table: c })} />
        );

        return (
            <MenuListItem
                className={editable ? MenuTableListItemClassName : 'menu-list-item-wrap'}
                key={byId(c)}
                left={leftContent}
                right={rightContent}
            >
                <MenuTableBase className="menu-list-item" {...c}>
                    {waiterList}
                </MenuTableBase>
            </MenuListItem>
        );
    });

    return (
        <MenuList {...props} type="tables">
            {tableList}
        </MenuList>
    );
};

export default MenuTables;
