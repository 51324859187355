const UnSupported = 'unsupported';

class PlatformFeature {
    constructor(options = {}) {
        _.assign(this, { options, UnSupported });
    }

    isBrowser() {
        return !this.isCordova();
    }

    isCordova() {
///////////////////////
////////////////////
/////////////////
        return false;
//////////////////
    }

    isAndroid() {
        return this.isCordova() && device.platform.toLowerCase() === 'android';
    }

    isIOS () {
        return this.isCordova() && !this.isAndroid();
    }

    isTouchScreenDevice () {
        if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
            return true;
        }

        const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return window.matchMedia(query).matches;
    }

    async ensureInitialized() {
        return this.initializePromise ? this.initializePromise : this.initialize();
    }

    initialize() {
        this.initializePromise = Promise.resolve()
            .then(() => {
                this.ready = true;
            });

        return this.initializePromise;
    }
}

_.assign(PlatformFeature, { UnSupported });

export default PlatformFeature;