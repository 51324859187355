const bindingHandler = {
    init($el) {
        this._countEl = $el.find('span.count');

        if (!this._countEl.length) {
            $('<span class="count"></span>').appendTo($el);
            this._countEl = $el.find('span.count');
        }
    },
    set($el, notificationsCount) {
        if (this._countEl && this._countEl.length) {
            const badgeKey = $el.attr('data-js-badge-key');
            let count = 0;

            if (Number(notificationsCount)) {
                count = notificationsCount;
            } else if (_.isObject(notificationsCount) && badgeKey) {
                count = notificationsCount[badgeKey] || count;
            }

            this._countEl.toggleClass('hidden', !count);
            this._countEl.text(count > 99 ? 99 : count);
        }
    }
};

export { bindingHandler as default };