import React from "react";

function usePagination(source) {
    const [pagination, setPagination] = React.useState(source || {
        pageSize: 0,
        page: 0,
        pageCount: 0,
        totalCount: 0
    });

    const hasMore = React.useMemo(() => {
        return pagination.page < pagination.pageCount;
    }, [pagination.page, pagination.pageCount]);

    return [pagination, hasMore, setPagination];
}

export default usePagination;
