import {
    getMenuCartItemByUniqueId,
    getMenuCartItems,
    getMenuCartItemsOrder,
    getMenuCartState,
    getMenuItemById
} from "store/menu/selectors";
import { getAdditionItems, getSelectedAdditionItems } from "components/menu/utils";
import { getMenuOrdersState } from "store/menu/orders/selectors";
import { getAdditionItemPrice } from 'store/menu/utils';

export const getMenuCartComment = state => getMenuCartState(state).comment;

export const getMenuMissingCartItemIds = state => {
    const items = getMenuCartItems(state);
    const missingItemIds = _(items).reduce((memo, item, id) => {
        const menuItem = getMenuItemById(state, item.id);
        const isMissing = !menuItem || menuItem['is_customizable'] && !menuItem['additions'];

        if (item && item.additions) {
            for (const additionId in item.additions) {
                const additionItem = getMenuItemById(state, additionId);
                if (!additionItem) {
                    memo.push(additionId);
                }
            }
        }

        if (item.id && isMissing) {
            memo.push(item.id);
        }
        return memo;
    }, []);

    return _.uniq(missingItemIds)
};

export const getExtendedCartItemWithSelectedAdditions = (state, cartItem, uniqueId = null) => {
    const menuItemId = cartItem.id;
    const menuItem = getMenuItemById(state, menuItemId);

    let selectedAdditionItems = [];
    let priceWithAdditions = null;
    let totalPrice = null;

    if (menuItem) {
        if (cartItem.additions) {
            const additionItems = menuItem && getAdditionItems(menuItem.additions);
            selectedAdditionItems = additionItems && getSelectedAdditionItems({
                additions: cartItem.additions,
                additionItems
            });
        }

        const additionsPrice = _(selectedAdditionItems).reduce((mem, addOn, addOnId) => {
            const id = addOn.hasOwnProperty('id') ? addOn.id : addOnId;
            const addOnPrice = getAdditionItemPrice(addOn) * cartItem.additions[id];
            return mem + addOnPrice
        }, 0);

        priceWithAdditions = menuItem.price + additionsPrice;
        totalPrice = priceWithAdditions * cartItem.quantity;
    }

    const extendedCartItem = {
        uniqueId,
        cartItem,
        menuItem,
        selectedAdditionItems,
        priceWithAdditions,
        totalPrice,
    };

    return extendedCartItem;
};

export const getMenuCartItemsAsArray = state => {
    const order = getMenuCartItemsOrder(state);
    return order.map(uniqueId => getMenuCartItemByUniqueId(state, uniqueId, true));
};

export const getExtendedCartItemsMap = state => {
    const items = {};
    const cartItems = getMenuCartItems(state);

    for (const key in cartItems) {
        const cartItem = { ...cartItems[key] };

        items[key] = getExtendedCartItemWithSelectedAdditions(state, cartItem, key);
    }

    return items;
};

export const getMenuExtendedCartItemsSortedByOrder = state => {
    const extendedCartItemsMap = getExtendedCartItemsMap(state);
    const order = getMenuCartItemsOrder(state);

    return _(order).map(id => extendedCartItemsMap[id]);
};

export const getMenuCartOrderParams = state => getMenuCartState(state).orderParams;

export const getMenuCartTotalPrice = state => {
    const items = getMenuExtendedCartItemsSortedByOrder(state);

    return _(items).reduce((memo, item, id) => {
        return memo + item.totalPrice;
    }, 0);
};

export const getPreparedCartItemsToOrder = state => {
    const cartItems = getMenuCartItems(state);
    const items = _(cartItems).reduce((memo, item, i) => {
        const preparedItem = {
            item_id: item.id,
            count: item.quantity,
            properties: _(item.properties).reduce((memProp, value, name) => {
                memProp.push({ name, value });
                return memProp;
            }, []),
            additions: _(item.additions).reduce((memAdd, count, item_id) => {
                memAdd.push({ item_id, count });
                return memAdd;
            }, []),
            comment: item.comment
        };
        memo.push(preparedItem);
        return memo;
    }, []);

    return items;
};

export const getMenuCartLoadingOrder = state => getMenuOrdersState(state).loadingOrder;
export const getMenuSelectedTableId = state => getMenuCartState(state).selectedTableId;