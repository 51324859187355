import { CollectionView, LayoutView, ViewModel } from '@b2cmessenger/backbone';
import PropertyModel from 'models/PropertyModel';
import PropertyCollection from 'models/PropertyCollection';
import PropertyItemWidget from 'widgets/PropertyItem/PropertyItem';
import PropertyEditWidget from 'widgets/PropertyItem/PropertyEdit';

import './Properties.scss';
import template from './Properties.jade';

let PropertiesCollectionView = CollectionView.extend({
    childViewOptions() {
        let viewModel = new ViewModel;
        viewModel.set('parentDisabled', this.viewModel.get('disabled'));
        viewModel.listenTo(this.viewModel, 'change:disabled', (m, disabled) => viewModel.set('parentDisabled', disabled));

        return { viewModel };
    },

    initialize(options) {
        this.limit = options.limit || false;

        this.listenTo(this, 'compact', () => { 
            this.el.classList.add('compact');
            this.el.classList.remove('expanded');
            if(this.limit != this.options.limit) {
                this.limit = options.limit;
                this.render();
            }
        });

        this.listenTo(this, 'expanded', () => { 
            this.el.classList.remove('compact');
            this.el.classList.add('expanded');
            if(this.limit != false) {
                this.limit = false;
                this.render();
            }
        });

        this.listenTo(this, 'full', () => { 
            this.el.classList.remove('compact');
            this.el.classList.remove('expanded');
            if(this.limit != false) {
                this.limit = false;
                this.render();
            }
        });
    },

    filter(child, index, collection) {
        if(this.limit) {
            if(this.options.omitEmpty) {
                let showingViews = 0;
                for(let m of collection.models) {
                    let isEmpty = !(m.get('widget') == PropertyModel.WIDGET_TYPE_SINGLE || m.get('value') && m.get('value').length);
                    if(m == child) return !isEmpty;
                    if(!isEmpty) showingViews++;
                    if(showingViews >= this.limit) return false;
                }
                return false;
            } else {
                return index < this.limit;
            }
        } else {
            return !this.options.omitEmpty || child.get('widget') == PropertyModel.WIDGET_TYPE_SINGLE || child.get('value') && child.get('value').length;
        }
    },

    isEmpty() {
        if(CollectionView.prototype.isEmpty.call(this)) {
            this.trigger('empty');
            return true;
        } else {
            this.trigger('not-empty');
            return false;
        }
    }
});

export default LayoutView.extend({
    template: template,
    className: "properties-widget widget",

    regions: {
        properties: '[data-js-collection-region]'
    },

    ui: {
        less: '[data-js-btn-less], [data-js-btn-collapse]',
        more: '[data-js-btn-more], [data-js-btn-expand]'
    },

    triggers: {
        'click @ui.less' : 'compact',
        'click @ui.more' : 'expanded'
    },

    initialize(options) {
        this.collection = PropertyCollection.createFromVarnamesWithValues(this.model.get('properties'));
        this.listenTo(this.collection, 'change:value', () => {
            this.model.set('properties', this.collection.toVarnamesWithValues(), { changedByCollectionChange: true });
        });
        this.listenTo(this.model, 'change:properties', (model, properties, options) => {
            if(options.changedByCollectionChange) return;

            this.collection.resetByVarnamesWithValues(this.model.get('properties'));

            if(this.options.limit && Number(this.options.limit) > 0 && Number(this.options.limit) < this.collection.length) {
                this.trigger('compact');
            } else {
                this.trigger('full');
            }
        });

        this.listenTo(this, 'compact', () => { 
            this.el.classList.add('compact');
            this.el.classList.remove('expanded');
            this.properties.currentView.trigger('compact');
        });

        this.listenTo(this, 'expanded', () => { 
            this.el.classList.remove('compact');
            this.el.classList.add('expanded');
            this.properties.currentView.trigger('expanded');
        });

        this.listenTo(this, 'full', () => { 
            this.el.classList.remove('compact');
            this.el.classList.remove('expanded');
            this.properties.currentView.trigger('expanded');
        });

        this.viewModel = new ViewModel;

        this.listenTo(this.viewModel, 'change:disabled', 
            (m, disabled) => this.regionManager.each(
                r => r.currentView && r.currentView.viewModel && r.currentView.viewModel.set('parentDisabled', disabled)));

        this.on('disable', () => this.viewModel.set('disable', true));
        this.on('enable', () => this.viewModel.set('disable', false));
    },

    onRender() {
        let propertiesCollectionView = new PropertiesCollectionView({
            childView: this.options.edit ? PropertyEditWidget : PropertyItemWidget,
            collection: this.collection,
            limit: this.options.limit,
            omitEmpty: !this.options.edit,
            viewModel: this.viewModel
        });
        this.listenTo(propertiesCollectionView, 'empty', () => this.el.classList.add('empty'));
        this.listenTo(propertiesCollectionView, 'not-empty', () => this.el.classList.remove('empty'));

        this.properties.show(propertiesCollectionView);

        if(this.options.limit && Number(this.options.limit) > 0 && Number(this.options.limit) < this.collection.length) {
            this.trigger('compact');
        } else {
            this.trigger('full');
        }
    }
});
