import { Optional } from "@b2cmessenger/backbone";
import Window from 'windows/Window';
import './ReservationCalendar.scss';
import CalendarLayoutView from "../../../widgets/Calendar/CalendarLayoutView";
import AjaxError from "../../../utils/AjaxError";
import ReservationSummaryCollection from "models/ReservationSummaryCollection";

const ReservationCalendarWindow = Window.extend({
    windowName: 'reservation-calendar-window',
    className: 'reservation-calendar-window',

    show(options) {
        _.extend(this.options, _.defaults(options || (options = {})));

        return Window.prototype.show.apply(this, arguments);
    },

    onRender() {
        const reservationCollection = new ReservationSummaryCollection();
        const contentView = this.contentView = new CalendarLayoutView(_.extend({
            additionalCollection: reservationCollection
        }, _.pick(this.options, 'calendarInitialDate')));

        this.content.show(contentView);
        this.listenTo(contentView, 'close', value => this.close(value));

        this.listenTo(contentView, 'update:additional:collection', startEndObj => {
            if (!startEndObj || !startEndObj.start || !startEndObj.end) {
                return;
            }

            if (startEndObj.start && !(startEndObj.start instanceof Date)) {
                startEndObj.start = new Date(startEndObj.start);
            }
            if (startEndObj.end && !(startEndObj.end instanceof Date)) {
                startEndObj.end = new Date(startEndObj.end);
            }

            reservationCollection.fetch(_.extend({
                dateBefore: B2Cjs.datetimeJSToServerLocal(startEndObj.end, true),
                dateAfter: B2Cjs.datetimeJSToServerLocal(startEndObj.start, true),
                remove: false,
                error: (col, error) => {
                    this.showError(new AjaxError(error));
                }
            }, this.options));
        });
    }
});

export default ReservationCalendarWindow;
