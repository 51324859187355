var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (cid, id, localFileUrl, model, p, ph, th) {p = p ? 1 : 0
id = id || null
cid = model && model.cid || null
let url = th || ph || model.get('thumbUrl')
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["thumb_cont",(p == 0 ? 'draft' : '')], [false,true]), false, true)+pug.attr("data-id", id, true, true)+pug.attr("data-cid", cid, true, true)+pug.attr("data-ph", ph, true, true)+pug.attr("data-th", th, true, true)) + "\u003E";
if (localFileUrl) {
pug_html = pug_html + "\u003Cimg" + (" class=\"thumb local\""+pug.attr("src", localFileUrl, true, true)) + "\u003E";
}
if (url) {
pug_html = pug_html + "\u003Cimg" + (" class=\"thumb remote\""+pug.attr("src", url, true, true)) + "\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"delmark\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";}.call(this,"cid" in locals_for_with?locals_for_with.cid:typeof cid!=="undefined"?cid:undefined,"id" in locals_for_with?locals_for_with.id:typeof id!=="undefined"?id:undefined,"localFileUrl" in locals_for_with?locals_for_with.localFileUrl:typeof localFileUrl!=="undefined"?localFileUrl:undefined,"model" in locals_for_with?locals_for_with.model:typeof model!=="undefined"?model:undefined,"p" in locals_for_with?locals_for_with.p:typeof p!=="undefined"?p:undefined,"ph" in locals_for_with?locals_for_with.ph:typeof ph!=="undefined"?ph:undefined,"th" in locals_for_with?locals_for_with.th:typeof th!=="undefined"?th:undefined));;return pug_html;};
module.exports = template;