import { Model } from '@b2cmessenger/backbone';

@Model.properties({
    defaults: {
        th: null,
        ph: null,
        th_w: null,
        th_h: null,
        ph_w: null,
        ph_h: null,
    },

    computeds: {
        url: {
            deps: ['th', 'ph'],
            get: (th, ph) => (ph && /\.gif$/.test(ph) && ph) ||
                th || ph || null
        },
        w: {
            deps: ['th', 'ph', 'th_w', 'ph_w'],
            get(th, ph, th_w, ph_w) {
                if (th) {
                    return th_w || null;
                } else if (ph) {
                    return ph_w || null;
                }

                return null;
            },
            set(v) {
                if (v) {
                    const th = this.get('th');
                    if (th) {
                        return { th_w: v };
                    }

                    const ph = this.get('ph');
                    if (ph) {
                        return { ph_w: v };
                    }
                }
            }
        },
        h: {
            deps: ['th', 'ph', 'th_h', 'ph_h'],
            get(th, ph, th_h, ph_h) {
                if (th) {
                    return th_h || null;
                } else if (ph) {
                    return ph_h || null;
                }

                return null;
            },
            set(v) {
                if (v) {
                    const th = this.get('th');
                    if (th) {
                        return { th_h: v };
                    }

                    const ph = this.get('ph');
                    if (ph) {
                        return { ph_h: v };
                    }
                }
            }
        },
        ar: {
            deps: ['w', 'h'],
            get: (w, h) => (w && h && w / h) || 1
        }
    },
})
class MessagePhotoModel extends Model {
    
};

export default MessagePhotoModel;
