import { put, takeEvery, select, all, take } from 'redux-saga/effects';
import { getMenuOrderNotificationsFromNotifications, getMenuWaiterCallNotifications } from "store/app/utils";
import { updateOrdersDueToNotifications } from "store/menu/orders/actions";
import { ADD_NOTIFICATIONS, LOGOUT } from "store/app/actions";
import { onLogoutMenuSaga } from "store/menu/sagas";

function* onAddNotifications(action) {
    const { notifications = [] } = action.payload;

    // Update orders in store by notifications
    const menuOrderNotificationsWithUpdate = getMenuOrderNotificationsFromNotifications(notifications);
    const menuWaiterCallNotifications = getMenuWaiterCallNotifications(notifications);

    const menuOrderNotifications = [...menuOrderNotificationsWithUpdate, ...menuWaiterCallNotifications];
    if (menuOrderNotifications && menuOrderNotifications.length > 0) {
        yield put(updateOrdersDueToNotifications(menuOrderNotifications));
    }
}

function* onLogout() {
    yield onLogoutMenuSaga();
}

function* watchAppEffects() {
    yield takeEvery(ADD_NOTIFICATIONS, onAddNotifications);
    yield takeEvery(LOGOUT, onLogout);
}

export default function* appSagas() {
    yield all([watchAppEffects()])
}
