import { Model, ViewModel, CollectionView, ItemView } from '@b2cmessenger/backbone';
import AjaxError from 'utils/AjaxError';
import BroadcastCampaignModel from 'models/BroadcastCampaignModel';
import BroadcastCollection from 'models/BroadcastCollection';

import Page from 'pages/Page';
import ContainerWithPullToRefresh from 'traits/ContainerWithPullToRefresh';
import HeaderView from 'widgets/Header/Header';
import FooterView from 'widgets/Footer/Footer';
import LeftMenuWindow from 'windows/LeftMenu/LeftMenu';
import BroadcastCollectionView from 'widgets/Broadcast/BroadcastCollection';
import BroadcastCampaignItemView from 'widgets/BroadcastCampaign/BroadcastCampaignItem';
import TaskTargetEditorWindow from 'windows/TaskTargetEditor/TaskTargetEditor';
import BroadcastHistoryWindow from 'windows/BroadcastHistory/BroadcastHistory';

import template from './BroadcastCampaign.jade';
import './BroadcastCampaign.scss';

import TaskTargetModel from 'models/TaskTargetModel';
import targetTemplate from './Target.jade';
import taskTemplate from './Task.jade';
import escapeHtml from 'utils/escapeHtml';
import ViewWithWindows from 'traits/ViewWithWindows';

const withWindowsTrait = {
    Trait: ViewWithWindows,
    options: {
        windowMap: [
            {
                cls: TaskTargetEditorWindow,
                trigger() {
                    const target = this.model.get('target');

                    if (target && target.get('status') == TaskTargetModel.Status.Unknown) {
                        this.listenToOnce(target, 'sync', () => {
                            this.ui.btnTarget.click();
                        })
                    } else {
                        this.ui.btnTarget.click();
                    }
                }
            },
            {
                cls: BroadcastHistoryWindow,
                trigger() {
                    const target = this.model.get('target');

                    if (target && target.get('status') == TaskTargetModel.Status.Unknown) {
                        this.listenToOnce(target, 'sync', () => {
                            this.ui.btnHistory.click();
                        })
                    } else {
                        this.ui.btnHistory.click();
                    }
                }
            }
        ],
    }
};

const BroadcastCampaignPage = Page.extendWithTraits([
    {
        Trait: ContainerWithPullToRefresh,
        options: {
            container: '> .content-region'
        }
    }, withWindowsTrait
], {
    getCampaignId() {
        return this.model.id;
    },

    refresh() {
        const refreshPromise = (this.viewModel.get('refreshPromise') || Promise.resolve());

        let e;
        const promise = refreshPromise
            .then(() => this.viewModel.set({ isLoading: true }))
            .then(() => Promise.all([
                new Promise((resolve, reject) => this.model.fetch({
                    success: resolve,
                    error: (c, jqXHR) => reject(new AjaxError(jqXHR))
                })),
                new Promise((resolve, reject) => this.historyCollection.fetch({
                    pageSize: 10,
                    page: 0,
                    success: (collection, resp, options) => {
                        this.viewModel.set({ historyCollectionTotalCount: options.totalCount });
                        resolve();
                    },
                    error: (c, jqXHR) => reject(new AjaxError(jqXHR))
                }))
            ]))
            .catch(_e => e = _e)
            .then(() => this.viewModel.set({ isLoading: false }))
            .then(() => {
                if (e) {
                    throw e;
                }
            });

        this.viewModel.set({ refreshPromise: promise.catch(e => console.error(e)) });

        return promise;
    },

    template: template,
    attributes: {
        'id': "broadcast-campaign-page"
    },

    className: "broadcast-campaign-page",

    ui: {
        header: '[data-js-header]',
        footer: '[data-js-footer]',
        campaign: '[data-js-campaign]',
        target: '[data-js-target]',
        task: '[data-js-task]',
        history: '[data-js-history]',
        historyTitle: '[data-js-history-title]',
        btnTarget: '[data-js-btn-target]',
        taskTitle: '[data-js-task-title]',
        btnTask: '[data-js-btn-task]',
        btnHistory: '[data-js-btn-history]',
        btnPause: '[data-js-btn-pause]',
        btnResume: '[data-js-btn-resume]'
    },

    regions: {
        header: '[data-js-header]',
        campaign: '[data-js-campaign]',
        target: '[data-js-target]',
        task: '[data-js-task]',
        history: '[data-js-history]',
        footer: '[data-js-footer]'
    },

    computeds: {
        'showBtnHistory': {
            deps: ['historyCollectionRestCount'],
            get: historyCollectionRestCount => historyCollectionRestCount > 0
        },
        'btnHistoryText': {
            deps: ['historyCollectionRestCount'],
            get: historyCollectionRestCount => `See ${historyCollectionRestCount} ${historyCollectionRestCount == 1 ? 'entry' : 'entries'} more`
        },
        'c_canBePaused': {
            deps: ['status'],
            get: status => status == BroadcastCampaignModel.Status.Scheduled
                || status == BroadcastCampaignModel.Status.InProgress
        }
    },

    bindings: {
        '@ui.history': 'classes:{hidden:not(historyCollectionTotalCount)}',
        '@ui.historyTitle': 'classes:{hidden:not(historyCollectionTotalCount)}',
        '@ui.btnTask': 'disabled:disabled',
        '@ui.btnTarget': 'disabled:disabled',
        '@ui.btnHistory': 'disabled:disabled,classes:{hidden:not(showBtnHistory)},text:btnHistoryText',
        '@ui.btnPause': 'disabled:disabled,classes:{hidden:any(not(c_canBePaused), targetPaused)}',
        '@ui.btnResume': 'disabled:disabled,classes:{hidden:any(not(c_canBePaused), not(targetPaused))}'
    },

    events: {
        'click @ui.btnTarget'() {
            this._editTarget();
        },
        'click @ui.btnTask'() {
            const target = this.model.get('target'),
                taskId = target && Number(target.get('task_id'));

            if (taskId) {
                app.controller.goToTaskPage({
                    taskId
                });
            }
        },
        'click @ui.btnHistory'() {
            new BroadcastHistoryWindow({
                campaign: this.model,
                sortBy: this.broadcastCollectionView.getSortBy()
            }).show();
        },
        'click @ui.btnPause'() {
            const target = this.model.get('target');
            if (target && !target.get('paused')) {
                this.viewModel.set({ disabled: true });
                target.save({ paused: 1 }, {
                    wait: true,
                    error: (m, jqXHR, options) => {
                        this.viewModel.set({ disabled: false });
                        if (jqXHR.status == 422) {
                            this._editTarget();
                        } else {
                            this.showError(jqXHR, options.textStatus, options.errorThrown);
                        }
                    },
                    success: () => this.viewModel.set({ disabled: false })
                });
            }
        },
        'click @ui.btnResume'() {
            const target = this.model.get('target');
            if (target && target.get('paused')) {
                this.viewModel.set({ disabled: true });
                target.save({ paused: 0 }, {
                    wait: true,
                    error: (m, jqXHR, options) => {
                        this.viewModel.set({ disabled: false });
                        if (jqXHR.status == 422) {
                            const target = this.model.get('target').clone();
                            target.set({ paused: 0 });
                            this._editTarget(target);
                        } else {
                            this.showError(jqXHR, options.textStatus, options.errorThrown);
                        }
                    },
                    success: () => this.viewModel.set({ disabled: false })
                });
            }
        }
    },

    initialize(options) {
        if (!this.viewModel) {
            this.viewModel = new ViewModel;
        }

        this.viewModel.addComputed('historyCollectionRestCount', {
            deps: ['historyCollectionTotalCount', 'historyCollectionCount'],
            get: (historyCollectionTotalCount, historyCollectionCount) => historyCollectionTotalCount - historyCollectionCount
        });

        this.viewModel.addComputed('traitContainerWithPullToRefreshCanBePulledToRefresh', {
            deps: ['isLoading'],
            get: isLoading => !isLoading
        });

        this.viewModel.set({
            historyCollectionTotalCount: null
        });

        this.model = new BroadcastCampaignModel({ id: 0 });
    },

    _reInitializeRegions() {
        const headerView = this.header.currentView;
        if (headerView) {
            this.stopListening(headerView);
        }

        const historyView = this.history.currentView;
        if (historyView) {
            this.stopListening(historyView);
        }
        return Page.prototype._reInitializeRegions.apply(this, arguments);
    },

    onRender() {
        const headerView = new HeaderView({
            leftButtons: ['back'],
            title: "Broadcast campaign" + (this.model && this.model.get('title') ? (": " + this.model.get('title')) : ""),
            rightButtons: ['notifications', 'menu']
        });
        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.listenTo(headerView, 'notifications:click', () => app.controller.showNotificationsWindow());
        this.listenTo(headerView, 'menu:click', () => new LeftMenuWindow({ right: true }).show());
        this.header.show(headerView);

        this.campaign.show(new BroadcastCampaignItemView({
            model: this.model,
        }));

        this.target.show(new TaskTargetItem({
            model: this.model.get('target')
        }));

        this.task.show(new TaskItem({
            model: this.model
        }));

        this.broadcastCollectionView = new BroadcastCollectionView({
            collection: this.historyCollection
        });
        this.history.show(this.broadcastCollectionView);

        this.listenTo(this.broadcastCollectionView, 'change:sortBy', this.refresh.bind(this));
    },

    onPulledToRefresh() {
        this.refresh();
    },

    show(options) {
        _.defaults(options || (options = {}), {
            campaign: this.model
        });

        if (_.isNumber(options.campaign) || _.isString(options.campaign)) {
            this.model.id = options.campaign;
        } else if (options.campaign instanceof Model) {
            this.model = options.campaign;
        } else {
            throw new Error("options.campaign is empty or incorrect!");
        }

        if (
            !this.historyCollection
            ||
            this.historyCollection
            &&
            (
                options.historyCollection instanceof BroadcastCollection && options.historyCollection != this.historyCollection
                ||
                this.historyCollection.targetId != this.model.id
            )
        )
        {
            if (this.historyCollection) {
                this.viewModel.stopListening(this.historyCollection, 'update reset');
                this.viewModel.set({
                    historyCollectionCount: null,
                    historyCollectionTotalCount: null
                });
            }

            this.historyCollection = (options.historyCollection instanceof BroadcastCollection)
                ? options.historyCollection
                : new BroadcastCollection(null, { campaign: this.model });
            this.viewModel.set({ historyCollectionCount: this.historyCollection.length });
            this.viewModel.listenTo(this.historyCollection, 'update reset',
                c => this.viewModel.set({ historyCollectionCount: c.length }));
        }

        if (!this.isRendered) {
            $("#pageContainer").append(this.el);
            this.render();
            this.triggerMethod('before:attach');
            this.triggerMethod('attach');

            this.$el.on("pageshow", () => this.onShow());
            this.$el.on("pagehide", () => this.onHide());
        } else {
            this.render();
        }

        return Page.prototype.show.apply(this, arguments);
    },

    onShow(options) {
        this.refresh();
        return Page.prototype.onShow.apply(this, arguments);
    },

    _editTarget(targetModel) {
        return new TaskTargetEditorWindow({
            model: targetModel || this.model.get('target').clone(),
            validateAtStart: true,
            returnNullIfModelHasNotBeenSaved: true
        })
            .show()
            .then(target => {
                if (target) {
                    this.model.get('target').set(target.attributes);
                }
            });
    }
});

export default BroadcastCampaignPage;

const TaskTargetItem = ItemView.extend({
    template: targetTemplate,
    className: 'widget task-target-item-widget',

    ui: {
        title: '[data-js-title]',
        broadcastSettings: '[data-js-broadcast-settings]',
        targetSettings: '[data-js-target-settings]',
    },

    computeds: {
        'c_targetSettings': {
            deps: [
                'age_min', 'age_max', 'gender', 'days_before_birthday', 'distance',
                'client_knowledge', 'interest_categories', 'interest_strength'
            ],
            get: (age_min, age_max, gender, days_before_birthday, distance,
                client_knowledge, interest_categories, interest_strength) => {

                const settings = new Array;
                if (age_min && age_max) settings.push(`age from ${age_min} to ${age_max}`);
                else if (age_min) settings.push(`age > ${age_min}`);
                else if (age_max) settings.push(`age < ${age_max}`);

                if (gender && gender.length) {
                    const genders = _.intersection(gender, ['male', 'female', 'other']);
                    if (genders.length == 1) {
                        settings.push(`only ${genders[0]}s`);
                    } else if (genders.length == 2) {
                        settings.push(`only ${genders[0]}s and ${genders[1]}s`);
                    }
                }

                if (!_.isNull(days_before_birthday)) {
                    const days = Number(days_before_birthday);
                    if (days > 0) {
                        settings.push(`${days} day${days == 1 ? '' : 's'} before birthday`);
                    } else {
                        settings.push(`birthday is today`);
                    }
                }

                if (!_.isNull(distance)) {
                    settings.push(`within ${distance} mile${distance == 1 ? '' : 's'}`)
                }

                if (client_knowledge) {
                    settings.push(`clients aware of brand`);
                }

                if (interest_categories && interest_categories.length) {
                    let str;
                    if (interest_categories.length == 1) {
                        const cat = window.categoryCollection.get(interest_categories[0]);
                        if (cat) {
                            str = cat.get('name');
                        } else {
                            str = '1 category';
                        }
                    } else if (interest_categories.length == 2) {
                        const cat0 = window.categoryCollection.get(interest_categories[0]),
                            cat1 = window.categoryCollection.get(interest_categories[1]);

                        if (cat0 && cat1) {
                            str = cat0.get('name') + ' and ' + cat1.get('name');
                        } else {
                            str = '2 categories';
                        }
                    } else {
                        str = interest_categories.length + ' categories';
                    }

                    if (interest_strength == 1 || interest_strength == 2) {
                        settings.push(`took interest in ${str} over a ${interest_strength == 1 ? 'week' : 'month'}`);
                    } else {
                        settings.push(`interested in ${str}`);
                    }
                }

                return settings.join(', ');
            }
        },
        'c_broadcastSettings': {
            deps: ['client_limit', 'repeat', 'dateStart', 'dateEnd', 'sendHour', 'sendMinute'],
            get: (client_limit, repeat, dateStart, dateEnd, sendHour, sendMinute) => {
                const settings = new Array;

                if (client_limit) {
                    settings.push(`limit: ${client_limit}`);
                }

                if (!repeat || repeat == TaskTargetModel.RepeatPeriods.Once) {
                    if (dateStart && !_.isNull(sendHour)) {
                        const date = new Date(dateStart);
                        date.setHours(sendHour, sendMinute || 0, 0, 0);
                        settings.push(`once at ${date.toLocaleString(undefined, { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}`);
                    } else if (dateStart) {
                        settings.push(`once at ${dateStart.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}`);
                    } else if (!_.isNull(sendHour)) {
                        const date = new Date;
                        date.setHours(sendHour, sendMinute || 0, 0, 0);
                        settings.push(`once at ${date.toLocaleTimeString(undefined, { hour: 'numeric' })}`);
                    }
                } else {
                    const repeatStr =
                        repeat == TaskTargetModel.RepeatPeriods.Day ? 'every day' :
                            repeat == TaskTargetModel.RepeatPeriods.Week ? 'every week' :
                                repeat == TaskTargetModel.RepeatPeriods.Month ? 'every month' :
                                    'every year';

                    let hourStr = '';
                    if (!_.isNull(sendHour)) {
                        const date = new Date;
                        date.setHours(sendHour, sendMinute || 0, 0, 0);
                        hourStr = ` at ${date.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' })}`;
                    }

                    let periodStr = ''
                    if (dateStart && dateEnd) {
                        periodStr = ` from ${dateStart.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })} until ${dateEnd.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}`;
                    } else if (dateStart) {
                        periodStr = ` from ${dateStart.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}`;
                    } else if (dateEnd) {
                        periodStr = ` until ${dateEnd.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}`;
                    }

                    settings.push(repeatStr + hourStr + periodStr);
                }

                return settings.join(', ');
            }
        }
    },

    bindings: {
        '@ui.title': 'text:title',
        '@ui.broadcastSettings': 'text:c_broadcastSettings',
        '@ui.targetSettings': 'text:c_targetSettings',
    },
});

const TaskItem = ItemView.extend({
    template: taskTemplate,
    className: 'widget task-item-widget',

    ui: {
        title: '[data-js-title]',
        text: '[data-js-text]',
        likesDislikes: '[data-js-likes-dislikes]',
        likes: '[data-js-likes]',
        dislikes: '[data-js-dislikes]',
        comments: '[data-js-comments]'
    },

    computeds: {
        'text': {
            deps: ['task'],
            get: task => task && task.text || ''
        },
        'likesHtml': {
            deps: ['task'],
            get: task => task && (Number(task.likes) || !Number(task.dislikes)) && `<span class="count">${Number(task.likes)}</span> ${task.likes == 1 ? 'like' : 'likes'}` || ""
        },
        'dislikesHtml': {
            deps: ['task'],
            get: task => task && (Number(task.dislikes) || !task.likes) && `<span class="count">${Number(task.dislikes)}</span> ${task.dislikes == 1 ? 'dislike' : 'dislikes'}` || ""
        },
        'areLikesZero': {
            deps: ['task'],
            get: task => !task || !task.likes
        },
        'areDislikesZero': {
            deps: ['task'],
            get: task => !task || !task.dislikes
        },
        'comments': {
            deps: ['task'],
            get: task => task && `${task.commentscount} ${task.commentscount == 1 ? 'comment' : 'comments'}` || "unknown"
        }
    },

    bindings: {
        '@ui.title': 'text:title',
        '@ui.text': 'text:text',
        '@ui.likes': 'html:likesHtml,classes:{empty:areLikesZero}',
        '@ui.dislikes': 'html:dislikesHtml,classes:{empty:areDislikesZero}',
        '@ui.comments': 'text:comments'
    },
});
