import React from 'react';
import { WindowContent, WindowFooter, WindowHeader } from 'components/window/Window';
import Header from 'components/UI/Header/Header';
import { BackButton } from 'components/UI/Button/Button';
import Footer from 'components/UI/Footer/Footer';
import KeyboardPadding from 'components/KeyboardPadding';

import './MenuCartItemRemoveChooser.scss';
import ReactWindow from 'windows/ReactWindow';
import TextButton from 'components/UI/TextButton';
import MenuItemsWithControl from 'components/menu/MenuItems/MenuItemsWithControl';
import { getAdditionItemPrice, getMenuCartItem } from 'store/menu/utils';
import MenuCartItemInfo from 'components/menu/MenuCart/MenuCartItemInfo';

function getMenuCartChangesInfo(item) {
    let error = null;
    let info = null;

    const isQuantityChanged = item.count !== item.maximum;
    const isRemoving = item.count <= 0;

    const changedAdditions = item.additions.filter((a) => a.count !== a.maximum);
    const isSomeAdditionsChanged = changedAdditions.length > 0 && item.additions.length > changedAdditions.length;
    const isAllAdditionsChanged = changedAdditions.length > 0 && changedAdditions.length === item.additions.length;

    const initialPrice =
        (item.price + item.additions.reduce((a, b) => a + getAdditionItemPrice(b) * b.maximum, 0)) * item.maximum;
    const price = (item.price + item.additions.reduce((a, b) => a + getAdditionItemPrice(b) * b.count, 0)) * item.count;

    const isPriceChanged = initialPrice !== price;

    if (isRemoving) {
        error = 'This item will be removed from your order';
    } else if (isAllAdditionsChanged && isQuantityChanged) {
        info = 'This item will decrease its quantity and quantity of all of its additions';
    } else if (isSomeAdditionsChanged && isQuantityChanged) {
        info = 'This item will decrease its quantity and quantity of some of its additions';
    } else if (isAllAdditionsChanged) {
        info = 'This item will decrease quantity of all of its additions';
    } else if (isSomeAdditionsChanged) {
        info = 'This item will decrease quantity of some of its additions';
    } else if (isQuantityChanged) {
        info = 'This item will decrease its quantity';
    }

    return {
        isRemoving,
        isQuantityChanged,
        isAllAdditionsChanged,
        isSomeAdditionsChanged,
        error,
        info,
        isPriceChanged,
        price,
        changedAdditions,
    };
}

const MenuCartItemRemoveChooser = (props) => {
    const { closeWindow } = props;

    const [items, setItems] = React.useState([]);
    const [changedItems, setChangedItems] = React.useState([]);

    const applyButtonText = React.useMemo(() => {
        if (changedItems.filter((i) => i.changes && i.changes.isRemoving).length === items.length) {
            return 'REMOVE ALL';
        }

        return changedItems.length
            ? `APPLY ${changedItems.length} CHANGE${changedItems.length > 1 ? 'S' : ''}`
            : `APPLY`;
    }, [items, changedItems]);
    const [cartItems, setCartItems] = React.useState([]);

    React.useEffect(() => {
        const values = _.map(cartItems, (cartItem) => {
            const initialCartItem = props.cartItems.find((ci) => ci.uniqueId === cartItem.uniqueId);

            const item = {
                ...cartItem,
                ...props.items[cartItem.id],
                count: cartItem.quantity,
                maximum: (initialCartItem && initialCartItem.quantity) || null,
                properties: _.chain(cartItem.properties)
                    .keys()
                    .map((key) => ({ name: key, value: cartItem.properties[key] }))
                    .value(),
                additions: _.chain(cartItem.additions)
                    .keys()
                    .map((key) => {
                        const count = cartItem.additions[key];
                        const addition = props.items[key];

                        return {
                            ...addition,
                            count: cartItem.quantity > 0 ? count : cartItem.quantity,
                            maximum:
                                cartItem.quantity > 0
                                    ? initialCartItem && initialCartItem.additions[key]
                                    : cartItem.quantity,
                            // minimum: initialCartItem && initialCartItem.additions[key]
                        };
                    })
                    .value(),
            };

            return {
                ...item,
                changes: getMenuCartChangesInfo(item),
            };
        });

        const changes = values.filter((v) => v.changes && Boolean(v.changes.info || v.changes.error));
        setChangedItems(changes);
        setItems(values);
    }, [props.items, props.cartItems, cartItems, setChangedItems, setItems]);

    React.useEffect(() => {
        setCartItems([...props.cartItems]);
    }, [props.cartItems]);

    const onChangeCount = React.useCallback(
        ({ item = {}, count = 0 } = {}) => {
            setCartItems((cartItems) =>
                cartItems.map((cartItem) => {
                    if (cartItem.uniqueId === item.uniqueId) {
                        return { ...cartItem, quantity: count };
                    }

                    return cartItem;
                })
            );
        },
        [setCartItems]
    );

    const onChangeAddition = React.useCallback(
        (uniqueId, changes) => {
            setCartItems((cartItems) => {
                return cartItems.map((cartItem) => {
                    if (cartItem.uniqueId === uniqueId) {
                        return { ...cartItem, additions: { ...cartItem.additions, [changes.item.id]: changes.count } };
                    }

                    return cartItem;
                });
            });
        },
        [setCartItems]
    );

    const childrenFn = React.useCallback(
        (i) => {
            return <MenuCartItemInfo {...i} onChangeAddition={onChangeAddition} />;
        },
        [onChangeAddition]
    );

    const listItemClassNameFn = React.useCallback((i) => {
        return [
            'menu-list-item-wrap',
            i.changes && Boolean(i.changes.info) && 'menu-list-item-wrap-warning',
            i.changes && Boolean(i.changes.error) && 'menu-list-item-wrap-error',
        ]
            .filter(Boolean)
            .join(' ');
    }, []);

    const applyChanges = React.useCallback(() => {
        const remove = changedItems
            .filter((c) => c.changes && c.changes.isRemoving)
            .reduce((memo, c) => ({ ...memo, [c.uniqueId]: getMenuCartItem(c) }), {});

        const change = changedItems
            .filter((c) => c.changes && !c.changes.isRemoving)
            .reduce(
                (memo, c) => ({
                    ...memo,
                    [c.uniqueId]: {
                        ...getMenuCartItem(c),
                        quantity: c.count,
                    },
                }),
                {}
            );

        closeWindow({ remove, change });
    }, [changedItems, closeWindow]);

    return (
        <>
            <WindowHeader>
                <Header>
                    <BackButton onClick={() => closeWindow()} />
                    <h1 className="title">Choose what to remove</h1>
                </Header>
            </WindowHeader>
            <WindowContent>
                <MenuItemsWithControl
                    className="menu-list-cart-items"
                    items={items}
                    onChangeCount={onChangeCount}
                    childrenFn={childrenFn}
                    listItemClassName={listItemClassNameFn}
                />
            </WindowContent>
            <WindowFooter>
                <Footer>
                    <TextButton onClick={() => closeWindow()}>CANCEL</TextButton>
                    <TextButton
                        onClick={applyChanges}
                        className="menu-cart-item-remove-chooser-apply"
                        disabled={changedItems.length === 0}
                    >
                        {applyButtonText}
                    </TextButton>
                </Footer>
            </WindowFooter>
            <KeyboardPadding />
        </>
    );
};

const MenuCartItemRemoveChooserWindow = ReactWindow.extend({
    constructor: function MenuCartItemRemoveChooserWindow(props) {
        const options = {
            props,
            name: 'MenuCartItemRemoveChooser',
            component: MenuCartItemRemoveChooser,
        };

        return ReactWindow.apply(this, [options]);
    },
});

export default MenuCartItemRemoveChooserWindow;
