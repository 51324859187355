import QRCode from 'qrcode';


/**
 * Generates dataURI with QR code image from string code or model with `code` attribute
 *
 * @param {Object|string} modelOrCode
 * @param {Object} [options] Refer to https://github.com/soldair/node-qrcode
 * @returns {Promise}
 */
export default function (modelOrCode, options) {
    return new Promise((resolve, reject) => {
        let code;

        if (_.isObject(modelOrCode) && _.isFunction(modelOrCode.get)) {
            code = modelOrCode.get('code');
        } else if (_.isNumber(modelOrCode)) {
            if (_.isNaN(modelOrCode) || !_.isFinite(modelOrCode)) {
                throw new Error(`Invalid modelOrCode: NaN or Infinity`);
            }

            code = String(modelOrCode);
        } else {
            code = modelOrCode;
        }

        options = _.defaults(options || {}, {errorCorrectionLevel: 'H'});

        QRCode.toDataURL(code, options, function (err, url) {
            if (err) {
                reject(err);
            } else {
                resolve(url);
            }
        });
    });
};