import PlatformFeature from './PlatformFeature';
import FacebookAuthProxy from 'utils/FacebookAuthProxy';
import { userDeviceInfo } from './UserDeviceInfo';
import settings from 'settings';

class FacebookAuth extends PlatformFeature {
    constructor(options) {
        super(options);

        if (!this.options.appId) {
            this.options.appId = '1664667817088378';
            // this.options.appId = '408833056502523';
        }

        if (!this.options.permissions) {
            _.extend(this.options, { permissions: ['public_profile', 'email'] });
        }

        if (!this.options.fields) {
            _.extend(this.options, { fields: 'id,email,first_name,last_name,gender,verified' });
        }
    }

    async initialize() {
///////////////////////
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

/////////////////
        this.initializePromise = new Promise((resolve, reject) => {
            FacebookAuthProxy.init(this.options.appId, () => resolve(FacebookAuthProxy));
        });

//////////////////

        return this.initializePromise.then((apiSource) => {
            this.ready = true;
            this.FBApi = apiSource;
        }).catch((error) => {
            console.error(`FacebookAuth error: ${error}`);
            this.ready = false;
            return error;
        });
    }

    async login() {
        return this.ensureInitialized()
            .then(() => this.logout())
            .then(() =>
                new Promise((resolve, reject) => {
                    this.FBApi.login(
                        this.options.permissions,
                        resolve,
                        e => {
                            if (typeof e === 'string') {
                                e = { errorCode: e };
                            }

                            if (e && (e.errorCode === '4201' || e.errorCode === 'There was a problem logging you in.')) {
                                this.FBApi.getLoginStatus(resolve, reject);
                            } else if (_.isUndefined(e)) {
                                reject('An error has occurred. Please try again later.');
                            } else {
                                reject(e);
                            }
                        }
                    );
                })
            )
            .then(userData => {
                return this.request_info(userData.authResponse.userID)
                    .then(data => {
                        return {
                            user_id: userData.authResponse.userID,
                            token: userData.authResponse.accessToken,
                            expires: userData.authResponse.expiresIn,
                            birthday: data.birthday,
                            email: data.email,
                            avatar: 'https://graph.facebook.com/' + userData.authResponse.userID + '/picture?type=large&width=720&height=720‌',
                            firstname: data.first_name,
                            lastname: data.last_name,
                            gender: data.gender,
                            info: userDeviceInfo.getInfo()
                        }
                    });
            })
            .then(data => {
                return new Promise((resolve, reject) => {
                    Server.callServer({
                        url: settings.host + settings.serv_user.facebook_login,
                        dataType: 'json',
                        method: 'POST',
                        data: data,
                        success: resolve,
                        error: reject
                    });
                });
            });
    }

    async logout() {
        return this.ensureInitialized()
            .then(() => this.request_status())
            .then(({ status }) => {
                if (status === 'connected') {
                    return new Promise((resolve, reject) => {
                        this.FBApi.logout(resolve, reject);
                    });
                }

                return Promise.resolve(true);
            });
    }

    async request_info(userId) {
        return this.ensureInitialized()
            .then(() =>
                new Promise((resolve, reject) => {
                    this.FBApi.api(userId + '/?fields=' + this.options.fields, this.options.permissions, resolve, reject)
                })
        );
    }

    async request_status() {
        return this.ensureInitialized()
            .then(() =>
                new Promise((resolve, reject) => {
                    this.FBApi.getLoginStatus(resolve, reject);
                })
            );
    }
}

export default new FacebookAuth();
