/**
 * Created by Alex on 04.05.2016.
 */
/**
 * Класс, представлющий из себя Controller и View для управления и отображения отдельно взятым Подарком
 *
 * Объектами данного класса могут быть "подарки" или "списание подарка"[this.gift.quantity < 0]
 *
 * Также объектами данного класса могут быть агрегационные подарки.
 * Агрегационный подарок это абстракция на уровне клиентской стороны, которой нет на стороне сервера.
 * Агрегационный подарок может быть только двух типов:
 * 1) B2CGiftTemplate.const.type.coins - монеты
 * 2) B2CGiftTemplate.const.type.quantitive - исчесляемые подарки
 *
 * Агрегационные подарки нужны:
 * 1) Чтобы визуально упростить подсчет сколько чего есть у пользователя
 * 2) Упростить списание подарков - представляют единую панель списания
 *
 * Агрегационный подарок типа Монета B2CGiftTemplate.const.type.coins агрегирует в себе все начисления и списания монет у конкретного пользоватлея в рамках конкретного бренда
 *
 * Агрегационный подарок типа Исчесляемый подарок B2CGiftTemplate.const.type.quantitive в себе все начисления и списания подарков у конкретного пользоватлея в рамках конкретного бренда и КОНКРЕТНОГО ШАБЛОНА ПОДАРКОВ
 *
 * Списание монет (Coins) происходит из "общего котла", поэтому подарок-списание такого типа не привязывается к какому-либо подарку-начислению
 *
 * Списание подарков типа Исчесляемый подарок B2CGiftTemplate.const.type.quantitive происходит всегда с конкретного подарка начисления. В этом случае Агрегационный подарок становится особенно правильным подходом, т.к. сам выбирает с каких падарков-начислений произвести списание, выбирая их по принципу наболее ранней просрочки
 *
 * @param options
 * @param options.gift - (type : Object not required) объект подарка в формате сервера
 * @param options.template - (type : Object not required) объект шаблона подарка в формате сервера
 * @param options.type - (type : integer not required) тип подарка (совпадает) с template.type
 * @param options.brand_id - (type : integer; required if options.is_aggregated) идентификатор бренда
 * @param options.user_id - (type : integer; required if options.is_aggregated) идентификатор владельца подарка
 * @param options.b_user - (type : Object) объект бизнес пользователя, который создал подарок
 * @param options.jq_gift - (type: JQuery Object; not required) существующий DOM элемент
 * @param options.is_aggregated - (type: boolean; not required) Если true - то объект описываем сразу несколько подарков с общим шаблоном
 * @param options.is_can_writeoff - (type: boolean; not required) Если true - то текущий пользователь имеет права на списание подарка
 * @param options.is_gifted - (type: boolean; not required) Если true (default) - то подарок уже считается подаренным (создан на сервере), если false - то бизнес еще не подтвердил дарение этого подарка пользователю (еще не создан на сервере)
 * @param options.show_compact - (type: boolean; not required) Если true - то будет показано в компактном режиме
 * @param options.show_born - (type: boolean; not required) Если true - то будет показано в режиме, как-будто подарок был подарен только что
 * @constructor
 */
B2CGift = function(options) {
    // Расширяем опции, опциями по умолчнаию
    options = $.extend(false, {}, this.def_options, options);
    
    if (options.jq_gift != null) {
        // Проверяем, был ли в options.jq_gift сохранен уже ранее сгенерированный объект класса B2CGift
        var b2cGift = options.jq_gift.data(this._className);
        if (b2cGift != null)
            // Если был, то возвращаем его вместо создания нового объекта
            return b2cGift;
        this.jq_gift = options.jq_gift;
    }

    this.options = options;
    // Сохраняем объект подарка в серверном формате для быстрого доступа
    this.gift = options.gift;
    // Сохраняем объект шаблона подарка в серверном формате для быстрого доступа
    this.template = options.template;
    // Задаем типа подарка либо на основании шаблона, либо на основании опции options.type
    if(this.template != null) {
        this.type = this.template.type;
    } else {
        this.type = options.type;
    }

    // Сохраняем объект публичного профиля бизнес-пользователя, который дарил / списывал подарок
    this.b_user = options.b_user;
    // Отметка, что это абстрактный подарок - Агрегационный подарок (см. описание к конструктору и API.docx)
    this.is_aggregated = options.is_aggregated;

    if (this.gift != null && this.gift.quantity < 0)
        // Количество единиц отрицательное - значит это списание
        this.is_write_off = true; // отметка, что это подарок-списание

    if(this.is_aggregated) {
        // Это агрегационный подарок
        // Контейнер для подарков-начислений
        this.b2c_gifts = {};
        this.b2c_parent_ids_to_writeof_gifts = {}; // {parent_gift_id : [{write_off_gift}, [write_off_gift]]}, где parent_gift_id - идентификатор подарка-начисления, а write_off_gift - подарка-списания с parent_gift_id
        // this.b2c_parent_ids_to_writeof_gifts - служит врменный буфером, на случай, если в агрегационный исчисоимый (B2CGiftTemplate.const.type.quantitive) подарок были добавлены сначала подарки-списания, а потом уже подарки-начисления
        this.brand_id = options.brand_id;
        this.user_id = options.user_id;
    } else if (! this.is_write_off){
        // Это подарок начислений
        // Контейнер для подарков-списаний с данного подарка-начислений
        this.b2c_writeof_gifts = {};
    }

    // Устанваливаем значения quantity - количество едениц подарка
    if(this.type == B2CGiftTemplate.const.type.coins || this.type == B2CGiftTemplate.const.type.quantitive) {
        if(this.is_aggregated) {
            this.quantity = 0;
        } else {
            this.quantity = this.gift.quantity;
        }
    }
    this.quantity_initial = this.gift.quantity_initial;

    this.jq_childs_list = null; // список гифтов потомков
};

B2CGift.prototype = {
    _className : 'B2CGift',// Имя класса - используется HTML, а также для сохранения ссылки на объект B2CGift внутри DOM элемента
    // Опции конструктора по умолчанию
    def_options : {
        is_aggregated : false,
        show_compact : false,
        show_born : false,
        is_gifted : true, // Подарок уже считается подаренным
    },

    /**
     * Строит HTML-код всего контейнера для отображения подарка
     * @returns {string} HTML
     * @private
     */
    build_HTML_gift : function() {
        // HTML-класс - состояние подарка
        var state;
        if (this.is_active())
            state = 'active';
        else
            state = 'inactive';

        // HTML-класс - агрегационный подарок?
        var aggregated = this.is_aggregated ? ' aggregated' : '';
        // HTML-класс - подарок-списание?
        var writeof = this.is_write_off ? ' writeof' : '';

        // HTML-атрибут Идентификатор подарка - отсутствует у агрегационных подарков и подарков еще не созданных на сервере (например, в случае если представителю бизнеса только отображатся диалого дарения подарка)
        var id = this.gift == null ? '' : this.gift.id;

        // HTML-класс - режим отображения компактный?
        var show_compact = this.options.show_compact ? ' show_compact' : '';
        // HTML-класс - режим отображения, как-будто подарок был подарен только что? Будет скрыта информация о списаниях, количество единиц будет отображено без учета ссписаний
        var show_born = this.options.show_born ? ' show_born' : '';

        // HTML-класс - подарок уже был частично списан?
        var writed_off_partly = '';
        if(this.is_write_off) {
            if (this.quantity != null && this.template != null && this.quantity < this.template.quantity)
                writed_off_partly = ' writed_off_partly';
            else
                writed_off_partly = '';
        }

        return '<div class="B2CGift '+state+aggregated+writeof+show_compact+writed_off_partly+show_born+'" data-type="'+this.type+'" data-id="'+id+'">'+this._build_HTML_body()+'</div>';
    },
    /**
     * Строит HTML-код основного тела подарка (содержит практически всю основную информацию для отображения)
     * @returns {string} HTML
     * @private
     */
    _build_HTML_body : function() {
        var model = new Backbone.Model({
            name: this.type !== 1 ? this.template.name : 'Coins',
            thumb: this.template.thumb,
            expireDate: this.get_valid_end_js_date(),
            type: this.type,
            quantity: this.quantity_initial || this.quantity,
            totalQty: Infinity //TODO B2CM-1046 / assigned: sanperrier
        });

        var gift = new GiftWidget({model: model}).render(),
            html = $('<div>').append(gift.$el.clone()).html();

        return '<div class="body">' + html + '</div>';
    },
    /**
     * Генерирует виджет отображения подарка
     *
     * @param jqParent {JQuery Element} родительский контейнер, в которых необходимо будет вставить сгенерированный виджет
     * @param needPrepend {boolean} если true - то сгенерированный виджет будет вставлен в качестве первого потомка, иначе последним
     */
    build_jq_gift : function(jqParent, needPrepend) {
        this.jq_gift = $(this.build_HTML_gift());
        this.jq_gift.data(this._className, this);

        if(needPrepend)
            this.jq_gift.prependTo(jqParent);
        else
            this.jq_gift.appendTo(jqParent);

        this._add_handlers();
    },
    /**
     * Устанавливает обработчики событий на DOM элементах виджета
     * Виджет уже должен быть сгенерирован и записа в this.jq_gift {JQuery Element}
     * @private
     */
    _add_handlers : function() {
        this._add_handlers_body(this.jq_gift.children('.body'));
        //this._add_handlers_bottom_panel(this.jq_gift.children('.bottom_panel'));
    },
    /**
     * Устанавливает обработчики событий на DOM элементах тела пордарка
     * @param jq_body {JQuery Element} - тело подарка
     * @private
     */
    _add_handlers_body : function(jq_body) {
        this._add_handlers_descr(jq_body.children('.descr'))
        this._add_handlers_bottom_panel(jq_body.children('.bottom_panel'));
    },
    /**
     * Устанавливает обработчики событий на DOM элементах описания пордарка
     * @param jq_descr {JQuery Element} - описание подарка
     * @private
     */
    _add_handlers_descr : function(jq_descr) {
        this.jq_quantity = jq_descr.find('.curr_quantity');
    },
    /**
     * Устанавливает обработчики событий на DOM элементах нижней панели пордарка
     * @param jq_bottom_panel {JQuery Element} - нижняя панель подарка
     * @private
     */
    _add_handlers_bottom_panel : function(jq_bottom_panel) {
        this._add_handlers_control_cont(jq_bottom_panel.children('.control_cont'));
    },
    /**
     * Устанавливает обработчики событий на DOM элементах контрольной панели пордарка
     * @param jq_control_cont {JQuery Element} - контрольная панель подарка
     * @private
     */
    _add_handlers_control_cont : function(jq_control_cont) {
        var that = this;
        // Кнопка показать / скрыть подарарки-потомки
        this.jq_btn_childs = jq_control_cont.children('.btn_childs').click(function(){ that._on_btn_childs_click() });
        // Панель управления списанием
        this._add_handlers_write_off_cont(jq_control_cont.children('.write_off_cont'))
    },
    /**
     * Устанавливает обработчики событий на DOM элементах контрольной панели пордарка
     * @param jq_write_off_cont {JQuery Element} - контрольная панель подарка
     * @private
     */
    _add_handlers_write_off_cont : function(jq_write_off_cont) {
        var that = this;
        // Кнопка уменьшить количество единиц подарка (увеличивает количество единиц для списания)
        jq_write_off_cont.find('.btn_minus').click(function(){ that._on_btn_minus_click() });
        // Кнопка увеличить количество единиц подарка (уменьшает количество единиц для списания)
        jq_write_off_cont.find('.btn_plus').click(function(){ that._on_btn_plus_click() });
        // Кнопка списать с подарка выбранное количество единиц
        jq_write_off_cont.find('.btn_writeoff').click(function(){ that._on_btn_writeoff_click() });
        // поле с количеством единиц для списания
        this.jq_input_amount = jq_write_off_cont.find('.input_amount').change(function(){ that._on_input_amount_change() });
    },
    /**
     * Отобразить виджет пользователю, если он бы скрыт
     */
    show : function() {
        this.jq_gift.removeClass('hidden');
    },
    /**
     * Скрыть виджет от пользователю, если он бы показан
     */
    hide : function() {
        this.jq_gift.addClass('hidden');
    },

    /**
     * Переводит виджет в режим отображания частично списанного подарка
     * @private
     */
    _set_gift_as_writed_off_partly : function() {
        if (this.jq_gift == null)
            return;
        this.jq_gift.addClass('writed_off_partly');
        this.jq_btn_childs.removeClass('hidden');
    },
    /**
     * Отображает всплвающее сообщение
     * @param message {String}
     * @private
     */
    _info_popup_show : function(message) {
        if (this.jqInfoPopup == null) {
            var that = this;
            this.jqInfoPopup = $('<div class="b2c_info_popup"></div>').appendTo(this.jq_gift).b2cpopup({
                html_content : '<p class="message"></p>',
                lightMode : true
            });
            this.jqInfoPopup.b2cpopup('getUserCont').click(function(event) { that.jqInfoPopup.b2cpopup('close'); });
        }
        this.jqInfoPopup.b2cpopup('getUserCont').text(message);
        this.jqInfoPopup.b2cpopup('show');
    },

    /**
     * Метод релевантен для агрегантных B2CGift и для B2CGift, которые являеются реальным подарком-списанием, а не его списанеим
     * @param gift {Gift} - добавляемый подарок в серверном формате (см API.docx)
     * @param template {GiftTemplate} - шаблон подарка в серверном формате (см API.docx)
     * @param b_user {User} - публичный профиль бизнес-пользователя, который начислил / списал подарок @gift
     * @param is_new - указывает, но то, что gift был только что создан, у него гарантировано нет списаний
     */
    add_gift : function(gift, template, b_user, is_new){
        template = template == null ? this.template : template;

        var b2c_gift,   // {B2CGift} объект B2CGift, который предсталвяет собой обертку для добавляемого gift
            parent_gift,// {B2CGift} подарок-потомок текущего подарка, который явлеяется родительским подарком по отношению к gift. Такая ситуация возможна, если мы находимся в агрегационном исчислимом (Quantitative) подарке. При этом gift - является подакром-списанием. Соответсвенно parent_gift - подарок начисление, с которого gift является списанием
            child_gifts,// {Array of obj_g_t_b}, где obj_g_t_b = {g : Gift, t : Template, b : User} g - подарок, t - шаблон подарка, b - публичный профиль бизнес-пользователя, который начислил / списал подарок. Это массив дочерних подарков-списаний для parent_gift
            obj_g_t_b; // {g : Gift, t : Template, b : User} g - подарок, t - шаблон подарка, b - публичный профиль бизнес-пользователя, который начислил / списал подарок

        if(gift.quantity != null && is_new) {
            //
            this.quantity += gift.quantity;
            if(this.jq_quantity != null)
                this.jq_quantity.text(this.quantity);
            if(!this.is_aggregated)
                // Отмечаем, что текущий подарок считается частично списанным
                this._set_gift_as_writed_off_partly();
        }

        if(this.is_aggregated) {
            // Мы сейчас в агрегационном подарке
            if (gift.quantity < 0 && this.type != B2CGiftTemplate.const.type.coins) {
                // gift - это подарок-списание, т.к. колиечество единиц отрицательное
                // так как текущий тип подарка не Монеты, а исчисляемый подарок (B2CGiftTemplate.const.type.quantitive),
                // то подарок-списание добавляется не в агрегационный подарок, а в подарок-начисление

                // Находим родительский подарок-начисления
                parent_gift = this.b2c_gifts[gift.parent_gift];
                if(parent_gift == null) {
                    // Родительский подарок начисление еще не был добавлен в данный объект, поэтому необходимо закешировать потомка - gift, чтобы когда будет добавляться родительский подарок-начисление, добавить в него данного поотомка
                    // Создаем кеширующий объект
                    obj_g_t_b = {
                        g : gift,
                        t : template,
                        b : b_user,
                    };

                    // Получаем массив буферных подарков для конкретного подарка-начисления
                    child_gifts = this.b2c_parent_ids_to_writeof_gifts[gift.parent_gift];
                    if(child_gifts == null) {
                        // Массив еще не был создан - создаем
                        this.b2c_parent_ids_to_writeof_gifts[gift.parent_gift] = [obj_g_t_b];
                    } else {
                        child_gifts.push(obj_g_t_b);
                    }
                } else {
                    // Родительский подарок - найден: значит сразу добавляем подарок списание в него
                    parent_gift.add_gift(gift, template, b_user, is_new);
                }
            } else {
                // Текущий агрегационный подарок типа (B2CGiftTemplate.const.type.coins) ИЛИ текущий подарок является подарком начислением,
                // а значит добавляется сразу в него без дополнительных вложенностей
                b2c_gift = new B2CGift({
                    gift : gift,
                    type : this.type,
                    template : template,
                    b_user : b_user,
                    show_compact : true,
                    is_can_writeoff : this.options.is_can_writeoff
                });
                if (this.jq_childs_list != null)
                    // Если уже инициализирован подвиджет списка дочерних подароков, то сразу доавляем в него
                    this._add_to_jq_childs_list(b2c_gift);

                // Получаем закешированные ранее подарки-списания с данного подарка gift. (Релевантно для B2CGiftTemplate.const.type.quantitive)
                child_gifts = this.b2c_parent_ids_to_writeof_gifts[gift.id];
                if (child_gifts != null) {
                    // Добавляем все его подарки-списания данный подарок
                    for(var i=0; i<child_gifts.length; i++){
                        obj_g_t_b = child_gifts[i];
                        b2c_gift.add_gift(obj_g_t_b.g, obj_g_t_b.t, obj_g_t_b.b);
                    }
                }
                // Уничтожаем закешированный массив, т.к. в случае поступления следующего подарка списания для данного gift, он будет добавлен сразу в gift
                delete this.b2c_parent_ids_to_writeof_gifts[gift.id];
                // Сохраняем добавленный подарок
                this.b2c_gifts[gift.id] = b2c_gift;

                if (!is_new && b2c_gift.is_active()) {
                    // Если это не только что созданный подарок, а загруженный с сервера, то пересчитываем количество единиц у текущего агргацинного подарка
                    this.quantity += gift.quantity;
                    if(this.jq_quantity != null)
                        // Меняем значение в виджете
                        this.jq_quantity.text(this.quantity);
                }
            }
        } else {
            // Текущий подарок является начислением
            // gift - подарок-списание к текущему подарку
            b2c_gift = new B2CGift({
                gift : gift,
                type : this.type,
                template : template,
                b_user : b_user,
                show_compact : true,
                is_can_writeoff : this.options.is_can_writeoff
            });
            this.b2c_writeof_gifts[gift.id] = b2c_gift;
            if (this.jq_childs_list != null)
                // Если подвиджет списка дочерних подарков инициализирован, то добавляем сразу в него
                this._add_to_jq_childs_list(b2c_gift);
        }
    },
    /**
     * Добавляет подарок потом в виджет текущего подарка, в подвиджет списка подарков-потомков
     *
     * @param b2c_gift {B2CGift} - подарок потомок для текущего подарка
     * @private
     */
    _add_to_jq_childs_list : function(b2c_gift) {
        if (this.jq_childs_list == null)
            // Если подвиджет списка подарков-потомков еще не был создан, то создаем
            // Он же автоматом добавит b2c_gift в себя, взяв из this.b2c_gifts или this.b2c_writeof_gifts
            this._build_to_jq_childs_list();
        else
            // Подвиджет списка подарков уже существует - просто добавляем еще один подарок
            b2c_gift.build_jq_gift(this.jq_childs_list);
    },
    /**
     * СТроит подвидежт списка подарков-потомков
     * Сразу добавляе их из this.b2c_gifts, если текущий подарок агрегатный
     * или из this.b2c_writeof_gifts, если текущий подарок является подарком-начислением
     *
     * @private
     */
    _build_to_jq_childs_list : function() {
        this.jq_childs_list = $('<div class="childs_list hidden"></div>').appendTo(this.jq_gift);

        var childs_gift, b2c_gift;

        if (this.is_aggregated)
            // Это агрегационный подарок
            childs_gift = this.b2c_gifts;
        else
            // Это обычный подарок - значит у него потомки подарки-списания
            childs_gift = this.b2c_writeof_gifts;

        for(var id in childs_gift) {
            b2c_gift = childs_gift[id];
            b2c_gift.build_jq_gift(this.jq_childs_list);
        }
    },
    /**
     * Отображает под-виджет со списком подарков-потомков
     * Если подвиджет еще не был инициаллизирован, то инициализирует
     */
    show_to_jq_childs_list : function() {
        if (this.jq_childs_list == null)
            this._build_to_jq_childs_list();
        this.jq_childs_list.removeClass('hidden');
    },
    /**
     * Скрывает под-виджет со списком подарков-потомков
     * Если подвиджет еще не был инициаллизирован, то инициализирует
     */
    hide_to_jq_childs_list : function() {
        if (this.jq_childs_list == null)
            this._build_to_jq_childs_list();
        this.jq_childs_list.addClass('hidden');
    },


    /**
     * Возвращает дату, до которой валиден текущий подарок
     * @returns {null | Date} - null - в случае, если лимита нет, инача JS Date
     */
    get_valid_end_js_date : function() {
        var valid_end_js_date = null; // Дата окончания действия подарка, если подарок будет сгенерирован в текущий момент

        if(this.is_write_off || this.type == B2CGiftTemplate.const.type.coins
            || (this.template.period == null && this.template.total_period == null))
            // Если текущий подарок является подарком-списания, или монетой, или в его шаблоне не заданы лимиты, то такой подарок не имеет лимитов
            return null;

        if(this.is_aggregated) {
            // Текущий подарок агрегационный
            // Для него датой действия является наибольшая дата действия его потомков
            var curr_gift_valid_end_js_date; // Дата действия текущего потомка

            for(var gift_id in this.b2c_gifts) {
                curr_gift_valid_end_js_date = this.b2c_gifts[gift_id].get_valid_end_js_date();
                if (curr_gift_valid_end_js_date != null
                && (valid_end_js_date == null || curr_gift_valid_end_js_date > valid_end_js_date)) 
                        valid_end_js_date = curr_gift_valid_end_js_date;                
            }
        } else {
            // Текущий подарок является начислением. Значит берем значения из expire
            if (this.gift.expire) {
                var expireDate = B2Cjs.datetimeServerToJS(this.gift.expire);

                if (expireDate) {
                    var expireDateLocal = new Date(expireDate);

                    expireDateLocal.setFullYear(expireDate.getUTCFullYear(), expireDate.getUTCMonth(), expireDate.getUTCDate());
                    expireDateLocal.setHours(23, 59, 59, 999);
                    valid_end_js_date = expireDateLocal;
                }
            }
        }
        return valid_end_js_date;
    },
    /**
     * Возвращает true, если подарок еще активный
     * дата действия - не заврешилась или не лимитирована
     * количество единиц - больше 0 (релевантно для quantitive)
     * @returns {boolean}
     */
    is_active : function() {
        var valid_end_js_date = this.get_valid_end_js_date();
        if (valid_end_js_date != null && valid_end_js_date < new Date) {
            return false;
        }
        if (this.type == B2CGiftTemplate.const.type.quantitive && this.quantity == 0)
            return false;
        return true;
    },
    /**
     * Возвращает дату создания подарка в JS формате
     * @returns {Date}
     */
    get_created_at_js_date : function() {
        if(this.created_at_js == null)
            this.created_at_js = B2Cjs.datetimeServerToJS(this.gift.created_at);
        return this.created_at_js;
    },

    /**
     * Создает на основе текущего гифта Объект гифт-списание в кличестве @amount единиц
     * @param amount {Integer}
     */
    get_writeoff_object : function(amount) {
        if (amount > 0)
            amount = -amount;

        var parent_gift, template_id;
        if (this.type == B2CGiftTemplate.const.type.quantitive) {
            parent_gift = this.gift.id;
            template_id = this.template.id;
        }

        return {
            brand_id : this.brand_id ? this.brand_id : this.gift.brand_id,
            parent_gift : parent_gift,
            template_id : template_id,
            user_id : this.user_id ? this.user_id : this.gift.user_id,
            quantity : amount
        };
    },

    /**
     * Возвращает массив активных не нулевых подарков-потовмков, отсортированный по дате создания, начиная с более старых
     * @returns {Array of B2CGift}
     */
    _get_active_sorted_gifts : function() {
        var b2c_gifts_arr = [], b2c_gift;
        for(var id in this.b2c_gifts) {
            b2c_gift = this.b2c_gifts[id];
            if (b2c_gift.is_active())
                b2c_gifts_arr.push(b2c_gift);
        }
        B2CGift.sort_by_created_at(b2c_gifts_arr);
        return b2c_gifts_arr;
    },
    /**
     * Создает списание с агрегационного подарка в размере @val
     *
     * Проходит по подаркам-потомкам в порядке от более старых к более новым, создавая к ним подарки-списания, которые в сумме дадут @val.
     *
     * Отправляет подарки-списания на сервер
     *
     * @param val {Integer} - количество единиц для списания
     * @private
     */
    _create_writeoff : function(val) {
        var that = this; // для создания замыканий

        var b2c_writeoff_gifts;

        if (this.type == B2CGiftTemplate.const.type.quantitive)
            b2c_writeoff_gifts = this._create_writeoff_quantitive_gift_array(val);
        else if (this.type == B2CGiftTemplate.const.type.coins)
            b2c_writeoff_gifts = this._create_writeoff_coins_gift_array(val);

        if(this.closure_on_server_create_writeoff_gifts_error == null) {
            //this.closure_on_server_create_writeoff_gifts_ok = function(data) { that._on_server_create_writeoff_gifts_ok(data) };
            this.closure_on_server_create_writeoff_gifts_error = function(jqXHR, textStatus, errorThrown) { that._on_server_create_writeoff_gifts_error(jqXHR, textStatus, errorThrown) };
        }

        // Отправляем подарки-списания на сервер для создания
        B2CGift.server_create(b2c_writeoff_gifts,
            function(data) { that._on_server_create_writeoff_gifts_ok(data, b2c_writeoff_gifts) },
            this.closure_on_server_create_writeoff_gifts_error);
    },

    /**
     * Создает массив объектов списания для подарков типа B2CGiftTemplate.const.type.quantitive
     *
     * @param val
     * @private
     */
    _create_writeoff_quantitive_gift_array : function(val) {
        // Получаем массив подарков потомков, отсортированный по дате создания, начиная с более старых
        var b2c_gifts = this._get_active_sorted_gifts();
        var rest = val,// Количество оставшихся единиц, которые надо списать в рамках данной операции
            b2c_gift, // текущий подарок-потомок
            b2c_writeoff_gifts = [], // Массив создаваемых подарков-списаний
            curr_writeoff_amount; // Количество единиц списания с b2c_gift

        for(var i=0; i<b2c_gifts.length && rest>0; i++) {
            // Проходим до тех пор, пока rest не станет нулевым

            b2c_gift = b2c_gifts[i];
            if (rest <= b2c_gift.quantity)
            // Если у текущего подарка единиц не меньше, чем осталось списать, значит списываем в объеме rest
                curr_writeoff_amount = rest;
            else
            // Иначе списываем все единицы
                curr_writeoff_amount = b2c_gift.quantity;
            b2c_writeoff_gifts.push(b2c_gift.get_writeoff_object(curr_writeoff_amount));
            rest -= curr_writeoff_amount;
        }
        return b2c_writeoff_gifts;
    },

    /**
     * Создает массив объектов списания для подарков типа B2CGiftTemplate.const.type.coins
     *
     * @param val
     * @private
     */
    _create_writeoff_coins_gift_array : function(val) {
        var b2c_writeoff_gifts = []; // Массив создаваемых подарков-списаний

        b2c_writeoff_gifts.push(this.get_writeoff_object(val));
        return b2c_writeoff_gifts;
    },

    /**
     * Обработчик события клика по кнопке "свернуть / развернуть" список подарков потомков
     * @private
     */
    _on_btn_childs_click : function() {
        this.jq_btn_childs.toggleClass('collapsed');
        if (this.jq_childs_list == null)
            this._build_to_jq_childs_list();
        this.jq_childs_list.toggleClass('hidden');
    },
    /**
     * Обработчик события клика по кнопке "списать" указанное количество единиц подарка
     * инициирует списание на сервере
     * @private
     */
    _on_btn_writeoff_click : function() {
        var val = Number(this.jq_input_amount.val());
        if (val == 0)
            return;
        this._create_writeoff(-val);
    },
    /**
     * Обработчик события клика по кнопке добавления к отрицательному числу единиц списания
     * @private
     */
    _on_btn_plus_click : function() {
        var val = Number(this.jq_input_amount.val());
        if (val < 0)
            this.jq_input_amount.val(++val);
    },
    /**
     * Обработчик события клика по кнопке уменьшения отрицательного числа единиц списания
     * @private
     */
    _on_btn_minus_click : function() {
        var val = Number(this.jq_input_amount.val());
        if (this.quantity + val > 0)
            this.jq_input_amount.val(--val);
    },
    /**
     * Обрабоатывае изменение поля - количества отрицательных единиц под списание
     * Корректирует введенное значение, если единиц в подарке не достаточно
     * @private
     */
    _on_input_amount_change : function() {
        var val = Number(this.jq_input_amount.val());
        if (val > 0) {
            val = -val;
            this.jq_input_amount.val(val);
        }
        if (this.quantity + val < 0)
            this.jq_input_amount.val(this.quantity);
    },
    /**
     * Обрабатывает событие успешного создания на сервере подарков-списания
     *
     * Подарки-списания добавляет в подвиджет списка подарков потомков
     * Сбрасывает поле ввода единиц для списания
     *
     *
     * @param data (Object) data.ids = Array of int - массив идентификаторов подарков-списаний в порядке, в котором были переданы на сервер подарки-списания для создания
     * @param gifts_without_ids - Array of Gift - массив подарков-списаний, который был отправлен на сервер (соответственно, в нем отсутствуют идентификаторы с сервера)
     * @private
     */
    _on_server_create_writeoff_gifts_ok : function(data, gifts_without_ids) {
        var created_at = B2Cjs.datetimeJSToServer(new Date); // Дата создания - текущая дата
        var b_user = LoginedUserHandler.getLoginedUser(); // Бизне-пользователь, который создал подарки-списания (текущий пользоатель)
        var gift;
        for(var i=0; i<gifts_without_ids.length; i++) {
            gift = gifts_without_ids[i];
            gift.id = data.ids[i];
            gift.created_at = created_at;
            gift.created_by = b_user.id;

            this.add_gift(gift, null, b_user, true);
        }
        this.jq_input_amount.val(0);
    },
    /**
     * Обрабатывает событие ошибки на сервере сохранения  подарков-списаний
     * выводит сообщение об ошибке
     * @param jqXHR
     * @param textStatus
     * @param errorThrown
     */
    _on_server_create_writeoff_gifts_error : function(jqXHR, textStatus, errorThrown) {
        var message = 'Some error occurred';
        if (jqXHR.responseJSON != null)
            message += ': ' + jqXHR.responseJSON.message;
        this._info_popup_show(message);
    }
};

/**
 * Создает объект Gift в сервером формате, но не отправляет на сервер
 *
 * @param template - (Object in Server format) шаблон подарок, на котором необходимо сгенерировать подарок (используется, если gift не указан)
 * @param user_id - (integer) идентификатор пользователя, которому дарим подарк (нужен в случае отсутствия options.user)
 * @param options
 * @param options.cl_task_id - (integer) Идентификатор таска клиента, за который бизнес дарит подарок
 * @param options.cl_comment_id - (integer) Идентификатор коммента клиента, за который бизнес дарит подарок
 * @param options.comment_id - (integer) Идентификатор коммента бизнеса, к которому прикрепляется подарок
 * @returns {{user_id: *, brand_id: *, template_id: *, expire: *, quantity: *, created_by: *}}
 */
B2CGift.generate_gift = function(template, user_id, options) {
    var def_options = {};
    options = $.extend(false, def_options, options);

    var expire = B2CGiftTemplate.get_valid_end_js_date(template);
    if (expire != null)
        expire = B2Cjs.datetimeJSToServer(new Date(Date.UTC(expire.getFullYear(), expire.getMonth(), expire.getDate())), true);

    return {
        user_id : user_id,
        brand_id : template.brand_id,
        template_id : template.id,
        expire : expire,
        cl_task_id : options.cl_task_id,
        cl_comment_id : options.cl_comment_id,
        comment_id : options.comment_id,
        quantity : template.quantity,
        created_by : LoginedUserHandler.getLoginedUser().id,
    }
};

/**
 * Создает массив подарков начислений или списаний на сервера
 * @param gifts - array of Gift
 * @param onSuccess {function(data)} - функция обратного вызова с успешным ответом от сервера
 * @param onError {function(jqXHR, textStatus, errorThrown)} - функция обратного вызова с ошибкой от сервера
 */
B2CGift.server_create = function(gifts, onSuccess, onError) {
    var userDate = new Date().toISOStringTZ();
    gifts.forEach(function (v) {
        v.user_datetime = userDate;
    });

    Server.callServer({
        url: pref.host+pref.serv_gift.create,
        type: "POST",
        data: {
            gifts : gifts
        },
        success : onSuccess,
        error : onError
    });
};

/**
 * Ищет подарки на сервере в соответствии с фильтром
 * @param filter - {Object}  см. API.docx
 * @param onSuccess {function(data)} - функция обратного вызова с успешным ответом от сервера
 * @param onError {function(jqXHR, textStatus, errorThrown)} - функция обратного вызова с ошибкой от сервера
 */
B2CGift.server_search = function(filter, onSuccess, onError) {
    Server.callServer({
        url: pref.host+pref.serv_gift.search,
        type: "POST",
        data: filter,
        success : onSuccess,
        error : onError
    });
};

/**
 * Запрашивает у сервера список брендов, в которых у пользователя есть подарки
 * @param only_active - {Integer}  см. API.docx или B2CGiftTemplate.const.active
 * @param onSuccess {function(data)} - функция обратного вызова с успешным ответом от сервера
 * @param onError {function(jqXHR, textStatus, errorThrown)} - функция обратного вызова с ошибкой от сервера
 */
B2CGift.server_get_brands = function(only_active, onSuccess, onError) {

    //var respData = {
    //    brands : [
    //        {"id":1,"name":"Му-Му","logo":"http://b2cm.2mx.org/images/catalog/000/place/logo/640/loocxv.png","logoThumb":"http://b2cm.2mx.org/images/catalog/000/place/logo/320/loocxv.png"},
    //        {"id":23,"name":"Anticafe Krugozor","logo":"http://b2cm.2mx.org/images/catalog/000/place/logo/640/hqf20l.png","logoThumb":"http://b2cm.2mx.org/images/catalog/000/place/logo/320/hqf20l.png"}
    //    ]
    //};

    //onSuccess(respData);
    //return;

    Server.callServer({
        url: pref.host+pref.serv_gift.brands,
        type: "POST",
        data: {
            only_active : only_active
        },
        success : onSuccess,
        error : onError
    });
};

/**
 * Сортирует массив подарков по дате создания от старых к новым
 * @param gifts_arr {Array of B2CGift}
 */
B2CGift.sort_by_created_at = function(gifts_arr) {
    gifts_arr.sort(B2CGift.compare_by_created_at);
};

/**
 * Сравнивает подарки по дате создания
 * @param b2c_gift_1 {B2CGift}
 * @param b2c_gift_2 {B2CGift}
 */
B2CGift.compare_by_created_at = function(b2c_gift_1, b2c_gift_2) {
    if(b2c_gift_1.get_created_at_js_date() <= b2c_gift_2.get_created_at_js_date())
        return -1;
    else
        return 1;
};

B2CGift.const = {};

window.B2CGift = B2CGift; // TODO: remove globals, use module exports
// export default B2CGift;