import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactPage from 'pages/ReactPage';
import withStore from 'components/withStore';
import { connect } from 'react-redux';

import './MenuOrders.scss';
import MenuOrders from 'components/menu/MenuOrder/MenuOrders';
import Page, { PageContent, PageHeader } from 'components/page/Page';
import { BackButton, MenuButton } from 'components/UI/Button/Button';
import HeaderWithTabs from 'components/UI/Header/HeaderWithTabs';
import usePagination from 'hooks/usePagination';
import { showError } from 'windows/Modal/Info';
import { MenuListEmptyItem } from 'components/menu/MenuList/MenuList';
import { withScrollContainer } from 'scrollmonitor-hooks';
import * as MenuAPI from 'utils/api/menu';
import Spinner from 'components/UI/Spinner/Spinner';
import MenuOrderWindow from 'components/window/MenuOrder/MenuOrder';
import ConfirmModal from 'windows/Modal/Confirm';
import { OrderStatusMap } from 'components/menu/MenuOrderStatus/MenuOrderStatus';
import { onEditOrder } from 'store/menu/orders/actions';
import useEventListener from 'hooks/useEventListener';

const Tabs = [
    { id: 'in-progress', title: 'IN PROGRESS' },
    { id: 'completed', title: 'COMPLETED' },
    { id: 'canceled', title: 'CANCELED' },
];

function getStatusValuesByTabId(id) {
    switch (id) {
        case Tabs[0].id:
            return [1, 2];
        case Tabs[1].id:
            return [3];
        case Tabs[2].id:
            return [-1];
        default:
            return null;
    }
}

const DefaultTab = Tabs[0].id;

const MenuOrdersPageContent = withScrollContainer(
    React.forwardRef((props, ref) => <PageContent {...props} forwardedRef={ref} />)
);

const initialOrderParams = {
    page: 0,
    pageSize: 20,
    includePlace: true,
    includeWaiterRequests: true,
    sort: '-created_at',
    status: getStatusValuesByTabId(DefaultTab),
};

const MenuUserOrdersContent = (props) => {
    const { title = 'My orders', isActive } = props;

    const [orders, setOrders] = useState([]);
    const [activeTab, setActiveTab] = useState(DefaultTab);
    const [loading, setLoading] = useState(true);
    const [orderParams, setOrderParams] = useState(initialOrderParams);

    const [pagination, hasMore, setPagination] = usePagination();

    const loadOrdersList = useCallback(async () => {
        setLoading(true);

        try {
            const { orders: ordersList, pagination: newPagination } = await MenuAPI.loadUserOrders({
                ...orderParams,
            });

            setPagination(newPagination);
            setOrders((prevOrders) => [...prevOrders, ...ordersList]);
        } catch (e) {
            setOrders([]);
            showError(e);
        } finally {
            setLoading(false);
        }
    }, [orderParams]);

    const loadMoreOrders = useCallback(
        (shouldLoadMore) => {
            if (!loading && shouldLoadMore && hasMore) {
                setOrderParams((params) => ({ ...params, page: pagination.page + 1 }));
            }
        },
        [loading, pagination, hasMore, setOrderParams, loadOrdersList]
    );

    useEffect(() => {
        if (isActive) {
            loadOrdersList();
        }
    }, [orderParams.status, orderParams.page, isActive]);

    useEffect(() => {
        setOrders([]);
        setOrderParams((prev) => {
            const orderParams = {
                ..._.omit(prev, 'page'),
            };

            if (!_.isEqual(prev.status, getStatusValuesByTabId(activeTab))) {
                orderParams.status = getStatusValuesByTabId(activeTab);
            }

            return orderParams;
        });
    }, [activeTab]);

    const menuOrdersEmptyContent = useMemo(() => {
        return !loading && <MenuListEmptyItem message="No orders was found" />;
    }, [loading]);

    const onChangeOrder = useCallback(
        (order) => {
            setOrders((prev) => {
                const orders = prev
                    .map((o) => {
                        if (o.id === order.id) {
                            const newOrder = { ...o, ...order };
                            const isCorrespondsToStatus =
                                !orderParams.status ||
                                orderParams.status.findIndex((s) => s === newOrder.status) !== -1;
                            return isCorrespondsToStatus ? newOrder : null;
                        }

                        return o;
                    })
                    .filter(Boolean);
                return orders;
            });
        },
        [orderParams]
    );

    useEventListener(props.__page__, 'change:order', onChangeOrder);

    const changeOrderStatusAsync = useCallback(
        async ({ placeId, status, id, includeUser, includePlace, items }) => {
            setLoading(true);
            try {
                const changedOrder = await MenuAPI.changeOrder({
                    placeId,
                    id,
                    data: {
                        status,
                    },
                    includeUser,
                    includePlace,
                    items,
                });

                onChangeOrder(changedOrder);
            } catch (e) {
                showError(e instanceof Error ? e.message : e);
            } finally {
                setLoading(false);
            }
        },
        [setLoading, showError, onChangeOrder]
    );

    const onEditOrder = useCallback(
        (order) => {
            props.onEditOrder(order);
        },
        [props.onEditOrder]
    );

    const onCancelOrder = useCallback(
        async (order) => {
            const window = new ConfirmModal({ message: 'Do you really want to cancel order?' });
            const result = await window.show();

            if (!result) {
                return;
            }

            changeOrderStatusAsync({
                id: order.id,
                placeId: order.place_id || order.placeId,
                status: OrderStatusMap.Canceled,
            });
        },
        [changeOrderStatusAsync]
    );

    const onSelectItem = (order, propsOptional) => {
        new MenuOrderWindow({ order, onChangeOrder, ...propsOptional }).show();
    };

    const onShow = useCallback(() => {
        setOrders([]);
        setOrderParams({
            ...initialOrderParams,
            status: getStatusValuesByTabId(activeTab),
        });
    }, [activeTab]);

    return (
        <Page instance={props.__page__} onShow={onShow}>
            <PageHeader>
                <HeaderWithTabs tabs={Tabs} activeTab={activeTab} setActiveTab={setActiveTab} resetActive>
                    <BackButton onClick={props.goBack} />
                    <h1 className="title">{title}</h1>
                    <MenuButton right />
                </HeaderWithTabs>
            </PageHeader>
            <MenuOrdersPageContent className="scrollable grow">
                {Boolean(isActive) && (
                    <MenuOrders
                        orders={orders}
                        onEditOrder={onEditOrder}
                        onCancelOrder={onCancelOrder}
                        emptyContent={menuOrdersEmptyContent}
                        onLastItemInViewportChange={loadMoreOrders}
                        onSelectItem={onSelectItem}
                    />
                )}
                {Boolean(!isActive || loading) && <Spinner />}
            </MenuOrdersPageContent>
        </Page>
    );
};

const ConnectedMenuOrdersContent = withStore(connect(null, { onEditOrder })(MenuUserOrdersContent));

const MenuOrdersPage = ReactPage.extend({
    immediateRenderOnShow: true,
    attributes: {
        id: 'page_menu_orders',
    },
    className: 'menu-orders-page menu-orders-base-page',
    component: ConnectedMenuOrdersContent,
    changeOrder(order) {
        this.trigger('change:order', order);
    },
});

export default MenuOrdersPage;
