import { Optional, Model, Collection, ItemView, ViewModel } from '@b2cmessenger/backbone';
import ImageInputHelpers from './ImageInputHelpers';
import template from './FileInput.jade';
import './FileInput.scss';
import PhotoModel from "../../widgets/MessageWithComments/MessageComments/CommentEditor/PhotoGrid/PhotoModel.js";

const Appearance = {
    Input: 0,
    Button: 1,
    ButtonInline: 2
};

/**
 * @fires change(collection) On input files changed and selected files are ready
 */
const FileInput = ItemView.extend({
    options: {
        accept: "image/*",
        multiple: false,
        appearance: Appearance.Input,
        maxProcessorCount: 300,
        collection: Optional,
    },

    reset() {
        if (this.ui.input && this.ui.input.length) {
            this.ui.input[0].value = null;
        }
        this.collection.reset();
    },

    tagName() {
        switch (this.options.appearance) {
            case Appearance.Input:
                return 'input';
            case Appearance.Button:
            default:
                return 'button';
        }
    },

    className() {
        switch (this.options.appearance) {
            case Appearance.Input:
                return 'widget file-input-widget needs-native-click';
            case Appearance.Button:
                return 'widget file-input-widget needs-native-click btn-big';
            case Appearance.ButtonInline:
            default:
                return 'widget file-input-widget needs-native-click';
        }
    },

    attributes() {
        switch (this.options.appearance) {
            case Appearance.Input:
                return {
                    type: 'file',
                    "data-role": 'none',
                    accept: this.options.accept || "image/*",
                    multiple: !!this.options.multiple
                };
            case Appearance.Button:
            default:
                return {
                    "data-role": 'none',
                };
        }
    },

    ui: {
        input: '[data-js-input]'
    },

    bindings: {
        ':el': 'disabled:disabled',
        '@ui.input': 'classes:{hidden:isAndroid},disabled:disabled'
    },

    events: {
        'click'() {
            if (this.viewModel.get('isAndroid')) {
                ImageInputHelpers.getImageSourceType()
                    .then(type => {
                        if (type === 'camera') {
                            ImageInputHelpers.getImageFromNavigator(type)
                                .then((fileUrl) => {
                                    if (fileUrl) {
                                        this.collection.add({
                                            status: PhotoModel.Status.ReadyToUpload,
                                            file: null,
                                            fileUrl: fileUrl
                                        });
                                    }
                                })
                                .catch(error => {
                                    if (error !== 'No Image Selected') {
                                        this.showError(error);
                                        if (window.Sentry) {
                                            Sentry.captureMessage(`resolveLocalFileSystemURL error: ${error}`);
                                        }
                                    }
                                });
                        } else if (type === 'gallery') {
                            this.ui.input.click();
                        }
                    });
            } else {
                this.ui.input.click();
            }
        },
        'click @ui.input'(e) {
            e.stopPropagation();
        },
        'change @ui.input'() {
            if (this.ui.input[0].files) {
                this.viewModel.set({ disabled: true });

                this._stopProcessingFiles();
                this._startProcessingFiles();
            }
        },
    },

    initialize(options) {
        if (!this.viewModel) {
            this.viewModel = new ViewModel({
                parentViewModel: this.options.parentViewModel,
                isAndroid: window.device && window.device.platform == "Android"
            });
        }

        this.collection = options.collection || new FileCollection;
        this.listenTo(this.collection, 'reset update', collection => this.trigger('change', collection));

        switch (this.options.appearance) {
            case Appearance.Input:
                this.template = false;
                break;
            case Appearance.Button:
            default:
                this.template = template;
        }
    },

    serializeData() {
        return {
            accept: this.options.accept || "image/*",
            multiple: !!this.options.multiple
        };
    },

    _stopProcessingFiles() {
        if (this._processor) {
            clearInterval(this._processor);
            this._processor = null;
            this._processorCount = 0;
        }
    },

    _startProcessingFiles() {
        if (this._checkFiles()) {
            this.collection.reset(
                _.chain(this.ui.input[0].files.length)
                    .times(i => ({
                        status: PhotoModel.Status.Ready,
                        file: this.ui.input[0].files.item(i)
                    }))
                    .filter(this.options.accept == 'image/*' ?
                        i => i.file.type.match(/^image\/(png|jpeg|jpg)$/) :
                        Boolean
                    )
                    .value()
            );
            this.viewModel.set({ disabled: false });
        } else {
            this.collection.reset();

            this._processorCount = 0;
            this._processor = setInterval(() => {
                this._processorCount++;
                if (!this.isDestroyed) {
                    if (this.ui.input && this.ui.input.length && this.ui.input[0].files && this._checkFiles()) {
                        this._stopProcessingFiles();

                        this.collection.reset(
                            _.chain(this.ui.input[0].files.length)
                                .times(i => ({
                                    status: PhotoModel.Status.Ready,
                                    file: this.ui.input[0].files.item(i)
                                }))
                                .filter(this.options.accept == 'image/*' ?
                                    i => i.file.type.match(/^image\/(png|jpeg|jpg)$/) :
                                    Boolean
                                )
                                .value()
                        );

                        this.viewModel.set({ disabled: false });
                    } else if (this._processorCount > this.options.maxProcessorCount) {
                        this._stopProcessingFiles();
                        this.viewModel.set({ disabled: false });
                        this.reset();
                    }
                } else {
                    this._stopProcessingFiles();
                }
            }, 100);
        }
    },

    _checkFiles() {
        for (let i = 0; i < this.ui.input[0].files.length; ++i) {
            if (!this.ui.input[0].files.item(i).size) {
                return false;
            }
        }

        return true;
    }
}, { Appearance });

const Status = {
    Unknown: -1,
    Ready: 0
};

const FileModel = Model.extend({
    defaults: {
        status: Status.Unknown,
        file: null
    }
}, { Status });

const FileCollection = Collection.extend({
    model: FileModel
});

export default FileInput;
