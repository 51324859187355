import React from 'react';
import MenuList, { MenuListType, byIdAndSortOrder, MenuListItem } from 'components/menu/MenuList/MenuList';
import { MenuVisibilityToggle } from 'components/menu/MenuVisibility/MenuVisibility';
import { MenuItemBase } from 'components/menu/MenuItem/MenuItem';
import './MenuItems.scss';
import { getAdditionItemPrice, getItemPrice } from 'store/menu/utils';

const MenuEditableItems = (props) => {
    const {
        items,
        onSelectItem = _.noop,
        onEditItem = _.noop,
        onRemoveItem = _.noop,
        onToggleVisibility = _.noop,
    } = props;

    const itemList = items.map((e) => {
        const i = {
            ...e,
            price: props.additions ? getAdditionItemPrice(e) : getItemPrice(e),
        };

        const leftContent = (
            <button className="ion-ios-close-outline remove" onClick={onRemoveItem.bind(null, i.id, i)} />
        );
        const rightContent = <button className="ion-md-create edit" onClick={onEditItem.bind(null, i.id, i)} />;

        return (
            <MenuListItem
                className="menu-list-item-with-controls menu-editable-list-item"
                key={byIdAndSortOrder(i)}
                left={leftContent}
                right={rightContent}
            >
                <MenuItemBase className="menu-list-item" onSelectItem={onSelectItem.bind(null, i.id, i)} {...i}>
                    <MenuVisibilityToggle
                        onClick={onToggleVisibility.bind(null, i)}
                        active={Boolean(i.is_hidden_forever || i.hidden_until)}
                    />
                </MenuItemBase>
            </MenuListItem>
        );
    });

    return (
        <MenuList {...props} type={MenuListType.Items}>
            {itemList}
        </MenuList>
    );
};

export default MenuEditableItems;
