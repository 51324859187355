import Header from 'widgets/Header/Header'
import template from './HeaderWithPlaceInfo.jade';
import './HeaderWithPlaceInfo.scss';
import { ViewModel } from '@b2cmessenger/backbone';
import backgroundImageHandler from 'bindings/handlers/backgroundImage';

const HeaderWithPlaceInfo = Header.extend({
    template: template,

    tagName: "div",
    className: "widget header header-with-place-info",

    ui: {
        title: '[data-js-title]',
        placeInfo: '[data-js-place-info]',
        placeLogo: '[data-js-place-logo]',
        placeName: '[data-js-place-name]',
        placeAddress: '[data-js-place-address]'
    },

    bindings: {
        '@ui.placeInfo': {
            css: {
                display: 'placeInfoDisplay'
            }
        },
        '@ui.placeLogo': 'backgroundImage:placeLogo',
        '@ui.placeName': 'text:placeName',
        '@ui.placeAddress': 'text:placeAddress'
    },

    bindingHandlers: {
        backgroundImage: backgroundImageHandler,
    },

    initialize(options) {
        Header.prototype.initialize.apply(this, [options]);

        this.viewModel.set({
            parentViewModel: this.options.parentViewModel
        });

        this.viewModel.addComputed('placeInfoDisplay', {
            deps: ['place'],
            get: place => place ? null : 'none'
        });

        this.viewModel.addComputed('placeLogo', {
            deps: ['place'],
            get: place => place && place.logoThumb
        });

        this.viewModel.addComputed('placeName', {
            deps: ['place'],
            get: place => place && place.name
        });

        this.viewModel.addComputed('placeAddress', {
            deps: ['place'],
            get: place => place &&
                B2Cjs.getFormattedAddress(place.adr_street_name, place.adr_street_number, place.adr_city)
        });
    },

    onRender() {
        Header.prototype.onRender.apply(this);
    },
});

export default HeaderWithPlaceInfo;