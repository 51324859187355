/**
 * Created by Alex on 15.05.2016.
 */
B2CEmployee = {

};

B2CEmployee.server_api_search = function(place_id, need_roles, status, onSuccess, onError) {
    var data = {
        place_id : place_id,
        need_roles : need_roles,
    };

    if (status != null)
        data.status = status;

    Server.callServer({
        url: pref.host+pref.serv_place.employee.search,
        type: "POST",
        data: data,
        success : onSuccess,
        error : onError
    });
};

B2CEmployee.server_api_add = function(place_id, user_id, email, roles, onSuccess, onError) {
    var data = {
        place_id : place_id,
        roles : roles,
    };

    if (user_id != null)
        data.user_id = user_id;
    if (email != null)
        data.email = email;

    Server.callServer({
        url: pref.host+pref.serv_place.employee.add,
        type: "POST",
        data: data,
        success : onSuccess,
        error : onError
    });
};

B2CEmployee.server_api_delete = function(place_id, user_id, onSuccess, onError) {

    /*onSuccess();
    return;*/

    Server.callServer({
        url: pref.host+pref.serv_place.employee.delete,
        type: "POST",
        data: {
            place_id : place_id,
            user_id : user_id
        },
        success : onSuccess,
        error : onError
    });
};

B2CEmployee.server_api_changeroles = function(place_id, user_id, roles, onSuccess, onError) {

    /*onSuccess();
    return;*/

    Server.callServer({
        url: pref.host+pref.serv_place.employee.changeroles,
        type: "POST",
        data: {
            place_id : place_id,
            user_id : user_id,
            roles : roles,
        },
        success : onSuccess,
        error : onError
    });
};

B2CEmployee.server_api_accept = function(place_id, status, onSuccess, onError) {

    /*onSuccess();
    return;*/

    Server.callServer({
        url: pref.host+pref.serv_place.employee.accept,
        type: "POST",
        data: {
            place_id : place_id,
            status : status
        },
        success : onSuccess,
        error : onError
    });
};

B2CEmployee.const = {};

B2CEmployee.const.status = {}; // статусы работников
B2CEmployee.const.status.declined = -1; // пользователь отклонил приглашение присоединиться к плейсу в качестве сотрудника
B2CEmployee.const.status.pending = 0; // ожидание решения от пользователя
B2CEmployee.const.status.accepted = 1; // пользователь принял приглашение присоединиться к плейсу в качестве сотрудника

// Констатны соответствия бизнес-ролей пользователя
B2CEmployee.const.roles = {};
B2CEmployee.const.roles.MESSAGE_MODERATOR = 1; // Может редактировать и удалять сообщения других сотрудников плейса
B2CEmployee.const.roles.MESSAGE_CREATOR = 2; // Может создавать, редактировать и удалять сообщения в рамках Place, в котором он является сотрудником
B2CEmployee.const.roles.PLACE_MANAGER = 3; // Может редактировать профиль компании, менять лого, добавлять фотографии в галлерею
B2CEmployee.const.roles.EMPLOYEE_MANAGER = 4; // Может добавлять и удалять сотрудников компании, назначать им роли
B2CEmployee.const.roles.EVIDENCE_MANAGER = 5; // Может просматривать, принимать или отклонять пользовательские доказательства, что пользоватлся услогами компании (Чекины, Бизнес чекинны, Карты лояльности, Фото-доказательсва)
B2CEmployee.const.roles.GIFT_TEMPLATE_MANAGER = 6; // Может создавать, редактировать и удалять шаблоны подарков в рамках бренда, которому принадлежит Place в котром он работает
B2CEmployee.const.roles.GIFT_CREATOR = 7; // Может дарить пользователю подарки, а тажке списаовать их

window.B2CEmployee = B2CEmployee; // TODO: remove globals, use module exports
// export default B2CEmployee;
