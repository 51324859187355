import PlatformFeature from './PlatformFeature';
import { userDeviceInfo } from 'utils/UserDeviceInfo';
import settings from 'settings';
import GoogleSignInProxy from './GoogleSignInProxy';
import GoogleAnalytics from "utils/GoogleAnalytics";

class GoogleSignIn extends PlatformFeature {
    constructor(options) {
        super(options);

        if (!this.options.loginOptions) {
            this.options.loginOptions = {
                'scope': 'profile email openid',
                'webClientId': '1072401535158-e6gl4id2610s5mn32g2mhm3skd0lhj1u.apps.googleusercontent.com',
                'offline': true,
                fetch_basic_profile: true
            };
        }
    }

    async initialize() {
///////////////////////
//////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////

/////////////////
        const gapi = new GoogleSignInProxy(this.options.loginOptions);
        this.initializePromise = gapi.initialize().then(() => gapi);

//////////////////

        return this.initializePromise.then((apiSource) => {
            this.ready = true;
            this.GApiSource = apiSource;
        });
    }

    async login() {
        return this.ensureInitialized()
            .then(() => this.isCordova() ?
                new Promise((resolve, reject) => {
                    this.GApiSource.login(this.options.loginOptions, resolve, reject);
                }) :
                this.GApiSource.login()
            );
    }

    async logout() {
        return this.ensureInitialized()
            .then(() => this.isCordova() ?
                new Promise((resolve, reject) => {
                    this.GApiSource.logout(resolve, e => {
                        // handle `Please use login or trySilentLogin before logging out` error
                        if (_.isString(e) && e.match(/use login/g)) {
                            resolve();
                            return;
                        }

                        reject(e);
                    });
                }) :
                this.GApiSource.logout()
            );
    }

    async performServerLogin(loginResult) {
        return new Promise((resolve, reject) => {
            const data = this.getUserInfoFromGoogle(loginResult);
///////////////////////////
            _.extend(data, { redirectUri: document.location.origin });
//////////////////////

            if (data == 'No serverAuthCode') {
                reject(data);
            } else {
                Server.callServer({
                    url: settings.host + settings.serv_user.google_login,
                    dataType: 'json',
                    method: 'POST',
                    data: data,
                    success: resolve,
                    error: reject
                });
            }
        })
    }

    async authorize() {
        try {
            await this.ensureInitialized();
            await this.logout();
            const loginResult = await this.login();
            return this.performServerLogin(loginResult);

            // return serverLoginResult;
        } catch (e) {
            const error = this.wrapGoogleSignInError(e);
            return Promise.reject(error);
        }
    }

    getUserInfoFromGoogle(obj) {
        console.log('GoogleAuth.getUserInfoFromGoogle', obj);

        if (!obj.serverAuthCode) {
            return 'No serverAuthCode';
        }

        let firstname, lastname;
        if (obj.givenName) {
            firstname = obj.givenName;
            lastname = obj.familyName ? obj.familyName : obj.displayName.replace(firstname, '').trim();
        } else if (obj.displayName) {
            [firstname, lastname] = obj.displayName.split(' ');
        }

        const b2cGoogle = {
            user_id : obj.userId,
            serverAuthCode : obj.serverAuthCode,
            firstname : firstname,
            lastname : lastname,
            avatar : obj.imageUrl,
            email : obj.email,
            token : obj.idToken,
            info : userDeviceInfo.getInfo()
        };

        return b2cGoogle;
    }

    onGoogleLoginFail(msg) {
        const error = JSON.stringify(msg.error || msg);
        if (error === "popup_closed_by_user") {
            return;
        }

        console.error('GoogleAuth.onGoogleLoginFail error: ' + error);

        if (GoogleAnalytics) {
            GoogleAnalytics.trackException('GoogleAuth.onGoogleLoginFail error: ' + error, false);
        }

        if (window.Sentry) {
            Sentry.captureMessage('GoogleAuth.onGoogleLoginFail error: ' + error);
        }
    }

    wrapGoogleSignInError(msg) {
        let reason = String(msg);
        if (msg) {
            if (msg.error) {
                if (msg.error == 'popup_closed_by_user') {
                    reason = 'The user canceled the sign-in flow.'
                } else {
                    reason = String(msg.error);
                }
            }
        }

        this.onGoogleLoginFail(msg);
        return reason;
    }
}

export default new GoogleSignIn();
