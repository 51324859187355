import React from 'react';

import { MenuItemBase } from '../MenuItem/MenuItem';
import { MenuHiddenVisibility } from 'components/menu/MenuVisibility/MenuVisibility';
import MenuList, { byIdAndSortOrder, MenuListItem, MenuListType } from 'components/menu/MenuList/MenuList';
import './MenuItems.scss';
import { getAdditionItemPrice, getItemPrice } from 'store/menu/utils';

const MenuItems = (props) => {
    const { items, onSelectItem = _.noop } = props;

    const itemList = items.map((e) => {
        const i = {
            ...e,
            price: props.additions ? getAdditionItemPrice(e) : getItemPrice(e),
        };

        return (
            <MenuListItem className="menu-list-item-wrap" key={byIdAndSortOrder(i)}>
                <MenuItemBase className="menu-list-item" onSelectItem={onSelectItem.bind(null, i.id)} {...i}>
                    {(i.is_hidden_forever || i.hidden_until) && <MenuHiddenVisibility />}
                </MenuItemBase>
            </MenuListItem>
        );
    });

    return (
        <MenuList {...props} type={MenuListType.Items}>
            {itemList}
        </MenuList>
    );
};

export default MenuItems;
