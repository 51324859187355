import JsBarcode from 'jsbarcode';

function bindingHandler($canvas, value) {
    if (value) {
        const
            params = _.create(null, {
                format: "CODE128B",
                width: 4,
            }),
            sval = String(value),
            size = sval.length;

        if (size <= 30) {
            if (size <= 10) {
                _.extend(params, {
                    height: 300,
                    fontSize: 40
                });
            } else if (size <= 20) {
                const height = 300 + (size - 10) * 22
                _.extend(params, {
                    height,
                    fontSize: 40 * height / 300
                });
            } else {
                const height = 300 + (size - 10) * 22
                _.extend(params, {
                    height,
                    fontSize: 30 * height / 300
                });
            }

            if (/\d+/i.test(sval)) {
                let formattedVal = sval[0];
                for (let i = 1; i < size; ++i) {
                    if (i % 4 == 0) {
                        formattedVal += ' ';
                    }
                    formattedVal += sval[i];
                }

                _.extend(params, {
                    text: formattedVal
                });
            }

            $canvas.removeClass('barcode-error');
        } else {
            _.extend(params, {
                height: 0,
                text: 'Code is too long',
                fontSize: 40,
            });

            $canvas.addClass('barcode-error');
        }

        JsBarcode($canvas[0], sval, params);
    } else {
        const canvas = $canvas[0],
            context = canvas.getContext('2d');

        context.save();
        context.setTransform(1, 0, 0, 1, 0, 0);
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.restore();

        $canvas.addClass('barcode-error');
    }
}

function buildBarcode(number) {
    const $canvas = $("<canvas></canvas>");
    bindingHandler($canvas, number);

    return $canvas; 
}

export { buildBarcode as default, bindingHandler }