export default function getXHRValidationErrors(jqXHR) {
    const fields = _.isArray(jqXHR.responseJSON) && jqXHR.responseJSON
        || (jqXHR.responseJSON.error && jqXHR.responseJSON.error.field) || [];

    return _(fields).reduce((fields, o) => {
        let field = o.field && String(o.field),
            msg = o.message && String(o.message);

        if (field && msg) {
            fields[field] = msg;
        }

        return fields;
    }, _.create(null));
}
