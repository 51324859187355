import InfoModal from './Info';
import FooterView from 'widgets/Footer/Footer';

import template from './Info.jade';
import './Info.scss';

export default InfoModal.extend({
    windowName: "confirm-modal-window",
    className: "modal-window info-modal-window confirm-modal-window",

    options: {
        buttons: [
            {
                id: "close",
                text: "NO",
                icn: "empty",
                value: false
            },
            {
                id: "yes",
                text: "YES",
                icn: "empty",
                value: true
            }
        ],
        additionalClassName: "red-border"
    },

    initialize() {
        if (this.options.message) {
            _.extend(this.options, {
                title: this.options.message
            });
        }

        return InfoModal.prototype.initialize.apply(this, arguments);
    },

    cancel() {
        this.close(false);
    }
});