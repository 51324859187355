var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (encodeURI, logoThumb, name, place, thumb) {var _name = name || place && place.name || 'Unknown place';
var _thumb = false;
var _useDefaultThumb = false;

if (thumb === false) {
} else {
  _thumb = thumb || logoThumb || place && (place.thumb || place.logoThumb);

  if(!_thumb) {
    _useDefaultThumb = true;
  }
}


if ((_thumb || _useDefaultThumb)) {
if (_thumb) {
pug_html = pug_html + "\u003Cspan" + (" class=\"place-thumb\""+pug.attr("style", pug.style(("background-image:url(" + encodeURI(_thumb) + ")")), true, true)) + "\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"place-thumb default\"\u003E\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "&nbsp;";
}
pug_html = pug_html + "\u003Cspan class=\"place-name\"\u003E" + (pug.escape(null == (pug_interp = name || place && place.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";}.call(this,"encodeURI" in locals_for_with?locals_for_with.encodeURI:typeof encodeURI!=="undefined"?encodeURI:undefined,"logoThumb" in locals_for_with?locals_for_with.logoThumb:typeof logoThumb!=="undefined"?logoThumb:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined,"place" in locals_for_with?locals_for_with.place:typeof place!=="undefined"?place:undefined,"thumb" in locals_for_with?locals_for_with.thumb:typeof thumb!=="undefined"?thumb:undefined));;return pug_html;};
module.exports = template;