import template from './dateTime.jade';
import './dateTime.scss';

const bindingHandler = {
    init($el) {
        $el.addClass('widget-lite-date-time');
        $el.html(dateTime(!!this.isChangable));

        this._$date = $el.find('[data-js-date]');
        this._$time = $el.find('[data-js-time]');
    },

    set($el, dateTime) {
        if (!dateTime) {
            this._$date.text("Invalid Date");
            this._$time.toggle(false);
            return;
        }

        if (!(dateTime instanceof Date) || _.isNaN(dateTime.valueOf())) {
            _logError(`dateTime.bindingHandler${this.isChangable ? 'Interactive' : ''}.set()`,
                new Error('Invalid dateTime'));
            return;
        }

        this._$date.text(dateTime.toLocaleDateString('en-US', {
            weekday: 'short',
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        }));

        this._$time.toggle(true).text(dateTime.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        }));
    }
};

const bindingHandlerInteractive = _.create(bindingHandler, {
    init($el) {
        bindingHandler.init.apply(this, arguments);

        this._$input = $el.find('[data-js-input-date-time]');
        this._$dateTimeBlock = $el.find('.date-time-block');

        this._$input.on('focus', () => {
            this._$dateTimeBlock.css('opacity', 0);
        });

        this._$input.on('blur', () => {
            this._$dateTimeBlock.css('opacity', 1);
        });
    },

    get() {
        return B2Cjs.datetimeInputToJs(this._$input.val());
    },

    set($el, dateTime) {
        if (!dateTime || !(dateTime instanceof Date) || _.isNaN(dateTime.valueOf())) {
            this._$date.text("Enter date");
            this._$time.toggle(false);
            this._$input.val('');
            return;
        }

        this._$input.val(B2Cjs.datetimeJsToInput(dateTime));
        bindingHandler.set.apply(this, arguments);
    },

    isChangable: true
});

function dateTime(isInteractive = false) {
    return template({
        isInteractive
    });
};

function _logError(methodName, error) {
    console.warn(error);
    if (window.Sentry) {
        Sentry.captureMessage(`${methodName} error: ${JSON.stringify(error)}`);
    }
};

export { dateTime as default, bindingHandler, bindingHandlerInteractive };
