import { LayoutView, ViewModel, Model, Collection } from '@b2cmessenger/backbone';

import CategoryModel from 'models/CategoryModel';
import CategoryCollection from 'models/CategoryCollection';
import CategoryWidget from 'widgets/Category/Category';
import CategoriesWidget from 'widgets/Category/Categories';
import CategoriesGroupedByParentWidget from 'widgets/Category/CategoriesGroupedByParent';

import template from './CategoryChooser.jade';
import './CategoryChooser.scss';

const SelectionModel = Model.extend({
    computeds: {
        selected: {
            deps: ['_selected'],
            get: v => v,
            set(values) {
                let ids = values.length 
                    ? values[0].id 
                        ? values.pluck ? values.pluck('id') : _.pluck(values, 'id')
                        : values
                    : [];

                const categories = this.get('categories'),
                      _selected = this.get('_selected');

                if(categories && _selected) {
                    _selected.reset(_(ids).map(id => categories.get(id)));
                }

                return { };
            }
        },
        hasSelectedChildren: {
            deps: ['_hasSelectedChildren'],
            get: v => v,
        },
        allChildrenSelected: {
            deps: ['_allChildrenSelected'],
            get: v => v,
        },
        selectedOrHasSelectedChildren: {
            deps: ['_selectedOrHasSelectedChildren'],
            get: v => v,
        }
    },
    initialize() {
        const selected = new CategoryCollection,
              hasSelectedChildren = new CategoryCollection,
              allChildrenSelected = new CategoryCollection,
              selectedOrHasSelectedChildren = new CategoryCollection;

        function someChildrenSelected(cat, selected) {
            const children = cat.get('children');
            return !!(children && children.find(c => selected.get(c.id)));
        }

        function allOrNoneChildrenSelected(cat, selected) {
            const children = cat.get('children');
            return !children || !someChildrenSelected(cat, selected) || isAllChildrenSelected(cat, selected);
        }

        function isAllChildrenSelected(cat, selected) {
            const children = cat.get('children');
            if(children) {
                return !children.find(c => !selected.get(c.id) || !allOrNoneChildrenSelected(c, selected));
            } else {
                return true;
            }
        }

        function getParentsWithAllChildrenSelected(cat, selected) {
            const parents = cat.get('parents');
            if(parents) {
                return _(parents).filter(p => isAllChildrenSelected(p, selected));
            }
            return [];
        }

        function resetCollections(selected) {
            const parentsHash = {};
            selected.each(s => {
                const parent = s.get('parent');
                if(parent) {
                    parentsHash[parent.id] = parent;
                }
            });

            hasSelectedChildren.reset(_(parentsHash).reduce((arr, p) => {
                arr.push(p);
                const parents = p.get('parents');
                if(parents && parents.length) {
                    arr.push(...parents);
                }
                return arr;
            }, []));

            selectedOrHasSelectedChildren.reset([].concat(selected.models, hasSelectedChildren.models));

            allChildrenSelected.reset(_(parentsHash).reduce((arr, p) => {
                if(isAllChildrenSelected(p, selected)) {
                    arr.push(p);
                }
                arr.push(...getParentsWithAllChildrenSelected(p, selected));
                return arr;
            }, []));
        }

        selected.on('reset', resetCollections);

        selected.on('add', model => {
            const parents = model.get('parents');
            if(parents && parents.length) {
                hasSelectedChildren.add(parents);
                const parentsWithAllChildrenSelected = getParentsWithAllChildrenSelected(model, selected);
                allChildrenSelected.add(parentsWithAllChildrenSelected);
                allChildrenSelected.remove(_.filter(parents, p => !_.contains(parentsWithAllChildrenSelected, p)));
                selectedOrHasSelectedChildren.add(parents);
            }
            selectedOrHasSelectedChildren.add(model);
        });

        selected.on('remove', (model, selected, options) => {
            selectedOrHasSelectedChildren.remove(model);
            const children = model.get('children');
            if(children && children.length) {
                hasSelectedChildren.remove(model);
                selected.add(children.models, { silent: true });
                selected.remove(children.models, { dontCheckParents: true });

            } 
            if(!options.dontCheckParents){
                const parents = model.get('parents');
                if(parents && parents.length) {
                    _(parents).each(p => {
                        const children = p.get('children');
                        if(!children || !children.find(c => selectedOrHasSelectedChildren.get(c.id))) {
                            hasSelectedChildren.remove(p);
                            if(!selected.get(p.id)) {
                                selectedOrHasSelectedChildren.remove(p);
                            }
                        }

                        if (allOrNoneChildrenSelected(p, selected)) {
                            allChildrenSelected.add(p);
                        } else {
                            allChildrenSelected.remove(p);
                        }
                    });
                }
            }
            allChildrenSelected.remove(model);
        });

        resetCollections(selected);

        this.set({
            _selected: selected,
            _hasSelectedChildren: hasSelectedChildren,
            _allChildrenSelected: allChildrenSelected,
            _selectedOrHasSelectedChildren: selectedOrHasSelectedChildren
        });

        function onCategoriesChange(m, categories) {
            if(!categories) {
                selected.reset(selected.map(s => categories.get(s.id)));
            } else {
                selected.listenTo(categories, 'reset', () => selected.reset(selected.map(s => categories.get(s.id))));
                selected.listenTo(categories, 'remove', model => selected.remove(model));
            }
        }

        this.on('change:categories', onCategoriesChange);

        onCategoriesChange(this, this.get('categories'));
    }
});

const CategoryChooserModel = Model.extend({
    defaults: {
        _rawfilter: ''
    },
    computeds: {
        selection: {
            deps: ['_selection'],
            get: v => v
        },
        selected: {
            deps: ['_selection'],
            get: selection => selection && selection.get('selected'),
            set(values) {
                const selection = this.get('selection');
                if(selection) {
                    selection.set('selected', values);
                }
                return { };
            }
        },
        selectedOrHasSelectedChildren: {
            deps: ['_selection'],
            get: selection => selection && selection.get('selectedOrHasSelectedChildren')
        },
        filter: {
            deps: ['_rawfilter'],
            get: _filter => String(_filter || "").replace(/\W/g, '').toLowerCase(),
            set: val => _.create(null, { _rawfilter: val })
        },
    },

    _initializeLevel0Collection(categories) {
        const level0 = new CategoryCollection(categories.filter(c => c.get('depth') == 1));
        this.listenTo(categories, 'reset', () => level0.reset(categories.filter(c => c.get('depth') == 1)));
        this.listenTo(categories, 'add', model => model.get('depth') == 1 && level0.add(model));
        this.listenTo(categories, 'remove', model => level0.remove(model));

        return level0;
    },

    _initializeHigherLevelCollection(selectedOrHasSelectedChildren, level) {
        const selectedParents = selectedOrHasSelectedChildren.filter(s => s.get('depth') == level),
              cats = new CategoryCollection(
                _(selectedParents)
                    .reduce((cats, s) => {
                        const children = s.get('children');
                        if(children && children.length) { 
                            cats.push(...children.models);
                        }
                        return cats;
                    }, [])
              );

        this.listenTo(selectedOrHasSelectedChildren, 'reset', () => {
            const selectedParents = selectedOrHasSelectedChildren.filter(s => s.get('depth') == level);

            cats.reset(selectedParents
                .reduce((cats, s) => {
                    const children = s.get('children');
                    if(children && children.length) { 
                        cats.push(...children.models);
                    }
                    return cats;
                }, []));
        });

        this.listenTo(selectedOrHasSelectedChildren, 'add', m => {
            if(m.get('depth') == level) {
                const children = m.get('children');
                if(children && children.length) {
                    cats.add(m.get('children').models);
                }
            }
        });

        this.listenTo(selectedOrHasSelectedChildren, 'remove', m => {
            if(m.get('depth') == level) {
                const children = m.get('children');
                if(children && children.length) {
                    cats.remove(m.get('children').models);
                }
            }
        });

        return cats;
    },

    initialize() {
        function onCategoriesChange(m, categories) {
            _([m.get('_level0'), m.get('_level1'), m.get('_level2'), m.get('_level3'), 
               m.get('_selection') && m.get('_selection').get('selectedOrHasSelectedChildren')])
                    .each(c => c && m.stopListening(c));

            const _selection = new SelectionModel({ categories }),
                  selectedOrHasSelectedChildren = _selection.get('selectedOrHasSelectedChildren'),
                  _level0 = m._initializeLevel0Collection(categories),
                  _level1 = m._initializeHigherLevelCollection(selectedOrHasSelectedChildren, 1),
                  _level2 = m._initializeHigherLevelCollection(selectedOrHasSelectedChildren, 2),
                  _level3 = m._initializeHigherLevelCollection(selectedOrHasSelectedChildren, 3);

            m.listenTo(selectedOrHasSelectedChildren, 'update reset', 
                selectedOrHasSelectedChildren => {
                    m.set(selectedOrHasSelectedChildren.reduce((vals, c) => {
                        switch(c.get('depth')) {
                            case 1: vals.level0SelectedCount++; break;
                            case 2: vals.level1SelectedCount++; break;
                            case 3: vals.level2SelectedCount++; break;
                            case 4: vals.level3SelectedCount++; break;
                        }
                        return vals;
                    }, _.create(null, {
                        level0SelectedCount: 0,
                        level1SelectedCount: 0,
                        level2SelectedCount: 0,
                        level3SelectedCount: 0,
                    })));
                });

            m.set(selectedOrHasSelectedChildren.reduce((vals, c) => {
                switch(c.get('depth')) {
                    case 1: vals.level0SelectedCount++; break;
                    case 2: vals.level1SelectedCount++; break;
                    case 3: vals.level2SelectedCount++; break;
                    case 4: vals.level3SelectedCount++; break;
                }
                return vals;
            }, _.create(null, {
                level0SelectedCount: 0,
                level1SelectedCount: 0,
                level2SelectedCount: 0,
                level3SelectedCount: 0,
            })));

            const filter = m.get('filter'),
                  isFilter = filter => filter && filter.length > 1,
                  contains = (name, filter) => name && name.indexOf(filter) > -1,
                  filterDepthAndContains = (depth, filter, c) => c.get('depth') > depth && contains(c.get('searchName'), filter),
                  hasParent = (c, l) => c.get('parents').find(p => l.get(p.id));

            const _level0subs = new CategoryCollection(
                isFilter(filter)
                    ? categories.filter(filterDepthAndContains.bind(null, 1, filter)) 
                    : []);

            _level0subs.listenTo(m, 'change:filter', (m, filter) => {
                if(isFilter(filter)) {
                    _level0subs.reset(categories.filter(filterDepthAndContains.bind(null, 1, filter)));
                } else {
                    _level0subs.reset();
                }
            });
            _level0subs.listenTo(categories, 'reset', () => {
                const filter = m.get('filter');
                if(isFilter(filter)) {
                    _level0subs.reset(categories.filter(filterDepthAndContains.bind(null, 1, filter)))
                } else {
                    _level0subs.reset();
                }
            });
            _level0subs.listenTo(categories, 'add', c => {
                const filter = this.get('filter');
                if(isFilter(filter) && filterDepthAndContains(1, filter, c)) {
                     _level0subs.add(c);
                }
            });
            _level0subs.listenTo(categories, 'remove', model => _level0subs.remove(model));

            const filterDepthAndLevel = 
                    (depth, parentLvl, c) => c.get('depth') > depth && hasParent(c, parentLvl),
                  filterDepthAndParent = 
                    (depth, parent, c) => c.get('depth') > depth && c.get('parents').find(p => p.id == parent.id)

            const _level1subs = new CategoryCollection(_level0subs.filter(filterDepthAndLevel.bind(null, 2, _level1)));
            _level1subs.listenTo(_level0subs, 'reset', () => _level1subs.reset(_level0subs.filter(filterDepthAndLevel.bind(null, 2, _level1))));
            _level1subs.listenTo(_level0subs, 'add', c => filterDepthAndLevel(2, _level1, c) && _level1subs.add(c));
            _level1subs.listenTo(_level0subs, 'remove', c => _level1subs.remove(c));
            _level1subs.listenTo(_level1, 'reset', () => _level1subs.reset(_level0subs.filter(filterDepthAndLevel.bind(null, 2, _level1))));
            _level1subs.listenTo(_level1, 'add', p => _level1subs.add(_level0subs.filter(filterDepthAndParent.bind(null, 2, p))));
            _level1subs.listenTo(_level1, 'remove', p => _level1subs.remove(_level1subs.filter(filterDepthAndParent.bind(null, 2, p))));

            const _level2subs = new CategoryCollection(_level1subs.filter(filterDepthAndLevel.bind(null, 3, _level2)));
            _level2subs.listenTo(_level1subs, 'reset', () => _level2subs.reset(_level1subs.filter(filterDepthAndLevel.bind(null, 3, _level2))));
            _level2subs.listenTo(_level1subs, 'add', c => filterDepthAndLevel(3, _level2, c) && _level2subs.add(c));
            _level2subs.listenTo(_level1subs, 'remove', c => _level2subs.remove(c));
            _level2subs.listenTo(_level2, 'reset', () => _level2subs.reset(_level1subs.filter(filterDepthAndLevel.bind(null, 3, _level2))));
            _level2subs.listenTo(_level2, 'add', p => _level2subs.add(_level1subs.filter(filterDepthAndParent.bind(null, 3, p))));
            _level2subs.listenTo(_level2, 'remove', p => _level2subs.remove(_level1subs.filter(filterDepthAndParent.bind(null, 3, p))));

            //const level2subs = new CategoryCollection(filter && filter.length > 1
            //    ? level0subs.filter(c => c.get('depth') > 3 && c.get('parents').find(p => level2.get(p.id))) 
            //    : []);

            //level2subs.listenTo(level0subs, 'reset', () => {
            //    level2subs.reset(level0subs.filter(c => c.get('depth') > 3 && c.get('parents').find(p => level2.get(p.id))));
            //});
            //level2subs.listenTo(level0subs, 'add', model => {
            //    model.get('depth') > 3 && model.get('parents').find(p => level2.get(p.id)) && level2subs.add(model);
            //});
            //level2subs.listenTo(level0subs, 'remove', model => level2subs.remove(model));

            //level2subs.listenTo(level2, 'reset', () => {
            //    level2subs.reset(level0subs.filter(c => c.get('depth') > 3 && c.get('parents').find(p => level2.get(p.id))));
            //});
            //level2subs.listenTo(level2, 'add', model => {
            //    level2subs.add(level0subs.filter(c => c.get('depth') > 3 && c.get('parents').find(p => p.id == model.id)));
            //});
            //level2subs.listenTo(level2, 'remove', model => level2subs.remove(level2subs.filter(c => c.get('parents').find(p => p.id == model.id))));

            m.set({
                categories,
                _selection,
                _level0, _level1, _level2, _level3,
                _level0subs, _level1subs, _level2subs
            });

            _({ level0: _level0, level1: _level1, level2: _level2, level3: _level3 })
                .each((c, name) => {
                    m.listenTo(c, 'update reset', c => m.set(name+'Count', c.length));
                    m.set(name+'Count', c.length);
                });
        }

        this.on('change:categories', onCategoriesChange);

        this.set({ categories: window.categoryCollection });
    }
});

export default LayoutView.extend({
    template,
    className: "widget category-chooser-widget",

    regions: {
        level0: '[data-js-level0-cats]',
        level1: '[data-js-level1-cats]',
        level2: '[data-js-level2-cats]',
        level3: '[data-js-level3-cats]',
        level0subs: '[data-js-level0-subs]',
        level1subs: '[data-js-level1-subs]',
        level2subs: '[data-js-level2-subs]',
    },

    ui: {
        tab0: '[data-js-tab0]',
        tab1: '[data-js-tab1]',
        tab2: '[data-js-tab2]',
        tab3: '[data-js-tab3]',
        searchInput: '[data-js-search-input]',
        searchClear: '[data-js-search-clear]',
        level0: '[data-js-level0]',
        level1: '[data-js-level1]',
        level2: '[data-js-level2]',
        level3: '[data-js-level3]',
        level0cats: '[data-js-level0-cats]',
        level1cats: '[data-js-level1-cats]',
        level2cats: '[data-js-level2-cats]',
        level3cats: '[data-js-level3-cats]',
        level0subs: '[data-js-level0-subs]',
        level1subs: '[data-js-level1-subs]',
        level2subs: '[data-js-level2-subs]',
    },

    bindingFilters: {
        isTabActive: (currentTab, tab) => currentTab == tab
    },

    bindings: {
        '@ui.tab0': 'classes:{active:isTabActive(currentTab,0),empty:not(level0Count)}',
        '@ui.tab0 > .count': 'text:level0SelectedCount',
        '@ui.tab1': 'classes:{active:isTabActive(currentTab,1),empty:not(level1Count)}',
        '@ui.tab1 > .count': 'text:level1SelectedCount',
        '@ui.tab2': 'classes:{active:isTabActive(currentTab,2),empty:not(level2Count)}',
        '@ui.tab2 > .count': 'text:level2SelectedCount',
        '@ui.tab3': 'classes:{active:isTabActive(currentTab,3),empty:not(level3Count)}',
        '@ui.tab3 > .count': 'text:level3SelectedCount',
        '@ui.searchInput': 'value:_rawfilter',
        '@ui.searchClear': 'classes:{hidden:not(filter)}',
        '@ui.level0': 'classes:{active:isTabActive(currentTab,0)}',
        '@ui.level1': 'classes:{active:isTabActive(currentTab,1)}',
        '@ui.level2': 'classes:{active:isTabActive(currentTab,2)}',
        '@ui.level3': 'classes:{active:isTabActive(currentTab,3)}',
    },

    computeds: {
        currentTab: {
            deps: ['activeTab', 'level1Count', 'level2Count', 'level3Count'],
            get: (activeTab, level1, level2, level3) => {
                switch(activeTab) {
                    case 3: if(level3) return 3;
                    case 2: if(level2) return 2;
                    case 1: if(level1) return 1;
                    default: return 0;
                }
            },
        }
    },

    events: {
        'click @ui.tab0': function() { 
            this.viewModel.set({ activeTab: 0 });
        },
        'click @ui.tab1': function() { 
            this.viewModel.set({ activeTab: 1 });
        },
        'click @ui.tab2': function() { 
            this.viewModel.set({ activeTab: 2 });
        },
        'click @ui.tab3': function() { 
            this.viewModel.set({ activeTab: 3 });
        },
        'keyup @ui.searchInput': _.debounce(function(e) {
            if(e.which != 13) {
                this.ui.searchInput.change();
            }
        }, 500),
        'click @ui.searchClear': function() {
            this.model.set('filter', '');
        }
    },

    initialize() {
        this.model = new CategoryChooserModel;
        this.viewModel = new ViewModel({
            activeTab: 0
        });

        this.listenTo(this.model, 'change:filter', (m, filter) => {
            _([this.level0, this.level1, this.level2, this.level3]).each(l => {
                l.currentView && l.currentView.setFilter(filter);
            });
        });
    },

    onAttach() {
        const filter = this.model.get('filter'),
              selected = this.model.get('selected'),
              selectedOrHasSelectedChildren = this.model.get('selectedOrHasSelectedChildren')

        const level0widget = new CategoriesWidget({
            collection: this.model.get('_level0'),
            selection: this.model.get('selection'),
            selectable: true,
            filter
        });
        this.level0.show(level0widget);

        const level1widget = new CategoriesGroupedByParentWidget({
            collection: this.model.get('_level1'),
            selection: this.model.get('selection'),
            selectable: true,
            filter
        });
        this.level1.show(level1widget);

        const level2widget = new CategoriesGroupedByParentWidget({
            collection: this.model.get('_level2'),
            selectable: true,
            selection: this.model.get('selection'),
            filter
        });
        this.level2.show(level2widget);

        const level3widget = new CategoriesGroupedByParentWidget({
            collection: this.model.get('_level3'),
            selectable: true,
            selection: this.model.get('selection'),
            filter
        });
        this.level3.show(level3widget);

        const level0subsWidget = new CategoriesGroupedByParentWidget({
            collection: this.model.get('_level0subs'),
            selectable: true,
            selection: this.model.get('selection'),
        });
        this.level0subs.show(level0subsWidget);

        const level1subsWidget = new CategoriesGroupedByParentWidget({
            collection: this.model.get('_level1subs'),
            selectable: true,
            selection: this.model.get('selection'),
        });
        this.level1subs.show(level1subsWidget);

        const level2subsWidget = new CategoriesGroupedByParentWidget({
            collection: this.model.get('_level2subs'),
            selectable: true,
            selection: this.model.get('selection'),
        });
        this.level2subs.show(level2subsWidget);

        _([level0widget, level1widget, level2widget, level3widget,
           level0subsWidget, level1subsWidget, level2subsWidget]).each(w => {
                this.listenTo(w, 'childview:select', cw => {
                    if(selectedOrHasSelectedChildren.get(cw.model.id)) {
                        selected.add(cw.model, { silent: true });
                        selected.remove(cw.model);
                    } else {
                        selected.add(cw.model);
                    }
                });
            });

        

        //const level1subsWidget = new CategoriesGroupedByParentWidget({
        //    collection: this.model.get('level1subs'),
        //    selectable: true,
        //});

        //this.listenTo(level1subsWidget, 'childview:select', cw => cw.model.set({ selected: !cw.model.get('selectedOrHasSelectedChildren') }));

        //this.level1subs.show(level1subsWidget);

        //const level2subsWidget = new CategoriesGroupedByParentWidget({
        //    collection: this.model.get('level2subs'),
        //    selectable: true,
        //});

        //this.listenTo(level2subsWidget, 'childview:select', cw => cw.model.set({ selected: !cw.model.get('selectedOrHasSelectedChildren') }));

        //this.level2subs.show(level2subsWidget);
    },

    selectCategoriesWithIds(ids) {
        this.model.set('selected', ids);
    },

    getSelection() {
        return this.model.get('selection');
    },
});
