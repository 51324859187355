var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (content_subject, type, type_title) {pug_html = pug_html + "\u003Clabel\u003E \u003Cp\u003E\u003Cspan data-js-caption\u003E\u003C\u002Fspan\u003E";
if (type == "fave") {
pug_html = pug_html + "You have blocked news from \u003Cspan class=\"place widget-lite\"\u003E\u003Cspan class=\"place-name\"\u003E" + (pug.escape(null == (pug_interp = content_subject) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E";
}
else
if (type == "task") {
pug_html = pug_html + "You have blocked notifications related to task \u003Cspan class=\"task\"\u003E\u003Cspan class=\"task-name\"\u003E" + (pug.escape(null == (pug_interp = content_subject) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "You have blocked notifications of type \"" + (pug.escape(null == (pug_interp = type_title) ? "" : pug_interp)) + "\" from source \"" + (pug.escape(null == (pug_interp = content_subject) ? "" : pug_interp)) + "\"";
}
pug_html = pug_html + "&nbsp;\u003Cspan class=\"ion-ios-help-circle-outline\" data-js-help\u003E\u003C\u002Fspan\u003E\u003C\u002Fp\u003E\u003C\u002Flabel\u003E\u003Cbutton class=\"btn-medium ion-ios-trash-outline\" data-js-remove\u003ERemove\u003C\u002Fbutton\u003E";}.call(this,"content_subject" in locals_for_with?locals_for_with.content_subject:typeof content_subject!=="undefined"?content_subject:undefined,"type" in locals_for_with?locals_for_with.type:typeof type!=="undefined"?type:undefined,"type_title" in locals_for_with?locals_for_with.type_title:typeof type_title!=="undefined"?type_title:undefined));;return pug_html;};
module.exports = template;