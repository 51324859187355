import { CollectionView, ItemView, ViewModel } from "@b2cmessenger/backbone";
import PhoneChooserItemView from "windows/Phone/PhoneChooserItem";
import emptyTemplate from "windows/Phone/PhoneEmpty.jade";

@CollectionView.properties({
    tagName: 'ul',
    className: "widget phone-chooser-collection-widget",
    childView: PhoneChooserItemView,
    childViewOptions() {
        return this.options
    },
    collectionEvents: {
        'reset update'(collection) {
            this.viewModel.set({ isEmpty: !collection.length });
        }
    },
    emptyView: ItemView.extend({
        className: 'phone-collection-empty-widget',
        template: emptyTemplate,

        ui: {
            add: '[data-js-add]'
        },

        triggers: {
            'click @ui.add': 'add'
        }
    }),
    sort: false,
    bindings: {
        ':el': 'classes:{empty:isEmpty}'
    }
})
class PhoneCollectionView extends CollectionView {
    initialize(options) {
        this.viewModel = new ViewModel({
            isEmpty: !this.collection.length
        });
    }
}

export default PhoneCollectionView;
