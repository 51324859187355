import { Model, ViewModel, LayoutView } from '@b2cmessenger/backbone';
import settings from 'settings';
import UserCollection from 'models/UserCollection';

import template from './UserSearch.jade';
import UserListWidget from 'widgets/UserList/UserList';
import qrCodeScanner from 'utils/qrCodeScanner';

import { IntlTelInputHandler } from 'widgets/lite/intlTelInput/intlTelInput';
import './UserSearch.scss';
import getUserCountryCode from 'utils/getUserCountryCode';

const UserSearchView = LayoutView.extend({
    options: {
        placeId: undefined
    },

    getPlaceId() {
        return this.options.placeId;
    },

    setPlaceId(placeId) {
        const shouldRerender = this.isRendered && placeId != this.options.placeId;

        this.options.placeId = placeId;

        if (shouldRerender) {
            _.defer(() => {
                this.render();
            });
        }
    },

    setLoyaltyCardNumber(loyaltyCardNumber, options) {
        _.defaults(options || (options = {}), {
            users: null
        });

        const payload = { loyaltyCardNumber };

        if (options.resetModel) {
            _.extend(payload,{
                fullname: '',
                email: '',
                phone: '',
                userId: '',
            });
        }

        this.model.set(payload);

        if (_.isArray(options.users)) {
            this.collection.reset(options.users);
        }

        if (options.performSearch) {
            this.ui.form.submit();
        }
    },

    search(dontTriggerOnEmptyResult) {
        this._search(dontTriggerOnEmptyResult);
    },

    className: "widget user-search-widget",
    template,

    regions: {
        results: '[data-js-search-result]',
    },

    ui: {
        searchBtn: '[data-js-search-btn]',
        qrBtn: '[data-js-qr-btn]',
        form: '[data-js-form]',
        fullname: '[data-js-fullname]',
        email: '[data-js-email]',
        phone: '[data-js-phone]',
        userId: '[data-js-user-id]',
        loyaltyCardNumber: '[data-js-loyalty-card-number]'
    },

    bindings: {
        '@ui.searchBtn': 'disabled:disabled',
        '@ui.qrBtn': 'disabled:disabled',
        '@ui.form': 'disabled:disabled',
        '@ui.fullname': 'value:fullname',
        '@ui.email': 'value:email',
        '@ui.phone': 'intlTelInput:phone',
        '@ui.userId': 'value:userId',
        '@ui.loyaltyCardNumber': 'value:loyaltyCardNumber'
    },

    bindingHandlers() {
        const countries = [];

        if (this.options.placeId && LoginedUserHandler.get_default_work_place_id() == this.options.placeId) {
            const workplace = LoginedUserHandler.get_default_work_place();

            if (workplace && workplace.adr_country_code) {
                countries.push(workplace.adr_country_code.toLowerCase());
            }
        }

        return {
            intlTelInput: new IntlTelInputHandler({
                preferredCountries() {
                    return getUserCountryCode()
                        .then(cc => {
                            if (cc) {
                                countries.unshift(cc);
                            }

                            return _.uniq(countries);
                        });
                }
            })
        };
    },

    events: {
        'submit @ui.form'(e) {
            e && e.preventDefault();

            this._search()
        },
        'click @ui.qrBtn'() {
            qrCodeScanner()
                .then(qrCodeModel => {
                    if (qrCodeModel) {
                        const userId = qrCodeModel.get('userId');
                        const loyaltyCardNumber = this.getPlaceId() && qrCodeModel.get('loyaltyCardNumber'); // place-scoped search only

                        if (userId || loyaltyCardNumber) {
                            this.model.set({
                                fullname: '',
                                email: '',
                                phone: '',
                                userId: userId || '',
                                loyaltyCardNumber: loyaltyCardNumber || ''
                            });

                            this.ui.form.submit();

                            if (userId) {
                                this._businessCheckin(userId);
                            }
                        }
                    }
                })
                .catch(this.showError);
        }
    },

    initialize() {
        if (!this.viewModel) {
            this.viewModel = new ViewModel;
        }

        this.model = new Model({
            fullname: '',
            email: '',
            phone: '',
            userId: '',
            loyaltyCardNumber: ''
        });

        this.collection = new UserCollection;

        this.on('disable', () => this.viewModel.set('disable', true));
        this.on('enable', () => this.viewModel.set('disable', false));
    },

    resetSearchForm() {
        this.model.set({
            fullname: '',
            email: '',
            phone: '',
            userId: '',
            loyaltyCardNumber: ''
        });

        this.collection.reset();
    },

    templateHelpers() {
        return {
            placeId: this.options.placeId
        };
    },

    onRender() {
        const collectionView = new UserListWidget({
            childViewOptions: {
                clickable: true
            },
            collection: this.collection
        });

        this.listenTo(collectionView, 'click:user', (m) => {
            this.triggerMethod('select', m);
        });

        this.results.show(collectionView);
    },

    _search(dontTriggerOnEmptyResult) {
        this.trigger('disable');

        const loyaltyCardNumber = this.model.get('loyaltyCardNumber') || null;
        const payload = {
            fullname: this.model.get('fullname') || null,
            email: this.model.get('email') || null,
            phone: this.model.get('phone') || null,
            id: this.model.get('userId') || null,
            loyalty_card_number: loyaltyCardNumber,
            limit: 99
        };

        if (!_.isNull(loyaltyCardNumber) && this.getPlaceId()) {
            const defaultWorkPlace = LoginedUserHandler.get_default_work_place();

            if (defaultWorkPlace) {
                _.extend(payload, {
                    current_place_id: defaultWorkPlace.id,
                    current_brand_id: defaultWorkPlace.brand_id
                });
            }
        }

        Server.callServer({
            url: settings.host + settings.serv_user.search,
            type: 'POST',
            data: payload,
            success: data => {
                this.trigger('enable');
                this.collection.reset(data);

                if (!dontTriggerOnEmptyResult && !this.collection.length) {
                    this.triggerMethod('empty:result', this.model);
                }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.trigger('enable');
                this.showError(jqXHR, textStatus, errorThrown);
            },
        })
    },

    _businessCheckin(userId) {
        const placeId = this.getPlaceId();
        if (userId && placeId && LoginedUserHandler.isHasRole(placeId, B2CEmployee.const.roles.EVIDENCE_MANAGER)) {
            B2CPlace.server_business_checkin(userId, placeId);
        }
    },
});

export default UserSearchView;
