import { ViewModel, LayoutView } from '@b2cmessenger/backbone';

import TaskTargetModel from 'models/TaskTargetModel';

import Window from 'windows/Window';
import HeaderView from 'widgets/Header/Header';
import FooterView from 'widgets/Footer/Footer';
import CategoriesWidget from 'widgets/Category/Categories';
import CategoryChooserWindow from 'windows/Category/CategoryChooser';
import CategoryCollection from 'models/CategoryCollection';
import ConfirmModal from 'windows/Modal/Confirm';
import 'utils/Element.scrollIntoCenter';

import template from './TaskTargetEditor.jade';
import './TaskTargetEditor.scss';

function pad2(n) {
    n = String(n);
    return n.length > 1 ? n : '0' + n;
}

const TaskTargetEditor = LayoutView.extend({
    options: {
        validateAtStart: false,
        support12HoursFormat: true,
    },

    template,
    className: 'widget task-target-editor-widget',

    ui: {
        form: '[data-js-form]',
        fieldset: '[data-js-fieldset]',
        title: '[data-js-title]',
        titleError: '[data-js-title-error]',
        generalError: '[data-js-general-error]',
        paused: '[data-js-paused]',
        pausedError: '[data-js-paused-error]',
        pausedGroup: '[data-js-group-paused]',
        ageMin: '[data-js-age-min]',
        ageMinError: '[data-js-age-min-error]',
        ageMax: '[data-js-age-max]',
        ageMaxError: '[data-js-age-max-error]',
        gender: '[data-js-gender]',
        genderError: '[data-js-gender-error]',
        birthday: '[data-js-birthday]',
        birthdaySlider: '[data-js-birthday-slider]',
        birthdayError: '[data-js-birthday-error]',
        distance: '[data-js-distance]',
        distanceSlider: '[data-js-distance-slider]',
        distanceError: '[data-js-distance-error]',
        clientAware: '[data-js-client-aware]',
        clientAwareError: '[data-js-client-aware-error]',
        categories: '[data-js-categories]',
        categoriesError: '[data-js-categories-error]',
        categoryChooseBtn: '[data-js-btn-category-choose]',
        interestPeriodGroup: '[data-js-group-interest-period]',
        interestPeriod: '[data-js-interest-period]',
        interestPeriodError: '[data-js-interest-period-error]',
        limit: '[data-js-limit]',
        limitError: '[data-js-limit-error]',
        repeat: '[data-js-repeat]',
        repeatError: '[data-js-repeat-error]',
        startTitle: '[data-js-start-title]',
        start: '[data-js-start]',
        startError: '[data-js-start-error]',
        endGroup: '[data-js-group-end]',
        end: '[data-js-end]',
        endError: '[data-js-end-error]',
        time: '[data-js-time]',
        timeError: '[data-js-time-error]',
        errors: '.error',
        inputs: 'input'
    },

    regions: {
        categories: '[data-js-categories]'
    },

    computeds: {
        'c_title': {
            deps: ['title'],
            get: title => title && String(title) || '',
            set(val) { this.model.set({ title: val && String(val) || null }) }
        },
        'c_isPaused': {
            deps: ['paused'],
            get: paused => paused == 1 ? 1 : 0,
            set(val) { this.model.set({ paused: val == 1 ? 1 : 0 }) }
        },
        'c_canBePaused': {
            deps: ['status'],
            get: status => status == TaskTargetModel.Status.Scheduled || status == TaskTargetModel.Status.InProgress
        },
        'c_age_min': {
            deps: ['age_min'],
            get: age_min => age_min && Number(age_min) || '',
            set(val) { this.model.set({ age_min: val && Number(val) || null }) }
        },
        'c_age_max': {
            deps: ['age_max'],
            get: age_max => age_max && Number(age_max) || '',
            set(val) { this.model.set({ age_max: val && Number(val) || null }) }
        },
        'c_birthday': {
            deps: ['days_before_birthday', '_recalculate_days_before_birthday'],
            get: days_before_birthday => {
                if(_.isNull(days_before_birthday)) {
                    return '';
                } else {
                    return Number(days_before_birthday);
                }
            },
            set(val) {
                this.model.set({
                    days_before_birthday: (_.isNull(val) || _.isNaN(Number(val)) || !String(val).length || val > 99 || val < 0) ? null : Number(val)
                });

                this.viewModel.set({
                    _recalculate_days_before_birthday: _.uniqueId()
                });
            }
        },
        'c_distance': {
            deps: ['distance', '_recalculate_distance'],
            get: distance => distance || '',
            set(val) {
                this.model.set({
                    distance: val && val <= 25 ? val : null
                });

                this.viewModel.set({
                    _recalculate_distance: _.uniqueId()
                });
            }
        },
        'c_clientAware': {
            deps: ['client_knowledge'],
            get: client_knowledge => client_knowledge ? 1 : 0,
            set(val) { this.model.set({ client_knowledge: val == 1 }) }
        },
        'c_isCategoriesEmpty': {
            deps: ['interest_categories'],
            get: interest_categories => !interest_categories || !interest_categories.length
        },
        'c_interestPeriod': {
            deps: ['interest_strength'],
            get: interest_strength => interest_strength == 1 ? 1 : interest_strength == 2 ? 2 : 0,
            set(val) { this.model.set({ interest_strength: val == 1 ? 1 : val == 2 ? 2 : null }) }
        },
        'c_limit': {
            deps: ['client_limit'],
            get: limit => Number(limit) || "",
            set(val) { this.model.set({ client_limit: Number(val) || null }) }
        },
        'c_isRepeat': {
            deps: ['repeat'],
            get: repeat => !!repeat && repeat != 'once'
        },
        'c_isStartingToday': {
            deps: ['dateStart'],
            get: dateStart => _.isNull(dateStart) || ((new Date).toDateString() == dateStart.toDateString())
        },
        'c_time': {
            deps: ['sendHour', 'sendMinute', '_recalculate_time'],
            get: (sendHour, sendMinute) => {
                if (!_.isNull(sendHour)) {
                    sendMinute = sendMinute || 0;
                    return `${pad2(sendHour)}:${pad2(sendMinute)}`;
                }
                return '';
            },
            set(val) {
                const m = /([0-9]+):([0-9]+)(:[0-9]+)?/.exec(val);
                if (m && m[0] && m[1] && m[2]) {
                    this.model.set({
                        sendHour: m[1],
                        sendMinute: m[2]
                    });
                } else {
                    this.model.set({
                        sendHour: null,
                        sendMinute: null
                    });
                }
            }
        },
        'c_timeError': {
            deps: ['sendHourError', 'sendMinuteError'],
            get: (sendHourError, sendMinuteError) => (sendHourError && sendMinuteError && sendHourError.concat(sendMinuteError)) || sendHourError || sendMinuteError || []
        }
    },

    bindingHandlers: {
        sliderValue: {
            init($element, value) {
                $element.slider();
            },
            get($element) {
                return $element.val();
            },
            set($element, value) {
                try {
                    if ($element.val() + '' != value + '') {
                        $element.val(value);
                        $element.slider && $element.slider('instance') && $element.slider('refresh');
                    }

                } catch (error) {}
            }
        },
        sliderEnabled: {
            set($element, value) {
                $element.prop('disabled', !value);
                $element.slider && $element.slider('instance') && (value ? $element.slider('enable') : $element.slider('disable'))
            },
        },
        dateValue: {
            get($element) {
                return B2Cjs.datetimeInputToJs($element.val());
            },
            set($element, value) {
                value = B2Cjs.datetimeJsToInput(value, true);
                try {
                    if ($element.val() != value) {
                        $element.val(value);

                        _.defer(() => $element[0].dispatchEvent(new Event('update', {
                            bubbles: true,
                            cancelable: true,
                        })));
                    }
                } catch (e) {}
            }
        },
        timeValue: {
            init($element, value) {
                $element.blur(() => {
                    const step = Number($element.attr('step')) || 1;
                    if (step >= 60) {
                        const m = /([0-9]+):([0-9]+)(:[0-9]+)?/.exec($element.val());

                        if (m && m[0] && m[1] && m[2]) {
                            const seconds = Math.max(
                                0,
                                Math.min(
                                    Math.ceil((m[1] * 60 * 60 + m[2] * 60 + (m[3] || 0) * 1) / step) * step,
                                    Math.floor((23 * 60 * 60 + 59 * 60 + 59) / step) * step
                                )
                            );

                            if (!_.isNaN(seconds)) {
                                $element.val(`${pad2(Math.floor(seconds / 60 / 60))}:${pad2(Math.floor(seconds / 60) % 60)}:${pad2(seconds % 60)}`);
                            }
                        }
                    }
                });
            },
            get($element) {
                return $element.val();
            },
            set($element, value) {
                try {
                    if ($element.val() != value) {
                        $element.val(value);

                        _.defer(() => $element[0].dispatchEvent(new Event('update', {
                            bubbles: true,
                            cancelable: true,
                        })));
                    }
                } catch (e) { }
            }
        }
    },

    bindingFilters: {
        joinWithNewline: arr => arr && arr.join && arr.join('\n') || '',
        isEmpty: val => _.isNull(val) || _.isUndefined(val) || !String(val),
        is0: val => val == '0',
        is1: val => val == '1',
        isSingleLetter: val => String(val).length == 1,
        slider0to99: val => (_.isNull(val) || _.isNaN(Number(val)) || !String(val).length || val > 99 || val < 0) ? 100 : Number(val),
        slider26: val => (_.isNull(val) || _.isNaN(Number(val)) || !String(val).length || val > 26) ? 26 : Number(val),
        date: {
            get: val => B2Cjs.datetimeJsToInput(val, true),
            set: val => B2Cjs.datetimeInputToJs(val)
        },
        number: {
            get: val => !_.isNull(val) && !_.isNaN(Number(val)) ? Number(val) : "",
            set: val => val && !_.isNaN(Number(val)) ? Number(val) : null
        }
    },

    bindings: {
        '@ui.fieldset': 'enabled:all(canBeChanged,not(disabled))',
        '@ui.title': 'value:c_title',
        '@ui.titleError': 'text:joinWithNewline(titleError)',
        '@ui.generalError': 'text:joinWithNewline(generalError)',
        '@ui.paused': 'checked:c_isPaused',
        '@ui.pausedError': 'text:joinWithNewline(age_minError)',
        '@ui.pausedGroup': 'classes:{hidden:not(c_canBePaused)}',
        '@ui.ageMin': 'value:c_age_min',
        '@ui.ageMinError': 'text:joinWithNewline(age_minError)',
        '@ui.ageMax': 'value:c_age_max',
        '@ui.ageMaxError': 'text:joinWithNewline(age_maxError)',
        '@ui.gender': 'checked:gender',
        '@ui.genderError': 'text:joinWithNewline(genderError)',
        '@ui.birthday': 'value:c_birthday,classes:{empty:isEmpty(c_birthday),today:is0(c_birthday),one:is1(c_birthday),single:isSingleLetter(c_birthday)}',
        '@ui.birthdaySlider': 'sliderValue:slider0to99(c_birthday),sliderEnabled:all(canBeChanged,not(disabled))',
        '@ui.birthdayError': 'text:joinWithNewline(days_before_birthdayError)',
        '@ui.distance': 'value:c_distance,classes:{empty:isEmpty(c_distance),one:is1(c_distance),single:isSingleLetter(c_distance)}',
        '@ui.distanceSlider': 'sliderValue:slider26(c_distance),sliderEnabled:all(canBeChanged,not(disabled))',
        '@ui.distanceError': 'text:joinWithNewline(distanceError)',
        '@ui.clientAware': 'checked:c_clientAware',
        '@ui.clientAwareError': 'text:joinWithNewline(client_knowledgeError)',
        '@ui.categories': 'classes:{empty:c_isCategoriesEmpty}',
        '@ui.categoriesError': 'text:joinWithNewline(interest_categoriesError)',
        '@ui.interestPeriodGroup': 'classes:{disabled:c_isCategoriesEmpty}',
        '@ui.interestPeriod': 'checked:c_interestPeriod,disabled:c_isCategoriesEmpty',
        '@ui.interestPeriodError': 'text:joinWithNewline(interest_strengthError)',
        '@ui.limit': 'value:c_limit',
        '@ui.limitError': 'text:joinWithNewline(client_limitError)',
        '@ui.repeat': 'checked:repeat',
        '@ui.repeatError': 'text:joinWithNewline(repeatError)',
        '@ui.startTitle': 'classes:{period:c_isRepeat}',
        '@ui.start': 'dateValue:dateStart',
        '@ui.startError': 'text:joinWithNewline(dateStartError)',
        '@ui.endGroup': 'classes:{hidden:not(c_isRepeat)}',
        '@ui.end': 'dateValue:dateEnd',
        '@ui.endError': 'text:joinWithNewline(dateEndError)',
        '@ui.time': 'timeValue:c_time,attr:{placeholder:select(c_isStartingToday,"Immediately","Enter time")}',
        '@ui.timeError': 'text:joinWithNewline(c_timeError)',
    },

    events: {
        'submit @ui.form'(e) {
            this.trigger('submit');
            e.preventDefault();
            return false;
        },
        'click @ui.categoryChooseBtn'(e) {
            new CategoryChooserWindow()
                .show(this.model.get('interest_categories') || [])
                .then(selection => {
                    const selected = selection.get('selected'),
                          hasSelectedChildren = selection.get('hasSelectedChildren'),
                          allChildrenSelected = selection.get('allChildrenSelected');

                    const cats = selected
                        .filter(c => !hasSelectedChildren.get(c.id) || allChildrenSelected.get(c.id))
                        .sort((c1, c2) => {
                            const parents1 = c1.get('parents').slice().reverse(),
                                    parents2 = c2.get('parents').slice().reverse();

                            parents1.push(c1);
                            parents2.push(c2);

                            for(let i = 0; i < Math.max(parents1.length, parents2.length); i++) {
                                let name1 = parents1[i] && parents1[i].get('name') || '',
                                    name2 = parents2[i] && parents2[i].get('name') || '';

                                if(name1 < name2) return -1;
                                else if(name1 > name2) return 1;
                            }

                            let name1 = c1.get('name'), name2 = c2.get('name');

                            return name1 < name2 ? -1 : name1 > name2;
                        });
                    this.model.set('interest_categories', _(cats).pluck('id'));
                });
        },
    },

    initialize() {
        if(!this.model) {
            this.model = new TaskTargetModel({
                place_id: this.options.placeId || undefined
            });
        }

        function parseCategoryIdArrayFromModelToArrayOfCategories(ids) {
            const cats = [];

            ids && _(ids).each(id => {
                const cat = window.categoryCollection.get(id);
                cat && cats.push(cat);
            });

            return cats;
        };

        if(!this.viewModel) {
            this.viewModel = new ViewModel;
        }

        this.viewModel.set({
            categories: new CategoryCollection(parseCategoryIdArrayFromModelToArrayOfCategories(this.model.get('interest_categories'))),
            generalError: '',
            titleError: '',
            age_minError: '',
            age_maxError: '',
            genderError: '',
            days_before_birthdayError: '',
            distanceError: '',
            client_knowledgeError: '',
            interest_categoriesError: '',
            interest_strengthError: '',
            client_limitError: '',
            repeatError: '',
            dateStartError: '',
            dateEndError: '',
            sendHourError: '',
            sendMinuteError: '',
            _recalculate_days_before_birthday: false,
            _recalculate_distance: false,
            _recalculate_time: false,
            _isBeforeFirstValidation: true,
            hasBeenSaved: false,
        });

        this.viewModel.addComputed('hasErrors', {
            deps: ['generalError', 'titleError', 'age_minError', 'age_maxError', 'genderError',
                   'days_before_birthdayError', 'distanceError', 'client_knowledgeError',
                   'interest_categoriesError', 'interest_strengthError', 'client_limitError',
                'repeatError', 'dateStartError', 'dateEndError', 'sendHourError', 'sendMinuteError'],
            get: (...args) => !!_(args).find(a => a),
        });

        this.viewModel.addComputed('isValid', {
            deps: ['hasErrors', '_isBeforeFirstValidation', 'disabled'],
            get: (hasErrors, _isBeforeFirstValidation, disabled) => !(hasErrors || _isBeforeFirstValidation || disabled),
        });

        this.viewModel.listenTo(this.model, 'change:interest_categories',
            (m, ids) => this.viewModel.get('categories').reset(parseCategoryIdArrayFromModelToArrayOfCategories(ids)));

        this.viewModel.listenTo(this.model, 'invalid', (m, fields) => {
            this.viewModel.set(_(fields).reduce((fields, msgs, field) => {
                const key = field && String(field) && (String(field) + 'Error');
                if(key && msgs && msgs.length) {
                    fields[key] = msgs;
                }
                return fields;
            }, _.create(null)));

            if (this.ui.inputs && !this.ui.inputs.filter(':focus').length) {
                const
                    $error = this.ui.errors && this.ui.errors.filter(':not(:empty)').first(),
                    $group = $error.closest('.input-group');

                if ($group.length) {
                    $group[0].scrollIntoCenter();
                } else if ($error.length) {
                    $error[0].scrollIntoCenter();
                }
            }
        });

        const debouncedValidation = _.debounce(() => {
            this.viewModel.set({
                generalError: '',
                titleError: '',
                age_minError: '',
                age_maxError: '',
                genderError: '',
                distanceError: '',
                days_before_birthdayError: '',
                client_knowledgeError: '',
                interest_categoriesError: '',
                interest_strengthError: '',
                client_limitError: '',
                repeatError: '',
                dateStartError: '',
                dateEndError: '',
                sendHourError: '',
                sendMinuteError: '',
                _isBeforeFirstValidation: false,
                disable: true,
            });
            this.model.isValid({
                validateOnServer: true,
                success: () => this.viewModel.set({
                    generalError: '',
                    titleError: '',
                    age_minError: '',
                    age_maxError: '',
                    genderError: '',
                    distanceError: '',
                    days_before_birthdayError: '',
                    client_knowledgeError: '',
                    interest_categoriesError: '',
                    interest_strengthError: '',
                    client_limitError: '',
                    repeatError: '',
                    dateStartError: '',
                    dateEndError: '',
                    sendHourError: '',
                    sendMinuteError: '',
                    disable: false,
                }),
                error: () => this.viewModel.set({ disable: false })
            });
        }, 500);

        this.listenTo(this.model, 'change:data', (m) => m.get('hasUnsavedChanges') && debouncedValidation());

        if (this.options.validateAtStart) {
            this.viewModel.set({
                _isBeforeFirstValidation: false,
                disable: true,
            });

            this.model.isValid({
                validateOnServer: true,
                success: () => this.viewModel.set({ disable: false }),
                error: () => this.viewModel.set({ disable: false })
            });
        }
    },

    onAttach() {
        this.categories.show(new CategoriesWidget({
            collection: this.viewModel.get('categories')
        }));
    },

    onDestroy() {
        this.viewModel.stopListening(this.model, 'change:interest_categories invalid');
    }
});

export { TaskTargetEditor };

const TaskTargetEditorWindow = Window.extend({
    options: {
        placeId: undefined,
        returnNullIfModelHasNotBeenSaved: false,
        validateAtStart: false,
        support12HoursFormat: true,
    },

    windowName: "task-target-editor-window",
    className: "task-target-editor-window",

    initialize() {
        if(!this.model) {
            this.model = new TaskTargetModel({
                place_id: this.options.placeId || undefined
            });
        }
    },

    onRender() {
        const editor = new TaskTargetEditor({
            model: this.model,
            placeId: this.options.placeId,
            viewModel: this.viewModel,
            validateAtStart: this.options.validateAtStart,
            support12HoursFormat: this.options.support12HoursFormat
        });
        this.content.show(editor);

        this.listenTo(editor, 'submit', () => this.save());

        const headerView = new HeaderView({
            leftButtons: ['back'],
            rightButtons: ['save'],
            title: 'Edit task target'
        });
        this.listenTo(headerView, 'back:click', view => this.cancel());
        this.listenTo(headerView, 'save:click', view => this.save({
            success: () => this.showMessage("Successfully saved")
        }));
        this.header.show(headerView);

        headerView.listenTo(this.viewModel, 'change:isValid',
            m => headerView.ui.btnsave.prop('disabled', !m.get('isValid')));

        const footerView = new FooterView({
            buttons: [{
                id: 'done',
                text: 'DONE',
            }, {
                id: 'cancel',
                text: 'CANCEL',
            }]
        });
        this.listenTo(footerView, 'cancel:click', view => this.cancel());
        this.listenTo(footerView, 'done:click', view => {
            if (this.model.get('hasUnsavedChanges')) {
                this.save({
                    success: () => this.close(this.model)
                })
            } else {
                this.close(this.model);
            }
        });
        footerView.listenTo(this.model, 'change:recipient_count',
            (m, recipient_count) =>
                footerView.ui.btndone
                    .find('.text')
                    .text(
                        _.isNull(recipient_count) ?
                            'DONE' :
                            ('~' + recipient_count + (recipient_count == 1 ? ' RECIPIENT' : ' RECIPIENTS'))
                    )
        );
        footerView.listenTo(this.viewModel, 'change:isValid',
            m => footerView.ui.btndone.prop('disabled', !m.get('isValid')));

        this.footer.show(footerView);

        footerView.ui.btndone.prop('disabled', !this.viewModel.get('isValid'));
    },

    save(options) {
        _.defaults(options || (options = {}));

        const success = options.success;
        options.success = (model, jsXHR, options) => {
            this.viewModel.set({ hasBeenSaved: true });

            if (success) success.call(options.context, model, jsXHR, options);
        };

        const error = options.error;
        options.error = (model, jqXHR, options) => {
            if (jqXHR.status != 422) {
                this.showError(jqXHR);
            }

            if (error) error.call(options.context, model, jqXHR, options);
        };

        this.model.save(null, options);
    },

    cancel() {
        if(!this.viewModel.get('disabled'))  {
            if(this.model.get('hasUnsavedChanges')) {
                new ConfirmModal({ message: "Cancel editing? All changes will be lost!" })
                    .show()
                    .then(confirm => confirm &&
                        this.close(this.model.isNew()
                            ? undefined
                            : this.options.returnNullIfModelHasNotBeenSaved
                                ? this.viewModel.get('hasBeenSaved')
                                    ? this.model
                                    : null
                                : this.model
                        )
                    );
            } else {
                return this.close(this.model.isNew() ? undefined : this.model);
            }
        }
    }
});

export default TaskTargetEditorWindow;
