import { Model, ItemView } from '@b2cmessenger/backbone';
import AjaxError from 'utils/AjaxError';

import Modal from './Modal';
import FooterView from 'widgets/Footer/Footer';

import template from './Info.jade';
import './Info.scss';

const InfoModal = Modal.extend({
    options: {
        buttons: null
    },
    windowName: "info-modal-window",
    className: "modal-window info-modal-window",
    ui: {},
    events: {},

    bindings: {
        ':el': 'classes:{"has-text":text,"only-title":not(text)}'
    },

    initialize() {
        if (!this.model) {
            this.model = new Model({
                title: this.options.title || '',
                text: this.options.text || '',
                isHtml: !!this.options.isHtml,
            });
        }

        if (!this.model.has('text')) {
            this.model.set({ text: '' });
        }
    },

    onRender() {
        if (this.options.additionalClassName) {
            this.$el.addClass(this.options.additionalClassName);
        }

        const buttons = _.filter(this.options.buttons, b => b.id);
        if (!buttons.length) {
            buttons.push({
                id: "close",
                text: "CLOSE",
                icn: "empty",
                value: true
            });
        }

        const footerView = new FooterView({ buttons });

        _.each(buttons, b => {
            this.listenTo(footerView, `${b.id}:click`, () => this.close(_.has(b, 'value') ? b.value : b.id));
        });

        this.footer.show(footerView);

        if (!this.options.content) {
            this.content.show(new ItemView({
                model: this.model,
                template: template,
                className: "widget info-modal-content-widget"
            }));
        } else {
            this.content.show(this.options.content);
        }
    },

    cancel() {
        this.close(true);
    }
});

let openModalsWithDontRepeat = {};

export function showMessage(message, options) {
    _.defaults(options || (options = {}));

    options = _.extend(
        _.pick(options, ['buttons', 'dontRepeat']),
        {
            additionalClassName: options.isError ? `red-border error` : (options.border || ('green-border')),
            title: _.isObject(message) ? (message.title || message.text) : message,
            text: _.isObject(message) && (message.title ? message.text : "") || "",
            isHtml: _.isObject(message) && !!message.isHtml || false
        }
    );

    if (options.dontRepeat) {
        const hash = JSON.stringify({
            message: _.pick(message, ['title', 'text', 'isHtml']),
            options: _.pick(options, ['buttons', 'isError', 'isHtml'])
        }).hashCode();

        let openModal = openModalsWithDontRepeat[hash];
        if (!openModal) {
            openModal = openModalsWithDontRepeat[hash] = new InfoModal(options).show();
        }

        return openModal.then(r => {
            delete openModalsWithDontRepeat[hash];
            return r;
        });
    }

    const modal = new InfoModal(options);

    _.extend(options, { modal });

    return modal.show();
};

export function showError(jqXHRorStringOrError, textStatus, errorThrown, options) {
    _.defaults(options || (options = {}), {
        dontSendToGaAndSentry: false
    });

    if (jqXHRorStringOrError instanceof AjaxError) {
        jqXHRorStringOrError = jqXHRorStringOrError.jqXHR;
        textStatus = jqXHRorStringOrError.textStatus;
        errorThrown = jqXHRorStringOrError.errorThrown;
    }

    let message = 'Unknown error';

    if (_.isString(jqXHRorStringOrError)) {
        message = {
            title: textStatus && String(textStatus),
            text: jqXHRorStringOrError
        };
    } else if (_.isError(jqXHRorStringOrError)) {
        message = {
            title: textStatus && String(textStatus) || jqXHRorStringOrError.message,
            text: JSON.stringify(jqXHRorStringOrError, null, '  ')
        };
    } else if (_.isObject(jqXHRorStringOrError) &&
        (_.has(jqXHRorStringOrError, 'status') || jqXHRorStringOrError.responseJSON)
    ) {
        const o = jqXHRorStringOrError;
        if (o.status == 0) {
            message = "No internet connection";

            _.extend(options, {
                dontRepeat: true
            });
        } else if (o.status == 502 || o.status == 503 || o.status == 504) {
////////////////////////
///////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////////////
////////////////////
            message = "Hi there! We're currently updating our servers and we will be online shortly.";
/////////////////////

            _.extend(options, {
                dontRepeat: true
            });
        } else if (o.status == 401) {
            if (LoginedUserHandler.isUserLogined()) {
                message = "Please, re-login into the app";
            } else {
                message = "You must be logged in to continue...";
            }

            _.extend(options, {
                dontRepeat: true
            });
        } else if (_.isObject(o.responseJSON)) {
            const r = o.responseJSON;

            if (r.error && r.error.message) {
                message = {
                    title: r.error.title || '',
                    text: r.error.message
                };
            } else if (r.length && r[0].field && r[0].message) {
                const general = _.find(r, v => v.field == "_general");
                if (general) {
                    message = {
                        title: general.title,
                        text: general.message
                    };
                } else {
                    message = {
                        title: `${ o.status || '' } ${ errorThrown || "Validation error" }`,
                        text: _.reduce(r, (text, v) => text + `"${v.field}": ${v.message}\n`, '')
                    };
                }
            } else {
                message = {
                    title: `${r.status || r.code || r.error && r.error.code || o.status || ""} ${r.name || r.error && r.error.title || errorThrown || ""}`,
                    text: JSON.stringify(r, null, '  ')
                };
            }
        } else if (_.isObject(o) && o.text) {
            message = o;
        } else {
            message = {
                title: `${o.status} ${errorThrown || ''}`,
                text: textStatus || ''
            };
        }
    }

    if (GoogleAnalytics && !options.dontSendToGaAndSentry) {
        GoogleAnalytics.trackException(`showError: ${JSON.stringify(jqXHRorStringOrError)}, ${JSON.stringify(textStatus)}, ${JSON.stringify(errorThrown)}`, false);
    }

    if (window.Sentry && !options.dontSendToGaAndSentry) {
        Sentry.captureMessage(`showError: ${JSON.stringify(jqXHRorStringOrError)}, ${JSON.stringify(textStatus)}, ${JSON.stringify(errorThrown)}, message: ${JSON.stringify(message)}`);
    }

    _.extend(options, {
        isError: true
    });

    console.warn(`showError`, jqXHRorStringOrError, textStatus, errorThrown, options);

    return this ? this.showMessage(message, options) : showMessage(message, options);
};

export function showsMessages(target) {
    Object.defineProperties(target.prototype, {
        showError: {
            value: showError,
            enumerable: false,
            configurable: false,
            writable: false
        },
        showMessage: {
            value: showMessage,
            enumerable: false,
            configurable: false,
            writable: false
        }
    });
}

_.assign(InfoModal, { showsMessages });

export default InfoModal;
