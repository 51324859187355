import { Model, LayoutView } from '@b2cmessenger/backbone';

import PhotoWidget from 'widgets/Photo/Photo';
import BigPhoto from 'widgets/Photo/BigPhoto';
import PhotoSlider from 'widgets/Photo/PhotoSlider';
import GridView from 'widgets/Photo/Grid';

import template from './Gallery.jade';
import './Gallery.scss';

let Gallery = LayoutView.extend({
    className: 'widget gallery-widget',
    template,

    regions: {
        bigPhoto: '[data-js-big-photo]',
        slider: '[data-js-slider]',
        grid: '[data-js-grid]',
    },

    bindings: {
        ':el': 'classes:{grid:showGrid,single:showSingle}',
        '[data-js-mask]': 'classes:{hidden:not(isZoomed)}'
    },

    events: {
        'touchmove': 'onTouchmove'
    },

    initialize() {
        this.viewModel = new (Model.extend({
            defaults: {
                zoomedTreshold: 1.1,
            },
            computeds: {
                isZoomed: {
                    deps: ['bigPhotoZoom', 'zoomedTreshold'],
                    get: (zoom, zoomedTreshold) => zoom > zoomedTreshold
                },
                showSingle: {
                    deps: ['count'],
                    get: length => length == 1
                },
                showGrid: {
                    deps: ['gridMode', 'count', 'editMode'],
                    get: (grid, count, editMode) => grid && (count != 1 || editMode)
                }
            }
        }))({
            activePhotoIndex: 0,
            bigPhotoZoom: 1,
            count: this.collection.length,
            gridMode: true,
            editMode: false
        });

        this.listenTo(this.collection, 'update reset', (collection) => {
            this.viewModel.set('count', collection.length);

            let activePhotoIndex = this.viewModel.get('activePhotoIndex');
            if(activePhotoIndex >= collection.length) {
                activePhotoIndex = collection.length ? collection.length - 1 : 0;
                this.viewModel.set({ activePhotoIndex });
            } else if(activePhotoIndex < 0) {
                activePhotoIndex = 0;
                this.viewModel.set({ activePhotoIndex });
            }
        });

        this.listenTo(this.viewModel, 'change:showGrid', (m, showGrid) => this.trigger('change:showGrid', this, showGrid));
        this.listenTo(this.viewModel, 'change:editMode', (m, editMode) => this.trigger('change:editMode', this, editMode));
    },

    onRender() {
        let bigPhoto = new BigPhoto({ collection: this.collection });
        this.listenTo(bigPhoto, 'change:activePhotoIndex', (bigPhoto, activePhotoIndex) => this.viewModel.set({ activePhotoIndex }));
        bigPhoto.listenTo(this.viewModel, 'change:activePhotoIndex', (m, activePhotoIndex) => bigPhoto.showPhoto(activePhotoIndex));
        this.listenTo(bigPhoto, 'gesture:collapse', (bigPhoto, startZoom, minZoom, maxZoom) => {
            if(maxZoom <= this.viewModel.get('zoomedTreshold')) {
                this.showGrid();
            }
        });
        this.listenTo(bigPhoto, 'change:activePhotoZoom', (bigPhoto, bigPhotoZoom) => this.viewModel.set({ bigPhotoZoom }));
        this.bigPhoto.show(bigPhoto);

        //TODO: remove viewModel passsing and refactor to events
        let slider = new PhotoSlider({ collection: this.collection, viewModel: this.viewModel });
        this.slider.show(slider);

        let grid = new GridView({ collection: this.collection });
        this.viewModel.set('editMode', grid.getEditMode());
        grid.listenTo(this.viewModel, 'change:editMode', (m, editMode) => grid.setEditMode(editMode));
        this.listenTo(grid, 'photo:click', (grid, m) => this.showPhotoWithId(m.id));
        this.listenTo(grid, 'childview:gesture:expand', childview => this.showPhotoWithId(childview.model.id));
        this.grid.show(grid);
    },

    onTouchmove(e) {
        if(this.viewModel.get('isZoomed')) e.preventDefault();
    },

    isShowingGrid() {
        return this.viewModel.get('showGrid');
    },

    isGridMode() {
        return this.viewModel.get('gridMode')
    },

    showGrid() {
        this.viewModel.set('gridMode', true);
    },

    showPhoto(index) {
        index = _.isUndefined(index) ? this.viewModel.get('activePhotoIndex') : Number(index);
        if(index >= 0 && index < this.collection.length) {
            this.viewModel.set({
                gridMode: false,
                activePhotoIndex: index
            });
        }
    },

    showPhotoWithId(id) {
        let m = this.collection.get(id);
        if(m) {
            this.showPhoto(this.collection.indexOf(m));
        }
    },

    setEditMode(mode) {
        this.viewModel.set('editMode', mode);
        if(mode) {
            this.showGrid();
        }
    },

    getEditMode() {
        return this.viewModel.get('editMode');
    },

    selectAll() {
        this.grid.currentView && this.grid.currentView.selectAll();
    },

    clearSelection() {
        this.grid.currentView && this.grid.currentView.clearSelection();
    },

    getSelection() {
        return this.grid.currentView && this.grid.currentView.getSelection() || [];
    },
});


export default Gallery;
