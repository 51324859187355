import PlatformFeature from 'utils/PlatformFeature';

class ScreenSleep extends PlatformFeature {
    constructor(options) {
        super(options);
    }

    async initialize() {
///////////////////////
////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
/////////////////
        this.initializePromise = Promise.reject(ScreenSleep.Unsupported);
//////////////////

        return this.initializePromise
            .then(plugin => {
                this.ready = true;
                this.isScreenAlwaysOn = false;
                return plugin
            });
    }

    async keepScreenAlwaysOn(value) {
        return this.ensureInitialized()
            .then(plugin =>
                new Promise((resolve, reject) => {
                    if (value) {
                        plugin.keepAwake(resolve, reject)
                    } else if (this.isScreenAlwaysOn) {
                        plugin.allowSleepAgain(resolve, reject)
                    } else {
                        resolve();
                    }
                })
            )
            .then(() => this.isScreenAlwaysOn = value)
            .catch(e => {
                if (e != ScreenSleep.Unsupported) {
                    throw e;
                }
            });
    }
}

export default new ScreenSleep();