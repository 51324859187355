import { ItemView } from '@b2cmessenger/backbone';
import BroadcastModel from 'models/BroadcastModel';
import ViewWithPrettyColor from 'traits/ViewWithPrettyColor';
import escapeHtml from 'utils/escapeHtml';

import template from './BroadcastItem.jade';
import './BroadcastItem.scss';

const BroadcastItem = ItemView.extend({
    tagName: 'tr',
    template,
    className: 'widget broadcast-item',

    ui: {
        date: '[data-js-date]',
        createdAt: '[data-js-created-at]',
        updatedAt: '[data-js-updated-at]',
        status: '[data-js-status]',
        audienceCount: '[data-js-audience-count]',
        recipientCount: '[data-js-recipient-count]',
        failCount: '[data-js-fail-count]',
    },

    events: {
        'click'() {
            if (this.$el.hasClass('expanded')) {
                this.$el.removeClass('expanded');
            } else {
                this.$el.addClass('expanded');
            }
        }
    },

    computeds: {
        isQueued: {
            deps: ['status'],
            get: val => val == BroadcastModel.Status.Queued
        },
        isInProgress: {
            deps: ['status'],
            get: val => val == BroadcastModel.Status.InProgress
        },
        isDone: {
            deps: ['status'],
            get: val => val == BroadcastModel.Status.Done
        },
        isError: {
            deps: ['status'],
            get: val => val == BroadcastModel.Status.Error
        },
        isWarning: {
            deps: ['status'],
            get: val => val == BroadcastModel.Status.Warning
        },
        isSkipped: {
            deps: ['status'],
            get: val => val == BroadcastModel.Status.Skipped
        },
        description: {
            deps: ['error'],
            get: error =>
                error && error.message || '',
        },
        statusHtml: {
            deps: ['status', 'description'],
            get: (status, description) => {
                let statusStr;
                switch (status) {
                    case BroadcastModel.Status.Queued:
                        statusStr = 'In Queue';
                        break;
                    case BroadcastModel.Status.InProgress:
                        statusStr = 'Sending...';
                        break;
                    case BroadcastModel.Status.Done:
                        statusStr = 'Done';
                        break;
                    case BroadcastModel.Status.Error:
                        statusStr = 'Error';
                        break;
                    case BroadcastModel.Status.Warning:
                        statusStr = 'Warning';
                        break;
                    case BroadcastModel.Status.Skipped:
                        statusStr = 'Skipped';
                        break;
                    default:
                        statusStr = 'Unknown';
                }

                return `${statusStr}<span class="description">${escapeHtml(description)}</span>`;
            }
        },
        audienceStr: {
            deps: ['audience_count'],
            get: audience_count => audience_count
        },
        recipientStr: {
            deps: ['recipient_count'],
            get: recipient_count => recipient_count || null
        },
        failStr: {
            deps: ['fail_count'],
            get: fail_count => fail_count || null
        }
    },

    bindingFilters: {
        prettyDate: val => {
            return val && val.toLocaleDateString && val.toLocaleTimeString && (
                val.toLocaleDateString(undefined, {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                }) + ' ' +
                val.toLocaleTimeString(undefined, {
                    hour: '2-digit',
                    minute: '2-digit',
                })
            ) || '';
        },
    },

    bindings: {
        ':el': 'attr:{"data-id":id},classes:{queued:isQueued,"in-progress":isInProgress,done:isDone,error:isError}',
        '@ui.createdAt': 'text:prettyDate(createdAt)',
        '@ui.updatedAt': 'text:prettyDate(updatedAt)',
        '@ui.status': 'html:statusHtml,classes:{queued:isQueued,"in-progress":isInProgress,done:isDone,error:isError,warning:isWarning,skipped:isSkipped}',
        '@ui.audienceCount': 'text:audienceStr',
        '@ui.recipientCount': 'text:recipientStr',
        '@ui.failCount': 'text:failStr'
    },

    serializeModel(model) {
        return model.toJSON({ computed: true });
    },
});

export default BroadcastItem;
