export const UPSERT_CART_INTO_CARTS = '[Menu] Upsert cart into carts';
export const REMOVE_CART_FROM_CARTS = '[Menu] Remove from carts';
export const RESET_CARTS = '[Menu] Reset carts';

export const upsertCartIntoCarts = (cart) => ({
    type: UPSERT_CART_INTO_CARTS,
    payload: {
        cart
    }
});

export const removeCartFromCarts = (cart) => ({
    type: REMOVE_CART_FROM_CARTS,
    payload: {
        cart
    }
});

export const resetCarts = (carts = []) => ({
    type: RESET_CARTS,
    payload: {
        carts
    }
});