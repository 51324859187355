import settings from 'settings';

/**
 * Created by Alex on 10.04.2016.
 */

var B2CLoyaltyCard  = {};

/**
 *
 * @param card
 * @param card.id - integer {not blank if update} {length: {max: 10}} {>=0} Идентификатор карты лояльности внтури системы B2C Messenger
 * @param card.brand_id - integer {not blank}  {length: {max: 10}} {>=0} Идентификатор бренда
 * @param card.number - string {length: {max: 50}} {>=0} Номер карты
 * @param card.LoyaltyCard[__photo_file__] - file card photo
 * @param onSuccess
 * @param onError
 */
B2CLoyaltyCard.server_api_save = function(card, onSuccess, onError) {
    return Server.callServer({
        url: settings.host + settings.serv_loyaltycard.save,
        type: "POST",
        cache: false,
        contentType: false,
        processData: false,
        data: card,
        success : onSuccess,
        error : onError
    });
};

/**
 * Поиск по картам лояльности
 * @param filter
 * @param filter.brand_id - integer {default: 0} {length: {max: 10}} {>=0}  Идентификатор бренда
 * @param filter.user_id - integer {length: {max: 10}} {>=0} Идентификатор пользователя-владельца, если не задан, то по текущему пользователю
 * @param filter.updated_after - datetime {format yyyy-MM-dd HH:mm:ss Z} Дата и время изменения карты
 * @param onSuccess
 * @param onError
 */
B2CLoyaltyCard.server_api_search = function(filter, onSuccess, onError) {
    Server.callServer({
        url: settings.host + settings.serv_loyaltycard.search,
        type: "POST",
        data: filter,
        success : onSuccess,
        error : onError
    });
};

B2CLoyaltyCard.server_api_delete = function(card_id, onSuccess, onError) {
    Server.callServer({
        url: settings.host + settings.serv_loyaltycard.delete,
        type: "POST",
        data: {id : card_id},
        success : onSuccess,
        error : onError
    });
};

B2CLoyaltyCard.server_api_business_delete = function(card_id, onSuccess, onError) {
    Server.callServer({
        url: settings.host + settings.serv_loyaltycard.business.base + '/' + card_id,
        type: "DELETE",
        success : onSuccess,
        error : onError
    });
};

B2CLoyaltyCard.server_api_businessvalidate = function(card_id, status, comment, onSuccess, onError) {
    Server.callServer({
        url: settings.host + settings.serv_loyaltycard.businessvalidate,
        type: "POST",
        data: {
            id: card_id,
            status: status,
            comment: comment,
        },
        success : onSuccess,
        error : onError
    });
};

B2CLoyaltyCard.format_card_to_user_view = function(card_number) {
    card_number = card_number.replace(/ |-/g, '');
    var result = '';
    for (var i=0,g=0; i<card_number.length; i++) {
        result += card_number[i];
        if (g == 3 && i<card_number.length-1) {
            result += ' ';
            g = 0;
        } else {
            g++;
        }
    }
    return result;
};

B2CLoyaltyCard.format_card_num_to_int_form = function(card_number) {
    return card_number && card_number.replace && card_number.replace(/ |-/g, '');
};

B2CLoyaltyCard.generate_barcode_url = function(num) {
    return 'https://www.barcodesinc.com/generator/image.php?code='+num+'&style=68&type=C128B&width=550&height=300&xres=3&font=5';
},

B2CLoyaltyCard.const = {};

B2CLoyaltyCard.const.status = {}; // статус объекта
/*B2CEvidence.const.status.draft = 0; // черновик*/
B2CLoyaltyCard.const.status.active = 1; // объект виден бизнесу и не отклонен
B2CLoyaltyCard.const.status.approved = 2; // объект был подтвержден бизнесом
B2CLoyaltyCard.const.status.discard = -1; // объект был отклонен бизнесом

B2CLoyaltyCard.const.need_brands = {}; // Нужно ли в ответ добавлять массив brands, который содержит информацию о брендах связанных к картам лояльности из результата
B2CLoyaltyCard.const.need_brands.no = 0; // нет
B2CLoyaltyCard.const.need_brands.yes = 1; // да

B2CLoyaltyCard.const.need_users = {}; // Нужно ли в ответ добавлять массив users, который содержит публичные профили представителей безнеса, валидировавших какую-либо из карт лояльности из результата
B2CLoyaltyCard.const.need_users.no = 0; // нет
B2CLoyaltyCard.const.need_users.yes = 1; // да

B2CLoyaltyCard.const.default_card_photo = 'common_res/icon/other/card-default.png';

/**
 * Temporary workaround to leave compatibility with www/*.js files which use this one as global variable
 * @todo Remove it when LoyaltyCard will be fully moved to src folder
 */
window.B2CLoyaltyCard = B2CLoyaltyCard;

export default B2CLoyaltyCard;
