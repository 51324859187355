import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import './MenuOrderConfirm.scss';

import { WindowWrapper } from 'components/window/Window';
import withStore from 'components/withStore';
import { getMenuCartComment, getMenuCartTotalPrice, getMenuSelectedTableId } from 'store/menu/cart/selectors';
import ReactModalWindow from 'windows/ReactModalWindow';
import MenuOrderConfirm from 'components/menu/MenuOrderConfirm/MenuOrderConfirm';
import { getMenuCurrency, getMenuPlaceId } from 'store/menu/selectors';
import { changeCartComment, setSelectedTableId } from 'store/menu/cart/actions';
import { getMenuPermissionsInCurrentPlace, getMenuTablesAsArray } from 'store/menu/sections/selectors';
import { upsertTables } from 'store/menu/sections/actions';
import * as TableAPI from 'utils/api/tables';
import Spinner from 'components/UI/Spinner/Spinner';
import UserSearchWindow from 'windows/UserSearch/UserSearch';
import { Model } from '@b2cmessenger/backbone';
import qrCodeScanner from 'utils/qrCodeScanner';
import { showError } from 'windows/Modal/Info';
import { uploadOrder } from 'store/menu/orders/actions';
import MenuSelectOrderWindow from 'components/window/MenuSelectOrder/MenuSelectOrder';
import { getLoginedUser } from 'store/app/selectors';
import { OrderStatus } from 'components/menu/MenuPlaceOrder/MenuPlaceOrder';
import * as MenuAPI from 'utils/api/menu';

const MenuOrderConfirmContainer = (props) => {
    const {
        totalPrice,
        currency,
        placeId,
        closeWindow,
        uploadOrder,
        comment: commentFromStore,
        changeCartComment,
        tables,
        upsertTables,
        selectedUser: selectedUserFromProp,
        selectedTableId,
        setSelectedTableId,
        menuPermissions,
        orderId,
        orderNumber,
        loginedUser,
    } = props;
    const { isWaiter, isOrderManager } = menuPermissions;
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState(commentFromStore);
    const [selectedUser, setSelectedUser] = useState(selectedUserFromProp);
    const [selectedExistingOrder, setSelectedExistingOrder] = useState(null);

    const onCommentBlur = useCallback(() => {
        if (comment !== commentFromStore) {
            changeCartComment(comment);
        }
    }, [comment, commentFromStore]);

    const sendOrder = useCallback(() => {
        const orderData = { tableId: selectedTableId, comment };

        if (isWaiter || isOrderManager) {
            orderData.user = selectedUser;
        }

        if (orderId) {
            orderData.id = orderId;
            orderData.number = orderNumber;
        } else if (selectedExistingOrder !== null) {
            orderData.id = selectedExistingOrder.id;
            orderData.number = selectedExistingOrder.order_number;
        }

        uploadOrder(placeId, orderData);
        closeWindow();
    }, [
        comment,
        placeId,
        selectedTableId,
        uploadOrder,
        closeWindow,
        selectedUser,
        isWaiter,
        selectedExistingOrder,
        orderId,
    ]);

    const updateTables = useCallback(async () => {
        setLoading(true);

        try {
            const tables = await TableAPI.getTables({ placeId });
            upsertTables(tables);
        } catch (e) {
            showError(e);
            closeWindow();
        } finally {
            setLoading(false);
        }
    }, [upsertTables, placeId]);

    const selectTableUsingQrScanner = useCallback(async () => {
        try {
            const qrCodeModel = await qrCodeScanner();

            if (qrCodeModel) {
                const tableFromQr = qrCodeModel.get('deepLinkMenuTable') || {};

                if (placeId === tableFromQr.placeId) {
                    const table = tables.find((t) => Number(t.id) === Number(tableFromQr.tableId));
                    const tableId = table && table.id;

                    if (tableId) {
                        setSelectedTableId(tableId);
                    }
                }
            }
        } catch (e) {
            if (e) {
/////////////////////////////
/////////////////////////////
/////////////////////////
                showError(_.isString(e) ? e : e.message);
//////////////////////////
            }
        }
    }, [placeId, tables]);

    useEffect(() => {
        if (!tables) {
            updateTables();
        }
    }, [tables, updateTables]);

    const [existOrders, setExistOrders] = useState([]);
    const [loadingExistOrders, setLoadingExistOrders] = useState(false);
    const isEditingOrder = useMemo(() => Boolean(orderId), [orderId]);

    useEffect(() => {
        if (isEditingOrder) {
            return;
        }

        const updateOrders = async () => {
            if (isWaiter && !selectedUser && !selectedTableId) {
                setExistOrders([]);
                return;
            }

            setLoadingExistOrders(true);

            try {
                let data;
                let loadedOrders = [];
                const options = {
                    placeId,
                    includeUser: true,
                    includeWaiter: true,
                };

                if (selectedTableId) {
                    options.tableId = Number(selectedTableId);
                }

                if (isWaiter) {
                    options.userId = (selectedUser && selectedUser.id) || 0;

                    data = await MenuAPI.loadOrders(options);
                    loadedOrders = data.orders;
                } else {
                    options.status = [OrderStatus.Created];

                    data = await MenuAPI.loadUserOrders(options);
                    loadedOrders = data.orders.map((o) => {
                        return loginedUser && Number(loginedUser.id) === Number(o.user_id)
                            ? {
                                ...o,
                                user: loginedUser,
                            }
                            : o;
                    });
                }

                setExistOrders(loadedOrders);
            } catch (e) {
                showError(e);
            } finally {
                setLoadingExistOrders(false);
            }
        };

        updateOrders();
    }, [selectedTableId, placeId, selectedUser, isWaiter, loginedUser, isEditingOrder]);

    useEffect(() => {
        setSelectedExistingOrder(null);
    }, [selectedTableId]);

    const displayAddToExistOrder = !isEditingOrder && existOrders && existOrders.length > 0;
    const onRemoveOrder = useCallback(() => setSelectedExistingOrder(null), []);
    const onAddToExistOrder = useCallback(async () => {
        try {
            const selectedOrderIds = selectedExistingOrder !== null ? [selectedExistingOrder.id] : [];
            const result = await new MenuSelectOrderWindow({ selectedOrderIds, orders: existOrders }).show();

            if (result) {
                setSelectedExistingOrder(result);

                if (result.user && isWaiter) {
                    setSelectedUser(result.user);
                }
            }
        } catch (e) {
            showError(e);
        }
    }, [selectedExistingOrder, existOrders]);

    const onSelectUser = useCallback(async () => {
        try {
            const result = await new UserSearchWindow().show();

            if (result) {
                const user = result instanceof Model ? result.attributes : result;
                setSelectedUser(user);
            }
        } catch (e) {
            showError(e);
        }
    }, []);
    const onRemoveUser = useCallback(() => setSelectedUser(null), []);

    const title = orderId ? `Order #${orderNumber} change confirmation` : 'Confirmation order';
    const disabled = loading || !tables || !selectedTableId;

    const content = loading ? (
        <Spinner />
    ) : (
        <MenuOrderConfirm
            currency={currency}
            totalPrice={totalPrice}
            tables={tables}
            isWaiter={isWaiter}
            isOrderManager={isOrderManager}
            onCommentBlur={onCommentBlur}
            selectedTableId={selectedTableId}
            setSelectedTableId={setSelectedTableId}
            selectedUser={selectedUser}
            onSelectUser={onSelectUser}
            onRemoveUser={onRemoveUser}
            comment={comment}
            setComment={setComment}
            selectTableUsingQrScanner={selectTableUsingQrScanner}
            loadingExistOrders={loadingExistOrders}
            displayAddToExistOrder={displayAddToExistOrder}
            onAddToExistOrder={onAddToExistOrder}
            selectedExistingOrder={selectedExistingOrder}
            onRemoveOrder={onRemoveOrder}
            isRemovableUser={!selectedUserFromProp}
        />
    );

    return (
        <WindowWrapper
            closeWindow={props.closeWindow}
            title={title}
            disabled={disabled}
            isModalWindow
            onClickContinue={sendOrder}
            continueButtonText="CONFIRM"
            continueButtonIcon={loadingExistOrders ? 'ion-ios-refresh-outline refresh animate-spin' : ''}
        >
            {content}
        </WindowWrapper>
    );
};

const ConnectedMenuCartItemCustomize = withStore(
    connect(
        (state) => ({
            totalPrice: getMenuCartTotalPrice(state),
            currency: getMenuCurrency(state),
            placeId: getMenuPlaceId(state),
            comment: getMenuCartComment(state),
            tables: getMenuTablesAsArray(state),
            selectedTableId: getMenuSelectedTableId(state),
            menuPermissions: getMenuPermissionsInCurrentPlace(state),
            loginedUser: getLoginedUser(state),
        }),
        {
            uploadOrder,
            changeCartComment,
            upsertTables,
            setSelectedTableId,
        }
    )(MenuOrderConfirmContainer)
);

const MenuOrderConfirmWindow = ReactModalWindow.extend({
    constructor: function MenuOrderConfirmWindow(props) {
        const options = {
            props,
            component: ConnectedMenuCartItemCustomize,
            intent: ReactModalWindow.Intent.Active,
            name: 'MenuOrderConfirmContainer',
        };

        return ReactModalWindow.apply(this, [options]);
    },
});

export default MenuOrderConfirmWindow;
