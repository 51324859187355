export async function callServer(options, mapToReplace, params = {}) {
    console.log('[utility] CALL_SERVER');
    if (mapToReplace) {
        let { url } = options;

        for (const key in mapToReplace) {
            url = url.replace(`\${${key}}`, mapToReplace[key]);
        }

        options = { ...options, url };
    }

    return call(options, params);
}

export async function call(options, params = {}) {
    return new Promise((resolve, reject) => {
        Server.callServerWithParameters(_.extend({
            success: resolve,
            error: (jqXHR, textStatus, errorThrown) => {
                reject(new AjaxError(jqXHR, textStatus, errorThrown));
            }
        }, options), params);
    });
}

export async function extendedCall(options, params = {}) {
    return new Promise((resolve, reject) => {
        Server.callServerWithParameters(_.extend({
            success: (data, resp, xhr) => {
                resolve([data, resp, xhr]);
            },
            error: (jqXHR, textStatus, errorThrown) => {
                reject(new AjaxError(jqXHR, textStatus, errorThrown));
            }
        }, options), params);
    });
}

export function getPaginationHeadersFromXhr(xhr) {
    return {
        pageSize: Number(xhr.getResponseHeader('X-Pagination-Per-Page')),
        page: Number(xhr.getResponseHeader('X-Pagination-Current-Page')),
        pageCount: Number(xhr.getResponseHeader('X-Pagination-Page-Count')),
        totalCount: Number(xhr.getResponseHeader('X-Pagination-Total-Count'))
    };
}
