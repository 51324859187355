import React from 'react';
import './Select.scss';

export const SelectGroup = ({ caption, info, className, children, disabled }) => {
    const classNameString = [className || 'select-group', disabled && 'disabled'].filter(Boolean).join();
    return (
        <div className={classNameString}>
            {caption && <span className="label">{caption}</span>}
            {children}
            {info && <span className="info">{info}</span>}
        </div>
    );
};

const SelectOptionsList = ({ options, defaultValue }) => {
    const optionsList = options.map(({ value: val, name: optName, label }) => {
        const name = optName || label;
        const key = val || name;
        return (
            <option key={key} value={val}>
                {name}
            </option>
        );
    });

    if (defaultValue) {
        optionsList.unshift(
            <option key={defaultValue} disabled>
                {defaultValue}
            </option>
        );
    }

    return optionsList;
};

const SelectOptionsListWithGrouping = ({ options, defaultValue }) => {
    const optGroups = [...new Set(options.map((item) => item.optgroup))].filter(Boolean);
    const optionsWithoutGroups = options.filter((o) => !o.optgroup);

    return (
        <>
            {Boolean(defaultValue) && (
                <option key={defaultValue} disabled>
                    {defaultValue}
                </option>
            )}
            {optionsWithoutGroups && optionsWithoutGroups.length > 0 && (
                <SelectOptionsList options={optionsWithoutGroups} />
            )}
            {optGroups.map((label, i) => {
                const key = label || i;
                return (
                    <optgroup key={key} label={label}>
                        <SelectOptionsList options={options.filter((o) => o.optgroup === label)} />
                    </optgroup>
                );
            })}
        </>
    );
};

const Select = ({
    value,
    onChange = _.noop,
    options,
    disabled = null,
    defaultValue,
    multiple = false,
    groupByOptGroup = false,
}) => {
    const optionsList = groupByOptGroup ? (
        <SelectOptionsListWithGrouping options={options} defaultValue={defaultValue} />
    ) : (
        <SelectOptionsList options={options} defaultValue={defaultValue} />
    );

    return (
        <select
            className="select"
            data-role="none"
            value={value}
            disabled={disabled}
            defaultValue={defaultValue}
            multiple={multiple}
            onChange={(e) => onChange(e.target.value)}
        >
            {optionsList}
        </select>
    );
};

export default Select;
