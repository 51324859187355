/**
 * Created by deanar on 14/03/2017.
 */

class UserDeviceInfo {
    getInfo() {
        const location = geo && geo.getCurrentPosition(),
              info = _.defaults(_.create(null, {}), {
                  frontend_version: window.app && window.app.version,
                  latitude: location && location.lt,
                  longitude: location && location.lg
              });

        if (window.device) {
            _.extend(info, {
                device_platform: window.device.platform,
                device_manufacturer: window.device.manufacturer,
                device_model: window.device.model,
                device_version: window.device.version,
                device_serial: window.device.serial,
                device_uuid: window.device.uuid,
            });
        }

        return info;
    }
};
export default UserDeviceInfo;
export const userDeviceInfo = new UserDeviceInfo;
