import { Optional, Required, ItemView, ViewModel } from '@b2cmessenger/backbone';
import Modal from 'windows/Modal/Modal';
import FooterView from 'widgets/Footer/Footer';
import template from './UserEmailSearchRegisterModal.jade';
import './UserEmailSearchModal.scss';
import UserSearchHelpers from "windows/Modal/UserSearchModal/UserSearchHelpers";

const UserEmailSearchRegisterModal = Modal.extend({
    options: {
        email: Required,
        placeId: Required,
        parentViewModel: Optional
    },

    windowName: "user-email-search-register-modal-window",
    className: "modal-window user-email-search-register-modal-window green-border",

    initialize() {
        this.viewModel = new ViewModel({
            parentViewModel: this.options.parentViewModel || null,
            vm_email: this.options.email,
            vm_placeId: this.options.placeId || null,
            vm_firstName: '',
            vm_lastName: ''
        });

        this.kioskHelpers = new UserSearchHelpers({
            viewModel: this.viewModel
        });

        return Modal.prototype.initialize.apply(this, arguments);
    },

    onRender() {
        const contentView = new UserEmailSearchRegisterContent({
            viewModel: this.viewModel
        });
        this.listenTo(contentView, 'continue', () => this._onContinue());
        this.content.show(contentView);

        const footerView = new FooterView({
            buttons: [{
                id: "close",
                text: "RETYPE E-MAIL",
                icn: "empty"
            }, {
                id: "continue",
                text: "REGISTER",
                icn: "empty"
            }]
        });
        this.listenTo(footerView, 'close:click', view => this.close(false));
        this.listenTo(footerView, 'continue:click', view => this._onContinue());
        this.footer.show(footerView);
    },

    cancel() {
        this.close(false);
    },

    _onContinue() {
        const userInfo = {
            email: this.viewModel.get('vm_email'),
            place_id: this.viewModel.get('vm_placeId')
        };

        if (this.viewModel.get('vm_firstName')) {
            userInfo.firstname = this.viewModel.get('vm_firstName');
        }

        if (this.viewModel.get('vm_lastName')) {
            userInfo.lastname = this.viewModel.get('vm_lastName');
        }

        this.kioskHelpers.inviteUser(userInfo)
            .then((data) => {
                this.close(data);
            })
            .catch(error => {
                this.showError(...error);
                this.close(false);
            });
    }
});

export default UserEmailSearchRegisterModal;

@ItemView.options({
    collection: Optional,
})
@ItemView.properties({
    template: template,
    tagName: 'form',
    className: "widget user-email-search-content-widget",

    ui: {
        email: '[data-js-email]',
        firstName: '[data-js-first-name]',
        lastName: '[data-js-last-name]',
    },

    bindings: {
        '@ui.email': 'value:vm_email',
        '@ui.firstName': 'value:vm_firstName',
        '@ui.lastName': 'value:vm_lastName',
    },

    events: {
        'submit'(e) {
            this.trigger('continue');
            e.preventDefault();
        }
    }
})
class UserEmailSearchRegisterContent extends ItemView {
}
