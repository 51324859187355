import _ from 'underscore';
import { Model } from '@b2cmessenger/backbone';

let EvidencePhotoModel = Model.extend({
    defaults: {},
    computeds: {
        url: {
            deps: ['photo', 'thumb'],
            get: (photo, thumb) => thumb || photo || ""
        },
    },

    sync(method, model, options) {
        _.defaults(options || (options = {}));

        var success = options.success;
        options.success = function (data) {
            model.unset('file');
            if (success) success.call(options.context, data);
        };

        var error = options.error;
        options.error = function (xhr, textStatus, errorThrown) {
            options.textStatus = textStatus;
            options.errorThrown = errorThrown;
            if (error) error.call(options.context, xhr, textStatus, errorThrown);
        };

        if (method == "create") {
            let data = new FormData();
            const file = model.get('file');
            data.append('id', model.get('evidence_id'));

            if (file instanceof File) {
                data.append('EvidencePhoto[__image_file__]', file);
            } else if (file instanceof Blob) {
                data.append('EvidencePhoto[__image_file__]', file, "file.jpg");
            } else if (_.isNumber(model.get('file'))) {
                data.append('image', file);
            }

            let xhr = B2CEvidence.server_api_photo_add(data, options.success, options.error);
            model.trigger('request', model, xhr, options);
            return xhr;
        }

        if (method == "delete") {
            if (model.get('evidence_id') && model.id)
                return B2CEvidence.server_api_photo_delete(model.get('evidence_id'), [model.id], options.success, options.error)
        }
    }
});

export default EvidencePhotoModel;
