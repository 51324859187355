import { Model, CollectionView } from '@b2cmessenger/backbone';
import justifiedLayout from 'justified-layout';

import PhotoWidget from 'widgets/Photo/Photo';

import './Grid.scss';

const GridItem = PhotoWidget.extend({
    className: () =>_.result(PhotoWidget.prototype, 'className', '') + ' photo-grid-item-widget',

    bindings() {
        const bindings = _.isFunction(PhotoWidget.prototype.bindings)
            ? PhotoWidget.prototype.bindings.apply(this)
            : PhotoWidget.prototype.bindings;

        return _.extend({}, bindings, {
            ':el':
                'css:{transform:halfScaleAndRotate(rubberZoom,rotation),width:select(calculatedWidth,calculatedWidth,"")},' +
                'classes:{selected:selected}'
        });
    },

    initialize() {
        let ret = PhotoWidget.prototype.initialize.apply(this, arguments);
        this.viewModel.set({
            calculatedWidth: false,
            selected: false,
        });

        this.listenTo(this.viewModel, 'change:selected', (m, selected) => this.trigger('change:selected', this, selected));

        return ret;
    },

    triggers: {
        'click': 'click'
    },

    setWidth(width) {
        this.viewModel.set({ calculatedWidth: width });
    },

    setSelected(selected) {
        this.viewModel.set({ selected: !!selected });
    },

    isSelected() {
        return this.viewModel.get('selected');
    }
});

let GridView = CollectionView.extend({
    className: 'widget photo-grid-widget',

    childView: GridItem,
    childViewOptions: {
        minZoom: 1,
        maxZoom: 1,
        rubberZoomCoef: 0.3,
        expandZoomTreshold: 1.4,
        collapseZoomTreshold: 0.6
    },

    bindings: {
        ':el': 'classes:{"justified-layout":justifiedGeometry,single:isSingle,big:isBigSize,medium:isMediumSize,small:isSmallSize,'+
            '"edit-mode":editMode}'
    },

    initialize() {
        this.viewModel = new (Model.extend({
            defaults: {
                selection: [],
            },
            computeds: {
                size: {
                    deps: ['count'],
                    get: count => count > 0 ? (
                            count == 1 ? 'single' :
                            count < 4 ? 'big' :
                            count < 10 ? 'medium' :
                                        'small'
                        ) : 'none'
                },
                isSingle: {
                    deps: ['count'],
                    get: count => count == 1
                },
                isBigSize: {
                    deps: ['count'],
                    get: count => count >= 2 && count <= 3
                },
                isMediumSize: {
                    deps: ['count'],
                    get: count => count >= 4 && count <= 9
                },
                isSmallSize: {
                    deps: ['count'],
                    get: count => count >= 10
                },
                justifiedGeometry: {
                    deps: ['allPhotosHasAR', 'photoARs', 'size'],
                    get: (allPhotosHasAR, photoARs, size) => {
                        switch(size) {
                            case 'small':
                                return justifiedLayout(this.collection.pluck('photoAR'), {
                                    containerWidth: 10000,
                                    containerPadding: {
                                        top: 0, left: 0,
                                        right: 7/368 * 10000,
                                        bottom: 0
                                    },
                                    boxSpacing: {
                                        horizontal: 7/368 * 10000,
                                        vertical: 7/368 * 10000,
                                    },
                                    targetRowHeight: 80/368 * 10000
                                });
                            case 'medium':
                                return justifiedLayout(this.collection.pluck('photoAR'), {
                                    containerWidth: 10000,
                                    containerPadding: {
                                        top: 0, left: 0,
                                        right: 13/362 * 10000,
                                        bottom: 0
                                    },
                                    boxSpacing: {
                                        horizontal: 13/362 * 10000,
                                        vertical: 13/362 * 10000,
                                    },
                                    targetRowHeight: 150/362 * 10000,
                                    targetRowHeightTolerance: 0.29
                                });
                            case 'big':
                                return justifiedLayout(this.collection.pluck('photoAR'), {
                                    containerWidth: 10000,
                                    containerPadding: {
                                        top: 0, left: 0,
                                        right: 20/355 * 10000,
                                        bottom: 0
                                    },
                                    boxSpacing: {
                                        horizontal: 20/355 * 10000,
                                        vertical: 20/355 * 10000,
                                    },
                                    targetRowHeight: 200/355 * 10000,
                                    targetRowHeightTolerance: 0.35
                                });
                            case 'single':
                            default:
                                return false;
                        }
                    }
                }
            }
        }))({
            allPhotosHasAR: this.collection.every(m => m.get('photoAR')),
            photoARs: this.collection.pluck('photoAR'),
            count: this.collection.length,
            editMode: false
        });

        this.listenTo(this.collection, 'update reset change:photoAR', () => this.viewModel.set({
            allPhotosHasAR: this.collection.every(m => m.get('photoAR')),
            photoARs: this.collection.pluck('photoAR')
        }));

        this.listenTo(this.collection, 'update reset', () => this.viewModel.set('count', this.collection.length));

        this.listenTo(this.viewModel, 'change:justifiedGeometry', () => this._applyJustifiedGeometry());
        this.listenTo(this.viewModel, 'change:selection', (m, selection) => this.trigger('change:selection', this, selection));
    },

    onRender() {
        this._applyJustifiedGeometry();
    },

    onAddChild(childview) {
        childview.setSelected(false);
    },

    onBeforeRemoveChild(childview) {
        childview.setSelected(false);
    },

    _applyJustifiedGeometry() {
        let geometry = this.viewModel.get('justifiedGeometry')
        if(geometry && geometry.boxes) {
            this.collection.each((m, index) => {
                let box = geometry.boxes[index],
                    childview = this.children.findByModel(m);

                if(childview && box) {
                    childview.setWidth(box.width / 10000 * 100 + '%');
                }
            });
        } else {
            this.children.each(childview => childview.setWidth(false));
        }
    },

    onChildviewClick(childview) {
        if(this.viewModel.get('editMode')) {
            childview.setSelected(!childview.isSelected());
        } else {
            this.trigger('photo:click', this, childview.model);
        }
    },

    onChildviewChangeSelected(childview, v, selected) {
        this.viewModel.set('selection', this.children.reduce((arr, childview) => {
            if(childview.isSelected()) {
                arr.push(childview.model.id);
            }
            return arr;
        }, []));
    },

    selectAll() {
        this.children.each(childview => childview.setSelected(true));
    },

    clearSelection() {
        this.children.each(childview => childview.setSelected(false));

        //let selection = this.viewModel.get('selection');
        //if(selection.length) {
        //    this.viewModel.modifyArray('selection', 'splice', 0, selection.length);
        //}
    },

    getSelection() {
        return this.viewModel.get('selection');
    },

    setEditMode(mode) {
        this.viewModel.set('editMode', !!mode);
    },

    getEditMode() {
        return this.viewModel.get('editMode');
    }

});

export default GridView;
