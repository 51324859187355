import React from 'react';
import { MenuListType, byIdAndSortOrder } from 'components/menu/MenuList/MenuList';
import MenuSortableList, {
    MenuSortableListDragHandle,
    MenuSortableListItem,
    MenuSortaBleListItemDragClassName,
} from 'components/menu/MenuSortable/MenuSortableList';
import { MenuItemBase } from 'components/menu/MenuItem/MenuItem';
import { MenuHiddenVisibility } from 'components/menu/MenuVisibility/MenuVisibility';
import '../MenuCategories/MenuCategories.scss';
import { getAdditionItemPrice, getItemPrice } from 'store/menu/utils';

const MenuSortableItems = (props) => {
    const { items, onChangeOrder, dragPressDelay, ...restProps } = props;
    const itemList = items.map((e, index) => {
        const i = {
            ...e,
            price: props.additions ? getAdditionItemPrice(e) : getItemPrice(e),
        };

        return (
            <MenuSortableListItem
                className="menu-list-item-with-controls menu-sortable-list-item"
                key={byIdAndSortOrder(i)}
                index={index}
                left={<MenuSortableListDragHandle />}
                right={<MenuSortableListDragHandle />}
            >
                <MenuItemBase className="menu-list-item" {...i}>
                    {(i.is_hidden_forever || i.hidden_until) && <MenuHiddenVisibility />}
                </MenuItemBase>
            </MenuSortableListItem>
        );
    });

    return (
        <MenuSortableList
            onSortEnd={onChangeOrder}
            pressDelay={dragPressDelay}
            helperClass={MenuSortaBleListItemDragClassName}
            lockAxis="y"
            type={MenuListType.Items}
            {...restProps}
        >
            <div className="menu-sortable-items">{itemList}</div>
        </MenuSortableList>
    );
};

export default MenuSortableItems;
