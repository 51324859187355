import { Model, Backbone } from '@b2cmessenger/backbone';

import UserModel from 'models/UserModel';
import Window from 'windows/Window';
import MyIDWindow from 'windows/MyID/MyID';
import SettingsWindow from 'windows/Settings/Settings';
import DefaultWorkPlaceChooserWindow from 'windows/PlaceChooser/DefaultWorkPlaceChooser';
import EmployeesList from 'windows/Employees/Employees';
import GiftTemplatesWindow from 'windows/GiftTemplates/GiftTemplates';
import GiftsWindow from 'windows/Gifts/Gifts';
///////////////////
//////////////////////////////////////////////
/////////

import template from './LeftMenu.jade';
import './LeftMenu.scss';
import qrCodeScanner from 'utils/qrCodeScanner';
import notificationBadge from 'widgets/lite/notificationBadge/notificationBadge';
import NotificationModel from 'models/NotificationModel';
import PhoneChooser from 'windows/Phone/PhoneChooser';
import Notifications from '../Notifications/Notifications';
import ViewWithWindows from 'traits/ViewWithWindows';
import LoyaltyKioskEnterModal from 'windows/Modal/LoyaltyKiosk/LoyaltyKioskEnter';

let openInstance;

const withWindowsTrait = {
    Trait: ViewWithWindows,
    options: {
        windowMap: [
            {
                cls: MyIDWindow,
                trigger() {
                    this.ui.myId.click();
                }
            },
            {
                cls: SettingsWindow,
                trigger() {
                    this.ui.settings.click();
                }
            },
            {
                cls: DefaultWorkPlaceChooserWindow,
                trigger() {
                    this.ui.workPlaces.click();
                }
            },
            {
                cls: EmployeesList,
                trigger() {
                    this.ui.employees.click();
                }
            },
            {
                cls: GiftTemplatesWindow,
                trigger() {
                    this.ui.giftTemplates.click();
                }
            },
            {
                cls: GiftsWindow,
                trigger() {
                    this.ui.gifts.click();
                }
            },
            {
                cls: PhoneChooser,
                trigger() {
                    this.ui.myPhones.click();
                }
            }
        ],
    }
};
const PAGES_MAP = {
    workplace: 'workplace',
    search: 'places',
    findClient: '/client/search',
    loyaltyCards: 'loyalty_cards',
    tasks: 'tasks',
    myReservations: 'my_reservations',
    placeReservations: 'place_reservations',
    broadcasts: 'broadcasts',
    favorites: 'favorites'
};

const LeftMenuWindow = Window.extendWithTraits([withWindowsTrait], {
    options: {
        right: false,
        unclosed: true,
    },
    scope: 'global',

    setDisabled(value) {
        this.viewModel.set('disabled', !!value);
    },

    setSelectedLink() {
        const hash = window.location.hash;
        const key = _.findKey(PAGES_MAP, val => hash.match(val));
        let selectedButton = key ? key : 'search';

        $(this.ui.button).removeClass('selected');
        $(this.ui[selectedButton]).addClass('selected');
    },

    show(...args) {
        if (!app.isMobileView && openInstance) {
            return Promise.resolve();
        }

        this._ensureSingleInstance();
        this.setUserData();

        this.listenTo(LoginedUserHandler, 'change:user_def_work_place_id', () => {
            this.model.set('defaultWorkPlace', LoginedUserHandler.get_default_work_place())
        });

        return Window.prototype.show.call(this, ...args);
    },

    setUserData() {
        this.viewModel.set({
            right: this.options.right,
        });

        const loginedUser = LoginedUserHandler.getLoginedUser(),
            workPlaces = loginedUser && LoginedUserHandler.get_user_work_places(),
            defaultWorkPlace = LoginedUserHandler.get_default_work_place(),
            loggedIn = !!loginedUser,
            workPlaceRoles = LoginedUserHandler.get_work_places_roles();

        if (loginedUser) {
            this.model.set(loginedUser);
        }

        this.model.set({
            loggedIn,
            defaultWorkPlace,
            workPlaceRoles,
            isBusiness: workPlaces && workPlaces.length,
            multipleWordPlaces: workPlaces && workPlaces.length > 1,
            notificationsCount: notificationCollection.getNewVisibleNotificationsCount(),
            notificationsCountByCategories: notificationCollection.getNewVisibleNotificationsCountByCategories(),
            app: app
        });
    },

    refresh() {
        this.setUserData();
    },

    close(...args) {
        if (app.isMobileView) {
            this._clearThisInstance();
            return Window.prototype.close.call(this, ...args);
        } else {
            this.setSelectedLink();
            return Promise.resolve();
        }
    },

    windowName: 'left-menu-window',
    template: template,

    ui: {
        button: 'button',
        user: '[data-js-user]',
        header: '[data-js-header-top]',
        userAvatar: '[data-js-user-avatar]',
        editProfile: '[data-js-btn-edit-profile]',
        login: '[data-js-btn-login]',
        workplace: '[data-js-workplace]',
        defaultWork: '[data-js-default-work]',
        search: '[data-js-search]',
        notifications: '[data-js-notifications]',
        notificationsCount: '[data-js-notifications-count]',
        favorites: '[data-js-favorites]',
        findClient: '[data-js-find-client]',
        gifts: '[data-js-gifts]',
        myId: '[data-js-my-id]',
        myPhones: '[data-js-my-phones]',
        loyaltyCards: '[data-js-loyalty-cards]',
        tasks: '[data-js-tasks]',
        myReservations: '[data-js-my-reservations]',
        myOrders: '[data-js-my-orders]',
        placeReservations: '[data-js-place-reservations]',
        employees: '[data-js-employees]',
        broadcasts: '[data-js-broadcasts]',
        giftTemplates: '[data-js-gift-templates]',
        workPlaces: '[data-js-work-places]',
        kioskMode: '[data-js-kiosk-mode]',
        scanQr: '[data-js-scan-qr]',
        b2c: '[data-js-b2c]',
        settings: '[data-js-settings]',
        logout: '[data-js-logout]',
        debug: '[data-js-debug]',
    },

    bindingHandlers: {
        notificationBadge,
    },

    bindings: {
        ':el': 'classes:{right:right, overlayed:disabled}',
        '@ui.notifications': 'notificationBadge:notificationsCount',
        '@ui.workplace': 'notificationBadge:notificationsCountByCategories',
        '@ui.gifts': 'notificationBadge:notificationsCountByCategories',
        '@ui.tasks': 'notificationBadge:notificationsCountByCategories',
        '@ui.myReservations': 'notificationBadge:notificationsCountByCategories',
        '@ui.placeReservations': 'notificationBadge:notificationsCountByCategories',
        '@ui.workPlaces': 'classes:{hidden:not(multipleWordPlaces)}',
        '@ui.kioskMode': 'classes:{hidden:not(isKioskModeAvailable)}'
    },

    events: {
        'click @ui.login'() {
            this.close(app.controller.goToLoginPage());
        },
        'click @ui.editProfile'() {
            if (!this._ifNotLoggedInRedirectToLoginPage()) {
                this.close(app.controller.goToProfileEditorPage());
            }
        },
        'click @ui.workplace'() {
            if (!this._ifNotLoggedInRedirectToLoginPage()) {
                this.close(app.controller.goToWorkplacePage());
            }
        },
        'click @ui.defaultWork'() {
            this._ensureDefaultWorkplace()
                .then(defaultWorkPlace => {
                    this.close(app.controller.goToPlacePage({ place: defaultWorkPlace }));
                })
                .catch(e => {
                    if (e) {
////////////////////////////////////
//////////////////////////////////////////
////////////////////////////////
                        e && e.message && this.showError(e.message);
/////////////////////////////////
                    }
                });
        },
        'click @ui.search'() {
            this.close(app.controller.goToPlaceSearchPage({ favorites: false }));
        },
        'click @ui.notifications'() {
            if (!this._ifNotLoggedInRedirectToLoginPage()) {
                const window = new Notifications({ collection: app.controller.notificationCollection });
                window.show();

                this.close(window);
                //this.close(app.controller.showNotificationsWindow());
            }
        },
        'click @ui.favorites'() {
            if (!this._ifNotLoggedInRedirectToLoginPage()) {
                this.close(app.controller.goToPlaceSearchPage({ favorites: true, resetToDefaults: true }));
            }
        },
        'click @ui.findClient'() {
            this._ensureDefaultWorkplace()
                .then(defaultWorkPlace => this.close(app.controller.goToUserSearchPage()))
                .catch(e => {
                    if (e) {
////////////////////////////////////
//////////////////////////////////////////
////////////////////////////////
                        e && e.message && this.showError(e.message);
/////////////////////////////////
                    }
                });
        },
        'click @ui.gifts'() {
            if (!this._ifNotLoggedInRedirectToLoginPage()) {
                const window = new GiftsWindow();
                window.show();
                this.close(window);
            }
        },
        'click @ui.myId'() {
            if (!this._ifNotLoggedInRedirectToLoginPage()) {
                const window = new MyIDWindow();
                window.show();
                this.close(window);
            }
        },
        'click @ui.loyaltyCards'() {
            if (!this._ifNotLoggedInRedirectToLoginPage()) {
                this.close(app.controller.goToLoyaltyCardsPage());
            }
        },
        'click @ui.tasks'() {
            if (!this._ifNotLoggedInRedirectToLoginPage()) {
                this.close(app.controller.goToTasksPage({ userId: this.model.id }));
            }
        },
        'click @ui.myReservations'() {
            if (!this._ifNotLoggedInRedirectToLoginPage()) {
                this.close(app.controller.goToMyReservations());
            }
        },
        'click @ui.myOrders'() {
            if (!this._ifNotLoggedInRedirectToLoginPage()) {
                this.close(app.controller.goToOrdersPage());
            }
        },
        'click @ui.placeReservations'() {
            this._ensureDefaultWorkplace()
                .then(defaultWorkPlace => {
                    this.close(app.controller.goToPlaceReservations());
                })
                .catch(e => {
                    if (e) {
////////////////////////////////////
//////////////////////////////////////////
////////////////////////////////
                        e && e.message && this.showError(e.message);
/////////////////////////////////
                    }
                });
        },
        'click @ui.employees'() {
            this._ensureDefaultWorkplace()
                .then(defaultWorkPlace => {
                    const window = new EmployeesList({
                        place: defaultWorkPlace
                    });
                    window.show();

                    this.close(window);
                })
                .catch(e => {
                    if (e) {
////////////////////////////////////
//////////////////////////////////////////
////////////////////////////////
                        e && e.message && this.showError(e.message);
/////////////////////////////////
                    }
                });
        },
        'click @ui.broadcasts'() {
            this._ensureDefaultWorkplace()
                .then(defaultWorkPlace => {
                    this.close(app.controller.goToBroadcastCampaignsPage());
                })
                .catch(e => {
                    if (e) {
////////////////////////////////////
//////////////////////////////////////////
////////////////////////////////
                        e && e.message && this.showError(e.message);
/////////////////////////////////
                    }
                });
        },
        'click @ui.giftTemplates'() {
            this._ensureDefaultWorkplace()
                .then(defaultWorkPlace => {
                    const window = new GiftTemplatesWindow({
                        brandId: defaultWorkPlace.brand_id
                    });
                    window.show();
                    this.close(window);
                })
                .catch(e => {
                    if (e) {
////////////////////////////////////
//////////////////////////////////////////
////////////////////////////////
                        e && e.message && this.showError(e.message);
/////////////////////////////////
                    }
                });
        },
        'click @ui.myPhones'() {
            if (!this._ifNotLoggedInRedirectToLoginPage()) {
                const window = new PhoneChooser({
                    title: 'My phones',
                    isEditable: true
                });
                window.show();
                this.close(window);
            }
        },
        'click @ui.workPlaces'() {
            if (!this._ifNotLoggedInRedirectToLoginPage()) {
                const window = new DefaultWorkPlaceChooserWindow();
                window.show();
                this.close(window);
            }
        },
        'click @ui.kioskMode'() {
            if (!this._ifNotLoggedInRedirectToLoginPage()) {
                const window = new LoyaltyKioskEnterModal();

                window
                    .show()
                    .then(optionsOrNull => {
                        const defaultWorkPlace = this.model.get('defaultWorkPlace');

                        if (optionsOrNull && defaultWorkPlace) {
                            LoginedUserHandler.setLoyaltyKioskMode(defaultWorkPlace.id, optionsOrNull);
                            app.showLoading();
                            app.controller.restart()
                                .then(() => {
                                    if (app.isMobileView) {
                                        this.close();
                                    }

                                    app.controller.goToLoyaltyKioskPage();
                                    app.hideLoading();
                                })
                                .catch(e => {
                                    this.showError(e);
                                    app.hideLoading();
                                })
                        }
                    });

                this.close(window);
            }
        },
        'click @ui.scanQr'() {
            this._ensureDefaultWorkplace()
                .then(qrCodeScanner)
                .then((qrCodeModel) => {
                    this.close();

                    if (qrCodeModel) {
                        window.app.executeQrAction(qrCodeModel);
                    }
                })
                .catch(e => {
                    if (e) {
////////////////////////////////////
//////////////////////////////////////////
////////////////////////////////
                        e && e.message && this.showError(e.message);
/////////////////////////////////
                    }
                });
        },
        'click @ui.b2c'() {
            this.close(app.controller.goToPlacePage({ place: { id: 1 } }));
        },
        'click @ui.settings'() {
            if (!this._ifNotLoggedInRedirectToLoginPage()) {
                const window = new SettingsWindow();
                window.show();
                this.close(window);
            }
        },
        'click @ui.logout'() {
            const loginedUser = LoginedUserHandler.getLoginedUser(),
                email = loginedUser && loginedUser.email;

            LoginedUserHandler.logout();

            this.model.set({
                loggedIn: false,
                isBusiness: false,
                defaultWorkPlace: null
            });

            this.close(app.controller.restart());
        },
////////////////////////////
/////////////////////////////
/////////////////////////////////////////////////
//////////
//////////////////
        'touchstart': 'onTouchstart',
        'touchmove': 'onTouchmove',
        'touchend': 'onTouchend',
        'touchcancel': 'onTouchend',
    },

    initialize() {
        this.model = new UserModel({
            loggedIn: false,
            defaultWorkPlace: null,
            isBusiness: false,
            notificationsCount: 0,
            notificationsCountByCategories: {},
            isKioskModeAvailable: false,
            workPlaceRoles: null
        });

        this.model.addComputed('isKioskModeAvailable', {
            deps: ['loggedIn', 'defaultWorkPlace', 'workPlaceRoles'],
            get: (loggedIn, defaultWorkPlace, workPlaceRoles) => loggedIn && defaultWorkPlace &&
                workPlaceRoles && workPlaceRoles[defaultWorkPlace.id] &&
                workPlaceRoles[defaultWorkPlace.id].find(role => role === B2CEmployee.const.roles.GIFT_CREATOR)
        });

        this.model.listenTo(notificationCollection, 'change:newVisibleNotificationsCount', (c, count) => {
            this.model.set('notificationsCount', count)
        });

        this.model.listenTo(notificationCollection, 'change:newVisibleNotificationsCountByCategory', (c, categories) => {
            this.model.set('notificationsCountByCategories', _.clone(categories))
        });

        this.listenTo(
            this.model,
            'change:loggedIn change:isBusiness change:defaultWorkPlace ' +
            'change:avatarUrl change:name change:email change:isKioskModeAvailable',
            _.debounce(() => this.isRendered && this.render(), 100)
        );

        this.listenTo(app.controller, 'window:show window:close', () => {
            this.setDisabled(Window.getCloseableOpenWindows(w => w.windowName == 'modal-window').length > 0);
        });

        this.listenTo(app.controller, 'page:show', this.setSelectedLink.bind(this));
    },

    templateHelpers: {
        Category: NotificationModel.Category
    },

    onRender() {
        if (app.controller.windowStack.find(obj => obj.isModalWindow)) {
            this.$el.toggleClass('overlayed', true);
        }

        app.getHeaderPadding().then((padding) => {
            if (padding && this.ui.header && this.ui.header.css) {
                this.ui.header.css('padding-top', padding);
            }
        });

        this.setSelectedLink();
    },

    onShow(options) {
        _.extend(this.options, options);

        this.viewModel.set({
            right: this.options.right
        });

        _.defer(() => this.$el.addClass('show'));
    },

    onClose(data) {
        this.el.style.transform = null;
        this.el.style.transitionDuration = null;
        this.isClosing = true;
        this.$el.removeClass('show');
        this.$el.addClass('hide');

        return data;
    },

    onTouchstart(e) {
        if (this.isClosing) return;
        if (!this.touches) this.touches = _.create(null, {});

        this.el.style.transitionDuration = `0s`;

        //this.el.addEventListener("touchmove", this.prevent, false);

        for (let i = 0; i < e.originalEvent.changedTouches.length; i++) {
            let eventTouch = e.originalEvent.changedTouches.item(i);
            this.touches[eventTouch.identifier] = _.create(null, {
                startX: eventTouch.pageX,
                startY: eventTouch.pageY,
                startTime: _.now()
            });
        }
    },

    onTouchmove(e) {
        if (this.isClosing) return;
        let distanceX = 0, distanceY = 0;
        for (let i = 0; i < e.originalEvent.changedTouches.length; i++) {
            let eventTouch = e.originalEvent.changedTouches.item(i);
            let touch = this.touches[eventTouch.identifier];
            if (touch) {
                distanceX += eventTouch.pageX - touch.startX;
                distanceY += eventTouch.pageY - touch.startY;
            }
        }

        if (Math.abs(distanceX) > Math.abs(distanceY) * 2) {
            this.swiping = true;
        }

        if (this.swiping) {
            this.el.style.transform = `translateX(${distanceX}px)`;
            e.preventDefault();
        }
    },

    onTouchend(e) {
        if (this.isClosing) return;

        if (!e.originalEvent.touches.length) {
            if (this.swiping) {
                this.swiping = false;
                this.el.style.transitionDuration = null;

                let distance = 0;
                let ms = 0;
                for (let i = 0; i < e.originalEvent.changedTouches.length; i++) {
                    let eventTouch = e.originalEvent.changedTouches.item(i);
                    let touch = this.touches[eventTouch.identifier];
                    if (touch) {
                        distance += eventTouch.pageX - touch.startX;
                        ms = Math.max(ms, _.now() - touch.startTime);
                    }
                }

                if (Math.abs(distance) >= this.el.offsetWidth / 2) {
                    if (distance > 0) this.$el.addClass('right');
                    else this.$el.removeClass('right');

                    this.close();
                } else if (e.originalEvent.changedTouches.length == 1 && ms <= 250 && Math.abs(distance) >= 50) {
                    if (distance > 0) this.$el.addClass('right');
                    else this.$el.removeClass('right');

                    this.close();
                } else {
                    this.el.style.transform = `translateX(0)`;
                }
            }
        } else {

        }

        for (let i = 0; i < e.originalEvent.changedTouches.length; i++) {
            delete this.touches[e.originalEvent.changedTouches[i].identifier];
        }
    },

    _ensureSingleInstance() {
        if (openInstance && openInstance != this) {
            openInstance.close();
        }

        openInstance = this;
    },

    _clearThisInstance() {
        if (this == openInstance) {
            openInstance = null;
        }
    },

    _ensureDefaultWorkplace(reopenLeftMenuIfNoWorkplaceChosen = true) {
        return new Promise((resolve, reject) => {
            const defaultWorkPlace = this.model.get('defaultWorkPlace');
            if (defaultWorkPlace) {
                resolve(defaultWorkPlace);
            } else {
                const chooser = new DefaultWorkPlaceChooserWindow({ closeOnSelect: true });
                this.close(chooser);

                chooser
                    .show()
                    .then(place => {
                        if (place) {
                            resolve(place.toJSON());
                        } else {
                            if (reopenLeftMenuIfNoWorkplaceChosen) {
                                new LeftMenuWindow(this.options).show();
                            }
                            reject();
                        }
                    });
            }
        });
    },

    _ifNotLoggedInRedirectToLoginPage() {
        if (!this.model.get('loggedIn')) {
            this.close(app.controller.goToLoginPage());
            return true;
        } else {
            return false;
        }
    },

    _removeElement: function () {
        if (this.$el.hasClass('hide')) {
            setTimeout(() => {
                Window.prototype._removeElement.apply(this, arguments);
            }, 250);
        } else {
            return Window.prototype._removeElement.apply(this, arguments);
        }
    },
});

export default LeftMenuWindow;
