import { Model, LayoutView, CollectionView } from '@b2cmessenger/backbone';
import template from './GiftHistoryView.jade';
import templateHistoryItem from './GiftHistoryItemView.jade';
import templateSubhistoryItem from './GiftSubhistoryItemView.jade';

let HistoryItemView = LayoutView.extend({
    className: 'history-item',

    regions: {
        history: '[data-js-history]'
    },

    ui: {
        validUntil: '[data-js-validuntil]',
        expired: '[data-js-expired]',
        createdAt: '[data-js-created-at]',
        title: '[data-js-title]',
        history: '[data-js-history]',
        subhistoryToggler: '[data-js-show-history-toggler]',
    },

    computeds: {
        createdAt: {
            deps: ['created_at'],
            get: created_at => {
                const date = B2Cjs.datetimeServerToJS(created_at);
                if (date) {
                    return date.toLocaleString([], {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                    })
                } else {
                    return '';
                }
            },
        },
        isGifted: {
            deps: ['quantity'],
            get: qty => qty > 0
        },
        isExpired: {
            deps: ['quantity', 'expireDateLocal'],
            get: (qty, expire) => qty == 0 || (expire && expire < new Date())
        },
        isWriteOff: {
            deps: ['quantity'],
            get: qty => qty < 0
        },
        validUntil: {
            deps: ['quantity', 'expireDateLocal'],
            get: (qty, expire) => qty > 0 && expire && expire > new Date() && expire.toLocaleDateString(
                [], {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                }),
        },
        expired: {
            deps: ['quantity', 'expireDateLocal'],
            get: (qty, expire) => qty > 0 && expire && expire < new Date() && expire.toLocaleDateString(
                [], {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                }),
        },
        title:{
            deps: ['template','chargedQty', 'quantity'],
            get: (template, chargedQty, quantity) => {
                let name = template.get('type') == 1 ? ( quantity == 1 ? 'Coin': 'Coins' ) : template.get('name');

                if(chargedQty) {
                    let total = chargedQty + quantity;
                    return `${quantity} of ${total} ${name}`;
                } else {
                    return `${quantity} ${name}`;
                }
            }
        },
        hasCharges: {
            deps: ['chargedQty'],
            get: chargedQty => !!chargedQty,
        }
    },

    bindings:{
        ':el': 'classes:{gifted:isGifted,expired:isExpired,writeoff:isWriteOff}',
        '@ui.validUntil': 'classes:{hidden:not(validUntil)},text:validUntil',
        '@ui.createdAt': 'classes:{hidden:expired},text:createdAt',
        '@ui.expired': 'classes:{hidden:not(expired)},text:expired',
        '@ui.history': 'classes:{hidden:not(isOpen)}',
        '@ui.subhistoryToggler': 'classes:{hidden:not(hasCharges),"is-open":isOpen}',
        '@ui.title': 'text:title'
    },

    events: {
        'click @ui.subhistoryToggler': 'clickToggler'
    },

    initialize() {
        this.template = this.options.template || templateHistoryItem;

        this.viewModel = new Model({
            isOpen: false
        });

        if(!this.options.noHistory) {
            this.historyCollection = new HistoryCollection(this.model.get('chargingGifts'));
            this.historyCollection.listenTo(this.model, 'change:chargingGifts',
                (m, chargingGifts) => this.historyCollection.reset(chargingGifts));
        }
    },

    clickToggler() {
        this.viewModel.set('isOpen', !!!this.viewModel.get('isOpen'));
    },

    onRender() {
        if(this.historyCollection) {
            this.history.show(new HistoryCollectionView({
                collection: this.historyCollection,
                childViewOptions: {
                    template: templateSubhistoryItem,
                    noHistory: true
                }
            }));
        }

        const unreadNotificationIds = notificationCollection.getNewVisibleNotificationIdsForGift(this.model.id);
        if (unreadNotificationIds.length) {
            this.$('[data-id]').attr('data-notification-ids', unreadNotificationIds.join(','));
        }
    }
});

let HistoryCollection = Backbone.Collection.extend({});
let HistoryCollectionView = CollectionView.extend({
    className: 'history-list',
    childView: HistoryItemView,
});

export default LayoutView.extend({
    className: 'gift-history',
    template: template,

    regions: {
        history: '> [data-js-history]'
    },

    ui: {
        history: '> [data-js-history]',
        subhistoryToggler: '> [data-js-show-history-toggler]',
    },

    bindings: {
        ':el': 'classes:{"is-open":isOpen}',
        '@ui.history': 'classes:{hidden:not(isOpen)}',
        '@ui.subhistoryToggler': 'classes:{"is-open":isOpen}'
    },

    events: {
        'click @ui.subhistoryToggler': 'clickToggler'
    },

    initialize() {
        this.viewModel = new Model({
            isOpen:false
        });

        this.historyCollection = new HistoryCollection(this.model.get('gifts'));
        this.historyCollection.listenTo(this.model, 'change:gifts',
            (m, gifts) => {
                this.historyCollection.set(gifts)
            });
    },

    onRender() {
        this.history.show(new HistoryCollectionView({
            collection: this.historyCollection,
            filter: (child, index, collection) => {
                if(this.model.get('type') == 2) {
                    return child.get('quantity') >= 0;
                }

                return true;
            }
        }));
    },

    clickToggler() {
        this.viewModel.set('isOpen', !!!this.viewModel.get('isOpen'));
    },

    open() {
        this.viewModel.set('isOpen', true);
    }
});
