import { Required, Optional, LayoutView, ViewModel } from "@b2cmessenger/backbone";
import {
    InputGroupBindingHandler as InputGroup,
    inputGroupDisabledBindingHandler as igDisabled,
    inputGroupErrorBindingHandler as igError,
    inputGroupLabelBindingHandler as igLabel,
    inputGroupMinBindingHandler as igMin
} from 'widgets/lite/inputGroup/inputGroup';
import Window from 'windows/Window';
import HeaderView from "widgets/Header/Header";
import FooterView from "widgets/Footer/Footer";
import ReservationModel from "models/ReservationModel";
import template from './ReservationEditor.jade';
import templateWindow from './ReservationEditorWindow.jade';
import moment from 'moment-timezone';
import humanize from 'humanize-duration';
import './ReservationEditor.scss';
import ReservationOptionsWindow from "windows/ReservationEditor/ReservationOptions";
import PhoneCollection from "../../models/PhoneCollection";
import PhoneEditorModal from "../Modal/Phone/PhoneEditor";
import PhoneVerifyModal from "../Modal/Phone/PhoneVerify";
import PhoneChooser from "../Phone/PhoneChooser";
import PhoneModel from "models/PhoneModel";
import PlaceModel from "models/PlaceModel";
import AjaxError from "utils/AjaxError";
import ViewWithWindows from 'traits/ViewWithWindows';

const MIN_GUEST_COUNT = 1;
const MAX_GUEST_COUNT = Number.MAX_SAFE_INTEGER;
const DEFAULT_START_TIME_DIFF_MINUTES = 30;
const MIN_DURATION_MINUTES = 30;
const DEFAULT_DURATION_MINUTES = 60;
const MAX_DURATION_MINUTES = 1440 * 30;
const MIN_CHILDREN_COUNT = 0;
const GUEST_STEP = 1;
const DEFAULT_DURATION_VARIANT = 60;
const CUSTOM_DURATION_VARIANT = 'custom';
const SERVER_ERROR_CODES = {
    outdatedChangesCode: 1103001
};
const DURATION_VARIANTS = {
    60: '1 hour',
    [CUSTOM_DURATION_VARIANT]: 'Custom'
};

const withWindowsTrait = {
    Trait: ViewWithWindows,
    options: {
        windowMap: [
            {
                cls: ReservationOptionsWindow,
                trigger() {
                    this.content.currentView.trigger('show:additional');
                }
            }
        ],
    }
};

const ReservationEditorWindow = Window.extendWithTraits([withWindowsTrait], {
    options: {
        model: Optional,
        disableSavingButCloseWithData: Optional,
        disableComment: Optional,
        parentViewModel: Optional,
        placeId: Optional,
        phoneId: Optional,
        workingDays: Optional
    },

    template: templateWindow,
    windowName: 'reservation-editor-window',
    className: 'reservation-editor-window',

    initialize() {
        if (!this.model) {
            this.model = new ReservationModel();
        }
        this.phoneCollection = new PhoneCollection();
        this.viewModel = new ViewModel(_.defaults(this._getInitDataFromModel(), {
            disableComment: this.options.disableComment,
            parentViewModel: this.options.parentViewModel || null,
            vm_place_id: this.model.get('place_id') || this.options.placeId,
            vm_phone_id: this.model.get('phone_id') || this.options.phoneId,
            vm_phone: '',
            vm_dateStartError: '',
            vm_dateEndError: '',
            vm_workingDays: this.options.workingDays,
            vm_isAuthor: this.model.isNew() || this.model.get('user_id') === LoginedUserHandler.getLoginedUser().id
        }));

        if (this.viewModel.get('vm_isAuthor')) {
            this._getLoginedUserVerifiedPhoneModel().then((model) => {
                if (model) {
                    this.viewModel.set({
                        vm_phone_id: model.get('id'),
                        vm_phone: model.get('phone')
                    });
                } else {
                    this.close(this.showError('No verified phone selected', undefined, undefined,
                        { dontSendToGaAndSentry: true }));
                }
            });
        }


        if (!this.options.workingDays && this.viewModel.get('vm_place_id')) {
            B2CPlace.server_get(
                {
                    id: this.viewModel.get('vm_place_id'),
                },
                place => {
                    if (place.id) {
                        const placeModel = new PlaceModel(_.extend(place, { full: true }));
                        this.viewModel.set('vm_workingDays', placeModel.get('days'));
                    } else {
                        new Error('Incorrect place.id');
                    }
                },
                (jqXHR, textStatus, errorThrown) => new AjaxError(jqXHR, textStatus, errorThrown)
            );
        }
    },

    onRender() {
        const contentView = new ReservationEditor({
            model: this.model,
            viewModel: this.viewModel,
            phoneCollection: this.phoneCollection
        });
        this.content.show(contentView);

        this.listenTo(contentView, 'continue', () => this._onContinue());
        this.listenTo(contentView, 'show:additional', () => {
            new ReservationOptionsWindow({
                viewModel: this.viewModel
            }).show();
        });

        const headerView = new HeaderView({
            leftButtons: ['back'],
            title: (this.model.isNew()) ? 'Suggest reservation' : 'Change reservation'
        });
        this.listenTo(headerView, 'back:click', view => this.cancel());
        headerView.listenTo(this.viewModel, 'change:disabled',
            () => headerView.ui.btnback.prop('disabled', this.viewModel.get('disabled')));

        this.header.show(headerView);

        const footerView = new FooterView({
            buttons: [{
                id: 'cancel',
                text: 'CANCEL',
            }, {
                id: 'continue',
                text: (this.model.isNew()) ? 'SAVE' : 'CONTINUE',
            }]
        });
        this.listenTo(footerView, 'cancel:click', view => this.cancel());
        this.listenTo(footerView, 'continue:click', view => this._onContinue());
        footerView.listenTo(this.viewModel, 'change:disabled', () => {
            footerView.ui.btncontinue.prop('disabled', this.viewModel.get('disabled'));
            footerView.ui.btncancel.prop('disabled', this.viewModel.get('disabled'));
        });

        this.footer.show(footerView);
    },

    cancel() {
        this.close(false);
    },

    _getLoginedUserVerifiedPhoneModel() {
        return new Promise((resolve, reject) => {
            this.showLoading();
            this.phoneCollection.fetch().then(() => {
                this.hideLoading();

                const existingPhone = this.phoneCollection.get(this.viewModel.get('vm_phone_id')) ||
                    this.phoneCollection.findWhere({ default: 1 }) ||
                    this.phoneCollection.findWhere({ status: PhoneModel.Status.Verified });

                if (existingPhone) {
                    resolve(existingPhone);
                } else {
                    const phoneEditorModal = new PhoneEditorModal({
                        collection: this.phoneCollection,
                    });
                    phoneEditorModal.show()
                        .then((model) => {
                            if (model) {
                                const phoneVerifyModal = new PhoneVerifyModal({
                                    model: model
                                });

                                phoneVerifyModal.show()
                                    .then((model) => {
                                        if (model && model.get('c_isVerified')) {
                                            resolve(model);
                                        } else {
                                            resolve(false);
                                        }
                                    });
                            } else {
                                resolve(false);
                            }
                        });
                }
            });
        });
    },

    _getInitDataFromModel() {
        const message = (this.model.get('task') && this.model.get('task').text) ? this.model.get('task').text : null;
        const dateStartLocal = this.model.get('date_start_local') ?
            B2Cjs.datetimeServerLocalToJS(this.model.get('date_start_local'))
            : moment().startOf('minute').add(DEFAULT_START_TIME_DIFF_MINUTES, 'minutes').toDate();

        const dateEndLocal = this.model.get('date_end_local') ?
            B2Cjs.datetimeServerLocalToJS(this.model.get('date_end_local'))
            : moment(dateStartLocal).add(DEFAULT_DURATION_MINUTES, 'minutes').toDate();

        const duration = Math.round((dateEndLocal.valueOf() - dateStartLocal.valueOf()) / 60000);

        return {
            vm_opt_guests_count: this.model.get('opt_guests_count') || GUEST_STEP,
            vm_message_text: message,
            vm_dateStartLocal: dateStartLocal,
            vm_dateEndLocal: dateEndLocal,
            vm_opt_children_count: this.model.get('opt_children_count') || MIN_CHILDREN_COUNT,
            vm_opt_smoking_area: this.model.get('opt_smoking_area') || null,
            vm_place_id: this.model.get('place_id') || undefined,
            vm_phone_id: this.model.get('phone_id') || undefined,
            vm_durationVariant: Number(duration) && DURATION_VARIANTS[duration]
                ? Number(duration)
                : CUSTOM_DURATION_VARIANT
        };
    },

    _getPreparedData() {
        const childrenCount = this.model.get('opt_children_count');
        const newChildrenCount = this.viewModel.get('vm_opt_children_count');
        const preparedData = {
            place_id: this.viewModel.get('vm_place_id'),
            date_start_local: B2Cjs.datetimeJSToServerLocal(this.viewModel.get('vm_dateStartLocal')),
            date_end_local: B2Cjs.datetimeJSToServerLocal(this.viewModel.get('vm_dateEndLocal')),
            opt_guests_count: this.viewModel.get('vm_opt_guests_count'),
            opt_children_count: _.isNull(childrenCount) && newChildrenCount == 0 ? childrenCount : newChildrenCount,
            opt_smoking_area: this.viewModel.get('vm_opt_smoking_area'),
        };

        if (this.viewModel.get('vm_isAuthor')) {
            preparedData.phone_id = this.viewModel.get('vm_phone_id');
        }

        return preparedData;
    },

    async _updateModelsWithActualDataFromServer() {
        this.viewModel.set('disabled', true);
        try {
            await this.model.fetch();
            this.viewModel.set(_.extend({
                    vm_dateStartError: '',
                    vm_dateEndError: '',
                    _recalculate_dateEndLocal: 0
                }, this._getInitDataFromModel())
            );
        } catch (error) {
            this.showError(error);
        } finally {
            this.viewModel.set('disabled', false);
        }
    },

    _onContinue() {
        if (!this.viewModel.get('disabled')) {
            if (this.options.disableSavingButCloseWithData) {
                this.close({
                    message: this.viewModel.get('vm_message_text'),
                    ...this._getPreparedData()
                });
                return;
            }

            this.viewModel.set('disabled', true);
            this.model.save(this._getPreparedData(), {
                includeTaskId: true,
                message: this.viewModel.get('vm_message_text'),
                success: () => {
                    this.close(this.model);
                    this.viewModel.set('disabled', false);

                    app.controller.trigger('reservation:change', this.model.id, {
                        status: this.model.get('status')
                    });
                },
                error: (m, jqXHR) => {
                    if (jqXHR.status == 422) {
                        const fieldsArray =
                            _.isArray(jqXHR.responseJSON) ?
                                jqXHR.responseJSON
                                : (jqXHR.responseJSON.error && jqXHR.responseJSON.error.field) || [];

                        const outdatedChangesError = fieldsArray.find((field) => {
                            return field.code === SERVER_ERROR_CODES.outdatedChangesCode;
                        });

                        if (outdatedChangesError) {
                            this.showError(outdatedChangesError.message);
                            this._updateModelsWithActualDataFromServer();
                            return;
                        }

                        const fields = _.filter(fieldsArray, o => {
                            let field = o.field,
                                msg = o.message && String(o.message);

                            switch (field) {
                                case 'date_start':
                                case 'date_start_local':
                                    this.viewModel.set('vm_dateStartError', msg);
                                    return false;
                                case 'date_end':
                                case 'date_end_local':
                                    this.viewModel.set('vm_dateEndError', msg);
                                    return false;
                                default:
                                    return true;
                            }
                        });

                        if (_.size(fields)) {
                            jqXHR.responseJSON = fields;
                            this.showError(jqXHR, undefined, undefined, { dontSendToGaAndSentry: true });
                        }
                    } else {
                        this.showError(jqXHR);
                    }
                    this.viewModel.set('disabled', false);
                }
            });
        }
    },
});

@LayoutView.options({
    model: Required,
    viewModel: Required,
    phoneCollection: Required
})
@LayoutView.properties({
    template,

    className: 'widget reservation-editor-widget',
    tagName: 'form',

    ui: {
        additionalBtn: '[data-js-btn-additional]',
        dateTimeStart: '[data-js-date-time-start]',
        dateEndVariants: '[data-js-date-end-variants]',
        dateTimeEnd: '[data-js-date-time-end]',
        dateTimeInput: '[data-js-input-date-time]',
        guestsPlusBtn: '[data-js-guests-wrapper] [data-js-plus]',
        guestsMinusBtn: '[data-js-guests-wrapper] [data-js-minus]',
        guestsInput: '[data-js-guests-wrapper] [data-js-guests-input]',
        message: '[data-js-textarea-message]',
        phone: '[data-js-phone]',
        nonWorkingHours: '[data-js-non-working-hours]'
    },

    events: {
        'click @ui.guestsPlusBtn'() {
            const value = this.viewModel.get('vm_opt_guests_count') + GUEST_STEP;
            this.viewModel.set('vm_opt_guests_count',
                this._getValidNumberByInterval(value, MIN_GUEST_COUNT, MAX_GUEST_COUNT));
        },
        'click @ui.guestsMinusBtn'() {
            const value = this.viewModel.get('vm_opt_guests_count') - GUEST_STEP;
            this.viewModel.set('vm_opt_guests_count',
                this._getValidNumberByInterval(value, MIN_GUEST_COUNT, MAX_GUEST_COUNT));
        },
        'blur @ui.guestsInput'(e) {
            $(e.currentTarget).val(this.viewModel.get('vm_opt_guests_count'));
        },
        'click @ui.additionalBtn'(e) {
            e.preventDefault();
            this.trigger('show:additional');
        },
        'click @ui.phone'() {
            new PhoneChooser({
                collection: this.options.phoneCollection,
                selectedPhoneId: this.viewModel.get('vm_phone_id')

            }).show().then((model) => {
                if (model && model.get('c_isVerified')) {
                    this.viewModel.set({
                        'vm_phone_id': model.get('id'),
                        'vm_phone': model.get('phone')
                    });
                }
            });
        },
        'submit'(e) {
            this.trigger('continue');
        }
    },

    computeds: {
        c_guestsMinusBtnDisabled: {
            deps: ['vm_opt_guests_count', 'disabled'],
            get: (value, disabled) => (value === GUEST_STEP) || disabled
        },
        c_dateStartLocal: {
            deps: ['vm_dateStartLocal'],
            get: value => B2Cjs.datetimeJsToInput(value),
            set(val) {
                const dateStart = this._fixDate(B2Cjs.datetimeInputToJs(val));
                if (dateStart) {
                    this.viewModel.set('vm_dateStartLocal', dateStart);
                    const duration = this.getBinding('c_duration');
                    if (duration) {
                        this.setBinding('c_duration', duration);
                    } else if (this.viewModel.get('vm_dateEndLocal')) {
                        const dateEnd = this._fixDate(this.viewModel.get('vm_dateEndLocal'));
                        const duration = this._getDurationBetween(dateStart, dateEnd);
                        this.setBinding('c_duration', duration);
                    }
                } else {
                    this.viewModel.set('vm_dateStartLocal', null);
                }
            }
        },
        c_dateEndLocal: {
            deps: ['vm_dateEndLocal'],
            get: value => B2Cjs.datetimeJsToInput(value),
            set(val) {
                const dateEnd = this._fixDate(B2Cjs.datetimeInputToJs(val));
                if (dateEnd) {
                    const dateStart = this._fixDate(this.viewModel.get('vm_dateStartLocal'));
                    if (dateStart) {
                        const duration = this._getDurationBetween(dateStart, dateEnd);
                        if (duration < MIN_DURATION_MINUTES) {
                            this.viewModel.set({
                                vm_dateStartLocal: dateStart,
                                vm_dateEndLocal: moment(dateStart).add(MIN_DURATION_MINUTES, 'minutes').toDate()
                            });
                        } else if (duration > MAX_DURATION_MINUTES) {
                            this.viewModel.set({
                                vm_dateStartLocal: dateStart,
                                vm_dateEndLocal: moment(dateStart).add(MAX_DURATION_MINUTES, 'minutes').toDate()
                            });
                        } else {
                            this.setBinding('c_duration', duration);
                        }
                    } else {
                        this.viewModel.set('vm_dateEndLocal', dateEnd);
                    }
                } else {
                    this.viewModel.set('vm_dateEndLocal', null);
                }
            }
        },
        c_dateEndMinLocal: {
            deps: ['vm_dateStartLocal'],
            get(vm_dateStartLocal) {
                const dateStart = this._fixDate(vm_dateStartLocal);
                const minDate = moment(dateStart).add(MIN_DURATION_MINUTES, 'minutes').toDate();
                return B2Cjs.datetimeJsToInput(minDate);
            }
        },
        c_includedNonWorkingHours: {
            deps: ['vm_dateStartLocal', 'vm_dateEndLocal', 'vm_workingDays'],
            get(dateStart, dateEnd, workingDays) {
                return this._isIncludedNonWorkingHours(dateStart, dateEnd, workingDays);
            }
        },
        c_duration: {
            deps: ['vm_durationVariant', 'vm_dateStartLocal', 'vm_dateEndLocal'],
            get(durationVariant, dateStart, dateEnd) {
                if (Number(durationVariant) && DURATION_VARIANTS[durationVariant]) {
                    return Number(durationVariant);
                }

                if (dateStart && dateEnd) {
                    return this._getDurationBetween(dateStart, dateEnd);
                } else {
                    return null;
                }
            },
            set(duration) {
                duration = Math.round(duration) || DEFAULT_DURATION_MINUTES;
                const dateStart = this._fixDate(this.viewModel.get('vm_dateStartLocal'));
                const dateEnd = this._fixDate(this.viewModel.get('vm_dateEndLocal'));

                if (dateStart) {
                    duration = Math.max(MIN_DURATION_MINUTES, Math.min(duration, MAX_DURATION_MINUTES));
                    this.viewModel.set('vm_dateEndLocal', moment(dateStart).add(duration, 'minutes').toDate());
                } else if (dateEnd) {
                    duration = Math.max(MIN_DURATION_MINUTES, Math.min(duration, MAX_DURATION_MINUTES));
                    this.viewModel.set('vm_dateStartLocal', moment(dateEnd).subtract(duration, 'minutes').toDate());
                }

                const currentDurationVariant = this.viewModel.get('vm_durationVariant');
                if (currentDurationVariant != CUSTOM_DURATION_VARIANT) {
                    if (currentDurationVariant != (DURATION_VARIANTS[duration] && duration)) {
                        this.viewModel.set({ vm_durationVariant: CUSTOM_DURATION_VARIANT });
                    }
                }
            }
        },
        c_durationVariant: {
            deps: ['vm_durationVariant'],
            get: durationVariant => DURATION_VARIANTS[durationVariant] && durationVariant || CUSTOM_DURATION_VARIANT,
            set(variant) {
                if (variant != CUSTOM_DURATION_VARIANT) {
                    variant = Number(variant) && DURATION_VARIANTS[variant] && variant;
                    if (variant) {
                        this.viewModel.set({
                            vm_durationVariant: variant
                        });
                        this.setBinding('c_duration', Number(variant));
                        return;
                    }
                }

                this.viewModel.set({
                    vm_durationVariant: CUSTOM_DURATION_VARIANT
                });
            }
        },
        c_durationString: {
            deps: ['vm_dateStartLocal', 'vm_dateEndLocal'],
            get(dateStartLocal, dateEndLocal) {
                if (!dateStartLocal || !dateEndLocal) {
                    return 'until';
                }
                const duration = moment.duration(moment(dateEndLocal).diff(dateStartLocal));
                if (!duration.isValid()) {
                    return 'until';
                }

                return humanize(duration, { largest: 2, units: ['d', 'h', 'm'] });
            }
        },
        c_guestCount: {
            deps: ['vm_opt_guests_count'],
            get: value => value,
            set(value) {
                this.viewModel.set('vm_opt_guests_count',
                    this._getValidNumberByInterval(value, MIN_GUEST_COUNT, MAX_GUEST_COUNT));
            }
        },
        c_dateTimeEndCss: {
            deps: ['c_durationVariant'],
            get(value) {
                return (value === CUSTOM_DURATION_VARIANT) ? '' : 'none'
            }
        },
        prettyPhone: {
            deps: ['vm_phone'],
            get: phone => phone && B2Cjs.phone_getExternalFormat(phone) || ""
        }
    },

    bindings: {
        '@ui.dateTimeStart':
            'dateStartInputGroup:c_dateStartLocal, igDisabled:disabled, igError:vm_dateStartError',
        '@ui.dateEndVariants': 'dateEndRadioInputGroup:c_durationVariant, igDisabled:disabled, igError:vm_dateEndError',
        '@ui.dateTimeEnd': 'dateEndInputGroup:c_dateEndLocal, igDisabled:disabled, ' +
        'igError:vm_dateEndError, igLabel:c_durationString, igMin:c_dateEndMinLocal,css:{display:c_dateTimeEndCss}',
        '@ui.message': 'value:vm_message_text, disabled:disabled, attr:{readonly:disabled}',
        '@ui.guestsInput': 'value:c_guestCount, disabled:disabled, attr:{readonly:disabled}',
        '@ui.guestsMinusBtn': 'disabled:c_guestsMinusBtnDisabled, attr:{readonly:c_guestsMinusBtnDisabled}',
        '@ui.guestsPlusBtn': 'disabled:disabled, attr:{readonly:disabled}',
        '@ui.additionalBtn': 'disabled:disabled, attr:{readonly:disabled}',
        '@ui.phone': 'text:prettyPhone',
        '@ui.nonWorkingHours': 'toggle:c_includedNonWorkingHours'
    },

    bindingHandlers: {
        dateStartInputGroup: new InputGroup('datetime-local', "When"),
        dateEndRadioInputGroup: new InputGroup('radio', 'Duration', {
            icon: 'ion-ios-time-outline',
            options: DURATION_VARIANTS
        }),
        dateEndInputGroup: new InputGroup('datetime-local', "Until"),
        igDisabled, igError, igLabel, igMin
    },

    templateHelpers() {
        return {
            disableComment: this.viewModel.get('disableComment'),
            isAuthor: this.viewModel.get('vm_isAuthor')
        }
    }
})
class ReservationEditor extends LayoutView {
    _isValidDateTimeLocalRange(dateStartLocal, dateEndLocal) {
        const minutes = (moment(dateEndLocal).diff(dateStartLocal) / 60000);

        return minutes >= MIN_DURATION_MINUTES && minutes <= MAX_DURATION_MINUTES;
    }

    _getValidNumberByInterval(value, min = 0, max = Number.MAX_SAFE_INTEGER) {
        return Math.max(min, Math.min(Number(value) || min, max)) || min;
    }

    _fixDate(date) {
        return date && moment(date).startOf('minute').toDate();
    }

    _getDurationBetween(startDate, endDate) {
        return Math.round((endDate.valueOf() - startDate.valueOf()) / 60000);
    }

    _isIncludedNonWorkingHours(dateStart, dateEnd, workingDays) {
        const mDateStart = moment(dateStart);
        const mDateEnd = moment(dateEnd);
        let currentDate = mDateStart.clone();
        let isIncludedNonWorkingHours = false;
        let isFound = false;

        if (!workingDays || !mDateStart.isValid() || !mDateEnd.isValid() || mDateStart.isAfter(mDateEnd) ||
            workingDays.filter((day) => !day.all || day.all !== 1).length === 0) {
            return false;
        }

        while (!isFound) {
            const workingDay = _.find(workingDays, (day) => day.d === currentDate.isoWeekday());
            if (workingDay) {
                if (workingDay.all && workingDay.all === -1) {
                    isIncludedNonWorkingHours = true;
                    isFound = true;
                } else if (workingDay.int) {
                    let closedSince = currentDate.clone().startOf('day');
                    let closedUntil = currentDate.clone().startOf('day');

                    _.each(workingDay.int, (item, index) => {
                        const curWorkingStart = currentDate.clone().set({
                            'hour': item.o_h,
                            'minute': item.o_m,
                            'second': 0
                        });
                        const curWorkingEnd = currentDate.clone().set({
                            'hour': item.c_h,
                            'minute': item.c_m,
                            'second': 0
                        });

                        if (index < workingDay.int.length - 1) {
                            closedUntil.set({
                                'hour': workingDay.int[index + 1].o_h,
                                'minute': workingDay.int[index + 1].o_m,
                                'second': 0
                            });
                        } else {
                            closedUntil = currentDate.clone().add(1, 'day').startOf('day');
                        }

                        closedUntil.subtract(1, 'seconds');

                        if (currentDate.isBetween(closedSince, curWorkingStart) ||
                            currentDate.isBetween(curWorkingEnd, closedUntil) ||
                            mDateEnd.isBetween(closedSince, curWorkingStart) ||
                            mDateEnd.isBetween(curWorkingEnd, closedUntil)) {
                            isIncludedNonWorkingHours = true;
                            isFound = true;
                        }

                        if (index < workingDay.int.length - 1) {
                            closedSince.set({
                                'hour': workingDay.int[index].c_h,
                                'minute': workingDay.int[index].c_m,
                                'second': 0
                            });

                            if (currentDate.isSameOrBefore(closedUntil) && mDateEnd.isSameOrAfter(closedUntil)) {
                                isIncludedNonWorkingHours = true;
                                isFound = true;
                            }
                        }
                    });
                }
            } else {
                isFound = true;
            }

            if (currentDate.isSame(mDateEnd, 'day')) {
                isFound = true;
            } else {
                currentDate = currentDate.add(1, 'day').startOf('day');
            }
        }

        return isIncludedNonWorkingHours;
    }
}

export default ReservationEditorWindow;
