import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactWindow from 'windows/ReactWindow';
import { connect } from 'react-redux';

import withStore from 'components/withStore';

import './MenuWaiterCall.scss';
import { WindowContent, WindowFooter, WindowHeader } from 'components/window/Window';
import Header from 'components/UI/Header/Header';
import { BackButton } from 'components/UI/Button/Button';
import Footer from 'components/UI/Footer/Footer';
import TextButton from 'components/UI/TextButton';
import KeyboardPadding from 'components/KeyboardPadding';
import { loadUserOrders, fetchCallWaiterHistory, fetchWaiterRequests } from 'utils/api/menu';
import { getPlaceMenuOrderById } from 'store/menu/orders/selectors';
import { onEditOrder } from 'store/menu/orders/actions';
import { OrderStatusMap } from 'components/menu/MenuOrderStatus/MenuOrderStatus';
import MenuWaiterCallContainer from 'components/menu/MenuWaiterCall/MenuWaiterCallContainer';

import MenuOrders from 'components/menu/MenuOrder/MenuOrders';
import { getMenuPermissionsInCurrentPlace, getMenuTablesAsArray } from 'store/menu/sections/selectors';
import { getMenuSelectedTableId } from 'store/menu/cart/selectors';
import { getMenuPlaceId } from 'store/menu/selectors';
import PropTypes from 'prop-types';
import Spinner from 'components/UI/Spinner/Spinner';

const stateMap = {
    init: 'init',
    initRequestsLoad: 'initRequestsLoad',
    requestsLoaded: 'requestsLoaded',
    initOrdersLoad: 'initOrdersLoad',
    ordersLoaded: 'ordersLoaded',
    initRequestsWithoutOrder: 'initRequestsWithoutOrder',
    requestsWithoutOrderLoaded: 'requestsWithoutOrderLoaded',
};

const MenuWaiterCall = (props) => {
    const { placeId, menuPermissions, isEmployee: isEmployeeForced, tables = [], selectedTableId } = props;
    const { isWaiter = false } = menuPermissions;

    const isEmployee = useMemo(() => {
        return _.isUndefined(isEmployeeForced) ? isWaiter : isEmployeeForced;
    }, [isWaiter, isEmployeeForced]);

    const pageTitle = useMemo(() => {
        return isEmployee ? 'Requests from clients' : 'Call Waiter';
    }, [isEmployee]);

    const [state, setState] = useState(stateMap.init);
    const [models, setModels] = useState([]);
    const [userOrders, setUserOrders] = useState([]);

    const fetchUserOrders = useCallback(async () => {
        try {
            const { orders = [] } = await loadUserOrders({
                status: [OrderStatusMap.New, OrderStatusMap.Confirmed],
                includePlace: true,
            });

            if (orders.length > 0) {
                setUserOrders(orders);
                setState(stateMap.ordersLoaded);
            } else {
                setState(stateMap.initRequestsWithoutOrder);
            }
        } catch (e) {
            showError(e);
        }
    }, []);

    const loadWaiterRequests = useCallback(async () => {
        try {
            const models = await fetchWaiterRequests(placeId, {
                hours: 8,
                includeOrder: true,
                includeUser: true,
            });

            if (models.length > 0) {
                setModels(models);
            }
            setState(stateMap.requestsLoaded);
        } catch (e) {
            showError(e);
        }
    }, []);

    const loadCallWaiterHistory = useCallback(async () => {
        try {
            const models = await fetchCallWaiterHistory(placeId, {
                hours: 8,
                includeOrder: true,
                includeWaiterUser: true,
            });

            if (models.length > 0) {
                setModels(models);
            }
            setState(stateMap.requestsWithoutOrderLoaded);
        } catch (e) {
            showError(e);
        }
    }, []);

    const chooseWithoutOrderOption = useCallback(() => {
        setState(stateMap.initRequestsWithoutOrder);
    }, []);

    useEffect(() => {
        switch (state) {
            case stateMap.init:
                if (isEmployee) {
                    setState(stateMap.initRequestsLoad);
                    loadWaiterRequests();
                } else {
                    setState(stateMap.initOrdersLoad);
                    fetchUserOrders();
                }

                break;
            case stateMap.initRequestsWithoutOrder:
                loadCallWaiterHistory();
                break;
            default:
                console.info(`No state action defined: ${state}`);
        }
    }, [state]);

    const chooseOrder = (order) => {
        app.controller.goToOrdersPage({ showOrderWindowWithId: order.id, forceOpenWaiterCallForm: true });
    };

    let content = null;
    switch (state) {
        case stateMap.init:
        case stateMap.initRequestsLoad:
        case stateMap.initOrdersLoad:
        case stateMap.initRequestsWithoutOrder:
            content = <Spinner />;
            break;

        case stateMap.requestsLoaded:
            content = (
                <MenuWaiterCallContainer
                    isEmployee={isEmployee}
                    readOnly
                    models={models}
                    emptyListText="No requests"
                    showClient
                    placeId={placeId}
                />
            );
            break;

        case stateMap.ordersLoaded:
            content = (
                <div>
                    <p>Select order to call waiter:</p>
                    <MenuOrders orders={userOrders} onSelectItem={chooseOrder} displayOrderActions={false} />
                    <p>
                        <TextButton className="wide normalized border-filled" onClick={chooseWithoutOrderOption}>
                            Without order
                        </TextButton>
                    </p>
                </div>
            );
            break;

        case stateMap.requestsWithoutOrderLoaded:
            content = (
                <MenuWaiterCallContainer
                    isEmployee={isEmployee}
                    readOnly={false}
                    models={models}
                    changeModels={setModels}
                    placeId={placeId}
                    emptyListText="No waiter calls"
                    forceOpenForm
                    tables={tables}
                    selectedTableId={selectedTableId}
                    showWaiter
                />
            );
            break;
        default:
            showError('An error has occurred, please try again later.');
            props.closeWindow();
            break;
    }

    return (
        <>
            <WindowHeader>
                <Header>
                    <BackButton onClick={() => props.closeWindow()} />
                    <h1 className="title">{pageTitle}</h1>
                </Header>
            </WindowHeader>
            <WindowContent className="menu-waiter-call-window-content">{content}</WindowContent>
            <WindowFooter>
                <Footer>
                    <TextButton onClick={() => props.closeWindow()}>CLOSE</TextButton>
                </Footer>
            </WindowFooter>
            <KeyboardPadding />
        </>
    );
};

MenuWaiterCall.propTypes = {
    placeId: PropTypes.number,
    isEmployee: PropTypes.bool,
    tables: PropTypes.array,
    selectedTableId: PropTypes.number,
};

const ConnectedMenuWaiterCall = withStore(
    connect(
        (state) => ({
            getPlaceOrderById: (id) => getPlaceMenuOrderById(state, id),
            placeId: Number(getMenuPlaceId(state)),
            tables: getMenuTablesAsArray(state),
            selectedTableId: getMenuSelectedTableId(state),
            menuPermissions: getMenuPermissionsInCurrentPlace(state),
        }),
        {
            onEditOrder,
        }
    )(MenuWaiterCall)
);

const MenuWaiterCallWindow = ReactWindow.extend({
    constructor: function MenuOrderWindow(props) {
        const options = {
            props: {
                ...props,
            },
            component: ConnectedMenuWaiterCall,
            name: 'MenuWaiterCall',
        };

        return ReactWindow.apply(this, [options]);
    },
});

export default MenuWaiterCallWindow;
