import _ from 'underscore';
import { ItemView, LayoutView, CollectionView, Model } from '@b2cmessenger/backbone';

import EvidenceModel from 'models/EvidenceModel';
import ImageInput from 'widgets/Inputs/ImageInput';
import ConfirmModal from 'windows/Modal/Confirm';

import editorTemplate from './EvidenceEditor.jade';
import photoTemplate from './EvidencePhoto.jade';
import './EvidenceEditor.scss';
import ImageInputHelpers from 'widgets/Inputs/ImageInputHelpers';

let EvidencePhotoWidget = ItemView.extend({
    tagName: "div",
    template: photoTemplate,
    className: "widget evidence-photo-widget",

    bindings: {
        'img': 'attr:{src:url}'
    },

    events: {
        'click .btn-delete': 'onBtnDeleteClick',
    },

    onRender() {
        this.$el.find('img').on('load', () => {
            this.$el.css('display', 'block');
        });

        this.$el.css('display', 'none');
    },

    onBtnDeleteClick() {
        new ConfirmModal({ message: "Delete this photo?" })
            .show()
            .then(confirm => confirm && this.model.destroy());
    }
});

let EvidenceEditor = LayoutView.extend({
    template: editorTemplate,
    className: "widget evidence-editor-widget",

    regions: {
        photos: '[data-js-photos]',
        btnAddNewPhoto: '[data-js-photo-add-new]',
    },

    bindings: {
        ':el': 'classes:{"no-photos":not(hasPhotos)}',
        '[data-js-photo-add-new] > button': 'classes:{"btn-big":hasPhotos}'
    },

    initialize() {
        if(!this.model) {
            if(!this.options.place_id) throw new Error('options have no place_id!');
            this.model = new EvidenceModel({ place_id: this.options.place_id });
        }
        if(!this.model.has('place_id')) throw new Error('model has no place_id!');
    },

    onRender() {
        let photos = new CollectionView({
            childView: EvidencePhotoWidget,
            collection: this.model.get('photoCollection'),
            className: "widget evidence-photo-collection-widget"
        });
        this.photos.show(photos);

        let imageInput = new ImageInput({
            model: new Model()
        });
        imageInput.el.classList.add('without-image');
        this.listenTo(imageInput, 'change', file => {
            if (file) {
                ImageInputHelpers.upload(file, 'ev')
                    .then(uploadedFile => {
                        if (this.model.isNew()) {
                            imageInput.trigger('disable');
                            this.model.save(null, {
                                success: () => {
                                    imageInput.trigger('enable');
                                    this.model.get('photoCollection').create({
                                        evidence_id: this.model.id,
                                        file: uploadedFile.id
                                    });
                                }
                            });
                        } else {
                            this.model.get('photoCollection').create({
                                evidence_id: this.model.id,
                                file: uploadedFile.id
                            }, { wait: true });
                        }
                    })
                    .catch(e => {
                        imageInput.trigger('enable');
                        this.showError(e);
                    });
            }
        });
        this.btnAddNewPhoto.show(imageInput);

        this.removeBindings();
        this.applyBindings();
    }
});

export default EvidenceEditor;
