/**
 * Created by Alex on 11.05.2016.
 */

// B2C Gift Give Dialog
(function( $ ){
    var methods = {
        init : function( options ) {

            return this.each(function(){

                var $this = $(this),
                    data = $this.data('b2cgift_give_dialog');

                if ( ! data ) {

                    data = {};
                    data.$this = $this;
                    data.options = $.extend(true, {}, $.fn.b2cgift_give_dialog.defaults, options);
                    
                    $(this).data('b2cgift_give_dialog', data);

                    $this.addClass(data.options.cl_main);
                    
                    $.fn.b2cgift_give_dialog.init_options(data);
                    
                    $.fn.b2cgift_give_dialog.init(data);
                }
            });
        },
        close : function() {
            return this.each(function(){
                var $this = $(this),
                    data = $this.data('b2cgift_give_dialog');
                $.fn.b2cgift_give_dialog.close(data);
            });
        },
        show : function() {
            return this.each(function(){
                var $this = $(this),
                    data = $this.data('b2cgift_give_dialog');
                $.fn.b2cgift_give_dialog.show(data);
            });
        },
        set_options : function(options) {
            var $this = $(this).first();
            data = $this.data('b2cgift_give_dialog');
            $.fn.b2cgift_give_dialog.set_options(data, options);
        },
        create_gift : function(templateModel) {
            var $this = $(this).first();
            var data = $this.data('b2cgift_give_dialog');
            if(templateModel) {
                // Gifts with custom quantity (B2CM-917)
                data.options.gift.quantity = templateModel.get('quantity');
                data.options.gift.transformation_type = templateModel.get('transformation_type') || null;
                data.options.gift.receipt_amount = templateModel.get('receiptAmount') || null;
            }
            $.fn.b2cgift_give_dialog.create_gift(data);
        },
    };

    $.fn.b2cgift_give_dialog = function( method ) {

        if ( methods[method] ) {
            return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ));
        } else if ( typeof method === 'object' || ! method ) {
            return methods.init.apply( this, arguments );
        } else {
            $.error( 'Cannot find ' +  method + ' in jQuery.b2cgift_give_dialog' );
        }

    };

    $.fn.b2cgift_give_dialog.defaults = {
        gift : null, //(Object in Server format) подарок, который собираемся дарить пользователю
        template : null, //(Object in Server format) шаблон подарок, на котором необходимо сгенерировать подарок
        user : null, //(Object in Server format) пользователь которому дарим
        user_id : null, //(integer) идентификатор пользователя, которому дарим подарк (нужен в случае отсутствия user)
        cl_task_id : null, //(integer) Идентификатор таска клиента, за который бизнес дарит подарок
        cl_comment_id : null, //(integer) Идентификатор коммента клиента, за который бизнес дарит подарок
        comment_id : null, //(integer) Идентификатор коммента бизнеса, к которому прикрепляется подарок
        on_cancel : null, //(function) функция обратного вызова, в случае отмены
        on_created : null, //(function(gift, template)) функция обратного вызова, в случае создания гифта на сервере
        on_before_send_on_server : null, //(function(gift, template)) функция обратного вызова, вызывается перед отправкоой гифта на сервер. Если on_before_send_on_server возвращает false, то отправка не происходит

        cl_main : 'b2cgift_give_dialog',

        cl_info_popup : 'b2c_info_popup',
        cl_info_popup_message : 'message',
        
    };

    $.fn.b2cgift_give_dialog.set_options = function(data, options) {
        data.options = $.extend(true, {}, $.fn.b2cgift_give_dialog.defaults, options);
        data.is_gift_created = false;
        $.fn.b2cgift_give_dialog.init_options(data);

        $.fn.b2cgift_give_dialog.build_jq_gift(data);
    };

    $.fn.b2cgift_give_dialog.init_options = function(data) {
        var options = data.options;
        if (options.user != null)
            options.user_id = options.user.id;
        if (options.gift == null) {
            options.gift = B2CGift.generate_gift(options.template, options.user_id, {
                cl_task_id : options.cl_task_id,
                cl_comment_id : options.cl_comment_id,
                comment_id : options.comment_id,
            })
        }
    };

    $.fn.b2cgift_give_dialog.init = function(data) {
        var html = $.fn.b2cgift_give_dialog.build_HTML(data);
        data.$this.b2cpopup({
            html_content : html,
            lightMode : false,
            onClose : function() { $.fn.b2cgift_give_dialog.on_main_popup_close(data); }
        });

        jq_dialog_cont = data.$this.b2cpopup('getUserCont');
        data.jq_dialog_cont = jq_dialog_cont;

        $.fn.b2cgift_give_dialog.build_jq_gift(data);

        $.fn.b2cgift_give_dialog.add_handlers(data);
    };

    $.fn.b2cgift_give_dialog.build_jq_gift = function(data) {
        var options = data.options;

        var jq_gift_cont = jq_dialog_cont.children('.gift_cont');
        jq_gift_cont.children().remove();

        var b2c_gift = new B2CGift({
            gift : options.gift,
            template : options.template,
            is_can_writeoff : false,
            is_gifted : false,
        });
        b2c_gift.build_jq_gift(jq_gift_cont);
    };

    $.fn.b2cgift_give_dialog.build_HTML = function(data) {
        var options = data.options;
        var to = '';
        if (options.user != null) {
            to = ' to';
            if(options.user.firstname != null)
                to += ' '+options.user.firstname;
            if(options.user.lastname != null)
                to += ' '+options.user.lastname;
        }
        var message = '<p class="message">Give this gift'+escapeHtml(to)+'?</p>';

        return '<div class="b2cgift_give_dialog_cont"><div class="gift_cont"></div>'+message+'<div class="yes_no_cont"><span class="b2c_btn_yes">Yes</span><span class="b2c_btn_no">No</span></div></div>';
    };    

    $.fn.b2cgift_give_dialog.add_handlers = function(data) {
        var jq_yes_no_cont = data.jq_dialog_cont.children('.yes_no_cont');
        jq_yes_no_cont.find('.b2c_btn_no').click(function() { $.fn.b2cgift_give_dialog.on_btn_cancel_click(data) });
        jq_yes_no_cont.find('.b2c_btn_yes').click(function() { $.fn.b2cgift_give_dialog.on_btn_yes_click(data) });
    };

    $.fn.b2cgift_give_dialog.create_gift = function(data) {
        var options = data.options;

        var gifts = [];
        gifts.push(options.gift);
        var onSuccess = function(serverData) { $.fn.b2cgift_give_dialog.on_server_create_gift_ok(data, serverData) };
        var onError = function(jqXHR, textStatus, errorThrown) { $.fn.b2cgift_give_dialog.on_server_create_gift_error(data, jqXHR, textStatus, errorThrown) };
        B2CGift.server_create(gifts, onSuccess, onError);
    };

    $.fn.b2cgift_give_dialog.on_btn_cancel_click = function(data) {
        $.fn.b2cgift_give_dialog.close(data);
    };

    $.fn.b2cgift_give_dialog.on_btn_yes_click = function(data) {
        var options = data.options;
        if (options.on_before_send_on_server != null) {
            if(! options.on_before_send_on_server(options.gift, options.template))
                return;
        }
        $.fn.b2cgift_give_dialog.create_gift(data);
    };

    $.fn.b2cgift_give_dialog.on_server_create_gift_ok = function(widgetData, serverData) {
        var options = widgetData.options;

        var created_at = B2Cjs.datetimeJSToServer(new Date);
        var b_user = LoginedUserHandler.getLoginedUser();
        options.gift.id = serverData.ids[0];
        options.gift.created_at = created_at;
        options.gift.created_by = b_user.id;

        widgetData.is_gift_created = true;

        $.fn.b2cgift_give_dialog.close(widgetData);

        if (options.on_created != null)
            options.on_created(options.gift, options.template);
    };

    $.fn.b2cgift_give_dialog.on_server_create_gift_error = function(widgetData, jqXHR, textStatus, errorThrown) {
        var message = 'Some error occurred';
        if (jqXHR.responseJSON != null)
            message += ': ' + jqXHR.responseJSON.message;
        $.fn.b2cgift_give_dialog.showMessage(widgetData, message);
    };

    $.fn.b2cgift_give_dialog.on_main_popup_close = function(data) {
        $.fn.b2cgift_give_dialog.close(data, true);
    };

    $.fn.b2cgift_give_dialog.showMessage = function(data, message, onClose) {
        if (data.jqInfoPopup == null) {
            data.jqInfoPopup = $('<div class="'+data.options.cl_info_popup+'"></div>').appendTo(data.$this).b2cpopup({
                html_content : '<p class="'+data.options.cl_info_popup+'"></p>',
                lightMode : true
            });
            data.jqInfoPopup.b2cpopup('getUserCont').click(function(event) { data.jqInfoPopup.b2cpopup('close'); });
        }
        data.jqInfoPopup.b2cpopup('option', 'onClose', onClose);
        data.jqInfoPopup.b2cpopup('getUserCont').text(message);
        data.jqInfoPopup.b2cpopup('show');
    };

    $.fn.b2cgift_give_dialog.show = function(data){
        data.$this.b2cpopup('show');
    };

    $.fn.b2cgift_give_dialog.close = function(data, isCallFrom_b2cpopup){
        if(!isCallFrom_b2cpopup)
            data.$this.b2cpopup('close');
        else if(!data.is_gift_created && data.options.on_cancel != null)
            data.options.on_cancel();
    };       

    

})( jQuery );