import React from 'react';
import PropTypes from 'prop-types';

import KeyboardPadding from 'components/KeyboardPadding';
import useEventListener from '../../hooks/useEventListener';
import 'pages/Page.scss';

export const PageHeader = (props) => {
    const classNames = ['header-region', props.className, !props.children && 'no-children'].filter(Boolean);

    return (
        <div className={classNames.join(' ')} data-js-header>
            {props.children}
        </div>
    );
};

PageHeader.propTypes = {
    className: PropTypes.string,
};

export const PageContent = (props) => {
    const ref = React.useRef(null);
    const classNames = ['content-region', props.className, !props.children && 'no-children'].filter(Boolean);

    return (
        <div ref={props.forwardedRef || ref} className={classNames.join(' ')} data-js-content>
            {props.children}
        </div>
    );
};

PageContent.propTypes = {
    className: PropTypes.string,
};

export const PageFooter = (props) => {
    const classNames = ['footer-region', props.className, !props.children && 'no-children'].filter(Boolean);

    return (
        <div className={classNames.join(' ')} data-js-footer>
            {props.children}
        </div>
    );
};

PageFooter.propTypes = {
    className: PropTypes.string,
};

const Page = (props) => {
    const { onShow = _.noop, onHide = _.noop } = props;
    const [time, setTime] = React.useState({ show: 0, hide: 0 });

    const afterShow = React.useCallback(() => {
        setTime((time) => {
            const now = Date.now();
            const newValue = { ...time, show: now };

            onShow.call(null, { ...newValue, idle: time.hide > 0 ? now - time.hide : 0 });
            return newValue;
        });
    }, [onShow]);

    const beforeHide = React.useCallback(() => {
        setTime((time) => {
            const newValue = { ...time, hide: Date.now() };

            onHide.call(null, newValue);
            return newValue;
        });
    }, [onHide]);

    useEventListener(props.instance, 'after:show', afterShow);
    useEventListener(props.instance, 'before:hide', beforeHide);

    return (
        <>
            {props.children}
            {props.appendKeyboardPadding && <KeyboardPadding />}
        </>
    );
};

export const PagePropTypes = {
    instance: PropTypes.object.isRequired,
    onShow: PropTypes.func,
    onHide: PropTypes.func,
    appendKeyboardPadding: PropTypes.bool,
};

Page.propTypes = PagePropTypes;

export default Page;
