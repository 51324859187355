import template from './stars.jade';
import './stars.scss';

const bindingHandler = {
    init($el) {
        $el.html(template({ simple: true })).addClass('widget-lite-stars');
    },
    set($el, rating) {
        this._currentRating = rating;

        $el.children('i').each((i, el) => {
            const level = i + 1;
            if (level <= rating) {
                el.classList.remove('ion-ios-star-half', 'ion-ios-star-outline');
                el.classList.add('ion-ios-star');
            } else if (level - 0.5 <= rating) {
                el.classList.remove('ion-ios-star', 'ion-ios-star-outline');
                el.classList.add('ion-ios-star-half');
            } else {
                el.classList.remove('ion-ios-star', 'ion-ios-star-half');
                el.classList.add('ion-ios-star-outline');
            }
        });
    }
};

const bindingHandlerInteractive = _.extend({}, bindingHandler, {
    init($el) {
        const eventName = this.evt = "star:selected.epoxy";
        $el.html(template({ simple: true, interactive: true })).addClass('widget-lite-stars').attr('data-changable', true);
        const self = this;
        $el.children().click(function () {
            const index = this.dataset.index || 1;

            if (self._currentRating != index) {
                const event = new $.Event(eventName);
                event.rating = index;
                $el.trigger(event);
            } else {
                const event = new $.Event(eventName);
                event.rating = 0;
                $el.trigger(event);
            }
        });
    },
    get($el, currentRating, e) {
        return Number(e.rating) || 0;
    },
    isChangable: true
});

function stars(rating) {
    return template({ simple: false, rating });
}

function stars$(rating) {
    return $('<div class="widget-lite-stars">').html(stars(rating));
}

export { stars as default, stars$, bindingHandler, bindingHandlerInteractive };