import Page from 'pages/Page';
import PlaceEditorWindow from 'windows/PlaceEditor/PlaceEditor';

import ViewWithWindows from 'traits/ViewWithWindows';
import { PlaceSearchCore } from 'windows/PlaceSearch/PlaceSearchCore';

const withWindowsTrait = {
    Trait: ViewWithWindows,
    options: {
        windowMap: [
            {
                cls: PlaceEditorWindow,
                trigger() {
                    this.results.currentView.trigger('addnew:click');
                }
            }
        ],
    }
};

let PlaceSearchPage = Page.extendWithTraits(
    [withWindowsTrait],
    _.defaults({
        attributes: {
            'id': "page_search_place",
        },
        className: "place-search-core place-search-page",
    }, PlaceSearchCore.protoProps),
    _.defaults({}, PlaceSearchCore.staticProps)
);

export default PlaceSearchPage;
