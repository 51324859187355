import { Required, ItemView, ViewModel } from '@b2cmessenger/backbone';

import TaskTargetModel from 'models/TaskTargetModel';

import template from './MessageTarget.jade';
import './MessageTarget.scss';

/** @type {(properties: import('./MessageTarget').Properties) => (target: any) => typeof MessageTargetView} */
const properties = ItemView.properties;

/** @type {(options: import('./MessageTarget').Options) => (target: any) => typeof MessageTargetView} */
const options = ItemView.options;

@options({
    model: Required
})
@properties({
    className: 'widget message-target-widget',
    template,

    bindings: {
        ':el': 'classes:{expanded:isExpanded}'
    },

    events: {
        'click'() {
            this.viewModel.set({
                isExpanded: !this.viewModel.get('isExpanded')
            })
        }
    },

    templateHelpers: {
        Status: TaskTargetModel.Status
    }
})
class MessageTargetView extends ItemView {
    initialize() {
        this.viewModel = new ViewModel({
            isExpanded: false,
        });
    }
};

export default MessageTargetView;
