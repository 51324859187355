import UserModel from 'models/UserModel';

const Roles = {
    MESSAGE_MODERATOR: 1, // Может редактировать и удалять сообщения других сотрудников плейса
    MESSAGE_CREATOR: 2, // Может создавать, редактировать и удалять сообщения в рамках Place, в котором он является сотрудником
    PLACE_MANAGER: 3, // Может редактировать профиль компании, менять лого, добавлять фотографии в галлерею
    EMPLOYEE_MANAGER: 4, // Может добавлять и удалять сотрудников компании, назначать им роли
    EVIDENCE_MANAGER: 5, // Может просматривать, принимать или отклонять пользовательские доказательства, что пользоватлся услогами компании (Чекины, Бизнес чекинны, Карты лояльности, Фото-доказательсва)
    GIFT_TEMPLATE_MANAGER: 6, // Может создавать, редактировать и удалять шаблоны подарков в рамках бренда, которому принадлежит Place в котром он работает
    GIFT_CREATOR: 7, // Может дарить пользователю подарки, а тажке списаовать их
    RESERVATION_MANAGER: 8,
    MENU_MANAGER: 9,
    WAITER: 10,
    ORDER_MANAGER: 11
}

@UserModel.extendProperties({
    defaults: {
        workplaceRoles: null
    },
    computeds: {
        isLoggedIn: {
            deps: ['id'],
            get: id => !!id
        },
        workplaces: {
            deps: ['workplaceRoles'],
            get: workplaceRoles => workplaceRoles && _.keys(workplaceRoles) || []
        }
    }
})
class LoginedUserModel extends UserModel {
    isEmployee(placeId) {
        const workplacesRoles = this.get('workplaceRoles');
        return workplacesRoles && _.any(workplacesRoles, (roles, workplaceId) => workplaceId == placeId);
    }

    hasRoleInPlace(placeId, role) {
        const workplacesRoles = this.get('workplaceRoles');
        return workplacesRoles && _.any(workplacesRoles, (roles, workplaceId) => {
            return workplaceId == placeId && _.any(roles, r => r == role);
        });
    }

    /**@param {{ asUserModel?: boolean }} [options] */
    clone(options) {
        if (options && options.asUserModel) {
            return new UserModel(this.omit(['workplaceRoles']));
        } else {
            return super.clone();
        }
    }
};

LoginedUserModel.Roles = Roles;

export default LoginedUserModel;