import { Collection, Optional } from '@b2cmessenger/backbone';
import CalendarModel from './CalendarModel';
import moment from 'moment-timezone';

const CALENDAR_GRID_SIZE = 42; // 7 * 6 строк

const CalendarCollection = Collection.extend({
    setMonthGrid(value) {
        const currentDate = value ? moment(value) : moment();

        if (!currentDate.isValid()) {
            throw new Error('[ERROR] Invalid date');
        }

        const daysBeforeStartOfMonth = moment(currentDate).startOf('month').weekday();
        const start = moment(currentDate).startOf('month').subtract(daysBeforeStartOfMonth, 'days');

        let days = [];

        for (let i = 0; i < CALENDAR_GRID_SIZE; i++) {
            const momentDate = moment(start).add(i, 'day');
            days.push({
                id: momentDate.toDate().setHours(0, 0, 0, 0)
            });
        }

        if (!this.currentDateMoment.isSame(currentDate, 'day')) {
            this.currentDateMoment = currentDate;
            this.trigger('change:date', moment(this.currentDateMoment).format('MMMM YYYY'));
        }

        this.reset(days);

        this.start = new Date(this.first().get('id'));
        this.end = new Date(this.last().get('id'));
    },

    setNextMonthGrid() {
        this.setMonthGrid(this.currentDateMoment.add(1, 'month'));
    },

    setPrevMonthGrid() {
        this.setMonthGrid(this.currentDateMoment.subtract(1, 'month'));
    },

    options: {
        date: Optional,
    },

    model: CalendarModel,

    initialize() {
        this.currentDateMoment = this.options.date ? moment(this.options.date) : moment();
        this.setMonthGrid(this.currentDateMoment);
    }
});

export default CalendarCollection;
