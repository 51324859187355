var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (level, rating, simple) {if (simple) {
// iterate [1, 2, 3, 4, 5]
;(function(){
  var $$obj = [1, 2, 3, 4, 5];
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var i = $$obj[pug_index0];
pug_html = pug_html + "\u003Ci" + (pug.attr("data-index", i, true, true)) + "\u003E\u003C\u002Fi\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var i = $$obj[pug_index0];
pug_html = pug_html + "\u003Ci" + (pug.attr("data-index", i, true, true)) + "\u003E\u003C\u002Fi\u003E";
    }
  }
}).call(this);

}
else {
// iterate [1, 2, 3, 4, 5]
;(function(){
  var $$obj = [1, 2, 3, 4, 5];
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var i = $$obj[pug_index1];
if (i <= rating) {
pug_html = pug_html + "\u003Ci" + (" class=\"ion-ios-star\""+pug.attr("data-index", i, true, true)) + "\u003E\u003C\u002Fi\u003E";
}
else
if (level - 0.5 <= rating) {
pug_html = pug_html + "\u003Ci" + (" class=\"ion-ios-star-half\""+pug.attr("data-index", i, true, true)) + "\u003E\u003C\u002Fi\u003E";
}
else {
pug_html = pug_html + "\u003Ci" + (" class=\"ion-ios-star-outline\""+pug.attr("data-index", i, true, true)) + "\u003E\u003C\u002Fi\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var i = $$obj[pug_index1];
if (i <= rating) {
pug_html = pug_html + "\u003Ci" + (" class=\"ion-ios-star\""+pug.attr("data-index", i, true, true)) + "\u003E\u003C\u002Fi\u003E";
}
else
if (level - 0.5 <= rating) {
pug_html = pug_html + "\u003Ci" + (" class=\"ion-ios-star-half\""+pug.attr("data-index", i, true, true)) + "\u003E\u003C\u002Fi\u003E";
}
else {
pug_html = pug_html + "\u003Ci" + (" class=\"ion-ios-star-outline\""+pug.attr("data-index", i, true, true)) + "\u003E\u003C\u002Fi\u003E";
}
    }
  }
}).call(this);

}}.call(this,"level" in locals_for_with?locals_for_with.level:typeof level!=="undefined"?level:undefined,"rating" in locals_for_with?locals_for_with.rating:typeof rating!=="undefined"?rating:undefined,"simple" in locals_for_with?locals_for_with.simple:typeof simple!=="undefined"?simple:undefined));;return pug_html;};
module.exports = template;