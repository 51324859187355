import { Collection } from '@b2cmessenger/backbone';
import PlaceChooserWindow from './PlaceChooser';
import PlaceModel from '../../models/PlaceModel';

let DefaultWorkPlaceChooserWindow = PlaceChooserWindow.extend({
    windowName: "default-work-place-chooser-window",
    className: "place-chooser-window default-work-place-chooser-window",

    initialize(options) {
        if(!options.collection)
            options.collection = new Collection(LoginedUserHandler.get_user_work_places(), {
                model: PlaceModel
            });

        options.strictSingle = true;

        let defaultPlace = options.collection.findWhere({ id: Number(LoginedUserHandler.get_default_work_place_id()) });
        if(defaultPlace) defaultPlace.set('cl_selected', true);

        PlaceChooserWindow.prototype.initialize.call(this, options);
    },

    onRender() {
        PlaceChooserWindow.prototype.onRender.call(this);

        if(this.options.closeOnSelect)
            this.listenTo(this.content.currentView, 'select', () => this.close(this.collection));
    },

    onClose(collection) {
        let place = collection.findWhere({ cl_selected: true });
        if(place) {
            LoginedUserHandler.set_default_work_place_id(place.id);
        }
        return place;
    }
});

export default DefaultWorkPlaceChooserWindow;

//TODO: remvoe globals!
window.DefaultWorkPlaceChooserWindow = DefaultWorkPlaceChooserWindow;
