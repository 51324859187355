import React from 'react';

import './MenuItemMarker.scss';

export const markersClassMap = {
    spicy: {
        value: null,
        keyByServer: 'marker_spicy',
        text: 'Spicy',
    },
    vegan: {
        value: null,
        keyByServer: 'marker_vegan',
        text: 'Vegan',
    },
    glutenfree: {
        value: null,
        keyByServer: 'marker_glutenfree',
        text: 'Gluten free',
    },
    sugarfree: {
        value: null,
        keyByServer: 'marker_sugarfree',
        text: 'Sugar free',
    },
    lactosefree: {
        value: null,
        keyByServer: 'marker_lactosefree',
        text: 'Lactose free',
    },
};

const MenuItemMarker = ({ type, caption }) => {
    const className = ['marker-icon', type].filter(Boolean).join(' ');

    return (
        <>
            <i className={className} />
            {caption && <span>{caption}</span>}
        </>
    );
};

export default MenuItemMarker;
