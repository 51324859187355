import * as Api from './Api';
import settings from "settings";
import {filterObject} from "store/utils";
import {getPaginationHeadersFromXhr} from "./Api";
import UserModel from "models/UserModel";

export const UnlistedCategory = {
    id: -1,
    is_addition: 0,
    name: 'Unlisted',
    message: 'Stores items which don\'t belong to any category',
    icon: 'ion-ios-list',
    parent_id: null,
    sort_order: Infinity
};

export async function loadMenuInfo({ placeId }) {
    const url = settings.host + settings.serv_place.menu.info;

    return Api.call({
        url: url.replace('${place_id}', placeId)
    })
        .then(data => {
            return { ...data, isFood: Boolean(data.is_food) }
        });
}

export async function saveMenuInfo({ placeId, currency = null, isFood = null }) {
    const url = settings.host + settings.serv_place.menu.info;
    const data = {};

    if (currency !== null) {
        data.currency = currency;
    }

    if (isFood !== null) {
        data.is_food = isFood ? 1 : 0;
    }

    return Api.call({
        method: 'PATCH',
        url: url.replace('${place_id}', placeId),
        contentType: 'application/json',
        data: JSON.stringify(data)
    });
}

export async function loadCategories({ placeId, includeUnlisted = false }) {
    return Api.call({
        url: settings.host + settings.serv_place.menu.categories.replace('${place_id}', placeId)
    }).then(categories => {
        if (includeUnlisted) {
            return [...categories, UnlistedCategory];
        }

        return categories;
    });
}

export async function loadCategory({ placeId, id, includeUnlisted = false }) {
    if (id === UnlistedCategory.id) {
        return loadUnlistedCategory({ placeId });
    }

    if (id === null) {
        return loadCategories({ placeId, includeUnlisted })
            .then(categories => ({ categories, items: [] }));
    }

    const url = settings.host + settings.serv_place.menu.category;

    return Api.call({
        url: url.replace('${place_id}', placeId).replace('${category_id}', id)
    });
}

export async function loadUnlistedCategory({ placeId }) {
    const url = settings.host + settings.serv_place.menu.unlisted;

    return Api.call({
        url: url.replace('${place_id}', placeId)
    }).then(response => ({ categories: [], items: response }));
}

export async function removeItem({ placeId, id, categoryId = null }) {
    if (categoryId !== null) {
        return removeItemFromCategory({ placeId, id, categoryId });
    }

    const url = settings.host + settings.serv_place.menu.item;

    return Api.call({
        method: 'DELETE',
        url: url.replace('${place_id}', placeId).replace('${item_id}', id)
    });
}

export async function removeItemFromCategory({ placeId, id, categoryId = null }) {
    const url = settings.host + settings.serv_place.menu.item_category;

    return Api.call({
        method: 'DELETE',
        url: url
            .replace('${place_id}', placeId)
            .replace('${category_id}', categoryId)
            .replace('${item_id}', id)
    });
}

export async function hideCategory({ placeId, id, period = null }) {
    const url = settings.host + settings.serv_place.menu.hide_category + (period > 0 ? `?for=${period}` : '');

    return Api.call({
        method: 'POST',
        url: url.replace('${place_id}', placeId).replace('${category_id}', id)
    });
}

export async function removeHideFromCategory({ placeId, id }) {
    const url = settings.host + settings.serv_place.menu.hide_category;

    return Api.call({
        method: 'DELETE',
        url: url.replace('${place_id}', placeId).replace('${category_id}', id)
    });
}

export async function hideItem({ placeId, id, period = null }) {
    const url = settings.host + settings.serv_place.menu.hide_item + (period > 0 ? `?for=${period}` : '');

    return Api.call({
        method: 'POST',
        url: url.replace('${place_id}', placeId).replace('${item_id}', id)
    });
}

export async function removeHideFromItem({ placeId, id }) {
    const url = settings.host + settings.serv_place.menu.hide_item;

    return Api.call({
        method: 'DELETE',
        url: url.replace('${place_id}', placeId).replace('${item_id}', id)
    });
}

export async function loadItem({ placeId, id, fetchOptions = {} }) {
    const url = settings.host + settings.serv_place.menu.detail;

    return Api.call({
        url: url.replace('${place_id}', placeId).replace('${item_id}', id)
    }, fetchOptions);
}

export async function loadItems({ placeId, ids, fetchOptions = {} }) {
    const url = settings.host + settings.serv_place.menu.detailed_items;

    return Api.call({
        url: url.replace('${place_id}', placeId)
    }, _.extend(fetchOptions, { ids }));
}

export async function uploadItem({ placeId, id, data }) {
    console.log(id);
    const isNew = !Boolean(id);
    const urlBase = settings.host + settings.serv_place.menu[isNew ? 'add_item' : 'detail'];
    const url = isNew ? urlBase.replace('${place_id}', placeId) :
        urlBase.replace('${place_id}', placeId).replace('${item_id}', id);
    const type = isNew ? 'POST' : 'PATCH';

    return Api.call({
        url,
        type,
        data,
        contentType: 'application/json'
    })
}

export async function changeMenuElementsOrder({ placeId, data }) {
    const url = settings.host + settings.serv_place.menu.change_sort;

    return Api.call({
        url: url.replace('${place_id}', placeId),
        type: 'POST',
        data,
        contentType: 'application/json'
    });
}

export async function getWholeMenu({ placeId }) {
    const categories = await loadCategories({ placeId });

    const items = [];
    const itemsMap = {};
    const uniqueItemIds = [];

    for (const category of categories) {
        const { items } = await loadCategory({ placeId, id: category.id });
        const itemsIds = items.map(i => i.id);

        for (const itemId of itemsIds) {
            if (uniqueItemIds.indexOf(itemId) < 0) {
                uniqueItemIds.push(itemId);
            }
        }

        itemsMap[category.id] = itemsIds;
    }

    for (const itemId of uniqueItemIds) {
        const item = await loadItem({ placeId, id: itemId });
        items.push(item);
    }

/////////////////
/////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////////////

    return { categories, items, itemsMap };
}

export async function uploadOrder({ placeId, data, orderId }) {
    const url = [settings.host + settings.serv_place.menu.order, orderId ? `/${orderId}` : ''].filter(Boolean).join('');
    const method = orderId ? 'PATCH' : 'POST';

    return Api.call({
        url: url.replace('${place_id}', placeId),
        method,
        contentType: 'application/json',
        data: JSON.stringify({ ...data })
    });
}

export async function changeOrder(options) {
    const { placeId, id, data, items } = options;

    const url = [settings.host + settings.serv_place.menu.order, id].join('/');
    const parameters = filterObject({
        include_user: Number(Boolean(options.includeUser)),
        include_place: Number(Boolean(options.includePlace)),
    }, (k, v) => Boolean(v));

    return Api.call({
        url: url.replace('${place_id}', placeId),
        method: 'PATCH',
        contentType: 'application/json',
        data: JSON.stringify({ data, items })
    }, parameters);
}

export async function loadOrders(options) {
    const url = settings.host + settings.serv_place.menu.orders;

    const parameters = filterObject({
        user_id: options.userId,
        status: _.isArray(options.status) && options.status || undefined,
        section_id: _.isNumber(options.sectionId) && options.sectionId || undefined,
        table_id: _.isNumber(options.tableId) && options.tableId || undefined,
        waiter_user_id: _.isNumber(options.waiterId) && options.waiterId || undefined,
        created_at_local_before: options.createdBefore && B2Cjs.datetimeJSToServerLocal(options.createdBefore),
        created_at_local_after: options.createdAfter && B2Cjs.datetimeJSToServerLocal(options.createdAfter),
        page: options.page,
        'per-page': options.pageSize,
        sort: options.sort,
        include_user: Number(Boolean(options.includeUser)),
        include_place: Number(Boolean(options.includePlace)),
        include_waiterRequests: Number(Boolean(options.includeWaiterRequests)),
        ids: options.ids || undefined
    }, (k, v) => Boolean(v));

    return Api.extendedCall({
        url: url.replace('${place_id}', options.placeId),
        method: 'GET'
    }, parameters)
        .then(([orders, resp, xhr]) => {
            return {
                orders: orders.map(o =>
                    ({
                        ...o,
                        created_at: B2Cjs.datetimeServerToJS(o.created_at).getTime(),
                        updated_at: B2Cjs.datetimeServerToJS(o.updated_at).getTime(),
                    })
                ),
                pagination: getPaginationHeadersFromXhr(xhr)
            };
        })
}

export async function loadOrder(options) {
    const { placeId, id} = options;

    const url = [settings.host + settings.serv_place.menu.order, id].join('/');
    const parameters = filterObject({
        include_waiterRequests: Number(Boolean(options.includeWaiterRequests)),
    }, (k, v) => Boolean(v));

    return Api.call({
        url: url.replace('${place_id}', placeId),
        method: 'GET',
    }, parameters);
}

/**
 * 
 * @param {Object} options
 * @param {number[]} [options.status]
 * @param {string} [options.createdBefore]
 * @param {string} [options.createdAfter]
 * @param {number} [options.page]
 * @param {number} [options.pageSize]
 * @param {string} [options.sort]
 * @param {number} [options.tableId]
 * @param {boolean} [options.includePlace=0]
 * @param {boolean} [options.includeWaiterRequests=0]
 * @param {number[]} [options.ids]
 * 
 * @returns {Promise<{pagination: {pageCount: number, pageSize: number, page: number, totalCount: number}, orders: *}>}
 */
export async function loadUserOrders(options) {
    const url = settings.host + settings.serv_user.orders;

    const parameters = filterObject({
        status: _.isArray(options.status) && options.status || undefined,
        created_at_local_before: options.createdBefore && B2Cjs.datetimeJSToServerLocal(options.createdBefore),
        created_at_local_after: options.createdAfter && B2Cjs.datetimeJSToServerLocal(options.createdAfter),
        page: options.page,
        ['per-page']: options.pageSize,
        sort: options.sort,
        table_id: _.isNumber(options.tableId) && options.tableId || undefined,
        include_place: Number(Boolean(options.includePlace)),
        include_waiterRequests: Number(Boolean(options.includeWaiterRequests)),
        ids: options.ids || undefined
    }, (k, v) => Boolean(v));

    return Api.extendedCall({
        url,
        method: 'GET'
    }, parameters)
        .then(([orders, resp, xhr]) => {
            return {
                orders,
                pagination: getPaginationHeadersFromXhr(xhr)
            };
        })
}

export async function loadWaiters(options) {
    const url = settings.host + settings.serv_place.menu.waiters;

    return Api.call({
        url: url.replace('${place_id}', options.placeId),
        method: 'GET'
    })
        .then(waiters => {
            return waiters.map(w => (new UserModel(w)).toJSON())
        });
}

export async function loadWorkingWaitersIds(options) {
    const url = settings.host + settings.serv_place.menu.waiters + '/working';
    return Api.call({
        url: url.replace('${place_id}', options.placeId),
        method: 'GET'
    });
}

export async function changeEmployeeStatus(options) {
    const url = settings.host + settings.serv_place.menu.employee_status;

    return Api.call({
        url: url.replace('${place_id}', options.placeId),
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({ status: options.status })
    })
}

export async function changeEmployeeStatusById(options) {
    const url = settings.host + settings.serv_place.menu.employee_status_by_id;

    return Api.call({
        url: url.replace('${place_id}', options.placeId).replace('${user_id}', options.userId),
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({ status: options.status })
    })
}

export async function search({placeId, query}) {
    const url = settings.host + settings.serv_place.menu.search;
    const fetchOptions = {query};

    return Api.call({
        url: url.replace('${place_id}', placeId),
    }, fetchOptions);
}


/**
 * @param {Object} order
 * @param {number} order.place_id
 * @param {number} order.id
 * @param {Object} options
 * @param {string} options.comment
 * @param {number} options.action
 * @param {number} options.options
 *
 * @returns {Promise<unknown>}
 */
export async function orderCallWaiter(order, options) {
    const url = settings.host + settings.serv_place.menu.call_waiter.order_call_waiter;

    return Api.call({
        url: url.replace('${place_id}', '' + order.place_id).replace('${order_id}', '' + order.id),
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
            comment: options.comment,
            action: options.action,
            options: options.options,
        })
    })
}

/**
 * @param {number} placeId
 * @param {Object} options
 * @param {string} options.comment
 * @param {number} options.tableId
 *
 * @returns {Promise<unknown>}
 */
export async function callWaiter(placeId, options) {
    const url = settings.host + settings.serv_place.menu.call_waiter.call_waiter;

    return Api.call({
        url: url.replace('${place_id}', '' + placeId),
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
            comment: options.comment,
            table_id: options.tableId,
        })
    }, {
        include_waiterUser: 1
    })
}


/**
 * @param {number} placeId
 * @param {Object} options
 * @param {number} [options.hours=4]
 * @param {boolean} [options.includeUser=0]
 * @param {boolean} [options.includeOrder=0]
 *
 * @returns {Promise<unknown>}
 */
export async function fetchWaiterRequests(placeId, options) {
    const url = settings.host + settings.serv_place.menu.call_waiter.waiter_requests;

    return Api.call({
        url: url.replace('${place_id}', '' + placeId),
    }, {
        hours: options.hours || 4,
        include_user: Number(Boolean(options.includeUser)),
        include_order: Number(Boolean(options.includeOrder)),
    })
}

/**
 * @param {number} placeId
 * @param {Object} options
 * @param {number} [options.hours=4]
 * @param {boolean} [options.includeWaiterUser=0]
 * @param {boolean} [options.includeOrder=0]
 *
 * @returns {Promise<unknown>}
 */
export async function fetchCallWaiterHistory(placeId, options) {
    const url = settings.host + settings.serv_place.menu.call_waiter.call_waiter_history;

    return Api.call({
        url: url.replace('${place_id}', '' + placeId),
    }, {
        hours: options.hours || 4,
        include_waiterUser: Number(Boolean(options.includeWaiterUser)),
        include_order: Number(Boolean(options.includeOrder)),
    })
}
