var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (id, quantity, user) {pug_html = pug_html + "\u003Cdiv" + (" class=\"history-inner\""+pug.attr("data-id", id, true, true)) + "\u003E\u003Cdiv class=\"history-name\"\u003E\u003Cdiv class=\"title\" data-js-title\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"info\"\u003E\u003Cdiv class=\"who\"\u003E";
if (quantity > 0) {
pug_html = pug_html + "\u003Cspan\u003EGifted by\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan\u003EWritten-off by\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan\u003E " + (pug.escape(null == (pug_interp = user.firstname) ? "" : pug_interp)) + " " + (pug.escape(null == (pug_interp = user.lastname || '') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"expired when date\" data-js-expired\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"when date\" data-js-created-at\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"id" in locals_for_with?locals_for_with.id:typeof id!=="undefined"?id:undefined,"quantity" in locals_for_with?locals_for_with.quantity:typeof quantity!=="undefined"?quantity:undefined,"user" in locals_for_with?locals_for_with.user:typeof user!=="undefined"?user:undefined));;return pug_html;};
module.exports = template;