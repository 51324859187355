import React, { useCallback } from 'react';

import './ImageInput.scss';
import { ButtonFileInput } from 'components/FileInput';
import Spinner from 'components/UI/Spinner/Spinner';
import ImageInputHelpers from 'widgets/Inputs/ImageInputHelpers';
import { showError } from 'windows/Modal/Info';

const ImageInput = ({ photo, photo_thumb, onPhotoSelect, loadingPhoto }) => {
    const hasPhoto = ((photo === null || Number(photo) > 0) && _.isString(photo_thumb)) || photo_thumb;
    const className = ['image-input', !hasPhoto && 'empty'].filter(Boolean).join(' ');

    const placeholderText = hasPhoto ? 'Tap to change' : 'Add photo';
    const previewStyle = { backgroundImage: (photo_thumb && `url(${photo_thumb})`) || null };

    let content = null;
    if (loadingPhoto) {
        content = <Spinner />;
    } else {
        content = (
            <ButtonFileInput onChange={onPhotoSelect} className="wide">
                <div className="photo-preview" style={previewStyle}>
                    <div className="photo-placeholder">{placeholderText}</div>
                </div>
            </ButtonFileInput>
        );
    }

    return <div className={className}>{content}</div>;
};

export const ImageInputWithUpload = ({ photo, photo_thumb, loadingPhoto, setLoadingPhoto, onChange, type = '' }) => {
    const onPhotoSelect = useCallback(
        (event) => {
            setLoadingPhoto(true);
            ImageInputHelpers.uploadFromDOMEvent(type, event)
                .then((data) => {
                    setLoadingPhoto(false);
                    onChange({
                        photo: data.id,
                        photo_thumb: data.full || data['1280'] || data['640'] || data['320'],
                    });
                })
                .catch((e) => {
                    showError(e instanceof Error ? e.message : e);
                    setLoadingPhoto(false);
                });
        },
        [showError]
    );

    return (
        <ImageInput
            {...{
                photo,
                photo_thumb,
                loadingPhoto,
                onPhotoSelect,
            }}
        />
    );
};

export default ImageInput;
