import { Trait, Model } from '@b2cmessenger/backbone';
import Scrollparent from 'scrollparent';
import AjaxError from 'utils/AjaxError';
import 'utils/Element.scrollIntoCenter';
import './RegionWithPagination.scss';
import ViewModel from '@b2cmessenger/backbone/src/ViewModel';

const RegionWithPaginationModel = Model.extend({
    computeds: {
        'canBePulledToRefresh': {
            deps: ['isLoading'],
            get: isLoading => !isLoading
        },
        'pageSize': {
            deps: ['_pageSize'],
            get: _pageSize => _pageSize,
            set(val) {
                const newPageSize = Math.max((val) || 30, 1),
                    oldPageSize = Math.max(Number(this.get('_pageSize')) || 30, 1);

                if (newPageSize != oldPageSize) {
                    const oldPages = this.get('pages'),
                        newPages = [];

                    let step = Math.min(newPageSize, oldPageSize);

                    const now = new Date;
                    for (let i = 0; i < oldPages.length * oldPageSize; i += step) {
                        const
                            oldPageIndex = Math.floor(i / oldPageSize),
                            oldPage = oldPages[oldPageIndex],
                            newPageIndex = Math.floor(i / newPageSize),
                            newPage = newPages[newPageIndex] || (newPages[newPageIndex] = {
                                status: 1,
                                items: [],
                                updatedAt: now
                            });

                        step = Math.min((oldPageIndex + 1) * oldPageSize, (newPageIndex + 1) * newPageSize) - i;

                        if (!oldPage || oldPage.status != 1) {
                            newPage.status = Math.min(newPage.status, oldPage ? oldPage.status : 0);
                        }

                        if (oldPage) {
                            const oldStart = i - oldPageIndex * oldPageSize,
                                oldEnd = oldStart + step;

                            newPage.items.push.apply(
                                newPage.items,
                                oldPage.items.slice(oldStart, oldEnd)
                            );

                            if (newPage.updatedAt > oldPage.updatedAt) {
                                newPage.updatedAt = oldPage.updatedAt;
                            }
                        }
                    }

                    oldPages.splice(0, oldPages.length, ...newPages);
                }

                return {
                    _pageSize: newPageSize,
                };
            }
        }
    }
});

const traitId = Symbol("RegionWithPagination");

class RegionWithPagination extends Trait {
    constructor(options) {
        super(traitId);

        _.extend(this.options, _.defaults(options || (options = {}), {
            pageSize: 30,
            collection() {
                return this.collection;
            },
            region() {
                return this.content;
            },
            parentViewModel: undefined,
            collectionView: undefined,
            wrapperContainerElement: undefined,
            initializeOnAttach: false,
            pageFreshPeriod: 60000
        }));

        _.extend(this.parentOptions, _.pick(this.options, ['pageSize']));
        if (this.options.parentViewModel) {
            this._resultOption('parentViewModel');
        }
        this._resultOption('collection');
        this._resultOption('region', { allowStringAsRegionName: true });
        if (this.options.region) {
            const trait = this;
            _.defaults(this.options, {
                collectionView() {
                    return trait.region.currentView;
                },
                wrapperContainerElement() {
                    return trait.region.$el
                }
            });
        }
        this._resultOption('collectionView');
        this._resultOption('wrapperContainerElement', { allowStringAsSelector: true });

        const trait = this,
            debouncedLoadNextPage = _.debounce(this._loadNextPage.bind(this), 1000, true),
            debouncedCheckPagination = _.debounce(function (e) {
                if (!e || !e.isDefaultPrevented()) {
                    if (!trait._checkIfScrollNearEnd()) {
                        _.delay(() => {
                            if (!trait._checkIfScrollNearEnd()) {
                                trait._checkAndLoadCurrentPage()
                            }
                        }, 300);
                    }
                }
            }, 500, true);

        _.extend(this.methods, {
            refresh: this.refresh,
            loadNextPage(f, trait, ...args) {
                debouncedLoadNextPage();

                f && f.apply(this, args);
            },
            checkPagination(f, trait, ...args) {
                debouncedCheckPagination();

                f && f.apply(this, args);
            },
            onScrollNearEnd: this.onScrollNearEnd,
        }, this.options.initializeOnAttach ? {
            onAttach: this.onRenderOrAttach
        } : {
            onRender: this.onRenderOrAttach
        });

        _.extend(this.events, {
            'scrollstop': debouncedCheckPagination,
        });

        this.model = new RegionWithPaginationModel({
            refreshPromise: Promise.resolve(),
            pages: [],
            pageSize: this.options.pageSize,
            isLoading: false,
            isRefreshing: false
        });
    };

    initialize(f, trait, ...args) {
        if (this.options.pageSize) {
            trait.model.set({ pageSize: this.options.pageSize });
        }

        if (!this.viewModel) {
            this.viewModel = new ViewModel;
        }

        this.viewModel.set({
            traitRegionWithPaginationIsLoading: trait.model.get('isLoading'),
        });

        this.listenTo(trait.model, 'change:isLoading', (m, loading) => this.viewModel.set({
            traitRegionWithPaginationIsLoading: loading,
        }));

        return f && f.apply(this, args);
    };

    refresh(f, trait, options, ...args) {
        _.defaults(options || (options = {}), {
            clear: false,
            scrollToTop: false
        });

        const refreshPromise = (trait.model.get('refreshPromise') || Promise.resolve());

        let e;
        const promise = refreshPromise
            .then(() => {
                if (options.clear) {
                    trait.model.set({ pages: [] });
                } else {
                    const pages = trait.model.get('pages'),
                        pageSize = trait.model.get('pageSize');

                    pages.length = 0;

                    trait.collection.each((m, index) => {
                        const pageIndex = Math.floor(index / pageSize),
                            page = pages[pageIndex] || (pages[pageIndex] = {
                                status: 0,
                                items: [],
                                updatedAt: 0
                            });

                        page.items.push(m.id);
                    });

                    if (options.scrollToTop) {
                        const first = trait.collectionView.children.first();
                        if (first) {
                            first.el.scrollIntoCenter();
                        }
                    }
                }
            })
            .then(() => {
                trait.model.set({ isRefreshing: true });
                trait.wrapperContainerElement.addClass('trait-region-with-pagination-refreshing');
            })
            .then(() => trait._loadPage(0, options.clear))
            .then(() => {
                trait.collection.sort();
                const pages = trait.model.get('pages');
                _.each(pages, (p, index) => {
                    if (index != 0) {
                        p.status = 0;
                    }
                });
            })
            .catch(_e => e = _e)
            .then(() => {
                trait.model.set({ isRefreshing: false });
                trait.wrapperContainerElement.removeClass('trait-region-with-pagination-refreshing');
            })
            .then(() => f && f.call(this, options, args))
            .then(() => {
                if (e) {
                    throw e;
                }
            });

        trait.model.set({ refreshPromise: promise.catch(e => console.error(e)) });

        return promise;
    };

    onScrollNearEnd(f, trait, ...args) {
        const pages = trait.model.get('pages'),
            lastPage = _.last(pages),
            pageSize = trait.model.get('pageSize');

        if (lastPage && lastPage.status == 0) {
            trait._loadPage(pages.length - 1);
        } else if (lastPage && lastPage.items.length < pageSize) {
            if (new Date - lastPage.updatedAt > trait.options.pageFreshPeriod) {
                trait._loadPage(trait.model.get('pages').length - 1);
            }
        } else {
            this.loadNextPage();
        }

        f && f.apply(this, args);
    };

    onRenderOrAttach(f, trait, ...args) {
        f && f.apply(this, args);

        this.el.classList.add('trait-region-with-pagination');
        trait.collectionView.el.classList.add('trait-region-with-pagination-collection-view');
        trait.wrapperContainerElement.addClass('trait-region-with-pagination-wrapper-container');
    };

    _loadNextPage(loadNextPage) {
        return this._loadPage(this.model.get('pages').length);
    };

    _loadPage(index, remove) {
        const pages = this.model.get('pages'),
            pageSize = this.model.get('pageSize');

        index = Math.max(Number(index) || 0, 0);

        let page = pages[index];

        if (page && page.promise) {
            return page.promise;
        } else if (index >= pages.length) {
            const lastPage = _.last(pages);
            if (lastPage && lastPage.items.length < pageSize) {
                page = lastPage;
                index = pages.length - 1;
            } else {
                index = pages.length;
                page = {
                    status: 0,
                    items: [],
                    updatedAt: new Date
                };
                pages.push(page);
            }
        }

        return page.promise = new Promise((resolve, reject) => {
            this.model.set({ isLoading: true });
            this.wrapperContainerElement.addClass('trait-region-with-pagination-loading');

            const options = {
                pageSize: this.model.get('pageSize'),
                page: index + 1,
                remove: !!remove,
                reset: !!remove,
                success: (collection, response, options) => {
                    const index = options.page - 1;

                    if (this.model.get('pageSize') != options.pageSize) {
                        this.model.set({
                            pageSize: options.pageSize,
                        });
                    }

                    const pages = this.model.get('pages');
                    if (options.changes) {
                        _.each(options.changes.merged, m => {
                            const page = _.find(pages, p => p.status > 0 && p.items.indexOf(m.id) != -1);
                            if (page) {
                                page.status = 0;
                            }
                        });

                        if (options.changes.added && options.changes.added.length) {
                            for (let i = index + 1; i < pages.length; ++i) {
                                const page = pages[i];
                                if (page && page.status > 0) {
                                    page.status = 0;
                                }
                            }
                        }
                    }

                    const page = pages[index] || (pages[index] = {
                        status: 0,
                        items: [],
                        updatedAt: 0
                    });

                    page.status = 1;
                    if (!!remove) {
                        page.items = collection.pluck('id');
                    } else if (options.changes) {
                        page.items = _.pluck(options.changes.added, 'id').concat(_.pluck(options.changes.merged, 'id'));
                    }
                    page.updatedAt = new Date;
                    delete page.promise;

                    if (page.items.length) {
                        const prevPage = index > 1 && pages[index - 1];
                        if (prevPage && prevPage.items.length < options.pageSize) {
                            prevPage.status = 0;
                        }
                    }

                    if (options.page < options.pageCount) {
                        _.defer(this._checkIfScrollNearEnd.bind(this));
                    }

                    if (pages.length > options.pageCount) {
                        pages.length = options.pageCount;
                    }

                    this.model.set({ isLoading: false });
                    this.wrapperContainerElement.removeClass('trait-region-with-pagination-loading');

                    resolve();
                },
                error: (collection, jqXHR, options) => {
                    this.model.set({ isLoading: false });
                    this.wrapperContainerElement.removeClass('trait-region-with-pagination-loading');
                    this.o.showError(jqXHR);
                    reject(new AjaxError(jqXHR));
                }
            };

            if (this.parentViewModel && this.parentViewModel.get('filtersModel')) {
                const filters = this.parentViewModel.get('filtersModel').toJSON();
                _.extend(options, filters);
            }

            this.collection.fetch(options);
        });
    };

    _checkAndLoadCurrentPage() {
        if (this.collectionView) {
            const scrollingContainer = Scrollparent(this.collectionView.el),
                upperThirdCoord = scrollingContainer.getBoundingClientRect().top + scrollingContainer.clientHeight / 3;

            let currentChild;
            this.collectionView.children.find(c => {
                currentChild = c;
                return c.el.getBoundingClientRect().top >= upperThirdCoord;
            });

            if (currentChild) {
                const pages = this.model.get('pages'),
                    pageSize = this.model.get('pageSize') || 1,
                    pageIndex = Math.floor(currentChild.$el.index() / pageSize),
                    page = pages[pageIndex];

                if (!page || !page.status) {
                    this._loadPage(pageIndex);
                }
            }
        }
    };

    _checkIfScrollNearEnd() {
        if (this.o.isPage && !this.o.isActive) {
            return false;
        }

        const scrollingContainer = Scrollparent(this.collectionView.el),
            scrollingContainerBoundingClientRect = scrollingContainer.getBoundingClientRect(),
            last = this.collectionView.getChildViewContainer ? this.collectionView.getChildViewContainer(this.collectionView)[0].lastElementChild : this.collectionView.el.lastElementChild;

        if (scrollingContainerBoundingClientRect.top + scrollingContainerBoundingClientRect.height >= (last && last.getBoundingClientRect().top || 0)) {
            this.o.triggerMethod('scroll:near:end');
            return true;
        }
        return false;
    };
};

export default RegionWithPagination;
