import { Model, Collection } from '@b2cmessenger/backbone';
import settings from 'settings';

import BroadcastModel from 'models/BroadcastModel';
import CollectionWithPagination from 'traits/CollectionWithPagination';

const BroadcastCollection = Collection.extendWithTraits([
    new CollectionWithPagination({
        restrictComparators: true,
        comparators: {
            'updated_at': {
                alias: 'date',
                comparator: (a, b) => {
                    const d = (a.get('updatedAt') || 0) - (b.get('updatedAt') || 0);
                    return d == 0 ? (a.id - b.id) : d;
                }
            },
            'updated_at desc': {
                alias: '-date',
                comparator: (a, b) => {
                    const d = (b.get('updatedAt') || 0) - (a.get('updatedAt') || 0);
                    return d == 0 ? (a.id - b.id) : d;
                }
            },
            'recipient_count': {
                alias: 'count',
                comparator: (a, b) => {
                    let ca = a.get('recipient_count'), cb = b.get('recipient_count');

                    ca = _.isNull(ca) ? -1 : ca;
                    cb = _.isNull(cb) ? -1 : cb;

                    if (ca == cb) return a.id - b.id;
                    return ca - cb;
                }
            },
            'recipient_count desc': {
                alias: '-count',
                comparator: (a, b) => {
                    let ca = a.get('recipient_count'), cb = b.get('recipient_count');

                    ca = _.isNull(ca) ? -1 : ca;
                    cb = _.isNull(cb) ? -1 : cb;

                    if (ca == cb) return a.id - b.id;
                    return cb - ca;
                }
            },
        }
    })
], {
    options: {
        campaign: undefined,
        pageSize: 200
    },
    model: BroadcastModel,

    url() {
        return settings.host + settings.serv_task.target + '/' + this.campaignId + '/history';
    },

    initialize() {
        if (_.isNumber(this.options.campaign) || _.isString(this.options.campaign)) {
            this.campaignId = this.options.campaign;
        } else if (_.isObject(this.options.campaign) && this.options.campaign.id) {
            this.campaignId = this.options.campaign.id;
        } else {
            throw new Error("options.campaign is empty or incorrect!");
        }
    }
});

export default BroadcastCollection;
