import template from './TaskPage.jade';
import settings from 'settings';
import MessageWithCommentsView from "widgets/MessageWithComments/MessageWithComments";
import Page from 'pages/Page';
import LoginedUserModel from "models/LoginedUserModel";
import UserModel from "models/UserModel";
import { ViewModel, Optional, Required } from "@b2cmessenger/backbone";
import HeaderView from "widgets/Header/Header";
import LeftMenuWindow from "windows/LeftMenu/LeftMenu";
import PlaceModel from "models/PlaceModel";
import MessageModel from "models/MessageModel";
import ContainerWithNotiicationsToRead from "traits/ContainerWIthNotificationsToRead";
import './TaskPage.scss';

const notificationsReadTrait = {
    Trait: ContainerWithNotiicationsToRead,
    options: {
        container: '[data-js-content]',
        notificationElSelector: '[data-notification-ids]'
    }
};

const TaskPage = Page.extendWithTraits([notificationsReadTrait], {
    options: {
        taskId: Required,
        includeReservation: Optional
    },

    attributes: { id: 'page_task' },
    template: template,
    className: "task-page",

    regions: {
        header: '[data-js-header]',
        content: '[data-js-content]',
        footer: '[data-js-footer]',
    },

    initialize() {
        if (!this.viewModel) {
            this.viewModel = new ViewModel;
        }

        this.loginedUserModel = new LoginedUserModel(_.extend({},
            _.defaults({}, LoginedUserHandler.getLoginedUser(), UserModel.prototype.defaults, { id: null }),
            {
                workplaceRoles: LoginedUserHandler.get_work_places_roles()
            }
        ));

        this.listenTo(notificationCollection, 'add', notification => {
            if (notification.get('isNewComment')) {
                const data = notification.get('data');
                if (data) {
                    const taskId = Number(data.task_dummy_id)
                        && Number(data.place_id)
                        && LoginedUserHandler.isUserEmployee(Number(data.place_id))
                        && Number(data.task_dummy_id)
                        ||
                        Number(data.task_id);

                    const commentId = data.comment_id;

                    if (taskId && commentId) {
                        const taskModel = this.taskModel;
                        if (taskModel) {
                            if (notification.get('isTaskSolution')) {
                                taskModel.set({ hasSolution: true });
                            }

                            /**@type {MessageWithCommentsView} */
                            const taskView = this.contentView;

                            if (taskView) {
                                const parentId = data.parent_id;
                                const loadCommentHandler = (commentModel => {
                                    if (commentModel) {
                                        this._setUnreadNotificationsForComment(commentModel.id);

                                        this.viewModel.trigger('check:notifications:in:viewport');

                                        if (commentModel.get('isWithGift')) {
                                            taskModel.set('hasgift', 1);
                                        }
                                    }
                                }).bind(this);

                                if (parentId) {
                                    taskView.loadNewSubComment(parentId, commentId)
                                        .then(loadCommentHandler);
                                } else {
                                    taskView.loadNewComment(commentId, notification.get('isTaskSolution'))
                                        .then(loadCommentHandler);
                                }
                            }
                        }
                    }
                }
            } else if (notification.get('isNewTaskSolutionRating')) {
                const data = notification.get('data');
                const taskId = data.task_id;
                const commentId = data.comment_id;

                if (taskId && commentId) {
                    const taskModel = this.taskModel;
                    if (taskModel) {
                        /**@type {MessageWithCommentsView} */
                        const taskView = this.contentView;

                        if (taskView) {
                            taskView.updateComment(commentId);
                            this._setUnreadNotificationsForComment(commentId);
                            this.viewModel.trigger('check:notifications:in:viewport');
                        }
                    }
                }
            } else if (notification.get('isNewTask')) {
                const data = notification.get('data');

                if (data) {
                    this._setUnreadNotificationsForTask(data.task_id);
                }
            }
        });
    },

    onRender() {
        if (!this.taskModel) {
            return;
        }

        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: this.taskModel ? `Task: ${this.taskModel.get('subject')}` : ''
        });

        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.header.show(headerView);

        this.contentView = new MessageWithCommentsView({
            placeModel: this.placeModel,
            model: this.taskModel,
            userModel: this.userModel,
            loginedUserModel: this.loginedUserModel,
            showPlaceInfo: true,
            showCommentsOnRender: this.taskId
        });

        this.listenTo(this.contentView, 'user:click', (userModel, isAnonym, isFromBussines) => {
            const userIsAuthor = userModel && userModel.id == this.loginedUserModel.id;
            const userIsEmployee = this.loginedUserModel.isEmployee(this.taskModel.get('place_id'));

            if (userModel && (!isAnonym || userIsAuthor || userIsEmployee)) {
                app.controller.goToOtherUserPage({
                    user: userModel.toJSON(),
                    place: this.placeModel.toJSON(),
                    anonym: isAnonym &&
                        !(userIsAuthor || isFromBussines && userIsEmployee)
                });
            }
        });

        this.listenTo(this.contentView, 'place:click', (placeModel) => {
            app.controller.goToPlacePage({ place: placeModel.toJSON() });
        });

        this.content.show(this.contentView);

        if (this.options.scrollToComment) {
            this.contentView.scrollToComment(this.options.scrollToComment, this.options.scrollToSubComment)
                .then(() => {
                    this.viewModel.trigger('check:notifications:in:viewport');
                });
        }
    },

    _loadModelsFromServer(taskId) {
        return new Promise((resolve, reject) => {
            this.viewModel.set('disabled', true);
            this.showLoading();
            this.taskId = taskId || this.options.taskId;

            const filter = {
                ids: [this.taskId],
                limit: 1,
                needplaceinfo: 1,
                needuserinfo: 1,
            };
            const fetchOptions = {
                include_reservation: this.options.includeReservation ? 1 : 0
            };

            Server.callServerWithParameters({
                url: settings.host + settings.serv_task.search,
                type: "POST",
                data: filter,
                success: data => {
                    this.viewModel.set('disabled', false);
                    this.hideLoading();

                    resolve(data);
                },
                error: error => {
                    this.viewModel.set('disabled', false);
                    this.hideLoading();

                    reject(error);
                }
            }, fetchOptions);
        });
    },

    onUnreadNotificationElIsVisible(trait, els) {
        if (!trait.o.isActive) {
            return;
        }

        const ids = _.chain(els)
            .reduce((memo, el) => {
                _.each(el.getAttribute('data-notification-ids').split(','), id => {
                    memo[id] = 1;
                });

                el.removeAttribute('data-notification-ids');

                return memo;
            }, {})
            .keys()
            .map(Number)
            .value();

        notificationCollection.markAsReadByIds(ids);
        console.log(`Mark as read notifications: ${ids.join(',')}`, els);
    },

    on() {
        if (_.has(this._events, 'visibility:change')) {
            return Page.prototype.on.apply(this, arguments);
        } else {
            const ret = Page.prototype.on.apply(this, arguments);
            if (_.has(this._events, 'visibility:change')) {
                if (!this._onVisibilityChangedBinded) {
                    this._onVisibilityChangedBinded = this._onVisibilityChanged.bind(this)
                }

                this.$el.on('load resize scrollstop', this._onVisibilityChangedBinded);
            }
            return ret;
        }
    },

    off() {
        if (!_.has(this._events, 'visibility:change')) {
            return Page.prototype.off.apply(this, arguments);
        } else {
            const ret = Page.prototype.off.apply(this, arguments);
            if (!_.has(this._events, 'visibility:change')) {
                this.$el.off('load resize scrollstop', this._onVisibilityChangedBinded);
            }
            return ret;
        }
    },

    listenTo() {
        if (_.has(this._events, 'visibility:change')) {
            return Page.prototype.listenTo.apply(this, arguments);
        } else {
            const ret = Page.prototype.listenTo.apply(this, arguments);
            if (_.has(this._events, 'visibility:change')) {
                if (!this._onVisibilityChangedBinded) {
                    this._onVisibilityChangedBinded = this._onVisibilityChanged.bind(this)
                }

                this.$el.on('load resize scrollstop', this._onVisibilityChangedBinded);
            }
            return ret;
        }
    },

    stopListening() {
        if (!_.has(this._events, 'visibility:change')) {
            return Page.prototype.stopListening.apply(this, arguments);
        } else {
            const ret = Page.prototype.stopListening.apply(this, arguments);
            if (!_.has(this._events, 'visibility:change')) {
                this.$el.off('load resize scrollstop', this._onVisibilityChangedBinded);
            }
            return ret;
        }
    },

    _setUnreadNotificationsForComment(commentId) {
        if (this.isRendered) {
            const $commentEl = this.$(`[data-comment][data-id=${commentId}]`);

            if ($commentEl.length) {
                const notificationIds = notificationCollection.getNewVisibleNotificationIdsForComment(commentId);

                $commentEl.attr(
                    'data-notification-ids',
                    notificationIds.length ? notificationIds.join(',') : null
                );
            }
        }
    },

    _setUnreadNotificationsForTask(taskId) {
        if (this.isRendered) {
            const $taskEl = this.$(`[data-task][data-id=${taskId}]`);

            if ($taskEl.length) {
                const notificationIds = notificationCollection.getNewVisibleNotificationIdsForTask(taskId);

                $taskEl.attr(
                    'data-notification-ids',
                    notificationIds.length ? notificationIds.join(',') : null
                );
            }
        }
    },

    _onVisibilityChanged: _.debounce(function () {
        this.trigger('visibility:change');
    }, 300),

    show(options) {
        _.extend(this.options, options);

        if (this.loginedUserModel.id != LoginedUserHandler.getLoginedUserId()) {
            this.loginedUserModel.set(_.extend({},
                _.defaults({}, LoginedUserHandler.getLoginedUser(), UserModel.prototype.defaults, { id: null }),
                {
                    workplaceRoles: LoginedUserHandler.get_work_places_roles()
                }
            ));
        } else {
            const oldWorkplaceRoles = this.loginedUserModel.get('workplaceRoles');
            if (!_.isEqual(oldWorkplaceRoles, LoginedUserHandler.get_work_places_roles())) {
                this.loginedUserModel.set(_.extend({},
                    _.defaults({}, LoginedUserHandler.getLoginedUser()),
                    {
                        workplaceRoles: LoginedUserHandler.get_work_places_roles()
                    }
                ));
            } else {
                this.loginedUserModel.set(LoginedUserHandler.getLoginedUser());
            }
        }

        this._loadModelsFromServer(options.taskId)
            .then((data) => {
                if (data.tasks && data.tasks.length > 0) {
                    this.taskModel = new MessageModel(_.extend({
                        notificationMuteCollection: app.notificationMuteCollection
                    }, data.tasks[0]));
                } else {
                    this.showError(`${this.options.includeReservation ? 'Reservation' : 'Task'} not found!`);
                    app.controller.goToTasksPage();
                }

                if (data.places && data.places.length > 0) {
                    this.placeModel = new PlaceModel(data.places[0]);
                }

                if (data.users && data.users.length > 0) {
                    this.userModel = new UserModel(data.users[0]);
                }

                if (this.isRendered) {
                    this.render();
                }
            })
            .catch(error => {
                this.showError(error);
                app.controller.goToTasksPage();
            });

        return Page.prototype.show.apply(this, arguments);
    }
});

export default TaskPage;
