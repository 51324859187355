import { Model, CollectionView } from '@b2cmessenger/backbone';

import PhotoWidget from 'widgets/Photo/Photo';

import './BigPhoto.scss';

let BigPhoto = CollectionView.extend({
    className: 'widget big-photo-widget',

    childView: PhotoWidget,
    childViewOptions: {
        maxZoom: 6,
        minZoom: 1
    },

    filter(child, index, collection) {
        return index == this.viewModel.get('activePhotoIndex');
    },

    bindings: {
        ':el': 'classes:{"is-zoomed":isZoomed},attr:{"active-photo-index":activePhotoIndex}'
    },

    initialize() {
        if(!this.viewModel) {
            this.viewModel = new (Model.extend({
                computeds: {
                    isZoomed: {
                        deps: ['activePhotoZoom'],
                        get: zoom => zoom > 1.1
                    }
                }
            }))({
                activePhotoIndex: 0,
                activePhotoZoom: 1,
            });
        }

        this.listenTo(this.viewModel, 'change:activePhotoIndex', (m, activePhotoIndex) => {
            //let childView = this.children.findByIndex(m.previous('activePhotoIndex'));
            //if(childView) {
            //    m.stopListening(childView, 'change:zoom');
            //}

            //if(activePhotoIndex >= this.collection.length) {
            //    activePhotoIndex = 0;
            //    m.set('activePhotoIndex', activePhotoIndex);
            //} else if(activePhotoIndex < 0) {
            //    activePhotoIndex = this.collection.length ? (this.collection.length - 1) : 0;
            //    m.set('activePhotoIndex', activePhotoIndex);
            //}

            this.reorder();

            let childView = this.children.findByIndex(activePhotoIndex);
            if(childView) {
                let activePhotoZoom = 1;
                childView.viewModel.set('zoom', activePhotoZoom);
            }

            this.trigger('change:activePhotoIndex', this, activePhotoIndex);
        });

        this.listenTo(this.viewModel, 'change:activePhotoZoom', (m, activePhotoZoom) => this.trigger('change:activePhotoZoom', this, activePhotoZoom));
    },

    onRender() {
        let childView = this.children.findByIndex(this.viewModel.get('activePhotoIndex'));
        if(childView) {
            childView.viewModel.set('zoom', this.viewModel.get('activePhotoZoom'));
        }
    },

    onChildviewChangeZoom(childview, view, zoom) {
        if(childview.model == this.collection.at(this.viewModel.get('activePhotoIndex'))) {
            this.viewModel.set('activePhotoZoom', zoom);
        }
    },

    onChildviewGestureSwipe(childview, view, distance) {
        if(!this.viewModel.get('isZoomed')) {
            if(distance < 0) {
                let activePhotoIndex = this.viewModel.get('activePhotoIndex') + 1;
                if(activePhotoIndex < this.collection.length) {
                    this.viewModel.set('activePhotoIndex', activePhotoIndex);
                }
            } else {
                let activePhotoIndex = this.viewModel.get('activePhotoIndex') - 1;
                if(activePhotoIndex >= 0) {
                    this.viewModel.set('activePhotoIndex', activePhotoIndex);
                }
            }
        }
    },

    onChildviewGestureSwipeLong(childview, view, distance) {
        this.onChildviewGestureSwipe(childview, view, distance);
    },

    onChildviewGestureCollapse(childview, view, startZoom, minZoom, maxZoom) {
        this.trigger('gesture:collapse', this, startZoom, minZoom, maxZoom);
    },

    showPhoto(index) {
        if(index >= 0 && index < this.collection.length) {
            this.viewModel.set({
                activePhotoIndex: index
            });
        }
    },

    showPhotoWithId(id) {
        let m = this.collection.get(id);
        if(m) {
            this.showPhoto(this.collection.indexOf(m));
        }
    }
});

export default BigPhoto;
