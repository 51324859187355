import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import ReactPage from 'pages/ReactPage';

import './MenuSettings.scss';

import Page, { PageContent, PageHeader } from 'components/page/Page';
import Header from 'components/UI/Header/Header';
import { BackButton, MenuButton } from 'components/UI/Button/Button';
import withStore from 'components/withStore';
import Select from 'components/UI/Select';
import { SliderGroup, SwitchInput, TextInputGroup } from 'components/UI/InputGroup/InputGroup';
import Spinner from 'components/UI/Spinner/Spinner';
import { getMenuCurrency, getMenuError, getMenuInfoLoading, getMenuIsFood } from 'store/menu/selectors';
import { saveMenuInfo } from 'store/menu/actions';
import MenuItemError from 'components/menu/MenuItemDetail/MenuItemError';
import { getMenuSectionsAsArray } from 'store/menu/sections/selectors';
import { removeSection, removeTable, upsertSection, upsertTable } from 'store/menu/sections/actions';
import TextButton from 'components/UI/TextButton';
import useInitializeMenu from 'hooks/useInitializeMenu';

const Currency = { USD: 'USD', EUR: 'EUR', JPY: 'JPY', CAD: 'CAD', GBP: 'GBP', CHF: 'CHF', RUB: 'RUB' };
const MenuCurrency = {
    [Currency.USD]: 'United States Dollar',
    [Currency.EUR]: 'Euro',
    [Currency.JPY]: 'Japanese Yen',
    [Currency.CAD]: 'Canadian Dollar',
    [Currency.GBP]: 'Pound Sterling',
    [Currency.CHF]: 'Swiss Franc',
    [Currency.RUB]: 'Russian Ruble',
};

const CurrencyList = _.pairs(MenuCurrency).map(([k, v]) => ({ value: k, name: v }));

const MenuSettingsContent = (props) => {
    const { placeId, currency, isFood, error, loading, isActive } = props;
    const { saveMenuInfo } = props;

    const [food, setFood] = useState(false);
    const [currencyType, setCurrencyType] = useState();

    const { shouldInitMenu, readyToInitialize, initialized } = useInitializeMenu({ placeId, isActive });

    const contentReady = useMemo(() => {
        return error === null && currencyType !== null && !shouldInitMenu && initialized;
    }, [shouldInitMenu, currencyType, error, initialized]);

    useEffect(() => setFood(Boolean(isFood)), [isFood]);
    useEffect(() => setCurrencyType(currency), [currency]);

    const saveMenuInfoAsync = useCallback(
        (currencyValue, foodValue) => {
            setCurrencyType(currencyValue);
            setFood(foodValue);
            saveMenuInfo(currencyValue, foodValue);
        },
        [saveMenuInfo, setCurrencyType, setFood]
    );

    const goToSectionsPage = useCallback(() => app.controller.goToMenuSections({ placeId }), [placeId]);

    const onShow = useCallback(() => {
        if (readyToInitialize) {
            readyToInitialize(true);
        }
    }, [readyToInitialize]);

    return (
        <Page instance={props.__page__} onShow={onShow}>
            <PageHeader>
                <Header>
                    <BackButton onClick={props.goBack} />
                    <h1 className="title">{props.title}</h1>
                    <MenuButton right />
                </Header>
            </PageHeader>
            <PageContent className="scrollable grow">
                {contentReady && (
                    <>
                        <TextInputGroup label="Currency" icon="ion-ios-cash">
                            <Select
                                value={currencyType}
                                options={CurrencyList}
                                onChange={(value) => saveMenuInfoAsync(value, food)}
                            />
                        </TextInputGroup>
                        <SliderGroup caption="This menu is for food">
                            <SwitchInput value={food} onChange={(value) => saveMenuInfoAsync(currencyType, value)} />
                        </SliderGroup>
                        <TextButton
                            className="wide normalized border-filled with-top-margin"
                            onClick={goToSectionsPage}
                        >
                            Sections and tables
                        </TextButton>
                    </>
                )}
                {((!contentReady && !error) || loading) && <Spinner />}
                {error !== null && <MenuItemError errorText={error} displayRetryButton={false} />}
            </PageContent>
        </Page>
    );
};

const ConnectedMenuSettingsContent = withStore(
    connect(
        (state) => ({
            title: 'Menu Settings',
            loading: getMenuInfoLoading(state),
            currency: getMenuCurrency(state),
            isFood: getMenuIsFood(state),

            error: getMenuError(state),
            sections: getMenuSectionsAsArray(state),
        }),
        {
            saveMenuInfo,
            upsertTable,
            upsertSection,
            removeSection,
            removeTable,
        }
    )(MenuSettingsContent)
);

const MenuSettingsPage = ReactPage.extend({
    attributes: {
        id: 'page_menu_settings',
    },
    className: 'menu-settings-page',
    component: ConnectedMenuSettingsContent,
});

export default MenuSettingsPage;
