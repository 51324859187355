export const getEditedOrderItems = (newOrderItems, orderItems) => {
    return orderItems.map(orderItem => {
        const idx = newOrderItems.findIndex(item => item.id === orderItem.id);

        // to remove item from order sending count 0
        if (idx < 0) {
            return {
                id: orderItem.id,
                count: 0
            }
        }

        const newItem = newOrderItems[idx];

        const newItemAdditions = newItem.additions;
        const orderItemAdditions = orderItem.additions;


        const editedAdditions = [];
        const removedAdditions = [];

        for (const addition of newItemAdditions) {
            const idx = orderItemAdditions.findIndex(a => Number(a['item_id']) === Number(addition['item_id']));

            if (idx < 0) {
                editedAdditions.push(addition);
            } else if (addition.count !== orderItemAdditions[idx].count) {
                editedAdditions.push({ ...addition, id: Number(orderItemAdditions[idx].id) });
            }
        }

        for (const addition of orderItemAdditions) {
            const idx = newItemAdditions.findIndex(a => Number(a['item_id']) === Number(addition['item_id']));

            if (idx < 0) {
                removedAdditions.push({ id: addition.id, count: 0 });
            }
        }

        return {
            ...newItem,
            additions: [...editedAdditions, ...removedAdditions]
        };
    });
};

export const initialOrderParams = {
    id: null,
    status: null,
    isAnonymous: null,
    waiterUserId: null,
    waiterUser: null,
    user: null,
    items: null,
    comment: null
};
