import { Optional, ItemView, ViewModel } from '@b2cmessenger/backbone';
import Modal from 'windows/Modal/Modal';
import FooterView from 'widgets/Footer/Footer';
import template from './UserEmailSearchModal.jade';
import './UserEmailSearchModal.scss';
import UserSearchHelpers from "windows/Modal/UserSearchModal/UserSearchHelpers";

const UserEmailSearchModal = Modal.extend({
    options: {
        parentViewModel: Optional,
        email: Optional
    },

    windowName: "user-email-search-modal-window",
    className: "modal-window user-email-search-modal-window green-border",

    initialize() {
        this.viewModel = new ViewModel({
            parentViewModel: this.options.parentViewModel || null,
            vm_emailError: '',
            vm_email: this.options.email || '',
            vm_canContinue: false
        });

        this.kioskHelpers = new UserSearchHelpers({
            viewModel: this.viewModel
        });

        this.viewModel.addComputed('vm_canContinue', {
            deps: ['vm_email', 'disabled'],
            get: (vm_email, disabled) => !!(vm_email && B2Cjs.checkMail(vm_email) && !disabled)
        });

        return Modal.prototype.initialize.apply(this, arguments);
    },

    onRender() {
        const contentView = new UserEmailSearchContent({
            viewModel: this.viewModel
        });

        this.listenTo(contentView, 'continue', () => {
            if (this.viewModel.get('vm_canContinue')) {
                this._onContinue();
            }
        });
        this.content.show(contentView);

        const footerView = new FooterView({
            buttons: [{
                id: "close",
                text: "CANCEL",
                icn: "empty"
            }, {
                id: "continue",
                text: "CONTINUE",
                icn: "empty"
            }]
        });

        this.listenTo(footerView, 'close:click', view => this.close(false));
        this.listenTo(footerView, 'continue:click', view => this._onContinue());
        this.footer.show(footerView);
        footerView.ui.btncontinue.prop('disabled', !this.viewModel.get('vm_canContinue'));
        footerView.listenTo(this.viewModel, 'change:vm_canContinue', () => {
            footerView.ui.btncontinue.prop('disabled', !this.viewModel.get('vm_canContinue'));
        });
    },

    onShow() {
        const ret = Modal.prototype.onShow.call(this);

        this.content && this.content.currentView && this.content.currentView.ui &&
        this.content.currentView.ui.email && this.content.currentView.ui.email.focus();

        return ret;
    },

    cancel() {
        this.close(false);
    },

    _onContinue() {
        if (this.viewModel.get('vm_canContinue')) {
            this.kioskHelpers.findClient({ email: this.viewModel.get('vm_email') })
                .then((data) => {
                    this.close({
                        user: data && data[0],
                        email: this.viewModel.get('vm_email')
                    });
                })
                .catch(error => {
                    this.showError(...error);
                });
        }
    }
});

export default UserEmailSearchModal;

@ItemView.options({
    collection: Optional,
})
@ItemView.properties({
    template: template,
    tagName: 'form',
    className: "widget user-email-search-content-widget",

    ui: {
        email: '[data-js-email]',
        emailError: '[data-js-email-error]'
    },

    computeds: {
        c_email: {
            deps: ['vm_email'],
            get: email => email || "",
            set(email) {
                this.viewModel.set('vm_email', email);
            }
        }
    },

    bindings: {
        '@ui.email': 'value:c_email, disabled:disabled, attr:{readonly:disabled}',
        '@ui.emailError': 'text:vm_emailError'
    },

    events: {
        'submit'(e) {
            this.trigger('continue');
            e.preventDefault();
        },
        'keyup @ui.email'(e) {
            this.viewModel.set('vm_email', this.ui.email.val());
        }
    }
})
class UserEmailSearchContent extends ItemView {}
