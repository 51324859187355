import { CollectionView, LayoutView, ViewModel, Model, Collection } from '@b2cmessenger/backbone';

import CategoryCollection from 'models/CategoryCollection';
import CategoriesWidget from 'widgets/Category/Categories';
import escapeHtml from 'utils/escapeHtml';

import template from './CategoryGroup.jade';

const CategoryGroupModel = Model.extend({
    defaults: {
        mixed: false
    },
    computeds: {
        length: {
            deps: ['categories'],
            get: categories => categories.length
        },
        parent: {
            deps: ['categories', 'mixed'],
            get: (categories, mixed) => !mixed && categories.length ? categories.first().get('parent') : undefined
        },
        parents: {
            deps: ['parent'],
            get: parent => {
                const parents = [];
                while(parent) {
                    parents.push(parent);
                    parent = parent.get('parent');
                }
                return parents;
            }
        },
        depth: {
            deps: ['parents', 'mixed'],
            get: (parents, mixed) => mixed ? -1 : parents.length
        }
    },

    initialize() {
        const categories = this.get('categories');
        this.listenTo(categories, 'update reset change:parent', () => this.trigger('change:categories change', this, categories, {}));
    }
});

const CategoryGroup = LayoutView.extend({
    template,
    className: 'widget category-group-widget',

    options: {
        autoGroupAndParentName: true,
        showGroupName: true,
        showParentName: false,
        selectable: false,
        selection: undefined,
        filter: false
    },

    regions: {
        categories: '[data-js-categories]'
    },

    ui: {
        name: '[data-js-name]'
    },

    computeds: {
        nameHtml: {
            deps: ['parents'],
            get: parents => parents.length &&
                _(parents).reduceRight((name, parent) => `${name}<span>${escapeHtml(parent.get('name'))}</span>`, '') || ''
        },
    },

    bindings: {
        ':el': 'attr:{"data-depth":depth,"data-mixed":mixed},classes:{mixed:mixed}',
        '@ui.name': 'html:nameHtml,classes:{hidden:not(showGroupName)}'
    },

    initialize() {
        this.collection = this.model.get('categories');

        this.viewModel = new ViewModel({
            autoGroupAndParentName: !!this.options.autoGroupAndParentName,
            selectable: !!this.options.selectable,
            selection: this.options.selection,
            filter: this.options.filter,
            isMixed: this.model.get('mixed'),
            collectionLength: this.collection.length
        });
        this.viewModel.addComputed('showGroupName', {
            deps: ['autoGroupAndParentName', 'isMixed', 'collectionLength'],
            get: (autoGroupAndParentName, mixed, collectionLength) => 
                autoGroupAndParentName 
                    ? (
                        mixed 
                            ? true
                            : this.collection.length != 1
                    ) 
                    : !!this.options.showGroupName
        });
        this.viewModel.addComputed('showParentName', {
            deps: ['autoGroupAndParentName', 'isMixed', 'collectionLength'],
            get: (autoGroupAndParentName, mixed, collectionLength) => 
                autoGroupAndParentName 
                    ? (
                        mixed 
                            ? true
                            : this.collection.length == 1
                    ) 
                    : !!this.options.showParentName
        });


        this.listenTo(this.model, 'change:mixed', (m, isMixed) => this.viewModel.set({ isMixed }));
        this.listenTo(this.collection, 'update reset', (collection) => this.viewModel.set({ collectionLength: collection.length }));

        this.listenTo(this.viewModel, 'change:showParentName change:selectable change:selection', () => {
            if(this.categories.currentView) {
                this.categories.currentView.showParentName(this.viewModel.get('showParentName'));
                this.categories.currentView.setSelectable(this.viewModel.get('selectable'));
                this.categories.currentView.setSelection(this.viewModel.get('selection'));
            }
        });

        this.listenTo(this.viewModel, 'change:filter', () => {
            if(this.categories.currentView) {
                this.categories.currentView.setFilter(this.viewModel.get('filter'));
            }
        });
    },

    onRender() {
        const categoriesWidget = new CategoriesWidget({
            collection: this.collection,
            showParentName: !!this.viewModel.get('showParentName'),
            selectable: !!this.viewModel.get('selectable'),
            selection: this.viewModel.get('selection'),
            filter: this.viewModel.get('filter')
        });

        this.categories.show(categoriesWidget);
    },

    setSelection(selection) {
        this.viewModel.set({ selection });
    },

    setSelectable(selectable) {
        selectable = !!selectable;
        this.viewModel.set('selectable', selectable);
    },

    setFilter(filter) {
        this.viewModel.set('filter', filter);
    }
});

const CategoryGroups = CollectionView.extend({
    className: 'widget category-groups-widget',
    childView: CategoryGroup,
    childViewOptions() { 
        return {
            selectable: this.viewModel.get('selectable'),
            selection: this.viewModel.get('selection'),
            filter: this.viewModel.get('filter')
        }
    },

    options: {
        selectable: false,
        filter: false,
    },

    initialize() {
        this.viewModel = new ViewModel({
            selectable: !!this.options.selectable,
            selection: this.options.selection,
            filter: this.options.filter
        });

        if(this.viewModel.get('filter')) {
            let filter = this.viewModel.get('filter'),
                f = _.isFunction(filter) ? filter : c => c.get('searchName').indexOf(filter) > -1;

            this.options.filter = g => console.log(g) && g.get('categories').find(f);
        } else {
            this.options.filter = undefined;
        }

        this.listenTo(this.viewModel, 'change:selectable', (m, selectable) => this.children.each(v => v.setSelectable(selectable)));
        this.listenTo(this.viewModel, 'change:selection', (m, selection) => this.children.each(v => v.setSelection(selection)));
        this.listenTo(this.viewModel, 'change:filter', (m, filter) => {
            if(filter) {
                let f = _.isFunction(filter) ? filter : c => c.get('searchName').indexOf(filter) > -1;

                this.options.filter = g => g.get('categories').find(f);
            } else {
                this.options.filter = undefined;
            }
            

            if(this.isRendered) this.resortView();

            this.children.each(v => v.setFilter(filter));
        });
    },

    setSelection(selection) {
        this.viewModel.set({ selection });
    },

    setSelectable(selectable) {
        selectable = !!selectable;
        this.viewModel.set('selectable', selectable);
    },

    setFilter(filter) {
        this.viewModel.set('filter', filter);
    }
});

import './CategoriesGroupedByParent.scss';

const CategoryGroupCollection = Collection.extend({
    model: CategoryGroupModel
});

const CategoriesGroupedByParentModel = Model.extend({
    options: {
        groupSingles: true,
    },

    initialize() {
        const categories = this.get('categories');

        const groups = new CategoryGroupCollection;

        const groupCategories = (categories, groupSingles) => {
            let groupedCategories = _(categories.groupBy ? categories.groupBy('parent_id') : _(categories).groupBy('parent_id'))
                .map(categories => {
                    const parent = categories[0].get('parent');
                    return new CategoryGroupModel({
                        id: parent ? parent.id * 100000000 : categories[0].id,
                        categories: new CategoryCollection(categories)
                    })
                });

            if(groupSingles) {
                let singlesGroup;
                groupedCategories = _(groupedCategories).reduce((groups, group) => {
                    if(group.get('length') == 1) {
                        if(!singlesGroup) {
                            singlesGroup = group;
                            singlesGroup.set({ id: 0, mixed: true });
                            groups.push(group);
                        } else {
                            singlesGroup.get('categories').add(group.get('categories').models);
                        }
                    } else {
                        groups.push(group);
                    }

                    return groups;
                }, []);
            }

            return groupedCategories;
        };

        groups.listenTo(categories, 'reset', () => groups.reset(groupCategories(categories, this.options.groupSingles)));
        groups.listenTo(categories, 'update', (categories, options) => {
            if(options.changes && options.changes.added) {
                _(groupCategories(options.changes.added, this.options.groupSingles)).each(_group => {
                    let group = groups.get(_group.id);
                    if(group) {
                        group.get('categories').add(_group.get('categories').models);
                    } else {
                        groups.add(_group);
                    }
                });
            }
            
            if(options.changes && options.changes.removed) {
                _(groupCategories(options.changes.removed, this.options.groupSingles)).each(_group => {
                    let group = groups.get(_group.id);
                    if(group) {
                        group.get('categories').remove(_group.get('categories').models);

                        if(!group.get('categories').length) groups.remove(group.id);
                    }
                });
            }
        });

        groups.reset(groupCategories(categories, this.options.groupSingles))

        this.set({ groups });
    }
});

const CategoriesGroupedByParent = LayoutView.extend({
    template: () => '<div data-js-groups></div>',
    className: 'widget categories-grouped-by-parent-widget',

    options: {
        selectable: false,
        selection: undefined,
        groupSingles: true,
        filter: false
    },

    regions: {
        groups: '[data-js-groups]'
    },

    initialize() {
        this.model = new CategoriesGroupedByParentModel({ categories: this.collection }, { groupSingles: this.options.groupSingles });

        this.viewModel = new ViewModel({
            selectable: !!this.options.selectable,
            filter: this.options.filter,
            selection: this.options.selection
        });
    },

    onRender() {
        const categoryGroupsWidget = new CategoryGroups({
            collection: this.model.get('groups'),
            selectable: !!this.viewModel.get('selectable'),
            selection: this.viewModel.get('selection'),
            filter: this.viewModel.get('filter'),
            viewComparator(m1, m2) {
                const mixed1 = m1.get('mixed'), mixed2 = m2.get('mixed');

                if(mixed1 && !mixed2) {
                    return 1;
                } else if(!mixed1 && mixed2) {
                    return -1;
                } else if(mixed1 && mixed2) {
                    return 0;
                }

                const depth1 = m1.get('depth'),
                      depth2 = m2.get('depth');

                if(depth1 < depth2) {
                    return -1;
                } else if(depth1 > depth2) {
                    return 1;
                } else {
                    const name1 = _(m1.get('parents')).reduceRight((name, parent) => name + parent.get('name'), ''),
                          name2 = _(m2.get('parents')).reduceRight((name, parent) => name + parent.get('name'), '');

                    return name1 < name2 ? -1 : name1 > name2;
                }
            },
        });

        categoryGroupsWidget.listenTo(this.viewModel, 'change:selectable', (m, selectable) => categoryGroupsWidget.setSelectable(selectable));
        categoryGroupsWidget.listenTo(this.viewModel, 'change:selection', (m, selection) => categoryGroupsWidget.setSelection(selection));
        categoryGroupsWidget.listenTo(this.viewModel, 'change:filter', (m, filter) => categoryGroupsWidget.setFilter(filter));
        this.listenTo(categoryGroupsWidget, 'childview:childview:select', (c, childview, event) => this.trigger('childview:select', childview, event));

        this.groups.show(categoryGroupsWidget);
    },

    setSelection(selection) {
        this.viewModel.set({ selection });
    },

    setSelectable(selectable) {
        selectable = !!selectable;
        this.viewModel.set('selectable', selectable);
    },

    setFilter(filter) {
        this.viewModel.set('filter', filter);
    }

});

export default CategoriesGroupedByParent;
