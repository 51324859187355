import React from 'react';

import InputGroup, { TextareaInput, TextInputGroup } from 'components/UI/InputGroup/InputGroup';
import Price from 'components/UI/Price/Price';
import './MenuOrderConfirm.scss';
import Select from 'components/UI/Select';
import InlineUser from 'components/UI/User/InlineUser';
import { Button } from 'components/UI/Button/Button';
import TextButton from 'components/UI/TextButton';

const MenuOrderWaiterOptions = (props) => {
    const { selectedUser, onSelectUser, onRemoveUser = _.noop, isRemovableUser = true } = props;

    const content = selectedUser ? (
        <>
            <InlineUser onClick={onSelectUser} {...selectedUser} />
            {isRemovableUser && <button className="ion-ios-close-outline remove" onClick={onRemoveUser} />}
        </>
    ) : (
        <div onClick={onSelectUser}>Anonymous</div>
    );

    return (
        <>
            <InputGroup className="input-group" label="USER">
                <div className="user">{content}</div>
            </InputGroup>
        </>
    );
};

const MenuAddToExistingOrder = (props) => {
    const { selectedExistingOrder = null, onAddToExistOrder, onRemoveOrder, disabled, loadingExistOrders } = props;
    const hasSelected = selectedExistingOrder !== null;
    const text = loadingExistOrders ? 'Checking for existing orders...' : 'Add to existing order';

    return (
        <>
            {hasSelected && (
                <InputGroup className="input-group existing-order" label="ORDER">
                    <div onClick={onAddToExistOrder}>Order #{selectedExistingOrder.order_number}</div>
                    <button className="ion-ios-close-outline remove" onClick={onRemoveOrder} />
                </InputGroup>
            )}
            {!hasSelected && (
                <TextButton
                    className="text-button add-to-exist btn-empty text medium normalized border-filled with-top-margin"
                    disabled={disabled}
                    onClick={onAddToExistOrder}
                >
                    {text}
                </TextButton>
            )}
        </>
    );
};

const MenuOrderConfirm = (props) => {
    const {
        totalPrice,
        currency,
        comment,
        setComment,
        selectedTableId,
        setSelectedTableId,
        isWaiter,
        isOrderManager,
        onCommentBlur,
        tables,
        disabled = false,
        selectTableUsingQrScanner,
        onAddToExistOrder,
        selectedExistingOrder,
        onRemoveOrder,
        loadingExistOrders,
        displayAddToExistOrder,
    } = props;
    const options = tables.map((t) => ({ value: t.id, name: t.number }));
    const value = _.isNull(selectedTableId) ? undefined : selectedTableId;

    const tableSelectorDisabled = React.useMemo(() => {
////////////////////////////////
        return disabled;
/////////////////
//////////////////////////////////////////////////
/////////////////////////////////////
//////////////////
    }, [disabled, isWaiter]);

    return (
        <div className="menu-order-confirm-content">
            <InputGroup label="Total price">
                <Price value={totalPrice} currency={currency} />
            </InputGroup>
            <div className="table-number">
                <InputGroup label="Table number">
                    <Select
                        disabled={tableSelectorDisabled}
                        defaultValue={
                            _.isNull(selectedTableId)
                                ? tableSelectorDisabled
                                    ? 'Scan table'
                                    : 'Select table'
                                : undefined
                        }
                        onChange={setSelectedTableId}
                        value={value}
                        options={options}
                    />
                    <Button className="icon-qrcode" onClick={selectTableUsingQrScanner} />
                </InputGroup>
            </div>
            {(isWaiter || isOrderManager) && <MenuOrderWaiterOptions {...props} />}
            {displayAddToExistOrder && (
                <MenuAddToExistingOrder
                    onAddToExistOrder={onAddToExistOrder}
                    onRemoveOrder={onRemoveOrder}
                    selectedExistingOrder={selectedExistingOrder}
                    disabled={disabled || loadingExistOrders}
                    loadingExistOrders={loadingExistOrders}
                />
            )}
            <div className="comment">
                <TextInputGroup label="Comment" className="input-group comment">
                    <TextareaInput
                        value={comment}
                        placeholder="Enter comment"
                        onBlur={onCommentBlur}
                        onChange={(e) => setComment(e.currentTarget.value)}
                        disabled={disabled}
                    />
                </TextInputGroup>
            </div>
        </div>
    );
};

export default MenuOrderConfirm;
