import { Required, Optional, Model, Collection } from '@b2cmessenger/backbone';
import settings from 'settings';

import MessageModel from 'models/MessageModel';
import LoginedUserModel from 'models/LoginedUserModel';
import CommentEditorPhotoModel from './CommentEditor/PhotoGrid/PhotoModel';
import AjaxError from 'utils/AjaxError';

/**@type {(properties: Model.Properties) => (target: (new (...args: any[]) => {})) => typeof CommentEditorModel} */
const properties = Model.properties;

@properties({
    defaults: {
        messageModel: Optional,
        parentMessageModel: Optional,
        userModel: Optional,
        loginedUserModel: Required
    },

    computeds: {
        _publishedAtValue: {
            deps: ['messageModel'],
            get: messageModel => messageModel && (messageModel.get('_publishedAtValue') + 1) || Infinity
        },

        isDirty: {
            deps: ['_text', '_gift', '_isSolution', '_isPhotoCollectionDirty'],
            get: (_text, _gift, _isSolution, _isPhotoCollectionDirty) => _text || _gift || _isSolution
                || _isPhotoCollectionDirty
        },

        parentMessageModel: {
            deps: ['messageModel', '_parentMessageModel'],
            get: (messageModel, _parentMessageModel) =>
                messageModel && messageModel.get('parentMessageModel') || _parentMessageModel,
            set: (val) => ({ _parentMessageModel: val })
        },

        userModel: {
            deps: ['_userModel', 'loginedUserModel'],
            get: (_userModel, loginedUserModel) => _userModel || loginedUserModel,
            set: val => ({ _userModel: val })
        },

        isDraft: {
            deps: ['messageModelStatus'],
            get: messageModelStatus => !messageModelStatus || messageModelStatus == 0
        },

        canSuggestReservationChanges: {
            deps: ['parentMessageModelIsReservation', 'hasLoginedUserReservationAccessRights', 'reservationModelIsInActiveState'],
            get: (parentMessageModelIsReservation, hasLoginedUserReservationAccessRights, reservationModelIsInActiveState) =>
                parentMessageModelIsReservation && hasLoginedUserReservationAccessRights
                && reservationModelIsInActiveState
        },

        canAcceptReservationChanges: {
            deps: ['isReservationAuthor', 'isReservationManager', 'reservationModelIsAcceptableForAuthor',
                'reservationModelIsAcceptableForBusiness'],
            get: (isReservationAuthor, isReservationManager, reservationModelIsAcceptableForAuthor,
                  reservationModelIsAcceptableForBusiness) =>
                (isReservationAuthor && reservationModelIsAcceptableForAuthor) ||
                (isReservationManager && reservationModelIsAcceptableForBusiness)
        },

        hasLoginedUserReservationAccessRights: {
            deps: ['isReservationAuthor', 'isReservationManager'],
            get: (isReservationAuthor, isReservationManager) => isReservationAuthor || isReservationManager
        },

        isReservationAuthor: {
            deps: ['parentMessageModel', 'loginedUserModel', 'reservationModelUserId'],
            get: (parentMessageModel, loginedUserModel, reservationModelUserId) => {
                return loginedUserModel && loginedUserModel.get('isLoggedIn')
                    && reservationModelUserId == loginedUserModel.id;
            }
        },

        isReservationManager: {
            deps: ['parentMessageModel', 'loginedUserModel', 'place_id', 'isReservationAuthor'],
            get: (parentMessageModel, loginedUserModel, place_id, isReservationAuthor) => {
                return loginedUserModel && loginedUserModel.get('isLoggedIn')
                && loginedUserModel.hasRoleInPlace(place_id, LoginedUserModel.Roles.RESERVATION_MANAGER)
                && !isReservationAuthor
            }
        },

        isFromBussines: {
            deps: ['messageModel', 'place_id', 'loginedUserModel', 'loginedUserModelRoles'],
            get(messageModel, place_id, loginedUserModel) {
                if (messageModel) {
                    return messageModel.get('isFromBussines');
                } else {
                    return place_id && loginedUserModel.isEmployee(place_id);
                }
            },
        },

        isAuthorCanBeAnonym: {
            deps: ['isFromBussines', 'parentMessageModel'],
            get: (isFromBussines, parentMessageModel) => {
                if (isFromBussines) {
                    return false;
                }

                if (!parentMessageModel) {
                    return false;
                }

                const taskModel = parentMessageModel.get('parentTaskModel');
                if (!taskModel) {
                    return false;
                }

                return !taskModel.get('to_user_id');
            }
        },

        isAuthorAnonym: {
            deps: ['isAuthorCanBeAnonym', '_isAuthorAnonym', 'messageModelIsAuthorAnonym'],
            get: (isAuthorCanBeAnonym, _isAuthorAnonym, messageModelIsAuthorAnonym) => isAuthorCanBeAnonym &&
                _.isBoolean(_isAuthorAnonym) ? _isAuthorAnonym :
                messageModelIsAuthorAnonym,
            set: (val) => ({ _isAuthorAnonym: !!val })
        },

        text: {
            deps: ['_text', 'messageModelText'],
            get: (_text, messageModelText) => _.isString(_text) ? _text : messageModelText || '',
            set: val => ({ _text: String(val) })
        },

        gift: {
            deps: ['canAddGift', '_gift', 'messageModelGift'],
            get: (canAddGift, _gift, messageModelGift) => _.isObject(_gift) && canAddGift ? _gift : messageModelGift,
            set: val => ({ _gift: val })
        },

        isSolution: {
            deps: ['canBeMarkedAsSolution', '_isSolution', 'messageModelIsSolution'],
            get: (canBeMarkedAsSolution, _isSolution, messageModelIsSolution) => canBeMarkedAsSolution &&
                _.isBoolean(_isSolution) ? _isSolution : messageModelIsSolution,
            set: val => ({ _isSolution: !!val })
        },

        canAddGift: {
            deps: ['isDraft', 'parentMessageModelDepth', 'place_id', 'loginedUserModel', 'canSuggestReservationChanges'],
            get: (isDraft, parentMessageModelDepth, place_id, loginedUserModel, canSuggestReservationChanges) => isDraft &&
                (!parentMessageModelDepth || parentMessageModelDepth == 0) && !canSuggestReservationChanges &&
                loginedUserModel && place_id &&
                loginedUserModel.hasRoleInPlace(place_id, LoginedUserModel.Roles.GIFT_CREATOR)
        },

        canBeMarkedAsSolution: {
            deps: ['parentMessageModel', 'place_id', 'loginedUserModel', 'loginedUserModelRoles'],
            get: (parentMessageModel, place_id, loginedUserModel) =>
                parentMessageModel && (
                    parentMessageModel.get('isIdea') || parentMessageModel.get('isIssue') ||
                    parentMessageModel.get('isQuestion')
                ) && !parentMessageModel.get('isBroadcast') &&
                loginedUserModel && place_id && loginedUserModel.isEmployee(place_id)
        },

        photoCollection: {
            deps: ['_photoCollection'],
            get: (_photoCollection) => _photoCollection,
            set(collection) {
                const oldPhotoCollection = this.attributes._photoCollection;
                if (oldPhotoCollection != collection) {
                    if (oldPhotoCollection) {
                        oldPhotoCollection.stopListening(this, 'change:messageModelPhotos');
                        this.stopListening(oldPhotoCollection);
                        this.set({
                            _isPhotoCollectionDirty: false
                        });
                    }

                    if (collection) {
                        const onMessageModelPhotosChange = (m, messageModelPhotos) => {
                            if (messageModelPhotos && messageModelPhotos.length) {
                                const messageModelPhotosIdsSet = _.reduce(messageModelPhotos,
                                    (ids, p) => ids[p.id] = p, {});

                                const toRemove = collection.filter(m =>
                                    (
                                        m.get('status') == CommentEditorPhotoModel.Status.Uploaded
                                        || m.get('status') == CommentEditorPhotoModel.Status.SelectedToDelete
                                    )
                                    && !messageModelPhotosIdsSet[m.id]
                                );

                                if (toRemove.length) {
                                    collection.remove(toRemove);
                                }

                                const messageModelPhotoModels = _.map(messageModelPhotos, p => {
                                    let status = CommentEditorPhotoModel.Status.Uploaded;
                                    const existingModel = collection.get(p.id);
                                    if (existingModel &&
                                        existingModel.get('status') == CommentEditorPhotoModel.Status.SelectedToDelete
                                    ) {
                                        status = CommentEditorPhotoModel.Status.SelectedToDelete;
                                    }
                                    return new CommentEditorPhotoModel({
                                        id: p.id,
                                        status,
                                        file: null,
                                        file_w: null,
                                        file_h: null,
                                        full: p.ph,
                                        '640': p.th,
                                        sizes: {
                                            full: {
                                                w: p.ph_w,
                                                h: p.ph_h
                                            },
                                            '640': {
                                                w: p.th_w,
                                                h: p.th_h
                                            }
                                        }
                                    });
                                });

                                collection.add(messageModelPhotoModels, { merge: true });
                            }
                        };

                        collection.listenTo(this, 'change:messageModelPhotos', onMessageModelPhotosChange);

                        this.listenTo(collection, 'add', (m) => {
                            if (m.get('status') != CommentEditorPhotoModel.Status.Uploaded) {
                                this.set({
                                    _isPhotoCollectionDirty: true
                                });
                            }
                        });

                        this.listenTo(collection, 'remove', (m) => {
                            if (m.get('status') != CommentEditorPhotoModel.Status.Uploaded) {
                                this.set({
                                    _isPhotoCollectionDirty: !!collection.find(m =>
                                        m.get('status') != CommentEditorPhotoModel.Status.Uploaded)
                                });
                            }
                        });

                        this.listenTo(collection, 'reset', (m) => {
                            this.set({
                                _isPhotoCollectionDirty: !!collection.find(m =>
                                    m.get('status') != CommentEditorPhotoModel.Status.Uploaded)
                            });
                        });

                        const messageModelPhotos = this.get('messageModelPhotos');
                        if (messageModelPhotos) {
                            onMessageModelPhotosChange(this.get('messageModel'), messageModelPhotos);
                        }
                    }
                }

                return { _photoCollection: collection || null }
            }
        }
    },

    proxies: {
        'place_id': {
            modelAttribute: 'parentMessageModel'
        },
        'messageModelText': {
            modelAttribute: 'messageModel',
            submodelAttribute: 'text',
            readOnly: true
        },
        'messageModelGift': {
            modelAttribute: 'messageModel',
            submodelAttribute: 'gift',
            readOnly: true
        },
        'messageModelStatus': {
            modelAttribute: 'messageModel',
            submodelAttribute: 'status',
            readOnly: true
        },
        'messageModelPhotos': {
            modelAttribute: 'messageModel',
            submodelAttribute: 'photos',
            readOnly: true
        },
        'messageModelIsSolution': {
            modelAttribute: 'messageModel',
            submodelAttribute: 'isSolution',
            readOnly: true
        },
        'messageModelIsAuthorAnonym': {
            modelAttribute: 'messageModel',
            submodelAttribute: 'isAuthorAnonym',
            readOnly: true
        },
        'messageModelIsReservationChangeComment': {
            modelAttribute: 'messageModel',
            submodelAttribute: 'isReservationChangeComment',
            readOnly: true
        },
        'messageModelReservationChange': {
            modelAttribute: 'messageModel',
            submodelAttribute: 'reservationChange',
            readOnly: true
        },
        'parentMessageModelIsReservation': {
            modelAttribute: 'parentMessageModel',
            submodelAttribute: 'isReservation',
            readOnly: true
        },
        'parentMessageModelDepth': {
            modelAttribute: 'parentMessageModel',
            submodelAttribute: 'depth',
            readOnly: true
        },
        'reservationModelIsInActiveState': {
            modelAttribute: 'reservationModel',
            submodelAttribute: 'isInActiveState',
            readOnly: true
        },
        'reservationModelIsAcceptableForAuthor': {
            modelAttribute: 'reservationModel',
            submodelAttribute: 'isAcceptableForAuthor',
            readOnly: true
        },
        'reservationModelIsAcceptableForBusiness': {
            modelAttribute: 'reservationModel',
            submodelAttribute: 'isAcceptableForBusiness',
            readOnly: true
        },
        'reservationModelUserId': {
            modelAttribute: 'reservationModel',
            submodelAttribute: 'user_id',
            readOnly: true
        },
        'loginedUserModelRoles': {
            modelAttribute: 'loginedUserModel',
            submodelAttribute: 'workplaceRoles',
            readOnly: true
        }
    }
})
class CommentEditorModel extends Model {
    constructor(attributes, options) {
        if (!attributes.photoCollection) {
            attributes.photoCollection = new Collection([], {
                model: CommentEditorPhotoModel
            });
        }

        super(attributes, options);
    }

    isNew() {
        const messageModel = this.get('messageModel');
        return !messageModel || messageModel.isNew();
    }

    destroy(options) {
        if (options && options.wait) {
            _.defer(() => {
                this.stopListening();
                this.trigger('destroy', this, this.collection, options);
                options && options.success && options.success.call(options.context, this, null, options);
            });
        } else {
            this.stopListening();
            this.trigger('destroy', this, this.collection, options);
            options && options.success && _.defer(() => options.success.call(options.context, this, null, options));
        }

        return $.Deferred().resolve(null);
    }

    /** @returns {JQueryXHR} */
    sync(method, model, options) {
        if (method == 'create') {
            const parentMessageModel = this.get('parentMessageModel');

            if (!parentMessageModel) {
                const err = new Error('parentMessageModel is falsey');
                if (options && options.error) {
                    options.error.call(options.context, err);
                }
                //@ts-ignore
                return $.Deferred().reject(err, 0, null);
            }

            const messageModel = new MessageModel({
                parentMessageModel,
                place_id: parentMessageModel.get('place_id'),
                parent_id: parentMessageModel.get('isTask') ? null : parentMessageModel.id,
                task_id: parentMessageModel.get('isTask') ? parentMessageModel.id : parentMessageModel.get('task_id'),
                depth: (parentMessageModel.get('depth') || 0) + 1,
                text: this.get('text'),
                authorvisibility: this.get('isAuthorAnonym') ? 0 : 1,
                issolution: 0
            });

            const deferred = $.Deferred();

            this._createGift(messageModel, this.get('gift'))
                // .then(messageModel => this._uploadPhotos(messageModel, this.get('photoCollection')))
                .then(() => deferred.resolve())
                .catch(e => {
                    if (options.error) {
                        options.error(e.jqXHR || e)
                    }
                    deferred.reject(e.jqXHR || e, e.textStatus, e.errorThrown);
                });

            //@ts-ignore
            return deferred.then(() => {
                return messageModel.save(
                    {
                        issolution: this.get('isSolution') ? 1 : 0
                    },
                    {
                        wait: options.wait,
                        addphotos: this.get('photoCollection').reduce((ids, m) =>  {
                          if (m.get('status') === CommentEditorPhotoModel.Status.UploadedNotSaved && !m.isNew()) {
                            ids.push(m.id);
                          }
                          return ids;
                        }, []),
                        success: (m, resp) => {
                            options.success({
                                messageModel,
                                parentMessageModel: null,
                                _text: undefined,
                                _isAuthorAnonym: undefined,
                                _gift: undefined,
                                _isPhotoCollectionDirty: false
                            });
                        },
                        error: (m, resp) => {
                            if (options.error) {
                                options.error(resp)
                            }
                        }
                    }
                );
            });

        } else if (method == 'update') {
            const messageModel = this.get('messageModel');
            const photoCollection = this.get('photoCollection');

            const deferred = $.Deferred();

            this._createGift(messageModel, this.get('gift'))
                .then(messageModel => {
                    this.set({
                        _gift: undefined
                    });
                    return messageModel;
                })
                // .then(messageModel => this._uploadPhotos(messageModel, this.get('photoCollection')))
                .then(() => deferred.resolve())
                .catch(e => {
                    if (options.error) {
                        options.error(e.jqXHR || e)
                    }
                    deferred.reject(e.jqXHR || e, e.textStatus, e.errorThrown);
                });

            //@ts-ignore
            return deferred.then(() => {
                return messageModel.save(
                    {
                        text: this.get('text'),
                        authorvisibility: this.get('isAuthorAnonym') ? 0 : 1,
                        issolution: this.get('isSolution') ? 1 : 0,
                    },
                    {
                        wait: options.wait,
                        delphotos: photoCollection.reduce((ids, m) => {
                            if (m.get('status') == CommentEditorPhotoModel.Status.SelectedToDelete) {
                                ids.push(m.id);
                            }
                            return ids
                        }, []),
                        addphotos: photoCollection.reduce((ids, m) =>  {
                          if (m.get('status') === CommentEditorPhotoModel.Status.UploadedNotSaved) {
                            ids.push(m.id);
                          }
                          return ids;
                        }, []),
                        success: (m, resp) => {
                            options.success({
                                messageModel,
                                parentMessageModel: null,
                                _text: undefined,
                                _isAuthorAnonym: undefined,
                                _gift: undefined,
                                _isPhotoCollectionDirty: false
                            })
                        },
                        error: (m, resp) => options.error(resp)
                    }
                );
            });
        } else {
            const err = new Error(`Sync method "${method}" is not supported`);
            if (options && options.error) {
                options.error.call(options.context, err);
            }
            //@ts-ignore
            return $.Deferred().reject(err, 0, null);
        }
    }

    _createDraft(messageModel) {
        if (messageModel.isNew()) {
            return new Promise((resolve, reject) => {
                messageModel.save({}, {
                    //@ts-ignore
                    draft: true,
                    wait: true,
                    success: (m, resp) => resolve(messageModel),
                    error: (m, resp) => reject(new AjaxError(resp))
                });
            });
        } else {
            return Promise.resolve(messageModel);
        }
    }

    _createGift(messageModel, gift) {
        if (gift) {
            return this._createDraft(messageModel)
                .then(messageModel => new Promise((resolve, reject) => {
                    const parentMessageModel = messageModel.get('parentMessageModel');
                    const templateModel = gift.templateModel || new Model(gift.template);

                    app.ajax({
                        url: settings.host + settings.serv_gift.create,
                        data: {
                            gifts: [{
                                template_id: templateModel.id,
                                brand_id: templateModel.get('brand_id'),
                                user_id: parentMessageModel.get('user_id'),
                                cl_task_id: parentMessageModel.get('isTask') ?
                                    parentMessageModel.id : null,
                                cl_comment_id: parentMessageModel.get('isComment') ?
                                    parentMessageModel.id : null,
                                comment_id: messageModel.id,
                                quantity: gift.quantity,
                                user_datetime: new Date().toISOStringTZ(),
                                transformation_type: gift.transformation_type || null,
                                receipt_amount: gift.receipt_amount || null
                            }]
                        },
                        type: 'POST',
                        success: (data) => {
                            messageModel.set({
                                gift: {
                                    id: data.ids && data.ids[0],
                                    template: templateModel.toJSON({ computed: true }),
                                    templateModel: templateModel,
                                    template_id: templateModel.id,
                                    expireDate: gift.expireDate,
                                    brand_id: templateModel.get('brand_id'),
                                    user_id: parentMessageModel.get('user_id'),
                                    cl_task_id: parentMessageModel.get('isTask') ?
                                        parentMessageModel.id : null,
                                    cl_comment_id: parentMessageModel.get('isComment') ?
                                        parentMessageModel.id : null,
                                    comment_id: messageModel.id,
                                    quantity: gift.quantity,
                                    quantity_initial: gift.quantity,
                                    transformation_type: gift.transformation_type || null,
                                    created_by: messageModel.get('user_id'),
                                }
                            });
                            resolve(messageModel);
                        },
                        error: (jqXHR, textStatus, errorThrown) => reject(new AjaxError(jqXHR, textStatus, errorThrown))
                    })
                }));
        } else {
            return Promise.resolve(messageModel);
        }

    }

    _uploadPhotos(messageModel, photoCollection) {
        const photoModels = photoCollection.reduce((photoModels, m) => {
            if (m.get('status') == CommentEditorPhotoModel.Status.ReadyToUpload) {
                const file = m.get('file');
                const id = m.id || null;

                if (file instanceof Blob || id !== null) {
                    photoModels.push(m);
                }
            }
            return photoModels;
        }, []);

        if (photoModels.length) {
            return this._createDraft(messageModel)
                .then(messageModel => _.reduce(photoModels, (promise, m) =>
                    promise.then(() => new Promise((function (resolve, reject) {
                        const file = m.get('file');
                        const id = m.id || null;
                        const data = new FormData();
                        data.append('comment_id', messageModel.id);

                        if (id !== null) {
                            data.append('TaskPhoto[image]', id);
                        } else if (file instanceof File) {
                            data.append('TaskPhoto[__image_file__]', file);
                        } else {
                            data.append('TaskPhoto[__image_file__]', file, "file.jpg");
                        }

                        m.set({ status: CommentEditorPhotoModel.Status.Uploading });
                        app.ajax({
                            url: settings.host + settings.serv_task.comment.addphoto,
                            type: "POST",
                            cache: false,
                            contentType: false,
                            processData: false,
                            data,
                            success: p => {
                                m.set({
                                    id: p.id,
                                    file: null,
                                    status: CommentEditorPhotoModel.Status.Uploaded,
                                    file_w: null,
                                    file_h: null,
                                    full: p.ph,
                                    '640': p.th,
                                    sizes: {
                                        full: {
                                            w: p.ph_w,
                                            h: p.ph_h
                                        },
                                        '640': {
                                            w: p.th_w,
                                            h: p.th_h
                                        }
                                    }
                                });
                                resolve(m);
                            },
                            error: (jqXHR, textStatus, errorThrown) =>
                                reject(new AjaxError(jqXHR, textStatus, errorThrown))
                        });
                    }))),
                    Promise.resolve())
                )
                .then(() => messageModel);
        } else {
            return Promise.resolve(messageModel);
        }
    }
}

export default CommentEditorModel;
