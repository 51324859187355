import { Trait, Model, ViewModel } from '@b2cmessenger/backbone';
import Scrollparent from 'scrollparent';
import './ContainerWithPullToRefresh.scss';

const traitId = Symbol("ContainerWithPullToRefresh");

class ContainerWithPullToRefresh extends Trait {
    constructor(options, _traitId) {
        super(_traitId || traitId);

        const trait = this;

        _.extend(this.options, _.defaults(options || (options = {}), {
            container() { return this.$el; },
            element() { return trait.container.children(); },
            initializeOnAttach: false,
            pullStartThreshold: 10,
            pullThreshold: 0.15,
            pullScale: 1 / 3.5
        }));

        _.extend(this.methods || (this.methods = {}),
            {
                onPulledToRefresh: this.onPulledToRefresh,
                onRender: this.onRender
            },
            this.options.initializeOnAttach ?
                {
                    onAttach: this.onRenderOrAttach
                }
                : {
                    onRender: this.onRenderOrAttach
                }
        );

        this._resultOption('container', { allowStringAsSelector: true });
        this._resultOption('element', { allowStringAsSelector: true });

        const touches = {};
        let isPullingToRefresh = false;

        function startPulling(el, parent) {
            const container = trait.container;

            isPullingToRefresh = true;

            container.addClass("trait-container-with-pull-to-refresh-pulling");
            container.removeClass("trait-container-with-pull-to-refresh-pulled");
            parent.style["-webkit-overflow-scrolling"] = "auto";
        }

        function stopPulling(el, parent) {
            const container = trait.container;

            isPullingToRefresh = false;

            el.style.transform = null;
            container.removeClass("trait-container-with-pull-to-refresh-pulling");
            container.removeClass("trait-container-with-pull-to-refresh-pulled");
            parent.style["-webkit-overflow-scrolling"] = null;
        }

        this.events = {
            'touchstart [data-js-trait-container-with-pull-to-refresh-element]'(e) {
                if (this.viewModel.get('traitContainerWithPullToRefreshCanBePulledToRefresh')) {
                    const el = e.currentTarget;
                    const parent = Scrollparent(el);

                    if (parent.scrollTop <= trait.options.pullStartThreshold) {
                        for (let i = 0; i < e.originalEvent.changedTouches.length; i++) {
                            const eventTouch = e.originalEvent.changedTouches.item(i);
                            touches[eventTouch.identifier] = {
                                el: e.currentTarget
                            };
                        }
                    }
                }
            },
            'touchmove [data-js-trait-container-with-pull-to-refresh-element]'(e) {
                const el = e.currentTarget;

                if (this.viewModel.get('traitContainerWithPullToRefreshCanBePulledToRefresh') && _.size(touches)) {
                    const parent = Scrollparent(el);

                    let distanceX = 0, distanceY = 0;
                    for (let i = 0; i < e.originalEvent.changedTouches.length; i++) {
                        const eventTouch = e.originalEvent.changedTouches.item(i),
                            touch = touches[eventTouch.identifier];
                        if (touch) {
                            if (!touch.start) {
                                touch.start = { x: eventTouch.pageX, y: eventTouch.pageY };
                            } else {
                                distanceX += eventTouch.pageX - touch.start.x;
                                distanceY += eventTouch.pageY - touch.start.y;
                            }
                        }
                    }

                    if (distanceY > 0 && !isPullingToRefresh) {
                        startPulling(el, parent);
                    } else if (isPullingToRefresh && distanceY <= 0) {
                        stopPulling(el, parent);
                    }

                    if (isPullingToRefresh) {
                        const height = parent.clientHeight;

                        let pull = Math.sqrt(distanceY / height) * trait.options.pullScale;

                        if (pull >= trait.options.pullThreshold) {
                            const container = trait.container;
                            pull = trait.options.pullThreshold;

                            _.each(_.keys(touches), k => delete touches[k]);
                            isPullingToRefresh = false;

                            el.style.transform = `translateY(${pull * height}px)`;
                            container.removeClass("trait-container-with-pull-to-refresh-pulling");
                            container.addClass("trait-container-with-pull-to-refresh-pulled");
                            requestAnimationFrame(() => {
                                el.style.transform = null;
                                parent.style["-webkit-overflow-scrolling"] = null;
                            });

                            this.triggerMethod('pulled:to:refresh');
                        } else {
                            el.style.transform = `translateY(${pull * height}px)`;
                        }

                        e.preventDefault();
                    }
                } else if (isPullingToRefresh) {
                    const parent = Scrollparent(el);

                    stopPulling(el, parent)
                }
            },
            'touchend [data-js-trait-container-with-pull-to-refresh-element]'(e) {
                for (let i = 0; i < e.originalEvent.changedTouches.length; i++) {
                    const id = e.originalEvent.changedTouches[i].identifier,
                        touch = touches[id];

                    if (touch) {
                        delete touches[id];

                        if (!_.find(touches, t => t.el == touch.el)) {
                            touch.el.style.transform = null;
                            const parent = Scrollparent(touch.el);
                            parent.style["-webkit-overflow-scrolling"] = null;
                        }
                    }
                }

                if (!_.size(touches)) {
                    const el = e.currentTarget;
                    stopPulling(el, Scrollparent(el));
                }
            }
        };

        _.each(_.keys(touches), k => delete touches[k]);
    };

    initialize(initialize, trait, ...args) {
        if (!this.viewModel) {
            this.viewModel = new ViewModel;
        }

        this.viewModel.set({
            traitContainerWithPullToRefreshCanBePulledToRefresh: true,
        });

        return initialize.apply(this, args);
    }

    onPulledToRefresh(f) {
        return f && f.apply(this);
    }

    onRenderOrAttach(f, trait) {
        f && f.apply(this);

        trait.container.attr('data-js-trait-container-with-pull-to-refresh', '');
        trait.container.addClass('trait-container-with-pull-to-refresh');

        trait.element.attr('data-js-trait-container-with-pull-to-refresh-element', '');
        trait.element.addClass('trait-container-with-pull-to-refresh-element');
    }
};

export default ContainerWithPullToRefresh;
