import { Model } from '@b2cmessenger/backbone';
import settings from 'settings';
import InfoModal from 'windows/Modal/Info';
import Window from 'windows/Window';

import HeaderView from 'widgets/HeaderWithTabs/HeaderWithTabs';
import FooterView from 'widgets/Footer/Footer';

import Collection from './GiftTemplatesCollection';
import CollectionView from './GiftTemplatesCollectionView';
import GiftTemplatesItemView from './GiftTemplatesItemView';
import GiftTemplatesEdit from 'windows/GiftTemplatesEdit/GiftTemplatesEdit';

import './GiftTemplates.scss';
import ViewWithWindows from 'traits/ViewWithWindows';

const GTYPE_COINS = 1;
const GTYPE_GIFTS = 2;
const GTYPE_DISCOUNTS = 3;

const withWindowsTrait = {
    Trait: ViewWithWindows,
    options: {
        windowMap: [
            {
                cls: GiftTemplatesEdit,
                trigger(value) {
                    if (value) {
                        if (value.model && value.model.id) {
                            const reg = this.getRegion('content');

                            if (reg) {
                                this.listenToOnce(reg, 'show', () => {
                                    const childView = this.collectionView.children.find(view => {
                                        return view.model.id == value.model.id;
                                    });

                                    if (childView) {
                                        childView.ui.editBtn.click();
                                    }
                                });
                            }
                        } else {
                            this.header.currentView.trigger('add:click');
                        }
                    }
                }
            }
        ],
    }
};

let GiftTemplates = Window.extendWithTraits([withWindowsTrait], {
    options: {
        transformationAllowed: true
    },

    className: 'gift-templates-list-window',

    initialize() {
        this.filterAttributes = {
            active: 1,
            type: false
        };
    },

    onRender() {
        this._renderHeader();
        this.dataRequest();

        if (this.options.choosable) {
            new InfoModal({
                additionalClassName: 'green-border',
                title: 'Please, choose gift template'
            }).show();
        }
    },

    _renderHeader() {
        let filterAttributes = this.filterAttributes,
            window = this;

        let headerView = new HeaderView({
            leftButtons: ['back'],
            title: 'Gift templates',
            rightButtons: [{
                id: 'add',
                text: 'Add',
                icn: 'add'
            }],
            tabs: [
                {
                    id: 'coins',
                    title: 'Coins',
                    icon: 'icon-coins',
                    type: GTYPE_COINS
                },
                {
                    id: 'gifts',
                    title: 'Gifts',
                    icon: 'icon-gift2',
                    type: GTYPE_GIFTS
                },
                {
                    id: 'discounts',
                    title: 'Discounts',
                    icon: 'icon-discount',
                    type: GTYPE_DISCOUNTS
                },
                {
                    id: 'active',
                    title: 'Active',
                    icon: 'ion-ios-checkmark-circle-outline',
                    active: filterAttributes.active > 0
                }
            ]
        });

        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.listenTo(headerView, 'add:click', () => {
            new GiftTemplatesEdit({
                model: new Model({
                    type: window.filterAttributes.type || GTYPE_COINS,
                    brand_id: window.options.brandId,
                    custom_quantity_allowed: 1,
                    is_for_business_checkin: 0,
                    transformation_type: 0,
                    transformation_ratio: 1000
                })
            })
                .show()
                .then(model => {
                    if (model) {
                        this.collectionView.collection.add(model);
                        this.trigger('reload');

                        _.defer(() => {
                            const cv = this.collectionView.children.findByModel(model);
                            if (cv) {
                                cv && cv.el && cv.el.scrollIntoCenter && cv.el.scrollIntoCenter();
                            }
                        });
                    }
                });
        });

        this.listenTo(headerView, 'tab:click', (parent, child) => {
            let type = child.model.get('type');

            if (type) {
                parent.resetTabs(['active']);

                if (window.filterAttributes.type == type) {
                    window.filterAttributes.type = false;
                    child.resetActive();
                } else {
                    window.filterAttributes.type = type;
                    child.setActive();
                }
            }

            if (child.model.get('id') == 'active') {
                window.filterAttributes.active = -window.filterAttributes.active;
                window.filterAttributes.active > 0 ? child.setActive() : child.resetActive();
            }

            this.triggerMethod('changeFilter');
        });

        this.header.show(headerView);
    },

    dataRequest() {
        Server.callServer({
            url: settings.host + settings.serv_gift.template.search,
            data: {
                brand_id : this.options.brandId
            },
            type: "POST",
            error: this.showError.bind(this)
        }).then(this.onFetch.bind(this));
    },

    onFetch(data) {
        this.collectionView = new CollectionView({
            collection: new Collection(data.templates),
            childView: GiftTemplatesItemView,
            filter: this.getCollectionFilter(),
            transformationAllowed: this.options.transformationAllowed,
            choosable: this.options.choosable,
        });

        this.collectionView.on('click', (child) => {
            this.close(child.model);
        });

        this.content.show(this.collectionView);
    },

    getCollectionFilter() {
        return (child, index, collection) => {
            let isActive = false;

            if(child.get('total_period')) {
                let expireDate = new Date(child.get('total_period').replace(/ /g, "T")),
                    expireDateLocal = new Date(expireDate);

                expireDateLocal.setFullYear(expireDate.getUTCFullYear(), expireDate.getUTCMonth(), expireDate.getUTCDate());
                expireDateLocal.setHours(23, 59, 59, 999);
                isActive = new Date() < expireDateLocal;
            } else {
                isActive = true;
            }

            if(child.get('active') == -1) isActive = false;

            let activity = this.filterAttributes.active === 1 ? isActive : !isActive;

            if(this.filterAttributes.type !== false) {
                return child.get('type') == this.filterAttributes.type && activity;
            }

            return activity;
        };
    },

    onChangeFilter() {
        this.collectionView.filter = this.getCollectionFilter();
        this.collectionView.render();
    },

    cancel() {
        this.close(this.model);
    }
});

/**
 * @todo
 * It's fallback for old design
 * Removed after all file will builds by webpack
 */
window.GiftTemplates = GiftTemplates;
export default GiftTemplates;
