import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactWindow from 'windows/ReactWindow';
import { connect } from 'react-redux';

import withStore from 'components/withStore';

import './MenuOrder.scss';
import { WindowContent, WindowFooter, WindowHeader } from 'components/window/Window';
import Header from 'components/UI/Header/Header';
import { BackButton } from 'components/UI/Button/Button';
import Footer from 'components/UI/Footer/Footer';
import TextButton from 'components/UI/TextButton';
import KeyboardPadding from 'components/KeyboardPadding';
import MenuPlaceOrder, { OrderStatus } from 'components/menu/MenuPlaceOrder/MenuPlaceOrder';
import MenuOrderStatusChangeWindow from 'components/window/MenuOrderStatusChange/MenuOrderStatusChange';
import * as MenuAPI from 'utils/api/menu';
import { showError } from 'windows/Modal/Info';
import Spinner from 'components/UI/Spinner/Spinner';
import MenuUserOrder from 'components/menu/MenuOrder/MenuOrder';
import ConfirmModal from 'windows/Modal/Confirm';
import { OrderStatusMap } from 'components/menu/MenuOrderStatus/MenuOrderStatus';
import { loadOrders, loadUserOrders } from 'utils/api/menu';

import { getPlaceMenuOrderById } from 'store/menu/orders/selectors';
import { onEditOrder } from 'store/menu/orders/actions';

export const MenuOrderWindowContentType = {
    User: 'user',
    Place: 'place',
};

const MenuOrder = (props) => {
    const { isMounted, windowContentType, onChangeOrder = _.noop, onEditOrder = _.noop, ...restProps } = props;
    const { orderFromStore } = props;
    const [order, setOrder] = useState();
    const [loading, setLoading] = useState(false);

    const changeOrder = useCallback(
        (order) => {
            if (isMounted()) {
                setOrder((prevOrder) => ({ ...prevOrder, ...order }));
            }

            onChangeOrder.call(null, order);
        },
        [isMounted, onChangeOrder, setOrder]
    );

    const getOrderByIdAsync = useCallback(
        async ({ orderId, windowContentType, placeId }) => {
            if (!orderId) {
                return null;
            }

            setLoading(true);

            try {
                let order = null;
                // all statuses to find order by id
                const status = _(OrderStatus).map((m) => m);

                switch (windowContentType) {
                    case MenuOrderWindowContentType.Place: {
                        const { orders = [] } = await loadOrders({
                            placeId,
                            includeUser: true,
                            includeWaiterRequests: true,
                            status,
                            ids: [orderId],
                        });

                        if (orders.length > 0) {
                            order = orders[0];
                        }
                        break;
                    }

                    case MenuOrderWindowContentType.User: {
                        const { orders = [] } = await loadUserOrders({
                            ids: [orderId],
                            status,
                            includePlace: true,
                            includeWaiterRequests: true,
                        });

                        if (orders.length > 0) {
                            order = orders[0];
                        }
                        break;
                    }

                    default:
                        break;
                }

                if (order) {
                    return order;
                }
                showError(`Order not found`);
                props.closeWindow();
            } catch (e) {
                showError(e);
                props.closeWindow();
            } finally {
                setLoading(false);
            }
        },
        [props.closeWindow]
    );

    const setOrderById = useCallback(
        async (orderId) => {
            const order = await getOrderByIdAsync({
                orderId,
                placeId: props.placeId,
                windowContentType,
            });

            if (order) {
                setOrder(order);
            }
        },
        [getOrderByIdAsync, props.placeId, windowContentType]
    );

    useEffect(() => {
        if (props.order) {
            setOrder(props.order);
        }
    }, [props.order]);

    useEffect(() => {
        if (orderFromStore) {
            return setOrder(orderFromStore);
        }

        if (props.orderId) {
            setOrderById(props.orderId);
        }
    }, [props.orderId, setOrderById, orderFromStore]);

    const changeOrderStatusAsync = useCallback(
        async ({ placeId, status, id, includeUser, includePlace, items }) => {
            setLoading(true);
            try {
                const changedOrder = await MenuAPI.changeOrder({
                    placeId,
                    id,
                    data: {
                        status,
                    },
                    includeUser,
                    includePlace,
                    items,
                });

                changeOrder(changedOrder);
            } catch (e) {
                isMounted() && showError(e instanceof Error ? e.message : e);
            } finally {
                isMounted() && setLoading(false);
            }
        },
        [isMounted, setLoading, showError, changeOrder]
    );

    const onCancelOrder = useCallback(async () => {
        const window = new ConfirmModal({ message: 'Do you really want to cancel order?' });
        const result = await window.show();

        if (!result) {
            return;
        }

        changeOrderStatusAsync({
            id: order.id,
            placeId: order.place_id || order.placeId,
            status: OrderStatusMap.Canceled,
        });
    }, [changeOrderStatusAsync, order]);

    const onChangeOrderStatus = useCallback(
        async (order) => {
            const window = new MenuOrderStatusChangeWindow({ order });
            const result = await window.show();

            if (!result) {
                return;
            }

            changeOrderStatusAsync({
                id: order.id,
                placeId: props.placeId || order.place_id || order.placeId,
                status: result.status,
                includeUser: Boolean(order.user),
                includePlace: Boolean(order.place),
            });
        },
        [changeOrderStatusAsync, props.placeId]
    );

    const headerTitle = useMemo(() => {
        if (order) {
            if (windowContentType === MenuOrderWindowContentType.Place) {
                return `Order #${order.order_number}`;
            }
            if (order.place && order.place.name) {
                return `Your order in ${order.place.name}`;
            }
        }

        return 'Menu order info';
    }, [order, windowContentType]);

    const onEditOrderClick = useCallback(() => {
        if (order) {
            props.closeWindow();
            onEditOrder(order);
        }
    }, [onEditOrder, props.closeWindow, order]);

    return (
        <>
            <WindowHeader>
                <Header>
                    <BackButton onClick={() => props.closeWindow()} />
                    <h1 className="title">{headerTitle}</h1>
                </Header>
            </WindowHeader>
            <WindowContent>
                {order && windowContentType === MenuOrderWindowContentType.User && (
                    <MenuUserOrder
                        displayAdditions
                        displayProperties
                        isWindow
                        onEditOrderClick={onEditOrderClick}
                        changeOrder={changeOrder}
                        {...restProps}
                        order={order}
                        onCancelOrder={onCancelOrder}
                    />
                )}
                {order && windowContentType === MenuOrderWindowContentType.Place && (
                    <MenuPlaceOrder
                        displayAdditions
                        displayProperties
                        {...restProps}
                        order={order}
                        onEditOrderClick={onEditOrderClick}
                        onChangeStatusClick={onChangeOrderStatus}
                    />
                )}
                {loading && <Spinner />}
            </WindowContent>
            <WindowFooter>
                <Footer>
                    <TextButton onClick={() => props.closeWindow()}>CLOSE</TextButton>
                </Footer>
            </WindowFooter>
            <KeyboardPadding />
        </>
    );
};

const ConnectedMenuOrder = withStore(
    connect(
        (state, ownProps) => ({
            orderFromStore: getPlaceMenuOrderById(state, ownProps.orderId),
        }),
        {
            onEditOrder,
        }
    )(MenuOrder)
);

const MenuOrderWindow = ReactWindow.extend({
    constructor: function MenuOrderWindow(props) {
        const options = {
            props: {
                ...props,
                windowContentType: props.windowContentType || MenuOrderWindowContentType.User,
            },
            component: ConnectedMenuOrder,
            name: 'MenuOrder',
        };

        return ReactWindow.apply(this, [options]);
    },
});

export default MenuOrderWindow;
