import { Model, ItemView, ViewModel } from '@b2cmessenger/backbone';
import settings from 'settings';

import Window from 'windows/Window';
import HeaderView from 'widgets/Header/Header';
import FooterView from 'widgets/Footer/Footer';
import NewPasswordWindow from 'windows/NewPassword/NewPassword';

import template from './Content.jade';
import './ResetPassword.scss';

const Content = ItemView.extend({
    template,

    ui: {
        generalError: '[data-js-general-error]',
        emailForm: '[data-js-email-form]',
        email: '[data-js-email]',
        emailError: '[data-js-email-error]',
        emailSubmit: '[data-js-email-submit]',
        codeForm: '[data-js-code-form]',
        code: '[data-js-code]',
        codeError: '[data-js-code-error]',
        codeSubmit: '[data-js-code-submit]',
    },

    bindings: {
        '@ui.generalError': 'text:generalError',
        '@ui.email': 'disabled:disabled,value:email',
        '@ui.emailError': 'text:emailError',
        '@ui.emailSubmit': 'disabled:any(disabled,not(email))',
        '@ui.code': 'disabled:any(disabled,not(email)),value:code',
        '@ui.codeError': 'text:codeError',
        '@ui.codeSubmit': 'disabled:any(disabled,not(email),not(code))'
    },

    events: {
        'change @ui.email'(e) {
            this.viewModel.set({
                emailError: '',
            });
        },
        'input @ui.email'(e) {
            this.ui.email.change();
        },
        'submit @ui.emailForm'(e) {
            e && e.preventDefault();

            if (this.model.get('email')) {
                this.viewModel.set({
                    emailError: '',
                    generalError: '',
                    disabled: true
                });

                Backbone.ajax({
                    url: settings.host + settings.serv_user.passwordreset,
                    method: 'post',
                    data: {
                        email: this.model.get('email'),
                    },
                    success: () => {
                        this.viewModel.set({ disabled: false });
                        this.showMessage('Reset code was sent to your email');
                    },
                    error: (jqXHR, textStatus, errorThrown) => {
                        this.viewModel.set({ disabled: false });

                        if (jqXHR.status == 422 && _.isArray(jqXHR.responseJSON)) {
                            this.viewModel.set(_(jqXHR.responseJSON).reduce((fields, o) => {
                                let field = o.field,
                                    msg = o.message;

                                switch (field) {
                                    case 'email':
                                        msg = o.message && String(o.message);
                                        break;
                                    default:
                                        field = 'general';
                                        msg = o.message && String(o.message);
                                }

                                if (field && msg) {
                                    fields[field + 'Error'] = msg;
                                }

                                return fields;
                            }, _.create(null)));
                        } else if (jqXHR.status == 422 && jqXHR.responseJSON.error && jqXHR.responseJSON.error.message) {
                            this.viewModel.set({
                                emailError: jqXHR.responseJSON.error.message
                            });
                        } else {
                            this.showError(jqXHR, textStatus, errorThrown)
                        }
                    }
                });
            } else {
                this.viewModel.set({
                    emailError: 'Please enter email',
                });
            }

            return false;
        },
        'change @ui.code'(e) {
            this.viewModel.set({
                codeError: '',
            });
        },
        'input @ui.code'(e) {
            this.ui.code.change();
        },
        'submit @ui.codeForm'(e) {
            e && e.preventDefault();

            if (this.model.get('email') && this.model.get('code')) {
                this.viewModel.set({
                    emailError: '',
                    codeError: '',
                    generalError: '',
                    disabled: true
                });

                Server.callServer({
                    url: settings.host + settings.serv_user.passwordresetcode,
                    method: 'post',
                    data: {
                        email: this.model.get('email'),
                        code: this.model.get('code')
                    },
                    success: data => {
                        this.viewModel.set({ disabled: false });

                        if (data.access_token) {
                            LoginedUserHandler.setAccessToken(data.access_token);
                        }

                        if (data.email) {
                            this.model.set({ email: data.email });
                        }

                        LoginedUserHandler.setLoginedUser({
                            id: data.id,
                            email: data.email,
                            firstname: data.firstname,
                            lastname: data.lastname || "",
                            phone: data.phone,
                            birthday: data.birthday,
                            gender: data.gender,
                            avatar: data.avatar,
                            avatarThumb: data.avatarThumb ? data.avatarThumb : data.avatar,
                            settings: {
                                viewanonym: (data.viewanonym == null ? true : data.viewanonym),
                                viewunverified: (data.viewunverified == null ? true : data.viewunverified),
                            }
                        });

                        this.trigger('loggedIn', data.new_password);
                    },
                    error: (jqXHR, textStatus, errorThrown) => {
                        this.viewModel.set({ disabled: false });

                        if (jqXHR.status == 422 && _.isArray(jqXHR.responseJSON)) {
                            this.viewModel.set(_(jqXHR.responseJSON).reduce((fields, o) => {
                                let field = o.field,
                                    msg = o.message;

                                switch (field) {
                                    case 'email':
                                        msg = o.message && String(o.message);
                                        break;
                                    case 'code':
                                    case 'hashcode':
                                        field = 'code';
                                        msg = o.message && String(o.message);
                                        break;
                                    case 'passwordResetAttemptsRemain':
                                        if (o.message) {
                                            field = 'general';
                                            msg = 'Reset attempts remain: ' + o.message;
                                        }
                                        break;
                                    default:
                                        field = 'general';
                                        msg = o.message && String(o.message);
                                }

                                if (field && msg) {
                                    fields[field + 'Error'] = msg;
                                }

                                return fields;
                            }, _.create(null)));
                        } else if (jqXHR.status == 422 && jqXHR.responseJSON.error && jqXHR.responseJSON.error.message) {
                            this.viewModel.set({
                                codeError: jqXHR.responseJSON.error.message
                            });
                        } else {
                            this.showError(jqXHR, textStatus, errorThrown)
                        }
                    }
                });
            } else {
                if (!this.model.get('email')) {
                    this.viewModel.set({
                        emailError: 'Please enter email',
                    });
                } else {
                    this.viewModel.set({
                        codeError: 'Please enter code',
                    });
                }
            }

            return false;
        },
    },

    initialize() {
        if (!this.viewModel) {
            this.viewModel = new ViewModel;
        }

        this.viewModel.set({
            emailError: '',
            codeError: '',
            generalError: '',
        });

        if (!this.model) {
            this.model = new Model({
                email: '',
                code: ''
            });
        }

        if (!this.model.has('email')) {
            this.model.set({
                email: ''
            });
        }

        if (!this.model.has('code')) {
            this.model.set({
                code: ''
            });
        }
    },

    onAttach() {
        if (this.model.get('email')) {
            this.ui.emailForm.submit();
        }
    }
});

export default Window.extend({
    windowName: 'reset-password-window',

    initialize() {
        this.model = new Model({
            email: this.options.email
        });
    },

    onAttach() {
        const headerView = new HeaderView({
            leftButtons: ['back'],
            title: 'Reset password',
            rightButtons: []
        });

        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.header.show(headerView);

        const content = new Content({
            model: this.model,
            viewModel: this.viewModel
        });

        content.on('loggedIn', (password) => {
            this.close();
            new NewPasswordWindow({ password }).show();
        });

        this.content.show(content);
    }
});
