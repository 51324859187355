import Window from 'windows/Window';
import PlaceEditorWindow from 'windows/PlaceEditor/PlaceEditor';

import ViewWithWindows from 'traits/ViewWithWindows';
import { PlaceSearchCore } from 'windows/PlaceSearch/PlaceSearchCore';
import B2CPlace from 'widgets/B2CPlace';

const withWindowsTrait = {
    Trait: ViewWithWindows,
    options: {
        windowMap: [
            {
                cls: PlaceEditorWindow,
                trigger() {
                    this.results.currentView.trigger('addnew:click');
                }
            }
        ],
    }
};

let PlaceSearchWindow = Window.extendWithTraits(
    [withWindowsTrait],
    _.defaults({
        className: "place-search-window place-search-core ",
        windowName: 'place-search-window',
        show(options) {
            return Window.prototype.show.apply(this, arguments);
        },
        cancel(options) {
            return Window.prototype.cancel.apply(this, arguments);
        },
        onShow(options) {
            this.init();

            _.defaults(options || (options = {}), {
                selectPlaceOnce: false,
                favorites: false,
            });

            if (options.selectPlaceOnce) {
                this._enableSelectMode(options.selectPlaceOnce);
            } else {
                if (_.isFunction(this._onPlaceSelectedOnce)) {
                    try {
                        this._onPlaceSelectedOnce(null);
                    } catch (e) {
                        this.showError(e);
                    }
                }
                this._disableSelectMode();
            }

            if (options.resetToDefaults) {
                if (_.isObject(options.resetToDefaults)) {
                    this.filterCont.setFilterToDefaults(
                        _.defaults(
                            options.resetToDefaults,
                            _.pick(options, ['favorites'])
                        )
                    );
                } else {
                    this.filterCont.setFilterToDefaults(_.pick(options, ['favorites']));
                }
            } else if (!_.isUndefined(options.favorites)) {
                this.filterCont.setIsFavoriteSearchMode(!!options.favorites);
            }
        },
        _is_need_load_more_by_page_scroll() {
            if (this.stopCheckingLoadingOnScroll) return false;

            const lastPlace = this.results.currentView.children.last(),
                lastPlaceHeight = lastPlace ? lastPlace.el.clientHeight : 0,
                threshold = B2CPlace.const.search.load_limit / 2 * lastPlaceHeight;

            if (this.results.el.scrollHeight > 0) {
                return this.results.el.scrollHeight - this.results.el.scrollTop - this.results.el.clientHeight <= threshold;
            } else {
                return false;
            }
        }
    }, PlaceSearchCore.protoProps),
    _.defaults({}, PlaceSearchCore.staticProps)
);

export default PlaceSearchWindow;
