import ReactDOM from "react-dom";
import React from "react";
import {showLoading, hideLoading} from 'windows/Modal/Loading';
import {showError, showMessage} from 'windows/Modal/Info'
import {Model} from '@b2cmessenger/backbone';
import Window from './Window';

const StaticProps = {
    showLoading,
    hideLoading,
    showMessage,
    showError,
};

const ReactWindow = Window.extend({
    isReactWindow: true,

    constructor: function ReactWindow(options) {
        if (!options.renderFn && !options.component) {
            throw new Error('Neither `renderFn` or `component` property of `ReactWindow` is presented');
        }

        _.extend(this, _.pick(options, ['renderFn', 'component']));

        this.setProps(options.props);
        this.setStaticProps(options.staticProps);

        if (options.model) {
            this.model = options.model;
        } else {
            this.model = new Model(this.props ? this.props : null);
        }

        const windowOptions = { ...options };

        let windowName = options.windowName || 'react-window';
        const classNames = options.className && options.className.split(' ') || [];

        if (_.isString(options.name)) {
            windowName = toKebabCase(`${options.name}Window`);
        }

        if (classNames.indexOf('window') === -1) {
            classNames.push('window');
        }

        if (classNames.indexOf('react-window') === -1) {
            classNames.push('react-window');
        }

        if (classNames.indexOf(windowName)) {
            classNames.push(windowName);
        }

        _.extend(windowOptions, {
            windowName,
            className: classNames.join(' '),
            template: false,
            model: this.model,
        });

        return Window.apply(this, [windowOptions]);
    },

    renderReactComponent() {
        const Component = this.component;

        const modelProps = this.model.toJSON();
        const props = {
            ...this.props,
            ...this.staticProps,
            ...modelProps
        };

        const renderFn = this.renderFn || function () {
            return <Component {...props}/>;
        };

        ReactDOM.render(renderFn(), this.el);
    },

    setProps(value, options = { triggerChanges: true }) {
        this.props = { ...this.props, ...value };

        if (options && options.triggerChanges && this.model) {
            this.model.set(this.props);
        }
    },

    setStaticProps(value, options = { triggerChanges: true }) {
       this.staticProps = {
           ...StaticProps,
           closeWindow: ReactWindow.prototype.close.bind(this),
           isMounted: this.isMounted.bind(this),
           isUnmounted: this.isUnmounted.bind(this),
           ...value
       };

       if (options && options.triggerChanges && this.model) {
           this.model.trigger('change');
       }
    },

    onShow(...args) {
        this.renderReactComponent();

        return Window.prototype.onShow.call(this, ...args)
    },

    onRender() {
        this.renderReactComponent();
        this.listenTo(this.model, 'change', _.debounce(this.renderReactComponent.bind(this), 10));

        this._mounted = true;
    },

    isMounted() {
        return Boolean(this._mounted);
    },

    isUnmounted() {
        return _.negate(this.isMounted.bind(this));
    },

    onDestroy() {
        if (this._mounted) {
            this._mounted = false;
            ReactDOM.unmountComponentAtNode(this.el);
        }
    },
});

function toKebabCase(str) {
    return str && str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.toLowerCase())
        .join('-');
}

export default ReactWindow;
