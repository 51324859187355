import MenuList, { MenuListItem } from 'components/menu/MenuList/MenuList';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import React from 'react';
import './MenuSortableList.scss';

const DragHandle = () => <i className="ion-ios-menu sort-drag" />;
const MenuSortableList = SortableContainer(MenuList);
export const MenuSortableListItem = SortableElement(MenuListItem);
export const MenuSortableListDragHandle = SortableHandle(DragHandle);
export const MenuSortaBleListItemDragClassName = 'menu-sortable-list-item-dragging';

export default MenuSortableList;
