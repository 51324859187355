import MenuItemEditorContainer from "containers/menu/MenuItemEditorContainer";
import './MenuItemEditorWindow.scss';
import ReactWindow from "windows/ReactWindow";

const MenuItemEditorWindow = ReactWindow.extend({
    constructor: function MenuItemEditorWindow(props) {
        const options = {
            props,
            component: MenuItemEditorContainer,
            name: 'MenuItemEditorContainer'
        };

        return ReactWindow.apply(this, [options]);
    }
});

export default MenuItemEditorWindow;
