import React from 'react';
import PropTypes from 'prop-types';
import './MenuWaiters.scss';

import MenuList, {
    byIdAndSortOrder,
    MenuListEmptyItem,
    MenuListItem,
    MenuListType,
} from 'components/menu/MenuList/MenuList';
import { UserItemBase } from 'components/menu/UserItem/UserItem';
import { SliderGroup, SwitchInput } from 'components/UI/InputGroup/InputGroup';
import { Button } from 'components/UI/Button/Button';

const MenuWaiters = (props) => {
    const {
        waiters = [],
        workingWaitersIds = [],
        onClickWaiterOrders = _.noop,
        emptyContentMessage = '',
        disabled,
        changeEmployeeStatus = _.noop,
    } = props;

    const itemList = waiters.map((i) => {
        const working = Boolean(workingWaitersIds.indexOf(i.id) > -1);

        return (
            <MenuListItem className="menu-list-item-wrap menu-waiters" key={byIdAndSortOrder(i)}>
                <UserItemBase className="menu-list-item" {...i}>
                    <Button wrapChildren={false} icon="ion-md-cart" onClick={onClickWaiterOrders.bind(null, i.id)}>
                        Orders
                    </Button>
                    <SliderGroup className="now-working outline" caption="Working status">
                        <SwitchInput
                            disabled={disabled}
                            value={working}
                            onChange={(value) => changeEmployeeStatus && changeEmployeeStatus(value, i.id)}
                        />
                    </SliderGroup>
                </UserItemBase>
            </MenuListItem>
        );
    });

    const emptyContent = <MenuListEmptyItem message={emptyContentMessage || 'No working waiters'} />;
    return (
        <MenuList emptyContent={emptyContent} {...props} type={MenuListType.Users}>
            {itemList}
        </MenuList>
    );
};

MenuWaiters.propTypes = {
    waiters: PropTypes.array,
    workingWaitersIds: PropTypes.array,
    onClickWaiterOrders: PropTypes.func,
    emptyContentMessage: PropTypes.string,
    changeEmployeeStatus: PropTypes.func,
};

export default MenuWaiters;
