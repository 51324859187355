import React, { useState, useCallback, useMemo, useEffect } from 'react';
import ReactPage from 'pages/ReactPage';
import { connect } from 'react-redux';
import store from 'store/index';
import './MenuItemDetail.scss';

import Page, { PageContent, PageHeader } from 'components/page/Page';
import Header from 'components/UI/Header/Header';
import { BackButton, EditButton, MenuButton, CartButton } from 'components/UI/Button/Button';
import withStore from 'components/withStore';
import MenuItemDetail from 'components/menu/MenuItemDetail/MenuItemDetail';
import Spinner from 'components/UI/Spinner/Spinner';
import MenuItemError from 'components/menu/MenuItemDetail/MenuItemError';
import { getPreparedMenuItemDetail } from 'containers/menu/MenuItemHelpers';
import MenuItemEditorWindow from 'windows/MenuItemEditor/MenuItemEditorWindow';
import MenuBrowserWindow from 'windows/MenuBrowser/MenuBrowser';
import {
    getMenuItem,
    getMenuLoadingItem,
    getMenuItemError,
    getMenuIsItemErrorCritical,
    getMenuItemCartQuantity,
    getMenuCartSliceByItemId,
    getMenuCartItemByUniqueId,
    getMenuCartTotalQuantity,
    getMenuIsEditPossible,
} from 'store/menu/selectors';
import { getIsUserLoggedIn } from 'store/app/selectors';
import { loadMenuItemDetail } from 'store/menu/actions';
import MenuCartItemCustomizeWindow from 'components/window/MenuCartItemCustomize/MenuCartItemCustomize';
import {
    addToCart,
    changeCartItem,
    decreaseQuantityOrRemoveFromCart,
    removeFromCartByUniqueId,
} from 'store/menu/cart/actions';
import MenuCartItemRemoveChooserWindow from 'components/window/MenuCartItemRemoveChooser/MenuCartItemRemoveChooser';
import MenuAdditionsManagementWindow from 'components/window/MenuAdditionsManagement/MenuAdditionsManagement';
import useInitializeMenu from 'hooks/useInitializeMenu';

const MenuItemDetailContent = (props) => {
    const {
        loading,
        error,
        isErrorCritical,
        item,
        isActive,
        itemId: id,
        placeId,
        isEditPossible,
        loadMenuItemDetail,
        count,
        totalQuantity,
        isUserLoggedIn,
    } = props;

    const {
        decreaseQuantityOrRemoveFromCart,
        removeFromCartByUniqueId,
        changeCartItem,
        getMenuCartSlice,
        addToCart,
    } = props;

    const [preSelectedAdditions, setPreSelectedAdditions] = useState([]);
    const [previousUsedAdditions, setPreviousUsedAdditions] = useState([]);

    const { readyToInitialize, initialized } = useInitializeMenu({ placeId, isActive });

    const contentReady = !error && !loading && Boolean(item) && Number(item.id) === Number(id);
    const preparedItem = useMemo(() => getPreparedMenuItemDetail(item, true), [item]);
    const goToMenuPage = useCallback(() => app.controller.goToMenuPage({ placeId }), [placeId]);
    const fetchData = useCallback(() => loadMenuItemDetail(placeId, id), [loadMenuItemDetail, placeId, id]);
    const editItem = useCallback(() => {
        new MenuItemEditorWindow({
            placeId,
            menuElementId: id,
        }).show();
    }, [id, placeId]);
    const displayAdditionCategory = useCallback(
        (id) => {
            const { additions = [], additionItems = [] } = preparedItem;

            const idx = _.findIndex(additions, (c) => c.id === id);
            const category = additions[idx] || null;
            const items = additionItems[idx] || null;
            const selectedAdditions = preSelectedAdditions[idx] || [];

            if (category) {
                if (!isUserLoggedIn) {
                    new MenuBrowserWindow({
                        title: category.name,
                        select: MenuBrowserWindow.Select.None,
                        displayCategories: false,
                        displayItems: true,
                        additions: items,
                    }).show();

                    return;
                }
                new MenuAdditionsManagementWindow({
                    title: category.name,
                    additionCategories: [category],
                    additionItems: _(items).map((item) => ({ ...item, categoryId: category.id })),
                    additions: selectedAdditions,
                    setAdditionItems: (additions) => {
                        setPreSelectedAdditions((prev) => {
                            return {
                                ...prev,
                                [idx]: additions,
                            };
                        });
                    },
                }).show();
            }
        },
        [preparedItem, preSelectedAdditions, isUserLoggedIn]
    );

    const onAddToCart = useCallback(() => {
        LoginedUserHandler.ensureUserLoggedIn(() => {
            if (item.is_customizable) {
                const additions = _(preSelectedAdditions).reduce((memo, current) => {
                    return memo.concat(current);
                }, []);
                new MenuCartItemCustomizeWindow({
                    menuItemId: item.id,
                    placeId,
                    customizationOptions: {
                        additions,
                    },
                    previousUsedAdditions,
                })
                    .show()
                    .then((result) => {
                        if (result) {
                            const { menuItemId, count, additions, properties, comment } = result;
                            addToCart({ id: menuItemId, additions, properties, comment }, count);
                            setPreSelectedAdditions([]);
                            setPreviousUsedAdditions(additions);
                        }
                    });

                return;
            }

            addToCart({ id: item.id }, 1);
        });
    }, [item, placeId, addToCart, preSelectedAdditions, previousUsedAdditions]);

    const onRemoveFromCart = useCallback(() => {
        const cartSlice = getMenuCartSlice(item.id);

        if (cartSlice.cartItems.length > 1) {
            new MenuCartItemRemoveChooserWindow({
                cartItems: cartSlice.cartItems,
                items: cartSlice.items,
            })
                .show()
                .then((result = null) => {
                    if (result !== null) {
                        for (const changeKey in result.change) {
                            if (result.change.hasOwnProperty(changeKey)) {
                                const value = result.change[changeKey];
                                console.log(`change cart item with uniqueId=${changeKey} to value:`, value);
                                changeCartItem(getMenuCartItemByUniqueId(store.getState(), changeKey), value);
                            }
                        }

                        for (const removeKey in result.remove) {
                            if (result.remove.hasOwnProperty(removeKey)) {
                                console.log(`remove cart item with uniqueId=${removeKey}`);
                                removeFromCartByUniqueId(removeKey);
                            }
                        }
                    }
                });
        } else {
            decreaseQuantityOrRemoveFromCart(_.first(cartSlice.cartItems), count > 1 ? 1 : null);
        }
    }, [item, getMenuCartSlice]);

    const goToCart = useCallback(() => {
        app.controller.goToMenuCart({ placeId });
    }, [placeId]);

    useEffect(() => {
        if (initialized && isActive) {
            loadMenuItemDetail(placeId, id);
        }
    }, [placeId, id, initialized, isActive]);

    const content = contentReady ? (
        <MenuItemDetail
            {...preparedItem}
            displayAdditionCategory={displayAdditionCategory}
            count={count}
            onAddToCart={onAddToCart}
            onRemoveFromCart={onRemoveFromCart}
        />
    ) : error ? (
        <MenuItemError
            displayRetryButton={!isErrorCritical}
            errorText={error}
            goToMainMenuPage={goToMenuPage}
            fetchData={fetchData}
        />
    ) : (
        <Spinner />
    );

    const onShow = useCallback(() => {
        if (readyToInitialize) {
            readyToInitialize(true);
        }
    }, [readyToInitialize]);

    return (
        <Page instance={props.__page__} onShow={onShow}>
            <PageHeader>
                <Header>
                    <BackButton onClick={props.goBack} />
                    <h1 className="title">Dish Details</h1>
                    {isEditPossible && <EditButton onClick={editItem} />}
                    <CartButton quantity={totalQuantity} onClick={goToCart} />
                    <MenuButton right />
                </Header>
            </PageHeader>
            <PageContent className="scrollable grow">{content}</PageContent>
        </Page>
    );
};

const ConnectedMenuItemDetailContent = withStore(
    connect(
        (state) => ({
            isEditPossible: getMenuIsEditPossible(state),
            item: getMenuItem(state),
            count: getMenuItemCartQuantity(state),
            totalQuantity: getMenuCartTotalQuantity(state),
            loading: getMenuLoadingItem(state),
            error: getMenuItemError(state),
            isErrorCritical: getMenuIsItemErrorCritical(state),
            getMenuCartSlice: (itemId) => getMenuCartSliceByItemId(state, itemId),
            isUserLoggedIn: getIsUserLoggedIn(state),
        }),
        {
            loadMenuItemDetail,
            addToCart,
            changeCartItem,
            decreaseQuantityOrRemoveFromCart,
            removeFromCartByUniqueId,
        }
    )(MenuItemDetailContent)
);

const MenuItemDetailPage = ReactPage.extend({
    immediateRenderOnShow: true,
    attributes: {
        id: 'page_menu_item',
    },
    className: 'menu-item-page',
    component: ConnectedMenuItemDetailContent,
    getPlaceId() {
        return this.props.get('placeId');
    },

    getMenuItemId() {
        return this.props.get('itemId');
    },
});

export default MenuItemDetailPage;
