/**
 * Created by Alex on 23.05.2016.
 */
/**
 *
 * @param options
 * @param options.days - array
 * days[] |  array |  Дни работы заведения
 * days[][d] | integer | {not blank} [0|1|2|3|4|6] |  Номер дня: 0 - Воскресенье; 6 - Суббота
 * days[][all] |  integer | [-1|1] | -1 - весь день закрыт;  1 - весь день открыт
 * days[][int][] | array |  Массив интервалов работы заведения внтури дня
 * days[][int][][o_h] | integer | [0..23] | Час открытия
 * days[][int][][o_m] | integer | [0..59] | Минута открытия
 * days[][int][][с_h] | integer | [0..23] | Час закрытия
 * days[][int][][с_m] | integer | [0..59] | Минута закрытия

 * @param options.is_view_mode - boolean Если true, то виджет работает в режиме показа графика работы, иначе в режиме редактирования
 * @constructor
 */
function B2CWorkHours(options) {
    options = $.extend(false, {}, this.def_options, options);
    this.options = options;

    if (options.days == null)
        options.days = this.def_days.slice();

    this.days = options.days;
    this.days.sort(function (l, r) { return l.d - r.d; });
}

B2CWorkHours.const = {}; // Константы
B2CWorkHours.const.day_num_to_name = {
    0 : 'Sunday',
    1 : 'Monday',
    2 : 'Tuesday',
    3 : 'Wednesday',
    4 : 'Thursday',
    5 : 'Friday',
    6 : 'Saturday'
};

B2CWorkHours.const.day_status = {};
B2CWorkHours.const.day_status.all_day_open = 1; // Целый день открыт
B2CWorkHours.const.day_status.all_day_closed = -1; // Целый день закрыт

B2CWorkHours.const.text = {};
B2CWorkHours.const.text.all_day = 'Open 24 hours';
B2CWorkHours.const.text.closed = 'Closed';

B2CWorkHours.const._className = 'B2CWorkHours';

B2CWorkHours.prototype = {
    _className : B2CWorkHours.const._className,
    def_options : {
    },
    def_days : [
        {d: 0, all: B2CWorkHours.const.day_status.all_day_closed},
        {d: 1, all: B2CWorkHours.const.day_status.all_day_closed},
        {d: 2, all: B2CWorkHours.const.day_status.all_day_closed},
        {d: 3, all: B2CWorkHours.const.day_status.all_day_closed},
        {d: 4, all: B2CWorkHours.const.day_status.all_day_closed},
        {d: 5, all: B2CWorkHours.const.day_status.all_day_closed},
        {d: 6, all: B2CWorkHours.const.day_status.all_day_closed},
    ],

    build_HTML_view_B2CWorkHours : function() {
        return '<div class="'+quoteattr(this._className)+' view"><p class="header">Working hours</p>'+this._build_HTML_view_body()+'</div>';
    },
    _build_HTML_view_body : function() {
        var html = '<div class="body">';

        for(var i=0; i<this.days.length; i++) {
            html += this._build_HTML_view_day(this.days[i]);
        }

        html += '</div>';
        return html;
    },
    _build_HTML_view_day : function(day_info) {
        var day_name = B2CWorkHours.const.day_num_to_name[day_info.d];
        var day_cl = 'd_'+ day_name;
        
        var html = '<div class="day '+quoteattr(day_cl)+'" data-d="'+quoteattr(day_info.d)+'"><span class="left"><span class="name">'+escapeHtml(day_name)+'</span></span>';
        
        
                
        var state_inner;
        if (day_info.all == B2CWorkHours.const.day_status.all_day_open)
            state_inner = '<span class="all_day_open">'+B2CWorkHours.const.text.all_day+'</span>';
        else if (day_info.all == B2CWorkHours.const.day_status.all_day_closed) {
            state_inner = '<span class="all_day_closed">'+B2CWorkHours.const.text.closed+'</span>';
        } else {
            state_inner = '<div class="intervals_cont">';
            for(var i=0; i<day_info.int.length; i++) {
                state_inner += this._build_HTML_view_day_interval(day_info.int[i]);    
            }
            state_inner += '</div>';
        }
        
        html += '<span class="right">'+state_inner+'</span></div>';
        return html;
    },
    _build_HTML_view_day_interval : function(interval) {
        var open_time_point_html = this._build_HTML_view_day_time_point(interval.o_h, interval.o_m, true);
        var close_time_point_html = this._build_HTML_view_day_time_point(interval.c_h, interval.c_m, false);
        return '<div class="interval">'+open_time_point_html+close_time_point_html+'</div>';
    },
    _build_HTML_view_day_time_point : function(h, m, is_start) {
        var cl_type;
        if(is_start)
            cl_type = 'start';
        else
            cl_type = 'end';

        var h_t = B2Cjs.addLeftZeros(h, 2);
        var m_t = B2Cjs.addLeftZeros(m, 2);
        return '<span class="time_point '+cl_type+'"><span class="h">'+escapeHtml(h_t)+'</span><span class="m">'+escapeHtml(m_t)+'</span></span>';
    },
    build_HTML_editor_B2CWorkHours : function() {
        return '<div class="'+quoteattr(this._className)+' edit"><p class="header">Working hours</p>'+this._build_HTML_edit_body()+'</div>';
    },
    _build_HTML_edit_body : function() {
        var html = '<div class="body">';

        for(var i=0; i<this.days.length; i++) {
            html += this._build_HTML_edit_day(this.days[i]);
        }

        html += '</div>';
        return html;
    },
    _build_HTML_edit_day : function(day_info) {
        var day_name = B2CWorkHours.const.day_num_to_name[day_info.d];
        var day_cl = 'd_'+ day_name;

        var html = '<div class="day '+quoteattr(day_cl)+'" data-d="'+quoteattr(day_info.d)+'"><span class="left"><span class="name">'+escapeHtml(day_name)+'</span></span>';

        html += '<span class="right">'+this._build_HTML_edit_day_toggle(day_info)+this._build_HTML_edit_day_intervals_cont(day_info)+'</span></div>';
        return html;
    },
    _build_HTML_edit_day_toggle : function(day_info) {
        var set_all_day_closed = '',
            set_all_day_open = '',
            set_open = '';

        if (day_info.all == B2CWorkHours.const.day_status.all_day_open)
            set_all_day_open = 'set';
        else if (day_info.all == B2CWorkHours.const.day_status.all_day_closed) {
            set_all_day_closed = 'set';
        } else {
            set_open = 'set';
        }

        return '<div class="day_toggle b2ctoggle"><span class="values"><span class="togglevalue left '+set_all_day_closed+'" data-val="all_day_closed">Closed</span><span class="togglevalue '+set_open+'" data-val="open">Open</span><span class="togglevalue right '+set_all_day_open+'" data-val="all_day_open">24 hours</span></span></div>';
    },
    _build_HTML_edit_day_intervals_cont : function(day_info) {
        var cl_hidden = '';
        if (day_info.all == B2CWorkHours.const.day_status.all_day_open || B2CWorkHours.const.day_status.all_day_closed)
            cl_hidden = 'hidden';

        var html = '<div class="intervals_cont '+cl_hidden+'">';
        if (day_info.int == null || day_info.int.length == 0) {
            html += this._build_HTML_edit_day_interval({o_h : 8, o_m : 0, c_h : 12, c_m : 0});
            html += this._build_HTML_edit_day_interval({o_h : 13, o_m : 0, c_h : 20, c_m : 0});
        } else {
            for(var i=0; i<day_info.int.length; i++) {
                html += this._build_HTML_edit_day_interval(day_info.int[i]);
            }
        }

        html += '</div>';
        return html;
    },
    _build_HTML_edit_day_interval : function(interval) {
        var open_time_point_html = this._build_HTML_edit_day_time_point(interval.o_h, interval.o_m, true);
        var close_time_point_html = this._build_HTML_edit_day_time_point(interval.c_h, interval.c_m, false);
        return '<div class="interval">'+open_time_point_html+close_time_point_html+'<span class="btn_delete"></span><span class="btn_add"></span></div>';
    },
    _build_HTML_edit_day_time_point : function(h, m, is_start) {
        var cl_type;
        if(is_start)
            cl_type = 'start';
        else
            cl_type = 'end';

        var h_t = B2Cjs.addLeftZeros(h, 2);
        var m_t = B2Cjs.addLeftZeros(m, 2);

        var input_h = '<input class="h b2cinputtext" type="number" min="0" max="23" value="'+h_t+'" data-role="none">';
        var input_m = '<input class="m b2cinputtext" type="number" min="0" max="59" value="'+m_t+'" data-role="none">';

        return '<span class="time_point '+cl_type+'">'+escapeHtml(input_h)+':'+escapeHtml(input_m)+'</span>';
    },

    build_B2CWorkHours : function(jq_parent, is_need_prepend) {
        if (this.options.is_view_mode)
            return this._build_view_B2CWorkHours(jq_parent, is_need_prepend);
        else
            return this._build_editor_B2CWorkHours(jq_parent, is_need_prepend);
    },
    _build_editor_B2CWorkHours : function(jq_parent, is_need_prepend) {
        this.jq_B2CWorkHours = $(this.build_HTML_editor_B2CWorkHours());

        if (is_need_prepend)
            this.jq_B2CWorkHours.prependTo(jq_parent);
        else
            this.jq_B2CWorkHours.appendTo(jq_parent);

        this._add_handlers();

        return this.jq_B2CWorkHours;
    },
    _build_view_B2CWorkHours : function(jq_parent, is_need_prepend) {
        this.jq_B2CWorkHours = $(this.build_HTML_view_B2CWorkHours());

        if (is_need_prepend)
            this.jq_B2CWorkHours.prependTo(jq_parent);
        else
            this.jq_B2CWorkHours.appendTo(jq_parent);

        this._add_handlers();

        return this.jq_B2CWorkHours;
    },
    _add_handlers : function() {
        this._add_handlers_body(this.jq_B2CWorkHours.children('.body'));
    },
    _add_handlers_body : function(jq_body) {
        var jq_days = jq_body.children('.day');
        for(var i=0; i<jq_days.length; i++) {
            this._add_handlers_day($(jq_days[i]));
        }
    },
    _add_handlers_day : function(jq_day) {
        var that = this;

        var jq_intervals_cont = jq_day.find('.intervals_cont');

        var jq_toggle = jq_day.find('.day_toggle');
        jq_toggle.b2ctoggle({
            multiMode : false,
            unsetAllEnabled : false,
            onValueChange : function(states) { that._on_day_toggle_value_change(jq_intervals_cont, states) }, // callback function(states)
        });

        var jq_day_interval = jq_intervals_cont.children('.interval');
        for(var i=0; i<jq_day_interval.length; i++) {
            this._add_handlers_day_interval($(jq_day_interval[i]));
        }
    },
    _add_handlers_day_interval : function(jq_day_interval) {
        var that = this;
        jq_day_interval.find('.btn_add').click(function() { that._on_btn_add_day_interval(jq_day_interval) });
        jq_day_interval.find('.btn_delete').click(function() { that._on_btn_delete_day_interval(jq_day_interval) });
        jq_day_interval.find('input.h').click(function() { that._on_input_h_change($(this)) });
        jq_day_interval.find('input.m').click(function() { that._on_input_m_change($(this)) });
    },
    _gathers_fields : function() {
        var jq_days = this.jq_B2CWorkHours.children('.body').children('.day');
        var days = [];

        for(var i=0; i<jq_days.length; i++) {
            days.push(this._gathers_fields_day($(jq_days[i])));
        }

        return days;
    },
    _gathers_fields_day : function(jq_day) {
        var day = {
            d : Number(jq_day.attr('data-d'))
        };

        var day_toggle_val = jq_day.find('.day_toggle').b2ctoggle('get_set_value');
        if(day_toggle_val == 'all_day_closed')
            day.all = B2CWorkHours.const.day_status.all_day_closed;
        else if(day_toggle_val == 'all_day_open')
            day.all = B2CWorkHours.const.day_status.all_day_open;
        else {
            day.int = [];
            var jq_day_interval = jq_day.find('.interval');
            for(var i=0; i<jq_day_interval.length; i++) {
                day.int.push(this._gathers_fields_day_interval($(jq_day_interval[i])));
            }
        }
        return day;
    },
    _gathers_fields_day_interval : function(jq_day_interval) {
        var jq_start = jq_day_interval.children('.time_point.start');
        var jq_end = jq_day_interval.children('.time_point.end');

        return {
            o_h : Number(jq_start.children('input.h').val()),
            o_m : Number(jq_start.children('input.m').val()),
            c_h : Number(jq_end.children('input.h').val()),
            c_m : Number(jq_end.children('input.m').val())
        };
    },
    get_days : function() {
        if (this.options.is_view_mode)
            return this.days;
        else
            return this._gathers_fields();
    },
    set_days : function(days) {
        this.days = days;
        if (this.days == null)
            this.days = this.def_days.slice();

        this.days.sort(function (l, r) { return l.d - r.d; });

        var jq_body_old = this.jq_B2CWorkHours.children('.body');
        var html_body;

        if (this.options.is_view_mode) {
            html_body = this._build_HTML_view_body();
        } else {
            html_body = this._build_HTML_edit_body();
        }

        var jq_body_new = $(html_body).insertAfter(jq_body_old);
        jq_body_old.remove();
        if (! this.options.is_view_mode)
            this._add_handlers_body(jq_body_new);
    },

    _add_day_interval : function(jq_prev_day_interval) {
        var inter = this._gathers_fields_day_interval(jq_prev_day_interval);

        var jq_new_day_interval = $(this._build_HTML_edit_day_interval({
            o_h : inter.o_h < 23 ? inter.o_h + 1 : 23,
            o_m : 0,
            c_h : 23,
            c_m : 59
        })).insertAfter(jq_prev_day_interval);
        this._add_handlers_day_interval(jq_new_day_interval);
    },
    _delete_day_interval : function(jq_day_interval) {
        jq_day_interval.remove();
    },

    _on_btn_add_day_interval : function(jq_day_interval) {
        this._add_day_interval(jq_day_interval);
    },
    _on_btn_delete_day_interval : function(jq_day_interval) {
        this._delete_day_interval(jq_day_interval)
    },
    _on_day_toggle_value_change : function(jq_intervals_cont, states) {
        var set_val;
        for(var i=0; i < states.length; i++) {
            var state = states[i];
            if (state.set) {
                set_val = state.val;
                break;
            }
        }
        if (set_val == 'all_day_closed' || set_val == 'all_day_open')
            jq_intervals_cont.addClass('hidden');
        else
            jq_intervals_cont.removeClass('hidden');
    },
    _on_input_h_change : function(jq_input) {
        var val = jq_input.val();
        if (val == '')
            jq_input.val(0);
    },
    _on_input_m_change : function(jq_input) {
        var val = jq_input.val();
        if (val == '')
            jq_input.val(0);
    },
};

window.B2CWorkHours = B2CWorkHours; // TODO: remove globals, use module exports
// export default B2CWorkHours;