import { Model } from '@b2cmessenger/backbone';

import Window from 'windows/Window';
import UserSearchView from 'widgets/UserSearch/UserSearch';
import HeaderView from 'widgets/HeaderWithTabs/HeaderWithTabs';
import InviteModal from 'windows/Modal/Invite';

const UserSearchWindow = Window.extend({
    options: {
        title: "User search",
        onEmptyResult(m, userSearchView) {
            const email = m && m.get('email'),
                currentWorkPlace = LoginedUserHandler.get_default_work_place();

            if (B2Cjs.checkMail(email) && currentWorkPlace) {
                new InviteModal({
                    model: new Model({
                        place_id: currentWorkPlace.id,
                        email: email,
                        firstname: m && m.get('fullname')
                    })
                })
                    .show()
                    .then(model => model && userSearchView.search(true));
            }
        }
    },

    windowName: 'user-search-window',

    onRender() {
        const headerView = new HeaderView({
            leftButtons: ['back'],
            title: this.options.title
        });

        this.listenTo(headerView, 'back:click', () => this.cancel());
        this.header.show(headerView);

        const userSearchView = new UserSearchView({
            viewModel: this.viewModel,
        });

        this.listenTo(userSearchView, 'select', m => this.close(m));

        this.listenTo(userSearchView, 'empty:result', m =>
            _.isFunction(this.options.onEmptyResult) && this.options.onEmptyResult(m, userSearchView)
        );

        this.content.show(userSearchView);
    }
});

export default UserSearchWindow;
