import React from 'react';

import MenuList, { byIdAndSortOrder, MenuListItem } from 'components/menu/MenuList/MenuList';
import { MenuSectionBase } from 'components/menu/MenuSections/MenuSection';
import TextButton from 'components/UI/TextButton';
import './MenuSections.scss';
import { Button } from 'components/UI/Button/Button';
import { RadioSwitch, SliderGroup } from 'components/UI/InputGroup/InputGroup';
import MenuTables from '../MenuTables/MenuTables';

const MenuSectionListItemClassName = 'menu-list-item-with-controls menu-editable-list-item menu-list-item-wrap';
const MenuSections = (props) => {
    const { sections, editable, selectable, displayTables } = props;
    const {
        onEditSection = _.noop,
        onRemoveSection = _.noop,
        onEditTable = _.noop,
        onRemoveTable = _.noop,
        onCreateTable = _.noop,
        onSelectionChange = _.noop,
        onClickSection = _.noop,
    } = props;

    const sectionList = sections.map((c) => {
        const leftContent = Boolean(editable) && (
            <Button className="ion-ios-close-outline remove" onClick={onRemoveSection.bind(null, { section: c })} />
        );
        const rightContent = editable ? (
            <Button className="ion-md-create edit" onClick={onEditSection.bind(null, { section: c })} />
        ) : selectable ? (
            <SliderGroup>
                <RadioSwitch
                    value={c.selected}
                    disabled={c.disabled}
                    onChange={onSelectionChange.bind(null, { section: c })}
                />
            </SliderGroup>
        ) : null;

        return (
            <React.Fragment key={byIdAndSortOrder(c)}>
                <MenuListItem
                    className={editable ? MenuSectionListItemClassName : 'menu-list-item-wrap'}
                    left={leftContent}
                    right={rightContent}
                    rightContentClassName={selectable ? 'with-switch' : null}
                    onClick={onClickSection.bind(null, { section: c })}
                >
                    <MenuSectionBase className="menu-list-item" {...c} />
                </MenuListItem>
                {displayTables && (
                    <MenuTables
                        tables={c.tables}
                        editable={editable}
                        onEditTable={onEditTable}
                        onRemoveTable={onRemoveTable}
                    >
                        {editable && (
                            <TextButton
                                className="medium border-filled"
                                editable
                                onClick={onCreateTable.bind(null, { sectionId: c.id })}
                            >
                                Create table in 
                                {' '}
                                <span>{c.name}</span>
                            </TextButton>
                        )}
                    </MenuTables>
                )}
            </React.Fragment>
        );
    });

    return (
        <MenuList {...props} type="sections">
            {sectionList}
        </MenuList>
    );
};

export default MenuSections;
