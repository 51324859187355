import React from 'react';
import { MenuListItem } from 'components/menu/MenuList/MenuList';
import './MenuItem.scss';
import './MenuItemText.scss';
import Price from 'components/UI/Price/Price';

export const getItemListItemClassName = (item, ...classNames) => {
    return ['menu-item', ...classNames].filter(Boolean).join(' ');
};

export const MenuItemContent = (props) => {
    const { icon, name, photo_thumb, price, currency } = props;

    return (
        <>
            <div className="photo" style={{ backgroundImage: `url(${photo_thumb})` }}>
                {icon && <i className={icon} />}
            </div>
            <div className="content">
                <div className="name">{name}</div>
                {Boolean(price) && <Price value={price} currency={currency} />}
            </div>
        </>
    );
};

export const MenuItemOrderContent = (props) => {
    const { icon, name, photo_thumb, children } = props;

    return (
        <>
            <div className="photo" style={{ backgroundImage: `url(${photo_thumb})` }}>
                {icon && <i className={icon} />}
            </div>
            <div className="content">
                <div className="name">{name}</div>
                {children}
            </div>
        </>
    );
};

export const MenuItemTextContent = (props) => {
    const { position, name, children } = props;

    return (
        <>
            <div className="content">
                {!_.isUndefined(position) && <div className="position">{position}</div>}
                <div className="name">{name}</div>
                {children}
            </div>
        </>
    );
};

const MenuItemBaseDefaultIcon = 'ion-ios-images';

export const MenuItemBase = (props) => {
    const { className, onSelectItem, icon, photo_thumb, price, currency, children, name } = props;

    return (
        <div className={getItemListItemClassName(props, className)} onClick={onSelectItem}>
            <MenuItemContent
                icon={icon || (!photo_thumb && MenuItemBaseDefaultIcon)}
                name={name}
                photo_thumb={photo_thumb}
                price={price}
                currency={currency}
            />
            {children}
        </div>
    );
};

export const MenuItemOrderBase = (props) => {
    const { className, selected, onSelectItem, icon, photo_thumb, name, price, currency, children } = props;

    return (
        <div
            className={getItemListItemClassName(
                props,
                className,
                selected && 'menu-item-order-selected',
                'menu-item-order'
            )}
            onClick={onSelectItem}
        >
            <MenuItemOrderContent
                icon={icon || (!photo_thumb && MenuItemBaseDefaultIcon)}
                name={name}
                photo_thumb={photo_thumb}
            >
                {Boolean(price && currency) && <Price value={price} currency={currency} />}
                {children}
            </MenuItemOrderContent>
        </div>
    );
};

export const MenuItemTextBase = (props) => {
    const { className, totalPrice, price, name, count, position, currency, children } = props;

    return (
        <div
            className={getItemListItemClassName(
                props,
                className,
                totalPrice !== price && 'menu-item-text-inconsistent-price',
                'menu-item-text'
            )}
        >
            <MenuItemTextContent name={name} count={count} position={position}>
                {Boolean(price && currency) && <Price value={price} currency={currency} />}
                {count > 1 && (
                    <div className="count">
                        &times; 
                        {' '}
                        <span>{count}</span>
                    </div>
                )}
                {children}
            </MenuItemTextContent>
        </div>
    );
};

const MenuItem = (props) => {
    const { className, onSelectItem, icon, name, photo_thumb, children } = props;

    return (
        <MenuListItem className={getItemListItemClassName(props, className, 'menu-list-item')} onClick={onSelectItem}>
            <MenuItemContent icon={icon} name={name} photo_thumb={photo_thumb} />
            {children}
        </MenuListItem>
    );
};

export default MenuItem;
