import {combineReducers, createStore, compose, applyMiddleware} from "redux";
import createSagaMiddleware from 'redux-saga';
import rootSaga from "./sagas";

import menuReducer from './menu/reducer';
import appReducer from './app/reducer';

const composeEnhancers = (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    combineReducers({
        app: appReducer,
        menu: menuReducer,
    }),
    composeEnhancers(
        applyMiddleware(
            sagaMiddleware
        )
    )
);

sagaMiddleware.run(rootSaga);

export default store;
