import React from 'react';
import './TextButton.scss';

export default function TextButton(props) {
    const { disabled = false } = props;
    const classNames = ['text-button', 'btn-empty', 'text', props.className].filter(Boolean);

    return (
        <button disabled={disabled} className={classNames.join(' ')} onClick={props.onClick} data-role="none">
            <p className="text">{props.children}</p>
        </button>
    );
}
