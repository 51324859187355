import { ItemView } from '@b2cmessenger/backbone';

import template from './LoyaltyKioskInfo.jade';
import './LoyaltyKioskInfo.scss';

const MAX_VISIBLE_COINS = 999999;

const LoyaltyKioskInfo = ItemView.extend({
    template,
    className: 'widget loyalty-kiosk-info-widget',
    ui: {
        coins: '[data-js-coins]',
        logout: '[data-js-logout]',
        time: '[data-js-time]',
        credentials: '[data-js-credentials]'
    },
    bindings: {
        '@ui.coins': 'text:coins',
        '@ui.credentials': 'text:c_Credentials',
        '@ui.time': 'classes:{hidden:not(secondsBeforeLogout)}',
        '@ui.time > span': 'text:timeLeft',
    },
    computeds: {
        c_Coins: {
            deps: ['coins'],
            get: coins => Math.max(0, Math.min(coins, MAX_VISIBLE_COINS))
        },
        c_Credentials: {
            deps: ['loggedInThroughPhone', 'loggedInThroughEmail', 'loggedInThroughLoyaltyCardNumber', 'userId'],
            get: (loggedInThroughPhone, loggedInThroughEmail, loggedInThroughLoyaltyCardNumber, userId) =>
                loggedInThroughPhone || loggedInThroughEmail || loggedInThroughLoyaltyCardNumber ||
                (userId && `User ID: ${userId}`) || 'Logged In'
        }
    },
    triggers: {
        'click @ui.logout': 'logout'
    }
});

export default LoyaltyKioskInfo;