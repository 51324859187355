var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (type) {pug_html = pug_html + "\u003Cdiv class=\"gift-templates-list\"\u003E\u003Cdiv class=\"gift-templates-list-item\"\u003E\u003Cdiv class=\"title\"\u003E";
if (type == 1) {
pug_html = pug_html + "\u003Cdiv class=\"name readonly\"\u003E\u003Cspan class=\"qty\" data-js-qty\u003E\u003C\u002Fspan\u003E\u003Cspan\u003E x Coins\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
}
else
if (type == 2) {
pug_html = pug_html + "\u003Cdiv class=\"name\"\u003E\u003Cspan class=\"qty\" data-js-qty\u003E\u003C\u002Fspan\u003E\u003Cspan\u003E x\u003C\u002Fspan\u003E\u003Cdiv class=\"editable\"\u003E\u003Cinput data-js-gift-name type=\"text\" placeholder=\"Enter gift name\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"name\"\u003E\u003Cdiv class=\"editable\"\u003E\u003Cinput data-js-gift-name type=\"text\" placeholder=\"Enter gift name\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"image\" data-js-image\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"type" in locals_for_with?locals_for_with.type:typeof type!=="undefined"?type:undefined));;return pug_html;};
module.exports = template;