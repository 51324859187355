import PhoneVerifyModal, {PhoneVerifyContent} from "windows/Modal/Phone/PhoneVerify";
import {ViewModel, Model, Required, Optional} from "@b2cmessenger/backbone";
import UserSearchHelpers from "windows/Modal/UserSearchModal/UserSearchHelpers";
import Modal from 'windows/Modal/Modal';

const LOCAL_BLOCK_SECONDS = 30;

const UserPhoneSearchVerifyModal = PhoneVerifyModal.extend({
    options: {
        user: Required,
        autoRequestVerificationCodeOnShow: true,
        parentViewModel: Optional
    },

    windowName: "phone-verify-modal-window",
    className: "modal-window phone-verify-modal-window green-border",

    initialize() {
        this.viewModel = new ViewModel({
            parentViewModel: this.options.parentViewModel || null,
            vm_code: null,
            vm_codeError: '',
            vm_now: null,
            vm_verifyId: null,
            vm_nextSmsAttemptTimeout: 300,
            vm_canContinue: null
        });

        this.viewModel.addComputed('vm_canContinue', {
            deps: ['vm_code', 'disabled'],
            get: (vm_code, disabled) => !!(vm_code && !disabled)
        });

        this.kioskHelpers = new UserSearchHelpers({ viewModel: this.viewModel });

        //@ts-ignore
        this._contentView = new UserPhoneSearchVerifyContent({
            model: new Model({ phone: this.options.user.phone }),
            viewModel: this.viewModel,
            kioskHelpers: this.kioskHelpers
        });

        this.listenToOnce(this._contentView, 'content:close', () => {
            this.close(false);
        });

        return Modal.prototype.initialize.apply(this, arguments);
    },

    async _verifyWithCode() {
        if (this.viewModel.get('vm_canContinue')) {
            const options = {
                id: this.viewModel.get('vm_verifyId'),
                code: this.viewModel.get('vm_code')
            };

            this.kioskHelpers.verifyWithCode(options)
                .then(data => {
                    if (data && data.valid) {
                        this.close(this.options.user);
                    }
                })
                .catch(error => {
                    if (error && error[0].status === 400) {
                        this.viewModel.set('vm_codeError', 'Invalid code');
                    } else {
                        this.showError(...error);
                        this.close(false);
                    }
                });
        }
    },
});

export default UserPhoneSearchVerifyModal;

class UserPhoneSearchVerifyContent extends PhoneVerifyContent {
    async _requestCode() {
        return this.options.kioskHelpers.requestCode({ phone: this.model.get('phone') })
            .then((data) => {
                if (data && data.verification_id) {
                    this.viewModel.set('vm_verifyId', data.verification_id);
                    this._updateBlockTimer(LOCAL_BLOCK_SECONDS);
                }
            })
            .catch((error) => {
                this.showError(...error);
                this.trigger('content:close', false);
            });
    }
}