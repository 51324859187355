import { Trait } from '@b2cmessenger/backbone';

const traitId = Symbol;

class ViewWithWindows extends Trait {
    constructor(options, _traitId) {
        super(_traitId || traitId);

        this.options = _.defaults(options || (options = {}), {
            windowMap: null
        });

        _.extend(this.methods || (this.methods = {}),
            {
                onRestoreWindowsState: this.onRestoreWindowsState,
                hasWindowMapping: this.hasWindowMapping
            }
        );
    };

    onRestoreWindowsState(f, trait, windows, options) {
        _.defaults(options || (options = {}), {
            routeName: null,
            args: null
        });

        f && f.apply(this, windows, options);

        const { windowMap } = trait.options;
        let promise = Promise.resolve();

        if (windowMap && windowMap.length && windows && windows.length) {
            const w = windows[0];
            let foundWindow = _.find(windowMap, wnd => wnd.cls.prototype.windowName == w.name);

            if (foundWindow) {
                const restoreFn = () => {
                    foundWindow.trigger.call(this, w.value);
                    promise
                        .then(() => trait._waitForWindow.call(this, w.name))
                        .then(openedWindow => {
                            if (openedWindow) {
                                openedWindow.triggerMethod(
                                    'restore:windows:state',
                                    windows.slice(1),
                                    options
                                );
                            }
                        })
                        .then(() => {
                            console.log(`[Window State] Successfully restored windows for '${this.windowName || this.pageName}'`);
                        });
                };

                if (this.isRendered) {
                    restoreFn();
                } else {
                    this.once(foundWindow.attachEventName || 'render', () => restoreFn());
                }
            }
        }
    }

    hasWindowMapping(f, trait, windowName) {
        f && f.apply(this, windowName);

        return _.find(trait.options.windowMap, wnd => wnd.cls.prototype.windowName == windowName);
    }

    _ensurePromise(value) {
        return value instanceof Promise ? value : Promise.resolve(value);
    }

    _waitForWindow(windowName) {
        return new Promise((resolve, reject) => {
            const timer = setTimeout(() => reject('Timed out'), 60000);

            const showHandler = w => {
                if (w && w.windowName == windowName) {
                    resolve(w);

                    this.stopListening(app.controller, 'window:show', showHandler);
                    clearTimeout(timer);
                }
            };

            this.listenTo(app.controller, 'window:show', showHandler.bind(this));
        });
    }
};

export default ViewWithWindows;