import React from 'react';
import PropTypes from 'prop-types';

import MenuList, { byIdAndSortOrder, MenuListItem, MenuListType } from 'components/menu/MenuList/MenuList';
import { MenuItemOrderBase } from 'components/menu/MenuItem/MenuItem';
import MenuItemQuantityControl from 'components/menu/MenuItemQuantityControl/MenuItemQuantityControl';
import './MenuItemsWithControl.scss';
import { MenuHiddenVisibility } from 'components/menu/MenuVisibility/MenuVisibility';
import { getAdditionItemPrice, getItemPrice } from 'store/menu/utils';

const MenuItemsWithControl = (props) => {
    const {
        items,
        onSelectItem = _.noop,
        onChangeCount = _.noop,
        className = '',
        itemsCountMap = [],
        autosize = false,
        listItemClassName = null,
        onIncrease = null,
        onDecrease = null,
        disabled = false,
        displayMenuHiddenVisibility = false,
    } = props;

    const itemList = _(items).map((e) => {
        const i = {
            ...e,
            price: props.additions ? getAdditionItemPrice(e) : getItemPrice(e),
        };

        let count = 0;

        // eslint-disable-next-line no-prototype-builtins
        if (i.hasOwnProperty('count')) {
            count = i.count;
        } else if (!itemsCountMap) {
            count = 0;
        } else if (_.isArray(itemsCountMap)) {
            const idx = _.findIndex(itemsCountMap, (a) => a.id === i.id);
            count = idx !== -1 ? itemsCountMap[idx].count : 0;
        } else if (_.isObject(itemsCountMap)) {
            count = itemsCountMap[i.id]
                ? _.isObject(itemsCountMap[i.id])
                    ? itemsCountMap[i.id].count
                    : itemsCountMap[i.id]
                : 0;
        }

        return (
            <MenuListItem
                className={_.isFunction(listItemClassName) ? listItemClassName(i) : 'menu-list-item-wrap'}
                key={i.key || i.uniqueId || byIdAndSortOrder(i)}
            >
                <MenuItemOrderBase
                    className="menu-list-item menu-list-item-with-quantity-change-control"
                    {...i}
                    selected={count > 0}
                    onSelectItem={onSelectItem.bind(null, i.id, i)}
                >
                    <MenuItemQuantityControl
                        wideClickArea
                        count={count}
                        autosize={autosize}
                        maximum={_.isNumber(i.maximum) ? i.maximum : null}
                        minimum={_.isNumber(i.minimum) ? i.minimum : null}
                        disabled={disabled}
                        onChange={(val) => onChangeCount({ item: i, count: val })}
                        onMinusClickHandler={onDecrease && onDecrease.bind(null, i, count)}
                        onPlusClickHandler={onIncrease && onIncrease.bind(null, i)}
                    />
                    {displayMenuHiddenVisibility && (i.is_hidden_forever || i.hidden_until) && <MenuHiddenVisibility />}
                </MenuItemOrderBase>
                {_.isFunction(props.childrenFn) && props.childrenFn(i, props)}
            </MenuListItem>
        );
    });

    return (
        <MenuList className={className} {...props} type={MenuListType.Items}>
            {itemList}
        </MenuList>
    );
};
MenuItemsWithControl.propTypes = {
    items: PropTypes.any,
    onSelectItem: PropTypes.func,
    onChangeCount: PropTypes.func,
    className: PropTypes.any,
    itemsCountMap: PropTypes.any,
    autosize: PropTypes.bool,
    listItemClassName: PropTypes.any,
    onIncrease: PropTypes.func,
    onDecrease: PropTypes.func,
    disabled: PropTypes.bool,
    displayMenuHiddenVisibility: PropTypes.bool,
};

export default MenuItemsWithControl;
