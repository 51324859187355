import { Model } from '@b2cmessenger/backbone';

import EvidencePhotoCollection from 'models/EvidencePhotoCollection';

const Type = {
    Selfie: 1,
    Receipt: 2,
    Other: 3
};

const Status = {
    Discarded: -1,
    Draft: 0,
    Active: 1,
    Approved: 2,
};

const EvidenceModel = Model.extend({
    defaults: () => _.create({
        type: Type.Other,
        status: Status.Draft,
        photoCollection: new EvidencePhotoCollection(),
        created_at: ""
    }),

    computeds: {
        photos: {
            deps: ['photoCollection'],
            get: photoCollection => photoCollection ? photoCollection.toJSON() : [],
            set: function(photos) {
                this.get('photoCollection').set(_(photos).map(p => _(p).extend({ evidence_id: this.id })));
                return { photoCollection: this.get('photoCollection') };
            }
        },
        hasPhotos: {
            deps: ['photos'],
            get: photos => photos && photos.length
        },
        isDraft: {
            deps: ['status'],
            get: status => status == Status.Draft
        }
    },

    initialize(attrs) {
        let photoCollection = this.get('photoCollection');
        photoCollection.listenTo(this, 'change:id', (m, id) => photoCollection.map(p => p.set('evidence_id', id)));
        this.listenTo(photoCollection, 'update reset change', () => this.trigger('change:photoCollection', this, photoCollection, {}));
    },

    sync(method, model, options) {
        _.defaults(options || (options = {}));

        var error = options.error;
        options.error = function(xhr, textStatus, errorThrown) {
            options.textStatus = textStatus;
            options.errorThrown = errorThrown;
            if (error) error.call(options.context, xhr, textStatus, errorThrown);
        };

        var success = options.success;
        options.success = data => {
            if(_.isArray(data.photos)) data.photos = data.photos;
            else delete data.photos;

            this._previousValidation = {
                status: data.status,
                date: data.validated_at,
                comment: data.comment,
                user_id: _.isObject(data.validate_user) ? data.validate_user.id : data.validate_user,
            };
            if (success) success.call(options.context, data);
        };

        if(method == "create") {
            let data = _(model.toJSON(options)).pick(["id", "place_id", "type", "status"]);
            data.mode = B2CEvidence.const.mode.save;

            let xhr = B2CEvidence.server_api_save(data, options.success, options.error);
            model.trigger('request', model, xhr, options);
            return xhr;
        } else if(method == "update" || method == "patch") {
            let currentValidation = model.get('validation');
            let prevValidation = this._previousValidation;

            let isNewValidation = currentValidation &&
                (
                    !prevValidation
                    ||
                    (currentValidation.user && currentValidation.user.id && currentValidation.user.id != prevValidation.user_id)
                    ||
                    ((currentValidation.status == Status.Approved || currentValidation.status == Status.Discarded) && currentValidation.status != prevValidation.status)
                    ||
                    (currentValidation.comment != prevValidation.comment)
                );

            if(isNewValidation) {
                let xhr = B2CEvidence.server_api_businessvalidate(
                    model.id,
                    currentValidation.status,
                    currentValidation.comment,
                    (data) => {
                        if(data.success) {
                            let validateUser = _.clone(LoginedUserHandler.getLoginedUser());
                            options.success({
                                comment: currentValidation.comment,
                                status: currentValidation.status,
                                validation: {
                                    status: currentValidation.status,
                                    date: B2Cjs.datetimeJSToServer(new Date),
                                    comment: currentValidation.comment,
                                    user: validateUser
                                },
                                validate_user: validateUser,
                                validated_at: B2Cjs.datetimeJSToServer(new Date)
                            });
                        } else {
                            options.error(xhr, xhr.statusText, data.error);
                        }
                    },
                    (jqXHR, textStatus, errorThrown) => {
                        options.error(jqXHR, textStatus, errorThrown)
                    });

                model.trigger('request', model, xhr, options);
                return xhr;
            } else {
                let data = _(model.toJSON(options)).pick(["id", "place_id", "type", "status"]);
                data.mode = B2CEvidence.const.mode.save;

                let xhr = B2CEvidence.server_api_save(data, options.success, options.error);
                model.trigger('request', model, xhr, options);
                return xhr;
            }

        } else if(method == "delete") {
            if(model.id)
                return B2CEvidence.server_api_delete(model.id, options.success, options.error);
            else
                throw new Error("Empty id!");
        } else {
            throw new Error("Not implemented!");
        }
    },

    parse(data, options) {
        if(data.user_id && options.users && options.users[data.user_id]) {
            data.user = options.users[data.user_id];
        }

        if(data.validate_user && (_.isNumber(data.validate_user) || _.isString(data.validate_user)) && options.users && options.users[data.validate_user]) {
            data.validate_user = options.users[data.validate_user];
        }

        return data;
    },

    toJSON(...args) {
        let data = Model.prototype.toJSON.call(this, ...args);
        data.photoCollection = data.photoCollection && data.photoCollection.toJSON && data.photoCollection.toJSON();
        return data;
    }
}, {
    Type, Status
});

export default EvidenceModel;
