import { Marionette } from '@b2cmessenger/backbone';
import template from './Search.jade';

export default Marionette.ItemView.extend({
    className: 'search-inner',
    template: template,
    ui: {
        input: '[data-js-search-input]'
    },
    events: {
        'change @ui.input': 'changeHandler',
        'keyup @ui.input': 'changeHandler'
    },
    changeHandler(e) {
        this.trigger('change', this.ui.input.val().trim());
    },
    onRender() {

    }
});
